import type { Action } from 'redux';
import type { ErrorObject } from 'serialize-error';
import { serializeError } from 'serialize-error';

export interface Failure<T extends string> extends Action {
  type: T;
  payload: { error: ErrorObject };
}

export interface EntityFailure<T extends string> extends Action {
  type: T;
  payload: { error: ErrorObject; id: string };
}

const errorCodesToIgnore = ['login_required'];

export function failureAction<T extends string>(type: T, error: Error): Failure<T>;
export function failureAction<T extends string>(type: T, error: Error, id: string): EntityFailure<T>;
export function failureAction<T extends string>(type: T, error: Error, id?: string): Failure<T> | EntityFailure<T> {
  const serializedError = serializeError(error);
  const errorCode = serializedError.code || serializedError.name;

  if (!errorCode || !errorCodesToIgnore.includes(errorCode)) {
    // eslint-disable-next-line no-console
    console.error('failureAction', serializedError);
  } else {
    // eslint-disable-next-line no-console
    console.warn('failureAction', serializedError);
  }

  return { type, payload: { error: serializeError(error), id } };
}
