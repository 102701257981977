import CheckIcon from '@mui/icons-material/Check';
import DeselectIcon from '@mui/icons-material/Deselect';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExploreIcon from '@mui/icons-material/Explore';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import type { Dictionary } from '@reduxjs/toolkit';
import { closeDialogAction, openDialogAction } from 'actions';
import { bringToBackAction, bringToFrontAction, changeShapeVisibilityAction, renameShapeAction } from 'actions/circuit';
import { assignNameToStockLines } from 'components/properties/stockzones-properties';
import { DialogTypes } from 'models';
import type { CircuitRack, CircuitShape, CircuitStockZone, SlotArray } from 'models/circuit';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LayerData, LoadedRack } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useDebounce } from 'use-debounce';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { isCircuitShape, isSlotArray, isStockZone } from 'utils/circuit/utils';
import { zoomToShape } from 'utils/circuit/zoom-to-shape';
import { capitalize } from 'utils/helpers';
import { isDefined } from 'utils/ts/is-defined';

const layersKeysSaveState = `search-shape-filters-layers-`;
const shapeTypesSaveState = `search-shape-filters-shape-`;
const shapeSlotsSaveState = `search-shape-filters-shape-`;

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      '& tr:nth-child(2n)': {
        background: 'rgba(0, 0, 0, 0.02)',
      },
    },
    chip: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    hiddenLayerIcon: {
      verticalAlign: 'bottom',
      color: theme.palette.grey[500],
      marginRight: theme.spacing(0.5),
    },
    lineThrough: {
      textDecoration: 'line-through',
    },
    dialog: {
      '&:not(:hover)': {
        opacity: 0.5,
      },
    },
  })
);
export interface RackPosition {
  id: string;
  value: string;
  user: boolean;
  disabled?: boolean | undefined;
}

interface ChipData {
  name: string;
  active: boolean;
  layerId?: string;
  shapeType?: ShapeTypes;
}

interface SlotChipData {
  name: string;
  active: boolean;
  layerId?: string;
  shapeType?: SlotTypes;
}

export default function SearchShapeDialog(): JSX.Element {
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const choosedShape = useAppSelector((state) =>
    state.dialog?.data && 'searchedShapesIds' in state.dialog?.data ? state.dialog.data.searchedShapesIds : undefined
  );
  const racksSlots: Dictionary<LoadedRack> = useAppSelector((state) => state.circuit.present.racks.entities);
  const racksArray: LoadedRack[] = Object.values(racksSlots).filter(isDefined);

  const stockZones = useAppSelector((state) => state.circuit.present.stockZones.entities);
  const stockZonesIds = useAppSelector((state) => state.circuit.present.stockZones.ids);

  const [useOnlyIdsForSearch, setUseOnlyIdsForSearch] = useState<boolean>(false);

  useEffect(() => {
    // Checking if choosedShape is not empty
    if (choosedShape && choosedShape.length > 0) {
      setUseOnlyIdsForSearch(true);
    } else {
      setUseOnlyIdsForSearch(false);
    }
  }, [choosedShape]);
  const choosedShapeIdsString = choosedShape ? choosedShape.join(', ') : '';

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerpage] = useState(30);

  const [updateShapes, setUpdateShapes] = useState(false);
  const [editShapeNameId, setEditShapeNameId] = useState('');

  const shapes = useMemo(() => {
    setUpdateShapes(false);

    return CircuitService.getShapes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShapes]);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleBringToFront = useCallback(
    (shapeId: string) => {
      const shape = CircuitService.getShape(shapeId);
      if (!shape) return;

      dispatch(bringToFrontAction({ id: shapeId, type: shape.properties.type }));
    },
    [dispatch]
  );

  const handleBringToBack = useCallback(
    (shapeId: string) => {
      const shape = CircuitService.getShape(shapeId);
      if (!shape) return;

      dispatch(bringToBackAction({ id: shapeId, type: shape.properties.type }));
    },
    [dispatch]
  );

  const handleZoomToShape = useCallback(
    (shapeId: string) => {
      zoomToShape(shapeId);

      handleClose();
    },
    [handleClose]
  );

  const toggleVisibility = useCallback(
    (shapeId: string) => {
      dispatch(
        changeShapeVisibilityAction({
          id: shapeId,
        })
      );

      setUpdateShapes(true);
    },
    [dispatch]
  );

  const shapesChipsInit = useMemo(() => {
    const shapeActiveDefaultValue = true;

    return Object.values(ShapeTypes).map((shapeName) => {
      const activeSavedValue = sessionStorage.getItem(`${shapeTypesSaveState}${shapeName}`);
      const active = activeSavedValue ? activeSavedValue === 'true' : shapeActiveDefaultValue; // active = shape type selected = shape of this type displayed

      return {
        name: capitalize(`${shapeName}s`),
        active,
        shapeType: shapeName,
      } as ChipData;
    });
  }, []);

  const slotsChipsInit: SlotChipData[] = useMemo(() => {
    const shapeActiveDefaultValue = true;

    const firstSlotType = Object.values(SlotTypes)[0]; // Get the first SlotType for now because we didnt add the STOCKLINE in the search menu yet
    const activeSavedValue = sessionStorage.getItem(`${shapeSlotsSaveState}${firstSlotType}`);
    const active = activeSavedValue ? activeSavedValue === 'true' : shapeActiveDefaultValue;

    return [
      {
        name: capitalize(`${firstSlotType}s`),
        active,
        shapeType: firstSlotType,
      },
    ];
  }, []);

  const [shapesChips, setShapesChips] = useState(shapesChipsInit);
  const [slotsChips, setSlotsChips] = useState(slotsChipsInit);

  const layersChipsInit = useMemo(() => {
    const layerActiveDefaultValue = true;

    return Object.values(layers).map((layer) => {
      const activeSavedValue = sessionStorage.getItem(`${layersKeysSaveState}${layer.id}`);
      const active = activeSavedValue ? activeSavedValue === 'true' : layerActiveDefaultValue; // active = layer selected = shape of this layer displayed

      return {
        name: layer.name,
        active,
        layerId: layer.id,
      } as ChipData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [layersChips, setLayersChips] = useState(layersChipsInit);

  /** save the value in the session storage */
  useEffect(() => {
    if (window.__TEST__) return; // we disable this feature during the tests

    shapesChips.forEach((shapeChip) => {
      sessionStorage.setItem(`${shapeTypesSaveState}${shapeChip.shapeType}`, shapeChip.active.toString());
    });
  }, [shapesChips]);
  useEffect(() => {
    if (window.__TEST__) return; // we disable this feature during the tests

    slotsChips.forEach((slotChips) => {
      sessionStorage.setItem(`${shapeSlotsSaveState}${slotChips.shapeType}`, slotChips.active.toString());
    });
  }, [slotsChips]);
  useEffect(() => {
    if (window.__TEST__) return; // we disable this feature during the tests

    layersChips.forEach((layerChip) => {
      sessionStorage.setItem(`${layersKeysSaveState}${layerChip.layerId}`, layerChip.active.toString());
    });
  }, [layersChips]);

  const handleShapeChipClick = useCallback(
    (e): void => {
      const el = e.target as HTMLElement;
      const name = (el.textContent || '').toUpperCase();

      setShapesChips(
        shapesChips.map((shapeChip) => {
          if (shapeChip.name.toUpperCase() === name) {
            shapeChip.active = !shapeChip.active;
          }

          return shapeChip;
        })
      );
    },
    [shapesChips]
  );

  const handleLayerChipClick = useCallback(
    (e) => {
      const el = e.target as HTMLElement;
      const name = (el.textContent || '').toUpperCase();

      setLayersChips(
        layersChips.map((layerChip) => {
          if (layerChip.name.toUpperCase() === name) {
            layerChip.active = !layerChip.active;
          }

          return layerChip;
        })
      );
    },
    [layersChips]
  );

  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    if (choosedShapeIdsString && choosedShapeIdsString !== searchName) {
      setSearchName(choosedShapeIdsString);
      const newChapeChips = shapesChips.map((shapeChip) => {
        shapeChip.active = true;

        return shapeChip;
      });
      const newSlotsChapeChips = slotsChips.map((SlotShapeChips) => {
        SlotShapeChips.active = true;

        return SlotShapeChips;
      });
      setShapesChips(newChapeChips);
      setSlotsChips(newSlotsChapeChips);
      const newLayerChips = layersChips.map((layersChip) => {
        layersChip.active = true;

        return layersChip;
      });
      setLayersChips(newLayerChips);
    }
  }, [choosedShapeIdsString, layersChips, searchName, shapesChips, slotsChips]);

  const [debouncedSeachName] = useDebounce(searchName, 500);

  const rackPositions: Record<string, RackPosition[]> = useMemo(() => {
    const res: Record<string, CircuitRack['properties']['columns'][0]['cells'][0]['names'][0]> = {};

    racksArray.forEach((rack) => {
      const rackId = rack.id?.toString();
      if (!rackId) return;

      const cellPositions = rack.properties.columns
        .flatMap((column) => column.cells.flatMap((cell) => cell.names))
        .flat();
      res[rackId] = cellPositions;
    });

    return res;
  }, [racksArray]);

  const stockLines = useMemo(() => {
    const res: Record<string, CircuitStockZone['properties']['slots']> = {};

    stockZonesIds.forEach((id) => {
      const stockZone = stockZones[id];

      const stockZoneId = stockZone.id?.toString();

      if (!stockZoneId) return;

      const stockLines = stockZone.properties.slots.flatMap((line) => line);
      res[stockZoneId] = stockLines;
    });

    return res;
  }, [stockZones, stockZonesIds]);

  const handleSlotShapeChipClick = useCallback((e: React.MouseEvent<HTMLElement>): void => {
    const el = e.target;

    if (el instanceof HTMLElement) {
      const name = (el.textContent || '').toUpperCase();

      setSlotsChips((prevChips) =>
        prevChips.map((SlotShapeChip) => {
          if (SlotShapeChip.name.toUpperCase() === name) {
            SlotShapeChip.active = !SlotShapeChip.active;

            return SlotShapeChip;
          }

          return SlotShapeChip;
        })
      );
    }
  }, []);

  const handleChangeSearchName = useCallback((e) => {
    setSearchName(e.target.value);
  }, []);

  const filteredShapesOrSlots = useMemo(() => {
    const debouncedSearchNameLowerCase = debouncedSeachName.toLowerCase();

    // first we get all the circuit shapes that matches the search
    const shapesThatMatch = shapes
      .filter((shape) => {
        return shapesChips.find((shapeChip) => shapeChip.shapeType === shape.properties.type)?.active;
      })
      .filter((shape) => {
        return layersChips.find((layerChip) => layerChip.layerId === shape.properties.layerId)?.active;
      })
      .filter((shape) => {
        if (useOnlyIdsForSearch) {
          // If useOnlyIdsForSearch is true, only filter based on shape.id
          return choosedShape && choosedShape.includes(shape.id as string);
        }

        if (!debouncedSearchNameLowerCase)
          // Otherwise, continue with the original logic
          return true;

        return (
          shape.properties?.name?.toLowerCase().includes(debouncedSearchNameLowerCase) ||
          shape.id === debouncedSearchNameLowerCase
        );
      });

    let slotsThatMatch: (RackPosition & { rackId: string })[] = [];

    const slotsChipEnabled = !!slotsChips.find((slotChip) => slotChip.active);
    if (!slotsChipEnabled) {
      // here we filter the slots based on the slot button
      slotsThatMatch = [];
    } else {
      Object.keys(rackPositions).forEach((rackId) => {
        const rack = racksSlots[rackId];
        const layerId = rack?.properties.layerId;
        const isLayerShipEnabled = !!layerId && layersChips.find((layerChip) => layerChip.layerId === layerId)?.active;
        if (!isLayerShipEnabled) return;

        const rackPositionsForSelectedRack = rackPositions[rackId];

        const matchedRackPosition = rackPositionsForSelectedRack
          .filter((selectedRackPosition) => {
            return selectedRackPosition.value.toLowerCase().includes(debouncedSearchNameLowerCase);
          })
          .map((rackPositionForThisRack) => {
            return {
              ...rackPositionForThisRack,
              rackId,
            };
          });

        slotsThatMatch.push(...matchedRackPosition);
      });
    }

    const stockLinesThatMatch: (SlotArray & { stockZoneId: string })[] = [];

    const stockLinesChipEnabled = !!slotsChips.find((slotChip) => slotChip.active);
    if (!stockLinesChipEnabled) {
      // here we filter the slots based on the slot button
      slotsThatMatch = [];
    } else {
      Object.keys(stockLines).forEach((stockZoneId) => {
        const stockZone = stockZones[stockZoneId];
        const layerId = stockZone?.properties.layerId;
        const isLayerShipEnabled = !!layerId && layersChips.find((layerChip) => layerChip.layerId === layerId)?.active;
        if (!isLayerShipEnabled) return;

        const stockLine = stockLines[stockZoneId];

        const matchedStockLine = stockLine
          .filter((lineArray) => {
            return lineArray.name.toLowerCase().includes(debouncedSearchNameLowerCase);
          })
          .map((line) => {
            return {
              ...line,
              stockZoneId,
            };
          });

        stockLinesThatMatch.push(...matchedStockLine);
      });
    }

    return [...shapesThatMatch, ...slotsThatMatch, ...stockLinesThatMatch];
  }, [
    debouncedSeachName,
    shapes,
    slotsChips,
    shapesChips,
    layersChips,
    useOnlyIdsForSearch,
    choosedShape,
    rackPositions,
    racksSlots,
    stockLines,
    stockZones,
  ]);

  const filteredShapesOnly = useMemo(() => {
    return filteredShapesOrSlots.filter(isCircuitShape);
  }, [filteredShapesOrSlots]);
  const onlySlotsLength = filteredShapesOrSlots.length - filteredShapesOnly.length;

  const selectAllFilteredShapes = useCallback(() => {
    /* Get their ids */
    if (!filteredShapesOrSlots.length) return;

    handleClose();

    /* Select them */
    dispatch(
      openDialogAction({
        type: DialogTypes.SearchShapeResultSelection,
        payload: {
          shapes: filteredShapesOnly,
        },
      })
    );
  }, [filteredShapesOrSlots.length, handleClose, dispatch, filteredShapesOnly]);

  const handleChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerpage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const isAllShapesChipsSelected = shapesChips.every((shapesChip) => shapesChip.active);
  const isAllSlotChipsSelected = slotsChipsInit.every((slotsChip) => slotsChip.active);
  const handleDeselectChips = useCallback(() => {
    if (isAllShapesChipsSelected && isAllSlotChipsSelected) {
      const newShapeChips = shapesChips.map((shapeChip) => {
        shapeChip.active = false;

        return shapeChip;
      });
      const newSlotShapeChips = slotsChips.map((slotShapeChip) => {
        slotShapeChip.active = false;

        return slotShapeChip;
      });
      setShapesChips(newShapeChips);
      setSlotsChips(newSlotShapeChips);

      return;
    }

    const newChapeChips = shapesChips.map((shapeChip) => {
      shapeChip.active = true;

      return shapeChip;
    });
    const newSlotShapeChips = slotsChips.map((slotShapeChip) => {
      slotShapeChip.active = true;

      return slotShapeChip;
    });

    setShapesChips(newChapeChips);
    setSlotsChips(newSlotShapeChips);
  }, [isAllShapesChipsSelected, isAllSlotChipsSelected, shapesChips, slotsChips]);

  const isAllLayerChipsSelected = layersChips.every((layerchip) => layerchip.active);
  const handleDeselectLayers = useCallback(() => {
    if (isAllLayerChipsSelected) {
      const newLayerChips = layersChips.map((layersChip) => {
        layersChip.active = false;

        return layersChip;
      });
      setLayersChips(newLayerChips);

      return;
    }

    const newLayerChips = layersChips.map((layersChip) => {
      layersChip.active = true;

      return layersChip;
    });
    setLayersChips(newLayerChips);
  }, [layersChips, isAllLayerChipsSelected]);

  function ChangeShapeNameInputComponent({
    shape,
    setUpdateShapes,
  }: {
    shape: CircuitShape;
    setUpdateShapes?: React.Dispatch<React.SetStateAction<boolean>>;
  }): JSX.Element {
    const shapeNameRef = useRef<HTMLInputElement>(null);
    const shapeTypeProperty = CircuitService.getStorePropertyOfShapeType(shape.properties.type);
    const shapeName = useAppSelector(
      (state) => state.circuit.present[shapeTypeProperty].entities[shape.id as string]?.properties?.name
    );

    const isShapeStockZone = isStockZone(shape);

    const stockZone = isShapeStockZone ? shape : undefined;

    const updateStockLineNames = useCallback(
      (name: string) => {
        if (stockZone) {
          assignNameToStockLines('all', null, undefined, true, name, stockZone, stockZone.properties.name);
        } else {
          // eslint-disable-next-line no-console
          console.error('StockZone not found');
        }
      },
      [stockZone]
    );

    const handleConfirm = useCallback(() => {
      if (!shapeNameRef.current?.value) return;
      const trimmedName = shapeNameRef.current?.value.trim();
      if (trimmedName === shape.properties?.name) {
        setEditShapeNameId('');

        return;
      }

      const isShapeNameUsed = !areAllShapeNamesUnique([shapeNameRef.current.value], undefined, {
        ignoreDuplicatesBefore: true,
      });
      if (isShapeNameUsed) {
        SnackbarUtils.closeSnackbar();
        SnackbarUtils.warning(`Shape name "${trimmedName}" already used, please choose another one`);

        return;
      }

      if (isShapeStockZone) {
        const trimmedName = shapeNameRef.current.value.trim();
        updateStockLineNames(trimmedName);

        setEditShapeNameId('');
        if (setUpdateShapes) setUpdateShapes(true);

        return;
      }

      const trimmedNameInput = shapeNameRef.current.value.trim();
      dispatch(renameShapeAction({ shape, name: trimmedNameInput }));
      setEditShapeNameId('');

      if (setUpdateShapes) setUpdateShapes(true);
    }, [isShapeStockZone, setUpdateShapes, shape, updateStockLineNames]);

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();

          setEditShapeNameId('');

          return;
        }

        if (e.key === 'Enter') {
          e.preventDefault();

          handleConfirm();
        }
      },
      [handleConfirm]
    );

    const handleBlur = useCallback((e) => {
      if (e.relatedTarget?.title === 'Edit shape name') return;

      setEditShapeNameId('');
    }, []);

    return (
      <TextField
        autoFocus
        fullWidth
        size="small"
        defaultValue={shapeName}
        variant="outlined"
        onKeyDown={handleKeyPress}
        onBlur={handleBlur}
        inputRef={shapeNameRef}
        InputProps={{
          endAdornment: (
            <IconButton title="Edit shape name" size="medium" onClick={handleConfirm}>
              <CheckIcon />
            </IconButton>
          ),
        }}
      />
    );
  }

  /**
   * To redirect to the associated shape (rack or stockZone) and update the filters
   */
  const handleRedirectToShape = useCallback(
    (isStockLine: boolean, id: string) => {
      const newShapesChips = shapesChips.map((shapeChip) => ({
        ...shapeChip,
        active:
          shapeChip.active ||
          (isStockLine
            ? shapeChip.shapeType === ShapeTypes.StockZoneShape
            : shapeChip.shapeType === ShapeTypes.RackShape),
      }));
      setShapesChips(newShapesChips);

      setSearchName(id);
    },
    [shapesChips]
  );

  return (
    <Dialog open={true} fullWidth={true} maxWidth="md" onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        <TextField
          fullWidth
          placeholder="Search shape name"
          value={searchName}
          onChange={handleChangeSearchName}
          variant="outlined"
        />
        <Box component="div" sx={{ flexGrow: 1, paddingTop: 2 }}>
          <Grid container spacing={2}>
            <Grid alignItems="center" justifyContent="center" item xs={1}>
              <Typography sx={{ mt: '6px' }}>Shapes</Typography>
            </Grid>
            <Grid alignItems="center" justifyContent="center" item xs={10}>
              <div>
                {shapesChips.map((shapeChip) => (
                  <Chip
                    size="small"
                    key={shapeChip.name}
                    color="primary"
                    label={shapeChip.name}
                    variant={shapeChip.active ? undefined : 'outlined'}
                    icon={<FormatShapesIcon fontSize="small" />}
                    className={classes.chip}
                    onClick={handleShapeChipClick}
                  />
                ))}
                {slotsChips.map((slotShapeChip) => (
                  <Chip
                    size="small"
                    key={slotShapeChip.name}
                    color="primary"
                    label={slotShapeChip.name}
                    variant={slotShapeChip.active ? undefined : 'outlined'}
                    icon={<FormatShapesIcon fontSize="small" />}
                    className={classes.chip}
                    onClick={handleSlotShapeChipClick}
                  />
                ))}
              </div>
            </Grid>
            <Grid alignItems="center" justifyContent="center" item xs={1}>
              <Tooltip title={!isAllShapesChipsSelected ? 'Select all shapes' : 'Deselect all shapes'}>
                <IconButton
                  size="large"
                  onClick={(e) => {
                    handleDeselectChips();
                  }}
                >
                  {isAllShapesChipsSelected ? <DeselectIcon /> : <SelectAllIcon />}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid alignItems="center" justifyContent="center" item xs={1}>
              <Typography sx={{ mt: '6px' }}>Layers</Typography>
            </Grid>
            <Grid alignItems="center" justifyContent="center" item xs={10}>
              <div>
                {layersChips.map((layerChip) => (
                  <Chip
                    size="small"
                    key={layerChip.name}
                    color="primary"
                    label={layerChip.name}
                    variant={layerChip.active ? undefined : 'outlined'}
                    icon={<LayersOutlinedIcon fontSize="small" />}
                    className={classes.chip}
                    onClick={handleLayerChipClick}
                    data-testid="search-shape-layer-button"
                  />
                ))}
              </div>
            </Grid>
            <Grid alignItems="center" justifyContent="center" item xs={1}>
              <Tooltip title={!isAllLayerChipsSelected ? 'Select all layers' : 'Deselect all layers'}>
                <IconButton
                  onClick={(e) => {
                    handleDeselectLayers();
                  }}
                  size="large"
                >
                  {isAllLayerChipsSelected ? <DeselectIcon /> : <SelectAllIcon />}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        {filteredShapesOrSlots.length ? (
          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Layer</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredShapesOrSlots
                  .slice(rowsPerPage * page, rowsPerPage + rowsPerPage * page)
                  .map((shapeOrSlot, index) => {
                    const isShape = isCircuitShape(shapeOrSlot);
                    const isStockLine = isSlotArray(shapeOrSlot);

                    let layer: LayerData | undefined = undefined;
                    let rackName: string | undefined;
                    let stockZoneName: string | undefined;
                    let shapeType: SlotTypes | ShapeTypes;
                    if (isShape) {
                      layer = layers[shapeOrSlot.properties.layerId];
                      shapeType = shapeOrSlot.properties.type;
                    } else {
                      //when isStockLine and isShape do not exist, the shape is a rack
                      if (!isStockLine) {
                        const position = shapeOrSlot;
                        const rackId = position.rackId;
                        const rack = store.getState().circuit.present.racks.entities[rackId];
                        if (!rack) {
                          // eslint-disable-next-line no-console
                          console.error(`Rack ${rackId} not found of position ${position.value}`);

                          return null;
                        }

                        rackName = rack.properties.name;
                        layer = layers[rack.properties.layerId];
                        shapeType = SlotTypes.Slot;
                      } else {
                        const position = shapeOrSlot;
                        const stockZoneId = position.stockZoneId;
                        const stockZone = store.getState().circuit.present.stockZones.entities[stockZoneId];
                        if (!stockZone) {
                          // eslint-disable-next-line no-console
                          console.error(`StockZone ${stockZoneId} not found`);

                          return null;
                        }

                        stockZoneName = stockZone.properties.name;
                        layer = layers[stockZone.properties.layerId];
                        shapeType = SlotTypes.StockLine;
                      }
                    }

                    if (!layer) {
                      // eslint-disable-next-line no-console
                      console.error(`Layer not found`);

                      return null;
                    }

                    return (
                      <TableRow
                        key={shapeOrSlot.id as string}
                        style={{ boxShadow: `inset 3px 0px 0px 0px ${layer.color}` }}
                        data-testid="row-data-shape"
                      >
                        <TableCell component="th" scope="row">
                          {shapeType}
                        </TableCell>
                        <TableCell>
                          {editShapeNameId === shapeOrSlot.id && isShape ? (
                            <ChangeShapeNameInputComponent shape={shapeOrSlot} setUpdateShapes={setUpdateShapes} />
                          ) : (
                            <ShapeNameCellComponent
                              shapeId={shapeOrSlot.id as string}
                              shapeName={
                                isShape
                                  ? shapeOrSlot.properties.name || ''
                                  : isStockLine
                                    ? shapeOrSlot.name
                                    : shapeOrSlot.value
                              }
                              setEditShapeNameId={setEditShapeNameId}
                              editable={isShape}
                            />
                          )}
                        </TableCell>
                        <TableCell className={layer.isDraft ? classes.lineThrough : undefined}>
                          {!layer.visibility && <VisibilityOffIcon className={classes.hiddenLayerIcon} />}
                          {layer.name}
                        </TableCell>
                        <TableCell align="right">
                          {isShape && (
                            <IconButton
                              title={shapeOrSlot.hidden ? 'Show' : 'Hide'}
                              onClick={() => toggleVisibility(shapeOrSlot.id as string)}
                              size="large"
                            >
                              {shapeOrSlot.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          )}
                          {isShape && (
                            <IconButton
                              title="Bring to front"
                              onClick={() => handleBringToFront(shapeOrSlot.id as string)}
                              size="large"
                            >
                              <FlipToFrontIcon />
                            </IconButton>
                          )}
                          {isShape && (
                            <IconButton
                              title="Send to back"
                              onClick={() => handleBringToBack(shapeOrSlot.id as string)}
                              size="large"
                            >
                              <FlipToBackIcon />
                            </IconButton>
                          )}
                          {isShape && (
                            <IconButton
                              title="Zoom-in to the shape"
                              onClick={() => handleZoomToShape(shapeOrSlot.id as string)}
                              size="large"
                            >
                              <ExploreIcon />
                            </IconButton>
                          )}
                          {!isShape ? (
                            <IconButton
                              title={`Redirect to ${!isStockLine ? rackName : stockZoneName}`}
                              onClick={() => {
                                handleRedirectToShape(
                                  isStockLine,
                                  isStockLine ? shapeOrSlot.stockZoneId : shapeOrSlot.rackId
                                );
                              }}
                            >
                              <ExitToAppIcon />
                            </IconButton>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Alert severity="warning">No shapes or slots found</Alert>
        )}
      </DialogContent>
      {filteredShapesOnly.length ? (
        <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', paddingInline: '24px' }}>
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
            <Typography variant="subtitle2" color="textSecondary">
              {filteredShapesOnly.length > 0 && (
                <span>
                  {filteredShapesOnly.length} shape{filteredShapesOnly.length !== 1 ? 's' : ''}{' '}
                  {!onlySlotsLength ? '' : 'and'}
                  {onlySlotsLength ? '' : 'found'}
                </span>
              )}
              {onlySlotsLength > 0 && (
                <span>
                  &nbsp;{onlySlotsLength} slot
                  {onlySlotsLength !== 1 ? 's' : ''} found
                </span>
              )}
            </Typography>
            {filteredShapesOnly.length ? (
              <Tooltip title="Go back to edit mode & select filtered shapes">
                <Button variant="outlined" onClick={selectAllFilteredShapes} size="small">
                  Select {filteredShapesOnly.length > 1 ? 'shapes' : 'shape'}
                </Button>
              </Tooltip>
            ) : undefined}
          </Box>
          <TablePagination
            rowsPerPageOptions={[30, 50, 100]}
            page={page}
            component="div"
            count={filteredShapesOrSlots.length}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ borderBottom: 'unset' }}
          />
        </Box>
      ) : onlySlotsLength ? (
        <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', paddingInline: '24px' }}>
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', columnGap: '8px', margin: '0.75rem' }}>
            <Typography variant="subtitle2" color="textSecondary">
              {onlySlotsLength} slot{onlySlotsLength !== 1 ? 's' : ''} found
            </Typography>
          </Box>
          <TablePagination
            rowsPerPageOptions={[30, 50, 100]}
            page={page}
            component="div"
            count={onlySlotsLength}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ borderBottom: 'unset' }}
          />
        </Box>
      ) : null}
    </Dialog>
  );
}

interface ShapeNameCellComponentProps {
  shapeId: string;
  shapeName: string;
  editable: boolean;
  setEditShapeNameId: (shapeId: string) => void;
}
function ShapeNameCellComponent(props: ShapeNameCellComponentProps): JSX.Element {
  const { shapeId, shapeName, editable, setEditShapeNameId } = props;

  return (
    <>
      {editable && (
        <Tooltip title="Edit shape name">
          <IconButton size="medium" onClick={() => editable && setEditShapeNameId(shapeId)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {shapeName}
    </>
  );
}
