/* eslint-disable @typescript-eslint/ban-types */
import { lineIntersect } from '@turf/turf';
import { selectToolAction } from 'actions';
import {
  addDeviceAction,
  addNoteAction,
  addSegmentAction,
  addTurnAction,
  clearShapesSelectionAction,
  selectCircuitShapeAction,
  updateTurnAction,
} from 'actions/circuit';
import * as d3 from 'd3';
import { snap90degLine } from 'librarycircuit/utils/geometry/snap-90-deg-line';
import { getDistanceBetweenPoints, getUnitaryVector } from 'librarycircuit/utils/geometry/vectors';
import type { CircuitTurn } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { findShapeOrientation, findShapeOrientationFromCorners, svgCoordsToMathCoords } from './helpers';
import { LayerNames, SVGLayer } from './layers';
import { drawPreviewMeasurer, removePreviousPreviewMeasurers } from './preview-measurer';
import type { SimpleSelection } from './shared';

export class DrawLayer extends SVGLayer {
  protected firstPointDrawn?: [number, number];
  protected lastPointDrawn?: [number, number];
  protected pointsDrawn?: number[][];
  protected onRectangleDrawn: Function;
  protected onSegmentDrawn: Function;
  protected onMeasurerDrawn: Function;
  protected onPointDrawn: Function;
  private scale?: d3.ZoomTransform;
  public activeTool: Tools = Tools.Move;

  constructor(onRectangleDrawn: Function, onSegmentDrawn: Function, onMeasurerDrawn: Function, onPointDrawn: Function) {
    super(LayerNames.Draw);

    this.onRectangleDrawn = onRectangleDrawn;
    this.onSegmentDrawn = onSegmentDrawn;
    this.onMeasurerDrawn = onMeasurerDrawn;
    this.onPointDrawn = onPointDrawn;
    this.dragStarted = this.dragStarted.bind(this);
    this.dragged = this.dragged.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.onCancelShape = this.onCancelShape.bind(this);
  }

  public setActiveTool(activeTool: Tools): void {
    this.activeTool = activeTool;

    if (![Tools.Move, Tools.AddTurn, Tools.SimulationConfiguration].includes(this.activeTool)) {
      this.node?.select('foreignObject').attr('width', '100%').attr('height', '100%');
    } else {
      this.node?.select('foreignObject').attr('width', '0%').attr('height', '0%');
    }
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    const dragHandler = d3
      .drag<SVGGElement, undefined>()
      .on('start', () => this.dragStarted())
      .on('drag', () => this.dragged())
      .on('end', () => this.dragEnd());

    const gElement = super
      .createNode()
      .style('x', '0px')
      .style('y', '0px')
      .style('width', '100%')
      .style('height', '100%')
      .style('overflow', 'visible')
      .on('mousemove', () => this.mouseMove())
      .on('mouseenter', () => this.mouseEnter())
      .call(dragHandler);

    const gNode = gElement.node();
    if (gNode) {
      gNode.addEventListener('cancel-shape', () => this.onCancelShape());
      gNode.addEventListener('draw-shape', () => {
        if (this.pointsDrawn && this.pointsDrawn.length && this.lastPointDrawn) {
          const point = [...this.lastPointDrawn];

          this.pointsDrawn.push(
            store.getState().tool.snap90deg
              ? snap90degLine([this.pointsDrawn[this.pointsDrawn.length - 1], point], 0)[1]
              : point
          );

          this.drawShape();
        }
      });
    }

    gElement
      .append('svg:foreignObject') //to manipulate layer width and height
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%');

    return gElement;
  }

  public updateScaleFactor(newScale: d3.ZoomTransform): void {
    this.scale = newScale;
  }

  protected dragStarted(): void {
    /* Check if the user is trying to do an action which changes the circuit even thought he's on a hidden layer
    Then display a snackbar, change his tool to Move and cancels the action
    */
    if (
      !store.getState().circuit.present.layers.layers[store.getState().circuit.present.layers.selectedLayer].visibility
    ) {
      SnackbarUtils.warning('The layer you are on is hidden, please make it visible to edit it');
      store.dispatch(
        selectToolAction({
          toolName: Tools.Move,
        })
      );

      return;
    }

    const event = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    this.firstPointDrawn = [event.x, event.y];

    if (
      this.activeTool === Tools.DrawPoint ||
      this.activeTool === Tools.AddDevice ||
      this.activeTool === Tools.AddNote
    ) {
      const [x, y] = [d3.event.x as number, d3.event.y as number];

      this.node
        .append('g')
        .append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('r', 10)
        .classed('POINT-DRAWN-STYLE', true);
    } else if (this.activeTool === Tools.DrawShape) {
      if (!this.pointsDrawn || !this.pointsDrawn.length) {
        this.pointsDrawn = [[...this.firstPointDrawn]];
      } else {
        const point = [...this.firstPointDrawn];
        const d = getDistanceBetweenPoints(this.pointsDrawn[this.pointsDrawn.length - 1], point);
        const dThreshold = 0.1; // m

        // if the click is close to the last point clicked, we stop and draw the shape, otherwise we continue to add points
        if (d < dThreshold * 100) {
          this.drawShape();
        } else {
          this.pointsDrawn.push(
            store.getState().tool.snap90deg
              ? snap90degLine([this.pointsDrawn[this.pointsDrawn.length - 1], point], 0)[1]
              : point
          );
        }
      }
    }
  }

  protected dragged(): void {
    if (this.firstPointDrawn) {
      if (
        this.activeTool === Tools.DrawZone ||
        this.activeTool === Tools.DrawStockZone ||
        this.activeTool === Tools.DrawRack ||
        this.activeTool === Tools.DrawConveyor
      ) {
        const event = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
        const [x, y] = [event.x, event.y] as [number, number];

        const drawnRectangle = this.node.selectAll('rect');
        const element =
          drawnRectangle.size() === 0 ? this.node.append('g').append('rect').selectAll('rect') : drawnRectangle;
        element
          .attr('x', Math.min(this.firstPointDrawn[0], x))
          .attr('y', Math.min(this.firstPointDrawn[1], y))
          .attr('width', Math.abs(this.firstPointDrawn[0] - x))
          .attr('height', Math.abs(this.firstPointDrawn[1] - y))
          .classed('ZONE-DRAWN-STYLE', true);
      } else if (
        this.activeTool === Tools.DrawSegment ||
        this.activeTool === Tools.DrawMeasurer ||
        this.activeTool === Tools.DrawSegmentOrTurn
      ) {
        let [x, y] = [d3.event.x, d3.event.y] as [number, number];

        const stateStore = store.getState();
        const snap90deg = stateStore.tool.snap90deg || false;

        if (snap90deg && this.firstPointDrawn[0] && this.firstPointDrawn[1]) {
          const coords = [this.firstPointDrawn, [x, y]];

          const snappedLine = snap90degLine(coords, 0);
          x = snappedLine[1][0];
          y = snappedLine[1][1];
        }

        if (this.activeTool !== Tools.DrawMeasurer) {
          // for segments, we just draw a simple line
          // we draw them in the draw layer, so we do not consider the zoom level in this layer
          const drawnSegments = this.node.selectAll('line');
          const element =
            drawnSegments.size() === 0 ? this.node.append('g').append('line').selectAll('line') : drawnSegments;
          element
            .attr('x1', this.firstPointDrawn[0] ?? x)
            .attr('y1', this.firstPointDrawn[1] ?? y)
            .attr('x2', x)
            .attr('y2', y)
            .classed('SEGMENT-DRAWN-STYLE', true);
        } else {
          // measurers have a special preview, we want to display an actual measurer to the user instead of a simple line
          // we want the preview to have the same visual as the new measurer so we insert it into the circuit layer
          // this layer considers the zoom level, so we have to take it in the point coordinates
          const scaledFirstPointDraw = this.scale?.invert(this.firstPointDrawn) ?? this.firstPointDrawn;
          const scaledXY = this.scale?.invert([x, y]) ?? [x, y];
          const selectedLayer = store.getState().circuit.present.layers.selectedLayer;
          drawPreviewMeasurer({
            points: [scaledFirstPointDraw, scaledXY],
            layerId: selectedLayer,
          });
        }
      }
    }
  }

  protected dragEnd(): void {
    const mouseEventCoords: [number, number] = [d3.event.x, d3.event.y] as [number, number];
    const lastPointDrawn: [number, number] = this.scale?.invert(mouseEventCoords) || mouseEventCoords;

    if (this.firstPointDrawn && this.scale) {
      const invertedFirstPoint = this.scale.invert(this.firstPointDrawn);

      const diagonalLength = getDistanceBetweenPoints([invertedFirstPoint[0], invertedFirstPoint[1]], lastPointDrawn);

      if (
        diagonalLength < 0.0001 &&
        this.activeTool !== Tools.DrawPoint &&
        this.activeTool !== Tools.AddDevice &&
        this.activeTool !== Tools.AddNote
      ) {
        // we do not create shapes when it is just a click
        return;
      }

      if (Math.pow(diagonalLength, 2) < 2) {
        const unitaryVector = getUnitaryVector({ start: invertedFirstPoint, end: lastPointDrawn });
        lastPointDrawn[0] = invertedFirstPoint[0] + unitaryVector[0];
        lastPointDrawn[1] = invertedFirstPoint[1] + unitaryVector[1];
      }

      if (this.activeTool === Tools.DrawZone) {
        this.onRectangleDrawn(ShapeTypes.ZoneShape, [
          svgCoordsToMathCoords(invertedFirstPoint),
          svgCoordsToMathCoords(lastPointDrawn),
        ]);
      } else if (this.activeTool === Tools.DrawRack || this.activeTool === Tools.DrawConveyor) {
        const p1 = svgCoordsToMathCoords(this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn);
        const p2 = svgCoordsToMathCoords(lastPointDrawn);
        const angle = findShapeOrientationFromCorners(p1, p2);

        this.onRectangleDrawn(ShapeTypes.RackShape, [p1, p2], angle, {
          isConveyor: this.activeTool === Tools.DrawConveyor,
        });
      } else if (this.activeTool === Tools.DrawStockZone) {
        const p1 = svgCoordsToMathCoords(this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn);
        const p2 = svgCoordsToMathCoords(lastPointDrawn);
        const angle = findShapeOrientationFromCorners(p1, p2);

        this.onRectangleDrawn(ShapeTypes.StockZoneShape, [p1, p2], angle);
      }

      if (this.activeTool === Tools.DrawSegment || this.activeTool === Tools.DrawSegmentOrTurn) {
        let p1 = svgCoordsToMathCoords(this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn);
        let p2 = svgCoordsToMathCoords(lastPointDrawn);

        const stateStore = store.getState();
        const snap90deg = stateStore.tool.snap90deg || false;

        if (snap90deg) {
          [p1 as number[], p2 as number[]] = snap90degLine([p1, p2], 0);
        }

        this.onSegmentDrawn([p1, p2]);
      }

      if (this.activeTool === Tools.DrawMeasurer) {
        let p1 = svgCoordsToMathCoords(this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn);
        let p2 = svgCoordsToMathCoords(lastPointDrawn);

        const stateStore = store.getState();
        const snap90deg = stateStore.tool.snap90deg || false;

        if (snap90deg) {
          [p1 as number[], p2 as number[]] = snap90degLine([p1, p2], 0);
        }

        this.onMeasurerDrawn([p1, p2]);

        // we remove the preview measurer once the measurer has been drawn
        removePreviousPreviewMeasurers();
      }

      if (this.activeTool === Tools.DrawPoint) {
        const angle = findShapeOrientation(
          [this.firstPointDrawn[0], -this.firstPointDrawn[1]],
          [mouseEventCoords[0], -mouseEventCoords[1]]
        ); //y abscissa must be invert
        const pointCoord: [number, number] = this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn;

        this.onPointDrawn(svgCoordsToMathCoords(pointCoord), Math.round(angle * 1000) / 1000);
      }

      if (this.activeTool === Tools.AddDevice) {
        const pointCoord: [number, number] = this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn;

        // we create the new device
        store.dispatch(
          addDeviceAction({
            coord: svgCoordsToMathCoords(pointCoord),
            deviceType: 'IOECombox',
            userAction: true,
          })
        );

        // and we automatically select it once created
        setTimeout(() => {
          const devicesState = store.getState().circuit.present.devices;
          const newDeviceId = devicesState.ids.at(-1);
          if (newDeviceId) {
            if (store.getState().local.selectedShapesData.length) store.dispatch(clearShapesSelectionAction());

            const shape = devicesState.entities[newDeviceId];
            store.dispatch(
              selectCircuitShapeAction({
                selectedShapeId: newDeviceId,
                selectedShapeType: ShapeTypes.DeviceShape,
                shape,
              })
            );
          }
        }, 0);
      }

      if (this.activeTool === Tools.AddNote) {
        const pointCoord: [number, number] = this.scale?.invert(this.firstPointDrawn) || this.firstPointDrawn;

        // we add a new note
        store.dispatch(
          addNoteAction({
            coord: svgCoordsToMathCoords(pointCoord),
            type: ShapeTypes.NoteShape,
            userAction: true,
          })
        );
        setTimeout(() => {
          const notesState = store.getState().circuit.present.notes;
          const newNoteId = notesState.ids.at(-1);
          if (newNoteId) {
            if (store.getState().local.selectedShapesData.length) store.dispatch(clearShapesSelectionAction());

            const shape = notesState.entities[newNoteId];
            store.dispatch(
              selectCircuitShapeAction({
                selectedShapeId: newNoteId,
                selectedShapeType: ShapeTypes.NoteShape,
                shape,
              })
            );
          }
        }, 0);
      }
    }

    // clear first point
    if (!this.pointsDrawn) {
      delete this.firstPointDrawn;

      // wait a bit before deleting thus we do not delete the preview shape
      // before the actual shape, otherwise the screen flickers a bit
      setTimeout(() => {
        this.node.selectAll('g').remove();
      }, 120);
    }
  }

  protected mouseMove(): void {
    if (this.activeTool === Tools.DrawShape && this.firstPointDrawn && this.pointsDrawn) {
      const rootNode = this.node.node();
      const unscaledMousePosition = d3.mouse(rootNode as SVGSVGElement); // relative to specified container
      let [x, y] = unscaledMousePosition; // transformer.invert(unscaledMousePosition); // relative to zoom

      const stateStore = store.getState();
      const snap90deg = stateStore.tool.snap90deg || false;

      const lastPointDrawn = this.pointsDrawn[this.pointsDrawn.length - 1];

      if (snap90deg && lastPointDrawn[0] && lastPointDrawn[1]) {
        const coords = [lastPointDrawn, [x, y]];

        const snappedLine = snap90degLine(coords, 0);
        x = snappedLine[1][0];
        y = snappedLine[1][1];

        this.firstPointDrawn = [x, y];
      }

      this.lastPointDrawn = [x, y];

      this.node.selectAll('line').remove();
      const points = [...this.pointsDrawn, [x, y]];
      for (let i = 1; i < points.length; i++) {
        const element = this.node.append('line');
        element
          .attr('x1', points[i - 1][0])
          .attr('y1', points[i - 1][1])
          .attr('x2', points[i][0])
          .attr('y2', points[i][1])
          .classed('SEGMENT-DRAWN-STYLE', true);
      }
    }
  }

  protected mouseEnter(): void {
    if (this.activeTool !== Tools.DrawShape) {
      delete this.pointsDrawn;
      this.node.selectAll('line').remove();
    }
  }

  protected drawShape(): void {
    if (!this.pointsDrawn || !this.pointsDrawn.length) return;

    const points = this.pointsDrawn;
    let nbTurnsDrawn = 0;
    let firstSegmentId: string | undefined;
    let lastSegmentId: string | undefined;
    for (let i = 1; i < points.length; i++) {
      // we add a segment
      const coord = [
        svgCoordsToMathCoords(
          this.scale?.invert([points[i - 1][0], points[i - 1][1]]) || [points[i - 1][0], points[i - 1][1]]
        ),
        svgCoordsToMathCoords(this.scale?.invert([points[i][0], points[i][1]]) || [points[i][0], points[i][1]]),
      ];

      store.dispatch(
        addSegmentAction({
          coord,
          userAction: i === 1, // the first segment drawn is a user action, not the following ones
          drawShape: i === 1, // the first segment drawn is a drawShape action
        })
      );

      // we eventually draw a turn
      if (i > 1) {
        const storeState = store.getState();
        const segmentIds = storeState.circuit.present.segments.ids;
        const segId = segmentIds[segmentIds.length - 1];
        const segBeforeId = segmentIds[segmentIds.length - 2];

        const seg = storeState.circuit.present.segments.entities[segId];
        const segBefore = storeState.circuit.present.segments.entities[segBeforeId];

        const segCoords = seg.geometry.coordinates;
        const segBeforeCoords = segBefore.geometry.coordinates;

        if (i === 2) {
          firstSegmentId = segBeforeId;
        } else if (i === points.length - 1) {
          lastSegmentId = segId;
        }

        store.dispatch(
          addTurnAction({
            destination: {
              id: segId,
              position: 0.5,
              orientation: findShapeOrientation(segCoords[0], segCoords[1]),
              coordinates: { x: (segCoords[0][0] + segCoords[1][0]) / 2, y: (segCoords[0][1] + segCoords[1][1]) / 2 },
              segment: segCoords,
            },
            origin: {
              id: segBeforeId,
              position: 0.5,
              orientation: findShapeOrientation(segBeforeCoords[0], segBeforeCoords[1]),
              coordinates: {
                x: (segBeforeCoords[0][0] + segBeforeCoords[1][0]) / 2,
                y: (segBeforeCoords[0][1] + segBeforeCoords[1][1]) / 2,
              },
              segment: segBeforeCoords,
            },
          })
        );
        nbTurnsDrawn++;
      }
    }

    // we eventually draw a final turn between the first segment and the last one if both cross each other
    if (points.length > 3 && lastSegmentId && firstSegmentId && firstSegmentId !== lastSegmentId) {
      // to cross, we need at least 3 segments
      const storeState = store.getState();
      const segments = storeState.circuit.present.segments.entities;

      const firstSegment = segments[firstSegmentId];
      const lastSegment = segments[lastSegmentId];
      const firstSegmentCoords = firstSegment.geometry.coordinates;
      const lastSegmentCoords = lastSegment.geometry.coordinates;

      const intersect = lineIntersect(firstSegment, lastSegment);
      if (intersect.features.length) {
        store.dispatch(
          addTurnAction({
            origin: {
              id: lastSegmentId,
              position: 0.5,
              orientation: findShapeOrientation(lastSegmentCoords[0], lastSegmentCoords[1]),
              coordinates: {
                x: (lastSegmentCoords[0][0] + lastSegmentCoords[1][0]) / 2,
                y: (lastSegmentCoords[0][1] + lastSegmentCoords[1][1]) / 2,
              },
              segment: lastSegmentCoords,
            },
            destination: {
              id: firstSegmentId,
              position: 0.5,
              orientation: findShapeOrientation(firstSegmentCoords[0], firstSegmentCoords[1]),
              coordinates: {
                x: (firstSegmentCoords[0][0] + firstSegmentCoords[1][0]) / 2,
                y: (firstSegmentCoords[0][1] + firstSegmentCoords[1][1]) / 2,
              },
              segment: firstSegmentCoords,
            },
          })
        );
        nbTurnsDrawn++;
      }
    }

    let nbUpdatedTurn = 0;
    do {
      nbUpdatedTurn = 0;

      const turnsToUpdate = new Set<CircuitTurn>();
      const turnIds = store.getState().circuit.present.turns.ids;
      for (let i = turnIds.length - 1; i > turnIds.length - nbTurnsDrawn; i--) {
        if (i === 0) continue;

        const turn1 = store.getState().circuit.present.turns.entities[turnIds[i]];
        const turn2 = store.getState().circuit.present.turns.entities[turnIds[i - 1]];

        const intersect = lineIntersect(turn1, turn2);

        if (intersect.features.length) {
          turnsToUpdate.add(turn1);
          turnsToUpdate.add(turn2);
        }
      }

      // eslint-disable-next-line no-loop-func
      turnsToUpdate.forEach((turnToUpdate) => {
        const doTheUpdate =
          turnToUpdate && turnToUpdate.properties && turnToUpdate.properties.radius
            ? turnToUpdate.properties.radius >= 0.25
            : false;

        if (doTheUpdate) {
          store.dispatch(
            updateTurnAction({
              idToUpdate: turnToUpdate.id as string,
              radius: (turnToUpdate.properties.radius || 1) / 2,
              turnType:
                turnToUpdate &&
                turnToUpdate.properties &&
                turnToUpdate.properties.radius &&
                turnToUpdate.properties.radius < 1
                  ? 'StopBeforeTurn'
                  : undefined,
            })
          );

          nbUpdatedTurn++;
        }
      });
    } while (nbUpdatedTurn);

    // once finished, we delete the visualizing shape
    delete this.pointsDrawn;
    delete this.firstPointDrawn;
    delete this.lastPointDrawn;

    // wait a bit before deleting thus we do not delete the preview shape
    // before the actual shape, otherwise the screen flickers a bit
    setTimeout(() => {
      this.node.selectAll('line').remove();
    }, 500);
  }

  protected onCancelShape(): void {
    delete this.pointsDrawn;
    delete this.firstPointDrawn;
    delete this.lastPointDrawn;

    this.node.selectAll('line').remove();
  }

  public convertScreenCoordinateToSvgCoordinate(point: [number, number]): [number, number] {
    return this.scale?.invert(point) || [0, 0];
  }
}
