import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { Transition } from 'react-transition-group';

const DURATION = 300;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
      transition: `opacity ${DURATION}ms ease-in-out, transform ${DURATION}ms ease-in-out`,
    },
  })
);

const transitionStyles = {
  fade: {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  },
  fromTop: {
    entering: { opacity: 0, transform: 'translateY(-100%)' },
    entered: { opacity: 1, transform: 'translateY(0)' },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  },
};

export function StandardFade({ children, top }: PropsWithChildren<{ top?: boolean }>): JSX.Element {
  const classes = useStyles();

  const transitionStyle = top ? 'fromTop' : 'fade';

  return (
    <Transition timeout={DURATION} in appear>
      {(state) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <div className={classes.root} style={{ ...transitionStyles[transitionStyle][state] }}>
          {children}
        </div>
      )}
    </Transition>
  );
}
