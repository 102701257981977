import { Alert } from '@mui/material';
import { useMemo } from 'react';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';

/**
 * React hook to that checks if all truck names are set in the preferences.
 */
export function DisplayErrorTrucksNameWhenNeeded(): JSX.Element {
  const errorTruckNamesMissing = useMemo(() => {
    let allTruckNames: string | string[] | undefined = undefined;
    let allTruckSerials: string | string[] | undefined = undefined;
    try {
      allTruckNames = PreferencesService.getPreferenceValue('general/trucksName');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }

    try {
      allTruckSerials = PreferencesService.getPreferenceValue('general/trucksSerial');

      if (!Array.isArray(allTruckSerials)) throw new Error('Truck serials are not an array');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }

    const allTruckNamesArr = !!(allTruckNames && Array.isArray(allTruckNames)) ? allTruckNames : undefined;
    const allTruckNamesArrLength = allTruckNamesArr ? allTruckNamesArr.length : 0;
    const allTruckSerialsArr = !!(allTruckSerials && Array.isArray(allTruckSerials)) ? allTruckSerials : undefined;
    const allTruckSerialsArrLength = allTruckSerialsArr ? allTruckSerialsArr.length : 0;

    if (!allTruckNamesArr || allTruckNamesArrLength !== allTruckSerialsArrLength) {
      // eslint-disable-next-line no-console
      console.warn('At least one truck name is missing. Please check your preferences.');
      // eslint-disable-next-line no-console
      console.warn('Truck names:', allTruckNamesArr);
      // eslint-disable-next-line no-console
      console.warn('Truck serials:', allTruckSerialsArr);

      return true;
    }

    return false;
  }, []);

  return errorTruckNamesMissing ? (
    <Alert
      severity="error"
      sx={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
      }}
    >
      At least one truck name is missing. Please check your preferences.
    </Alert>
  ) : (
    <></>
  );
}
