import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { AccordionProps, SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { closeDialogAction } from 'actions';
import { addLayerAction, updateLayerAction } from 'actions/circuit';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useCallback, useMemo, useState } from 'react';
import { sortLayersByOrder } from 'reducers/circuit/layers.reducer';
import type { LayersDataObject } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { computeAislesAndAddToCircuit, computeMidSegmentAisleAndAddToCircuit } from 'utils/circuit/compute-aisles';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { balyoGradient } from 'utils/colors/colors';
import { theme } from 'utils/mui-theme';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  ({ theme }) => ({
    margintop: 0,
    marginBottom: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
  },
  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

interface AislesOptionsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type Options = {
  detectAisles: boolean;
  detectAislesMiddlesSegments: {
    selected: boolean;
    offset: number;
  };
};

export function AislesOptionsDialog({ open, setOpen }: AislesOptionsProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const defaultSelectedLayers = useMemo(() => {
    return Object.values(layers).map((layer) => layer.id);
  }, [layers]);

  const [selectedLayers, setSelectedLayers] = useState<string[]>(defaultSelectedLayers);

  const [options, setOptions] = useState<Options>({
    detectAisles: false,
    detectAislesMiddlesSegments: {
      selected: false,
      offset: 0,
    },
  });

  const handleDetectAisles = useCallback(() => {
    const nbAisles = computeAislesAndAddToCircuit({ selectedLayers: selectedLayers });

    if (nbAisles > 0) {
      SnackbarUtils.success(`${nbAisles} aisles zones have been added or updated`);
    } else {
      SnackbarUtils.info('No aisles zones have been added or updated');
    }
  }, [selectedLayers]);

  const handleDetectAislesSegments = useCallback(() => {
    const selectedLayerId = store.getState().circuit.present.layers.selectedLayer;

    // if the actual selected layer id is not in the selectedLayers arr, middle aisle segments will not be computed because the zones to compute them are in the actual selected layer
    const allSelectedLayers = selectedLayers.includes(selectedLayerId)
      ? selectedLayers
      : [...selectedLayers, selectedLayerId];

    const nbSegments = computeMidSegmentAisleAndAddToCircuit({
      selectedLayers: allSelectedLayers,
      offset: options.detectAislesMiddlesSegments.offset * 100,
    });

    if (nbSegments > 0) {
      SnackbarUtils.success(`${nbSegments} middle segments have been added or updated`);
    } else {
      SnackbarUtils.info(
        'No middle segments have been added or updated because no aisles have been detected in the circuit. Consider creating the aisles zones before.'
      );
    }
  }, [options.detectAislesMiddlesSegments.offset, selectedLayers]);

  const handleValidate = (): void => {
    if (options.detectAisles) {
      handleDetectAisles();
    }

    if (options.detectAislesMiddlesSegments.selected) {
      handleDetectAislesSegments();
    }

    dispatch(closeDialogAction());
    setOpen(false);
  };

  const handleCancel = (): void => {
    setSelectedLayers(defaultSelectedLayers);
    setOptions({
      detectAisles: false,
      detectAislesMiddlesSegments: {
        selected: false,
        offset: 0,
      },
    });
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'xs'} onClose={handleCancel}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Aisles Actions
      </DialogTitle>
      <DialogContent>
        <SelectLayers layers={layers} selectedLayers={selectedLayers} setSelectedLayers={setSelectedLayers} />

        <ActionsComponent options={options} setOptions={setOptions} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleValidate}>
          Validate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface SelectLayersProps {
  layers: LayersDataObject;
  selectedLayers: string[];
  setSelectedLayers: React.Dispatch<React.SetStateAction<string[]>>;
}

function SelectLayers({ layers, selectedLayers, setSelectedLayers }: SelectLayersProps): JSX.Element {
  const dispatch = useAppDispatch();

  const layersNames = useMemo(() => {
    return Object.values(layers).map((layer) => layer.name);
  }, [layers]);

  const currentSelectedLayer = useAppSelector((state) => state.circuit.present.layers.selectedLayer);

  const currentSelectedLayerName = useMemo(() => {
    return layers[currentSelectedLayer].name;
  }, [currentSelectedLayer, layers]);

  const isAllLayerSelected = selectedLayers.length === Object.values(layers).length;

  const handleSelectLayer = (id: string | 'all', newValue: boolean): void => {
    let newSelectedLayers = [...selectedLayers];

    if (id === 'all') {
      if (newValue) {
        const layerIds = Object.values(layers).map((layer) => layer.id);
        newSelectedLayers = layerIds;
      } else {
        newSelectedLayers = [];
      }
    } else {
      if (newValue) {
        newSelectedLayers.push(id);
      } else {
        newSelectedLayers = newSelectedLayers.filter((layerId) => layerId !== id);
      }
    }

    setSelectedLayers(newSelectedLayers);
  };

  const handleCreateAislesLayer = (): void => {
    const layerId = generateShapeId();
    dispatch(
      addLayerAction({
        name: 'Aisles',
        visibility: true,
        isDraft: false,
        color: balyoGradient[Math.floor(Math.random() * balyoGradient.length)],
        userAction: true,
        id: layerId,
      })
    );

    setSelectedLayers([...selectedLayers, layerId]);
  };

  const handleChangeSelectedLayer = (event: SelectChangeEvent): void => {
    const layerName = event.target.value;

    const selectedLayerData = Object.values(layers).find((layer) => layer.name === layerName);

    if (!selectedLayerData) return;

    dispatch(
      updateLayerAction({
        layerId: selectedLayerData.id,
        selectedLayer: selectedLayerData.id,
      })
    );
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto',
            }}
          />
        }
      >{`Select layers`}</AccordionSummary>
      <AccordionDetails>
        From: <HelpIconTooltip title="Select layers in where the racks are" />
        <Border
          sx={{
            paddingLeft: 0,
            marginLeft: theme.spacing(2),
            marginTop: 1,
          }}
        >
          <List dense sx={{ maxHeight: 200, overflowY: 'auto' }}>
            <ListItem
              secondaryAction={
                <Tooltip title={'Select/unselect all the layers'} disableInteractive>
                  <Checkbox
                    edge="end"
                    checked={isAllLayerSelected}
                    indeterminate={!isAllLayerSelected && selectedLayers.length > 0}
                    onChange={(e, newValue) => {
                      handleSelectLayer('all', newValue);
                    }}
                  />
                </Tooltip>
              }
              sx={{ padding: 0, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
            ></ListItem>

            {Object.values(layers)
              .sort(sortLayersByOrder)
              .map((layer, index) => (
                <ListItem>
                  <ListItemText>{layer.name}</ListItemText>
                  <Checkbox
                    edge="end"
                    checked={selectedLayers.includes(layer.id)}
                    onChange={(e, newValue) => {
                      handleSelectLayer(layer.id, newValue);
                    }}
                  />
                </ListItem>
              ))}
          </List>
        </Border>
        <Box component={'div'} marginTop={1}>
          <Box component={'div'}>
            To: <HelpIconTooltip title=" Select the layer where to compute the zones and middle aisles segments" />
          </Box>

          <Border
            sx={{
              paddingLeft: 0,
              marginLeft: theme.spacing(2),
            }}
          >
            <Box
              component={'div'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              marginLeft={2}
              marginTop={1}
            >
              Layer
              <Select
                variant="standard"
                sx={{ minWidth: 100 }}
                onChange={handleChangeSelectedLayer}
                value={currentSelectedLayerName}
              >
                {layersNames.map((layerName) => (
                  <MenuItem value={layerName}>{layerName}</MenuItem>
                ))}
              </Select>
            </Box>
          </Border>
          {((layersNames.includes('Aisles') && currentSelectedLayerName !== 'Aisles') ||
            !layersNames.includes('Aisles')) && (
            <Alert severity="info" sx={{ marginTop: 1, '& .MuiAlert-message': { fontSize: 14 } }}>
              {!layersNames.includes('Aisles') && (
                <>
                  It's recommended to add them in an "Aisles" layer.
                  <Box component={'div'} display={'flex'} justifyContent={'end'}>
                    <Button onClick={handleCreateAislesLayer}>Create and select an Aisles layer</Button>
                  </Box>
                </>
              )}
              {layersNames.includes('Aisles') && currentSelectedLayerName !== 'Aisles' && (
                <>It is recommended to add them in your "Aisles" layer.</>
              )}
            </Alert>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

interface ActionsComponentProps {
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
}

function ActionsComponent({ options, setOptions }: ActionsComponentProps): JSX.Element {
  const handleChangeOptions = (option: 'zone' | 'segments', newValue?: boolean, offset?: string): void => {
    const newOptions = { ...options };

    let newOffset = offset ? Number(offset) : 0;

    if (offset === '' || isNaN(newOffset)) newOffset = 0;
    if (newValue !== undefined) {
      if (option === 'zone') {
        newOptions.detectAisles = newValue;
      }

      if (option === 'segments') {
        newOptions.detectAislesMiddlesSegments.selected = newValue;
      }
    }

    newOptions.detectAislesMiddlesSegments.offset = Math.round(newOffset * 100) / 100;

    setOptions(newOptions);
  };

  return (
    <List dense>
      <ListItemText
        primary="Actions"
        primaryTypographyProps={{
          fontSize: 18,
          fontWeight: 'medium',
        }}
      />
      <ListItem>
        <ListItemText>Detect Aisles</ListItemText>
        <Checkbox
          edge="end"
          checked={!!options.detectAisles}
          onChange={(e, newValue) => {
            handleChangeOptions('zone', newValue);
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText>Detect Middle Segments</ListItemText>
        <Checkbox
          edge="end"
          checked={!!options.detectAislesMiddlesSegments.selected}
          onChange={(e, newValue) => {
            handleChangeOptions('segments', newValue);
          }}
        />
      </ListItem>
      <Collapse in={options.detectAislesMiddlesSegments.selected} timeout="auto" unmountOnExit>
        <Border
          sx={{
            paddingLeft: 0,
            marginLeft: theme.spacing(4),
          }}
        >
          <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ListItemText>Choose from center</ListItemText>
            <TextField
              type="number"
              value={options.detectAislesMiddlesSegments.offset.toString()}
              onChange={(e) => {
                handleChangeOptions('segments', undefined, e.target.value);
              }}
              sx={{ width: 100 }}
              InputProps={{
                endAdornment: <EndAdornmentOffsetTextfield handleChangeOptions={handleChangeOptions} />,
                inputProps: { step: 0.01, min: 0 },
              }}
              variant="standard"
            />
          </ListItem>
        </Border>
      </Collapse>
    </List>
  );
}

interface EndAdornmentOffsetTextfieldProps {
  handleChangeOptions: (option: 'zone' | 'segments', newValue?: boolean, offset?: string) => void;
}

function EndAdornmentOffsetTextfield({ handleChangeOptions }: EndAdornmentOffsetTextfieldProps): JSX.Element {
  //const suggestedOffsetValue = 'best value beetween H1 et H2';

  return (
    <InputAdornment position="end" sx={{ marginLeft: 1 }}>
      {/* <>
        <Tooltip
          title={`Suggested offset 
           ${suggestedOffsetValue}
              `}
        >
          <Box component="span">
            <IconButton
              onClick={() => {
                handleChangeOptions('segments', undefined, suggestedOffsetValue);
              }}
            >
              <LightbulbIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
        m
      </> */}
      m
    </InputAdornment>
  );
}
