import type { ProxyMethods, RemoteObject } from 'comlink';
import { PreferencesService } from 'utils/preferences';
import { trafficSWALFileName } from './load-circuit';
import type { Simulation } from './simulation.model';

interface RetrieveTrafficSwalContentProps {
  /** the simulation service */
  simulationService: RemoteObject<Simulation> & ProxyMethods;
}
/**
 * Retrieves the traffic SWAL file content from the simulation service
 * @param {RetrieveTrafficSwalContentProps} props - the properties for retrieving the traffic SWAL file content
 * @returns {Promise<Uint8Array | null>} - the traffic SWAL file content if successful, otherwise null
 */
export async function retrieveTrafficSwalContent(props: RetrieveTrafficSwalContentProps): Promise<Uint8Array | null> {
  const { simulationService } = props;

  // eslint-disable-next-line no-console
  console.log(`Getting the generated ${trafficSWALFileName} file...`);
  const tafficSWALFileContent = await (async () => {
    try {
      const trafficSWALFileNamePtr = await simulationService.allocateUTF8(trafficSWALFileName);
      const fileSizePtr = await simulationService._malloc(Int32Array.BYTES_PER_ELEMENT);
      const trafficSWALFilePtr = await simulationService._OS_WasmWrapper_readFile(trafficSWALFileNamePtr, fileSizePtr);
      const fileSize = await simulationService.getValue(fileSizePtr, 'i32');

      const tafficSWALFileContentTmp =
        trafficSWALFilePtr && fileSize
          ? await simulationService.getWasmMemorySlice(trafficSWALFilePtr, fileSize)
          : null;

      await simulationService._OS_WasmWrapper_free(trafficSWALFilePtr);
      await simulationService._OS_WasmWrapper_free(trafficSWALFileNamePtr);

      return tafficSWALFileContentTmp;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error while getting the newly generated ${trafficSWALFileName} file`, e);

      return null;
    }
  })();

  return tafficSWALFileContent;
}

interface SaveTrafficSwalFileProps {
  tafficSWALFileContent: Uint8Array;
}
/**
 * Saves the traffic SWAL file content to disk
 * @param {SaveTrafficSwalFileProps} props - the properties for saving the traffic SWAL file content
 * @returns {Promise<boolean>} - true if successful, otherwise false
 */
export async function saveTrafficSwalFile(props: SaveTrafficSwalFileProps): Promise<boolean> {
  const { tafficSWALFileContent } = props;

  let success = false;

  // eslint-disable-next-line no-console
  console.log(`Writing new ${trafficSWALFileName} file content on disk...`);
  const projectDirectory = await PreferencesService.getDirectoryHandle();
  if (!projectDirectory) {
    // eslint-disable-next-line no-console
    console.warn('Project directory not found');
  } else {
    try {
      const trafficSWALFileHandle = await projectDirectory.getFileHandle(trafficSWALFileName, { create: true });
      const trafficSWALFileWritable = await trafficSWALFileHandle.createWritable({});

      const tempBuffer = new ArrayBuffer(tafficSWALFileContent.byteLength);
      const tempView = new Uint8Array(tempBuffer);
      let sharedView = new Uint8Array(tafficSWALFileContent.buffer);
      if (tafficSWALFileContent.buffer.byteLength !== tempBuffer.byteLength) {
        sharedView = sharedView.subarray(0, tempBuffer.byteLength);
      }

      tempView.set(sharedView);

      await trafficSWALFileWritable.write(tempView);
      await trafficSWALFileWritable.close();

      success = true;

      // eslint-disable-next-line no-console
      console.log(`${trafficSWALFileName} file content written on disk`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error while writing the new ${trafficSWALFileName} file content on disk`, e);
    }
  }

  return success;
}

interface RetrieveAndSaveTrafficSwalFileProps {
  /** the simulation service */
  simulationService: RemoteObject<Simulation> & ProxyMethods;
}
/**
 * Retrieves and saves the traffic SWAL file content
 * @param {RetrieveAndSaveTrafficSwalFileProps} props - the properties for retrieving and saving the traffic SWAL file content
 * @returns {Promise<boolean>} - true if successful, otherwise false
 */
export async function retrieveAndSaveTrafficSwalFile(props: RetrieveAndSaveTrafficSwalFileProps): Promise<boolean> {
  const { simulationService } = props;

  const tafficSWALFileContent = await retrieveTrafficSwalContent({ simulationService });
  if (!tafficSWALFileContent) return false;
  const success = await saveTrafficSwalFile({ tafficSWALFileContent });

  return success;
}
