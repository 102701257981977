import type {
  Init,
  LoadUserProfile,
  LoadUserProfileFailure,
  LoadUserProfileSuccess,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout,
  RefreshToken,
  RefreshTokenFailure,
  RefreshTokenSuccess,
} from 'actions';
import {
  AuthActionTypes,
  CoreActionTypes,
  loadUserProfileAction,
  loadUserProfileFailureAction,
  loadUserProfileSuccessAction,
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  logoutAction,
  refreshTokenAction,
  refreshTokenFailureAction,
  refreshTokenSuccessAction,
} from 'actions';
import type { CallHistoryMethodAction, LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { selectTokenStatus, selectUserLoggedIn } from 'reducers/auth';
import { selectAppOnline } from 'reducers/core';
import type { AppState } from 'reducers/state';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { of, timer } from 'rxjs';
import { catchError, delayWhen, filter, ignoreElements, map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import { Container } from 'typescript-ioc';

export function forceLogin$(actions$: ActionsObservable<any>, state$: StateObservable<AppState>): Observable<Login> {
  return actions$.pipe(
    ofType<LocationChangeAction>(LOCATION_CHANGE),
    take(1),
    map(({ payload }) => payload.location.pathname),
    filter(
      (pathname) => selectAppOnline(state$.value) && pathname !== '/callback' && !selectUserLoggedIn(state$.value)
    ),
    map((returnUrl) => loginAction({ returnUrl }))
  );
}

export function callback$(
  authService: AuthService,
  actions$: ActionsObservable<any>
): Observable<LoginSuccess | LoginFailure> {
  return actions$.pipe(
    ofType<LocationChangeAction>(LOCATION_CHANGE),
    take(1),
    map(({ payload }) => payload.location.pathname),
    filter((pathname) => pathname === '/callback'),
    mergeMap(() => {
      return authService.handleLoginCallback().pipe(
        map(({ expiresAt }) => loginSuccessAction({ expiresAt })),
        catchError((error) => of(loginFailureAction(error)))
      );
    })
  );
}

export function login$(authService: AuthService, actions$: ActionsObservable<any>): Observable<void> {
  return actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    map(({ payload }) => payload.returnUrl || window.location.pathname),
    tap((returnUrl) => {
      localStorage.setItem('returnUrl', returnUrl);
      authService.login();
    }),
    ignoreElements()
  );
}

export function logout$(authService: AuthService, actions$: ActionsObservable<any>): Observable<void> {
  return actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      localStorage.removeItem('returnUrl');
      authService.logout();
      // authService.login();
    }),
    ignoreElements()
  );
}

export function loginSuccess$(actions$: ActionsObservable<any>): Observable<LoadUserProfile> {
  return actions$.pipe(ofType<LoginSuccess>(AuthActionTypes.LoginSuccess), mapTo(loadUserProfileAction()));
}

export function loadUserProfile$(
  authService: AuthService,
  actions$: ActionsObservable<any>
): Observable<LoadUserProfileSuccess | LoadUserProfileFailure> {
  return actions$.pipe(
    ofType<LoadUserProfile>(AuthActionTypes.LoadUserProfile),
    mergeMap(() => {
      return authService.fetchUserProfile().pipe(
        map((profile) => loadUserProfileSuccessAction({ profile })),
        catchError((error) => of(loadUserProfileFailureAction(error)))
      );
    })
  );
}

export function loadUserProfileSuccess$(
  actions$: ActionsObservable<any>
): Observable<CallHistoryMethodAction | undefined> {
  return actions$.pipe(
    ofType<LoadUserProfileSuccess>(AuthActionTypes.LoadUserProfileSuccess),
    map(() => {
      const returnUrlDefaultValue = '/home';
      const returnUrl = localStorage.getItem('returnUrl') || returnUrlDefaultValue;
      localStorage.removeItem('returnUrl');

      if (returnUrl !== returnUrlDefaultValue && returnUrl !== '/editor') {
        window.location.href = returnUrl;

        return;
      }

      return push(returnUrl);
    })
  );
}

export function refreshToken$(
  authService: AuthService,
  actions$: ActionsObservable<any>
): Observable<RefreshTokenSuccess | RefreshTokenFailure> {
  return actions$.pipe(
    ofType<RefreshToken>(AuthActionTypes.RefreshToken),
    mergeMap(() => {
      return authService.refreshToken().pipe(
        map(({ expiresAt }) => refreshTokenSuccessAction({ expiresAt })),
        catchError((error) => of(refreshTokenFailureAction(error)))
      );
    })
  );
}

export function refreshTokenSuccess$(actions$: ActionsObservable<any>): Observable<RefreshToken> {
  return actions$.pipe(
    ofType<RefreshTokenSuccess>(AuthActionTypes.RefreshTokenSuccess),
    delayWhen(({ payload: { expiresAt } }) => timer(Math.max(1, expiresAt - new Date().getTime() - 10000))),
    mapTo(refreshTokenAction())
  );
}

export function refreshTokenFailure$(actions$: ActionsObservable<any>): Observable<Logout> {
  return actions$.pipe(ofType<RefreshTokenFailure>(AuthActionTypes.RefreshTokenFailure), mapTo(logoutAction()));
}

export function initRefresh$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<RefreshToken> {
  return actions$.pipe(
    ofType<Init>(CoreActionTypes.Init),
    take(1),
    filter(
      () =>
        selectAppOnline(state$.value) && selectUserLoggedIn(state$.value) && !selectTokenStatus(state$.value).refreshed
    ),
    mapTo(refreshTokenAction())
  );
}

export function combineAuthEpics(): any {
  const authService = Container.get(AuthService);

  return combineEpics(
    callback$.bind(null, authService),
    forceLogin$,
    initRefresh$,
    loadUserProfile$.bind(null, authService),
    loadUserProfileSuccess$,
    login$.bind(null, authService),
    loginSuccess$,
    logout$.bind(null, authService),
    refreshToken$.bind(null, authService),
    refreshTokenFailure$,
    refreshTokenSuccess$
  );
}
