import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type { PositionItinerary } from 'routes/routes';

/**
 * This function takes a list of positions and returns a new list of positions with less points (spaced by a minimum distance)
 * @param positions the list of points
 * @param minimumDistance the minimum distance between two points
 * @returns the simplified list of points
 */
export function simplifyListOfPositions(positions: PositionItinerary[], minimumDistance: number): PositionItinerary[] {
  const newPositions = positions.reduce((acc, position, index) => {
    const nbPositionsInserted = acc.length;
    if (!nbPositionsInserted || index === positions.length - 1) {
      return [...acc, position];
    }

    const prevPosition = acc[acc.length - 1];

    // we decrease the number of points for optimization purposes
    const d = getDistanceBetweenPoints([prevPosition.x, prevPosition.y], [position.x, position.y]);
    if (d > minimumDistance) {
      return [...acc, position];
    }

    return acc;
  }, [] as PositionItinerary[]);

  return newPositions;
}

/**
 * This function takes a list of positions and returns the total distance between the points
 * @param positions the list of points
 * @returns the total distance between the points
 */
export function computeDistanceListOfPositions(positions: PositionItinerary[]): number {
  return positions.reduce((acc, position, index) => {
    if (index === 0) return acc;

    const prevPosition = positions[index - 1];

    return acc + getDistanceBetweenPoints([prevPosition.x, prevPosition.y], [position.x, position.y]);
  }, 0);
}
