import { Button } from '@mui/material';
import { Border } from 'components/utils/border';
import { CollapseMore } from 'components/utils/collapse-more';
import { useCallback, useState } from 'react';
import { DisplayTrafficToolbox } from './display-traffic';

interface DisplayTrafficCollapseProps {
  getSimulationData: (force?: boolean) => Promise<void>;
}

export function DisplayTrafficCollapse(props: DisplayTrafficCollapseProps): JSX.Element {
  const [loadingData, setLoadingData] = useState(false);

  const handleClickUpdate = useCallback(async () => {
    setLoadingData(true);

    await props.getSimulationData(true);

    setLoadingData(false);
  }, [props]);

  return (
    <CollapseMore title="Traffic" defaultExpended={true} unmountOnExit={true}>
      <Border>
        <Button onClick={handleClickUpdate} fullWidth disabled={loadingData} variant="contained">
          Update
        </Button>

        <DisplayTrafficToolbox
          loadCircuit={false}
          positioning="element"
          displayCard={false}
          displayLayerGroupSelect={false}
          displayKeepDisplaySwitch={false}
          displayNoStopCantons={false}
        />
      </Border>
    </CollapseMore>
  );
}
