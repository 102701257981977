import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const CorneredButton = styled(Button)(() => ({
  '&::before,&::after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderColor: 'transparent',
    borderStyle: 'solid',
  },
  '&::before': {
    borderWidth: '4px',
    borderRightColor: 'rgba(0, 0, 0, 0.87)',
    borderBottomColor: 'rgba(0, 0, 0, 0.87)',
  },
  '&::after': {
    borderRadius: '3px',
    borderWidth: '5px',
    borderLeftColor: '#fffff',
    borderBottomColor: '#fffff',
  },
}));
