import { clearMapImageAction, loadMapImageFromYJSAction, loadMapImagePropertiesFromYJSAction } from 'actions';
import type { MapImage } from 'models/maps';
import { remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { addMapImageFilterAction, removeMapImageFilterAction } from 'reducers/local/filters.reducer';
import store from 'store';
import type { YMap } from 'yjs/dist/src/internals';

export const useMapImageSubscription = (): void => {
  const mapImageMap = remoteDoc?.getMap('mapImage') as YMap<{
    data: Partial<MapImage>;
    uInt8Array: Uint8Array;
  }>;

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      if (isTransactionLocal) return;

      const mapImages = store.getState().maps.mapImage.mapImages;
      if (mapImages?.length) {
        mapImages.forEach((mapImage) => {
          if (mapImageMap.has(mapImage.name)) return;

          store.dispatch(clearMapImageAction({ name: mapImage.name }));
          store.dispatch(removeMapImageFilterAction({ name: mapImage.name }));
        });
      }

      if (!mapImageMap.size) return;

      mapImageMap.forEach((remoteMapImage, key) => {
        const mapImagePropagated = mapImages?.find((mapImage) => mapImage.name === key);

        if (
          !mapImagePropagated ||
          mapImagePropagated.height !== remoteMapImage.data.height ||
          mapImagePropagated.scaling !== remoteMapImage.data.scaling ||
          mapImagePropagated.x !== remoteMapImage.data.x ||
          mapImagePropagated.y !== remoteMapImage.data.y
        ) {
          store.dispatch(loadMapImagePropertiesFromYJSAction({ data: { ...remoteMapImage.data, name: key } }));
        }

        if (mapImagePropagated && mapImagePropagated.URL) return;

        store.dispatch(loadMapImageFromYJSAction({ name: key }));
        store.dispatch(addMapImageFilterAction({ name: key }));
      });
    };

    mapImageMap.observe(observerHandle);

    return () => {
      mapImageMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
