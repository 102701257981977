import { awareness } from 'multiplayer/globals';
import { unsetFollowUser } from 'multiplayer/multiplayer';
import { useLayoutEffect } from 'react';
import store, { useAppSelector } from 'store';
import { useUsers } from 'y-presence';

export function useUnfollowUserView(): void {
  if (!awareness) throw new Error('Missing awareness');

  const followClientId = useAppSelector((state) => state.multiplayer.followClientId);
  const followedUser = useUsers(awareness, (state) => state.get(followClientId ?? 0));
  const userSize = useUsers(awareness, (state) => state.size);

  useLayoutEffect(() => {
    if (!followClientId) return;

    const isUserStillConnected = !!followedUser;
    if (isUserStillConnected) return;

    store.dispatch(unsetFollowUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSize]);
}
