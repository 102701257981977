/**
 * Computes the current browser used
 */
export const browser = (function (agent) {
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('edg/') > -1:
      return 'chromium based edge'; // Match also / to avoid matching for the older Edge
    case agent.indexOf('opr') > -1 && !!(window as any).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
})(window.navigator.userAgent.toLowerCase());

/**
 * Promise that resolves after a 0 ms timeout (not actual 0ms, actually around 10ms if the cpu load is not too high)
 * @returns
 */
export function setImmediateTimeoutPromise(): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 0);
  });
}

/**
 * Test if the browser support the webp image format
 * More precisely if the browser can export a webp from a canvas, which is what we want
 *
 * - https://stackoverflow.com/questions/5573096/detecting-webp-support
 * - https://caniuse.com/webp
 *
 * @returns wether the browser support the webp format
 */
export const webpSupport = (): boolean =>
  document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
