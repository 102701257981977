import { ArrowDropDown, ChildFriendly } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SchoolIcon from '@mui/icons-material/School';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { closeDialogAction, openDialogAction } from 'actions';
import { DialogTypes } from 'models';
import { USER_COLORS, setProjectHost } from 'multiplayer/globals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthService } from 'services/auth.service';
import { loadProject } from 'services/project';
import store, { useAppDispatch, useAppSelector } from 'store';
import { Container } from 'typescript-ioc';
import { browser } from 'utils/browser';
import { TRAINING_URL, getConfig } from 'utils/config';
import { getRecentProjects } from 'utils/editor/recent-projects';
import { PreferencesService } from 'utils/preferences';
import { timeAgoString } from 'utils/time';
import packageJson from '../../../package.json';

const { REACT_APP_CACHE_BUST2: REACT_APP_CACHE_BUST_SHA } = process.env;

function isBrowserSupported(): boolean {
  if (!('showDirectoryPicker' in window)) {
    // eslint-disable-next-line no-console
    console.warn('showDirectoryPicker not supported');

    return false;
  }

  if (!HTMLCanvasElement.prototype.transferControlToOffscreen) {
    // eslint-disable-next-line no-console
    console.warn('transferControlToOffscreen not supported');

    return false;
  }

  return browser === 'chrome' || browser === 'chromium based edge' || browser === 'opera';
}

export default function WelcomeDialog(): JSX.Element {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(!localStorage.skipToBlank);
  const [loadingProject, setLoadingProject] = useState(false);

  const recentProjects = useMemo(() => getRecentProjects(), []);

  const [browserSupported] = useState(isBrowserSupported());

  const greeting = useMemo(() => timeToGreeting(), []);
  const profile = useAppSelector((state) => state.auth.profile);
  const userName = useMemo(() => {
    if (profile && 'name' in profile && profile.name) {
      const splittedName = profile.name.split(' ');
      if (splittedName.length === 2) {
        return splittedName[0];
      }
    }

    return null;
  }, [profile]);

  const handleClose = useCallback((): void => {
    setOpen(false);

    setTimeout(() => {
      dispatch(closeDialogAction());
    }, theme.transitions.duration.leavingScreen);
  }, [dispatch, theme.transitions.duration.leavingScreen]);

  const handleClickBlanck = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleClickOpenProject = useCallback(async () => {
    setLoadingProject(true);
    try {
      const projectLoaded = await loadProject({});

      if (projectLoaded) {
        handleClose();
      }
    } catch (e) {
      setLoadingProject(false);
    }
  }, [handleClose]);

  const isOnline = useAppSelector((state) => state.core.appOnline);
  const authService = Container.get(AuthService);

  const dialogToOpen = !isOnline
    ? DialogTypes.Welcome
    : authService.authorized
      ? DialogTypes.Welcome
      : DialogTypes.AccessRequired;
  if (dialogToOpen === DialogTypes.AccessRequired) {
    dispatch(openDialogAction({ type: dialogToOpen, payload: undefined }));
  }

  const [displayReleaseNotes, setDisplayReleaseNotes] = useState(false);

  const roadEditorVersion = useMemo(() => getConfig('roadEditorVersion') || packageJson.version || undefined, []);
  const [lastViewedReleaseNotes, setLastViewedReleaseNotes] = useState(
    localStorage.lastViewedReleaseNotes as string | undefined
  );
  const alreadyViewedReleaseNote = lastViewedReleaseNotes === roadEditorVersion && roadEditorVersion !== undefined;
  const versionLabelReleaseNotes = useMemo(() => {
    if (displayReleaseNotes) {
      return <>Go back</>;
    }

    return alreadyViewedReleaseNote ? <>Release notes</> : <>What's new? ({roadEditorVersion})</>;
  }, [alreadyViewedReleaseNote, displayReleaseNotes, roadEditorVersion]);
  const handleClickReleaseNoteChip = useCallback(() => {
    setDisplayReleaseNotes(!displayReleaseNotes);

    localStorage.lastViewedReleaseNotes = roadEditorVersion;
    setLastViewedReleaseNotes(roadEditorVersion);
  }, [displayReleaseNotes, roadEditorVersion]);

  const removeProjectNameFromRecent = useCallback(
    (
      recentProjects: {
        name: string;
        lastOpen: string;
      }[],
      projectName: string
    ): void => {
      const index = recentProjects.findIndex((project) => project.name === projectName);
      if (index > -1) {
        recentProjects.splice(index, 1);
      }

      localStorage.setItem('recentProjects', JSON.stringify(recentProjects));
    },
    []
  );

  const handleProjectSuccess = useCallback(
    async (
      directoryHandle: FileSystemDirectoryHandle,
      projectName: string,
      recentProjects: { name: string; lastOpen: string }[],
      db: IDBDatabase
    ): Promise<void> => {
      const permissionState = await directoryHandle.requestPermission({ mode: 'readwrite' });
      if (permissionState !== 'granted') {
        PreferencesService.setDirectoryHandle();
        setLoadingProject(false);

        return;
      }

      try {
        PreferencesService.setDirectoryHandle(directoryHandle);
        await loadProject({ keepDirectoryHandle: true });
        handleClose();

        const index = recentProjects.findIndex((project) => project.name === projectName);
        if (index > -1) {
          recentProjects.splice(index, 1);
        }

        recentProjects.unshift({ name: projectName, lastOpen: new Date().toUTCString() });

        localStorage.setItem('recentProjects', JSON.stringify(recentProjects));
        db.close();
      } catch (e) {
        if (e instanceof DOMException && e.name === 'NotFoundError') {
          removeProjectNameFromRecent(recentProjects, projectName);
        } else if (e instanceof Error && e.name === 'AbortError') {
          // eslint-disable-next-line no-console
          console.log('User closed the dialog without selecting a directory');
        } else {
          throw e;
        }

        PreferencesService.setDirectoryHandle();
        setLoadingProject(false);
      }
    },
    [handleClose, removeProjectNameFromRecent]
  );

  const handleClickOpenRecentProject = useCallback(
    (projectName: string) => {
      if (!projectName) return;

      const dbPromise = indexedDB.open('projects');

      dbPromise.onsuccess = (event) => {
        setLoadingProject(true);
        try {
          const db = (event.target as IDBOpenDBRequest).result;
          const transaction = db.transaction('projects', 'readwrite');
          const objectStore = transaction.objectStore('projects');
          const getProject = objectStore.get(projectName);

          getProject.onsuccess = async (event) => {
            const directoryHandle = (event.target as IDBRequest).result as FileSystemDirectoryHandle | undefined;
            const recentProjects = JSON.parse(localStorage.getItem('recentProjects') || '[]') as {
              name: string;
              lastOpen: string;
            }[];

            if (!directoryHandle) {
              setLoadingProject(false);
              removeProjectNameFromRecent(recentProjects, projectName);

              return;
            }

            await handleProjectSuccess(directoryHandle, projectName, recentProjects, db);
          };

          getProject.onerror = (event) => {
            // eslint-disable-next-line no-console
            console.error('Error getting project:', (event.target as IDBRequest).error);
            setLoadingProject(false);
          };

          transaction.onerror = (event) => {
            // eslint-disable-next-line no-console
            console.error('Error opening transaction:', (event.target as IDBTransaction).error);
            setLoadingProject(false);
          };
        } catch (e) {
          recentProjects.length = 0;
          localStorage.removeItem('recentProjects');
          PreferencesService.setDirectoryHandle();
          setLoadingProject(false);
        }
      };

      dbPromise.onerror = (event) => {
        // eslint-disable-next-line no-console
        console.error('Error opening indexedDB:', (event.target as IDBOpenDBRequest).error);

        removeProjectNameFromRecent(recentProjects, projectName);
      };

      dbPromise.onblocked = () => {
        // eslint-disable-next-line no-console
        console.error('Error opening indexedDB: blocked');

        removeProjectNameFromRecent(recentProjects, projectName);
      };
    },

    [handleProjectSuccess, recentProjects, removeProjectNameFromRecent]
  );

  const date = useMemo(() => new Date(), []);

  const [anchorElDropdown, setAnchorElDropdown] = useState<HTMLButtonElement | null>(null);
  const handleClickDropdown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElDropdown(event.currentTarget);
    },
    [setAnchorElDropdown]
  );

  useEffect(() => {
    setProjectHost(true);
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      aria-labelledby="welcome-dialog-title"
      TransitionProps={{ appear: false }}
      scroll="paper"
      maxWidth={displayReleaseNotes ? 'xl' : undefined}
      sx={
        {
          // maxWidth: displayReleaseNotes ? undefined : '610px',
        }
      }
    >
      <DialogTitle
        id="welcome-dialog-title"
        sx={{
          fontStyle: 'bolder',
        }}
      >
        Good {greeting} {userName || ''}
        <Chip
          sx={{
            float: 'right',
          }}
          label={versionLabelReleaseNotes}
          onClick={handleClickReleaseNoteChip}
          icon={displayReleaseNotes ? <ArrowBackIcon /> : alreadyViewedReleaseNote ? undefined : <NewReleasesIcon />}
          color={displayReleaseNotes ? 'primary' : undefined}
        />
      </DialogTitle>

      {!displayReleaseNotes && (
        <>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2) }}>
            <Typography>What do you want to do?</Typography>
            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', columnGap: theme.spacing() }}>
              <Button
                variant="contained"
                size="large"
                onClick={handleClickBlanck}
                disabled={loadingProject}
                sx={{
                  width: '80%',
                  borderRadius: '24px',
                  // paddingTop: theme.spacing(1),
                  // paddingBottom: theme.spacing(1),
                  height: '48px',
                }}
                color="inherit"
              >
                <CreateIcon sx={{ marginRight: theme.spacing(1) }} />
                Create new blank
              </Button>
              <ButtonGroup
                variant="contained"
                color="primary"
                size="large"
                disabled={loadingProject}
                sx={{
                  width: '100%',
                  borderRadius: '24px',
                  height: '48px',
                }}
              >
                <Button
                  sx={{
                    width: '100%',
                    borderRadius: '24px',
                  }}
                  onClick={handleClickOpenProject}
                >
                  {!loadingProject ? (
                    <FolderOpenIcon sx={{ marginRight: theme.spacing(1) }} />
                  ) : (
                    <CircularProgress sx={{ marginRight: theme.spacing(1) }} size={24} />
                  )}
                  Open existing project
                </Button>
                <Button
                  size="small"
                  sx={{
                    borderRadius: '24px',
                  }}
                  onClick={handleClickDropdown}
                >
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
            </Box>
          </DialogContent>
          <Menu anchorEl={anchorElDropdown} open={!!anchorElDropdown} onClose={() => setAnchorElDropdown(null)}>
            <Box component={'span'}>
              <MenuItem
                onClick={() =>
                  store.dispatch(
                    openDialogAction({
                      type: DialogTypes.CreateProject,
                      payload: undefined,
                    })
                  )
                }
              >
                <ListItemIcon>
                  <ChildFriendly fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create a new project</ListItemText>
              </MenuItem>
            </Box>
          </Menu>

          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: theme.spacing(1),
              paddingBottom: browserSupported ? undefined : theme.spacing(0),
            }}
          >
            <Link target="_blank" href={TRAINING_URL} sx={{ display: 'flex', columnGap: theme.spacing(1) }}>
              <SchoolIcon fontSize="small" />
              <Typography variant="body2">Need help? Follow the training here</Typography>
              <OpenInNewIcon fontSize="small" />
            </Link>
          </DialogContent>

          {!browserSupported && (
            <DialogContent sx={{ paddingTop: 0 }}>
              <Alert severity="warning">
                <AlertTitle>Browser not supported</AlertTitle>
                <Typography>
                  It looks like your browser is not supported. Please use{' '}
                  <a href="https://www.google.com/chrome/">Google Chrome</a>,{' '}
                  <a href="https://www.chromium.org/getting-involved/download-chromium/">Chromium</a>,{' '}
                  <a href="https://www.opera.com/download">Opera</a>, or{' '}
                  <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>.
                </Typography>
              </Alert>
            </DialogContent>
          )}

          {recentProjects.length ? (
            <>
              <Divider />
              <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography sx={{ paddingTop: theme.spacing(2) }}>Recently opened projects</Typography>
              </DialogContent>
              <DialogContent sx={{ paddingTop: 0 }}>
                <List dense={true} sx={{ maxHeight: '206px' }}>
                  {recentProjects.map((project, index) => (
                    <ListItemButton
                      onClick={() => handleClickOpenRecentProject(project.name)}
                      sx={{ boxShadow: `inset 3px 0px 0px 0px ${USER_COLORS[index]}` }}
                      key={`${index}-${project.name}`}
                    >
                      <ListItemText primary={project.name} />
                      <ListItemText
                        primary={timeAgoString(new Date(project.lastOpen), date)}
                        sx={{ textAlign: 'right', color: theme.palette.grey[400] }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </DialogContent>
            </>
          ) : null}
        </>
      )}

      {displayReleaseNotes && (
        <DialogContent>
          <object
            title="Release notes"
            data={`/release-note.pdf?cachebust=${REACT_APP_CACHE_BUST_SHA}`}
            style={{
              width: '100%',
              height: '1000px',
            }}
            type="application/pdf"
          ></object>
        </DialogContent>
      )}
    </Dialog>
  );
}

/**
 * Returns a greeting depeding of the time in the day
 */
function timeToGreeting(now = new Date()): string {
  const currentHour = now.getHours();

  if (currentHour > 3 && currentHour < 12) {
    return 'morning';
  }

  if (currentHour >= 12 && currentHour < 18) {
    return 'afternoon';
  }

  return 'evening';
}
