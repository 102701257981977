import { Functions } from '@mui/icons-material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WarningIcon from '@mui/icons-material/Warning';
import type { SelectChangeEvent } from '@mui/material';
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { saveCellTemplateAction } from 'actions/cell-templates';
import Load from 'assets/load.svg';
import PalletOnGround from 'assets/pallet-on-ground.svg';
import BeamFaceXToleranceImg from 'assets/perception/diagrams/beamFaceXTolerance.png';
import BeamHeightZToleranceImg from 'assets/perception/diagrams/beamHeightZTolerance.png';
import CameraProjectionBeforeInspectionPlaneImg from 'assets/perception/diagrams/cameraProjectionBeforeInspectionPlane.png';
import DepthClearanceImg from 'assets/perception/diagrams/depth-clearance-x.png';
import FlyingObjectDetectionImg from 'assets/perception/diagrams/flyingObjectDetection.png';
import HorizontalProjectionImg from 'assets/perception/diagrams/horizontalProjection.png';
import HorizontalProjectionOnInspectionPlaneImg from 'assets/perception/diagrams/horizontalProjectionOnInspectionPlane.png';
import InitialSolutionXImg from 'assets/perception/diagrams/initial-solution-x.png';
import InitialSolutionYImg from 'assets/perception/diagrams/initial-solution-y.png';
import InitialSolutionZImg from 'assets/perception/diagrams/initial-solution-z.png';
import LateralClearanceImg from 'assets/perception/diagrams/lateral-clearance-y.png';
import MinObstacleMultiplierImg from 'assets/perception/diagrams/minObstacleMultiplier.png';
import MinimumHeightVerificationImg from 'assets/perception/diagrams/minimum-height-verification.png';
import OverhangToleranceImg from 'assets/perception/diagrams/overhangTolerance.png';
import StartDepthImg from 'assets/perception/diagrams/start-depth.png';
import StartHeightImg from 'assets/perception/diagrams/start-height.png';
import UprightYToleranceImg from 'assets/perception/diagrams/uprightYTolerance.png';
import VerticalClearanceImg from 'assets/perception/diagrams/vertical-clearance-z.png';
import { Expand } from 'components/utils/expand';
import { SmallChip } from 'components/utils/small-chip';
import {
  CustomWidthHelpIconTooltip,
  HelpIconTooltip,
  PreviewHelper,
  WarningIconTooltip,
} from 'components/utils/tooltips';
import { toString } from 'lodash';
import type { CellLoad, PerceptionReference } from 'models/circuit';
import { isPerceptionReference } from 'models/circuit.guard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { CellTemplate } from 'reducers/circuit/state';
import store, { useAppDispatch, useAppSelector } from 'store';
import {
  getDefaultBeamUpMarginObstacle,
  getDefaultRackCellTemplate,
  getEuroPallet,
} from 'utils/circuit/default-circuit-shapes';
import {
  approachDistanceMin,
  cellHeightMaxHeight,
  cellHeightMinHeight,
  columnWidthMin,
  computeWidthTakenByLoads,
} from 'utils/circuit/racks';
import { epsilon } from 'utils/circuit/utils';
import { theme } from 'utils/mui-theme';
import type { ActionsRack } from '.';
import { RackEditionCellTemplateParameters } from './RackEditionCellTemplateParameters';
import { RackEditionCellTemplatePreview } from './RackEditionCellTemplatePreview';

export type FocusInputs = undefined | 'cellWidth' | 'a1' | 'loadWidth' | 'loadOverflow' | 'loadlength';

export const beamThickness = 0.1;

export const minimumVerticalClearanceForDropColor = 'red';

const beamDistanceToRackFrontMin = -1; // m
const beamDistanceToRackFrontMax = 1; // m

/**
 * @link https://redmine.balyo.com/issues/47557
 */
const authorizedSafetyMarginRange = [50, 180]; // mm
const recommendedSafetyMarginRange = [50, 150]; // mm

interface RackEditionCellTemplateProps {
  /** id of the cell template */
  cellTemplateId: string;
  /** list of the project cell templates */
  cellTemplates: Record<string, CellTemplate>;

  /** callback to reset the cell template that is currently being edited */
  resetCellTemplateRef?: React.MutableRefObject<(() => void) | undefined>;
  /** callback to save the cell template that is currently being edited */
  saveCellTemplateRef?: React.MutableRefObject<(() => void) | undefined>;
  /** callback to create a new cell template */
  addCellTemplateRef?: React.MutableRefObject<(() => void) | undefined>;

  actions?: ActionsRack;
}
export function RackEditionCellTemplate({
  cellTemplateId,
  cellTemplates,
  resetCellTemplateRef,
  saveCellTemplateRef,
  actions,
}: RackEditionCellTemplateProps): JSX.Element {
  const [cellTemplate, setCellTemplate] = useState(cellTemplates[cellTemplateId]);
  const [displayView, setDisplayView] = useState({ front: true, top: false });
  const [view, setView] = useState<ViewCellTemplate>('geometry');

  const [focusedInputs, setFocusedInputs] = useState<FocusInputs>(undefined);

  const dispatch = useAppDispatch();

  const onChangeFocusedInputs = useCallback((newState: FocusInputs) => {
    setFocusedInputs(newState);
  }, []);

  const updateCellTemplate = useCallback(
    (newCellTemplate: CellTemplate) => {
      setCellTemplate(newCellTemplate);

      if (actions) actions.cellTemplateChange(true);
    },
    [actions]
  );

  useEffect(() => {
    setCellTemplate(cellTemplates[cellTemplateId]);
  }, [cellTemplateId, cellTemplates]);

  const updateDisplayView = useCallback((newDisplayView: { front: boolean; top: boolean }) => {
    setDisplayView(newDisplayView);
  }, []);

  const resetCellTemplate = useCallback(() => {
    setCellTemplate({ ...cellTemplates[cellTemplateId] });

    actions?.cellTemplateChange(false);
  }, [actions, cellTemplateId, cellTemplates]);

  const saveCellTemplate = useCallback(() => {
    dispatch(
      saveCellTemplateAction({
        ...cellTemplate,
        userAction: true,
      })
    );

    actions?.cellTemplateChange(false);
  }, [actions, cellTemplate, dispatch]);

  useEffect(() => {
    if (resetCellTemplateRef) {
      resetCellTemplateRef.current = resetCellTemplate;
    }
  }, [resetCellTemplate, resetCellTemplateRef]);
  useEffect(() => {
    if (saveCellTemplateRef) {
      saveCellTemplateRef.current = saveCellTemplate;
    }
  }, [saveCellTemplate, saveCellTemplateRef]);

  const handleSetView = useCallback((newView: ViewCellTemplate) => {
    setView(newView);
  }, []);

  const widthTakenByLargestLoads = useMemo(() => {
    const loads = cellTemplate.loads;
    let widthTakenByLargestLoads = 0;

    for (const load of loads) {
      const widthTakenByLoad = computeWidthTakenByLoads(load, true);

      if (widthTakenByLoad > widthTakenByLargestLoads) widthTakenByLargestLoads = widthTakenByLoad;
    }

    return widthTakenByLargestLoads;
  }, [cellTemplate.loads]);

  const [pickOrDropMode, setPickOrDropMode] = useState<PickOrDrop>('pick');

  return (
    <Grid container spacing={3}>
      {/** the menu with the parameters */}
      <Grid item xs={12} sm={12} md={6} lg={4} sx={{ overflow: 'auto', overflowX: 'hidden' }}>
        <RackEditionCellTemplateParameters
          cellTemplate={cellTemplate}
          updateCellTemplate={updateCellTemplate}
          view={view}
          setView={handleSetView}
          focusedInputs={focusedInputs}
          onChangeFocusedInputs={onChangeFocusedInputs}
          widthTakenByLargestLoads={widthTakenByLargestLoads}
          setPickOrDropMode={setPickOrDropMode}
        />
      </Grid>
      {/** the preview of the cell template */}
      <Grid item xs={12} sm={12} md={6} lg={8} sx={{}}>
        <RackEditionCellTemplatePreview
          cellTemplate={cellTemplate}
          updateCellTemplate={updateCellTemplate}
          frontView={displayView.front}
          topView={displayView.top}
          updateDisplayViewAction={updateDisplayView}
          view={view}
          focusedInputs={focusedInputs}
          onChangeFocusedInputs={onChangeFocusedInputs}
          widthTakenByLargestLoads={widthTakenByLargestLoads}
          pickOrDropMode={pickOrDropMode}
        />
      </Grid>
    </Grid>
  );
}

export type ViewCellTemplate = 'geometry' | 'perception';

export interface GeometryCellTemplateData {
  width: string;
  height: string;
  approachDistance: string;
  leftObstacle: string;
  rightObstacle: string;
  palletOverflow: string;
  loadLength: string;
  beamDistanceToRackFront: string;
  maximumLoadHeight: string;
}
interface CellTemplateParametersGeometryProps {
  cellTemplate: CellTemplate;
  handleBlurNumberField: (
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    fallbackValue: number,
    options?: {
      min?: number;
      max?: number;
      unitFactor?: number;
      toFixedValue?: number;
    }
  ) => void;
  handleKeyPressNumberField: (
    e: React.KeyboardEvent<HTMLDivElement>,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    fallbackValue: number,
    options?: {
      min?: number;
      max?: number;
      /** by default * 1000 because mm */
      unitFactor?: number;
      /** 0 by default */
      toFixedValue?: number;
    }
  ) => void;
  geometryDataRef: React.MutableRefObject<GeometryCellTemplateData | undefined>;

  focusedInputs?: FocusInputs;
  onChangeFocusedInputs?: (focusedInputs: FocusInputs) => void;
  widthTakenByLargestLoads?: number;
  requestCellTemplateUpdate?: () => void;
}
export function CellTemplateParametersGeometry({
  cellTemplate,
  handleBlurNumberField,
  handleKeyPressNumberField,
  geometryDataRef,
  focusedInputs,
  onChangeFocusedInputs,
  widthTakenByLargestLoads,
  requestCellTemplateUpdate,
}: CellTemplateParametersGeometryProps): JSX.Element {
  const [width, setWidth] = useState((cellTemplate.width * 1000).toFixed(0));
  const [height, setHeight] = useState((cellTemplate.height * 1000).toFixed(0));
  const [approachDistance, setApproachDistance] = useState((cellTemplate.approachDistance * 1000).toFixed(0));
  const [leftObstacle, setLeftObstacle] = useState((cellTemplate.leftObstacle * 1000).toFixed(0));
  const [rightObstacle, setRightObstacle] = useState((cellTemplate.rightObstacle * 1000).toFixed(0));
  const [palletOverflow, setPalletOverflow] = useState((cellTemplate.palletOverflow * 1000).toFixed(0));
  const [loadLength, setLoadLength] = useState(
    ((cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 1.2) * 1000).toFixed(0)
  );
  const [beamDistanceToRackFront, setBeamDistanceToRackFront] = useState(
    ((cellTemplate.beamDistanceToRackFront ?? 0) * 1000).toFixed(0)
  );
  const [maximumLoadHeight, setMaximumLoadHeight] = useState(
    ((cellTemplate.maximumLoadHeight ?? getDefaultRackCellTemplate().maximumLoadHeight) * 1000).toFixed(0)
  );

  useEffect(() => {
    setWidth((cellTemplate.width * 1000).toFixed(0));
  }, [cellTemplate.width, cellTemplate]);
  useEffect(() => {
    setHeight((cellTemplate.height * 1000).toFixed(0));
  }, [cellTemplate.height, cellTemplate]);
  useEffect(() => {
    setApproachDistance((cellTemplate.approachDistance * 1000).toFixed(0));
  }, [cellTemplate.approachDistance, cellTemplate]);

  useEffect(() => {
    setLeftObstacle((cellTemplate.leftObstacle * 1000).toFixed(0));
  }, [cellTemplate.leftObstacle]);
  useEffect(() => {
    setRightObstacle((cellTemplate.rightObstacle * 1000).toFixed(0));
  }, [cellTemplate.rightObstacle]);
  useEffect(() => {
    setPalletOverflow((cellTemplate.palletOverflow * 1000).toFixed(0));
  }, [cellTemplate.palletOverflow]);
  useEffect(() => {
    setLoadLength(((cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 1.2) * 1000).toFixed(0));
  }, [cellTemplate.loadLength]);
  useEffect(() => {
    setBeamDistanceToRackFront(((cellTemplate.beamDistanceToRackFront ?? 0) * 1000).toFixed(0));
  }, [cellTemplate.beamDistanceToRackFront]);

  useEffect(() => {
    geometryDataRef.current = {
      width,
      height,
      approachDistance,
      leftObstacle,
      rightObstacle,
      palletOverflow,
      loadLength,
      beamDistanceToRackFront,
      maximumLoadHeight,
    };
  }, [
    approachDistance,
    beamDistanceToRackFront,
    geometryDataRef,
    height,
    leftObstacle,
    loadLength,
    maximumLoadHeight,
    palletOverflow,
    rightObstacle,
    width,
  ]);

  const [advisedThCellWidth, setAdvisedThCellWidth] = useState<undefined | number>(undefined);

  const handleAdvisedValue = useCallback(() => {
    if (advisedThCellWidth !== undefined) {
      const convertedAdvised = (advisedThCellWidth * 1000).toFixed(0).toString();
      setWidth(convertedAdvised);

      if (requestCellTemplateUpdate) requestCellTemplateUpdate();
    }
  }, [advisedThCellWidth, requestCellTemplateUpdate]);
  // here we calculate the proper suggested cell Value
  useEffect(() => {
    let minimumColumnWidthAffected = 1 / 0;
    const storeState = store.getState();
    const rackIds = storeState.circuit.present.racks.ids;
    const racks = rackIds.map((rackId) => storeState.circuit.present.racks.entities[rackId]);

    const columnsWithThisCellTemplateAssigned = racks
      .map((rack) => rack.properties.columns)
      .flat()
      .filter((column) => column.cells.some((cell) => cell.cellTemplate === cellTemplate.id));
    columnsWithThisCellTemplateAssigned.forEach((column) => {
      const columnWidth = column.width;
      if (columnWidth < minimumColumnWidthAffected) {
        minimumColumnWidthAffected = columnWidth;
      }
    });

    if (minimumColumnWidthAffected < 1 / 0) {
      setAdvisedThCellWidth(minimumColumnWidthAffected);
    }
  }, [cellTemplate.id]);

  const isSafetyMarginAuthorized = useMemo(() => {
    const safetyMargin = parseFloat(approachDistance);

    return safetyMargin >= authorizedSafetyMarginRange[0] && safetyMargin <= authorizedSafetyMarginRange[1];
  }, [approachDistance]);
  const isSafetyMarginRecommended = useMemo(() => {
    const safetyMargin = parseFloat(approachDistance);

    return safetyMargin >= recommendedSafetyMarginRange[0] && safetyMargin <= recommendedSafetyMarginRange[1];
  }, [approachDistance]);

  const verticalClearance = useMemo(() => {
    if (!+maximumLoadHeight) return '0';

    const verticalClearance = parseFloat(height) - parseFloat(maximumLoadHeight);

    return verticalClearance.toFixed(0);
  }, [height, maximumLoadHeight]);

  const isSafetyClearanceRecommended = useMemo(() => {
    if (!+verticalClearance) return true;
    const safetyClearanceValue = parseFloat(verticalClearance);

    return safetyClearanceValue >= 150;
  }, [verticalClearance]);

  const isMaximumLoadHeightAuthorized = useMemo(() => {
    if (!+maximumLoadHeight) return true;

    return parseFloat(maximumLoadHeight) < parseFloat(height);
  }, [height, maximumLoadHeight]);

  const isMaximumLoadHeightTooLow = useMemo(() => {
    if (!+maximumLoadHeight) return false;

    return parseFloat(maximumLoadHeight) < 144;
  }, [maximumLoadHeight]);

  return (
    <>
      <Grid container columnSpacing={1} rowSpacing={2} paddingTop={3}>
        <Grid item xs={6}>
          <TextField
            label={
              <>
                Theoretical Cell Width <PreviewHelper />
                <HelpIconTooltip
                  title={
                    widthTakenByLargestLoads
                      ? `Template cell width compatible down to a column of ${(widthTakenByLargestLoads * 1000).toFixed(
                          0
                        )} mm`
                      : 'Template cell width but the column width will be used'
                  }
                />
              </>
            }
            type="number"
            value={width}
            fullWidth
            sx={{ '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'dashed' } }}
            size="small"
            onChange={(e) => setWidth(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {advisedThCellWidth !== undefined &&
                  Math.abs(advisedThCellWidth * 1000 - parseFloat(width)) > epsilon ? (
                    <Tooltip
                      title={`Your current value is ${width} mm but we have detected that it is affected in a column of width  ${(
                        advisedThCellWidth * 1000
                      ).toFixed(
                        0
                      )} mm, which is the most critical case. It has no actual effect but it could help you better design your rack.`}
                    >
                      <IconButton onClick={handleAdvisedValue}>
                        <TipsAndUpdatesIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : undefined}
                  mm
                </InputAdornment>
              ),
            }}
            inputProps={{
              step: 10,
              min: columnWidthMin * 1000,
            }}
            onFocus={() => onChangeFocusedInputs && onChangeFocusedInputs('cellWidth')}
            onBlur={() => handleBlurNumberField(width, setWidth, cellTemplate.width)}
            onKeyDown={(e) => handleKeyPressNumberField(e, width, setWidth, cellTemplate.width)}
            focused={focusedInputs === 'cellWidth' ? true : undefined}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label={
              <>
                Theoretical Cell Height <PreviewHelper />
                <HelpIconTooltip
                  title={
                    'Value used for the visualization. Actually, this value is not used elsewhere, it is the actual height of the cell that is used.'
                  }
                />
              </>
            }
            type="number"
            value={height}
            fullWidth
            sx={{ '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'dashed' } }}
            size="small"
            onChange={(e) => setHeight(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            inputProps={{
              step: 10,
              min: cellHeightMinHeight * 1000,
              max: cellHeightMaxHeight * 1000,
            }}
            onBlur={() => handleBlurNumberField(height, setHeight, cellTemplate.height)}
            onKeyDown={(e) => handleKeyPressNumberField(e, height, setHeight, cellTemplate.height)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label={
              <>
                Maximum load height
                <HelpIconTooltip title="Maximum height of the load allowed in the cell. If not defined, no limitation will be applied." />
              </>
            }
            type="number"
            value={+maximumLoadHeight ? maximumLoadHeight : undefined}
            fullWidth
            size="small"
            onChange={(e) => setMaximumLoadHeight(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {!isMaximumLoadHeightAuthorized && (
                      <WarningIconTooltip
                        sx={{
                          color: theme.palette.error.main,
                          marginRight: theme.spacing(1),
                        }}
                        title={`Maximum load height should be lower than the cell height.`}
                      />
                    )}
                    {isMaximumLoadHeightTooLow && (
                      <WarningIconTooltip
                        sx={{
                          color: theme.palette.error.main,
                          marginRight: theme.spacing(1),
                        }}
                        title={`Maximum load height should be higher than 144mm (normal pallet height).`}
                      />
                    )}
                    mm
                  </>
                </InputAdornment>
              ),
            }}
            inputProps={{
              step: 10,
              min: 0,
              max: cellHeightMaxHeight * 1000 - 150,
            }}
            onBlur={() => handleBlurNumberField(maximumLoadHeight, setMaximumLoadHeight, 0)}
            onKeyDown={(e) => handleKeyPressNumberField(e, maximumLoadHeight, setMaximumLoadHeight, 0)}
            error={!isMaximumLoadHeightAuthorized}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={
              <>
                Theorical vertical clearance
                <HelpIconTooltip title="Free space between the top of the load and the upper beam" />
              </>
            }
            type="number"
            value={+verticalClearance > 0 ? verticalClearance : ''}
            fullWidth
            size="small"
            disabled
            InputProps={{
              startAdornment: (
                <Tooltip title="Computed value">
                  <InputAdornment position="start">
                    <Functions fontSize="small" />
                  </InputAdornment>
                </Tooltip>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {!isSafetyClearanceRecommended && (
                      <WarningIconTooltip
                        sx={{
                          color: theme.palette.error.main,
                          marginRight: theme.spacing(1),
                        }}
                        title={`Vertical clearance minimum recommended value is 150mm.`}
                      />
                    )}
                    mm
                  </>
                </InputAdornment>
              ),
            }}
            inputProps={{
              step: 10,
              min: 0,
            }}
            error={!isSafetyMarginAuthorized}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={
              <>
                Safety Margin{' '}
                <HelpIconTooltip title="Distance between the robot safety field and the front of the pallet. It should be as small as possible to avoid crushing someone between an obstacle but large enough to do not trigger the software safety." />
              </>
            }
            type="number"
            value={approachDistance}
            fullWidth
            size="small"
            onChange={(e) => setApproachDistance(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {!isSafetyMarginAuthorized && (
                      <WarningIconTooltip
                        sx={{
                          color: theme.palette.error.main,
                          marginRight: theme.spacing(1),
                        }}
                        title={`Safety marging outside of the authorized range. Authorized range: [${authorizedSafetyMarginRange[0]}, ${authorizedSafetyMarginRange[1]}] mm. The norm ISO 3691-4 specifies a maxmimum value of 180 mm.`}
                      />
                    )}
                    {!isSafetyMarginRecommended && isSafetyMarginAuthorized && (
                      <WarningIconTooltip
                        sx={{
                          marginRight: theme.spacing(1),
                        }}
                        title={`Safety marging outside of the recommended range. Recommended range: [${recommendedSafetyMarginRange[0]}, ${recommendedSafetyMarginRange[1]}] mm.`}
                      />
                    )}
                    mm
                  </>
                </InputAdornment>
              ),
            }}
            inputProps={{
              step: 10,
              min: approachDistanceMin * 1000,
            }}
            onBlur={() => handleBlurNumberField(approachDistance, setApproachDistance, cellTemplate.approachDistance)}
            onKeyDown={(e) =>
              handleKeyPressNumberField(e, approachDistance, setApproachDistance, cellTemplate.approachDistance)
            }
            error={!isSafetyMarginAuthorized}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={
              <>
                Load Overflow{' '}
                <HelpIconTooltip title="Distance from the front of the beam and the front of the pallet. Beware the robots can reach the pallet position (foot protection, etc.)." />
              </>
            }
            type="number"
            value={palletOverflow}
            fullWidth
            size="small"
            onChange={(e) => setPalletOverflow(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {parseFloat(palletOverflow) < 0 && (
                    <Tooltip
                      title={
                        <>
                          Negative load overflow must only be used with cells that have a grill.
                          <br />
                          <br />
                          In certain configuration, the 3D camera may hit the beam.
                        </>
                      }
                    >
                      <WarningIcon color="warning" />
                    </Tooltip>
                  )}
                  <InputAdornment position="end">mm</InputAdornment>
                </>
              ),
            }}
            inputProps={{
              step: 10,
              min: -100,
            }}
            onBlur={() => {
              if (onChangeFocusedInputs) onChangeFocusedInputs(undefined);
              handleBlurNumberField(palletOverflow, setPalletOverflow, cellTemplate.palletOverflow);
            }}
            onKeyDown={(e) =>
              handleKeyPressNumberField(e, palletOverflow, setPalletOverflow, cellTemplate.palletOverflow)
            }
            focused={focusedInputs === 'loadOverflow' ? true : undefined}
            onFocus={() => onChangeFocusedInputs && onChangeFocusedInputs('loadOverflow')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={
              <>
                Load Length <HelpIconTooltip title="Length of the pallet." />
              </>
            }
            type="number"
            value={loadLength}
            fullWidth
            size="small"
            onChange={(e) => setLoadLength(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            inputProps={{
              step: 10,
              min: 0,
            }}
            onBlur={() =>
              handleBlurNumberField(
                loadLength,
                setLoadLength,
                cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 1.2
              )
            }
            onKeyDown={(e) =>
              handleKeyPressNumberField(
                e,
                loadLength,
                setLoadLength,
                cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 1.2
              )
            }
          />
        </Grid>
      </Grid>

      <Expand label="Additional Parameters" sx={{ paddingTop: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={2} paddingTop={2}>
          <Grid item xs={6}>
            <TextField
              label={
                <>
                  Left Obstacle <PreviewHelper />
                </>
              }
              type="number"
              value={leftObstacle}
              fullWidth
              sx={{ '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'dashed' } }}
              size="small"
              onChange={(e) => setLeftObstacle(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: 0,
              }}
              onBlur={() => handleBlurNumberField(leftObstacle, setLeftObstacle, cellTemplate.leftObstacle)}
              onKeyDown={(e) => handleKeyPressNumberField(e, leftObstacle, setLeftObstacle, cellTemplate.leftObstacle)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={
                <>
                  Right Obstacle <PreviewHelper />
                </>
              }
              type="number"
              value={rightObstacle}
              fullWidth
              sx={{ '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'dashed' } }}
              size="small"
              onChange={(e) => setRightObstacle(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: 0,
              }}
              onBlur={() => handleBlurNumberField(rightObstacle, setRightObstacle, cellTemplate.rightObstacle)}
              onKeyDown={(e) =>
                handleKeyPressNumberField(e, rightObstacle, setRightObstacle, cellTemplate.rightObstacle)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Beam distance to rack front
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>beamDistToRackFront</strong>
                        <br />
                        Offset between the front face and the horizontal beam seen by perception. If hollowed beam (IPN)
                        use positive value to increase the forks to beam distance. If bulked beam, use negative value.
                        The perception algorithm uses this value. (default: 0.00 [m])
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={beamDistanceToRackFront}
              fullWidth
              size="small"
              onChange={(e) => setBeamDistanceToRackFront(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: beamDistanceToRackFrontMin * 1000,
                max: beamDistanceToRackFrontMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  beamDistanceToRackFront,
                  setBeamDistanceToRackFront,
                  cellTemplate.beamDistanceToRackFront ?? 0,
                  {
                    min: beamDistanceToRackFrontMin,
                    max: beamDistanceToRackFrontMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  beamDistanceToRackFront,
                  setBeamDistanceToRackFront,
                  cellTemplate.beamDistanceToRackFront ?? 0,
                  {
                    min: beamDistanceToRackFrontMin,
                    max: beamDistanceToRackFrontMax,
                  }
                )
              }
              sx={{
                marginBottom: theme.spacing(1),
              }}
            />
          </Grid>
        </Grid>
      </Expand>
    </>
  );
}

export interface PerceptionCellTemplateData {
  startHeight: string;
  startDepth: string;
  beamUpMarginObstacle: string;
  minimumHeightVerification: string;
  minimumVerticalClearanceForDrop: string;
  freeSpaceMinObjectSize: string;
  minimumLateralClearanceForDrop: string;
  minimumDepthClearanceForDrop: string;
  beamFaceXTolerance: string;
  uprightYTolerance: string;
  beamHeightZTolerance: string;
  overhangTolerance: string;
  initialSolution: [string, string, string];
  rejectPercentage: {
    model: string;
    feet: string;
    pockets: string;
  };
  minObstacleMultiplier: string;
  cameraProjectionBeforeInspectionPlane: boolean;
  horizontalProjectionOnInspectionPlane: boolean;
  horizontalProjection: boolean;
  flyingObjectDetection: boolean;
  activateCrossShapedMethod: boolean;
  relPickActivateCorr: {
    depth: 0 | 1;
    lift: 0 | 1;
    shift: 0 | 1;
  };
  relDropActivateCorr: {
    depth: 0 | 1 | 2;
    lift: 0 | 1 | 2;
    shift: 0 | 1 | 2;
  };
  rangefinderDetection: boolean;
  enablePerceptionPick: boolean;
  enablePerceptionDrop: boolean;
}
interface CellTemplateParametersPerceptionProps {
  cellTemplate: CellTemplate;
  handleBlurNumberField: (
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    fallbackValue: number,
    options?: { min?: number; max?: number; unitFactor?: number; toFixedValue?: number }
  ) => void;
  handleKeyPressNumberField: (
    e: React.KeyboardEvent<HTMLDivElement>,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    fallbackValue: number,
    options?: { min?: number; max?: number; unitFactor?: number; toFixedValue?: number }
  ) => void;
  perceptionDataRef: React.MutableRefObject<PerceptionCellTemplateData | undefined>;
  updateCellTemplate: () => void;
  /** fonction to call to change the pickOrDropMode */
  setPickOrDropMode: React.Dispatch<React.SetStateAction<PickOrDrop>>;
}
export function CellTemplateParametersPerception({
  handleBlurNumberField,
  handleKeyPressNumberField,
  cellTemplate,
  perceptionDataRef,
  updateCellTemplate,
  setPickOrDropMode,
}: CellTemplateParametersPerceptionProps): JSX.Element {
  const [startHeight, setStartHeight] = useState((cellTemplate.perception.startHeight * 1000).toFixed(0));
  const [startDepth, setStartDepth] = useState((cellTemplate.perception.startDepth * 1000).toFixed(0));
  const [minimumHeightVerification, setMinimumHeightVerification] = useState(
    (cellTemplate.perception.minimumHeightVerification * 1000).toFixed(0)
  );
  const [minimumVerticalClearanceForDrop, setMinimumVerticalClearanceForDrop] = useState(
    (cellTemplate.perception.minimumVerticalClearanceForDrop * 1000).toFixed(0)
  );
  const [freeSpaceMinObjectSize, setFreeSpaceMinObjectSize] = useState(
    (cellTemplate.perception.freeSpaceMinObjectSize * 1000).toFixed(0)
  );
  const [minimumLateralClearanceForDrop, setMinimumLateralClearanceForDrop] = useState(
    (cellTemplate.perception.minimumLateralClearanceForDrop * 1000).toFixed(0)
  );
  const [minimumDepthClearanceForDrop, setMinimumDepthClearanceForDrop] = useState(
    (cellTemplate.perception.minimumDepthClearanceForDrop * 1000).toFixed(0)
  );
  const [beamFaceXTolerance, setBeamFaceXTolerance] = useState(
    (cellTemplate.perception.beamFaceXTolerance * 1000).toFixed(0)
  );
  const [uprightYTolerance, setUprightYTolerance] = useState(
    (cellTemplate.perception.uprightYTolerance * 1000).toFixed(0)
  );
  const [beamHeightZTolerance, setBeamHeightZTolerance] = useState(
    (cellTemplate.perception.beamHeightZTolerance * 1000).toFixed(0)
  );
  const [overhangTolerance, setOverhangTolerance] = useState(
    (cellTemplate.perception.overhangTolerance * 1000).toFixed(0)
  );
  const [initialSolution, setInitialSolution] = useState(
    cellTemplate.perception.initialSolution.map((s) => (s * 1000).toFixed(0))
  );
  const [rejectPercentage, setRejectPercentage] = useState({
    model: (cellTemplate.perception.rejectPercentage.model * 100).toFixed(0),
    feet: (cellTemplate.perception.rejectPercentage.feet * 100).toFixed(0),
    pockets: (cellTemplate.perception.rejectPercentage.pockets * 100).toFixed(0),
  } as {
    model: string;
    feet: string;
    pockets: string;
  });
  const beamUpMarginObstacleDefault = toString((getDefaultBeamUpMarginObstacle() * 1000).toFixed(0));
  const [minObstacleMultiplier, setMinObstacleMultiplier] = useState(
    (cellTemplate.perception.minObstacleMultiplier * 1).toFixed(2)
  );
  const [cameraProjectionBeforeInspectionPlane, setCameraProjectionBeforeInspectionPlane] = useState(
    cellTemplate.perception.cameraProjectionBeforeInspectionPlane
  );
  const [horizontalProjectionOnInspectionPlane, setHorizontalProjectionOnInspectionPlane] = useState(
    cellTemplate.perception.horizontalProjectionOnInspectionPlane
  );
  const [horizontalProjection, setHorizontalProjection] = useState(cellTemplate.perception.horizontalProjection);
  const [flyingObjectDetection, setFlyingObjectDetection] = useState(cellTemplate.perception.flyingObjectDetection);
  const [activateCrossShapedMethod, setActivateCrossShapedMethod] = useState(
    cellTemplate.perception.activateCrossShapedMethod
  );
  const [relPickActivateCorr, setRelPickActivateCorr] = useState(cellTemplate.perception.relPickActivateCorr);
  const [relDropActivateCorr, setRelDropActivateCorr] = useState(cellTemplate.perception.relDropActivateCorr);
  const [rangefinderDetection, setRangefinderDetection] = useState(cellTemplate.perception.rangefinderDetection);
  const [enablePerceptionPick, setEnablePerceptionPick] = useState(cellTemplate.perception.enablePerceptionPick);
  const [enablePerceptionDrop, setEnablePerceptionDrop] = useState(cellTemplate.perception.enablePerceptionDrop);
  const [beamUpMarginObstacle, setBeamUpMarginObstacle] = useState(
    cellTemplate.perception && cellTemplate.perception.beamUpMarginObstacle
      ? (cellTemplate.perception.beamUpMarginObstacle * 1000).toFixed(0)
      : beamUpMarginObstacleDefault
  );

  useEffect(() => {
    const value =
      cellTemplate.perception && cellTemplate.perception.beamUpMarginObstacle
        ? (cellTemplate.perception.beamUpMarginObstacle * 1000).toFixed(0)
        : beamUpMarginObstacleDefault;

    setBeamUpMarginObstacle(value);
  }, [
    beamUpMarginObstacleDefault,
    cellTemplate.perception,
    cellTemplate.perception.beamUpMarginObstacle,
    updateCellTemplate,
  ]);
  useEffect(() => {
    setStartHeight((cellTemplate.perception.startHeight * 1000).toFixed(0));
  }, [cellTemplate.perception.startHeight]);
  useEffect(() => {
    setStartDepth((cellTemplate.perception.startDepth * 1000).toFixed(0));
  }, [cellTemplate.perception.startDepth]);
  useEffect(() => {
    setMinimumHeightVerification((cellTemplate.perception.minimumHeightVerification * 1000).toFixed(0));
  }, [cellTemplate.perception.minimumHeightVerification]);
  useEffect(() => {
    setMinimumVerticalClearanceForDrop((cellTemplate.perception.minimumVerticalClearanceForDrop * 1000).toFixed(0));
  }, [cellTemplate.perception.minimumVerticalClearanceForDrop]);
  useEffect(() => {
    setFreeSpaceMinObjectSize((cellTemplate.perception.freeSpaceMinObjectSize * 1000).toFixed(0));
  }, [cellTemplate.perception.freeSpaceMinObjectSize]);
  useEffect(() => {
    setMinimumLateralClearanceForDrop((cellTemplate.perception.minimumLateralClearanceForDrop * 1000).toFixed(0));
  }, [cellTemplate.perception.minimumLateralClearanceForDrop]);
  useEffect(() => {
    setMinimumDepthClearanceForDrop((cellTemplate.perception.minimumDepthClearanceForDrop * 1000).toFixed(0));
  }, [cellTemplate.perception.minimumDepthClearanceForDrop]);
  useEffect(() => {
    setBeamFaceXTolerance((cellTemplate.perception.beamFaceXTolerance * 1000).toFixed(0));
  }, [cellTemplate.perception.beamFaceXTolerance]);
  useEffect(() => {
    setUprightYTolerance((cellTemplate.perception.uprightYTolerance * 1000).toFixed(0));
  }, [cellTemplate.perception.uprightYTolerance]);
  useEffect(() => {
    setBeamHeightZTolerance((cellTemplate.perception.beamHeightZTolerance * 1000).toFixed(0));
  }, [cellTemplate.perception.beamHeightZTolerance]);
  useEffect(() => {
    setOverhangTolerance((cellTemplate.perception.overhangTolerance * 1000).toFixed(0));
  }, [cellTemplate.perception.overhangTolerance]);
  useEffect(() => {
    setInitialSolution(cellTemplate.perception.initialSolution.map((s) => (s * 1000).toFixed(0)));
  }, [cellTemplate.perception.initialSolution]);
  useEffect(() => {
    setRejectPercentage({
      model: (cellTemplate.perception.rejectPercentage.model * 100).toFixed(0),
      feet: (cellTemplate.perception.rejectPercentage.feet * 100).toFixed(0),
      pockets: (cellTemplate.perception.rejectPercentage.pockets * 100).toFixed(0),
    } as {
      model: string;
      feet: string;
      pockets: string;
    });
  }, [
    cellTemplate.perception.rejectPercentage.feet,
    cellTemplate.perception.rejectPercentage.model,
    cellTemplate.perception.rejectPercentage.pockets,
  ]);
  useEffect(() => {
    setMinObstacleMultiplier((cellTemplate.perception.minObstacleMultiplier * 1).toFixed(2));
  }, [cellTemplate.perception.minObstacleMultiplier]);
  useEffect(() => {
    setCameraProjectionBeforeInspectionPlane(cellTemplate.perception.cameraProjectionBeforeInspectionPlane);
  }, [cellTemplate.perception.cameraProjectionBeforeInspectionPlane]);
  useEffect(() => {
    setHorizontalProjectionOnInspectionPlane(cellTemplate.perception.horizontalProjectionOnInspectionPlane);
  }, [cellTemplate.perception.horizontalProjectionOnInspectionPlane]);
  useEffect(() => {
    setHorizontalProjection(cellTemplate.perception.horizontalProjection);
  }, [cellTemplate.perception.horizontalProjection]);
  useEffect(() => {
    setFlyingObjectDetection(cellTemplate.perception.flyingObjectDetection);
  }, [cellTemplate.perception.flyingObjectDetection]);
  useEffect(() => {
    setActivateCrossShapedMethod(cellTemplate.perception.activateCrossShapedMethod);
  }, [cellTemplate.perception.activateCrossShapedMethod]);
  useEffect(() => {
    setRelPickActivateCorr(cellTemplate.perception.relPickActivateCorr);
  }, [cellTemplate.perception.relPickActivateCorr]);
  useEffect(() => {
    setRelDropActivateCorr(cellTemplate.perception.relDropActivateCorr);
  }, [cellTemplate.perception.relDropActivateCorr]);
  useEffect(() => {
    setRangefinderDetection(cellTemplate.perception.rangefinderDetection);
  }, [cellTemplate.perception.rangefinderDetection]);
  useEffect(() => {
    setEnablePerceptionPick(cellTemplate.perception.enablePerceptionPick);
  }, [cellTemplate.perception.enablePerceptionPick]);
  useEffect(() => {
    setEnablePerceptionDrop(cellTemplate.perception.enablePerceptionDrop);
  }, [cellTemplate.perception.enablePerceptionDrop]);

  useEffect(() => {
    if (perceptionDataRef) {
      const updatedBeamUpMarginObstacle = beamUpMarginObstacle || beamUpMarginObstacleDefault;
      perceptionDataRef.current = {
        startHeight,
        startDepth,
        minimumHeightVerification,
        beamUpMarginObstacle: updatedBeamUpMarginObstacle,
        minimumVerticalClearanceForDrop,
        freeSpaceMinObjectSize,
        minimumLateralClearanceForDrop,
        minimumDepthClearanceForDrop,
        beamFaceXTolerance,
        uprightYTolerance,
        beamHeightZTolerance,
        overhangTolerance,
        initialSolution: initialSolution as [string, string, string],
        rejectPercentage,
        minObstacleMultiplier,
        cameraProjectionBeforeInspectionPlane,
        horizontalProjectionOnInspectionPlane,
        horizontalProjection,
        flyingObjectDetection,
        activateCrossShapedMethod,
        relDropActivateCorr,
        relPickActivateCorr,
        rangefinderDetection,
        enablePerceptionPick,
        enablePerceptionDrop,
      };
    }
  }, [
    activateCrossShapedMethod,
    beamUpMarginObstacle,
    beamFaceXTolerance,
    beamHeightZTolerance,
    cameraProjectionBeforeInspectionPlane,
    enablePerceptionDrop,
    enablePerceptionPick,
    flyingObjectDetection,
    freeSpaceMinObjectSize,
    horizontalProjection,
    horizontalProjectionOnInspectionPlane,
    initialSolution,
    minObstacleMultiplier,
    minimumDepthClearanceForDrop,
    minimumHeightVerification,
    minimumLateralClearanceForDrop,
    minimumVerticalClearanceForDrop,
    overhangTolerance,
    perceptionDataRef,
    rangefinderDetection,
    rejectPercentage,
    relDropActivateCorr,
    relPickActivateCorr,
    startDepth,
    startHeight,
    uprightYTolerance,
    beamUpMarginObstacleDefault,
  ]);

  const startHeightMin = 0.1; // m
  const startHeightMax = 0.5; // m

  const startDepthMin = 0.02; // m
  const startDepthMax = 0.5; // m

  const minimumHeightVerificationMin = 0.1; // m
  const minimumHeightVerificationMax = 0.5; // m

  const minimumVerticalClearanceForDropMin = 0.05; // m
  const minimumVerticalClearanceForDropMax = 0.5; // m

  const freeSpaceMinObjectSizeMin = 0.02; // m
  const freeSpaceMinObjectSizeMax = 0.07; // m

  const minimumLateralClearanceForDropMin = 0.03; // m
  const minimumLateralClearanceForDropMax = 0.5; // m

  const minimumDepthClearanceForDropMin = 0.0; // m
  const minimumDepthClearanceForDropMax = 0.5; // m

  const beamFaceXToleranceMin = 0.03; // m
  const beamFaceXToleranceMax = 0.2; // m

  const uprightYToleranceMin = 0.03; // m
  const uprightYToleranceMax = 0.1; // m

  const beamHeightZToleranceMin = 0.03; // m
  const beamHeightZToleranceMax = 0.1; // m

  const overhangToleranceMin = 0.0; // m
  const overhangToleranceMax = 0.2; // m

  const initialSolutionMin = 0.0; // m
  const initialSolutionMax = 5.0; // m

  const rejectPercentageMin = 0; // [1]
  const rejectPercentageMax = 1; // [1]

  const minObstacleMultiplierMin = 1; // [1]
  const minObstacleMultiplierMax = 10; // [1]

  const [openedMenu, setOpenedMenu] = useState<'pick' | 'drop' | null>('pick');
  const onChangeOpenedMenuPick = useCallback(
    (newState: boolean) => {
      setOpenedMenu(newState ? 'pick' : null);
      if (newState) setPickOrDropMode('pick');
    },
    [setPickOrDropMode]
  );
  const onChangeOpenedMenuDrop = useCallback(
    (newState: boolean) => {
      setOpenedMenu(newState ? 'drop' : null);
      setPickOrDropMode(newState ? 'drop' : 'pick');
    },
    [setPickOrDropMode]
  );

  const isStandardMode = useAppSelector((state) => state.local.standardMode);

  return (
    <>
      <Expand
        label={<>Pick</>}
        forceOpenState={openedMenu === 'pick'}
        onChange={onChangeOpenedMenuPick}
        openByDefault={true}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={enablePerceptionPick} />}
                label={
                  <>
                    Use Perception on Pick
                    <CustomWidthHelpIconTooltip
                      placement={'top'}
                      title={<>Use camera 3D perception to recognize load to pick</>}
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setEnablePerceptionPick(!enablePerceptionPick);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
            <Divider textAlign="left">Offsets on research area</Divider>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={
                <>
                  X{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>initialSolutionX</strong> range
                        <br />
                        enlargement of the pallet research area. (min: 0; max: 5; default: X=0.1 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={InitialSolutionXImg}
                            alt="initialSolutionX diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={initialSolution[0]}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setInitialSolution((prev) => [e.target.value, prev[1], prev[2]])}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: initialSolutionMin * 1000,
                max: initialSolutionMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  initialSolution[0],
                  (val) => setInitialSolution((prev) => [val.toString(), prev[1], prev[2]]),
                  cellTemplate.perception.initialSolution[0],
                  {
                    min: initialSolutionMin,
                    max: initialSolutionMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  initialSolution[0],
                  (val) => setInitialSolution((prev) => [val.toString(), prev[1], prev[2]]),
                  cellTemplate.perception.initialSolution[0],
                  {
                    min: initialSolutionMin,
                    max: initialSolutionMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={
                <>
                  Y{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>initialSolutionY</strong> range
                        <br />
                        enlargement of the pallet research area. (min: 0; max: 5; default: Y=0.1 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={InitialSolutionYImg}
                            alt="initialSolutionY diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={initialSolution[1]}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setInitialSolution((prev) => [prev[0], e.target.value, prev[2]])}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: initialSolutionMin * 1000,
                max: initialSolutionMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  initialSolution[1],
                  (val) => setInitialSolution((prev) => [prev[0], val.toString(), prev[2]]),
                  cellTemplate.perception.initialSolution[1],
                  {
                    min: initialSolutionMin,
                    max: initialSolutionMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  initialSolution[1],
                  (val) => setInitialSolution((prev) => [prev[0], val.toString(), prev[2]]),
                  cellTemplate.perception.initialSolution[1],
                  {
                    min: initialSolutionMin,
                    max: initialSolutionMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={
                <>
                  Z{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>initialSolutionZ</strong> range
                        <br />
                        enlargement of the pallet research area. (min: 0; max: 5; default: Z=0.05 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={InitialSolutionZImg}
                            alt="initialSolutionZ diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={initialSolution[2]}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setInitialSolution((prev) => [prev[0], prev[1], e.target.value])}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: initialSolutionMin * 1000,
                max: initialSolutionMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  initialSolution[2],
                  (val) => setInitialSolution((prev) => [prev[0], prev[1], val.toString()]),
                  cellTemplate.perception.initialSolution[2],
                  {
                    min: initialSolutionMin,
                    max: initialSolutionMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  initialSolution[2],
                  (val) => setInitialSolution((prev) => [prev[0], prev[1], val.toString()]),
                  cellTemplate.perception.initialSolution[2],
                  {
                    min: initialSolutionMin,
                    max: initialSolutionMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
            <Divider textAlign="left">Load recognition validity thresholds</Divider>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={
                <>
                  Model{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'top'}
                    title={
                      <>
                        <strong>rejectPercentageModel</strong>
                        <br />
                        Minimum scores the detected load should match to be recognized. (min: 0, max: 1, default:0.6)
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={rejectPercentage.model}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setRejectPercentage((prev) => ({ ...prev, model: e.target.value }))}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{
                step: 1,
                min: rejectPercentageMin * 100,
                max: rejectPercentageMax * 100,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  rejectPercentage.model,
                  (val) => setRejectPercentage((prev) => ({ ...prev, model: val.toString() })),
                  cellTemplate.perception.rejectPercentage.model,
                  {
                    min: rejectPercentageMin,
                    max: rejectPercentageMax,
                    unitFactor: 100,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  rejectPercentage.model,
                  (val) => setRejectPercentage((prev) => ({ ...prev, model: val.toString() })),
                  cellTemplate.perception.rejectPercentage.model,
                  {
                    min: rejectPercentageMin,
                    max: rejectPercentageMax,
                    unitFactor: 100,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={
                <>
                  Feet{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'top'}
                    title={
                      <>
                        <strong>rejectPercentageFeet</strong>
                        <br />
                        Minimum scores the detected load should match to be recognized. (min: 0, max: 1, default: 0.6)
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={rejectPercentage.feet}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setRejectPercentage((prev) => ({ ...prev, feet: e.target.value }))}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{
                step: 1,
                min: rejectPercentageMin * 100,
                max: rejectPercentageMax * 100,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  rejectPercentage.feet,
                  (val) => setRejectPercentage((prev) => ({ ...prev, feet: val.toString() })),
                  cellTemplate.perception.rejectPercentage.feet,
                  {
                    min: rejectPercentageMin,
                    max: rejectPercentageMax,
                    unitFactor: 100,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  rejectPercentage.feet,
                  (val) => setRejectPercentage((prev) => ({ ...prev, feet: val.toString() })),
                  cellTemplate.perception.rejectPercentage.feet,
                  {
                    min: rejectPercentageMin,
                    max: rejectPercentageMax,
                    unitFactor: 100,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={
                <>
                  Pockets{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'top'}
                    title={
                      <>
                        <strong>rejectPercentagePockets</strong>Minimum scores the detected load should match to be
                        recognized. (min: 0, max: 1, default: 0.5)
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={rejectPercentage.pockets}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setRejectPercentage((prev) => ({ ...prev, pockets: e.target.value }))}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{
                step: 1,
                min: rejectPercentageMin * 100,
                max: rejectPercentageMax * 100,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  rejectPercentage.pockets,
                  (val) => setRejectPercentage((prev) => ({ ...prev, pockets: val.toString() })),
                  cellTemplate.perception.rejectPercentage.pockets,
                  {
                    min: rejectPercentageMin,
                    max: rejectPercentageMax,
                    unitFactor: 100,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  rejectPercentage.pockets,
                  (val) => setRejectPercentage((prev) => ({ ...prev, pockets: val.toString() })),
                  cellTemplate.perception.rejectPercentage.pockets,
                  {
                    min: rejectPercentageMin,
                    max: rejectPercentageMax,
                    unitFactor: 100,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left">Correction</Divider>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      variant={relPickActivateCorr.depth === 0 ? 'contained' : undefined}
                      onClick={() => {
                        setRelPickActivateCorr((state) => ({ ...state, depth: 0 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Disable
                    </Button>
                    <Button
                      variant={relPickActivateCorr.depth === 1 ? 'contained' : undefined}
                      onClick={() => {
                        setRelPickActivateCorr((state) => ({ ...state, depth: 1 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Absolute
                    </Button>
                  </ButtonGroup>
                }
                label={
                  <>
                    Depth
                    <HelpIconTooltip title="Enable depth corrections during the drop sequence on the given slot" />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      variant={relPickActivateCorr.shift === 0 ? 'contained' : undefined}
                      onClick={() => {
                        setRelPickActivateCorr((state) => ({ ...state, shift: 0 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Disable
                    </Button>
                    <Button
                      variant={relPickActivateCorr.shift === 1 ? 'contained' : undefined}
                      onClick={() => {
                        setRelPickActivateCorr((state) => ({ ...state, shift: 1 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Absolute
                    </Button>
                  </ButtonGroup>
                }
                label={
                  <>
                    Shift
                    <HelpIconTooltip title="Enable shift corrections during the drop sequence on the given slot" />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      variant={relPickActivateCorr.lift === 0 ? 'contained' : undefined}
                      onClick={() => {
                        setRelPickActivateCorr((state) => ({ ...state, lift: 0 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Disable
                    </Button>
                    <Button
                      variant={relPickActivateCorr.lift === 1 ? 'contained' : undefined}
                      onClick={() => {
                        setRelPickActivateCorr((state) => ({ ...state, lift: 1 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Absolute
                    </Button>
                  </ButtonGroup>
                }
                label={
                  <>
                    Lift
                    <HelpIconTooltip title="Enable lift corrections during the drop sequence on the given slot" />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left">Optional analysis</Divider>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={rangefinderDetection} />}
                label={
                  <>
                    Rangefinder Detection
                    <CustomWidthHelpIconTooltip
                      placement={'top'}
                      title={<>Check if the pallet is properly placed with the rangerfinder (telemeter)</>}
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setRangefinderDetection(!rangefinderDetection);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Expand>
      <Expand label={<>Drop</>} forceOpenState={openedMenu === 'drop'} onChange={onChangeOpenedMenuDrop}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={enablePerceptionDrop} />}
                label={
                  <>
                    Use Perception on Drop
                    <CustomWidthHelpIconTooltip
                      placement={'top'}
                      title={<>Use camera 3D perception to recognize empty space to drop loads</>}
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setEnablePerceptionDrop(!enablePerceptionDrop);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
            <Divider textAlign="left">Inspection Point Position</Divider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={
                <>
                  Point Height{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>freeSpaceStartHeight</strong>
                        <br />
                        Height between the beam top face and the Free Space inspection point. (min:0.1, max: 0.5,
                        default: 0.15) [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={StartHeightImg}
                            alt="freeSpaceStartHeight diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={startHeight}
              fullWidth
              sx={{ marginTop: theme.spacing(0) }}
              margin="none"
              size="small"
              onChange={(e) => setStartHeight(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: startHeightMin * 1000,
                max: startHeightMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(startHeight, setStartHeight, cellTemplate.perception.startHeight, {
                  min: startHeightMin,
                  max: startHeightMax,
                })
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(e, startHeight, setStartHeight, cellTemplate.perception.startHeight, {
                  min: startHeightMin,
                  max: startHeightMax,
                })
              }
              disabled={isStandardMode}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={
                <>
                  Point Depth{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>freeSpaceStartDepth</strong>
                        <br />
                        Height between the beam front face and the Free Space inspection point. (min: 0.02, max: 0.5,
                        default: 0.1 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={StartDepthImg}
                            alt="freeSpaceStartDepth diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={startDepth}
              fullWidth
              sx={{ marginTop: theme.spacing(0) }}
              margin="none"
              size="small"
              onChange={(e) => setStartDepth(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: startDepthMin * 1000,
                max: startDepthMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(startDepth, setStartDepth, cellTemplate.perception.startDepth, {
                  min: startDepthMin,
                  max: startDepthMax,
                })
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(e, startDepth, setStartDepth, cellTemplate.perception.startDepth, {
                  min: startDepthMin,
                  max: startDepthMax,
                })
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
            <Divider textAlign="left">Clearances</Divider>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: theme.spacing(1),
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <TextField
                label={
                  <>
                    Blind zone above beam
                    {
                      <HelpIconTooltip
                        title={
                          <>
                            <strong>beamUpMarginObstacle</strong>
                            <br />
                            Margin used into the freeSpace detection, it start to check the area at the height expected
                            + BeamUpMargin. The mission has to read this value when setting the detection parameters.
                            (default: 0,05 [m])
                          </>
                        }
                      />
                    }
                  </>
                }
                type="number"
                value={beamUpMarginObstacle}
                fullWidth
                sx={{}}
                margin="none"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  step: 10,
                }}
                onChange={(e) => setBeamUpMarginObstacle(e.target.value)}
                onBlur={() =>
                  handleBlurNumberField(
                    beamUpMarginObstacle,
                    setBeamUpMarginObstacle,
                    cellTemplate.perception.beamUpMarginObstacle ?? getDefaultBeamUpMarginObstacle()
                  )
                }
                onKeyDown={(e) =>
                  handleKeyPressNumberField(
                    e,
                    beamUpMarginObstacle,
                    setBeamUpMarginObstacle,
                    cellTemplate.perception.beamUpMarginObstacle ?? getDefaultBeamUpMarginObstacle()
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <>
                    Minimum FreeSpace Height{' '}
                    <CustomWidthHelpIconTooltip
                      placement={'right'}
                      title={
                        <>
                          <strong>minimumHeightVerification</strong>
                          <br />
                          Minimum height of the free space in the slot required to authorize a drop. (min: 0.1, max:
                          0.5, default: 0.2 [m])
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={MinimumHeightVerificationImg}
                              alt="minimumHeightVerification diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                type="number"
                value={minimumHeightVerification}
                fullWidth
                sx={{}}
                margin="none"
                size="small"
                onChange={(e) => setMinimumHeightVerification(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  step: 10,
                  min: minimumHeightVerificationMin * 1000,
                  max: minimumHeightVerificationMax * 1000,
                }}
                onBlur={() =>
                  handleBlurNumberField(
                    minimumHeightVerification,
                    setMinimumHeightVerification,
                    cellTemplate.perception.minimumHeightVerification,
                    {
                      min: minimumHeightVerificationMin,
                      max: minimumHeightVerificationMax,
                    }
                  )
                }
                onKeyDown={(e) =>
                  handleKeyPressNumberField(
                    e,
                    minimumHeightVerification,
                    setMinimumHeightVerification,
                    cellTemplate.perception.minimumHeightVerification,
                    {
                      min: minimumHeightVerificationMin,
                      max: minimumHeightVerificationMax,
                    }
                  )
                }
                disabled={isStandardMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <>
                    Depth Clearance (X){' '}
                    <CustomWidthHelpIconTooltip
                      placement={'right'}
                      title={
                        <>
                          <strong>minimumDepthClearanceForDrop</strong>
                          <br />
                          Required minimum clearance behind the load to authorize drop. (min: 0, max: 0.5, default: 0.02
                          [m])
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={DepthClearanceImg}
                              alt="minimumDepthClearanceForDrop diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                type="number"
                value={minimumDepthClearanceForDrop}
                fullWidth
                sx={{}}
                margin="none"
                size="small"
                onChange={(e) => setMinimumDepthClearanceForDrop(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  step: 10,
                  min: minimumDepthClearanceForDropMin * 1000,
                  max: minimumDepthClearanceForDropMax * 1000,
                }}
                onBlur={() =>
                  handleBlurNumberField(
                    minimumDepthClearanceForDrop,
                    setMinimumDepthClearanceForDrop,
                    cellTemplate.perception.minimumDepthClearanceForDrop,
                    {
                      min: minimumDepthClearanceForDropMin,
                      max: minimumDepthClearanceForDropMax,
                    }
                  )
                }
                onKeyDown={(e) =>
                  handleKeyPressNumberField(
                    e,
                    minimumDepthClearanceForDrop,
                    setMinimumDepthClearanceForDrop,
                    cellTemplate.perception.minimumDepthClearanceForDrop,
                    {
                      min: minimumDepthClearanceForDropMin,
                      max: minimumDepthClearanceForDropMax,
                    }
                  )
                }
                disabled={isStandardMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <>
                    Lateral Clearance (Y){' '}
                    <CustomWidthHelpIconTooltip
                      placement={'right'}
                      title={
                        <>
                          <strong>minimumLateralClearanceForDrop</strong>
                          <br />
                          Required minimum clearance on left and right sides of the load to authorize drop (min: 0.03,
                          max: 0.5, default: 0.04 [m])
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={LateralClearanceImg}
                              alt="minimumLateralClearanceForDrop diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                type="number"
                value={minimumLateralClearanceForDrop}
                fullWidth
                sx={{}}
                margin="none"
                size="small"
                onChange={(e) => setMinimumLateralClearanceForDrop(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  step: 10,
                  min: minimumLateralClearanceForDropMin * 1000,
                  max: minimumLateralClearanceForDropMax * 1000,
                }}
                onBlur={() =>
                  handleBlurNumberField(
                    minimumLateralClearanceForDrop,
                    setMinimumLateralClearanceForDrop,
                    cellTemplate.perception.minimumLateralClearanceForDrop,
                    {
                      min: minimumLateralClearanceForDropMin,
                      max: minimumLateralClearanceForDropMax,
                    }
                  )
                }
                onKeyDown={(e) =>
                  handleKeyPressNumberField(
                    e,
                    minimumLateralClearanceForDrop,
                    setMinimumLateralClearanceForDrop,
                    cellTemplate.perception.minimumLateralClearanceForDrop,
                    {
                      min: minimumLateralClearanceForDropMin,
                      max: minimumLateralClearanceForDropMax,
                    }
                  )
                }
                disabled={isStandardMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <>
                    Vertical Clearance (Z){' '}
                    <CustomWidthHelpIconTooltip
                      placement={'right'}
                      title={
                        <>
                          <strong>MinimalVerticalClearanceForDrop</strong>
                          <br />
                          Minimum clearance required on top of the load to authorize drop. (min: 0.05, max: 0.5,
                          default: 0.1 [m])
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={VerticalClearanceImg}
                              alt="MinimalVerticalClearanceForDrop diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                type="number"
                value={minimumVerticalClearanceForDrop}
                fullWidth
                sx={{}}
                margin="none"
                size="small"
                onChange={(e) => setMinimumVerticalClearanceForDrop(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SmallChip color={minimumVerticalClearanceForDropColor} />
                    </InputAdornment>
                  ),
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  step: 10,
                  min: minimumVerticalClearanceForDropMin * 1000,
                  max: minimumVerticalClearanceForDropMax * 1000,
                }}
                onBlur={() =>
                  handleBlurNumberField(
                    minimumVerticalClearanceForDrop,
                    setMinimumVerticalClearanceForDrop,
                    cellTemplate.perception.minimumVerticalClearanceForDrop,
                    {
                      min: minimumVerticalClearanceForDropMin,
                      max: minimumVerticalClearanceForDropMax,
                    }
                  )
                }
                onKeyDown={(e) =>
                  handleKeyPressNumberField(
                    e,
                    minimumVerticalClearanceForDrop,
                    setMinimumVerticalClearanceForDrop,
                    cellTemplate.perception.minimumVerticalClearanceForDrop,
                    {
                      min: minimumVerticalClearanceForDropMin,
                      max: minimumVerticalClearanceForDropMax,
                    }
                  )
                }
                disabled={isStandardMode}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
            <Divider textAlign="left">Circuit Position Tolerances</Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Tolerance Circuit vs. Perception (X){' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>beamFaceXTolerance</strong>
                        <br />
                        Maximum positioning error of the beam front face (min: 0.03, max: 0.2, default: 0.05 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={BeamFaceXToleranceImg}
                            alt="beamFaceXTolerance diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={beamFaceXTolerance}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setBeamFaceXTolerance(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: beamFaceXToleranceMin * 1000,
                max: beamFaceXToleranceMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  beamFaceXTolerance,
                  setBeamFaceXTolerance,
                  cellTemplate.perception.beamFaceXTolerance,
                  {
                    min: beamFaceXToleranceMin,
                    max: beamFaceXToleranceMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  beamFaceXTolerance,
                  setBeamFaceXTolerance,
                  cellTemplate.perception.beamFaceXTolerance,
                  {
                    min: beamFaceXToleranceMin,
                    max: beamFaceXToleranceMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Tolerance Circuit vs. Perception (Y){' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>uprightYTolerance</strong>
                        <br />
                        Maximum positioning error of the upright (min: 0.03, max: 0.1, default: 0.06 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={UprightYToleranceImg}
                            alt="uprightYTolerance diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={uprightYTolerance}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setUprightYTolerance(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: uprightYToleranceMin * 1000,
                max: uprightYToleranceMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  uprightYTolerance,
                  setUprightYTolerance,
                  cellTemplate.perception.uprightYTolerance,
                  {
                    min: uprightYToleranceMin,
                    max: uprightYToleranceMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  uprightYTolerance,
                  setUprightYTolerance,
                  cellTemplate.perception.uprightYTolerance,
                  {
                    min: uprightYToleranceMin,
                    max: uprightYToleranceMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Tolerance Circuit vs. Perception (Z){' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>beamHeightZTolerance</strong>
                        <br />
                        Maximum positioning error of the beam height (min: 0.03, max: 0.1, default: 0.05 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={BeamHeightZToleranceImg}
                            alt="beamHeightZTolerance diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={beamHeightZTolerance}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setBeamHeightZTolerance(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: beamHeightZToleranceMin * 1000,
                max: beamHeightZToleranceMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  beamHeightZTolerance,
                  setBeamHeightZTolerance,
                  cellTemplate.perception.beamHeightZTolerance,
                  {
                    min: beamHeightZToleranceMin,
                    max: beamHeightZToleranceMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  beamHeightZTolerance,
                  setBeamHeightZTolerance,
                  cellTemplate.perception.beamHeightZTolerance,
                  {
                    min: beamHeightZToleranceMin,
                    max: beamHeightZToleranceMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Overhang Tolerance{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>overhangTolerance</strong>
                        <br />
                        Maximum pallet overhang after drop (min: 0, max: 0.2, default: 0.05 [m])
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={OverhangToleranceImg}
                            alt="overhangTolerance diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={overhangTolerance}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setOverhangTolerance(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: overhangToleranceMin * 1000,
                max: overhangToleranceMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  overhangTolerance,
                  setOverhangTolerance,
                  cellTemplate.perception.overhangTolerance,
                  {
                    min: overhangToleranceMin,
                    max: overhangToleranceMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  overhangTolerance,
                  setOverhangTolerance,
                  cellTemplate.perception.overhangTolerance,
                  {
                    min: overhangToleranceMin,
                    max: overhangToleranceMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
            <Divider textAlign="left">Minimum Object Detection</Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Minimum Object Size
                  <HelpIconTooltip
                    title={
                      <>
                        <strong>freeSpaceMinObjectSize</strong>
                        <br />
                        Minimal diameter of objects to be detected (All smaller objects will be ignored) (min: 0.02,
                        max: 0.07, default: 0.04 [m])
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={freeSpaceMinObjectSize}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setFreeSpaceMinObjectSize(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              inputProps={{
                step: 10,
                min: freeSpaceMinObjectSizeMin * 1000,
                max: freeSpaceMinObjectSizeMax * 1000,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  freeSpaceMinObjectSize,
                  setFreeSpaceMinObjectSize,
                  cellTemplate.perception.freeSpaceMinObjectSize,
                  {
                    min: freeSpaceMinObjectSizeMin,
                    max: freeSpaceMinObjectSizeMax,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  freeSpaceMinObjectSize,
                  setFreeSpaceMinObjectSize,
                  cellTemplate.perception.freeSpaceMinObjectSize,
                  {
                    min: freeSpaceMinObjectSizeMin,
                    max: freeSpaceMinObjectSizeMax,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                <>
                  Multiplier{' '}
                  <CustomWidthHelpIconTooltip
                    placement={'right'}
                    title={
                      <>
                        <strong>minObstacleMultiplier</strong>
                        <br />
                        Defines the size of the cross while using cross-shape methods (bandwidth = Multiplier x
                        MinimumObjectSize). (min: 1, max: 10, default: 4)
                        <Paper
                          elevation={5}
                          sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                        >
                          <Box
                            component="img"
                            src={MinObstacleMultiplierImg}
                            alt="minObstacleMultiplier diagram"
                            sx={{ maxWidth: '100%' }}
                          />
                        </Paper>
                      </>
                    }
                  />
                </>
              }
              type="number"
              value={minObstacleMultiplier}
              fullWidth
              sx={{}}
              margin="none"
              size="small"
              onChange={(e) => setMinObstacleMultiplier(e.target.value)}
              InputProps={{
                endAdornment: undefined,
              }}
              inputProps={{
                step: 0.1,
                min: minObstacleMultiplierMin * 1,
                max: minObstacleMultiplierMax * 1,
              }}
              onBlur={() =>
                handleBlurNumberField(
                  minObstacleMultiplier,
                  setMinObstacleMultiplier,
                  cellTemplate.perception.minObstacleMultiplier,
                  {
                    min: minObstacleMultiplierMin,
                    max: minObstacleMultiplierMax,
                    unitFactor: 1,
                    toFixedValue: 2,
                  }
                )
              }
              onKeyDown={(e) =>
                handleKeyPressNumberField(
                  e,
                  minObstacleMultiplier,
                  setMinObstacleMultiplier,
                  cellTemplate.perception.minObstacleMultiplier,
                  {
                    min: minObstacleMultiplierMin,
                    max: minObstacleMultiplierMax,
                    unitFactor: 1,
                    toFixedValue: 2,
                  }
                )
              }
              disabled={isStandardMode}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left">Correction</Divider>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      variant={relDropActivateCorr.depth === 0 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, depth: 0 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Disable
                    </Button>
                    <Button
                      variant={relDropActivateCorr.depth === 1 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, depth: 1 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Absolute
                    </Button>
                    <Button
                      variant={relDropActivateCorr.depth === 2 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, depth: 2 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Relative
                    </Button>
                  </ButtonGroup>
                }
                label={
                  <>
                    Depth
                    <HelpIconTooltip title="Enable depth corrections during the drop sequence on the given slot" />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      variant={relDropActivateCorr.shift === 0 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, shift: 0 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Disable
                    </Button>
                    <Button
                      variant={relDropActivateCorr.shift === 1 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, shift: 1 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Absolute
                    </Button>
                    <Button
                      variant={relDropActivateCorr.shift === 2 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, shift: 2 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Relative
                    </Button>
                  </ButtonGroup>
                }
                label={
                  <>
                    Shift
                    <HelpIconTooltip title="Enable shift corrections during the drop sequence on the given slot" />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      variant={relDropActivateCorr.lift === 0 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, lift: 0 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Disable
                    </Button>
                    <Button
                      variant={relDropActivateCorr.lift === 1 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, lift: 1 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Absolute
                    </Button>
                    <Button
                      variant={relDropActivateCorr.lift === 2 ? 'contained' : undefined}
                      onClick={() => {
                        setRelDropActivateCorr((state) => ({ ...state, lift: 2 }));
                        setTimeout(() => updateCellTemplate(), 10);
                      }}
                    >
                      Relative
                    </Button>
                  </ButtonGroup>
                }
                label={
                  <>
                    Lift
                    <HelpIconTooltip title="Enable lift corrections during the drop sequence on the given slot" />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="center">Projections</Divider>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={cameraProjectionBeforeInspectionPlane} />}
                label={
                  <>
                    Camera Projection Before Inspection Plane{' '}
                    <CustomWidthHelpIconTooltip
                      placement={'right'}
                      title={
                        <>
                          <strong>CameraProjectionBeforeInspectionPlane</strong>
                          <br />
                          Activate / Deactivate the said projection. When this flag is True, the portion of point clouds
                          before the inspection plane, already cleaned by the forks, is projected on the Inspection
                          Plane using the camera projection based on the pin-hole model. (default: Deactivate)
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={CameraProjectionBeforeInspectionPlaneImg}
                              alt="CameraProjectionBeforeInspectionPlane diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setCameraProjectionBeforeInspectionPlane(!cameraProjectionBeforeInspectionPlane);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={horizontalProjectionOnInspectionPlane} />}
                label={
                  <>
                    Horizontal Projection On Inspection Plane{' '}
                    <CustomWidthHelpIconTooltip
                      placement={'top'}
                      title={
                        <>
                          <strong>horizontalProjectionOnInspectionPlane</strong>
                          <br />
                          Activate / Deactivate the said projection. When this flag is True and “Camera Projection
                          Before Inspection Plane“ is False the cloud points before the Inspection Plane are projected
                          to the Inspection Plane using horizontal projection (their Y and Z remain the same, only the X
                          value is increased to the Projection Plane). (default: Activate)
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={HorizontalProjectionOnInspectionPlaneImg}
                              alt="horizontalProjectionOnInspectionPlane diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setHorizontalProjectionOnInspectionPlane(!horizontalProjectionOnInspectionPlane);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={horizontalProjection} />}
                label={
                  <>
                    Horizontal Projection{' '}
                    <CustomWidthHelpIconTooltip
                      placement={'top'}
                      title={
                        <>
                          <strong>horizontalProjection</strong>
                          <br />
                          Activate / Deactivate the said projection. When this flag is True, we will use the “horizontal
                          projection” after the freeSpace analysis to eventually detect objects larger than “object
                          minimum size” under the carried pallet. (default: Deactivate)
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={HorizontalProjectionImg}
                              alt="horizontalProjection diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setHorizontalProjection(!horizontalProjection);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="center">Optional Diagnosis</Divider>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={activateCrossShapedMethod} />}
                label={
                  <>
                    Cross-shaped FreeSpace analysis{' '}
                    <HelpIconTooltip
                      title={
                        <>
                          <strong>activateCrossShapeMethod</strong>
                          <br />
                          Activate / Deactivate the switch from standard to “cross-shaped” freeSpace analysis. Changes
                          the geometry of the scanning area. (default: Deactivate){' '}
                        </>
                      }
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setActivateCrossShapedMethod(!activateCrossShapedMethod);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={flyingObjectDetection} />}
                label={
                  <>
                    “Flying Object” detection{' '}
                    <CustomWidthHelpIconTooltip
                      placement={'top'}
                      title={
                        <>
                          <strong>flyingObjectDetection</strong>
                          <br />
                          Activate / Deactivate the “Flying Object” diagnosis. Triggers an error anytime an obstacle is
                          seen on top of the inspection point. (default: Activate)
                          <Paper
                            elevation={5}
                            sx={{ textAlign: 'center', margin: theme.spacing(1), padding: theme.spacing(0.5) }}
                          >
                            <Box
                              component="img"
                              src={FlyingObjectDetectionImg}
                              alt="flyingObjectDetection diagram"
                              sx={{ maxWidth: '100%' }}
                            />
                          </Paper>
                        </>
                      }
                    />
                  </>
                }
                labelPlacement="start"
                sx={{
                  marginRight: 0,
                  justifyContent: 'space-between',
                }}
                onChange={() => {
                  setFlyingObjectDetection(!flyingObjectDetection);
                  setTimeout(() => updateCellTemplate(), 10);
                }}
                disabled={isStandardMode}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Expand>
    </>
  );
}

export type PickOrDrop = 'pick' | 'drop';

interface PalletRackPreviewProps {
  /** cell template */
  cellTemplate: CellTemplate;
  /** selected load of the cell template to be rendered */
  load: CellLoad;
  /** x position of the pallet in the cell [m] */
  x: number;
  /** y position of the pallet [m], default = 0 */
  y?: number;
  /** should we display a colored shadow around the pallets? */
  displayShadow?: boolean;
  disabled?: boolean;
  /** opacity of the pallets */
  opacity?: number;
}
export function PalletRackPreview({
  cellTemplate,
  load,
  x,
  y = 0,
  displayShadow = true,
  disabled = false,
  opacity,
}: PalletRackPreviewProps): JSX.Element {
  const w = load.W * 100;
  const heightBasePallet = 0.144; // m

  const filter =
    [
      displayShadow ? `drop-shadow(0px 0px 10px ${cellTemplate.color})` : undefined,
      disabled ? 'brightness(40%)' : undefined,
    ]
      .filter((f) => !!f)
      .join(';') || undefined;

  const maximumLoadHeight = cellTemplate.maximumLoadHeight ? cellTemplate.maximumLoadHeight : load.W;

  return (
    <>
      {maximumLoadHeight > heightBasePallet && (
        <image
          href={Load}
          width={w}
          height={(maximumLoadHeight - heightBasePallet) * 100}
          x={x * 100}
          y={(y - maximumLoadHeight) * 100}
          style={{ filter, opacity }}
          preserveAspectRatio="none"
        />
      )}
      <image
        href={PalletOnGround}
        width={w}
        height={heightBasePallet * 100}
        x={x * 100}
        y={(y - heightBasePallet) * 100}
        style={{ filter, opacity }}
        preserveAspectRatio="none"
      />
    </>
  );
}

interface SelectReferenceCellTemplateProps {
  perceptionRef: PerceptionReference;
  index: number;
  x: number;
  cellTemplate: CellTemplate;
  onChangeParameter: (param: string, value: number | PerceptionReference[]) => void;
  load: CellLoad;
}
export function SelectReferenceCellTemplate({
  perceptionRef,
  index,
  x,
  cellTemplate,
  onChangeParameter,
  load,
}: SelectReferenceCellTemplateProps): JSX.Element {
  const y = -(cellTemplate.height + 0.6) * 100;

  const handleChange = useCallback(
    (e: SelectChangeEvent<PerceptionReference>) => {
      const newValue = e.target.value;

      if (!isPerceptionReference(newValue)) {
        // eslint-disable-next-line no-console
        console.error(`Invalid value: ${newValue} is not of PerceptionReference type`);

        return;
      }

      const newReferences = [...load.references];
      newReferences[index] = newValue;

      onChangeParameter('load.references', newReferences);
    },
    [index, load.references, onChangeParameter]
  );

  const minWidth = 85; // px
  const scaleInput = 0.5;

  return (
    <foreignObject x={x * 100 + (minWidth * scaleInput) / 2} y={y} className="overflow-visible">
      <FormControl
        size="small"
        sx={{
          transform: `scale(${scaleInput})`,
          transformOrigin: 'left',
          minWidth: `${minWidth}px`,
        }}
      >
        <InputLabel id={`perception-ref-${index}`}>Reference</InputLabel>
        <Select labelId={`perception-ref-${index}`} label="Reference" value={perceptionRef} onChange={handleChange}>
          <MenuItem value="left" dense={true}>
            Left
          </MenuItem>
          <MenuItem value="right" dense={true}>
            Right
          </MenuItem>
          <MenuItem value="none" dense={true}>
            None
          </MenuItem>
          <MenuItem value="symmetrical" dense={true}>
            Symmetrical
          </MenuItem>
        </Select>
      </FormControl>
    </foreignObject>
  );
}

/**
 * A component to insert a diagonal hatch pattern in a svg
 * @returns the component
 */
function DiagonalHatchPattern(): JSX.Element {
  return (
    <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
      <path
        d="M-1,1 l2,-2
   M0,4 l4,-4
   M3,5 l2,-2"
        style={{
          stroke: theme.palette.secondary.light,
          strokeWidth: 1,
        }}
      />
    </pattern>
  );
}

/**
 * A component to insert defs related to the cell template in a svg
 * @returns the component
 */
export function DefsCellTemplateSvg(): JSX.Element {
  return (
    <defs>
      <marker
        id="arrow-axe"
        viewBox="0 0 10 10"
        refX="10"
        refY="5"
        markerWidth="6"
        markerHeight="6"
        orient="auto-start-reverse"
        fill={theme.palette.primary.main}
      >
        <path d="M 0 0 L 10 5 L 0 10 z" />
      </marker>

      <marker
        id="arrow-axe-small"
        viewBox="0 0 5 5"
        refX="5"
        refY="2.5"
        markerWidth="3"
        markerHeight="3"
        orient="auto-start-reverse"
        fill={theme.palette.primary.main}
      >
        <path d="M 0 0 L 5 2.5 L 0 5 z" />
      </marker>

      <DiagonalHatchPattern />
    </defs>
  );
}
