import * as turf from '@turf/turf';
import type { SelectTool, UpdateMapOpacity, UpdateObstacleOpacity } from 'actions';
import { MapsActionTypes, selectToolAction, updateMapOpacityAction, updateObstacleOpacityAction } from 'actions';
import type { SaveCellTemplate } from 'actions/cell-templates';
import { CellTemplateActionTypes, createCellTemplateSuccessAction } from 'actions/cell-templates';
import type {
  AddDevice,
  AddMeasurer,
  AddMultipleSegments,
  AddNote,
  AddPoint,
  AddRack,
  AddSegment,
  AddStockZone,
  AddTurn,
  AddZone,
  AlignElement,
  AskUpdateRack,
  AttachMeasurer,
  BringToBack,
  BringToFront,
  ChangeLockState,
  ChangeLockStateSelection,
  ChangeShapeVisibility,
  ClearShapesSelection,
  DeleteLayer,
  DeleteMultipleShapes,
  DeleteSelectedShapes,
  DetachMeasurer,
  ImportCircuit,
  ImportCircuitFailure,
  ImportCircuitSuccess,
  LoadCircuitFromYJS,
  MoveSelection,
  MultipleSegmentsMoved,
  PointMoved,
  SegmentMoved,
  SelectMultipleShapes,
  SelectShapesInRect,
  ShapeToSelectPayload,
  TransferToAnotherLayer,
  UnselectSeveralShapes,
  UpdateMeasurer,
  UpdateSegmentsPortions,
  UpdateTurn,
} from 'actions/circuit';
import {
  AddMultipleSegmentsAction,
  CircuitActionTypes,
  askUpdateRackAction,
  changeLockStateAction,
  clearShapesSelectionAction,
  deleteMultipleShapesAction,
  importCircuitFailureAction,
  importCircuitSuccessAction,
  multipleSegmentsMovedAction,
  pointMovedAction,
  saveCircuitToHistoryAction,
  segmentMovedAction,
  selectMultipleCircuitShapesAction,
  unselectSeveralCircuitShapesAction,
  updateMeasurerAction,
  updateTurnAction,
} from 'actions/circuit';
import type { CreateDeviceSuccess, DeleteDevice, DeleteDeviceSuccess, SaveDevice } from 'actions/devices';
import {
  DeviceActionTypes,
  createDeviceSuccessAction,
  deleteDeviceAction,
  deleteDeviceSuccessAction,
  saveDeviceAction,
} from 'actions/devices';
import type {
  CreateMeasurerFailure,
  CreateMeasurerSuccess,
  DeleteMeasurer,
  DeleteMeasurerSuccess,
  SaveMeasurer,
} from 'actions/measurers';
import {
  MeasurerActionTypes,
  createMeasurerFailureAction,
  createMeasurerSuccessAction,
  deleteMeasurerAction,
  deleteMeasurerSuccessAction,
  saveMeasurerAction,
} from 'actions/measurers';
import type { CreateNoteSuccess, DeleteNote, SaveNote } from 'actions/notes';
import {
  NotesActionTypes,
  createNoteSuccessAction,
  deleteNoteAction,
  deleteNoteSuccessAction,
  saveNoteAction,
} from 'actions/notes';
import type { CreatePointSuccess, DeletePoint, DeletePointSuccess, SavePoint } from 'actions/points';
import {
  PointsActionTypes,
  createPointSuccessAction,
  deletePointAction,
  deletePointSuccessAction,
  savePointAction,
} from 'actions/points';
import type {
  CreateRackFailure,
  CreateRackSuccess,
  DeleteRack,
  DeleteRackSuccess,
  SaveRack,
  SaveRackSuccess,
} from 'actions/racks';
import {
  RackActionTypes,
  createRackFailureAction,
  createRackSuccessAction,
  deleteRackAction,
  deleteRackSuccessAction,
  saveRackAction,
  saveRackSuccessAction,
} from 'actions/racks';
import type {
  CreateSegmentFailure,
  CreateSegmentSuccess,
  DeleteSegment,
  DeleteSegmentSuccess,
  SaveSegment,
} from 'actions/segment';
import {
  SegmentActionTypes,
  createSegmentFailureAction,
  createSegmentSuccessAction,
  deleteSegmentAction,
  deleteSegmentSuccessAction,
  saveSegmentAction,
} from 'actions/segment';
import type {
  CreateStockZoneFailure,
  CreateStockZoneSuccess,
  DeleteStockZone,
  SaveStockZone,
  SaveStockZoneSuccess,
} from 'actions/stock-zones';
import {
  StockZonesActionTypes,
  createStockZoneFailureAction,
  createStockZoneSuccessAction,
  deleteStockZoneAction,
  deleteStockZoneSuccessAction,
  saveStockZoneAction,
} from 'actions/stock-zones';
import type {
  CreateTurnFailure,
  CreateTurnSuccess,
  DeleteTurn,
  DeleteTurnSuccess,
  DeleteTurnSuccessMany,
  SaveManyTurnsSuccess,
  SaveTurn,
  SaveTurnFailure,
  SaveTurnSuccess,
} from 'actions/turns';
import {
  TurnActionTypes,
  createTurnFailureAction,
  createTurnSuccessAction,
  deleteTurnAction,
  deleteTurnSuccessManyAction,
  saveManyTurnsSuccessAction,
  saveTurnAction,
  saveTurnFailureAction,
} from 'actions/turns';
import type {
  CreateZoneFailure,
  CreateZoneSuccess,
  DeleteZone,
  DeleteZoneFailure,
  DeleteZoneSuccess,
  SaveZone,
  SaveZoneSuccess,
} from 'actions/zones';
import {
  ZonesActionTypes,
  createZoneFailureAction,
  createZoneSuccessAction,
  deleteZoneAction,
  deleteZoneFailureAction,
  deleteZoneSuccessAction,
  saveZoneAction,
} from 'actions/zones';
import { snackbarLockedSegmentExtended } from 'components/editor/snackbarLockedSegmentExtended';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import * as d3 from 'd3';
import { combineCopyPasteEpics } from 'drawings/copy-paste';
import {
  findShapeOrientation,
  findShortestDistanceBetweenSegments,
  getClosestPointAndLineInPolygon,
  getClosestPointInSegment,
  isPointInRect,
  pDistance,
  rotateCoordinates,
  rotatePolygon,
} from 'drawings/helpers';
import type { Feature, LineString as LineStringGeoJSON, Position } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { difference, differenceBy, groupBy, isEqual } from 'lodash';
import type {
  CircuitCellTemplate,
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitShape,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
  ComboxVersionType,
  MeasurerLinkedShape,
  NetworkType,
  RackCellTemplate,
  TurnProperties,
} from 'models/circuit';
import { Intersection, MeasurementType, ShapeTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import { MAX_EDITING_USERS, awareness, localDoc, projectHost, provider, remoteDoc } from 'multiplayer/globals';
import { connectRoom, setSynced } from 'multiplayer/multiplayer';
import type { SnackbarKey } from 'notistack';
import { setDescription, setDevicePrefManagement, setVersion } from 'project/project';
import {
  selectAllMeasurersEntities,
  selectAllPointsEntities,
  selectAllTurnsEntities,
} from 'reducers/circuit/selectors';
import type {
  CircuitState,
  LayerData,
  LayerGroupData,
  LoadedDevice,
  LoadedPoint,
  LoadedRack,
  LoadedSegment,
  LoadedStockZone,
  LoadedZone,
} from 'reducers/circuit/state';
import type { SetBackdropState } from 'reducers/core/reducer';
import { setBackdropStateAction, setLoadingStateAction } from 'reducers/core/reducer';
import type { ChangeOpacityFilter, ChangeStateFilter } from 'reducers/local/filters.reducer';
import { FilterActionTypes } from 'reducers/local/filters.reducer';
import { selectSelectedShapeData } from 'reducers/local/selectors';
import type { SelectedShapesData } from 'reducers/local/state';
import type { AppState } from 'reducers/state';
import type { Action } from 'redux';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import { ActionCreators } from 'redux-undo';
import type { Observable } from 'rxjs';
import { from, of } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { CircuitService, getMaxDisplayPriority, getMinDisplayPriority } from 'services/circuit.service';
import { getLoadedCircuitName } from 'services/project';
import { SnackbarUtils } from 'services/snackbar.service';
import { getTurnFeature } from 'services/turn.worker';
import type { UserProfileData } from 'shared';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import type { InOrOut } from 'utils/circuit/compute-portions-segment';
import { computePortionsOfSegment } from 'utils/circuit/compute-portions-segment';
import {
  defaultExtendedLength,
  getDefaultConveyorCellTemplate,
  getDefaultRackCellTemplate,
} from 'utils/circuit/default-circuit-shapes';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { defaultConveyorCellHeight, defaultConveyorStartHeight, defaultConveyorWidth } from 'utils/circuit/racks';
import {
  isCircuitDevice,
  isCircuitMeasurer,
  isCircuitNote,
  isCircuitPoint,
  isCircuitRack,
  isCircuitSegment,
  isCircuitStockZone,
  isCircuitZone,
} from 'utils/circuit/shape-guards';
import { updateStockZone } from 'utils/circuit/stockzones';
import { epsilon, polygonDistanceFrom } from 'utils/circuit/utils';
import { SEGMENT_ARROW_LENGTH, getConfig } from 'utils/config';
import { ZoneConveyorDeletionError } from 'utils/errors';
import { computeAutoExtendedLength, nbDigitsExtendedLength } from 'utils/extended-length';
import { toDigits, toRad } from 'utils/helpers';
import type {
  DeleteAction,
  DeleteSuccessAction,
  DeleteSuccessManyAction,
  EntityState,
  SaveSuccessAction,
} from 'utils/redux';
import { canBeUndefined, isDefined } from 'utils/ts/is-defined';
import { PreferencesService, convertBlobUrlToUInt8Array, loadFileAsUint8Array } from './../utils/preferences';
import { showSnackbarOnHiddenLayerDeletion$, updateLayer$ } from './layer.epic';

const MINIMUM_SHAPE_LENGTH = getConfig('editor').minimumShapeLength;
const snackbarTurnChangedAutoHideDuration = 4000; // ms

export function importCircuit$(
  actions$: ActionsObservable<any>
): Observable<ImportCircuitSuccess | ImportCircuitFailure> {
  return actions$.pipe(
    ofType<ImportCircuit>(CircuitActionTypes.ImportCircuit),
    mergeMap(({ payload }) =>
      CircuitService.circuitShapeSorting(payload.shapes).pipe(
        map((data) => importCircuitSuccessAction({ importedCircuit: data })),
        catchError((err) => of(importCircuitFailureAction(err)))
      )
    )
  );
}

export function addCircuitZone$(actions$: ActionsObservable<any>): Observable<CreateZoneSuccess | CreateZoneFailure> {
  return actions$.pipe(
    ofType<AddZone>(CircuitActionTypes.AddZone),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONZone(payload.coord, payload.zoneType, payload.layerId).pipe(
        map((data) => {
          const coord = payload.coord;
          const lengthDiag = getDistanceBetweenPoints(coord[0], coord[coord.length - 1]);

          if (lengthDiag >= MINIMUM_SHAPE_LENGTH) return createZoneSuccessAction(data);

          return createZoneFailureAction(
            new Error(
              `Zone is too small, actual size: ${lengthDiag.toPrecision(2)}, minimum size: ${MINIMUM_SHAPE_LENGTH}`
            ),
            true
          );
        })
      )
    )
  );
}

export function addCircuitRack$(actions$: ActionsObservable<any>): Observable<CreateRackSuccess | CreateRackFailure> {
  return actions$.pipe(
    ofType<AddRack>(CircuitActionTypes.AddRack),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONRack(payload.coord, payload.layerId, undefined, payload.orientation).pipe(
        map((rack) => {
          const coord = payload.coord;
          const lengthDiag = getDistanceBetweenPoints(coord[0], coord[coord.length - 1]);

          const storeState = store.getState();
          const cellTemplatesIds = storeState.circuit.present.cellTemplates.ids;
          const cellTemplates = storeState.circuit.present.cellTemplates.entities;
          const defaultCellTemplate = getDefaultRackCellTemplate();

          // when a project is loaded we get the most appropriate extended length for all the trucks of the project (max) otherwise we get the default value
          let extendedLength = defaultExtendedLength;
          try {
            if (PreferencesService.arePreferencesFullyLoaded()) {
              extendedLength = toDigits(computeAutoExtendedLength({}), nbDigitsExtendedLength, true);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
          }

          if (Math.abs(rack.properties.defaultExtendedLength - extendedLength) > epsilon) {
            rack.properties.defaultExtendedLength = extendedLength;

            rack.properties.columns.forEach((column) => {
              column.extendedLength = extendedLength;
            });
          }

          let isTheDefaultCellTemplateDefined = false;
          for (let i = 0; i < cellTemplatesIds.length; i++) {
            const cellTemplate = cellTemplates[cellTemplatesIds[i]];

            if (cellTemplate && !cellTemplate.forConveyor) {
              isTheDefaultCellTemplateDefined = true;
              break;
            }
          }

          if (!isTheDefaultCellTemplateDefined) {
            store.dispatch(createCellTemplateSuccessAction(defaultCellTemplate));
          }

          if (payload.isConveyor) {
            const defaultConveyorCellTemplate = getDefaultConveyorCellTemplate();
            let defaultCellTemplateId: string | undefined = undefined;
            for (let i = 0; i < cellTemplatesIds.length; i++) {
              const cellTemplate = canBeUndefined(cellTemplates[cellTemplatesIds[i]]);

              if (cellTemplate?.forConveyor) {
                defaultCellTemplateId = cellTemplate.id;
                break;
              }
            }

            if (!defaultCellTemplateId) {
              store.dispatch(
                createCellTemplateSuccessAction({
                  ...defaultConveyorCellTemplate,
                  id: generateShapeId(),
                })
              );

              defaultCellTemplateId = (
                Object.values(store.getState().circuit.present.cellTemplates.entities).find(
                  (cellTemplate) => cellTemplate?.forConveyor
                ) as CircuitCellTemplate | undefined
              )?.id;
            }

            if (!defaultCellTemplateId) {
              return createRackFailureAction(
                new Error(`Default conveyor cell template not created and thereby rack creation (conveyor) aborted`),
                false
              );
            }

            const zone: CircuitZone = {
              type: 'Feature',
              id: generateShapeId(),
              geometry: {
                type: 'Polygon',
                coordinates: [], // are defined again below
              },
              properties: {
                type: ShapeTypes.ZoneShape,
                intersectionType: Intersection.GabaritIntersection,
                layerId: rack.properties.layerId,
                name: `Conveyor zone ${rack.id}`,
                prio: getMaxDisplayPriority(),
                rules: [['Door'], ['LimitAgvCount', 1]],
                conveyor: rack.id as string,

                door: {
                  enabled: true,
                  devices: [],
                  dAsk: 3,
                  dStop: 0,
                },
              },
            };

            rack.properties.conveyor = {
              access: {
                enabled: false,
                dAsk: 0,
                dStop: 0,
                devices: [],
              },
              zone: zone.id as string,
            };

            const defaultColumnWidth = defaultConveyorWidth;
            const defaultCellHeight = defaultConveyorCellHeight;
            const defaultStartHeight = defaultConveyorStartHeight;

            rack.properties.name = `Conveyor${rack.id}`;
            rack.properties.defaultNbLevels = 1;
            rack.properties.defaultColumnWidth = defaultColumnWidth;
            rack.properties.defaultCellHeight = defaultCellHeight;

            rack.properties.columns = [rack.properties.columns[0]];
            const column = rack.properties.columns[0];
            column.nbLevels = 1;
            column.cells = [rack.properties.columns[0].cells[0]];
            column.startHeight = defaultStartHeight;
            column.linkedProperties.startHeight = false;
            column.width = defaultColumnWidth;

            const cell = column.cells[0];
            cell.cellTemplate = defaultCellTemplateId;
            cell.height = defaultCellHeight;

            // disable the uprights by default
            rack.properties.uprights[0].enabled = false;
            rack.properties.uprights[1].enabled = false;

            // recompute the geometry of the rack
            let actualLength = 0;
            rack.properties.columns.forEach((c) => (actualLength += c.width));
            rack.properties.uprights.forEach((upright) => (actualLength += upright.width));
            actualLength *= 100;

            const x1 = rack.geometry.coordinates[0][0][0];
            const y1 = rack.geometry.coordinates[0][0][1];
            const orientation = rack.properties.cap;
            const depth = rack.properties.depth * 100;
            const xx = x1 + actualLength;
            const yy = y1 - depth;
            let coordinates = [
              [x1, y1],
              [xx, y1],
              [xx, yy],
              [x1, yy],
              [x1, y1],
            ];

            if (orientation) {
              const rotationCenter = [x1, y1];
              [coordinates] = rotateCoordinates(rotationCenter, [coordinates], -orientation);
            }

            rack.geometry.coordinates = [coordinates];

            const distanceFromTheRack = 0.5; // in metres
            // we create a shape larger than the rack by a specified radius, and then take the envelope of this shape
            const zoneCoordinates = polygonDistanceFrom(rack.geometry.coordinates, distanceFromTheRack * 100);

            zone.geometry.coordinates = zoneCoordinates;
            store.dispatch(createZoneSuccessAction(zone));

            // put the conveyor in front of the zone
            rack.properties.prio = getMaxDisplayPriority();
          }

          if (lengthDiag >= MINIMUM_SHAPE_LENGTH) return createRackSuccessAction(rack);

          return createRackFailureAction(new Error(`Rack not created because too small`), true);
        })
      )
    )
  );
}

export function addCircuitStockZone$(
  actions$: ActionsObservable<any>
): Observable<CreateStockZoneSuccess | CreateStockZoneFailure | AddSegment> {
  return actions$.pipe(
    ofType<AddStockZone>(CircuitActionTypes.AddStockZone),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONStockZone(
        payload.coord,
        payload.stockZoneType,
        payload.layerId,
        payload.orientation
      ).pipe(
        map((stockZone) => {
          const coord = payload.coord;
          const lengthDiag = getDistanceBetweenPoints(coord[0], coord[coord.length - 1]);

          if (!stockZone.properties.length)
            return createStockZoneFailureAction(new Error(`Length of the stock zone is too small`), true);
          if (!stockZone.properties.width)
            return createStockZoneFailureAction(new Error(`Width of the stock zone is too small`), true);

          // when a project is loaded we get the most appropriate extended length for all the trucks of the project (max) otherwise we get the default value
          let extendedLength = defaultExtendedLength;
          try {
            if (PreferencesService.arePreferencesFullyLoaded()) {
              extendedLength = toDigits(
                computeAutoExtendedLength({
                  palletOverflow: 0, // no pallet overflow for stock zones
                  perceptionEnabled: false, // no perception for stock zones
                }),
                nbDigitsExtendedLength,
                true
              );
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
          }

          if (Math.abs(stockZone.properties.extendedLength - extendedLength) > epsilon) {
            stockZone.properties.extendedLength = extendedLength;
          }

          if (lengthDiag >= MINIMUM_SHAPE_LENGTH) return createStockZoneSuccessAction(stockZone);

          return createStockZoneFailureAction(new Error(`Stock zone not created because too small`), true);
        }),
        mergeMap((zoneAction) => {
          const actions: (CreateStockZoneSuccess | CreateStockZoneFailure)[] = [zoneAction];

          return actions;
        })
      )
    )
  );
}

export function addCircuitSegment$(
  actions$: ActionsObservable<any>
): Observable<CreateSegmentSuccess | CreateSegmentFailure> {
  return actions$.pipe(
    ofType<AddSegment>(CircuitActionTypes.AddSegment),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONSegment(
        payload.coord,
        payload.layerId,
        payload.stockLine,
        payload.rack && payload.rackColumn
          ? {
              rack: payload.rack,
              rackColumn: payload.rackColumn,
            }
          : undefined,
        payload.id
      ).pipe(
        map((data) => {
          const coord = data.geometry.coordinates;
          const lengthSegment = getDistanceBetweenPoints(coord[0], coord[1]);

          if (payload.locked) {
            data.properties.locked = true;
          }

          // if the segment is not a rack segment or a stock line segment, we prevent it from being created if it is too small
          if (lengthSegment > MINIMUM_SHAPE_LENGTH || data.properties.rack || data.properties.stockLine) {
            return createSegmentSuccessAction(data);
          }

          return createSegmentFailureAction(new Error(`Segment not created because too small`), true);
        })
      )
    )
  );
}

export function addCircuitMeasurer$(
  actions$: ActionsObservable<any>
): Observable<CreateMeasurerSuccess | CreateMeasurerFailure> {
  return actions$.pipe(
    ofType<AddMeasurer>(CircuitActionTypes.AddMeasurer),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONMeasurer(payload.coord, payload.layerId).pipe(
        map((data) => {
          const coord = data.geometry.coordinates;
          const length = getDistanceBetweenPoints(coord[0], coord[1]);

          if (length > MINIMUM_SHAPE_LENGTH) return createMeasurerSuccessAction(data);

          return createMeasurerFailureAction(new Error(`Measurer not created because too small`), true);
        })
      )
    )
  );
}

export function addCircuitPoint$(actions$: ActionsObservable<any>): Observable<CreatePointSuccess> {
  return actions$.pipe(
    ofType<AddPoint>(CircuitActionTypes.AddPoint),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONPoint(payload.coord, payload.angle, payload.layerId).pipe(
        map((data) => createPointSuccessAction(data))
      )
    )
  );
}

export function addCircuitDevice$(actions$: ActionsObservable<any>): Observable<CreateDeviceSuccess> {
  return actions$.pipe(
    ofType<AddDevice>(CircuitActionTypes.AddDevice),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONDevice(payload.coord, payload.deviceType, {
        IP: payload.IP,
        name: payload.name,
        displayName: payload.displayName,
        frequency: payload.frequency,
        layerId: payload.layerId,
        network: payload.network as NetworkType,
        comboxVersion: payload.comboxVersion as ComboxVersionType,
        gateway: payload.gateway,
      }).pipe(map((data) => createDeviceSuccessAction(data)))
    )
  );
}

export function addCircuitNotes$(actions$: ActionsObservable<any>): Observable<CreateNoteSuccess> {
  return actions$.pipe(
    ofType<AddNote>(CircuitActionTypes.AddNote),
    mergeMap(({ payload }) =>
      CircuitService.createGeoJSONNote(payload.coord, payload.type, payload.name, payload.prio).pipe(
        map((data) => createNoteSuccessAction(data))
      )
    )
  );
}

export function deleteSelectedShapes$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<DeleteSuccessAction | ClearShapesSelection | DeleteMultipleShapes | undefined> {
  return actions$.pipe(
    ofType<DeleteSelectedShapes>(CircuitActionTypes.DeleteSelectedShapes),
    withLatestFrom(state$),
    map(([, state]) => {
      const selectedShapes = selectSelectedShapeData(state);

      return selectedShapes;
    }),
    filter((shapes) => shapes.length > 0),
    switchMap((shapes) => {
      /**
       * Find zones associated to a conveyor that will be deleted
       */
      const conveyorsZonesToDelete = new Set();

      shapes.forEach((shape) => {
        const isRack = shape.type === ShapeTypes.RackShape;
        if (isRack) {
          const racks = store.getState().circuit.present.racks.entities;
          const rack = racks[shape.id];
          if (rack && rack.properties.conveyor) {
            conveyorsZonesToDelete.add(rack.properties.conveyor.zone);
          }
        }
      });

      const deleteShapeActions: (DeleteAction | undefined)[] = shapes
        .sort((a, b) => {
          // we remove firstly the turns for performance (otherwise, we detect that we have to delete the turn after deleting the segment and trigger a second delete turn action)
          // also nice for the ctrl+z
          if (b.type === ShapeTypes.TurnShape) return 1;

          return 0;
        })
        .map((elt) => {
          const shapeType = elt.type;

          switch (shapeType) {
            case ShapeTypes.ZoneShape: {
              if (conveyorsZonesToDelete.has(elt.id)) {
                // we do nothing because the zone will be automatically removed following the conveyor deletion
                return undefined;
              }

              return deleteZoneAction({ id: elt.id });
            }

            case ShapeTypes.StockZoneShape: {
              return deleteStockZoneAction({ id: elt.id });
            }

            case ShapeTypes.PointShape: {
              return deletePointAction({ id: elt.id });
            }

            case ShapeTypes.SegmentShape: {
              return deleteSegmentAction({ id: elt.id });
            }

            case ShapeTypes.MeasurerShape: {
              return deleteMeasurerAction({ id: elt.id });
            }

            case ShapeTypes.TurnShape: {
              return deleteTurnAction({ id: elt.id });
            }

            case ShapeTypes.RackShape: {
              return deleteRackAction({ id: elt.id });
            }

            case ShapeTypes.DeviceShape: {
              return deleteDeviceAction({ id: elt.id });
            }

            case ShapeTypes.NoteShape: {
              return deleteNoteAction({ id: elt.id });
            }

            default:
              // eslint-disable-next-line no-console
              console.error(`Unknown shape type: ${elt.type}`);

              assert<Equals<typeof shapeType, never>>();

              return undefined;
          }
        });

      const actions: (DeleteMultipleShapes | ClearShapesSelection)[] = [
        clearShapesSelectionAction(),
        deleteMultipleShapesAction({
          actions: deleteShapeActions.filter((elt): elt is DeleteAction => !!elt),
        }),
      ];

      return actions;
    })
  );
}

export function deleteShape$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<
  | DeleteTurnSuccess
  | DeleteTurnSuccessMany
  | DeleteSegmentSuccess
  | DeletePointSuccess
  | DeleteSegmentSuccess
  | DeleteMeasurerSuccess
  | DeleteZoneSuccess
  | DeleteZoneFailure
  | DeleteRackSuccess
  | SaveZone
  | SaveMeasurer
  | SaveDevice
  | SavePoint
  | DeleteMultipleShapes
  | void
> {
  return actions$.pipe(
    ofType<
      | DeleteTurn
      | DeleteSegment
      | DeletePoint
      | DeleteSegment
      | DeleteMeasurer
      | DeleteZone
      | DeleteStockZone
      | DeleteRack
      | DeleteDevice
      | DeleteNote
      | DeleteMultipleShapes
    >(
      TurnActionTypes.Delete,
      SegmentActionTypes.Delete,
      MeasurerActionTypes.Delete,
      PointsActionTypes.Delete,
      ZonesActionTypes.Delete,
      StockZonesActionTypes.Delete,
      RackActionTypes.Delete,
      DeviceActionTypes.Delete,
      NotesActionTypes.Delete,
      CircuitActionTypes.DeleteMultipleShapes
    ),
    switchMap((actionToProcess) => {
      const forceDelete = !!('force' in actionToProcess.payload && actionToProcess.payload.force);

      /* Unselect shape before deleting */
      if (store.getState().local.selectedShapesData.length) {
        store.dispatch(
          unselectSeveralCircuitShapesAction({
            unselectedShapes:
              'id' in actionToProcess.payload
                ? [
                    {
                      id: actionToProcess.payload.id,
                    },
                  ]
                : actionToProcess.payload.actions.map((action) => ({
                    id: action.payload.id,
                  })),
          })
        );
      }

      let resActions: (
        | DeleteSuccessAction
        | DeleteTurnSuccessMany
        | DeleteAction
        | ReturnType<typeof deleteTurnAction>
        | SaveZone
        | SaveMeasurer
        | SaveDevice
        | SavePoint
        | DeleteMultipleShapes
      )[] = [];

      const deleteActions = (
        'actions' in actionToProcess.payload ? actionToProcess.payload.actions : [actionToProcess]
      ).filter((act): act is DeleteAction => 'id' in act.payload);

      const turnsIdsToRemove = new Set<string>();

      deleteActions.forEach((action) => {
        const id = action.payload.id;

        let shape:
          | CircuitZone
          | CircuitPoint
          | CircuitSegment
          | CircuitMeasurer
          | CircuitStockZone
          | CircuitRack
          | CircuitDevice
          | CircuitNote
          | undefined; // CircuitShape \ CircuitTurn

        let resActionsForThisShape: typeof resActions | undefined;

        switch (action.type) {
          case TurnActionTypes.Delete: {
            // resActionsForThisShape = [deleteTurnSuccessAction(action.payload)];
            turnsIdsToRemove.add(id);
            break;
          }

          case SegmentActionTypes.Delete: {
            const segmentId = action.payload.id;

            // for the segments, we need to check if it has associated turns to delete them
            const actionsResSeg: (
              | DeleteSuccessAction
              | DeleteAction
              | ReturnType<typeof deleteTurnAction>
              | SaveMeasurer
              | SavePoint
            )[] = [deleteSegmentSuccessAction(action.payload)];
            const turns = state$.value.circuit.present.turns.entities;
            const turnIds = state$.value.circuit.present.turns.ids;

            // we remove all the turns connected to the segment
            turnIds.forEach((turnId) => {
              const turn = turns[turnId];
              if (turn.properties.originId === id || turn.properties.destinationId === id) {
                actionsResSeg.push(deleteTurnAction({ id: turn.id as string }));
              }
            });

            // we need to detach the measurers attached to the segment
            const measurers = state$.value.circuit.present.measurers.entities;
            const measurerIds = state$.value.circuit.present.measurers.ids;

            measurerIds.forEach((measurerId) => {
              const measurer = measurers[measurerId];
              const measurerProps = measurer.properties;

              // the measurer is attached to this very segment, we remove a link
              if (measurerProps.link0?.id === segmentId || measurerProps.link1?.id === segmentId) {
                actionsResSeg.push(
                  saveMeasurerAction({
                    id: measurerId,
                    properties: {
                      ...measurerProps,
                      link0: measurerProps.link0?.id === segmentId ? undefined : measurerProps.link0,
                      link1: measurerProps.link1?.id === segmentId ? undefined : measurerProps.link1,
                    },
                  })
                );
              }
            });

            // we need to detach the points attached to the segment
            const points = state$.value.circuit.present.points.entities;
            const pointIds = state$.value.circuit.present.points.ids;

            pointIds.forEach((pointId) => {
              const point = points[pointId];
              const pointProps = point.properties;

              // the point is attached to this very segment, we remove a link
              if (pointProps.segment?.id === segmentId) {
                actionsResSeg.push(
                  savePointAction({
                    id: pointId,
                    properties: {
                      ...pointProps,
                      segment: undefined,
                    },
                  })
                );
              }
            });

            resActionsForThisShape = actionsResSeg;
            shape = state$.value.circuit.present.segments.entities[id];
            break;
          }

          case MeasurerActionTypes.Delete: {
            shape = state$.value.circuit.present.measurers.entities[id];
            if (!shape) {
              // eslint-disable-next-line no-console
              console.error(`Measurer with id ${id} not found`);
              break;
            }

            resActionsForThisShape = [deleteMeasurerSuccessAction(action.payload)];
            break;
          }

          case PointsActionTypes.Delete: {
            shape = state$.value.circuit.present.points.entities[id];
            if (!shape) {
              // eslint-disable-next-line no-console
              console.error(`Point with id ${id} not found`);
              break;
            }

            const pointId = action.payload.id;
            const actionsRes: (DeleteSuccessAction | SaveMeasurer)[] = [deletePointSuccessAction(action.payload)];

            // we need to detach the measurers attached to the point
            const measurers = state$.value.circuit.present.measurers.entities;
            const measurerIds = state$.value.circuit.present.measurers.ids;

            measurerIds.forEach((measurerId) => {
              const measurer = measurers[measurerId];
              const measurerProps = measurer.properties;

              // the measurer is attached to this very point, we remove a link
              if (measurerProps.link0?.id === pointId || measurerProps.link1?.id === pointId) {
                actionsRes.push(
                  saveMeasurerAction({
                    id: measurerId,
                    properties: {
                      ...measurerProps,
                      link0: measurerProps.link0?.id === pointId ? undefined : measurerProps.link0,
                      link1: measurerProps.link1?.id === pointId ? undefined : measurerProps.link1,
                    },
                  })
                );
              }
            });

            resActionsForThisShape = actionsRes;
            break;
          }

          case ZonesActionTypes.Delete: {
            const zoneId = id;
            shape = state$.value.circuit.present.zones.entities[id];

            if (!shape) {
              // eslint-disable-next-line no-console
              console.error(`Zone with id ${id} not found`);
              break;
            }

            resActionsForThisShape = [];

            if ('conveyor' in shape.properties && shape.properties.conveyor) {
              resActions = [deleteZoneFailureAction(id, new ZoneConveyorDeletionError(), true)];
              break;
            }

            // we need to detach the measurers attached to the zone
            const measurers = state$.value.circuit.present.measurers.entities;
            const measurerIds = state$.value.circuit.present.measurers.ids;

            measurerIds.forEach((measurerId) => {
              const measurer = measurers[measurerId];
              const measurerProps = measurer.properties;

              // the measurer is attached to this very segment, we remove a link
              if (
                (measurerProps.link0?.id === zoneId || measurerProps.link1?.id === zoneId) &&
                resActionsForThisShape
              ) {
                resActionsForThisShape.push(
                  saveMeasurerAction({
                    id: measurerId,
                    properties: {
                      ...measurerProps,
                      link0: measurerProps.link0?.id === zoneId ? undefined : measurerProps.link0,
                      link1: measurerProps.link1?.id === zoneId ? undefined : measurerProps.link1,
                    },
                  })
                );
              }
            });

            resActionsForThisShape.push(deleteZoneSuccessAction(action.payload));
            break;
          }

          case StockZonesActionTypes.Delete: {
            const stockZone = state$.value.circuit.present.stockZones.entities[action.payload.id];
            if (!stockZone) {
              // eslint-disable-next-line no-console
              console.error(`Stockzone ${action.payload.id} not found for deletion`);
              break;
            }

            const stockZoneId = action.payload.id;

            const actionsResStockZone: (DeleteAction | DeleteSuccessAction | DeleteMultipleShapes | SaveMeasurer)[] = [
              deleteStockZoneSuccessAction({ id: action.payload.id }),
            ];
            const stockLinesIds = stockZone.properties.slots.map((slotLine) => slotLine.id);

            const segments = state$.value.circuit.present.segments.entities;
            const segmentsIds = state$.value.circuit.present.segments.ids;

            const deleteSegmentActions: DeleteAction[] = [];

            segmentsIds.forEach((segmentId) => {
              const segment = segments[segmentId];
              if (segment.properties.stockLine && stockLinesIds.includes(segment.properties.stockLine)) {
                deleteSegmentActions.push(deleteSegmentAction({ id: segment.id as string }));

                //stockLinesIds.splice(stockLinesIds.indexOf(segment.properties.stockLine), 1); // optimization for perf, can be removed
              }
            });

            actionsResStockZone.push(
              deleteMultipleShapesAction({
                actions: deleteSegmentActions,
                force: true,
              })
            );

            // we need to detach the measurers attached to the stock zone
            const measurers = state$.value.circuit.present.measurers.entities;
            const measurerIds = state$.value.circuit.present.measurers.ids;

            measurerIds.forEach((measurerId) => {
              const measurer = measurers[measurerId];
              const measurerProps = measurer.properties;

              // the measurer is attached to this very segment, we remove a link
              if (measurerProps.link0?.id === stockZoneId || measurerProps.link1?.id === stockZoneId) {
                actionsResStockZone.push(
                  saveMeasurerAction({
                    id: measurerId,
                    properties: {
                      ...measurerProps,
                      link0: measurerProps.link0?.id === stockZoneId ? undefined : measurerProps.link0,
                      link1: measurerProps.link1?.id === stockZoneId ? undefined : measurerProps.link1,
                    },
                  })
                );
              }
            });

            resActionsForThisShape = actionsResStockZone;
            shape = state$.value.circuit.present.stockZones.entities[id];
            break;
          }

          case RackActionTypes.Delete: {
            shape = state$.value.circuit.present.racks.entities[id];
            if (!shape) {
              // eslint-disable-next-line no-console
              console.error(`Rack with id ${id} not found`);
              break;
            }

            const actionsResRack: (
              | DeleteSegment
              | DeleteMultipleShapes
              | DeleteSuccessAction
              | DeleteRackSuccess
              | SaveMeasurer
            )[] = [deleteRackSuccessAction({ id: action.payload.id })];
            const rackId = action.payload.id;
            const rackName = shape.properties.name;

            const segmentsId = state$.value.circuit.present.segments.ids;
            const segments = state$.value.circuit.present.segments.entities;

            const deleteSegmentActions: DeleteAction[] = [];
            for (let i = 0; i < segmentsId.length; i++) {
              const segment = segments[segmentsId[i]];

              if (segment && segment.properties.rack === rackId) {
                deleteSegmentActions.push(deleteSegmentAction({ id: segment.id as string }));
              }
            }

            if (deleteSegmentActions.length) {
              actionsResRack.push(
                deleteMultipleShapesAction({
                  actions: deleteSegmentActions,
                  force: true,
                })
              );
            }

            if (shape.properties.conveyor) {
              // if it's a conveyor, we need to delete the associated zone
              const zoneId = shape.properties.conveyor.zone;
              const zone = state$.value.circuit.present.zones.entities[zoneId];
              if (zone) {
                actionsResRack.push(deleteZoneSuccessAction({ id: zoneId }));
              } else {
                // eslint-disable-next-line no-console
                console.error(`Zone ${zoneId} not found for deletion with the rack ${rackId} (${rackName})`);

                SnackbarUtils.error(
                  `The zone associated with the conveyor ${rackName} has not been peroperly deleted.`
                );
              }
            }

            // we need to detach the measurers attached to the rack
            const measurers = state$.value.circuit.present.measurers.entities;
            const measurerIds = state$.value.circuit.present.measurers.ids;

            measurerIds.forEach((measurerId) => {
              const measurer = measurers[measurerId];
              const measurerProps = measurer.properties;

              // the measurer is attached to this very segment, we remove a link
              if (measurerProps.link0?.id === rackId || measurerProps.link1?.id === rackId) {
                actionsResRack.push(
                  saveMeasurerAction({
                    id: measurerId,
                    properties: {
                      ...measurerProps,
                      link0: measurerProps.link0?.id === rackId ? undefined : measurerProps.link0,
                      link1: measurerProps.link1?.id === rackId ? undefined : measurerProps.link1,
                    },
                  })
                );
              }
            });

            resActionsForThisShape = actionsResRack;
            break;
          }

          case DeviceActionTypes.Delete: {
            shape = state$.value.circuit.present.devices.entities[id];

            if (!shape) {
              // eslint-disable-next-line no-console
              console.error(`Device with id ${id} not found`);
              break;
            }

            const actionsResDevice: (DeleteDeviceSuccess | SaveDevice | SaveZone)[] = [
              deleteDeviceSuccessAction({ id: action.payload.id }),
            ];

            // the device may be linked to a door, we have to check for that
            const zones = state$.value.circuit.present.zones.entities;
            const zonesIds = state$.value.circuit.present.zones.ids;

            for (let i = 0; i < zonesIds.length; i++) {
              const zone = zones[zonesIds[i]];
              const zoneProps = zone.properties;

              if (zoneProps.door) {
                const door = zoneProps.door;
                const doorDevices = door.devices;
                const doorDevicesIds = doorDevices.map((device) => device.deviceId);

                if (doorDevicesIds.includes(id)) {
                  const newDevices = doorDevices.filter((device) => device.deviceId !== id);
                  const newZone: typeof zone = {
                    ...zone,
                    properties: {
                      ...zoneProps,
                      door: {
                        ...door,
                        devices: newDevices,
                      },
                    },
                  };

                  actionsResDevice.push(saveZoneAction(newZone));

                  SnackbarUtils.info(
                    `The device ${shape.properties.name} was linked to the door ${zone.properties.name}, the link has been removed`
                  );
                }
              }
            }

            //the device may have some associated devices
            const devices = state$.value.circuit.present.devices.entities;
            const devicesIds = state$.value.circuit.present.devices.ids;

            for (let i = 0; i < devicesIds.length; i++) {
              const device = devices[devicesIds[i]];
              const deviceProps = device.properties;

              if (deviceProps.gateway && deviceProps.gateway === id) {
                const newDevice: typeof device = {
                  ...device,
                  properties: {
                    ...deviceProps,
                    gateway: undefined,
                  },
                };

                actionsResDevice.push(saveDeviceAction(newDevice));
              }
            }

            resActionsForThisShape = actionsResDevice;
            break;
          }

          case NotesActionTypes.Delete: {
            shape = state$.value.circuit.present.notes.entities[id];
            if (!shape) {
              // eslint-disable-next-line no-console
              console.error(`Note with id ${id} not found`);
              break;
            }

            resActionsForThisShape = [deleteNoteSuccessAction(action.payload)];
            break;
          }

          default: {
            // assert<Equals<typeof action.type, never>>();

            throw new Error(`Action type ${action.type} not catched by the switch`);
          }
        }

        // we don't want locked shapes to be deleted
        if (!forceDelete && shape && shape.properties.locked) {
          // we do not add the actions for this shape then
        } else if (resActionsForThisShape) {
          // we add the actions for this shape
          resActions.push(...resActionsForThisShape);
        }
      });

      if (turnsIdsToRemove.size) {
        resActions.unshift(
          deleteTurnSuccessManyAction({
            ids: Array.from(turnsIdsToRemove),
          })
        );
      }

      return resActions;
    })
  );
}

export function selectShapesInRect$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SelectMultipleShapes> {
  return actions$.pipe(
    ofType<SelectShapesInRect>(CircuitActionTypes.SelectShapesInRect),
    switchMap((action) => {
      const payload = action.payload;
      const { x, y, x2, y2 } = payload;
      const shapes = CircuitService.getShapes(CircuitService.getSelectedLayer());

      const shapesToSelect: ShapeToSelectPayload[] = [];

      const multiplayer = store.getState().multiplayer.multiplayer;
      const selectedShapesMap = remoteDoc?.getMap<string[]>('selectedShapes');
      const selectedShapes = Array.from(selectedShapesMap?.values() || []).flat();

      shapes.forEach((shape) => {
        // we don't select hidden shapes
        if (!shape || shape.hidden || !shape.properties || !shape.properties.type) return;
        if (
          multiplayer &&
          selectedShapes.length &&
          selectedShapes.find((selectedShapeId) => selectedShapeId === (shape.id as string))
        )
          return;

        const shapeTypeStoreProperty = CircuitService.getStorePropertyOfShapeType(shape.properties.type);
        const isShapeHiddenInFilter = !store.getState().local.filters[shapeTypeStoreProperty];
        if (isShapeHiddenInFilter) return;

        const shapeType = shape.properties.type;
        if (
          shapeType === ShapeTypes.PointShape ||
          shapeType === ShapeTypes.DeviceShape ||
          shapeType === ShapeTypes.NoteShape
        ) {
          if (isPointInRect((shape as CircuitPoint).geometry.coordinates, x, y, x2, y2)) {
            shapesToSelect.push({
              id: shape.id as string,
              type: shapeType,
              shape,
            });
          }
        } else if (
          shapeType === ShapeTypes.ZoneShape ||
          shapeType === ShapeTypes.StockZoneShape ||
          shapeType === ShapeTypes.RackShape
        ) {
          const coords = [...(shape as CircuitZone).geometry.coordinates].pop();
          if (!coords) return;

          if (coords.every((coord) => isPointInRect(coord, x, y, x2, y2))) {
            shapesToSelect.push({
              id: shape.id as string,
              type: shapeType,
              shape,
            });
          }
        } else if (
          shapeType === ShapeTypes.SegmentShape ||
          shapeType === ShapeTypes.TurnShape ||
          shapeType === ShapeTypes.MeasurerShape
        ) {
          // we don't select the stockline's hidden segments
          if (shapeType === ShapeTypes.SegmentShape && shape.properties.stockLine) return;

          const coords = [...(shape as CircuitSegment).geometry.coordinates];
          if (!coords) return;

          if (coords.every((coord) => isPointInRect(coord, x, y, x2, y2))) {
            shapesToSelect.push({
              id: shape.id as string,
              type: shapeType,
              shape,
            });
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Shape not handled', shape);
        }
      });

      return shapesToSelect.length ? [selectMultipleCircuitShapesAction(shapesToSelect)] : [];
    })
  );
}

export function changeLockState$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<
  | SaveZone
  | SaveSegment
  | SaveMeasurer
  | SavePoint
  | SaveTurn
  | SaveStockZone
  | SaveRack
  | SaveDevice
  | SaveNote
  | undefined
> {
  return actions$.pipe(
    ofType<ChangeLockState>(CircuitActionTypes.ChangeLockState),
    map(({ payload }) => {
      const shape = CircuitService.getShape(payload.idShape, payload.shapeType);
      const saveAction = getShapeSaveAction(payload.shapeType);

      if (saveAction && shape) {
        return saveAction({
          id: payload.idShape,
          geometry: shape.geometry,
          properties: { ...shape.properties, locked: payload.newLockState },
        });
      }
    })
  );
}

export function changeLockStateSelection$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<ChangeLockState> {
  return actions$.pipe(
    ofType<ChangeLockStateSelection>(CircuitActionTypes.ChangeLockStateSelection),
    withLatestFrom(state$),
    map(([action, state]) => {
      const selectedShapes = selectSelectedShapeData(state);

      return { action, selectedShapes };
    }),
    filter((data) => !!data.selectedShapes.length),
    switchMap((data) => {
      const actions: ChangeLockState[] = [];
      const action = data.action;
      const selectedShapes = data.selectedShapes;
      const newLockState = action.payload.newLockState;

      selectedShapes.forEach((shape) => {
        actions.push(
          changeLockStateAction({
            idShape: shape.id,
            newLockState,
            shapeType: shape.type,
          })
        );
      });

      return actions;
    })
  );
}

export function attachMeasurer$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveMeasurer | UpdateMeasurer> {
  return actions$.pipe(
    ofType<AttachMeasurer>(CircuitActionTypes.AttachMeasurer),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      store.dispatch(saveCircuitToHistoryAction());

      const payload = action.payload;
      const endPoint = payload.endPoint;
      const shapeToAttachId = payload.shapeToAttach;
      const measurer = state.circuit.present.measurers.entities[payload.measurerId];
      const coordsEndPoint = measurer.geometry.coordinates[endPoint];

      // we will try to find the closest element of the endpoint to attach it
      let minimumDistance = Infinity;
      const threshold = 10 * 100; // cm
      const segments = state.circuit.present.segments.entities;
      const segmentsIds = state.circuit.present.segments.ids;
      const points = state.circuit.present.points.entities;
      const pointsIds = state.circuit.present.points.ids;
      const racks = state.circuit.present.racks.entities;
      const racksIds = state.circuit.present.racks.ids;
      const zones = state.circuit.present.zones.entities;
      const zonesIds = state.circuit.present.zones.ids;
      const stockZones = state.circuit.present.stockZones.entities;
      const stockZonesIds = state.circuit.present.stockZones.ids;

      let shapeToAttach: LoadedSegment | LoadedPoint | LoadedRack | LoadedZone | LoadedStockZone | undefined;

      if (shapeToAttachId) {
        let segmentFound = false; // Flag to keep track of whether the segment has been found
        let rackFound = false;
        let zoneFound = false;
        let stockZoneFound = false;

        for (const rackId of racksIds) {
          const rack = racks[rackId];
          if (rackId === shapeToAttachId) {
            shapeToAttach = rack;
            rackFound = true; // Set the flag to true if the segment is found
            break;
          }
        }

        if (!rackFound) {
          for (const stockZoneId of stockZonesIds) {
            const stockZone = stockZones[stockZoneId];
            if (stockZoneId === shapeToAttachId) {
              shapeToAttach = stockZone;
              stockZoneFound = true; // Set the flag to true if the segment is found
              break;
            }
          }
        }

        if (!rackFound && !stockZoneFound) {
          for (const segmentId of segmentsIds) {
            const segment = segments[segmentId];
            if (segmentId === shapeToAttachId) {
              shapeToAttach = segment;
              segmentFound = true; // Set the flag to true if the segment is found
              break;
            }
          }
        }

        if (!rackFound && !segmentFound && !stockZoneFound) {
          for (const zoneId of zonesIds) {
            const zone = zones[zoneId];
            if (zoneId === shapeToAttachId) {
              shapeToAttach = zone;
              zoneFound = true; // Set the flag to true if the segment is found
              break;
            }
          }
        }

        if (!segmentFound && !rackFound && !zoneFound && !stockZoneFound) {
          // Only enter the second loop if the segment is not found
          for (const pointId of pointsIds) {
            const point = points[pointId];
            if (pointId === shapeToAttachId) {
              shapeToAttach = point;
              break;
            }
          }
        }
      } else {
        racksIds.forEach((rackId) => {
          const rack = racks[rackId];
          const coords = rack.geometry.coordinates[0];

          let d = pDistance(
            coordsEndPoint[0],
            coordsEndPoint[1],
            coords[0][0],
            coords[0][1],
            coords[1][0],
            coords[1][1]
          );

          //If the polygon is close, we compute a more precise distance
          if (d < threshold) {
            const closestLine = getClosestPointAndLineInPolygon(coordsEndPoint, rack).line;
            d = pDistance(
              coordsEndPoint[0],
              coordsEndPoint[1],
              closestLine[0][0],
              closestLine[0][1],
              closestLine[1][0],
              closestLine[1][1]
            );
          }

          if (d < minimumDistance) {
            minimumDistance = d;
            shapeToAttach = rack;
          }
        });

        stockZonesIds.forEach((stockZoneId) => {
          const stockZone = stockZones[stockZoneId];
          const coords = stockZone.geometry.coordinates[0];

          let d = pDistance(
            coordsEndPoint[0],
            coordsEndPoint[1],
            coords[0][0],
            coords[0][1],
            coords[1][0],
            coords[1][1]
          );

          //If the polygon is close, we compute a more precise distance
          if (d < threshold) {
            const closestLine = getClosestPointAndLineInPolygon(coordsEndPoint, stockZone).line;
            d = pDistance(
              coordsEndPoint[0],
              coordsEndPoint[1],
              closestLine[0][0],
              closestLine[0][1],
              closestLine[1][0],
              closestLine[1][1]
            );
          }

          if (d < minimumDistance) {
            minimumDistance = d;
            shapeToAttach = stockZone;
          }
        });

        segmentsIds.forEach((segmentId) => {
          const segment = segments[segmentId];
          const coords = segment.geometry.coordinates;

          const d = pDistance(
            coordsEndPoint[0],
            coordsEndPoint[1],
            coords[0][0],
            coords[0][1],
            coords[1][0],
            coords[1][1]
          );
          if (d < minimumDistance) {
            minimumDistance = d;
            shapeToAttach = segment;
          }
        });

        zonesIds.forEach((zoneId) => {
          const zone = zones[zoneId];
          const coords = zone.geometry.coordinates[0];

          let d = pDistance(
            coordsEndPoint[0],
            coordsEndPoint[1],
            coords[0][0],
            coords[0][1],
            coords[1][0],
            coords[1][1]
          );

          //If the polygon is close, we compute a more precise distance
          if (d < threshold) {
            const closestLine = getClosestPointAndLineInPolygon(coordsEndPoint, zone).line;
            d = pDistance(
              coordsEndPoint[0],
              coordsEndPoint[1],
              closestLine[0][0],
              closestLine[0][1],
              closestLine[1][0],
              closestLine[1][1]
            );
          }

          if (d < minimumDistance) {
            minimumDistance = d;
            shapeToAttach = zone;
          }
        });

        pointsIds.forEach((pointId) => {
          const point = points[pointId];
          const coords = point.geometry.coordinates;

          const d = getDistanceBetweenPoints(coordsEndPoint, coords);
          if (d < minimumDistance) {
            minimumDistance = d;
            shapeToAttach = point;
          }
        });
      }

      const res: Partial<CircuitMeasurer> = {
        id: payload.measurerId,
      };

      const isLockedLength = !!measurer.properties.lockedLength;
      const coordinates = measurer.geometry.coordinates;

      const measurerLength = getDistanceBetweenPoints(coordinates[0], coordinates[1]);
      const measurerAngleRad = toRad(findShapeOrientation(coordinates[0], coordinates[1]));

      const link0 = measurer.properties.link0;
      const link1 = measurer.properties.link1;

      const isConnectedToAPolygonShape = (
        link0: MeasurerLinkedShape | undefined,
        link1: MeasurerLinkedShape | undefined
      ): boolean => {
        if (
          link0?.type === 'RACK' ||
          link1?.type === 'RACK' ||
          link0?.type === 'ZONE' ||
          link1?.type === 'ZONE' ||
          link0?.type === 'STOCK' ||
          link1?.type === 'STOCK'
        ) {
          return true;
        }

        return false;
      };

      if (shapeToAttach) {
        res.geometry = structuredClone(measurer.geometry);

        if (isCircuitPoint(shapeToAttach)) {
          if (link0?.type === 'SEGMENT' || link1?.type === 'SEGMENT') {
            const segmentId = (link0?.type === 'SEGMENT' ? link0.id : link1?.id) as string;
            const segment = segments[segmentId];
            const endPointToMove = link0 ? 0 : 1;

            const [closestPoint] = getClosestPointInSegment(
              shapeToAttach.geometry.coordinates,
              segment.geometry.coordinates[0],
              segment.geometry.coordinates[1]
            );
            res.geometry.coordinates[endPointToMove] = closestPoint;
            res.geometry.coordinates[payload.endPoint] = (shapeToAttach as CircuitPoint).geometry.coordinates;
          } else if (isConnectedToAPolygonShape(link0, link1)) {
            const shapeId = (isConnectedToAPolygonShape(link0, undefined) ? link0?.id : link1?.id) as string;
            const shape = racks[shapeId] ?? zones[shapeId] ?? stockZones[shapeId];
            const endPointToMove = link0 ? 0 : 1;

            const closestPoint = getClosestPointAndLineInPolygon(shapeToAttach.geometry.coordinates, shape).point;
            res.geometry.coordinates[endPointToMove] = closestPoint;
            res.geometry.coordinates[payload.endPoint] = (shapeToAttach as CircuitPoint).geometry.coordinates;
          } else {
            res.geometry.coordinates[payload.endPoint] = (shapeToAttach as CircuitPoint).geometry.coordinates;
          }
        } else if (isCircuitSegment(shapeToAttach)) {
          if (measurer.properties.measurementType === MeasurementType.MinimumDistance) {
            if (link0?.type === 'SEGMENT' || link1?.type === 'SEGMENT') {
              const segmentId = (link0?.type === 'SEGMENT' ? link0.id : link1?.id) as string;
              const segment = segments[segmentId];
              const endPointToMove = link0 ? 0 : 1;

              const [, , closestSegmentSecondEndPoint] = getClosestPointInSegment(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach.geometry.coordinates[0],
                shapeToAttach.geometry.coordinates[1]
              );

              const [closestPoint] = getClosestPointInSegment(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach.geometry.coordinates[0],
                shapeToAttach.geometry.coordinates[1]
              );

              const [, P1, P2] = findShortestDistanceBetweenSegments(
                segment.geometry.coordinates[0],
                segment.geometry.coordinates[1],
                closestSegmentSecondEndPoint[0],
                closestSegmentSecondEndPoint[1],
                closestPoint
              );

              res.geometry.coordinates = [P1, P2];
            } else if (link0?.type === 'POINT' || link1?.type === 'POINT') {
              const [closestPoint] = getClosestPointInSegment(
                measurer.geometry.coordinates[payload.endPoint === 0 ? 1 : 0],
                shapeToAttach.geometry.coordinates[0],
                shapeToAttach.geometry.coordinates[1]
              );
              res.geometry.coordinates[payload.endPoint] = closestPoint;
            } else if (isConnectedToAPolygonShape(link0, link1)) {
              const shapeId = (isConnectedToAPolygonShape(link0, undefined) ? link0?.id : link1?.id) as string;
              const shape = racks[shapeId] ?? zones[shapeId] ?? stockZones[shapeId];
              const endPointToMove = link0 ? 0 : 1;

              const attachedLine = getClosestPointAndLineInPolygon(measurer.geometry.coordinates[endPoint], shape).line;

              const [, , closestSegmentSecondEndPoint] = getClosestPointInSegment(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach.geometry.coordinates[0],
                shapeToAttach.geometry.coordinates[1]
              );

              const [closestPoint] = getClosestPointInSegment(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach.geometry.coordinates[0],
                shapeToAttach.geometry.coordinates[1]
              );

              const [, P1, P2] = findShortestDistanceBetweenSegments(
                closestSegmentSecondEndPoint[0],
                closestSegmentSecondEndPoint[1],
                attachedLine[0],
                attachedLine[1],
                closestPoint
              );

              res.geometry.coordinates = [P1, P2];
            } else {
              // we get the new coordinates to minimize the distance
              const [closestPoint] = getClosestPointInSegment(
                measurer.geometry.coordinates[payload.endPoint === 0 ? 1 : 0],
                shapeToAttach.geometry.coordinates[0],
                shapeToAttach.geometry.coordinates[1]
              );
              res.geometry.coordinates[payload.endPoint] = closestPoint;
            }
          } else if (measurer.properties.measurementType === MeasurementType.Center2Center) {
            // the middle of the segment, i.e. the average of the two end points
            const coords = shapeToAttach.geometry.coordinates;
            res.geometry.coordinates[payload.endPoint][0] = (coords[0][0] + coords[1][0]) / 2;
            res.geometry.coordinates[payload.endPoint][1] = (coords[0][1] + coords[1][1]) / 2;
          } else {
            // eslint-disable-next-line no-console
            console.error('Measurement type not catched');
          }
        } else if (isCircuitRack(shapeToAttach) || isCircuitZone(shapeToAttach) || isCircuitStockZone(shapeToAttach)) {
          if (measurer.properties.measurementType === MeasurementType.MinimumDistance) {
            // we get the new coordinates to minimize the distance

            const shapeAttachItself = res.properties?.link0?.id === res.properties?.link1?.id;

            const otherEndPoint = endPoint === 0 ? 1 : 0;
            const endPointToMove = shapeAttachItself ? endPoint : otherEndPoint;

            if (link0?.type === 'SEGMENT' || link1?.type === 'SEGMENT') {
              const segmentId = (link0?.type === 'SEGMENT' ? link0.id : link1?.id) as string;
              const segment = segments[segmentId];
              const endPointToMove = link0 ? 0 : 1;

              const closestLine = getClosestPointAndLineInPolygon(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach
              ).line;

              const closestPoint = getClosestPointAndLineInPolygon(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach
              ).point;

              const [, P1, P2] = findShortestDistanceBetweenSegments(
                closestLine[0],
                closestLine[1],
                segment.geometry.coordinates[0],
                segment.geometry.coordinates[1],
                closestPoint
              );

              res.geometry.coordinates = [P1, P2];
            }

            if (link0?.type === 'POINT' || link1?.type === 'POINT') {
              const endPointToMove = link0 ? 0 : 1;
              const closestPoint = getClosestPointAndLineInPolygon(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach
              ).point;
              res.geometry.coordinates[payload.endPoint] = closestPoint;
            } else if (isConnectedToAPolygonShape(link0, link1)) {
              const shapeId = (isConnectedToAPolygonShape(link0, undefined) ? link0?.id : link1?.id) as string;

              const shape = racks[shapeId] ?? zones[shapeId] ?? stockZones[shapeId];

              const attachedLine = getClosestPointAndLineInPolygon(measurer.geometry.coordinates[endPoint], shape).line;

              const closestLine = getClosestPointAndLineInPolygon(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach
              ).line;

              const closestPoint = getClosestPointAndLineInPolygon(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach
              ).point;

              const [, P1, P2] = findShortestDistanceBetweenSegments(
                closestLine[0],
                closestLine[1],
                attachedLine[0],
                attachedLine[1],
                closestPoint
              );

              res.geometry.coordinates = [P1, P2];
            } else {
              /**
               * We always try to have a measurer with the minimum size
               * When the shape attach itself, we change a bit the behavior to allow the measurer to measure the shape
               * Thereby, by moving the closest end point, the user can measurer the rack/stockzone/zone length/height/diagonal/whatever
               */

              const closestPoint = getClosestPointAndLineInPolygon(
                measurer.geometry.coordinates[endPointToMove],
                shapeToAttach
              ).point;
              res.geometry.coordinates[payload.endPoint] = closestPoint;
            }
          } else if (measurer.properties.measurementType === MeasurementType.Center2Center) {
            // search the coordinate of the middle of the rack
            const coords = shapeToAttach.geometry.coordinates[0].map((coord) => {
              return coord;
            });

            const polygon = turf.polygon([coords]);
            const center = turf.centroid(polygon);

            res.geometry.coordinates[payload.endPoint][0] = center.geometry.coordinates[0];
            res.geometry.coordinates[payload.endPoint][1] = center.geometry.coordinates[1];
          } else {
            // eslint-disable-next-line no-console
            console.error('Measurement type not catched');
          }
        }

        if (isLockedLength) {
          // we need to keep the length of the measurer

          const otherEndPoint = endPoint === 0 ? 1 : 0;
          const coordsBaseEndPoint = res.geometry.coordinates[endPoint];

          if (otherEndPoint === 1) {
            const newCoordsOtherEndPoint = [
              coordsBaseEndPoint[0] + Math.cos(measurerAngleRad) * measurerLength,
              coordsBaseEndPoint[1] + Math.sin(measurerAngleRad) * measurerLength,
            ];

            res.geometry.coordinates[otherEndPoint] = newCoordsOtherEndPoint;
          } else if (otherEndPoint === 0) {
            const newCoordsOtherEndPoint = [
              coordsBaseEndPoint[0] - Math.cos(measurerAngleRad) * measurerLength,
              coordsBaseEndPoint[1] - Math.sin(measurerAngleRad) * measurerLength,
            ];

            res.geometry.coordinates[otherEndPoint] = newCoordsOtherEndPoint;
          }
        }

        res.properties = {
          ...measurer.properties,
          [`link${endPoint}`]: {
            id: shapeToAttach.id,
            type: shapeToAttach.properties.type,
          } as MeasurerLinkedShape,
        };
      }

      const actions: (SaveMeasurer | UpdateMeasurer)[] = [saveMeasurerAction(res)];

      if (
        res?.properties?.link0?.type === ShapeTypes.SegmentShape &&
        res?.properties?.link1?.type === ShapeTypes.SegmentShape &&
        res?.properties?.measurementType === MeasurementType.MinimumDistance
      ) {
        actions.push(
          updateMeasurerAction({
            measurerId: payload.measurerId,
          })
        );
      }

      if (
        res?.properties?.link0?.type === ShapeTypes.RackShape &&
        res?.properties?.link1?.type === ShapeTypes.RackShape &&
        res?.properties?.measurementType === MeasurementType.MinimumDistance
      ) {
        actions.push(
          updateMeasurerAction({
            measurerId: payload.measurerId,
          })
        );
      }

      if (
        res?.properties?.link0?.type === ShapeTypes.ZoneShape &&
        res?.properties?.link1?.type === ShapeTypes.ZoneShape &&
        res?.properties?.measurementType === MeasurementType.MinimumDistance
      ) {
        actions.push(
          updateMeasurerAction({
            measurerId: payload.measurerId,
          })
        );
      }

      if (
        res?.properties?.link0?.type === ShapeTypes.StockZoneShape &&
        res?.properties?.link1?.type === ShapeTypes.StockZoneShape &&
        res?.properties?.measurementType === MeasurementType.MinimumDistance
      ) {
        actions.push(
          updateMeasurerAction({
            measurerId: payload.measurerId,
          })
        );
      }

      return actions;
    })
  );
}

export function updateMeasurer$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveMeasurer> {
  return actions$.pipe(
    ofType<UpdateMeasurer>(CircuitActionTypes.UpdateMeasurer),
    withLatestFrom(state$),
    map(([action, state]) => {
      const payload = action.payload;
      const measurer = state.circuit.present.measurers.entities[payload.measurerId];

      const segments = state.circuit.present.segments.entities;
      const racks = state.circuit.present.racks.entities;
      const zones = state.circuit.present.zones.entities;
      const stockZones = state.circuit.present.stockZones.entities;
      const points = state.circuit.present.points.entities;

      const isLockedLength = !!measurer.properties.lockedLength;

      if (measurer && measurer.geometry && measurer.geometry.coordinates) {
        const coordinates = measurer.geometry.coordinates;
        const measurerLength = getDistanceBetweenPoints(coordinates[0], coordinates[1]);
        const measurerAngleRad = toRad(findShapeOrientation(coordinates[0], coordinates[1]));

        const endPointToUpdate: (0 | 1)[] = [];
        if (payload.endPointToUpdate === undefined) {
          if (measurer.properties.link0) endPointToUpdate.push(0);
          if (measurer.properties.link1) endPointToUpdate.push(1);
        } else {
          if (measurer.properties[`link${payload.endPointToUpdate}`]) endPointToUpdate.push(payload.endPointToUpdate);
        }

        const res: Partial<CircuitMeasurer> = {
          id: payload.measurerId,
          geometry: {
            ...measurer.geometry,
            coordinates: [...coordinates],
          },
        };

        const link0 = measurer.properties.link0;
        const link1 = measurer.properties.link1;

        if (
          endPointToUpdate.length < 2 ||
          !(link0?.type === ShapeTypes.PointShape && link1?.type === ShapeTypes.PointShape) ||
          measurer.properties.measurementType !== MeasurementType.MinimumDistance
        ) {
          endPointToUpdate.forEach((endPoint) => {
            const link = measurer.properties[`link${endPoint}`] as MeasurerLinkedShape;
            if (!link) return;
            const shape = CircuitService.getShape(link.id, link.type);

            if (!shape || !shape.geometry || !res || !res.geometry || !res.geometry.coordinates) return;

            if (isCircuitPoint(shape)) {
              res.geometry.coordinates[endPoint] = shape.geometry.coordinates;
            } else if (isCircuitSegment(shape)) {
              if (measurer.properties.measurementType === MeasurementType.MinimumDistance) {
                if (link0 && link1) {
                  if (link0?.type === 'SEGMENT' && link1?.type === 'SEGMENT') {
                    const segmentId = link0.id;
                    const segment = segments[segmentId];
                    const endPointToMove = link0 ? 0 : 1;

                    const [, , closestSegmentSecondEndPoint] = getClosestPointInSegment(
                      measurer.geometry.coordinates[endPointToMove],
                      shape.geometry.coordinates[0],
                      shape.geometry.coordinates[1]
                    );

                    const [closestPoint] = getClosestPointInSegment(
                      measurer.geometry.coordinates[endPointToMove],
                      segment.geometry.coordinates[0],
                      segment.geometry.coordinates[1]
                    );

                    const [, P1, P2] = findShortestDistanceBetweenSegments(
                      segment.geometry.coordinates[0],
                      segment.geometry.coordinates[1],
                      closestSegmentSecondEndPoint[0],
                      closestSegmentSecondEndPoint[1],
                      closestPoint
                    );

                    res.geometry.coordinates = structuredClone([P1, P2]);
                  } else if (link0?.type === 'POINT' || link1?.type === 'POINT') {
                    const pointId = link0?.type === 'POINT' ? link0.id : link1?.type === 'POINT' ? link1.id : '';
                    const point = points[pointId];

                    const [closestPoint] = getClosestPointInSegment(
                      point.geometry.coordinates,
                      shape.geometry.coordinates[0],
                      shape.geometry.coordinates[1]
                    );
                    res.geometry.coordinates[endPoint] = closestPoint;
                  }
                } else {
                  const [closestPoint] = getClosestPointInSegment(
                    measurer.geometry.coordinates[endPoint === 0 ? 1 : 0],
                    shape.geometry.coordinates[0],
                    shape.geometry.coordinates[1]
                  );
                  res.geometry.coordinates[endPoint] = closestPoint;
                }
                // we get the new coordinates to minimize the distance
              } else if (measurer.properties.measurementType === MeasurementType.Center2Center) {
                // the middle of the segment, i.e. the average of the two end points
                const coords = shape.geometry.coordinates;
                res.geometry.coordinates[endPoint][0] = (coords[0][0] + coords[1][0]) / 2;
                res.geometry.coordinates[endPoint][1] = (coords[0][1] + coords[1][1]) / 2;
              } else {
                // eslint-disable-next-line no-console
                console.error('Measurement type not caught');
              }

              if (payload.doNotRecomputeCoordinates && payload.coordinates) {
                res.geometry.coordinates = payload.coordinates;
              }
            } else if (isCircuitRack(shape) || isCircuitZone(shape) || isCircuitStockZone(shape)) {
              if (measurer.properties.measurementType === MeasurementType.MinimumDistance) {
                // we get the new coordinates to minimize the distance

                const shapeAttachItself = measurer.properties.link0?.id === measurer.properties.link1?.id;
                const otherEndPoint = endPoint === 0 ? 1 : 0;

                /**
                 * We always try to have a measurer with the minimum size
                 * When the shape attach itself, we change a bit the behavior to allow the measurer to measure the shape
                 * Thereby, by moving the closest end point, the user can measurer the rack/stockzone/zone length/height/diagonal/whatever
                 */
                const endPointToMove = shapeAttachItself ? endPoint : otherEndPoint;

                if (link0 && link1) {
                  //We search to which kind of polygon the measurer is linked
                  if (
                    (link0?.type === 'RACK' && link1?.type === 'RACK') ||
                    (link0?.type === 'ZONE' && link1?.type === 'ZONE') ||
                    (link0?.type === 'STOCK' && link1?.type === 'STOCK') ||
                    (link0?.type === 'RACK' && link1?.type === 'ZONE') ||
                    (link0?.type === 'RACK' && link1?.type === 'STOCK') ||
                    (link0?.type === 'ZONE' && link1?.type === 'RACK') ||
                    (link0?.type === 'ZONE' && link1?.type === 'STOCK') ||
                    (link0?.type === 'STOCK' && link1?.type === 'RACK') ||
                    (link0?.type === 'STOCK' && link1?.type === 'ZONE')
                  ) {
                    const shapeId = link0?.id ?? link1?.id;

                    const attachedShape = racks[shapeId] ?? zones[shapeId] ?? stockZones[shapeId];

                    const attachedLine = getClosestPointAndLineInPolygon(
                      measurer.geometry.coordinates[endPoint],
                      attachedShape
                    ).line;

                    const closestLine = getClosestPointAndLineInPolygon(
                      measurer.geometry.coordinates[endPointToMove],
                      shape,
                      attachedLine
                    ).line;

                    const closestPoint = getClosestPointAndLineInPolygon(
                      measurer.geometry.coordinates[endPointToMove],
                      shape
                    ).point;

                    const [, P1, P2] = findShortestDistanceBetweenSegments(
                      closestLine[0],
                      closestLine[1],
                      attachedLine[0],
                      attachedLine[1],
                      closestPoint
                    );

                    res.geometry.coordinates = structuredClone([P1, P2]);
                  } else if (link0?.type === 'SEGMENT' || link1?.type === 'SEGMENT') {
                    const segmentId = link0?.type === 'SEGMENT' ? link0.id : link1?.type === 'SEGMENT' ? link1.id : '';
                    const segment = segments[segmentId];
                    const endPointToMove = link0 ? 0 : 1;

                    const closestLine = getClosestPointAndLineInPolygon(
                      measurer.geometry.coordinates[endPointToMove],
                      shape,
                      segment.geometry.coordinates as [Position, Position]
                    ).line;

                    const [closestPoint] = getClosestPointInSegment(
                      measurer.geometry.coordinates[endPointToMove],
                      segment.geometry.coordinates[0],
                      segment.geometry.coordinates[1]
                    );

                    const [, P1, P2] = findShortestDistanceBetweenSegments(
                      segment.geometry.coordinates[0],
                      segment.geometry.coordinates[1],
                      closestLine[0],
                      closestLine[1],
                      closestPoint
                    );

                    res.geometry.coordinates = structuredClone([P1, P2]);
                  } else if (link0?.type === 'POINT' || link1?.type === 'POINT') {
                    const pointId = link0?.type === 'POINT' ? link0.id : link1?.type === 'POINT' ? link1.id : '';
                    const point = points[pointId];

                    const closestPoint = getClosestPointAndLineInPolygon(point.geometry.coordinates, shape).point;
                    res.geometry.coordinates[endPoint] = closestPoint;
                  }
                } else {
                  const closestPoint = getClosestPointAndLineInPolygon(
                    measurer.geometry.coordinates[endPointToMove],
                    shape
                  ).point;
                  res.geometry.coordinates[endPoint] = closestPoint;
                }
              } else if (measurer.properties.measurementType === MeasurementType.Center2Center) {
                // search the coordinate of the middle of the rack
                const coords = shape.geometry.coordinates[0].map((coord) => {
                  return coord;
                });

                const polygon = turf.polygon([coords]);
                const center = turf.centroid(polygon);

                res.geometry.coordinates[endPoint][0] = center.geometry.coordinates[0];
                res.geometry.coordinates[endPoint][1] = center.geometry.coordinates[1];
              } else {
                // eslint-disable-next-line no-console
                console.error('Measurement type not catched');
              }

              if (payload.doNotRecomputeCoordinates && payload.coordinates) {
                res.geometry.coordinates = payload.coordinates;
              }
            }
          });

          if (isLockedLength && endPointToUpdate.length === 1 && res?.geometry?.coordinates) {
            // we need to keep the length of the measurer

            const otherEndPoint = endPointToUpdate[0] === 0 ? 1 : 0;

            const coordsBaseEndPoint = res.geometry.coordinates[endPointToUpdate[0]];

            if (otherEndPoint === 1) {
              const newCoordsOtherEndPoint = [
                coordsBaseEndPoint[0] + Math.cos(measurerAngleRad) * measurerLength,
                coordsBaseEndPoint[1] + Math.sin(measurerAngleRad) * measurerLength,
              ];

              res.geometry.coordinates[otherEndPoint] = newCoordsOtherEndPoint;
            } else if (otherEndPoint === 0) {
              const newCoordsOtherEndPoint = [
                coordsBaseEndPoint[0] - Math.cos(measurerAngleRad) * measurerLength,
                coordsBaseEndPoint[1] - Math.sin(measurerAngleRad) * measurerLength,
              ];

              res.geometry.coordinates[otherEndPoint] = newCoordsOtherEndPoint;
            }
          }
        } else {
          const point0 = CircuitService.getShape(link0.id, link0.type);
          const point1 = CircuitService.getShape(link1.id, link1.type);

          if (isCircuitPoint(point0) && isCircuitPoint(point1)) {
            if (res.geometry && res.geometry.coordinates) {
              res.geometry.coordinates[0] = point0.geometry.coordinates;
              res.geometry.coordinates[1] = point1.geometry.coordinates;
            }
          } else {
            // eslint-disable-next-line no-console
            console.error(`At least one of the point is not a point`, {
              point0,
              point1,
            });
          }
        }

        return saveMeasurerAction(res);
      }

      return saveMeasurerAction({ id: payload.measurerId });
    })
  );
}

export function detachMeasurer$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveMeasurer> {
  return actions$.pipe(
    ofType<DetachMeasurer>(CircuitActionTypes.DetachMeasurer),
    withLatestFrom(state$),
    map(([action, state]) => {
      const payload = action.payload;
      const measurer = state.circuit.present.measurers.entities[payload.measurerId];

      const res: Partial<CircuitMeasurer> = {
        id: payload.measurerId,
        properties: {
          ...measurer.properties,
          [`link${payload.endPoint}`]: undefined,
        },
      };

      return saveMeasurerAction(res);
    })
  );
}

export function pointMoved$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<UpdateMeasurer> {
  return actions$.pipe(
    ofType<PointMoved>(CircuitActionTypes.PointMoved),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const payload = action.payload;
      const id = payload.id;

      // when a point is moved, we need to update the associated measurers
      const measurers = selectAllMeasurersEntities(state).filter((measurer) => {
        return (
          (measurer.properties.link0 && measurer.properties.link0.id === id) ||
          (measurer.properties.link1 && measurer.properties.link1.id === id)
        );
      });

      return measurers;
    }),
    map((measurer) => {
      return updateMeasurerAction({
        measurerId: measurer.id as string,
      });
    })
  );
}

export function deleteLayer$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<DeleteSuccessAction | ClearShapesSelection | DeleteMultipleShapes | undefined | SetBackdropState> {
  return actions$.pipe(
    ofType<DeleteLayer>(CircuitActionTypes.DeleteLayer),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const layerId = action.payload.layerId;
      const shapesInTheLayer = CircuitService.getShapes()
        .filter((shape) => {
          return shape.properties.layerId === layerId;
        })
        .sort((a, b) => {
          // we remove firstly the turns for performance (otherwise, we detect that we have to delete the turn after deleting the segment and trigger a second delete turn action)
          // also nice for the ctrl+z
          if (b.properties.type === ShapeTypes.TurnShape) return 1;

          return 0;
        });

      // deleteMultipleShapesAction

      const deleteActions = shapesInTheLayer
        .map((elt) => {
          const shapeType = elt.properties.type;
          switch (shapeType) {
            case ShapeTypes.ZoneShape:
              return deleteZoneAction({ id: elt.id as string });
            case ShapeTypes.StockZoneShape:
              return deleteStockZoneAction({ id: elt.id as string });
            case ShapeTypes.RackShape:
              return deleteRackAction({ id: elt.id as string });
            case ShapeTypes.PointShape:
              return deletePointAction({ id: elt.id as string });
            case ShapeTypes.SegmentShape:
              return deleteSegmentAction({ id: elt.id as string });
            case ShapeTypes.MeasurerShape:
              return deleteMeasurerAction({ id: elt.id as string });
            case ShapeTypes.TurnShape:
              return deleteTurnAction({ id: elt.id as string });
            case ShapeTypes.DeviceShape:
              return deleteDeviceAction({ id: elt.id as string });
            case ShapeTypes.NoteShape:
              return deleteNoteAction({ id: elt.id as string });

            default: {
              // eslint-disable-next-line no-console
              console.error('Unknown shape type', shapeType);

              assert<Equals<typeof shapeType, never>>();

              return undefined;
            }
          }
        })
        .filter(isDefined);

      const actions: (DeleteSuccessAction | ClearShapesSelection | DeleteMultipleShapes | SetBackdropState)[] = [];

      actions.push(setBackdropStateAction({ newBackdropState: true }));

      if (deleteActions.length) {
        const chunkSize = 10;
        for (let i = 0; i < deleteActions.length; i += chunkSize) {
          const chunk = deleteActions.slice(i, i + chunkSize);
          actions.push(
            deleteMultipleShapesAction({
              actions: chunk,
              force: true,
            })
          );
        }
      }

      if (state.local.selectedShapesData.length) {
        actions.unshift(clearShapesSelectionAction());
      }

      actions.push(setBackdropStateAction({ newBackdropState: false }));

      const largeLayerDeleteActionThreshold = 100;
      if (shapesInTheLayer.length > largeLayerDeleteActionThreshold) {
        (async () => {
          for (const action of actions) {
            if (action) store.dispatch(action);
            await new Promise((resolve) => setTimeout(resolve, 0));
          }
        })();

        return [];
      }

      return actions;
    })
  );
}

export function applyFilters$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void | UpdateMapOpacity | UpdateObstacleOpacity> {
  return actions$.pipe(
    ofType<ChangeStateFilter | ChangeOpacityFilter>(
      FilterActionTypes.changeStateFilter,
      FilterActionTypes.changeOpacityFilter
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const actions: (UpdateMapOpacity | UpdateObstacleOpacity)[] = [];
      if (action.type === FilterActionTypes.changeStateFilter) {
        // we do no nothing, all the filters are handled by the reducer and the selectors
      } else if (action.type === FilterActionTypes.changeOpacityFilter) {
        const payload = action.payload;
        if (payload.mapOpacity !== undefined) {
          const mapOpacity = payload.mapOpacity;

          if (typeof mapOpacity === 'number' && mapOpacity >= 0 && mapOpacity <= 1) {
            actions.push(
              updateMapOpacityAction({
                mapOpacity: mapOpacity * 100,
              })
            );
          } else {
            // eslint-disable-next-line no-console
            console.error('Wrong map opacity:', mapOpacity);
          }
        }

        if (payload.mapObstacleOpacity !== undefined) {
          const mapObstacleOpacity = payload.mapObstacleOpacity;
          if (typeof mapObstacleOpacity === 'number' && mapObstacleOpacity >= 0 && mapObstacleOpacity <= 1) {
            actions.push(
              updateObstacleOpacityAction({
                mapObstacleOpacity: mapObstacleOpacity * 100,
              })
            );
          } else {
            // eslint-disable-next-line no-console
            console.error('Wrong obstacle map opacity:', mapObstacleOpacity);
          }
        }

        if (payload.mapImageOpacity !== undefined) {
          const mapImageOpacity = payload.mapImageOpacity;
          if (typeof mapImageOpacity === 'number' && mapImageOpacity >= 0 && mapImageOpacity <= 1) {
            d3.select('[layer=floor-plan]').style('opacity', mapImageOpacity);
          } else {
            // eslint-disable-next-line no-console
            console.error('Wrong map image (layout) opacity:', mapImageOpacity);
          }
        }
      }

      return actions;
    })
  );
}

type ShapeTypesString =
  | 'zones'
  | 'segments'
  | 'points'
  | 'turns'
  | 'measurers'
  | 'stockZones'
  | 'racks'
  | 'devices'
  | 'notes'
  | 'mapImageOpacity'
  | 'gabarit';

export function strTypeToShapeType(
  strType: ShapeTypesString | 'Map' | 'MapImage' | 'MapObstacle' | 'gabarit'
): ShapeTypes | undefined {
  switch (strType) {
    case 'zones':
      return ShapeTypes.ZoneShape;
    case 'notes':
      return ShapeTypes.NoteShape;
    case 'segments':
      return ShapeTypes.SegmentShape;
    case 'points':
      return ShapeTypes.PointShape;
    case 'turns':
      return ShapeTypes.TurnShape;
    case 'measurers':
      return ShapeTypes.MeasurerShape;
    case 'stockZones':
      return ShapeTypes.StockZoneShape;
    case 'racks':
      return ShapeTypes.RackShape;
    case 'devices':
      return ShapeTypes.DeviceShape;
    case 'Map':
    case 'MapObstacle':
    case 'MapImage':
    case 'gabarit':
      return undefined;
  }

  // eslint-disable-next-line no-console
  console.error('Unknown shape type:', strType);
}

export function goBackToHandTool$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<void | SelectTool> {
  return actions$.pipe(
    ofType<AddSegment | AddMeasurer | AddPoint | AddStockZone | AddTurn | AddZone | AddRack | AddDevice | AddNote>(
      CircuitActionTypes.AddSegment,
      CircuitActionTypes.AddPoint,
      CircuitActionTypes.AddMeasurer,
      CircuitActionTypes.AddStockZone,
      CircuitActionTypes.AddZone,
      CircuitActionTypes.AddTurn,
      CircuitActionTypes.AddRack,
      CircuitActionTypes.AddDevice,
      CircuitActionTypes.AddNote
    ),
    mergeMap((action) => {
      if (action) {
        const toolStoreState = store.getState().tool;
        const activeTool = toolStoreState.activeTool;
        if (activeTool === Tools.Move) {
          // we do not want to go back to the hand tool if the user is already using the hand tool
          return [];
        }

        const goBackToMoveTool = toolStoreState.returnToMoveTool;
        if (
          goBackToMoveTool === 'always' ||
          (goBackToMoveTool === 'sometimes' &&
            (action.type === CircuitActionTypes.AddRack ||
              action.type === CircuitActionTypes.AddStockZone ||
              (action.type === CircuitActionTypes.AddSegment && action.payload.drawShape) ||
              action.type === CircuitActionTypes.AddDevice ||
              action.type === CircuitActionTypes.AddNote))
        ) {
          return [
            selectToolAction({
              toolName: Tools.Move,
            }),
          ];
        }
      }

      return [];
    })
  );
}

export function bringToFrontOrBack$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<
  | SaveZone
  | SaveSegment
  | SaveMeasurer
  | SavePoint
  | SaveTurn
  | SaveStockZone
  | SaveRack
  | SaveDevice
  | SaveNote
  | undefined
> {
  return actions$.pipe(
    ofType<BringToFront | BringToBack>(CircuitActionTypes.BringToFront, CircuitActionTypes.BringToBack),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const shapeId = action.payload.id;
      const type = action.payload.type;

      const shape = (() => {
        switch (type) {
          case ShapeTypes.ZoneShape:
            return state.circuit.present.zones.entities[shapeId];
          case ShapeTypes.StockZoneShape:
            return state.circuit.present.stockZones.entities[shapeId];
          case ShapeTypes.RackShape:
            return state.circuit.present.racks.entities[shapeId];
          case ShapeTypes.SegmentShape:
            return state.circuit.present.segments.entities[shapeId];
          case ShapeTypes.PointShape:
            return state.circuit.present.points.entities[shapeId];
          case ShapeTypes.TurnShape:
            return state.circuit.present.turns.entities[shapeId];
          case ShapeTypes.MeasurerShape:
            return state.circuit.present.measurers.entities[shapeId];
          case ShapeTypes.DeviceShape:
            return state.circuit.present.devices.entities[shapeId];
        }
      })();

      if (!shape) {
        // eslint-disable-next-line no-console
        console.error(`Shape not found (id: ${shapeId}, type: ${type})`);

        return [];
      }

      const returnAction = getShapeSaveAction(type)({
        id: shapeId,
        properties: {
          ...shape.properties,
          prio: action.type === CircuitActionTypes.BringToFront ? getMaxDisplayPriority() : getMinDisplayPriority(),
        },
      });

      return returnAction ? [returnAction] : [];
    })
  );
}

export function computeTurn$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<CreateTurnSuccess | CreateTurnFailure> {
  return actions$.pipe(
    ofType<AddTurn>(CircuitActionTypes.AddTurn),
    mergeMap(async ({ payload }) => {
      let origin = payload.origin;
      let destination = payload.destination;

      if (isNaN(origin.position)) {
        return createTurnFailureAction(new Error('Origin position is not a number'));
      }

      if (isNaN(destination.position)) {
        return createTurnFailureAction(new Error('Destination position is not a number'));
      }

      const originSeg = state$.value.circuit.present.segments.entities[origin.id];
      if (!originSeg) {
        return createTurnFailureAction(new Error(`Segment with the id ${origin.id} not found`));
      }

      const destSeg = state$.value.circuit.present.segments.entities[destination.id];
      if (!destSeg) {
        return createTurnFailureAction(new Error(`Segment with the id ${destination.id} not found`));
      }

      const isStockLineTurn = !!(originSeg.properties.stockLine || destSeg.properties.stockLine);
      const isRackTurn = !!(originSeg.properties.rack || destSeg.properties.rack);

      // we avoid connecting two extended length segments
      if (originSeg.properties.stockLine && destSeg.properties.stockLine) {
        return createTurnFailureAction(new Error(`Cannot connect two extended length segments`));
      }

      if (originSeg.properties.rack && destSeg.properties.rack) {
        return createTurnFailureAction(new Error(`Cannot connect two extended length segments`));
      }

      // we want to force the direction of the turn when the turn is connected to an extended length
      if (destSeg.properties.stockLine || destSeg.properties.rack) {
        [origin, destination] = [destination, origin];
      }

      const id = generateShapeId();

      let turnFeature: Feature<LineStringGeoJSON, TurnProperties> | undefined;

      const radius = payload.radius ?? getConfig('defaultValues').turnRadius;
      const maxOvershoot = payload.maxOvershoot ?? getConfig('defaultValues').maxOvershoot;
      const turnType = payload.turnType ?? state$.value.tool.turnType;
      const isExtendedLength = payload.extendedLength ?? (isStockLineTurn || isRackTurn);
      const startPointOffset = payload.startPointOffset ?? 0;

      if (turnType === 'Normal') {
        turnFeature =
          (await getTurnFeature({
            radius,
            maxOvershoot,
            startLine: origin.segment,
            endLine: destination.segment,
            originSegmentLocked: originSeg.properties.locked,
            destSegmentLocked: destSeg.properties.locked,
            stopBeforeTurn: false,
            startPointPositionFactor: !isExtendedLength ? origin.position : 1,
            extendedLength: isExtendedLength,
            startPointOffset,
          })) || undefined;
      }

      if (turnType !== 'Normal' || !turnFeature) {
        turnFeature =
          (await getTurnFeature({
            radius,
            maxOvershoot,
            startLine: origin.segment,
            endLine: destination.segment,
            originSegmentLocked: originSeg.properties.locked,
            destSegmentLocked: destSeg.properties.locked,
            stopBeforeTurn: true,
            startPointPositionFactor: !isExtendedLength ? origin.position : 1,
            extendedLength: isExtendedLength,
            startPointOffset,
          })) || undefined;
      }

      if (!turnFeature)
        return createTurnFailureAction(
          new Error('Not able to generate the turn, the segments may be parallels or both locked')
        );

      // we define the layer id of the newly created turn
      let layerId: string;
      if (payload.layerId) {
        layerId = payload.layerId;
      } else if (isExtendedLength) {
        /**
         * We don't want to create a turn on a layer that is not the same as the extended length segment
         */
        const isOriginExtendedLength = originSeg.properties.stockLine || originSeg.properties.rack;
        layerId = isOriginExtendedLength ? destSeg.properties.layerId : originSeg.properties.layerId;
      } else {
        layerId = CircuitService.getSelectedLayer();
      }

      return createTurnSuccessAction({
        type: 'Feature',
        id,
        geometry: turnFeature.geometry,
        properties: {
          ...turnFeature.properties,
          startPointOffset: isExtendedLength ? startPointOffset : undefined,
          positionFactorOrigin: origin.position,
          positionFactorDest: destination.position,
          originId: origin.id,
          destinationId: destination.id,
          layerId,
        },
      });
    })
  );
}

export function segmentMoved$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<UpdateTurn | UpdateMeasurer | PointMoved> {
  return actions$.pipe(
    ofType<SegmentMoved | MultipleSegmentsMoved>(
      CircuitActionTypes.SegmentMoved,
      CircuitActionTypes.MultipleSegmentsMoved
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      const payload = action.payload;
      const ids =
        'segmentsData' in payload
          ? payload.segmentsData.map((segmentData) => segmentData.idSegment)
          : [payload.idSegment];

      const segmentEntities = state.circuit.present.segments.entities;
      const segmentsData =
        'segmentsData' in payload
          ? payload.segmentsData
          : [
              {
                idSegment: payload.idSegment,
                coordinates: segmentEntities[payload.idSegment].geometry.coordinates,
              },
            ];

      // when a segment is moved, we retrieved the associated turns in order to update them
      const turns = selectAllTurnsEntities(state).filter((turn) => {
        // the turn is connected to the segment (either on the origin or the destination)
        return (
          (turn.properties.originId && ids.includes(turn.properties.originId)) ||
          (turn.properties.destinationId && ids.includes(turn.properties.destinationId))
        );
      });

      // same for the measurers
      const measurers = selectAllMeasurersEntities(state).filter((measurer) => {
        return (
          (measurer.properties.link0 && ids.includes(measurer.properties.link0.id)) ||
          (measurer.properties.link1 && ids.includes(measurer.properties.link1.id))
        );
      });

      // when a segment is moved, we retrieved the associated points in order to update them
      const points = selectAllPointsEntities(state).filter((point) => {
        return point.properties.segment?.id && ids.includes(point.properties.segment.id);
      });

      return { turns, measurers, points, segmentsData };
    }),
    switchMap(({ turns, measurers, points, segmentsData }) => {
      const actions: (UpdateTurn | UpdateMeasurer | PointMoved)[] = [];

      const turnsIds = turns.map((turn) => turn.id as string);
      if (turnsIds.length) {
        actions.push(
          updateTurnAction({
            idToUpdate: turnsIds,
          })
        );
      }

      measurers.forEach((measurer) => {
        actions.push(
          updateMeasurerAction({
            measurerId: measurer.id as string,
          })
        );
      });

      points.forEach((point) => {
        const segment = segmentsData.find((segmentData) => segmentData.idSegment === point.properties.segment?.id);
        if (!segment) {
          // eslint-disable-next-line no-console
          console.error(`Segment not found for point ${point.id}`);

          return;
        }

        const positionOnSegment = point.properties?.segment?.position;
        if (positionOnSegment === undefined) {
          // eslint-disable-next-line no-console
          console.error(`Position on segment not found for point ${point.id}`);

          return;
        }

        const segmentCoords = segment.coordinates;
        if (!segmentCoords) return; // no error because it is a normal case

        const newCoordinates = [
          segmentCoords[0][0] + (segmentCoords[1][0] - segmentCoords[0][0]) * positionOnSegment,
          segmentCoords[0][1] + (segmentCoords[1][1] - segmentCoords[0][1]) * positionOnSegment,
        ];

        actions.push(
          pointMovedAction({
            id: point.id as string,
            coordinates: newCoordinates,
            segment: point.properties.segment,
          })
        );
      });

      return actions;
    })
  );
}

/**
 * This epic re-compute the state of a turn when requested
 * By recomputing, we means the position, orientation, and coordinates of the geometry.
 */
export function updateTurn$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveTurnSuccess | SaveTurnFailure | DeleteTurn | SaveManyTurnsSuccess> {
  return actions$.pipe(
    ofType<UpdateTurn>(CircuitActionTypes.UpdateTurn),
    mergeMap(async (action) => {
      const payload = action.payload;
      const ids = Array.isArray(payload.idToUpdate) ? payload.idToUpdate : [payload.idToUpdate];
      const turns = ids.map((id) => state$.value.circuit.present.turns.entities[id]);

      const actions: (SaveTurnSuccess | SaveTurnFailure | DeleteTurn | SaveManyTurnsSuccess)[] = [];
      const saveManyTurnsPayload: SaveManyTurnsSuccess['payload'] = [];

      for (let i = 0; i < turns.length; i++) {
        const id = ids[i];
        const turn = turns[i];
        if (!turn) {
          actions.push(
            saveTurnFailureAction(
              id,
              new Error(`Turn with the id ${id} not found (payload: ${JSON.stringify(payload)})`)
            )
          );
          continue;
        }

        const originId = payload.originId ?? turn.properties.originId;
        const destinationId = payload.destinationId ?? turn.properties.destinationId;

        if (!originId || !destinationId) {
          actions.push(
            saveTurnFailureAction(
              id,
              new Error(
                `Turn with the id ${id} is missing either (or both) the origin (${turn.properties.originId}) or the destination (${turn.properties.destinationId})`
              )
            )
          );
          continue;
        }

        if (!turn.geometry) {
          actions.push(saveTurnFailureAction(id, new Error(`Turn with the id ${id} is missing the geometry`)));
          continue;
        }

        if (
          isNaN(turn.properties?.positionFactorOrigin as number) ||
          isNaN(turn.properties?.positionFactorDest as number)
        ) {
          actions.push(
            saveTurnFailureAction(
              id,
              new Error(
                `Turn with the id ${id} is missing the position factor (origin: ${turn.properties?.positionFactorOrigin}, dest: ${turn.properties?.positionFactorDest})`
              )
            )
          );
          continue;
        }

        if (
          !state$.value.circuit.present.segments.ids.includes(originId) ||
          !state$.value.circuit.present.segments.ids.includes(destinationId)
        ) {
          actions.push(
            saveTurnFailureAction(
              id,
              new Error(
                `No segment found with the id '${turn.properties.originId}' or '${turn.properties.destinationId}'`
              )
            )
          );
          continue;
        }

        // we get the segments linked with the turn
        const originSegment = state$.value.circuit.present.segments.entities[originId];
        const destSegment = state$.value.circuit.present.segments.entities[destinationId];
        // as well as the possibly updated properties
        const turnType = payload.turnType || turn.properties.turnType;
        const radius = payload.radius && payload.radius > 0.0 ? payload.radius : turn.properties.radius || 1;

        const maxOvershoot =
          payload.maxOvershoot && payload.maxOvershoot > 0.0 ? payload.maxOvershoot : turn.properties.maxOvershoot || 5;

        let positionFactorOrigin =
          payload.positionFactorOrigin !== undefined && !isNaN(payload.positionFactorOrigin)
            ? payload.positionFactorOrigin
            : turn.properties.positionFactorOrigin || 0.5;

        const isConnectedToExtendedLength =
          originSegment?.properties.rackColumn ||
          originSegment?.properties.stockLine ||
          destSegment?.properties.rackColumn ||
          destSegment?.properties.stockLine;

        const startPointOffset = isConnectedToExtendedLength
          ? payload.startPointOffset !== undefined && payload.startPointOffset >= 0.0
            ? payload.startPointOffset
            : turn.properties.startPointOffset ?? 0
          : undefined;

        const positionFactorDest =
          payload.positionFactorDest && payload.positionFactorDest >= 0.0 && payload.positionFactorDest <= 1.0
            ? payload.positionFactorDest
            : turn.properties.positionFactorDest || 0.5;

        if (
          turn.properties.drivenBy === 'StartPoint' &&
          !originSegment?.properties.rackColumn &&
          !originSegment?.properties.stockLine &&
          !destSegment?.properties.rackColumn &&
          !destSegment?.properties.stockLine
        ) {
          if (payload.positionFactorOrigin === undefined) {
            const coordsBeginTurn = turn.geometry.coordinates[0];

            const [, positionOnSegment] = getClosestPointInSegment(
              coordsBeginTurn,
              originSegment.geometry.coordinates[0],
              originSegment.geometry.coordinates[1]
            );

            positionFactorOrigin = positionOnSegment;
          }
        }

        let turnFeature: Feature<LineStringGeoJSON, TurnProperties> | undefined;

        const isStockLineTurn = !!(originSegment.properties.stockLine || destSegment.properties.stockLine);
        const isRackTurn = !!(originSegment.properties.rack || destSegment.properties.rack);

        if (turnType === 'Normal') {
          turnFeature =
            (await getTurnFeature({
              radius,
              maxOvershoot,
              startLine: originSegment.geometry.coordinates,
              endLine: destSegment.geometry.coordinates,
              originSegmentLocked: originSegment.properties.locked,
              destSegmentLocked: destSegment.properties.locked,
              stopBeforeTurn: false,
              startPointPositionFactor: !(isStockLineTurn || isRackTurn) ? positionFactorOrigin : 1,
              extendedLength: isStockLineTurn || isRackTurn,
              startPointOffset,
            })) || undefined;
        }

        let snackbarToCreate: 'stopBeforeTurn' | 'delete' | undefined;

        if (turnType !== 'Normal' || !turnFeature) {
          // we acknowledge the user that the turn has be modified to a stopBeforeTurn because
          // the turn creation failed
          if (turnType === 'Normal') {
            snackbarToCreate = 'stopBeforeTurn';
          }

          turnFeature =
            (await getTurnFeature({
              radius,
              maxOvershoot,
              startLine: originSegment.geometry.coordinates,
              endLine: destSegment.geometry.coordinates,
              originSegmentLocked: originSegment.properties.locked,
              destSegmentLocked: destSegment.properties.locked,
              stopBeforeTurn: true,
              startPointPositionFactor: !(isStockLineTurn || isRackTurn) ? positionFactorOrigin : 1,
              extendedLength: isStockLineTurn || isRackTurn,
              startPointOffset,
            })) || undefined;
        }

        if (!turnFeature) {
          // eslint-disable-next-line no-console
          console.warn('Turn computation failed, we delete the turn');

          snackbarToCreate = 'delete';

          actions.push(deleteTurnAction({ id }));
        }

        if (snackbarToCreate) {
          setTimeout(() => {
            SnackbarUtils.debouncedToast(
              snackbarToCreate === 'stopBeforeTurn'
                ? `The turn computation was not possible with these parameters, the turn has been modified to a stop before turn.`
                : `The turn computation was not possible with these parameters, the turn has been deleted.`,
              'snackbar_duplicate',
              {
                autoHideDuration: snackbarTurnChangedAutoHideDuration,
                variant: snackbarToCreate === 'stopBeforeTurn' ? 'info' : 'warning',
              }
            );
          }, 0);
        }

        if (!turnFeature) {
          continue;
        }

        const turnToSave: CircuitTurn = {
          type: 'Feature',
          id,
          geometry: turnFeature.geometry,
          properties: {
            ...turnFeature.properties,
            startPointOffset: isConnectedToExtendedLength ? startPointOffset : undefined,
            positionFactorOrigin,
            positionFactorDest,
            originId,
            destinationId,
            layerId: turn.properties.layerId,
            trafficType: turn.properties.trafficType,
          },
        };

        if (payload.gabarit) {
          turnToSave.properties = {
            ...turnToSave.properties,
            gabarit: {
              ...turnToSave.properties.gabarit,
              ...payload.gabarit,
            },
          };
        } else if (turn.properties.gabarit) {
          turnToSave.properties.gabarit = {
            ...turn.properties.gabarit,
          };
        }

        saveManyTurnsPayload.push(turnToSave);
      }

      if (saveManyTurnsPayload.length) {
        actions.push(saveManyTurnsSuccessAction(saveManyTurnsPayload));
      }

      return actions;
    }),
    mergeMap((actions) => from(actions))
  );
}

export function updateSegmentsAfterTurn$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveSegment | UpdateSegmentsPortions> {
  return actions$.pipe(
    ofType<SaveSuccessAction<CircuitTurn> | SaveManyTurnsSuccess>(
      TurnActionTypes.SaveSuccess,
      TurnActionTypes.CreateSuccess,
      TurnActionTypes.SaveManySuccess
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const payload = action.payload;

      const turns = Array.isArray(payload) ? payload : [payload];
      const actions: (SaveSegment | UpdateSegmentsPortions)[] = [];
      const segmentsToUpdate: SaveSegment['payload'][] = [];

      const layers = store.getState().circuit.present.layers.layers;

      const lockedSegmentsExtended: LoadedSegment[] = [];

      for (let i = 0; i < turns.length; i++) {
        const turn = turns[i];

        if (!turn.properties.originId || !turn.properties.destinationId) {
          // eslint-disable-next-line no-console
          console.error(`No origin or destination id found for the turn ${turn.id}`);

          return actions;
        }

        const originSegment = state.circuit.present.segments.entities[turn.properties.originId];
        const destSegment = state.circuit.present.segments.entities[turn.properties.destinationId];

        if (!originSegment || !destSegment) {
          // eslint-disable-next-line no-console
          console.error(`No origin or destination segment found for the turn ${turn.id}, destroying the turn`, {
            originSegment,
            destSegment,
            turn,
          });

          actions.push(deleteTurnAction({ id: turn.id as string }));

          continue;
        }

        const segments = [originSegment, destSegment];

        const threshold = 1e-2;

        for (let i = 0; i < segments.length; i++) {
          const segment = segments[i];
          const segmentCoord = segment.geometry.coordinates;
          const formerPortions = segment.properties.portions;

          // 1 - we compute if we need to extend the segment
          const p = turn.geometry.coordinates[i === 0 ? 0 : turn.geometry.coordinates.length - 1];
          const d = pDistance(
            p[0],
            p[1],
            segmentCoord[0][0],
            segmentCoord[0][1],
            segmentCoord[1][0],
            segmentCoord[1][1]
          );

          const newCoordinates = [...segmentCoord];

          if (d > threshold) {
            // the turn is not on the segment, we need to extend it
            const d1 = getDistanceBetweenPoints(p, segmentCoord[0]);
            const d2 = getDistanceBetweenPoints(p, segmentCoord[1]);
            const pointToMove = d1 < d2 ? 0 : 1;

            newCoordinates[pointToMove] = p;

            if (segment.properties.locked === true) {
              lockedSegmentsExtended.push(segment);
            }
          }

          // 2 - we compute the portions of the segments
          // we retrieve all the connected turns
          const turnsIds = state.circuit.present.turns.ids;
          const turns = state.circuit.present.turns.entities;
          const connectedTurns = turnsIds
            .filter((turnId) => {
              const t = turns[turnId];
              const layer = layers[t.properties.layerId];

              if (layer?.isDraft) return false;

              return t.properties.originId === segment.id || t.properties.destinationId === segment.id ? t : undefined;
            })
            .map((turnId) => turns[turnId]);
          // and then compute which extremity of the turn is connected to the segment
          const pointsCrossingTurns = connectedTurns.map((t) => {
            const extremity1 = t.geometry.coordinates[0];
            const extremity2 = t.geometry.coordinates[t.geometry.coordinates.length - 1];

            const d1 = pDistance(
              extremity1[0],
              extremity1[1],
              newCoordinates[0][0],
              newCoordinates[0][1],
              newCoordinates[1][0],
              newCoordinates[1][1]
            );
            const d2 = pDistance(
              extremity2[0],
              extremity2[1],
              newCoordinates[0][0],
              newCoordinates[0][1],
              newCoordinates[1][0],
              newCoordinates[1][1]
            );

            // in theory, either d1 or d2 equals 0 (or almost by a small epsilon, except if we will update the geomtry due to step 1)
            return [d1 < d2 ? extremity1 : extremity2, t, d1 < d2 ? 'in' : 'out'] as [Position, CircuitTurn, InOrOut];
          });

          // we compute the distance between the beginning of the segment and the crossing point to sort the points and compute the
          const sortedCrossingTurns = pointsCrossingTurns
            .map(([pt, t, inOrOut]) => {
              const distance = getDistanceBetweenPoints(newCoordinates[0], pt);

              return [pt, distance, t, inOrOut] as [Position, number, CircuitTurn, InOrOut];
            })
            .sort(([, dA], [, dB]) => {
              return dA - dB; // ascending sort by the distance from the segment start point
            });

          // and we format the points to have a CircuitPortion array
          const portionsPts: [Position, CircuitTurn?, InOrOut?][] = [[newCoordinates[0]]];
          sortedCrossingTurns.forEach(([pt, , t, inOrOut]) => {
            portionsPts.push([pt, t, inOrOut]);
          });
          portionsPts.push([newCoordinates[1]]);

          const portions = computePortionsOfSegment(portionsPts, segment, formerPortions);

          const segmentEdited = {
            id: segment.id,
            geometry: {
              ...segment.geometry,
              coordinates: newCoordinates,
            },
            properties: {
              ...segment.properties,
              portions,
            },
          };

          segmentsToUpdate.push(segmentEdited);
        }
      }

      // we select the resulting segment with the greater length
      const segmentsToUpdateById = groupBy(segmentsToUpdate, 'id');
      Object.keys(segmentsToUpdateById).forEach((segmentId) => {
        if (!segmentsToUpdateById[segmentId].length) return;

        const sortedSegmentsByLength = segmentsToUpdateById[segmentId].sort((a, b) => {
          if (!a.geometry || !b.geometry) {
            throw new Error(`Segment ${a.id} or ${b.id} has no geometry`);
          }

          return (
            getDistanceBetweenPoints(b.geometry.coordinates[0], b.geometry.coordinates[1]) -
            getDistanceBetweenPoints(a.geometry.coordinates[0], a.geometry.coordinates[1])
          );
        });

        segmentsToUpdateById[segmentId] = [sortedSegmentsByLength[0]];
      });

      const segmentsToUpdateId = Object.keys(segmentsToUpdateById);
      const selectedShapesId = state.local.selectedShapesData.flatMap((selectedShape) => selectedShape.id);

      // An island of segments and turns means that no other linked shape remain unselected
      const selectionIsAnIslandOfSegmentsAndTurns = segmentsToUpdateId.every((segmentToUpdateId) =>
        selectedShapesId.includes(segmentToUpdateId)
      );

      if (!selectionIsAnIslandOfSegmentsAndTurns) {
        Object.values(segmentsToUpdateById).forEach((segmentsToUpdate) =>
          actions.push(saveSegmentAction(segmentsToUpdate[0]))
        );
      }

      snackbarLockedSegmentExtended(lockedSegmentsExtended);

      return actions;
    })
  );
}

export function updateSegmentsPortions(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveSegment> {
  return actions$.pipe(
    ofType<DeleteSuccessAction | DeleteSuccessManyAction | UpdateSegmentsPortions>(
      TurnActionTypes.DeleteSuccess,
      TurnActionTypes.DeleteSuccessMany,
      CircuitActionTypes.UpdateSegmentsPortions
    ),
    delay(10), // waits for all the update turns to be executed
    switchMap((action, index) => {
      const payload = action.payload;
      const turnsIdsArr = 'ids' in payload ? payload.ids : 'id' in payload ? [payload.id] : undefined;
      const turnsIds = turnsIdsArr ? new Set(turnsIdsArr) : undefined;

      let segments: CircuitSegment[] = [];
      const allSegmentsIds = state$.value.circuit.present.segments.ids;
      const allSegments = state$.value.circuit.present.segments.entities;

      if (turnsIds) {
        for (let i = 0, l = allSegmentsIds.length; i < l; i++) {
          const segment = allSegments[allSegmentsIds[i]];
          const toInclude = segment.properties.portions.some(
            (portion) =>
              (portion.turnIdStart && turnsIds.has(portion.turnIdStart)) ||
              (portion.turnIdEnd && turnsIds.has(portion.turnIdEnd))
          );

          if (toInclude) {
            segments.push(segment);
          }
        }
      }

      if ('segmentsIds' in payload && payload.segmentsIds && payload.segmentsIds.length) {
        payload.segmentsIds.forEach((segmentId) => {
          segments.push(allSegments[segmentId]);
        });
      }

      segments = Array.from(new Set(segments));

      const layers = store.getState().circuit.present.layers.layers;

      const actions: SaveSegment[] = [];

      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const segmentCoord = segment.geometry.coordinates;
        const formerPortions = segment.properties.portions;

        const turnsIds = state$.value.circuit.present.turns.ids;
        const turns = state$.value.circuit.present.turns.entities;

        const connectedTurns = turnsIds
          .filter((turnId) => {
            const t = turns[turnId];
            const layer = layers[t.properties.layerId];

            if (layer?.isDraft) return false;

            return t.properties.originId === segment.id || t.properties.destinationId === segment.id ? t : undefined;
          })
          .map((turnId) => turns[turnId]);

        // and then compute which extremity of the turn is connected to the segment
        const pointsCrossingTurns = connectedTurns.map((t) => {
          const extremity1 = t.geometry.coordinates[0];
          const extremity2 = t.geometry.coordinates[t.geometry.coordinates.length - 1];

          const d1 = pDistance(
            extremity1[0],
            extremity1[1],
            segmentCoord[0][0],
            segmentCoord[0][1],
            segmentCoord[1][0],
            segmentCoord[1][1]
          );
          const d2 = pDistance(
            extremity2[0],
            extremity2[1],
            segmentCoord[0][0],
            segmentCoord[0][1],
            segmentCoord[1][0],
            segmentCoord[1][1]
          );

          // in theory, either d1 or d2 equals 0 (or almost by a small epsilon, except if we will update the geomtry due to step 1)
          return [d1 < d2 ? extremity1 : extremity2, t, d1 < d2 ? 'in' : 'out'] as [Position, CircuitTurn, InOrOut];
        });

        // we compute the distance between the beginning of the segment and the crossing point to sort the points and compute the
        const sortedCrossingTurns = pointsCrossingTurns
          .map(([pt, t, inOrOut]) => {
            const distance = getDistanceBetweenPoints(segmentCoord[0], pt);

            return [pt, distance, t, inOrOut] as [Position, number, CircuitTurn, InOrOut];
          })
          .sort(([, dA], [, dB]) => {
            return dA - dB; // ascending sort by the distance from the segment start point
          });

        const portionsPts: [Position, CircuitTurn?, InOrOut?][] = [[segmentCoord[0]]];
        sortedCrossingTurns.forEach(([pt, , t, inOrOut]) => {
          portionsPts.push([pt, t, inOrOut]);
        });
        portionsPts.push([segmentCoord[segmentCoord.length - 1]]);

        const portions = computePortionsOfSegment(portionsPts, segment, formerPortions);

        const segmentEdited = {
          ...segment,
          properties: {
            ...segment.properties,
            portions,
          },
        };
        actions.push(saveSegmentAction(segmentEdited));
      }

      return actions;
    })
  );
}

// when a zone is moved, we need to update the associated measurers
export function zoneMoved$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<UpdateMeasurer | SaveZoneSuccess> {
  return actions$.pipe(
    ofType<SaveZoneSuccess>(ZonesActionTypes.Save),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: (UpdateMeasurer | SaveZoneSuccess)[] = [];

      const zonesIds = state.circuit.present.zones.ids;

      const measurers = selectAllMeasurersEntities(state).filter((measurer) => {
        return (
          (measurer.properties.link0 && zonesIds.includes(measurer.properties.link0.id)) ||
          (measurer.properties.link1 && zonesIds.includes(measurer.properties.link1.id))
        );
      });

      measurers.forEach((measurer) => {
        actions.push(
          updateMeasurerAction({
            measurerId: measurer.id as string,
          })
        );
      });

      return actions;
    })
  );
}

export function createOrUpdateOrDeleteStockzoneExtendedLength$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<
  | AddSegment
  | SegmentMoved
  | UpdateMeasurer
  | SaveStockZone
  | SaveStockZoneSuccess
  | AddMultipleSegments
  | DeleteMultipleShapes
> {
  return actions$.pipe(
    ofType<CreateStockZoneSuccess | SaveStockZoneSuccess>(
      StockZonesActionTypes.CreateSuccess,
      StockZonesActionTypes.Save
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (!action.payload.id) {
        throw new Error(`StockZone ${action.payload} has no id`);
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const actionType = action.type;

      const actions: (
        | AddSegment
        | SegmentMoved
        | UpdateMeasurer
        | SaveStockZone
        | SaveStockZoneSuccess
        | AddMultipleSegments
        | DeleteMultipleShapes
      )[] = [];
      const segmentsToMove: { idSegment: string; coordinates: Position[] }[] = [];

      const stockZone = state.circuit.present.stockZones.entities[action.payload.id];
      const segments = Object.values(state.circuit.present.segments.entities);

      if (!stockZone) {
        throw new Error(`StockZone ${action.payload.id} not found`);
      }

      const stockLinesIds = stockZone.properties.slots.map((stockLine) => stockLine.id);

      const stockZonesIds = state.circuit.present.stockZones.ids;

      // when a stockZone is moved, we need to update the associated measurers
      const measurers = selectAllMeasurersEntities(state).filter((measurer) => {
        return (
          (measurer.properties.link0 && stockZonesIds.includes(measurer.properties.link0.id)) ||
          (measurer.properties.link1 && stockZonesIds.includes(measurer.properties.link1.id))
        );
      });
      const allStockZones = state.circuit.present.stockZones.entities;
      const allStockLinesIds = new Set<string>();
      for (let i = 0, l = stockZonesIds.length; i < l; i++) {
        const sz = allStockZones[stockZonesIds[i]];
        sz.properties.slots.forEach((stockLine) => allStockLinesIds.add(stockLine.id));
      }

      const originZone = [...stockZone.geometry.coordinates[0][0]];
      originZone[1] *= -1;

      // we compute the new wanted positions of all the (existing or not) extended length of
      // the stockzone
      let extendedLengthPosPerStockline = stockZone.properties.slots.map((stockLine) => {
        const slot = stockLine.slots[0];

        const pointFirstSlot = [slot.slotPosition.x, slot.slotPosition.y];

        const startArrow = [originZone[0], -pointFirstSlot[1] - (stockZone.properties.slotSize.width * 100) / 2];
        const endArrow = [
          originZone[0] +
            getDistanceBetweenPoints(stockZone.geometry.coordinates[0][0], stockZone.geometry.coordinates[0][1]),
          startArrow[1],
        ];

        const arrowLength = SEGMENT_ARROW_LENGTH; // px

        const extendedLengthPos = rotatePolygon(
          [originZone[0], -originZone[1]],
          [
            endArrow,
            [
              endArrow[0] +
                (stockZone.properties.extendedLength > arrowLength / 100
                  ? stockZone.properties.extendedLength
                  : arrowLength / 100) *
                  100,
              endArrow[1],
            ],
          ],
          stockZone.properties.cap
        );

        return {
          stocklineId: stockLine.id,
          position: extendedLengthPos,
          processed: false,
          measurers,
        };
      });

      // we find the already existing extended lengths of the stockzone
      const allExtendedLengthStockLine = segments.filter((segment) => {
        return segment.properties.stockLine;
      });
      let existingStockzoneExtendedLengths: CircuitSegment[] = [];
      if (actionType !== StockZonesActionTypes.CreateSuccess) {
        // for create action, no related segments exist
        existingStockzoneExtendedLengths = allExtendedLengthStockLine.filter((segment) => {
          return segment.properties.stockLine && stockLinesIds.includes(segment.properties.stockLine);
        });
      }

      // and we create a move action for these existing extended lengths (segments)
      extendedLengthPosPerStockline = extendedLengthPosPerStockline.map((extendedLengthPos) => {
        const matchingSegment = existingStockzoneExtendedLengths.find((segment) => {
          return segment.properties.stockLine === extendedLengthPos.stocklineId;
        });

        if (!matchingSegment) {
          return extendedLengthPos;
        }

        segmentsToMove.push({
          idSegment: matchingSegment.id as string,
          coordinates: extendedLengthPos.position,
        });

        return {
          ...extendedLengthPos,
          processed: true,
        };
      });

      // we look if there are missing extended lengths (segments) to create
      const stockzoneExtendedLengthsToCreate = extendedLengthPosPerStockline.filter(
        (extendedLengthPos) => !extendedLengthPos.processed
      );
      const segmentsToAdd: AddSegment['payload'][] = [];
      stockzoneExtendedLengthsToCreate.forEach((extendedLengthToCreate) => {
        const coord = extendedLengthToCreate.position;
        const segId = generateShapeId();
        const seg: AddSegment['payload'] = {
          coord: coord,
          stockLine: extendedLengthToCreate.stocklineId,
          locked: true,
          layerId: stockZone.properties.layerId,
          id: segId,
        };

        segmentsToAdd.push(seg);
      });
      actions.push(AddMultipleSegmentsAction(segmentsToAdd));

      // we remove the existing extended lengths (segments) that are not needed anymore
      const segmentsToDelete = allExtendedLengthStockLine.filter((segment) => {
        return segment.properties.stockLine && !allStockLinesIds.has(segment.properties.stockLine);
      });
      const actionsDeleteSegments: DeleteAction[] = [];
      segmentsToDelete.forEach((segment) => {
        actionsDeleteSegments.push(deleteSegmentAction({ id: segment.id as string }));
      });
      actions.push(
        deleteMultipleShapesAction({
          actions: actionsDeleteSegments,
          force: true,
        })
      );

      // we actually create the action to move the segments for the already made array
      if (segmentsToMove.length) {
        actions.push(multipleSegmentsMovedAction({ segmentsData: segmentsToMove }));
      }

      // We update the new position of the measurer
      measurers.forEach((measurer) => {
        actions.push(
          updateMeasurerAction({
            measurerId: measurer.id as string,
          })
        );
      });

      return actions;
    })
  );
}

/**
 * Create rack's extended length at the rack creation
 */
export function createOrUpdateOrDeleteRackExtendedLength$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<
  | AddSegment
  | SegmentMoved
  | UpdateMeasurer
  | SaveRack
  | SaveRackSuccess
  | AskUpdateRack
  | AddMultipleSegments
  | DeleteMultipleShapes
> {
  return actions$.pipe(
    ofType<CreateRackSuccess | SaveRackSuccess | SaveCellTemplate>(
      RackActionTypes.CreateSuccess,
      //RackActionTypes.SaveSuccess,
      RackActionTypes.Save,
      CellTemplateActionTypes.Save
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (!action.payload.id) {
        throw new Error(`Rack ${action.payload} has no id`);
      }

      const actions: (
        | AddSegment
        | SegmentMoved
        | UpdateMeasurer
        | SaveRack
        | SaveRackSuccess
        | AskUpdateRack
        | AddMultipleSegments
        | DeleteMultipleShapes
      )[] = [];
      const segmentsToMove: { idSegment: string; coordinates: Position[] }[] = [];
      /** ids of the segments to remove after updating the rack */
      const segmentsToRemove: string[] = [];

      const segmentsToAdd: AddSegment['payload'][] = [];

      const racksIds = state.circuit.present.racks.ids;

      // when a rack is moved, we need to update the associated measurers
      const measurers = selectAllMeasurersEntities(state).filter((measurer) => {
        return (
          (measurer.properties.link0 && racksIds.includes(measurer.properties.link0.id)) ||
          (measurer.properties.link1 && racksIds.includes(measurer.properties.link1.id))
        );
      });

      measurers.forEach((measurer) => {
        actions.push(
          updateMeasurerAction({
            measurerId: measurer.id as string,
          })
        );
      });

      const racksIdsToUpdate: (string | number)[] = [];
      if (action.type === CellTemplateActionTypes.Save) {
        const cellTemplateId = action.payload.id;

        const racksIds = state$.value.circuit.present.racks.ids;
        const racks = state$.value.circuit.present.racks.entities;

        for (let i = 0; i < racksIds.length; i++) {
          const rack = racks[racksIds[i]];
          let cellTemplateInThisRack = false;

          for (let j = 0; j < rack.properties.columns.length; j++) {
            const column = rack.properties.columns[j];

            for (let k = 0; k < column.cells.length; k++) {
              const cell = column.cells[k];

              if (cell.cellTemplate === cellTemplateId && rack.id) {
                racksIdsToUpdate.push(rack.id);
                cellTemplateInThisRack = true;
                break;
              }
            }

            if (cellTemplateInThisRack) break;
          }
        }
      } else {
        racksIdsToUpdate.push(action.payload.id);
      }

      for (let i = 0; i < racksIdsToUpdate.length; i++) {
        const rackId = racksIdsToUpdate[i];
        const rack = state$.value.circuit.present.racks.entities[rackId];
        if (!rack) {
          throw new Error(`Rack ${action.payload.id} not found`);
        }

        const columns = rack.properties.columns;
        let columnsUpdated = false;
        let newColumns = columns;
        // we check that all the columns are properly sorted
        for (let i = 0; i < columns.length - 1; i++) {
          if (columns[i].x > columns[i + 1].x) {
            // if the columns are not properly sorted, we sort them and we rerun the action
            newColumns = [...newColumns];
            newColumns.sort((a, b) => a.x - b.x);

            columnsUpdated = true;
          }
        }

        if (columnsUpdated) {
          actions.push(saveRackSuccessAction({ ...rack, properties: { ...rack.properties, columns: newColumns } }));

          actions.push(
            askUpdateRackAction({
              id: rack.id as string,
              type: 'save',
            })
          );

          if (action.type === CellTemplateActionTypes.Save) {
            actions.push(action);
          }

          return actions;
        }

        const cellTemplates = state$.value.circuit.present.cellTemplates.entities;
        const segments = state$.value.circuit.present.segments.entities;
        const segmentsIds = state$.value.circuit.present.segments.ids;

        const positions = CircuitService.computeRackExtendedLengthPosition(rack, cellTemplates);
        const coords = CircuitService.computeRackExtendedLengthCoords(rack, positions);

        const coordsByColumn = groupBy(coords, (c) => c[1]);
        const coordsInColumn = Object.values(coordsByColumn);

        const columnsSegments: Record<string, CircuitRack['properties']['columns'][0]['extendedLengthSegments']> = {};

        for (let i = 0; i < coordsInColumn.length; i++) {
          for (let j = 0; j < coordsInColumn[i].length; j++) {
            const coord = coordsInColumn[i][j];

            const coordinates = coord[0];
            const columnId = coord[1];
            const position = coord[2];

            // we need to check if we need to create a new segment or move an existing one
            const column = rack.properties.columns.find((c) => c.id === coord[1]);
            const segmentId =
              column &&
              column.extendedLengthSegments &&
              column.extendedLengthSegments[j] &&
              Array.isArray(column.extendedLengthSegments[j])
                ? column.extendedLengthSegments[j][0]
                : undefined;

            const segment = segmentId ? segments[segmentId] : undefined;

            if (column) {
              if (!columnsSegments[column.id]) columnsSegments[column.id] = [];

              if (segmentId && segment) {
                // we move an existing one
                const fomerCoordinates = segment.geometry.coordinates;
                const d1 = getDistanceBetweenPoints(fomerCoordinates[0], coordinates[0]);
                const d2 = getDistanceBetweenPoints(fomerCoordinates[1], coordinates[1]);

                const sameCoordinates = d1 < epsilon && d2 < epsilon;

                if (!sameCoordinates) {
                  segmentsToMove.push({ idSegment: segmentId, coordinates });
                }

                columnsSegments[column.id].push([segmentId, position]);
              } else {
                // we create a new one
                const segId = generateShapeId();
                const seg: AddSegment['payload'] = {
                  coord: coordinates,
                  rack: rack.id as string,
                  rackColumn: columnId,
                  locked: true,
                  layerId: rack.properties.layerId,
                  id: segId,
                };

                segmentsToAdd.push(seg);

                columnsSegments[column.id].push([segId, position]);
              }
            } else {
              // eslint-disable-next-line no-console
              console.error(`Column ${coord[1]} not found in rack ${rack.id}`);
            }
          }
        }

        const SKIP_OPTIMIZATION_COLUMN_RECOMPUTATION = false;
        if (
          !rack.properties.columns.every((c) => isEqual(c.extendedLengthSegments, columnsSegments[c.id])) ||
          SKIP_OPTIMIZATION_COLUMN_RECOMPUTATION
        ) {
          const newColumns = rack.properties.columns.map((c) => {
            const newExtendedLengthSegs = columnsSegments[c.id];

            /**
           * if the segments are not here anymore (if we have removed/changed cells/cell templates, etc.),
           * we have to remove the associated segments
           *
           * let A be the set made of segments of the rack in the PREVIOUS state
           * let B be the set made of segments of the rack in the NEW state
           * then the difference C between A and B is the set of segments to remove (C = A\B)
           * 
           * If needed here's an example (1,2,3 are numbered extended length)
              +-----------+-----------+-------+------------------------------------+
              |     A     |     B     |   C   |                                    |
              +-----------+-----------+-------+------------------------------------+
              | { 1 2 3 } | { 1 2 }   | { 3 } | When you remove an extended length |
              |           |           |       |                                    |
              | { 1 2 }   | { 1 2 3 } | Ø     | When an extended length is added   |
              +-----------+-----------+-------+------------------------------------+

              Then we remove the extended length presents in C from the segments in the store
           */
            const diff = differenceBy(c.extendedLengthSegments, newExtendedLengthSegs, (s) => s[0]);
            segmentsToRemove.push(...diff.map((s) => s[0]));

            /** we update the state of the extended length data in the rack column */
            return {
              ...c,
              extendedLengthSegments: newExtendedLengthSegs ?? [],
            };
          });

          const extendedLengthsFlatMapped = newColumns.flatMap((c) => c.extendedLengthSegments);
          let allExtendedLengthSorted = true;
          for (let i = 0; i < extendedLengthsFlatMapped.length - 1; i++) {
            if (extendedLengthsFlatMapped[i][1] > extendedLengthsFlatMapped[i + 1][1]) {
              allExtendedLengthSorted = false;
              break;
            }
          }

          if (!allExtendedLengthSorted) {
            // eslint-disable-next-line no-console
            console.error(`The extended lengths of the rack ${rack.id} are not sorted`, extendedLengthsFlatMapped);
          }

          actions.push(
            saveRackSuccessAction({
              ...rack,
              properties: {
                ...rack.properties,
                columns: newColumns,
              },
            })
          );
        }

        const newSegmentsIdsToRemove = segmentsIds.filter((segmentId) => {
          const segment = segments[segmentId];

          const connectedToTheRack = segment.properties.rack === rack.id;
          if (!connectedToTheRack) return false;

          // if the segment is connected to the rack but not to a column, we remove it
          const connectedToAColumn = rack.properties.columns.find(
            (column) => column.id === segment.properties.rackColumn
          );
          if (!connectedToAColumn) return true;

          // last case where we want to remove the segment:
          // if the segment is associated to the proper column
          // but we don't need it anymore (is has not been added to the columnsSegments list of this column)
          const segmentsOfThisColumn = columnsSegments[connectedToAColumn.id];
          const segmentInThisColumn = segmentsOfThisColumn?.find((s) => s?.[0] === segmentId);
          if (!segmentInThisColumn) return true;

          return false;
        });

        // let's remove the extended length segments associated with the deleted columns
        segmentsToRemove.push(...newSegmentsIdsToRemove);
      }

      if (segmentsToAdd.length) {
        actions.push(AddMultipleSegmentsAction(segmentsToAdd));
      }

      if (segmentsToRemove.length) {
        // We remove duplicates, useless to dispatch twice the same actions
        const segmentsToRemoveUnique = Array.from(new Set(segmentsToRemove));
        // and then we dispatch the actions to remove the segments we previously detected as not useful anymore
        const deleteSegmentActions: DeleteAction[] = segmentsToRemoveUnique.map((id) => deleteSegmentAction({ id }));
        actions.push(deleteMultipleShapesAction({ actions: deleteSegmentActions, force: true }));
      }

      if (segmentsToMove.length) {
        actions.push(multipleSegmentsMovedAction({ segmentsData: segmentsToMove }));
      }

      return actions;
    })
  );
}

export function moveSelection$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<
  AddSegment | SegmentMoved | SaveRack | PointMoved | SaveStockZone | SaveMeasurer | SaveZone | SaveDevice | SaveNote
> {
  return actions$.pipe(
    ofType<MoveSelection>(CircuitActionTypes.MoveSelection),
    switchMap((action) => {
      const payload = action.payload;
      const dx = payload.dx;
      const dy = payload.dy;

      const actions: (
        | AddSegment
        | SegmentMoved
        | SaveRack
        | PointMoved
        | SaveStockZone
        | SaveMeasurer
        | SaveZone
        | SaveDevice
        | SaveNote
      )[] = [];

      store.dispatch(saveCircuitToHistoryAction());
      const selectedShapes = state$.value.local.selectedShapesData;

      selectedShapes.forEach((selectedShape) => {
        const shapeType =
          selectedShape.type === ShapeTypes.StockZoneShape ? 'stockZones' : `${selectedShape.type.toLowerCase()}s`;
        const shape = state$.value.circuit.present[shapeType]?.entities?.[selectedShape.id] as CircuitShape;
        if (!shape) {
          // eslint-disable-next-line no-console
          console.warn(`Shape ${selectedShape.id} not found in store`);

          return;
        }

        if ('locked' in shape.properties && shape.properties.locked) {
          return; // we prevent locked shapes from being moved
        }

        if (isCircuitSegment(shape)) {
          const segment = shape;
          const coords = segment.geometry.coordinates;
          const newCoord = coords.map((coord) => [coord[0] + dx, coord[1] + dy]);

          actions.push(
            segmentMovedAction({
              idSegment: segment.id as string,
              coordinates: newCoord,
            })
          );
        } else if (isCircuitPoint(shape)) {
          const point = shape;
          const coords = point.geometry.coordinates;
          const newCoord = [coords[0] + dx, coords[1] + dy];

          actions.push(
            pointMovedAction({
              id: point.id as string,
              coordinates: newCoord,
            })
          );
        } else if (isCircuitZone(shape)) {
          const zone = shape;
          const coords = zone.geometry.coordinates;
          const newCoord = coords[0].map((coord) => [coord[0] + dx, coord[1] + dy]);

          actions.push(
            saveZoneAction({
              ...zone,
              geometry: {
                ...zone.geometry,
                coordinates: [newCoord],
              },
            })
          );
        } else if (isCircuitMeasurer(shape)) {
          const measurer = shape;
          const coords = measurer.geometry.coordinates;
          const newCoord = coords.map((coord) => [coord[0] + dx, coord[1] + dy]);

          actions.push(
            updateMeasurerAction({
              measurerId: measurer.id as string,
              coordinates: newCoord,
            })
          );
        } else if (isCircuitStockZone(shape)) {
          const stockZone = shape;
          const coords = stockZone.geometry.coordinates;
          const newCoord = coords[0].map((coord) => [coord[0] + dx, coord[1] + dy]);

          const action = updateStockZone(stockZone, {
            doNotDispatchAndReturn: true,
            forceUpdateCap: true,
            newCoordinates: [newCoord],
          });

          if (action) actions.push(action);
        } else if (isCircuitRack(shape)) {
          const rack = shape;
          const coords = rack.geometry.coordinates;
          const newCoord = coords[0].map((coord) => [coord[0] + dx, coord[1] + dy]);

          actions.push(
            saveRackAction({
              ...rack,
              geometry: {
                ...rack.geometry,
                coordinates: [newCoord],
              },
            })
          );
        } else if (isCircuitDevice(shape)) {
          const device = shape;
          const coords = device.geometry.coordinates;
          const newCoord = [coords[0] + dx, coords[1] + dy];

          actions.push(
            saveDeviceAction({
              id: device.id,
              geometry: {
                ...device.geometry,
                coordinates: newCoord,
              },
            })
          );
        } else if (isCircuitNote(shape)) {
          const note = shape;
          const coords = note.geometry.coordinates;
          const newCoord = [coords[0] + dx, coords[1] + dy];

          actions.push(
            saveNoteAction({
              ...note,
              geometry: {
                ...note.geometry,
                coordinates: newCoord,
              },
            })
          );
        } else {
          // ts error triggered if we add a circuit shape, but we don't handle it here
          // turns cannot be moved
          assert<Equals<typeof shape, CircuitTurn>>();
        }
      });

      return actions;
    })
  );
}

export function transferShapesToAnotherLayer$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<ReturnType<ReturnType<typeof getShapeSaveAction>>> {
  return actions$.pipe(
    ofType<TransferToAnotherLayer>(CircuitActionTypes.TransferToAnotherLayer),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const payload = action.payload;
      const { layerId, shapes } = payload;

      const actions: ReturnType<ReturnType<typeof getShapeSaveAction>>[] = [];

      shapes.forEach((shapeData) => {
        const shape = CircuitService.getShape(shapeData.id, shapeData.type);
        if (!shape) {
          // eslint-disable-next-line no-console
          console.warn(`Shape ${shapeData.id} not found in store`);

          return;
        }

        const saveAction = getShapeSaveAction(shape.properties.type);

        actions.push(
          saveAction({
            ...shape,
            properties: {
              ...shape.properties,
              layerId,
            },
          })
        );

        // for racks & stockzones, we need to transfer the extended length too
        if (shape.properties.type === ShapeTypes.RackShape || shape.properties.type === ShapeTypes.StockZoneShape) {
          let segments: CircuitSegment[] = [];
          if (shape.properties.type === ShapeTypes.RackShape) {
            const rack = shape as CircuitRack;
            const segmentsIds = rack.properties.columns.flatMap((column) =>
              (column.extendedLengthSegments ?? []).flatMap((extendedLength) => extendedLength[0])
            );
            segments = segmentsIds.map(
              (segmentId) => CircuitService.getShape(segmentId, ShapeTypes.SegmentShape) as CircuitSegment
            );
          } else if (shape.properties.type === ShapeTypes.StockZoneShape) {
            const stockZone = shape as CircuitStockZone;
            const stockLinesIds = stockZone.properties.slots.map((stockLine) => stockLine.id);
            segments = Object.values(state.circuit.present.segments.entities).filter(
              (segment) => segment.properties.stockLine && stockLinesIds.includes(segment.properties.stockLine)
            );
          } else {
            // eslint-disable-next-line no-console
            console.error(`Shape ${(shape as any)?.properties?.type} not supported in this function`);
          }

          segments.forEach((segment) => {
            const segmentSaveAction = saveSegmentAction({
              ...segment,
              properties: {
                ...segment.properties,
                layerId,
              },
            });

            actions.push(segmentSaveAction);
          });
        }
      });

      return actions;
    })
  );
}

let timeoutIDSnackBarPrefDeviceUpdated: number | undefined | ReturnType<typeof setTimeout>;
let loraIdUndefinedErrorSnackbar: SnackbarKey;
let loraIdNotUniqueSnackbar: SnackbarKey;

export function handleSaveDevice$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveZone> {
  return actions$.pipe(
    ofType<SaveDevice>(DeviceActionTypes.Save),
    debounceTime(0),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const deviceId = action.payload.id;
      if (!deviceId || typeof deviceId !== 'string') {
        // eslint-disable-next-line no-console
        console.error('Device id is not defined', action);

        return [];
      }

      const devices = state.circuit.present.devices.entities;
      const device = devices[deviceId];
      if (!device) {
        // eslint-disable-next-line no-console
        console.error(`Device ${deviceId} not found`);

        return [];
      }

      if (device.properties.deviceType === 'comboxGen2') {
        const loraIdSet = new Set<string>();
        Object.values(devices).forEach((d: LoadedDevice) => {
          if (d.properties.deviceType !== 'comboxGen2' || d.id === deviceId) return;

          const loraId = d.properties.loraID;
          if (!loraId || !loraId.length) {
            return;
          }

          if (loraIdSet.has(loraId)) {
            return;
          }

          loraIdSet.add(loraId);
        });

        if (!device.properties?.loraID || !device.properties.loraID.length) {
          if (loraIdUndefinedErrorSnackbar) {
            SnackbarUtils.closeSnackbar(loraIdUndefinedErrorSnackbar);
          }

          loraIdUndefinedErrorSnackbar = SnackbarUtils.warning(
            `The device ${device.properties.name} has an undefined LoRa ID. Please set a LoRa ID to it.`
          );
        } else if (loraIdSet.has(device.properties.loraID)) {
          if (loraIdNotUniqueSnackbar) {
            SnackbarUtils.closeSnackbar(loraIdNotUniqueSnackbar);
          }

          SnackbarUtils.warning(
            `The device ${device.properties.name} has a duplicate LoRa ID. Please set another unique LoRa ID to it.`
          );
        }
      }

      const pinsIn = device.properties.pinsIn ?? [];

      const actions: SaveZone[] = [];

      /**
       * 1. we check if the device is linked to a zone (a door in this case)
       * if the pins are removed from the device, we need to remove it from the door
       */

      const zonesIds = state$.value.circuit.present.zones.ids;
      const zones = state$.value.circuit.present.zones.entities;

      for (let i = 0; i < zonesIds.length; i++) {
        const zone = zones[zonesIds[i]];
        const doorDevices = zone.properties.door?.devices;

        if (!doorDevices) continue;

        const pinsToRemove: number[] = [];

        for (let j = 0; doorDevices.length; j++) {
          const doorDevice = doorDevices[j];
          if (!doorDevice) break;

          if (doorDevice.deviceId === deviceId && doorDevice.pinId >= pinsIn.length) {
            pinsToRemove.push(doorDevice.pinId);

            SnackbarUtils.info(
              `Door ${zone.properties.name} was linked with the pin ${doorDevice.pinId} of device ${deviceId} which is not available anymore. The link has been removed.`
            );
          }
        }

        if (pinsToRemove.length) {
          const newDevices = doorDevices.filter((doorDevice) => !pinsToRemove.includes(doorDevice.pinId));

          actions.push(
            saveZoneAction({
              ...zone,
              properties: {
                ...zone.properties,
                door: {
                  enabled: zone.properties.door?.enabled ?? false,
                  dAsk: zone.properties.door?.dAsk ?? 0,
                  dStop: zone.properties.door?.dStop ?? 0,
                  devices: newDevices,
                },
              },
            })
          );
        }
      }

      /**
       * 2. We check if the device name has changed and if it is used in a fire alarm
       * If yes we edit the associated preference of the install.xml file
       * Only if the core used is >= 26
       */

      const isNEWv26OrMore = (PreferencesService.getNEW4XCoreVersion() || 0) >= 26;

      const formerDevice = state.circuit.past.at(-1)?.devices.entities[deviceId] ?? device;

      const formerDeviceName = formerDevice.properties.name;
      const newDeviceName = action.payload?.properties?.name ?? formerDeviceName;
      const deviceNameChanged = formerDeviceName !== newDeviceName;

      if (deviceNameChanged && PreferencesService.arePreferencesFullyLoaded() && isNEWv26OrMore) {
        let formerFireAlarmDeviceNames: string[] | undefined;
        try {
          const retrievedPrefs = PreferencesService.getPreferenceValue('fireAlarm/deviceNames');
          if (retrievedPrefs && Array.isArray(retrievedPrefs)) {
            formerFireAlarmDeviceNames = retrievedPrefs;
          }
        } catch (e) {}

        if (formerFireAlarmDeviceNames) {
          const newDeviceNames: string[] = [...formerFireAlarmDeviceNames];

          formerFireAlarmDeviceNames?.forEach((formerFireAlarmDeviceName, i) => {
            if (formerFireAlarmDeviceName === formerDeviceName) {
              newDeviceNames[i] = newDeviceName;
            }
          });

          if (!isEqual(formerFireAlarmDeviceNames, newDeviceNames)) {
            PreferencesService.setPreferenceValue('fireAlarm/deviceNames', newDeviceNames)
              .then(([ok]) => {
                if (ok) {
                  if (timeoutIDSnackBarPrefDeviceUpdated) clearTimeout(timeoutIDSnackBarPrefDeviceUpdated as number);

                  timeoutIDSnackBarPrefDeviceUpdated = setTimeout(
                    () => SnackbarUtils.success('Fire alarm device name preference updated'),
                    1000
                  );
                } else {
                  SnackbarUtils.error('Error while updating the fire alarm device name preference');
                }
              })
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.error('Error while saving the fire alarm device name preference', e);

                SnackbarUtils.error(
                  'Error while updating the fire alarm device name preference, please check your install.xml file'
                );
              });
          }
        }
      }

      return actions;
    })
  );
}

export function updateRack$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<SaveRack | SaveRackSuccess | AskUpdateRack | void> {
  return actions$.pipe(
    ofType<AskUpdateRack | CreateRackSuccess>(CircuitActionTypes.AskUpdateRack, RackActionTypes.CreateSuccess),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: (SaveRack | SaveRackSuccess | AskUpdateRack)[] = [];

      if ('properties' in action.payload) {
        const isConveyorJustCreated = !!action.payload.properties.conveyor;
        if (isConveyorJustCreated) {
          actions.push(
            askUpdateRackAction({
              id: action.payload.id as string,
              type: 'save',
            })
          );
          actions.push(
            askUpdateRackAction({
              id: action.payload.id as string,
              type: 'saveSuccess',
            })
          );
        }
      } else {
        const rackId = action.payload.id;
        const saveType = action.payload.type;
        const rack = state.circuit.present.racks.entities[rackId];

        if (!rack) {
          // eslint-disable-next-line no-console
          console.error(`Rack ${rackId} not found`);

          return [];
        }

        actions.push(
          (saveType === 'save' ? saveRackAction : saveRackSuccessAction)({
            ...rack,
            userAction: undefined,
          })
        );
      }

      return actions;
    })
  );
}

const createSuccessActionRecordShapeNames = {
  [DeviceActionTypes.CreateSuccess]: 'devices',
  [MeasurerActionTypes.CreateSuccess]: 'measurers',
  [NotesActionTypes.CreateSuccess]: 'notes',
  [PointsActionTypes.CreateSuccess]: 'points',
  [RackActionTypes.CreateSuccess]: 'racks',
  [SegmentActionTypes.CreateSuccess]: 'segments',
  [StockZonesActionTypes.CreateSuccess]: 'stockZones',
  [TurnActionTypes.CreateSuccess]: 'turns',
  [ZonesActionTypes.CreateSuccess]: 'zones',
};
const createSuccessActionRecordKets = Object.keys(createSuccessActionRecordShapeNames);

let filterCreateShapeActionsEpicSnackbar: ReturnType<typeof SnackbarUtils.info> | undefined = undefined;
export const filterCreateShapeActionsEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<AppState>
): Observable<void> => {
  return action$.pipe(
    ofType(...createSuccessActionRecordKets),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const isShapeLayerHidden = state.local.filters[createSuccessActionRecordShapeNames[action.type]] === false;

      if (isShapeLayerHidden && !filterCreateShapeActionsEpicSnackbar) {
        filterCreateShapeActionsEpicSnackbar = SnackbarUtils.info(
          'The newly created shape is hidden, you can display it by turning on the associated filter '
        );

        // we display the snackbar only once per second to prevent spamming
        setTimeout(() => {
          filterCreateShapeActionsEpicSnackbar = undefined;
        }, 1000);
      }

      return [];
    })
  );
};

export const yjsActions = new Set<string>();
Object.values(CircuitActionTypes).forEach(yjsActions.add, yjsActions);

Object.values(PointsActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(DeviceActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(MeasurerActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(NotesActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(RackActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(SegmentActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(StockZonesActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(TurnActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(ZonesActionTypes).forEach(yjsActions.add, yjsActions);
Object.values(CellTemplateActionTypes).forEach(yjsActions.add, yjsActions);

yjsActions.delete(CircuitActionTypes.SaveCircuitToHistory);
yjsActions.delete(CircuitActionTypes.ApplyUpdatedShapesFromYJS);
yjsActions.delete(CircuitActionTypes.LoadCircuitFromYJS);
yjsActions.delete(CircuitActionTypes.SelectShape);
yjsActions.delete(CircuitActionTypes.SelectMultipleShapes);
yjsActions.delete(CircuitActionTypes.ClearShapesSelection);
yjsActions.delete(CircuitActionTypes.SelectShapesInRect);
yjsActions.delete(CircuitActionTypes.ChangeLayersOrder);

yjsActions.forEach((action) => {
  const actionLowerCase = action.toLowerCase();

  const conditionFulfilled =
    !actionLowerCase.includes('success') &&
    !actionLowerCase.includes('layer') &&
    (actionLowerCase.includes('delete') || actionLowerCase.includes('failure') || actionLowerCase.includes('add'));

  if (conditionFulfilled) {
    yjsActions.delete(action);
  }
});

yjsActions.add(CircuitActionTypes.DeleteLayer);
yjsActions.add(CircuitActionTypes.DeleteLayerGroup);
yjsActions.add(CircuitActionTypes.AddLayer);
yjsActions.add(CircuitActionTypes.AddLayerGroup);
yjsActions.add(CircuitActionTypes.AddMultipleSegments);
yjsActions.add('@@redux-undo/UNDO');
yjsActions.add('@@redux-undo/REDO');

export type UpdatedShapesAction = {
  /* 
  Type of the action : 
  "u" for update
  "d" for delete
  */

  t: 'u' | 'd';

  /* Added shape */
  s?: any;

  /* Id of the updated & deleted* shape */
  i: string;
};

let uniqueShapeIdSet = new Map<string, string>();
let updatedShapesActions: UpdatedShapesAction[] = [];

interface Actions extends Action {
  type: string;
  payload: { [key: string]: string } | [{ [key: string]: string }];
}

function setUniqueShapeId(action: Actions, id: string | string[]): void {
  if (!id) return;

  if (Array.isArray(id)) {
    id.forEach((idElement) => {
      setUniqueShapeId(action, idElement);
    });

    return;
  }

  if (uniqueShapeIdSet.has(id)) {
    uniqueShapeIdSet.delete(id);
  }

  if (action.type.includes('Delete')) {
    uniqueShapeIdSet.set(id, 'd');

    return;
  }

  uniqueShapeIdSet.set(id, 'u');
}

function getShapeIdDiff(previousProperty: EntityState<any>, currentProperty: EntityState<any>): void {
  if (previousProperty.entities === currentProperty.entities && previousProperty.ids === currentProperty.ids) return;

  if (previousProperty.ids.length !== currentProperty.ids.length) {
    if (Object.keys(previousProperty.entities).length > Object.keys(currentProperty.entities).length) {
      difference(previousProperty.ids, currentProperty.ids).forEach((id: string) => {
        if (uniqueShapeIdSet.has(id)) {
          uniqueShapeIdSet.delete(id);
        }

        uniqueShapeIdSet.set(id, 'd');
      });
    } else {
      difference(currentProperty.ids, previousProperty.ids).forEach((id: string) => {
        if (uniqueShapeIdSet.has(id)) {
          uniqueShapeIdSet.delete(id);
        }

        uniqueShapeIdSet.set(id, 'u');
      });
    }
  } else {
    Object.keys(previousProperty.entities).forEach((id: string) => {
      if (previousProperty.entities[id] !== currentProperty.entities[id]) {
        if (uniqueShapeIdSet.has(id)) {
          uniqueShapeIdSet.delete(id);
        }

        uniqueShapeIdSet.set(id, 'u');
      }
    });
  }
}

function getShapeIdDiffBetweenTwoState(previousState: CircuitState, nextState: CircuitState): void {
  if (previousState === nextState) return;

  Object.keys(previousState).forEach((property) => {
    if (property === 'layers') {
      if (previousState.layers === nextState.layers) return;

      const previousStateLayersKeys = Object.keys(previousState.layers.layers);
      const nextStateLayersKeys = Object.keys(nextState.layers.layers);

      if (previousStateLayersKeys.length !== nextStateLayersKeys.length) {
        if (previousStateLayersKeys.length > nextStateLayersKeys.length) {
          difference(previousStateLayersKeys, nextStateLayersKeys).forEach((id: string) => {
            if (uniqueShapeIdSet.has(id)) {
              uniqueShapeIdSet.delete(id);
            }

            uniqueShapeIdSet.set(id, 'd');
          });
        } else {
          difference(nextStateLayersKeys, previousStateLayersKeys).forEach((id: string) => {
            if (uniqueShapeIdSet.has(id)) {
              uniqueShapeIdSet.delete(id);
            }

            uniqueShapeIdSet.set(id, 'u');
          });
        }
      } else {
        previousStateLayersKeys.forEach((id: string) => {
          if (previousState.layers.layers[id] !== nextState.layers.layers[id]) {
            if (uniqueShapeIdSet.has(id)) {
              uniqueShapeIdSet.delete(id);
            }

            uniqueShapeIdSet.set(id, 'u');
          }
        });
      }

      const previousStateLayerGroupsKeys = Object.keys(previousState.layers.layerGroups);
      const nextStateLayerGroupsKeys = Object.keys(nextState.layers.layerGroups);

      if (previousStateLayerGroupsKeys.length !== nextStateLayerGroupsKeys.length) {
        if (previousStateLayerGroupsKeys.length > nextStateLayerGroupsKeys.length) {
          difference(previousStateLayerGroupsKeys, nextStateLayerGroupsKeys).forEach((id: string) => {
            if (uniqueShapeIdSet.has(id)) {
              uniqueShapeIdSet.delete(id);
            }

            uniqueShapeIdSet.set(id, 'd');
          });
        } else {
          difference(nextStateLayerGroupsKeys, previousStateLayerGroupsKeys).forEach((id: string) => {
            if (uniqueShapeIdSet.has(id)) {
              uniqueShapeIdSet.delete(id);
            }

            uniqueShapeIdSet.set(id, 'u');
          });
        }
      } else {
        previousStateLayerGroupsKeys.forEach((id: string) => {
          if (previousState.layers.layerGroups[id] !== nextState.layers.layerGroups[id]) {
            if (uniqueShapeIdSet.has(id)) {
              uniqueShapeIdSet.delete(id);
            }

            uniqueShapeIdSet.set(id, 'u');
          }
        });
      }
    }

    getShapeIdDiff(previousState[property], nextState[property]);
  });
}

export function propagateStateToOtherPeople$(
  actions$: ActionsObservable<Actions>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    filter((action) => yjsActions.has(action.type)),
    withLatestFrom(state$),
    map(([action, state]) => {
      if (!state.multiplayer.multiplayer) return action;

      /* CTRL+Y */
      if (action.type === '@@redux-undo/REDO') {
        getShapeIdDiffBetweenTwoState(state.circuit.past[state.circuit.past.length - 1], state.circuit.present);

        return action;
      }

      /* CTRL+Z */
      if (action.type === '@@redux-undo/UNDO') {
        getShapeIdDiffBetweenTwoState(state.circuit.future[state.circuit.future.length - 1], state.circuit.present);

        return action;
      }

      if (!action?.payload) return action;

      if (Array.isArray(action.payload?.['segmentsData'])) {
        action.payload?.['segmentsData'].forEach((innerAction) => {
          Object.keys(innerAction)
            .filter((property) => property.toLowerCase().includes('id'))
            .forEach((propertyName) => {
              const id = innerAction[propertyName] as string | string[];

              setUniqueShapeId(action, id);
            });
        });
      } else if (Array.isArray(action.payload)) {
        action.payload.forEach((innerAction) => {
          Object.keys(innerAction)
            .filter(
              (property) =>
                property.toLowerCase().includes('id') &&
                property.toLowerCase() !== 'width' &&
                !(action.type === CircuitActionTypes.AddMultipleSegments && property.toLowerCase() === 'layerid')
            )
            .forEach((propertyName) => {
              const id = innerAction[propertyName];

              setUniqueShapeId(action, id);
            });
        });
      } else {
        Object.keys(action.payload)
          .filter((property) => property.toLowerCase().includes('id') && property.toLowerCase() !== 'width')
          .forEach((propertyName) => {
            const id = action.payload[propertyName] as string;
            if (!id) return;

            setUniqueShapeId(action, id);
          });
      }

      return action;
    }),
    debounceTime(0),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: VoidFunction[] = [];
      if (!state.multiplayer.multiplayer) return actions;

      uniqueShapeIdSet.forEach((type, id) => {
        if (type === 'd') {
          updatedShapesActions.push({ t: 'd', i: id });

          return;
        }

        let updatedShape: CircuitShape | RackCellTemplate | LayerData | LayerGroupData | undefined;

        if (!!state.circuit.present.cellTemplates.entities[id]) {
          updatedShape = state.circuit.present.cellTemplates.entities[id];
        } else if (!!state.circuit.present.layers.layers[id]) {
          updatedShape = state.circuit.present.layers.layers[id];
        } else if (!!state.circuit.present.layers.layerGroups[id]) {
          updatedShape = state.circuit.present.layers.layerGroups[id];
        } else {
          updatedShape = CircuitService.getShape(id);
        }

        if (!updatedShape) {
          // eslint-disable-next-line no-console
          console.error(`Shape of id ${id} not found`);

          return;
        }

        updatedShapesActions.push({ t: 'u', s: updatedShape, i: id });
      });

      const updatedShapesActionsArrayType = localDoc.getArray<UpdatedShapesAction>('updatedShapesActions');
      const updatedShapesActionsArray = updatedShapesActionsArrayType.toArray();

      const maxUpdatedShapesActionsArrayLength = 100;
      if (updatedShapesActionsArray.length >= maxUpdatedShapesActionsArrayLength) {
        const localCircuitMap = localDoc.getMap('circuit');

        localDoc.transact(() => {
          updatedShapesActionsArrayType.delete(0, updatedShapesActionsArray.length);
          localCircuitMap.set('circuit', state.circuit.present);
        });
      }

      localDoc.transact(() => {
        updatedShapesActions.forEach((updatedShapeAction) => {
          const duplicateIndex = updatedShapesActionsArrayType
            .toJSON()
            .findIndex((remoteUpdatedShapeAction) => remoteUpdatedShapeAction.i === updatedShapeAction.i);
          if (duplicateIndex !== -1) {
            updatedShapesActionsArrayType.delete(duplicateIndex);
          }

          updatedShapesActionsArrayType.push([updatedShapeAction]);
        });
      });

      syncYJSLocalToRemote();

      updatedShapesActions = [];
      uniqueShapeIdSet = new Map();

      return actions;
    })
  );
}

export function propagateDevicePrefManagement$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    filter(setDevicePrefManagement.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: VoidFunction[] = [];
      if (!state.multiplayer.multiplayer) return actions;

      const enableDevicePrefManagementMap = localDoc.getMap('enableDevicePrefManagement');
      enableDevicePrefManagementMap.set('state', action.payload);

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function propagateCircuitDescription$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    filter(setDescription.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: VoidFunction[] = [];
      if (!state.multiplayer.multiplayer) return actions;

      const circuitDescriptionStr = localDoc.getText('circuitDescription');
      localDoc.transact(() => {
        circuitDescriptionStr.delete(0, circuitDescriptionStr?.length);
        circuitDescriptionStr.insert(0, action.payload);
      });

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function propagateCircuitVersion$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    filter(setVersion.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: VoidFunction[] = [];
      if (!state.multiplayer.multiplayer) return actions;

      const circuitVersionStr = localDoc.getText('circuitVersion');
      localDoc.transact(() => {
        circuitVersionStr.delete(0, circuitVersionStr?.length);
        circuitVersionStr.insert(0, action.payload);
      });

      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function connectionToYJSDoc$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<LoadCircuitFromYJS> {
  return actions$.pipe(
    filter(connectRoom.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: LoadCircuitFromYJS[] = [];
      if (!provider) return actions;

      provider.on('sync', async (isSynced: boolean) => {
        if (!isSynced || !awareness) return;

        const profilesMap = localDoc.getMap('profiles');
        const profile = JSON.parse(localStorage.getItem('profile') || '{}') as UserProfileData;
        profilesMap.set(awareness.clientID.toString(), profile);

        const localPrefMap = localDoc.getMap('pref');

        /*
        ! User joined a room
        */
        if (!projectHost) {
          store.dispatch(setLoadingStateAction({ newLoadingState: false }));
          store.dispatch(setSynced());
          store.dispatch(ActionCreators.clearHistory());

          const xmls = localPrefMap.get('xmls');
          const projectName = localPrefMap.get('projectName');
          const projectNEWVersion = localPrefMap.get('projectNEWVersion');
          const availableCircuits = localPrefMap.get('availableCircuits');

          PreferencesService.setInitialPref({ xmls, projectName, projectNEWVersion, availableCircuits });

          syncYJSLocalToRemote();

          return;
        }

        /*
        ! User created a room
        */

        const localCircuitMap = localDoc.getMap('circuit');
        const localLidarMap = localDoc.getMap('lidar');
        const localMapImageMap = localDoc.getMap('mapImage');

        /* NextFreeId */
        const localNextFreeIdArray = localDoc.getArray('nextFreeId');
        localDoc.transact(() => {
          localNextFreeIdArray.delete(0, localNextFreeIdArray?.length);
          localNextFreeIdArray.insert(0, window.nextFreeIdArray ?? Array(MAX_EDITING_USERS).fill(0));
        });

        /* Circuit */
        localCircuitMap.set('circuit', state.circuit.present);

        /* Lidar */
        localLidarMap.set('lidar', state.maps.lidar);
        const mapFilePath = state.maps.lidar['background-lidar']?.name;
        if (mapFilePath) {
          const mapFile = await PreferencesService.getFileByPath(`MAP/${mapFilePath}`);

          if (mapFile) {
            const backgroundLidarUInt8Array = await loadFileAsUint8Array(mapFile);

            localLidarMap.set('background-uInt8Array', backgroundLidarUInt8Array);
          }
        }

        /* Map Image */
        const mapImages = state.maps.mapImage.mapImages;
        const mapImagesTiles = state.maps.mapImageTiles.mapImageTilesData;
        const mapTilesURL = state.maps.mapImageTiles.fullImgURL;

        if (mapImages?.length) {
          for await (const mapImage of mapImages) {
            const layoutImageUInt8Array = await convertBlobUrlToUInt8Array(mapImage.URL);

            const { URL, name, ...data } = mapImage;
            localMapImageMap.set(name, {
              data,
              uInt8Array: layoutImageUInt8Array,
            });
          }
        }

        if (mapImagesTiles && mapTilesURL) {
          const layoutImageUInt8Array = await convertBlobUrlToUInt8Array(mapTilesURL);

          const { name, ...data } = mapImagesTiles;
          localMapImageMap.set(name, {
            data,
            uInt8Array: layoutImageUInt8Array,
          });
        }

        /* Preferences */
        if (PreferencesService.getInstallDocument()) {
          const xmlFilesWithIndentation = PreferencesService.getPreferencesXmlFilesWithIndentation();
          if (xmlFilesWithIndentation) {
            const { install, ...xmls } = xmlFilesWithIndentation;

            if (install) {
              const localInstallStr = localDoc.getText('install');
              localDoc.transact(() => {
                localInstallStr.delete(0, localInstallStr?.length);
                localInstallStr.insert(0, install);
              });
            }

            const circuitVersion = state.project.circuitVersion;
            if (circuitVersion) {
              const circuitVersionStr = localDoc.getText('circuitVersion');
              localDoc.transact(() => {
                circuitVersionStr.delete(0, circuitVersionStr?.length);
                circuitVersionStr.insert(0, circuitVersion);
              });
            }

            const circuitDescription = state.project.circuitDescription;
            if (circuitDescription) {
              const circuitDescriptionStr = localDoc.getText('circuitDescription');
              localDoc.transact(() => {
                circuitDescriptionStr.delete(0, circuitDescriptionStr?.length);
                circuitDescriptionStr.insert(0, circuitDescription);
              });
            }

            const enableDevicePrefManagement = state.project.enableDevicePrefManagement;
            const enableDevicePrefManagementMap = localDoc.getMap('enableDevicePrefManagement');
            enableDevicePrefManagementMap.set('state', enableDevicePrefManagement);

            if (xmls) {
              localPrefMap.set('xmls', xmls);
            }

            const projectName = PreferencesService.getProjectName();
            if (projectName) {
              localPrefMap.set('projectName', projectName);
            }

            const circuitName = getLoadedCircuitName().replace('.geojson', '');
            if (circuitName) {
              localPrefMap.set('circuitName', circuitName);
            }

            const projectNEWVersion = PreferencesService.getNEW4XCoreVersion();
            if (projectNEWVersion) {
              localPrefMap.set('projectNEWVersion', projectNEWVersion);
            }

            const availableCircuits = PreferencesService.getAvailableCircuitsName();
            if (availableCircuits) {
              localPrefMap.set('availableCircuits', availableCircuits);
            }
          }
        }

        syncYJSLocalToRemote();
      });

      return actions;
    })
  );
}

export function shareSelectedShapes$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    ofType(
      CircuitActionTypes.SelectShape,
      CircuitActionTypes.SelectMultipleShapes,
      CircuitActionTypes.UnselectShape,
      CircuitActionTypes.UnselectSeveralShapes
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions = [];

      if (!state.multiplayer.multiplayer || !awareness) return actions;

      const localSelectedShapes = state.local.selectedShapesData.map((shape) => shape.id);

      localDoc.getMap('selectedShapes').set(awareness.clientID.toString(), localSelectedShapes);
      syncYJSLocalToRemote();

      return actions;
    })
  );
}

export function shareClearShapesSelection$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<VoidFunction> {
  return actions$.pipe(
    ofType(CircuitActionTypes.ClearShapesSelection),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions = [];

      const selectedShapesMap = localDoc.getMap('selectedShapes');
      if (!state.multiplayer.multiplayer || !awareness || !selectedShapesMap.has(awareness.clientID.toString()))
        return actions;

      selectedShapesMap.delete(awareness.clientID.toString());
      syncYJSLocalToRemote();

      return actions;
    })
  );
}

type ErrorAction = {
  type: string;
  payload: {
    error: string;
    info?: boolean;
    message?: string;
  };
};

export function failureActionSnackbar$(actions$: ActionsObservable<ErrorAction>): Observable<VoidFunction> {
  return actions$.pipe(
    filter((action) => action.type.includes('Failure')),
    switchMap((action) => {
      if (action.payload.info) {
        if (action.payload.message) {
          SnackbarUtils.info(action.payload.message);
        }
      } else if (
        action.type === MapsActionTypes.ImportLidarFailure ||
        action.type === MapsActionTypes.ImportMapImageFailure
      ) {
        SnackbarUtils.error(action.payload.error);
      } else {
        // eslint-disable-next-line no-console
        console.warn('Failure: no error message found for the snackbar');
      }

      return [];
    })
  );
}

export function changeShapeVisibility$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<ReturnType<ReturnType<typeof getShapeSaveAction>> | UnselectSeveralShapes | void> {
  return actions$.pipe(
    ofType<ChangeShapeVisibility>(CircuitActionTypes.ChangeShapeVisibility),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: (ReturnType<ReturnType<typeof getShapeSaveAction>> | UnselectSeveralShapes)[] = [];
      const payload = action.payload;
      const ids = Array.isArray(payload.id) ? payload.id : [payload.id];

      /** we unselect the selected shapes before hiding them */
      const selectedShapes = state.local.selectedShapesData;
      const shapesToUnselect: SelectedShapesData = [];
      selectedShapes.forEach((shape) => {
        if (ids.includes(shape.id)) {
          shapesToUnselect.push(shape);
        }
      });
      actions.push(
        unselectSeveralCircuitShapesAction({
          unselectedShapes: shapesToUnselect,
          userAction: true,
        })
      );

      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const shape = CircuitService.getShape(id);
        if (!shape) {
          // eslint-disable-next-line no-console
          console.error(`Shape ${id} not found`);
          continue;
        }

        const shapeType = shape.properties.type;
        const shapeSaveAction = getShapeSaveAction(shapeType);

        actions.push(
          shapeSaveAction({
            ...shape,
            hidden: payload.hidden ?? !shape.hidden,
          })
        );
      }

      return actions;
    })
  );
}

export function updateTurnsAfterAlignElements$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<UpdateTurn | VoidFunction> {
  return actions$.pipe(
    ofType<AlignElement>(CircuitActionTypes.AlignElement),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const actions: UpdateTurn[] = [];

      const selectedShapes = state.local.selectedShapesData;
      const selectedShapesIdSet = new Set(selectedShapes.map((shape) => shape.id));
      const segmentsToUpdate = new Set<string>();
      const turnsToUpdate = new Set<string>();

      selectedShapes.forEach((shape) => {
        if (shape.type === ShapeTypes.SegmentShape) {
          segmentsToUpdate.add(shape.id);
        }
      });

      const segmentsIds = state.circuit.present.segments.ids;
      const segments = state.circuit.present.segments.entities;
      const stockZones = state.circuit.present.stockZones.entities;
      const turnsIds = state.circuit.present.turns.ids;
      const turns = state.circuit.present.turns.entities;

      const selectedStockLinesIdsSet = new Set(
        selectedShapes
          .filter((shape) => shape.type === ShapeTypes.StockZoneShape)
          .map((shape) => stockZones[shape.id])
          .filter((stockZone) => isCircuitStockZone(stockZone))
          .flatMap((stockZone) => stockZone.properties.slots.map((slot) => slot.id))
      );

      segmentsIds.forEach((segmentId) => {
        const segment = segments[segmentId];
        if (!segment) return;

        const segmentBelongToRack = segment.properties.rack && selectedShapesIdSet.has(segment.properties.rack);
        const segmentBelongToStockline =
          segment.properties.stockLine && selectedStockLinesIdsSet.has(segment.properties.stockLine);

        if (segmentBelongToRack || segmentBelongToStockline) {
          segmentsToUpdate.add(segmentId);
        }
      });

      turnsIds.forEach((turnId) => {
        const turn = turns[turnId];
        if (!turn) return;

        const turnConnectedToOriginSegment = turn.properties.originId && segmentsToUpdate.has(turn.properties.originId);
        const turnConnectedToDestinationSegment =
          turn.properties.destinationId && segmentsToUpdate.has(turn.properties.destinationId);

        if (turnConnectedToOriginSegment) {
          turnsToUpdate.add(turnId);
        }

        if (turnConnectedToDestinationSegment) {
          turnsToUpdate.add(turnId);
        }
      });

      if (turnsToUpdate.size > 0) {
        actions.push(updateTurnAction({ idToUpdate: Array.from(turnsToUpdate) }));
      }

      return actions;
    })
  );
}

export function combineCircuitEpics(): any {
  return combineEpics(
    deleteSelectedShapes$.bind(null),
    importCircuit$.bind(null),
    addCircuitZone$.bind(null),
    addCircuitStockZone$.bind(null),
    addCircuitRack$.bind(null),
    addCircuitPoint$.bind(null),
    addCircuitDevice$.bind(null),
    addCircuitSegment$.bind(null),
    addCircuitMeasurer$.bind(null),
    addCircuitNotes$.bind(null),
    deleteShape$.bind(null),
    selectShapesInRect$.bind(null),
    combineCopyPasteEpics(),
    changeLockState$.bind(null),
    changeLockStateSelection$.bind(null),
    attachMeasurer$.bind(null),
    detachMeasurer$.bind(null),
    updateMeasurer$.bind(null),
    pointMoved$.bind(null),
    deleteLayer$.bind(null),
    applyFilters$.bind(null),
    bringToFrontOrBack$.bind(null),
    computeTurn$.bind(null),
    updateTurn$.bind(null),
    segmentMoved$.bind(null),
    updateSegmentsAfterTurn$.bind(null),
    updateSegmentsPortions.bind(null),
    goBackToHandTool$.bind(null),
    createOrUpdateOrDeleteRackExtendedLength$.bind(null),
    createOrUpdateOrDeleteStockzoneExtendedLength$.bind(null),
    moveSelection$.bind(null),
    zoneMoved$.bind(null),
    transferShapesToAnotherLayer$.bind(null),
    handleSaveDevice$.bind(null),
    updateRack$.bind(null),
    filterCreateShapeActionsEpic.bind(null),
    propagateStateToOtherPeople$.bind(null),
    propagateCircuitDescription$.bind(null),
    propagateCircuitVersion$.bind(null),
    propagateDevicePrefManagement$.bind(null),
    connectionToYJSDoc$.bind(null),
    shareSelectedShapes$.bind(null),
    shareClearShapesSelection$.bind(null),
    failureActionSnackbar$.bind(null),
    changeShapeVisibility$.bind(null),
    updateLayer$.bind(null),
    showSnackbarOnHiddenLayerDeletion$.bind(null),
    updateTurnsAfterAlignElements$.bind(null)
  );
}

export function getShapeSaveAction(
  shapeType: ShapeTypes
):
  | typeof saveRackAction
  | typeof saveZoneAction
  | typeof saveStockZoneAction
  | typeof savePointAction
  | typeof saveSegmentAction
  | typeof saveTurnAction
  | typeof saveMeasurerAction
  | typeof saveDeviceAction
  | typeof saveNoteAction {
  switch (shapeType) {
    case ShapeTypes.ZoneShape: {
      return saveZoneAction;
    }

    case ShapeTypes.StockZoneShape: {
      return saveStockZoneAction;
    }

    case ShapeTypes.RackShape: {
      return saveRackAction;
    }

    case ShapeTypes.PointShape: {
      return savePointAction;
    }

    case ShapeTypes.SegmentShape: {
      return saveSegmentAction;
    }

    case ShapeTypes.MeasurerShape: {
      return saveMeasurerAction;
    }

    case ShapeTypes.TurnShape: {
      return saveTurnAction;
    }

    case ShapeTypes.DeviceShape: {
      return saveDeviceAction;
    }

    case ShapeTypes.NoteShape: {
      return saveNoteAction;
    }
  }

  // eslint-disable-next-line no-console
  console.error('Unknown shape type:', shapeType);

  assert<Equals<typeof shapeType, never>>();
}
