import { z } from 'zod';

export const perfoRecordDataSchema = z.object({
  /** Timestamp when the record occured [s] */
  timestamp: z.number(),
  /** State of the missing when the record occured
   *
   * 0: Available
   * 1: Switch
   * 3: RunToTaxi
   * 5: Calibration
   * 7: Run
   * 16: Manual
   * 32: Error Mission
   * 48: Error Navigation
   * 64: Error Battery
   * 255: Undefined
   */
  missionState: z.number(),
  /** Speed of the wheels when the record occured [m/s] */
  wheelSpeed: z.number(),
  /** Height of the forks when the record occured [m] */
  forksHeight: z.number(),
  rpk: z.number(),
  /** ID of the task the robot was doing when the record occured */
  taskID: z.number(),
  /**
   * 0 = start of the action
   * 1 = end of the action
   **/
  beginend: z.number().int(),
  /**
   * Category of the action
   *
   * 0: Undefined
   * 1: PERFO_TURRET_DISC
   * 2: PERFO_FRK_VERTICAL
   * 3: PERFO_FRK_ROTATION
   * 4: PERFO_FRK_RETRACT
   * 5: PERFO_MISS_RUN
   * 6: PERFO_TASK_RUN
   * 7: PERFO_TAXI_RUN
   * 8: PERFO_RPK_EVACUATION
   * 9: PERFO_RPK_APPROACH
   * 10: PERFO_RPK_LASTREVERSE
   * 11: PERFO_RPK_DESTINATION
   * 12: PERFO_NAV_RUN
   * 13: PERFO_NAV_DISC
   * 14: PERFO_NAV_PORTION
   * 15: PERFO_KERNEL_RUN
   * 16: PERFO_KERNEL_NOTINIT
   * 17: PERFO_BATT_LEVEL
   * 18: PERFO_DELETE_DB
   * 19: PERFO_RESET_DB
   * 20: PERFO_EXPORT_DB
   * 21: PERFO_KILL_APP
   * 22: PERFO_DEBUG_SET
   * 23: PERFO_STOP_RECORD
   **/
  category: z.number().int(),
  /**
   * The category label in string
   */
  categoryLabel: z.string(),
  /**
   * The step index
   * If the task is pick -> move -> drop
   * the step index will be 1 for pick
   * 2 for move
   * 3 for drop
   * 0 when the mission is still not loaded
   */
  stepID: z.number(),
  /** The robot ID on which the record occured (1 = first robot, etc.) */
  robotID: z.number().int(),
  /** The ID of the record (increments) */
  recordID: z.number().int(),
});

export type PerfoRecordData = z.infer<typeof perfoRecordDataSchema>;

export const perfoDataSchema = z.object({
  /**
   * Array of records
   */
  Records: z.array(perfoRecordDataSchema),
});
