interface SmallChipProps {
  color: string;
}
export function SmallChip({ color }: SmallChipProps): JSX.Element {
  return (
    <span
      style={{
        background: color,
        width: '10px',
        height: '10px',
        borderRadius: '50%',
      }}
    ></span>
  );
}
