import {
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Box } from '@mui/system';
import { saveCircuitToHistoryAction } from 'actions/circuit';
import { saveNoteAction } from 'actions/notes';
import { ShapeTypes } from 'models/circuit';
import { isNoteDisplayMode } from 'models/circuit.guard';
import { useCallback, useEffect, useState } from 'react';
import type { LoadedNote } from 'reducers/circuit/state';
import { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { convertNoteSizeToPx } from 'utils/circuit/note';
import { theme } from 'utils/mui-theme';
import { PropertiesComponent } from './properties-component';

interface NotePropertiesProps {
  noteId: string;
}

export const NoteProperties = ({ noteId }: NotePropertiesProps): JSX.Element => {
  const note = useAppSelector((state) => state.circuit.present.notes.entities[noteId]) as LoadedNote | undefined;
  const dispatch = useAppDispatch();

  const [noteName, setNoteName] = useState(note?.properties.name || '');

  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!note) return;

    const name = noteName;
    dispatch(saveCircuitToHistoryAction());
    dispatch(
      saveNoteAction({
        id: note.id,
        properties: {
          ...note.properties,
          name,
        },
      })
    );
  }, 400);

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      if (newName?.length < 1000) {
        setNoteName(newName);
      }

      updatePropertiesDebounced();
    },
    [updatePropertiesDebounced]
  );

  const locked = !!note?.properties?.locked;

  useEffect(() => {
    if (note?.properties.name) setNoteName(note.properties.name);
  }, [note?.properties.name]);

  const displayMode = note?.properties.displayMode;

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newDisplay: string) => {
      if (!note || !note.id) {
        throw new Error('note must be defined');
      }

      if (!isNoteDisplayMode(newDisplay)) {
        // eslint-disable-next-line no-console
        console.error(`Wrong type for the note display mode: ${newDisplay}`);

        return;
      }

      dispatch(
        saveNoteAction({
          id: note.id,
          properties: { ...note.properties, displayMode: newDisplay },
        })
      );
    },
    [dispatch, note]
  );

  const textSize = note?.properties.size;

  const handleNoteSize = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!note || !note.id) {
        throw new Error('note must be defined');
      }

      const newSizeInPx = parseInt((event.target as HTMLInputElement).value, 10);

      if (isNaN(newSizeInPx)) {
        throw new Error(`Wrong value for the new size in px: ${newSizeInPx}`);
      }

      dispatch(
        saveNoteAction({
          id: note.id,
          properties: { ...note.properties, size: newSizeInPx },
        })
      );
    },
    [dispatch, note]
  );

  return !noteId || !note ? (
    <></>
  ) : (
    <PropertiesComponent
      shape={note}
      shapeId={noteId}
      shapeType={ShapeTypes.NoteShape}
      sx={{
        maxWidth: 'min-content',
      }}
    >
      <ToggleButtonGroup
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', my: 2 }}
        color="primary"
        exclusive
        value={displayMode}
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton sx={{ textTransform: 'capitalize' }} value={'icon'}>
          Icon
        </ToggleButton>
        <ToggleButton sx={{ textTransform: 'capitalize' }} value={'text'}>
          Text
        </ToggleButton>
      </ToggleButtonGroup>
      <Box component="div" sx={{ flexDirection: 'column' }}>
        <Collapse in={displayMode === 'text'} timeout="auto">
          <FormControl>
            <Divider sx={{ marginBottom: '4px' }}>Text size</Divider>
            <RadioGroup
              value={textSize}
              onChange={handleNoteSize}
              sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
            >
              <FormControlLabel value={convertNoteSizeToPx('S')} control={<Radio size="small" />} label="S" />
              <FormControlLabel value={convertNoteSizeToPx('M')} control={<Radio size="small" />} label="M" />
              <FormControlLabel value={convertNoteSizeToPx('L')} control={<Radio size="small" />} label="L" />
              <FormControlLabel value={convertNoteSizeToPx('XL')} control={<Radio size="small" />} label="XL" />
            </RadioGroup>
          </FormControl>
        </Collapse>
      </Box>
      <TextField
        value={noteName}
        onChange={onNameChangeHandler}
        fullWidth
        margin="dense"
        multiline
        rows={4}
        label={<>Note</>}
        disabled={locked}
        size="small"
        sx={{
          color: locked ? theme.palette.grey[300] : undefined,
          pointerEvents: locked ? 'none' : undefined,
        }}
      />
    </PropertiesComponent>
  );
};
