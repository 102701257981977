import { unselectSeveralCircuitShapesAction } from 'actions/circuit';
import type { EditorDrawing } from 'drawings/editor.drawing';
import { findShapeOrientation, pDistance, rotateCoordinates, rotatePolygon2 } from 'drawings/helpers';
import type { Feature, LineString, Point, Polygon, Position } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type {
  Circuit,
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitPortion,
  CircuitRack,
  CircuitSegment,
  CircuitShape,
  CircuitShapes,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
  ComboxVersionType,
  DeviceType,
  GeoJsonCircuit,
  NetworkType,
  PalletPosition,
  PinType,
  Position3D,
  RackCellTemplate,
  RackColumn,
  RackUpright,
  Size3D,
  SlotArray,
} from 'models/circuit';
import {
  Intersection,
  MeasurementType,
  PatternTypes,
  ReferenceMethodX,
  ReferenceMethodY,
  ShapeTypes,
} from 'models/circuit';
import { devicesData } from 'models/devices';
import { memoize } from 'moderndash';
import type { CircuitState, LoadedPoint, Measurer, Rack, Segment, StockZone, Turn, Zone } from 'reducers/circuit/state';
import type { SelectedShapesData } from 'reducers/local/state';
import type { AppState } from 'reducers/state';
import type { AnyAction, Store } from 'redux';
import type { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import {
  defaultExtendedLength,
  getDefaultColumnWidth,
  getDefaultRackCell,
  getDefaultRackCellTemplate,
  getDefaultUpright,
  getEuroPallet,
} from 'utils/circuit/default-circuit-shapes';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { convertNoteSizeToPx } from 'utils/circuit/note';
import { computeLoadsPosition } from 'utils/circuit/racks-compute-load-position';
import { isCircuitTurn } from 'utils/circuit/shape-guards';
import { isPositiveInt } from 'utils/circuit/utils';
import { getConfig } from 'utils/config';
import { toRad } from 'utils/helpers';
import { isDefined } from 'utils/ts/is-defined';

const epsilon = 1e-5;

const ID_COLLAB_MIN = 100000000;

interface CreateGeoJSONDeviceOpts {
  IP?: string;
  name?: string;
  displayName?: string;
  frequency?: number;
  layerId?: string;
  network?: NetworkType;
  comboxVersion?: ComboxVersionType;
  gateway?: string;
}

export class CircuitService {
  /**
   * Get the store state
   * @returns the store
   */
  private static getStoreState(): AppState {
    return window.getStoreState();
  }

  public static get zones(): Zone[] {
    return Object.values(this.getStoreState().circuit.present.zones.entities);
  }

  public static get stockZones(): StockZone[] {
    return Object.values(this.getStoreState().circuit.present.stockZones.entities);
  }

  public static get racks(): Rack[] {
    return Object.values(this.getStoreState().circuit.present.racks.entities);
  }

  public static get points(): LoadedPoint[] {
    return Object.values(this.getStoreState().circuit.present.points.entities);
  }

  public static get segments(): Segment[] {
    return Object.values(this.getStoreState().circuit.present.segments.entities);
  }

  public static get measurers(): Measurer[] {
    return Object.values(this.getStoreState().circuit.present.measurers.entities);
  }

  public static get turns(): Turn[] {
    return Object.values(this.getStoreState().circuit.present.turns.entities);
  }

  public static get devices(): CircuitDevice[] {
    return Object.values(this.getStoreState().circuit.present.devices.entities);
  }

  public static get notes(): CircuitNote[] {
    return Object.values(this.getStoreState().circuit.present.notes.entities);
  }

  public static get cellTemplates(): RackCellTemplate[] {
    return Object.values(this.getStoreState().circuit.present.cellTemplates.entities);
  }

  public static existingStockEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.stockZones.entities[id];
  }

  public static existingRackEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.racks.entities[id];
  }

  public static existingPointEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.points.entities[id];
  }

  public static existingSegmentEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.segments.entities[id];
  }

  public static existingMeasurerEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.measurers.entities[id];
  }

  public static existingTurnEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.turns.entities[id];
  }

  public static existingDeviceEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.devices.entities[id];
  }

  public static existingNoteEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.notes.entities[id];
  }

  public static existingCellEntity(id: string): boolean {
    return !!this.getStoreState().circuit.present.cellTemplates.entities[id];
  }

  public static existingLayer(id: string): boolean {
    return !!this.getStoreState().circuit.present.layers.layers[id];
  }

  public static getSelectedLayer(): string {
    return this.getStoreState().circuit.present.layers.selectedLayer;
  }

  private static drawingRefence: EditorDrawing;
  private static onShapeGeometryUpdatedRef: (
    type: string,
    shapeId: string,
    geometry: Polygon | Point | LineString,
    orientation?: any
  ) => void;

  public static setDrawingReference(drawingRefence: EditorDrawing | null): void {
    if (drawingRefence) this.drawingRefence = drawingRefence;
  }

  public static getDrawingReference(): EditorDrawing {
    return this.drawingRefence;
  }

  public static setOnShapeGeometryUpdatedRef(onShapeGeometryUpdated: {
    (type: string, shapeId: string, geometry: Polygon | Point | LineString, orientation?: any): void;
    (type: string, shapeId: string, geometry: Polygon | Point | LineString, orientation?: any): void;
  }): void {
    if (onShapeGeometryUpdated) this.onShapeGeometryUpdatedRef = onShapeGeometryUpdated;
  }

  /**
   * Get all the shapes from the store
   * @returns the circuit shapes
   */
  public static getCircuitElements(): Circuit {
    return {
      zones: CircuitService.zones,
      stockZones: CircuitService.stockZones,
      racks: CircuitService.racks,
      points: CircuitService.points,
      segments: CircuitService.segments,
      measurers: CircuitService.measurers,
      turns: CircuitService.turns,
      devices: CircuitService.devices,
      notes: CircuitService.notes,
    };
  }

  /**
   * Get the maximum shape ID in the circuit state.
   * @returns The maximum shape ID.
   */
  public static getMaxShapeId(): number {
    const circuitState = this.getStoreState().circuit.present;

    let maxId = -Infinity;

    Object.values(circuitState).forEach((shapeType: CircuitState[keyof CircuitState]) => {
      if ('ids' in shapeType) {
        const idsOfThisShapeToConsider = shapeType.ids
          .map((idStr) => parseInt(idStr, 10))
          .filter((id) => id < ID_COLLAB_MIN); // we want to ignore the "collaborative" ids that are defined over the ID_COLLAB_MIN value
        const maxOfThisShapeType = Math.max(...idsOfThisShapeToConsider);

        if (maxOfThisShapeType > maxId) {
          maxId = maxOfThisShapeType;
        }
      }
    });

    return maxId;
  }

  /**
   * Get all the shapes of a certain type (and a certain layer (optional))
   * If no type is specified, all the shapes are returned
   * @param shapeType the shape type to retrieve
   * @param layerId (optional) the layer id to retrieve, can be a list of string for multiple layers
   * @returns the shapes array of the given type
   */
  public static getShapesOfThisType(shapeType?: ShapeTypes, layerId?: string | string[]): CircuitShape[] {
    let shapes: CircuitShape[];
    switch (shapeType) {
      case ShapeTypes.ZoneShape: {
        shapes = this.zones;
        break;
      }

      case ShapeTypes.PointShape: {
        shapes = this.points;
        break;
      }

      case ShapeTypes.SegmentShape: {
        shapes = this.segments;
        break;
      }

      case ShapeTypes.MeasurerShape: {
        shapes = this.measurers;
        break;
      }

      case ShapeTypes.TurnShape: {
        shapes = this.turns;
        break;
      }

      case ShapeTypes.StockZoneShape: {
        shapes = this.stockZones;
        break;
      }

      case ShapeTypes.RackShape: {
        shapes = this.racks;
        break;
      }

      case ShapeTypes.DeviceShape: {
        shapes = this.devices;
        break;
      }

      case ShapeTypes.NoteShape: {
        shapes = this.notes;
        break;
      }

      default: {
        if (shapeType) {
          // eslint-disable-next-line no-console
          console.error(`Shape type ${shapeType} not recognized`);

          assert<Equals<typeof shapeType, never>>();
        }

        shapes = this.getShapes();
      }
    }

    if (layerId) {
      if (typeof layerId === 'string') {
        shapes = shapes.filter((shape) => shape.properties.layerId === layerId);
      } else if (typeof layerId === 'object' && Array.isArray(layerId)) {
        shapes = shapes.filter((shape) => layerId.includes(shape.properties.layerId));
      } else {
        // eslint-disable-next-line no-console
        console.error(`Layer id ${layerId} not recognized`);

        assert<Equals<typeof layerId, never>>();
      }
    }

    return shapes;
  }

  /**
   * Get the circuit property of the type as a string
   * @param shapeType the shape type to retrieve the store type
   * @returns the store type as a string
   */
  public static getStorePropertyOfShapeType(
    shapeType: ShapeTypes
  ): 'zones' | 'stockZones' | 'racks' | 'points' | 'segments' | 'measurers' | 'turns' | 'devices' | 'notes' {
    switch (shapeType) {
      case ShapeTypes.ZoneShape: {
        return 'zones';
      }

      case ShapeTypes.PointShape: {
        return 'points';
      }

      case ShapeTypes.SegmentShape: {
        return 'segments';
      }

      case ShapeTypes.MeasurerShape: {
        return 'measurers';
      }

      case ShapeTypes.TurnShape: {
        return 'turns';
      }

      case ShapeTypes.StockZoneShape: {
        return 'stockZones';
      }

      case ShapeTypes.RackShape: {
        return 'racks';
      }

      case ShapeTypes.DeviceShape: {
        return 'devices';
      }

      case ShapeTypes.NoteShape: {
        return 'notes';
      }

      default:
        throw new Error('Shape type not recognized');
    }
  }

  /**
   * Get a shape by name
   * @param name name of the shape
   * @param layerId {optional} layerId of the shape
   * @returns the shape if found, undefined otherwise
   */
  public static getShapeByName(name: string, layerId?: string): CircuitShape | undefined {
    const shapes = this.getShapes(layerId);

    return shapes.find((shape) => 'name' in shape.properties && shape.properties.name === name);
  }

  /**
   * Retrieve a shape from its id
   * @param shapeId the shape id
   * @param shapeType (optional) the shape type, to optimize the search
   * @param layerId (optional) the layer id, to optimize the search
   * @returns the circuit shape returned
   */
  public static getShape(shapeId: string, shapeType?: ShapeTypes, layerId?: string): CircuitShape | undefined {
    const shapes = this.getShapesOfThisType(shapeType, layerId);

    return shapes.find((shape) => {
      return shape.id === shapeId;
    });
  }

  /**
   * Get all the shapes from the store
   * @param layerId (optional) filter shapes by a layer (id)
   * @param options (optional) options to exclude some shapes
   * @returns the circuit shape
   */
  public static getShapes(
    layerId?: string,
    options: {
      exclude?: Partial<Record<ShapeTypes, boolean | undefined>>;
    } = {
      exclude: {},
    }
  ): CircuitShape[] {
    const exclude = options.exclude ?? {};

    let shapes = [
      ...(exclude[ShapeTypes.ZoneShape] !== true ? this.zones : []),
      ...(exclude[ShapeTypes.StockZoneShape] !== true ? this.stockZones : []),
      ...(exclude[ShapeTypes.PointShape] !== true ? this.points : []),
      ...(exclude[ShapeTypes.SegmentShape] !== true ? this.segments : []),
      ...(exclude[ShapeTypes.MeasurerShape] !== true ? this.measurers : []),
      ...(exclude[ShapeTypes.TurnShape] !== true ? this.turns : []),
      ...(exclude[ShapeTypes.RackShape] !== true ? this.racks : []),
      ...(exclude[ShapeTypes.DeviceShape] !== true ? this.devices : []),
      ...(exclude[ShapeTypes.NoteShape] !== true ? this.notes : []),
    ];
    if (layerId) {
      shapes = shapes.filter((shape) => shape.properties.layerId === layerId);
    }

    return shapes;
  }

  public static getShapesNames(
    layerId?: string,
    options: {
      exclude?: Partial<Record<ShapeTypes, boolean | undefined>>;
    } = {
      exclude: {},
    }
  ): string[] {
    return CircuitService.getShapes(layerId, options)
      .map((shape) => shape.properties.name)
      .filter(isDefined);
  }

  /**
   * Hide or display a shape
   * @param displayState the new display state (true = display, false = hide)
   * @param shapesToHide a list of ids of a shape type of the shapes to hide/display
   * @param store (optional) the store to update
   * @param atomic (optional) do we want to hide the shape one by one or hide all the shapes at once with css
   */
  public static changeDisplayState(
    displayState: boolean,
    shapesToHide: ShapeTypes | string[],
    store?: Store<AppState, AnyAction>,
    atomic = true
  ): void {
    const shapes = (
      typeof shapesToHide === 'string'
        ? this.getShapesOfThisType(shapesToHide)
        : shapesToHide.map((shapeId) => this.getShape(shapeId)).filter((shape) => !!shape)
    ) as CircuitShape[];
    const shapesIds = shapes.map((shape) => shape.id as string);

    if (atomic) {
      // not good we don't go through the reducer.........
      shapes.forEach((shape) => {
        shape.hidden = !displayState; // not good, we should just use css, see ticket re-881 https://balyorobot.atlassian.net/jira/software/c/projects/RE/boards/10/backlog?view=detail&selectedIssue=RE-881&issueLimit=100
      });
    }

    // we unselect the shapes that we hide
    if (!displayState && store && store.dispatch && store.getState) {
      const unselectShapesData: SelectedShapesData = [];
      const selectedShapes = store.getState().local.selectedShapesData;

      selectedShapes.forEach((shape) => {
        if (!shape.id) return;

        if (shapesIds.includes(shape.id)) {
          unselectShapesData.push({
            id: shape.id,
            type: shape.type,
          });
        }
      });

      store.dispatch(
        unselectSeveralCircuitShapesAction({
          unselectedShapes: unselectShapesData,
        })
      );
    }
  }

  /**
   * Returns elements sorted by types from a list of elements
   * @param shapes the list of elements
   * @returns the object sorted by types
   */
  public static circuitShapeSorting(shapes: CircuitShapes): Observable<Circuit> {
    return new Observable((observer: Observer<Circuit>) => {
      const zones = shapes
        .filter((shape: CircuitShape): shape is CircuitZone => {
          return shape.properties.type === ShapeTypes.ZoneShape;
        })
        .map((zone) => {
          if (!zone.id) zone.id = generateShapeId();

          return zone;
        });

      const stockZones = shapes
        .filter((shape: CircuitShape): shape is CircuitStockZone => {
          return shape.properties.type === ShapeTypes.StockZoneShape;
        })
        .map((stockZone) => {
          if (!stockZone.id) stockZone.id = generateShapeId();

          return stockZone;
        });

      const racks = shapes
        .filter((shape: CircuitShape): shape is CircuitRack => {
          return shape.properties.type === ShapeTypes.RackShape;
        })
        .map((rack) => {
          if (!rack.id) rack.id = generateShapeId();

          return rack;
        });

      const points = shapes
        .filter((shape: CircuitShape): shape is CircuitPoint => {
          return shape.properties.type === ShapeTypes.PointShape;
        })
        .map((point) => {
          if (!point.id) point.id = generateShapeId();

          return point;
        });

      const segments = shapes
        .filter((shape: CircuitShape): shape is CircuitSegment => {
          return shape.properties.type === ShapeTypes.SegmentShape;
        })
        .map((segment) => {
          if (!segment.id) segment.id = generateShapeId();

          return segment;
        });

      const measurers = shapes
        .filter((shape: CircuitShape): shape is CircuitMeasurer => {
          return shape.properties.type === ShapeTypes.MeasurerShape;
        })
        .map((measurer) => {
          if (!measurer.id) measurer.id = generateShapeId();

          return measurer;
        });

      const turns = shapes
        .filter((shape: CircuitShape): shape is CircuitTurn => {
          return shape.properties.type === ShapeTypes.TurnShape;
        })
        .map((turn) => {
          if (!turn.id) turn.id = generateShapeId();

          return turn;
        });
      const devices = shapes
        .filter((shape: CircuitShape): shape is CircuitDevice => {
          return shape.properties.type === ShapeTypes.DeviceShape;
        })
        .map((device) => {
          if (!device.id) device.id = generateShapeId();

          return device;
        });
      const notes = shapes
        .filter((shape: CircuitShape): shape is CircuitNote => {
          return shape.properties.type === ShapeTypes.NoteShape;
        })
        .map((note) => {
          if (!note.id) note.id = generateShapeId();

          return note;
        });

      const circuit: Circuit = {
        zones,
        stockZones,
        racks,
        points,
        segments,
        measurers,
        turns,
        devices,
        notes,
      };

      observer.next(circuit);

      return observer.complete();
    });
  }

  /**
   * Generate a name for a shape
   * @param type the type of the shape
   * @returns the generated name
   */
  public static generateName(type: ShapeTypes, shapesOfThisType?: CircuitShape[]): string {
    let unique = true;
    let elementNb: number;
    let elementName: string;

    do {
      elementNb = (parseInt(localStorage[`naming.${type}`], 10) || 0) + 1;
      elementName = `${type}${elementNb.toString()}`.toLowerCase();

      if (shapesOfThisType) {
        // eslint-disable-next-line no-loop-func
        unique = !shapesOfThisType.find((shape) => shape.properties.name === elementName);
      } else {
        unique = CircuitService.getShapeByName(elementName) === undefined;
      }

      localStorage[`naming.${type}`] = elementNb;
    } while (!unique);

    return elementName;
  }

  /**
   * Create a geojson zone ready to be inserted into the store
   * @param coord the left-top an the right-bottom coordinates of the zone
   * @param type the zone type (Rectangle, Triangle)
   * @param layerId
   * @returns the newly created zone
   */
  public static createGeoJSONZone(
    coord: number[][],
    type = 'Rectangle',
    layerId = CircuitService.getSelectedLayer()
  ): Observable<CircuitZone> {
    return new Observable((observer: Observer<CircuitZone>) => {
      const point1 = coord[0];
      const point2 = coord[1];

      const x1 = point1[0];
      const y1 = point1[1];
      const x2 = point2[0];
      const y2 = point2[1];

      const newShapeId = generateShapeId();

      const coordinates = [[x1, y1], [x2, y1], [x2, y2], type === 'Triangle' ? undefined : [x1, y2], [x1, y1]].filter(
        (x) => x !== undefined
      ) as Position[];

      const addedZone: CircuitZone = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
        properties: {
          type: ShapeTypes.ZoneShape,
          name: CircuitService.generateName(ShapeTypes.ZoneShape),
          rules: [],
          intersectionType: Intersection.PointIntersection,
          layerId,
          prio: getMaxDisplayPriority(),
        },
      };

      observer.next(addedZone);

      return observer.complete();
    });
  }

  /**
   * Generate the slots data to be inserted in the stock zone properties
   * @param coord the left-top an the right-bottom coordinates of the zone
   * @param slotSize the size of the slots (in width and length)
   * @param gap the gap between the slots (in width and length)
   * @param length the length of the stockzone
   * @param width the width of the stockzone
   * @param palletTypes the types of pallets accepeted in the slots
   * @param palletPosition how are the pallets positioned in the slots (+ ability to add an offset)
   * @param palletSize the sizes of the pallets
   * @param tolerancePosition the tolerance of the pallets position
   * @param orientation the angle of the stock zone
   * @param previousSlots when uptading a stock zone, provide the previous slots data
   * @param customGapSlots custom gap if you don't want the same gap between the slots
   * @param customGapLines custom gap if you don't want the same gap between the lines
   * @param name the name of the stockzone (otherwise automatically generated)
   * @returns the slots data
   */
  public static generateStockZoneSlots(
    coord: number[][],
    slotSize: Size3D,

    gap: Size3D,
    length: number,
    width: number,
    palletTypes: string[] = [getEuroPallet().name],
    palletPosition: PalletPosition = {
      offsetX: 0,
      offsetY: 0,
      referenceX: ReferenceMethodX.frontEdge,
      referenceY: ReferenceMethodY.center,
    },
    palletSize: Size3D = { length: 1.2, width: 0.8 },
    tolerancePosition: Position3D = { x: 0.02, y: 0.02, z: 0.01, cap: 0.01 },
    orientation = 0,
    previousSlots?: SlotArray[],
    customGapSlots?: number[][],
    customGapLines?: number[],
    name?: string,
    overwrite: {
      palletSize?: boolean;
      lineName?: boolean;
      lineNameOnlyIfNotUserGenerated?: boolean;
    } = {}
  ): SlotArray[] {
    if (!name) name = Math.random().toString(36).substring(2, 15);

    if (overwrite?.lineName === undefined) overwrite.lineName = false;
    if (overwrite?.palletSize === undefined) overwrite.palletSize = true;
    if (overwrite?.lineNameOnlyIfNotUserGenerated === undefined) overwrite.lineNameOnlyIfNotUserGenerated = false;

    const x1 = coord[0][0];
    const y1 = coord[0][1];

    const slots: SlotArray[] = [];

    let sumX = 0;
    let sumY = 0;

    const newlyAddedNames: string[] = [];
    for (let i = 0; i < width; i++) {
      const lineId = previousSlots && previousSlots[i]?.id;

      const userDefinedName = !overwrite.lineNameOnlyIfNotUserGenerated ? false : previousSlots?.[i]?.userEditedName;

      // generateStockZoneSlots can be called either at the initialization of the stockzone or could be
      // an update of the oroperties of the stockzone (such as the slots)
      // if the linename is not already defined we set a default name
      const lineName =
        previousSlots && previousSlots[i]?.name && !overwrite.lineName && userDefinedName
          ? previousSlots[i].name
          : `${name}-${i}`;

      const otherNamesToConsider = newlyAddedNames;

      const isNameAlreadyUsed = !areAllShapeNamesUnique(
        [lineName],
        lineId ? [lineId] : undefined,
        {
          ignoreDuplicatesBefore: true,
        },
        otherNamesToConsider
      );

      const slotLine: SlotArray = {
        id: lineId || generateShapeId(),
        name: lineName,
        userEditedName: !!userDefinedName,
        slots: [],
      };

      if (isNameAlreadyUsed) {
        const suggestedName = CircuitService.generateDifferentName(lineName, {
          shapeIdsToIgnore: lineId ? [lineId] : undefined,
        });

        slotLine.name = suggestedName;
      }

      newlyAddedNames.push(slotLine.name);

      sumX = customGapSlots && customGapSlots[i] && customGapSlots[i][0] !== undefined ? customGapSlots[i][0] : 0;

      for (let j = 0; j < length; j++) {
        const previousSlot =
          previousSlots && previousSlots[i] && previousSlots[i].slots[j] ? previousSlots[i].slots[j] : undefined;
        const slotId = (previousSlots && previousSlots[i]?.slots[j]?.id) || generateShapeId();

        const thisSlotSize = previousSlot ? previousSlot.slotSize : slotSize;
        const thisSlotGap: Size3D = {
          length:
            customGapSlots && customGapSlots[i] && customGapSlots[i][j + 1] !== undefined
              ? customGapSlots[i][j + 1] / 100
              : gap.length,
          width: customGapLines && customGapLines[i + 1] !== undefined ? customGapLines[i + 1] / 100 : gap.width,
        };

        const slotPosition: Position3D = {
          x: x1 + sumX,
          y: -y1 + sumY,
          z: 0,
          cap: orientation,
        };
        const geometry: Position[] = [
          [slotPosition.x, slotPosition.y],
          [slotPosition.x + slotSize.length * 100, slotPosition.y],
          [slotPosition.x + slotSize.length * 100, slotPosition.y + slotSize.width * 100],
          [slotPosition.x, slotPosition.y + slotSize.width * 100],
        ];

        slotLine.slots.push({
          id: slotId,
          name: slotId.toString(),
          palletPosition: previousSlot ? previousSlot.palletPosition : palletPosition,
          palletSize: previousSlot && !overwrite?.palletSize ? previousSlot.palletSize : palletSize,
          slotPosition,
          palletTypes: previousSlot && previousSlot.palletTypes ? previousSlot.palletTypes : palletTypes,
          slotSize: previousSlot ? previousSlot.slotSize : { ...slotSize },
          tolerancePosition: previousSlot ? previousSlot.tolerancePosition : tolerancePosition,
          freespaceMethod: 0,
          geometry,
        });

        sumX += (thisSlotSize.length + thisSlotGap.length) * 100;
        if (j === length - 1) sumY += (thisSlotSize.width + thisSlotGap.width) * 100;
      }

      slots.push(slotLine);
    }

    return slots;
  }

  /**
   * Compute the position of the geometry of the slots; ready to be displayed
   * @param slots the slots data
   * @param cap the angle of the slots
   * @returns the geometry of the slots
   */
  public static computeSlotsGeometry(slots: SlotArray[], cap: number, forceUpdate = false): SlotArray[] {
    // if the angle is small, we don't need to rotate the slots
    if (Math.abs(cap) < 1e-5 && !forceUpdate) return slots;

    if (!slots || !slots.length || !slots[0].slots || !slots[0].slots.length) return slots;

    const newSlots = [...slots];

    // we compute the gabarit of the slots
    let minX = 1 / 0;
    let maxX = -1 / 0;
    let minY = 1 / 0;
    // let maxY = -1 / 0;
    for (let i = 0, slotLinesLastIndex = newSlots[0].slots.length - 1; i < newSlots.length; i++) {
      const firstSlotInLine = newSlots[i].slots[0];
      if (firstSlotInLine.slotPosition.x < minX) {
        minX = firstSlotInLine.slotPosition.x;
      }

      const lastSlotInLine = newSlots[i].slots[slotLinesLastIndex];
      const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + lastSlotInLine.slotSize.length * 100;
      if (maxXLastSlotInLine > maxX) {
        maxX = maxXLastSlotInLine;
      }
    }

    minY = -newSlots[0].slots[0].slotPosition.y;
    /*
    maxY =
      -newSlots[slots.length - 1].slots[0].slotPosition.y - newSlots[slots.length - 1].slots[0].slotSize.width * 100;
    */

    for (let i = 0; i < newSlots.length; i++) {
      for (let j = 0; j < newSlots[i].slots.length; j++) {
        const slot = newSlots[i].slots[j];
        const slotSize = slot.slotSize;
        const slotPosition = slot.slotPosition;

        const geometry: Position[] = [
          [slotPosition.x, slotPosition.y],
          [slotPosition.x + slotSize.length * 100, slotPosition.y],
          [slotPosition.x + slotSize.length * 100, slotPosition.y + slotSize.width * 100],
          [slotPosition.x, slotPosition.y + slotSize.width * 100],
        ];
        slot.geometry = Math.abs(cap) > epsilon ? rotatePolygon2([minX, -minY], geometry, cap) : geometry;
      }
    }

    return newSlots;
  }

  /**
   * Create a geojson stock zone ready to be inserted into the store
   * @param coord the left-top and the right-bottom coordinates of the stock zone
   * @param type the type of the stock zone (always stockzone for now, can be single slot or something else in the future)
   * @param layerId the layer id of the stock zone
   * @param orientation the angle of the stock zone
   * @returns a geojson stock zone
   */
  public static createGeoJSONStockZone(
    coord: number[][],
    type = 'StockZone',
    layerId = CircuitService.getSelectedLayer(),
    orientation = 0,
    opts = {
      usePreferences: true,
    }
  ): Observable<CircuitStockZone> {
    return new Observable((observer: Observer<CircuitStockZone>) => {
      const point1 = coord[0];
      const point2 = coord[1];

      const x1_tmp = point1[0];
      const y1_tmp = point1[1];
      const x2_tmp = point2[0];
      const y2_tmp = point2[1];

      let x1 = Math.min(x1_tmp, x2_tmp);
      let y1 = Math.max(y1_tmp, y2_tmp);
      let x2 = Math.max(x1_tmp, x2_tmp);
      let y2 = Math.min(y1_tmp, y2_tmp);

      if (orientation === 90) {
        [x1, x2] = [x2, x1];
      } else if (orientation === -90) {
        [y1, y2] = [y2, y1];
      } else if (Math.abs(orientation) === 180) {
        [x1, x2] = [x2, x1];
        [y1, y2] = [y2, y1];
      }

      const newShapeId = generateShapeId();

      const coordinates = [
        [x1, y1],
        [x2, y1],
        [x2, y2],
        [x1, y2],
        [x1, y1],
      ];
      const cap = orientation;

      const euroPallet = getEuroPallet();
      const palletSize: Size3D = {
        length: euroPallet.palletLength,
        width: euroPallet.palletWidth,
      };
      const slotSize: Size3D = {
        length: palletSize.length,
        width: palletSize.width,
      };
      const gap: Size3D = {
        length: 0.5,
        width: 0.5,
      };
      const palletPosition: PalletPosition = {
        offsetX: 0,
        offsetY: 0,
        referenceX: ReferenceMethodX.frontEdge,
        referenceY: ReferenceMethodY.center,
      };
      const stockZoneMargin = 0.0;

      if (Math.abs(x2 - x1) > Math.abs(y2 - y1)) {
        if (Math.abs(x2 - x1) < slotSize.length * 100) {
          x2 = x1 + slotSize.length * 100;
        }

        if (Math.abs(y2 - y1) < slotSize.width * 100) {
          y2 = y1 - slotSize.width * 100;
        }
      } else {
        if (Math.abs(x2 - x1) < slotSize.width * 100) {
          x2 = x1 + slotSize.width * 100;
        }

        if (Math.abs(y2 - y1) < slotSize.length * 100) {
          y2 = y1 - slotSize.length * 100;
        }
      }

      const dX = Math.abs(x2 - x1) / 100;
      const dY = Math.abs(y2 - y1) / 100;

      let zoneLength = Math.max(dX, dY);
      let zoneWidth = Math.min(dX, dY);

      const length = Math.floor((zoneLength + gap.length) / (slotSize.length + gap.length));
      const width = Math.floor((zoneWidth + gap.width) / (slotSize.width + gap.width));

      // const offsetX = ((zoneLength - length * slotSize.length) / (length + 1)) * 100;
      // const offsetY = ((zoneWidth - width * slotSize.width) / (width + 1)) * 100;

      const palletTypes = [getEuroPallet().name];
      const tolerancePosition: Position3D = {
        x: 0.02,
        y: 0.02,
        z: 0.01,
        cap: 0.01,
      };

      const name = CircuitService.generateName(ShapeTypes.StockZoneShape);

      let slots = CircuitService.generateStockZoneSlots(
        coordinates,
        slotSize,
        gap,
        length,
        width,
        palletTypes,
        palletPosition,
        palletSize,
        tolerancePosition,
        orientation,
        undefined,
        undefined,
        undefined,
        name
      );

      slots = CircuitService.computeSlotsGeometry(slots, cap);

      zoneWidth = width * (slotSize.width + gap.width) - gap.width;
      zoneLength = length * (slotSize.length + gap.length) - gap.length + stockZoneMargin;

      let coords = [coordinates];
      if (zoneWidth > 0 && zoneLength > 0) {
        const zonePosX = slots[0].slots[0].slotPosition.x;
        const zonePosY = -slots[0].slots[0].slotPosition.y;
        const theta = toRad(cap);
        coords = [
          [
            [zonePosX, zonePosY],
            [zonePosX + zoneLength * 100 * Math.cos(theta), zonePosY - zoneLength * 100 * Math.sin(theta)],
            [
              zonePosX + zoneLength * 100 * Math.cos(theta) - zoneWidth * 100 * Math.sin(theta),
              zonePosY - zoneLength * 100 * Math.sin(theta) - zoneWidth * 100 * Math.cos(theta),
            ],
            [zonePosX - Math.sin(theta) * zoneWidth * 100, zonePosY - Math.cos(theta) * zoneWidth * 100],
            [zonePosX, zonePosY],
          ],
        ];
      }

      const addedStockZone: CircuitStockZone = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: coords,
        },
        properties: {
          type: ShapeTypes.StockZoneShape,
          name,
          layerId,
          slots,
          length,
          width,
          palletSize,
          cap: orientation,
          pattern: PatternTypes.uniformDistribution,
          extendedLength: defaultExtendedLength,
          palletTypes,
          slotSize,
          gap,
          displayPallets: true,
          palletToDisplay: palletTypes[0],

          referencePosX: palletPosition.referenceX,
          referenceOffsetX: palletPosition.offsetX,
          referencePosY: palletPosition.referenceY,
          referenceOffsetY: palletPosition.offsetY,

          enableScan: true,
          fillStrategy: 2,
          emptyStrategy: 2,

          stockZoneMargin,

          prio: getMaxDisplayPriority(),
        },
      };

      observer.next(addedStockZone);

      return observer.complete();
    });
  }

  /**
   * Create a geojson rack ready to be inserted into the store
   * @param coord the top-left and bottom-right coordinates of the rack
   * @param layerId the layer id in which the rack will be inserted
   * @param name the name of the rack (otherwise automatically generated)
   * @param orientation the angle of the rack
   * @returns the geojson rack
   */
  public static createGeoJSONRack(
    coord: number[][],
    layerId = CircuitService.getSelectedLayer(),
    name?: string,
    orientation = 0,
    opts = {
      usePreferences: true,
    }
  ): Observable<CircuitRack> {
    return new Observable((observer) => {
      const newShapeId = generateShapeId();

      const point1 = coord[0];
      const point2 = coord[1];

      const x1_tmp = point1[0];
      const y1_tmp = point1[1];
      const x2_tmp = point2[0];
      const y2_tmp = point2[1];

      let x1 = Math.min(x1_tmp, x2_tmp);
      let y1 = Math.max(y1_tmp, y2_tmp);
      let x2 = Math.max(x1_tmp, x2_tmp);
      let y2 = Math.min(y1_tmp, y2_tmp);

      if (orientation === 90) {
        [x1, x2] = [x2, x1];
      } else if (orientation === -90) {
        [y1, y2] = [y2, y1];
      } else if (Math.abs(orientation) === 180) {
        [x1, x2] = [x2, x1];
        [y1, y2] = [y2, y1];
      }

      const maxLength = Math.max(Math.abs(x2 - x1), Math.abs(y2 - y1));
      const defaultCell = getDefaultRackCell();
      const columnWidth = getDefaultColumnWidth();
      const euroPallet = getEuroPallet();
      const defaultCellTemplate = getDefaultRackCellTemplate();

      const nbColumns = Math.floor(maxLength / (columnWidth * 100)) || 1;

      const uprights: RackUpright[] = [];
      for (let i = 0; i < nbColumns + 1; i++) {
        uprights.push(getDefaultUpright());
      }

      const defaultNbLevels = 3;

      const columns: RackColumn[] = [];
      let xColumn = 0;
      for (let i = 0; i < nbColumns; i++) {
        const previousUpright = uprights[i];
        if (previousUpright.enabled) {
          xColumn += uprights[0].width;
        }

        columns.push({
          id: generateShapeId(),
          extendedLength: defaultExtendedLength,
          nbLevels: defaultNbLevels,
          cells: Array(defaultNbLevels)
            .fill(0)
            .map(() => ({ ...defaultCell, id: generateShapeId() })),
          x: xColumn,
          width: columnWidth,
          startHeight: 0,
          linkedProperties: {
            width: true, // by default all the properties are linked
            nbLevels: true,
            extendedLength: true,
            startHeight: true,
          },
          extendedLengthSegments: [],
        });

        xColumn += columnWidth;
      }

      let actualLength = 0;
      columns.forEach(() => (actualLength += columnWidth));
      uprights.forEach((upright) => (actualLength += upright.width));
      actualLength *= 100;

      // we center a euro pallet on it
      const depth = euroPallet.palletLength - defaultCellTemplate.palletOverflow * 2;

      const xx = x1 + actualLength;
      const yy = y1 - depth * 100;
      let coordinates = [
        [x1, y1],
        [xx, y1],
        [xx, yy],
        [x1, yy],
        [x1, y1],
      ];

      if (orientation) {
        const rotationCenter = [x1, y1];
        [coordinates] = rotateCoordinates(rotationCenter, [coordinates], orientation);
      }

      const rack: CircuitRack = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
        properties: {
          type: ShapeTypes.RackShape,
          name: name || CircuitService.generateName(ShapeTypes.RackShape),
          layerId,
          columns,
          prio: getMaxDisplayPriority(),
          cap: -orientation,
          depth,
          uprights,
          defaultCellHeight: 1.5,
          defaultBeamThickness: 0.1,
          defaultColumnWidth: getDefaultColumnWidth(),
          defaultUprightWidth: getDefaultUpright().width,
          defaultNbLevels,
          defaultExtendedLength,
          defaultFootProtection: {
            enabled: false,
            height: 0.16,
            width: 0.16,
            overflow: 0.1,
          },
        },
      };

      observer.next(rack);

      observer.complete();
    });
  }

  /**
   * Create a geojson segment ready to be inserted into the store
   * @param coord the top-left and bottom-right coordinates of the segment
   * @param layerId the layer id in which the segment will be inserted
   * @param stockLine the stock line id if the segment is connected to a stockline
   * @param rackData the rack id if the segment is connected to a rack as well as the column id
   * @param id the id of the segment (otherwise automatically generated)
   * @returns the geojson segment
   */
  public static createGeoJSONSegment(
    coord: number[][],
    layerId = CircuitService.getSelectedLayer(),
    stockLine?: string,
    rackData?: { rack: string; rackColumn: string },
    id?: string
  ): Observable<CircuitSegment> {
    return new Observable((observer: Observer<CircuitSegment>) => {
      const newShapeId = id ?? generateShapeId();

      const portion: CircuitPortion = {
        id: newShapeId,
        points: coord,
      };

      const addedSegment: CircuitSegment = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coord,
        },
        properties: {
          type: ShapeTypes.SegmentShape,
          name: CircuitService.generateName(ShapeTypes.SegmentShape),
          twoWay: false,
          layerId,
          portions: [portion],
          prio: getMaxDisplayPriority(),
        },
      };
      if (stockLine) addedSegment.properties.stockLine = stockLine;
      if (rackData?.rack) addedSegment.properties.rack = rackData.rack;
      if (rackData?.rackColumn) addedSegment.properties.rackColumn = rackData.rackColumn;

      observer.next(addedSegment);

      return observer.complete();
    });
  }

  /**
   * Create a geojson measurer ready to be inserted into the store
   * @param coord the top-left and bottom-right coordinates of the measurer
   * @param layerId the layer id in which the measurer will be inserted
   * @returns the geojson measurer
   */
  public static createGeoJSONMeasurer(
    coord: number[][],
    layerId = CircuitService.getSelectedLayer()
  ): Observable<CircuitMeasurer> {
    return new Observable((observer: Observer<CircuitMeasurer>) => {
      const newShapeId = generateShapeId();

      const addedMeasurer: CircuitMeasurer = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coord,
        },
        properties: {
          type: ShapeTypes.MeasurerShape,
          name: CircuitService.generateName(ShapeTypes.MeasurerShape),
          measurementType: MeasurementType.MinimumDistance,
          layerId,
          prio: getMaxDisplayPriority(),
        },
      };

      observer.next(addedMeasurer);

      return observer.complete();
    });
  }

  /**
   * Create a point ready to be inserted into the store
   * @param coord the top-left and bottom-right coordinates of the point
   * @param angle the orientation of the point
   * @param layerId the layer in which the point needs to be inserted
   * @returns the geojson point
   */
  public static createGeoJSONPoint(
    coord: number[],
    angle: number,
    layerId = CircuitService.getSelectedLayer()
  ): Observable<CircuitPoint> {
    return new Observable((observer: Observer<CircuitPoint>) => {
      const newShapeId = generateShapeId();

      const addedPoint: CircuitPoint = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coord,
        },
        properties: {
          type: ShapeTypes.PointShape,
          name: CircuitService.generateName(ShapeTypes.PointShape),
          orientation: angle,
          isTaxi: false,
          isInit: false,
          isBattery: false,
          isTeleportation: false,
          height: 0,
          layerId,
          prio: getMaxDisplayPriority(),
        },
      };

      observer.next(addedPoint);

      return observer.complete();
    });
  }

  public static createGeoJSONDevice(
    coord: number[],
    deviceType: DeviceType = 'IOECombox',
    opts: CreateGeoJSONDeviceOpts = {}
  ): Observable<CircuitDevice> {
    return new Observable((observer: Observer<CircuitDevice>) => {
      const {
        name,
        displayName,
        IP,
        frequency,
        layerId = CircuitService.getSelectedLayer(),
        network,
        comboxVersion,
        gateway,
      } = opts;

      const newShapeId = generateShapeId();

      let ip = IP;
      if (!ip) {
        ip = generateUnusedIP();
      }

      const newName = CircuitService.generateName(ShapeTypes.DeviceShape);

      // if needed, we generate placeholder names for the pins
      const pinsIn: PinType[] = [];
      const pinsOut: PinType[] = [];

      let nbPinsInToAdd;
      if (deviceType) {
        const deviceData = devicesData[deviceType];
        if ('nbPinsIn' in deviceData) {
          nbPinsInToAdd = deviceData.nbPinsIn;
        }
      }

      let nbPinsOutToAdd;
      if (deviceType) {
        const deviceData = devicesData[deviceType];
        if ('nbPinsOut' in deviceData) {
          nbPinsOutToAdd = deviceData.nbPinsOut;
        }
      }

      if (nbPinsInToAdd && typeof nbPinsInToAdd === 'number') {
        for (let i = 0; i < nbPinsInToAdd; i++) {
          pinsIn.push({
            name: `Alias ${i + 1}`,
          });
        }
      }

      if (nbPinsOutToAdd && typeof nbPinsOutToAdd === 'number') {
        for (let i = 0; i < nbPinsOutToAdd; i++) {
          pinsOut.push({
            name: `Alias ${i + 1}`,
          });
        }
      }

      const addedDevice: CircuitDevice = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coord,
        },
        properties: {
          type: ShapeTypes.DeviceShape,
          displayName: displayName ?? newName,
          name: name ?? newName,
          deviceType,
          layerId,
          IP: ip,
          displayUI: true,
          frequency: frequency,
          pinsIn,
          pinsOut,

          prio: getMaxDisplayPriority(),

          network,
          comboxVersion,
          gateway,
        },
      };

      observer.next(addedDevice);

      return observer.complete();
    });
  }

  // Create a GeaJSON Note
  public static createGeoJSONNote(
    coord: number[],
    type: ShapeTypes.NoteShape,
    name?: string,
    prio?: number,
    size?: number,
    layerId = CircuitService.getSelectedLayer()
  ): Observable<CircuitNote> {
    return new Observable((observer: Observer<CircuitNote>) => {
      const newShapeId = generateShapeId();
      const newName = CircuitService.generateName(ShapeTypes.NoteShape);

      const addedNote: CircuitNote = {
        id: newShapeId,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coord,
        },
        properties: {
          type,
          layerId,
          size: size ?? convertNoteSizeToPx('M'),
          name: name ?? newName,
          displayMode: 'icon',
          prio: getMaxDisplayPriority(),
        },
      };

      observer.next(addedNote);

      return observer.complete();
    });
  }

  /**
   * Convert a circuit shapes into an array of geojson features
   * @param features the circuit part of the store containing the features
   * @returns the features array
   */
  public static circuitDataToFeatureArray(features: ExportCircuitFeatures): Feature[] {
    const shapesToExport: Feature[] = Object.values(features)
      .flatMap((f: CircuitShape[]) => f)
      .filter((f) => !!f);

    return shapesToExport;
  }

  public static convertFeatureArrayCoordinates(
    features: Feature[],
    factorFunction: (value: number) => number,
    nbDigits = 6
  ): Feature[] {
    const convertedFeatures = features.map((feature) => {
      switch (feature.geometry.type) {
        case 'Point':
          return {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [
                factorFunction(feature.geometry.coordinates[0]),
                factorFunction(feature.geometry.coordinates[1]),
              ],
            },
          };

        case 'Polygon':
          feature.geometry.coordinates.forEach((elt) => elt.forEach);

          return {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [
                feature.geometry.coordinates[0].map((current) => [
                  factorFunction(current[0]),
                  factorFunction(current[1]),
                ]),
              ],
            },
          };

        case 'LineString':
          feature.geometry.coordinates.forEach((elt) => elt.forEach);

          let properties = feature.properties;
          if (isCircuitTurn(feature)) {
            properties = {
              ...feature.properties,
              coordinateProperties: {
                ...feature.properties.coordinateProperties,
                cap: feature.properties.coordinateProperties.cap.map((cap: number) =>
                  parseFloat(cap.toFixed(nbDigits))
                ),
              },
              positionFactorOrigin:
                typeof feature.properties.positionFactorOrigin !== 'undefined'
                  ? parseFloat(feature.properties.positionFactorOrigin.toFixed(nbDigits))
                  : undefined,
              positionFactorDest:
                typeof feature.properties.positionFactorDest !== 'undefined'
                  ? parseFloat(feature.properties.positionFactorDest.toFixed(nbDigits))
                  : undefined,
            };
          }

          return {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: feature.geometry.coordinates.map((current) => [
                factorFunction(current[0]),
                factorFunction(current[1]),
              ]),
            },
            properties,
          };

        default:
          // eslint-disable-next-line no-console
          console.warn('[convertFeatureArrayCoordinates] Unknown geometry type');
      }

      return { ...feature };
    });

    return convertedFeatures;
  }

  /**
   * Covnert a circuit shape to a geojson feature
   * @param shape the circuit shape
   * @returns the geojson feature
   */
  public static shapeToFeature(shape: CircuitShape): Feature {
    return {
      type: shape.type,
      id: shape.id,
      geometry: shape.geometry,
      properties: shape.properties,
    };
  }

  /**
   * Computes if the turn is well connected to its segment
   * @param turn
   * @returns if the turn is well connected, i.e. next to its segments + same angle of the first and last points
   */
  public static isTurnWellConnected(turn: CircuitTurn): boolean {
    const properties = turn.properties;

    if (!properties.originId) {
      // eslint-disable-next-line no-console
      console.error(`[isTurnWellConnected] originId is undefined for turn ${turn.id}`);

      return false;
    }

    if (!properties.destinationId) {
      // eslint-disable-next-line no-console
      console.error(`[isTurnWellConnected] destinationId is undefined for turn ${turn.id}`);

      return false;
    }

    const segments = [
      CircuitService.getShape(properties.originId, ShapeTypes.SegmentShape) as CircuitSegment,
      CircuitService.getShape(properties.destinationId, ShapeTypes.SegmentShape) as CircuitSegment,
    ];

    if (segments.length !== 2 || !segments[0] || !segments[1]) {
      // eslint-disable-next-line no-console
      console.warn(`${segments.length} segments found instead of 2...`);

      return false;
    }

    const distanceThreshold = 1e-3;
    const angleThreshold = 1;

    return segments.every((segment, index) => {
      const turnCoords = turn.geometry.coordinates[index === 0 ? 0 : turn.geometry.coordinates.length - 1];
      const segCoords = segment?.geometry.coordinates;
      if (!segCoords) return true;

      const d = pDistance(
        turnCoords[0],
        turnCoords[1],
        segCoords[0][0],
        segCoords[0][1],
        segCoords[1][0],
        segCoords[1][1]
      );

      if (d > distanceThreshold) return false;

      // we also need to compute the angle
      const segAngle = findShapeOrientation(segCoords[0], segCoords[1]);
      const turnCap = turn.properties.coordinateProperties.cap;
      const deltaAngle = Math.abs(turnCap[index === 0 ? 0 : turnCap.length - 1] - segAngle - 360) % 360;

      return deltaAngle < angleThreshold;
    });
  }

  /**
   * Computes if the measurer is well connected to its segment
   * @param measurer
   * @returns if the measurer is well connected, i.e. next to its segments
   */
  public static isMeasurerWellConnected(measurer: CircuitMeasurer): boolean {
    const properties = measurer.properties;
    const shapes: (CircuitSegment | CircuitPoint)[] = [];
    const endPoints: (0 | 1)[] = [];
    if (properties.link0) {
      shapes.push(CircuitService.getShape(properties.link0.id, properties.link0.type) as CircuitSegment | CircuitPoint);
      endPoints.push(0);
    }

    if (properties.link1) {
      shapes.push(CircuitService.getShape(properties.link1.id, properties.link1.type) as CircuitSegment | CircuitPoint);
      endPoints.push(1);
    }

    if (!shapes.length) return true;

    const distanceThreshold = 1e-3;

    return shapes.every((shape, index) => {
      const shapeCoords = shape.geometry.coordinates;
      const endPoint = endPoints[index];
      const measurerCoords =
        measurer.geometry.coordinates[endPoint === 0 ? 0 : measurer.geometry.coordinates.length - 1];

      if (shape.properties.type === ShapeTypes.SegmentShape) {
        const d = pDistance(
          measurerCoords[0],
          measurerCoords[1],
          shapeCoords[0][0],
          shapeCoords[0][1],
          shapeCoords[1][0],
          shapeCoords[1][1]
        );

        return d < distanceThreshold;
      }

      // Point Shape
      const d = getDistanceBetweenPoints(measurerCoords, shapeCoords as Position);

      return d < distanceThreshold;
    });
  }

  /**
   * Computes if all the snapped point is well connected to its segment
   * @param point
   * @returns boolean, if the snapped point is well connected to its segment, i.e. is close to it
   */
  public static isSnappedPointWellConnected(point: CircuitPoint): boolean {
    const properties = point.properties;

    // not connected to a segment (not a snapped point), we return true but we could return false in a way
    if (!properties.segment) return true;

    const segmentId = properties.segment.id;
    const segment = CircuitService.getShape(segmentId, ShapeTypes.SegmentShape);

    if (!segment) return false;

    const distanceThreshold = 1e-3;
    const ptCoords = point.geometry.coordinates;
    const segCoords = segment.geometry.coordinates;
    // we measure the distance between the point and the segment
    // if both are close, it means that the point is well snapped on it
    const d = pDistance(ptCoords[0], ptCoords[1], segCoords[0][0], segCoords[0][1], segCoords[1][0], segCoords[1][1]);

    return d < distanceThreshold;
  }

  /**
   * Return a list of all the shapes name
   * @param excludeId Optional, an id of shape we don't want include its name in the list
   * @returns The shape names
   */
  public static getAllShapesName(excludeId?: string | string[]): string[] {
    const shapes = this.getShapes();
    const names: string[] = [];

    const isExcludeIdArray = excludeId && Array.isArray(excludeId);
    const excludeIds = !excludeId ? null : new Set(isExcludeIdArray ? excludeId : [excludeId]);

    shapes.forEach((shape) => {
      if (excludeIds && excludeIds.has(shape.id as string)) return;

      const shapeProps = shape.properties;
      if ('name' in shapeProps && shapeProps.name) {
        names.push(shapeProps.name);
      }
    });

    return names;
  }

  /**
   * This function generates a different name in case of conflict
   * @param originalName Generate a close name from this name
   * @param options Options to generate the name
   * @returns the close name
   */
  public static generateDifferentName(
    originalName: string,
    options: GenerateDifferentNameOptions | undefined = {}
  ): string {
    let { shapesName } = options;
    const { shapeIdsToIgnore } = options;

    if (!shapesName) shapesName = CircuitService.getAllShapesName(shapeIdsToIgnore);

    const separator = getConfig('editor').copyPaste.separatorNewName;
    const splittedName = originalName.split(separator);
    const isThereAnAppendixInTheName = splittedName.length > 1 && isPositiveInt(splittedName[splittedName.length - 1]);

    let newName: string;
    if (!isThereAnAppendixInTheName) splittedName.push('0');

    do {
      splittedName[splittedName.length - 1] = (parseInt(splittedName[splittedName.length - 1], 10) + 1).toString();
      newName = splittedName.join(separator);
    } while (shapesName.includes(newName));

    return newName;
  }

  /**
   * Generate a temporary meaningless layer name
   * @returns
   */
  public static generateLayerName(isCommon = false): string {
    if (isCommon) {
      return `Common-${Math.floor(Math.random() * 1000)}`;
    }

    const layers = store.getState().circuit.present.layers.layers;
    const layersArr = Object.values(layers);

    const newName = `Layer${layersArr.length + 1}`;

    if (layersArr.find((layer) => layer.name === newName)) {
      return CircuitService.generateDifferentName(newName);
    }

    return newName;
  }

  public static getClosestSegment(P: Position, layerId?: string | string[]): CircuitSegment | void {
    const segments = CircuitService.getShapesOfThisType(ShapeTypes.SegmentShape, layerId) as CircuitSegment[];

    let minDist = Number.POSITIVE_INFINITY;
    let closestSegment = -1;

    segments.forEach((segment, i) => {
      const coords = segment.geometry.coordinates;
      const d = pDistance(P[0], P[1], coords[0][0], coords[0][1], coords[1][0], coords[1][1]);

      if (d < minDist) {
        minDist = d;
        closestSegment = i;
      }
    });

    if (closestSegment !== -1) {
      const segment = segments[closestSegment];

      return segment;
    }
  }

  /**
   * The idea is to get the best segment to draw a turn
   *
   * We define "best" with the following criteria:
   * - we sort all the segments by distance from the point (closer is better)
   * - we compute the difference with the closest segment
   * - we ignore all the segments with a difference greater than the threshold
   * - if at least one is in the current layer, we ignore the others
   * - we return the one the more in the foreground
   *
   * @param P
   * @param layerId
   * @param options
   */
  public static getBestMatchingSegmentForTurn(
    P: Position,
    layerId: string,
    options: GetBestMatchingSegmentForTurnOptions = {
      ignoreHiddenLayers: true,
      ignoreSegmentsIds: [] as string[],
      distThreshold: 20, // m
      maxDeltaD: 0.05, // m
    }
  ): CircuitSegment | undefined {
    if (!P) return;

    const circuitState = options.circuitState ?? CircuitService.getStoreState().circuit.present;
    const segments = circuitState.segments.entities;
    let segmentsIds = circuitState.segments.ids;
    const layers = circuitState.layers.layers;

    // we possiblly ignore the segments in the hidden layers
    if (options.ignoreHiddenLayers) {
      segmentsIds = segmentsIds.filter((segmentId) => {
        const segmentLayerId = segments[segmentId].properties.layerId;
        const layer = layers[segmentLayerId];

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error(`The segment ${segmentId} is in the layer ${segmentLayerId} which doesn't exist`);

          return false;
        }

        return layer.visibility;
      });
    }

    if (options?.ignoreSegmentsIds?.length) {
      segmentsIds = segmentsIds.filter((segmentId) => !(options.ignoreSegmentsIds ?? []).includes(segmentId));
    }

    const distThreshold = options.distThreshold ?? 20; // m
    const maxDeltaD = options.maxDeltaD ?? 0.05; // m

    // we sort all segments by distance from the point
    const distSegments = segmentsIds
      .map((segmentId) => {
        const coords = segments[segmentId].geometry.coordinates;
        const d = pDistance(P[0], P[1], coords[0][0], coords[0][1], coords[1][0], coords[1][1]) / 100; // m

        return {
          id: segmentId,
          d,
        };
      })
      .filter((segment) => segment.d < distThreshold)
      .sort((a, b) => a.d - b.d);
    if (!distSegments.length) return;

    const minDist = distSegments[0].d;

    // we ignore all the segments with a difference greater than the threshold
    const distSegments2 = distSegments
      .map((segment) => {
        return {
          ...segment,
          deltaD: segment.d - minDist,
        };
      })
      .filter((segment) => segment.deltaD < maxDeltaD);

    // if there's at least one segment in the current layer
    const segmentsInCurrentLayer =
      distSegments2.findIndex((seg) => {
        const segment = segments[seg.id] as CircuitSegment;

        return segment.properties.layerId === layerId;
      }) !== -1;

    const distSegments3 = segmentsInCurrentLayer
      ? distSegments2.filter((seg) => segments[seg.id].properties.layerId === layerId)
      : distSegments2;

    // we return the one the more in the foreground
    const distSegments4 = distSegments3.sort((a, b) => {
      const segmentA = segments[a.id] as CircuitSegment;
      const segmentB = segments[b.id] as CircuitSegment;

      return segmentA.properties.prio - segmentB.properties.prio;
    });

    return segments[distSegments4[0].id] as CircuitSegment;
  }

  /**
   * Compute all the positions of the extended lengths of a rack
   * From left to right of the rack, in the x-axis of the rack
   * @param rack a circuit rack
   * @param cellTemplates a subset of the cell templates (can be extracted directly from the store)
   * @returns all the positions in an array
   */
  public static computeRackExtendedLengthPosition(
    rack: CircuitRack,
    cellTemplates: Record<string, RackCellTemplate>
  ): number[] {
    const columns = rack.properties.columns;
    const positions = new Set<number>();

    // we go trough all the possible positions of the cells
    // that are contained in the rack's columns
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const cells = column.cells;

      for (let j = 0; j < cells.length; j++) {
        const cell = cells[j];

        if (cell.disabled) continue; // disabled cells don't generate positions / UPDATE: they actually need to generate positions, otherwise we don't have extended length for them
        // and this leads to issues, especially when exporting the xml, etc.

        const cellTemplateId = cell.cellTemplate;
        if (!cellTemplateId) continue; // no cell template, no position
        const cellTemplate = cellTemplates[cellTemplateId];
        if (!cellTemplate) continue;
        const loads = cellTemplate.loads;

        // a cell template can have multiple loads that superimpose
        // we need to compute a position for every load of every cell (template)
        for (let k = 0; k < loads.length; k++) {
          const load = loads[k];

          // we get the center of every load in the rack frame of reference
          const pos = computeLoadsPosition(load, column.width)
            // we convert the position in the column frame of reference to the rack frame of refence + convert the left position of the load to its center
            .map((x) => column.x + x + load.W / 2);

          // add all the computed positions to the set (unique)
          pos.forEach(positions.add, positions);
        }
      }
    }

    // if needed, insert here a filter if floats positions are really close to each other

    // we used the order later, it is important to sort the positions
    const posArr = Array.from(positions).sort((a, b) => a - b);

    return posArr;
  }

  /**
   * Computes the extended length segment coordinates for a rack from the already computed positons in x in the rack
   * - positions in x in the rack = position in the rack frame of reference without any rotation (from the left side of the rack)
   * - extended length segment coordinates = position in the circuit frame of reference with rotation applied, etc.
   * @param rack the circuit rack
   * @param positions the positions in x in the rack (without rotation)
   * @returns the computed coordinates with their associated column id, and the position in x (in the rack frame of reference)
   */
  public static computeRackExtendedLengthCoords(
    rack: CircuitRack,
    positions: number[]
  ): [number[][], string, number][] {
    const coords: [number[][], string, number][] = [];

    // we go from the left bottom of the rack
    const from = rack.geometry.coordinates[0][3];
    const angle = toRad(rack.properties.cap);
    const columns = rack.properties.columns;

    const sortedColumnsByX = [...columns].sort((a, b) => a.x - b.x);

    // we will compute the coordinates of the extended length segments for every computed load position
    for (let i = 0; i < positions.length; i++) {
      const position = positions[i];

      const nextColumnIndex = sortedColumnsByX.findIndex((c) => c.x > position);
      const column = nextColumnIndex !== -1 ? sortedColumnsByX[nextColumnIndex - 1] : columns[columns.length - 1];
      if (!column) {
        // eslint-disable-next-line no-console
        console.error('No column found for position', position);
        continue;
      }

      const length = column.extendedLength;

      // from is the left bottom of the rack, we add the positions in the rack frame of refrence and apply the rotation of the rack
      // for the first point of the segment
      const extendedLengthOrigin = [
        from[0] + position * Math.cos(angle) * 100,
        from[1] + position * Math.sin(angle) * 100,
      ];
      // and for the second point of the segment
      const extendedLengthDest = [
        extendedLengthOrigin[0] + length * Math.cos(angle - Math.PI / 2) * 100,
        extendedLengthOrigin[1] + length * Math.sin(angle - Math.PI / 2) * 100,
      ];

      // we return the coordinates of the segment in the circuit frame of reference as well as the column id to which the segment belongs
      coords.push([[extendedLengthOrigin, extendedLengthDest], column.id, position]);
    }

    return coords;
  }
}

export let shapeJustMoved: string | undefined;
export function getShapeJustMoved(): string | undefined {
  return shapeJustMoved;
}

export function resetShapeJustMoved(): void {
  shapeJustMoved = undefined;
}

export function setShapeJustMoved(shapeId: string): void {
  shapeJustMoved = shapeId;
}

/**
 * Detects if an object is a valid Road Editor geojson circuit
 * @param obj the object to test
 * @returns wether the object is valid or not
 */
export function isGeoJsonCircuit(obj): obj is GeoJsonCircuit {
  // the library has a few problems, we shortcut it for now
  return true;

  // if (isGeoJsonCircuitNotExtended(obj)) {
  //   const features = obj.features;
  //   features.forEach((feature: any) => {
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //     if (!isLayerFeatureCollection(feature)) {
  //       // eslint-disable-next-line no-console
  //       console.error(`Feature ${feature.id} is not a valid layer feature collection`, feature);

  //       return false;
  //     }

  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //     // feature.features.forEach((shape) => {
  //     //   /**
  //     //    * Unfortunately, it looks like that ts-auto-guard does not produce
  //     //    * a proper output for the following function check (or I am missing something)
  //     //    * I cannot find the problem.
  //     //    * One way of solving this would be to check the geometry of the shape and then
  //     //    * the properties of the shape. I think ts-auto-guard would works better.
  //     //    */
  //     //   if (!isCircuitShape(shape)) {
  //     //     // eslint-disable-next-line no-console
  //     //     console.error('CircuitService: invalid shape detected', shape);
  //     //   }
  //     // });
  //   });

  //   return true;
  // }

  // // eslint-disable-next-line no-console
  // console.error('CircuitService: invalid geojson properties');

  // return false;
}

export let minDisplayPriority = -1;
export let maxDisplayPriority = 1;

export function resetDisplayPriority(): [number, number] {
  minDisplayPriority = -1;
  maxDisplayPriority = 1;

  return [minDisplayPriority, maxDisplayPriority];
}

export function getMinDisplayPriority(): number {
  return minDisplayPriority--;
}

export function getMaxDisplayPriority(): number {
  return maxDisplayPriority++;
}

export function setDisplayPriority(min: number, max: number): void {
  minDisplayPriority = min;
  maxDisplayPriority = max;
}

export interface ExportCircuitFeatures {
  zones?: CircuitZone[];
  stockZones?: CircuitStockZone[];
  points?: CircuitPoint[];
  segments?: CircuitSegment[];
  measurers?: CircuitMeasurer[];
  turns?: CircuitTurn[];
  racks?: CircuitRack[];
  devices?: CircuitDevice[];
  notes?: CircuitNote[];
}

function isIPUsedByADevice(devices: CircuitDevice[], ip: string): boolean {
  return devices.some((d) => d.properties.IP === ip);
}

export function generateUnusedIP(): string {
  let ip = '';
  if (!ip) {
    const devices = CircuitService.devices;
    let nb = 1;
    while (isIPUsedByADevice(devices, `0.0.0.${nb}`)) {
      nb++;
    }

    ip = `0.0.0.${nb}`;
  }

  return ip;
}

interface GetBestMatchingSegmentForTurnOptions {
  ignoreHiddenLayers?: boolean;
  ignoreSegmentsIds?: string[];
  distThreshold?: number; // m
  maxDeltaD?: number; // m

  circuitState?: CircuitState;
}

interface GenerateDifferentNameOptions {
  /**
   * List of the shapes name, get from the circuit otherwise
   */
  shapesName?: string[];
  /**
   * List of the shapes id to ignore
   */
  shapeIdsToIgnore?: string[];
}

export const getShapesNamesMemoized = memoize(CircuitService.getShapesNames, {
  ttl: 100,
  resolver: (layerId, options) => {
    return `${layerId} + ${JSON.stringify(options)}`;
  },
});
