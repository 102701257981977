import type { Action } from 'redux';
import type { UserProfileData } from 'shared/core';
import type { Failure } from './shared';
import { failureAction } from './shared';

export enum AuthActionTypes {
  LoadUserProfile = '[Auth] Load User Profile',
  LoadUserProfileFailure = '[Auth] Load User Profile Failure',
  LoadUserProfileSuccess = '[Auth] Load User Profile Success',
  Login = '[Auth] Login',
  LoginFailure = '[Auth] Login Failure',
  LoginSuccess = '[Auth] Login Success',
  Logout = '[Auth] Logout',
  RefreshToken = '[Auth] Refresh Token',
  RefreshTokenFailure = '[Auth] Refresh Token Failure',
  RefreshTokenSuccess = '[Auth] Refresh Token Success',
}

export interface Login extends Action {
  type: AuthActionTypes.Login;
  payload: { returnUrl?: string };
}

export function loginAction(payload: Login['payload'] = {}): Login {
  return { type: AuthActionTypes.Login, payload };
}

export interface LoginSuccess extends Action {
  type: AuthActionTypes.LoginSuccess;
  payload: { expiresAt: number };
}

export function loginSuccessAction(payload: LoginSuccess['payload']): LoginSuccess {
  return { type: AuthActionTypes.LoginSuccess, payload };
}

export interface LoginFailure extends Failure<AuthActionTypes.LoginFailure> {}

export function loginFailureAction(error: Error): LoginFailure {
  return failureAction(AuthActionTypes.LoginFailure, error);
}

export interface Logout extends Action {
  type: AuthActionTypes.Logout;
  payload: Record<string, never>;
}

export function logoutAction(): Logout {
  return { type: AuthActionTypes.Logout, payload: {} };
}

export interface RefreshToken extends Action {
  type: AuthActionTypes.RefreshToken;
  payload: Record<string, never>;
}

export function refreshTokenAction(): RefreshToken {
  return { type: AuthActionTypes.RefreshToken, payload: {} };
}

export interface RefreshTokenSuccess extends Action {
  type: AuthActionTypes.RefreshTokenSuccess;
  payload: { expiresAt: number };
}

export function refreshTokenSuccessAction(payload: RefreshTokenSuccess['payload']): RefreshTokenSuccess {
  return { type: AuthActionTypes.RefreshTokenSuccess, payload };
}

export interface RefreshTokenFailure extends Failure<AuthActionTypes.RefreshTokenFailure> {}

export function refreshTokenFailureAction(error: Error): RefreshTokenFailure {
  return failureAction(AuthActionTypes.RefreshTokenFailure, error);
}

export interface LoadUserProfile extends Action {
  type: AuthActionTypes.LoadUserProfile;
  payload: { returnUrl?: string };
}

export function loadUserProfileAction(payload: LoadUserProfile['payload'] = {}): LoadUserProfile {
  return { type: AuthActionTypes.LoadUserProfile, payload };
}

export interface LoadUserProfileSuccess extends Action {
  type: AuthActionTypes.LoadUserProfileSuccess;
  payload: { profile: UserProfileData };
}

export function loadUserProfileSuccessAction(payload: LoadUserProfileSuccess['payload']): LoadUserProfileSuccess {
  return { type: AuthActionTypes.LoadUserProfileSuccess, payload };
}

export interface LoadUserProfileFailure extends Failure<AuthActionTypes.LoadUserProfileFailure> {}

export function loadUserProfileFailureAction(error: Error): LoadUserProfileFailure {
  return failureAction(AuthActionTypes.LoadUserProfileFailure, error);
}

export type AuthActionsUnion =
  | LoadUserProfile
  | LoadUserProfileFailure
  | LoadUserProfileSuccess
  | Login
  | LoginFailure
  | LoginSuccess
  | Logout
  | RefreshToken
  | RefreshTokenFailure
  | RefreshTokenSuccess;
