import { bbox, feature, featureCollection } from '@turf/turf';
import type { BBox } from 'geojson';
import type { CircuitShape } from 'models/circuit';
import { CircuitService } from 'services/circuit.service';

export function zoomToAllShapes(shapeIds: string[]): void {
  const shapes = shapeIds.map((id) => CircuitService.getShape(id));
  const drawing = CircuitService.getDrawingReference(); // Create a feature collection from the individual shape geometries
  const combinedFeatureCollection = featureCollection(
    shapes.filter((shape): shape is CircuitShape => shape !== undefined).map((shape) => feature(shape.geometry))
  );
  let combinedBbox: BBox;
  try {
    combinedBbox = bbox(combinedFeatureCollection);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Computation of the combined BBox failed`, e);

    return;
  }

  if (drawing) {
    const svgEl = drawing.getRootNode().node();
    if (!svgEl) return;
    const w = svgEl.clientWidth;
    const h = svgEl.clientHeight;
    const bboxWidth = combinedBbox[2] - combinedBbox[0];
    const bboxHeight = combinedBbox[3] - combinedBbox[1];
    const zoomFactor = 0.5; // same factor as before
    const scale = w && h ? Math.min(Math.abs(w / bboxWidth), Math.abs(h / bboxHeight)) * zoomFactor : 1; // Compute the center of the combined bounding box
    const centerX = (combinedBbox[0] + combinedBbox[2]) / 2;
    const centerY = (combinedBbox[1] + combinedBbox[3]) / 2;
    drawing.zoomTo(centerX, centerY, scale, false, true);
  }
}
