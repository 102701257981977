import { Celebration, DoubleArrow, RotateLeft } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Alert,
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { centroid } from '@turf/turf';
import { askUpdateRackAction, saveCircuitToHistoryAction } from 'actions/circuit';
import { saveRackAction } from 'actions/racks';
import { HelpIconTooltip, WarningIconTooltip } from 'components/utils/tooltips';
import { rotatePolygon2 } from 'drawings/helpers';
import { useCallback, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch } from 'store';
import { AnimatedIcon } from 'utils/animated-icon';
import { toDeg, toRad } from 'utils/helpers';
import { createRackAnalysisObjectStores } from 'utils/indexed-db';
import { ChartMeasuresRack } from './chart-measures';
import type { OffsetOrAngle, Recommendation, RobotMeasures } from './models';
import { StyledTableRowRackAnalysis, unlikelyToBeUsedSeparator } from './rack-analysis-dialog';
import { convertToDisplayUnitRackAnalysis, getMeasureColor, secondRowStickyHeaderTopOffset } from './utils';

interface RecommendationRowProps {
  row: Recommendation;
  applyRecommendationOnRack: () => void;
  applyAngleRecommendationOnRack?: () => void;
  applyOffsetRecommendationOnRack?: () => void;
  dataList: RobotMeasures;
}

function RecommendationRow(props: RecommendationRowProps): JSX.Element {
  const { row, applyRecommendationOnRack, applyAngleRecommendationOnRack, applyOffsetRecommendationOnRack, dataList } =
    props;
  const [open, setOpen] = useState(false);

  const dzRecommendation = Object.entries(row.recommendation.dz).sort();
  const angleRecommendation = row.recommendation.dTheta;

  const angleAndOffsetApplied = row.applied === true || row.applied === 'angleAndOffset';
  const offsetApplied = row.applied === 'offset';
  const angleApplied = row.applied === 'angle';

  const rackName = row.rackName;
  const dataListForThisRack = dataList.filter((v) => v['Rack Name'] === rackName);

  const dx = offsetApplied || angleAndOffsetApplied ? 0 : row.recommendation.dx;
  const dy = offsetApplied || angleAndOffsetApplied ? 0 : row.recommendation.dy;
  const dTheta = angleApplied || angleAndOffsetApplied ? 0 : row.recommendation.dTheta;

  const displayWarningNotEnoughMeasures = row.measuresCount < 4;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width={50} sx={{ paddingRight: 0, borderBottom: 'unset' }}>
          {dzRecommendation.length > 0 || angleRecommendation !== 0 ? (
            <IconButton size="small" onClick={() => setOpen((prevState) => !prevState)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell>{row.rackName}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row.measuresCount}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <Typography sx={{ color: getMeasureColor(dx, row.authorizedTolerance.x) }}>
            {row.recommendation?.dx !== 0 ? `${convertToDisplayUnitRackAnalysis(dx)}` : ''}
          </Typography>
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <Typography sx={{ color: getMeasureColor(dy, row.authorizedTolerance.y) }}>
            {row.recommendation?.dy !== 0 ? `${convertToDisplayUnitRackAnalysis(dy)}` : ''}
          </Typography>
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <Typography sx={{ color: getMeasureColor(row.recommendation?.dTheta, row.authorizedTolerance.theta) }}>
            {row.recommendation?.dTheta !== 0 && !isNaN(row.recommendation?.dTheta)
              ? `${toDeg(dTheta ?? 0).toFixed(2)}°`
              : ''}
            {isNaN(row.recommendation.dTheta) ? 'N/A' : ''}
            {displayWarningNotEnoughMeasures && (
              <WarningIconTooltip
                title="Not enough measures to have a reliable recommendation"
                sx={{
                  fontSize: '1rem',
                }}
              />
            )}
          </Typography>
        </TableCell>
        <TableCell></TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <ButtonGroup variant="contained">
            <Tooltip title={`${angleAndOffsetApplied ? 'Reset' : 'Apply'} angle correction and offset correction`}>
              <Button
                color={angleAndOffsetApplied ? 'secondary' : 'primary'}
                onClick={applyRecommendationOnRack}
                disabled={row.applied !== false && !angleAndOffsetApplied}
              >
                {angleAndOffsetApplied ? 'Reset' : 'Apply'}
              </Button>
            </Tooltip>
            <Tooltip title={`${angleAndOffsetApplied ? 'Reset' : 'Apply'} offset correction only`}>
              <Box component="span">
                <Button
                  disabled={(row.applied !== false && !offsetApplied) || !applyOffsetRecommendationOnRack}
                  color={offsetApplied ? 'secondary' : 'primary'}
                  size="small"
                  sx={{
                    height: '100%',
                  }}
                  onClick={applyOffsetRecommendationOnRack}
                >
                  <DoubleArrow />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip title={`${angleAndOffsetApplied ? 'Reset' : 'Apply'} angle correction only`}>
              <Box component="span">
                <Button
                  disabled={(row.applied !== false && !angleApplied) || !applyAngleRecommendationOnRack}
                  color={angleApplied ? 'secondary' : 'primary'}
                  size="small"
                  sx={{
                    height: '100%',
                  }}
                  onClick={applyAngleRecommendationOnRack}
                >
                  <RotateLeft />
                </Button>
              </Box>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={7}>
          {dzRecommendation.length ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box component="div" sx={{ margin: 1, marginTop: 'unset' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width={164}>Row height</TableCell>
                      <TableCell width={200} sx={{ textAlign: 'right' }}>
                        Valid measures count
                        <HelpIconTooltip
                          title="Measures in X only were not taken into account as they are floor measures and are irrelevant"
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      </TableCell>
                      <TableCell width={340} sx={{ textAlign: 'right' }}>
                        ΔZ
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dzRecommendation.map(([level, dz]) => (
                      <StyledTableRowRackAnalysis key={`row-${level}`}>
                        <TableCell>{level}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>{dz.count}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <Typography sx={{ color: getMeasureColor(dz.value, row.authorizedTolerance.z) }}>
                            {dz.value !== 0 ? `${convertToDisplayUnitRackAnalysis(dz.value).toFixed(0)}` : ''}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </StyledTableRowRackAnalysis>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={9} sx={{ padding: 0, borderBottom: 'unset' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ChartMeasuresRack dataList={dataListForThisRack} row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface StepRecommendationProps {
  recommendationList: Recommendation[];
  setRecommendationList: React.Dispatch<React.SetStateAction<Recommendation[]>>;
  dataList: RobotMeasures;
}

export function StepRecommendation(props: StepRecommendationProps): JSX.Element {
  const { recommendationList, setRecommendationList, dataList } = props;

  const dispatch = useAppDispatch();

  const applyRecommendationOnRack = useCallback(
    (recommendation: Recommendation, index: number, offsetOrAngle: OffsetOrAngle): void => {
      const circuitState = store.getState().circuit.present;
      const racksIds = circuitState.racks.ids;
      const racks = circuitState.racks.entities;

      const rackIdToMove = racksIds.find((id) => racks[id]?.properties?.name === recommendation.rackName);
      const rackToMove = rackIdToMove ? racks[rackIdToMove] : undefined;

      if (!rackToMove || !rackIdToMove) {
        // eslint-disable-next-line no-console
        console.error(`Rack ${recommendation.rackName} not found`);
        SnackbarUtils.error(`Rack ${recommendation.rackName} not found`);

        return;
      }

      let rackCoordinates = structuredClone(rackToMove.geometry.coordinates[0]);

      const rackOrientationInRad = toRad(rackToMove.properties.cap);

      let cap = rackToMove.properties.cap;

      const dX =
        (recommendation.recommendation.dx * Math.cos(rackOrientationInRad) -
          recommendation.recommendation.dy * Math.sin(rackOrientationInRad)) *
        100;
      const dY =
        (recommendation.recommendation.dx * Math.sin(rackOrientationInRad) +
          recommendation.recommendation.dy * Math.cos(rackOrientationInRad)) *
        100;

      let dTheta = -recommendation.recommendation.dTheta;
      if (dTheta === undefined || isNaN(dTheta)) dTheta = 0;
      const formerDTheta = -(recommendation.recommendation.formerDTheta ?? 0);

      const signOfOperation = recommendation.applied ? 1 : -1;

      const dThetaToUse = signOfOperation === 1 ? formerDTheta : dTheta;
      if (offsetOrAngle === 'angleAndOffset' || offsetOrAngle === 'angle') {
        cap = cap + toDeg(dThetaToUse * signOfOperation);

        const rackCenter = centroid(rackToMove).geometry.coordinates;

        try {
          rackCoordinates = rotatePolygon2(rackCenter, rackCoordinates, dThetaToUse * signOfOperation);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);

          SnackbarUtils.error(`Error while applying rotation to rack ${recommendation.rackName}`);
        }
      }

      if (offsetOrAngle === 'angleAndOffset' || offsetOrAngle === 'offset') {
        rackCoordinates = [
          [rackCoordinates[0][0] - dY * signOfOperation, rackCoordinates[0][1] - dX * signOfOperation],
          [rackCoordinates[1][0] - dY * signOfOperation, rackCoordinates[1][1] - dX * signOfOperation],
          [rackCoordinates[2][0] - dY * signOfOperation, rackCoordinates[2][1] - dX * signOfOperation],
          [rackCoordinates[3][0] - dY * signOfOperation, rackCoordinates[3][1] - dX * signOfOperation],
          [rackCoordinates[4][0] - dY * signOfOperation, rackCoordinates[4][1] - dX * signOfOperation],
        ];
      }

      const newRack = {
        ...rackToMove,
        geometry: {
          ...rackToMove.geometry,
          coordinates: [rackCoordinates],
        },
        properties: {
          ...rackToMove.properties,
          cap,
        },
      };

      if (recommendation.applied) {
        SnackbarUtils.info(`Rack ${recommendation.rackName} reset`);
      } else {
        SnackbarUtils.success(`Rack ${recommendation.rackName} fixed`);
      }

      setRecommendationList((prev) => {
        let newAppliedValue: (typeof recommendation)['applied'];
        if (recommendation.applied) {
          newAppliedValue = false;
        } else {
          newAppliedValue = offsetOrAngle;
        }

        const newPrev = prev.map((item, idx) => (idx === index ? { ...item, applied: newAppliedValue } : item));

        return newPrev;
      });
      dispatch(saveCircuitToHistoryAction());
      dispatch(saveRackAction(newRack));
      dispatch(
        askUpdateRackAction({
          id: rackIdToMove,
          type: 'saveSuccess',
        })
      );

      (async () => {
        const dbPromise = indexedDB.open('rackAnalysis');
        const db = await new Promise<IDBDatabase>((resolve, reject) => {
          dbPromise.onsuccess = (event) => resolve((event.target as IDBOpenDBRequest).result);
          dbPromise.onerror = (event) => reject((event.target as IDBOpenDBRequest).error);
        });

        if (!db.objectStoreNames.contains('_unsaved')) {
          createRackAnalysisObjectStores(db);
        }

        const transaction = db.transaction('_unsaved', 'readwrite');
        const objectStore = transaction.objectStore('_unsaved');

        if (recommendation.applied) {
          // eslint-disable-next-line no-console
          console.log(`Deleting record for ${recommendation.rackName} in the IndexedDB`);
          await new Promise((resolve, reject) => {
            const request = objectStore.delete(recommendation.rackName);
            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(`Adding record for ${recommendation.rackName} in the IndexedDB`);
          const appliedRecommentationStr = JSON.stringify(recommendation);
          const newAppliedValueWithDate = `${offsetOrAngle}-${Date.now()}-${unlikelyToBeUsedSeparator}${appliedRecommentationStr}`;
          await new Promise((resolve, reject) => {
            const request = objectStore.put(newAppliedValueWithDate, recommendation.rackName);
            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
          });
        }

        db.close();
      })();
    },
    [dispatch, setRecommendationList]
  );

  return (
    <>
      <Alert severity="info" sx={{ margin: '0 24px' }}>
        The recommendation is based on the average of the measures, it may not be accurate for all racks (especially if
        a few measures have been poorly taken).
        <br />
        It is strongly recommended to check the rack position before applying the recommendation.
      </Alert>
      <TableContainer sx={{ maxHeight: 690, padding: '0 24px 24px 24px', width: 'unset' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Rack name</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                Valid measures count
                <HelpIconTooltip
                  title="Measures in X only were not taken into account as they are floor measures and are irrelevant"
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </TableCell>
              <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                Recommendation (mm)
                <HelpIconTooltip
                  title="In the rack frame of reference"
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }} width={200}></TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }} width={120}>
                ΔX
              </TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }} width={120}>
                ΔY
              </TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }} width={120}>
                θ
              </TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }} width={120}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recommendationList.map((row, index) => (
              <RecommendationRow
                key={row.rackName}
                row={row}
                applyRecommendationOnRack={() => applyRecommendationOnRack(row, index, 'angleAndOffset')}
                applyAngleRecommendationOnRack={
                  row.recommendation.dTheta ? () => applyRecommendationOnRack(row, index, 'angle') : undefined
                }
                applyOffsetRecommendationOnRack={
                  row.recommendation.dx || row.recommendation.dy
                    ? () => applyRecommendationOnRack(row, index, 'offset')
                    : undefined
                }
                dataList={dataList}
              />
            ))}

            {recommendationList.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                  No recommendation available{' '}
                  <AnimatedIcon
                    defaultAnimation="zoom-and-rotate"
                    animationDuration={500}
                    triggerOnAppear={true}
                    sx={{ verticalAlign: 'middle' }}
                  >
                    <Celebration />
                  </AnimatedIcon>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
