import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import CampaignIcon from '@mui/icons-material/Campaign';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ElevatorIcon from '@mui/icons-material/Elevator';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import LayersIcon from '@mui/icons-material/Layers';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SpeedIcon from '@mui/icons-material/Speed';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { saveZoneAction } from 'actions/zones';
import { getSvgFromIcon } from 'components/utils/generate-jsx';
import type * as d3 from 'd3';
import type { Polygon } from 'geojson';
import type { ZoneProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import store from 'store';
import type { DragEventProps } from '../elements';
import { RectangleOrTriangle } from '../elements';

const speedIconSvg = getSvgFromIcon(SpeedIcon);
const doorIconSvg = getSvgFromIcon(MeetingRoomIcon);
const elevatorIconSvg = getSvgFromIcon(ElevatorIcon);
const floorIconSvg = getSvgFromIcon(LayersIcon);
const stopCircleIconSvg = getSvgFromIcon(StopCircleIcon);
const noBeepIconSvg = getSvgFromIcon(VolumeOffIcon);
const hornIconSvg = getSvgFromIcon(CampaignIcon);
const blackHoleIconSvg = getSvgFromIcon(GpsOffIcon);
const localParkingIconSvg = getSvgFromIcon(LocalParkingIcon);
const doNotDisturbIconSvg = getSvgFromIcon(DoNotDisturbIcon);
const reduceCapacityIconSvg = getSvgFromIcon(ReduceCapacityIcon);
const batteryChargingFullIcon = getSvgFromIcon(BatteryChargingFullIcon);
const safetyAlertIconSvg = getSvgFromIcon(GppMaybeIcon);
const sensorOffIconSvg = getSvgFromIcon(SensorsOffIcon);
const roofHeightIcon = new DOMParser().parseFromString(
  '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M 5.75 20 v -6 h 3 v 6 h 3.75 v -8 h 2.25 L 7.25 3 L -0.25 12 h 2.25 v 8 z M 19.25 6.99 h 2.25 L 18.5 3 L 15.5 6.99 h 2.25 v 10.02 H 15.5 L 18.5 21 l 3 -3.99 h -2.25 z"></path></svg>',
  'image/svg+xml'
);
const safetyCheckIcon = getSvgFromIcon(SafetyCheckIcon);

export class Zone extends RectangleOrTriangle {
  constructor(
    id: string,
    geometry: Polygon,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    public properties: ZoneProperties
  ) {
    super(id, ShapeTypes.ZoneShape, geometry, projection, zoomScale);

    this.setLocked(!!this.properties?.locked);

    this.node.attr('layer-id', properties?.layerId || '');
  }

  public setActive(active = true): void {
    super.setActive(active);

    if (this.active) {
      this.drawZoneType();
    } else {
      this.removeZoneTypes();
    }
  }

  public hover(hover = true): void {
    super.hover(hover);

    if (hover) {
      this.drawZoneType();
    } else if (!this.active) {
      this.removeZoneTypes();
    }
  }

  protected drawZoneType(): void {
    const props = this.properties;
    const iconsToDisplay: (Document | Document[])[] = [];

    let isDoor = false;
    let isMaxSpeed = false;
    let isStop = false;
    let isHorn = false;
    let isNoParking = false;
    let isNoBeep = false;
    let isBlackHole = false;
    let isRoofHeight = false;
    let isLimitRobotCount = false;
    let isCurtainChange = false;
    let isBatteryCharger = false;
    let isSafetyAlert = false;
    let isSafetyManualAck = false;
    let isFloor = false;
    let isElevator = false;

    // we check if the zone has a property (or several ones)
    props?.rules?.forEach((rule) => {
      const ruleName = rule[0];

      if (ruleName === 'Door') {
        isDoor = true;
      } else if (ruleName === 'LimitSpeed') {
        isMaxSpeed = true;
      } else if (ruleName === 'StopEntering' || ruleName === 'StopLeaving') {
        isStop = true;
      } else if (ruleName === 'HornEntering' || ruleName === 'HornLeaving' || ruleName === 'HornInside') {
        isHorn = true;
      } else if (ruleName === 'NoParking') {
        isNoParking = true;
      } else if (ruleName === 'NoBeepInside') {
        isNoBeep = true;
      } else if (ruleName === 'BlackHole') {
        isBlackHole = true;
      } else if (ruleName === 'RoofHeight') {
        isRoofHeight = true;
      } else if (ruleName === 'LimitAgvCount') {
        isLimitRobotCount = true;
      } else if (ruleName === 'CurtainInhibitedArea') {
        isCurtainChange = true;
      } else if (ruleName === 'BatteryCharger') {
        isBatteryCharger = true;
      } else if (ruleName === 'NoHardSafetyInside') {
        isSafetyAlert = true;
      } else if (ruleName === 'SafetyManualAck') {
        isSafetyManualAck = true;
      } else if (ruleName === 'FloorName') {
        isFloor = true;
      } else if (ruleName === 'ElevatorName') {
        isElevator = true;
      }
    });

    // we list all the icons to add to the zone
    if (isDoor && !isFloor && !isElevator) {
      iconsToDisplay.push(doorIconSvg);
    }

    if (isFloor && !isElevator) {
      iconsToDisplay.push(floorIconSvg);
    }

    if (isElevator || (isElevator && isFloor) || (isElevator && isDoor) || (isElevator && isLimitRobotCount)) {
      iconsToDisplay.push(elevatorIconSvg);
    }

    if (isSafetyAlert) {
      iconsToDisplay.push(safetyAlertIconSvg);
    }

    if (isMaxSpeed) {
      iconsToDisplay.push(speedIconSvg);
    }

    if (isRoofHeight) {
      iconsToDisplay.push(roofHeightIcon);
    }

    if (isStop) {
      iconsToDisplay.push(stopCircleIconSvg);
    }

    if (isNoParking) {
      iconsToDisplay.push([localParkingIconSvg, doNotDisturbIconSvg]);
    }

    if (isNoBeep) {
      iconsToDisplay.push(noBeepIconSvg);
    }

    if (isHorn) {
      iconsToDisplay.push(hornIconSvg);
    }

    if (isLimitRobotCount && !isElevator) {
      iconsToDisplay.push(reduceCapacityIconSvg);
    }

    if (isBatteryCharger) {
      iconsToDisplay.push(batteryChargingFullIcon);
    }

    if (isBlackHole) {
      iconsToDisplay.push(blackHoleIconSvg);
    }

    if (isCurtainChange) {
      iconsToDisplay.push(sensorOffIconSvg);
    }

    if (isSafetyManualAck) {
      iconsToDisplay.push(safetyCheckIcon);
    }

    const zoneWidth = Math.abs(this.geometry.coordinates[0][0][0] - this.geometry.coordinates[0][1][0]);
    const zoneHeight = Math.abs(this.geometry.coordinates[0][0][1] - this.geometry.coordinates[0][2][1]);
    const zoneIconsCoverage = 0.5; // the coverage of the icons in the zone

    // we add the icons to the zone
    iconsToDisplay.forEach((iconOrIcons, index) => {
      const iconDim = Math.min((zoneWidth * zoneIconsCoverage) / iconsToDisplay.length, zoneHeight * zoneIconsCoverage);
      const iconsTotalDim = iconDim * iconsToDisplay.length;

      let i = 0;

      do {
        const icon = Array.isArray(iconOrIcons) ? iconOrIcons[i] : iconOrIcons;
        this.node
          .append('svg')
          .attr('viewBox', icon.querySelector('svg')?.getAttribute('viewBox') || '')
          .attr(
            'x',
            (this.geometry.coordinates[0][0][0] + this.geometry.coordinates[0][1][0]) / 2 -
              iconDim / 2 +
              index * iconDim -
              (iconsTotalDim - iconDim) / 2
          )
          .attr('y', -(this.geometry.coordinates[0][0][1] + this.geometry.coordinates[0][2][1]) / 2 - iconDim / 2)
          .attr('width', iconDim)
          .attr('height', iconDim)
          .attr('stroke', 'none')
          .attr('fill-opacity', 0.5)
          .classed('icon-zone', true)
          .append('path')
          .attr('d', icon.querySelector('path')?.getAttribute('d') || '');

        i++;
      } while (Array.isArray(iconOrIcons) && i < iconOrIcons.length);
    });
  }

  protected removeZoneTypes(): void {
    this.node.selectAll('.icon-zone').remove();
  }

  protected onDragEnd(properties?: ZoneProperties): void {
    const zoneMoved =
      this.geometry.coordinates !== store.getState().circuit.present.zones.entities[this.id].geometry.coordinates;
    if (!this.draggeable() || !zoneMoved) return;

    super.onDragEnd(properties);

    setTimeout(() => {
      store.dispatch(
        saveZoneAction({
          id: this.id,
          geometry: this.geometry,
        })
      );
    }, 0);
  }

  protected translateEnd(): void {
    if (!this.draggeable()) return;

    store.dispatch(
      saveZoneAction({
        id: this.id,
        geometry: this.geometry,
      })
    );
  }

  protected onDrag(): void {
    if (!this.draggeable()) return;

    this.node.selectAll('.icon-zone').remove();

    super.onDrag();
  }

  protected onHandleDragged(): void {
    if (!this.draggeable()) return;

    super.onHandleDragged();

    this.node.selectAll('.icon-zone').remove();
  }

  protected onHandleDragEnd(properties?: DragEventProps): void {
    super.onHandleDragEnd(properties);

    store.dispatch(
      saveZoneAction({
        id: this.id,
        geometry: this.geometry,
      })
    );
  }

  protected translate(): void {
    if (!this.draggeable()) return;

    this.node.selectAll('.icon-zone').style('display', `none`);

    super.translate();
  }
}
