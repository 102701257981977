import {
  Alert,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { HelpIconTooltip } from 'components/utils/tooltips';
import { useMemo, useState } from 'react';
import { theme } from 'utils/mui-theme';
import type { RobotsHealth, RobotsMeasuresInfo } from './models';
import { StyledTableRowRackAnalysis } from './rack-analysis-dialog';
import { convertToDisplayUnitRackAnalysis, secondRowStickyHeaderTopOffset } from './utils';

interface StepRobotHealthProps {
  robotsHealth: RobotsHealth;
  toggledRobotsMeasure: RobotsMeasuresInfo;
  toggleRobotHealth: (robotName: string) => void;
}

export function StepRobotHealth(props: StepRobotHealthProps): JSX.Element {
  const { robotsHealth, toggleRobotHealth, toggledRobotsMeasure } = props;

  const sortedRobotsHealth = useMemo(
    () => Object.entries(robotsHealth).sort(([nameA], [nameB]) => nameA.localeCompare(nameB)),
    [robotsHealth]
  );

  const [substractTotalMean, setSubstractTotalMean] = useState(false);

  const totalMean = useMemo(() => {
    const totalMean = {
      x: 0,
      y: 0,
      z: 0,
    };

    let enabledRobotsCount = 0;

    Object.values(sortedRobotsHealth).forEach(([robotName, robotHealth]) => {
      if (!toggledRobotsMeasure[robotName].disabled) {
        enabledRobotsCount += 1;
        totalMean.x += robotHealth.mean.x;
        totalMean.y += robotHealth.mean.y;
        totalMean.z += robotHealth.mean.z;
      }
    });

    if (enabledRobotsCount > 0) {
      totalMean.x /= enabledRobotsCount;
      totalMean.y /= enabledRobotsCount;
      totalMean.z /= enabledRobotsCount;
    }

    return totalMean;
  }, [sortedRobotsHealth, toggledRobotsMeasure]);

  return (
    <>
      {!Object.values(toggledRobotsMeasure).some((robotHealth) => !robotHealth.disabled) && (
        <Alert severity="error" sx={{ margin: '0 24px' }}>
          At least one robot must be enabled to continue
        </Alert>
      )}
      <TableContainer sx={{ maxHeight: 690, padding: '0 24px 24px 24px', width: 'unset' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }}>
                <HelpIconTooltip
                  title="Toggle a robot to include or exclude it from all calculation"
                  sx={{
                    fontSize: '1rem',
                    marginLeft: 'unset !important',
                  }}
                />
              </TableCell>
              <TableCell>Robot name</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Unique slots count</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Measures count</TableCell>
              <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                <Tooltip title="Substract the robot mean by the total mean of toggled robots">
                  <Checkbox
                    checked={substractTotalMean}
                    onChange={(event) => setSubstractTotalMean(event.target.checked)}
                    indeterminate={substractTotalMean}
                    sx={{
                      marginLeft: 'unset !important',
                    }}
                  />
                </Tooltip>
                Mean (mm)
                <HelpIconTooltip
                  title="Mean of the measures taken by the robot on racks (precision)"
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </TableCell>
              <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                Standard deviation (mm)
                <HelpIconTooltip
                  title="Standard deviation of the measures taken by the robot on racks (accuracy)"
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔX</TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔY</TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  top: secondRowStickyHeaderTopOffset,
                }}
              >
                ΔZ
              </TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔX</TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔY</TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔZ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRobotsHealth.map(([robotName, robotHealth]) => {
              const checked = !toggledRobotsMeasure[robotName].disabled;
              const measuresCount = toggledRobotsMeasure[robotName].measures.length;
              const slotsCount = toggledRobotsMeasure[robotName].slotsCount;

              const disabledStyle = !checked ? { color: theme.palette.grey[400] } : {};

              return (
                <StyledTableRowRackAnalysis key={robotName}>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <Checkbox checked={checked} onChange={() => toggleRobotHealth(robotName)} />
                  </TableCell>
                  <TableCell sx={{ ...disabledStyle }}>{robotName}</TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>{slotsCount}</TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>{measuresCount}</TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>
                    {convertToDisplayUnitRackAnalysis(
                      substractTotalMean ? robotHealth.mean.x - totalMean.x : robotHealth.mean.x
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>
                    {convertToDisplayUnitRackAnalysis(
                      substractTotalMean ? robotHealth.mean.y - totalMean.y : robotHealth.mean.y
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>
                    {convertToDisplayUnitRackAnalysis(
                      substractTotalMean ? robotHealth.mean.z - totalMean.z : robotHealth.mean.z
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>
                    {convertToDisplayUnitRackAnalysis(robotHealth.sd.x)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>
                    {convertToDisplayUnitRackAnalysis(robotHealth.sd.y)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', ...disabledStyle }}>
                    {convertToDisplayUnitRackAnalysis(robotHealth.sd.z)}
                  </TableCell>
                </StyledTableRowRackAnalysis>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
