import type { SimpleSelection } from 'drawings/shared';
import type { LineString as LineStringGeoJSON } from 'geojson';
import type { OnDragOptions } from './shape.element';
import { Shape } from './shape.element';

export class LineString extends Shape<LineStringGeoJSON> {
  public createNode(): SimpleSelection<SVGGElement, undefined> {
    const node = super.createNode().style('stroke-width', null);

    return node;
  }

  protected onDrag(opts?: OnDragOptions): void {
    super.onDrag(opts);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected onDragEnd(properties: any): void {
    super.onDragEnd(properties);
  }
}
