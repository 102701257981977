import { useDebouncedValue } from '@mantine/hooks';
import { AddCircle, Check, Clear, Delete, Edit, Plumbing } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import { CollapseMore } from 'components/utils/collapse-more';
import { collapseTransitionDuration } from 'components/utils/constants';
import type { CustomStep, ExtraStepNames } from 'flows/custom-steps.model';
import { defaultMissionType, extraStepNames } from 'flows/custom-steps.model';
import {
  addCustomStep,
  addEventToCustomStep,
  removeCustomStep,
  setCustomStepMissionType,
  setCustomStepName,
  setSelectedCustomStepId,
} from 'flows/flows';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch, useAppSelector } from 'store';
import { isInArray } from 'utils/array';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { theme } from 'utils/mui-theme';
import { getObjectKeys } from 'utils/object';
import { CustomEventList } from './custom-event-list';
import { extraStepNameToLabel } from './custom-steps-inputs-description';

export function CustomStepsEditor(): JSX.Element {
  const customSteps = useAppSelector((state) => state.flows.customSteps);
  const selectedCustomStepId = useAppSelector((state) => state.flows.selectedCustomStepId);
  const [delayedSelectedCustomStepId] = useDebouncedValue(selectedCustomStepId, collapseTransitionDuration);

  const [editName, setEditName] = useState(false);

  const selectedCustomStep = useMemo(() => {
    return customSteps.find((customStep) => customStep.id === selectedCustomStepId);
  }, [customSteps, selectedCustomStepId]);

  const delayedSelectedCustomStep = useMemo(() => {
    return customSteps.find((customStep) => customStep.id === delayedSelectedCustomStepId);
  }, [customSteps, delayedSelectedCustomStepId]);

  const AvatarIcon = useMemo(() => {
    return Plumbing;
  }, []);

  const handleEditName = useCallback((newValue: boolean) => {
    setEditName(newValue);
  }, []);

  const displayCustomStepData = selectedCustomStepId === delayedSelectedCustomStepId;

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '500px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title="Custom Step Editor"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <Stack direction={'row'} spacing={1}>
          {!editName ? (
            <CustomStepListAndSelection
              customSteps={customSteps}
              selectedCustomStepId={selectedCustomStepId}
              handleEditName={handleEditName}
            />
          ) : (
            selectedCustomStep && (
              <RenameCustomStep
                customStep={selectedCustomStep}
                customSteps={customSteps}
                handleEditName={handleEditName}
              />
            )
          )}
        </Stack>

        {selectedCustomStep && (
          <Collapse
            in={displayCustomStepData}
            timeout={delayedSelectedCustomStepId === selectedCustomStepId ? collapseTransitionDuration : 0}
            sx={{
              marginTop: theme.spacing(1),
            }}
          >
            <Border>
              <CustomStepEdition
                key={delayedSelectedCustomStepId || selectedCustomStepId}
                customStep={
                  !displayCustomStepData && delayedSelectedCustomStep
                    ? delayedSelectedCustomStep
                    : selectedCustomStep ?? {}
                }
              />
            </Border>
          </Collapse>
        )}
      </CardContent>
    </Card>
  );
}

interface CustomStepDataProps {
  customStep: CustomStep;
}
function CustomStepEdition(props: CustomStepDataProps): JSX.Element {
  const { customStep } = props;

  const dispatch = useAppDispatch();

  const handleMissionTypeChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newMissionType = e.target.value;
      if (!isInArray(defaultMissionType, newMissionType)) {
        // eslint-disable-next-line no-console
        console.error(`Invalid mission type: ${newMissionType}`);

        return;
      }

      dispatch(
        setCustomStepMissionType({
          id: customStep.id,
          missionType: newMissionType,
        })
      );
    },
    [customStep.id, dispatch]
  );

  return (
    <Box
      component="div"
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="mission-type-select-label">Mission Type</InputLabel>
        <Select
          labelId="mission-type-select-label"
          id="mission-type-select"
          fullWidth
          size="small"
          value={customStep.missionType}
          onChange={handleMissionTypeChange}
          label="Mission Type"
        >
          <MenuItem value="move">Move</MenuItem>
          <MenuItem value="pick">Pick</MenuItem>
          <MenuItem value="drop">Drop</MenuItem>
        </Select>
      </FormControl>

      <Border>
        <CustomEventsEditor customStep={customStep} />
      </Border>
    </Box>
  );
}

interface CustomStepListAndSelectionProps {
  customSteps: CustomStep[];
  selectedCustomStepId?: string;
  handleEditName: (newValue: boolean) => void;
}

function CustomStepListAndSelection(props: CustomStepListAndSelectionProps): JSX.Element {
  const { customSteps, selectedCustomStepId, handleEditName } = props;

  const dispatch = useAppDispatch();

  const selectedCustomStep = useMemo(() => {
    return customSteps.find((customStep) => customStep.id === selectedCustomStepId);
  }, [customSteps, selectedCustomStepId]);

  const handleSelectCustomStepId = useCallback(
    (e: SelectChangeEvent<string>) => {
      dispatch(setSelectedCustomStepId(e.target.value));
    },
    [dispatch]
  );

  const handleDeleteCustomStep = useCallback(() => {
    if (!selectedCustomStep) {
      // eslint-disable-next-line no-console
      console.error('No selected custom step');

      return;
    }

    dispatch(removeCustomStep(selectedCustomStep.id));
    SnackbarUtils.success(`Custom Step "${selectedCustomStep.name}" removed`);
  }, [dispatch, selectedCustomStep]);

  const handleCreateCustomStep = useCallback(() => {
    let newCustomStepName = 'New Custom Step';
    let nb = 1;
    // eslint-disable-next-line no-loop-func
    while (customSteps.find((flow) => flow.name === newCustomStepName)) {
      newCustomStepName = `New Custom Step ${nb++}`;
    }

    const newCustomStepId = generateShapeId();

    dispatch(
      addCustomStep({
        id: newCustomStepId,
        name: newCustomStepName,
        missionType: 'move', // Default mission type
        scan: 'optional',
        perception: 'optional',
        customEvents: {},
      })
    );

    dispatch(setSelectedCustomStepId(newCustomStepId));
    SnackbarUtils.success(`Custom Step "${newCustomStepName}" created`);
  }, [customSteps, dispatch]);

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="custom-steps-list">Custom Steps</InputLabel>
        <Select
          labelId="custom-steps-list"
          label="Custom Steps"
          id="custom-steps-list-select"
          value={selectedCustomStepId || ''}
          onChange={handleSelectCustomStepId}
          size="small"
        >
          {customSteps.map((customStep) => (
            <MenuItem key={customStep.id} value={customStep.id}>
              {customStep.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Rename this custom step">
        <Box component="span">
          <IconButton
            aria-label="rename custom step"
            disabled={!selectedCustomStep}
            onClick={() => handleEditName && handleEditName(true)}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Delete this custom step">
        <Box component="span">
          <IconButton aria-label="delete custom step" disabled={!selectedCustomStep} onClick={handleDeleteCustomStep}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Create a new custom step">
        <Box component="span">
          <IconButton aria-label="create custom step" onClick={handleCreateCustomStep}>
            <AddCircle fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface RenameCustomStepProps {
  customStep: CustomStep;
  customSteps: CustomStep[];
  handleEditName: (newValue: boolean) => void;
}
function RenameCustomStep(props: RenameCustomStepProps): JSX.Element {
  const { customStep, customSteps, handleEditName } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleValidateName = useCallback(() => {
    const newName = inputRef.current?.value;
    if (!newName || customSteps.find((cs) => cs.name === newName && cs.id !== customStep.id)) {
      setError(true);

      return;
    }

    dispatch(
      setCustomStepName({
        id: customStep.id,
        name: newName,
      })
    );

    if (handleEditName) {
      handleEditName(false);
    }
  }, [dispatch, customStep.id, customSteps, handleEditName]);

  const handleRevertName = useCallback(() => {
    if (handleEditName) {
      handleEditName(false);
    }
  }, [handleEditName]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (error) setError(false);

      if (e.key === 'Enter') {
        handleValidateName();
      } else if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [error, handleRevertName, handleValidateName]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="custom-step-name"
          label="Custom Step name"
          variant="outlined"
          size="small"
          defaultValue={customStep.name}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          error={error}
          helperText={error ? 'This name is incorrect or already used' : undefined}
        />
      </FormControl>

      <Tooltip title="Revert">
        <Box component="span">
          <IconButton aria-label="revert" onClick={handleRevertName}>
            <Clear fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Validate">
        <Box component="span">
          <IconButton aria-label="validate" onClick={handleValidateName}>
            <Check fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface CustomEventsEditorProps {
  customStep: CustomStep;
}
export function CustomEventsEditor(props: CustomEventsEditorProps): JSX.Element {
  const { customStep } = props;

  const dispatch = useAppDispatch();

  const availableEventTypes = useMemo(() => {
    return getObjectKeys(customStep.customEvents).sort((a, b) => {
      return extraStepNames.indexOf(a) - extraStepNames.indexOf(b);
    });
  }, [customStep.customEvents]);

  const handleAddCustomEvent = useCallback(() => {
    const stepName: ExtraStepNames = 'RPK_START';
    let nb = 1;
    let newEventName = `Event ${nb}`;

    const allEventNames = new Set(
      Object.values(customStep.customEvents)
        .flat()
        .map((event) => event.name)
    );

    // eslint-disable-next-line no-loop-func
    while (allEventNames.has(newEventName)) {
      nb++;
      newEventName = `Event ${nb}`;
    }

    dispatch(
      addEventToCustomStep({
        customStepId: customStep.id,
        stepName,
        event: {
          name: newEventName,
          actionType: 'none',
        },
      })
    );
  }, [customStep.customEvents, customStep.id, dispatch]);

  return (
    <Box
      component="div"
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <Button fullWidth variant="contained" color="primary" onClick={handleAddCustomEvent}>
        Add Custom Event
      </Button>

      {availableEventTypes.map((eventType) => {
        const label = extraStepNameToLabel[eventType];
        const events = customStep.customEvents[eventType];

        if (!events) return <></>;

        return (
          <Box key={eventType} component="div">
            <CollapseMore defaultExpended={true} title={label}>
              <CustomEventList events={events} customStepId={customStep.id} stepName={eventType} />
            </CollapseMore>
          </Box>
        );
      })}
    </Box>
  );
}
