import { Avatar, Chip, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { openDialogAction } from 'actions';
import { DialogTypes } from 'models';
import { useMemo } from 'react';
import { useAppDispatch } from 'store';
import { PreferencesService } from 'utils/preferences';
import { truckNameToMarketingTruckName } from 'utils/robots-name';

// Maximum characters length for trucksSerial and trucksName
export const maximumCharLength = 32;
interface TrucksChipsProps {
  /**
   * The list of trucks to display
   * [string, number][] = [model name, number of trucks of this model]
   */
  trucksPictures: [string, number][];
  /**
   * Providing this parameter will enable the dense mode if the number of models is over this number
   * In dense mode, the chips will be smaller and closer to each other to save some space
   * To always enable the dense mode, set this parameter to 0
   * To never enable the dense mode, set this parameter to undefined (or infinity)
   */
  maxModelsEnableDenseMode?: number;
}
export function TrucksChips({ trucksPictures, maxModelsEnableDenseMode }: TrucksChipsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const nbTruckPictures = trucksPictures.length;
  const denseModeEnabled = maxModelsEnableDenseMode !== undefined && nbTruckPictures >= maxModelsEnableDenseMode;

  const trucksPicturePath = useMemo(() => {
    if (!PreferencesService.arePreferencesFullyLoaded()) return [];

    return PreferencesService.getPreferenceValue('general/trucksPicturePath') as string[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trucksPictures]);

  const errorsPerModel = useMemo(() => {
    const errorsPerModel: {
      [key: string]: {
        serial: number;
        name: number;
      };
    } = {};

    if (!PreferencesService.arePreferencesFullyLoaded()) return errorsPerModel;

    let trucksName: string[] = [];
    let trucksSerial: string[] = [];

    try {
      trucksName = PreferencesService.getPreferenceValue('general/trucksName') as string[];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get general/trucksName from preferences`, e);
    }

    try {
      trucksSerial = PreferencesService.getPreferenceValue('general/trucksSerial') as string[];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get general/trucksSerial from preferences`, e);
    }

    trucksPicturePath.forEach((robotName, index) => {
      const robotIds = trucksPicturePath
        .map((path, index) => (path === robotName ? index : null))
        .filter((id): id is number => id !== null);

      if (!robotIds.includes(index)) return;

      const truckSerial = trucksSerial?.[index];
      if (truckSerial && truckSerial.length >= maximumCharLength) {
        if (!errorsPerModel[robotName]) {
          errorsPerModel[robotName] = {
            serial: 0,
            name: 0,
          };
        }

        errorsPerModel[robotName].serial++;
      }

      const truckName = trucksName?.[index];
      if (truckName && truckName.length >= maximumCharLength) {
        if (!errorsPerModel[robotName]) {
          errorsPerModel[robotName] = {
            serial: 0,
            name: 0,
          };
        }

        errorsPerModel[robotName].name++;
      }
    });

    return errorsPerModel;
  }, [trucksPicturePath]);

  return (
    <Box component="span">
      {trucksPictures.map((truckPicture) => {
        const modelName = truckPicture[0];
        const errors = errorsPerModel?.[modelName];
        const isError = !!errors;

        const highestErrorNb = isError ? Math.max(errors?.name, errors?.serial) : 0;
        const plural = highestErrorNb > 1;
        const errorMsg = isError
          ? plural
            ? `${highestErrorNb} robots have an error`
            : '1 robot has an error'
          : undefined;

        return (
          <Tooltip title={errorMsg} arrow key={modelName}>
            <Chip
              size={denseModeEnabled ? 'small' : undefined}
              avatar={<Avatar src={`/img/trucks/${modelName}-icon.png`} />}
              label={
                <>
                  {truckPicture[1]}
                  <Box
                    component={'span'}
                    className="nameTruckChipProject"
                    sx={{
                      width: 0,
                      display: 'inline-flex',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'unset',
                    }}
                  >
                    {'× '}
                    {truckNameToMarketingTruckName(modelName.split('/')[1])}
                  </Box>
                </>
              }
              variant="outlined"
              color={isError ? 'error' : undefined}
              onClick={() =>
                dispatch(
                  openDialogAction({
                    type: DialogTypes.RobotData,
                    payload: {
                      robotName: modelName,
                    },
                  })
                )
              }
              sx={{
                marginRight: denseModeEnabled ? '5px' : '10px',
                marginLeft: denseModeEnabled ? '0px' : '10px',
                textOverflow: 'unset',
                '@media (max-width:800px)': {
                  display: 'none',
                },
                '&:hover .nameTruckChipProject': {
                  width: '100%',
                },
                '& .MuiChip-label': {
                  textOverflow: 'unset',
                },
              }}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
}
