import { LayerNames } from './shared';
import { SVGLayer } from './svg.layer';

export class FloorPlanLayer extends SVGLayer {
  constructor() {
    super(LayerNames.FloorPlan);
  }

  /*
  public setImage(image: MapImage): void {
    if (image.height) {
      const floorPlanEl = this.node;

      // if a plan has been already drawn, we remove it
      floorPlanEl.selectAll('image').remove();

      // then we draw the image
      floorPlanEl
        .append('svg:image')
        .attr('data-test', image.x.toString())
        .attr('x', image.x)
        .attr('y', -image.y)
        .attr('xlink:href', image.URL)
        .attr('height', image.height)
        .style('image-rendering', 'pixelated');
    }
  }
  */
}
