import type { StationPositionTypeAndAll } from 'components/toolboxes/station';
import type { StationPositionWithName } from 'flows/flows';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import store from 'store';

interface GetAllPositionsProps {
  /** Whether we can add points */
  canAddPoints?: boolean;
  /** Whether we can add slots */
  canAddSlots?: boolean;
  /** The selected position type */
  selectedPositionType?: StationPositionTypeAndAll;
}

export function getAllPositions(props: GetAllPositionsProps): StationPositionWithName[] {
  const { canAddPoints = true, canAddSlots = true, selectedPositionType = 'all' } = props;

  const storeState = store.getState();

  const racksIds = storeState.circuit.present.racks.ids;
  const racks = storeState.circuit.present.racks.entities;

  const stockZonesIds = storeState.circuit.present.stockZones.ids;
  const stockZones = storeState.circuit.present.stockZones.entities;

  const pointsIds = storeState.circuit.present.points.ids;
  const points = storeState.circuit.present.points.entities;

  const availablePositions: StationPositionWithName[] = [];

  if (canAddPoints) {
    pointsIds.forEach((pointId) => {
      const point = points[pointId];

      if (selectedPositionType === 'all' || selectedPositionType === ShapeTypes.PointShape)
        availablePositions.push({
          id: pointId,
          type: ShapeTypes.PointShape,
          name: point.properties.name,
        });
    });
  }

  if (canAddSlots) {
    stockZonesIds.forEach((stockZoneId) => {
      const stockZone = stockZones[stockZoneId];

      if (selectedPositionType === 'all' || selectedPositionType === ShapeTypes.StockZoneShape) {
        availablePositions.push({
          id: stockZoneId,
          type: ShapeTypes.StockZoneShape,
          name: stockZone.properties.name,
        });
      }

      if (selectedPositionType === 'all' || selectedPositionType === SlotTypes.StockLine) {
        stockZone.properties.slots.forEach((slotLine) => {
          availablePositions.push({
            id: slotLine.id,
            type: SlotTypes.StockLine,
            name: slotLine.name,
          });
        });
      }
    });
  }

  if (canAddSlots) {
    racksIds.forEach((rackId) => {
      const rack = racks[rackId];

      if (selectedPositionType === 'all' || selectedPositionType === ShapeTypes.RackShape) {
        availablePositions.push({
          id: rackId,
          type: ShapeTypes.RackShape,
          name: rack.properties.name,
        });
      }

      if (selectedPositionType === 'all' || selectedPositionType === SlotTypes.Slot) {
        rack.properties.columns.forEach((column) => {
          column.cells.forEach((cell) => {
            cell.names.forEach((names) => {
              names.forEach((n) => {
                availablePositions.push({
                  id: n.id,
                  type: SlotTypes.Slot,
                  name: n.value,
                  disabled: n.disabled || cell.disabled,
                });
              });
            });
          });
        });
      }
    });
  }

  return availablePositions;
}
