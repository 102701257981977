import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import type { SelectChangeEvent } from '@mui/material';
import { Box, IconButton, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAsyncMemo } from 'use-async-memo';
import { PreferencesService } from 'utils/preferences';
import type { LanguageCode } from './constants';
import { availableLanguages, delayStopEditBlur } from './constants';

function languageCodeToLabel(code: string): string {
  const lang = availableLanguages.find((lang) => lang.code === code);

  return lang ? lang.label : code;
}

interface EditLanguageProps {
  projectOpened: boolean;
}

export function EditLanguage({ projectOpened }: EditLanguageProps): JSX.Element | null {
  const [editLanguage, setEditLanguage] = useState(false);
  const [language, setLanguage] = useState<LanguageCode | null>(null);
  useLayoutEffect(() => {
    if (!projectOpened) return;

    try {
      setLanguage(PreferencesService.getPreferenceValue('general/language') as LanguageCode);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get general/language from preferences`, e);
    }
  }, [projectOpened]);
  const fancyLanguage = useMemo(() => {
    return language ? languageCodeToLabel(language) : language;
  }, [language]);

  const handleChangeLanguage = useCallback(async (e: SelectChangeEvent<string>) => {
    const newLanguage = e.target.value as LanguageCode;

    const [ok] = await PreferencesService.setPreferenceValue('general/language', newLanguage, true);

    if (ok) {
      setLanguage(newLanguage);

      SnackbarUtils.success(`Language updated to ${languageCodeToLabel(newLanguage)} (${newLanguage})`);

      setTimeout(() => setEditLanguage(false), 100);
    } else {
      SnackbarUtils.error(`Could not update language`);
    }
  }, []);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  if (!language) return null;

  return (
    <ListItem>
      <ListItemIcon>
        <LanguageIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            {editLanguage ? (
              <Select
                value={language || ''}
                size="small"
                autoWidth
                renderValue={(value) => languageCodeToLabel(value)}
                onChange={handleChangeLanguage}
                onClose={(e) => setTimeout(() => setEditLanguage(false), delayStopEditBlur)}
              >
                {availableLanguages.map((lang) => (
                  <MenuItem value={lang.code} key={lang.code} selected={lang.code === language}>
                    {!!lang.icon && (
                      <ListItemIcon sx={{ height: '15px' }}>
                        <lang.icon />
                      </ListItemIcon>
                    )}

                    <ListItemText primary={lang.label} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <>
                {fancyLanguage}
                {!editLanguage ? (
                  <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
                    <Box component="span">
                      <IconButton
                        onClick={() => {
                          setEditLanguage(true);
                        }}
                        disabled={!editProjectPerm}
                      >
                        <EditIcon
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Tooltip>
                ) : undefined}
              </>
            )}
          </>
        }
        secondary={
          <>
            Language
            <HelpIconTooltip
              title="The language of the project. The robots displays will use it by default."
              sx={{
                fontSize: '1rem',
              }}
            />
          </>
        }
      />
    </ListItem>
  );
}
