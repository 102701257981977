import { FormControlLabel, Switch } from '@mui/material';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import ecStat from 'echarts-stat';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/utils';
import { useCallback, useMemo, useState } from 'react';
import store from 'store';
import { theme } from 'utils/mui-theme';
import { formatTime } from 'utils/time';
import type { Recommendation, RobotMeasures } from './models';
import { computeLinearRegressionFromMeasures } from './utils';

echarts.registerTransform((ecStat as any).transform.regression);

export interface LinearRegressionResult {
  expression: string;
  points: [number, number][];
  parameter: {
    gradient: number;
    intercept: number;
  };
}

interface ChartMeasuresProps {
  row: Recommendation;
  dataList: RobotMeasures;
}

export function ChartMeasuresRack(props: ChartMeasuresProps): JSX.Element {
  const { row, dataList } = props;

  /**
   * Use same scale for both axis
   */
  const [sameScale, setSameScale] = useState(false);

  const { dataPoints, actualMeasures, expectedMeasures, linearRegressionActualData, maxExpectedY, minExpectedY } =
    useMemo(
      () =>
        computeLinearRegressionFromMeasures({
          dataList,
          rackName: row.rackName,
        }),
      [dataList, row.rackName]
    );

  /**
   * Convert a date from csv format to a Date object
   * @param date - The date in csv format
   * @returns The date as a Date object
   */
  const convertCsvDate = useCallback((date: string): Date => {
    // example of csv date: 20230323_111756743
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const hours = date.slice(9, 11);
    const minutes = date.slice(11, 13);
    const seconds = date.slice(13, 15);
    const milliseconds = date.slice(15, 18);

    const newDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`);

    return newDate;
  }, []);

  const options = useMemo(() => {
    const rackName = row.rackName;
    const racksIds = store.getState().circuit.present.racks.ids;
    const racks = store.getState().circuit.present.racks.entities;

    const rackId = racksIds.find((id) => racks[id].properties.name === rackName);
    const rack = rackId ? racks[rackId] : null;

    const rackDimensions = rack
      ? [
          getDistanceBetweenPoints(rack.geometry.coordinates[0][0], rack.geometry.coordinates[0][1]),
          getDistanceBetweenPoints(rack.geometry.coordinates[0][1], rack.geometry.coordinates[0][2]),
        ]
      : null;
    const rackLength = rackDimensions ? Math.max(...rackDimensions) / 100 : null; // [m]

    const useSameScale = sameScale && !!rack && !!rackLength;

    const opts: echarts.EChartsOption = {
      xAxis: {
        name: 'Y [m]',
        min: useSameScale ? Math.floor(-rackLength / 2) : (value) => Math.floor(value.min - 0.1),
        max: useSameScale ? Math.ceil(rackLength / 2) : (value) => Math.ceil(value.max + 0.1),
        scale: false,
      },
      yAxis: {
        name: 'X [m]',
        min: useSameScale ? Math.floor(-rackLength / 2) : (value) => Math.floor((value.min - 0.005) * 100) / 100,
        max: useSameScale ? Math.ceil(rackLength / 2) : (value) => Math.ceil((value.max + 0.005) * 100) / 100,
        scale: false,
      },
      grid: {
        top: 100,
      },
      title: {
        text: `Measures for rack ${row.rackName}`,
        subtext: 'Top view of the rack',
        left: 'center',
        top: 5,
      },
      legend: {
        bottom: 5,
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const dataPoint = dataPoints.find((v) => v?.id === params.data[2]);

          if (!dataPoint) return '';

          return `
            <div style="padding: 10px; font-size: 12px; line-height: 1.5;">
              <div><strong>Date:</strong> ${formatTime(convertCsvDate(dataPoint.date))}</div>
              <div><strong>Serial:</strong> ${dataPoint.serial}</div>
              <div><strong>Slot:</strong> ${dataPoint.slotName}</div>
              <div><strong>Rack:</strong> ${dataPoint.rackName}</div>
              <div><strong>Expected X:</strong> ${dataPoint.expectedX.toFixed(3)} m</div>
              <div><strong>Expected Y:</strong> ${dataPoint.expectedY.toFixed(3)} m</div>
              <div><strong>Actual X:</strong> ${dataPoint.actualX.toFixed(3)} m</div>
              <div><strong>Actual Y:</strong> ${dataPoint.actualY.toFixed(3)} m</div>
              <div><strong>Error in X:</strong> ${dataPoint.errorInX.toFixed(3)} m</div>
              <div><strong>Error in Y:</strong> ${dataPoint.errorInY.toFixed(3)} m</div>
            </div>
          `;
        },
      },
      dataset: [
        {
          id: 'expected_measures',
          name: 'expected_measures',
          source: expectedMeasures,
        },
        {
          id: 'actual_measures',
          name: 'actual_measures',
          source: actualMeasures,
        },
        {
          id: 'actual_measures_linear_regression',
          source: actualMeasures.map((v) => [v[0], v[1]]),
          transform: {
            type: 'ecStat:regression',
            config: {
              method: 'linear',
            },
          },
        },
      ],
      series: [
        {
          name: 'Expected measures',
          type: 'scatter',
          datasetId: 'expected_measures',
          itemStyle: {
            color: theme.palette.primary.main,
          },
          symbol: 'circle',
        },
        {
          name: 'Actual measures',
          type: 'scatter',
          datasetId: 'actual_measures',
          itemStyle: {
            color: theme.palette.secondary.main,
          },
          symbol: 'circle',
        },
        {
          name: 'Expected rack angle',
          type: 'line',
          data: [
            [minExpectedY, 0],
            [maxExpectedY, 0],
          ],
          lineStyle: {
            color: theme.palette.primary.main,
            width: 2,
            type: 'solid',
          },
          itemStyle: {
            opacity: 0,
          },
        },
        {
          name: 'Actual rack angle',
          type: 'line',
          data: linearRegressionActualData.points,
          lineStyle: {
            color: theme.palette.secondary.main,
            width: 2,
            type: 'solid',
          },
          itemStyle: {
            opacity: 0,
          },
        },
        {
          name: 'ΔX',
          type: 'line',
          data: [
            [0, linearRegressionActualData.parameter.intercept],
            [0, 0],
          ],
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            type: 'dotted',
            color: '#009E73',
            width: 3,
          },
          symbol: 'rect',
          symbolSize: 10,
          z: 100,
        },
        // {
        //   name: 'ΔY',
        //   type: 'line',
        //   data: [
        //     [-linearRegressionActualData.parameter.intercept / linearRegressionActualData.parameter.gradient, 0],
        //     [0, 0],
        //   ],
        //   itemStyle: {
        //     opacity: 0,
        //   },
        //   lineStyle: {
        //     type: 'dashed',
        //     color: '#E69F00',
        //     width: 3,
        //   },
        //   symbol: 'rect',
        //   symbolSize: 10,
        //   z: 100,
        // },
      ],
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0],
          start: 0,
          end: 100,
        },
        {
          type: 'inside',
          yAxisIndex: [0],
          start: 0,
          end: 100,
        },
      ],
    };

    return opts;
  }, [
    actualMeasures,
    convertCsvDate,
    dataPoints,
    expectedMeasures,
    linearRegressionActualData.parameter.intercept,
    linearRegressionActualData.points,
    maxExpectedY,
    minExpectedY,
    row.rackName,
    sameScale,
  ]);

  return (
    <>
      <FormControlLabel
        control={<Switch checked={sameScale} onChange={() => setSameScale((prev) => !prev)} />}
        label="Same scale for both axis"
      />
      <ReactECharts
        option={options}
        style={{
          height: 500,
          width: 1000,
        }}
      />
    </>
  );
}
