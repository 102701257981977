import React from 'react';
import { LargeError } from 'components/core';
import { FullPageLayout } from 'components/layouts';

interface NotImplementedPageProps {}

export function NotImplementedPage(props: NotImplementedPageProps): JSX.Element {
  return (
    <FullPageLayout verticallyCentered>
      <LargeError code={501} />
    </FullPageLayout>
  );
}
