import type React from 'react';
import { useLayoutEffect, useState } from 'react';
import { PreferencesService } from 'utils/preferences';

export const trucksEmulationPrefName = 'general/trucksEmulation';

export interface RobotEmulation {
  /** The index of the robot (from 1) */
  ID: number;
  /** The robot serial */
  serial: string;
  /** The robot name */
  name: string;
  /** The robot model name */
  modelName: string;
  /** Whether the emulation is enabled for this robot */
  emulationEnabled: boolean;
  /** The picture to use to display the robot */
  picturePath: string;
  /** The model of battery on the robot */
  batteryModel: string;
}

/**
 * Get data about the robots as well as their emulator state
 * @returns the robots data
 */
export function getRobotsEmulation(): RobotEmulation[] {
  const trucks = PreferencesService.getTrucks();
  let trucksEmulation: string | string[] | undefined;
  try {
    trucksEmulation = PreferencesService.getPreferenceValue(trucksEmulationPrefName);
  } catch (e) {}

  if (
    !trucksEmulation ||
    typeof trucksEmulation === 'string' ||
    !Array.isArray(trucksEmulation) ||
    trucksEmulation.length !== trucks.length
  ) {
    trucksEmulation = trucks.map(() => '0');
  }

  let robotNames: string[] = [];
  let trucksPicturePath: string[] = [];
  try {
    const robotNamesFromPref = PreferencesService.getPreferenceValue('general/trucksName');
    if (Array.isArray(robotNamesFromPref)) {
      robotNames = robotNamesFromPref;
    } else {
      // eslint-disable-next-line no-console
      console.error('robotNamesFromPref is not an array', robotNamesFromPref);
    }
  } catch (e) {}

  try {
    const trucksPicturePathFromPref = PreferencesService.getPreferenceValue('general/trucksPicturePath');
    if (Array.isArray(trucksPicturePathFromPref)) {
      trucksPicturePath = trucksPicturePathFromPref;
    } else {
      // eslint-disable-next-line no-console
      console.error('trucksPicturePathFromPref is not an array', trucksPicturePathFromPref);
    }
  } catch (e) {}

  const robotsEmulation: RobotEmulation[] = trucksEmulation.map((emulationValue, index) => {
    const truck = trucks[index];

    const batteryModel = PreferencesService.getPreferenceValue('battery/batteryType', truck.serial) as string;

    return {
      ID: index + 1,
      serial: truck.serial,
      modelName: truck.modelName,
      name: robotNames[index] ?? 'unknown',
      emulationEnabled: emulationValue === '1',
      picturePath: trucksPicturePath[index] ?? 'otherManufacturer',
      batteryModel: batteryModel,
    };
  });

  return robotsEmulation;
}

/**
 * A react hook to manage the robot state
 * @returns the robots state and a function to update it
 */
export function useRobotsEmulation(): [RobotEmulation[], React.Dispatch<React.SetStateAction<RobotEmulation[]>>] {
  const [robots, setRobots] = useState<RobotEmulation[]>([]);

  useLayoutEffect(() => {
    const robotsEmulation = getRobotsEmulation();

    setRobots(robotsEmulation);
  }, []);

  return [robots, setRobots];
}
