import { ShapeTypes } from 'models/circuit';
import store from 'store';
import type { DefaultMissionType } from './custom-steps.model';
import type { Flow } from './flows';

export interface StepData {
  name: string;
  missionType: DefaultMissionType;
}

export function getSteps(flow: Flow): StepData[] | null {
  const stations = store.getState().flows.stations;

  const steps: StepData[] = [];

  let previousRobotState: 'empty' | 'carry' = 'empty';
  for (let stepIndex = 0; stepIndex < flow.stations.length; stepIndex++) {
    const flowStep = flow.stations[stepIndex];
    const station = stations.find((station) => station.id === flowStep.id);

    if (!station) {
      // eslint-disable-next-line no-console
      console.error(`Station with id ${station} not found`);

      return null;
    }

    if ('missionType' in flowStep && flowStep.missionType === 'custom') {
      const customStep = store
        .getState()
        .flows.customSteps.find((customStep) => customStep.id === flowStep.customMissionTypeId);

      if (!customStep) {
        // eslint-disable-next-line no-console
        console.error(`Custom step with id ${flowStep.customMissionTypeId} not found`);

        return null;
      }

      steps.push({
        name: customStep.name,
        missionType: customStep.missionType,
      });
      previousRobotState = customStep.missionType === 'pick' ? 'carry' : 'empty';

      continue;
    }

    let missionType: DefaultMissionType | undefined = undefined;
    if ('missionType' in flowStep && flowStep.missionType) {
      missionType = flowStep.missionType;
    }

    if (!missionType) {
      const firstPosition = station.positions[0];

      if (!firstPosition) {
        // eslint-disable-next-line no-console
        console.error(`Station with id ${station.id} has no positions`);

        return null;
      }

      if (firstPosition.type === ShapeTypes.PointShape) {
        missionType = 'move';
      } else {
        missionType = previousRobotState === 'empty' ? 'pick' : 'drop';
      }
    }

    steps.push({
      name: missionType,
      missionType,
    });

    if (missionType === 'pick' || missionType === 'drop') {
      previousRobotState = missionType === 'pick' ? 'carry' : 'empty';
    }
  }

  return steps;
}
