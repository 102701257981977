import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import type { SelectChangeEvent } from '@mui/material';
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import type { CircuitDevice, DeviceInDoor, Intersection } from 'models/circuit';
import type { ClassNameMap } from 'notistack';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import type { ZoneFormValues } from './zone-properties/zone-properties';

export interface ConveyorFormValues {
  doordAsk: number;
  doordStop: number;
  doorDevices: DeviceInDoor[];
}

interface DoorSettingsProps {
  formValues: ZoneFormValues | ConveyorFormValues;
  setFormValues:
    | React.Dispatch<React.SetStateAction<ZoneFormValues>>
    | React.Dispatch<React.SetStateAction<ConveyorFormValues>>;
  classes: ClassNameMap<'suggestedName' | 'helperText' | 'helpTooltip'>;
  handleChangeFormValue?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<Intersection>,
    fieldName: keyof ZoneFormValues,
    roundValue?: boolean
  ) => void;
  handleBlurFormValue?: (e: React.FocusEvent) => void;
  locked: boolean;
  handleKeyPressFormValue?: (e: React.KeyboardEvent) => void;
  setAskSaveShape?: React.Dispatch<React.SetStateAction<boolean>>;

  componentWidth?: number;

  /** to hide the field associated dAskDistance & dStopDistance */
  hideDistances?: boolean;

  doorSettingsFor: 'zone' | 'conveyor';
}
export function DoorSettings(props: DoorSettingsProps): JSX.Element {
  const {
    formValues,
    classes,
    handleChangeFormValue,
    handleBlurFormValue,
    locked,
    handleKeyPressFormValue,
    setFormValues,
    setAskSaveShape,
    componentWidth = 400,
    hideDistances = false,
    doorSettingsFor,
  } = props;

  const confirm = useConfirm();

  const devices = useAppSelector((state) => state.circuit.present.devices.entities);
  const devicesIds = useAppSelector((state) => state.circuit.present.devices.ids);

  const [deviceToAdd, setDeviceToAdd] = useState<CircuitDevice | null>(null);
  const [selectedPin, setSelectedPin] = useState<number | null>(null);

  const [openValue, setOpenValue] = useState<0 | 1>(1);

  const handleAddDevice = useCallback(() => {
    if (!deviceToAdd) {
      // eslint-disable-next-line no-console
      console.error('No device to add');

      return;
    }

    if (typeof selectedPin !== 'number') {
      // eslint-disable-next-line no-console
      console.error('No pin selected');

      return;
    }

    const newDevices: DeviceInDoor[] = [
      ...formValues.doorDevices,
      {
        deviceId: deviceToAdd.id as string,
        openValue,
        pinId: selectedPin,
      },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    setFormValues((prev) => ({
      ...prev,
      doorDevices: newDevices,
    }));

    if (setAskSaveShape) setAskSaveShape(true);
  }, [deviceToAdd, formValues.doorDevices, openValue, selectedPin, setAskSaveShape, setFormValues]);

  const handleChangeSelectDevice = useCallback(
    (e: SelectChangeEvent<string>) => {
      const val = e.target.value;
      if (typeof val === 'string') {
        const device = devices[val];
        if (device) {
          setDeviceToAdd(device);
          setSelectedPin(null);
        } else {
          // eslint-disable-next-line no-console
          console.error('Device not found', val);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Invalid value', val);
      }
    },
    [devices]
  );

  const handleDeleteDevice = useCallback(
    async (deviceIndex: number) => {
      try {
        await confirm({ title: `Are you sure you want to remove this device from the door?`, allowClose: false });
      } catch (e) {
        return;
      }

      const doorDevices = formValues.doorDevices;
      const newDevices = doorDevices.filter((_, i) => deviceIndex !== i);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      setFormValues((prev) => ({
        ...prev,
        doorDevices: newDevices,
      }));

      if (setAskSaveShape) setAskSaveShape(true);
    },
    [confirm, formValues.doorDevices, setAskSaveShape, setFormValues]
  );

  const handleChangeOpenValue = useCallback(
    (deviceIndex: number, newOpenValue: number) => {
      if (newOpenValue !== 0 && newOpenValue !== 1) {
        // eslint-disable-next-line no-console
        console.error('Invalid open value', newOpenValue);

        return;
      }

      const newDevices = formValues.doorDevices.map((device, index) => {
        if (deviceIndex === index) {
          const newDevice: DeviceInDoor = {
            ...device,
            openValue: newOpenValue,
          };

          return newDevice;
        }

        return device;
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      setFormValues((prev) => ({
        ...prev,
        doorDevices: newDevices,
      }));

      if (setAskSaveShape) setAskSaveShape(true);
    },
    [formValues.doorDevices, setAskSaveShape, setFormValues]
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: componentWidth,
          paddingTop: hideDistances ? theme.spacing(2) : undefined,
        }}
      >
        {!hideDistances && (
          <>
            <Grid item xs={6}>
              <TextField
                type="number"
                value={formValues.doordAsk}
                onChange={(e) => handleChangeFormValue && handleChangeFormValue(e, 'doordAsk')}
                onBlur={(e) => handleBlurFormValue && handleBlurFormValue(e)}
                onKeyPress={(e) => handleKeyPressFormValue && handleKeyPressFormValue(e)}
                fullWidth
                margin="normal"
                label={
                  <>
                    Ask distance{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title={
                        <>
                          <strong>dAsk</strong>
                          <br />
                          The distance from the zone where the robots will ask the Robot Manager to open the door.
                        </>
                      }
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
                inputProps={{ step: 0.5, min: 0, lang: 'en' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
                disabled={locked}
                variant="standard"
                sx={{
                  maxWidth: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                value={formValues.doordStop}
                onChange={(e) => handleChangeFormValue && handleChangeFormValue(e, 'doordStop')}
                onBlur={(e) => handleBlurFormValue && handleBlurFormValue(e)}
                onKeyPress={(e) => handleKeyPressFormValue && handleKeyPressFormValue(e)}
                fullWidth
                margin="normal"
                label={
                  <>
                    Stop distance{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title={
                        <>
                          <strong>dStop</strong>
                          <br />
                          The distance from the zone where the robots will stop and wait for the door to be opened.
                        </>
                      }
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
                inputProps={{ step: 0.5, min: 0, lang: 'en' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
                disabled={locked}
                variant="standard"
                sx={{
                  maxWidth: '100%',
                }}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Table>
        <>
          <thead>
            <tr>
              <th>Device</th>
              <th>Pin</th>
              <th>{doorSettingsFor === 'zone' ? 'Open' : 'OK'} when</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formValues.doorDevices.map((doorDevice, index) => {
              const device = devices[doorDevice.deviceId] as CircuitDevice | undefined;
              if (!device) return null;

              const deviceName = device.properties.name;
              const devicePinName = device.properties.pinsIn?.[doorDevice.pinId]?.name ?? doorDevice.pinId;

              return (
                <tr key={device.id}>
                  <td>{deviceName}</td>
                  <td>{devicePinName}</td>
                  <td>
                    <ToggleButtonGroup
                      value={doorDevice.openValue}
                      color="primary"
                      size="small"
                      exclusive
                      onChange={(e, val) => {
                        const deviceId = device.id;
                        if (typeof val === 'number' && typeof deviceId === 'string') {
                          handleChangeOpenValue(index, val);
                        } else {
                          // eslint-disable-next-line no-console
                          console.error('Invalid value', val, device);
                        }
                      }}
                    >
                      <ToggleButton value={0}>Off</ToggleButton>
                      <ToggleButton value={1}>On</ToggleButton>
                    </ToggleButtonGroup>
                  </td>
                  <td>
                    <Tooltip title="Remove the device from the door">
                      <IconButton onClick={() => handleDeleteDevice(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Select
                  size="small"
                  placeholder="Device"
                  fullWidth
                  variant="standard"
                  displayEmpty
                  defaultValue={''}
                  onChange={handleChangeSelectDevice}
                >
                  <MenuItem value="" disabled>
                    {devicesIds.length ? 'Select a device' : 'No device available'}
                  </MenuItem>
                  {devicesIds.map((deviceId) => {
                    const device = devices[deviceId];
                    const deviceName = device.properties.name;

                    return (
                      <MenuItem key={deviceId} value={device.id}>
                        {deviceName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </td>
              <td>
                <Select
                  size="small"
                  label="Pin"
                  fullWidth
                  disabled={!deviceToAdd}
                  variant="standard"
                  value={selectedPin?.toString() || ''}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (typeof val === 'number') {
                      setSelectedPin(val);
                    } else {
                      // eslint-disable-next-line no-console
                      console.error('Invalid value', val);
                    }
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a pin
                  </MenuItem>
                  {deviceToAdd &&
                    deviceToAdd.properties.pinsIn &&
                    deviceToAdd.properties.pinsIn.map((pinIn, pinIndex) => {
                      return (
                        <MenuItem key={pinIndex} value={pinIndex.toString()}>
                          {pinIn.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </td>
              <td>
                <ToggleButtonGroup
                  color="primary"
                  value={openValue}
                  onChange={(e, value) => {
                    if (value === null) return;

                    if (typeof value === 'number' && (value === 0 || value === 1)) setOpenValue(value);
                    // eslint-disable-next-line no-console
                    else console.error('Invalid value', value);
                  }}
                  // sx={{
                  //   '& .MuiToggleButton-root': {
                  //     padding: theme.spacing(0.5, 0),
                  //   },
                  // }}
                  exclusive
                  size="small"
                  // fullWidth
                >
                  <ToggleButton value={0}>Off</ToggleButton>
                  <ToggleButton value={1}>On</ToggleButton>
                </ToggleButtonGroup>
              </td>
              <td>
                <Tooltip title="Add a device">
                  <span>
                    <IconButton onClick={handleAddDevice} disabled={!deviceToAdd || selectedPin === null || locked}>
                      <AddCircleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </td>
            </tr>
          </tfoot>
        </>
      </Table>
    </>
  );
}
