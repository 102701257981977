import type { MapsActions } from 'actions/maps';
import { MapsActionTypes } from 'actions/maps';
import { LidarPosition } from 'models/maps';
import type { LidarState } from './state';

export function getLidarInitialState(): LidarState {
  return {
    [LidarPosition.Background]: undefined,
    [LidarPosition.Foreground]: undefined,
    error: undefined,
    updated: false,
    updating: false,
  };
}

export function lidarReducer(state = getLidarInitialState(), action: MapsActions): LidarState {
  switch (action.type) {
    case MapsActionTypes.ImportLidar: {
      return {
        ...state,
        updating: true,
      };
    }

    case MapsActionTypes.ImportLidarSuccess: {
      const { lidar, position } = action.payload;

      return {
        ...state,
        [position]: lidar,
        updating: false,
        updated: true,
      };
    }

    case MapsActionTypes.ImportLidarFailure: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        updating: false,
        updated: true,
      };
    }

    case MapsActionTypes.ClearLidarMap: {
      const payload = action.payload;
      const { name } = payload;
      delete localStorage[`${name}-isDrawingPoints`];

      return {
        ...state,
        [name]: getLidarInitialState()[name],
      };
    }

    default: {
      return state;
    }
  }
}

export const getBackgroundLidar = (state: LidarState): LidarState[LidarPosition.Background] =>
  state[LidarPosition.Background];
export const getForegroundLidar = (state: LidarState): LidarState[LidarPosition.Background] =>
  state[LidarPosition.Foreground];
export const getLidarUpdating = (state: LidarState): LidarState['updating'] => state.updating;
export const getLidarUpdated = (state: LidarState): LidarState['updated'] => state.updated;
