// @ts-nocheck
/*
 * Generated type guards for "simulation.ts".
 * WARNING: Do not manually change this file.
 */
import { RobotDataSimulation, RobotsDataSimulation, SimulationThreadInfo, SimulationTaskEvents, SimulationTasksEvents, RobotTimers, BalyoSimulationPromptMsg, RobotOccupation } from "./simulation";

export function isRobotDataSimulation(obj: unknown): obj is RobotDataSimulation {
    const typedObj = obj as RobotDataSimulation
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typedObj["carry"] === 0 ||
            typedObj["carry"] === 1) &&
        (typedObj["state"] !== null &&
            typeof typedObj["state"] === "object" ||
            typeof typedObj["state"] === "function") &&
        typeof typedObj["state"]["task"] === "number" &&
        typeof typedObj["state"]["navigation"] === "number" &&
        typeof typedObj["state"]["traffic"] === "number" &&
        typeof typedObj["state"]["position"] === "number" &&
        typeof typedObj["state"]["safety"] === "number" &&
        typeof typedObj["state"]["communication"] === "number" &&
        (typedObj["position"] !== null &&
            typeof typedObj["position"] === "object" ||
            typeof typedObj["position"] === "function") &&
        typeof typedObj["position"]["x"] === "number" &&
        typeof typedObj["position"]["y"] === "number" &&
        typeof typedObj["position"]["heading"] === "number" &&
        (typedObj["battery"] !== null &&
            typeof typedObj["battery"] === "object" ||
            typeof typedObj["battery"] === "function") &&
        (typeof typedObj["battery"]["level"] === "undefined" ||
            typeof typedObj["battery"]["level"] === "number") &&
        (typeof typedObj["action"] === "undefined" ||
            typeof typedObj["action"] === "number") &&
        typeof typedObj["odometry"] === "number" &&
        (typeof typedObj["speed"] === "undefined" ||
            typeof typedObj["speed"] === "number") &&
        (typeof typedObj["forksHeight"] === "undefined" ||
            typeof typedObj["forksHeight"] === "number") &&
        (typeof typedObj["trackPosition"] === "undefined" ||
            (typedObj["trackPosition"] !== null &&
                typeof typedObj["trackPosition"] === "object" ||
                typeof typedObj["trackPosition"] === "function") &&
            (typeof typedObj["trackPosition"]["linearAbscissa"] === "undefined" ||
                typeof typedObj["trackPosition"]["linearAbscissa"] === "number") &&
            (typeof typedObj["trackPosition"]["portionID"] === "undefined" ||
                typeof typedObj["trackPosition"]["portionID"] === "number"))
    )
}

export function isRobotsDataSimulation(obj: unknown): obj is RobotsDataSimulation {
    const typedObj = obj as RobotsDataSimulation
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        Object.entries<any>(typedObj)
            .every(([key, value]) => (isRobotDataSimulation(value) as boolean &&
                typeof key === "string"))
    )
}

export function isSimulationThreadInfo(obj: unknown): obj is SimulationThreadInfo {
    const typedObj = obj as SimulationThreadInfo
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typeof typedObj["targetSpeed"] === "number" &&
        typeof typedObj["currentSpeed"] === "number" &&
        typeof typedObj["simulatedTime"] === "number"
    )
}

export function isSimulationTaskEvents(obj: unknown): obj is SimulationTaskEvents {
    const typedObj = obj as SimulationTaskEvents
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typeof typedObj["newTimeStamp"] === "undefined" ||
            typeof typedObj["newTimeStamp"] === "number") &&
        (typeof typedObj["deleteTimeStamp"] === "undefined" ||
            typeof typedObj["deleteTimeStamp"] === "number") &&
        (typeof typedObj["data"] === "undefined" ||
            (typedObj["data"] !== null &&
                typeof typedObj["data"] === "object" ||
                typeof typedObj["data"] === "function") &&
            (typeof typedObj["data"]["id"] === "undefined" ||
                typeof typedObj["data"]["id"] === "number") &&
            typeof typedObj["data"]["uniqueId"] === "string" &&
            typeof typedObj["data"]["taskName"] === "string" &&
            Array.isArray(typedObj["data"]["prev"]) &&
            typedObj["data"]["prev"].every((e: any) =>
                typeof e === "number"
            ) &&
            (typeof typedObj["data"]["src"] === "undefined" ||
                typeof typedObj["data"]["src"] === "number") &&
            (typeof typedObj["data"]["dst"] === "undefined" ||
                typeof typedObj["data"]["dst"] === "number") &&
            typeof typedObj["data"]["priority"] === "number" &&
            typeof typedObj["data"]["state"] === "number" &&
            (Array.isArray(typedObj["data"]["robAuth"]) &&
                typedObj["data"]["robAuth"].every((e: any) =>
                    typeof e === "number"
                ) ||
                Array.isArray(typedObj["data"]["robAuth"]) &&
                typedObj["data"]["robAuth"].every((e: any) =>
                    Array.isArray(e) &&
                    (typeof e[0] === "string" ||
                        typeof e[0] === "number")
                )) &&
            (typeof typedObj["data"]["rob"] === "undefined" ||
                typeof typedObj["data"]["rob"] === "number") &&
            (typeof typedObj["data"]["currentId"] === "undefined" ||
                typeof typedObj["data"]["currentId"] === "number") &&
            (typeof typedObj["data"]["allowSuspend"] === "undefined" ||
                typeof typedObj["data"]["allowSuspend"] === "number") &&
            (typeof typedObj["data"]["creationDate"] === "undefined" ||
                typeof typedObj["data"]["creationDate"] === "number") &&
            (typeof typedObj["data"]["startDate"] === "undefined" ||
                typeof typedObj["data"]["startDate"] === "number") &&
            (typeof typedObj["data"]["endDate"] === "undefined" ||
                typeof typedObj["data"]["endDate"] === "number") &&
            (typeof typedObj["data"]["dueDate"] === "undefined" ||
                typeof typedObj["data"]["dueDate"] === "number") &&
            (typeof typedObj["data"]["stepLabel"] === "undefined" ||
                typeof typedObj["data"]["stepLabel"] === "string") &&
            (typeof typedObj["data"]["startPoint"] === "undefined" ||
                typeof typedObj["data"]["startPoint"] === "string") &&
            (typeof typedObj["data"]["destinationPoint"] === "undefined" ||
                typeof typedObj["data"]["destinationPoint"] === "string") &&
            (typeof typedObj["data"]["stepEndDate"] === "undefined" ||
                Array.isArray(typedObj["data"]["stepEndDate"]) &&
                typedObj["data"]["stepEndDate"].every((e: any) =>
                    typeof e === "number"
                )) &&
            (typeof typedObj["data"]["stepNames"] === "undefined" ||
                Array.isArray(typedObj["data"]["stepNames"]) &&
                typedObj["data"]["stepNames"].every((e: any) =>
                    typeof e === "string"
                )))
    )
}

export function isSimulationTasksEvents(obj: unknown): obj is SimulationTasksEvents {
    const typedObj = obj as SimulationTasksEvents
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        Object.entries<any>(typedObj)
            .every(([key, value]) => (isSimulationTaskEvents(value) as boolean &&
                typeof key === "string"))
    )
}

export function isRobotTimers(obj: unknown): obj is RobotTimers {
    const typedObj = obj as RobotTimers
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typeof typedObj["carry"] === "number" &&
        typeof typedObj["total"] === "number" &&
        (typedObj["task"] !== null &&
            typeof typedObj["task"] === "object" ||
            typeof typedObj["task"] === "function") &&
        typeof typedObj["task"]["Calibration"] === "number" &&
        typeof typedObj["task"]["ErrorNavigation"] === "number" &&
        typeof typedObj["task"]["Manual"] === "number" &&
        typeof typedObj["task"]["Switch"] === "number" &&
        typeof typedObj["task"]["Available"] === "number" &&
        typeof typedObj["task"]["ErrorBattery"] === "number" &&
        typeof typedObj["task"]["ErrorMission"] === "number" &&
        typeof typedObj["task"]["RunToTaxi"] === "number" &&
        typeof typedObj["task"]["Run"] === "number" &&
        (typedObj["navigation"] !== null &&
            typeof typedObj["navigation"] === "object" ||
            typeof typedObj["navigation"] === "function") &&
        typeof typedObj["navigation"]["BadCanton"] === "number" &&
        typeof typedObj["navigation"]["Canton"] === "number" &&
        typeof typedObj["navigation"]["ESCanton"] === "number" &&
        typeof typedObj["navigation"]["Manual"] === "number" &&
        typeof typedObj["navigation"]["NoDestination"] === "number" &&
        typeof typedObj["navigation"]["StopPgm"] === "number" &&
        typeof typedObj["navigation"]["Door"] === "number" &&
        typeof typedObj["navigation"]["OK"] === "number" &&
        (typedObj["traffic"] !== null &&
            typeof typedObj["traffic"] === "object" ||
            typeof typedObj["traffic"] === "function") &&
        typeof typedObj["traffic"]["ManuThief"] === "number" &&
        typeof typedObj["traffic"]["AutoStart"] === "number" &&
        typeof typedObj["traffic"]["Error"] === "number" &&
        typeof typedObj["traffic"]["AutoAU"] === "number" &&
        typeof typedObj["traffic"]["ManuOff"] === "number" &&
        typeof typedObj["traffic"]["ManuOK"] === "number" &&
        typeof typedObj["traffic"]["AutoNav"] === "number" &&
        typeof typedObj["traffic"]["AutoWait"] === "number" &&
        typeof typedObj["traffic"]["Auto"] === "number" &&
        typeof typedObj["traffic"]["Bug"] === "number" &&
        typeof typedObj["traffic"]["Init"] === "number" &&
        (typedObj["position"] !== null &&
            typeof typedObj["position"] === "object" ||
            typeof typedObj["position"] === "function") &&
        typeof typedObj["position"]["NearTrack"] === "number" &&
        typeof typedObj["position"]["Uninitialized"] === "number" &&
        typeof typedObj["position"]["NoData"] === "number" &&
        typeof typedObj["position"]["OffTrack"] === "number" &&
        typeof typedObj["position"]["OK"] === "number" &&
        (typedObj["safety"] !== null &&
            typeof typedObj["safety"] === "object" ||
            typeof typedObj["safety"] === "function") &&
        typeof typedObj["safety"]["CurtainRear"] === "number" &&
        typeof typedObj["safety"]["CurtainFront"] === "number" &&
        typeof typedObj["safety"]["Front"] === "number" &&
        typeof typedObj["safety"]["ESCharge"] === "number" &&
        typeof typedObj["safety"]["AU"] === "number" &&
        typeof typedObj["safety"]["BA"] === "number" &&
        typeof typedObj["safety"]["Gpio"] === "number" &&
        typeof typedObj["safety"]["FC"] === "number" &&
        typeof typedObj["safety"]["SAU"] === "number" &&
        typeof typedObj["safety"]["Rear"] === "number" &&
        typeof typedObj["safety"]["Loc"] === "number" &&
        typeof typedObj["safety"]["OK"] === "number" &&
        (typedObj["communication"] !== null &&
            typeof typedObj["communication"] === "object" ||
            typeof typedObj["communication"] === "function") &&
        typeof typedObj["communication"]["CRC"] === "number" &&
        typeof typedObj["communication"]["CMD"] === "number" &&
        typeof typedObj["communication"]["DST"] === "number" &&
        typeof typedObj["communication"]["OK"] === "number" &&
        typeof typedObj["communication"]["OFF"] === "number"
    )
}

export function isBalyoSimulationPromptMsg(obj: unknown): obj is BalyoSimulationPromptMsg {
    const typedObj = obj as BalyoSimulationPromptMsg
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "balyosimulation-prompt" &&
        typeof typedObj["category"] === "string" &&
        typeof typedObj["categoryId"] === "number" &&
        (typeof typedObj["title"] === "undefined" ||
            typeof typedObj["title"] === "string") &&
        typeof typedObj["msg"] === "string" &&
        (typeof typedObj["actions"] === "undefined" ||
            Array.isArray(typedObj["actions"]) &&
            typedObj["actions"].every((e: any) =>
                typeof e === "string"
            )) &&
        (typeof typedObj["parameter"] === "undefined" ||
            typeof typedObj["parameter"] === "number") &&
        (typeof typedObj["severity"] === "undefined" ||
            typedObj["severity"] === "info" ||
            typedObj["severity"] === "success" ||
            typedObj["severity"] === "warning" ||
            typedObj["severity"] === "error") &&
        (typeof typedObj["timeout"] === "undefined" ||
            typeof typedObj["timeout"] === "number") &&
        (typeof typedObj["stopSimu"] === "undefined" ||
            typedObj["stopSimu"] === false ||
            typedObj["stopSimu"] === true) &&
        (typeof typedObj["notification"] === "undefined" ||
            typedObj["notification"] === false ||
            typedObj["notification"] === true)
    )
}

export function isRobotOccupation(obj: unknown): obj is RobotOccupation {
    const typedObj = obj as RobotOccupation
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typeof typedObj["itinerary"] === "undefined" ||
            Array.isArray(typedObj["itinerary"]) &&
            typedObj["itinerary"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["x"] === "number" &&
                typeof e["y"] === "number"
            )) &&
        (typeof typedObj["occupiedCantons"] === "undefined" ||
            (typedObj["occupiedCantons"] !== null &&
                typeof typedObj["occupiedCantons"] === "object" ||
                typeof typedObj["occupiedCantons"] === "function") &&
            Object.entries<any>(typedObj["occupiedCantons"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number") &&
                    typeof key === "string"))) &&
        (typeof typedObj["manualCantons"] === "undefined" ||
            (typedObj["manualCantons"] !== null &&
                typeof typedObj["manualCantons"] === "object" ||
                typeof typedObj["manualCantons"] === "function") &&
            Object.entries<any>(typedObj["manualCantons"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number") &&
                    typeof key === "string"))) &&
        (typeof typedObj["lockedCantons"] === "undefined" ||
            (typedObj["lockedCantons"] !== null &&
                typeof typedObj["lockedCantons"] === "object" ||
                typeof typedObj["lockedCantons"] === "function") &&
            Object.entries<any>(typedObj["lockedCantons"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number") &&
                    typeof key === "string"))) &&
        (typeof typedObj["reservedCantons"] === "undefined" ||
            (typedObj["reservedCantons"] !== null &&
                typeof typedObj["reservedCantons"] === "object" ||
                typeof typedObj["reservedCantons"] === "function") &&
            Object.entries<any>(typedObj["reservedCantons"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number") &&
                    typeof key === "string"))) &&
        (typeof typedObj["currentCanton"] === "undefined" ||
            (typedObj["currentCanton"] !== null &&
                typeof typedObj["currentCanton"] === "object" ||
                typeof typedObj["currentCanton"] === "function") &&
            Object.entries<any>(typedObj["currentCanton"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number") &&
                    typeof key === "string"))) &&
        (typeof typedObj["checkCanton"] === "undefined" ||
            (typedObj["checkCanton"] !== null &&
                typeof typedObj["checkCanton"] === "object" ||
                typeof typedObj["checkCanton"] === "function") &&
            Object.entries<any>(typedObj["checkCanton"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number") &&
                    typeof key === "string"))) &&
        (typeof typedObj["checkDescription"] === "undefined" ||
            typeof typedObj["checkDescription"] === "string")
    )
}
