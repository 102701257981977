import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';
import { checkPermission } from 'services/check-permission';
import { useAsyncMemo } from 'use-async-memo';

export interface ImportCircuitComponentProps {
  isBackgroundLoaded: boolean;
  uploadCircuit: (file: File) => void;
}

export function ImportCircuitComponent({
  isBackgroundLoaded,
  uploadCircuit,
}: ImportCircuitComponentProps): JSX.Element {
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0]) {
        uploadCircuit(files[0]);
      }
    },
    [uploadCircuit]
  );

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  return (
    <>
      <input
        type="file"
        onChange={onChangeHandler}
        style={{ display: 'none' }}
        id="contained-input-circuit"
        accept=".geojson"
      />
      <label htmlFor="contained-input-circuit">
        <Tooltip title={!editCircuitPerm ? "You don't have permission to edit circuits" : ''}>
          <Box component="span">
            <MenuItem disabled={!editCircuitPerm}>
              <ListItemIcon>
                <FileUploadIcon />
              </ListItemIcon>
              <ListItemText>Import Circuit (GeoJSON)</ListItemText>
            </MenuItem>
          </Box>
        </Tooltip>
      </label>
    </>
  );
}
