/**
 * Reads the content of the WebAssembly VM MEMFS FileSystem
 *
 * This function interfaces with a WebAssembly-based simulation service to read files.
 * It allocates memory for the file path and file size, reads the file content, and then
 * frees the allocated memory. If any step fails, it returns null and logs the error.
 *
 * @param {string} filePath - The path of the file to be read in the simulation environment.
 * @param
 * @returns {Promise<string | undefined | null>} - The content of the file as a string, or undefined/null if an error occurs.
 */

export async function readFileSimulation(filePath: string): Promise<string | undefined | null> {
  const simulationService = (await import('services/simulation.service'))?.simulationService;

  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('Simulation service not found');

    return;
  }

  const fileContent = await (async () => {
    try {
      const fileNamePtr = await simulationService.allocateUTF8(filePath);
      const fileSizePtr = await simulationService._malloc(Int32Array.BYTES_PER_ELEMENT);
      const fileContentPtr = await simulationService._OS_WasmWrapper_readFile(fileNamePtr, fileSizePtr);
      const fileSize = await simulationService.getValue(fileSizePtr, 'i32');

      const fileContentTmp =
        fileContentPtr && fileSize ? await simulationService.getWasmMemorySlice(fileContentPtr, fileSize) : null;

      const fileContentStr = fileContentTmp ? new TextDecoder().decode(fileContentTmp) : null;

      await simulationService._OS_WasmWrapper_free(fileContentPtr);
      await simulationService._OS_WasmWrapper_free(fileNamePtr);

      return fileContentStr;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Error while getting the newly generated ${filePath} file`, e);

      return null;
    }
  })();

  return fileContent;
}
