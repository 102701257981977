import type { Action } from 'redux';
import { getConfig } from 'utils/config';
import type { CoreState } from './state';

export enum CoreActionTypes {
  SetLoadingState = '[Core] Set Loading State',
  SetBackdropState = '[Core] Set Backdrop State',
}

function getInitialState(): CoreState {
  return {
    appOnline: !window?.__TEST__ ? !!JSON.parse(getConfig('online') || 'false') : false,
    loading: false,
    backdrop: false,
  };
}

export function coreReducer(state = getInitialState(), action: CoreActions): CoreState {
  switch (action.type) {
    case CoreActionTypes.SetLoadingState: {
      return {
        ...state,
        loading: action.payload.newLoadingState,
      };
    }

    case CoreActionTypes.SetBackdropState: {
      return {
        ...state,
        backdrop: action.payload.newBackdropState,
      };
    }
  }

  return state;
}

export const getAppOnline = (state: CoreState): CoreState['appOnline'] => state.appOnline;

export interface SetLoadingState extends Action {
  type: CoreActionTypes.SetLoadingState;
  payload: { newLoadingState: boolean };
}

export function setLoadingStateAction(payload: SetLoadingState['payload']): SetLoadingState {
  return { type: CoreActionTypes.SetLoadingState, payload };
}

export interface SetBackdropState extends Action {
  type: CoreActionTypes.SetBackdropState;
  payload: { newBackdropState: boolean };
}

export function setBackdropStateAction(payload: SetBackdropState['payload']): SetBackdropState {
  return { type: CoreActionTypes.SetBackdropState, payload };
}

export type CoreActions = SetLoadingState | SetBackdropState;
