import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { InputAdornment, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { theme } from 'utils/mui-theme';

interface CellTemplateDimProps {
  /** name of the parameter in the cellTemplate, ex: width */
  param: string;
  /** x position of the first arrow */
  x1: number;
  /** x position of the second arrow */
  x2: number;
  /** y position of the first arrow */
  y1: number;
  /** y position of the second arrow */
  y2: number;
  /** value of the parameter */
  value: number;
  /** unit in text */
  unit: string | undefined;
  /** factor to convert in metres */
  unitFactor: number;
  /** name of the field related to the value */
  label: string | JSX.Element | undefined;
  /** callback to call when we need to update the value */
  onChange?: (param: string, newValue: number) => void;
  /** the width of the input in comparison with the arrow */
  ratioWidth?: number;
  /** height of the input in px */
  inputHeight?: number;
  /** font size of the input in px */
  fontSize?: number;
  /** minimum value of the input (ex: 0) */
  min?: number;
  /** maximum value of the input */
  max?: number;
  /** step of the input */
  step?: number;
  /** minimum width of the input in px */
  inputMinWidth?: number;

  arrowSize?: string;

  linked?: boolean;

  linkState?: boolean;

  disabled?: boolean;
  onChangeLink?: () => void;
  offsetInputX?: number;
  offsetInputY?: number;
  error?: boolean;

  focused?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  /** tooltip to be added */
  title?: string;
}
export function CellTemplateDim({
  param,
  x1,
  x2,
  y1,
  y2,
  value,
  unit,
  unitFactor,
  label,
  ratioWidth,
  inputHeight,
  fontSize,
  min,
  max,
  step,
  onChange,
  inputMinWidth,
  arrowSize,
  linked,
  linkState,
  disabled,
  onChangeLink,
  offsetInputX,
  offsetInputY,
  error,
  focused,
  onFocus,
  onBlur,
  title,
}: CellTemplateDimProps): JSX.Element {
  const [val, setVal] = useState((value * unitFactor).toFixed(0));

  const onBlurInput = useCallback(() => {
    const newVal = parseFloat(val) / unitFactor;
    if (isNaN(newVal)) {
      setVal(value.toFixed(0));
    } else if (onChange) {
      onChange(param, newVal);
    }

    if (onBlur) onBlur();
  }, [onBlur, onChange, param, unitFactor, val, value]);
  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const newVal = parseFloat(val) / unitFactor;
        if (isNaN(newVal)) {
          setVal(value.toFixed(0));
        } else if (onChange) {
          onChange(param, newVal);
        }
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        setVal((value * unitFactor).toFixed(0));
        setTimeout(() => (e.target as HTMLInputElement).blur(), 0);
      }
    },
    [onChange, param, unitFactor, val, value]
  );

  useEffect(() => {
    setVal((value * unitFactor).toFixed(0));
  }, [unitFactor, value, linkState]);

  ratioWidth = ratioWidth || 1;
  inputHeight = inputHeight || 20;
  fontSize = fontSize || 12;

  const offsetArrowInput = 4;
  const inputWidth = x2 - x1;

  const foreignObjectWidth = Math.abs(inputWidth * ratioWidth);
  const offsetXForeignObject = inputWidth * ratioWidth < 0 ? inputWidth * ratioWidth : 0;

  return (
    <g>
      <foreignObject
        x={x1 + (inputWidth * (1 - ratioWidth)) / 2 + (offsetInputX || 0) + offsetXForeignObject}
        y={y1 - inputHeight - offsetArrowInput + (offsetInputY || 0)}
        width={foreignObjectWidth}
        height="0"
        className="overflow-visible"
      >
        <Tooltip title={title ? title : ''}>
          <TextField
            type={label ? 'number' : undefined}
            value={val}
            inputProps={{
              sx: {
                fontSize,
                '&::-webkit-inner-spin-button': {
                  // prevent the buttons from being displayed (it displays a white background when the input is not focused)
                  display: 'none',

                  height: `${fontSize}px`,
                  margin: 'auto',
                },
                '&:hover::-webkit-inner-spin-button': {
                  display: 'inline',
                  opacity: arrowSize === 'small' ? 0 : 1,
                },
              },
              min,
              max,
              step,
            }}
            InputProps={{
              startAdornment: label && (
                <InputAdornment position="start" sx={{ fontSize }}>
                  {linked && (
                    <IconButton size="small" disabled={false} onClick={onChangeLink}>
                      {linkState ? <LinkIcon sx={{ fontSize }} /> : <LinkOffIcon sx={{ fontSize }} />}
                    </IconButton>
                  )}
                  {label}=
                </InputAdornment>
              ),
              endAdornment: unit && (
                <InputAdornment position="end" sx={{ fontSize, '& > p': { fontSize }, margin: !label ? 0 : undefined }}>
                  {unit}
                </InputAdornment>
              ),
            }}
            fullWidth
            margin="none"
            onChange={(e) => setVal(e.target.value)}
            sx={{
              '& > div': {
                height: `${inputHeight}px`,
                paddingLeft: !label ? 0 : linked ? 0 : '5px',
                paddingRight: '5px',
              },
              '& input': { padding: !label ? 0 : undefined },
              fontSize,
              minWidth: inputMinWidth,
            }}
            size="small"
            onBlur={onBlurInput}
            onKeyDown={onKeyPress}
            disabled={disabled}
            variant={!label ? 'standard' : undefined}
            error={error ? true : undefined}
            focused={focused}
            onFocus={onFocus}
          />
        </Tooltip>
      </foreignObject>
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        stroke={!error ? theme.palette.primary.main : theme.palette.secondary.light}
        strokeWidth={1}
        markerStart={`url(#arrow-axe${arrowSize === 'small' ? '-small' : ''})`}
        markerEnd={`url(#arrow-axe${arrowSize === 'small' ? '-small' : ''})`}
      />
    </g>
  );
}
