import React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import type { AuthState } from 'reducers/auth';
import { selectUserLoggedIn, selectUserProfile } from 'reducers/auth';
import { selectAppOnline } from 'reducers/core';
import type { AppState } from 'reducers/state';
import type { UserPermissions } from 'shared';

interface StateProps {
  allowed: boolean;
  appOnline: boolean;
  loggedIn: boolean;
}

interface PrivateRouteOwnProps {
  allowedFunction?: (state: AppState) => boolean;
  component?: React.ElementType;
  exact?: boolean;
  path?: string | string[];
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  requiredPermissions?: UserPermissions[];
  sensitive?: boolean;
  strict?: boolean;
}

type PrivateRouteProps = PrivateRouteOwnProps & StateProps;

function mapStateToProps(
  state: AppState,
  { allowedFunction = () => true, requiredPermissions }: PrivateRouteOwnProps
): StateProps {
  const appOnline = selectAppOnline(state);
  const loggedIn = selectUserLoggedIn(state);
  const profile = selectUserProfile(state);
  const allowed = checkIfUserHasPermission(profile, requiredPermissions) && allowedFunction(state);

  return { appOnline, allowed, loggedIn };
}

export function PrivateRouteComponent({
  allowed,
  appOnline,
  component: Component,
  loggedIn,
  render,
  requiredPermissions,
  ...rest
}: PrivateRouteProps): JSX.Element {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (appOnline && !loggedIn) {
          return <Redirect to="/unauthorized" />;
        }

        if (appOnline && !allowed) {
          return <Redirect to="/forbidden" />;
        }

        if (Component) {
          return <Component {...props} />;
        }

        if (render) {
          return render(props);
        }

        return null;
      }}
    />
  );
}

export function checkIfUserHasPermission(
  profile: AuthState['profile'],
  requiredPermissions: UserPermissions[] = []
): boolean {
  if (requiredPermissions.length === 0) {
    return true;
  }

  if (!profile || !profile.loaded || !profile.permissions) {
    return false;
  }

  return requiredPermissions.every((routePermission) => profile.permissions.includes(routePermission));
}

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
