import type { FlowStep } from './flows';

export interface SimulationCustomConfigSchema {
  /**
   * Duration of the perception with the 3D Camera
   * [s]
   */
  perceptionTime: number;
  /**
   * Custom events to add additional custom times to robots when doing tasks
   * key = id of the custom step
   */
  customSteps: Record<string, CustomStep>;
}

export const defaultMissionType = ['pick', 'drop', 'move', 'batt'] as const;

export type DefaultMissionType = (typeof defaultMissionType)[number];

export const extraStepNames = [
  'RPK_START',
  'RPK_EVACUATION',
  'RPK_LASTREVERSE',
  'RPK_APPROACH',
  'RPK_DESTINATION',
] as const;

export type ExtraStepNames = (typeof extraStepNames)[number];

type EnableScanOrPerception = 'none' | 'mandatory' | 'optional';

interface CustomStepEventBase {
  /** Name of the custom event */
  name: string;
}

interface CustomStepEventWithoutProperty extends CustomStepEventBase {
  /** Type of the property of the event */
  actionType: 'none';
}

interface CustomStepEventWithWaitTime extends CustomStepEventBase {
  /**
   * Additional time to add to the step [s]
   */
  waitTime: number;
  /** Type of the property of the event */
  actionType: 'waitTime';
}

interface CustomStepEventWithForksMovement extends CustomStepEventBase {
  /**
   * Extra forks movement to add to the step [m]
   * Positive value = lift up
   * Negative value = lift down
   */
  forksMovement: number;
  /** Type of the property of the event */
  actionType: 'forksMovement';
}

interface CustomStepEventWithForksTarget extends CustomStepEventBase {
  /**
   * Target position for the forks [m]
   */
  forksTarget: number;
  /** Type of the property of the event */
  actionType: 'forksTarget';
}

export type CustomStepEvent =
  | CustomStepEventWithoutProperty
  | CustomStepEventWithWaitTime
  | CustomStepEventWithForksMovement
  | CustomStepEventWithForksTarget;

export interface CustomStep {
  /** id of the custom step */
  id: string;
  /** name of the custom step */
  name: string;
  /** primitive type of the mission (pick, drop, move) */
  missionType: DefaultMissionType;
  /** whether we should enable or not the scan */
  scan: EnableScanOrPerception;
  /** whether we should enable or not the perception */
  perception: EnableScanOrPerception;
  /** the custom events to execute */
  customEvents: Partial<Record<ExtraStepNames, CustomStepEvent[]>>;
}

export interface FlowStepWithDefaultMissionType extends FlowStep {
  missionType?: DefaultMissionType;
}

interface FlowStepWithCustomMissionType extends FlowStep {
  missionType: 'custom';
  customMissionTypeId: string;
}

export type FlowStepWithissionType = FlowStepWithDefaultMissionType | FlowStepWithCustomMissionType;
