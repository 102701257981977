import type { Position } from 'geojson';

export type AlignAction =
  | 'horizontallyLeft'
  | 'horizontallyCenter'
  | 'horizontallyRight'
  | 'verticallyTop'
  | 'verticallyCenter'
  | 'verticallyBottom';

/**
 * Function that gives the new ref value to align vartically or horizontally the shape
 * @param rectangle The coordinates of the shape (rack or stockZone)
 * @param alignAction The action the user want to apply
 * @returns number: the ref value to align the shape
 */
export function findNewPositionToAlignElement(rectangle: Position[][], alignAction: AlignAction): number {
  if (alignAction === 'horizontallyLeft') {
    let leftMinX = rectangle[0][0][0];

    rectangle.forEach((point) => {
      for (let i = 0; i < point.length; i++) {
        if (point[i][0] < leftMinX) {
          leftMinX = point[i][0];
        }
      }
    });

    return leftMinX;
  }

  if (alignAction === 'horizontallyCenter') {
    let sumX = 0;

    rectangle.forEach((point) => {
      for (let i = 0; i < point.length - 1; i++) {
        sumX += point[i][0];
      }
    });

    return sumX / (rectangle[0].length - 1);
  }

  if (alignAction === 'horizontallyRight') {
    let rightMostX = rectangle[0][0][0];

    rectangle.forEach((point) => {
      for (let i = 0; i < point.length; i++) {
        if (point[i][0] > rightMostX) {
          rightMostX = point[i][0];
        }
      }
    });

    return rightMostX;
  }

  if (alignAction === 'verticallyTop') {
    let topMostY = rectangle[0][0][1];

    rectangle.forEach((point) => {
      for (let i = 0; i < point.length; i++) {
        if (point[i][1] > topMostY) {
          topMostY = point[i][1];
        }
      }
    });

    return topMostY;
  }

  if (alignAction === 'verticallyCenter') {
    let sumY = 0;

    rectangle.forEach((point) => {
      for (let i = 0; i < point.length - 1; i++) {
        sumY += point[i][1];
      }
    });

    return sumY / (rectangle[0].length - 1);
  }

  if (alignAction === 'verticallyBottom') {
    let bottomMinY = rectangle[0][0][1];

    rectangle.forEach((point) => {
      for (let i = 0; i < point.length; i++) {
        if (point[i][1] < bottomMinY) {
          bottomMinY = point[i][1];
        }
      }
    });

    return bottomMinY;
  }

  return rectangle[0][0][0];
}
