import type { ExportCircuitFeatures } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import { generateGeoJSON } from 'utils/export/generate-geojson';

/**
 * This function generates a geojson circuit and trigger the download of the file
 * @param filename the name of the file to download
 * @param features the geojson features to include in the file
 * @param pretty whether or not the geojson should be pretty printed @default false
 * @returns a promise that resolves when the download is done
 */
export async function downloadGeojsonCircuit(
  filename: string,
  features: ExportCircuitFeatures,
  pretty = false,
  options = {
    projectName: 'unknown',
  }
): Promise<void> {
  let geojson = '';
  try {
    [geojson] = await generateGeoJSON(features, pretty, {
      projectName: options.projectName,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while generating the geojson', e);

    SnackbarUtils.error(
      'An error occurred while generating the GeoJSON circuit file. Please contact the support to get help.'
    );

    return;
  }

  const blob = new Blob([geojson], { type: 'application/geo+json' });

  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

  window.URL.revokeObjectURL(url);
}
