import type { RoadEditorAction } from 'actions/circuit';
import type { ErrorObject } from 'serialize-error';
import { serializeError } from 'serialize-error';
import { ZoneConveyorDeletionError } from 'utils/errors';
import type { Action } from '../shared';

export interface SelectAction extends Action {
  payload: { id: string };
}

export interface EnsureOneAction extends Action {
  payload: { id: string };
}

export interface EnsureManyAction extends Action {
  payload: { ids: string[] };
}

export interface LoadOneAction extends Action {
  payload: { id: string };
}

export interface LoadManyAction extends Action {
  payload: { ids: string[] };
}

export interface LoadOneSuccessAction<T> extends Action {
  payload: T;
}

export interface LoadManySuccessAction<T> extends Action {
  payload: T[];
}

export interface LoadOneFailureAction extends Action {
  payload: { id: string; error: ErrorObject; message?: string; info?: boolean };
}

export interface LoadManyFailureAction extends Action {
  payload: { ids: string[]; error: ErrorObject; message?: string; info?: boolean };
}

export interface SaveAction<T> extends Action {
  payload: Partial<T>;
}

export interface SaveManyAction<T> extends Action {
  payload: Partial<T>[];
}

export interface SaveManySuccessAction<T> extends Action {
  payload: T[];
}

export interface SaveSuccessAction<T> extends Action {
  payload: T;
}

export interface SaveFailureAction extends Action {
  payload: { id: string; error: ErrorObject };
}

export interface DeleteAction extends Action {
  payload: { id: string };
}

export interface DeleteSuccessAction extends Action {
  payload: { id: string };
}

export interface DeleteSuccessManyAction extends Action {
  payload: { ids: string[] };
}

export interface DeleteFailureAction extends Action {
  payload: { id: string; error: ErrorObject; message?: string; info?: boolean };
}

export interface CreateAction<T> extends Action {
  payload: Partial<T>;
}

export interface CreateSuccessAction<T> extends Action {
  payload: T;
}

export interface CreateFailureAction extends Action {
  payload: { error: ErrorObject; message?: string; info?: boolean };
}

export interface EntityActionTypes {
  Create: string;
  CreateFailure: string;
  CreateSuccess: string;
  Delete: string;
  DeleteFailure: string;
  DeleteSuccess: string;
  DeleteSuccessMany: string;
  EnsureMany: string;
  EnsureOne: string;
  LoadMany: string;
  LoadManyFailure: string;
  LoadManySuccess: string;
  LoadOne: string;
  LoadOneFailure: string;
  LoadOneSuccess: string;
  Save: string;
  SaveMany: string;
  SaveFailure: string;
  SaveSuccess: string;
  SaveManySuccess: string;
  Select: string;
}

export interface EntityActionCreators<T> {
  create: (payload: CreateAction<T>['payload']) => CreateAction<T>;
  createFailure: (error: Error, info?: boolean) => CreateFailureAction;
  createSuccess: (payload: CreateSuccessAction<T>['payload']) => CreateSuccessAction<T>;
  delete: (payload: DeleteAction['payload'] & RoadEditorAction['payload']) => DeleteAction;
  deleteFailure: (id: string, error: Error, info?: boolean) => DeleteFailureAction;
  deleteSuccess: (payload: DeleteSuccessAction['payload']) => DeleteSuccessAction;
  deleteSuccessMany: (payload: DeleteSuccessManyAction['payload']) => DeleteSuccessManyAction;
  ensureMany: (payload: EnsureManyAction['payload']) => EnsureManyAction;
  ensureOne: (payload: EnsureOneAction['payload']) => EnsureOneAction;
  loadMany: (payload: LoadManyAction['payload']) => LoadManyAction;
  loadManyFailure: (ids: string[], error: Error) => LoadManyFailureAction;
  loadManySuccess: (payload: LoadManySuccessAction<T>['payload']) => LoadManySuccessAction<T>;
  loadOne: (payload: LoadOneAction['payload']) => LoadOneAction;
  loadOneFailure: (id: string, error: Error) => LoadOneFailureAction;
  loadOneSuccess: (payload: LoadOneSuccessAction<T>['payload']) => LoadOneSuccessAction<T>;
  save: (payload: SaveAction<T>['payload']) => SaveAction<T>;
  saveMany: (payload: SaveManyAction<T>['payload']) => SaveManyAction<T>;
  saveFailure: (id: string, error: Error) => SaveFailureAction;
  saveSuccess: (payload: SaveSuccessAction<T>['payload']) => SaveSuccessAction<T>;
  saveManySuccess: (payload: SaveManySuccessAction<T>['payload']) => SaveManySuccessAction<T>;
  select: (payload: SelectAction['payload']) => SelectAction;
}

export interface EntityActions<T> {
  types: EntityActionTypes;
  creators: EntityActionCreators<T>;
}

export function createEntityActions<T>(tag: string): EntityActions<T> {
  const Create = `[${tag}] Create`;
  const CreateFailure = `[${tag}] Create Failure`;
  const CreateSuccess = `[${tag}] Create Success`;
  const Delete = `[${tag}] Delete`;
  const DeleteFailure = `[${tag}] Delete Failure`;
  const DeleteSuccess = `[${tag}] Delete Success`;
  const DeleteSuccessMany = `[${tag}] Delete Success Many`;
  const EnsureMany = `[${tag}] Ensure Many`;
  const EnsureOne = `[${tag}] Ensure One`;
  const LoadMany = `[${tag}] Load Many`;
  const LoadManyFailure = `[${tag}] Load Many Failure`;
  const LoadManySuccess = `[${tag}] Load Many Success`;
  const LoadOne = `[${tag}] Load One`;
  const LoadOneFailure = `[${tag}] Load One Failure`;
  const LoadOneSuccess = `[${tag}] Load One Success`;
  const Save = `[${tag}] Save`;
  const SaveFailure = `[${tag}] Save Failure`;
  const SaveSuccess = `[${tag}] Save Success`;
  const SaveMany = `[${tag}] Save Many`;
  const SaveManySuccess = `[${tag}] Save Many Success`;
  const Select = `[${tag}] Select`;

  return {
    types: {
      Create,
      CreateFailure,
      CreateSuccess,
      Delete,
      DeleteFailure,
      DeleteSuccess,
      DeleteSuccessMany,
      EnsureMany,
      EnsureOne,
      LoadMany,
      LoadManyFailure,
      LoadManySuccess,
      LoadOne,
      LoadOneFailure,
      LoadOneSuccess,
      Save,
      SaveMany,
      SaveFailure,
      SaveSuccess,
      SaveManySuccess,
      Select,
    },
    creators: {
      create: (payload) => ({ type: Create, payload }),
      createFailure: (error, info = true) => {
        const serializedError = serializeError(error);
        // eslint-disable-next-line no-console
        console.warn(serializedError);

        return { type: CreateFailure, payload: { error: serializedError, message: serializedError.message, info } };
      },
      createSuccess: (payload) => ({ type: CreateSuccess, payload }),
      delete: (payload) => ({ type: Delete, payload }),
      deleteFailure: (id, error, info) => {
        const serializedError = serializeError(error);

        // we don't want to log the error if it's a ZoneConveyorDeletionError (normal handled case)
        if (!(error instanceof ZoneConveyorDeletionError)) {
          // eslint-disable-next-line no-console
          console.error(serializedError);
        }

        return { type: DeleteFailure, payload: { id, error: serializedError, message: serializedError.message, info } };
      },
      deleteSuccess: (payload) => ({ type: DeleteSuccess, payload }),
      deleteSuccessMany: (payload) => ({ type: DeleteSuccessMany, payload }),
      ensureMany: (payload) => ({ type: EnsureMany, payload }),
      ensureOne: (payload) => ({ type: EnsureOne, payload }),
      loadMany: (payload) => ({ type: LoadMany, payload }),
      loadManyFailure: (ids, error, info = true) => {
        const serializedError = serializeError(error);
        // eslint-disable-next-line no-console
        console.error(serializedError);

        return {
          type: LoadManyFailure,
          payload: { ids, error: serializedError, message: serializedError.message, info },
        };
      },
      loadManySuccess: (payload) => ({ type: LoadManySuccess, payload }),
      loadOne: (payload) => ({ type: LoadOne, payload }),
      loadOneFailure: (id, error, info = true) => {
        const serializedError = serializeError(error);
        // eslint-disable-next-line no-console
        console.error(serializedError);

        return {
          type: LoadOneFailure,
          payload: { id, error: serializedError, message: serializedError.message, info },
        };
      },
      loadOneSuccess: (payload) => ({ type: LoadOneSuccess, payload }),
      save: (payload) => ({ type: Save, payload }),
      saveMany: (payload) => ({ type: SaveMany, payload }),
      saveFailure: (id, error, info = true) => {
        const serializedError = serializeError(error);
        // eslint-disable-next-line no-console
        console.error(serializedError);

        return { type: SaveFailure, payload: { id, error: serializedError, message: serializedError.message, info } };
      },
      saveSuccess: (payload) => ({ type: SaveSuccess, payload }),
      saveManySuccess: (payload) => ({ type: SaveManySuccess, payload }),
      select: (payload) => ({ type: Select, payload }),
    },
  };
}
