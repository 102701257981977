import { Box } from '@mui/system';
import { memo, useEffect, useRef, useState } from 'react';
import { theme } from 'utils/mui-theme';

interface CursorProps {
  globalZoom: number;
  zoom?: number;
  followClientId?: number;
  points: [number, number];
  color: string;
  name: string;
  inactive: boolean;
}

export const Cursor = memo(({ globalZoom, followClientId, points, color, name, zoom, inactive }: CursorProps) => {
  const [nameRectLength, setNameRectLength] = useState(0);
  const nameRef = useRef<SVGTextElement>(null);

  /* Resize the background of a user cursor nametag */
  useEffect(() => {
    if (!name || !nameRef || !nameRef.current) return;

    const { width } = nameRef.current.getBBox();

    setNameRectLength(width);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameRef, nameRef.current]);

  if (!points || !color) return null;

  const textColor = theme.palette.getContrastText(color);

  return (
    <Box
      component="g"
      sx={{
        pointerEvents: 'none',
        userSelect: 'none',
        transition: 'transform 0.5s cubic-bezier(.17,.93,.38,1)',
        transformOrigin: 'top left',
        transform: `translate(${points[0]}px, ${points[1]}px)`,
        opacity: inactive ? 0.5 : 1,
      }}
    >
      <Box
        component="g"
        sx={{
          transformOrigin: 'top left',
          /* Rescale cursor to compensate d3 */
          transform: followClientId && zoom ? `scale(${1 / zoom})` : `scale(${1 / globalZoom})`,
        }}
      >
        {/* Cursor */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50px"
          height="50px"
          viewBox="0 0 35 35"
          fill="none"
          fillRule="evenodd"
        >
          <g fill="rgba(0,0,0,.2)" transform="translate(1,1)">
            <path d="m0 16.0219v-16.015l11.591 11.619h-6.781l-.411.124z" />
            <path d="m9.0845 16.6962-3.605 1.535-4.682-11.089 3.686-1.553z" />
          </g>
          <g fill="white">
            <path d="m0 16.0219v-16.015l11.591 11.619h-6.781l-.411.124z" />
            <path d="m9.0845 16.6962-3.605 1.535-4.682-11.089 3.686-1.553z" />
          </g>
          <g fill={color}>
            <path d="m7.751 16.0155-1.844.774-3.1-7.374 1.841-.775z" />
            <path d="m1 2.414v11.188l2.969-2.866.428-.139h4.768z" />
          </g>
        </svg>
        {/* Nametag */}
        <svg>
          <rect x="16" y="28" width={nameRectLength + 8 * 2} height="28" fill={color} />
          <text x="24" y="48" ref={nameRef} fill={textColor}>
            {name}
          </text>
        </svg>
      </Box>
    </Box>
  );
});
