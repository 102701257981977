import type { ConfirmOptions } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';

export let confirmRef: (options?: ConfirmOptions | undefined) => Promise<void>;
export const ConfirmUtilConfigurator: React.FC = () => {
  confirmRef = useConfirm();

  return null;
};

export const ConfirmUtil = (options?: ConfirmOptions): Promise<void> => {
  if (confirmRef) {
    return confirmRef(options);
  }

  return Promise.reject('confirmRef not initialized');
};
