import type { CloseDialog, DialogData } from 'actions';
import { closeDialogAction } from 'actions';
import type { DialogTypes } from 'models';
import type { Dispatch } from 'react';
import { connect } from 'react-redux';
import { selectDialogData, selectDialogOpen, selectDialogType } from 'reducers/dialog';
import type { AppState } from 'reducers/state';
import { DialogDisplayer as DialogDisplayerComponent } from './dialog-displayer';

interface StateProps {
  open: boolean;
  type: DialogTypes;
  data: DialogData;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    open: selectDialogOpen(state),
    type: selectDialogType(state),
    data: selectDialogData(state),
  };
}

interface DispatchProps {
  closeDialog: () => void;
}

function mapDispatchToProps(dispatch: Dispatch<CloseDialog>): DispatchProps {
  return {
    closeDialog: () => dispatch(closeDialogAction()),
  };
}

export const DialogDisplayer = connect(mapStateToProps, mapDispatchToProps)(DialogDisplayerComponent);
