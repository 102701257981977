import { aggregate } from '@manufac/echarts-simple-transform';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import type { Flow } from 'flows/flows';
import { useMemo } from 'react';
import type { SimulationTask } from 'simulation/simulation';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';

echarts.registerTransform(aggregate as any);

interface TasksDurationChartProps {
  tasks: SimulationTask[];
  flows: Flow[];
  considerWaitingTime: boolean;
}

interface FlowStatsDurations {
  /** The name of the flow */
  flowName: string;
  /** The minimum duration of the flow */
  min: number;
  /** The maximum duration of the flow */
  max: number;
  /** The mean duration of the flow */
  mean: number;
  /** The median duration of the flow */
  median: number;
  /** The first quartile duration of the flow */
  Q1: number;
  /** The third quartile duration of the flow */
  Q3: number;
  /** Every durations */
  values: number[];
}

export function TasksDurationChartReport(props: TasksDurationChartProps): JSX.Element {
  const { tasks, flows, considerWaitingTime } = props;

  const flowStatsDurations: FlowStatsDurations[] = useMemo(() => {
    const flowStats: Record<string, number[]> = {};

    for (const task of tasks) {
      if (!flowStats[task.taskName]) {
        flowStats[task.taskName] = [];
      }

      if (!task.endDate || !task.startDate || !task.creationDate) {
        continue;
      }

      const taskDuration = considerWaitingTime ? task.endDate - task.creationDate : task.endDate - task.startDate;

      flowStats[task.taskName].push(taskDuration);
    }

    const flowStatsDurations: FlowStatsDurations[] = [];

    for (const flowName in flowStats) {
      const durations = flowStats[flowName].sort((a, b) => a - b);

      const min = durations[0];
      const max = durations[durations.length - 1];

      let mean = 0;
      for (const duration of durations) {
        mean += duration;
      }

      mean /= durations.length;

      let median = 0;
      if (durations.length % 2 === 0) {
        median = (durations[durations.length / 2 - 1] + durations[durations.length / 2]) / 2;
      } else {
        median = durations[(durations.length - 1) / 2];
      }

      let Q1 = 0;
      if (durations.length % 4 === 0) {
        Q1 = (durations[durations.length / 4 - 1] + durations[durations.length / 4]) / 2;
      } else {
        Q1 = durations[(durations.length - 1) / 4];
      }

      let Q3 = 0;
      if (durations.length % 4 === 0) {
        Q3 = (durations[(durations.length * 3) / 4 - 1] + durations[(durations.length * 3) / 4]) / 2;
      } else {
        Q3 = durations[(durations.length * 3 - 1) / 4];
      }

      flowStatsDurations.push({
        flowName,
        min,
        max,
        mean,
        median,
        Q1,
        Q3,
        values: durations,
      });
    }

    return flowStatsDurations;
  }, [considerWaitingTime, tasks]);

  const nbMaxTimesLabels = useMemo(() => {
    return flows.map((flow) => flow.maximumTaskTime).filter(isDefined).length;
  }, [flows]);

  const options = useMemo(() => {
    const opts: echarts.EChartsOption = {
      tooltip: {
        trigger: 'axis',
        confine: true,
      },
      xAxis: {
        name: 'Duration',
        axisLabel: {
          formatter: (value: number) => {
            return `${value.toFixed(1)}s`;
          },
        },
        nameLocation: 'middle',
        nameGap: 30,
        scale: true,
      },
      yAxis: {
        type: 'category',
        axisTick: {
          show: true,
          length: 10000,
          inside: true,
          lineStyle: {
            opacity: 0.1,
          },
        },
      },
      grid: {
        bottom: 80,
        left: 40,
        top: nbMaxTimesLabels * 15 + 30,
        containLabel: true,
      },
      legend: {
        selected: {},
      },
      dataZoom: [
        {
          type: 'inside',
        },
        {
          type: 'slider',
          height: 20,
        },
      ],
      series: [
        {
          type: 'boxplot',
          name: 'Statistics',
          datasetId: 'duration_agregate',
          tooltip: {
            valueFormatter(value, dataIndex) {
              if (typeof value === 'number') {
                return `${value.toFixed(1)}s`;
              }

              // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
              return value ? value.toString() : '';
            },
          },
          encode: {
            x: ['min', 'Q1', 'median', 'Q3', 'max', 'mean'],
            y: 'flowName',
            itemName: ['flowName'],
            tooltip: ['min', 'Q1', 'median', 'Q3', 'max', 'mean'],
          },
          itemStyle: {
            borderColor: theme.palette.primary.main,
          },
          emphasis: {
            itemStyle: {
              borderColor: theme.palette.primary.main,
            },
          },
        },
        {
          type: 'scatter',
          name: 'Tasks',
          datasetId: 'raw',
          symbolSize: 7,
          tooltip: {
            trigger: 'item',
            valueFormatter(value, dataIndex) {
              if (typeof value === 'number') {
                return `${value.toFixed(1)}s`;
              }

              // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
              return value ? value.toString() : '';
            },
          },

          encode: {
            x: 'duration',
            y: 'flowName',
            label: 'Duration',
            itemName: 'Duration',
            tooltip: ['flowName', 'duration'],
          },
          color: theme.palette.common.black,
          itemStyle: {
            color: (params) => {
              const defaultColor = theme.palette.common.black;
              const flowName: unknown = params?.data?.[0];
              const duration: unknown = params?.data?.[1];
              if (!flowName || typeof flowName !== 'string' || !duration || typeof duration !== 'number')
                return defaultColor;

              const maximumTaskTimeForFlow = flows.find((flow) => flow.name === flowName)?.maximumTaskTime;
              if (maximumTaskTimeForFlow === undefined) return defaultColor;

              if (duration > maximumTaskTimeForFlow) return theme.palette.error.main;

              return defaultColor;
            },
          },
          emphasis: {
            itemStyle: {
              color: theme.palette.secondary.main,
            },
          },
          markArea: {
            silent: false,
            itemStyle: {
              color: theme.palette.secondary.light,
              opacity: 0.5,
              borderWidth: 2,
              borderColor: theme.palette.secondary.main,
            },

            data: flows
              .map((flow) => {
                if (flow.maximumTaskTime === undefined) return null;

                return {
                  name: flow.name,
                  xAxis: flow.maximumTaskTime,
                };
              })
              .filter(isDefined)
              .map((flow, i) => {
                return [
                  {
                    xAxis: flow.xAxis,
                    yAxis: flow.name,
                  },
                  {
                    xAxis: '10000000',
                    yAxis: flow.name,
                  },
                ];
              }),
          },
          markLine: {
            silent: false,
            itemStyle: {
              color: theme.palette.common.black,
              borderWidth: 2,
            },
            symbol: ['none', 'none'],
            data: flows
              .map((flow) => {
                if (flow.maximumTaskTime === undefined) return null;

                return {
                  name: flow.name,
                  xAxis: flow.maximumTaskTime,
                };
              })
              .filter(isDefined)
              .map((flow, i) => {
                return {
                  name: flow.name,
                  xAxis: flow.xAxis,
                  label: {
                    formatter: (params) => {
                      return `Maximum task time (${params.name})`;
                    },
                    distance: i * 15,
                  },
                };
              }),
          },
        },
      ],
      dataset: [
        {
          id: 'raw',
          source: flowStatsDurations
            .map((flowStatsDuration) => {
              return flowStatsDuration.values.map((value) => [flowStatsDuration.flowName, value]);
            })
            .flat(),
          dimensions: ['flowName', 'duration'],
        },
        {
          id: 'maximum_flow_time',
          source: flows.map((flow) => [flow.name, flow.maximumTaskTime]),
          dimensions: ['flowName', 'maximumTaskTime'],
        },
        {
          id: 'duration_agregate',
          fromDatasetId: 'raw',
          transform: [
            {
              type: 'ecSimpleTransform:aggregate',
              config: {
                resultDimensions: [
                  { name: 'min', from: 'duration', method: 'min' },
                  { name: 'Q1', from: 'duration', method: 'Q1' },
                  { name: 'median', from: 'duration', method: 'median' },
                  { name: 'Q3', from: 'duration', method: 'Q3' },
                  { name: 'max', from: 'duration', method: 'max' },
                  { name: 'mean', from: 'duration', method: 'average' },
                  { name: 'Flow Name', from: 'flowName' },
                ],
                groupBy: 'flowName',
              },
            },
          ],
        },
      ],
    };

    return opts;
  }, [flowStatsDurations, flows, nbMaxTimesLabels]);

  return (
    <ReactECharts
      option={options}
      style={{
        height: flowStatsDurations.length * 40 + 120,
      }}
    />
  );
}
