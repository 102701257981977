import type { GeoJsonCircuit } from 'models/circuit';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { containsOnlyNumbers } from './upgrade-1.10';

/**
 * Function to convert a circuit to the 2.7.0 version
 *
 * List of changes made:
 * - Use ids only made of numbers for cell templates #50528
 *
 * @param circuit
 * @returns the upgraded circuit
 */
export function fixCellTemplatesIds(circuit: any): GeoJsonCircuit {
  let cirStr = JSON.stringify(circuit);

  window.nextFreeId = (circuit as GeoJsonCircuit).properties.nextFreeId++;
  window.nextFreeIdArray[0] = window.nextFreeId;

  Object.values(circuit.properties.cellTemplates ?? []).forEach((cellTemplate) => {
    const id = cellTemplate && typeof cellTemplate === 'object' && 'id' in cellTemplate ? cellTemplate.id : undefined;

    if (id && typeof id === 'string') {
      if (!containsOnlyNumbers(id)) {
        const newId = generateShapeId();
        cirStr = cirStr.replaceAll(`"${id}"`, `"${newId}"`);
      }
    } else if (typeof id === 'number') {
      // eslint-disable-next-line no-console
      console.error(`cell template id ${id} is not a string`);
    }
  });

  const newCircuit = JSON.parse(cirStr) as GeoJsonCircuit;

  newCircuit.properties.roadEditorVersion = '2.7.0';
  newCircuit.properties.nextFreeId = window.nextFreeId++;
  if (newCircuit.properties.nextFreeIdArray?.[0]) {
    newCircuit.properties.nextFreeIdArray[0] = window.nextFreeId;
  }

  return newCircuit;
}

/**
 * Function to check if the function fixCellTemplatesIds is needed
 *
 * @param circuit
 * @returns true if the function fixCellTemplatesIds is needed
 */
export function checkCellTemplatesIdsFixNeeded(circuit: any): boolean {
  return Object.values(circuit.properties.cellTemplates ?? []).some((cellTemplate) => {
    const id = cellTemplate && typeof cellTemplate === 'object' && 'id' in cellTemplate ? cellTemplate.id : undefined;

    if (id && typeof id === 'string') {
      if (!containsOnlyNumbers(id)) {
        return true;
      }
    } else if (typeof id === 'number') {
      // eslint-disable-next-line no-console
      console.error(`cell template id ${id} is not a string`);
    }

    return false;
  });
}
