import type { CircuitSegment } from 'models/circuit';
import { roundCoordinates } from 'utils/helpers';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveManyAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: SegmentActionTypes,
  creators: {
    createFailure: createSegmentFailureAction,
    createSuccess: createSegmentSuccessAction,
    delete: deleteSegmentAction,
    deleteFailure: deleteSegmentFailureAction,
    deleteSuccess: deleteSegmentSuccessAction,
    loadOne: loadSegmentAction,
    loadOneFailure: loadSegmentFailureAction,
    loadOneSuccess: loadSegmentSuccessAction,
    saveFailure: saveSegmentFailureAction,
    saveSuccess: saveSegmentSuccessAction,
    saveMany: saveManySegmentsAction,
    select: selectSegmentAction,
  },
} = createEntityActions<CircuitSegment>('Segments');

const {
  creators: { save },
} = createEntityActions<CircuitSegment>('Segments');

export function saveSegmentAction(payload: Partial<CircuitSegment> & RoadEditorAction['payload']): SaveSegment {
  let action = save(payload);
  delete payload.userAction;
  if (action.payload.geometry) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        geometry: {
          ...action.payload.geometry,
          coordinates: action.payload.geometry.coordinates.map((coords) => roundCoordinates(coords)),
        },
      },
    };
  }

  return action;
}

export interface CreateSegment extends CreateAction<CircuitSegment> {}
export interface CreateSegmentFailure extends CreateFailureAction {}
export interface CreateSegmentSuccess extends CreateSuccessAction<CircuitSegment> {}
export interface DeleteSegment extends DeleteAction {}
export interface DeleteSegmentFailure extends DeleteFailureAction {}
export interface DeleteSegmentSuccess extends DeleteSuccessAction {}
export interface LoadSegment extends LoadOneAction {}
export interface LoadSegmentFailure extends LoadOneFailureAction {}
export interface LoadSegmentSuccess extends LoadOneSuccessAction<CircuitSegment> {}
export interface SaveSegment extends SaveAction<CircuitSegment> {}
export interface SaveSegmentFailure extends SaveFailureAction {}
export interface SaveSegmentSuccess extends SaveSuccessAction<CircuitSegment> {}
export interface SelectSegment extends SelectAction {}
export interface SaveManySegments extends SaveManyAction<CircuitSegment> {}

export type SegmentActions =
  | CreateSegment
  | CreateSegmentFailure
  | CreateSegmentSuccess
  | DeleteSegment
  | DeleteSegmentFailure
  | DeleteSegmentSuccess
  | LoadSegment
  | LoadSegmentFailure
  | LoadSegmentSuccess
  | SaveSegment
  | SaveSegmentFailure
  | SaveSegmentSuccess
  | SelectSegment
  | SaveManySegments;
