/*
 * Generated type guards for "racks-naming.model.ts".
 * WARNING: Do not manually change this file.
 */
import {
  StringVariableName,
  NumberVariableName,
  VariablesName,
  BlockDataPositionName,
  BlockDataPositionNames,
} from './racks-naming.model';

export function isStringVariableName(obj: unknown): obj is StringVariableName {
  const typedObj = obj as StringVariableName;
  return typedObj === '@rackName!' || typedObj === '@loadPattern!';
}

export function isNumberVariableName(obj: unknown): obj is NumberVariableName {
  const typedObj = obj as NumberVariableName;
  return (
    typedObj === '@column!' ||
    typedObj === '@positionInCell!' ||
    typedObj === '@level!' ||
    typedObj === '@numberOfColumns!' ||
    typedObj === '@numberOfLevels!' ||
    typedObj === '@position!' ||
    typedObj === '@positionInCellMultiLoad!' ||
    typedObj === '@positionMultiLoad!'
  );
}

export function isVariableName(obj: unknown): obj is VariablesName {
  const typedObj = obj as VariablesName;
  return (
    typedObj === '@rackName!' ||
    typedObj === '@loadPattern!' ||
    typedObj === '@column!' ||
    typedObj === '@positionInCell!' ||
    typedObj === '@level!' ||
    typedObj === '@numberOfColumns!' ||
    typedObj === '@numberOfLevels!' ||
    typedObj === '@position!' ||
    typedObj === '@positionInCellMultiLoad!' ||
    typedObj === '@positionMultiLoad!'
  );
}

export function isBlockDataPositionName(obj: unknown): obj is BlockDataPositionName {
  const typedObj = obj as BlockDataPositionName;
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typedObj['type'] === 'char' || typedObj['type'] === 'nbVariable' || typedObj['type'] === 'strVariable') &&
    typeof typedObj['value'] === 'string' &&
    (typeof typedObj['params'] === 'undefined' ||
      (((typedObj['params'] !== null && typeof typedObj['params'] === 'object') ||
        typeof typedObj['params'] === 'function') &&
        (typedObj['params']['formatting'] === 'number' || typedObj['params']['formatting'] === 'letter') &&
        (typedObj['params']['formattingDirection'] === 'ltr' || typedObj['params']['formattingDirection'] === 'rtl') &&
        typeof typedObj['params']['nbCharacters'] === 'number' &&
        typeof typedObj['params']['fillCharacter'] === 'string' &&
        typeof typedObj['params']['startAt'] === 'number' &&
        typeof typedObj['params']['step'] === 'number'))
  );
}

export function isBlockDataPositionNames(obj: unknown): obj is BlockDataPositionNames {
  const typedObj = obj as BlockDataPositionNames;
  return Array.isArray(typedObj) && typedObj.every((e: any) => isBlockDataPositionName(e) as boolean);
}
