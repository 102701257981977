/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { Dictionary } from 'shared/core';
import type { EntityState, CreationFormState } from './state';

// @ts-ignore
export interface EntitySelectors<T, S extends EntityState<T>, A> {
  getEntity: (entities: Dictionary<T>, id?: string) => T | undefined;
  selectAll: (state: A) => T[];
  selectCreationForm: (state: A) => CreationFormState;
  selectEntities: (state: A) => Dictionary<T>;
  selectIds: (state: A) => string[];
  selectSelectedId: (state: A) => string | undefined;
  selectTotal: (state: A) => number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function defaultSelectId(model: any): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return model.id;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function defaultWriteId(model: any, value: string): any {
  model.id = value;

  return model;
}

// not that easy to fix, was working in ts 4.7
// @ts-ignore
export function selectIds<T, S extends EntityState<T>>(state: S): string[] {
  return state.ids;
}

// @ts-ignore
export function selectEntities<T, S extends EntityState<T>>(state: S): Dictionary<T> {
  return state.entities;
}

// @ts-ignore
export function selectAll<T, S extends EntityState<T>>(state: S): T[] {
  const entities = selectEntities<T, S>(state);

  return selectIds<T, S>(state).map((id) => entities[id]);
}

// @ts-ignore
export function selectTotal<T, S extends EntityState<T>>(state: S): number {
  return selectIds<T, S>(state).length;
}

// @ts-ignore
export function selectSelectedId<T, S extends EntityState<T>>(state: S): string | undefined {
  return state.selectedId;
}

// @ts-ignore
export function selectCreationForm<T, S extends EntityState<T>>(state: S): CreationFormState {
  return state.creationForm;
}

// @ts-ignore
export function getEntity<T, S extends EntityState<T>>(entities: Dictionary<T>, id?: string): T | undefined {
  if (!id) {
    return;
  }

  return entities[id];
}

// @ts-ignore
export function createEntitySelectors<T, S extends EntityState<T>, A>(slice: string): EntitySelectors<T, S, A> {
  return {
    getEntity,
    selectAll: (state: A) => selectAll(state[slice]),
    selectCreationForm: (state: A) => selectCreationForm(state[slice]),
    selectEntities: (state: A) => selectEntities(state[slice]),
    selectIds: (state: A) => selectIds(state[slice]),
    selectSelectedId: (state: A) => selectSelectedId(state[slice]),
    selectTotal: (state: A) => selectTotal(state[slice]),
  };
}
