import { Clear, Done, Storage } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { PreferencesService } from 'utils/preferences';
import { delayStopEditBlur } from './constants';

interface EditSupervisorIPProps {
  editProjectPerm?: boolean;
  projectOpened: boolean;
}

const EditSupervisorIP: React.FC<EditSupervisorIPProps> = ({ editProjectPerm, projectOpened }) => {
  const defineNewSupervisorIP = useRef<string | null>(null);

  const supervisorIP = useMemo(() => {
    if (defineNewSupervisorIP.current) return defineNewSupervisorIP.current;
    if (!projectOpened) return;

    try {
      return PreferencesService.getPreferenceValue('communication/TCP/IP_supervisor') as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/IP_supervisor from preferences`, e);
    }
  }, [projectOpened]);

  const [editSupervisorIP, setEditSupervisorIP] = useState(false);
  const refInputSupervisorIP = useRef<HTMLInputElement | null>(null);

  const updateSupervisorIP = useCallback(async (newIP: string): Promise<void> => {
    const isIP = (await import('is-ip')).isIP;
    if (isIP(newIP)) {
      const [ok] = await PreferencesService.setPreferenceValue('communication/TCP/IP_supervisor', newIP, true);

      if (ok) {
        SnackbarUtils.success(`Supervisor IP updated to ${newIP}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        defineNewSupervisorIP.current = newIP;
        setEditSupervisorIP(false);
      } else {
        SnackbarUtils.error(`Could not update Supervisor IP`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`${newIP} is not a valid IP address`);

      SnackbarUtils.error(`${newIP} is not a valid IP address`);
    }
  }, []);

  if (!supervisorIP) return null;

  return (
    <ListItem>
      <ListItemIcon>
        <Storage />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            {editSupervisorIP ? (
              <TextField
                defaultValue={supervisorIP}
                onBlur={() => setTimeout(() => setEditSupervisorIP(false), delayStopEditBlur)}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setEditSupervisorIP(false)}>
                        <Clear />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          if (refInputSupervisorIP.current) {
                            updateSupervisorIP(refInputSupervisorIP.current.value);
                          }
                        }}
                      >
                        <Done />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={refInputSupervisorIP}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (refInputSupervisorIP.current) {
                      updateSupervisorIP(refInputSupervisorIP.current.value);
                    }
                  }
                }}
              />
            ) : (
              <>
                {supervisorIP}
                {!editSupervisorIP ? (
                  <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
                    <Box component="span">
                      <IconButton
                        onClick={() => {
                          setEditSupervisorIP(true);
                        }}
                        disabled={!editProjectPerm}
                      >
                        <EditIcon
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Tooltip>
                ) : undefined}
              </>
            )}
          </>
        }
        secondary="Supervisor IP"
      />
    </ListItem>
  );
};

export default EditSupervisorIP;
