import type { EditorDrawing } from 'drawings/editor.drawing';
import { awareness } from 'multiplayer/globals';
import type { UserPresence } from 'multiplayer/types';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { useUsers } from 'y-presence';
import { Cursors } from './cursors';
import { useCircuitDescriptionSubscription } from './hooks/useCircuitDescriptionSubscription';
import { useCircuitSubscription } from './hooks/useCircuitSubscription';
import { useCircuitVersionSubscription } from './hooks/useCircuitVersionSubscription';
import { useCollabWindowVisibility } from './hooks/useCollabWindowVisibility';
import { useDisconnectedUserDataCleanup } from './hooks/useDisconnectedUserDataCleanup';
import { useEnableDevicePrefManagementSubscription } from './hooks/useEnableDevicePrefManagementSubscription';
import { useFollowUserView } from './hooks/useFollowUserView';
import { useHighlight } from './hooks/useHighlight';
import { useInstallSubscription } from './hooks/useInstallSubscription';
import { useLidarSubscription } from './hooks/useLidarSubscription';
import { useMapImageSubscription } from './hooks/useMapImageSubscription';
import { useNextFreeIdSubscription } from './hooks/useNextFreeIdSubscription';
import { useProjectHostChangedSubscription } from './hooks/useProjectHostChangedSubscription';
import { useSyncYJSRemoteToLocal } from './hooks/useSyncYJSRemoteToLocal';
import { useUnfollowUserView } from './hooks/useUnfollowUserView';
import { useUpdatedShapesActionsSubscription } from './hooks/useUpdatedShapesActionsSubscription';

export type RenderMode = '2d' | '3d';
interface MultiplayerProps {
  drawing?: EditorDrawing;
  mode: RenderMode;
}

export function Multiplayer({ drawing, mode }: MultiplayerProps): JSX.Element {
  if (!awareness) throw new Error('Missing awareness');

  const users = useUsers(awareness) as Map<number, UserPresence>;
  const followClientId = useAppSelector((state) => state.multiplayer.followClientId);
  const synced = useAppSelector((state) => state.multiplayer.synced);
  const loading = useAppSelector((state) => state.core.loading);

  // When the user moves after following another user, unfollow the view
  useEffect(() => {
    if (followClientId) return;

    if (mode === '3d') {
      const canvas = document.getElementsByTagName('canvas')[0];
      if (!canvas) return;

      canvas.style.outline = 'unset';
    } else {
      const editorView = document.getElementById('editorView');
      if (!editorView) return;

      editorView.style.outline = 'unset';
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followClientId]);

  /* Follow/Unfollow user feature */
  useFollowUserView({ drawing: mode === '2d' ? drawing : undefined });
  useUnfollowUserView();

  /* Multiplayer circuit changes subscription */
  useNextFreeIdSubscription();
  useInstallSubscription();

  useCircuitSubscription();
  useUpdatedShapesActionsSubscription(loading);
  useLidarSubscription();
  useMapImageSubscription();

  useCircuitVersionSubscription();
  useCircuitDescriptionSubscription();
  useEnableDevicePrefManagementSubscription();

  useHighlight({ mode });
  useProjectHostChangedSubscription();

  /* Automatically synchronize remote changes */
  useSyncYJSRemoteToLocal();

  useDisconnectedUserDataCleanup();
  useCollabWindowVisibility();

  if (!synced || mode === '3d') return <></>;

  return (
    <>
      {/* Shows other users cursors */}
      <Cursors awareness={awareness} users={users} followClientId={followClientId} />
    </>
  );
}
