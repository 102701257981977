import { Replay } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { NewTaskDispatcherWithTime, NewTasksDispatcherWithTime, ReplayTrigger } from 'models/simulation';
import { useCallback, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addTrigger } from 'simulation/triggers';
import { useAppDispatch } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { formatTime } from 'utils/time';

interface CreateReplayTriggerBtnProps {
  tasks: (NewTaskDispatcherWithTime | NewTasksDispatcherWithTime)[];
}

export function CreateReplayTriggerBtn(props: CreateReplayTriggerBtnProps): JSX.Element {
  const { tasks } = props;

  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();

  const handleCreateReplayTrigger = useCallback(() => {
    setDisabled(true);

    const newTrigger: ReplayTrigger = {
      id: generateShapeId(),
      type: 'replay',
      enabled: false,
      name: `Replay ${formatTime(new Date())}`,
      tasks: tasks,
    };

    dispatch(addTrigger(newTrigger));

    SnackbarUtils.success('Replay trigger created');

    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }, [dispatch, tasks]);

  return (
    <Tooltip title="Create a replay trigger with the same tasks as this simulation">
      <Box component="span">
        <Button
          variant="contained"
          color="inherit"
          onClick={handleCreateReplayTrigger}
          startIcon={<Replay />}
          disabled={disabled}
        >
          Create replay trigger
        </Button>
      </Box>
    </Tooltip>
  );
}
