import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import { Button, Card, CardContent, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import { Integrations } from '@sentry/tracing';
import { emergencySave } from 'components/export/emergency-save';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import ReactDOMClient from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import 'reflect-metadata';
import { SnackbarUtils, SnackbarUtilsConfigurator } from 'services/snackbar.service';
import { getConfig } from 'utils/config';
import packageJson from '../package.json';
import { App } from './app.container';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store, { history } from './store';
import { theme } from './utils/mui-theme';

const sentryURL = process.env.REACT_APP_SENTRY_URL;

let lastTimeSentrySent = 0; // the date of the last time sentry sent an event
let sentrySampleRate = 1.0; // the current sample rate for sentry
const resetSentrySampleRateAfterTime = 5 * 60 * 1000; // 5 minutes in ms

let stopSample = false;
export const changeStopSampleState = (newState: boolean): boolean => (stopSample = newState);

if (sentryURL) {
  const integrations = [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }) as any, // type error: https://github.com/getsentry/sentry-docs/issues/1015
    // new OfflineIntegration(),
  ];
  const environment = !isDevVersion() ? (document.location.host.includes('staging') ? 'staging' : 'production') : 'dev';

  const hasReplays = isSentryReplaysModeEnabled();

  if (hasReplays) integrations.push(new SentryRRWeb());

  Sentry.init({
    dsn: sentryURL,
    integrations,
    release: `${getConfig('roadEditorVersion') || packageJson.version}${isDevVersion() ? '-dev' : ''}`,
    environment: environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    normalizeDepth: 10,

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (
        event.exception &&
        hint?.originalException instanceof Error &&
        hint.originalException.message === 'Manual Report'
      ) {
        const profile = store.getState().auth.profile;
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: 'Report an issue',
          user: {
            name: profile && 'name' in profile ? profile.name : undefined,
            email: profile && 'email' in profile ? profile.email : undefined,
          },
        });

        /**
         * We have an issue, when we click outside of the sentry form, the
         * sentry dialog is closed, but we don't want to close it unless we
         * manually send (or close) the feedback
         * https://redmine.balyo.com/issues/40557
         */
        const intervalID = setInterval(() => {
          const wrapperSentryEl = document.querySelector('.sentry-error-embed-wrapper');

          if (wrapperSentryEl && wrapperSentryEl instanceof HTMLElement) {
            wrapperSentryEl.style.zIndex = '100000';

            wrapperSentryEl.addEventListener(
              'click',
              (e) => {
                const target = e.target as HTMLElement;
                const dialogSentryEl = document.querySelector('.sentry-error-embed') as HTMLElement;

                if (
                  dialogSentryEl &&
                  dialogSentryEl instanceof HTMLElement &&
                  e?.target instanceof HTMLElement &&
                  !dialogSentryEl.contains(target)
                ) {
                  e.stopImmediatePropagation();
                  e.preventDefault();
                }
              },
              true
            );

            clearInterval(intervalID);
          }
        }, 100);
      }

      const now = Date.now();
      if (lastTimeSentrySent + resetSentrySampleRateAfterTime < now) {
        sentrySampleRate = 1.0;
      }

      lastTimeSentrySent = now;

      if (stopSample) {
        changeStopSampleState(false);

        return event;
      }

      const randomNb = Math.random();
      const shouldSend = randomNb < sentrySampleRate;
      if (shouldSend) {
        sentrySampleRate /= 2;

        return event;
      }

      return null;
    },
  });

  Sentry.setTag('rrweb.active', hasReplays ? 'yes' : 'no');
} else {
  // eslint-disable-next-line no-console
  console.log('No Sentry URL environment variable found. Sentry will not be initialized.');
}

function onAppCrash(): void {
  emergencySave();
}

const themeMantine = createTheme({
  /** Put your mantine theme override here */
});

function FallbackComponentAppCrash(): JSX.Element {
  return (
    <Box sx={{ width: 500, margin: 'auto' }} component="div">
      <Card variant="outlined">
        <CardContent>
          <h1>Road Editor has crashed</h1>
          <p>We emergency saved the circuit. It has been placed into your download folder.</p>
          <Button variant="outlined" onClick={() => window.location.reload()}>
            Restart Road Editor
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  if (!container) throw new Error('No root container found');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
  const root = ReactDOMClient.createRoot(container);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  root.render(
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <MantineProvider theme={themeMantine}>
          <ThemeProvider theme={theme}>
            {/** ts ignore to be removed once this issue is fixed: https://github.com/supasate/connected-react-router */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <ConnectedRouter history={history}>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <SnackbarUtilsConfigurator />
                <StrictMode>
                  <ErrorBoundary onError={onAppCrash} FallbackComponent={FallbackComponentAppCrash}>
                    <App />
                  </ErrorBoundary>
                </StrictMode>
              </SnackbarProvider>
            </ConnectedRouter>
          </ThemeProvider>
        </MantineProvider>
      </StyledEngineProvider>
    </Provider>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const snackBarRef = SnackbarUtils.toast(`A new version of Road Editor is available`, {
      action: (
        <Button
          onClick={() => {
            /**
             * We want to display the alert when:
             * - we detect and unsaved change (works only when a project is loaded)
             * - when no project is loaded
             *
             * I had to use a querySelector instead of the PreferenceService because it is too
             * soon for the app to load this piece of code.
             * !!document.querySelector('#project-and-circuit-name') === true <=> a project is loaded
             */
            const displayAlert =
              !document.querySelector('#project-and-circuit-name') || sessionStorage.unsavedChanges === 'true';
            if (displayAlert) {
              // eslint-disable-next-line no-alert
              const userOk = window.confirm(
                'Updating the application will erase all unsaved changes.\nDo you want to continue?'
              );
              if (!userOk) return;
            }

            if (registration && registration.waiting) {
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
              setTimeout(window.location.reload.bind(window.location), 1000);
              SnackbarUtils.closeSnackbar(snackBarRef);
            } else {
              // eslint-disable-next-line no-console
              console.error('No registration found', registration);
            }
          }}
          color="primary"
          variant="contained"
        >
          Update now
        </Button>
      ),
      autoHideDuration: 3600 * 1000, // 1 hr
    });

    window.updateAvailable = true;
    window.registrationWorker = registration;
  },
});

/**
 * Preload resources for offline pwa use
 */
window.addEventListener('load', () => {
  fetch('/wasm/libTurnGenerator.wasm');
});

/**
 * Returns if it is a dev web server or not
 * @returns {boolean}
 */
export function isDevVersion(): boolean {
  return !!document.location.port || document.location.host.includes('dev');
}

/**
 * Returns if it is a staging or not
 * @returns {boolean}
 */
export function isStaging(): boolean {
  return document.location.host.includes('staging');
}

export function isDevOrStaging(): boolean {
  return isDevVersion() || isStaging();
}

/**
 * Returns whether the sentry replays mode is enabled or not
 * @returns whether the sentry replays mode is enabled or not
 */
export function isSentryReplaysModeEnabled(): boolean {
  const replaysUserEnabled = localStorage.getItem('sentryReplays') === 'enabled';
  const environment = !isDevVersion() ? (document.location.host.includes('staging') ? 'staging' : 'production') : 'dev';

  return (
    replaysUserEnabled || environment === 'staging' || (environment === 'dev' && document.location.host.includes('dev'))
  );
}

// eslint-disable-next-line no-console
if (window.balyoSimulationVersion) console.log(window.balyoSimulationVersion);
