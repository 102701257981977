import { useDebouncedValue } from '@mantine/hooks';
import { ArrowDropDown, Bolt, Checklist, Edit } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { selectToolAction } from 'actions';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { Border } from 'components/utils/border';
import { collapseTransitionDuration } from 'components/utils/constants';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { FlowStepWithissionType } from 'flows/custom-steps.model';
import type { Flow, Station } from 'flows/flows';
import {
  addFlow,
  addStationToFlow,
  removeFlow,
  removeStationFromFlow,
  reorderStationsInFlow,
  setFlow,
  setFlowName,
  setFlowStepMissionType,
  setSelectedFlowId,
  setSelectedStationId,
} from 'flows/flows';
import type { StepData } from 'flows/get-steps-from-flow';
import { getSteps } from 'flows/get-steps-from-flow';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { useConfirm } from 'material-ui-confirm';
import { Tools } from 'models/tools';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clearAllItineraries } from 'routes/routes';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import type { ItineraryOfAFlow } from 'simulation/check-flows';
import {
  computeFlowItineraries,
  getComputingProgressFlowItineraries,
  stopComputingFlowItineraries,
} from 'simulation/check-flows';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { theme } from 'utils/mui-theme';
import { formatSecondsToHHMMSS } from 'utils/time';
import { isDefined } from 'utils/ts/is-defined';
import { ListPositionOfStation } from './station';

/**
 * @link https://redmine.balyo.com/issues/48263
 */
const maximumFlowNameLength = 30;

interface FlowCheckResult {
  /** number of itineraries checked */
  nbItineraries: number;
  /** itineraries with issues */
  issues: ItineraryOfAFlow[];
}

export interface FlowsToolboxProps {}
export function FlowsToolbox(props: FlowsToolboxProps): JSX.Element {
  const flows = useAppSelector((state) => state.flows.flows);
  const stations: (Station | undefined)[] = useAppSelector((state) => state.flows.stations);
  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);
  const [delayedSelectedFlowId] = useDebouncedValue(selectedFlowId, collapseTransitionDuration);

  const [editName, setEditName] = useState(false);

  //Add the default palletsPerTask value for the already created flows
  flows.forEach((flow) => {
    if (!flow.palletsPerTask) {
      store.dispatch(setFlow({ ...flow, palletsPerTask: 1 }));
    }
  });

  const selectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === selectedFlowId);
  }, [flows, selectedFlowId]);
  const delayedSelectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === delayedSelectedFlowId);
  }, [delayedSelectedFlowId, flows]);

  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.FlowConfiguration)?.icon;
  }, []);

  const handleEditName = useCallback((newValue: boolean) => {
    setEditName(newValue);
  }, []);

  const stationsOfSelectedFlow = useMemo(() => {
    return selectedFlow?.stations.map((station) => stations.find((st) => st?.id === station.id)).filter(isDefined);
  }, [selectedFlow, stations]);
  const stationsOfDelayedSelectedFlow = useMemo(() => {
    return delayedSelectedFlow?.stations
      .map((station) => stations.find((st) => st?.id === station.id))
      .filter(isDefined);
  }, [delayedSelectedFlow, stations]);

  const displayFlowData = selectedFlowId === delayedSelectedFlowId;

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '400px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title="Flow Configuration"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <Stack direction={'row'} spacing={1}>
          {!editName ? (
            <FlowListAndSelection flows={flows} selectedFlowId={selectedFlowId} handleEditName={handleEditName} />
          ) : (
            selectedFlow && <RenameFlow flow={selectedFlow} flows={flows} handleEditName={handleEditName} />
          )}
        </Stack>

        {selectedFlow && selectedFlowId && (
          <>
            <Stack
              direction={'row'}
              spacing={1}
              sx={{
                marginTop: theme.spacing(2),
              }}
            >
              <ObjectiveTextfield selectedFlow={selectedFlow} selectedFlowId={selectedFlowId} />

              <MaximumTaskTimeTextfield selectedFlow={selectedFlow} selectedFlowId={selectedFlowId} />
            </Stack>
            <Box
              component={'div'}
              sx={{
                marginTop: theme.spacing(2),
              }}
            >
              <PalletsPerTaskTexfield selectedFlow={selectedFlow} selectedFlowId={selectedFlowId} />
            </Box>
          </>
        )}

        {selectedFlow && (
          <Collapse
            in={displayFlowData}
            timeout={delayedSelectedFlow ? collapseTransitionDuration : 0}
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <Border>
              <ListStationsOfFlow
                key={delayedSelectedFlowId || selectedFlowId}
                stations={
                  !displayFlowData && stationsOfDelayedSelectedFlow
                    ? stationsOfDelayedSelectedFlow
                    : stationsOfSelectedFlow ?? []
                }
                flow={!displayFlowData && delayedSelectedFlow ? delayedSelectedFlow : selectedFlow}
              />
            </Border>
          </Collapse>
        )}
      </CardContent>
    </Card>
  );
}

interface FlowConfigurationProps {
  selectedFlowId: string;
  selectedFlow: Flow;
}
function ObjectiveTextfield(props: FlowConfigurationProps): JSX.Element {
  const { selectedFlowId, selectedFlow } = props;

  const dispatch = useAppDispatch();

  const objectiveInputRef = useRef<HTMLInputElement>(null);

  const minObjectiveValue = 0;

  const handleChangeObjective = useCallback(() => {
    if (!selectedFlow) return;
    const input = objectiveInputRef.current;
    if (!input) return;

    const objective = input.valueAsNumber;
    let objectifOk = true;
    if (isNaN(objective)) objectifOk = false;
    else if (objective < minObjectiveValue) objectifOk = false;

    if (!objectifOk) {
      input.value = '';

      dispatch(
        setFlow({
          ...selectedFlow,
          objective: undefined,
        })
      );

      return;
    }

    dispatch(
      setFlow({
        ...selectedFlow,
        objective,
      })
    );
  }, [dispatch, selectedFlow]);

  return (
    <TextField
      key={selectedFlowId}
      type="number"
      label={
        <>
          Objective <HelpIconTooltip title="Objective for the flows in task per hour (optional)" />
        </>
      }
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{ min: minObjectiveValue, step: 1 }}
      InputProps={{
        endAdornment: (
          <>
            <IconButton
              onClick={(e) => {
                if (objectiveInputRef.current) {
                  objectiveInputRef.current.value = '';
                  handleChangeObjective();
                }
              }}
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            tasks/hr
          </>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={selectedFlow?.objective}
      inputRef={objectiveInputRef}
      onBlur={handleChangeObjective}
    />
  );
}

interface MaximumTaskTimeTextfieldProps {
  selectedFlowId: string;
  selectedFlow: Flow;
}
function MaximumTaskTimeTextfield(props: MaximumTaskTimeTextfieldProps): JSX.Element {
  const { selectedFlowId, selectedFlow } = props;

  const dispatch = useAppDispatch();

  const maximumTaskTimeInputRef = useRef<HTMLInputElement>(null);

  const minMaxTaskTimeValue = 0;

  const handleChangeMaxTime = useCallback(() => {
    if (!selectedFlow) return;
    const input = maximumTaskTimeInputRef.current;
    if (!input) return;

    const maximumTaskTime = input.valueAsNumber;
    let maximumTaskTimeOk = true;
    if (isNaN(maximumTaskTime)) maximumTaskTimeOk = false;
    else if (maximumTaskTime < minMaxTaskTimeValue) maximumTaskTimeOk = false;

    if (!maximumTaskTimeOk) {
      input.value = '';

      dispatch(
        setFlow({
          ...selectedFlow,
          maximumTaskTime: undefined,
        })
      );

      return;
    }

    dispatch(
      setFlow({
        ...selectedFlow,
        maximumTaskTime,
      })
    );
  }, [dispatch, selectedFlow]);

  const [maximumTaskTimeForTooltip, setMaximumTaskTimeForTooltip] = useState<number | undefined>(
    selectedFlow?.maximumTaskTime
  );
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    setMaximumTaskTimeForTooltip(selectedFlow?.maximumTaskTime);
  }, [selectedFlow?.maximumTaskTime]);

  const maximumTaskTimeFormatted =
    maximumTaskTimeForTooltip !== undefined ? formatSecondsToHHMMSS(maximumTaskTimeForTooltip) : '';

  return (
    <Tooltip
      title={maximumTaskTimeFormatted}
      arrow
      open={inputFocus && !!maximumTaskTimeForTooltip && !isNaN(maximumTaskTimeForTooltip)}
    >
      <TextField
        key={selectedFlowId}
        type="number"
        label={
          <>
            Maximum Task Time <HelpIconTooltip title="Maximum task time for the flows in seconds (optional)" />
          </>
        }
        variant="outlined"
        fullWidth
        size="small"
        defaultValue={selectedFlow?.maximumTaskTime}
        inputProps={{ min: minMaxTaskTimeValue, step: 1 }}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                onClick={(e) => {
                  if (maximumTaskTimeInputRef.current) {
                    maximumTaskTimeInputRef.current.value = '';
                    handleChangeMaxTime();
                  }
                }}
                size="small"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              s
            </>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onFocus={() => setInputFocus(true)}
        onBlur={() => {
          setInputFocus(false);
          handleChangeMaxTime();
        }}
        onChange={(e) => {
          const input = e.target;
          if (!(input instanceof HTMLInputElement)) return;
          setMaximumTaskTimeForTooltip(input.valueAsNumber);
        }}
        inputRef={maximumTaskTimeInputRef}
      />
    </Tooltip>
  );
}

function PalletsPerTaskTexfield(props: FlowConfigurationProps): JSX.Element {
  const { selectedFlowId, selectedFlow } = props;

  const dispatch = useAppDispatch();

  const palletsPerTaskInputRef = useRef<HTMLInputElement>(null);

  const minPalletsPerTaskValue = 0.001;
  const maxPalletsPerTaskValue = 50;

  const handleChangePalletsPerTask = useCallback(() => {
    if (!selectedFlow) return;
    const input = palletsPerTaskInputRef.current;
    if (!input) return;

    const palletsPerTask = Math.round(input.valueAsNumber * 1000) / 1000;

    let palletsPerTaskOk = true;
    if (isNaN(palletsPerTask)) palletsPerTaskOk = false;
    else if (palletsPerTask < minPalletsPerTaskValue) palletsPerTaskOk = false;

    if (!palletsPerTaskOk) {
      input.value = '0.001';

      dispatch(
        setFlow({
          ...selectedFlow,
          palletsPerTask: 0.001,
        })
      );

      return;
    }

    if (palletsPerTask > maxPalletsPerTaskValue) {
      input.value = '50';

      dispatch(
        setFlow({
          ...selectedFlow,
          palletsPerTask: 50,
        })
      );

      return;
    }

    dispatch(
      setFlow({
        ...selectedFlow,
        palletsPerTask: palletsPerTask,
      })
    );

    input.value = `${palletsPerTask}`;
  }, [dispatch, selectedFlow]);

  return (
    <TextField
      key={selectedFlowId}
      type="number"
      label={
        <>
          Number of pallets per task{' '}
          <HelpIconTooltip title="Number of pallets moved during one task. Warning ! This value can affect the throughput when expressed in pallets per hour." />
        </>
      }
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{ min: minPalletsPerTaskValue, step: 1 }}
      InputProps={{
        endAdornment: (
          <>
            <IconButton
              onClick={(e) => {
                if (palletsPerTaskInputRef.current) {
                  palletsPerTaskInputRef.current.value = '';
                  handleChangePalletsPerTask();
                }
              }}
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            pal/task
          </>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={selectedFlow.palletsPerTask}
      inputRef={palletsPerTaskInputRef}
      onBlur={handleChangePalletsPerTask}
    />
  );
}

interface FlowListAndSelectionProps {
  flows: Flow[];
  selectedFlowId?: string;
  handleEditName: (newValue: boolean) => void;
}
function FlowListAndSelection(props: FlowListAndSelectionProps): JSX.Element {
  const { flows, selectedFlowId, handleEditName } = props;

  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const selectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === selectedFlowId);
  }, [flows, selectedFlowId]);

  const handleSelectFLowId = useCallback(
    (e: SelectChangeEvent<string>) => {
      dispatch(setSelectedFlowId(e.target.value));
    },
    [dispatch]
  );

  const handleDeleteFlow = useCallback(async () => {
    if (!selectedFlow) {
      // eslint-disable-next-line no-console
      console.error('No selected flow');

      return;
    }

    try {
      await confirm({
        title: `Are you sure you want to remove "${selectedFlow.name}"?`,
      });
    } catch (e) {
      return;
    }

    dispatch(removeFlow(selectedFlow.id));

    SnackbarUtils.success(`Flow "${selectedFlow.name}" removed`);
  }, [confirm, dispatch, selectedFlow]);

  const handleCreateFlow = useCallback(() => {
    let newFlowName = 'New Flow';
    let nb = 1;
    // eslint-disable-next-line no-loop-func
    while (flows.find((flow) => flow.name === newFlowName)) {
      newFlowName = `New Flow ${nb++}`;
    }

    const newFlowId = generateShapeId();

    dispatch(
      addFlow({
        id: newFlowId,
        name: newFlowName,
        stations: [],
        robotsAssigned: 'all',
        palletsPerTask: 1,
      })
    );

    dispatch(setSelectedFlowId(newFlowId));

    SnackbarUtils.success(`Flow "${newFlowName}" created`);
  }, [dispatch, flows]);

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="flows-list">Flows</InputLabel>
        <Select
          labelId="flows-list"
          label="Flows"
          id="flows-list"
          value={selectedFlowId || ''}
          onChange={handleSelectFLowId}
          size="small"
        >
          {flows.map((flow) => (
            <MenuItem key={flow.id} value={flow.id}>
              {flow.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Rename this flow">
        <Box component="span">
          <IconButton
            aria-label="rename flow"
            disabled={!selectedFlow}
            onClick={() => handleEditName && handleEditName(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Delete this flow">
        <Box component="span">
          <IconButton aria-label="delete flow" disabled={!selectedFlow} onClick={handleDeleteFlow}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Create a new flow">
        <Box component="span">
          <IconButton aria-label="create flow" onClick={handleCreateFlow}>
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface RenameFlowProps {
  flow: Flow;
  flows: Flow[];
  handleEditName: (newValue: boolean) => void;
}
function RenameFlow(props: RenameFlowProps): JSX.Element {
  const { flow, flows, handleEditName } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleValidateName = useCallback(() => {
    const newName = inputRef.current?.value;
    if (newName?.length && newName.length > maximumFlowNameLength) {
      setError(true);

      return;
    }

    const existingFlow = flows.find((f) => f.name === newName && f.id !== flow.id);
    if (!newName || existingFlow) {
      setError(true);

      return;
    }

    dispatch(
      setFlowName({
        id: flow.id,
        name: newName,
      })
    );

    if (handleEditName) {
      handleEditName(false);
    }
  }, [dispatch, flow.id, flows, handleEditName]);

  const handleRevertName = useCallback(() => {
    if (handleEditName) {
      handleEditName(false);
    }
  }, [handleEditName]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (error) setError(false);

      if (e.key === 'Enter') {
        handleValidateName();
      } else if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [error, handleRevertName, handleValidateName]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="flow-name"
          label="Flow name"
          variant="outlined"
          size="small"
          defaultValue={flow.name}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          error={error}
          helperText={error ? 'This name is incorrect or already used' : undefined}
          inputProps={{
            maxLength: maximumFlowNameLength,
          }}
        />
      </FormControl>

      <Tooltip title="Revert">
        <Box component="span">
          <IconButton aria-label="revert" onClick={handleRevertName}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Validate">
        <Box component="span">
          <IconButton aria-label="validate" onClick={handleValidateName}>
            <CheckIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface ListStationsOfFlowProps {
  stations: Station[];
  flow: Flow;
}
function ListStationsOfFlow(props: ListStationsOfFlowProps): JSX.Element {
  const { stations, flow } = props;

  const dispatch = useAppDispatch();

  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);
  const availableStations = useAppSelector((state) => state.flows.stations);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedStationId, setSelectedStationIdLocal] = useState<string | null>(null);

  const handleSelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;

    setSelectedStationIdLocal(value);
  }, []);

  const handleAddStation = useCallback(() => {
    if (!selectedStationId) return;
    if (!selectedFlowId) return;

    dispatch(
      addStationToFlow({
        flowId: selectedFlowId,
        stationId: selectedStationId,
      })
    );

    setSelectedStationIdLocal(null);
  }, [dispatch, selectedFlowId, selectedStationId]);

  const handleClickStep = useCallback((step: number) => {
    setActiveStep(step);
  }, []);

  const handleClickRemoveStation = useCallback(
    (stationId: string) => {
      if (!selectedFlowId) return;

      dispatch(
        removeStationFromFlow({
          flowId: selectedFlowId,
          stationId,
        })
      );
    },
    [dispatch, selectedFlowId]
  );

  const handleEditStation = useCallback(
    (stationId: string) => {
      dispatch(setSelectedStationId(stationId));

      dispatch(
        selectToolAction({
          toolName: Tools.StationsConfiguration,
        })
      );
    },
    [dispatch]
  );

  const handleReorderStations = useCallback(
    (stationId: string, direction: 'up' | 'down') => {
      if (!selectedFlowId) return;

      const newActiveStep = activeStep + (direction === 'up' ? -1 : +1);
      setActiveStep(-1);

      setTimeout(() => {
        dispatch(
          reorderStationsInFlow({
            flowId: selectedFlowId,
            stationId,
            direction,
          })
        );

        setActiveStep(newActiveStep);
      }, 500);
    },
    [activeStep, dispatch, selectedFlowId]
  );

  const editStationPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:stations');
  }, []);

  const editRobotAssignation = useCallback(() => {
    dispatch(selectToolAction({ toolName: Tools.FlowAssignation }));
  }, [dispatch]);

  const [checkingFlow, setCheckingFlow] = useState(false);
  const [checkingFlowProgress, setCheckingFlowProgress] = useState<undefined | number>(undefined);
  const [previousCheckResult, setPreviousCheckResult] = useState<FlowCheckResult | undefined>(undefined);

  useEffect(() => {
    if (!selectedFlowId) return;

    setPreviousCheckResult(undefined);
  }, [selectedFlowId]);

  const updateCheckingFlowProgress = useCallback(() => {
    if (!selectedFlowId) return;

    const progress = getComputingProgressFlowItineraries(selectedFlowId);
    setCheckingFlowProgress(progress);
  }, [selectedFlowId]);

  const checkFlow = useCallback(
    async (displayItineraries: boolean) => {
      if (!selectedFlowId) return;

      setCheckingFlow(true);
      setPreviousCheckResult(undefined);

      const checkProgressInterval = setInterval(updateCheckingFlowProgress, 1000);

      try {
        const res = await computeFlowItineraries({
          flowId: selectedFlowId,
          displayItineraries,
        });

        const itineraries = res?.itineraries?.flat() ?? [];

        const nbItineraries = itineraries.length;
        const issues = itineraries.filter((itinerary) => !itinerary.ok);

        if (res) {
          setPreviousCheckResult({
            nbItineraries,
            issues,
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        SnackbarUtils.error('An error occured while checking the flow');
      }

      clearInterval(checkProgressInterval);

      setCheckingFlow(false);
      setCheckingFlowProgress(undefined);
    },
    [selectedFlowId, updateCheckingFlowProgress]
  );
  const stopCheckingFlow = useCallback(() => {
    stopComputingFlowItineraries();
  }, []);

  const EditRobotAssignationIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.FlowAssignation)?.icon;
  }, []);

  const stationsWithStep = useMemo(() => {
    const steps = getSteps(flow);

    return stations.map((station, index) => {
      const step = steps?.[index];

      return {
        ...station,
        step,
      };
    });
  }, [flow, stations]);

  useEffect(() => {
    return () => {
      dispatch(clearAllItineraries());
    };
  }, [dispatch]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel id="stations-list-label">Stations</InputLabel>
          <Select
            value={selectedStationId || ''}
            size="small"
            labelId="stations-list-label"
            label="Stations"
            id="stations-list"
            onChange={handleSelectChange}
          >
            {availableStations.map((station) => (
              <MenuItem key={station.id} value={station.id}>
                {station.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tooltip title="Add station to the flow">
          <Box component="span">
            <IconButton
              aria-label="add station"
              color="primary"
              disabled={!selectedStationId}
              onClick={handleAddStation}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>

      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        {stationsWithStep.map((station, i) => {
          const step = 'step' in station ? station.step : undefined;

          return (
            <Step key={`${station.id}-step`} completed={false}>
              <StepLabel
                onClick={() => handleClickStep(i)}
                sx={{
                  cursor: activeStep !== i ? 'pointer' : undefined,
                }}
                optional={
                  step && <EditStepBtn key={station.id} step={step} station={station} flow={flow} stepIndex={i} />
                }
              >
                {station.name}
              </StepLabel>

              <StepContent>
                {station.positions?.length > 0 ? (
                  <ListPositionOfStation
                    station={station}
                    sx={{
                      maxHeight: '200px',
                    }}
                    displayRemovePositionBtn={false}
                    displayStockLineShuffleMode={false}
                  />
                ) : (
                  <p>No positions in this station</p>
                )}

                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => handleClickRemoveStation(station.id)}
                  sx={{
                    textTransform: 'none',
                  }}
                  size="small"
                >
                  Remove
                </Button>

                <Tooltip title={!editStationPerm ? 'You do not have the permission to edit stations' : ''}>
                  <Box component="span">
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => handleEditStation(station.id)}
                      sx={{
                        textTransform: 'none',
                      }}
                      size="small"
                      disabled={!editStationPerm}
                    >
                      Edit the station
                    </Button>
                  </Box>
                </Tooltip>

                <IconButton disabled={i === 0} onClick={() => handleReorderStations(station.id, 'up')}>
                  <KeyboardArrowUpIcon />
                </IconButton>

                <IconButton
                  disabled={i === stations.length - 1}
                  onClick={() => handleReorderStations(station.id, 'down')}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>

      <Button
        fullWidth
        onClick={editRobotAssignation}
        startIcon={!!EditRobotAssignationIcon ? <EditRobotAssignationIcon /> : undefined}
        variant="outlined"
        sx={{ marginTop: theme.spacing(1) }}
      >
        Edit Robot Assignation
      </Button>

      <ButtonGroup
        sx={{
          width: '100%',
          marginTop: theme.spacing(1),
        }}
      >
        <Button
          fullWidth
          onClick={() => checkFlow(true)}
          disabled={!selectedFlowId || checkingFlow}
          startIcon={<Checklist />}
          variant="outlined"
        >
          Check flow
        </Button>
        {!checkingFlow && (
          <Tooltip title="Check flow without displaying itineraries (much faster on large projects)">
            <Button onClick={() => checkFlow(false)}>
              <Bolt />
            </Button>
          </Tooltip>
        )}
        {checkingFlow && (
          <Tooltip title="Abort the computation">
            <Button onClick={stopCheckingFlow} disabled={!checkingFlowProgress}>
              <ClearIcon />
            </Button>
          </Tooltip>
        )}
      </ButtonGroup>
      {checkingFlow && (
        <LinearProgress
          value={checkingFlow && checkingFlowProgress !== undefined ? checkingFlowProgress * 100 : undefined}
          variant={checkingFlowProgress ? 'determinate' : 'indeterminate'}
          sx={{
            marginTop: '-4px',
          }}
          color={'inherit'}
        />
      )}

      {previousCheckResult && <CheckResultAlert checkResult={previousCheckResult} />}
    </>
  );
}

interface EditStepBtnProps {
  step: StepData;
  station: FlowStepWithissionType;
  flow: Flow;
  stepIndex: number;
}
function EditStepBtn(props: EditStepBtnProps): JSX.Element {
  const { step, flow, stepIndex } = props;

  const customSteps = useAppSelector((state) => state.flows.customSteps);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);

  const availableSteps = useMemo(() => {
    return [
      {
        name: 'Default',
        missionType: undefined,
      },
      {
        name: 'Pick',
        missionType: 'pick' as const,
      },
      {
        name: 'Drop',
        missionType: 'drop' as const,
      },
      {
        name: 'Move',
        missionType: 'move' as const,
      },
      ...customSteps.map((customStep) => ({
        name: customStep.name,
        missionType: 'custom' as const,
        customStepId: customStep.id,
      })),
    ];
  }, [customSteps]);

  const handleClickSelectStep = useCallback(
    ({
      stepIndex,
      missionType,
      customMissionId,
    }: {
      stepIndex: number;
      missionType: FlowStepWithissionType['missionType'] | undefined;
      customMissionId?: string;
    }) => {
      if (customMissionId && missionType !== 'custom') {
        // eslint-disable-next-line no-console
        console.error(`Incoherency between missionType and customMissionId`, { customMissionId, missionType });

        return;
      }

      dispatch(
        setFlowStepMissionType({
          flowId: flow.id,
          stepIndex,
          missionType,
          customMissionId,
        })
      );

      setOpen(false);
    },
    [dispatch, flow.id]
  );

  const handleOpenCustomStepsEditor = useCallback(() => {
    dispatch(
      selectToolAction({
        toolName: Tools.CustomStepsEditor,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Button ref={anchorEl} onClick={() => setOpen(true)} size="small" endIcon={<ArrowDropDown />} color="inherit">
        {step.name}
      </Button>

      <Menu open={open} anchorEl={anchorEl.current} onClose={() => setOpen(false)}>
        {availableSteps.map((availableStep, i) => {
          const step = flow.stations[stepIndex];
          const isCustom = step.missionType === 'custom';
          const selected =
            (!isCustom && step.missionType === availableStep.missionType) ||
            (isCustom && 'customStepId' in availableStep && step.customMissionTypeId === availableStep.customStepId);

          return (
            <MenuItem
              key={`${availableStep.name}-${i}`}
              selected={selected}
              onClick={() =>
                handleClickSelectStep({
                  stepIndex,
                  missionType: availableStep.missionType,
                  customMissionId: 'customStepId' in availableStep ? availableStep.customStepId : undefined,
                })
              }
            >
              {availableStep.name}
            </MenuItem>
          );
        })}

        <Divider />
        <MenuItem onClick={handleOpenCustomStepsEditor}>
          <Edit
            fontSize="small"
            sx={{
              marginRight: theme.spacing(1),
            }}
          />
          Edit custom steps
        </MenuItem>
      </Menu>
    </>
  );
}

interface CheckResultAlertProps {
  checkResult: FlowCheckResult;
}
function CheckResultAlert(props: CheckResultAlertProps): JSX.Element {
  const { checkResult } = props;

  const nbMaxSlotsIssuesDisplay = 50;

  const allSlotsReachables = checkResult.issues.length === 0;

  const alertRef = useRef<HTMLDivElement>(null);

  const nbUnreachableSlots = useMemo(() => {
    const slots = new Set<string>();

    checkResult.issues.forEach((itinerary) => {
      slots.add(itinerary.src);
      slots.add(itinerary.dst);
    });

    return slots.size;
  }, [checkResult]);

  useEffect(() => {
    // scroll to the alert element when mounted
    if (alertRef.current) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);

  return (
    <Alert
      severity={allSlotsReachables ? 'success' : 'warning'}
      ref={alertRef}
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <AlertTitle>
        {allSlotsReachables ? 'All slots are reachables' : `${nbUnreachableSlots} slots are not reachable`}
      </AlertTitle>
      {checkResult.nbItineraries} itineraries computed (
      {((1 - checkResult.issues.length / checkResult.nbItineraries) * 100).toFixed(1)}% OK)
      {checkResult.issues.length > 0 && (
        <Box
          component="p"
          sx={{
            maxHeight: '150px',
          }}
        >
          The following itineraries had issues:
          <ul>
            {checkResult.issues.map((itinerary, i) => {
              if (i === nbMaxSlotsIssuesDisplay) return <li key="more">...</li>;
              if (i >= nbMaxSlotsIssuesDisplay) return null;

              const from = positionIdToPositionName(itinerary.src, 'all');
              const to = positionIdToPositionName(itinerary.dst, 'all');

              return (
                <li key={`${itinerary.src}-${itinerary.dst}`}>
                  {from} → {to}
                </li>
              );
            })}
          </ul>
        </Box>
      )}
    </Alert>
  );
}
