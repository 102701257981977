import type { Theme } from '@mui/material';
import { Drawer, List } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Route } from 'react-router';
import { ToolBar } from './tool-bar.container';

const DRAWER_WIDTH = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      zIndex: theme.zIndex.appBar - 1,
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '64px',
    },
    paper: {
      paddingTop: '65px',
    },
  })
);

function useToggleMenu(initialValue = false): [boolean, () => void] {
  const [isMenuOpen, setIsOpenMenu] = useState(initialValue);

  function toggleMenu(): void {
    return setIsOpenMenu(!isMenuOpen);
  }

  return [isMenuOpen, toggleMenu];
}

export function ToolBarDrawer(): JSX.Element {
  const classes = useStyles();
  const [isMenuOpen, toggleMenu] = useToggleMenu();

  const handleClickOnOpenMenu = useCallback(() => {
    if (isMenuOpen) {
      toggleMenu();
    }
  }, [isMenuOpen, toggleMenu]);

  return (
    <Drawer
      open={isMenuOpen}
      onClose={toggleMenu}
      variant="permanent"
      PaperProps={{
        elevation: 3,
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isMenuOpen,
        [classes.drawerClose]: !isMenuOpen,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: isMenuOpen,
          [classes.drawerClose]: !isMenuOpen,
        }),
      }}
    >
      <div onClick={handleClickOnOpenMenu} onKeyDown={handleClickOnOpenMenu}>
        <List>
          <Route path="/:section" component={ToolBar} />
        </List>
      </div>
    </Drawer>
  );
}
