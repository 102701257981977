import { PreferencesService } from 'utils/preferences';
import { getAllPositions } from './get-all-positions';

/**
 * Exports the positions of all slots in the circuit to a CSV file.
 * The CSV file includes the id, name, and type of each slot.
 * The file is named with the current project name and the current date and time.
 * The file is automatically downloaded by the user's browser.
 */
export function exportCSVSlots(): void {
  const allPositions = getAllPositions({
    selectedPositionType: 'all',
  });

  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += '"id","name","type","state"\n';
  allPositions.forEach((position) => {
    const enabledTxt = position.disabled === true ? 'disabled' : position.disabled === false ? 'enabled' : '';

    csvContent += `"${position.id}","${position.name}","${position.type}","${enabledTxt}"\n`;
  });

  const projectName = PreferencesService.arePreferencesFullyLoaded() ? PreferencesService.getProjectName() : 'unknown';
  const date = new Date().toISOString().replace(/:/g, '-').replace('T', '_').replace(/\..+/, '');
  const filename = `${projectName}_positions_${date}.csv`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF

  link.click();
  document.body.removeChild(link);
}
