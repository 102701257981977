/**
 * Recursively resets all properties of an object to a specified value.
 *
 * This function takes an object and a value, then iterates over the object's properties.
 * If a property is an object itself, the function is called recursively on that object.
 * Otherwise, the property is set to the specified value. The modified object is then returned.
 *
 * @param {T} obj - The object to reset.
 * @param {number | string} value - The value to set each property to.
 * @returns {T} - The modified object with all properties set to the specified value.
 * @template T - The type of the object being reset.
 */
export function resetObject<T>(obj: T, value: number | string): T {
  const obj2 = { ...obj };

  for (const key in obj2) {
    if (typeof obj2[key] === 'object') {
      obj2[key] = resetObject(obj2[key], value);
    } else {
      (obj2 as any)[key] = value as T[keyof T];
    }
  }

  return obj2;
}

export function divideAllPropertiesBy<T>(obj: T, divisor: number): T {
  const obj2 = { ...obj };

  for (const key in obj2) {
    if (typeof obj2[key] === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      (obj2 as any)[key] = divideAllPropertiesBy(obj2[key] as any, divisor);
    } else {
      (obj2 as any)[key] = (obj2[key] as number) / divisor;
    }
  }

  return obj2;
}
