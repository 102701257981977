import { cloneDeep } from 'lodash';
import type { CircuitShape, CircuitZone, GeoJsonCircuit } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { generateShapeId, getNextFreeId } from 'utils/circuit/next-free-id';

/**
 * Function to convert a circuit from the 1.10.1 version to the 1.10.2 version
 *
 * List of changes made:
 * - Use ids only made of numbers
 *
 * @param circuit
 * @returns the upgraded circuit
 */
export function upgrade1_10_2(circuit: GeoJsonCircuit): GeoJsonCircuit {
  // eslint-disable-next-line no-console
  console.log('Upgrading circuit to 1.10.2');

  window.nextFreeId = 1000;

  // const newCircuit = cloneDeep(circuit);
  let cirStr = JSON.stringify(circuit);

  circuit.features.forEach((feature) => {
    const id = feature.id;

    if (id && typeof id === 'string') {
      if (!containsOnlyNumbers(id)) {
        const newId = generateShapeId();
        cirStr = cirStr.replaceAll(`"${id}"`, `"${newId}"`);
      }
    } else if (typeof id === 'number') {
      // eslint-disable-next-line no-console
      console.error(`Shape id ${id} is not a string`);
    }
  });
  Object.values(circuit.properties.layers.layers).forEach((layer) => {
    const id = layer.id;

    if (id && typeof id === 'string') {
      if (!containsOnlyNumbers(id)) {
        const newId = generateShapeId();
        cirStr = cirStr.replaceAll(`"${id}"`, `"${newId}"`);
      }
    } else if (typeof id === 'number') {
      // eslint-disable-next-line no-console
      console.error(`Layer id ${id} is not a string`);
    }
  });
  Object.values(circuit.properties.layers.layerGroups).forEach((layerGroup) => {
    const id = layerGroup.id;

    if (id && typeof id === 'string') {
      if (!containsOnlyNumbers(id)) {
        const newId = generateShapeId();
        cirStr = cirStr.replaceAll(`"${id}"`, `"${newId}"`);
      }
    } else if (typeof id === 'number') {
      // eslint-disable-next-line no-console
      console.error(`LayerGroup id ${id} is not a string`);
    }
  });
  Object.values(circuit.properties.cellTemplates ?? []).forEach((cellTemplate) => {
    const id = cellTemplate.id;

    if (id && typeof id === 'string') {
      if (!containsOnlyNumbers(id)) {
        const newId = generateShapeId();
        cirStr = cirStr.replaceAll(`"${id}"`, `"${newId}"`);
      }
    } else if (typeof id === 'number') {
      // eslint-disable-next-line no-console
      console.error(`LayerGroup id ${id} is not a string`);
    }
  });

  const newCircuit = JSON.parse(cirStr) as GeoJsonCircuit;

  newCircuit.properties.roadEditorVersion = '1.10.2';
  newCircuit.properties.nextFreeId = getNextFreeId();

  return newCircuit;
}

export function containsOnlyNumbers(str: string): boolean {
  return /^[0-9]+$/.test(str);
}

/**
 * Function to convert a circuit from the 1.10.2 version to the 1.11.0 version
 *
 * List of changes made:
 * - zone properties are now in a rules array
 *
 * @param circuit the circuit to upgrade
 * @returns the upgraded circuit
 */
export function upgrade1_11_0(circuit: any): GeoJsonCircuit {
  // eslint-disable-next-line no-console
  console.log('Upgrading circuit to 1.11.0');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newCircuit = cloneDeep(circuit);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  newCircuit.features.forEach((feature: CircuitShape) => {
    if (feature?.properties?.type === ShapeTypes.ZoneShape) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const zone: any = feature as unknown as any;
      const rules: CircuitZone['properties']['rules'] = [];

      if (zone.properties.beep === 'NO_BEEP') {
        rules.push(['NoBeepInside']);
      }

      if (zone.properties.horn === 'ENTRY_HORN') {
        rules.push(['HornEntering']);
      }

      if (zone.properties.horn === 'EXIT_HORN') {
        rules.push(['HornLeaving']);
      }

      if (zone.properties.noParking) {
        rules.push(['NoParking']);
      }

      if (zone.properties.curtainInhibition) {
        rules.push(['CurtainInhibitedArea', zone.properties.curtainInhibition]);
      }

      if (zone.properties.door) {
        rules.push(['Door']);
      }

      if (zone.properties.stop === 'ENTRY_STOP') {
        rules.push(['StopEntering', 2]);
      }

      if (zone.properties.stop === 'EXIT_STOP') {
        rules.push(['StopLeaving', 2]);
      }

      if (zone.properties.blackHoleLocalisation) {
        rules.push(['BlackHole']);
      }

      if (zone.properties.roofHeight) {
        rules.push(['RoofHeight', zone.properties.roofHeight]);
      }

      if (zone.properties.maxSpeed) {
        rules.push(['LimitSpeed', zone.properties.maxSpeed]);
      }

      delete zone.properties.maxSpeed;
      delete zone.properties.beep;
      delete zone.properties.horn;
      delete zone.properties.stop;
      delete zone.properties.noParking;
      delete zone.properties.roofHeight;
      delete zone.properties.door;
      delete zone.properties.curtainInhibition;
      delete zone.properties.blackHoleLocalisation;

      zone.properties.rules = rules;
    }
  });

  newCircuit.properties.roadEditorVersion = '1.11.0';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return newCircuit;
}
