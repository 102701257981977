import {
  importCellTemplatesAction,
  importCircuitAction,
  importLayersAction,
  restoreCircuitInitialStateAction,
} from 'actions/circuit';
import type { CircuitShapes, GeoJsonCircuit } from 'models/circuit';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { isCircuitRack } from 'utils/circuit/shape-guards';
import { generateGeoJSON } from 'utils/export/generate-geojson';

/**
 * Test whether a slot id is missing in a rack of the circuit
 * @param circuit the circuit to test
 * @returns if we need to upgrade the cirucit
 */
export function testUpgradeRackSlotIdNeeded(circuit: GeoJsonCircuit): boolean {
  for (const layer of circuit.features) {
    for (const feature of layer.features) {
      if (isCircuitRack(feature)) {
        const rack = feature;

        for (const column of rack.properties.columns) {
          for (const cell of column.cells) {
            for (const loadNames of cell.names) {
              for (const slot of loadNames) {
                if (!slot.id) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
  }

  return false;
}

/**
 * This function will upgrade the circuit to add the slot id to the rack slots
 * @param circuit the circuit to upgrade
 * @returns the upgraded circuit
 */
export function upgradeRackSlotId(circuit: GeoJsonCircuit): GeoJsonCircuit {
  // eslint-disable-next-line no-console
  console.log('Upgrading circuit to RACK SLOT ID');

  window.nextFreeId = circuit.properties.nextFreeId++;
  window.nextFreeIdArray[0] = window.nextFreeId;

  const newCircuit: GeoJsonCircuit = structuredClone(circuit);

  let nbIdsSet = 0;

  newCircuit.features.forEach((layer) => {
    layer.features.forEach((feature) => {
      if (isCircuitRack(feature)) {
        const rack = feature;

        rack.properties.columns.forEach((column) => {
          column.cells.forEach((cell) => {
            const loadNames = cell.names;

            loadNames.forEach((slots) => {
              slots.forEach((slot) => {
                if (!slot.id) {
                  slot.id = generateShapeId();
                  nbIdsSet++;
                }
              });
            });
          });
        });
      }
    });
  });

  // eslint-disable-next-line no-console
  console.log(`${nbIdsSet} ids set for rack slots`);

  return newCircuit;
}

/**
 * This function will upgrade the circuit to add the slot id to the rack slots
 */
export const updateCircuitSimu = async (): Promise<void> => {
  const circuitElements = CircuitService.getCircuitElements();

  const [, , circuit] = await generateGeoJSON(circuitElements);

  const newCircuit = upgradeRackSlotId(circuit);

  const shapes: CircuitShapes = CircuitService.convertFeatureArrayCoordinates(
    newCircuit && newCircuit.features ? newCircuit.features.flatMap((layer) => layer.features) : [],
    (value) => value * DISPLAY_UNIT_FACTOR
  ) as CircuitShapes;

  store.dispatch(restoreCircuitInitialStateAction());

  store.dispatch(
    importLayersAction({
      layers: newCircuit.properties.layers.layers,
      layerGroups: newCircuit.properties.layers.layerGroups,
      selectedLayer: newCircuit.properties.layers.selectedLayer,
    })
  );

  if (newCircuit.properties.cellTemplates) {
    store.dispatch(importCellTemplatesAction({ cellTemplates: newCircuit.properties.cellTemplates }));
  }

  store.dispatch(
    importCircuitAction({
      shapes,
    })
  );

  SnackbarUtils.success('Circuit updated');
};
