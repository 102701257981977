import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { deleteMissingPositionsInStations } from 'flows/check-positions';
import type { EditorMode } from './model';

export const performanceModeKey = 'settings.performance-mode';
export const libCirModeKey = 'settings.lib-cir-mode';

export interface EditorSliceState {
  /** current mode for the editor */
  editorMode: EditorMode;
  /** Whether or not we enable the performance mode */
  performanceMode: boolean;
  /** Whether or not we enable the library circuit */
  enableLibCir: boolean;
  /** Whether or not we are computing layer lidar gabarits */
  computingLayerLidarGabarits: boolean;
}

const REACT_APP_FEATURE_FLAG_LIBCIR = process.env.REACT_APP_FEATURE_FLAG_LIBCIR === 'true';

if (localStorage[libCirModeKey] !== undefined && !REACT_APP_FEATURE_FLAG_LIBCIR) {
  delete localStorage[libCirModeKey];
}

const initialState: EditorSliceState = {
  editorMode: 'circuit',
  performanceMode: localStorage[performanceModeKey] ? localStorage[performanceModeKey] === 'true' : false, // by default the performance mode is disabled
  enableLibCir: localStorage[libCirModeKey] ? localStorage[libCirModeKey] === 'true' : false, // by default the libCir mode is disabled
  computingLayerLidarGabarits: false,
};

const editorSlicePrivate = createSlice({
  initialState,
  name: 'editor',
  reducers: {
    setEditorMode: (state, action: PayloadAction<{ editorMode: EditorMode }>) => {
      state.editorMode = action.payload.editorMode;

      if (action.payload.editorMode === 'flow') {
        // if shapes have been deleted in circuit edition mode, we need to remove them from the stations
        setTimeout(() => deleteMissingPositionsInStations(), 0);
      }
    },
    setPerformanceMode: (state, action: PayloadAction<{ performanceMode: boolean }>) => {
      state.performanceMode = action.payload.performanceMode;
      localStorage[performanceModeKey] = action.payload.performanceMode;
    },
    setLibCirMode: (state, action: PayloadAction<{ enableLibCir: boolean }>) => {
      state.enableLibCir = action.payload.enableLibCir;
      localStorage[libCirModeKey] = action.payload.enableLibCir;
    },
    setComputingLayerLidarGabarits: (state, action: PayloadAction<{ computingLayerLidarGabarits: boolean }>) => {
      state.computingLayerLidarGabarits = action.payload.computingLayerLidarGabarits;
    },
  },
});

export const editorSlice = {
  reducer: editorSlicePrivate.reducer,
  name: editorSlicePrivate.name,
};

export const { setEditorMode, setPerformanceMode, setLibCirMode, setComputingLayerLidarGabarits } =
  editorSlicePrivate.actions;
