import { CircuitActionTypes } from 'actions/circuit';
import { RackActionTypes } from 'actions/racks';
import { connectRouter } from 'connected-react-router';
import { editorSlice } from 'editor/editor';
import { flowsSlice } from 'flows/flows';
import type { History } from 'history';
import { multiplayerSlice } from 'multiplayer/multiplayer';
import { projectSlice } from 'project/project';
import type { Reducer } from 'redux';
import { combineReducers } from 'redux';
import undoable from 'redux-undo';
import { robotsSlice } from 'robots/robots';
import { routesSlice } from 'routes/routes';
import { simulationSlice } from 'simulation/simulation';
import { triggersSlice } from 'simulation/triggers';
import { trafficSlice } from 'traffic/traffic';
import { StockZonesActionTypes } from './../actions/stock-zones';
import { authReducer } from './auth';
import { circuitReducer } from './circuit/reducer';
import { coreReducer } from './core';
import { dialogReducer } from './dialog';
import { localReducer } from './local/reducer';
import { mapsReducer } from './maps/reducer';
import type { AppState } from './state';
import { toolReducer } from './tools';

// eslint-disable-next-line import/no-anonymous-default-export
export default (history: History): Reducer<AppState> =>
  combineReducers<AppState>({
    router: connectRouter(history),
    auth: authReducer,
    core: coreReducer,
    dialog: dialogReducer,
    maps: mapsReducer,
    circuit: undoable(circuitReducer, {
      limit: 100,
      filter: (action: { type: string; payload: any }) => {
        if (
          ([RackActionTypes.Save, CircuitActionTypes.SegmentMoved, StockZonesActionTypes.Save].includes(action.type) &&
            action.payload.userAction) ||
          !action.payload?.userAction ||
          action.type === CircuitActionTypes.LoadCircuitFromYJS
        ) {
          return false;
        }

        return true;
      },
    }),
    tool: toolReducer,
    project: projectSlice.reducer,
    robots: robotsSlice.reducer,
    routes: routesSlice.reducer,
    traffic: trafficSlice.reducer,
    editor: editorSlice.reducer,
    multiplayer: multiplayerSlice.reducer,
    local: localReducer,
    simulation: simulationSlice.reducer,
    flows: flowsSlice.reducer,
    triggers: triggersSlice.reducer,
  });
