/**
 * Checks if a value is defined (not undefined and not null).
 *
 * @param {T | undefined | null} val - The value to check.
 * @returns {boolean} True if the value is defined, false otherwise.
 *
 * @link https://stackoverflow.com/a/62753258/1090255
 */
export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}

/**
 * Add the undefined type to a type.
 *
 * @param {T} val
 * @returns {T | undefined}
 */
export function canBeUndefined<T>(val: T): T | undefined {
  return val;
}
