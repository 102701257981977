import { change90DegSnapAction, openDialogAction, selectToolAction } from 'actions';
import {
  addMeasurerAction,
  addPointAction,
  addRackAction,
  addSegmentAction,
  addStockZoneAction,
  addZoneAction,
  clearShapesSelectionAction,
  deleteSelectedCircuitShapesAction,
  saveCircuitToHistoryAction,
  selectCircuitShapeAction,
} from 'actions/circuit';
import { saveMeasurerAction } from 'actions/measurers';
import { savePointAction } from 'actions/points';
import { saveSegmentAction } from 'actions/segment';
import { saveStockZoneAction } from 'actions/stock-zones';
import { saveZoneAction } from 'actions/zones';
import { findShapeOrientation, rotatePolygon2, svgCoordsToMathCoords } from 'drawings/helpers';
import type { LineString as LineStringGeoJSON, Point, Polygon, Position } from 'geojson';
import { DialogTypes } from 'models';
import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
  MeasurerProperties,
  Position3D,
  SegmentProperties,
  StockZoneProperties,
} from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { Lidar, MapImage, MapImageTiles } from 'models/maps';
import type { Tools } from 'models/tools';
import { startTransition } from 'react';
import { connect } from 'react-redux';
import {
  selectAllDevicesEntities,
  selectAllMeasurersEntities,
  selectAllNotesEntities,
  selectAllPointsEntities,
  selectAllRacksEntities,
  selectAllSegmentsEntities,
  selectAllStockZonesEntities,
  selectAllTurnsEntities,
  selectAllZonesEntities,
} from 'reducers/circuit/selectors';
import { selectSelectedShapeData } from 'reducers/local/selectors';
import { selectBackgroundLidar, selectForegroundLidar, selectMapImage, selectMapImageTiles } from 'reducers/maps';
import type { AppState } from 'reducers/state';
import { selectTool } from 'reducers/tools';
import type { Dispatch } from 'redux';
import { CircuitService, getShapeJustMoved, resetShapeJustMoved } from 'services/circuit.service';
import store from 'store';
import { Editor as EditorComponent } from './editor';

const epsilon = 0.0001;

export interface OnRectangleDrawnOptions {
  isConveyor?: boolean;
}

interface StateProps {
  backgroundLidar?: Lidar;
  foregroundLidar?: Lidar;
  image?: MapImage;
  imageTiles?: MapImageTiles;
  zones?: CircuitZone[];
  stockZones?: CircuitStockZone[];
  racks?: CircuitRack[];
  points?: CircuitPoint[];
  segments?: CircuitSegment[];
  measurers?: CircuitMeasurer[];
  turns?: CircuitTurn[];
  devices?: CircuitDevice[];
  notes?: CircuitNote[];
  selectedShapeIds: string[];
  activeTool?: Tools;
}

function mapStateToProps(state: AppState): StateProps {
  const backgroundLidar = selectBackgroundLidar(state);
  const foregroundLidar = selectForegroundLidar(state);
  const mapImage = selectMapImage(state);
  const mapImageTiles = selectMapImageTiles(state);
  const zones = selectAllZonesEntities(state);
  const stockZones = selectAllStockZonesEntities(state);
  const racks = selectAllRacksEntities(state);
  const points = selectAllPointsEntities(state);
  const segments = selectAllSegmentsEntities(state);
  const measurers = selectAllMeasurersEntities(state);
  const turns = selectAllTurnsEntities(state);
  const devices = selectAllDevicesEntities(state);
  const notes = selectAllNotesEntities(state);

  const selectedShapeData = selectSelectedShapeData(state);

  const tool = selectTool(state);

  return {
    backgroundLidar,
    foregroundLidar,
    image: mapImage,
    imageTiles: mapImageTiles,
    zones,
    stockZones,
    racks,
    points,
    segments,
    measurers,
    turns,
    devices,
    notes,
    selectedShapeIds: selectedShapeData.map((value) => value.id),
    activeTool: tool,
  };
}

interface DispatchProps {
  onShapeSelected: (selectedShapeId: string, selectedShapeType: ShapeTypes) => void;
  onShapeUnselected: (shapeId: string, unselectedShapeType: ShapeTypes) => void;
  onDeletionRequired: () => void;
  onShapesSelectionCleared: () => void;
  onRectangleDrawn: (type: string, points: [number, number][]) => void;
  onPointDrawn: (point: [number, number], angle: number) => void;
  onSegmentDrawn: (points: [number, number][]) => void;
  onMeasurerDrawn: (points: [number, number][]) => void;
  onShapeGeometryUpdated: (
    type: string,
    shapeId: string,
    geometry: Polygon | Point | LineStringGeoJSON,
    properties?: any
  ) => void;
  selectNewTool: (toolName: Tools) => void;
  change90DegSnap: (snap90deg: boolean) => void;
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    selectNewTool: (toolName: Tools) => dispatch(selectToolAction({ toolName })),
    change90DegSnap: (snap90deg: boolean) => dispatch(change90DegSnapAction({ snap90deg })),
    onShapeSelected: (selectedShapeId: string, selectedShapeType: ShapeTypes) => {
      // eslint-disable-next-line no-console
      console.warn('Useless code? To be deleted?');
    },
    onShapeUnselected: (shapeId: string, unselectedShapeType: ShapeTypes) => {
      // eslint-disable-next-line no-console
      console.warn('Useless code? To be deleted?');
    },
    onDeletionRequired: () => {
      startTransition(() => {
        dispatch(deleteSelectedCircuitShapesAction(true));
      });
    },
    onShapesSelectionCleared: () => {
      if (store.getState().local.selectedShapesData.length) dispatch(clearShapesSelectionAction());
    },
    onShapeGeometryUpdated: (
      type: string,
      shapeId: string,
      geometry: GeoJSON.GeometryObject,
      properties: GeoJSON.GeoJsonProperties
    ) => {
      if (type === ShapeTypes.ZoneShape && geometry.type === 'Polygon') {
        dispatch(saveZoneAction({ id: shapeId, geometry }));
      } else if (type === ShapeTypes.StockZoneShape && geometry.type === 'Polygon') {
        const shape = CircuitService.getShape(shapeId, type) as CircuitStockZone;
        // in addition to the geometry that we need to update, we also need to update the position of the slots
        if (shape) {
          const coords = shape.geometry.coordinates;
          const dx = geometry.coordinates[0][0][0] - coords[0][0][0];
          const dy = geometry.coordinates[0][0][1] - coords[0][0][1];

          const p1 = svgCoordsToMathCoords(geometry.coordinates[0][0] as [number, number]);
          const p2 = svgCoordsToMathCoords(geometry.coordinates[0][1] as [number, number]);
          const cap = findShapeOrientation(p1, p2);

          const properties = {
            ...shape.properties,
            slots: [...shape.properties.slots.map((s1) => ({ ...s1, slots: s1.slots.map((s2) => ({ ...s2 })) }))],
          } as StockZoneProperties;
          properties.cap = cap;
          const slots = properties.slots;

          slots
            .map((stockLine) =>
              stockLine.slots.map((slot) => {
                const slotPosition: Position3D = {
                  ...slot.slotPosition,
                  x: slot.slotPosition.x + dx,
                  y: slot.slotPosition.y - dy,
                  cap,
                };

                return {
                  ...slot,
                  slotPosition,
                };
              })
            )
            .forEach((slots, index) => (properties.slots[index].slots = slots));

          let minX = 1 / 0;
          let maxX = -1 / 0;
          let minY = 1 / 0;
          // let maxY = -1 / 0;
          for (let i = 0, slotLinesLastIndex = slots[0].slots.length - 1; i < slots.length; i++) {
            const firstSlotInLine = slots[i].slots[0];
            if (firstSlotInLine.slotPosition.x < minX) {
              minX = firstSlotInLine.slotPosition.x;
            }

            const lastSlotInLine = slots[i].slots[slotLinesLastIndex];
            const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + lastSlotInLine.slotSize.length * 100;
            if (maxXLastSlotInLine > maxX) {
              maxX = maxXLastSlotInLine;
            }
          }

          minY = -slots[0].slots[0].slotPosition.y;
          // maxY =
          //  -slots[slots.length - 1].slots[0].slotPosition.y - slots[slots.length - 1].slots[0].slotSize.width * 100;

          slots
            .map((stockLine) =>
              stockLine.slots.map((slot) => {
                const slotSize = slot.slotSize;
                const slotPosition = slot.slotPosition;

                let geometry: Position[] = [
                  [slotPosition.x, slotPosition.y],
                  [slotPosition.x + slotSize.length * 100, slotPosition.y],
                  [slotPosition.x + slotSize.length * 100, slotPosition.y + slotSize.width * 100],
                  [slotPosition.x, slotPosition.y + slotSize.width * 100],
                ];

                if (Math.abs(cap) > epsilon) {
                  geometry = rotatePolygon2([minX, -minY], geometry, cap);
                }

                return {
                  ...slot,
                  geometry,
                };
              })
            )
            .forEach((slots, index) => (properties.slots[index].slots = slots));

          dispatch(
            saveStockZoneAction({
              id: shapeId,
              geometry,
              properties,
            })
          );
        }
      } else if (type === ShapeTypes.PointShape && geometry.type === 'Point') {
        dispatch(savePointAction({ id: shapeId, geometry, properties }));
      } else if (type === ShapeTypes.SegmentShape && geometry.type === 'LineString' && properties) {
        dispatch(
          saveSegmentAction({
            id: shapeId,
            geometry,
            properties: properties as SegmentProperties,
            userAction: getShapeJustMoved() === shapeId,
          })
        );
      } else if (type === ShapeTypes.MeasurerShape && geometry.type === 'LineString' && properties) {
        dispatch(
          saveMeasurerAction({
            id: shapeId,
            geometry,
            properties: properties as MeasurerProperties,
            userAction: getShapeJustMoved() === shapeId,
          })
        );
      }

      resetShapeJustMoved();
    },
    onRectangleDrawn: (
      type: string,
      points: [number, number][],
      orientation = 0,
      options: OnRectangleDrawnOptions = {}
    ) => {
      if (type === ShapeTypes.ZoneShape) {
        dispatch(addZoneAction({ coord: points, zoneType: store.getState().tool.zoneType, userAction: true }));
      } else if (type === ShapeTypes.RackShape) {
        const nbRacks = store.getState().circuit.present.racks.ids.length;
        dispatch(
          addRackAction({
            coord: points,
            orientation,
            isConveyor: options?.isConveyor,
            userAction: true,
          })
        );

        const storeState = store.getState();
        if (storeState.circuit.present.racks.ids.length !== nbRacks) {
          // it means that a rack has been created
          dispatch(clearShapesSelectionAction());

          const newRackId = storeState.circuit.present.racks.ids[storeState.circuit.present.racks.ids.length - 1];

          dispatch(
            selectCircuitShapeAction({
              selectedShapeId: newRackId,
              selectedShapeType: ShapeTypes.RackShape,
              shape: storeState.circuit.present.racks.entities[newRackId],
            })
          );

          setTimeout(() => {
            dispatch(
              openDialogAction({
                type: DialogTypes.RackEdition,
                payload: undefined,
              })
            );
          }, 100);
        }
      } else if (type === ShapeTypes.StockZoneShape) {
        const nbStockZones = store.getState().circuit.present.stockZones.ids.length;
        dispatch(
          addStockZoneAction({ coord: points, stockZoneType: 'StockZone', orientation: -orientation, userAction: true })
        );

        const storeState = store.getState();
        if (storeState.circuit.present.stockZones.ids.length !== nbStockZones) {
          // it means that a stock zone has been created
          dispatch(clearShapesSelectionAction());

          const newStockZoneId =
            storeState.circuit.present.stockZones.ids[storeState.circuit.present.stockZones.ids.length - 1];

          dispatch(
            selectCircuitShapeAction({
              selectedShapeId: newStockZoneId,
              selectedShapeType: ShapeTypes.StockZoneShape,
              shape: storeState.circuit.present.stockZones.entities[newStockZoneId],
            })
          );

          setTimeout(() => {
            dispatch(
              openDialogAction({
                type: DialogTypes.StockZoneSettings,
                payload: undefined,
              })
            );
          }, 100);
        }
      }
    },
    onPointDrawn: (points: [number, number], angle: number) => {
      dispatch(saveCircuitToHistoryAction());
      dispatch(addPointAction({ coord: [points[0], points[1]], angle }));
    },
    onSegmentDrawn: (points: [number, number][]) => {
      dispatch(saveCircuitToHistoryAction());
      dispatch(addSegmentAction({ coord: points }));
    },
    onMeasurerDrawn: (points: [number, number][]) => {
      dispatch(saveCircuitToHistoryAction());
      dispatch(addMeasurerAction({ coord: points }));
    },
  };
}

export const Editor = connect(mapStateToProps, mapDispatchToProps)(EditorComponent);
