import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { PreferencesService, getPreferenceValue } from './preferences';

/**
 * Retrieves the device preference sample period name preference name based on the version of the NEW4X core.
 *
 * @returns {string} The device name property.
 */
export function getDeviceSamplePeriodPrefName(): string {
  const new4xVersion = PreferencesService.getNEW4XCoreVersion() ?? Infinity;
  const devicePrefProperty = new4xVersion >= 27 ? 'samplePeriod' : 'frequency';

  return devicePrefProperty;
}

/**
 * Checks if the fire alarm devices exist in the circuit.
 * If any fire alarm device is missing, a warning message is displayed.
 *
 * @returns {boolean} True if all fire alarm devices exist, otherwise false.
 */
export function checkFireAlarmsDeviceNames(): boolean {
  if (!PreferencesService.arePreferencesFullyLoaded()) return true;

  const new4xVersion = PreferencesService.getNEW4XCoreVersion() ?? Infinity;

  if (new4xVersion < 26) return true;

  try {
    const fireAlarmsDeviceNames = getPreferenceValue('fireAlarm/deviceNames');
    if (fireAlarmsDeviceNames && Array.isArray(fireAlarmsDeviceNames)) {
      const devicesIds = store.getState().circuit.present.devices.ids;
      const devices = store.getState().circuit.present.devices.entities;

      fireAlarmsDeviceNames.forEach((deviceName) => {
        const deviceId = devicesIds.find((id) => devices[id]?.properties.name === deviceName);
        if (!deviceId) {
          // eslint-disable-next-line no-console
          console.warn(`Fire alarm device ${deviceName} not found`);

          SnackbarUtils.warning(`Fire alarm device ${deviceName} not found, please check your configuration.`);
        }
      });
    }
  } catch (e) {}

  return true;
}
