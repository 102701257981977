/**
 * Take the seconds and return a string with the format HH:MM:SS
 * @param seconds the number of seconds
 * @returns the formatted string
 */
export function formatSecondsToHHMMSS(seconds: number): string {
  const hh = Math.floor(seconds / 3600);
  const mm = Math.floor((seconds % 3600) / 60);
  const ss = Math.floor((seconds % 3600) % 60);

  return `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`;
}

export function formatSecondsToHHhMM(seconds: number): string {
  const hh = Math.floor(seconds / 3600);
  const mm = Math.floor((seconds % 3600) / 60);

  return `${hh.toString()}h${mm.toString().padStart(2, '0')}`;
}

/**
 * Takes 2 dates and returns a string with the time difference between them in a human readable format
 * @param formerDate the former date
 * @param date the date to compare with the former date
 * @returns the string with the time difference between the 2 dates
 */
export function timeAgoString(formerDate: Date, date: Date): string {
  const differenceMilliseconds = date.getTime() - formerDate.getTime();
  const year = differenceMilliseconds / 1000 / 60 / 60 / 24 / 365;
  const month = (differenceMilliseconds / 1000 / 60 / 60 / 24 / 30) % 12;
  const day = (differenceMilliseconds / 1000 / 60 / 60 / 24) % 30;
  const hour = (differenceMilliseconds / 1000 / 60 / 60) % 24;
  const minute = (differenceMilliseconds / 1000 / 60) % 60;
  const second = (differenceMilliseconds / 1000) % 60;

  const differenceDateObject = {
    ...(year > 1 ? { year: Math.floor(year) } : {}),
    ...(month > 1 ? { month: Math.floor(month) } : {}),
    ...(day > 1 ? { day: Math.floor(day) } : {}),
    ...(hour > 1 ? { hour: Math.floor(hour) } : {}),
    ...(minute > 1 ? { minute: Math.floor(minute) } : {}),
    ...(second > 1 ? { second: Math.floor(second) } : {}),
  };

  let timeAgoString = '';
  let end = false;
  Object.entries(differenceDateObject).forEach(([key, value]) => {
    if (end) return;

    if (value > 0) {
      if (timeAgoString.length || key === 'second') {
        end = true;
      }

      timeAgoString += `${value} ${key}${value > 1 ? 's' : ''} ${
        timeAgoString.length || key === 'second' ? 'ago' : ''
      }`;
    }
  });

  return timeAgoString;
}

/**
 * Convert hours to seconds.
 *
 * @param hours The number of hours to convert.
 * @returns The equivalent number of seconds.
 */
export function hoursToSeconds(hours: number): number {
  return hours * 60 * 60;
}

/**
 * Convert seconds to hours.
 *
 * @param seconds The number of seconds to convert.
 * @returns The equivalent number of hours.
 */
export function secondsToHours(seconds: number): number {
  return seconds / 60 / 60;
}

/**
 * Formats a Date object into a string with the format "YYYY-MM-DD HH:MM:SS".
 *
 * @param {Date} time - The Date object to format.
 * @returns {string} A string representing the formatted date and time.
 */
export function formatTime(time: Date): string {
  const timeFormatted = `${time.getFullYear().toString().padStart(2, '0')}-${(time.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')} ${time
    .getHours()
    .toString()
    .padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;

  return timeFormatted;
}
