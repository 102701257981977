/**
 * Convert a cpx position to a position
 * cpx are juste strings like "cpx(x, y)" (x = abscissa, y = ordinate (complex number on a 2d plan))
 * @param strCpx the cpx string (cpx(2, 12) for example)
 * @param isInt do we convert the number to an int or to a float? default value = int
 * @returns the position
 */
export function cpxStrToPosition(strCpx: string, isInt = true): number[] {
  const pos = strCpx
    .split('(')[1]
    .replace(')', '')
    .split(',')
    .map((c) => (isInt ? parseInt(c, 10) : parseFloat(c)));

  let isValidCpx = pos.length === 2;
  pos.forEach((coord) => {
    if (isNaN(coord)) {
      isValidCpx = false;
    }
  });

  // eslint-disable-next-line no-console
  if (!isValidCpx) console.error(`Invalid cpx string: ${strCpx}`);

  return pos;
}
