import { z } from 'zod';

export const availableModels = ['reachy', 'veeny', 'lowy', 'lowy CB', 'tuggy', 'trucky'] as const;
export const AvailableModels = z.enum(availableModels);

export type AvailableModelsUnion = (typeof availableModels)[number];

/**
 * Battery types categorized by voltage.
 *
 * @property {string[]} 24V - Battery types for 24V
 * @property {string[]} 48V - Battery types for 48V
 * @property {string[]} 80V - Battery types for 80V
 *
 * @see {@link https://docs.google.com/spreadsheets/d/1-aLwhr0eRvi-e7qyORQaiUY0hRlFS3LJr6LF2DPgJt8/edit#gid=948337235}
 * @see {@link https://docs.google.com/spreadsheets/d/1MkjW7ygCbmKEifAiT6JzEiTSl5VTk7QTePL326FOA7A/edit#gid=789787036}
 */
export const batteryTypes = {
  '24V': [
    'LeadAcid_24V',
    'LTO_24V_Leclanche',
    'LTO_24V_Microvast',
    'TPPL_24V_Enersys',
    'NMC_24V_Enersys',
    'LFP_24V_OneCharge',
  ],
  '48V': ['LeadAcid_48V', 'LTO_48V_Leclanche', 'TPPL_48V_Enersys', 'NMC_48V_Enersys'],
  '80V': ['LeadAcid_80V', 'TPPL_80V_Enersys', 'NMC_80V_Enersys', 'LFP_80V_JB'],
};

export const batteryHardwares = {
  CAN: ['LeadAcid_24V', 'LeadAcid_48V', 'LeadAcid_80V'],
  Emrol: ['LTO_24V_Leclanche', 'LTO_48V_Leclanche', 'LTO_24V_Microvast'],
  NMC: ['NMC_24V_Enersys', 'NMC_48V_Enersys', 'NMC_80V_Enersys'],
  LFP: ['LFP_24V_OneCharge', 'LFP_80V_JB'],
  TPPL: ['TPPL_24V_Enersys', 'TPPL_48V_Enersys', 'TPPL_80V_Enersys'],
} as const;
