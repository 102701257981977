import { saveCircuitToHistoryAction } from 'actions/circuit';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import type { CustomEventParameters } from 'd3';
import * as d3 from 'd3';
import type { PointDrawingProperties } from 'drawings/editor-elements';
import { offsetPosition, offsetPositionArray, offsetPositionLine } from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import type { FeatureCollection, GeoJsonProperties, LineString, Point, Polygon } from 'geojson';
import { ShapeTypes } from 'models/circuit';
import { awareness, isShapeInSelectedShapes, localDoc } from 'multiplayer/globals';
import store from 'store';

export interface OnDragOptions {
  isEndPoint?: boolean;
}

export abstract class Shape<T extends Polygon | Point | LineString | FeatureCollection = any> {
  public properties: GeoJsonProperties = {};
  public geometry: T;
  protected projection: d3.GeoPath<T, d3.GeoPermissibleObjects>;
  protected zoomScale: number;
  private isDragging = false;
  public readonly id: string;
  public readonly type: ShapeTypes;
  public node: SimpleSelection<SVGGElement, undefined>;
  private onClickListener: ((shapeId: string, selectedShapeType: ShapeTypes) => void) | undefined;
  private onUnselectListener: ((shapeId: string, unselectedShapeType: ShapeTypes) => void) | undefined;
  protected active = false;
  protected onGeometryUpdatedListener:
    | ((type: string, shapeId: string, geometry: T, properties?: any) => void)
    | undefined;

  protected bbox: GeoJSON.BBox = [0, 0, 0, 0, 0, 0];
  protected drawTextRef: (active?) => void;
  protected updateShapeRef: () => void;
  public locked = false;
  protected drawingProperties: PointDrawingProperties | undefined;

  constructor(
    id: string,
    type: ShapeTypes,
    geometry: T,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    drawingProperties?: PointDrawingProperties
  ) {
    this.id = id;
    this.type = type;
    this.onDragStart = this.onDragStart.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.callOnClickListener = this.callOnClickListener.bind(this);
    this.geometry = { ...geometry };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.projection = projection;
    this.zoomScale = zoomScale;
    this.drawingProperties = drawingProperties;
    this.node = this.createNode();
    this.drawNameText();
    this.enableStyle();
    this.drawTextRef = this.drawNameText;
    this.updateShapeRef = this.updateShape;
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    const dragHandler = d3
      .drag<SVGGElement, undefined>()
      .on('drag', this.onDrag)
      .on('start', this.onDragStart)
      .on('end', this.onDragEnd);

    const node = d3
      .create<SVGGElement>('svg:g')
      .attr('uuid', this.id)
      .attr('type', this.type)
      .attr('width', '100%')
      .attr('height', '100%')
      .style('stroke-width', '0px')
      .call(dragHandler);

    node
      .on('click', this.callOnClickListener)
      .on('mouseenter', () => this.hover())
      .on('mouseleave', () => this.hover(false))
      .on('translate', () => this.translate())
      .on('translateEnd', () => this.translateEnd());

    node
      .selectAll('path')
      .data([this.geometry])
      .enter()
      .append('path')
      .attr('d', this.projection as any)
      .attr('main-shape', true)
      .exit();

    return node;
  }

  protected enableStyle(active = false): void {
    this.node.classed('active', active);
    this.drawNameText(active);
  }

  protected setLocked(locked = false): void {
    this.locked = locked;

    this.node.classed('locked', locked);
  }

  protected callOnClickListener(): void {
    if (!this.isDragging && this.onClickListener && this.onUnselectListener) {
      // If dragging, a click event may be dispatched but we don't want
      if (this.active) {
        this.onUnselectListener(this.id, this.type);
      } else {
        this.onClickListener(this.id, this.type);
      }
    }
  }

  public setZoomScale(zoomScale: number): void {
    this.zoomScale = zoomScale;
  }

  public onGeometryUpdate(listener: (type: string, shapeId: string, geometry: T, properties?: any) => void): void {
    this.onGeometryUpdatedListener = listener;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected callOnGeometryUpdateListener(properties?: any): void {
    if (this.onGeometryUpdatedListener) {
      this.onGeometryUpdatedListener(this.type, this.id, { ...this.geometry }, properties);
    }
  }

  public onSelect(func: (shapeId: string, selectedShapeType: ShapeTypes) => void): void {
    this.onClickListener = func;
  }

  public onUnselect(func: (shapeId: string, unselectedShapeType: ShapeTypes) => void): void {
    this.onUnselectListener = func;
  }

  public hover(hover = true): void {
    if (!this.active) {
      this.enableStyle(hover);
    }
  }

  public setActive(active = true): void {
    this.active = active;
    this.enableStyle(active);
  }

  /**
   * Function trigged when a shape is dragged
   * @returns void
   */
  protected onDrag(opts?: OnDragOptions): void {
    // locked shapes are not draggeable
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    if (awareness && !store.getState().local.selectedShapesData.find((shape) => shape.id === this.id)) {
      // if the shape is not selected, we select it
      const localSelectedShapes = store.getState().local.selectedShapesData.map((shape) => shape.id);
      localSelectedShapes.push(this.id);
      localDoc.getMap('selectedShapes').set(awareness.clientID.toString(), localSelectedShapes);
      syncYJSLocalToRemote();
    }

    this.isDragging = true;
    if (this.drawingProperties?.refreshGeometryOnDrag !== false) {
      this.refreshGeometry();
    }

    // when we drag a shape, we may want to drag the whole selection and not just the shape
    const event = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    if (event) {
      if (opts?.isEndPoint) {
        return; // we don't want to move the whole selection if we drag an end point
      }

      const storeState = store.getState();
      const selectedElements = storeState.local.selectedShapesData;
      const isShapeSelected = !!selectedElements.find((shape) => shape.id === this.id);

      // if the dragged shape is in the selection, then we drag the whole selection #34648
      if (isShapeSelected) {
        const selectedElementsWithoutShapeAndTurns = selectedElements
          .filter((shape) => shape.type !== ShapeTypes.TurnShape) // we remove the turn from the list because we don't want to drag turns
          .filter((shape) => shape.id !== this.id); // we remove the dragged shape from the list because we don't want to drag it twice

        const isThisASegment = this.properties?.type === ShapeTypes.SegmentShape;

        selectedElementsWithoutShapeAndTurns.forEach((shape) => {
          if (isThisASegment && shape.type === ShapeTypes.PointShape) {
            const point = storeState.circuit.present.points.entities[shape.id];
            if (!point) {
              // eslint-disable-next-line no-console
              console.error(`Point ${shape.id} not found in store`);

              return;
            }

            if (point.properties?.segment?.id === this.id) {
              return; // we don't want to move the point twice
            }
          }

          d3.select(`[uuid='${shape.id}']`).dispatch('translate', {
            detail: { dx: event.dx, dy: event.dy },
          } as CustomEventParameters);
        });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected onDragEnd(properties?: any): void {
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    if (awareness && !store.getState().local.selectedShapesData.find((shape) => shape.id === this.id)) {
      const localSelectedShapes = store.getState().local.selectedShapesData.map((shape) => shape.id);
      if (localSelectedShapes.includes(this.id)) return;

      const index = localSelectedShapes.indexOf(this.id);
      localSelectedShapes.splice(index, 1);

      localDoc.getMap('selectedShapes').set(awareness.clientID.toString(), localSelectedShapes);
      syncYJSLocalToRemote();
    }

    this.geometry = { ...this.geometry, coordinates: [...(this.geometry as Point | Polygon | LineString).coordinates] };
    this.isDragging = false;

    const event = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    if (event) {
      const selectedElements = store.getState().local.selectedShapesData.filter((shape) => shape.id !== this.id);
      selectedElements.forEach((shape) => {
        if (shape.type === ShapeTypes.PointShape) {
          const point = store.getState().circuit.present.points.entities[shape.id];
          if (!point) {
            // eslint-disable-next-line no-console
            console.error(`Point ${shape.id} not found in store`);

            return;
          }

          if (point.properties?.segment?.id === this.id) {
            return; // we don't want to move the point twice
          }
        }

        d3.select(`[uuid='${shape.id}']`).dispatch('translateEnd');
      });
    }

    this.callOnGeometryUpdateListener(properties);
  }

  protected onDragStart(): void {
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    store.dispatch(saveCircuitToHistoryAction());

    this.isDragging = true;

    const rootNode = d3.select('#root-node-svg').node() as SVGSVGElement | undefined;
    if (!rootNode) return;
    const unscaledMousePosition = d3.mouse(rootNode);

    rootNode.dispatchEvent(
      new CustomEvent('click', {
        detail: {
          unscaledMousePosition,
        },
      })
    );
  }

  protected refreshGeometry(): void {
    this.node
      .select('path[main-shape]')
      .data([this.geometry])
      //.enter()
      //.append('path')
      .attr('d', this.projection as any)
      .exit();
    this.drawNameText();
  }

  protected translate(): void {
    if (!this.geometry) return;
    if (!this.draggeable()) return;

    const event = d3.event as d3.D3DragEvent<SVGGElement, any, any> & { detail: { dx: number; dy: number } };
    const dx = event.detail.dx;
    const dy = event.detail.dy;

    switch (this.geometry.type) {
      case 'Point':
        this.geometry.coordinates = offsetPosition(this.geometry.coordinates, dx, dy);
        break;
      case 'LineString':
        this.geometry.coordinates = offsetPositionLine(this.geometry.coordinates, dx, dy);
        break;
      case 'Polygon':
        this.geometry.coordinates = offsetPositionArray(this.geometry.coordinates, dx, dy);
        break;
    }

    this.updateShape();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected translateEnd(): void {}

  protected drawNameText(active?: boolean): void {
    if (this.drawTextRef !== this.drawNameText && typeof this.drawTextRef === 'function') this.drawTextRef(active);
  }

  protected updateShape(): void {
    this.refreshGeometry();

    if (this.updateShapeRef !== this.updateShape && typeof this.updateShapeRef === 'function') this.updateShapeRef();
  }

  protected draggeable(): boolean {
    if (isShapeInSelectedShapes(this.id)) return false;

    return !this.locked;
  }
}
