import * as d3 from 'd3';
import { setComputingLayerLidarGabarits } from 'editor/editor';
import { generateGabaritsLayer } from 'editor/generate-gabarits-layer';
import type { ShapeTypes } from 'models/circuit';
import type { Lidar } from 'models/maps';
import { LidarPosition } from 'models/maps';
import { Tools } from 'models/tools';
import { getLidarBounds } from 'services/maps.service';
import store from 'store';
import { BaseDrawing } from './base.drawing';
import { DrawLayer } from './draw.layer';
import { CircuitLayer, FloorPlanLayer, LayerNames, LidarLayer } from './layers';

export class EditorDrawing extends BaseDrawing {
  public activeTool: Tools = Tools.Move;

  constructor(
    container: HTMLDivElement,
    onRectangleDrawn: (type: string, points: [number, number][]) => void,
    onSegmentDrawn: (points: [number, number][]) => void,
    onMeasurerDrawn: (points: [number, number][]) => void,
    onPointDrawn: (point: [number, number], angle: number) => void,
    onShapeSelected: (selectedShapeId: string, selectedShapeType: ShapeTypes) => void,
    onShapeUnselected: (unselectedShapeId: string, unselectedShapeType: ShapeTypes) => void,
    onShapesSelectionCleared: () => void
  ) {
    super(container);

    this.addLayer(new FloorPlanLayer());
    this.addLayer(new LidarLayer(LidarPosition.Background));
    this.addLayer(new LidarLayer(LidarPosition.Foreground));
    this.addLayer(new CircuitLayer(onShapeSelected, onShapeUnselected));
    this.addOverlay(new DrawLayer(onRectangleDrawn, onSegmentDrawn, onMeasurerDrawn, onPointDrawn));
    this.createClickToUnselectCallback(onShapesSelectionCleared);
  }

  private createClickToUnselectCallback(onShapesSelectionCleared: () => void): void {
    this.rootNode.on('click', () => {
      if (d3.event.ctrlKey) return;

      onShapesSelectionCleared();
    });
  }

  public setActiveTool(toolName: Tools): void {
    const drawLayer = this.getLayer<DrawLayer>(LayerNames.Draw);

    drawLayer.setActiveTool(toolName);
  }

  public drawLidar(
    lidar: Lidar,
    position: LidarPosition,
    opacity = 100,
    updateOpacity = false,
    displayCollisionPolygonLayerId?: string
  ): void {
    const lidarMapping = {
      [LidarPosition.Foreground]: LayerNames.ForegroundLidar,
      [LidarPosition.Background]: LayerNames.BackgroundLidar,
    };
    const lidarLayer = this.getLayer<LidarLayer>(lidarMapping[position]);

    if (displayCollisionPolygonLayerId) {
      const layer = store.getState().circuit.present.layers.layers[displayCollisionPolygonLayerId];
      const gabaritName = layer.defaultPattern;
      const modelName = layer.defaultModel;

      if (gabaritName && modelName) {
        store.dispatch(
          setComputingLayerLidarGabarits({
            computingLayerLidarGabarits: true,
          })
        );

        generateGabaritsLayer({
          gabaritName,
          modelName,
          displaySnackbar: true,
          layerId: layer.id,
          returnMergedGabarit: true,
        }).then((gabaritsLayerRes) => {
          const mergedPolygon = gabaritsLayerRes?.mergedPolygon;

          store.dispatch(
            setComputingLayerLidarGabarits({
              computingLayerLidarGabarits: false,
            })
          );

          if (mergedPolygon) {
            lidarLayer.setPoints(lidar, opacity, false, {
              geometry: mergedPolygon.geometry,
            });
          }
        });
      }
    }

    if (!displayCollisionPolygonLayerId) {
      lidarLayer.setPoints(lidar, opacity, !updateOpacity);

      const { minX, maxY, width, height } = getLidarBounds(lidar.coordinates);
      setTimeout(() => {
        const windowRect = this.rootNode.node()?.getBoundingClientRect();

        if (windowRect && !updateOpacity) {
          const heightScale = windowRect.bottom / height;
          const widthScale = windowRect.right / width;

          const scale = heightScale > widthScale ? widthScale : heightScale;

          this.zoomTo(minX, maxY, scale);
        }
      }, 0);
    }
  }

  public centerZoomToBackground(): void {
    const lidarData = store.getState().maps.lidar['background-lidar'];
    const mapImages = store.getState().maps.mapImage.mapImages;
    const mapImageTiles = store.getState().maps.mapImageTiles.mapImageTilesData?.tiles[32];

    const windowRect = this.rootNode.node()?.getBoundingClientRect();

    if (lidarData) {
      const { minX, maxY, width, height } = getLidarBounds(lidarData.coordinates);

      if (windowRect) {
        const heightScale = windowRect.bottom / height;
        const widthScale = windowRect.right / width;

        const scale = heightScale > widthScale ? widthScale : heightScale;

        this.zoomTo(minX, maxY, scale);
      }
    } else if (mapImageTiles) {
      const tilesPixelSize = store.getState().maps.mapImageTiles.mapImageTilesData?.tilePixelSize;

      if (windowRect) {
        if (tilesPixelSize) {
          // 4 tiles for the total width and 2 for the height
          const fullImgWidth = tilesPixelSize * 32 * 4;
          const fullImgHeight = tilesPixelSize * 32 * 2;

          const heightScale = windowRect.bottom / fullImgHeight;
          const widthScale = windowRect.right / fullImgWidth;

          const scale = heightScale > widthScale ? widthScale : heightScale;

          this.zoomTo(mapImageTiles[0].x * 100, -mapImageTiles[0].y * 100, scale);
        }
      }
    } else if (mapImages && mapImages.length > 0) {
      const mapImage = mapImages[0];

      if (windowRect) {
        if (mapImage.height) {
          //Calculate the width based on the coefficient which allowed to have the height
          const width = mapImage.originalWidth * (mapImage.height / mapImage.originalHeight);

          const heightScale = windowRect.bottom / mapImage.height;
          const widthScale = windowRect.right / width;

          const scale = heightScale > widthScale ? widthScale : heightScale;

          this.zoomTo(mapImage.x, mapImage.y, scale);
        }
      }
    } else {
      this.zoomTo(0, 0, 1);
    }
  }

  public getCircuitLayer(): CircuitLayer {
    return this.getLayer<CircuitLayer>(LayerNames.Circuit);
  }
}
