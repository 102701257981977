import { Avatar, Tooltip } from '@mantine/core';
import { Box } from '@mui/system';
import { awareness, localDoc } from 'multiplayer/globals';
import { setFollowUser } from 'multiplayer/multiplayer';
import { useCallback, useState } from 'react';
import type { UserProfileData } from 'shared';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { useUsers } from 'y-presence';
import type { UserPresence } from '../../multiplayer/types';

export default function Users(): JSX.Element {
  if (!awareness) throw new Error('Missing awareness');

  const users = useUsers(awareness) as Map<number, UserPresence>;
  const dispatch = useAppDispatch();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const followClientId = useAppSelector((state) => state.multiplayer.followClientId);
  const render2Enabled = useAppSelector((state) => state.editor.enableLibCir);

  // When the user clicks on an avatar, follow the user
  const handleAvatarClick = useCallback(
    (clientId: number): void => {
      if (followClientId === clientId) return;

      dispatch(setFollowUser({ clientId }));

      if (render2Enabled) {
        const canvas = document.getElementsByTagName('canvas')[0];
        if (!canvas) return;

        canvas.style.outline = `8px solid ${users.get(clientId)?.c}`;
        canvas.style.outlineOffset = '-8px';
      } else {
        const editorView = document.getElementById('editorView');
        if (!editorView) return;

        const translateContainer = document.querySelector('.translate-container') as HTMLElement;
        const zoomContainer = document.querySelector('.zoom-container') as HTMLElement;

        if (!translateContainer || !zoomContainer) return;
        editorView.style.outline = `8px solid ${users.get(clientId)?.c}`;
      }
    },

    [users, followClientId, render2Enabled, dispatch]
  );

  const maxInlineAvatarIndex = 4;

  const profilesMap = localDoc.getMap<UserProfileData>('profiles');
  const inactiveMap = localDoc.getMap('inactive');

  return (
    <Tooltip.Group closeDelay={100}>
      <Avatar.Group spacing="sm">
        {Array.from(users.entries()).map(([key, value], index) => {
          /* When there are more than 5 users in the room, group the 4th and in a tooltip */
          if (index > maxInlineAvatarIndex) return null;
          if (index === maxInlineAvatarIndex && users.size > maxInlineAvatarIndex + 1) {
            return (
              <Tooltip
                key={`tooltip-${index}`}
                opened={tooltipOpened}
                withArrow
                style={{
                  pointerEvents: 'unset',
                  cursor: 'pointer',
                  padding: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '4px',
                }}
                label={
                  <>
                    {Array.from(users.entries())
                      .slice(4)
                      .map(([key, value]) => {
                        /* Change text color for it to contrast with the background */
                        const textColor = theme.palette.getContrastText(value.c);

                        const profile = profilesMap.get(key.toString());
                        const inactive = inactiveMap.has(key.toString());

                        if (!profile) return null;

                        return (
                          <Box
                            component="div"
                            key={index}
                            sx={{
                              color: textColor,
                              backgroundColor: value.c,
                              padding: '5px 10px',
                              borderRadius: '4px',
                              ...(inactive && {
                                opacity: 0.5,
                              }),
                            }}
                            onClick={() => {
                              handleAvatarClick(key);
                            }}
                          >
                            {profile.name}
                          </Box>
                        );
                      })}
                  </>
                }
                zIndex={1100}
              >
                <Avatar
                  key={`avatar-${index}`}
                  radius="xl"
                  onClick={() => setTooltipOpened((prevState) => !prevState)}
                  onBlur={() => setTooltipOpened(false)}
                  tabIndex={index}
                  style={{
                    ...(followClientId &&
                      Array.from(users.keys()).slice(maxInlineAvatarIndex).includes(followClientId) && {
                        borderWidth: '3px',
                        borderColor: users.get(followClientId)?.c,
                      }),
                  }}
                >
                  +{users.size - 4}
                </Avatar>
              </Tooltip>
            );
          }

          /* Change text color for it to contrast with the background */
          const textColor = theme.palette.getContrastText(value.c);

          const profile = profilesMap.get(key.toString());
          const inactive = inactiveMap.has(key.toString());

          if (!profile) return null;

          return (
            <Tooltip
              key={`tooltip-${index}`}
              label={index === 0 ? `${profile.name} (you)` : profile.name}
              color={value.c}
              withArrow
              style={{
                color: textColor,
              }}
              zIndex={1100}
            >
              <Avatar
                key={`avatar-${index}`}
                alt={profile.name}
                src={profile.picture}
                tabIndex={index}
                style={{
                  ...(inactive && {
                    opacity: 0.5,
                  }),
                  ...(key === followClientId && {
                    borderColor: users.get(followClientId)?.c,
                    borderWidth: '3px',
                  }),
                }}
                imageProps={{
                  /**
                   * referrerPolicy added because otherwise sometimes Google sends a 403 instead of the image
                   * link: https://stackoverflow.com/questions/56242788/http-403-on-images-loaded-from-googleusercontent-com
                   */
                  referrerPolicy: 'no-referrer',
                  /**
                   * Required by the cross-origin-embedder-policy: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cross-Origin-Embedder-Policy
                   */
                  crossOrigin: 'anonymous',
                }}
                radius="xl"
                onClick={
                  key === awareness?.clientID
                    ? undefined
                    : () => {
                        handleAvatarClick(key);
                      }
                }
              />
            </Tooltip>
          );
        })}
      </Avatar.Group>
    </Tooltip.Group>
  );
}
