import { Elevator, Layers } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  Input,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  type SelectChangeEvent,
} from '@mui/material';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { Intersection } from 'models/circuit';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import type { ZoneFormValues } from '../zone-properties/zone-properties';

interface ElevatorOptionsProps {
  setFormValues: React.Dispatch<React.SetStateAction<ZoneFormValues>>;
  setAskUpdateZone: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeFormValue: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<Intersection>
      | SelectChangeEvent<number>,
    fieldName: keyof ZoneFormValues,
    roundValue?: boolean
  ) => void;
  handleBlurFormValue: (e: React.FocusEvent) => void;
  handleKeyPressFormValue: (e: React.KeyboardEvent) => void;
  formValues: ZoneFormValues;
  locked: boolean;
}

export function ElevatorOptions({
  setFormValues,
  setAskUpdateZone,
  handleChangeFormValue,
  handleBlurFormValue,
  handleKeyPressFormValue,
  formValues,
  locked,
}: ElevatorOptionsProps): JSX.Element {
  const handleFloorChange = useCallback(
    (e): void => {
      setFormValues((state) => {
        const newState: ZoneFormValues = {
          ...state,
          intersectionType: Intersection.PointIntersection,
          floor: !state.floor,
          floorLevel: undefined,
        };

        return newState;
      });

      setAskUpdateZone(true);
    },
    [setAskUpdateZone, setFormValues]
  );

  const handleElevatorChange = useCallback(
    (e): void => {
      setFormValues((state) => {
        const newState: ZoneFormValues = {
          ...state,
          intersectionType: Intersection.PointIntersection,
          elevator: !state.elevator,
          elevatorName: undefined,
          limitRobotCountEnabled: !state.elevator,
        };

        return newState;
      });

      setAskUpdateZone(true);
    },
    [setAskUpdateZone, setFormValues]
  );

  const handleChangeVirtualFloorLevel = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setFormValues((state) => {
        const newFloorLevel = e.target.checked ? 'virtual' : undefined;

        return { ...state, floorLevel: newFloorLevel };
      });

      setAskUpdateZone(true);
    },
    [setAskUpdateZone, setFormValues]
  );

  return (
    <>
      <ListItemButton disabled={locked} onClick={handleFloorChange}>
        <ListItemIcon>
          <Layers />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-floor"
          primary={
            <>
              Floor <HelpIconTooltip title="The zone will be associated to a floor level." />
            </>
          }
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            checked={formValues.floor}
            inputProps={{ 'aria-labelledby': 'switch-list-label-floor' }}
            disabled={locked}
            tabIndex={-1}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
      <Collapse in={formValues.floor} timeout="auto" unmountOnExit>
        <Border>
          <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
            Virtual floor level
            <Switch
              checked={formValues.floorLevel?.toString().includes('virtual')}
              onChange={handleChangeVirtualFloorLevel}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true} error={!formValues.floorLevel}>
              Floor level
            </InputLabel>
            <Input
              value={formValues.floorLevel}
              onChange={(e) => handleChangeFormValue(e, 'floorLevel', true)}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              error={!formValues.floorLevel}
              inputProps={{
                lang: 'en',
                'aria-label': 'Elevator name',
              }}
              disabled={locked || formValues.floorLevel?.toString().includes('virtual')}
              type="number"
            />
          </FormControl>
        </Border>
      </Collapse>

      <ListItemButton disabled={locked} onClick={handleElevatorChange}>
        <ListItemIcon>
          <Elevator />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-elevator"
          primary={
            <>
              Elevator{' '}
              <HelpIconTooltip title="The zone will be associated to an elevator. Elevators must be on a floor type zone. Only one robot is allowed per elevator." />
            </>
          }
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            checked={formValues.elevator}
            inputProps={{ 'aria-labelledby': 'switch-list-label-elevator' }}
            disabled={locked}
            tabIndex={-1}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
      <Collapse in={formValues.elevator} timeout="auto" unmountOnExit>
        <Border>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true} error={!formValues.elevatorName}>
              Elevator name{' '}
              <HelpIconTooltip title='The elevator name should match the elevator names provided in the "elevatorConfiguration.json" file' />
            </InputLabel>
            <Input
              value={formValues.elevatorName}
              onChange={(e) => handleChangeFormValue(e, 'elevatorName')}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              error={!formValues.elevatorName}
              inputProps={{
                lang: 'en',
                'aria-label': 'Elevator name',
              }}
              disabled={locked}
            />
          </FormControl>
        </Border>
      </Collapse>
    </>
  );
}
