import { ShapeTypes, SlotTypes } from 'models/circuit';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe/assert';
import type { Station, StationPosition, StationPositionWithName } from './flows';

/**
 * Retrieves all slot IDs of a given station or array of station positions.
 *
 * This function iterates over the provided station or positions and adds the slot IDs
 * to a Set to ensure uniqueness. It handles different types of positions such as
 * PointShape, Slot, StockLine, and StockZoneShape by checking the type and retrieving
 * the corresponding IDs from the store's state.
 *
 * @param {Station | StationPositionWithName[]} stationOrPositions - The station or array of positions to retrieve slots from.
 * @returns {Set<string>} A set containing unique slot IDs.
 */
export function getAllSlotsOfStation(
  stationOrPositions: Station | (StationPositionWithName | StationPosition)[]
): Set<string> {
  const storeState = store.getState();

  const positions = 'positions' in stationOrPositions ? stationOrPositions.positions : stationOrPositions;

  const slotsIds = new Set<string>();

  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];

    if (position.type === ShapeTypes.PointShape) {
      slotsIds.add(position.id);
    } else if (position.type === SlotTypes.Slot) {
      slotsIds.add(position.id);
    } else if (position.type === SlotTypes.StockLine) {
      const stockZonesIds = storeState.circuit.present.stockZones.ids;
      const stockZones = storeState.circuit.present.stockZones.entities;

      for (let j = 0; j < stockZonesIds.length; j++) {
        const stockZoneId = stockZonesIds[j];
        const stockZone = stockZones[stockZoneId];

        const stockLines = stockZone.properties.slots;

        for (let k = 0; k < stockLines.length; k++) {
          const stockLine = stockLines[k];

          if (stockLine.id === position.id) {
            slotsIds.add(stockLine.id);
          }
        }
      }
    } else if (position.type === ShapeTypes.StockZoneShape) {
      const sockZonesIds = storeState.circuit.present.stockZones.ids;
      const stockZones = storeState.circuit.present.stockZones.entities;

      for (let j = 0; j < sockZonesIds.length; j++) {
        const stockZoneId = sockZonesIds[j];
        const stockZone = stockZones[stockZoneId];

        if (stockZone.id === position.id) {
          stockZone.properties.slots.forEach((stockLine) => slotsIds.add(stockLine.id));
          break;
        }
      }
    } else if (position.type === ShapeTypes.RackShape) {
      const racksIds = storeState.circuit.present.racks.ids;
      const racks = storeState.circuit.present.racks.entities;

      for (let j = 0; j < racksIds.length; j++) {
        const rackId = racksIds[j];
        const rack = racks[rackId];

        if (rack.id === position.id) {
          rack.properties.columns.forEach((column) =>
            column.cells.forEach((cell) => {
              if (cell.disabled) return;

              cell.names.forEach((slots) => slots.forEach((slot) => !slot.disabled && slotsIds.add(slot.id)));
            })
          );
          break;
        }
      }
    } else {
      assert<Equals<typeof position.type, never>>();
    }
  }

  return slotsIds;
}
