import { z } from 'zod';

export const EditorModeSchema = z.enum(['circuit', 'traffic', 'flow']);
export type EditorMode = z.infer<typeof EditorModeSchema>;

export function isEditorMode(value: unknown): value is EditorMode {
  return EditorModeSchema.safeParse(value).success;
}

export const RecentProjectTypeSchema = z.object({
  name: z.string(),
  lastOpen: z.string(),
});
export type RecentProjectType = z.infer<typeof RecentProjectTypeSchema>;

export const RecentProjectTypesSchema = z.array(RecentProjectTypeSchema);
export type RecentProjectTypes = z.infer<typeof RecentProjectTypesSchema>;

export function isRecentProjectTypes(value: unknown): value is RecentProjectTypes {
  return RecentProjectTypesSchema.safeParse(value).success;
}
