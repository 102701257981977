import { Button } from '@mui/material';
import type { CircuitSegment } from 'models/circuit';
import { useCallback } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { computeMidSegmentAisleAndAddToCircuit } from 'utils/circuit/compute-aisles';

interface UpdateMiddleAisleSegmentBtnProps {
  segment: CircuitSegment;
}

export function UpdateMiddleAisleSegmentBtn(props: UpdateMiddleAisleSegmentBtnProps): JSX.Element {
  const { segment } = props;

  const handleClickUpdateMiddleAisleSegment = useCallback(() => {
    if (typeof segment.id !== 'string') return;

    const updatedSegmentNb = computeMidSegmentAisleAndAddToCircuit({
      segmentsIdsToUpdate: [segment.id],
    });

    if (updatedSegmentNb !== 1) {
      // eslint-disable-next-line no-console
      console.error(
        `Error updating middle aisle segment for zone with ID: ${segment.id}. Expected to update 1 segment, but updated ${updatedSegmentNb} segments.`
      );
      SnackbarUtils.error('Error while updating middle aisle segment');

      return;
    }

    SnackbarUtils.success('Middle aisle segment updated');
  }, [segment.id]);

  if (!segment.properties.aisle?.aisleId) {
    return <></>;
  }

  return (
    <Button onClick={handleClickUpdateMiddleAisleSegment} variant="outlined" disabled={segment.properties.locked}>
      Update Middle Aisle Segment
    </Button>
  );
}
