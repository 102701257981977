import { Card, CardContent, CardHeader } from '@mui/material';
import { isValidElement, useMemo } from 'react';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import { theme } from 'utils/mui-theme';

interface DisplaySchedulerConfigProps {
  schedulerConfigName: string;
  schedulerConfig: SchedulerConfig;
}

export function DisplaySchedulerConfig(props: DisplaySchedulerConfigProps): JSX.Element {
  const { schedulerConfigName, schedulerConfig } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <CardHeader
        title={`Scheduler configuration`}
        subheader={schedulerConfigName}
        subheaderTypographyProps={{
          sx: {
            textTransform: 'capitalize',
          },
        }}
      />
      <CardContent
        sx={{
          paddingTop: 0,
        }}
      >
        <JSONAsTable json={schedulerConfig} />
      </CardContent>
    </Card>
  );
}

interface JSONAsTableProps {
  json: Record<string, unknown>;
}
function JSONAsTable(props: JSONAsTableProps): JSX.Element {
  const { json } = props;

  const keys = useMemo(() => Object.keys(json), [json]);

  return (
    <ul
      style={{
        marginTop: 0,
      }}
    >
      {keys.map((key) => {
        const value = json[key];

        const valueOrJson =
          typeof value === 'object' && value ? <JSONAsTable json={value as Record<string, unknown>} /> : value;
        const content =
          (valueOrJson && typeof valueOrJson === 'object' && isValidElement(valueOrJson)) ||
          typeof valueOrJson === 'string' ||
          typeof valueOrJson === 'number'
            ? valueOrJson
            : '';

        return (
          <li key={key}>
            {key}: {content}
          </li>
        );
      })}
    </ul>
  );
}
