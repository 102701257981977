import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system';
import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

type AvailableAnimations = 'shake' | 'turn-on-itself' | 'turn-on-itself-reverse' | 'flash' | 'zoom-and-rotate';
interface AnimatedIconProps {
  children: React.ReactNode;
  defaultAnimation?: AvailableAnimations;
  /** animation duration [ms] */
  animationDuration?: number;
  animationTimingFunction?: string;
  animateRef?: MutableRefObject<((anim?: any) => void) | undefined>;
  triggerOnClick?: boolean;
  triggerOnAppear?: boolean;
  sx?: SxProps<Theme> | undefined;
}
export function AnimatedIcon({
  children,
  defaultAnimation = 'turn-on-itself',
  animationDuration = 500,
  animationTimingFunction = 'ease-in-out',
  animateRef,
  triggerOnClick = false,
  triggerOnAppear = false,
  sx,
}: AnimatedIconProps): JSX.Element {
  const [animation, setAnimation] = useState<string | undefined>(undefined);

  const triggerAnimation = useCallback(
    (anim = defaultAnimation) => {
      setAnimation(anim);

      setTimeout(() => {
        setAnimation(undefined);
      }, animationDuration);
    },
    [animationDuration, defaultAnimation]
  );

  useEffect(() => {
    if (animateRef) {
      animateRef.current = triggerAnimation;
    }
  }, [animateRef, triggerAnimation]);

  useEffect(() => {
    if (triggerOnAppear) {
      triggerAnimation();
    }
  }, [triggerAnimation, triggerOnAppear]);

  return (
    <Box
      component="span"
      sx={{
        animation: animation ? `${animation} ${animationDuration}ms ${animationTimingFunction}` : undefined,
        display: 'inline-flex',
        ...sx,
      }}
      onClick={triggerOnClick ? () => triggerAnimation() : undefined}
    >
      {children}
    </Box>
  );
}
