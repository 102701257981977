import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import { selectFirstSelectedShapeDataId } from 'reducers/local/selectors';
import type { AppState } from 'reducers/state';
import { createSelector } from 'reselect';
import { createEntitySelectors } from 'utils/redux';
import type {
  CircuitDeviceState,
  CircuitMeasurerState,
  CircuitNoteState,
  CircuitPointState,
  CircuitRackState,
  CircuitSegmentState,
  CircuitState,
  CircuitStockZoneState,
  CircuitTurnState,
  CircuitZoneState,
} from './state';

function circuitSelector(state: AppState): CircuitState {
  return state.circuit.present;
}

export const ZONES_SLICE = 'zones';
export const STOCK_ZONES_SLICE = 'stockZones';
export const RACKS_SLICE = 'racks';
export const POINTS_SLICE = 'points';
export const SEGMENTS_SLICE = 'segments';
export const MEASURER_SLICE = 'measurers';
export const TURNS_SLICE = 'turns';
export const DEVICES_SLICE = 'devices';
export const NOTES_SLICE = 'notes';

const { selectAll: selectAllZones, selectEntities: selectZonesEntities } = createEntitySelectors<
  CircuitZone,
  CircuitZoneState,
  CircuitState
>(ZONES_SLICE);
const { selectAll: selectAllStockZones, selectEntities: selectStockZonesEntities } = createEntitySelectors<
  CircuitStockZone,
  CircuitStockZoneState,
  CircuitState
>(STOCK_ZONES_SLICE);
const { selectAll: selectAllRacks, selectEntities: selectRacksEntities } = createEntitySelectors<
  CircuitRack,
  CircuitRackState,
  CircuitState
>(RACKS_SLICE);

const { selectAll: selectAllPoints, selectEntities: selectPointsEntities } = createEntitySelectors<
  CircuitPoint,
  CircuitPointState,
  CircuitState
>(POINTS_SLICE);
const { selectAll: selectAllSegments, selectEntities: selectSegmentsEntities } = createEntitySelectors<
  CircuitSegment,
  CircuitSegmentState,
  CircuitState
>(SEGMENTS_SLICE);
const { selectAll: selectAllMeasurers, selectEntities: selectMeasurerEntities } = createEntitySelectors<
  CircuitMeasurer,
  CircuitMeasurerState,
  CircuitState
>(MEASURER_SLICE);
const { selectAll: selectAllTurns, selectEntities: selectTurnsEntities } = createEntitySelectors<
  CircuitTurn,
  CircuitTurnState,
  CircuitState
>(TURNS_SLICE);
const { selectAll: selectAllDevices, selectEntities: selectDevicesEntities } = createEntitySelectors<
  CircuitDevice,
  CircuitDeviceState,
  CircuitState
>(DEVICES_SLICE);
const { selectAll: selectAllNotes, selectEntities: selectNotesEntities } = createEntitySelectors<
  CircuitNote,
  CircuitNoteState,
  CircuitState
>(NOTES_SLICE);

export const selectCurrentCircuit = createSelector(circuitSelector, (circuit) => {
  return circuit;
});

export const selectAllZonesEntities = createSelector(circuitSelector, selectAllZones);
export const selectAllStockZonesEntities = createSelector(circuitSelector, selectAllStockZones);
export const selectAllRacksEntities = createSelector(circuitSelector, selectAllRacks);
export const selectAllPointsEntities = createSelector(circuitSelector, selectAllPoints);
export const selectAllSegmentsEntities = createSelector(circuitSelector, selectAllSegments);
export const selectAllMeasurersEntities = createSelector(circuitSelector, selectAllMeasurers);
export const selectAllTurnsEntities = createSelector(circuitSelector, selectAllTurns);
export const selectAllDevicesEntities = createSelector(circuitSelector, selectAllDevices);
export const selectAllNotesEntities = createSelector(circuitSelector, selectAllNotes);

export const selectSelectedCircuitZone = createSelector(
  selectZonesEntities,
  selectFirstSelectedShapeDataId,
  (zones, id) => {
    return zones[id];
  }
);
export const selectSelectedCircuitStockZone = createSelector(
  selectStockZonesEntities,
  selectFirstSelectedShapeDataId,
  (stockZone, id) => {
    return stockZone[id];
  }
);
export const selectSelectedCircuitRack = createSelector(
  selectRacksEntities,
  selectFirstSelectedShapeDataId,
  (racks, id) => racks[id]
);

export const selectSelectedCircuitPoint = createSelector(
  selectPointsEntities,
  selectFirstSelectedShapeDataId,
  (points, id) => {
    return points[id];
  }
);
export const selectSelectedCircuitSegments = createSelector(
  selectSegmentsEntities,
  selectFirstSelectedShapeDataId,
  (segments, id) => {
    return segments[id];
  }
);
export const selectSelectedCircuitMeasurers = createSelector(
  selectMeasurerEntities,
  selectFirstSelectedShapeDataId,
  (measurers, id) => {
    return measurers[id];
  }
);
export const selectSelectedCircuitTurns = createSelector(
  selectTurnsEntities,
  selectFirstSelectedShapeDataId,
  (turns, id) => {
    return turns[id];
  }
);
export const selectSelectedCircuitDevices = createSelector(
  selectDevicesEntities,
  selectFirstSelectedShapeDataId,
  (devices, id) => {
    return devices[id];
  }
);
export const selectSelectedCircuitNotes = createSelector(
  selectNotesEntities,
  selectFirstSelectedShapeDataId,
  (notes, id) => {
    return notes[id];
  }
);
