/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { partition } from '../../helpers';
import type { Update } from '../shared';
import type { EntityState } from './state';
import { defaultSelectId, selectIds, selectEntities, defaultWriteId } from './selectors';

type IdSelector<T> = (model: T) => string;
type IdWriter<T> = (model: T, value: string) => T;
type Comparer<T> = (a: T, b: T) => string;

export interface EntityAdapterOptions<T> {
  selectId?: IdSelector<T>;
  sortComparer?: false | Comparer<T>;
  writeId?: IdWriter<T>;
}

// @ts-ignore
export interface EntityStateAdapter<T, S extends EntityState<T>> {
  addAll(entities: T[], state: S): S;
  addMany(entities: T[], state: S): S;
  addOne(entity: T, state: S): S;
  getInitialState(): S;
  removeAll(state: S): S;
  removeMany(keys: string[], state: S): S;
  removeOne(key: string, state: S): S;
  selectId: IdSelector<T>;
  updateMany(updates: Update<T>[], state: S): S;
  updateOne(update: Update<T>, state: S): S;
  upsertMany(entities: T[], state: S): S;
  upsertOne(entity: T, state: S): S;
  writeId: IdWriter<T>;
}

// @ts-ignore
export function addOne<T, S extends EntityState<T>>(selectId: IdSelector<T>, entity: T, state: S): S {
  const id = selectId(entity);

  const ids = [...selectIds<T, S>(state), id];
  const entities = { ...selectEntities<T, S>(state), [id]: entity };

  return { ...state, ids, entities };
}

// @ts-ignore
export function addMany<T, S extends EntityState<T>>(selectId: IdSelector<T>, newEntities: T[], state: S): S {
  const ids = [...selectIds<T, S>(state), ...newEntities.map(selectId)];
  const entities = newEntities.reduce((result, entity) => {
    result[selectId(entity)] = entity;

    return result;
  }, selectEntities<T, S>(state));

  return { ...state, ids, entities };
}

// @ts-ignore
export function addAll<T, S extends EntityState<T>>(selectId: IdSelector<T>, newEntities: T[], state: S): S {
  const ids = newEntities.map(selectId);
  const entities = newEntities.reduce((result, entity) => {
    result[selectId(entity)] = entity;

    return result;
  }, {});

  return { ...state, ids, entities };
}

// @ts-ignore
export function removeOne<T, S extends EntityState<T>>(key: string, state: S): S {
  const ids = selectIds<T, S>(state).filter((id) => id !== key);
  const entities = { ...selectEntities<T, S>(state) };
  delete entities[key];

  return { ...state, ids, entities };
}

// @ts-ignore
export function removeMany<T, S extends EntityState<T>>(keys: string[], state: S): S {
  const ids = selectIds<T, S>(state).filter((id) => !keys.includes(id));
  const stateEntities = selectEntities<T, S>(state);
  const entities = Object.keys(stateEntities).reduce((result, id) => {
    if (!keys.includes(id)) {
      result[id] = stateEntities[id];
    }

    return result;
  }, {});

  return { ...state, ids, entities };
}

// @ts-ignore
export function removeAll<T, S extends EntityState<T>>(state: S): S {
  return { ...state, ids: [], entities: {} };
}

// @ts-ignore
export function updateOne<T, S extends EntityState<T>>(update: Update<T>, state: S): S {
  const ids = selectIds<T, S>(state);
  const stateEntities = selectEntities<T, S>(state);
  const entities = ids.reduce((result, id) => {
    const entity = stateEntities[id];
    result[id] = id === update.id ? { ...entity, ...update.changes } : entity;

    return result;
  }, {});

  return { ...state, entities };
}

// @ts-ignore
export function updateMany<T, S extends EntityState<T>>(updates: Update<T>[], state: S): S {
  const ids = selectIds<T, S>(state);
  const stateEntities = selectEntities<T, S>(state);

  const changes = new Map();
  updates.forEach((update) => changes.set(update.id, update.changes));

  const entities = ids.reduce((result, id) => {
    const entity = stateEntities[id];

    if (changes.has(id)) {
      result[id] = { ...entity, ...changes.get(id) };
    } else {
      result[id] = entity;
    }

    return result;
  }, {});

  return { ...state, entities };
}

// @ts-ignore
export function upsertOne<T, S extends EntityState<T>>(selectId: IdSelector<T>, entity: T, state: S): S {
  const ids = selectIds<T, S>(state);
  const id = selectId(entity);
  const exists = ids.includes(id);

  // @ts-ignore
  return exists ? updateOne({ id, changes: entity }, state) : addOne(selectId, entity, state);
}

// @ts-ignore
export function upsertMany<T, S extends EntityState<T>>(selectId: IdSelector<T>, entities: T[], state: S): S {
  const stateIds = selectIds<T, S>(state);

  const [updates, insertions] = partition(entities, (entity) => stateIds.includes(selectId(entity)));

  let nextState = updateMany(
    // @ts-ignore
    updates.map((update) => ({ id: selectId(update), changes: update })),
    state
  );
  nextState = addMany(selectId, insertions, nextState);

  return nextState;
}

// @ts-ignore
export function getInitialState<T, S extends EntityState<T>>(): S {
  return {
    creationForm: { pending: false },
    entities: {},
    ids: [] as string[],
    selectedId: undefined,
  } as S;
}

// @ts-ignore
export function createEntityStateAdapter<T, S extends EntityState<T>>(
  options: EntityAdapterOptions<T> = {}
): EntityStateAdapter<T, S> {
  const { selectId = defaultSelectId, writeId = defaultWriteId } = options;

  return {
    addAll: (entity, state) => addAll(selectId, entity, state),
    addMany: (entity, state) => addMany(selectId, entity, state),
    addOne: (entity, state) => addOne(selectId, entity, state),
    getInitialState,
    removeAll,
    removeMany,
    removeOne,
    selectId,
    updateMany,
    updateOne,
    upsertMany: (entities, state) => upsertMany(selectId, entities, state),
    upsertOne: (entity, state) => upsertOne(selectId, entity, state),
    writeId,
  };
}
