import { useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'store';
import { windowCollision } from 'utils/circuit/map-tiles';
import { fequal, findCoefficientGridMargin, zoomToScale } from 'utils/circuit/utils';

export interface MapTilesAttr {
  x: number;
  y: number;
  href: string;
  height: number;
  name: string;
}

export interface GridCoordinate {
  /** left top corner */
  x0: number;
  /** right top corner */
  x1: number;
  /** left top corner */
  y0: number;
  /** left bottom corner */
  y1: number;
}

export function MapImageTilesComponent(): JSX.Element | null {
  const mapImagesTiles = useAppSelector((state) => state.maps.mapImageTiles.mapImageTilesData);

  const displayMapImage = useAppSelector((state) => state.local.filters.mapImage);
  const opacityMapImage = useAppSelector((state) => state.local.filters.mapImageOpacity);
  const mapImageArray = useAppSelector((state) => state.local.filters.mapImageArray);

  const defaultScale = {
    current: 128,
    former: 128,
    loaded: true,
  };

  const [scale, setScale] = useState(defaultScale);

  const currentZoomLevelClass = useAppSelector((state) => state.tool.zoomLevel);

  const gridCoordinate = useAppSelector((state) => state.tool.gridCoordinate);

  // const performanceMode = useAppSelector((state) => state.editor.performanceMode);

  // it is nice to have some margin because when we move the playground, they nearby tiles are already loaded so we do not see some
  // blank space but it takes more memory and the extra tiles have to be processed to in performance mode we do not load any extra tile
  const gridMargin = 0; // performanceMode ? 0 : 500;

  // We need this coefficent because depend on the scale we need more or less pre-load tiles.
  const coefficientGridMargin = findCoefficientGridMargin(scale.current);

  const tilesToLoad = useRef(new Set<string>());
  const tilesLoaded = useRef(new Set<string>());

  const scaleAfterZoom = currentZoomLevelClass ? zoomToScale(currentZoomLevelClass) : null;
  if (scaleAfterZoom && scaleAfterZoom !== scale.current) {
    tilesToLoad.current = new Set<string>();
    tilesLoaded.current = new Set<string>();

    setScale((prev) => ({
      current: scaleAfterZoom,
      former: prev.current,
      loaded: false,
    }));
  }

  const birdviews = useMemo(
    () =>
      mapImagesTiles?.tiles
        ? Object.fromEntries(
            Object.entries(mapImagesTiles.tiles).filter(
              ([scaleKeyStr, value]) =>
                fequal(Number(scaleKeyStr), scale.current) ||
                (!scale.loaded && fequal(Number(scaleKeyStr), scale.former))
            )
          )
        : null,
    [mapImagesTiles?.tiles, scale]
  );

  const mapImageFilter = useMemo(() => {
    if (!mapImagesTiles) return null;

    return mapImageArray.find((mapImageFilter) => mapImageFilter.name === mapImagesTiles.name);
  }, [mapImageArray, mapImagesTiles]);

  if (!birdviews || !mapImagesTiles || !mapImageFilter?.toggle) return null;

  if (!coefficientGridMargin) return null;

  return (
    <g
      {...{ layer: 'floor-plan-tiles' }}
      style={{ display: displayMapImage ? 'inline' : 'none', opacity: opacityMapImage }}
    >
      {displayMapImage && mapImagesTiles
        ? Object.entries(birdviews).map(([scaleKeyStr, tile]) => {
            const scaleKey = Number(scaleKeyStr);
            const isFormer = !scale.loaded && fequal(scaleKey, scale.former);

            return tile.map((file) => {
              if (!mapImagesTiles.tilePixelSize) return null;

              const tileName = `tile_${scaleKeyStr}_${file.x}_${file.y}`;

              const attr: MapTilesAttr = {
                x: file.x * 100,
                y: -file.y * 100 - mapImagesTiles.tilePixelSize * scaleKey,
                href: file.file || '',
                height: mapImagesTiles.tilePixelSize * scaleKey,
                name: tileName,
              };

              const width = mapImagesTiles.tilePixelSize * scaleKey;

              const margin = gridMargin * coefficientGridMargin;

              /** We want to see if the tile collides with the window, which means, if the tiles should be displayed because it is present (or partially) on the screen  */
              const collidingTile = windowCollision(gridCoordinate, attr, width, margin);

              if (!collidingTile) {
                return null;
              }

              if (!isFormer) tilesToLoad.current.add(tileName);

              const inMargin = gridMargin ? !windowCollision(gridCoordinate, attr, width, 0) : false;

              return (
                <image
                  crossOrigin="anonymous"
                  {...collidingTile}
                  key={tileName}
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    display: mapImageFilter?.toggle ? 'initial' : 'none',
                    zIndex: isFormer ? -1 : undefined,
                    opacity: isFormer ? 0.5 : 1,
                  }}
                  onLoad={() => {
                    tilesLoaded.current.add(tileName);
                    if (tilesLoaded.current.size === tilesToLoad.current.size) {
                      setScale((prev) => ({
                        ...prev,
                        loaded: true,
                      }));
                    }
                  }}
                  {...{
                    fetchPriority: inMargin || isFormer ? 'low' : 'auto',
                  }}
                />
              );
            });
          })
        : null}
    </g>
  );
}
