import { useAppSelector } from 'store';

function DisplayExtendedLength(): JSX.Element {
  const displayExtendedLength = useAppSelector((state) => state.local.filters.extendedLengthTurns);

  return (
    <style id="display-extended-length">
      {displayExtendedLength
        ? `
         g[type="SEGMENT"][rack-id], g[type="SEGMENT"][stockline-id] {
           display: none;
         }
       `
        : ''}

      {displayExtendedLength
        ? `g[type="TURN"][extended-length-turn] {
        display: none;
      }`
        : ''}
    </style>
  );
}

export default DisplayExtendedLength;
