import type { SchedulerConfig } from './scheduler-config';

/**
 * The replacer function used to compare two scheduler configs in the JSON.stringify function
 * @param key the key
 * @param value the value
 * @returns the upated value
 */
function replacerToFixedNumbers(key: string, value: unknown): any {
  if (typeof value === 'number') {
    return value.toFixed(5);
  }

  return value;
}

/**
 * Check whether two scheduler configs are the same
 * @param configA the first config
 * @param configB the second config
 * @returns a boolean indicating whether the two configs are the same
 */
export function checkConfigsAreTheSame(configA: SchedulerConfig, configB: SchedulerConfig): boolean {
  return JSON.stringify(configA, replacerToFixedNumbers) === JSON.stringify(configB, replacerToFixedNumbers);
}
