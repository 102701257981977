import { z } from 'zod';

export const tileDescriptor = z.object({
  file: z.string(),
  x: z.number(),
  y: z.number(),
});

export const tileArrayDescriptor = z.array(tileDescriptor);

const release = z.object({
  release: z.string(),
  layers: z.array(z.string()),
});

const error = [
  'Project not found',
  'Invalid parameter: layer',
  'File not found',
  'Bad descriptor format',
  'Forbidden',
] as const;

export const serverErrorResponse = z.strictObject({
  success: z.literal(false),
  message: z.enum(error),
});

export const mapTilesSchema = z.object({
  layer: z.string(),
  release: z.string(),
  tilePixelSize: z.number(),
  version: z.string(),
  tiles: z.object({
    2: tileArrayDescriptor,
    4: tileArrayDescriptor,
    8: tileArrayDescriptor,
    16: tileArrayDescriptor,
    32: tileArrayDescriptor,
    64: tileArrayDescriptor,
    128: tileArrayDescriptor,
  }),
});

export const mapTilesFullImgSchema = z.string();

export const releasesVersionSchema = z.array(release);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const serverSuccessResponse = <T extends z.ZodType<any, z.ZodTypeDef, any>>(dataSchema: T) =>
  z.strictObject({
    success: z.literal(true),
    data: dataSchema,
  });
