import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  FormControl,
  MenuItem,
  Popover,
  Select,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { ShapeTypes, SlotTypes, type CircuitRack, type RackCell } from 'models/circuit';
import { useCallback, useMemo, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppSelector } from 'store';
import type { SlotName, StationPositions } from './rack-edition';

interface AddPositionsToStationMenuProps {
  anchorEl: SVGSVGElement | null;
  open: boolean;
  onClose?: () => void;
  rack: CircuitRack;
  selectedCellsForStation: [string, number, number][];
  allCellsId: string[];
  stationPositions: StationPositions[];
  setStationPositions: React.Dispatch<React.SetStateAction<StationPositions[]>>;
  setUserRemovedStationPositions: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddPositionsToStationMenu(props: AddPositionsToStationMenuProps): JSX.Element {
  const {
    anchorEl,
    open,
    onClose,
    rack,
    selectedCellsForStation,
    allCellsId,
    stationPositions,
    setStationPositions,
    setUserRemovedStationPositions,
  } = props;

  const stations = useAppSelector((state) => state.flows.stations);

  const [selectedStation, setSelectedStation] = useState('');

  const selectedStationPositions = useMemo(() => {
    return stations.find((station) => station.id === selectedStation)?.positions;
  }, [selectedStation, stations]);

  const selectedStationPositionsFormatted: StationPositions[] | undefined = useMemo(() => {
    return selectedStationPositions?.map((selectedPosition) => {
      return {
        selectedStation: selectedStation,
        id: selectedPosition.id,
        type: selectedPosition.type,
      };
    });
  }, [selectedStation, selectedStationPositions]);

  const handleSelectStation = (event: SelectChangeEvent): void => {
    setSelectedStation(event.target.value);
  };

  const onCloseHandler = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const columns = rack.properties.columns;

  const cells = useMemo(() => {
    return columns.map((column) => column.cells);
  }, [columns]);

  const findCellsByIds = useCallback(
    (ids: string[]) => {
      return ids.map((id) => cells[0].find((cell) => cell.id === id));
    },
    [cells]
  );

  const extractSlotNames = useCallback((cells: (RackCell | undefined)[]): SlotName[] => {
    const slotNames: SlotName[] = [];
    if (cells.length > 0) {
      cells.forEach((cell) => {
        if (cell?.names[0]) {
          cell?.names[0].forEach((name) => {
            slotNames.push(name);
          });
        }
      });
    }

    return slotNames;
  }, []);

  const selectedCells = useMemo(() => {
    const ids = selectedCellsForStation.map((pair) => pair[0]);

    return findCellsByIds(ids);
  }, [selectedCellsForStation, findCellsByIds]);

  const selectedSlots = useMemo(() => extractSlotNames(selectedCells), [extractSlotNames, selectedCells]);

  const allCells = useMemo(() => findCellsByIds(allCellsId), [allCellsId, findCellsByIds]);

  const allSlots = useMemo(() => extractSlotNames(allCells), [allCells, extractSlotNames]);

  const handleAddPositions = useCallback(
    (slots: SlotName[]) => {
      const newStationPositions = stationPositions;

      const newStationPositionIds = newStationPositions.map((stationPosition) => stationPosition.id);

      for (let i = 0; i < slots.length; i++) {
        if (!newStationPositionIds.includes(slots[i].id))
          newStationPositions.push({
            selectedStation: selectedStation,
            id: slots[i].id,
            type: SlotTypes.Slot,
          });
      }

      if (slots.length < 1) {
        SnackbarUtils.warning('No rack cells selected');

        return;
      }

      setUserRemovedStationPositions(false);

      setStationPositions(newStationPositions);

      const stationName = stations.find((station) => station.id === selectedStation)?.name;

      const message =
        slots.length > 1
          ? `${slots.length} slots will be added to ${stationName} after validation of the rack`
          : `${slots.length} slot will be added to ${stationName} after validation of the rack`;

      SnackbarUtils.info(message);

      onCloseHandler();
    },
    [onCloseHandler, selectedStation, setStationPositions, setUserRemovedStationPositions, stationPositions, stations]
  );

  const handleRemovePositions = useCallback(
    (slots: SlotName[]) => {
      const positionsId = slots.map((slot) => slot.id);

      if (positionsId.length < 1) {
        SnackbarUtils.warning('No rack cells selected');

        return;
      }

      const stationPositionsToFilter =
        stationPositions.length > 0 ? stationPositions : selectedStationPositionsFormatted || [];

      const stationPositionToRemove = stationPositionsToFilter.filter(
        (position) => position.selectedStation === selectedStation && positionsId.includes(position.id)
      );

      setUserRemovedStationPositions(true);

      setStationPositions(stationPositionToRemove);

      const stationName = stations.find((station) => station.id === selectedStation)?.name;

      const message =
        positionsId.length > 1
          ? `${stationPositionToRemove.length} slots will be removed to ${stationName} after validation of the rack`
          : `${stationPositionToRemove.length} slot will be removed to ${stationName} after validation of the rack`;

      SnackbarUtils.info(message);

      onCloseHandler();
    },
    [
      stationPositions,
      selectedStationPositionsFormatted,
      setUserRemovedStationPositions,
      setStationPositions,
      stations,
      onCloseHandler,
      selectedStation,
    ]
  );

  const isOnlyPointsStations = useMemo(() => {
    return stations.every(
      (station) =>
        station.positions.length > 0 && station.positions.every((position) => position.type === ShapeTypes.PointShape)
    );
  }, [stations]);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onCloseHandler}
    >
      <Card>
        {stations.length < 1 && (
          <Alert severity="info" sx={{ marginTop: 0 }}>
            You must create a station first
          </Alert>
        )}
        {stations.length > 0 && isOnlyPointsStations && (
          <Alert severity="info" sx={{ marginTop: 0 }}>
            No available stations
          </Alert>
        )}
        <CardContent
          sx={{ paddingTop: 1, paddingBottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box component={'div'} mr={2}>
            Select a station
            <HelpIconTooltip
              title={'Stations that have points are not selectable because they cannot include slots'}
            ></HelpIconTooltip>
          </Box>
          <FormControl sx={{ minWidth: 150 }} disabled={stations.length < 1}>
            <Select value={selectedStation} onChange={handleSelectStation} autoWidth>
              {stations.map((station, i) => {
                const isStationIncludePoints = !!station.positions.find(
                  (position) => position.type === ShapeTypes.PointShape
                );

                if (!isStationIncludePoints) {
                  return (
                    <MenuItem value={station.id} key={i}>
                      {station.name}
                    </MenuItem>
                  );
                }

                return (
                  <Tooltip title={'This station has points so it cannot include slots'}>
                    <span>
                      <MenuItem value={station.id} key={i} disabled>
                        {station.name}
                      </MenuItem>
                    </span>
                  </Tooltip>
                );
              })}
            </Select>
          </FormControl>
        </CardContent>
        <CardActions sx={{ float: 'right' }}>
          <Tooltip title="Resetting the name of the cell will generate a new default name for the cells.">
            <ButtonGroup color="inherit" variant="contained" size="small" disabled={selectedStation === ''}>
              <Button
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => handleRemovePositions(allSlots)}
              >
                Remove All
              </Button>
              <Button
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => handleRemovePositions(selectedSlots)}
              >
                Remove Selection
              </Button>
            </ButtonGroup>
          </Tooltip>

          <ButtonGroup color="primary" variant="contained" disabled={selectedStation === ''}>
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => handleAddPositions(allSlots)}
              startIcon={<ReplyAllIcon sx={{ transform: 'rotate(180deg)' }} />}
            >
              Add all cells
            </Button>
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => handleAddPositions(selectedSlots)}
              startIcon={<ReplyIcon sx={{ transform: 'rotate(180deg)' }} />}
            >
              Add selected cells
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Popover>
  );
}
