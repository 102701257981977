import { FlipCameraAndroid } from '@mui/icons-material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import InfoIcon from '@mui/icons-material/Info';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useConfirm } from 'material-ui-confirm';
import type { RackColumn, RackUpright } from 'models/circuit';
import type { FormEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import type { LoadedRack } from 'reducers/circuit/state';
import { AnimatedIcon } from 'utils/animated-icon';
import { computeNbLoadsInRack } from 'utils/circuit/compute-nb-loads';
import {
  beamThicknessMax,
  beamThicknessMin,
  cellHeightMaxHeight,
  cellHeightMinHeight,
  columnWidthMax,
  columnWidthMin,
  extendedLengthMin,
  nbColumnsMax,
  nbColumnsMin,
  nbLevelsMax,
  nbLevelsMin,
  uprightWidthMax,
  uprightWidthMin,
} from 'utils/circuit/racks';
import { epsilon } from 'utils/circuit/utils';
import { theme } from 'utils/mui-theme';
import type { ActionsRack } from '.';
import { updateCellsAfterChangeNbLevels, zoomToRack } from '.';

export interface RackEditionPropertiesFormProps {
  rack: LoadedRack;
  columns: RackColumn[];
  uprights: RackUpright[];
  actions: ActionsRack;
  editMode: boolean;
}

export function RackEditionPropertiesForm({
  columns,
  actions,
  editMode,
  rack,
  uprights,
}: RackEditionPropertiesFormProps): JSX.Element {
  const isConveyor = !!rack.properties.conveyor;

  const nbLevelsInitialValue = rack.properties.defaultNbLevels.toString();
  const columnWidthInitialValue = (rack.properties.defaultColumnWidth * 1000).toString();
  const cellHeightInitialValue = (rack.properties.defaultCellHeight * 1000).toString();
  const beamThicknessInitialValue = (rack.properties.defaultBeamThickness * 1000).toString();
  const uprightWidthInitialValue = (rack.properties.defaultUprightWidth * 1000).toString();
  const extendedLengthInitialValue = (rack.properties.defaultExtendedLength * 1000).toString();

  const [nbColumns, setNbColumns] = useState(columns.length.toString());
  const [nbLevels, setNbLevels] = useState(nbLevelsInitialValue);
  const [columnWidth, setColumnWidth] = useState(columnWidthInitialValue);
  const [cellHeight, setCellHeight] = useState(cellHeightInitialValue);
  const [beamThickness, setBeamThickness] = useState(beamThicknessInitialValue);
  const [uprightWidth, setUprightWidth] = useState(uprightWidthInitialValue);
  const [extendedLength, setExtendedLength] = useState(extendedLengthInitialValue);

  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();
  const confirm = useConfirm();

  useEffect(() => {
    setNbColumns(columns.length.toString());
  }, [columns]);
  useEffect(() => {
    setNbLevels(nbLevelsInitialValue);
  }, [nbLevelsInitialValue]);
  useEffect(() => {
    setColumnWidth(columnWidthInitialValue);
  }, [columnWidthInitialValue]);
  useEffect(() => {
    setCellHeight(cellHeightInitialValue);
  }, [cellHeightInitialValue]);
  useEffect(() => {
    setBeamThickness(beamThicknessInitialValue);
  }, [beamThicknessInitialValue]);
  useEffect(() => {
    setUprightWidth(uprightWidthInitialValue);
  }, [uprightWidthInitialValue]);
  useEffect(() => {
    setExtendedLength(extendedLengthInitialValue);
  }, [extendedLengthInitialValue]);

  const handleUpdateField = useCallback(
    /**
     *
     * @param input html input element of the form
     * @param setFn the function to call to change the value of the state
     * @param fallbackValue the value to set if the input value is not ok
     * @returns true if value of the input is ok, false otherwise
     */
    (input: HTMLInputElement, setFn: (value: React.SetStateAction<string>) => void, fallbackValue: string): boolean => {
      const inputValue = input.value;
      const newValue = parseInt(inputValue, 10);

      let min = parseInt(input.min, 10);
      let max = parseInt(input.max, 10);
      if (isNaN(min)) min = -1 / 0;
      if (isNaN(max)) max = 1 / 0;

      if (isNaN(newValue) || newValue < min || newValue > max) {
        setFn(fallbackValue);

        return false;
      }

      const newValueStr = newValue.toFixed(0);
      if (newValueStr !== inputValue) setFn(newValueStr);

      return true;
    },
    []
  );
  const handleKeyDownField = useCallback(
    (
      e: React.KeyboardEvent<HTMLDivElement>,
      setFn: (value: React.SetStateAction<string>) => void,
      fallbackValue: string
    ) => {
      if (e.key === 'Enter') {
        handleUpdateField(e.target as HTMLInputElement, setFn, fallbackValue);
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setFn(fallbackValue);
        setTimeout(() => (e.target as HTMLInputElement).blur(), 0);
      }
    },
    [handleUpdateField]
  );

  const handleLinkAllColumnProp = useCallback(
    async (prop: keyof RackColumn['linkedProperties']): Promise<void> => {
      let nbEditedColumns = 0;
      columns.forEach((column) => {
        if (!column.linkedProperties[prop]) {
          nbEditedColumns++;
        }
      });

      if (nbEditedColumns === 0) {
        // eslint-disable-next-line no-console
        // console.log(`handleLinkAllColumnProp: No column to edit ${prop}`);

        return;
      }

      try {
        await confirm({
          title: 'Link back all columns to this master value',
          description: `This will link back ${nbEditedColumns} column${nbEditedColumns !== 1 ? 's' : ''}.`,
          allowClose: false,
        });

        const columnsToEdit = columns
          .map((column) =>
            !column.linkedProperties[prop]
              ? {
                  ...column,
                  linkedProperties: {
                    ...column.linkedProperties,
                    [prop]: true,
                  },
                }
              : null
          )
          .filter((column): column is RackColumn => !!column);

        columnsToEdit.forEach((column) => {
          actions.updateColumn(column.id, column);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        if (e) console.warn(e);
      }
    },
    [actions, columns, confirm]
  );
  const handleLinkAllCells = useCallback(
    async (prop: 'height' | 'beamThickness'): Promise<void> => {
      const capitalizedProp = prop[0].toUpperCase() + prop.slice(1);
      const defaultPropName =
        prop === 'height' ? 'defaultCellHeight' : prop === 'beamThickness' ? 'defaultBeamThickness' : undefined;
      const linkedPropName = `linked${capitalizedProp}`;

      if (!defaultPropName) {
        // eslint-disable-next-line no-console
        console.error('Unknown property', prop);

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const defaultValue = rack.properties[defaultPropName];

      if (defaultValue === undefined) {
        // eslint-disable-next-line no-console
        console.error(`${defaultPropName} is undefined`);

        return;
      } else if (typeof defaultValue !== 'number') {
        // eslint-disable-next-line no-console
        console.error(`${defaultPropName} is not a number (${defaultValue})`);

        return;
      }

      let nbCellsToEdit = 0;
      columns.forEach((column) => {
        column.cells.forEach((cell) => {
          if (!cell[linkedPropName]) {
            nbCellsToEdit++;
          }
        });
      });

      if (nbCellsToEdit === 0) {
        // eslint-disable-next-line no-console
        // console.log(`handleLinkAllCells: No cell to edit ${prop}`);
        return;
      }

      try {
        await confirm({
          title: 'Link back all cells to this master value',
          description: `This will link back ${nbCellsToEdit} cell${nbCellsToEdit !== 1 ? 's' : ''}.`,
          allowClose: false,
        });

        const newColumns = columns.map((column) => ({
          ...column,
          cells: column.cells.map((cell) => ({
            ...cell,
            [`linked${capitalizedProp}`]: true,
            [prop]: defaultValue,
          })),
        }));

        actions.updateColumns(newColumns);
      } catch (e) {
        // eslint-disable-next-line no-console
        if (e) console.warn(e);
      }
    },
    [actions, columns, confirm, rack.properties]
  );
  const handleClickAllUprights = useCallback(
    async (prop: 'width'): Promise<void> => {
      let nbUprightsToEdit = 0;
      uprights.forEach((upright) => {
        if (!upright.linkedWidth) nbUprightsToEdit++;
      });

      if (nbUprightsToEdit === 0) {
        // eslint-disable-next-line no-console
        // console.log(`handleClickAllUprights: No upright to edit ${prop}`);
        return;
      }

      try {
        await confirm({
          title: 'Link back all uprights to this master value',
          description: `This will link back ${nbUprightsToEdit} upright${nbUprightsToEdit !== 1 ? 's' : ''}.`,
          allowClose: false,
        });

        const uprightsToEdit = uprights
          .map((upright, index) => {
            return !upright.linkedWidth
              ? {
                  index,
                  value: {
                    ...upright,
                    linkedWidth: true,
                  },
                }
              : null;
          })
          .filter((upright): upright is { index: number; value: RackUpright } => !!upright);

        uprightsToEdit.forEach((upright) => actions.updateUpright(upright.index, upright.value));
      } catch (e) {
        // eslint-disable-next-line no-console
        if (e) console.warn(e);
      }
    },
    [actions, confirm, uprights]
  );

  const [needUpdateRackAgain, setNeedUpdateRackAgain] = useState(false);
  const handleUpdateRack = useCallback(
    (e: FormEvent<HTMLFormElement> | HTMLFormElement) => {
      if (e && e.preventDefault) (e as FormEvent<HTMLFormElement>).preventDefault();

      const form = (e.target as HTMLFormElement) || e;
      const inputs = form.elements;

      const inputNames = [
        'nbColumns',
        'nbLevels',
        'columnWidth',
        'cellHeight',
        'beamThickness',
        'uprightWidth',
        'extendedLength',
      ];
      const inputsValues = [nbColumns, nbLevels, columnWidth, cellHeight, beamThickness, uprightWidth, extendedLength];

      const inputsOk = inputNames.every((name, i) => {
        const input = inputs[name] as HTMLInputElement | undefined;

        if (!input) return true; // for conveyors, all the inputs are not listed, we consider it is ok if not present

        return handleUpdateField(inputs[name] as HTMLInputElement, setNbColumns, inputsValues[i]);
      });

      if (inputsOk) {
        setLoading(true);

        const newNbColumns = parseInt(nbColumns, 10);
        const newNbLevels = parseInt(nbLevels, 10);
        const newColumnWidth = parseInt(columnWidth, 10) / 1000;
        const newCellHeight = parseInt(cellHeight, 10) / 1000;
        const newBeamThickness = parseInt(beamThickness, 10) / 1000;
        const newUprightWidth = parseInt(uprightWidth, 10) / 1000;
        const newExtendedLength = parseInt(extendedLength, 10) / 1000;

        if (newNbColumns !== columns.length) {
          startTransition(() => {
            actions.updateNbColumns(newNbColumns);
            setNeedUpdateRackAgain(true);
          });

          return;
        }

        if (newNbLevels !== rack.properties.defaultNbLevels) {
          startTransition(() => {
            actions.updateRack({
              ...rack,
              properties: {
                ...rack.properties,
                defaultNbLevels: newNbLevels,
                columns: rack.properties.columns.map((c) => ({
                  ...c,
                  nbLevels: c.linkedProperties.nbLevels ? newNbLevels : c.nbLevels,
                  cells: c.linkedProperties.nbLevels ? updateCellsAfterChangeNbLevels(newNbLevels, c.cells) : c.cells,
                })),
              },
            });
            setNeedUpdateRackAgain(true);
          });

          return;
        }

        if (
          Math.abs(newColumnWidth - rack.properties.defaultColumnWidth) > epsilon ||
          Math.abs(newExtendedLength - rack.properties.defaultExtendedLength) > epsilon
        ) {
          let newColumns = rack.properties.columns.map((c) => ({
            ...c,
            width: c.linkedProperties.width ? newColumnWidth : c.width,
            extendedLength: c.linkedProperties.extendedLength ? newExtendedLength : c.extendedLength,
          }));

          newColumns = actions.computeColumnsPosition(newColumns);

          startTransition(() => {
            actions.updateRack({
              ...rack,
              properties: {
                ...rack.properties,
                defaultColumnWidth: newColumnWidth,
                defaultExtendedLength: newExtendedLength,
                columns: newColumns,
              },
            });
            setNeedUpdateRackAgain(true);
          });

          return;
        }

        if (
          Math.abs(newCellHeight - rack.properties.defaultCellHeight) > epsilon ||
          Math.abs(newBeamThickness - rack.properties.defaultBeamThickness) > epsilon ||
          Math.abs(newExtendedLength - rack.properties.defaultExtendedLength) > epsilon
        ) {
          // update all the cell heights
          const newColumns = columns.map((column, i) => {
            const newCells = column.cells.map((cell, j) => {
              if (
                (cell.linkedHeight && cell.height !== newCellHeight) ||
                (cell.linkedBeamThickness && cell.beamThickness !== newBeamThickness)
              ) {
                return {
                  ...cell,
                  height: newCellHeight,
                  beamThickness: newBeamThickness,
                };
              }

              return cell;
            });

            const areCellsTheSame = newCells.every((cell, j) => cell === column.cells[j]);
            if (!areCellsTheSame) {
              return {
                ...column,
                cells: newCells,
              };
            }

            return column;
          });

          const areColumnsTheSame = newColumns.every((column, i) => column === columns[i]);

          startTransition(() => {
            actions.updateRack({
              ...rack,
              properties: {
                ...rack.properties,
                defaultCellHeight: newCellHeight,
                defaultBeamThickness: newBeamThickness,
                defaultExtendedLength: newExtendedLength,
                columns: areColumnsTheSame ? rack.properties.columns : newColumns,
              },
            });

            setNeedUpdateRackAgain(true);
          });

          return;
        }

        // update all the uprights
        if (Math.abs(newUprightWidth - rack.properties.defaultUprightWidth) > epsilon) {
          const newUprights = uprights.map((upright, i) => {
            if (upright.linkedWidth && Math.abs(upright.width - newUprightWidth) > epsilon) {
              return {
                ...upright,
                width: newUprightWidth,
              };
            }

            return upright;
          });
          let newColumns = columns;

          const areUprightsTheSame = newUprights.every((upright, i) => upright === uprights[i]);
          if (!areUprightsTheSame) {
            newColumns = actions.computeColumnsPosition([...newColumns], newUprights);
          }

          startTransition(() => {
            actions.updateRack({
              ...rack,
              properties: {
                ...rack.properties,
                defaultUprightWidth: newUprightWidth,
                uprights: newUprights,
                columns: newColumns,
              },
            });
          });
        }

        // let's blur all inputs when validating
        setTimeout(() => inputNames.forEach((name, i) => (inputs[name] as HTMLInputElement | undefined)?.blur()), 0);
      }

      setNeedUpdateRackAgain(false);
    },
    [
      actions,
      beamThickness,
      cellHeight,
      columnWidth,
      columns,
      extendedLength,
      handleUpdateField,
      nbColumns,
      nbLevels,
      rack,
      uprightWidth,
      uprights,
    ]
  );

  useEffect(() => {
    if (loading && !isPending && !needUpdateRackAgain) {
      setLoading(false);

      // and zoom to the rack once the updates have been taken into account
      zoomToRack();
    }
  }, [isPending, loading, needUpdateRackAgain]);

  const idFormUpdateRack = 'form-update-rack';
  useEffect(() => {
    if (needUpdateRackAgain) handleUpdateRack(document.querySelector(`#${idFormUpdateRack}`) as HTMLFormElement);
  }, [handleUpdateRack, needUpdateRackAgain, rack]);

  const nbOfLoads = useMemo(() => {
    return computeNbLoadsInRack(rack);
  }, [rack]);
  const hasMultipleLoads = useMemo(() => {
    return nbOfLoads.max !== nbOfLoads.selected;
  }, [nbOfLoads.max, nbOfLoads.selected]);

  return (
    <foreignObject x={-8 * 100} y={-4 * 100} className="overflow-visible" style={{ margin: 'auto' }}>
      <Box component="div" sx={{ width: 'fit-content' }}>
        <Paper
          elevation={3}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(5),
          }}
        >
          <form id={idFormUpdateRack} onSubmit={handleUpdateRack} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="nbColumns"
                  type="number"
                  variant="standard"
                  value={nbColumns}
                  inputProps={{
                    step: 1,
                    min: nbColumnsMin,
                    max: nbColumnsMax,
                  }}
                  fullWidth
                  label="Columns"
                  title="The number of columns in the rack"
                  onChange={(e) => setNbColumns(e.target.value)}
                  onBlur={(e) =>
                    handleUpdateField(e.currentTarget as HTMLInputElement, setNbColumns, columns.length.toString())
                  }
                  onKeyDown={(e) => handleKeyDownField(e, setNbColumns, columns.length.toString())}
                  disabled={isConveyor}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="nbLevels"
                  type="number"
                  variant="standard"
                  value={nbLevels}
                  inputProps={{
                    step: 1,
                    min: nbLevelsMin,
                    max: nbLevelsMax,
                  }}
                  fullWidth
                  label="Levels"
                  title="The number of levels per column in the rack"
                  onChange={(e) => setNbLevels(e.target.value)}
                  onBlur={(e) =>
                    handleUpdateField(e.currentTarget as HTMLInputElement, setNbLevels, nbLevelsInitialValue)
                  }
                  onKeyDown={(e) => handleKeyDownField(e, setNbLevels, nbLevelsInitialValue)}
                  disabled={isConveyor}
                />
              </Grid>
            </Grid>
            <TextField
              name="columnWidth"
              type="number"
              variant="standard"
              value={columnWidth}
              inputProps={{
                step: 10,
                min: columnWidthMin * 1000,
                max: columnWidthMax * 1000,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      mm
                      <AnimatedIcon triggerOnClick={true} defaultAnimation="flash">
                        <Tooltip title="Link back all columns to this master value">
                          <IconButton onClick={() => handleLinkAllColumnProp('width')}>
                            <AddLinkIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AnimatedIcon>
                      <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself">
                        <Tooltip title="Restore the value to the previous value">
                          <IconButton
                            onClick={() => setColumnWidth(columnWidthInitialValue)}
                            color={columnWidth !== columnWidthInitialValue ? 'primary' : undefined}
                          >
                            <SettingsBackupRestoreIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AnimatedIcon>
                    </>
                  </InputAdornment>
                ),
              }}
              sx={{
                marginTop: theme.spacing(1),
              }}
              fullWidth
              label="Column width"
              title="Space between two uprights"
              onChange={(e) => setColumnWidth(e.target.value)}
              onBlur={(e) =>
                handleUpdateField(e.currentTarget as HTMLInputElement, setColumnWidth, columnWidthInitialValue)
              }
              onKeyDown={(e) => handleKeyDownField(e, setColumnWidth, columnWidthInitialValue)}
            />
            <Tooltip
              title="Height of the cells. The beam height is included in the cell height"
              disableHoverListener
              placement="top"
              arrow
            >
              <TextField
                name="cellHeight"
                type="number"
                variant="standard"
                value={cellHeight}
                inputProps={{
                  step: 10,
                  min: cellHeightMinHeight * 1000,
                  max: cellHeightMaxHeight * 1000,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <>
                        mm
                        <AnimatedIcon triggerOnClick={true} defaultAnimation="flash">
                          <Tooltip title="Link back all the cells to this master value">
                            <IconButton onClick={() => handleLinkAllCells('height')}>
                              <AddLinkIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </AnimatedIcon>
                        <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself">
                          <Tooltip title="Restore the value to the previous value">
                            <IconButton
                              onClick={() => setCellHeight(cellHeightInitialValue)}
                              color={cellHeight !== cellHeightInitialValue ? 'primary' : undefined}
                            >
                              <SettingsBackupRestoreIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </AnimatedIcon>
                      </>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: theme.spacing(1) }}
                fullWidth
                label="Cell height"
                onChange={(e) => setCellHeight(e.target.value)}
                onBlur={(e) =>
                  handleUpdateField(e.currentTarget as HTMLInputElement, setCellHeight, cellHeightInitialValue)
                }
                onKeyDown={(e) => handleKeyDownField(e, setCellHeight, cellHeightInitialValue)}
              />
            </Tooltip>
            <TextField
              name="extendedLength"
              type="number"
              variant="standard"
              value={extendedLength}
              inputProps={{
                step: 10,
                min: extendedLengthMin * 1000,
                // max: columnWidthMax * 1000,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      mm
                      <AnimatedIcon triggerOnClick={true} defaultAnimation="flash">
                        <Tooltip title="Link back all columns to this master value">
                          <IconButton onClick={() => handleLinkAllColumnProp('extendedLength')}>
                            <AddLinkIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AnimatedIcon>
                      <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself">
                        <Tooltip title="Restore the value to the previous value">
                          <IconButton
                            onClick={() => setExtendedLength(extendedLengthInitialValue)}
                            color={extendedLength !== extendedLengthInitialValue ? 'primary' : undefined}
                          >
                            <SettingsBackupRestoreIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AnimatedIcon>
                    </>
                  </InputAdornment>
                ),
              }}
              sx={{
                marginTop: theme.spacing(1),
              }}
              fullWidth
              label="Extended Length"
              title="Length of the segment in front of the rack"
              onChange={(e) => setExtendedLength(e.target.value)}
              onBlur={(e) =>
                handleUpdateField(e.currentTarget as HTMLInputElement, setExtendedLength, extendedLengthInitialValue)
              }
              onKeyDown={(e) => handleKeyDownField(e, setExtendedLength, extendedLengthInitialValue)}
            />
            {!isConveyor && (
              <TextField
                name="beamThickness"
                type="number"
                variant="standard"
                value={beamThickness}
                inputProps={{
                  step: 10,
                  min: beamThicknessMin * 1000,
                  max: beamThicknessMax * 1000,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <>
                        mm
                        <AnimatedIcon triggerOnClick={true} defaultAnimation="flash">
                          <Tooltip title="Link back all the cells to this master value">
                            <IconButton onClick={() => handleLinkAllCells('beamThickness')}>
                              <AddLinkIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </AnimatedIcon>
                        <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself">
                          <Tooltip title="Restore the value to the previous value">
                            <IconButton
                              onClick={() => setBeamThickness(beamThicknessInitialValue)}
                              color={beamThickness !== beamThicknessInitialValue ? 'primary' : undefined}
                            >
                              <SettingsBackupRestoreIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </AnimatedIcon>
                      </>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: theme.spacing(1) }}
                fullWidth
                label="Beam thickness"
                title="Thickness of the beams"
                onChange={(e) => setBeamThickness(e.target.value)}
                onBlur={(e) =>
                  handleUpdateField(e.currentTarget as HTMLInputElement, setBeamThickness, beamThicknessInitialValue)
                }
                onKeyDown={(e) => handleKeyDownField(e, setBeamThickness, beamThicknessInitialValue)}
              />
            )}
            <TextField
              name="uprightWidth"
              type="number"
              variant="standard"
              value={uprightWidth}
              inputProps={{
                step: 10,
                min: uprightWidthMin * 1000,
                max: uprightWidthMax * 1000,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      mm
                      <AnimatedIcon triggerOnClick={true} defaultAnimation="flash">
                        <Tooltip title="Link back all the uprights to this master value">
                          <IconButton onClick={() => handleClickAllUprights('width')}>
                            <AddLinkIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AnimatedIcon>
                      <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself">
                        <Tooltip title="Restore the value to the previous value">
                          <IconButton
                            onClick={() => setUprightWidth(uprightWidthInitialValue)}
                            color={uprightWidth !== uprightWidthInitialValue ? 'primary' : undefined}
                          >
                            <SettingsBackupRestoreIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </AnimatedIcon>
                    </>
                  </InputAdornment>
                ),
              }}
              sx={{ marginTop: theme.spacing(1) }}
              fullWidth
              label="Upright width"
              title="Width of the uprights"
              onChange={(e) => setUprightWidth(e.target.value)}
              onBlur={(e) =>
                handleUpdateField(e.currentTarget as HTMLInputElement, setUprightWidth, uprightWidthInitialValue)
              }
              onKeyDown={(e) => handleKeyDownField(e, setUprightWidth, uprightWidthInitialValue)}
            />

            <Grid container sx={{ marginTop: theme.spacing(4), width: '300px', justifyContent: 'space-evenly' }}>
              <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <Tooltip title="Zoom to the rack">
                  <IconButton onClick={() => zoomToRack()}>
                    <ZoomOutMapIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  endIcon={!loading ? <TaskAltIcon /> : <CircularProgress size="20px" />}
                  disabled={loading}
                >
                  Update rack
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>

      <Box
        component="span"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: 'fit-content',
          pointerEvents: 'none',
          '& > *': {
            pointerEvents: 'auto',
          },
        }}
      >
        <AdditionalInformation hasMultipleLoads={hasMultipleLoads} nbOfLoads={nbOfLoads} />

        {editMode && <AdditionalActions actions={actions} />}
      </Box>
    </foreignObject>
  );
}

interface AdditionalInformationProps {
  nbOfLoads: ReturnType<typeof computeNbLoadsInRack>;
  hasMultipleLoads: boolean;
}
function AdditionalInformation(props: AdditionalInformationProps): JSX.Element {
  const { nbOfLoads, hasMultipleLoads } = props;

  return (
    <Paper
      elevation={3}
      sx={{
        width: '380px',
        padding: theme.spacing(5),
        marginTop: theme.spacing(20),
      }}
    >
      <Typography variant="h6" color={theme.palette.grey[700]}>
        <InfoIcon sx={{ verticalAlign: 'sub', marginRight: theme.spacing(2) }} /> Additional Information
      </Typography>
      <List dense={false}>
        <ListItem>
          <ListItemText primary={nbOfLoads.max} secondary={'Maximum number of loads'} />
        </ListItem>
        {hasMultipleLoads && (
          <ListItem>
            <ListItemText
              primary={nbOfLoads.selected}
              secondary={
                <>
                  Number of loads with the currently displayed load
                  <HelpIconTooltip title="You enabled the mix pallet feature and have cell templates with different loads position in it." />
                </>
              }
            />
          </ListItem>
        )}
      </List>
    </Paper>
  );
}

interface AdditionalActionsProps {
  actions: ActionsRack;
}
function AdditionalActions(props: AdditionalActionsProps): JSX.Element {
  const { actions } = props;

  return (
    <Paper
      elevation={3}
      sx={{
        width: '400px',
        padding: theme.spacing(5),
        marginTop: theme.spacing(20),
        marginLeft: theme.spacing(20),
        marginBottom: 'auto',
      }}
    >
      <Typography variant="h6" color={theme.palette.grey[700]}>
        <InfoIcon sx={{ verticalAlign: 'sub', marginRight: theme.spacing(2) }} /> Actions
      </Typography>
      <List dense={false}>
        <ListItem>
          <ListItemText primary="Mirror the rack" secondary="Reverse the rack like in a mirror" />
          <ListItemSecondaryAction>
            <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself">
              <IconButton onClick={() => actions.mirrorRack()}>
                <FlipCameraAndroid />
              </IconButton>
            </AnimatedIcon>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  );
}
