import { getDistanceBetweenPoints } from 'librarycircuit/utils/utils';
import type { CircuitTurn } from 'models/circuit';
import { getRadiusFrom3Points } from './get-radius-from-3-points';

/**
 * Estimates the radius of a turn.
 *
 * @param turn - The turn to estimate the radius of.
 * @returns The estimated radius of the turn, or undefined if the turn is invalid or the radius cannot be estimated.
 */
export function estimateRadiusOfTurn(turn: CircuitTurn): number | undefined {
  /**
   * In this function, the goal is to approximate the radius of the turn even if the turn does not have a radius
   */
  if (turn?.properties?.drivenBy !== 'StartPoint' || !turn) return;

  const nbPts = turn.geometry.coordinates.length;

  let traveledDistance = 0;
  let firstPtIndexTurn = 0;
  // we ignore the start point offset of the turn, because it is just extra straight distance, it is not an arc of circle
  // it would not have any meaning to consider it

  if (turn.properties.startPointOffset) {
    const maxNbIter = 1e4; // to avoid infinite loop
    let i = 0;
    while (
      traveledDistance < turn.properties.startPointOffset &&
      firstPtIndexTurn < turn.geometry.coordinates.length - 1
    ) {
      const A = turn.geometry.coordinates[firstPtIndexTurn];
      const B = turn.geometry.coordinates[firstPtIndexTurn + 1];
      traveledDistance += getDistanceBetweenPoints(A, B);
      firstPtIndexTurn++;
      i++;

      if (i > maxNbIter) {
        // eslint-disable-next-line no-console
        console.error('Too many iterations', {
          traveledDistance,
          turn,
          i,
          ptIndex: firstPtIndexTurn,
          firstPtIndexTurn,
        });

        return undefined;
      }
    }
  }

  const middleIndex = Math.floor(firstPtIndexTurn + (nbPts - 1) / 2);

  const A = turn.geometry.coordinates[firstPtIndexTurn]; // first point
  const B = turn.geometry.coordinates[middleIndex]; // middle point
  const C = turn.geometry.coordinates[nbPts - 1]; // last point

  if (A === undefined || B === undefined || C === undefined) {
    // eslint-disable-next-line no-console
    console.error('point not found', {
      A,
      B,
      C,
    });

    return;
  }

  const ptA = {
    x: A[0] / 100,
    y: A[1] / 100,
  };
  const ptB = {
    x: B[0] / 100,
    y: B[1] / 100,
  };
  const ptC = {
    x: C[0] / 100,
    y: C[1] / 100,
  };

  const radius = getRadiusFrom3Points(ptA, ptB, ptC);

  return radius;
}
