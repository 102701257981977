import AddCircleIcon from '@mui/icons-material/AddCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import DeleteIcon from '@mui/icons-material/Delete';
import FireTruckIcon from '@mui/icons-material/FireTruck';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { CollapseMore } from 'components/utils/collapse-more';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useConfirm } from 'material-ui-confirm';
import type { CircuitDevice } from 'models/circuit';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';

type ZeroOrOneString = '0' | '1';
function isZeroOrOneString(value: string): value is ZeroOrOneString {
  return value === '0' || value === '1';
}

export function FireAlarm({ projectOpened }: { projectOpened: number }): JSX.Element {
  const [locked, setLocked] = useState(true);

  const confirm = useConfirm();

  const fireAlarmEnabledPrefName = 'fireAlarm/enabled';
  const fireAlarmEnabledDefaultValue = false;
  const [fireAlarmEnabled, setFireAlarmEnabled] = useState<boolean>(fireAlarmEnabledDefaultValue);

  const isNEWv26OrMore = (PreferencesService.getNEW4XCoreVersion() || 0) >= 26;
  const fireAlarmDevicesPrefName = isNEWv26OrMore ? 'fireAlarm/deviceNames' : 'fireAlarm/deviceID';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fireAlarmDevicesDefaultValue: string[] = useMemo(() => [], []);
  const [fireAlarmDevices, setFireAlarmDevices] = useState<string[]>(fireAlarmDevicesDefaultValue);

  const fireAlarmInputPrefName = 'fireAlarm/inputID';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fireAlarmInputDefaultValue: string[] = useMemo(() => [], []);
  const [fireAlarmInput, setFireAlarmInput] = useState<string[]>(fireAlarmInputDefaultValue);

  const fireAlarmTriggeringValuePrefName = 'fireAlarm/triggeringValue';
  const fireAlarmTriggeringValueDefaultValue = '1';
  const [fireAlarmTriggeringValue, setFireAlarmTriggeringValue] = useState<ZeroOrOneString>(
    fireAlarmTriggeringValueDefaultValue
  );

  const devicesIds = useAppSelector((state) => state.circuit.present.devices.ids);
  const devices = useAppSelector((state) => state.circuit.present.devices.entities);

  const [deviceToBeAdded, setDeviceToBeAdded] = useState<CircuitDevice | undefined>(undefined);
  const refSelectDevice = useRef<HTMLSelectElement>(null);
  const refSelectInput = useRef<HTMLSelectElement>(null);

  /**
   * we retrieve the project preferences, if the preference is not found (normal behavior, some preferences are not defined in the project),
   * getPreferenceValue throws an error, we need to catch it and keep the default value
   */
  useLayoutEffect(() => {
    if (!projectOpened) return;

    try {
      setFireAlarmEnabled((PreferencesService.getPreferenceValue(fireAlarmEnabledPrefName) as string) === '1');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${fireAlarmEnabledPrefName} from preferences`, e);
    }
  }, [projectOpened]);

  useLayoutEffect(() => {
    if (!projectOpened) return;

    if (isNEWv26OrMore) {
      try {
        const newVal = PreferencesService.getPreferenceValue('fireAlarm/deviceNames');

        if (Array.isArray(newVal)) {
          setFireAlarmDevices(newVal);
        } else {
          setFireAlarmDevices(fireAlarmDevicesDefaultValue);
        }
      } catch (e) {
        try {
          const newVal = PreferencesService.getPreferenceValue('fireAlarm/deviceID');

          if (Array.isArray(newVal)) {
            const newDevicesNames = newVal.map(
              (deviceIndex: string) => devices[devicesIds[parseInt(deviceIndex, 10)]]?.properties.name
            );
            setFireAlarmDevices(newVal);
            PreferencesService.setPreferenceValue('fireAlarm/deviceNames', newDevicesNames, true);
          } else {
            setFireAlarmDevices(fireAlarmDevicesDefaultValue);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(`Could not get ${fireAlarmDevicesPrefName} from preferences`, e);
        }
      }
    } else {
      try {
        const newVal = PreferencesService.getPreferenceValue(fireAlarmDevicesPrefName);
        if (Array.isArray(newVal)) {
          setFireAlarmDevices(newVal);
        } else {
          setFireAlarmDevices(fireAlarmDevicesDefaultValue);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Could not get ${fireAlarmDevicesPrefName} from preferences`, e);
      }
    }
  }, [fireAlarmDevicesDefaultValue, projectOpened, fireAlarmDevicesPrefName, isNEWv26OrMore, devices, devicesIds]);

  useLayoutEffect(() => {
    if (!projectOpened) return;

    try {
      const newVal = PreferencesService.getPreferenceValue(fireAlarmInputPrefName);
      if (Array.isArray(newVal)) {
        setFireAlarmInput(newVal);
      } else {
        setFireAlarmInput(fireAlarmInputDefaultValue);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${fireAlarmInputPrefName} from preferences`, e);
    }
  }, [fireAlarmInputDefaultValue, projectOpened]);

  useLayoutEffect(() => {
    if (!projectOpened) return;

    try {
      const val = (
        (PreferencesService.getPreferenceValue(fireAlarmTriggeringValuePrefName) as string) ??
        fireAlarmTriggeringValueDefaultValue
      ).toString();

      if (isZeroOrOneString(val)) setFireAlarmTriggeringValue(val);
      // eslint-disable-next-line no-console
      else console.log(`Invalid value for ${fireAlarmTriggeringValuePrefName}: ${val}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${fireAlarmTriggeringValuePrefName} from preferences`, e);
    }
  }, [projectOpened]);

  const onChangeEnabled = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    try {
      const [ok] = await PreferencesService.setPreferenceValue(fireAlarmEnabledPrefName, checked ? '1' : '0', true);
      if (ok) {
        setFireAlarmEnabled(checked);

        SnackbarUtils.success(`Fire alarm ${checked ? 'enabled' : 'disabled'}`);
      } else {
        SnackbarUtils.error(`Could not ${checked ? 'enable' : 'disable'} fire alarm`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`Could not set ${fireAlarmEnabledPrefName} to preferences`, err);
    }

    setFireAlarmEnabled(checked);
  }, []);

  const handleAddDevice = useCallback(async () => {
    if (!deviceToBeAdded) return;

    const input = refSelectInput.current?.value;
    if (input === undefined) return;

    const selectedDeviceIndex = devicesIds.findIndex((deviceId) => deviceId === deviceToBeAdded.id);
    if (selectedDeviceIndex === -1) {
      SnackbarUtils.error('Could not find selected device');

      return;
    }

    const newDevicesIndexes = [...fireAlarmDevices, selectedDeviceIndex.toString()];
    const newDevicesNames = newDevicesIndexes.map(
      (deviceIndex) => devices[devicesIds[parseInt(deviceIndex, 10)]]?.properties.name
    );
    const newInputs = [...fireAlarmInput, input];

    newDevicesNames.forEach((deviceName, index) => {
      if (!deviceName) {
        SnackbarUtils.error(`Could not find device name for device ${index}`);
        // eslint-disable-next-line no-console
        console.error(`Could not find device name for device ${index}`);
      }
    });

    const errMsg = `An error occured while updating the preferences file, the device was not added to the fire alarm`;
    try {
      const [okDevice] = await PreferencesService.setPreferenceValue(
        fireAlarmDevicesPrefName,
        isNEWv26OrMore ? newDevicesNames : newDevicesIndexes,
        true
      );
      if (okDevice) {
        const newDevicesCopy = [...newDevicesIndexes];
        newDevicesCopy[newDevicesCopy.length - 1] = deviceToBeAdded.properties.name;
        setFireAlarmDevices(newDevicesIndexes);

        const [okInput] = await PreferencesService.setPreferenceValue(fireAlarmInputPrefName, newInputs, true);
        if (okInput) {
          setFireAlarmInput(newInputs);
        } else {
          SnackbarUtils.error(errMsg);
        }
      } else {
        SnackbarUtils.error(errMsg);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`Could not set ${fireAlarmDevicesPrefName} to preferences (or ${fireAlarmInputPrefName})`, err);

      SnackbarUtils.error(errMsg);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesIds, fireAlarmDevices, fireAlarmInput, deviceToBeAdded]);

  const handleRemoveDevice = useCallback(
    async (deviceIndex: number) => {
      try {
        await confirm({ title: `Are you sure you want to remove this device from the fire alarm?`, allowClose: false });
      } catch (e) {
        return;
      }

      const newDevicesIndexes = fireAlarmDevices.filter((_, index) => index !== deviceIndex);
      const newDevicesNames = newDevicesIndexes.map(
        (deviceIndex) => devices[devicesIds[parseInt(deviceIndex, 10)]]?.properties.name
      );
      const newInputs = fireAlarmInput.filter((_, index) => index !== deviceIndex);

      const errMsg = `An error occured while updating the preferences file, the device was not removed to the fire alarm`;
      try {
        const [okDevice] = await PreferencesService.setPreferenceValue(
          fireAlarmDevicesPrefName,
          isNEWv26OrMore ? newDevicesNames : newDevicesIndexes,
          true
        );

        if (okDevice) {
          setFireAlarmDevices(newDevicesIndexes);

          const [okInput] = await PreferencesService.setPreferenceValue(fireAlarmInputPrefName, newInputs, true);
          if (okInput) {
            setFireAlarmInput(newInputs);

            SnackbarUtils.success(`Device removed from fire alarm`);
          } else {
            SnackbarUtils.error(errMsg);
          }
        } else {
          SnackbarUtils.error(errMsg);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(`Could not set ${fireAlarmDevicesPrefName} to preferences (or ${fireAlarmInputPrefName})`, err);

        SnackbarUtils.error(errMsg);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm, fireAlarmDevices, fireAlarmInput]
  );

  const selectTriggeringValueRef = useRef<HTMLSelectElement>(null);

  const handleChangeTriggeringValue = useCallback(async (e: SelectChangeEvent<string | number>) => {
    const newValue = e.target?.value.toString();

    if (!isZeroOrOneString(newValue)) {
      // eslint-disable-next-line no-console
      console.log(`Invalid value for ${fireAlarmTriggeringValuePrefName}: ${newValue}`);

      return;
    }

    const errMsg = `An error occured while updating the preferences file, the triggering value has not been updated`;
    try {
      const [ok] = await PreferencesService.setPreferenceValue(fireAlarmTriggeringValuePrefName, newValue, true);
      if (ok) {
        setFireAlarmTriggeringValue(newValue);

        SnackbarUtils.success(`Fire alarm will be triggered if the input is ${newValue === '1' ? 'ON' : 'OFF'}`);
      } else {
        SnackbarUtils.error(errMsg);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`Could not set ${fireAlarmTriggeringValuePrefName} to preferences`, err);

      SnackbarUtils.error(errMsg);
    }
  }, []);

  const handleChangeLocked = useCallback(
    async (e: React.MouseEvent) => {
      // we stop the propagation of the event to avoid the collapse to be opened/closed
      e.stopPropagation();

      if (!locked) {
        setLocked(true);

        return;
      }

      try {
        await confirm({
          title: 'You are about to unlock the fire alarm preferences.',
          description: 'These ones have safety effects. Are you sure you want to continue?',
          allowClose: false,
        });
        setLocked(false);
      } catch (e) {}
    },
    [confirm, locked]
  );

  const editSafetyPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_safety_configuration');
  }, []);

  return (
    <CollapseMore
      title={
        <>
          <FireTruckIcon
            sx={{ marginRight: theme.spacing(2), color: 'rgba(0, 0, 0, 0.54)', verticalAlign: 'middle' }}
          />
          Fire Alarm{' '}
          <Tooltip title={`${fireAlarmEnabled ? 'Deactivate' : 'Activate'} the fire alarm`}>
            <Box component="span">
              <Switch
                checked={fireAlarmEnabled}
                onChange={onChangeEnabled}
                onClick={(e) => {
                  // we stop the propagation of the click event to avoid the collapse to be toggled
                  e.stopPropagation();
                }}
                disabled={locked}
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={
              editSafetyPerm
                ? `${locked ? 'Unlock' : 'Lock'} the fire alarm`
                : 'You are not authorized to edit the safety configuration'
            }
          >
            <Box component="span">
              <IconButton onClick={handleChangeLocked} disabled={!editSafetyPerm}>
                {locked ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>
            </Box>
          </Tooltip>
        </>
      }
    >
      <Table size="small">
        <TableHead sx={{ fontWeight: 'bold !important' }}>
          <TableRow>
            <TableCell>Device</TableCell>
            <TableCell>Input</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fireAlarmDevices.map((deviceIndex, index) => {
            const device =
              devices[devicesIds[deviceIndex]] ?? Object.values(devices).find((d) => d.properties.name === deviceIndex);
            const inputIndex = fireAlarmInput[index];
            const input = device?.properties.pinsIn?.[parseInt(inputIndex, 10)];

            return (
              <TableRow key={`${deviceIndex}-${index}`}>
                <TableCell>{device ? device.properties.name : deviceIndex}</TableCell>
                <TableCell>{input?.name ?? inputIndex}</TableCell>
                <TableCell>
                  <Tooltip title="Remove device from fire alarm">
                    <Box component="span">
                      <IconButton onClick={() => handleRemoveDevice(index)} disabled={locked}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell>
              <Select
                value={typeof deviceToBeAdded?.id === 'string' ? deviceToBeAdded.id : ''}
                placeholder="Select a device"
                onChange={(e) => {
                  const value = (e?.target as HTMLSelectElement)?.value as string | undefined;
                  if (value) {
                    const device = devices[value];
                    if (device) setDeviceToBeAdded(device);
                  }
                }}
                inputRef={refSelectDevice}
                fullWidth
                displayEmpty
                variant="standard"
                size="small"
                disabled={locked}
              >
                {devicesIds.map((id) => {
                  const device = devices[id];

                  return (
                    <MenuItem key={id} value={id}>
                      {device.properties.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell>
              <Select
                placeholder="Select an input"
                inputRef={refSelectInput}
                fullWidth
                displayEmpty
                defaultValue={''}
                disabled={!deviceToBeAdded || locked}
                variant="standard"
                size="small"
              >
                {!!(deviceToBeAdded && deviceToBeAdded.properties.pinsIn && deviceToBeAdded.properties.pinsIn.length) &&
                  deviceToBeAdded.properties.pinsIn.map((pinsIn, indexPin) => {
                    return (
                      <MenuItem key={`${indexPin}-${pinsIn.name}`} value={indexPin}>
                        {pinsIn.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </TableCell>
            <TableCell>
              <Tooltip title="Add device to fire alarm">
                <Box component="span">
                  <IconButton onClick={handleAddDevice} disabled={!deviceToBeAdded || locked}>
                    <AddCircleIcon />
                  </IconButton>
                </Box>
              </Tooltip>
              <HelpIconTooltip
                title={
                  <>
                    We define here the devices that will be used to trigger the fire alarm. The fire alarm will be
                    triggered if the associated input is ON (or OFF, depending on the triggering value).
                  </>
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <List>
        <ListItem>
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Select
                value={fireAlarmTriggeringValue || ''}
                onChange={handleChangeTriggeringValue}
                variant="standard"
                size="small"
                inputRef={selectTriggeringValueRef}
                disabled={locked}
                sx={{ marginBottom: '0.5rem' }}
              >
                <MenuItem value={0}>Inactive</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
              </Select>
            }
            secondary={
              <>
                Triggering Value
                <HelpIconTooltip
                  title={`Whether we trigger the fire alarm when the input is active or inactive`}
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </>
            }
          />
        </ListItem>
      </List>
    </CollapseMore>
  );
}
