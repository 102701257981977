import type { DefiningType } from 'components/core/dialog-displayers/dialog-image-scaling/dialog-image-scaling';
import * as d3 from 'd3';
import { findShapeOrientation } from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { getConfig } from 'utils/config';

const POINT_RADIUS = 5;

export let zoomPointerMapScaling: d3.ZoomBehavior<Element, unknown> | undefined;

type Direction = 'horizontal' | 'vertical' | 'same-horizontal' | 'same-vertical';
const lightRed = '#ff6666';

export class MapScalingDrawing {
  protected container: SimpleSelection<HTMLDivElement>;
  protected rootNode: SimpleSelection<SVGElement>;
  protected mainNode: SimpleSelection<SVGGElement>;
  protected firstPointDrawn?: [number, number];
  protected secondPointDrawn?: [number, number];
  protected setDefiningType: (type: 'width' | 'height' | 'custom') => void;

  private zoomFactor = 1;

  private draggingPointId: string | undefined;

  constructor(container: HTMLDivElement, setDefiningType: (type: DefiningType) => void) {
    this.onMouseClick = this.onMouseClick.bind(this);
    this.container = d3.select(container);
    this.rootNode = this.createRootNode();
    this.mainNode = this.createMainNode();
    this.initRootEvents();
    this.setDefiningType = setDefiningType;
  }

  protected createRootNode(): SimpleSelection<SVGElement> {
    this.container.selectAll('svg').remove(); // remove the previous svg if it exists

    return this.container
      .append<SVGElement>('svg:svg')
      .attr('id', 'map-image-scaling-root-node')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('pointer-events', 'visible')
      .attr('font-family', 'var(--sans-serif)')
      .attr('font-size', 16);
  }

  protected createMainNode(): SimpleSelection<SVGGElement> {
    // remove the previous main node if it exists
    this.rootNode.selectAll('g').remove();

    // add the main node
    const mainNode = this.rootNode.append<SVGGElement>('svg:g');

    mainNode.attr('id', 'map-image-scaling-main-node');

    // we disable the context menu because we have the drag on the right click, otherwise it will open the context menu every time we drag
    mainNode.node()?.addEventListener('contextmenu', (e) => e.preventDefault());

    const zoom = d3
      .zoom()
      .filter(function () {
        // we change this function because we want to use the right click button for dragging
        switch (d3.event.type) {
          case 'mousedown':
            return d3.event.button === 0;
          case 'wheel':
            return d3.event.button === 0;
          default:
            return false;
        }
      })
      .on('zoom', () => {
        if (!isFinite(d3.event.transform.k)) {
          // eslint-disable-next-line no-console
          console.error('not finite');

          return;
        }

        mainNode.attr('transform', d3.event.transform);

        this.zoomFactor = d3.event.transform.k as number;

        // we update the points
        this.draw(this.zoomFactor);
      });

    this.rootNode.call(zoom as any).on('dblclick.zoom', null);

    zoomPointerMapScaling = zoom;

    return mainNode;
  }

  protected initRootEvents(): void {
    this.mainNode.on('click', this.onMouseClick);
  }

  protected onMouseClick(): void {
    const mainNode = this.mainNode.node() as SVGSVGElement;
    const unscaledMousePosition = d3.mouse(mainNode);
    const mousePosition = unscaledMousePosition;

    if (!this.firstPointDrawn) {
      this.firstPointDrawn = mousePosition;
    } else if (!this.secondPointDrawn) {
      this.secondPointDrawn = mousePosition;

      this.drawProjection();
    } else {
      return;
    }

    delete this.draggingPointId;

    this.draw(this.zoomFactor);
  }

  protected draw(zoomFactor = 1): void {
    const values: number[][] = [];

    if (this.firstPointDrawn) {
      values.push(this.firstPointDrawn);
    }

    if (this.secondPointDrawn) {
      values.push(this.secondPointDrawn);
    }

    const onDrag = (): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const event = d3.event;

      const mainNode = this.mainNode.node() as SVGSVGElement;
      const unscaledMousePosition = d3.mouse(mainNode);
      const mousePosition = unscaledMousePosition;

      let point = d3.select<SVGCircleElement, any>(event.sourceEvent.target as SVGCircleElement);
      const pointId = point.attr('point-id');

      // when we go out of the circle, we don't have the point id because the event target is the background image
      // we save it for later if we have it
      if (pointId) {
        this.draggingPointId = pointId;
      } else {
        // otherwise we get it from the saved value
        const pointId = this.draggingPointId;
        point = this.mainNode.select(`circle[point-id="${pointId}"]`);
      }

      const holdingShift = !!event.sourceEvent.shiftKey;
      let direction: Direction | undefined;

      if (this.firstPointDrawn && this.secondPointDrawn) {
        direction =
          Math.abs(this.firstPointDrawn[0] - this.secondPointDrawn[0]) <=
          Math.abs(this.firstPointDrawn[1] - this.secondPointDrawn[1])
            ? 'horizontal'
            : 'vertical';
      }

      if (this.draggingPointId === '0') {
        if (holdingShift && direction && this.firstPointDrawn && this.secondPointDrawn) {
          this.firstPointDrawn = [
            direction === 'horizontal' ? this.secondPointDrawn[0] : mousePosition[0],
            direction === 'vertical' ? this.secondPointDrawn[1] : mousePosition[1],
          ];
        } else {
          this.firstPointDrawn = mousePosition;
        }

        point.attr('cx', this.firstPointDrawn[0]).attr('cy', this.firstPointDrawn[1]);
        this.setDefiningType('custom');
      } else if (this.draggingPointId === '1') {
        if (holdingShift && direction && this.firstPointDrawn && this.secondPointDrawn) {
          this.secondPointDrawn = [
            direction === 'horizontal' ? this.firstPointDrawn[0] : mousePosition[0],
            direction === 'vertical' ? this.firstPointDrawn[1] : mousePosition[1],
          ];
        } else {
          this.secondPointDrawn = mousePosition;
        }

        point.attr('cx', this.secondPointDrawn[0]).attr('cy', this.secondPointDrawn[1]);
        this.setDefiningType('custom');
      } else {
        // eslint-disable-next-line no-console
        console.error('Invalid point id, found', pointId);
      }

      this.drawProjection();
    };

    const dragHandler = d3
      .drag<SVGGElement, undefined>()
      .on('drag', onDrag)
      .on('end', () => {
        // we're not dragging anymore, we remove the saved value
        delete this.draggingPointId;
      });

    this.mainNode.selectAll('circle').remove();

    const points = this.mainNode.selectAll<SVGCircleElement, any>('circle').data(values);

    points
      .enter()
      .append<SVGCircleElement>('svg:circle')
      .merge(points)
      .attr('cx', (d) => {
        return d ? d[0] : null;
      })
      .attr('cy', (d) => {
        return d ? d[1] : null;
      })
      .attr('point-id', (_, i) => i)
      .style('fill', 'red')
      .style('cursor', 'pointer')
      .attr('r', POINT_RADIUS / zoomFactor)
      .call(dragHandler as any);

    this.drawProjection();
  }

  public getPointsDistance(): number | undefined {
    if (this.firstPointDrawn && this.secondPointDrawn) {
      return getDistanceBetweenPoints(this.firstPointDrawn, this.secondPointDrawn);
    }

    return undefined;
  }

  public getImageHeight(): number | undefined {
    const transform: string | undefined = this.mainNode.attr('transform');
    const zoomLevelStr = transform?.match(/scale\((\d+\.?\d*)\)/)?.[1];
    const zoomLevel = zoomLevelStr ? parseFloat(zoomLevelStr) : 1;

    const imageSelection = this.mainNode.select('image');
    const imageNode = imageSelection?.node() as SVGImageElement;

    return (imageNode?.getBoundingClientRect().height ?? 0) / zoomLevel;
  }

  public getImageWidth(): number | undefined {
    const transform: string | undefined = this.mainNode.attr('transform');
    const zoomLevelStr = transform?.match(/scale\((\d+\.?\d*)\)/)?.[1];
    const zoomLevel = zoomLevelStr ? parseFloat(zoomLevelStr) : 1;

    const imageSelection = this.mainNode.select('image');
    const imageNode = imageSelection?.node() as SVGImageElement;

    return (imageNode?.getBoundingClientRect().width ?? 0) / zoomLevel;
  }

  public drawMapImage(imageURL: string, width: number): void {
    // remove the previous image if it exists
    this.mainNode.selectAll('image').remove();

    // set the image url (base64)
    this.mainNode.append('svg:image').attr('xlink:href', imageURL);
  }

  public getPoints(): [number, number][] | undefined {
    if (this.firstPointDrawn && this.secondPointDrawn) {
      return [this.firstPointDrawn, this.secondPointDrawn];
    }

    return undefined;
  }

  public drawProjection(): void {
    if (!this.firstPointDrawn || !this.secondPointDrawn) return;

    let direction: string | undefined;

    if (this.firstPointDrawn[0] === this.secondPointDrawn[0]) {
      direction = 'same-vertical';
    }

    if (this.firstPointDrawn[1] === this.secondPointDrawn[1]) {
      direction = 'same-horizontal';
    }

    if (!direction) {
      direction =
        Math.abs(this.firstPointDrawn[0] - this.secondPointDrawn[0]) <=
        Math.abs(this.firstPointDrawn[1] - this.secondPointDrawn[1])
          ? 'horizontal'
          : 'vertical';
    }

    let firstPath: d3.Selection<SVGPathElement, unknown, null, undefined> = this.mainNode.select('#arrow-body-1');
    let secondPath: d3.Selection<SVGPathElement, unknown, null, undefined> = this.mainNode.select('#arrow-body-2');
    if (['same-vertical', 'same-horizontal'].includes(direction)) {
      if (firstPath.size()) {
        firstPath.remove();
      }

      if (secondPath.size()) {
        secondPath.remove();
      }
    } else {
      if (!firstPath.size()) {
        firstPath = this.mainNode.append('path').attr('id', 'arrow-body-1').attr('stroke', lightRed);
      }

      firstPath
        .attr(
          'd',
          `M ${this.firstPointDrawn[0]} ${this.firstPointDrawn[1]} L ${
            direction.includes('horizontal') ? this.firstPointDrawn[0] : this.secondPointDrawn[0]
          } ${direction.includes('vertical') ? this.firstPointDrawn[1] : this.secondPointDrawn[1]}`
        )
        .attr('stroke-width', (2 * getConfig('editor').elementWidth.measurerStroke) / this.zoomFactor)
        .attr('stroke-dasharray', `${8 / this.zoomFactor} ${4 / this.zoomFactor}`);

      if (!secondPath.size()) {
        secondPath = this.mainNode.append('path').attr('id', 'arrow-body-2').attr('stroke', lightRed);
      }

      secondPath
        .attr(
          'd',
          `M ${direction === 'horizontal' ? this.secondPointDrawn[0] : this.firstPointDrawn[0]} ${
            direction === 'vertical' ? this.secondPointDrawn[1] : this.firstPointDrawn[1]
          } L ${this.secondPointDrawn[0]} ${this.secondPointDrawn[1]}`
        )
        .attr('stroke-width', (2 * getConfig('editor').elementWidth.measurerStroke) / this.zoomFactor)
        .attr('stroke-dasharray', `${8 / this.zoomFactor} ${4 / this.zoomFactor}`);
    }

    const measurerAngle = findShapeOrientation(this.firstPointDrawn, this.secondPointDrawn);
    const offsetInAbsissaDirection = Math.abs(measurerAngle) > 45 && Math.abs(measurerAngle) < 135;
    const offsetSmallMeasurer = 5;
    const textPosX =
      (this.firstPointDrawn[0] + this.secondPointDrawn[0]) / 2 + (offsetInAbsissaDirection ? -offsetSmallMeasurer : 0);
    const textPosY =
      (this.firstPointDrawn[1] + this.secondPointDrawn[1]) / 2 + (!offsetInAbsissaDirection ? offsetSmallMeasurer : 0);
    const textEl = this.mainNode.select('text');

    const distanceInputTxt = d3.select('#layout-image-distance').property('value') as string | undefined;

    if (!textEl.size()) {
      this.mainNode.append('path').attr('id', 'projection-arrow').attr('stroke', '#ffb3b3');

      this.mainNode
        .append('svg:text')
        .attr('x', textPosX)
        .attr('y', textPosY)
        .attr('stroke', 'none')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .classed('length-measurer-text', true)
        .text(`${distanceInputTxt ? distanceInputTxt + 'm' : '?'}`);

      // we need the setTimeout to wait for the text element to be rendered and get its bounding box, otherwise we get 0
      setTimeout(() => {
        const bbox = (this.mainNode.select('.length-measurer-text').node() as SVGGraphicsElement).getBBox();

        this.mainNode
          .insert('svg:rect', 'text')
          .attr('x', bbox.x)
          .attr('y', bbox.y)
          .attr('width', bbox.width)
          .attr('height', bbox.height)
          .attr('stroke', 'none')
          .classed('length-measurer-bg-txt', true);
      }, 0);
    } else {
      this.mainNode
        .select('#projection-arrow')
        .attr(
          'd',
          `M ${this.firstPointDrawn[0]} ${this.firstPointDrawn[1]} L ${this.secondPointDrawn[0]} ${this.secondPointDrawn[1]}`
        )
        .attr('stroke-width', (2 * getConfig('editor').elementWidth.measurerStroke) / this.zoomFactor)
        .attr('stroke-dasharray', `${8 / this.zoomFactor} ${4 / this.zoomFactor}`);

      this.mainNode
        .select('.length-measurer-text')
        .attr('x', textPosX)
        .attr('y', textPosY)
        .text(`${distanceInputTxt ? distanceInputTxt + 'm' : '?'}`)
        .style('font-size', `${16 / this.zoomFactor}px`);

      setTimeout(() => {
        const bbox = (this.mainNode.select('.length-measurer-text').node() as SVGGraphicsElement).getBBox();
        this.mainNode
          .select('.length-measurer-bg-txt')
          .attr('x', bbox.x)
          .attr('y', bbox.y)
          .attr('width', bbox.width)
          .attr('height', bbox.height);
      }, 0);
    }
  }

  public setPoints(points: [number, number][]): void {
    if (points.length === 2) {
      this.firstPointDrawn = points[0];
      this.secondPointDrawn = points[1];

      // idk exactly why it doesn't work without the timeout, but i guess we don't mind having it
      setTimeout(() => {
        this.draw(this.zoomFactor);
        this.drawProjection();
      }, 100);
    } else {
      throw new Error(`Invalid number of points, expected 2, got ${points.length}}`);
    }
  }
}
