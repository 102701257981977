import * as Comlink from 'comlink';
import { CircuitService, getMaxDisplayPriority } from './circuit.service';
import type { GetTurnFeature, GetTurnFeatureServiceProps, TrajectoryService } from './trajectory.service';

const worker = new Worker(new URL('./trajectory.service.ts', import.meta.url), {
  name: 'trajectory-service-worker',
});

const trajectoryProxy = Comlink.wrap<{
  getTurnFeature: GetTurnFeature;
}>(worker);

type GetTurnFeatureProps = Omit<GetTurnFeatureServiceProps, 'layerId' | 'prio'>;

export async function getTurnFeature(
  props: GetTurnFeatureProps
): Promise<ReturnType<(typeof TrajectoryService)['getTurnFeature']>> {
  const layerId = CircuitService.getSelectedLayer();
  const prio = getMaxDisplayPriority();

  const p = trajectoryProxy.getTurnFeature({ layerId, prio, ...props });

  const r = await p;

  return r;
}
