import { z } from 'zod';

export const staticBatteryDataSchema = z.object({
  /** A.h */
  capacity: z.number(),
  /** A.h */
  userCapacity: z.number(),
  /** A */
  current: z.number(),
  meanCurrent_Crate: z.number(),
  /** A */
  meanConsumption: z.number(),
  /** % */
  batteryThresholdCritical: z.number(),
  /**
   * undefined = -1,
   * LEADACID_24V = 0,
   * LEADACID_48V = 1,
   * LEADACID_80V = 2,
   * LTO_24V_LECLANCHE = 3,
   * LTO_48V_LECLANCHE = 4,
   * LTO_24V_MICROVAST = 5,
   * TPPL_24V_ENERSYS = 6,
   * TPPL_48V_ENERSYS = 7,
   * TPPL_80V_ENERSYS = 8,
   * NMC_24V_ENERSYS = 9,
   * NMC_48V_ENERSYS = 10,
   * NMC_80V_ENERSYS = 11,
   * LFP_24V_ONECHARGE = 12,
   * LFP_80V_JB = 13
   */
  type: z.number(),
  /** V */
  voltage: z.number(),
  /** % */
  rangeSOCmin: z.number(),
  /** % */
  rangeSOCmax: z.number(),
  size: z.number(),
  /** ms */
  equalizationTime: z.number(),
  /** ms */
  chargeTime: z.number(),
  autoCharge: z.number(),
});

export const dynamicBatteryDataSchema = z.object({
  /** % */
  SOC: z.number(),
  /** sec */
  lastSOCupdate: z.number(),
  /** % */
  lastSOCSent: z.number(),
  /** sec */
  chargeStartTime: z.number(),
  /** % */
  cumulatedDischarge: z.number(),
  /** ms */
  equalizationTimeLeft: z.number(),
  isFlagEqualRaised: z.number(),
  equalChargeNeeded: z.number(),
  isCharging: z.number(),
  /**
   * none = 0,
   * standard = 1,
   * opportunity = 2,
   * equalization = 3,
   * waitForBatt = 4
   */
  chargeType: z.number().optional(),
});
