import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import { cleanUpExcessSegmentLengthsAction, saveCircuitToHistoryAction } from 'actions/circuit';
import { useConfirm } from 'material-ui-confirm';
import { useCallback } from 'react';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch } from 'store';
import { isCircuitSegment } from 'utils/circuit/shape-guards';

interface CleanUpProps {
  layerId: string;
  layerName: string;
}

export function CleanUpExcessSegmentLengths({ layerName, layerId }: CleanUpProps): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const handleCleanUpExcessSegmentLengths = useCallback(
    async (layerId: string, layerName: string) => {
      try {
        await confirm({
          title: `All the excess segment lengths will be cleaned up into the layer ${layerName}?`,
          allowClose: false,
        });
      } catch (e) {
        return;
      }

      const shapesInTheLayer = CircuitService.getShapes(layerId);

      dispatch(saveCircuitToHistoryAction());

      dispatch(
        cleanUpExcessSegmentLengthsAction({
          shapes: shapesInTheLayer,
        })
      );

      const segmentsInTheLayer = shapesInTheLayer.filter(isCircuitSegment);

      // We want the number of segment that are not locked but also just the segment under 3 portions (segments not concerned by the clean up action) and the segment locked
      const numberOfUnconcernedSegments = segmentsInTheLayer.filter(
        (segment) => (segment.properties.portions.length < 3 && !segment.properties.locked) || segment.properties.locked
      ).length;

      const numberOfConcernedSegments = segmentsInTheLayer.length - numberOfUnconcernedSegments;

      if (numberOfConcernedSegments <= 0) {
        SnackbarUtils.info("No segments have been cleaned because they don't have enough portions or are locked");

        return;
      }

      const concernedSeg = numberOfConcernedSegments === 1 ? 'segment has' : 'segments have';

      if (numberOfUnconcernedSegments <= 0) {
        SnackbarUtils.success(`${numberOfConcernedSegments} ${concernedSeg} been cleaned`);

        return;
      }

      const unconcernedSeg = numberOfUnconcernedSegments === 1 ? 'segment' : 'segments';

      SnackbarUtils.info(
        <List sx={{ listStyleType: 'disc', padding: 0, marginLeft: 3 }}>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            <ListItemText>
              {numberOfConcernedSegments} {concernedSeg} been cleaned
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            <ListItemText>
              {numberOfUnconcernedSegments} {unconcernedSeg} not affected (locked or cleaning not needed)
            </ListItemText>
          </ListItem>
        </List>
      );
    },
    [confirm, dispatch]
  );

  return (
    <>
      <IconButton onClick={() => handleCleanUpExcessSegmentLengths(layerId, layerName)} size="large">
        <CleaningServicesIcon />
      </IconButton>
    </>
  );
}
