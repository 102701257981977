import { awareness, remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { useUsers } from 'y-presence';

export function useDisconnectedUserDataCleanup(): void {
  if (!awareness) throw new Error('Missing awareness');

  const anotherUserDisconnected = useUsers(
    awareness,
    (state) => state.size,
    (prev, next) => prev > next
  );

  const users = useUsers(awareness);

  useLayoutEffect(() => {
    if (!remoteDoc) return;

    const profiles = remoteDoc.getMap('profiles');
    const selectedShapes = remoteDoc.getMap('selectedShapes');

    const usersToRemove = new Set<string>();

    profiles.forEach((_, key) => {
      if (!users.has(+key)) usersToRemove.add(key);
    });

    selectedShapes.forEach((_, key) => {
      if (!users.has(+key)) usersToRemove.add(key);
    });

    usersToRemove.forEach((key) => {
      profiles.delete(key);
      selectedShapes.delete(key);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anotherUserDisconnected]);
}
