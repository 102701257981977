import type { EditorDrawing } from 'drawings/editor.drawing';
import type { LayerNames, LidarLayer } from 'drawings/layers';
import type { Lidar } from 'models/maps';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store';

interface LidarLayerComponentProps {
  drawing: EditorDrawing;
  layerName: LayerNames;
}

export function LidarLayerComponent(props: LidarLayerComponentProps): JSX.Element {
  const ref = useRef<SVGGElement | null>(null);
  const lidarLayerPointer = props.drawing.layers.get(props.layerName);

  const lidarData = useAppSelector((state) => state.maps.lidar[props.layerName] as Lidar);

  useEffect(() => {
    let lidarLayer: LidarLayer;
    try {
      lidarLayer = props.drawing.getLayer<LidarLayer>(props.layerName);
    } catch (e) {
      return;
    }

    const node = lidarLayer.node.node();
    if (!ref.current) return;

    while (ref.current.firstChild) {
      ref.current.removeChild(ref.current.firstChild);
    }

    if (!node) return;

    ref.current.appendChild(node);
  }, [props.drawing, lidarLayerPointer, props.layerName, lidarData]);

  useEffect(() => {
    // we need to delete the children if the lidar data is removed in the store
    if (lidarData === undefined && ref.current) {
      while (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
    }
  }, [lidarData]);

  const attr = {
    layer: props.layerName,
    canvas: 'true',
  };

  return <g ref={ref} {...attr}></g>;
}
