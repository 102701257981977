import { Rack } from 'drawings/editor-elements/rack.element';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitRack } from 'models/circuit';
import { useEffect, useMemo, useRef } from 'react';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface ZoneComponentProps {
  rack: CircuitRack;
  id: string;
  selected?: boolean;
  displayPalletOverflow: boolean;
}

export function RackComponent(props: ZoneComponentProps): JSX.Element {
  const rack = props.rack;
  const ref = useRef<SVGSVGElement>(null);

  const shape = useMemo(() => {
    if (props.id && rack && rack.id) {
      return new Rack(rack.id.toString(), rack.geometry, projection, zoomScale, rack.properties, {
        displayPalletOverflow: props.displayPalletOverflow,
      });
    }
  }, [props.displayPalletOverflow, props.id, rack]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();
      if (node) {
        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        ref.current.appendChild(node);
      }
    }
  }, [shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return (
    <g
      ref={ref}
      className={
        rack.properties.anomaly && rack.properties.anomaly.state
          ? rack.properties.anomaly.state === 2
            ? 'rack-error-state'
            : 'rack-warning-state'
          : undefined
      }
    ></g>
  );
}
