import type { SimpleSelection } from 'drawings/shared';
import type { LayerNames } from './shared';

export abstract class BaseLayer<T = unknown> {
  public readonly node: SimpleSelection<SVGGElement, T>;

  constructor(public readonly name: LayerNames) {
    this.node = this.createNode();
  }

  protected abstract createNode(): SimpleSelection<SVGGElement, T>;
}
