import type { AppState } from 'reducers/state';
import { createSelector } from 'reselect';
import { getBackgroundLidar, getForegroundLidar, getLidarUpdated, getLidarUpdating } from './lidar-reducer';
import { getMapImage, getMapImageUpdated, getMapImageUpdating } from './map-image-reducer';
import { getMapImageTiles } from './map-image-tiles-reducer';
import type { LidarState, MapImageTilesData, MapImages } from './state';

function lidarSelector(state: AppState): LidarState {
  return state.maps.lidar;
}

export const selectLidarUpdated = createSelector(lidarSelector, getLidarUpdated);
export const selectLidarUpdating = createSelector(lidarSelector, getLidarUpdating);
export const selectBackgroundLidar = createSelector(lidarSelector, getBackgroundLidar);
export const selectForegroundLidar = createSelector(lidarSelector, getForegroundLidar);

function mapImageSelector(state: AppState): MapImages {
  return state.maps.mapImage;
}

function mapImageTilesSelector(state: AppState): MapImageTilesData {
  return state.maps.mapImageTiles;
}

export const selectMapImageUpdated = createSelector(mapImageSelector, getMapImageUpdated);
export const selectMapImageUpdating = createSelector(mapImageSelector, getMapImageUpdating);
export const selectMapImage = createSelector(mapImageSelector, getMapImage);
export const selectMapImageTiles = createSelector(mapImageTilesSelector, getMapImageTiles);
