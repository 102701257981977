import { combineReducers } from 'redux';
import { reduceReducers } from 'utils/redux';
import { cameraPositionReducer } from './camera-position.reducer';
import { circuitStandardModeReducer } from './circuit-standard-mode';
import { filtersReducer } from './filters.reducer';
import { selectedShapesDataReducer } from './selected-shape-data.reducer';
import { showDynamicShortcutsReducer } from './show-dynamic-shortcuts';
import type { LocalState } from './state';

export const localReducer = reduceReducers([
  combineReducers({
    selectedShapesData: selectedShapesDataReducer,
    filters: filtersReducer,
    standardMode: circuitStandardModeReducer,
    showDynamicShortcuts: showDynamicShortcutsReducer,
    cameraPosition: cameraPositionReducer,
  }),
]);

export const getSelectedShapesData = (state: LocalState): LocalState['selectedShapesData'] => state.selectedShapesData;
