import type { Equals } from 'tsafe';
import { assert } from 'tsafe';

export type NoteSize = 'S' | 'M' | 'L' | 'XL';

/**
 * Convert a note size (S, M, L, XL) to a pixel size
 * @param noteSize the note size
 * @returns the size in pixels
 */
export function convertNoteSizeToPx(noteSize: NoteSize): number {
  if (noteSize === 'S') return 10;
  if (noteSize === 'M') return 20;
  if (noteSize === 'L') return 30;
  if (noteSize === 'XL') return 40;

  // assert if we handled all the cases
  assert<Equals<typeof noteSize, never>>();

  return 20;
}
