import type { CircuitPortion } from 'models/circuit';
import store from 'store';
import { isSegmentAnExtendedLength } from 'utils/circuit/is-segment-an-extended-length';
import { calculateCRCOfString } from 'utils/crc/crc-calculate';
import { PreferencesService } from 'utils/preferences';
import { generateHandlingStationGroupFromRack } from './generate-handling-station-group-from-rack';
import { generateHandlingStationGroupFromStockZone } from './generate-handling-station-group-from-stockzone';
import type {
  CircuitVDA5050,
  EdgeVDA5050,
  HandlingStationGroupVDA5050,
  NodeVDA5950,
  VDA5050JSONOutput,
} from './vda5050-model';

export function generateVda5050Json(circuitName = 'unknown'): VDA5050JSONOutput {
  const storeState = store.getState();
  const segmentsIds = storeState.circuit.present.segments.ids;
  const segments = storeState.circuit.present.segments.entities;
  const turnsIds = storeState.circuit.present.turns.ids;
  const turns = storeState.circuit.present.turns.entities;
  const racksIds = storeState.circuit.present.racks.ids;
  const racks = storeState.circuit.present.racks.entities;
  const stockZonesIds = storeState.circuit.present.stockZones.ids;
  const stockZones = storeState.circuit.present.stockZones.entities;

  const portions: CircuitPortion[] = [];

  let nodes: NodeVDA5950[] = [];
  // coordinates of the node "x,y,z" => id of the node
  const nodesCoordinates = new Map<string, string>();

  function addNode(node: NodeVDA5950): string {
    const precision = 2;
    const nodeCoordsStr = `${node.graphX.toFixed(precision)},${node.graphY.toFixed(precision)},${(
      node.graphZ ?? 0
    ).toFixed(precision)}`;

    if (!nodesCoordinates.has(nodeCoordsStr)) {
      const id = calculateCRCOfString(nodeCoordsStr, 10);
      node.id = id;
      nodes.push(node);

      nodesCoordinates.set(nodeCoordsStr, id);

      return id;
    }

    return nodesCoordinates.get(nodeCoordsStr) as string;
  }

  let edges: EdgeVDA5050[] = [];
  const edgesIds = new Set<string>();

  const edgesToRemove = new Set<string>();

  function addEdge(edge: EdgeVDA5050): string | undefined {
    if (edge.startNodeId === edge.endNodeId) return;

    if (!edgesIds.has(edge.id)) {
      edges.push(edge);
      edgesIds.add(edge.id);
    }

    return edge.id;
  }

  segmentsIds.forEach((segmentId) => {
    const segment = segments[segmentId];

    portions.push(...segment.properties.portions);
  });

  portions.forEach((portion, index) => {
    const { points, inStart, outStart, inEnd, outEnd } = portion;

    let isExtremity = false;
    if (portion.inStart && portion.inStart.length === 1 && (!portion.outEnd || !portion.outEnd.length))
      isExtremity = true;
    if (portion.outEnd && portion.outEnd.length === 1 && (!portion.inStart || !portion.inStart.length))
      isExtremity = true;

    // we don't want extremities
    if (isExtremity) return;

    const isExtendedLength = isSegmentAnExtendedLength(portion.id);

    const edgesToAdd: EdgeVDA5050[] = [];
    let ins: string[] | undefined = undefined;
    let outs: string[] | undefined = undefined;

    if (inEnd && outEnd) {
      ins = inEnd;
      outs = outEnd;
    } else if (inStart && outStart) {
      ins = inStart;
      outs = outStart;
    } else if (inStart && outEnd) {
      ins = inStart;
      outs = outEnd;
    }

    if (ins && outs) {
      for (let i = 0; i < ins.length; i++) {
        for (let j = 0; j < outs.length; j++) {
          let startId = ins[i];
          let endId = outs[j];

          const startNode: NodeVDA5950 = {
            id: startId,
            graphX: points[0][0] / 100,
            graphY: points[0][1] / 100,
            graphZ: 0,
            dependentNodeIds: [],
            emergencySpot: false,
          };

          const endNode: NodeVDA5950 = {
            id: endId,
            graphX: points[1][0] / 100,
            graphY: points[1][1] / 100,
            graphZ: 0,
            dependentNodeIds: [],
            emergencySpot: false,
          };

          startId = addNode(startNode);
          endId = addNode(endNode);

          const edge: EdgeVDA5050 = {
            id: `${portion.id}`,
            startNodeId: startId,
            endNodeId: endId,
          };

          edgesToAdd.push(edge);
        }
      }
    }

    edgesToAdd.forEach((edge) => {
      addEdge(edge);

      // extended length are not exported in the synaos export
      if (isExtendedLength) {
        edgesToRemove.add(edge.id);
      }
    });
  });

  turnsIds.forEach((turnId) => {
    const turn = turns[turnId];
    const origin = turn.properties.originId;
    const destination = turn.properties.destinationId;
    const coordinates = turn.geometry.coordinates;

    if (!origin || !destination) {
      // eslint-disable-next-line no-console
      console.warn('Turn without origin or destination', turn);

      return;
    }

    const startNode: NodeVDA5950 = {
      id: 'start-node-turn',
      graphX: coordinates[0][0] / 100,
      graphY: coordinates[0][1] / 100,
      graphZ: 0,
      dependentNodeIds: [],
      emergencySpot: false,
    };
    const endNode: NodeVDA5950 = {
      id: 'end-node-turn',
      graphX: coordinates[coordinates.length - 1][0] / 100,
      graphY: coordinates[coordinates.length - 1][1] / 100,
      graphZ: 0,
      dependentNodeIds: [],
      emergencySpot: false,
    };

    const startId = addNode(startNode);
    const endId = addNode(endNode);

    const edge: EdgeVDA5050 = {
      id: `${turnId}`,
      startNodeId: startId,
      endNodeId: endId,
    };

    addEdge(edge);

    const isConnectedToExtendedLength =
      (turn.properties.originId && isSegmentAnExtendedLength(turn.properties.originId)) ||
      (turn.properties.destinationId && isSegmentAnExtendedLength(turn.properties.destinationId));

    if (isConnectedToExtendedLength) {
      // duplicate the turn in reverse to allow both directions
      const edgeReverse: EdgeVDA5050 = {
        id: `${turnId}-reverse`,
        startNodeId: endId,
        endNodeId: startId,
      };

      addEdge(edgeReverse);
    }
  });

  const nodesFromNavigationGraphToRemove = new Set<string>();

  const nowInSeconds = Math.floor(Date.now() / 1000);
  const circuitId = `${circuitName}_${nowInSeconds}`;
  const navigationGraphId = `${circuitId}_graph`;

  const handlingStationGroups: HandlingStationGroupVDA5050[] = [];

  racksIds.forEach((rackId) => {
    const rack = racks[rackId];

    const { handlingStationGroup: handlingStationGroupsForThisRack, nodesToRemove } =
      generateHandlingStationGroupFromRack({
        rack,
        navigationGraphId,
        circuitEdges: edges,
      });

    handlingStationGroups.push(handlingStationGroupsForThisRack);

    nodesToRemove.forEach((nodeId) => nodesFromNavigationGraphToRemove.add(nodeId));
  });

  stockZonesIds.forEach((stockZoneId) => {
    const stockZone = stockZones[stockZoneId];

    const { handlingStationGroup: handlingStationGroupsForThisStockZone, nodesToRemove } =
      generateHandlingStationGroupFromStockZone({
        stockZone,
        navigationGraphId,
        circuitEdges: edges,
      });

    handlingStationGroups.push(handlingStationGroupsForThisStockZone);

    nodesToRemove.forEach((nodeId) => nodesFromNavigationGraphToRemove.add(nodeId));
  });

  nodes = nodes.filter((node) => {
    return !nodesFromNavigationGraphToRemove.has(node.id);
  });

  edges = edges.filter((edge) => !edgesToRemove.has(edge.id));

  const circuitVDA5050: CircuitVDA5050 = {
    id: circuitId,
    name: circuitName,
    description: circuitName,
    uploadUser: 'unknown',
    coordinateSystem: {
      latitude: 0,
      longitude: 0,
      orientationXAxisX: 1,
      orientationXAxisY: 0,
      orientationXAxisZ: 0,
      orientationYAxisX: 0,
      orientationYAxisY: 0,
      orientationYAxisZ: 0,
      orientationZAxisX: 0,
      orientationZAxisY: 0,
      orientationZAxisZ: 1,
    },
    trafficLights: [],
    waitingSpots: [],
    handlingStationGroups,
    navigationGraphs: [
      {
        id: navigationGraphId,
        nodes: nodes,
        edges,
        agvTypes: [{ typeName: 'Balyo_LHP', vendorName: 'Balyo' }],
        graphToMapTransformation: {
          xTranslation: 0,
          yTranslation: 0,
          zTranslation: 0,
          zRotation: 0,
        },
      },
    ],
    chargingStations: [],
    limitedCapacityAreas: [],
    areas: [],
    emergencyDetectors: [],
  };

  return {
    circuit: circuitVDA5050,
  };
}

/**
 * Generates a VDA5050 JSON object for a given circuit.
 *
 * This function creates a SynaOS JSON object and download it as a file.
 */
export function downloadVda5050Json(circuitName?: string): void {
  if (!circuitName)
    circuitName = (
      (PreferencesService.arePreferencesFullyLoaded() ? PreferencesService.getCircuitName() : null) ?? 'unknown'
    ).replace('.geojson', '');

  const vda5050Json = generateVda5050Json(circuitName);

  const circuitStr = JSON.stringify(vda5050Json.circuit, null, 2);

  const projectName = store.getState().project.projectName ?? 'unknown';
  const blob = new Blob([circuitStr], { type: 'application/geo+json' });

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = `${projectName}-vda5050.json`;
  a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
}
