import { createSelector } from 'reselect';
import type { AppState } from '../../reducers/state';
import { getData, getOpen, getType } from './reducer';
import type { DialogState } from './state';

export function dialogSelector(state: AppState): DialogState {
  return state.dialog;
}

export const selectDialogOpen = createSelector(dialogSelector, getOpen);
export const selectDialogData = createSelector(dialogSelector, getData);
export const selectDialogType = createSelector(dialogSelector, getType);
