import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { selectLayerGroup } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

interface LayerGroupSelectProps {}

export function LayerGroupSelect(props: LayerGroupSelectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedLayerGroup = useAppSelector((state) => state.routes.selectedLayerGroup);
  const layerGroups = useAppSelector((state) => state.circuit.present.layers.layerGroups);
  const layerGroupsArrWithoutCommon = useMemo(
    () => Object.values(layerGroups).filter((layerGroup) => layerGroup.name !== 'Common'),
    [layerGroups]
  );

  useEffect(() => {
    // if none layer group is selected, we select the first one
    if (selectedLayerGroup === null && layerGroupsArrWithoutCommon[0]) {
      dispatch(
        selectLayerGroup({
          layerGroup: layerGroupsArrWithoutCommon[0].id,
        })
      );
    }
  }, [dispatch, layerGroupsArrWithoutCommon, selectedLayerGroup]);

  const handleChangeSelectedLayerGroup = useCallback(
    (e: SelectChangeEvent) => {
      dispatch(selectLayerGroup({ layerGroup: e.target.value }));
    },
    [dispatch]
  );

  return (
    <FormControl
      fullWidth
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      <InputLabel id="layer-group-label">Layer Group</InputLabel>
      <Select
        label="Layer Group"
        labelId="layer-group-label"
        id="layer-group-select"
        value={selectedLayerGroup || layerGroupsArrWithoutCommon[0]?.id}
        onChange={handleChangeSelectedLayerGroup}
        fullWidth
      >
        {layerGroupsArrWithoutCommon.map((layerGroup) => {
          return (
            <MenuItem key={layerGroup.id} value={layerGroup.id}>
              {layerGroup.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
