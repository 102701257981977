import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { closeDialogAction } from 'actions';
import { DialogTypes } from 'models';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { rotateCircuitAndReload } from 'utils/circuit/rotate-circuit';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';

export const RotateCircuitPropertiesComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const rotateCircuit = useAppSelector((state) => state.dialog.type === DialogTypes.RotateCircuit && state.dialog.open);

  const [deltaAngle, setDeltaAngle] = useState('0');
  const [isRotating, setIsRotating] = useState(false);

  const handleClose = (): void => {
    dispatch(closeDialogAction());
  };

  const handleRotate = async (): Promise<void> => {
    const deltaAngleNum = parseFloat(deltaAngle);

    if (isNaN(deltaAngleNum) || deltaAngleNum === 0) {
      return;
    }

    setIsRotating(true);

    await rotateCircuitAndReload(deltaAngleNum);

    setIsRotating(false);
  };

  return !rotateCircuit ? (
    <> </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        position: 'absolute',
        right: theme.spacing(1),
        top: '0',
        pointerEvents: 'none',
      }}
      component="div"
    >
      <Card
        sx={{
          display: 'flex',
          background: 'white',
          pointerEvents: 'initial',
          alignSelf: 'center',
          zIndex: 401,
          maxHeight: getConfig('editor').properties.maxHeight,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexFlow: 'column',
            overflow: 'auto',
            width: '400px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: theme.spacing(1),
            }}
            component="div"
          >
            <Typography variant="h6">Rotate Circuit</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label="Angle"
            value={deltaAngle}
            onChange={(e) => setDeltaAngle(e.target.value)}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            component="div"
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <Tooltip title="Rotate Circuit">
              <Button
                onClick={handleRotate}
                variant="contained"
                color="primary"
                disabled={isRotating || deltaAngle === '0' || isNaN(parseFloat(deltaAngle))}
                fullWidth
              >
                {isRotating ? '...' : 'Rotate'}
              </Button>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
