import type { Flow } from 'flows/flows';
import type { SimulationTask } from 'simulation/simulation';
import { taskStrToNumber } from 'simulation/states';
import store from 'store';

/**
 * Returns the number of tasks that are done in the scheduler.
 *
 * @param schedulerTasks - The scheduler tasks.
 * @returns The number of tasks that are done.
 */
export function getNbTasksDone(schedulerTasks?: Record<string, SimulationTask>): number {
  if (!schedulerTasks) {
    schedulerTasks = store.getState().simulation.tasks;
  }

  const taskStatusDone = taskStrToNumber('done');
  const schedulerTasksArr = Object.values(schedulerTasks);

  return schedulerTasksArr.filter((task) => task.state === taskStatusDone).length;
}

interface GetTasksPerFlowParams {
  flows?: Flow[];
  schedulerTasks?: Record<string, SimulationTask>;
}
type GetTasksPerFlowReturn = {
  flowId: string;
  flowName: string;
  tasks: SimulationTask[];
}[];

/**
 * Returns an array of objects, each object containing the flowId, flowName, and an array of tasks associated with that flow.
 *
 * @param params - An object containing the flows and schedulerTasks.
 * @returns An array of objects, each object containing the flowId, flowName, and an array of tasks associated with that flow.
 */
export function getTasksPerFlow(params: GetTasksPerFlowParams): GetTasksPerFlowReturn {
  let { flows, schedulerTasks } = params;

  if (!schedulerTasks) {
    schedulerTasks = store.getState().simulation.tasks;
  }

  if (!flows) {
    flows = store.getState().flows.flows;
  }

  const schedulerTasksArr = Object.values(schedulerTasks);

  return flows.map((flow) => {
    return {
      flowId: flow.id,
      flowName: flow.name,
      tasks: schedulerTasksArr.filter((task) => task.taskName === flow.name),
    };
  });
}

export type NbRemainingTasksPerFlow = {
  flowId: string;
  flowName: string;
  count: number;
  countPerStep: number[];
}[];

/**
 * Returns an array of objects, each object containing the flowId, flowName, and the number of remaining tasks associated with that flow.
 *
 * @param tasksPerFlow - An array of objects, each object containing the flowId, flowName, and an array of tasks associated with that flow.
 * @returns An array of objects, each object containing the flowId, flowName, and the number of remaining tasks associated with that flow.
 */
export function getNbRemainingTasksPerFlow(tasksPerFlow?: GetTasksPerFlowReturn): NbRemainingTasksPerFlow {
  const taskStatusDone = taskStrToNumber('done');
  if (!tasksPerFlow) {
    tasksPerFlow = getTasksPerFlow({});
  }

  return tasksPerFlow.map(({ flowName, flowId, tasks }) => ({
    flowId,
    flowName,
    count: tasks.filter((task) => task.state !== taskStatusDone).length,
    countPerStep: tasks.reduce((acc, task) => {
      if (taskStatusDone === task.state) return acc;

      const stepNb = task.stepEndDate?.length ?? 0;

      if (!acc[stepNb]) acc[stepNb] = 0;
      acc[stepNb] += 1;

      return acc;
    }, [] as number[]),
  }));
}

/**
 * Returns the total number of remaining tasks across all flows.
 *
 * @param nbRemainingTasksPerFlow - An array of objects, each object containing the flowId, flowName, and the number of remaining tasks associated with that flow.
 * @returns The total number of remaining tasks.
 */
export function getNbRemainingTasks(nbRemainingTasksPerFlow?: NbRemainingTasksPerFlow): number {
  if (!nbRemainingTasksPerFlow) {
    nbRemainingTasksPerFlow = getNbRemainingTasksPerFlow();
  }

  return nbRemainingTasksPerFlow.reduce((acc, { count }) => acc + count, 0);
}
