import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { exportCSVSlots } from 'utils/circuit/export-csv-slots';

export function ExportAllSlotsBtn(): JSX.Element {
  return (
    <ListItem>
      <ListItemIcon>
        <FileDownloadIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Button sx={{ marginBottom: '0.5rem' }} onClick={exportCSVSlots} variant="outlined">
            Export slots
          </Button>
        }
        secondary={
          <>
            Export all slots in a CSV
            <HelpIconTooltip
              title="Ability to export all the slots in a CSV file containing their name, type and id"
              sx={{
                fontSize: '1rem',
              }}
            />
          </>
        }
      />
    </ListItem>
  );
}
