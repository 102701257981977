import { Button } from '@mui/material';
import { Box } from '@mui/system';
import type { BalyoSimulationPromptMsgJSX } from 'models/simulation';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { theme } from 'utils/mui-theme';
import { resetDeadLockRobots } from './simulation';

export function displayPromptSnackbar(data: BalyoSimulationPromptMsgJSX): void {
  let alreadyClicked = false;

  const snackbarId = SnackbarUtils.toast(
    <Box component="span">
      {data.title && (
        <>
          <strong>{data.title}</strong>
          <br />
        </>
      )}
      <>{data.msg}</>
    </Box>,
    {
      persist: data.timeout === -1,
      autoHideDuration: data.timeout === -1 ? undefined : data.timeout,
      variant: data.severity,
      action: data.actions
        ? (id) => (
            <Box component="div">
              {(data.actions ?? []).map((action, index) => (
                <Button
                  key={index}
                  onClick={() => handleClickAction(action, index)}
                  variant="contained"
                  // color={data.severity}
                  // color="primary"
                  sx={{
                    marginRight: theme.spacing(1),
                    textTransform: 'none',
                    backgroundColor: data.severity ? theme.palette[data.severity].contrastText : undefined,
                    color: data.severity ? theme.palette[data.severity].main : undefined,
                    '&:hover': {
                      backgroundColor: data.severity ? theme.palette[data.severity].dark : undefined,
                      color: data.severity ? theme.palette[data.severity].contrastText : undefined,
                    },
                  }}
                  size="small"
                >
                  {action}
                </Button>
              ))}
            </Box>
          )
        : undefined,
    }
  );

  async function handleClickAction(action: string, actionIndex: number): Promise<void> {
    if (alreadyClicked) return;

    alreadyClicked = true;
    if (snackbarId) SnackbarUtils.closeSnackbar(snackbarId);

    if (data.category === 'Robot-Deadlock') {
      if (action === 'Reset robot') {
        store.dispatch(resetDeadLockRobots());
      }
    }

    const simulationService = (await import('services/simulation.service'))?.simulationService;

    if (!simulationService) {
      // eslint-disable-next-line no-console
      console.error('Simulation service not found');

      return;
    }

    await simulationService._WEBSIMU_WasmWrapper_promptReply(data.categoryId, actionIndex, data.parameter ?? 0);
  }
}
