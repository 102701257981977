export type FormatingType = 'number' | 'letter';
export type FormatingDirectionType = 'ltr' | 'rtl';

/**
 * The array with all the custom variable names that describes a string
 */
export const stringVariableNames = ['@rackName!', '@loadPattern!'] as const;

/**
 * The array with all the custom variable names that describes a number
 */
export const numberVariableNames = [
  '@column!',
  '@positionInCell!',
  '@level!',
  '@numberOfColumns!',
  '@numberOfLevels!',
  '@position!',
  '@positionInCellMultiLoad!',
  '@positionMultiLoad!',
] as const;

export const variablesNames = [...stringVariableNames, ...numberVariableNames] as const;

/**
 * Is it a number variable name?
 *
 * @see {isStringVariableName} ts-auto-guard:type-guard
 */
export type StringVariableName = (typeof stringVariableNames)[number];
/**
 * Is it a string variable name?
 *
 * @see {isNumberVariableName} ts-auto-guard:type-guard
 */
export type NumberVariableName = (typeof numberVariableNames)[number];

/**
 * Is it a variable name?
 *
 * @see {isVariableName} ts-auto-guard:type-guard
 */
export type VariablesName = StringVariableName | NumberVariableName;

/**
 * The formatting parameters for a variable in a rack position name
 */
export interface VariableParams {
  /** whether we output the variable in numbers (base 10) or in letters (base 26) */
  formatting: FormatingType;
  /** whether we output the variable from the left (0, 1, ..., end) to the right (end, end-1, ..., 0) or from the right to the left */
  formattingDirection: FormatingDirectionType;
  /** the minimum number of characters outputed (ex: 22 with nbCharacters = 4 will output '0022') */
  nbCharacters: number;
  /** the character that fill the  outputed, ex: if its A then => (ex: 22 with fillCharacter = 4 will output 'AA22') */
  fillCharacter: string;
  /** from which number the variable begins */
  startAt: number;
  /** the step increamented between every step (startAt, startAt + step, startAt + 2*step, ..., end) */
  step: number;
}

/**
 * The formatting parameters for a rack position name
 *
 * @see {isBlockDataPositionName} ts-auto-guard:type-guard
 */
export interface BlockDataPositionName {
  /**
   * char = string (ex: hello)
   * nbVariable = number variable (ex: index of the column)
   * strVariable = string variable (ex: rack name)
   */
  type: 'char' | 'nbVariable' | 'strVariable';
  value: string;
  params?: VariableParams;
}
/**
 * @see {isBlockDataPositionNames} ts-auto-guard:type-guard
 */
export type BlockDataPositionNames = BlockDataPositionName[];
