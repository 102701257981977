import type { EditorDrawing } from 'drawings/editor.drawing';
import type { AxisLayer } from 'drawings/layers';
import { LayerNames } from 'drawings/layers';
import { useEffect, useRef } from 'react';

interface AxisLayerComponentProps {
  drawing: EditorDrawing;
}

export function AxisLayerComponent(props: AxisLayerComponentProps): JSX.Element {
  const ref = useRef<SVGSVGElement>(null);
  const axisLayerPointer = props.drawing.layers.get(LayerNames.Axis);

  useEffect(() => {
    let axisLayer: AxisLayer;
    try {
      axisLayer = props.drawing.getLayer<AxisLayer>(LayerNames.Axis);
    } catch (e) {
      return;
    }

    const node = axisLayer.node.node();
    if (!node || !ref.current) return;

    while (ref.current.firstChild) {
      ref.current.removeChild(ref.current.firstChild);
    }

    ref.current.appendChild(node);
  }, [props.drawing, axisLayerPointer]);

  return <g ref={ref} {...{ _comment: 'axis container' }}></g>;
}
