import type { MultiPolygon, Polygon } from 'geojson';

export enum LidarPosition {
  Foreground = 'foreground-lidar',
  Background = 'background-lidar',
}

export interface Lidar {
  /** width of the lidar map */
  width: number;
  /** height of the lidar map */
  height: number;
  /** all the points of the lidar map */
  coordinates: [number, number, number?][];
  /** name of the map */
  name: string;
}

export interface MapImageScalingSourceData {
  /** Coordinates of the two source points */
  points: number[][];
  /** Distance between the two source points (in meters) */
  distance: number;
}

/**
 * Map image data
 * @see {isMapImage} ts-auto-guard:type-guard
 */
export interface MapImage {
  /** Base64 url of the image */
  URL: string;
  /** Name of the image */
  name: string;
  /** Height of the image (computed by the user scaling) */
  height?: number;
  /** Offset of the map image (abscissa) */
  x: number;
  /** Offset of the map image (ordinate) */
  y: number;
  /** Data about the source points entered by the user */
  scaling?: MapImageScalingSourceData;
  /** the width of the image */
  originalWidth: number;
  /** the height of the image */
  originalHeight: number;
}

/**Scale to display the map (number of cm represented by 1 pixel) */
export type Scale = 128 | 64 | 32 | 16 | 8 | 4 | 2;

export interface MapImageTiles {
  /** Name of the map (project name) */
  name: string;
  /** name of the format version */
  version: string;
  /** Name of the last release */
  release: string;
  /** Size of the tile in px */
  tilePixelSize: number;
  /** Tiles to display the project map */
  tiles: {
    [K in Scale]: {
      file: string;
      x: number;
      y: number;
    }[];
  };
}

export interface CollisionPolygon {
  geometry: MultiPolygon | Polygon;
}
