import type {
  CellLoad,
  CellTemplatePerceptionProperties,
  PerceptionReference,
  RackCell,
  RackCellTemplate,
  RackUpright,
} from 'models/circuit';
import { balyoGradient } from 'utils/colors/colors';
import { generateShapeId } from './next-free-id';
import { defaultConveyorCellHeight, defaultConveyorWidth } from './racks';

/**
 * Returns a EURO pallet
 * @returns a euro pallet
 */
export function getEuroPallet(): Pallet {
  return {
    name: 'EUR1_800x1200_Face',
    palletWidth: 0.8,
    palletLength: 1.2,
    centralGapWidth: 0.2275,
    centralGapWidthTolMin: -0.03,
    centralGapWidthTolMax: 0.03,
    centralGapHeight: 0.1,
    centralGapHeightTol: 0.03,
    centralBlockWidth: 0.145,
    centralBlockWidthTolMin: -0.03,
    centralBlockWidthTolMax: 0.03,
    externalBlockWidth: 0.1,
    externalBlockWidthTolMin: -0.03,
    externalBlockWidthTolMax: 0.03,
    palletWidthToleranceMin: -0.05,
    palletWidthToleranceMax: 0.05,
    bottomDeckBoardThickness: 0,
  };
}

/**
 * Returns a default rack cell
 * @returns a rack cell
 */
export function getDefaultRackCell(): RackCell {
  return {
    id: generateShapeId(),
    height: 1.5,
    cellTemplate: undefined, // 'default',
    linkedHeight: true,
    linkedBeamThickness: true,
    beamThickness: 0.1,
    names: [],
  };
}

/**
 * @returns a default column width [m]
 */
export function getDefaultColumnWidth(): number {
  const euroPallet = getEuroPallet();
  const a = 0.05;
  const N = 4;

  const width = N * euroPallet.palletWidth + 2 * N * a;

  return width;
}

/**
 * Returns the default cell template
 * @returns the defailt cell template
 */
export function getDefaultRackCellTemplate(): RackCellTemplate {
  const euroPallet = getEuroPallet();
  const defaultRackCell = {
    width: getDefaultColumnWidth(),
    depth: (euroPallet.palletLength || 1.2) + 0.1,
    height: 1.5,
    maximumLoadHeight: 0,
  };

  return {
    id: 'default',
    name: 'Default EURO',
    width: defaultRackCell.width,
    height: defaultRackCell.height,
    maximumLoadHeight: defaultRackCell.maximumLoadHeight,
    approachDistance: 0.05,
    loads: [getDefaultLoad(false)],
    leftObstacle: 0,
    rightObstacle: 0,
    palletOverflow: 0.05,
    palletTypes: [euroPallet.name],
    selectedLoad: 0,
    color: balyoGradient[Math.floor(Math.random() * balyoGradient.length)],
    perception: getDefaultCellTemplatePerceptionProperties(),
    loadLength: getEuroPallet().palletLength,
  };
}

/**
 * Return the default cell template for the conveyor
 * @returns the default cell template for the conveyor
 */
export function getDefaultConveyorCellTemplate(): RackCellTemplate {
  const rackDefaultCellTemplate = getDefaultRackCellTemplate();

  rackDefaultCellTemplate.forConveyor = true;
  rackDefaultCellTemplate.id = 'default_conveyor';
  rackDefaultCellTemplate.name = 'Default EURO Conveyor';
  rackDefaultCellTemplate.loads[0].N = 1;
  rackDefaultCellTemplate.loads[0].center = true;
  rackDefaultCellTemplate.loads[0].references = ['none'];
  rackDefaultCellTemplate.width = defaultConveyorWidth;
  rackDefaultCellTemplate.height = defaultConveyorCellHeight;

  return rackDefaultCellTemplate;
}

/**
 * Return a default load (NaW) that can be used to fill a rack cell
 * @returns the default cell load
 */
export function getDefaultLoad(generateUniqueName = false, otherLoadNames: string[] = []): CellLoad {
  const euroPallet = getEuroPallet();
  const a1 = 0.05; // minimal distance to an upright
  const references: PerceptionReference[] = ['left', 'none', 'right'];
  let name = 'A';

  if (generateUniqueName) {
    const loadNames = new Set();
    const storeState = window.getStoreState();
    const cellTemplateIds = storeState.circuit.present.cellTemplates.ids;
    const cellTemplates = storeState.circuit.present.cellTemplates.entities;

    cellTemplateIds.forEach((cellTemplateId) => {
      cellTemplates[cellTemplateId].loads.forEach((load) => {
        loadNames.add(load.name.toUpperCase());
      });
    });

    name = generateUniqueLoadName([...(Array.from(loadNames) as string[]), ...otherLoadNames]);
  }

  return { N: 3, W: euroPallet.palletWidth, a1, references, name };
}

/**
 * @returns a default upright properties
 */
export function getDefaultUpright(): RackUpright {
  return { width: 0.08, enabled: true, linkedWidth: true, linkedFootProtection: true };
}

export interface Pallet {
  name: string;
  palletLength: number;
  palletWidth: number;

  centralGapWidth?: number;
  centralGapWidthTolMin?: number;
  centralGapWidthTolMax?: number;
  centralGapHeight?: number;
  centralGapHeightTol?: number;
  centralBlockWidth?: number;
  centralBlockWidthTolMin?: number;
  centralBlockWidthTolMax?: number;
  externalBlockWidth?: number;
  externalBlockWidthTolMin?: number;
  externalBlockWidthTolMax?: number;
  palletWidthToleranceMin?: number;
  palletWidthToleranceMax?: number;
  bottomDeckBoardThickness?: number;

  groups?: string[];
}

/**
 * Default cell template start depth perception properties
 * @returns the value in meters
 */
export function getDefaultStartDepth(): number {
  return 0.1;
}

/**
 * Default cell template start height perception properties
 * @returns the value in meters
 */
export function getDefaultStartHeight(): number {
  return 0.15;
}

/**
 * Default cell template minimumHeightVerification perception property
 * @returns the value in meters
 */
export function getDefaultMinimumHeightVerification(): number {
  return 0.2;
}

export function getDefaultBeamUpMarginObstacle(): number {
  return 0.05;
}

/**
 * Default cell template minimumVerticalClearanceForDrop perception property
 * @returns the value in meters
 */
export function getDefaultMinimumVerticalClearanceForDrop(): number {
  return 0.1;
}

/**
 * Default cell template freeSpaceMinObjectSize perception property
 * @returns the value in meters
 */
export function getDefaultFreeSpaceMinObjectSize(): number {
  return 0.04;
}

/**
 * Default cell template minimumLateralClearanceForDrop perception property
 * @returns the value in meters
 */
export function getDefaultMinimumLateralClearanceForDrop(): number {
  return 0.04;
}

/**
 * Default cell template minimumDepthClearanceForDrop perception property
 * @returns the value in meters
 */
export function getDefaultMinimumDepthClearanceForDrop(): number {
  return 0.02;
}

/**
 * Default cell template beamFaceXTolerance perception property
 * @returns the value in meters
 */
export function getDefaultBeamFaceXTolerance(): number {
  return 0.05;
}

/**
 * Default cell template uprightYTolerance perception property
 * @returns the value in meters
 */
export function getDefaultUprightYTolerance(): number {
  return 0.06;
}

/**
 * Default cell template beamHeightZTolerance perception property
 * @returns the value in meters
 */
export function getDefaultBeamHeightZTolerance(): number {
  return 0.05;
}

/**
 * Default cell template overhangTolerance perception property
 * @returns the value in meters
 */
export function getDefaultOverhangTolerance(): number {
  return 0.05;
}

/** Default cell template initialSolution perception properties
 * @eturns the values (X, Y, Z) in meters
 */
function getDefaultInitialSolution(): [number, number, number] {
  return [0.1, 0.1, 0.05];
}

function getDefaultRejectPercentages(): { model: number; feet: number; pockets: number } {
  return { model: 0.6, feet: 0.6, pockets: 0.5 };
}

function getDefaultMinObstacleMultiplier(): number {
  return 4;
}

/**
 * Default value for the dAsk Door property
 * @returns the value in metres
 */
export function getDefaultdAsk(): number {
  return 5.0;
}

/**
 * Default value for the dStop Door property
 * @returns the value in metres
 */
export function getDefaultdStop(): number {
  return 0.5;
}

/**
 * The default values for a cell template related to the perception properties
 * Defined here: https://docs.google.com/document/d/1nR45AbDqDbCTf8F0uWEpZ_cp8vrVTDNmo9M_Nu-5pM0/edit?usp=sharing
 * @returns the object
 */
export function getDefaultCellTemplatePerceptionProperties(): CellTemplatePerceptionProperties {
  return {
    beamUpMarginObstacle: getDefaultBeamUpMarginObstacle(),
    startDepth: getDefaultStartDepth(),
    startHeight: getDefaultStartHeight(),
    minimumHeightVerification: getDefaultMinimumHeightVerification(),
    minimumVerticalClearanceForDrop: getDefaultMinimumVerticalClearanceForDrop(),
    freeSpaceMinObjectSize: getDefaultFreeSpaceMinObjectSize(),
    minimumLateralClearanceForDrop: getDefaultMinimumLateralClearanceForDrop(),
    minimumDepthClearanceForDrop: getDefaultMinimumDepthClearanceForDrop(),
    beamFaceXTolerance: getDefaultBeamFaceXTolerance(),
    uprightYTolerance: getDefaultUprightYTolerance(),
    beamHeightZTolerance: getDefaultBeamHeightZTolerance(),
    overhangTolerance: getDefaultOverhangTolerance(),
    initialSolution: getDefaultInitialSolution(),
    rejectPercentage: getDefaultRejectPercentages(),
    minObstacleMultiplier: getDefaultMinObstacleMultiplier(),
    cameraProjectionBeforeInspectionPlane: false,
    horizontalProjectionOnInspectionPlane: true,
    horizontalProjection: false,
    flyingObjectDetection: true,
    activateCrossShapedMethod: false,
    relPickActivateCorr: {
      depth: 1,
      lift: 1,
      shift: 1,
    },
    relDropActivateCorr: {
      depth: 2,
      lift: 2,
      shift: 2,
    },
    rangefinderDetection: true,
    enablePerceptionPick: true,
    enablePerceptionDrop: true,
  };
}

/**
 * Default extended length used [m]
 */
export const defaultExtendedLength = 0.3;

/**
 * From a list of names, it generates a unique name for a load
 * Load names are like A, B, C, ..., Z, AA, AB, ..., AZ, BA, ..., BZ, ...
 * @param names the names to be excluded, can be empty
 * @returns the new generated name
 */
export function generateUniqueLoadName(names: string[] = []): string {
  let name = 'A';
  let i = 1;
  while (names.includes(name)) {
    name = convertBase(i.toString(), 10, 26).toUpperCase();
    i++;
  }

  return name;
}

/**
 * Convert from base 10 to base 26
 * @param value
 * @param from_base
 * @param to_base
 * @returns
 */
export function convertBase(value: string, from_base: number, to_base: number): string {
  const from_range = '0123456789'.split('');
  const to_range = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  let dec_value = value
    .split('')
    .reverse()
    .reduce(function (carry, digit, index) {
      if (from_range.indexOf(digit) === -1) throw new Error(`Invalid digit '${digit}' for base '${from_base}.`);

      return (carry += from_range.indexOf(digit) * Math.pow(from_base, index));
    }, 0);

  let new_value = '';
  while (dec_value > 0) {
    new_value = to_range[dec_value % to_base] + new_value;
    dec_value = (dec_value - (dec_value % to_base)) / to_base;
  }

  return new_value || 'A';
}
