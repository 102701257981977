import type { TrafficType } from 'models/circuit';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';

/**
 * Converts traffic type to CSS filter.
 * @param trafficType - The traffic type to convert.
 * @returns The CSS filter string or undefined if traffic type is 'auto'.
 */
export function trafficTypeToCssFilter(trafficType: TrafficType | undefined): string | undefined {
  if (!trafficType || trafficType === 'auto') return undefined;
  if (trafficType === 'kernel') return 'hue-rotate(80deg)';
  if (trafficType === 'deadend') return 'hue-rotate(145deg)';
  if (trafficType === 'deadend-entry') return 'hue-rotate(165deg)';
  if (trafficType === 'deadend-exit') return 'hue-rotate(175deg)';

  assert<Equals<typeof trafficType, never>>();
}
