import { remoteDoc } from 'multiplayer/globals';
import { setDevicePrefManagementFromYJS } from 'project/project';
import { useLayoutEffect } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import type { YMap } from 'yjs/dist/src/internals';

export const useEnableDevicePrefManagementSubscription = (): void => {
  const enableDevicePrefManagementMap = remoteDoc?.getMap('enableDevicePrefManagement') as YMap<boolean>;

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      if (isTransactionLocal) return;

      const enableDevicePrefManagement = enableDevicePrefManagementMap.get('state');

      if (typeof enableDevicePrefManagement !== 'boolean') return;
      store.dispatch(setDevicePrefManagementFromYJS(enableDevicePrefManagement));

      if (store.getState().dialog.open && store.getState().dialog.type === 'project-settings') {
        SnackbarUtils.info('A user updated the devices preferences management', {
          autoHideDuration: 3000,
        });
      }
    };

    enableDevicePrefManagementMap.observe(observerHandle);

    return () => {
      enableDevicePrefManagementMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
