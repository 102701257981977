export const REFRESH_SIMU_ROBOTS_INTERVAL = 1000; // ms
export const MIN_REFRESH_SIMU_ROBOTS_INTERVAL = REFRESH_SIMU_ROBOTS_INTERVAL / 20;
export const REFRESH_SIMU_THREAD_INFO_INTERVAL = 500; // ms
export const REFRESH_SIMU_DISPLAY_INTERVAL = 1000; // ms

export const animateRobotSpeedFactorThreshold = 1;

/**
 * Whether we enable robot smoothing or not
 * @param speedFactor the speed factor of the simulation
 * @param fastRefreshEnabled whether fast refresh is enabled or not
 * @param using3D whether we are using 3D or not
 * @returns true if robot smoothing is enabled, false otherwise
 */
export function isRobotSmoothingEnabled(speedFactor: number, fastRefreshEnabled: boolean): boolean {
  return speedFactor <= animateRobotSpeedFactorThreshold && !fastRefreshEnabled;
}
