import { deletePointAction, savePointAction } from 'actions/points';
import type { CircuitPoint } from 'models/circuit';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import type { Dispatch } from 'react';
import { connect } from 'react-redux';
import { selectCurrentCircuit, selectSelectedCircuitPoint } from 'reducers/circuit/selectors';
import type { AppState } from 'reducers/state';
import { selectCurrentLocal } from './../../../reducers/local/selectors';
import { PointPropertiesComponent } from './point-properties';

interface StateProps {
  point?: CircuitPoint;
}

function mapStateToProps(state: AppState): StateProps {
  const circuit = selectCurrentCircuit(state);
  const local = selectCurrentLocal(state);
  const point = selectSelectedCircuitPoint({ ...circuit, ...local });

  // Since coords are in centimeters, we need to convert to meters
  return {
    point: point && {
      ...point,
      geometry: {
        ...point.geometry,
        coordinates: [
          Number(point.geometry.coordinates[0] / DISPLAY_UNIT_FACTOR),
          Number(point.geometry.coordinates[1] / DISPLAY_UNIT_FACTOR),
        ],
      },
    },
  };
}

interface DispatchProps {
  onSubmit: (payload: Partial<CircuitPoint>) => void;
  onDelete: (payload: string) => void;
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
  return {
    onSubmit: (payload: Partial<CircuitPoint>) => {
      if (
        !payload ||
        !payload.geometry ||
        !payload.geometry.coordinates ||
        isNaN(payload.geometry.coordinates[0]) ||
        isNaN(payload.geometry.coordinates[1]) ||
        isNaN(payload?.properties?.orientation || 0)
      ) {
        // eslint-disable-next-line no-console
        console.error('Invalid point data', payload);

        return;
      }

      const shapeData = {
        ...payload,
        geometry: {
          ...payload.geometry,
          coordinates: [
            payload.geometry.coordinates[0] * DISPLAY_UNIT_FACTOR,
            payload.geometry.coordinates[1] * DISPLAY_UNIT_FACTOR,
          ],
        },
      };

      dispatch(savePointAction({ ...shapeData, userAction: true }));
    },
    onDelete: (payload: string) => dispatch(deletePointAction({ id: payload, userAction: true })),
  };
}

export const PointProperties = connect(mapStateToProps, mapDispatchToProps)(PointPropertiesComponent);
