import { useInterval } from '@mantine/hooks';
import { useCallback, useEffect, useState } from 'react';

interface BatteryManager extends EventTarget {
  charging: boolean;
  chargingTime: number;
  dischargingTime: number;
  level: number;
  onchargingchange: ((this: BatteryManager, ev: Event) => any) | null;
  onchargingtimechange: ((this: BatteryManager, ev: Event) => any) | null;
  ondischargingtimechange: ((this: BatteryManager, ev: Event) => any) | null;
  onlevelchange: ((this: BatteryManager, ev: Event) => any) | null;
}

interface Navigator {
  getBattery(): Promise<BatteryManager>;
}

/**
 * detect iOS/iPad/macOS Low Power Mode, Chromium Energy Saver Mode, and maybe future Firefox power saving mode
 *
 * @link https://gist.github.com/fuweichin/f7b675c7a5bab86dd1488962e646c6cc - this code is from this url
 *
 * @async
 * @method detectPowerSavingMode
 * @returns {Promise<boolean | undefined>} return `undefined` if not sure
 */
export const detectPowerSavingMode = async (): Promise<boolean | undefined> => {
  // for iOS/iPadOS Safari, and maybe MacBook macOS Safari (not tested)
  if (/(iP(?:hone|ad|od)|Mac OS X)/.test(navigator.userAgent)) {
    // In Low Power Mode, cumulative delay effect happens on setInterval()
    return new Promise((resolve) => {
      const fps = 60;
      const interval = 1000 / fps;
      const numFrames = 30;
      const startTime = performance.now();
      let i = 0;
      const handle = setInterval(() => {
        if (i < numFrames) {
          i++;

          return;
        }

        clearInterval(handle);
        const actualInterval = (performance.now() - startTime) / numFrames;
        const ratio = actualInterval / interval; // 1.3x or more in Low Power Mode, 1.1x otherwise
        // console.log(actualInterval, interval, ratio);
        resolve(ratio > 1.3);
      }, interval);
    });
  }

  // for Safari, Chromium, and maybe future Firefox
  const frameRate = await detectFrameRate();
  // In Battery Saver Mode frameRate will be about 30fps or 20fps,
  // otherwise frameRate will be closed to monitor refresh rate (typically 60Hz)
  if (frameRate < 34) {
    return true;
  }

  // FIXME fallback to regard as Low Power Mode when battery power is low (down to 20%)
  if ('getBattery' in navigator && navigator.getBattery && typeof navigator.getBattery === 'function') {
    const battery = await (navigator as Navigator).getBattery();

    return !battery.charging && battery.level <= 0.2;
  }

  return undefined;
};

/**
 * Detect the frame rate of the browser.
 *
 * @async
 * @method detectFrameRate
 * @returns {Promise<number>} The frame rate of the browser.
 */
async function detectFrameRate(): Promise<number> {
  return new Promise((resolve) => {
    const numFrames = 30;
    const startTime = performance.now();
    let i = 0;
    const tick = (): void => {
      if (i < numFrames) {
        i++;
        requestAnimationFrame(tick);

        return;
      }

      const frameRate = numFrames / ((performance.now() - startTime) / 1000);
      resolve(frameRate);
    };

    requestAnimationFrame(tick);
  });
}

export function usePowerSavingMode(): [boolean | undefined] {
  const [powerSavingMode, setPowerSavingMode] = useState<boolean | undefined>(undefined);

  const detectPowerSaving = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await detectPowerSavingMode();
    setPowerSavingMode(result);
  }, []);

  useEffect(() => {
    setTimeout(() => detectPowerSaving(), 2000);

    let batteryManager: BatteryManager | undefined;
    if ('getBattery' in navigator && navigator.getBattery && typeof navigator.getBattery === 'function') {
      const batteryManagerPromise = (navigator as Navigator).getBattery();
      batteryManagerPromise.then((battery) => {
        batteryManager = battery;
        battery.addEventListener('chargingchange', detectPowerSaving);
      });
    }

    document.addEventListener('visibilitychange', detectPowerSaving);

    return () => {
      batteryManager?.removeEventListener('chargingchange', detectPowerSaving);
      document.removeEventListener('visibilitychange', detectPowerSaving);
    };
  }, [detectPowerSaving]);

  useInterval(detectPowerSaving, 1000 * 60 * 5); // check every 5 minutes

  return [powerSavingMode];
}
