import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { disconnectRoom } from './multiplayer';

export async function connectRoomSideEffect(): Promise<void> {
  const accessRoomAuth = await checkPermission('access:room');
  if (!accessRoomAuth) {
    store.dispatch(disconnectRoom());

    SnackbarUtils.info('You are not authorized to connect to a collaborative mode room.');
  }
}
