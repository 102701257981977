import AnimationIcon from '@mui/icons-material/Animation';
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { openDialogAction } from 'actions';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { DialogTypes } from 'models';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';

export function TranslateRotateWholeCircuitBtns(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClickTranslate = (): void => {
    dispatch(
      openDialogAction({
        type: DialogTypes.TranslateCircuit,
        payload: undefined,
      })
    );
  };

  const handleClickRotate = (): void => {
    dispatch(
      openDialogAction({
        type: DialogTypes.RotateCircuit,
        payload: undefined,
      })
    );
  };

  return (
    <ListItem>
      <ListItemIcon>
        <AnimationIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            <Button sx={{ marginBottom: '0.5rem' }} onClick={handleClickTranslate} variant="outlined">
              Translate Circuit
            </Button>
            <Button
              sx={{ marginBottom: '0.5rem', marginLeft: theme.spacing(2) }}
              onClick={handleClickRotate}
              variant="outlined"
            >
              Rotate Circuit
            </Button>
          </>
        }
        secondary={
          <>
            Translate or Rotate whole Circuit
            <HelpIconTooltip
              title="Ability to define an offset and apply it on the whole circuit"
              sx={{
                fontSize: '1rem',
              }}
            />
          </>
        }
      />
    </ListItem>
  );
}
