import { loadCircuitFromYJSAction } from 'actions/circuit';
import { remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import type { CircuitState } from 'reducers/circuit/state';
import store from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import { setLastCircuitTransactionOrigin } from './../../../multiplayer/globals';

export const useCircuitSubscription = (): void => {
  const circuitMap = remoteDoc?.getMap('circuit') as YMap<CircuitState>;

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      setLastCircuitTransactionOrigin(isTransactionLocal ? 'local' : 'remote');
      if (isTransactionLocal) return;

      const circuit = circuitMap.get('circuit') as CircuitState;

      store.dispatch(loadCircuitFromYJSAction({ circuit: circuit }));
    };

    circuitMap.observe(observerHandle);

    return () => {
      circuitMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
