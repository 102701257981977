import type {
  CellTemplateActions,
  CreateCellTemplateSuccess,
  DeleteCellTemplateSuccess,
  SaveCellTemplate,
  SaveCellTemplateSuccess,
} from 'actions/cell-templates';
import { CellTemplateActionTypes } from 'actions/cell-templates';
import type { CircuitActions } from 'actions/circuit';
import { CircuitActionTypes } from 'actions/circuit';
import type { CreateRackSuccess, RackActions, SaveRackSuccess } from 'actions/racks';
import { RackActionTypes, saveRackAction } from 'actions/racks';
import {
  maxRecommendedPalletOverflow,
  minDistanceLoadUpright,
  minRecommendedBeamThickness,
  minRecommendedPalletOverflow,
  minRecommendedUprightWidth,
} from 'components/editor/rack-edition';
import { findNewPositionToAlignElement } from 'drawings/elements/align';
import { isPointOnSegment } from 'drawings/helpers';
import { produce } from 'immer';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type { EntityAnomaly, RackCell, RackColumn } from 'models/circuit';
import { RackAnomalyIds, ShapeTypes } from 'models/circuit';
import { isCellTemplate } from 'models/circuit.guard';
import { combineReducers } from 'redux';
import {
  CircuitService,
  getMaxDisplayPriority,
  resetDisplayPriority,
  setDisplayPriority,
} from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import { generateUniqueLoadName, getDefaultRackCellTemplate } from 'utils/circuit/default-circuit-shapes';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { computeWidthTakenByLoads } from 'utils/circuit/racks';
import { generatePositionNames } from 'utils/circuit/racks-naming';
import {
  isCircuitDevice,
  isCircuitMeasurer,
  isCircuitNote,
  isCircuitPoint,
  isCircuitRack,
  isCircuitSegment,
  isCircuitStockZone,
  isCircuitTurn,
  isCircuitZone,
} from 'utils/circuit/shape-guards';
import { regexCellTemplateLoadName } from 'utils/circuit/utils';
import { getObjectKeys } from 'utils/object';
import { createEntityStateAdapter, createEntityStateReducer, reduceReducers } from 'utils/redux';
import { isDefined } from 'utils/ts/is-defined';
import { removeUndefinedKeys } from 'utils/ts/remove-undefined-keys';
import { getLayersInitialState, layersReducer } from './layers.reducer';
import {
  type CellTemplate,
  type CircuitCellTemplateState,
  type CircuitDeviceState,
  type CircuitMeasurerState,
  type CircuitNoteState,
  type CircuitPointState,
  type CircuitRackState,
  type CircuitSegmentState,
  type CircuitState,
  type CircuitStockZoneState,
  type CircuitTurnState,
  type CircuitZoneState,
  type Device,
  type LayerData,
  type LayerGroupData,
  type LoadedCellTemplate,
  type LoadedDevice,
  type LoadedMeasurer,
  type LoadedNote,
  type LoadedPoint,
  type LoadedRack,
  type LoadedSegment,
  type LoadedStockZone,
  type LoadedTurn,
  type LoadedZone,
  type Measurer,
  type Note,
  type Point,
  type Rack,
  type Segment,
  type StockZone,
  type Turn,
  type Zone,
} from './state';

const zoneAdapter = createEntityStateAdapter<Zone, CircuitZoneState>();
const stockZoneAdapter = createEntityStateAdapter<StockZone, CircuitStockZoneState>();
const rackAdapter = createEntityStateAdapter<Rack, CircuitRackState>();
const pointAdapter = createEntityStateAdapter<Point, CircuitPointState>();
const segmentAdapter = createEntityStateAdapter<Segment, CircuitSegmentState>();
const measurerAdapter = createEntityStateAdapter<Measurer, CircuitMeasurerState>();
const turnAdapter = createEntityStateAdapter<Turn, CircuitTurnState>();
const deviceAdapter = createEntityStateAdapter<Device, CircuitDeviceState>();
const noteAdapter = createEntityStateAdapter<Note, CircuitNoteState>();
export const cellTemplateAdapter = createEntityStateAdapter<CellTemplate, CircuitCellTemplateState>();

export const zoneReducer = createEntityStateReducer('Zones', zoneAdapter, zoneAdapter.getInitialState);
export const stockZoneReducer = createEntityStateReducer(
  'StockZones',
  stockZoneAdapter,
  stockZoneAdapter.getInitialState
);
export const rackReducer = createEntityStateReducer('Racks', rackAdapter, rackAdapter.getInitialState);
export const pointReducer = createEntityStateReducer('Points', pointAdapter, pointAdapter.getInitialState);
export const segmentReducer = createEntityStateReducer('Segments', segmentAdapter, segmentAdapter.getInitialState);
export const measurerReducer = createEntityStateReducer('Measurers', measurerAdapter, measurerAdapter.getInitialState);
export const turnReducer = createEntityStateReducer('Turns', turnAdapter, turnAdapter.getInitialState);
export const deviceReducer = createEntityStateReducer('Devices', deviceAdapter, deviceAdapter.getInitialState);
export const notesReducer = createEntityStateReducer('Notes', noteAdapter, noteAdapter.getInitialState);
export const cellTemplatesReducer = createEntityStateReducer(
  'CellTemplates',
  cellTemplateAdapter,
  getInitialStateCellTemplates
);

export function getInitialStateCellTemplates(): CircuitCellTemplateState {
  const initState = cellTemplateAdapter.getInitialState();
  const defaultCellTemplate = getDefaultRackCellTemplate();
  const defaultCellTemplate2 = getDefaultRackCellTemplate();

  defaultCellTemplate.id = generateShapeId();
  defaultCellTemplate2.id = generateShapeId();

  return {
    ...initState,
    entities: {
      ...initState.entities,
      [defaultCellTemplate.id]: {
        ...defaultCellTemplate,
        loaded: true,
      },
      [`${defaultCellTemplate2.id}`]: {
        ...defaultCellTemplate2,
        name: 'Default US',
        loads: [
          { ...defaultCellTemplate2.loads[0], N: 2, W: (40 * 2.54) / 100, references: ['left', 'right'], name: 'B' },
        ],

        loaded: true,
      },
    },
    ids: [...initState.ids, defaultCellTemplate.id, defaultCellTemplate2.id],
  };
}

export function getInitialState(): CircuitState {
  return {
    zones: zoneAdapter.getInitialState(),
    stockZones: stockZoneAdapter.getInitialState(),
    racks: rackAdapter.getInitialState(),
    points: pointAdapter.getInitialState(),
    segments: segmentAdapter.getInitialState(),
    measurers: measurerAdapter.getInitialState(),
    turns: turnAdapter.getInitialState(),
    devices: deviceAdapter.getInitialState(),
    notes: noteAdapter.getInitialState(),
    layers: getLayersInitialState(),
    cellTemplates: getInitialStateCellTemplates(),
  };
}

export const circuitReducer = reduceReducers([
  combineReducers({
    zones: zoneReducer,
    stockZones: stockZoneReducer,
    racks: rackReducer,
    points: pointReducer,
    segments: segmentReducer,
    measurers: measurerReducer,
    turns: turnReducer,
    devices: deviceReducer,
    notes: notesReducer,
    layers: layersReducer,
    cellTemplates: cellTemplatesReducer,
  }),
  ShapesReducer,
  CellTemplateReducer2,
  rackReducerAnomalyCheck,
  rackReducerPositionNames,
  updateLayerReducer,
]);

export function ShapesReducer(state = getInitialState(), action: CircuitActions): CircuitState {
  switch (action.type) {
    case CircuitActionTypes.ImportCircuit: {
      return state;
    }

    case CircuitActionTypes.ApplyUpdatedShapesFromYJS: {
      const shapes = action.payload;

      return produce(state, (draftState) => {
        for (const shape of shapes) {
          const { t, i } = shape;

          if (t === 'u') {
            const s = shape?.s as Segment | Point | Turn | StockZone | Rack | Zone | Measurer | Device | Note;
            const shapeType = s?.properties?.type;

            const shapeTypes = Object.values(ShapeTypes);
            if (shapeType && shapeTypes.includes(shapeType)) {
              switch (shapeType) {
                case ShapeTypes.SegmentShape:
                  if (!isCircuitSegment(s)) break;
                  const isSegmentAlreadyInState = !!draftState.segments.entities[i];
                  draftState.segments.entities[i] = s;

                  if (isSegmentAlreadyInState) break;
                  draftState.segments.ids.push(i);

                  break;

                case ShapeTypes.TurnShape:
                  if (!isCircuitTurn(s)) break;
                  const isTurnAlreadyInState = !!draftState.turns.entities[i];
                  draftState.turns.entities[i] = s;

                  if (isTurnAlreadyInState) break;
                  draftState.turns.ids.push(i);

                  break;

                case ShapeTypes.PointShape:
                  if (!isCircuitPoint(s)) break;
                  const isPointAlreadyInState = !!draftState.points.entities[i];
                  draftState.points.entities[i] = s;

                  if (isPointAlreadyInState) break;
                  draftState.points.ids.push(i);

                  break;

                case ShapeTypes.StockZoneShape:
                  if (!isCircuitStockZone(s)) break;
                  const isStockZoneAlreadyInState = !!draftState.stockZones.entities[i];
                  draftState.stockZones.entities[i] = s;

                  if (isStockZoneAlreadyInState) break;
                  draftState.stockZones.ids.push(i);

                  break;

                case ShapeTypes.RackShape:
                  if (!isCircuitRack(s)) break;
                  const isRackAlreadyInState = !!draftState.racks.entities[i];
                  draftState.racks.entities[i] = s;

                  if (isRackAlreadyInState) break;
                  draftState.racks.ids.push(i);

                  break;

                case ShapeTypes.ZoneShape:
                  if (!isCircuitZone(s)) break;
                  const isZoneAlreadyInState = !!draftState.zones.entities[i];
                  draftState.zones.entities[i] = s;

                  if (isZoneAlreadyInState) break;
                  draftState.zones.ids.push(i);

                  break;

                case ShapeTypes.MeasurerShape:
                  if (!isCircuitMeasurer(s)) break;
                  const isMeasurerAlreadyInState = !!draftState.measurers.entities[i];
                  draftState.measurers.entities[i] = s;

                  if (isMeasurerAlreadyInState) break;
                  draftState.measurers.ids.push(i);

                  break;

                case ShapeTypes.DeviceShape:
                  if (!isCircuitDevice(s)) break;
                  const isDeviceAlreadyInState = !!draftState.devices.entities[i];
                  draftState.devices.entities[i] = s;

                  if (isDeviceAlreadyInState) break;
                  draftState.devices.ids.push(i);

                  break;

                case ShapeTypes.NoteShape:
                  if (!isCircuitNote(s)) break;
                  const isNoteAlreadyInState = !!draftState.notes.entities[i];
                  draftState.notes.entities[i] = s;

                  if (isNoteAlreadyInState) break;
                  draftState.notes.ids.push(i);

                  break;
              }
            } else {
              // It's not a default shape
              if (!isCellTemplate(s)) {
                if (shape.s?.color) {
                  /* It's a layer */

                  if (draftState.layers.layers[i]) {
                    if (draftState.layers.layers[i].visibility !== shape.s.visibility) {
                      if (shape.s.Visibility) {
                        SnackbarUtils.info(`Layer "${draftState.layers.layers[i].name}" was shown`);
                      } else {
                        SnackbarUtils.info(`Layer "${draftState.layers.layers[i].name}" was hidden`);
                      }
                    }
                  }

                  draftState.layers.layers[i] = shape.s as LayerData;
                } else {
                  /* It's a layer group */
                  draftState.layers.layerGroups[i] = shape.s as LayerGroupData;
                }
              } else {
                /* It's a cell template */
                const isCellTemplateAlreadyInState = !!draftState.cellTemplates.entities[i];

                draftState.cellTemplates.entities[i] = shape.s as CellTemplate;

                if (!isCellTemplateAlreadyInState) {
                  draftState.cellTemplates.ids.push(i);
                }
              }
            }
          } else if (t === 'd') {
            switch (true) {
              case draftState.segments.entities[i] !== undefined:
                delete draftState.segments.entities[i];
                draftState.segments.ids = draftState.segments.ids.filter((id) => id !== i);
                break;

              case draftState.turns.entities[i] !== undefined:
                delete draftState.turns.entities[i];
                draftState.turns.ids = draftState.turns.ids.filter((id) => id !== i);
                break;

              case draftState.points.entities[i] !== undefined:
                delete draftState.points.entities[i];
                draftState.points.ids = draftState.points.ids.filter((id) => id !== i);
                break;

              case draftState.stockZones.entities[i] !== undefined:
                delete draftState.stockZones.entities[i];
                draftState.stockZones.ids = draftState.stockZones.ids.filter((id) => id !== i);
                break;

              case draftState.racks.entities[i] !== undefined:
                delete draftState.racks.entities[i];
                draftState.racks.ids = draftState.racks.ids.filter((id) => id !== i);
                break;

              case draftState.zones.entities[i] !== undefined:
                delete draftState.zones.entities[i];
                draftState.zones.ids = draftState.zones.ids.filter((id) => id !== i);
                break;

              case draftState.measurers.entities[i] !== undefined:
                delete draftState.measurers.entities[i];
                draftState.measurers.ids = draftState.measurers.ids.filter((id) => id !== i);
                break;

              case draftState.devices.entities[i] !== undefined:
                delete draftState.devices.entities[i];
                draftState.devices.ids = draftState.devices.ids.filter((id) => id !== i);
                break;

              case draftState.notes.entities[i] !== undefined:
                delete draftState.notes.entities[i];
                draftState.notes.ids = draftState.notes.ids.filter((id) => id !== i);
                break;

              case draftState.cellTemplates.entities[i] !== undefined:
                delete draftState.cellTemplates.entities[i];
                draftState.cellTemplates.ids = draftState.cellTemplates.ids.filter((id) => id !== i);
                break;

              case draftState.layers.layers[i] !== undefined:
                draftState.layers.selectedLayer = '';
                delete draftState.layers.layers[i];
                break;

              case draftState.layers.layerGroups[i] !== undefined:
                delete draftState.layers.layerGroups[i];
                break;
            }
          }
        }
      });
    }

    case CircuitActionTypes.SaveCircuitToHistory: {
      return state;
    }

    case CircuitActionTypes.LoadCircuitFromYJS: {
      const { circuit } = action.payload;

      return circuit;
    }

    case CircuitActionTypes.ImportCircuitSuccess: {
      const { importedCircuit } = action.payload;

      let [minDisplayPriority, maxDisplayPriority] = resetDisplayPriority();

      const shapeTypes = getObjectKeys(importedCircuit);
      const shapes = shapeTypes
        .map((shapeType) => {
          return importedCircuit[shapeType];
        })
        .flat();

      shapes.forEach((shape) => {
        const prio = shape.properties.prio;
        if (prio < minDisplayPriority) minDisplayPriority = prio;
        if (prio > maxDisplayPriority) maxDisplayPriority = prio;
      });

      setDisplayPriority(minDisplayPriority, maxDisplayPriority);

      return {
        ...state,
        zones: zoneAdapter.addMany(
          importedCircuit.zones.map((zone) => ({
            ...zone,
            loaded: true,
          })),
          zoneAdapter.getInitialState()
        ),
        stockZones: stockZoneAdapter.addMany(
          importedCircuit.stockZones.map((stockZone) => ({
            ...stockZone,
            loaded: true,
          })),
          stockZoneAdapter.getInitialState()
        ),
        racks: rackAdapter.addMany(
          importedCircuit.racks.map((rack) => ({
            ...rack,
            loaded: true,
          })),
          rackAdapter.getInitialState()
        ),

        points: pointAdapter.addMany(
          importedCircuit.points.map((interestPoint) => ({
            ...interestPoint,
            loaded: true,
          })),
          pointAdapter.getInitialState()
        ),
        segments: segmentAdapter.addMany(
          importedCircuit.segments.map((segment) => ({
            ...segment,
            loaded: true,
          })),
          segmentAdapter.getInitialState()
        ),
        measurers: measurerAdapter.addMany(
          importedCircuit.measurers.map((measurer) => ({
            ...measurer,
            loaded: true,
          })),
          measurerAdapter.getInitialState()
        ),
        turns: turnAdapter.addMany(
          importedCircuit.turns.map((turn) => ({
            ...turn,
            loaded: true,
          })),
          turnAdapter.getInitialState()
        ),
        devices: deviceAdapter.addMany(
          importedCircuit.devices.map((device) => ({
            ...device,
            loaded: true,
          })),
          deviceAdapter.getInitialState()
        ),
        notes: noteAdapter.addMany(
          importedCircuit.notes.map((note) => ({
            ...note,
            loaded: true,
          })),
          noteAdapter.getInitialState()
        ),
      };
    }

    case CircuitActionTypes.ImportCircuitFailure: {
      return state;
    }

    case CircuitActionTypes.RemoveAllGabarits: {
      const newPointsEntities = { ...state.points.entities };
      const newSegmentsEntities = { ...state.segments.entities };
      const newTurnsEntities = { ...state.turns.entities };

      if (action.payload !== undefined) {
        // here we want to remove the property gabarit of all the shapes in the selected layer that have it
        // shapes that can have a gabarit: point, segments, turns

        const layerId = action.payload.layerId;

        const segmentsIds = state.segments.ids;
        const segments = segmentsIds.map((id) => {
          return state.segments.entities[id];
        });
        const segmentsToUpdate = layerId
          ? segments.filter((segment) => segment.properties.layerId === layerId)
          : segments;

        segmentsToUpdate.forEach((segment) => {
          const segmentId = segment.id;

          if (segmentId && segment.properties.gabarit) {
            newSegmentsEntities[segmentId] = {
              ...segment,
              properties: {
                ...segment.properties,
                gabarit: undefined,
              },
            };
          }
        });

        const turnsIds = state.turns.ids;
        const turns = turnsIds.map((id) => {
          return state.turns.entities[id];
        });
        const turnsToUpdate = layerId ? turns.filter((turn) => turn.properties.layerId === layerId) : turns;

        turnsToUpdate.forEach((turn) => {
          const turnId = turn.id;

          if (turnId && turn.properties.gabarit) {
            newTurnsEntities[turnId] = {
              ...turn,
              properties: {
                ...turn.properties,
                gabarit: undefined,
              },
            };
          }
        });

        const pointsIds = state.points.ids;
        const points = pointsIds.map((id) => {
          return state.points.entities[id];
        });
        const pointsToUpdate = layerId ? points.filter((point) => point.properties.layerId === layerId) : points;

        pointsToUpdate.forEach((point) => {
          const pointId = point.id;

          if (pointId && point.properties.gabarit) {
            newPointsEntities[pointId] = {
              ...point,
              properties: {
                ...point.properties,
                gabarit: undefined,
              },
            };
          }
        });
      } else {
        // here we want to remove the property gabarit of all the shapes that have it
        // shapes that can have a gabarit: point, segments, turns

        for (const turnId in newTurnsEntities) {
          const turn = newTurnsEntities[turnId];

          if (turn.properties.gabarit) {
            newTurnsEntities[turnId] = {
              ...turn,
              properties: {
                ...turn.properties,
                gabarit: undefined,
              },
            };
          }
        }

        for (const segmentId in newSegmentsEntities) {
          const segment = newSegmentsEntities[segmentId];

          if (segment.properties.gabarit) {
            newSegmentsEntities[segmentId] = {
              ...segment,
              properties: {
                ...segment.properties,
                gabarit: undefined,
              },
            };
          }
        }

        for (const pointId in newPointsEntities) {
          const point = newPointsEntities[pointId];

          if (point.properties.gabarit) {
            newPointsEntities[pointId] = {
              ...point,
              properties: {
                ...point.properties,
                gabarit: undefined,
              },
            };
          }
        }
      }

      const newState = {
        ...state,

        points: {
          ...state.points,
          entities: newPointsEntities,
        },
        segments: {
          ...state.segments,
          entities: newSegmentsEntities,
        },
        turns: {
          ...state.turns,
          entities: newTurnsEntities,
        },
      };

      return newState;
    }

    case CircuitActionTypes.SegmentMoved: {
      if (action.payload.coordinates) {
        const segmentId = action.payload.idSegment;
        const segment = state.segments.entities[segmentId];
        if (!segment) {
          // eslint-disable-next-line no-console
          console.warn(`Segment moved action: segment (${segmentId}) not found`);

          return state;
        }

        let portions = state.segments.entities[action.payload.idSegment].properties.portions;

        const newState: CircuitState = {
          ...state,
          segments: {
            ...state.segments,
            entities: {
              ...state.segments.entities,
              [action.payload.idSegment]: {
                ...state.segments.entities[action.payload.idSegment],
                geometry: {
                  ...state.segments.entities[action.payload.idSegment].geometry,
                  coordinates: action.payload.coordinates,
                },
              },
            },
          },
        };

        if (portions.length === 1) {
          let portion = portions[0];
          portion = { ...portion, points: action.payload.coordinates };

          portions = [portion];
        } else {
          portions = portions.map((portion) => ({
            ...portion,
            points: [],
          }));
        }

        newState.segments.entities[action.payload.idSegment].properties = {
          ...newState.segments.entities[action.payload.idSegment].properties,
          portions,
        };

        return newState;
      }

      return state;
    }

    case CircuitActionTypes.MultipleSegmentsMoved: {
      const segmentsData = action.payload.segmentsData;

      if (segmentsData) {
        const newState: CircuitState = {
          ...state,
          segments: {
            ...state.segments,
            entities: {
              ...state.segments.entities,
            },
          },
        };

        segmentsData.forEach((segmentData) => {
          const segment = { ...newState.segments.entities[segmentData.idSegment] };
          const newCoordinates = segmentData.coordinates;
          let portions = segment.properties.portions;

          if (newCoordinates) {
            segment.geometry = {
              ...segment.geometry,
              coordinates: newCoordinates,
            };

            if (portions.length === 1) {
              let portion = portions[0];

              portion = { ...portion, points: newCoordinates };

              portions = [portion];
            } else {
              portions = portions.map((portion) => ({
                ...portion,
                points: [],
              }));
            }

            segment.properties = {
              ...segment.properties,
              portions,
            };

            newState.segments.entities[segmentData.idSegment] = segment;
          }
        });

        return newState;
      }

      return state;
    }

    case CircuitActionTypes.PointMoved: {
      const coords = action.payload.coordinates;
      if (coords) {
        return {
          ...state,
          points: {
            ...state.points,
            entities: {
              ...state.points.entities,
              [action.payload.id]: {
                ...state.points.entities[action.payload.id],
                properties: {
                  ...state.points.entities[action.payload.id].properties,
                  segment: action.payload.segment,
                },
                geometry: {
                  ...state.points.entities[action.payload.id].geometry,
                  coordinates: coords,
                },
              },
            },
          },
        };
      }

      return state;
    }

    case CircuitActionTypes.UpdateMeasurer: {
      const coordsMeasurer = action.payload.coordinates;
      if (coordsMeasurer) {
        return {
          ...state,
          measurers: {
            ...state.measurers,
            entities: {
              ...state.measurers.entities,
              [action.payload.measurerId]: {
                ...state.measurers.entities[action.payload.measurerId],
                geometry: {
                  ...state.measurers.entities[action.payload.measurerId].geometry,
                  coordinates: coordsMeasurer,
                },
              },
            },
          },
        };
      }

      return state;
    }

    case CircuitActionTypes.DeleteAllCellTemplates: {
      return {
        ...state,
        cellTemplates: cellTemplateAdapter.getInitialState(),
      };
    }

    case CircuitActionTypes.ImportCellTemplates: {
      const cellTemplates = action.payload.cellTemplates;
      const cellTemplatesIds = Object.keys(cellTemplates);

      // to be removed....the loaded, loading, updating things are useless in this project.......
      const loadedCellTemplates: Record<string, LoadedCellTemplate> = {};
      for (const cellTemplateId in cellTemplates) {
        const cellTemplate = cellTemplates[cellTemplateId];
        loadedCellTemplates[cellTemplateId] = {
          ...cellTemplate,
          loaded: true,
          loading: false,
          updating: false,
        };
      }

      return {
        ...state,
        cellTemplates: {
          ...state.cellTemplates,
          entities: {
            ...state.cellTemplates.entities,
            ...loadedCellTemplates,
          },
          ids: [...state.cellTemplates.ids, ...cellTemplatesIds],
        },
      };
    }

    case CircuitActionTypes.DeleteAllShapes: {
      return getInitialState();
    }

    case CircuitActionTypes.AddMultipleSegments: {
      const payload = action.payload;
      const selectedLayer = state.layers.selectedLayer;

      const newState: CircuitState = {
        ...state,
        segments: {
          ...state.segments,
          entities: {
            ...state.segments.entities,
          },
          ids: [...state.segments.ids],
        },
      };

      const allSegments = Object.values(newState.segments.entities);

      payload.forEach((data, i) => {
        const id = data.id ?? generateShapeId();
        const newSegment: LoadedSegment = {
          id,
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: data.coord,
          },
          properties: {
            name: data.name ?? CircuitService.generateName(ShapeTypes.SegmentShape, allSegments),
            layerId: data.layerId ?? selectedLayer,
            prio: getMaxDisplayPriority(),
            twoWay: false,
            locked: data.locked ?? undefined,
            type: ShapeTypes.SegmentShape,
            portions: [
              {
                id,
                points: data.coord,
              },
            ],
          },
          loaded: true,
        };

        if (data.stockLine) newSegment.properties.stockLine = data.stockLine;
        if (data.rack) newSegment.properties.rack = data.rack;
        if (data.rackColumn) newSegment.properties.rackColumn = data.rackColumn;

        newState.segments.ids.push(id);
        newState.segments.entities[id] = newSegment;

        allSegments.push(newSegment);
      });

      return newState;
    }

    case CircuitActionTypes.AlignElement: {
      const alignAction = action.payload.alignAction;
      const selectedShapes = action.payload.selectedShapes;

      const racksIds = state.racks.ids;
      const racks = racksIds.map((id) => {
        return state.racks.entities[id];
      });

      const zonesIds = state.zones.ids;
      const zones = zonesIds.map((id) => {
        return state.zones.entities[id];
      });

      const segmentsIds = state.segments.ids;
      const segments = segmentsIds.map((id) => {
        return state.segments.entities[id];
      });

      const stockZonesIds = state.stockZones.ids;
      const stockZones = stockZonesIds.map((id) => {
        return state.stockZones.entities[id];
      });

      const newRacksEntities = { ...state.racks.entities };
      const newStockZonesEntities = { ...state.stockZones.entities };
      const newSegmentsEntities = { ...state.segments.entities };
      const newZonesEntities = { ...state.zones.entities };

      const shapesCoordinate = selectedShapes
        .map((shape) => {
          if (isCircuitRack(shape) || isCircuitStockZone(shape)) {
            return shape.geometry.coordinates;
          }

          return null;
        })
        .filter(isDefined);

      // the ref value to align the shapes
      let refAlingValue = findNewPositionToAlignElement(shapesCoordinate[0], alignAction);
      let sumCenter = 0;
      shapesCoordinate.forEach((shapeCoordinate) => {
        const newRefValue = findNewPositionToAlignElement(shapeCoordinate, alignAction);

        if (alignAction === 'horizontallyLeft' && newRefValue < refAlingValue) {
          refAlingValue = newRefValue;
        }

        if (alignAction === 'horizontallyRight' && newRefValue > refAlingValue) {
          refAlingValue = newRefValue;
        }

        if (alignAction === 'verticallyTop' && newRefValue > refAlingValue) {
          refAlingValue = newRefValue;
        }

        if (alignAction === 'verticallyBottom' && newRefValue < refAlingValue) {
          refAlingValue = newRefValue;
        }

        if (alignAction === 'horizontallyCenter' || alignAction === 'verticallyCenter') {
          sumCenter = sumCenter + newRefValue;

          refAlingValue = sumCenter / selectedShapes.length;
        }
      });

      const allSegmentsIdsToUpdate = new Set<string>();

      selectedShapes.forEach((shape) => {
        if (!shape) return;

        if (!isCircuitRack(shape) && !isCircuitStockZone(shape)) return;

        if (shape.properties.locked) return;

        // the value that we will add to the shape coord to align the shape with the other
        const displacement = refAlingValue - findNewPositionToAlignElement(shape.geometry.coordinates, alignAction);

        // segments linked to a rack or stockZone
        const segmentsToUpdate: LoadedSegment[] = [];

        // zone linked to a conveyor
        const zonesToUpdate: LoadedZone[] = [];

        const racksToUpdate = racks.filter((rack) => rack.id === shape.id);

        racksToUpdate.forEach((rack) => {
          if (!rack.id) return;

          const segmentsToUpdateArr = segments.filter((segment) => segment.properties.rack === rack.id);

          segmentsToUpdateArr.map((segment) => {
            return segmentsToUpdate.push(segment);
          });

          const zonesToUpdateArr = zones.filter((zone) => zone.id === rack.properties.conveyor?.zone);

          zonesToUpdateArr.map((zone) => {
            return zonesToUpdate.push(zone);
          });

          const newCoordinates = structuredClone(rack.geometry.coordinates);

          if (newCoordinates[0][0] !== newCoordinates[0][4]) {
            newCoordinates[0][4] = newCoordinates[0][0];
          }

          newCoordinates.forEach((coord) => {
            for (let i = 1; i < coord.length; i++) {
              if (alignAction === 'horizontallyLeft' && coord[i][0] > refAlingValue) {
                coord[i][0] += displacement;
              }

              if (alignAction === 'horizontallyCenter') {
                coord[i][0] += displacement;
              }

              if (alignAction === 'horizontallyRight' && coord[i][0] < refAlingValue) {
                coord[i][0] += displacement;
              }

              if (alignAction === 'verticallyTop' && coord[i][1] < refAlingValue) {
                coord[i][1] += displacement;
              }

              if (alignAction === 'verticallyCenter') {
                coord[i][1] += displacement;
              }

              if (alignAction === 'verticallyBottom' && coord[i][1] > refAlingValue) {
                coord[i][1] += displacement;
              }
            }

            return;
          });

          newRacksEntities[rack.id] = {
            ...rack,
            geometry: {
              ...rack.geometry,
              coordinates: newCoordinates,
            },
          };
        });

        const stockZonesToUpdate = stockZones.filter((stockZone) => stockZone.id === shape.id);

        stockZonesToUpdate.forEach((stockZone) => {
          if (!stockZone.id) return;

          const newCoordinates = structuredClone(stockZone.geometry.coordinates);
          const newSlots = structuredClone(stockZone.properties.slots);

          if (newCoordinates[0][0] !== newCoordinates[0][4]) {
            newCoordinates[0][4] = newCoordinates[0][0];
          }

          newCoordinates.forEach((coord) => {
            for (let i = 1; i < coord.length; i++) {
              if (alignAction === 'horizontallyLeft' && coord[i][0] > refAlingValue) {
                coord[i][0] += displacement;
              }

              if (alignAction === 'horizontallyCenter') {
                coord[i][0] += displacement;
              }

              if (alignAction === 'horizontallyRight' && coord[i][0] < refAlingValue) {
                coord[i][0] += displacement;
              }

              if (alignAction === 'verticallyTop' && coord[i][1] < refAlingValue) {
                coord[i][1] += displacement;
              }

              if (alignAction === 'verticallyCenter') {
                coord[i][1] += displacement;
              }

              if (alignAction === 'verticallyBottom' && coord[i][1] > refAlingValue) {
                coord[i][1] += displacement;
              }
            }

            return;
          });

          newSlots.forEach((slot) => {
            const segmentsToUpdateArr = segments.find((segment) => segment.properties.stockLine === slot.id);
            if (segmentsToUpdateArr !== undefined) {
              segmentsToUpdate.push(segmentsToUpdateArr);
            }

            slot.slots.forEach((slotData) => {
              if (
                alignAction === 'horizontallyLeft' ||
                alignAction === 'horizontallyCenter' ||
                alignAction === 'horizontallyRight'
              ) {
                slotData.slotPosition.x += displacement;
              }

              if (
                alignAction === 'verticallyTop' ||
                alignAction === 'verticallyCenter' ||
                alignAction === 'verticallyBottom'
              ) {
                slotData.slotPosition.y -= displacement;
              }

              for (let i = 0; i < slotData.geometry.length; i++) {
                if (
                  alignAction === 'horizontallyLeft' ||
                  alignAction === 'horizontallyCenter' ||
                  alignAction === 'horizontallyRight'
                ) {
                  slotData.geometry[i][0] += displacement;
                }

                if (
                  alignAction === 'verticallyTop' ||
                  alignAction === 'verticallyCenter' ||
                  alignAction === 'verticallyBottom'
                ) {
                  slotData.geometry[i][1] -= displacement;
                }
              }
            });
          });

          newStockZonesEntities[stockZone.id] = {
            ...stockZone,
            geometry: {
              ...stockZone.geometry,
              coordinates: newCoordinates,
            },
            properties: {
              ...stockZone.properties,
              slots: newSlots,
            },
          };
        });

        segmentsToUpdate.forEach((segment) => {
          if (!segment.id) return;

          if (typeof segment.id === 'string') allSegmentsIdsToUpdate.add(segment.id);

          const newCoordinates = structuredClone(segment.geometry.coordinates);
          const newPortions = structuredClone(segment.properties.portions);

          newCoordinates.forEach((coord) => {
            if (alignAction === 'horizontallyLeft' && coord[0] > refAlingValue) {
              coord[0] += displacement;
            }

            if (alignAction === 'horizontallyCenter') {
              coord[0] += displacement;
            }

            if (alignAction === 'horizontallyRight' && coord[0] < refAlingValue) {
              coord[0] += displacement;
            }

            if (alignAction === 'verticallyTop' && coord[1] < refAlingValue) {
              coord[1] += displacement;
            }

            if (alignAction === 'verticallyCenter') {
              coord[1] += displacement;
            }

            if (alignAction === 'verticallyBottom' && coord[1] > refAlingValue) {
              coord[1] += displacement;
            }

            return;
          });

          newPortions.forEach((portion) => {
            for (let i = 0; i < portion.points.length; i++) {
              if (alignAction === 'horizontallyLeft' && portion.points[i][0] > refAlingValue) {
                portion.points[i][0] += displacement;
              }

              if (alignAction === 'horizontallyCenter') {
                portion.points[i][0] += displacement;
              }

              if (alignAction === 'horizontallyRight' && portion.points[i][0] < refAlingValue) {
                portion.points[i][0] += displacement;
              }

              if (alignAction === 'verticallyTop' && portion.points[i][1] < refAlingValue) {
                portion.points[i][1] += displacement;
              }

              if (alignAction === 'verticallyCenter') {
                portion.points[i][1] += displacement;
              }

              if (alignAction === 'verticallyBottom' && portion.points[i][1] > refAlingValue) {
                portion.points[i][1] += displacement;
              }
            }

            return;
          });

          newSegmentsEntities[segment.id] = {
            ...segment,
            geometry: {
              ...segment.geometry,
              coordinates: newCoordinates,
            },
            properties: {
              ...segment.properties,
              portions: newPortions,
            },
          };
        });

        zonesToUpdate.forEach((zone) => {
          if (!zone.id) return;

          const newCoordinates = structuredClone(zone.geometry.coordinates);

          if (newCoordinates[0][0] !== newCoordinates[0][4]) {
            newCoordinates[0][4] = newCoordinates[0][0];
          }

          newCoordinates.forEach((coord) => {
            for (let i = 1; i < coord.length; i++) {
              if (alignAction === 'horizontallyLeft' && coord[i][0] > refAlingValue) {
                coord[i][0] += displacement;
              }

              if (alignAction === 'horizontallyCenter') {
                coord[i][0] += displacement;
              }

              if (alignAction === 'horizontallyRight' && coord[i][0] < refAlingValue) {
                coord[i][0] += displacement;
              }

              if (alignAction === 'verticallyTop' && coord[i][1] < refAlingValue) {
                coord[i][1] += displacement;
              }

              if (alignAction === 'verticallyCenter') {
                coord[i][1] += displacement;
              }

              if (alignAction === 'verticallyBottom' && coord[i][1] > refAlingValue) {
                coord[i][1] += displacement;
              }
            }

            return;
          });

          newZonesEntities[zone.id] = {
            ...zone,
            geometry: {
              ...zone.geometry,
              coordinates: newCoordinates,
            },
          };
        });
      });

      const newState = {
        ...state,
        racks: {
          ...state.racks,
          entities: newRacksEntities,
        },
        segments: {
          ...state.segments,
          entities: newSegmentsEntities,
        },
        stockZones: {
          ...state.stockZones,
          entities: newStockZonesEntities,
        },
        zones: {
          ...state.zones,
          entities: newZonesEntities,
        },
      };

      return newState;
    }

    default: {
      return state;
    }
  }
}

export function CellTemplateReducer2(state = getInitialState(), action: CellTemplateActions): CircuitState {
  switch (action.type) {
    case CellTemplateActionTypes.DeleteSuccess: {
      const { id } = (action as DeleteCellTemplateSuccess).payload;

      const racksIds = state.racks.ids;
      const racks = state.racks.entities;

      const racksToChange: LoadedRack[] = [];

      for (let i = 0; i < racksIds.length; i++) {
        const rackId = racksIds[i];
        const rack = racks[rackId];
        let changeInThisRack = false;

        let newRack: LoadedRack | undefined;
        let newColumns: RackColumn[] | undefined;

        for (let j = 0; j < rack.properties.columns.length; j++) {
          const column = rack.properties.columns[j];
          let newCells: RackCell[] | undefined;
          let changeInThisColumn = false;
          for (let k = 0; k < column.cells.length; k++) {
            const cell = column.cells[k];
            if (cell.cellTemplate === id) {
              if (!newCells) newCells = [...column.cells];

              newCells[k] = {
                ...cell,
                cellTemplate: undefined,
              };

              changeInThisColumn = changeInThisRack = true;
            }
          }

          if (changeInThisColumn && newCells) {
            if (!newColumns) newColumns = [...rack.properties.columns];

            const newColumn = {
              ...column,
              cells: newCells,
            };
            newColumns[j] = newColumn;
          }
        }

        if (changeInThisRack && newColumns) {
          newRack = {
            ...rack,
            properties: {
              ...rack.properties,
              columns: newColumns,
            },
          };
          racksToChange.push(newRack);
        }
      }

      if (racksToChange.length) {
        const newState = {
          ...state,
          racks: {
            ...state.racks,
          },
        };

        racksToChange.forEach((rack) => {
          if (rack.id) {
            newState.racks.entities[rack.id] = rack;
          }
        });

        // we save the rack to run the check rack algorithm (not that nice to put it here because we make this reducer impure)
        setTimeout(() => {
          racksToChange.forEach((rack) => {
            window?.dispatchStore(
              saveRackAction({
                id: rack.id,
              })
            );
          });
        }, 0);

        return newState;
      }

      return state;
    }

    case CellTemplateActionTypes.CreateSuccess:
    case CellTemplateActionTypes.Save:
    case CellTemplateActionTypes.SaveSuccess: {
      /**
       * When saving/creating a cell template, we check if all the loads names are unique.
       * If not, we genrate a new name not used in the project
       */
      const payload = (action as CreateCellTemplateSuccess | SaveCellTemplateSuccess).payload;
      const id = payload.id;
      const newNames = payload.loads.map((load) => load.name.toUpperCase());
      const names = new Set();

      const isNewNameUnique = newNames.map((_) => true);

      // checking the unicity of the load name in this cell template only
      const cellTemplate = state.cellTemplates.entities[id];
      cellTemplate.loads.forEach((load, loadIndex) => {
        const name = load.name;
        names.add(name.toUpperCase());

        const indexInNewNames = newNames.lastIndexOf(name);

        if (indexInNewNames !== -1) {
          // we don't want that the name of the load conflicts with itself
          if (newNames.findIndex((n) => n.toUpperCase() === name) === loadIndex) return;

          isNewNameUnique[indexInNewNames] = false;
        }
      });

      const isNewNameValid = newNames.map(
        (name, index) => isNewNameUnique[index] && name.length > 0 && name.match(regexCellTemplateLoadName)
      );
      const isAllNamesValid = isNewNameValid.every((b) => b);

      if (!isAllNamesValid) {
        for (let i = 0; i < newNames.length; i++) {
          const isNameValid = isNewNameValid[i];
          if (!isNameValid) {
            const nameBefore = newNames[i];
            newNames[i] = generateUniqueLoadName([...(Array.from(names) as string[]), ...newNames]);

            // NOTE: reducers should be pure and have no side effects
            // the following lines breaks the pureness of the reducer
            // and it's not a good idea to do it in a reducer
            if (!window.__TEST__)
              SnackbarUtils.toast(
                `The name "${nameBefore}" is not valid or a duplicate. It has been replaced automatically by "${newNames[i]}".`
              );
            // END NOTE
          }
        }

        const newState = {
          ...state,
          cellTemplates: {
            ...state.cellTemplates,
          },
        };

        newState.cellTemplates.entities[id] = {
          ...state.cellTemplates.entities[id],
          loads: payload.loads.map((load, index) => ({
            ...load,
            name: newNames[index],
          })),
        };

        return newState;
      }
    }
  }

  return state;
}

export function rackReducerAnomalyCheck(state = getInitialState(), action: RackActions): CircuitState {
  switch (action.type) {
    case RackActionTypes.CreateSuccess:
    case RackActionTypes.Save: {
      const { id } = (action as CreateCellTemplateSuccess | SaveCellTemplateSuccess).payload;

      const rack = state.racks.entities[id];

      const anomaly: EntityAnomaly = {
        state: 0,
        issues: [],
      };

      for (let i = 0, l1 = rack.properties.columns.length; i < l1; i++) {
        const column = rack.properties.columns[i];
        for (let j = 0, l2 = column.cells.length; j < l2; j++) {
          const cell = column.cells[j];

          /** all the cells must have a cell template */
          if (!cell.cellTemplate && !cell.disabled) {
            anomaly.state = 2; // error
            anomaly.issues.push(RackAnomalyIds.cellNotAssigned);
          }

          if (!cell.cellTemplate || cell.disabled) continue;

          const cellTemplate = state.cellTemplates.entities[cell.cellTemplate];
          if (!cellTemplate) continue;

          /** all the cells must have enough space for their loads */
          for (let k = 0; k < cellTemplate.loads.length; k++) {
            const load = cellTemplate.loads[k];
            const widthTakenByLoads = computeWidthTakenByLoads(load, true);
            const cellWidth = column.width;

            if (widthTakenByLoads > cellWidth) {
              anomaly.state = 2; // error
              anomaly.issues.push(RackAnomalyIds.cellNotEnoughSpace);

              break; // only one error per cell for this type of error
            }
          }

          /** all the cells must have higher heigh than the maximum load height */
          if (!cellTemplate.maximumLoadHeight || cell.height >= cellTemplate.maximumLoadHeight) continue;

          for (let k = 0; k < cellTemplate.loads.length; k++) {
            anomaly.state = 1; // warning
            anomaly.issues.push(RackAnomalyIds.cellNotEnoughHeight);

            break; // only one error per cell for this type of error
          }

          if (cellTemplate.palletOverflow < minRecommendedPalletOverflow) {
            anomaly.state = 1; // warning
            anomaly.issues.push(RackAnomalyIds.palletOverflowNotEnough);
          }

          if (cellTemplate.palletOverflow > maxRecommendedPalletOverflow) {
            anomaly.state = 1; // warning
            anomaly.issues.push(RackAnomalyIds.palletOverflowTooMuch);
          }

          for (let k = 0; k < cellTemplate.loads.length; k++) {
            const load = cellTemplate.loads[i];

            if (
              (!load.center && load.a1 < minDistanceLoadUpright) ||
              (load.a2 !== undefined ? load.a2 < minDistanceLoadUpright : false)
            ) {
              anomaly.state = 1; // warning
              anomaly.issues.push(RackAnomalyIds.cellNotEnoughMarginLoadUpright);

              break; // only one error per cell for this type of error
            }
          }
        }
      }

      for (let i = 0; i < rack.properties.uprights.length; i++) {
        const upright = rack.properties.uprights[i];

        if (upright.enabled && upright.width < minRecommendedUprightWidth) {
          anomaly.state = 1; // warning
          anomaly.issues.push(RackAnomalyIds.uprightWidthNotEnough);
        }
      }

      if (rack.properties.defaultBeamThickness < minRecommendedBeamThickness) {
        anomaly.state = 1; // warning
        anomaly.issues.push(RackAnomalyIds.beamThicknessNotEnough);
      }

      const newRackState: LoadedRack = {
        ...rack,
        properties: {
          ...rack.properties,
          anomaly: anomaly.state ? anomaly : undefined,
        },
      };

      return {
        ...state,

        racks: {
          ...state.racks,
          entities: {
            ...state.racks.entities,
            [id]: newRackState,
          },
        },
      };
    }
  }

  return state;
}

export function rackReducerPositionNames(
  state = getInitialState(),
  action: RackActions | CellTemplateActions
): CircuitState {
  switch (action.type) {
    case RackActionTypes.CreateSuccess:
    case RackActionTypes.SaveSuccess:
    case CellTemplateActionTypes.CreateSuccess:
    case CellTemplateActionTypes.SaveSuccess:
    case CellTemplateActionTypes.Save: {
      type actionType =
        | CreateCellTemplateSuccess
        | SaveCellTemplateSuccess
        | SaveCellTemplate
        | CreateCellTemplateSuccess
        | CreateRackSuccess
        | SaveRackSuccess;
      const payload = (action as actionType).payload;

      // first we need to get to which rack we need to check the positions names
      // if it's a rack action, it's only this very rack
      // if it's a cell template action, we need to check all the racks where the cell template is used
      const id = payload.id;
      if (!id) return state;

      const isItARackAction =
        action.type === RackActionTypes.CreateSuccess || action.type === RackActionTypes.SaveSuccess;

      const racksToProcess = isItARackAction
        ? [id]
        : (() => {
            const rackIds = state.racks.ids;
            const rackEntities = state.racks.entities;
            const r: string[] = [];

            for (let i = 0; i < rackIds.length; i++) {
              const rackId = rackIds[i];
              const rack = rackEntities[rackId];

              if (rack.properties.columns.some((column) => column.cells.some((cell) => cell.cellTemplate === id))) {
                r.push(rackId);
              }
            }

            return r;
          })();

      if (!racksToProcess.length) return state;

      const newRacksEntities = { ...state.racks.entities };
      for (let i = 0; i < racksToProcess.length; i++) {
        const rackId = racksToProcess[i];
        const rack = newRacksEntities[rackId];

        newRacksEntities[rackId] = {
          ...rack,
          properties: {
            ...rack.properties,
            columns: rack.properties.columns.map((column, indexColumn) => ({
              ...column,
              cells: column.cells.map((cell, indexCell) => {
                if ((isItARackAction || cell.cellTemplate === id) && cell.cellTemplate) {
                  const cellTemplate = state.cellTemplates.entities[cell.cellTemplate];

                  const newPositionNames = generatePositionNames(
                    rack,
                    cell,
                    { column: indexColumn, level: indexCell },
                    cellTemplate
                  );

                  return {
                    ...cell,
                    names: newPositionNames.map((loadNames, indexLoad) =>
                      loadNames.map((name, indexName) => {
                        // if the name is already defined by the user, we keep it
                        if (cell.names?.[indexLoad]?.[indexName]?.user) {
                          return cell.names[indexLoad][indexName];
                        }

                        if (
                          'properties' in payload &&
                          payload.properties.columns?.[indexColumn]?.cells?.[indexCell]?.names?.[indexLoad]?.[indexName]
                            ?.user
                        ) {
                          return payload.properties.columns[indexColumn].cells[indexCell].names[indexLoad][indexName];
                        }

                        const disabled =
                          'properties' in payload &&
                          payload.properties?.columns?.[indexColumn]?.cells?.[indexCell]?.names?.[indexLoad]?.[
                            indexName
                          ]?.disabled;

                        const newNames: (typeof cell)['names'][0][0] = {
                          value: name,
                          user: false,
                          id: cell.names?.[indexLoad]?.[indexName]?.id ?? generateShapeId(),
                          disabled,
                        };
                        if (!disabled) delete newNames.disabled;

                        return newNames;
                      })
                    ),
                  };
                }

                return cell;
              }),
            })),
          },
        };
      }

      return {
        ...state,
        racks: {
          ...state.racks,
          entities: newRacksEntities,
        },
      };
    }
  }

  return state;
}

export function updateLayerReducer(state = getInitialState(), action: CircuitActions): CircuitState {
  switch (action.type) {
    case CircuitActionTypes.EnableAllGabarits: {
      // here we want to update the property gabarit of all the shapes in the selected layer to enable/disable all the gabarit
      // shapes that can have a gabarit: point, segments, turns

      const layerId = action.payload.layerId;
      const defaultModel = action.payload.defaultModel;
      const defaultPattern = action.payload.defaultPattern;

      const newPointsEntities = { ...state.points.entities };
      const newSegmentsEntities = { ...state.segments.entities };
      const newTurnsEntities = { ...state.turns.entities };

      const segmentsIds = state.segments.ids;
      const segments = segmentsIds.map((id) => {
        return state.segments.entities[id];
      });
      const segmentsToUpdate = layerId
        ? segments.filter((segment) => segment.properties.layerId === layerId)
        : segments;

      segmentsToUpdate.forEach((segment) => {
        const segmentId = segment.id;

        if (segmentId) {
          newSegmentsEntities[segmentId] = {
            ...segment,
            properties: {
              ...segment.properties,
              gabarit: {
                display: true,
                modelName: defaultModel,
                type: defaultPattern,
              },
            },
          };
        }
      });

      const turnsIds = state.turns.ids;
      const turns = turnsIds.map((id) => {
        return state.turns.entities[id];
      });
      const turnsToUpdate = layerId ? turns.filter((turn) => turn.properties.layerId === layerId) : turns;

      turnsToUpdate.forEach((turn) => {
        const turnId = turn.id;

        if (turnId) {
          newTurnsEntities[turnId] = {
            ...turn,
            properties: {
              ...turn.properties,
              gabarit: {
                display: true,
                modelName: defaultModel,
                type: defaultPattern,
              },
            },
          };
        }
      });

      const pointsIds = state.points.ids;
      const points = pointsIds.map((id) => {
        return state.points.entities[id];
      });
      const pointsToUpdate = layerId ? points.filter((point) => point.properties.layerId === layerId) : points;

      pointsToUpdate.forEach((point) => {
        const pointId = point.id;

        if (pointId) {
          newPointsEntities[pointId] = {
            ...point,
            properties: {
              ...point.properties,
              gabarit: {
                display: true,
                modelName: defaultModel,
                type: defaultPattern,
              },
            },
          };
        }
      });

      const newState = {
        ...state,
        points: {
          ...state.points,
          entities: newPointsEntities,
        },
        segments: {
          ...state.segments,
          entities: newSegmentsEntities,
        },
        turns: {
          ...state.turns,
          entities: newTurnsEntities,
        },
      };

      return newState;
    }

    case CircuitActionTypes.UpdateGabaritState: {
      // here we want to show or hide the gabarit present in a layer
      // shapes that can have a gabarit: point, segments, turns

      const layerId = action.payload.layerId;
      const newDisplayGabarit = action.payload.newDisplayGabarit;

      const newPointsEntities = { ...state.points.entities };
      const newSegmentsEntities = { ...state.segments.entities };
      const newTurnsEntities = { ...state.turns.entities };
      const newLayer = { ...state.layers.layers };

      if (newDisplayGabarit !== undefined) {
        const segmentsIds = state.segments.ids;
        const segments = segmentsIds.map((id) => {
          return state.segments.entities[id];
        });
        const segmentsRackToUpdate = layerId
          ? segments.filter((segment) => segment.properties.layerId === layerId)
          : segments;

        segmentsRackToUpdate.forEach((segment) => {
          const segmentId = segment.id;

          if (segmentId && segment.properties.gabarit) {
            newSegmentsEntities[segmentId] = {
              ...segment,
              properties: {
                ...segment.properties,
                gabarit: {
                  display: newDisplayGabarit,
                  modelName: segment.properties.gabarit.modelName,
                  type: segment.properties.gabarit.type,
                },
              },
            };
          }
        });

        const turnsIds = state.turns.ids;
        const turns = turnsIds.map((id) => {
          return state.turns.entities[id];
        });
        const turnsToUpdate = layerId ? turns.filter((turn) => turn.properties.layerId === layerId) : turns;

        turnsToUpdate.forEach((turn) => {
          const turnId = turn.id;

          if (turnId && turn.properties.gabarit) {
            newTurnsEntities[turnId] = {
              ...turn,
              properties: {
                ...turn.properties,
                gabarit: {
                  display: newDisplayGabarit,
                  modelName: turn.properties.gabarit.modelName,
                  type: turn.properties.gabarit.type,
                },
              },
            };
          }
        });

        const pointsIds = state.points.ids;
        const points = pointsIds.map((id) => {
          return state.points.entities[id];
        });
        const pointsToUpdate = layerId ? points.filter((point) => point.properties.layerId === layerId) : points;

        pointsToUpdate.forEach((point) => {
          const pointId = point.id;

          if (pointId && point.properties.gabarit) {
            newPointsEntities[pointId] = {
              ...point,
              properties: {
                ...point.properties,
                gabarit: {
                  display: newDisplayGabarit,
                  modelName: point.properties.gabarit.modelName,
                  type: point.properties.gabarit.type,
                },
              },
            };
          }
        });

        if (action.payload.newDisplayGabarit !== undefined && layerId) {
          newLayer[layerId] = {
            ...newLayer[layerId],
            displayGabarits: newDisplayGabarit,
          };
        }

        const newState = {
          ...state,
          points: {
            ...state.points,
            entities: newPointsEntities,
          },
          segments: {
            ...state.segments,
            entities: newSegmentsEntities,
          },
          turns: {
            ...state.turns,
            entities: newTurnsEntities,
          },
          layers: {
            ...state.layers,
            layers: newLayer,
          },
        };

        return newState;
      }

      return state;
    }

    case CircuitActionTypes.CopyAllShapes: {
      // here we want to duplicate all the shapes present on the selected layer to create a duplicate layer of the selected one

      const toLayerId = action.payload.toLayerId;
      const shapesFrom = structuredClone(action.payload.shapes);

      /**
       ** key: former id
       ** value: new id
       */
      const ids = new Map<string, string>();

      // for each duplicated shape we want to create a new id base on the old one
      shapesFrom.forEach((shapeFrom) => {
        if (!shapeFrom.id || typeof shapeFrom.id !== 'string') return;
        const newId = generateShapeId();

        ids.set(shapeFrom.id, newId);

        if (isCircuitStockZone(shapeFrom)) {
          shapeFrom.properties.slots.forEach((stockLine) => {
            const newStockLinesIds = generateShapeId();

            ids.set(stockLine.id, newStockLinesIds);
          });
        }

        if (isCircuitRack(shapeFrom)) {
          shapeFrom.properties.columns.forEach((column) => {
            const newStockColumnIds = generateShapeId();

            ids.set(column.id, newStockColumnIds);
          });
        }

        if (isCircuitSegment(shapeFrom)) {
          shapeFrom.properties.portions.forEach((portion) => {
            const newPortionId = generateShapeId();

            ids.set(portion.id, newPortionId);
          });
        }
      });

      return produce(state, (draftState) => {
        let newSegmentsEntities = { ...draftState.segments.entities };
        const newSegmentsIds = [...draftState.segments.ids];

        let newMeasurersEntities = { ...draftState.measurers.entities };
        const newMeasurersIds = [...draftState.measurers.ids];

        let newZonesEntities = { ...draftState.zones.entities };
        const newZonesIds = [...draftState.zones.ids];

        let newStockZonesEntities = { ...draftState.stockZones.entities };
        const newStockZonesIds = [...draftState.stockZones.ids];

        let newRacksEntities = { ...draftState.racks.entities };
        const newRacksIds = [...draftState.racks.ids];

        let newPointsEntities = { ...draftState.points.entities };
        const newPointsIds = [...draftState.points.ids];

        let newNotesEntities = { ...draftState.notes.entities };
        const newNotesIds = [...draftState.notes.ids];

        let newDevicesEntities = { ...draftState.devices.entities };
        const newDevicesIds = [...draftState.devices.ids];

        let newTurnsEntities = { ...draftState.turns.entities };
        const newTurnsIds = [...draftState.turns.ids];

        shapesFrom.forEach((shape) => {
          if (typeof shape.id !== 'string') return;

          const newShapeId = ids.get(shape.id);
          // we need an existing id to create a newShape and avoid error
          if (!newShapeId) return;

          // for each new shape on the duplicated layer, we want to update at least their id, name and layerId
          const newShape = {
            ...shape,
            id: newShapeId,
            properties: {
              ...shape.properties,
              name: `${shape.properties.name} (copy)`,
              layerId: toLayerId,
            },
          };

          if (isCircuitSegment(shape) && isCircuitSegment(newShape)) {
            newSegmentsIds.push(newShapeId);

            // We need to update the linked stockLine by finding the new id of the new linked stockLine
            if (shape.properties.stockLine) {
              const newStockLineId = ids.get(shape.properties.stockLine);

              if (newStockLineId && newShape.properties.stockLine) {
                newShape.properties.stockLine = newStockLineId;
              }
            }

            // Same for the rack and rackColumn
            if (shape.properties.rack) {
              const newRackId = ids.get(shape.properties.rack);

              if (newRackId && newShape.properties.rack) {
                newShape.properties.rack = newRackId;
              }
            }

            if (shape.properties.rackColumn) {
              const newRackColumnId = ids.get(shape.properties.rackColumn);

              if (newRackColumnId && newShape.properties.rackColumn) {
                newShape.properties.rackColumn = newRackColumnId;
              }
            }

            // We need to update each portion of the new segment
            if (newShape.properties.portions) {
              const formerPortions = newShape.properties.portions;

              const newPortions = formerPortions
                .map((portion) => {
                  const newId = ids.get(portion.id);
                  if (!newId) return null;

                  const inStart = portion.inStart?.map((id) => ids.get(id) as string);

                  const inEnd = portion.inEnd?.map((id) => ids.get(id) as string);

                  const outStart = portion.outStart?.map((id) => ids.get(id) as string);

                  const outEnd = portion.outEnd?.map((id) => ids.get(id) as string);

                  const issue =
                    inStart?.some((id) => !isDefined(id)) ||
                    inEnd?.some((id) => !isDefined(id)) ||
                    outStart?.some((id) => !isDefined(id)) ||
                    outEnd?.some((id) => !isDefined(id));

                  if (issue) return null;

                  const newPortion = {
                    ...portion,
                    id: newId,
                    inStart: inStart,
                    inEnd: inEnd,
                    outStart: outStart,
                    outEnd: outEnd,
                  };

                  return removeUndefinedKeys(newPortion);
                })
                .filter(isDefined);

              newShape.properties.portions = newPortions;
            }

            newSegmentsEntities = { ...newSegmentsEntities, [newShapeId]: newShape as LoadedSegment };

            return;
          }

          if (isCircuitMeasurer(shape) && isCircuitMeasurer(newShape)) {
            newMeasurersIds.push(newShapeId);

            // We need to update the linked shape id by finding the new id of the new linked shape
            if (shape.properties.link0) {
              const newLink0Id = ids.get(shape.properties.link0?.id);

              if (newLink0Id && newShape.properties.link0) {
                newShape.properties.link0.id = newLink0Id;
              }
            }

            if (shape.properties.link1) {
              const newLink1Id = ids.get(shape.properties.link1?.id);

              if (newLink1Id && newShape.properties.link1) {
                newShape.properties.link1.id = newLink1Id;
              }
            }

            newMeasurersEntities = { ...newMeasurersEntities, [newShapeId]: newShape as LoadedMeasurer };

            return;
          }

          if (isCircuitZone(shape) && isCircuitZone(newShape)) {
            newZonesIds.push(newShapeId);

            // We need to update the conveyor by finding the new id of the new linked conveyor
            if (shape.properties.conveyor) {
              const newConveyorId = ids.get(shape.properties.conveyor);

              if (newConveyorId && newShape.properties.conveyor) {
                newShape.properties.conveyor = newConveyorId;
              }
            }

            newZonesEntities = { ...newZonesEntities, [newShapeId]: newShape as LoadedZone };

            return;
          }

          if (isCircuitStockZone(shape) && isCircuitStockZone(newShape)) {
            newStockZonesIds.push(newShapeId);

            // We need to update the stockLine id by creating a new id base on the old one
            const newStockLines: typeof shape.properties.slots = shape.properties.slots.map((stockLine) => {
              const newId = ids.get(stockLine.id);
              if (!newId) throw new Error(`Failed in the copy of the stock line ${stockLine.id}`);

              const newStockLine = {
                ...stockLine,
                id: newId,
              };

              return newStockLine;
            });

            newShape.properties.slots = newStockLines;

            newStockZonesEntities = { ...newStockZonesEntities, [newShapeId]: newShape as LoadedStockZone };

            return;
          }

          if (isCircuitRack(shape) && isCircuitRack(newShape)) {
            newRacksIds.push(newShapeId);

            // We need to update each column to be sure we don't have any duplicate id or name
            const newRackColumns = shape.properties.columns.map((column) => {
              const newExtendedLenghtSegments = column.extendedLengthSegments.map((segment) => {
                const newId = ids.get(segment[0]);
                if (!newId) throw new Error(`Cannot find new id for ${segment[0]}`);

                const newSegment = [newId, segment[1]] as typeof segment;

                return newSegment;
              });

              const newCells = column.cells.map((cell) => {
                const newCellId = generateShapeId();
                if (!newCellId) throw new Error(`Cannot find new id for ${cell.id}`);

                const newNames = cell.names.map((nameArray) => {
                  const newNameValue = nameArray.map((name) => {
                    const newNameId = generateShapeId();

                    const newName = { ...name, id: newNameId, value: `${name.value} (copy)` };

                    return newName;
                  });

                  return newNameValue;
                });

                const newCell = { ...cell, id: newCellId, names: newNames };

                return newCell;
              });

              const newColumnId = ids.get(column.id);
              if (!newColumnId) throw new Error(`Cannot find new id for ${column.id}`);

              const newColumn = {
                ...column,
                id: newColumnId,
                extendedLengthSegments: newExtendedLenghtSegments,
                cells: newCells,
              };

              return newColumn;
            });

            newShape.properties.columns = newRackColumns;

            // We need to update the linked zone by finding the new id of the new linked zone
            if (shape.properties.conveyor) {
              const newZoneId = ids.get(shape.properties.conveyor.zone);

              if (newZoneId && newShape.properties.conveyor) {
                newShape.properties.conveyor.zone = newZoneId;
              }
            }

            newRacksEntities = { ...newRacksEntities, [newShapeId]: newShape as LoadedRack };

            return;
          }

          if (isCircuitPoint(shape) && isCircuitPoint(newShape)) {
            newPointsIds.push(newShapeId);

            // We need to update the snapped segment by finding the new id of the new snapped segment
            if (shape.properties.segment) {
              const newSnappedSegmentId = ids.get(shape.properties.segment?.id);

              if (newSnappedSegmentId && newShape.properties.segment) {
                newShape.properties.segment.id = newSnappedSegmentId;
              }
            }

            newPointsEntities = { ...newPointsEntities, [newShapeId]: newShape as LoadedPoint };

            return;
          }

          if (isCircuitNote(shape)) {
            newNotesIds.push(newShapeId);

            newNotesEntities = { ...newNotesEntities, [newShapeId]: newShape as LoadedNote };

            return;
          }

          if (isCircuitDevice(shape)) {
            newDevicesIds.push(newShapeId);

            newDevicesEntities = { ...newDevicesEntities, [newShapeId]: newShape as LoadedDevice };

            return;
          }

          if (isCircuitTurn(shape) && isCircuitTurn(newShape)) {
            newTurnsIds.push(newShapeId);

            // We need to update the origin and destination segment by finding the new id of the new segments
            if (shape.properties.originId) {
              const newOriginId = ids.get(shape.properties.originId);

              if (newOriginId && newShape.properties.originId) {
                newShape.properties.originId = newOriginId;
              }
            }

            if (shape.properties.destinationId) {
              const newDestinationId = ids.get(shape.properties.destinationId);

              if (newDestinationId && newShape.properties.destinationId) {
                newShape.properties.destinationId = newDestinationId;
              }
            }

            newTurnsEntities = { ...newTurnsEntities, [newShapeId]: newShape as LoadedTurn };

            return;
          }
        });

        const newState = {
          ...draftState,
          segments: {
            ...draftState.segments,
            entities: newSegmentsEntities,
            ids: newSegmentsIds,
          },
          measurers: {
            ...draftState.measurers,
            entities: newMeasurersEntities,
            ids: newMeasurersIds,
          },
          zones: {
            ...draftState.zones,
            entities: newZonesEntities,
            ids: newZonesIds,
          },
          stockZones: {
            ...draftState.stockZones,
            entities: newStockZonesEntities,
            ids: newStockZonesIds,
          },
          racks: {
            ...draftState.racks,
            entities: newRacksEntities,
            ids: newRacksIds,
          },
          points: {
            ...draftState.points,
            entities: newPointsEntities,
            ids: newPointsIds,
          },
          notes: {
            ...draftState.notes,
            entities: newNotesEntities,
            ids: newNotesIds,
          },
          devices: {
            ...draftState.devices,
            entities: newDevicesEntities,
            ids: newDevicesIds,
          },
          turns: {
            ...draftState.turns,
            entities: newTurnsEntities,
            ids: newTurnsIds,
          },
        };

        return newState;
      });
    }

    case CircuitActionTypes.CleanUpExcessSegmentLengths: {
      const shapes = action.payload.shapes;

      // Create deep copies of the necessary parts of the state
      const newSegmentsEntities = { ...state.segments.entities };
      const newMeasurersEntities = { ...state.measurers.entities };
      const newPointsEntities = { ...state.points.entities };

      const measurersIds = [...state.measurers.ids];
      const pointsIds = [...state.points.ids];

      const measurers = measurersIds.map((id) => newMeasurersEntities[id]);
      const points = pointsIds.map((id) => newPointsEntities[id]);

      shapes.forEach((shape) => {
        if (!isCircuitSegment(shape)) return;

        // If the segment is locked, we don't want to clean it
        if (shape.properties.locked) return;
        if (!shape.id) return;

        const portionArrLength = shape.properties.portions.length;

        // If there is less than 3 portions, we don't want to clean the segment
        if (portionArrLength < 3) return;

        const newFirstPortion = { ...shape.properties.portions[0] };
        const newLastPortion = { ...shape.properties.portions[portionArrLength - 1] };

        const measurersOnTheSegment = measurers.filter(
          (measurer) => measurer.properties.link0?.id === shape.id || measurer.properties.link1?.id === shape.id
        );

        // Verify if there are measurers attached on the portions we want to clean
        // If it's true, we want to detach the measurer
        measurersOnTheSegment.forEach((measurer) => {
          const updatedMeasurer = { ...measurer, properties: { ...measurer.properties } };
          if (!updatedMeasurer.id) return;

          if (updatedMeasurer.properties.link0 && updatedMeasurer.properties.link0.id === shape.id) {
            const isMeasurerOnFirstPortion = isPointOnSegment(
              newFirstPortion.points[0],
              updatedMeasurer.geometry.coordinates[0],
              newFirstPortion.points[1]
            );

            const isMeasurerOnLastPortion = isPointOnSegment(
              newLastPortion.points[0],
              updatedMeasurer.geometry.coordinates[0],
              newLastPortion.points[1]
            );

            if (isMeasurerOnFirstPortion || isMeasurerOnLastPortion) {
              delete updatedMeasurer.properties.link0;
              newMeasurersEntities[updatedMeasurer.id] = updatedMeasurer;
            }
          }

          if (updatedMeasurer.properties.link1 && updatedMeasurer.properties.link1.id === shape.id) {
            const isMeasurerOnFirstPortion = isPointOnSegment(
              newFirstPortion.points[0],
              updatedMeasurer.geometry.coordinates[1],
              newFirstPortion.points[1]
            );

            const isMeasurerOnLastPortion = isPointOnSegment(
              newLastPortion.points[0],
              updatedMeasurer.geometry.coordinates[1],
              newLastPortion.points[1]
            );

            if (isMeasurerOnFirstPortion || isMeasurerOnLastPortion) {
              delete updatedMeasurer.properties.link1;
              newMeasurersEntities[updatedMeasurer.id] = updatedMeasurer;
            }
          }
        });

        const pointsOnTheSegment = points.filter((point) => point.properties.segment?.id === shape.id);

        // Verify if there are points on the portions we want to clean
        // If it's true, we don't want to clean the concerned portion
        let isPointOnFirstPortion = false;
        let isPointOnLastPortion = false;

        pointsOnTheSegment.forEach((point) => {
          const isPointOnFirstPortionVar = isPointOnSegment(
            newFirstPortion.points[0],
            point.geometry.coordinates,
            newFirstPortion.points[1]
          );

          const isPointOnLastPortionVar = isPointOnSegment(
            newLastPortion.points[0],
            point.geometry.coordinates,
            newLastPortion.points[1]
          );

          if (isPointOnFirstPortion === false) {
            isPointOnFirstPortion = isPointOnFirstPortionVar;
          }

          if (isPointOnLastPortion === false) {
            isPointOnLastPortion = isPointOnLastPortionVar;
          }
        });

        const length = Math.sqrt(
          Math.pow(shape.geometry.coordinates[1][0] - shape.geometry.coordinates[0][0], 2) +
            Math.pow(shape.geometry.coordinates[1][1] - shape.geometry.coordinates[0][1], 2)
        );

        const unitVectorX = (shape.geometry.coordinates[1][0] - shape.geometry.coordinates[0][0]) / length;
        const unitVectorY = (shape.geometry.coordinates[1][1] - shape.geometry.coordinates[0][1]) / length;

        // Extend the segment with a fixed value
        const dx = 50 * unitVectorX;
        const dy = 50 * unitVectorY;

        // Calculations of new first portion coordinates
        const newFirstPortionX = newFirstPortion.points[1][0] - dx;
        const newFirstPortionY = newFirstPortion.points[1][1] - dy;

        const currentFirstPortionLength = getDistanceBetweenPoints(
          newFirstPortion.points[0],
          newFirstPortion.points[1]
        );
        const newFirstPortionLength = getDistanceBetweenPoints(
          [newFirstPortionX, newFirstPortionY],
          newFirstPortion.points[1]
        );

        //Calculations of new last portion coordinates
        const newLastPortionX = newLastPortion.points[0][0] + dx;
        const newLastPortionY = newLastPortion.points[0][1] + dy;

        const currentLastPortionLength = getDistanceBetweenPoints(newLastPortion.points[0], newLastPortion.points[1]);
        const newLastPortionLength = getDistanceBetweenPoints(
          [newLastPortionX, newLastPortionY],
          newLastPortion.points[1]
        );

        // Clean the segment if there are no points on the concerned portion and if the new portion is smaller than the current one
        if (isPointOnFirstPortion === false && currentFirstPortionLength > newFirstPortionLength) {
          newFirstPortion.points = [[newFirstPortionX, newFirstPortionY], newFirstPortion.points[1]];
        }

        if (isPointOnLastPortion === false && currentLastPortionLength > newLastPortionLength) {
          newLastPortion.points = [newLastPortion.points[0], [newLastPortionX, newLastPortionY]];
        }

        const newCoordinates = [...shape.geometry.coordinates];
        newCoordinates[0] = newFirstPortion.points[0];
        newCoordinates[1] = newLastPortion.points[1];

        const newPortions = [...shape.properties.portions];
        newPortions.splice(0, 1, newFirstPortion);
        newPortions.splice(portionArrLength - 1, 1, newLastPortion);

        newSegmentsEntities[shape.id] = {
          ...shape,
          geometry: {
            ...shape.geometry,
            coordinates: newCoordinates,
          },
          properties: {
            ...shape.properties,
            portions: newPortions,
          },
        } as LoadedSegment;
      });

      const newState = {
        ...state,
        segments: {
          ...state.segments,
          entities: newSegmentsEntities,
        },
        measurers: {
          ...state.measurers,
          entities: newMeasurersEntities,
        },
        points: {
          ...state.points,
          entities: newPointsEntities,
        },
      };

      return newState;
    }
  }

  return state;
}
