import type { RobotModelData } from 'components/utils/robot-model-data';

/**
 * This function computes the minimum radius to perform a proper turn given a robot
 *
 * @param modelData data related to the robot model
 *
 * @return Rleft minimum radius to the left direction
 * @return Rright minimum radius to the right direction
 * for most use, just take max(RLeft, RMin) as the minimum acceptable radius
 */
export function computeTurnMinRadius(modelData: RobotModelData): [number, number] {
  const L = modelData.turretXPositionForks;
  const alphaMax = modelData.maxTurretAngle;
  const alphaMin = modelData.minTurretAngle;
  const dY = modelData.turretYPosition;

  const RLeft = L * Math.tan(((90 - alphaMax) * Math.PI) / 180) + dY;
  const RRight = L * Math.tan(((90 + alphaMin) * Math.PI) / 180) - dY;

  return [RLeft, RRight];
}
