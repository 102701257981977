import { isRecentProjectTypes, type RecentProjectType } from 'editor/model';

/**
 * This function retrieves the recent projects from the local storage.
 *
 * @returns {RecentProjectType[]} An array of recent projects.
 */
export function getRecentProjects(): RecentProjectType[] {
  const recentProjectsStr = localStorage.getItem('recentProjects');
  if (!recentProjectsStr) return [];

  const recentProjects = JSON.parse(recentProjectsStr) as unknown;
  if (!Array.isArray(recentProjects)) {
    // eslint-disable-next-line no-console
    console.warn('recentProjects is not an array, removing the recent projects');
    localStorage.removeItem('recentProjects');

    return [];
  }

  if (!isRecentProjectTypes(recentProjects)) {
    // eslint-disable-next-line no-console
    console.warn('recentProjects is not an array of RecentProjectType, removing the recent projects');
    localStorage.removeItem('recentProjects');

    return [];
  }

  return recentProjects;
}
