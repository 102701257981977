import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { openDialogAction } from 'actions';
import { DialogTypes } from 'models';
import type { LoadedSegment } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { zoomToShape } from 'utils/circuit/zoom-to-shape';

export function snackbarLockedSegmentExtended(segments: LoadedSegment[]): void {
  if (segments.length === 0) return;

  if (segments.length < 2) {
    const segment = segments[0];

    const handleZoomToShape = (shapeId: string): void => {
      zoomToShape(shapeId);
    };

    SnackbarUtils.info(
      <Box component="span">
        {`To allow the creation of turns, a locked segment has been extended`}
        <Button
          color="primary"
          variant="contained"
          sx={{ marginLeft: 2 }}
          onClick={() => handleZoomToShape(segment.id as string)}
        >
          Show
        </Button>
      </Box>
    );
  } else {
    const selectAllFilteredShapes = (): void => {
      store.dispatch(
        openDialogAction({
          type: DialogTypes.SearchShapeResultSelection,
          payload: {
            shapes: segments,
          },
        })
      );
    };

    SnackbarUtils.info(
      <Box component="span">
        {`To allow the creation of turns, ${segments.length} locked segments have been extended`}
        <Button color="primary" variant="contained" sx={{ marginLeft: 2 }} onClick={selectAllFilteredShapes}>
          Show
        </Button>
      </Box>
    );
  }
}
