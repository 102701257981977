import { EnergySavingsLeaf } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Tooltip } from '@mui/material';
import { usePowerSavingMode } from 'utils/detect-power-saving';

interface PowerSavingIconProps {
  sx?: SxProps<Theme> | undefined;
}

export function PowerSavingIcon(props: PowerSavingIconProps): JSX.Element | null {
  const { sx } = props;

  const [powerSavingMode] = usePowerSavingMode();

  if (!powerSavingMode) {
    return null;
  }

  return (
    <Tooltip title="Power saving mode is enabled, simulation is running at a lower speed" sx={sx}>
      <EnergySavingsLeaf />
    </Tooltip>
  );
}
