import type { CircuitShape } from 'models/circuit';
import store from 'store';
import type { SimplifiedLayerInterface } from 'utils/export/generate-xml.worker';
import { getLayerGroupsParents } from 'utils/export/generate-xml.worker';
import { PreferencesService } from 'utils/preferences';
import { getRobotLayerGroupName } from './get-robot-layer-group';

/**
 * Check if a circuit shape (segment, turn, etc.) is available to a robot (= in the proper layer group)
 * @param shape the circuit shape
 * @param robotSerial the serial of the robot
 * @returns whether the shape is available to the robot
 */
export function isShapeAvailableToRobot(shape: CircuitShape, robotSerial: string): boolean {
  const layersState = store.getState().circuit.present.layers;
  const layers = layersState.layers;
  const layersGroups = layersState.layerGroups;
  const layersGroupsArr: SimplifiedLayerInterface[] = Object.values(layersGroups).map((layerGroup) => {
    return {
      id: parseInt(layerGroup.id, 10),
      modelName: layerGroup.name,
    };
  });

  const layerGroupsWithThisShape = getLayerGroupsParents(layersGroupsArr, layers, shape, layersGroups);
  const layerGroupsNamesWithThisShape = layerGroupsWithThisShape.map((layerGroup) => layerGroup.modelName);

  const robots = PreferencesService.getTrucks();
  const robot = robots.find((robot) => robot.serial === robotSerial);

  if (!robot) {
    // eslint-disable-next-line no-console
    console.warn(`Robot with serial ${robotSerial} not found`);

    return false;
  }

  const robotLayerGroupName = getRobotLayerGroupName(robot);

  return layerGroupsNamesWithThisShape.includes(robotLayerGroupName);
}
