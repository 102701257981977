import { Checkbox, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    formCheckBox: {
      display: 'flex',
      cursor: 'pointer',
      textAlign: 'left',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
    },
    checkBoxText: {
      width: 100,
    },
    disabled: {
      color: theme.palette.grey[300],
    },
  })
);

type CheckBoxInputProps = {
  label: string;
  isChecked: boolean;
  disabled: boolean;
};

export function CheckBoxInput({ label, isChecked, disabled = false }: CheckBoxInputProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" className={`${classes.checkBoxText}${disabled ? ` ${classes.disabled}` : ''}`}>
        {label}
      </Typography>
      <Checkbox checked={isChecked} disabled={disabled} />
    </>
  );
}
