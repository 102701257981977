import { saveStockZoneAction } from 'actions/stock-zones';
import type { CircuitStockZone, PalletPosition } from 'models/circuit';
import { CircuitService } from 'services/circuit.service';
import store from 'store';
import { computeAgainAllPortions } from 'utils/circuit';
import { getAllIdsFromShapes } from './get-all-ids-from-shapes';
import { getNextFreeId } from './next-free-id';
import { getAllRackPositions, getRackPosition } from './racks';

/**
 * Test wether there are duplicate ids in the circuit
 * @returns true if there are duplicate ids
 */
export function testDuplicateIds(): boolean {
  const shapes = CircuitService.getShapes();
  const ids = getAllIdsFromShapes(shapes);

  const layers = store.getState().circuit.present.layers.layers;
  const layerGroups = store.getState().circuit.present.layers.layerGroups;
  const layersAndLayerGroupsIds = Object.keys(layers).concat(Object.keys(layerGroups));

  ids.push(...layersAndLayerGroupsIds);

  const uniqueIds = new Set(ids);

  return uniqueIds.size !== ids.length;
}

/**
 * Fix duplicate ids in the circuit
 * @returns void
 */
export async function fixDuplicateIds(recomputePortions = true): Promise<void> {
  if (!testDuplicateIds()) {
    // eslint-disable-next-line no-console
    console.log('No duplicate ids found');

    return;
  }

  const shapes = CircuitService.getShapes();
  const slots = getAllRackPositions();

  const ids = getAllIdsFromShapes(shapes);

  const uniqueIds = new Set(ids);

  const numberIds = Array.from(uniqueIds).map((id) => {
    return {
      id,
      count: ids.filter((id2) => id2 === id).length,
    };
  });
  const duplicatedIds = numberIds.filter((id) => id.count > 1);
  const maxNbDuplicates = Math.max(...duplicatedIds.map((id) => id.count));

  // eslint-disable-next-line no-console
  console.log(`Found ${duplicatedIds.length} shapes with duplicated ids`, {
    duplicatedIds,
  });

  if (recomputePortions) {
    // eslint-disable-next-line no-console
    console.log('Recomputing all portions');
    await computeAgainAllPortions();

    await fixDuplicateIds(false);

    return;
  }

  duplicatedIds.forEach((duplicatedId) => {
    const id = duplicatedId.id;
    if (typeof id !== 'string') {
      // eslint-disable-next-line no-console
      console.error('id is not a string', id);

      return;
    }

    const shape = CircuitService.getShape(id);
    const slotWithRackId = slots.find((slot) => slot.id === id);
    if (!shape && !slotWithRackId) {
      // eslint-disable-next-line no-console
      console.error('shape or slot not found', id);

      return;
    }

    const newId = getNextFreeId().toString();
    if (shape) {
      shape.id = newId;

      // eslint-disable-next-line no-console
      console.log(`Shape ${shape.properties.name} with id ${id} has a new id:${newId}`);
    } else if (slotWithRackId) {
      const slot = getRackPosition(slotWithRackId.id);
      if (!slot) {
        // eslint-disable-next-line no-console
        console.error('slot not found', id);

        return;
      }

      slot.id = newId;

      // eslint-disable-next-line no-console
      console.log(`Slot ${slot.value} with id ${id} has a new id:${newId}`);
    }
  });

  if (maxNbDuplicates > 2) {
    // eslint-disable-next-line no-console
    console.log(`Found ${maxNbDuplicates} duplicates for one shape, reitering the process`);
    setTimeout(fixDuplicateIds, 0);
  }
}

export async function regenerateStockLinesName(): Promise<void> {
  const state = store.getState();
  const stockZones = state.circuit.present.stockZones.entities;
  const stockZonesIds = state.circuit.present.stockZones.ids;

  const waitEverySteps = 5;

  for (let i = 0; i < stockZonesIds.length; i++) {
    const stockZoneId = stockZonesIds[i];
    const stockZone = stockZones[stockZoneId];

    if (stockZone) {
      const props = stockZone.properties;
      const coords = stockZone.geometry.coordinates;
      const palletPosition: PalletPosition = {
        referenceX: props.referencePosX,
        offsetX: props.referenceOffsetX,
        referenceY: props.referencePosY,
        offsetY: props.referenceOffsetY,
      };
      const slotSample = props.slots[0].slots[0];

      let slots = CircuitService.generateStockZoneSlots(
        coords[0],
        props.slotSize,
        props.gap,
        props.length,
        props.width,
        props.palletTypes,
        palletPosition,
        props.palletSize,
        slotSample.tolerancePosition,
        props.cap,
        undefined,
        props.customGapSlots,
        props.customGapLines,
        props.name,
        {
          lineName: true,
        }
      );

      slots = CircuitService.computeSlotsGeometry(slots, props.cap);

      const newStockZone: CircuitStockZone = {
        ...stockZone,
        properties: {
          ...props,
          slots,
        },
      };

      store.dispatch(saveStockZoneAction(newStockZone));

      if (i % waitEverySteps === 0) {
        // eslint-disable-next-line no-console
        console.log(
          `regenerateStockLinesName: ${i}/${stockZonesIds.length} stock zones processed (${Math.round(
            (i / stockZonesIds.length) * 100
          )}%)`
        );

        await new Promise((resolve) => setTimeout(resolve, 0));
      }
    }
  }

  // eslint-disable-next-line no-console
  console.log(`regenerateStockLinesName: ${stockZonesIds.length}/${stockZonesIds.length} stock zones processed (100%)`);
}

window.regenerateStockLinesName = regenerateStockLinesName;
