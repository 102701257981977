import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { centerOfMass } from '@turf/turf';
import { saveRackAction } from 'actions/racks';
import { getSvgFromIcon } from 'components/utils/generate-jsx';
import * as d3 from 'd3';
import { findShapeOrientation } from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import type { Polygon } from 'geojson';
import type { RackProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { startTransition } from 'react';
import store from 'store';
import { computePalletOverflowGeometry, computeUprightsGeometry } from 'utils/circuit/racks';
import { theme } from 'utils/mui-theme';
import { Rectangle } from '../elements';

const errorIcon = getSvgFromIcon(ErrorIcon).querySelector('path');
const warningIcon = getSvgFromIcon(WarningIcon).querySelector('path');

interface RackDrawingProperties {
  displayPalletOverflow?: boolean;
}

export class Rack extends Rectangle {
  private translating = false;

  private dx = 0;
  private dy = 0;

  constructor(
    id: string,
    geometry: Polygon,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    public properties: RackProperties,
    public rackDrawingProperties: RackDrawingProperties
  ) {
    super(id, ShapeTypes.RackShape, geometry, projection, zoomScale);

    this.setLocked(!!this.properties?.locked);

    this.node.attr('layer-id', properties?.layerId || '');

    this.node = this.showUprights();

    if (rackDrawingProperties.displayPalletOverflow) {
      this.node = this.showPalletOverflow();
    }

    if (this.properties.anomaly && this.properties.anomaly.state) {
      this.node = this.displayErrorStatus(this.properties.anomaly.state);
    }
  }

  public displayErrorStatus(state: 1 | 2): SimpleSelection<SVGGElement, undefined> {
    if (!errorIcon || !warningIcon) {
      // eslint-disable-next-line no-console
      console.error('Error loading the error or warning icons');

      return this.node;
    }

    const node = this.node;

    const center = centerOfMass({ id: this.id, geometry: this.geometry, properties: this.properties, type: 'Feature' });
    const centerCoords = center.geometry.coordinates;
    const iconSize = 24; // px
    const scaleIcon = 4;
    const iconSizeScaled = iconSize * scaleIcon;
    const bgSize = iconSize / (state === 2 ? 2 : 3);

    const d = (state === 2 ? errorIcon : warningIcon).getAttribute('d');

    if (d) {
      // we add a white background
      node
        .append('svg:rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', bgSize)
        .attr('height', bgSize)
        .classed('error-shape-bg', true)
        .attr('stroke', 'none')
        .attr('fill', theme.palette.common.white)
        .attr('fill-opacity', 1)
        .attr('fill-rule', 'nonzero')
        .attr(
          'transform',
          `translate(${centerCoords[0] - bgSize * 2}, ${
            -centerCoords[1] - bgSize * (state === 2 ? 2 : 1)
          }) scale(${scaleIcon})`
        );

      // as well as the error/warning icon
      node
        .append('svg:path')
        .classed('error-shape-icon', true)
        .attr('d', d)
        .attr('stroke', 'none')
        .attr('fill', state === 2 ? theme.palette.error.main : theme.palette.warning.main)
        .attr('stroke-width', 1)
        .attr('fill-opacity', 1)
        .attr(
          'transform',
          `translate(${centerCoords[0] - iconSizeScaled / 2}, ${
            -centerCoords[1] - iconSizeScaled / 2
          }) scale(${scaleIcon})`
        );
    }

    return this.node;
  }

  public showUprights(): SimpleSelection<SVGGElement, undefined> {
    const node = this.node;

    node.selectAll('line.upright').remove();

    const uprightsGeometry = computeUprightsGeometry({
      id: this.id,
      geometry: this.geometry,
      type: 'Feature',
      properties: this.properties,
    });
    uprightsGeometry.forEach((uprightGeometry, index) => {
      if (!uprightGeometry.enabled) return;

      const coords = uprightGeometry.coords;
      const x1 = coords[0][0];
      const y1 = coords[0][1];
      const x2 = coords[1][0];
      const y2 = coords[1][1];
      const width = uprightGeometry.width;

      node
        .append('svg:line')
        .attr('x1', x1)
        .attr('y1', -y1)
        .attr('x2', x2)
        .attr('y2', -y2)
        .style('stroke-width', `${width * 100}`)
        .classed('upright', true)
        .classed('left-upright', index === 0)
        .classed('right-upright', index === uprightsGeometry.length - 1)
        .classed('middle-upright', index !== 0 && index !== uprightsGeometry.length - 1);
    });

    return node;
  }

  public showPalletOverflow(): SimpleSelection<SVGGElement, undefined> {
    const node = this.node;

    node.selectAll('line.pallet-overflow').remove();

    const cellTemplates = store.getState().circuit.present.cellTemplates.entities;

    const palletOverflowsGeometry = computePalletOverflowGeometry(
      { id: this.id, geometry: this.geometry, type: 'Feature', properties: this.properties },
      cellTemplates
    );

    palletOverflowsGeometry.forEach((palletOverflowGeometry, index) => {
      const coords = palletOverflowGeometry.coords;

      const x1 = coords[0][0];
      const y1 = coords[0][1];
      const x2 = coords[1][0];
      const y2 = coords[1][1];

      node
        .append('svg:line')
        .attr('x1', x1)
        .attr('y1', -y1)
        .attr('x2', x2)
        .attr('y2', -y2)
        .style('stroke-width', `2`)
        .classed('pallet-overflow', true)
        .classed('bottom-overflow', index === palletOverflowsGeometry.length - 1);
    });

    return node;
  }

  protected onDragEnd(properties?: RackProperties): void {
    if (!this.draggeable()) return;

    super.onDragEnd(properties);

    if (this.dx || this.dy) {
      startTransition(() => {
        store.dispatch(
          saveRackAction({
            id: this.id,
            geometry: this.geometry,
          })
        );
      });
    }

    this.dx = 0;
    this.dy = 0;
  }

  protected translateEnd(): void {
    if (!this.draggeable()) return;

    startTransition(() => {
      store.dispatch(
        saveRackAction({
          id: this.id,
          geometry: this.geometry,
        })
      );
    });

    d3.selectAll(`[TYPE="SEGMENT"][rack-id="${this.id}"]`).style('opacity', 'unset');
  }

  protected onDrag(): void {
    if (!this.draggeable()) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    this.dx += event.dx;
    this.dy += event.dy;

    if (!this.translating) {
      this.translating = true;
      this.hideNotMainShape();
    }

    this.showUprights();

    if (this.rackDrawingProperties.displayPalletOverflow) {
      this.showPalletOverflow();
    }

    super.onDrag();
  }

  protected translate(): void {
    if (!this.draggeable()) return;

    if (!this.translating) {
      this.translating = true;
      this.hideNotMainShape();
    }

    super.translate();
  }

  private hideNotMainShape(): void {
    this.node.selectAll('path:not([main-shape]), line, rect').remove();

    d3.selectAll(`[TYPE="SEGMENT"][rack-id="${this.id}"]`).style('opacity', 0.1);
  }

  protected onHandleDragStart(): void {
    this.hideNotMainShape();

    super.onHandleDragStart(true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected onHandleDragEnd(properties?: any): void {
    const coords = this.geometry.coordinates[0];
    const p1 = coords[0];
    const p2 = coords[1];
    const cap = findShapeOrientation(p1, p2);

    startTransition(() => {
      store.dispatch(
        saveRackAction({
          id: this.id,
          geometry: {
            ...this.geometry,
            coordinates: [...this.geometry.coordinates],
          },
          properties: {
            ...this.properties,
            cap,
          },
        })
      );
    });

    super.onHandleDragEnd(properties);
    d3.selectAll(`[TYPE="SEGMENT"][rack-id="${this.id}"]`).style('opacity', 'unset');
  }
}
