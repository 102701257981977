import { centerOfMass, bbox as turfBbox } from '@turf/turf';
import { clearShapesSelectionAction, selectCircuitShapeAction } from 'actions/circuit';
import type { BBox } from 'geojson';
import { CircuitService } from 'services/circuit.service';
import store from 'store';

export function zoomToShape(shapeId: string, skipAnim = false): void {
  const shape = CircuitService.getShape(shapeId);
  const drawing = CircuitService.getDrawingReference();

  // it takes 0.5 of the playground
  const zoomFactor = 0.5; // defined arbitrarily

  if (shape && drawing) {
    let bbox: BBox;
    try {
      bbox = turfBbox(shape.geometry);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Computation of the BBox of the shape '${shapeId} failed'`, shape, e);

      return;
    }

    const center = centerOfMass(shape.geometry);
    const centerCoords = center.geometry.coordinates;

    const svgEl = drawing.getRootNode().node();
    if (!svgEl) return;

    const w = svgEl.clientWidth;
    const h = svgEl.clientHeight;
    const bboxWidth = bbox[2] - bbox[0];
    const bboxHeight = bbox[3] - bbox[1];

    // we compute the scale to fit the shape in the playground
    let scale = w && h ? Math.min(Math.abs(w / bboxWidth), Math.abs(h / bboxHeight)) * zoomFactor : 1;

    // for points, we define a zoom to an arbitrarily value given that the bbox has a width and height of 0
    if (typeof shape.geometry.coordinates[0] === 'number') {
      scale = 2;
    }

    drawing.zoomTo(centerCoords[0], centerCoords[1], scale, skipAnim, true);

    const selectedShapesData = store.getState().local.selectedShapesData;
    const shapeAlreadySelectedAndOnlyThisOne = selectedShapesData.length === 1 && selectedShapesData[0].id === shape.id;
    if (!shapeAlreadySelectedAndOnlyThisOne) {
      // this is noop if the shape is already selected
      if (selectedShapesData.length) {
        // useless to clear the selection if the selection is empty
        store.dispatch(clearShapesSelectionAction());
      }

      setTimeout(() => {
        store.dispatch(
          selectCircuitShapeAction({
            selectedShapeId: shape.id as string,
            selectedShapeType: shape.properties.type,
            shape,
          })
        );
      }, 0);
    }
  }
}
