import type { GeoJsonCircuit } from 'models/circuit';
import packageJson from '../../../package.json';
import { upgrade1_10_2, upgrade1_11_0 } from './upgrade-1.10';
import { fixCellTemplatesIds } from './upgrade-2.7';
import { upgrade1_12_0, upgrade1_12_1 } from './uprade-1.12';

interface CircuitUpgradeChange {
  /** if the version of the circuit is lower than this version, the upgraderFn has to be called */
  to: string;
  /** the function that converts the circuit to the new version */
  upgraderFn: (circuit: GeoJsonCircuit) => GeoJsonCircuit;
}

/**
 * List of the upgrades needed, to be sorted by the version of the circuit
 */
const upgradesNeeded: CircuitUpgradeChange[] = [
  { to: '1.10.2', upgraderFn: upgrade1_10_2 },
  { to: '1.11.0', upgraderFn: upgrade1_11_0 },
  { to: '1.12.0', upgraderFn: upgrade1_12_0 },
  { to: '1.12.1', upgraderFn: upgrade1_12_1 },
  { to: '2.7.0', upgraderFn: fixCellTemplatesIds },
];

/**
 * A function to check if we need to upgrade a circuit
 * @param circuit the circuit to upgrade
 * @returns if we need to upgrade it or not
 */
export function checkNeedUpgradeCircuit(circuit: GeoJsonCircuit): boolean {
  const currentVersion = packageJson.version;
  const circuitVersion = circuit.properties.roadEditorVersion;

  if (currentVersion === circuitVersion) return false;

  const cirVersion = circuitVersion.split('.').map((v) => parseInt(v, 10));

  for (const upgrade of upgradesNeeded) {
    const toVersion = upgrade.to.split('.').map((v) => parseInt(v, 10));

    /**
     * It works with versions formatted like that: x.y.z where x, y and z are numbers
     * If x is lower than the current version, the circuit is not up to date
     * If it is equals, we check the y number
     * And then the z version
     */
    if (cirVersion[0] < toVersion[0]) return true;
    if (cirVersion[0] === toVersion[0] && cirVersion[1] < toVersion[1]) return true;
    if (cirVersion[0] === toVersion[0] && cirVersion[1] === toVersion[1] && cirVersion[2] < toVersion[2]) return true;
  }

  return false;
}

/**
 * A function to upgrade a circuit to the latest version
 * @param circuit the circuit to upgrade
 * @returns the upgraded circuit
 */
export function upgradeCircuit(circuit: GeoJsonCircuit): GeoJsonCircuit {
  const currentVersion = packageJson.version;
  const circuitVersion = circuit.properties.roadEditorVersion;

  let newCircuit = circuit;

  if (currentVersion === circuitVersion) return circuit;

  const cirVersion = circuitVersion.split('.').map((v) => parseInt(v, 10));

  // we go through the list of upgrades and apply the upgrader function if needed
  for (const upgrade of upgradesNeeded) {
    const toVersion = upgrade.to.split('.').map((v) => parseInt(v, 10));

    if (
      cirVersion[0] < toVersion[0] ||
      (cirVersion[0] === toVersion[0] && cirVersion[1] < toVersion[1]) ||
      (cirVersion[0] === toVersion[0] && cirVersion[1] === toVersion[1] && cirVersion[2] < toVersion[2])
    )
      newCircuit = upgrade.upgraderFn(newCircuit);
  }

  return newCircuit;
}
