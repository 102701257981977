import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Stack } from '@mui/system';
import { saveZoneAction } from 'actions/zones';
import { HelpIconTooltip, WarningIconTooltip } from 'components/utils/tooltips';
import type { ZoneEvent } from 'components/utils/use-available-zone-events';
import { useAvailableZoneEvents } from 'components/utils/use-available-zone-events';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import type { CircuitZone } from 'models/circuit';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';

type EventType = 'enter' | 'exit';

interface SelectZoneEventsProps {
  zone: CircuitZone;
}
export function SelectZoneEvents(props: SelectZoneEventsProps): JSX.Element {
  const { zone } = props;
  const dispatch = useAppDispatch();

  const availableZoneEvents = useAvailableZoneEvents();

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>, type: EventType) => {
      const eventName = event.target.value || undefined;
      const newZone = structuredClone(zone);

      if (type === 'enter') {
        newZone.properties.enterEventName = eventName;
      } else if (type === 'exit') {
        newZone.properties.exitEventName = eventName;
      }

      dispatch(saveZoneAction(newZone));
    },
    [dispatch, zone]
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      sx={{
        textAlign: 'left',
      }}
    >
      <SelectEvent handleChange={handleChange} zone={zone} availableZoneEvents={availableZoneEvents} type="enter" />
      <SelectEvent handleChange={handleChange} zone={zone} availableZoneEvents={availableZoneEvents} type="exit" />
    </Stack>
  );
}

interface SelectEventProps {
  handleChange: (event: SelectChangeEvent<string>, type: EventType) => void;
  zone: CircuitZone;
  availableZoneEvents: ZoneEvent[];
  type: EventType;
}
function SelectEvent(props: SelectEventProps): JSX.Element {
  const { handleChange, zone, availableZoneEvents, type } = props;

  /**
   * Minimum version to define an enter or exit event on a zone.
   * @link
   */
  const minimumSDKVersionEnterExitEvents = '4.14.0';
  const isSdkVersionOk = useSdkVersionOk(minimumSDKVersionEnterExitEvents);

  const value = type === 'enter' ? zone.properties.enterEventName : zone.properties.exitEventName;
  const tooltip = `Event triggered on the robot when it ${type === 'enter' ? 'enters' : 'exits'} the zone.`;
  const label = (
    <>
      {`${type === 'enter' ? 'Enter' : 'Exit'} Event`} <HelpIconTooltip title={tooltip} />
      {!isSdkVersionOk && (
        <WarningIconTooltip title={`Requires SDK version ${minimumSDKVersionEnterExitEvents} or higher`} />
      )}
    </>
  );

  return (
    <FormControl
      sx={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      }}
      size="small"
      fullWidth
    >
      <InputLabel id={`${type}-event-name-label`} shrink>
        {label}
      </InputLabel>
      <Select
        labelId={`${type}-event-name-label`}
        id={`${type}-event-name`}
        value={value || ''}
        onChange={(event) => handleChange(event, type)}
        label={label}
        notched
      >
        <MenuItem value="">None</MenuItem>
        {availableZoneEvents.map((zoneEvent) => (
          <MenuItem key={zoneEvent.name} value={zoneEvent.name}>
            {zoneEvent.name}
          </MenuItem>
        ))}

        {availableZoneEvents.length === 0 && (
          <MenuItem value="" disabled>
            No events available
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
