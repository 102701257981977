import type { Login, Logout } from 'actions';
import { loginAction, logoutAction } from 'actions';
import type { UserProfile } from 'models';
import type { Dispatch } from 'react';
import { connect } from 'react-redux';
import { selectUserLoggedIn, selectUserProfile } from 'reducers/auth';
import { selectAppOnline } from 'reducers/core';
import type { AppState } from 'reducers/state';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MenuBar as MenuBarComponent } from './menu-bar';

interface StateProps {
  appOnline: boolean;
  loggedIn: boolean;
  profile?: UserProfile;
  projectName?: string;
}

interface DispatchProps {
  login: () => void;
  logout: () => void;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    appOnline: selectAppOnline(state),
    loggedIn: selectUserLoggedIn(state),
    profile: selectUserProfile(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Login | Logout>): DispatchProps {
  return {
    login: () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const returnUrl = urlParams.get('originalUrl') || undefined;

      return dispatch(
        loginAction({
          returnUrl,
        })
      );
    },
    logout: () => dispatch(logoutAction()),
  };
}

export const MenuBar = connect(mapStateToProps, mapDispatchToProps)(MenuBarComponent);
