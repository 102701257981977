import type { union } from '@turf/turf';
import * as Comlink from 'comlink';
import type {
  generateGabaritPolygon,
  generatePointGabarit,
  generateSegmentGabarit,
  generateTurnGabarit,
  mergeAllGabarits,
} from 'utils/circuit/gabarits';

const worker = new Worker(new URL('../utils/circuit/gabarits.ts', import.meta.url), {
  name: 'gabarit-worker',
});

export const gabaritsWorkerProxy = Comlink.wrap<{
  generateGabaritPolygon: typeof generateGabaritPolygon;
  generateSegmentGabarit: typeof generateSegmentGabarit;
  generateTurnGabarit: typeof generateTurnGabarit;
  generatePointGabarit: typeof generatePointGabarit;
  union: typeof union;
  mergeAllGabarits: typeof mergeAllGabarits;
}>(worker);
