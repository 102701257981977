import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';

export const localStorageKeyCookieConsent = 'cookie-consent';

export function useConsentCookie(): [boolean | undefined, (value: boolean) => void] {
  const [consent, setConsent] = useState<boolean | undefined>(undefined);

  const handleChangeConsent = useCallback((value: boolean) => {
    localStorage.setItem(localStorageKeyCookieConsent, value.toString());
    setConsent(value);
  }, []);

  const showConsentSnackbar = useCallback(() => {
    const snackbarKey = SnackbarUtils.toast(
      'Allow cookies to improve your experience',
      {
        persist: true,
        action: (
          <>
            <Button
              color="inherit"
              onClick={() => {
                handleChangeConsent(false);
                SnackbarUtils.closeSnackbar(snackbarKey);
              }}
            >
              Deny
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleChangeConsent(true);
                SnackbarUtils.closeSnackbar(snackbarKey);
              }}
            >
              Allow
            </Button>
          </>
        ),
      },
      false
    );
  }, [handleChangeConsent]);

  const handleUpdateConsent = useCallback(() => {
    const consentValue = localStorage.getItem(localStorageKeyCookieConsent);
    if (consentValue !== null && consentValue !== undefined) {
      setConsent(consentValue === 'true');

      return;
    }

    showConsentSnackbar();
  }, [showConsentSnackbar]);

  useEffect(() => {
    handleUpdateConsent();
  }, [handleUpdateConsent]);

  useEffect(() => {
    if (consent === undefined) return;

    const consentKeyword = consent ? 'granted' : 'denied';

    if (!window.gtag) {
      // eslint-disable-next-line no-console
      console.log(`gtag not defined`);

      return;
    }

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'consent_update',
      ad_storage: consentKeyword,
      ad_user_data: consentKeyword,
      ad_personalization: consentKeyword,
      analytics_storage: consentKeyword,
    });
  }, [consent]);

  return [consent, handleChangeConsent];
}
