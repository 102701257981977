import type { CircuitDevice } from 'models/circuit';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: DeviceActionTypes,
  creators: {
    create: createDeviceAction,
    createFailure: createDeviceFailureAction,
    createSuccess: createDeviceSuccessAction,
    delete: deleteDeviceAction,
    deleteFailure: deleteDeviceFailureAction,
    deleteSuccess: deleteDeviceSuccessAction,
    loadOne: loadDeviceAction,
    loadOneFailure: loadDeviceFailureAction,
    loadOneSuccess: loadDeviceSuccessAction,
    saveFailure: saveDeviceFailureAction,
    saveSuccess: saveDeviceSuccessAction,
    select: selectDeviceAction,
  },
} = createEntityActions<CircuitDevice>('Devices');

const {
  creators: { save },
} = createEntityActions<CircuitDevice>('Devices');

export function saveDeviceAction(payload: Partial<CircuitDevice> & RoadEditorAction['payload']): SaveDevice {
  const action = save(payload);

  return action;
}

export interface CreateDevice extends CreateAction<CircuitDevice> {}
export interface CreateDeviceFailure extends CreateFailureAction {}
export interface CreateDeviceSuccess extends CreateSuccessAction<CircuitDevice> {}
export interface DeleteDevice extends DeleteAction {}
export interface DeleteDeviceFailure extends DeleteFailureAction {}
export interface DeleteDeviceSuccess extends DeleteSuccessAction {}
export interface LoadDevice extends LoadOneAction {}
export interface LoadDeviceFailure extends LoadOneFailureAction {}
export interface LoadDeviceSuccess extends LoadOneSuccessAction<CircuitDevice> {}
export interface SaveDevice extends SaveAction<CircuitDevice> {
  payload: SaveAction<CircuitDevice>['payload'] & RoadEditorAction['payload'];
}
export interface SaveDeviceFailure extends SaveFailureAction {}
export interface SaveDeviceSuccess extends SaveSuccessAction<CircuitDevice> {}
export interface SelectDevice extends SelectAction {}

export type DeviceActions =
  | CreateDevice
  | CreateDeviceFailure
  | CreateDeviceSuccess
  | DeleteDevice
  | DeleteDeviceFailure
  | DeleteDeviceSuccess
  | LoadDevice
  | LoadDeviceFailure
  | LoadDeviceSuccess
  | SaveDevice
  | SaveDeviceFailure
  | SaveDeviceSuccess
  | SelectDevice;
