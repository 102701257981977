import LogoutIcon from '@mui/icons-material/Logout';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { openDialogAction } from 'actions';
import { isDevOrStaging } from 'index';
import { DialogTypes } from 'models';
import { useCallback, useEffect } from 'react';
import { AuthService } from 'services/auth.service';
import { useAppDispatch, useAppSelector } from 'store';
import { Container } from 'typescript-ioc';
import { theme } from 'utils/mui-theme';

const PARTNER_PORTAL_CATALOG_URL = 'https://partner-portal.balyo.io/catalog';
const PARTNER_PORTAL_CATALOG_URL_STAGING = 'http://staging.partner-portal.balyo.test/catalog';

export function AccessRequiredDialog(): JSX.Element {
  const authService = Container.get(AuthService);
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const profile = auth.profile;

  const firstName = profile && 'name' in profile ? profile.name.split(' ')[0] : '';

  const handleLogout = useCallback(() => {
    authService.logout();
  }, [authService]);

  const isOnline = useAppSelector((state) => state.core.appOnline);

  const dialogToOpen = !isOnline
    ? DialogTypes.Welcome
    : authService.authorized
      ? DialogTypes.Welcome
      : DialogTypes.AccessRequired;
  useEffect(() => {
    if (dialogToOpen === DialogTypes.Welcome) {
      dispatch(openDialogAction({ type: dialogToOpen, payload: undefined }));
    }
  }, [dialogToOpen, dispatch]);

  // reload the page times to times if the access is required
  // prevent people from hiding the dialog
  useEffect(() => {
    const intervalID = setInterval(() => {
      document.location.reload();
    }, 60 * 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <Dialog open={true}>
      <DialogTitle>
        Access Required{' '}
        <Tooltip title="Logout">
          <IconButton onClick={handleLogout} color="secondary" sx={{ float: 'right' }}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        Hello{firstName ? ` ${firstName}` : ''}, it looks like you don't have access to Road Editor.
        <br />
        <Button
          href={!isDevOrStaging() ? PARTNER_PORTAL_CATALOG_URL : PARTNER_PORTAL_CATALOG_URL_STAGING}
          variant="contained"
          color="primary"
          endIcon={<SwitchAccessShortcutIcon sx={{ transform: 'scaleX(-1)' }} />}
          sx={{ margin: theme.spacing(3) }}
        >
          Request my access through Balyo Partner Portal
        </Button>
      </DialogContent>
    </Dialog>
  );
}
