import store from 'store';
import { addRobotPath } from './robots';

/**
 * Import a robot path from a csv string
 *
 * @param input the text input of the csv to be parsed
 * @param debug {optional} debug mode for the function (more logs)
 *
 * Format of the input file (csv):
 * #AGV infos
 * #MODEL ROBID
 * L12HP_800w_1200 1
 * #AGV path
 * #X Y CAP
 * -28.7828 -58.5039 1.54699
 * -28.7834 -58.5044 1.54706
 * -28.7847 -58.5041 1.54715
 * ...
 * -28.7838 -58.5039 1.54707
 * #LASER Data
 * #TIMESTAMP LASER_ID LASER_MODEL NUM_DATA ANGLE_STEP ANGLE_START MIN_DISTANCE MAX_DISTANCE ROBOT_X ROBOT_Y ROBOT_CAP LASER_X LASER_Y LASER_CAP
 * very long line (like 10kB of characters or way more)
 */
export function importRobotPath(input: string, debug = false): void {
  // eslint-disable-next-line no-console
  if (debug) console.time('importRobotPath');

  const csv = input.split('\n');

  const firstLine = csv[0].trim();
  if (firstLine !== '#AGV infos') {
    // eslint-disable-next-line no-console
    console.warn('Expected "#AGV infos" as first line, got', firstLine);

    throw new Error('Invalid file format (missing #AGV infos)');
  }

  const secondLine = csv[1].trim();
  if (secondLine !== '#MODEL ROBID') {
    throw new Error('Invalid file format (missing #MODEL ROBID)');
  }

  const thirdLine = csv[2].trim();
  const [robotModel, robotId] = thirdLine.split(' ');
  if (!robotModel || !robotId) {
    throw new Error('Invalid file format (missing robot model or id)');
  }

  const fourthLine = csv[3].trim();
  if (fourthLine !== '#AGV path') {
    throw new Error('Invalid file format (missing #AGV path)');
  }

  const fifthLine = csv[4].trim();
  if (fifthLine !== '#X Y CAP') {
    throw new Error('Invalid file format (missing #X Y CAP)');
  }

  const length = csv.length - 5; // we want to ignore the last lines (laser data)
  const path = csv
    .map((line, i) => {
      if (i < 5) return null;
      if (i > length) return null;

      const [x, y, cap] = line.split(' ');
      if (!x || !y || !cap) {
        throw new Error(`Invalid file format (missing x, y, or cap line ${i})`);
      }

      // return [parseFloat(x), parseFloat(y), parseFloat(cap)];
      // given that for now we don't use the cap, we can just return [x, y]
      // and convert from meters to centimeters
      return [parseFloat(x) * 100, -parseFloat(y) * 100];
    })
    .filter(
      (line): line is number[] =>
        line !== null && line[0] !== null && line[1] !== null && !isNaN(line[0]) && !isNaN(line[1])
    )
    .filter((line): line is [number, number] | [number, number, number] => line.length === 2 || line.length === 3);

  store.dispatch(addRobotPath({ robotModel, robotId: parseInt(robotId, 10), path }));

  // eslint-disable-next-line no-console
  if (debug) console.timeEnd('importRobotPath');
}
