import { ChevronRight, Functions, Help } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Chip,
  Collapse,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { updateTurnAction } from 'actions/circuit';
import { saveTurnAction } from 'actions/turns';
import type { RobotModelData } from 'components/utils/robot-model-data';
import { findShapeOrientation, pDistance } from 'drawings/helpers';
import type { GabaritProperties, TrafficType } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import type { LoadedSegment, LoadedTurn } from 'reducers/circuit/state';
import type { RootState } from 'store';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { estimateRadiusOfTurn } from 'utils/circuit/estimate-radius-of-turn';
import { getGabarit } from 'utils/circuit/get-gabarit';
import { computeTurnMinRadius } from 'utils/compute-turn-min-radius';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { isDefined } from 'utils/ts/is-defined';
import { GabaritSelect } from '../components/gabarit-select';
import { TrafficTypeSelect } from '../components/traffic-type-select';
import { PropertiesComponent } from '../properties-component';

const MIN_RADIUS = 0.01;

const useStyles = makeStyles((theme) =>
  createStyles({
    fadedTooltip: {
      color: theme.palette.grey[400],
      verticalAlign: 'middle',
    },
  })
);

interface TurnPropertiesFormValue {
  radius: string;
  turnType: string;
  maxOvershoot?: string;
  gabarit: GabaritProperties;
  startPointOffset?: string;
}

type OriginOrDestination = 'origin' | 'destination';

export const TurnPropertiesComponent = ({ turnId }: { turnId: string }): JSX.Element => {
  const turn = useAppSelector(
    (state: RootState) => state.circuit.present.turns.entities[turnId] as LoadedTurn | undefined
  );
  const segments = useAppSelector((state) => state.circuit.present.segments.entities);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const prefsLoaded = PreferencesService.arePreferencesFullyLoaded();

  const modelsName = useMemo(() => PreferencesService.getModelNames(), []);
  const trucks = useMemo(() => PreferencesService.getTrucks(), []);

  const gabarit = useMemo(
    () => getGabarit(turn?.properties.gabarit, modelsName),
    [modelsName, turn?.properties.gabarit]
  );

  const segmentOrigin = turn && turn.properties.originId ? segments[turn.properties.originId] : undefined;
  const segmentDest = turn && turn.properties.destinationId ? segments[turn.properties.destinationId] : undefined;
  const isConnectedToExtendedLength =
    segmentOrigin?.properties.rackColumn ||
    segmentOrigin?.properties.stockLine ||
    segmentDest?.properties.rackColumn ||
    segmentDest?.properties.stockLine;

  const segmentOriginAngle = useMemo(() => {
    if (!segmentOrigin) return;

    const coords = segmentOrigin.geometry.coordinates;

    return findShapeOrientation(coords[0], coords[1]);
  }, [segmentOrigin]);
  const segmentDestAngle = useMemo(() => {
    if (!segmentDest) return;

    const coords = segmentDest.geometry.coordinates;

    return findShapeOrientation(coords[0], coords[1]);
  }, [segmentDest]);

  const [formValue, setFormValue] = useState<TurnPropertiesFormValue>({
    radius: turn?.properties.radius || '-1',
    turnType: turn?.properties.turnType || 'StopBeforeTurn',
    maxOvershoot: turn?.properties.maxOvershoot || getConfig('defaultValues').maxOvershoot.toString(),
    startPointOffset: isConnectedToExtendedLength ? turn?.properties.startPointOffset ?? '0' : undefined,
    gabarit: gabarit,
  } as TurnPropertiesFormValue);

  useEffect(() => {
    // this useffect is used when the generated turn fails and is switched to a stopbeforeturn automatically
    // we need to update the form value to reflect the change and thereby updates the gui
    if (turn && turn.properties.turnType === 'StopBeforeTurn' && formValue.turnType !== turn.properties.turnType) {
      setFormValue((state) => ({
        ...state,
        turnType: turn.properties.turnType,
      }));
    }
  }, [formValue.turnType, turn, turn?.properties.turnType]);

  if (!formValue.gabarit.modelName) formValue.gabarit.modelName = modelsName.length ? modelsName[0] : '';
  if (!formValue.gabarit.type) formValue.gabarit.type = 'general/shape';

  const robotsData = useMemo(
    () =>
      modelsName
        .map((modelName) => {
          const truckOfThisModel = trucks.find((truck) => truck.modelName === modelName);
          if (!truckOfThisModel) return {} as RobotModelData;

          try {
            const serialOfThisModel = truckOfThisModel.serial;
            const modelData: RobotModelData = {
              name: modelName,
              minTurretAngle: parseFloat(
                PreferencesService.getPreferenceValue(
                  'trajectoryDriver/robotKinematicsModel/minTurretAngle',
                  serialOfThisModel
                ) as string
              ),
              maxTurretAngle: parseFloat(
                PreferencesService.getPreferenceValue(
                  'trajectoryDriver/robotKinematicsModel/maxTurretAngle',
                  serialOfThisModel
                ) as string
              ),
              turretYPosition: parseFloat(
                PreferencesService.getPreferenceValue(
                  'trajectoryDriver/robotKinematicsModel/turretYPosition',
                  serialOfThisModel
                ) as string
              ),
              turretXPositionForks: Math.max(
                parseFloat(
                  PreferencesService.getPreferenceValue(
                    'trajectoryDriver/robotKinematicsModel/turretXPositionForksDown',
                    serialOfThisModel
                  ) as string
                ),
                parseFloat(
                  PreferencesService.getPreferenceValue(
                    'trajectoryDriver/robotKinematicsModel/turretXPositionForksUp',
                    serialOfThisModel
                  ) as string
                )
              ),
            };
            modelData.minTurnRadius = Math.max(...computeTurnMinRadius(modelData));

            return modelData;
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);

            return null;
          }
        })
        .filter(isDefined),
    [modelsName, trucks]
  );

  const [radiusTooSmallForTheseTrucks, setRadiusTooSmallForTheseTrucks] = useState([] as RobotModelData[]);

  const dispatchUpdateTurn = useDebouncedCallback((turnId: string, radius: number) => {
    startTransition(() => {
      dispatch(updateTurnAction({ idToUpdate: turnId, radius, userAction: true }));
    });
  }, 100);
  const handleRadiusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined): void => {
      const newValue = e?.target?.value;
      if (newValue === undefined || parseFloat(newValue) < 0) return;

      const newRadius = parseFloat(newValue);
      setFormValue((state) => ({ ...state, radius: newValue }));

      if (!newRadius) return;

      dispatchUpdateTurn(turnId, newRadius);
    },
    [dispatchUpdateTurn, turnId]
  );
  const handleRadiusBlur = useCallback(
    (e): void => {
      const radius = parseFloat(formValue.radius);
      if (isNaN(radius) || radius < MIN_RADIUS) setFormValue({ ...formValue, radius: '1' });
    },
    [formValue]
  );

  const handleChangeTurnType = useCallback(
    (e: SelectChangeEvent<string>): void => {
      const newTurnType = e.target.value as 'Normal' | 'StopBeforeTurn' | 'Tesseract';

      setFormValue((state) => ({ ...state, turnType: newTurnType }));

      startTransition(() =>
        dispatch(updateTurnAction({ idToUpdate: turnId, turnType: newTurnType, userAction: true }))
      );
    },
    [dispatch, turnId]
  );

  const handleMaxOvershootChange = useCallback(
    (e): void => {
      const val = e.target.value as string;
      if (val === undefined || parseFloat(val) < 0) return;

      const maxOvershoot = parseFloat(val);
      setFormValue((state) => ({ ...state, maxOvershoot: val }));

      if (!maxOvershoot) return;

      startTransition(() => dispatch(updateTurnAction({ idToUpdate: turnId, maxOvershoot, userAction: true })));
    },
    [dispatch, turnId]
  );

  const handleStartPointOffsetChange = useCallback(
    (e) => {
      const val = e.target.value as string;
      const startPointOffset = parseFloat(val ?? '0');
      setFormValue((state) => ({ ...state, startPointOffset: val }));

      if (isNaN(startPointOffset) || startPointOffset === undefined || startPointOffset < 0) return;

      startTransition(() => dispatch(updateTurnAction({ idToUpdate: turnId, startPointOffset, userAction: true })));
    },
    [dispatch, turnId]
  );

  const handleChangeGabarit = useCallback(
    (newGabarit: GabaritProperties) => {
      setFormValue((state) => ({
        ...state,
        gabarit: newGabarit,
      }));

      startTransition(() => dispatch(updateTurnAction({ idToUpdate: turnId, gabarit: newGabarit, userAction: true })));
    },
    [dispatch, turnId]
  );

  const [attachTurnMenuRef, setAttachTurnMenuRef] = useState<HTMLElement | null>(null);

  const [attachMenuType, setAttachMenuType] = useState<OriginOrDestination | null>(null);

  const [closeSegmentsAttach, setCloseSegmentsAttach] = useState<
    {
      segment: LoadedSegment;
      distance: number;
    }[]
  >([]);

  useEffect(() => {
    /**
     * The following useffect is triggered when the user opens the menu to reattach a turn to another segment
     * The idea is to find the closest segments to the turn and display them in the menu
     */

    if (!attachMenuType) {
      if (closeSegmentsAttach.length) setCloseSegmentsAttach([]);

      return;
    }

    const turnCoords = turn?.geometry.coordinates;
    if (!turnCoords) {
      // eslint-disable-next-line no-console
      console.error('No turn coords');

      return;
    }

    const coordsEndPoint = attachMenuType === 'origin' ? turnCoords[0] : turnCoords[turnCoords.length - 1];

    const storeState = store.getState();
    const segmentsIds = storeState.circuit.present.segments.ids;
    const segments = storeState.circuit.present.segments.entities;

    const nbMaxSegments = 5;

    const minimumDistances: number[] = [];
    let maxDistOfMin = 1 / 0;

    // we order by distance to the turn
    const selectedSegments = segmentsIds
      .filter((segmentId) => {
        // we remove the segments that are already connected to the turn
        return segmentId !== turn.properties.originId && segmentId !== turn.properties.destinationId;
      })
      .map((segmentId, index) => {
        const segment = segments[segmentId];
        const coords = segment.geometry.coordinates;

        const distance = pDistance(
          coordsEndPoint[0],
          coordsEndPoint[1],
          coords[0][0],
          coords[0][1],
          coords[1][0],
          coords[1][1]
        );

        // we compute the minimum distances
        if (index < nbMaxSegments || distance < maxDistOfMin) {
          minimumDistances.push(distance);
          maxDistOfMin = Math.max(...minimumDistances);
        }

        return {
          segment,
          distance,
        };
      })
      .filter((segmentAndDist) => {
        return segmentAndDist.distance <= maxDistOfMin;
      })
      .sort((segmentAndDistA, segmentAndDistB) => segmentAndDistA.distance - segmentAndDistB.distance)
      .slice(0, nbMaxSegments);

    setCloseSegmentsAttach(selectedSegments);
  }, [
    attachMenuType,
    closeSegmentsAttach.length,
    turn?.geometry.coordinates,
    turn?.properties.destinationId,
    turn?.properties.originId,
  ]);

  const handleOverReattachTurn = useCallback((segmentId: string) => {
    startTransition(() => {
      /**
       * The following lines apply a class to the segment that is hovered
       * The idea is to emphasize the segment that will be connected to the turn
       */
      document.querySelector(`g[uuid="${segmentId}"]`)?.classList.add('highlighted-turn-attach');
      document.querySelector('#root')?.classList.add('enable-highlighted-turn-attach');
    });
  }, []);

  const handleOutReattachTurn = useCallback((segmentId: string) => {
    // we remove the previously added class
    document.querySelector('#root')?.classList.remove('enable-highlighted-turn-attach');

    document
      .querySelectorAll('.highlighted-turn-attach')
      .forEach((el) => el.classList.remove('highlighted-turn-attach'));
  }, []);

  const handleReattachTurn = useCallback(
    (segmentId: string, originOrDestination: OriginOrDestination) => {
      setAttachTurnMenuRef(null);
      handleOutReattachTurn(segmentId);

      if (!turn) return;

      const action = updateTurnAction({
        idToUpdate: turnId,
        originId: originOrDestination === 'origin' ? segmentId : undefined,
        destinationId: originOrDestination === 'destination' ? segmentId : undefined,
      });

      dispatch(action);
    },
    [dispatch, turn, turnId, handleOutReattachTurn]
  );

  const computedRadiusStartPoint = useMemo(() => {
    return turn ? estimateRadiusOfTurn(turn) : undefined;
  }, [turn]);

  const handleClickConnectedSegmentChip = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, originOrDestination: OriginOrDestination) => {
      setAttachTurnMenuRef(e.currentTarget);
      setAttachMenuType(originOrDestination);
    },
    []
  );

  const [anchorElTrafficType, setAnchorElTrafficType] = useState<HTMLButtonElement | null>(null);
  const handleClickPortionChangeTrafficType = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const el = e.target as HTMLElement;
      if (el && turn) {
        const trafficType = el.innerText.toLowerCase() as TrafficType;
        dispatch(
          saveTurnAction({
            ...turn,
            properties: {
              ...turn.properties,
              trafficType: trafficType !== 'auto' ? trafficType : undefined,
            },
            userAction: true,
          })
        );
      }

      setAnchorElTrafficType(null);
    },
    [dispatch, turn]
  );

  const changeHighlightStatusPortion = useCallback(
    (e: React.MouseEvent<HTMLElement>, portionId: string | undefined) => {
      if (!portionId) {
        // eslint-disable-next-line no-console
        console.log('No portion id to highlight');

        return;
      }

      const el = e.target;

      if (el && turn && el instanceof HTMLElement) {
        const trafficType = el.innerText.toLowerCase() as TrafficType;

        const turnEl = document.querySelector(`[uuid="${turn.id}"]`);

        if (!turnEl) {
          // eslint-disable-next-line no-console
          console.error(`No svg element found for segment ${turn.id}`);

          return;
        }

        const portionEl = turnEl.querySelector('[main-shape]');

        if (!portionEl) {
          // eslint-disable-next-line no-console
          console.log(`Portion ${portionId} not found in the svg for ${turn.id}`);

          return;
        }

        const originPortionTrafic = turn.properties.trafficType;

        const classListValue = portionEl.classList.value;

        if (e.type === 'mouseleave' && originPortionTrafic) {
          portionEl.classList.replace(`${classListValue}`, `portion-${originPortionTrafic}`);

          return;
        }

        if (originPortionTrafic) {
          portionEl.classList.replace(`portion-${originPortionTrafic}`, `portion-${trafficType}`);

          return;
        }

        if (e.type === 'mouseleave' && !originPortionTrafic) {
          portionEl.classList.replace(`${classListValue}`, `portion-auto`);

          return;
        }

        if (!classListValue) {
          portionEl.classList.add(`portion-${trafficType}`);

          return;
        }

        portionEl.classList.replace(`${classListValue}`, `portion-${trafficType}`);
      }
    },
    [turn]
  );

  useEffect(() => {
    const modelsRadiusTooSmall = robotsData.filter((robotData) => {
      const radius = computedRadiusStartPoint ?? parseFloat(formValue.radius);

      return (robotData.minTurnRadius || 0) > radius;
    });

    setRadiusTooSmallForTheseTrucks(modelsRadiusTooSmall);
  }, [computedRadiusStartPoint, formValue.radius, robotsData]);

  return !turn || !turnId ? (
    <></>
  ) : (
    <PropertiesComponent
      shape={turn}
      shapeId={turnId}
      shapeType={ShapeTypes.TurnShape}
      sx={{ maxWidth: 'min-content' }}
    >
      {(formValue.radius === '' || parseFloat(formValue.radius) >= 0) && (
        <TextField
          id="input-turn-radius"
          value={computedRadiusStartPoint !== undefined ? computedRadiusStartPoint.toFixed(2) : formValue.radius}
          onChange={handleRadiusChange}
          onBlur={handleRadiusBlur}
          fullWidth
          margin="dense"
          type="number"
          label="Radius"
          inputProps={{
            'aria-label': 'Radius',
            min: 0.01,
            max: 50,
            step: 0.01,
          }}
          disabled={turn && turn.properties && turn.properties.drivenBy === 'StartPoint'}
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
            startAdornment:
              computedRadiusStartPoint !== undefined ? (
                <InputAdornment position="start">
                  <Tooltip title="Approximated radius">
                    <Functions />
                  </Tooltip>
                </InputAdornment>
              ) : undefined,
          }}
          variant="standard"
        />
      )}

      {radiusTooSmallForTheseTrucks.length && parseFloat(formValue.radius) >= 0 ? (
        <Alert
          severity="warning"
          sx={{
            maxWidth: 'fit-content',
            textAlign: 'left',
          }}
        >
          <AlertTitle sx={{ textAlign: 'center' }}>Radius too small</AlertTitle>
          The radius of this turn may be too small for the following robots:
          <List dense={true}>
            {radiusTooSmallForTheseTrucks.map((robotModelData, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <ChevronRight></ChevronRight>
                </ListItemIcon>
                <ListItemText>{robotModelData.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Alert>
      ) : (
        <></>
      )}

      <FormControl>
        <InputLabel id="turn-type-select" sx={{ transform: 'scale(0.75)' }}>
          Turn type
        </InputLabel>
        <Select
          labelId="turn-type-select"
          label="Turn type"
          value={formValue.turnType}
          onChange={handleChangeTurnType}
          variant="standard"
        >
          <MenuItem value={'Normal'}>Normal</MenuItem>
          <MenuItem value={'StopBeforeTurn'}>Stop before turn</MenuItem>
          <MenuItem value={'Tesseract'} disabled>
            Tesseract
          </MenuItem>
        </Select>
      </FormControl>

      {(formValue.radius === '' || parseFloat(formValue.radius) >= 0) && (
        <Collapse in={formValue.turnType === 'Normal'} timeout="auto" sx={{ flexShrink: 0 }}>
          <TextField
            id="input-turn-maxovershoot"
            value={formValue.maxOvershoot}
            onChange={handleMaxOvershootChange}
            fullWidth
            margin="dense"
            type="number"
            label={
              <>
                <span>Max Overshoot</span>{' '}
                <Tooltip title="This change the shape of the turn when decreased to prevent the robot from overshooting too much.">
                  <Help fontSize="small" className={classes.fadedTooltip}></Help>
                </Tooltip>
              </>
            }
            inputProps={{
              'aria-label': 'Max Overshoot',
              min: 0.01,
              step: 0.05,
            }}
            disabled={formValue.turnType !== 'Normal'}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">m</InputAdornment>
                </>
              ),
            }}
            variant="standard"
          />
        </Collapse>
      )}

      {isConnectedToExtendedLength && formValue.startPointOffset !== undefined && (
        <FormControl>
          <TextField
            id="input-turn-startpointoffset"
            value={formValue.startPointOffset}
            onChange={handleStartPointOffsetChange}
            fullWidth
            margin="dense"
            type="number"
            label={
              <>
                <span>Start Point Offset</span>{' '}
                <Tooltip title="The turn begins with a straight line">
                  <Help fontSize="small" className={classes.fadedTooltip}></Help>
                </Tooltip>
              </>
            }
            inputProps={{
              'aria-label': 'Start Point Offset',
              min: 0,
              max: 10, // to be changed
              step: 0.1,
            }}
            disabled={!isConnectedToExtendedLength}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">m</InputAdornment>
                </>
              ),
            }}
            variant="standard"
          />
        </FormControl>
      )}

      <TrafficTypeSelect
        setAnchorElPortion={setAnchorElTrafficType}
        anchorElPortion={anchorElTrafficType}
        shape={turn}
        handleClickPortionChangeTrafficType={handleClickPortionChangeTrafficType}
        changeHighlightStatusPortion={changeHighlightStatusPortion}
      />

      <Divider>Connected segments</Divider>
      <Stack direction="row" spacing={1}>
        <Chip
          label="Origin"
          icon={
            <ArrowForwardIcon
              sx={{
                transform: `rotate(${-(segmentOriginAngle ?? 0)}deg)`, // we rotate the arrow to match the segment orientation
              }}
            />
          }
          deleteIcon={<ArrowDropDownIcon />} // deleteIcon = secondIcon
          onDelete={(e) => {
            handleClickConnectedSegmentChip(e, 'origin');
          }}
          onClick={(e) => {
            handleClickConnectedSegmentChip(e, 'origin');
          }}
        />
        <Chip
          label="Destination"
          icon={
            <ArrowForwardIcon
              sx={{
                transform: `rotate(${-(segmentDestAngle ?? 0)}deg)`, // we rotate the arrow to match the segment orientation
              }}
            />
          }
          deleteIcon={<ArrowDropDownIcon />} // deleteIcon = secondIcon
          onDelete={(e) => {
            handleClickConnectedSegmentChip(e, 'destination');
          }}
          onClick={(e) => {
            handleClickConnectedSegmentChip(e, 'destination');
          }}
        />
      </Stack>

      {attachTurnMenuRef && attachMenuType && (
        <Menu
          open={true}
          anchorEl={attachTurnMenuRef}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setAttachTurnMenuRef(null)}
        >
          {closeSegmentsAttach.map((segmentAndDist) => {
            const segment = segmentAndDist.segment;
            const segmentCoords = segment.geometry.coordinates;
            const angle = findShapeOrientation(segmentCoords[0], segmentCoords[1]);
            const layers = store.getState().circuit.present.layers.layers;
            const color = layers[segment.properties.layerId]?.color ?? '#000000';
            const distance = segmentAndDist.distance / 100; // [m]

            return (
              <MenuItem
                key={segment.id}
                onClick={() => handleReattachTurn(segment.id as string, attachMenuType)}
                onMouseOver={() => handleOverReattachTurn(segment.id as string)}
                onMouseOut={() => handleOutReattachTurn(segment.id as string)}
              >
                <ArrowForwardIcon
                  sx={{
                    transform: `rotate(${-angle}deg)`,
                    color,
                    marginRight: theme.spacing(2),
                  }}
                />
                {distance.toFixed(2)} m
              </MenuItem>
            );
          })}

          {closeSegmentsAttach.length === 0 && <MenuItem>No segment found</MenuItem>}
        </Menu>
      )}

      <GabaritSelect
        handleChangeGabarit={handleChangeGabarit}
        gabarit={formValue.gabarit}
        disabled={!prefsLoaded || !modelsName.length}
      />
    </PropertiesComponent>
  );
};
