import { Help } from '@mui/icons-material';
import { Button, ButtonGroup, Divider, MenuItem, MenuList, Popover, Tooltip } from '@mui/material';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import { useCallback, useState } from 'react';
import type { LoadedSegment, LoadedTurn } from 'reducers/circuit/state';
import { useAppSelector } from 'store';
import { isCircuitSegment, isCircuitTurn } from 'utils/circuit/shape-guards';
import { trafficTypeToCssFilter } from 'utils/ui/traffic-type-to-color';

type TrafficTypeSelectProps = {
  locked?: boolean;
  handleClickPortionChangeTrafficType: (e: React.MouseEvent<HTMLLIElement>, portionId: string | undefined) => void;
  shape: LoadedTurn | LoadedSegment;
  anchorElPortion: HTMLButtonElement | null;
  setAnchorElPortion: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  changeHighlightStatusPortion: (e: React.MouseEvent<HTMLLIElement>, portionId: string | undefined) => void;
};

export function TrafficTypeSelect({
  locked,
  handleClickPortionChangeTrafficType,
  shape,
  anchorElPortion,
  setAnchorElPortion,
  changeHighlightStatusPortion,
}: TrafficTypeSelectProps): JSX.Element {
  const [openedPortionId, setOpenedPortionId] = useState<string | undefined>(undefined);

  const handleClickPortion = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, portionId: string) => {
      setAnchorElPortion(e.currentTarget);
      setOpenedPortionId(portionId);
    },
    [setAnchorElPortion]
  );
  const handleClosePortionPopover = useCallback(() => {
    setAnchorElPortion(null);
    setOpenedPortionId(undefined);
  }, [setAnchorElPortion]);

  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const shapeLayerId = shape?.properties.layerId;
  const layerColor = shapeLayerId ? layers[shapeLayerId]?.color : undefined;

  // DeadEnd-Exit and DeadEnd-Entry are only available for SDK version >= 4.15.0
  const minimumSDKVersionDeadEndExit = '4.15.0';
  const isSdkVersionOk = useSdkVersionOk(minimumSDKVersionDeadEndExit);

  return (
    <>
      <Divider>
        Traffic type{' '}
        <Tooltip title="Force a traffic type rule on portion" style={{ verticalAlign: 'middle', marginLeft: 1 }}>
          <Help fontSize="small"></Help>
        </Tooltip>
      </Divider>
      {isCircuitSegment(shape) ? (
        <ButtonGroup
          variant="contained"
          color="inherit"
          size="small"
          disabled={locked}
          fullWidth
          sx={{
            /** if the segment is connected to a lot of other segments, there will be a lot of portions in it,
             * thereby a lot of buttons, we don't want to segment properties to take all the user display,
             * so we set a max width and we allow the user to scroll */
            maxWidth: '300px',
            overflowX: 'auto',
            overflowY: 'visible',
          }}
        >
          {shape.properties.portions.map((portion, index) => (
            <Button
              data-id={portion.id}
              data-index={index}
              key={portion.id}
              onClick={(e) => handleClickPortion(e, portion.id)}
              sx={{
                backgroundColor: portion.trafficType ? layerColor : undefined,
                filter: trafficTypeToCssFilter(portion.trafficType),
              }}
            >
              {portion.trafficType || 'Auto'}
            </Button>
          ))}
        </ButtonGroup>
      ) : isCircuitTurn(shape) ? (
        <Button
          variant="contained"
          color="inherit"
          size="small"
          disabled={locked}
          fullWidth
          onClick={(e) => handleClickPortion(e, shape.id as string)}
          sx={{
            backgroundColor: shape.properties.trafficType ? layerColor : undefined,
            filter: trafficTypeToCssFilter(shape.properties.trafficType),
          }}
        >
          {shape.properties.trafficType || 'Auto'}
        </Button>
      ) : (
        <></>
      )}
      <Popover
        open={!!anchorElPortion}
        anchorEl={anchorElPortion}
        onClose={handleClosePortionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList>
          <MenuItem
            onClick={(e) => handleClickPortionChangeTrafficType(e, openedPortionId)}
            onMouseEnter={(e) => changeHighlightStatusPortion(e, openedPortionId)}
            onMouseLeave={(e) => changeHighlightStatusPortion(e, openedPortionId)}
          >
            Auto
          </MenuItem>
          <MenuItem
            color="primary"
            onClick={(e) => handleClickPortionChangeTrafficType(e, openedPortionId)}
            onMouseEnter={(e) => changeHighlightStatusPortion(e, openedPortionId)}
            onMouseLeave={(e) => changeHighlightStatusPortion(e, openedPortionId)}
          >
            Kernel
          </MenuItem>

          <MenuItem
            color="primary"
            onClick={(e) => handleClickPortionChangeTrafficType(e, openedPortionId)}
            onMouseEnter={(e) => changeHighlightStatusPortion(e, openedPortionId)}
            onMouseLeave={(e) => changeHighlightStatusPortion(e, openedPortionId)}
          >
            DeadEnd
          </MenuItem>

          {isSdkVersionOk && (
            <>
              <MenuItem
                color="primary"
                onClick={(e) => handleClickPortionChangeTrafficType(e, openedPortionId)}
                onMouseEnter={(e) => changeHighlightStatusPortion(e, openedPortionId)}
                onMouseLeave={(e) => changeHighlightStatusPortion(e, openedPortionId)}
              >
                DeadEnd-Entry
              </MenuItem>

              <MenuItem
                color="primary"
                onClick={(e) => handleClickPortionChangeTrafficType(e, openedPortionId)}
                onMouseEnter={(e) => changeHighlightStatusPortion(e, openedPortionId)}
                onMouseLeave={(e) => changeHighlightStatusPortion(e, openedPortionId)}
              >
                DeadEnd-Exit
              </MenuItem>
            </>
          )}
        </MenuList>
      </Popover>
    </>
  );
}
