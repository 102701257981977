import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'services/snackbar.service';
import type { SimulationErrorResponse } from 'simulation/states';

export interface RobotPath {
  /** model of the robot (ex: LHP, etc.) */
  robotModel: string;
  /** id of the robot in the project (index+1) */
  robotId: number;
  /** Robot path [x, y, cap?] */
  path: ([number, number] | [number, number, number])[];
}

export interface Robot {
  /** Id of the robot (int) */
  id: number;
  /** name of the robot */
  name: string;
  /** serial number of the robot */
  serial: string;

  /** position of the robot */
  position: {
    /** position of the robot [m] */
    x: number;
    /** position of the robot [m] */
    y: number;
    /** orientation of the robot [rad] */
    heading: number;
  };

  /** data about the state of the robot */
  state?: {
    task?: number;
    navigation?: number;
    traffic?: number;
    position?: number;
    safety?: number;
    communication?: number;
  };

  /** "Action" state of the robot, that represents if it is idling, travelling, or moving its forks */
  action?: number;

  /** image of the robot */
  picturePath?: string;

  /** distance between the wheels of the robot and the top point of the robot in x [m] */
  distanceXWheelTop?: number;
  /** distance between the wheels of the robot and the top point of the robot in y [m] */
  distanceYWheelTop?: number;
  /** length of the robot [m] */
  robotLength?: number;
  /** width of the robot [m] */
  robotWidth?: number;

  /** is the robot carrying a pallet? */
  carry?: boolean;

  /** Data about the robot battery */
  battery?: {
    /** Battery level in % [0-100] */
    level?: number;
  };

  /** Distance travelled by the robot since the beginning of the simulation [m] */
  odometry?: number;
  /** Current speed of the robot [m/s] */
  speed?: number;
  /** Current height of the robot's forks [m] */
  forksHeight?: number;

  /** Data about the robot traffic */
  trafficData?: {
    /** name of the other robot blocking this robot */
    otherRobotBlocking?: string;
    /** Description of the traffic status of the robot */
    info?: string;
  };

  /** id of the layer group of the robot */
  layerGroup: string;
}

export interface RobotsSliceState {
  /**
   * Path of robots to display on the playground
   * Can be used to retrace a log
   */
  robotPaths: RobotPath[];
  /**
   * List of robots to display on the playground
   */
  robots: Robot[];

  /**
   * Indexes of the selected robots
   */
  selectedRobotsIndexes: number[];

  /**
   * Index of the robot hovered by the user
   */
  hoveredRobotIndex?: number;
}

const initialState: RobotsSliceState = {
  robotPaths: [],
  robots: [],
  selectedRobotsIndexes: [],
};

const robotsSliceLocal = createSlice({
  initialState,
  name: 'robots',
  reducers: {
    addRobotPath: (state, action: PayloadAction<RobotPath>) => {
      state.robotPaths.push(action.payload);
    },
    removeRobotPath: (state, action: PayloadAction<{ indexPathToRemove: number }>) => {
      state.robotPaths = state.robotPaths.filter((_, index) => index !== action.payload.indexPathToRemove);
    },
    clearAllRobotPaths: (state) => {
      state.robotPaths = [];
    },

    setRobots: (state, action: PayloadAction<Robot[]>) => {
      const newRobots = action.payload;

      state.robots = newRobots;
    },
    addRobot: (state, action: PayloadAction<Robot>) => {
      state.robots.push(action.payload);
    },
    removeRobot: (state, action: PayloadAction<{ indexRobotToRemove: number }>) => {
      state.robots = state.robots.filter((_, index) => index !== action.payload.indexRobotToRemove);
    },
    clearAllRobots: (state) => {
      state.robots = [];
    },
    updateRobot: (state, action: PayloadAction<{ indexRobotToUpdate: number; robot: Robot }>) => {
      state.robots[action.payload.indexRobotToUpdate] = action.payload.robot;
    },
    selectRobot: (state, action: PayloadAction<{ indexRobotToSelect: number }>) => {
      state.selectedRobotsIndexes = [action.payload.indexRobotToSelect];
    },
    selectRobotByName: (state, action: PayloadAction<{ nameRobotToSelect: string; unselectOtherRobots?: boolean }>) => {
      const { nameRobotToSelect, unselectOtherRobots = true } = action.payload;
      const indexRobotToSelect = state.robots.findIndex((robot) => robot.name === nameRobotToSelect);

      if (indexRobotToSelect === -1) {
        return;
      }

      if (unselectOtherRobots) {
        state.selectedRobotsIndexes = [indexRobotToSelect];
      } else {
        state.selectedRobotsIndexes.push(indexRobotToSelect);
      }
    },
    addRobotToSelection: (state, action: PayloadAction<{ indexRobotToAdd: number }>) => {
      state.selectedRobotsIndexes.push(action.payload.indexRobotToAdd);
    },
    unselectAllRobots: (state) => {
      state.selectedRobotsIndexes = [];
    },
    unselectRobot: (state, action: PayloadAction<{ indexRobotToUnselect: number }>) => {
      state.selectedRobotsIndexes = state.selectedRobotsIndexes.filter(
        (_, index) => index !== action.payload.indexRobotToUnselect
      );
    },
    resetRobot: (state, action: PayloadAction<{ indexRobotToReset: number }>) => {
      resetRobotsSimulation([action.payload.indexRobotToReset + 1]);
    },
    resetSelectedRobots: (state) => {
      resetRobotsSimulation(state.selectedRobotsIndexes.map((index) => state.robots[index].id));
    },
    setManualSelectedRobots: (state) => {
      setManualRobotsSimulation(state.selectedRobotsIndexes.map((index) => state.robots[index].id));
    },
    setAutoSelectedRobots: (state) => {
      setAutoRobotsSimulation(state.selectedRobotsIndexes.map((index) => state.robots[index].id));
    },
    setHoveredRobotIndex: (state, action: PayloadAction<{ indexRobotHovered: number | undefined }>) => {
      state.hoveredRobotIndex = action.payload.indexRobotHovered;
    },
  },
});

export const robotsSlice = {
  reducer: robotsSliceLocal.reducer,
};

export const {
  addRobotPath,
  clearAllRobotPaths,
  removeRobotPath,
  setRobots,
  addRobot,
  removeRobot,
  clearAllRobots,
  updateRobot,
  resetSelectedRobots,
  setManualSelectedRobots,
  setAutoSelectedRobots,
  selectRobot,
  addRobotToSelection,
  unselectAllRobots,
  unselectRobot,
  resetRobot,
  selectRobotByName,
  setHoveredRobotIndex,
} = robotsSliceLocal.actions;

/**
 * Reset the simulation of the robots by their robot IDS (start from 1)
 * @param robotsIds the ids of the robots to reset
 * @returns void
 */
async function resetRobotsSimulation(robotsIds: number[]): Promise<void> {
  const simulationService = (await import('services/simulation.service'))?.simulationService;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('Simulation service not found');

    return;
  }

  for (let i = 0; i < robotsIds.length; i++) {
    const robotId = robotsIds[i];
    await simulationService._ROBEMU_WasmWrapper_resetRobot(robotId);
  }
}

/**
 * Set the simulation mode of the robots to manual control
 * @param robotsIds the ids of the robots to set to manual control
 * @returns void
 */
export async function setManualRobotsSimulation(robotsIds: number[]): Promise<void> {
  const simulationService = (await import('services/simulation.service'))?.simulationService;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('Simulation service not found');

    return;
  }

  for (let i = 0; i < robotsIds.length; i++) {
    const robotId = robotsIds[i];
    const resStrPtr = await simulationService._ROBEMU_WasmWrapper_toManu(robotId);

    const resStr = await simulationService.UTF8ToString(resStrPtr);
    let res: SimulationErrorResponse | undefined;
    try {
      res = JSON.parse(resStr) as SimulationErrorResponse;
    } catch (e) {}

    if (!res || res.errorID !== 0) {
      // eslint-disable-next-line no-console
      console.error('Error setting robot to manual control', resStr);

      SnackbarUtils.error(`Error setting robot ${robotId} to manual control`);
    }
  }
}

/**
 * Set the simulation mode of the robots to automatic control
 * @param robotsIds the ids of the robots to set to automatic control
 * @returns void
 */
export async function setAutoRobotsSimulation(robotsIds: number[]): Promise<void> {
  const simulationService = (await import('services/simulation.service'))?.simulationService;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('Simulation service not found');

    return;
  }

  for (let i = 0; i < robotsIds.length; i++) {
    const robotId = robotsIds[i];
    const resStrPtr = await simulationService._ROBEMU_WasmWrapper_toAuto(robotId);

    const resStr = await simulationService.UTF8ToString(resStrPtr);
    let res: SimulationErrorResponse | undefined;
    try {
      res = JSON.parse(resStr) as SimulationErrorResponse;
    } catch (e) {}

    if (!res || res.errorID !== 0) {
      // eslint-disable-next-line no-console
      console.error('Error setting robot to automatic control', resStr);

      SnackbarUtils.error(`Error setting robot ${robotId} to automatic control`);
    }
  }
}

interface SetRobotPositionSimulationParams {
  robotId: number;
  x: number;
  y: number;
  heading: number;
}
export async function setRobotPositionSimulation(params: SetRobotPositionSimulationParams): Promise<void> {
  const simulationService = (await import('services/simulation.service'))?.simulationService;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('Simulation service not found');

    return;
  }

  const { robotId, x, y, heading } = params;
  const resStrPtr = await simulationService._ROBEMU_WasmWrapper_setRobotPosition(robotId, x, y, heading);

  const resStr = await simulationService.UTF8ToString(resStrPtr);
  let res: SimulationErrorResponse | undefined;
  try {
    res = JSON.parse(resStr) as SimulationErrorResponse;
  } catch (e) {}

  if (!res || res.errorID !== 0) {
    // eslint-disable-next-line no-console
    console.error('Error setting robot position', resStr);

    SnackbarUtils.error(`Error setting robot ${robotId} position`);
  }
}
