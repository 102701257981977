import store from 'store';

/**
 * This function checks the segment that if it is a extended length or not and return a boolean if its a extended length or not
 * @param segmentId
 * @returns a boolean
 */
export function isSegmentAnExtendedLength(segmentId: string): boolean {
  const circuitState = store.getState().circuit.present;
  const segments = circuitState.segments.entities;
  const segment = segments[segmentId];
  if (!segment || !segment.properties) {
    return false;
  }

  const hasStockLine = segment.properties.stockLine !== undefined;

  const hasRack = segment.properties.rack !== undefined;

  return hasStockLine || hasRack;
}
