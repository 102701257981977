import { remoteDoc, setProjectHost } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { PreferencesService } from 'utils/preferences';
import type { YMap } from 'yjs/dist/src/internals';

export const useProjectHostChangedSubscription = (): void => {
  const projectHostChangedMap = remoteDoc?.getMap('projectHostChanged') as YMap<boolean>;
  const prefMap = remoteDoc?.getMap('pref');

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      if (!prefMap) return;

      const isTransactionLocal = transaction.origin === 'local' || !!transaction.local;

      if (isTransactionLocal && projectHostChangedMap.get('bool')) {
        projectHostChangedMap.set('bool', false);

        return;
      }

      if (isTransactionLocal) return;

      const xmls = prefMap.get('xmls');
      const projectName = prefMap.get('projectName');
      const projectNEWVersion = prefMap.get('projectNEWVersion');
      const availableCircuits = prefMap.get('availableCircuits');

      PreferencesService.setInitialPref({ xmls, projectName, projectNEWVersion, availableCircuits });

      setProjectHost(false);
    };

    projectHostChangedMap.observe(observerHandle);

    return () => {
      projectHostChangedMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
