import type * as d3 from 'd3';
import type { PointDrawingProperties } from 'drawings/editor-elements';
import { pointTextSizeToPx } from 'drawings/editor-elements';
import type { SimpleSelection } from 'drawings/shared';
import type { Point } from 'geojson';
import { ShapeTypes } from 'models/circuit';
import { getConfig } from 'utils/config';
import { Shape } from './shape.element';

export class Circle extends Shape<Point> {
  constructor(
    id: string,
    geometry: Point,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    drawingProperties: PointDrawingProperties
  ) {
    super(id, ShapeTypes.PointShape, geometry, projection, zoomScale, drawingProperties);

    this.drawingProperties = drawingProperties;
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    this.zoomScale = 1;

    this.projection.pointRadius(pointTextSizeToPx(this.drawingProperties?.size ?? 'small') / this.zoomScale);

    return super.createNode();
  }

  public setZoomScale(zoomScale: number): void {
    if (zoomScale > getConfig('editor').elementWidth.zoomScale.points.max)
      zoomScale = getConfig('editor').elementWidth.zoomScale.points.max;
    else if (zoomScale < getConfig('editor').elementWidth.zoomScale.points.min)
      zoomScale = getConfig('editor').elementWidth.zoomScale.points.min;

    zoomScale = 1;

    super.setZoomScale(zoomScale);

    this.projection.pointRadius(pointTextSizeToPx(this.drawingProperties?.size ?? 'small') / this.zoomScale);
    this.node.selectAll('path[main-shape=true]').attr('d', this.projection as any);
  }

  protected onDrag(): void {
    super.onDrag();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected onDragEnd(properties: any): void {
    super.onDragEnd(properties);
  }
}
