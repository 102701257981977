import { IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import ColorizeIcon from '@mui/icons-material/Colorize';
import Popover from '@mui/material/Popover';
import { SmallChip } from './small-chip';

interface ColorPickerProps {
  onChangeFinished?(color: string): any;
  defaultColor?: string;
  variant?: 'pick-icon' | 'color-bubble';
}

export const ColorPicker = ({
  onChangeFinished,
  defaultColor,
  variant = 'pick-icon',
}: ColorPickerProps): JSX.Element => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor || '#aabbcc');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setDisplayColorPicker(!displayColorPicker);

      setAnchorEl(e.currentTarget);
    },
    [displayColorPicker]
  );

  const handleClose = useCallback(() => {
    setDisplayColorPicker(false);

    if (onChangeFinished) onChangeFinished(color);
  }, [color, onChangeFinished]);

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        {variant === 'pick-icon' && <ColorizeIcon />}
        {variant === 'color-bubble' && <SmallChip color={color} />}
      </IconButton>
      <Popover
        open={displayColorPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ style: { overflow: 'hidden', background: 'transparent' } }}
      >
        <HexColorPicker color={color} onChange={setColor} />
      </Popover>
    </>
  );
};
