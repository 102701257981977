import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import FilterIcon from '@mui/icons-material/Filter';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { updateMapImageTilesReleaseVersionAction } from 'actions';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { setDescription, setDescriptionFromYJS, setVersion, setVersionFromYJS } from 'project/project';
import React from 'react';
import { checkPermission } from 'services/check-permission';
import { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';

export function CircuitVersionAndDescription(): JSX.Element {
  const dispatch = useAppDispatch();
  const circuitDescription = useAppSelector((state) => state.project.circuitDescription);
  const circuitVersion = useAppSelector((state) => state.project.circuitVersion);

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDescriptionFromYJS(e.target.value));
  };

  const handleChangeVersion = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setVersionFromYJS(e.target.value));
  };

  const handleSaveDescription = (e: React.FocusEvent<HTMLInputElement>): void => {
    dispatch(setDescription(e.target.value));
  };

  const handleSaveVersion = (e: React.FocusEvent<HTMLInputElement>): void => {
    dispatch(setVersion(e.target.value));
  };

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  const mapImageTiles = useAppSelector((state) => state.maps.mapImageTiles.mapImageTilesData);

  const projectName = useAppSelector((state) => state.project.projectName);

  const releaseVersionArr = useAppSelector((state) => state.maps.mapImageTiles.mapTilesReleaseVersion);

  const releaseVersion = mapImageTiles?.release;

  const releaseVersionFetchError = useAppSelector((state) => state.maps.mapImageTiles.mapTilesReleaseVersionFetchError);

  const handleChangeReleaseVersion = (event: SelectChangeEvent): void => {
    dispatch(
      updateMapImageTilesReleaseVersionAction({
        newRelease: event.target.value,
      })
    );
  };

  return (
    <List>
      <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
        <Box component="span">
          <ListItem disabled={!editCircuitPerm}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <TextField
                  value={circuitVersion}
                  onChange={handleChangeVersion}
                  onBlur={handleSaveVersion}
                  size="small"
                  disabled={!editCircuitPerm}
                  sx={{ marginBottom: '0.5rem' }}
                />
              }
              secondary="Circuit Version"
            />
          </ListItem>
        </Box>
      </Tooltip>
      <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
        <Box component="span">
          <ListItem disabled={!editCircuitPerm}>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <TextField
                  value={circuitDescription}
                  onChange={handleChangeDescription}
                  onBlur={handleSaveDescription}
                  size="small"
                  disabled={!editCircuitPerm}
                  sx={{ marginBottom: '0.5rem' }}
                />
              }
              secondary="Circuit Description"
            />
          </ListItem>
        </Box>
      </Tooltip>

      <Box component="span">
        <ListItem sx={{ paddingBottom: !!releaseVersionFetchError || !mapImageTiles ? 0 : '8px' }}>
          <ListItemIcon>
            <FilterIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Select
                value={releaseVersion}
                size="small"
                onChange={handleChangeReleaseVersion}
                disabled={!!releaseVersionFetchError || !mapImageTiles}
                sx={{ marginBottom: '0.5rem', minWidth: '223px' }}
              >
                {releaseVersionArr ? (
                  releaseVersionArr.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={''}>No release version</MenuItem>
                )}
              </Select>
            }
            secondary={
              <>
                Map Editor Birdview Release Version
                <HelpIconTooltip
                  title="The birdview is a high-definition map of the site. It is automatically downloaded from the Balyo cloud if detected"
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </>
            }
          />
        </ListItem>
        {(releaseVersionFetchError || !mapImageTiles) && (
          <Alert severity="info" sx={{ mt: 2, marginTop: 0 }}>
            No bird view detected in the Balyo cloud for the project {projectName}
          </Alert>
        )}
      </Box>
    </List>
  );
}
