import { ShapeTypes } from 'models/circuit';
import { connect } from 'react-redux';
import {
  selectCurrentLocal,
  selectFirstSelectedShapeDataType,
  selectHasManyShapesSelected,
} from 'reducers/local/selectors';
import type { AppState } from 'reducers/state';
import { DeviceProperties } from './device-properties';
import { MeasurerProperties } from './measurer-properties/measurer-properties';
import { NoteProperties } from './note-properties';
import { PointProperties } from './point-properties';
import { RackProperties } from './rack-properties/rack-properties';
import { SegmentPropertiesComponent } from './segment-properties/segment-properties';
import { StockZoneProperties } from './stockzones-properties';
import { TurnPropertiesComponent } from './turn-properties/turn-properties';
import { ZoneProperties } from './zone-properties';

interface StateProps {
  type?: ShapeTypes;
  hasManyShapesSelected?: boolean;
  selectedShapeIds?: string[];
}

function mapStateToProps(state: AppState): StateProps {
  const local = selectCurrentLocal(state);
  const type = selectFirstSelectedShapeDataType(local);
  const hasManyShapesSelected = selectHasManyShapesSelected(local);
  const selectedShapeIds = state.local.selectedShapesData.map((shape) => shape.id);

  return {
    type,
    hasManyShapesSelected,
    selectedShapeIds,
  };
}

export const Properties = connect(mapStateToProps)(PropertiesComponent);

function PropertiesComponent({ type, hasManyShapesSelected, selectedShapeIds }: StateProps): JSX.Element | null {
  if (hasManyShapesSelected) {
    // If more than one shape selected, no properties toolbox displayed
    return null;
  }

  if (!type) return null;

  switch (type) {
    case ShapeTypes.ZoneShape: {
      return <ZoneProperties />;
    }

    case ShapeTypes.PointShape: {
      return <PointProperties />;
    }

    case ShapeTypes.TurnShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <TurnPropertiesComponent key={selectedShapeIds[0]} turnId={selectedShapeIds[0]} />
      ) : null;
    }

    case ShapeTypes.SegmentShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <SegmentPropertiesComponent key={selectedShapeIds[0]} segmentId={selectedShapeIds[0]} />
      ) : null;
    }

    case ShapeTypes.StockZoneShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <StockZoneProperties key={selectedShapeIds[0]} stockZoneId={selectedShapeIds[0]} />
      ) : null;
    }

    case ShapeTypes.MeasurerShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <MeasurerProperties key={selectedShapeIds[0]} measurerId={selectedShapeIds[0]} />
      ) : null;
    }

    case ShapeTypes.RackShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <RackProperties key={selectedShapeIds[0]} rackId={selectedShapeIds[0]} />
      ) : null;
    }

    case ShapeTypes.DeviceShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <DeviceProperties key={selectedShapeIds[0]} deviceId={selectedShapeIds[0]} />
      ) : null;
    }

    case ShapeTypes.NoteShape: {
      return selectedShapeIds && selectedShapeIds[0] ? (
        <NoteProperties key={selectedShapeIds[0]} noteId={selectedShapeIds[0]} />
      ) : null;
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`PropertiesComponent: unknown type ${type}`);

      return null;
    }
  }
}
