import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { useRef } from 'react';
import { theme } from 'utils/mui-theme';

const colorAnimationDuration = theme.transitions.duration.complex;

type TextfieldWithSubmitAndClearProps = TextFieldProps & {
  /**
   * If true, the textfield will submit on enter
   * @default true
   */
  submitOnEnter?: boolean;
  /**
   * If true, the textfield will clear on escape
   * @default true
   */
  clearOnEscape?: boolean;
  /**
   * Submit callback
   * @returns void
   */
  onValidate?: () => void;
  /**
   * Clear callback
   * @returns void
   */
  onClear?: () => void;
};

export function TextFieldWithSubmitAndClear(props: TextfieldWithSubmitAndClearProps): JSX.Element {
  const { submitOnEnter = true, clearOnEscape = true, onValidate, onClear, ...rest } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <TextField
      {...rest}
      sx={{
        ...rest.sx,
        transition: `color ${colorAnimationDuration}ms ease-in-out`,
      }}
      inputRef={(r: HTMLInputElement) => {
        inputRef.current = r;
        if (rest.inputRef) (rest.inputRef as any).current = r;
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && submitOnEnter && onValidate) {
          onValidate();

          if (inputRef.current) {
            const initialColor = inputRef.current.style.color;

            inputRef.current.style.transition = `color ${colorAnimationDuration}ms ease-in-out`;
            inputRef.current.style.color = `${theme.palette.success.light}`;

            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.style.color = initialColor;
              }
            }, colorAnimationDuration);
          }
        } else if (e.key === 'Escape' && clearOnEscape && onClear) {
          e.stopPropagation();
          onClear();
          inputRef.current?.blur();
        }

        if (rest.onKeyDown) {
          rest.onKeyDown(e);
        }
      }}
      onBlur={() => {
        if (onClear) {
          onClear();
        }
      }}
    />
  );
}
