import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import HeightIcon from '@mui/icons-material/Height';
import {
  Box,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAsyncMemo } from 'use-async-memo';
import {
  maxRoofHeightDefaultValue,
  maxRoofHeightMinValue,
  roofClearanceDefaultValue,
  roofClearanceMinValue,
} from 'utils/config';
import { PreferencesService } from 'utils/preferences';
import { delayStopEditBlur } from './constants';

export function MaxHeightItemsPreferences({ projectOpened }: { projectOpened: number }): JSX.Element {
  const maxRoofHeightPrefName = 'heightLimits/maxRoofHeight';
  const roofClearancePrefName = 'heightLimits/roofClearance';

  const [editMaxRoofHeight, setEditMaxRoofHeight] = useState(false);
  const [editRoofClearance, setEditRoofClearance] = useState(false);

  const [maxRoofHeight, setMaxRoofHeight] = useState<string | null | undefined>(undefined);
  const [roofClearance, setRoofClearance] = useState<string | null | undefined>(undefined);

  const refInputMaxRoofHeight = useRef<HTMLInputElement | null>(null);
  const refInputRoofClearance = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    if (!projectOpened) return;

    try {
      setMaxRoofHeight(PreferencesService.getPreferenceValue(maxRoofHeightPrefName, undefined, false) as string);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${maxRoofHeightPrefName} from preferences`, e);
    }
  }, [projectOpened]);
  useLayoutEffect(() => {
    if (!projectOpened) return;

    try {
      setRoofClearance(PreferencesService.getPreferenceValue(roofClearancePrefName, undefined, false) as string);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${roofClearancePrefName} from preferences`, e);
    }
  }, [projectOpened]);

  const handleChangeMaxRoofHeight = useCallback(async (newValue: string) => {
    const parsedValue = parseFloat(newValue);
    const min = refInputMaxRoofHeight.current?.min;
    const max = refInputMaxRoofHeight.current?.max;

    if (
      isNaN(parsedValue) ||
      (min !== undefined && parsedValue < parseFloat(min)) ||
      (max !== undefined && parsedValue > parseFloat(max))
    ) {
      // if the value is invalid, we restore the value from the preferences
      setEditMaxRoofHeight(false);

      SnackbarUtils.warning(
        `${parsedValue || 'The entered value'} is not a valid value, we didn't update the preference`
      );

      return;
    }

    const [ok] = await PreferencesService.setPreferenceValue(maxRoofHeightPrefName, newValue, true);

    if (ok) {
      setMaxRoofHeight(newValue);

      SnackbarUtils.success(`Maximum roof height updated to ${newValue} m`);

      setTimeout(() => setEditMaxRoofHeight(false), 100);
    } else {
      SnackbarUtils.error(`Could not update maximum roof height`);
    }
  }, []);
  const handleChangeRoofClearance = useCallback(async (newValue: string) => {
    const parsedValue = parseFloat(newValue);
    const min = refInputRoofClearance.current?.min;
    const max = refInputRoofClearance.current?.max;

    if (
      isNaN(parsedValue) ||
      (min !== undefined && parsedValue < parseFloat(min)) ||
      (max !== undefined && parsedValue > parseFloat(max))
    ) {
      // if the value is invalid, we restore the value from the preferences
      setEditRoofClearance(false);

      SnackbarUtils.warning(
        `${parsedValue || 'The entered value '} is not a valid value, we didn't update the preference`
      );

      return;
    }

    const [ok] = await PreferencesService.setPreferenceValue(roofClearancePrefName, newValue, true);

    if (ok) {
      setRoofClearance(newValue);

      SnackbarUtils.success(`Roof clearance updated to ${newValue} m`);

      setTimeout(() => setEditRoofClearance(false), 100);
    } else {
      SnackbarUtils.error(`Could not update roof clearance`);
    }
  }, []);

  const editSafetyPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_safety_configuration');
  }, []);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <HeightIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              {editMaxRoofHeight ? (
                <TextField
                  type="number"
                  defaultValue={maxRoofHeight ?? maxRoofHeightDefaultValue.toString()}
                  onBlur={() => setTimeout(() => setEditMaxRoofHeight(false), delayStopEditBlur)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        m
                        <IconButton onClick={() => setEditMaxRoofHeight(false)}>
                          <ClearIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (refInputMaxRoofHeight.current)
                              handleChangeMaxRoofHeight(refInputMaxRoofHeight.current.value);
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: maxRoofHeightMinValue,
                    step: 0.1,
                  }}
                  inputRef={refInputMaxRoofHeight}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (refInputMaxRoofHeight.current) handleChangeMaxRoofHeight(refInputMaxRoofHeight.current.value);
                    }
                  }}
                />
              ) : (
                <>
                  {maxRoofHeight ? `${maxRoofHeight} m` : 'Not Defined'}
                  {!editMaxRoofHeight ? (
                    <Tooltip title={!editSafetyPerm ? 'You are not authorized to edit the safety configuration' : ''}>
                      <Box component="span">
                        <IconButton
                          onClick={() => {
                            setEditMaxRoofHeight(true);
                          }}
                          disabled={!editSafetyPerm}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  ) : undefined}
                </>
              )}
            </>
          }
          secondary={
            <>
              Maximum roof height
              <HelpIconTooltip
                title={`Maximum height of the roof. (min: ${maxRoofHeightMinValue} m)`}
                sx={{
                  fontSize: '1rem',
                }}
              />
            </>
          }
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <HeightIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              {editRoofClearance ? (
                <TextField
                  type="number"
                  defaultValue={roofClearance ?? roofClearanceDefaultValue.toString()}
                  onBlur={() => setTimeout(() => setEditRoofClearance(false), delayStopEditBlur)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        m
                        <IconButton onClick={() => setEditRoofClearance(false)}>
                          <ClearIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (refInputRoofClearance.current)
                              handleChangeRoofClearance(refInputRoofClearance.current.value);
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: roofClearanceMinValue,
                    step: 0.1,
                  }}
                  inputRef={refInputRoofClearance}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (refInputRoofClearance.current) handleChangeRoofClearance(refInputRoofClearance.current.value);
                    }
                  }}
                />
              ) : (
                <>
                  {roofClearance ? `${roofClearance} m` : 'Not Defined'}
                  {!editRoofClearance ? (
                    <Tooltip title={!editSafetyPerm ? 'You are not authorized to edit the safety configuration' : ''}>
                      <Box component="span">
                        <IconButton
                          onClick={() => {
                            setEditRoofClearance(true);
                          }}
                          disabled={!editSafetyPerm}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  ) : undefined}
                </>
              )}
            </>
          }
          secondary={
            <>
              Roof clearance
              <HelpIconTooltip
                title={`Minimum space to keep at all time between the roof and the highest point of the robot or the load. (min: ${roofClearanceMinValue} m)`}
                sx={{
                  fontSize: '1rem',
                }}
              />
            </>
          }
        />
      </ListItem>
    </>
  );
}
