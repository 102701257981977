import type { ExportCircuitFeatures } from 'services/circuit.service';
import { CircuitService } from 'services/circuit.service';
import { PreferencesService } from 'utils/preferences';
import { downloadGeojsonCircuit } from './download-circuit';

export async function emergencySave(): Promise<void> {
  const zones = CircuitService.zones;
  const points = CircuitService.points;
  const segments = CircuitService.segments;
  const stockZones = CircuitService.stockZones;
  const measurers = CircuitService.measurers;
  const turns = CircuitService.turns;
  const racks = CircuitService.racks;
  const devices = CircuitService.devices;

  const features: ExportCircuitFeatures = {
    zones,
    points,
    segments,
    stockZones,
    measurers,
    turns,
    racks,
    devices,
  };

  const projectName = PreferencesService.arePreferencesFullyLoaded()
    ? (PreferencesService.getPreferenceValue('general/projectName') as string)
    : 'unknown';

  // eslint-disable-next-line no-console
  console.log('Starting the download of the emergency save...');
  await downloadGeojsonCircuit('emergency_save_circuit.geojson', features, true, {
    projectName,
  });
  // eslint-disable-next-line no-console
  console.log('Emergency save: done');
}
