import { clearShapesSelectionAction, selectCircuitShapeAction, unselectCircuitShapeAction } from 'actions/circuit';
import type { CircuitShape } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { isShapeType } from 'models/circuit.guard';
import type { lineStrokeSize, pointSize } from 'models/drawings';
import { Tools } from 'models/tools';
import React, { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';
import type {
  LoadedDevice,
  LoadedMeasurer,
  LoadedNote,
  LoadedPoint,
  LoadedRack,
  LoadedSegment,
  LoadedStockZone,
  LoadedTurn,
  LoadedZone,
} from 'reducers/circuit/state';
import { checkPermission } from 'services/check-permission';
import { CircuitService } from 'services/circuit.service';
import type { StockManagerRecord } from 'simulation/simulation';
import { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { useAsyncMemo } from 'use-async-memo';
import { isCircuitTurn } from 'utils/circuit/shape-guards';
import { DeviceComponent } from './device';
import { MeasurerComponent } from './measurer';
import { NoteComponent } from './notes';
import { PointComponent } from './point';
import { RackComponent } from './rack';
import { SegmentComponent } from './segment';
import { StockZoneComponent } from './stockzone';
import { TurnComponent } from './turn';
import { ZoneComponent } from './zone';

export const ShapeComponentMemo = React.memo(ShapeComponent);

export const CircuitLayerComponentMemo = React.memo(CircuitLayerComponent);

interface CircuitLayerComponentProps {
  pointsSize?: pointSize;
  lineStrokeSize?: lineStrokeSize;
  disabled?: boolean;
}
export function CircuitLayerComponent(props: CircuitLayerComponentProps): JSX.Element {
  const dispatch = useAppDispatch();

  const activeTool = useAppSelector((state) => state.tool.activeTool);

  const selectedShapes = useAppSelector((state) => state.local.selectedShapesData);
  const selectedShapesIds = useMemo(() => {
    return selectedShapes.map((shape) => shape.id);
  }, [selectedShapes]);

  const filters = useAppSelector((state) => state.local.filters);
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);

  const segments = useAppSelector((state) => state.circuit.present.segments.entities);
  const segmentsIds = useAppSelector((state) => state.circuit.present.segments.ids);

  const turns = useAppSelector((state) => state.circuit.present.turns.entities);
  const turnsIds = useAppSelector((state) => state.circuit.present.turns.ids);

  const zones = useAppSelector((state) => state.circuit.present.zones.entities);
  const zonesIds = useAppSelector((state) => state.circuit.present.zones.ids);

  const points = useAppSelector((state) => state.circuit.present.points.entities);
  const pointsIds = useAppSelector((state) => state.circuit.present.points.ids);

  const measurers = useAppSelector((state) => state.circuit.present.measurers.entities);
  const measurersIds = useAppSelector((state) => state.circuit.present.measurers.ids);

  const stockZones = useAppSelector((state) => state.circuit.present.stockZones.entities);
  const stockZonesIds = useAppSelector((state) => state.circuit.present.stockZones.ids);

  const racks = useAppSelector((state) => state.circuit.present.racks.entities);
  const racksIds = useAppSelector((state) => state.circuit.present.racks.ids);

  const devices = useAppSelector((state) => state.circuit.present.devices.entities);
  const devicesIds = useAppSelector((state) => state.circuit.present.devices.ids);

  const notes = useAppSelector((state) => state.circuit.present.notes.entities);
  const notesIds = useAppSelector((state) => state.circuit.present.notes.ids);

  const performanceModeEnabled = useAppSelector((state) => state.editor.performanceMode);

  const handleClick = useCallback(
    (e: React.MouseEvent<SVGGElement>) => {
      const el = e.target as SVGElement;
      let parent: SVGElement | undefined = el;

      if (el) {
        let uuid = el.getAttribute('uuid');
        let type = el.getAttribute('type');

        while (!uuid || !type) {
          parent = parent?.parentElement as unknown as SVGElement | undefined;
          if (!parent) break;

          uuid = parent.getAttribute('uuid');
          type = parent.getAttribute('type');
        }

        e.stopPropagation();

        const ctrlPressed = e.ctrlKey;
        batch(() => {
          if (typeof uuid !== 'string') throw new Error(`uuid must be a string, uuid value: ${uuid}`);
          // assert type as a ShapeTypes
          if (!isShapeType(type)) throw new Error('type must be a ShapeTypes');

          const alreadySelected = selectedShapesIds.includes(uuid);

          if (!ctrlPressed) {
            if (selectedShapesIds.length > 0) dispatch(clearShapesSelectionAction());
            dispatch(
              selectCircuitShapeAction({
                selectedShapeId: uuid,
                selectedShapeType: type,
              })
            );

            return;
          }

          if (!alreadySelected) {
            const shape = CircuitService.getShape(uuid, type);
            dispatch(
              selectCircuitShapeAction({
                selectedShapeId: uuid,
                selectedShapeType: type,
                shape,
              })
            );
          } else {
            dispatch(
              unselectCircuitShapeAction({
                unselectedShapeId: uuid,
                unselectedShapeType: type,
              })
            );
          }
        });
      }
    },
    [dispatch, selectedShapesIds]
  );

  const shapes = useMemo(() => {
    return { ...segments, ...turns, ...zones, ...points, ...measurers, ...stockZones, ...racks, ...devices, ...notes };
  }, [segments, turns, zones, points, measurers, stockZones, racks, devices, notes]);

  const segmentAndTurnsGabaritIds = useMemo(() => {
    let segmentAndTurnsGabaritIds = [...segmentsIds, ...turnsIds].filter((id) => {
      const shape = shapes[id];
      const layer = layers[shape.properties.layerId];

      return 'gabarit' in shape.properties && shape.properties.gabarit?.display && layer.visibility;
    });

    segmentAndTurnsGabaritIds = segmentAndTurnsGabaritIds.sort((idA, idB) => {
      const prioA = shapes[idA].properties.prio;
      const prioB = shapes[idB].properties.prio;

      return prioA - prioB;
    });

    return segmentAndTurnsGabaritIds;
  }, [layers, segmentsIds, shapes, turnsIds]);

  const pointsGabaritIds = useMemo(() => {
    let pointsGabaritIds = pointsIds.filter((id) => {
      const shape = shapes[id];
      const layer = layers[shape.properties.layerId];

      return 'gabarit' in shape.properties && shape.properties.gabarit?.display && layer.visibility;
    });

    pointsGabaritIds = pointsGabaritIds.sort((idA, idB) => {
      const prioA = shapes[idA].properties.prio;
      const prioB = shapes[idB].properties.prio;

      return prioA - prioB;
    });

    return pointsGabaritIds;
  }, [layers, pointsIds, shapes]);

  const smallShapesIds = useMemo(() => {
    const pIds = filters.points ? pointsIds : [];
    const dIds = filters.devices ? devicesIds : [];
    const nIds = filters.notes ? notesIds : [];

    let smallShapesIds = [...pIds, ...dIds, ...nIds];

    smallShapesIds = smallShapesIds.sort((idA, idB) => {
      const prioA = shapes[idA].properties.prio;
      const prioB = shapes[idB].properties.prio;

      return prioA - prioB;
    });

    return smallShapesIds;
  }, [filters.devices, filters.notes, filters.points, devicesIds, notesIds, pointsIds, shapes]);

  const highlightTurnsOptionEnabled = useAppSelector((state) => state.tool.highlightTurns);
  const mediumShapesIds = useMemo(() => {
    const sIds = filters.segments ? segmentsIds : [];
    const tIds = filters.turns ? turnsIds : [];
    const mIds = filters.measurers ? measurersIds : [];

    let mediumShapesIds = [...sIds, ...tIds, ...mIds];

    const bonusPrioTurnsHighlight = 1e6;

    mediumShapesIds = mediumShapesIds.sort((idA, idB) => {
      const prioA =
        shapes[idA].properties.prio +
        (highlightTurnsOptionEnabled && isCircuitTurn(shapes[idA]) ? bonusPrioTurnsHighlight : 0);
      const prioB =
        shapes[idB].properties.prio +
        (highlightTurnsOptionEnabled && isCircuitTurn(shapes[idB]) ? bonusPrioTurnsHighlight : 0);

      return prioA - prioB;
    });

    return mediumShapesIds;
  }, [
    filters.measurers,
    filters.segments,
    filters.turns,
    highlightTurnsOptionEnabled,
    measurersIds,
    segmentsIds,
    shapes,
    turnsIds,
  ]);

  const bigShapesIds = useMemo(() => {
    const zIds = filters.zones ? zonesIds : [];
    const szIds = filters.stockZones ? stockZonesIds : [];
    const rIds = filters.racks ? racksIds : [];

    let bigShapesIds = [...zIds, ...szIds, ...rIds];

    bigShapesIds = bigShapesIds.sort((idA, idB) => {
      const prioA = shapes[idA].properties.prio;
      const prioB = shapes[idB].properties.prio;

      return prioA - prioB;
    });

    return bigShapesIds;
  }, [filters.racks, filters.stockZones, filters.zones, racksIds, shapes, stockZonesIds, zonesIds]);

  const shapesIds = useMemo(() => {
    return [...bigShapesIds, ...mediumShapesIds, ...smallShapesIds];
  }, [bigShapesIds, mediumShapesIds, smallShapesIds]);

  const displayGabaritFilter = useAppSelector((state) => state.local.filters.gabarit);

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  const displayPalletOverflow = !performanceModeEnabled;

  return (
    <g
      {...{ layer: 'circuit' }}
      onClick={handleClick}
      style={{
        pointerEvents: !editCircuitPerm ? 'none' : 'visible',
      }}
      className={`${
        activeTool === Tools.DisplayTraffic || activeTool === Tools.Route
          ? 'display-traffic-mode'
          : activeTool === Tools.SimulationConfiguration
            ? 'simulation-mode'
            : undefined
      } ${props.disabled ? 'disabled' : undefined}`}
    >
      {segmentAndTurnsGabaritIds.map((id) => (
        <use key={id} href={`#gabarit-${id}`} />
      ))}
      {pointsGabaritIds.map((id) => (
        <use key={id} href={`#gabarit-${id}`} />
      ))}
      {shapesIds.map((id) => {
        const shape = shapes[id];
        const selected = selectedShapesIds.includes(id);

        return (
          <ShapeComponentMemo
            key={id}
            shape={shape}
            selected={selected}
            size={props.pointsSize}
            strokeSize={props.lineStrokeSize}
            displayGabarit={displayGabaritFilter}
            displayPalletOverflow={displayPalletOverflow}
          />
        );
      })}
    </g>
  );
}

interface ShapeComponentProps {
  shape: CircuitShape;
  selected?: boolean;
  size?: pointSize; // type to be changed to be more general if we want to handle this attribute for other elements
  strokeSize?: lineStrokeSize;
  displayGabarit?: boolean;
  displayPalletOverflow?: boolean;
  stock?: StockManagerRecord;
}

function ShapeComponent(props: ShapeComponentProps): JSX.Element {
  const shape = props.shape;
  const type = shape.properties.type;

  if (shape.hidden) {
    return <></>;
  }

  switch (type) {
    case ShapeTypes.SegmentShape: {
      return (
        <SegmentComponent
          key={shape.id}
          id={shape.id as string}
          segment={shape as LoadedSegment}
          selected={props.selected}
          strokeSize={props.strokeSize}
          displayGabarit={props.displayGabarit}
        />
      );
    }

    case ShapeTypes.TurnShape: {
      return (
        <TurnComponent
          key={shape.id}
          id={shape.id as string}
          turn={shape as LoadedTurn}
          selected={props.selected}
          strokeSize={props.strokeSize}
          displayGabarit={props.displayGabarit}
        />
      );
    }

    case ShapeTypes.ZoneShape: {
      return (
        <ZoneComponent key={shape.id} id={shape.id as string} zone={shape as LoadedZone} selected={props.selected} />
      );
    }

    case ShapeTypes.PointShape: {
      return (
        <PointComponent
          key={shape.id}
          id={shape.id as string}
          point={shape as LoadedPoint}
          selected={props.selected}
          size={props.size}
          displayGabarit={props.displayGabarit}
        />
      );
    }

    case ShapeTypes.MeasurerShape: {
      return (
        <MeasurerComponent
          key={shape.id}
          id={shape.id as string}
          measurer={shape as LoadedMeasurer}
          selected={props.selected}
        />
      );
    }

    case ShapeTypes.StockZoneShape: {
      return (
        <StockZoneComponent
          key={shape.id}
          id={shape.id as string}
          stockZone={shape as LoadedStockZone}
          selected={props.selected}
          stock={props.stock}
        />
      );
    }

    case ShapeTypes.RackShape: {
      return (
        <RackComponent
          key={shape.id}
          id={shape.id as string}
          rack={shape as LoadedRack}
          selected={props.selected}
          displayPalletOverflow={!!props.displayPalletOverflow}
        />
      );
    }

    case ShapeTypes.DeviceShape: {
      return (
        <DeviceComponent
          key={shape.id}
          id={shape.id as string}
          device={shape as LoadedDevice}
          selected={props.selected}
          size={'medium'}
        />
      );
    }

    case ShapeTypes.NoteShape: {
      return (
        <NoteComponent
          key={shape.id}
          id={shape.id as string}
          note={shape as LoadedNote}
          selected={props.selected}
          size={'medium'}
        />
      );
    }
  }

  // eslint-disable-next-line no-console
  console.error('Unknown shape type', shape);

  // ts error triggered if we add a type but not in the switch
  assert<Equals<typeof type, never>>();
}
