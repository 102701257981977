import type { TaskState } from 'simulation/states';
import { theme } from 'utils/mui-theme';

export interface TaskSchedulerInfo {
  id: string | number;
  taskName: string;
  source?: string;
  destination?: string;
  duration: string;
  robotId: string | number;
  robotAuthorised?: string;
  waitingTime: string;
  priority?: number;
  status?: TaskState;
  dueDate?: string;
  stepLabel: string;
}

export const taskStatusColor: Partial<Record<TaskState, string>> = {
  running: theme.palette.info.main,
  waiting: theme.palette.grey[300],
  done: theme.palette.success.main,

  beforestart: theme.palette.common.black,
  prerunning: theme.palette.common.black,
};
