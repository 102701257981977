import { createSelector } from 'reselect';
import type { AppState } from '../../reducers/state';
import type { CoreState } from './state';
import { getAppOnline } from './reducer';

export function coreSelector(state: AppState): CoreState {
  return state.core;
}

export const selectAppOnline = createSelector(coreSelector, getAppOnline);
