import { Turn } from 'drawings/editor-elements';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitTurn } from 'models/circuit';
import type { lineStrokeSize } from 'models/drawings';
import { useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from 'store';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface TurnComponentProps {
  turn: CircuitTurn;
  id: string;
  selected?: boolean;
  strokeSize?: lineStrokeSize;
  displayGabarit?: boolean;
}

const maxNbPointsPerformanceMode = 5;

export function TurnComponent(props: TurnComponentProps): JSX.Element {
  const turn = props?.turn;
  const ref = useRef<SVGSVGElement>(null);
  const performanceMode = useAppSelector((state) => state.editor.performanceMode);

  const geometry = useMemo(() => {
    if (!performanceMode || !turn?.geometry) {
      return turn?.geometry;
    }

    return simplifyTurnGeometry(turn.geometry, maxNbPointsPerformanceMode);
  }, [performanceMode, turn.geometry]);

  const shape = useMemo(() => {
    if (props?.id && turn && turn?.id) {
      try {
        return new Turn(turn.id.toString(), geometry, projection, zoomScale, turn.properties, {
          strokeSize: props.strokeSize ?? 's',
          displayGabarit: props.displayGabarit,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('weird error while generating the turn after a ctrl+z', e);
      }
    }
  }, [props?.id, props.strokeSize, props.displayGabarit, turn, geometry]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();
      const clickElement = node?.querySelector('[main-shape]')?.cloneNode() as SVGElement;

      if (node && clickElement) {
        // this element is a transparent element to handle clicks
        // on a larger area than juste the shape
        clickElement.removeAttribute('main-shape');
        clickElement.style.removeProperty('stroke-width');
        clickElement.classList.add('select-shape-helper');

        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        node.insertBefore(clickElement, node.firstChild);
        ref.current.appendChild(node);
      }
    }
  }, [shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props?.selected, shape]);

  return <g ref={ref}></g>;
}

/**
 * Simplifies the geometry of a turn by reducing the number of points.
 *
 * @param {CircuitTurn['geometry']} geometry - The original geometry of the turn.
 * @param {number} maxNbPoints - The maximum number of points the simplified geometry should have.
 *
 * @returns {CircuitTurn['geometry']} The simplified geometry.
 */
function simplifyTurnGeometry(geometry: CircuitTurn['geometry'], maxNbPoints: number): CircuitTurn['geometry'] {
  const coords = geometry.coordinates;
  if (coords.length <= maxNbPoints) return geometry;

  const nbPoints = coords.length;
  const step = Math.floor(nbPoints / maxNbPoints);

  const newCoords: number[][] = [coords[0]];
  for (let i = step, l = nbPoints - step; i < l; i += step) {
    newCoords.push(coords[i]);
  }

  newCoords.push(coords[nbPoints - 1]);

  return {
    ...geometry,
    coordinates: newCoords,
  };
}
