import type * as d3 from 'd3';
import type { Measurer } from 'drawings/editor-elements';
import type { Shape } from 'drawings/elements';
import type { ShapeTypes } from 'models/circuit';
import { LayerNames } from './shared';
import { SVGLayer } from './svg.layer';

export class CircuitLayer extends SVGLayer {
  protected onShapeSelected?: (selectedShapeId: string, selectedShapeType: ShapeTypes) => void;
  protected onShapeUnselected?: (shapeId: string, unselectedShapeType: ShapeTypes) => void;
  public readonly selectedShapesId: Set<string> = new Set();

  constructor(
    onShapeSelected?: (selectedShapeId: string, selectedShapeType: ShapeTypes) => void,
    onShapeUnselected?: (unselectedShapeId: string, unselectedShapeType: ShapeTypes) => void,
    layerName = LayerNames.Circuit
  ) {
    super(layerName);
    this.onShapeSelected = onShapeSelected;
    this.onShapeUnselected = onShapeUnselected;
  }

  public addShape(shape: Shape): void {
    super.addShape(shape);
    if (this.onShapeSelected && this.onShapeUnselected) {
      shape.onSelect(this.onShapeSelected);
      shape.onUnselect(this.onShapeUnselected);
    }
  }

  public unSelectAllShapes(): void {
    this.selectedShapesId.forEach((shapeId) => this.unSelectSingleShape(shapeId));
    this.selectedShapesId.clear();
  }

  public unSelectSingleShape(shapeId: string): void {
    this.shapes.get(shapeId)?.setActive(false);
  }

  public setSelectedShapes(selectedShapeIds: string[]): void {
    this.unSelectAllShapes();
    if (selectedShapeIds) {
      for (const selectedShapeId of selectedShapeIds) {
        this.shapes.get(selectedShapeId)?.setActive();
        this.selectedShapesId.add(selectedShapeId);
      }
    }
  }

  public updateZoomScale(transformation: d3.ZoomTransform): void {
    this.shapes.forEach((shape) => shape.setZoomScale(transformation.k));
  }

  public getNbShapes(): number {
    return this.shapes.size;
  }

  public updateShapeProperties(shapeId: string): void {
    (this.shapes.get(shapeId) as Measurer)?.updateProperties();
  }
}
