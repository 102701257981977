import type { Position } from 'geojson';
import type { CircuitPortion, CircuitSegment, CircuitTurn } from 'models/circuit';
import { generateShapeId } from './next-free-id';

export type InOrOut = 'in' | 'out';

/**
 * This function computes all the portion of a segment
 * Portions are part of segments used by the SDK to generate the initerary, etc.
 * @param portionsPts Data about the portion [[start point (x, y), other points (x, y)(straight portions have only two points but turns have a lot of points)..., end point (x, y)], if the portion is not straight the turn data about the portion, is it an input portion or an output portion?]
 * @param segment Data about the segment
 * @param formerPortions Previously generated data if it is an update of the portions and not a new segment
 * @returns the geberated portions
 */
export function computePortionsOfSegment(
  portionsPts: [Position, CircuitTurn?, InOrOut?][],
  segment: CircuitSegment,
  formerPortions?: CircuitPortion[]
): CircuitPortion[] {
  const portions: CircuitPortion[] = [];
  const portionsIds: string[] = [
    ...Array(portionsPts.length - 1)
      .fill(null)
      .map((_, index) => (formerPortions && formerPortions[index]?.id) || generateShapeId()),
  ];
  // by default portions don't have traffic type but sometimes the user wants to force it
  const portionsTrafficType = portionsIds.map(
    (val, index) => (formerPortions && formerPortions[index]?.trafficType) || undefined
  );

  for (let i = 0, l = portionsPts.length - 1; i < l; i++) {
    const portion: CircuitPortion = {
      id: portionsIds[i],
      points: [portionsPts[i][0], portionsPts[i + 1][0]],
    };

    if (portionsTrafficType[i]) portion.trafficType = portionsTrafficType[i];

    if (i > 0) portion.inStart = [portionsIds[i - 1]];
    if (i < portionsPts.length - 2) portion.outEnd = [portionsIds[i + 1]];

    if (
      i === 0 &&
      segment.properties.portions &&
      segment.properties.portions.length &&
      segment.properties.portions[0].stockZoneId
    ) {
      // if the first portion has a stockZoneId we need to keep it
      portion.stockZoneId = segment.properties.portions[0].stockZoneId;
    }

    if (portionsPts[i] && portionsPts[i][2] && portionsPts[i][1] && portionsPts[i][1]?.id) {
      if (portionsPts[i][2] === 'out') {
        const inOrOut: `${InOrOut}Start` = 'inStart';
        const inOrOutInv: `${InOrOut}Start` = 'outStart';

        if (!portion[inOrOut]) portion[inOrOut] = [];
        if (!portion[inOrOutInv]) portion[inOrOutInv] = [];

        const id = portionsPts[i][1]?.id as string | undefined;
        if (id) {
          portion[inOrOut]?.push(id);
          portion.turnIdStart = id;

          portion[inOrOutInv]?.push(portion.id);
        }
      }
    }

    if (portionsPts[i + 1] && portionsPts[i + 1][2] && portionsPts[i + 1][1] && portionsPts[i + 1][1]?.id) {
      if (portionsPts[i + 1][2] === 'in') {
        const inOrOut: `${InOrOut}End` = 'outEnd';
        const inOrOutInv: `${InOrOut}End` = 'inEnd';

        if (!portion[inOrOut]) portion[inOrOut] = [];
        if (!portion[inOrOutInv]) portion[inOrOutInv] = [];

        const id = portionsPts[i + 1][1]?.id as string | undefined;
        if (id) {
          portion[inOrOut]?.push(id);
          portion.turnIdEnd = id;

          portion[inOrOutInv]?.push(portion.id);
        }
      }
    }

    portions.push(portion);
  }

  return portions;
}
