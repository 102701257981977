import type {
  ChangeDisplayStateImageProperties,
  ClearMapImage,
  ImportLidar,
  ImportLidarFailure,
  ImportLidarSuccess,
  ImportMapImage,
  ImportMapImageSuccess,
  OpenDialog,
} from 'actions';
import {
  MapsActionTypes,
  changeDisplayStateMapImagePropertiesAction,
  closeDialogAction,
  importLidarFailureAction,
  importLidarSuccessAction,
  importMapImageSuccessAction,
  openDialogAction,
  updateMapImagePropertiesAction,
} from 'actions';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import { DialogTypes } from 'models';
import type { MapImage } from 'models/maps';
import { LidarPosition } from 'models/maps';
import { localDoc } from 'multiplayer/globals';
import { addMapImageFilterAction } from 'reducers/local/filters.reducer';
import type { AppState } from 'reducers/state';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { MapsService } from 'services/maps.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { Container } from 'typescript-ioc';
import { regexMapImageOuster } from 'utils/map-scaling';
import { PreferencesService, convertBlobUrlToUInt8Array, loadFileAsUint8Array, writeToFile } from 'utils/preferences';

export function importLidar$(
  mapsService: MapsService,
  actions$: ActionsObservable<any>
): Observable<ImportLidarSuccess | ImportLidarFailure> {
  return actions$.pipe(
    ofType<ImportLidar>(MapsActionTypes.ImportLidar),
    mergeMap(({ payload }) => {
      localStorage[`${payload.position}-isDrawingPoints`] = 'false';

      return mapsService.buildLidar(payload.coords, payload.name).pipe(
        map((data) =>
          importLidarSuccessAction({
            lidar: data,
            position: payload.position,
            saveBackgroundLidar: payload.saveBackgroundLidar,
          })
        ),
        catchError((err) => of(importLidarFailureAction(err)))
      );
    })
  );
}

export function importMapImage$(actions$: ActionsObservable<any>): Observable<ImportMapImageSuccess> {
  return actions$.pipe(
    ofType<ImportMapImage>(MapsActionTypes.ImportMapImage),
    filter(({ payload }) => !!payload),
    map(({ payload }) => importMapImageSuccessAction(payload))
  );
}

export function importMapImageSuccess$(
  action$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<OpenDialog | ChangeDisplayStateImageProperties> {
  return action$.pipe(
    ofType<ImportMapImageSuccess>(MapsActionTypes.ImportMapImageSuccess),
    map(async ({ payload }) => {
      const { imageURL, name } = payload;

      if (regexMapImageOuster.test(name)) {
        const resRegex = regexMapImageOuster.exec(name);

        if (resRegex) {
          const scale = parseFloat(resRegex?.groups?.scale ?? '');
          const originX = parseFloat(resRegex?.groups?.originX ?? '');
          const originY = parseFloat(resRegex?.groups?.originY ?? '');

          if (!isNaN(scale) && !isNaN(originX) && !isNaN(originY)) {
            const img = new Image();
            img.src = imageURL;

            let imageDecodeOk = false;
            try {
              await img.decode();
              imageDecodeOk = true;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Error decoding image', e);

              SnackbarUtils.error(`The layout image ${name} could not be decoded. It may be corrupted or too large.`);
            }

            if (!imageDecodeOk) return;

            const heightImgPx = img.height;

            const heightImgM = heightImgPx / scale;

            store.dispatch(
              updateMapImagePropertiesAction({
                properties: {
                  x: originX * 100, // m -> cm
                  y: originY * 100 + heightImgM * 100, // m -> cm
                  height: heightImgM * 100, // m -> cm
                  name,
                  URL: imageURL,
                },
                targetName: name,
              })
            );

            // we close the project settings dialog
            store.dispatch(closeDialogAction());

            // and we open the image properties dialog
            let openIndex = 0;
            store.getState().maps.mapImage.mapImages?.forEach((mapImage, index) => {
              if (mapImage.name !== name) return;
              openIndex = index;
            });
            store.dispatch(
              changeDisplayStateMapImagePropertiesAction({
                openIndex,
              })
            );
            store.dispatch(addMapImageFilterAction({ name }));

            SnackbarUtils.success('Map image imported successfully. It has been scaled automatically.');

            return;
          }

          // eslint-disable-next-line no-console
          console.error('Error parsing map image name (invalid scale or origin)', {
            scale,
            originX,
            originY,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error('Error parsing map image name', resRegex);
        }
      }

      // we close the project settings dialog and open the image scalaing dialog
      store.dispatch(openDialogAction({ type: DialogTypes.ImageScaling, payload: undefined }));

      // and we open the image properties dialog
      let openIndex = 0;
      store.getState().maps.mapImage.mapImages?.forEach((mapImage, index) => {
        if (mapImage.name !== name) return;
        openIndex = index;
      });
      store.dispatch(
        changeDisplayStateMapImagePropertiesAction({
          openIndex,
        })
      );
      store.dispatch(addMapImageFilterAction({ name }));
    }),
    withLatestFrom(state$),
    switchMap(async ([action, state]) => {
      if (
        !state.multiplayer.multiplayer ||
        !state.maps.mapImage.openProperties ||
        !state.maps.mapImage.mapImages ||
        !state.maps.mapImage.mapImages[state.maps.mapImage.openProperties]
      )
        return;

      /* Map Image */
      const localMapImageMap = localDoc.getMap('mapImage');
      const { URL, name } = state.maps.mapImage.mapImages[state.maps.mapImage.openProperties];
      const layoutImageUInt8Array = await convertBlobUrlToUInt8Array(URL);

      localMapImageMap.set(name, { data: { URL }, uInt8Array: layoutImageUInt8Array });

      syncYJSLocalToRemote();
    }),
    ignoreElements()
  );
}

export function clearMapImage$(actions$: ActionsObservable<any>): Observable<null> {
  return actions$.pipe(
    ofType<ClearMapImage>(MapsActionTypes.ClearMapImage),
    map((action) => {
      const localMapImageMap = localDoc.getMap('mapImage');

      if (action.payload.name) {
        localMapImageMap.delete(action.payload.name);
      } else {
        localMapImageMap.clear();
      }

      syncYJSLocalToRemote();
    }),
    ignoreElements()
  );
}

export function updateImageProps$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<null> {
  return actions$.pipe(
    filter((action) =>
      [MapsActionTypes.UpdateMapImageProperties, MapsActionTypes.SetImageHeight].includes(action.type)
    ),
    debounceTime(0),
    withLatestFrom(state$),
    map(async ([action, state]) => {
      if (
        !state.multiplayer.multiplayer ||
        !state.maps.mapImage.openProperties ||
        !state.maps.mapImage.mapImages ||
        !state.maps.mapImage.mapImages[state.maps.mapImage.openProperties]
      )
        return;

      /* Map Image */
      const { URL, name, ...data } = state.maps.mapImage.mapImages[state.maps.mapImage.openProperties];
      const localMapImageMap = localDoc.getMap('mapImage');
      const remoteMapImage = localMapImageMap.get(name) as {
        data: Partial<MapImage>;
        uInt8Array: Uint8Array;
      } | null;

      if (
        action.type === MapsActionTypes.UpdateMapImageProperties &&
        action.payload.targetName &&
        action.payload.targetName !== action.payload.properties.name
      ) {
        localMapImageMap.delete(action.payload.targetName);

        const layoutImageUInt8Array = await convertBlobUrlToUInt8Array(URL);
        localMapImageMap.set(action.payload.properties.name, { data, uInt8Array: layoutImageUInt8Array });
      } else if (remoteMapImage?.uInt8Array) {
        localMapImageMap.set(name, { data, uInt8Array: remoteMapImage.uInt8Array });
      } else {
        const layoutImageUInt8Array = await convertBlobUrlToUInt8Array(URL);
        localMapImageMap.set(name, { data, uInt8Array: layoutImageUInt8Array });
      }

      syncYJSLocalToRemote();
    }),
    ignoreElements()
  );
}

export function copyMapObstacleInFolder$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<null> {
  return actions$.pipe(
    ofType<ImportLidarSuccess>(MapsActionTypes.ImportLidarSuccess),
    map(async ({ payload }) => {
      if (!PreferencesService.arePreferencesFullyLoaded()) return;

      const { lidar, position } = payload;

      const fileName = lidar.name;

      // we want to check if the lidar is already saved in the project folder
      const lidarMapFile = await PreferencesService.getFileByPath(`MAP/${fileName}`);

      const saveForegroundLidar = position === LidarPosition.Foreground;
      const saveBackgroundLidar = payload.saveBackgroundLidar && position === LidarPosition.Background;
      if (saveBackgroundLidar) {
        await PreferencesService.setPreferenceValue('localisation/mapFilePath', fileName.replace('.txt', '.geo'));
      }

      if (lidarMapFile) return;

      const projectFolderHandle = await PreferencesService.getDirectoryHandle();
      if (!projectFolderHandle) {
        SnackbarUtils.error(
          `Error while copying the ${
            position === LidarPosition.Foreground ? 'obstacle' : 'navigation'
          } map in the project folder`
        );

        return;
      }

      if (!saveForegroundLidar && !saveBackgroundLidar) return;

      // if the file is not in the project, we have to copy it
      const txt = lidar.coordinates
        .map((coord) => {
          const firstValue = Math.round(coord?.[0] / 4).toFixed(0);
          const secondValue = Math.round(coord?.[1] / 4).toFixed(0);

          return `${firstValue};${secondValue};${coord?.[2]}\n`;
        })
        .join('');

      SnackbarUtils.info(
        `${saveForegroundLidar ? 'Map obstacle' : 'Navigation map'} ${fileName} copied in the project folder`
      );

      if (txt.length) {
        const mapFolderHandle = await projectFolderHandle.getDirectoryHandle('MAP');
        const lidarMapFileHandle = await mapFolderHandle.getFileHandle(fileName, { create: true });
        writeToFile(lidarMapFileHandle, txt);
      }
    }),
    withLatestFrom(state$),
    switchMap(async ([action, state]) => {
      if (!state.multiplayer.multiplayer) return;

      /* Lidar */
      const localLidarMap = localDoc.getMap('lidar');

      localLidarMap.set('lidar', state.maps.lidar);

      const backgroundLidarInput = document.getElementById('background-lidar-input') as HTMLInputElement;
      const backgroundLidarFile = backgroundLidarInput?.files?.[0];

      if (backgroundLidarFile) {
        const backgroundLidarUInt8Array = await loadFileAsUint8Array(backgroundLidarFile);

        localLidarMap.set('background-uInt8Array', backgroundLidarUInt8Array);
      }

      syncYJSLocalToRemote();
    }),
    ignoreElements()
  );
}

export function combineMapsEpics(): any {
  const mapsService = Container.get(MapsService);

  return combineEpics(
    importLidar$.bind(null, mapsService),
    importMapImage$,
    importMapImageSuccess$,
    clearMapImage$,
    updateImageProps$,
    copyMapObstacleInFolder$
  );
}
