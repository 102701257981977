/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { Action as ReduxAction, Reducer } from 'redux';

export interface Action<T = any> extends ReduxAction {
  payload: T;
}

export interface Update<T, I = string> {
  id: I;
  changes: Partial<T>;
}

export interface Loadable {
  ensuredCount?: number;
  loaded?: boolean;
  loading?: boolean;
  updated?: boolean;
  updating?: boolean;
  updatedAt?: string;
}

export function disableActions<T>(actionTypes: T, disabledActionTypes: string[] = []): T {
  // @ts-ignore
  return Object.keys(actionTypes).reduce((result, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    result[key] = disabledActionTypes.includes(key) ? '💀' : actionTypes[key];

    return result;
  }, {} as T);
}

export function reduceReducers<S, A extends Action = Action>(reducers: Reducer<S, A>[]): Reducer<S, A> {
  return (state: S | undefined, action: A) => reducers.reduce((result, reducer) => reducer(result, action), state) as S;
}
