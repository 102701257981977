import centroid from '@turf/centroid';
import { polygon as turfPolygon } from '@turf/helpers';
import type { SaveStockZone } from 'actions/stock-zones';
import { saveStockZoneAction } from 'actions/stock-zones';
import { rotatePolygon2 } from 'drawings/helpers';
import { cloneDeep } from 'lodash';
import type { CircuitStockZone, SlotArray } from 'models/circuit';
import type { LoadedStockZone } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import store from 'store';
import { toRad } from 'utils/helpers';
import { epsilon } from './utils';

export interface UpdateStockZoneParams {
  cap?: number;
  name?: string;
  extendedLength?: number;
  forceUpdateCap?: boolean;
  forceUpdateSlots?: boolean;
  doNotDispatchAndReturn?: boolean;
  newCoordinates?: CircuitStockZone['geometry']['coordinates'];
  stockZoneMargin?: number;
  slots?: SlotArray[];
}
export function updateStockZone(
  stockZone: LoadedStockZone | CircuitStockZone,
  params?: UpdateStockZoneParams
): void | SaveStockZone {
  const cap = params?.cap ?? stockZone.properties.cap;
  const name = params?.name ?? stockZone.properties.name;
  const extendedLength = params?.extendedLength ?? stockZone.properties.extendedLength;
  const stockZoneMargin = params?.stockZoneMargin ?? stockZone.properties.stockZoneMargin;
  let coordinates = params?.newCoordinates ?? stockZone.geometry.coordinates;
  let slots = params?.slots ?? stockZone.properties.slots;

  /** if the orientation changed, we need to recompute the position of the slots */
  const deltaCap = Math.abs(cap - stockZone.properties.cap);
  const deltaStockZoneMargin = Math.abs((stockZoneMargin ?? 0) - (stockZone.properties.stockZoneMargin ?? 0));
  if (deltaCap > epsilon || deltaStockZoneMargin > epsilon || params?.forceUpdateCap || params?.forceUpdateSlots) {
    const slotSample = stockZone.properties.slots[0].slots[0];

    if (!params?.newCoordinates) {
      coordinates = cloneDeep(coordinates);

      const center = centroid(turfPolygon(coordinates));
      const centerCoords = center.geometry.coordinates;

      const deltaAngle = cap - stockZone.properties.cap;

      coordinates = [rotatePolygon2(centerCoords, stockZone.geometry.coordinates[0], -deltaAngle)];
    }

    // we first recompute the position of the slots
    slots = CircuitService.generateStockZoneSlots(
      [...coordinates[0]],
      stockZone.properties.slotSize,
      stockZone.properties.gap,
      stockZone.properties.length,
      stockZone.properties.width,
      stockZone.properties.palletTypes,
      slotSample.palletPosition,
      stockZone.properties.palletSize,
      slotSample.tolerancePosition,
      cap,
      stockZone.properties.slots,
      stockZone.properties.customGapSlots,
      stockZone.properties.customGapLines,
      name,
      {
        lineNameOnlyIfNotUserGenerated: true,
      }
    );

    // then we compute the border of the stockzones in order to recompute the coordinates of the stockzone
    let minX = 1 / 0;
    let maxX = -1 / 0;
    let minY = 1 / 0;
    let maxY = -1 / 0;
    for (let i = 0, slotLinesLastIndex = slots[0].slots.length - 1; i < slots.length; i++) {
      const firstSlotInLine = slots[i].slots[0];
      if (firstSlotInLine.slotPosition.x < minX) {
        minX = firstSlotInLine.slotPosition.x;
      }

      const lastSlotInLine = slots[i].slots[slotLinesLastIndex];
      const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + lastSlotInLine.slotSize.length * 100;
      if (maxXLastSlotInLine > maxX) {
        maxX = maxXLastSlotInLine;
      }
    }

    minY = -slots[0].slots[0].slotPosition.y;
    maxY = -slots[slots.length - 1].slots[0].slotPosition.y - slots[slots.length - 1].slots[0].slotSize.width * 100;

    const zonePosX = minX;
    const zonePosY = minY;

    const zoneWidth = Math.abs(maxY - minY) / 100;
    const zoneLength = Math.abs(maxX - minX) / 100 + (stockZoneMargin ?? 0);

    // we apply the rotation to the coordinates (rotation matrix)
    const theta = toRad(cap);
    coordinates = [
      [
        [zonePosX, zonePosY],
        [zonePosX + zoneLength * 100 * Math.cos(theta), zonePosY - zoneLength * 100 * Math.sin(theta)],
        [
          zonePosX + zoneLength * 100 * Math.cos(theta) - zoneWidth * 100 * Math.sin(theta),
          zonePosY - zoneLength * 100 * Math.sin(theta) - zoneWidth * 100 * Math.cos(theta),
        ],
        [zonePosX - Math.sin(theta) * zoneWidth * 100, zonePosY - Math.cos(theta) * zoneWidth * 100],
        [zonePosX, zonePosY],
      ],
    ];

    slots = CircuitService.computeSlotsGeometry(slots, cap);
  }

  const action = saveStockZoneAction({
    id: stockZone.id,
    isEditing: undefined,
    properties: {
      ...stockZone.properties,
      name,
      cap: !isNaN(cap) ? cap : stockZone.properties.cap,
      extendedLength: !isNaN(extendedLength) ? extendedLength : stockZone.properties.extendedLength,
      slots,
      stockZoneMargin,
    },
    geometry: {
      ...stockZone.geometry,
      coordinates,
    },
  });

  if (params?.doNotDispatchAndReturn) {
    return action;
  }

  store.dispatch(action);
}
