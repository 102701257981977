import { TextField } from '@mui/material';
import type { BufferTrigger, IntervalTrigger } from 'models/simulation';
import { theme } from 'utils/mui-theme';

interface PriorityTextfieldProps {
  trigger: IntervalTrigger | BufferTrigger;
  handleUpdateTrigger: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}
export function TriggerPriorityTextfield(props: PriorityTextfieldProps): JSX.Element {
  const { trigger, handleUpdateTrigger, inputRef } = props;

  return (
    <TextField
      label="Priority"
      type="number"
      inputProps={{
        step: 1,
      }}
      defaultValue={trigger.priority ?? 1}
      onBlur={handleUpdateTrigger}
      inputRef={inputRef}
      sx={{
        marginTop: theme.spacing(1),
      }}
      fullWidth
      size="small"
      variant="outlined"
    />
  );
}
