import { Visibility, VisibilityOff } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import DnsIcon from '@mui/icons-material/Dns';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import LanIcon from '@mui/icons-material/Lan';
import RouterIcon from '@mui/icons-material/Router';
import SettingsIcon from '@mui/icons-material/Settings';
import WifiChannelIcon from '@mui/icons-material/WifiChannel';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import type { SelectChangeEvent } from '@mui/material';
import {
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { CollapseMore } from 'components/utils/collapse-more';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { delayStopEditBlur } from './constants';

export interface WifiConfigProps {
  projectOpened: boolean;
}

export function WifiConfig({ projectOpened }: WifiConfigProps): JSX.Element {
  const [wifiConfig, setWifiConfig] = useState('');

  const [editWifiConfig, setEditWifiConfig] = useState(false);

  // all Wi-Fi configuration preference
  type WifiConfigMode = 'WEP' | 'OPEN' | 'ADHOC' | 'WPA-PSK' | 'WPA-EAP' | 'WPA-TLS';
  const wifiPrefValue = 'communication/wifi/encryption';
  interface AvailableWifiConfigMode {
    value: WifiConfigMode;
    label: string;
  }

  const availableWifiConfigModes: AvailableWifiConfigMode[] = useMemo(
    () => [
      {
        value: 'WEP',
        label: 'WEP',
      },
      {
        value: 'OPEN',
        label: 'OPEN',
      },
      {
        value: 'ADHOC',
        label: 'ADHOC',
      },
      {
        value: 'WPA-PSK',
        label: 'WPA-PSK',
      },
      {
        value: 'WPA-EAP',
        label: 'WPA-EAP',
      },
      {
        value: 'WPA-TLS',
        label: 'WPA-TLS',
      },
    ],
    []
  );

  const prettifyWifiConfigMode = useCallback(
    (modeValue: string): string => {
      const mode = availableWifiConfigModes.find((mode) => mode.value === modeValue);

      return mode ? mode.label : modeValue;
    },
    [availableWifiConfigModes]
  );

  const fancyWifiConfigMode = useMemo(() => {
    return wifiConfig ? prettifyWifiConfigMode(wifiConfig) : wifiConfig;
  }, [wifiConfig, prettifyWifiConfigMode]);

  useLayoutEffect(() => {
    try {
      setWifiConfig(PreferencesService.getPreferenceValue(wifiPrefValue) as string);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${wifiPrefValue} from wifi encryption preferences`, e);
    }
  }, [projectOpened]);

  const handleUpdateWifiConfig = useCallback(async (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value;
    const wifiPrefValue = 'communication/wifi/encryption';

    const [ok] = await PreferencesService.setPreferenceValue(wifiPrefValue, newValue, true);

    if (ok) {
      setWifiConfig(newValue);

      SnackbarUtils.success(`Wi-Fi encryption changed to (${newValue})`);
    } else {
      SnackbarUtils.error(`Could not update Wi-Fi encryption`);
    }
  }, []);
  const refInputNetworkName = useRef<HTMLInputElement | null>(null);
  const [editNetworkName, setEditNetworkName] = useState(false);
  const defineNetworkName = useRef<string | null>(null);

  const networkName = useMemo(() => {
    const networkPrefName = 'communication/wifi/SSID';
    if (defineNetworkName.current) return defineNetworkName.current;
    if (!projectOpened) return;

    try {
      return PreferencesService.getPreferenceValue(networkPrefName) as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/SSID from preferences`, e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defineNetworkName.current, projectOpened]);

  const updateNetworkName = useCallback(async (newNetName: string): Promise<void> => {
    const networkPrefName = 'communication/wifi/SSID';
    const [ok] = await PreferencesService.setPreferenceValue(networkPrefName, newNetName, true);

    if (ok) {
      SnackbarUtils.success(`NetworkName updated to ${newNetName}`);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      defineNetworkName.current = newNetName;
      setEditNetworkName(false);
    } else {
      SnackbarUtils.error(`Could not update NetworkName`);
    }
  }, []);

  const refInputNetworkPassword = useRef<HTMLInputElement | null>(null);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const [editNetworkPassword, setEditNetworkPassword] = useState(false);
  const defineNetworkPassword = useRef<string | null>(null);

  const networkPassword = useMemo(() => {
    const networkPrefPassword = 'communication/wifi/key';
    if (defineNetworkPassword.current) return defineNetworkPassword.current;
    if (!projectOpened) return;

    try {
      return PreferencesService.getPreferenceValue(networkPrefPassword) as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/Key from preferences`, e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defineNetworkPassword.current, projectOpened]);

  const updateNetworkPassword = useCallback(async (newNetPassword: string): Promise<void> => {
    const networkPrefPassword = 'communication/wifi/key';
    const [ok] = await PreferencesService.setPreferenceValue(networkPrefPassword, newNetPassword, true);

    if (ok) {
      SnackbarUtils.success(`Network Password updated to ${newNetPassword}`);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      defineNetworkPassword.current = newNetPassword;
      setEditNetworkPassword(false);
    } else {
      SnackbarUtils.error(`Could not update Network Password`);
    }
  }, []);

  const [wifiFrequencies, setWifiFrequencies] = useState('All');

  const [editWifiFrequencies, setEditWifiFrequencies] = useState(false);

  type WifiFrequenciesMode = '2.4' | '5' | 'All';

  interface AvailableWifiFrequenciesMode {
    value: WifiFrequenciesMode;
    label: string;
  }

  const availableWifiFrequenciesModes: AvailableWifiFrequenciesMode[] = useMemo(
    () => [
      {
        value: '2.4',
        label: '2.4 GHz',
      },
      {
        value: '5',
        label: '5 GHz',
      },
      {
        value: 'All',
        label: 'All',
      },
    ],
    []
  );

  const prettifyWifiFrequenciesMode = useCallback(
    (modeValue: string): string => {
      const mode = availableWifiFrequenciesModes.find((mode) => mode.value === modeValue);

      return mode ? mode.label : modeValue;
    },
    [availableWifiFrequenciesModes]
  );

  const fancyWifiFrequenciesMode = useMemo(() => {
    return wifiFrequencies ? prettifyWifiFrequenciesMode(wifiFrequencies) : wifiFrequencies;
  }, [wifiFrequencies, prettifyWifiFrequenciesMode]);

  useLayoutEffect(() => {
    if (!projectOpened) return;

    const wifiFrequenciesPrefValue = 'communication/wifi/frequencies';
    try {
      setWifiFrequencies(PreferencesService.getPreferenceValue(wifiFrequenciesPrefValue) as string);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Could not get ${wifiFrequenciesPrefValue} from wifi frequencies preferences`, e);
    }
  }, [projectOpened]);

  const handleUpdateWifiFrequencies = useCallback(async (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value;
    const wifiFrequenciesPrefValue = 'communication/wifi/frequencies';

    const [ok] = await PreferencesService.setPreferenceValue(wifiFrequenciesPrefValue, newValue, true);

    if (ok) {
      setWifiFrequencies(newValue);

      SnackbarUtils.success(`Wi-Fi frequencies changed to (${newValue})`);
    } else {
      SnackbarUtils.error(`Could not update Wi-Fi frequencies`);
    }
  }, []);

  const defineNewGatewayIpAddress = useRef<string | null>(null);
  const gatewayIpAddress = useMemo(() => {
    if (defineNewGatewayIpAddress.current) return defineNewGatewayIpAddress.current;
    if (!projectOpened) return;

    try {
      return PreferencesService.getPreferenceValue('communication/wifi/gateway') as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get gateway from preferences`, e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defineNewGatewayIpAddress.current, projectOpened]);
  const [editGatewayIpAddress, setEditGatewayIpAddress] = useState(false);
  const refInputGatewayIpAddress = useRef<HTMLInputElement | null>(null);

  const updateGatewayIpAddress = useCallback(async (newIP: string): Promise<void> => {
    const isIPv4 = (await import('is-ip')).isIPv4;
    if (isIPv4(newIP)) {
      const [ok] = await PreferencesService.setPreferenceValue('communication/wifi/gateway', newIP, true);

      if (ok) {
        SnackbarUtils.success(`Gateway updated to ${newIP}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        defineNewGatewayIpAddress.current = newIP;
        setEditGatewayIpAddress(false);
      } else {
        SnackbarUtils.error(`Could not update gateway`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`${newIP} is not a valid IP address`);

      SnackbarUtils.error(`${newIP} is not a valid IP address`);
    }
  }, []);

  const defineNewMaskIpAddress = useRef<string | null>(null);
  const maskIpAddress = useMemo(() => {
    if (defineNewMaskIpAddress.current) return defineNewMaskIpAddress.current;
    if (!projectOpened) return;

    try {
      return PreferencesService.getPreferenceValue('communication/wifi/mask') as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get mask from preferences`, e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defineNewMaskIpAddress.current, projectOpened]);
  const [editMaskIpAddress, setEditMaskIpAddress] = useState(false);
  const refInputMaskIpAddress = useRef<HTMLInputElement | null>(null);

  const updateMaskIpAddress = useCallback(async (newIP: string): Promise<void> => {
    const isIPv4 = (await import('is-ip')).isIPv4;
    if (isIPv4(newIP)) {
      const [ok] = await PreferencesService.setPreferenceValue('communication/wifi/mask', newIP, true);

      if (ok) {
        SnackbarUtils.success(`Mask updated to ${newIP}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        defineNewMaskIpAddress.current = newIP;
        setEditMaskIpAddress(false);
      } else {
        SnackbarUtils.error(`Could not update Mask IP address`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`${newIP} is not a valid IP address`);

      SnackbarUtils.error(`${newIP} is not a valid IP address`);
    }
  }, []);

  return (
    <>
      <CollapseMore
        title={
          <>
            <SettingsIcon
              sx={{ marginRight: theme.spacing(1), color: 'rgba(0, 0, 0, 0.54)', verticalAlign: 'middle' }}
            />
            Wi-Fi Configuration Preferences
          </>
        }
      >
        {wifiConfig && (
          <ListItem>
            <ListItemIcon>
              <RouterIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {editWifiConfig ? (
                    <Select
                      value={wifiConfig || ''}
                      size="small"
                      autoWidth
                      renderValue={(value) => prettifyWifiConfigMode(value)}
                      onChange={handleUpdateWifiConfig}
                      onClose={(e) => setTimeout(() => setEditWifiConfig(false), delayStopEditBlur)}
                    >
                      {availableWifiConfigModes.map((mode) => (
                        <MenuItem value={mode.value} key={mode.value} selected={mode.value === wifiConfig}>
                          <ListItemText primary={mode.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <>
                      {fancyWifiConfigMode}
                      {!editWifiConfig ? (
                        <IconButton
                          onClick={() => {
                            setEditWifiConfig(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      ) : undefined}
                    </>
                  )}
                </>
              }
              secondary="Network authentication protocol"
            />
          </ListItem>
        )}
        {networkName && (
          <ListItem>
            <ListItemIcon>
              <LanIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {editNetworkName ? (
                    <TextField
                      defaultValue={networkName}
                      onBlur={() => setTimeout(() => setEditNetworkName(false), delayStopEditBlur)}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setEditNetworkName(false)}>
                              <ClearIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (refInputNetworkName.current) updateNetworkName(refInputNetworkName.current.value);
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={refInputNetworkName}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (refInputNetworkName.current) updateNetworkName(refInputNetworkName.current.value);
                        }
                      }}
                    />
                  ) : (
                    <>
                      {networkName}
                      {!editNetworkName ? (
                        <IconButton
                          onClick={() => {
                            setEditNetworkName(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      ) : undefined}
                    </>
                  )}
                </>
              }
              secondary="Network name"
            />
          </ListItem>
        )}
        {networkPassword && (
          <ListItem>
            <ListItemIcon>
              <WifiPasswordIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {editNetworkPassword ? (
                    <TextField
                      defaultValue={networkPassword}
                      type={showPassword ? 'password' : 'text'}
                      onBlur={() => setTimeout(() => setEditNetworkPassword(false), delayStopEditBlur)}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setEditNetworkPassword(false)}>
                              <ClearIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (refInputNetworkPassword.current)
                                  updateNetworkPassword(refInputNetworkPassword.current.value);
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={refInputNetworkPassword}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (refInputNetworkPassword.current)
                            updateNetworkPassword(refInputNetworkPassword.current.value);
                        }
                      }}
                    />
                  ) : (
                    <>
                      ************
                      {!editNetworkPassword ? (
                        <IconButton
                          onClick={() => {
                            setEditNetworkPassword(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      ) : undefined}
                    </>
                  )}
                </>
              }
              secondary="Network password"
            />
          </ListItem>
        )}
        {wifiFrequencies && (
          <ListItem>
            <ListItemIcon>
              <WifiChannelIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {editWifiFrequencies ? (
                    <Select
                      value={wifiFrequencies || ''}
                      size="small"
                      autoWidth
                      renderValue={(value) => prettifyWifiFrequenciesMode(value)}
                      onChange={handleUpdateWifiFrequencies}
                      onClose={(e) => setTimeout(() => setEditWifiFrequencies(false), delayStopEditBlur)}
                    >
                      {availableWifiFrequenciesModes.map((mode) => (
                        <MenuItem value={mode.value} key={mode.value} selected={mode.value === wifiFrequencies}>
                          <ListItemText primary={mode.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <>
                      {fancyWifiFrequenciesMode}
                      {!editWifiFrequencies ? (
                        <IconButton
                          onClick={() => {
                            setEditWifiFrequencies(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      ) : undefined}
                    </>
                  )}
                </>
              }
              secondary="Network Frequencies"
            />
          </ListItem>
        )}
        {gatewayIpAddress && (
          <ListItem>
            <ListItemIcon>
              <DnsIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {editGatewayIpAddress ? (
                    <TextField
                      defaultValue={gatewayIpAddress}
                      onBlur={() => setTimeout(() => setEditGatewayIpAddress(false), delayStopEditBlur)}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setEditGatewayIpAddress(false)}>
                              <ClearIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (refInputGatewayIpAddress.current)
                                  updateGatewayIpAddress(refInputGatewayIpAddress.current.value);
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={refInputGatewayIpAddress}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (refInputGatewayIpAddress.current)
                            updateGatewayIpAddress(refInputGatewayIpAddress.current.value);
                        }
                      }}
                    />
                  ) : (
                    <>
                      {gatewayIpAddress}
                      {!editGatewayIpAddress ? (
                        <IconButton
                          onClick={() => {
                            setEditGatewayIpAddress(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      ) : undefined}
                    </>
                  )}
                </>
              }
              secondary="Gateway IP address"
            />
          </ListItem>
        )}
        {maskIpAddress && (
          <ListItem>
            <ListItemIcon>
              <DnsIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {editMaskIpAddress ? (
                    <TextField
                      defaultValue={maskIpAddress}
                      onBlur={() => setTimeout(() => setEditMaskIpAddress(false), delayStopEditBlur)}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setEditMaskIpAddress(false)}>
                              <ClearIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (refInputMaskIpAddress.current)
                                  updateMaskIpAddress(refInputMaskIpAddress.current.value);
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={refInputMaskIpAddress}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (refInputMaskIpAddress.current) updateMaskIpAddress(refInputMaskIpAddress.current.value);
                        }
                      }}
                    />
                  ) : (
                    <>
                      {maskIpAddress}
                      {!editMaskIpAddress ? (
                        <IconButton
                          onClick={() => {
                            setEditMaskIpAddress(true);
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: '1rem',
                            }}
                          />
                        </IconButton>
                      ) : undefined}
                    </>
                  )}
                </>
              }
              secondary="Mask IP address"
            />
          </ListItem>
        )}
        <Divider />
      </CollapseMore>
    </>
  );
}
