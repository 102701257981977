import type { SelectChangeEvent } from '@mui/material';
import {
  Collapse,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { GabaritFilterWarning } from 'components/layers/gabarit-select-layer';
import type { GabaritProperties } from 'models/circuit';
import type { ChangeEvent, MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import store from 'store';
import { theme } from 'utils/mui-theme';
import { PreferencesService, getPreferenceValue } from 'utils/preferences';

export function searchAvailableGabarits(
  gabarit: GabaritProperties | undefined,
  modelsName: string[]
): {
  prefName: string;
  displayName: string;
}[] {
  const trucks = PreferencesService.getTrucks();
  const selectedLayer = store.getState().circuit.present.layers.selectedLayer;
  const layer = store.getState().circuit.present.layers.layers[selectedLayer];
  const layerDefaultModelName = layer.defaultModel;
  const layerDefaultPattern = layer.defaultPattern;

  if (!gabarit) {
    gabarit = {
      display: false,
      modelName: layerDefaultModelName ? layerDefaultModelName : modelsName.length ? modelsName[0] : '',
      type: layerDefaultPattern ? layerDefaultPattern : 'general/shape',
    };
  }

  if (!gabarit) return [];
  const modelName = gabarit.modelName;

  if (!modelName) return [];
  const truckOfThisModel = trucks.find((truck) => truck.modelName === modelName);
  if (!truckOfThisModel) return [];
  const serialOfThisModel = truckOfThisModel.serial;

  const shapesForwardSafetyGen2 = PreferencesService.getPreferenceNamesInCategory(
    'safetyGen2/forward',
    serialOfThisModel
  )
    .filter((prefName) => prefName.includes('shape'))
    .map((prefName) => {
      return { prefName: `safetyGen2/forward/${prefName}`, displayName: '' };
    });
  const shapesBackwardSafetyGen2 = PreferencesService.getPreferenceNamesInCategory(
    'safetyGen2/backward',
    serialOfThisModel
  )
    .filter((prefName) => prefName.includes('shape'))
    .map((prefName) => {
      return { prefName: `safetyGen2/backward/${prefName}`, displayName: '' };
    });

  const allGabaritsAvailable = [
    ...shapesForwardSafetyGen2,
    ...shapesBackwardSafetyGen2,

    { prefName: 'general/shape', displayName: 'Shape' },
    { prefName: 'cantonnement/pattern', displayName: 'Traffic' },
    { prefName: 'traffic/pattern', displayName: 'Traffic' },
    { prefName: 'safety/pattern/Fempty', displayName: 'Fempty' },
    { prefName: 'safety/pattern/Fcarry', displayName: 'Fcarry' },
    { prefName: 'filters/dockingPattern', displayName: 'Docking' },
  ]
    .filter((gabarit) => {
      try {
        getPreferenceValue(gabarit.prefName, serialOfThisModel);
      } catch (e) {
        return false;
      }

      return true;
    })
    .map((gabarit) => {
      if (gabarit.prefName.includes('safetyGen2')) {
        const splittedName = gabarit.prefName.split('/');
        const direction = splittedName[1];
        const shapeNumber = splittedName[2].replace('shape', '');
        const speedStr = getPreferenceValue(
          `safetyGen2/${direction}/speedInterval${shapeNumber}`,
          serialOfThisModel
        ) as string;
        const speed = speedStr.split('(')[1].replace(')', '').split(',');

        gabarit.displayName = `${direction.charAt(0).toUpperCase()}${direction.slice(1)} / [${speed[0]} ; ${
          speed[1]
        }[ m/s`;
      }

      return gabarit;
    });

  /** We add the  */
  const shapeGabaritAvailable = !!allGabaritsAvailable.find(
    (availableGabarit) => availableGabarit.prefName === 'general/shape'
  );
  if (shapeGabaritAvailable) {
    allGabaritsAvailable.push({
      prefName: 'offset+0.5m_general/shape',
      displayName: 'Shape + 0.5m (ISO 3691-4)',
    });
  }

  return allGabaritsAvailable;
}

interface GabaritSelectProps {
  gabarit?: GabaritProperties;
  handleChangeGabarit?: (newGabarit: GabaritProperties) => void;
  disabled?: boolean;
}
export function GabaritSelect({ gabarit, handleChangeGabarit, disabled }: GabaritSelectProps): JSX.Element {
  const modelsName = useMemo(() => PreferencesService.getModelNames(), []);
  const gabaritFilter = store.getState().local.filters.gabarit;

  /**
   * We try to get all the gabarits available for the robot model
   * They are:
   * - safety soft gen 2 gabarits, they define the maximum speed of the robot
   * - safety soft gen 1 gabarits
   * - traffic gabarits
   * - other gabarits
   *
   * For every gabarit, we try to see if it is defined for the robot model
   * If it is, we add it to the list of available gabarits
   */
  const availableGabarits = useMemo(() => searchAvailableGabarits(gabarit, modelsName), [gabarit, modelsName]);

  const handleGabaritModelNameChange = useCallback(
    (e: SelectChangeEvent<string>): void => {
      const display = !!gabarit?.display;
      const modelName = (e.target as HTMLSelectElement).value;
      const gabaritName = gabarit?.type ?? 'no name';
      const newGabarit: GabaritProperties = {
        display,
        modelName,
        type: gabaritName,
      };

      if (handleChangeGabarit) {
        handleChangeGabarit(newGabarit);
      }
    },
    [gabarit?.display, gabarit?.type, handleChangeGabarit]
  );

  const handleGabaritTypeChange = useCallback(
    (e: SelectChangeEvent<string>): void => {
      const display = !!gabarit?.display;
      const modelName = gabarit?.modelName ?? 'no name';
      const gabaritName = (e.target as HTMLSelectElement).value;

      const newGabarit: GabaritProperties = {
        display,
        modelName,
        type: gabaritName,
      };

      if (handleChangeGabarit) {
        handleChangeGabarit(newGabarit);
      }
    },
    [gabarit?.display, gabarit?.modelName, handleChangeGabarit]
  );

  const handleGabaritDisplayChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void => {
      const display = !gabarit?.display;

      const modelName = gabarit?.modelName;
      if (!modelName) throw new Error('No model name');
      const gabaritName = gabarit?.type;
      if (!gabaritName) throw new Error('No gabarit name');
      const newGabarit: GabaritProperties = {
        display,
        modelName,
        type: gabaritName,
      };

      if (handleChangeGabarit) {
        handleChangeGabarit(newGabarit);
      }
    },
    [gabarit, handleChangeGabarit]
  );

  return (
    <List
      component="nav"
      sx={{
        width: '100%',
        color: disabled ? theme.palette.grey[300] : undefined,
        pointerEvents: disabled ? 'none' : undefined,
        padding: 0,
      }}
    >
      <ListItemButton
        onClick={(e) => {
          if (gabaritFilter) {
            handleGabaritDisplayChange(e);
          }
        }}
      >
        <ListItemText id="switch-list-label-gabarit" primary="Show gabarit" />
        {!gabaritFilter && <GabaritFilterWarning />}
        <Switch
          id="turn-gabarit-display"
          edge="end"
          onChange={handleGabaritDisplayChange}
          checked={gabarit?.display}
          inputProps={{ 'aria-labelledby': 'switch-list-label-gabarit' }}
          disabled={!gabaritFilter ? true : disabled}
          color="primary"
        />
      </ListItemButton>
      <Collapse in={gabarit?.display} timeout="auto">
        <List component="div" disablePadding>
          <ListItem sx={{ padding: 0 }}>
            <FormControl
              sx={{
                width: '100%',
                margin: theme.spacing(1),
                minWidth: 120,
              }}
            >
              <InputLabel id="turn-gabarit-select-model-label" sx={{ transform: 'scale(0.75)' }}>
                Robot Model
              </InputLabel>
              <Select
                labelId="turn-gabarit-select-model-label"
                label="Robot Model"
                id="turn-gabarit-select-model"
                value={modelsName.length ? gabarit?.modelName : ''}
                onChange={handleGabaritModelNameChange}
                fullWidth
                sx={{ marginLeft: 0, marginTop: 0, marginBottom: 0, width: '100%', minWidth: 120 }}
                variant="standard"
              >
                {modelsName.map((modelName) => (
                  <MenuItem key={modelName} value={modelName}>
                    {modelName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem sx={{ padding: 0 }}>
            <FormControl
              sx={{
                width: '100%',
                margin: theme.spacing(1),
                minWidth: 120,
              }}
            >
              <InputLabel id="turn-gabarit-select-gabarit-name-label" sx={{ transform: 'scale(0.75)' }}>
                Gabarit
              </InputLabel>
              <Select
                labelId="turn-gabarit-select-gabarit-name-label"
                label="Gabarit"
                id="turn-gabarit-select-gabarit-name"
                value={modelsName.length ? gabarit?.type : ''}
                onChange={handleGabaritTypeChange}
                fullWidth
                sx={{ marginLeft: 0, marginTop: 0, marginBottom: 0, marginRight: 0, width: '100%', minWidth: 120 }}
                variant="standard"
              >
                {availableGabarits.map((gabarit) => (
                  <MenuItem key={gabarit.prefName} value={gabarit.prefName}>
                    {gabarit.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
