import { updateTurnAction } from 'actions/circuit';
import store from 'store';
import { estimateRadiusOfTurn } from './estimate-radius-of-turn';

export async function computeAgainAllTurns(): Promise<void> {
  const turnsIds = store.getState().circuit.present.turns.ids;

  let i = 0;
  const waitEverySteps = 10;
  for (const turnId of turnsIds) {
    store.dispatch(
      updateTurnAction({
        idToUpdate: turnId,
      })
    );

    if (++i % waitEverySteps === 0) {
      // eslint-disable-next-line no-console
      console.log(`computeAgainAllTurns: ${i} turns computed (${Math.round((i / turnsIds.length) * 100)}%)`);

      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  // eslint-disable-next-line no-console
  console.log(`computeAgainAllTurns: ${turnsIds.length} turns computed (100%)`);
}

export function exportAllTurns(): void {
  const turns = store.getState().circuit.present.turns.entities;
  const turnsIds = store.getState().circuit.present.turns.ids;

  const header = 'id;layerId;startPointOffset;radius;stopBeforeTurn;originId;destinationId';

  const lines = turnsIds.map((turnId) => {
    const turn = turns[turnId];

    const radius = estimateRadiusOfTurn(turn) ?? turn.properties.radius;

    return `${turn.id};${turn.properties.layerId};${turn.properties.startPointOffset ?? ''};${radius};${
      turn.properties.turnType === 'StopBeforeTurn' ? 1 : 0
    };${turn.properties.originId};${turn.properties.destinationId}`;
  });

  const csv = [header, ...lines].join('\n');

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'turns.csv';
  a.click();
}
