import type { AppState } from 'reducers/state';
import { createSelector } from 'reselect';
import { getSelectedShapesData } from './reducer';
import type { LocalState } from './state';

function localSelector(state: AppState): LocalState {
  return state.local;
}

export const selectCurrentLocal = createSelector(localSelector, (local) => {
  return local;
});
export const selectSelectedShapeData = createSelector(localSelector, getSelectedShapesData);

export const selectFirstSelectedShapeDataId = createSelector(getSelectedShapesData, (selectedShapesData) => {
  return selectedShapesData[0]?.id;
});
export const selectFirstSelectedShapeDataType = createSelector(getSelectedShapesData, (selectedShapesData) => {
  return selectedShapesData[0]?.type;
});
export const selectHasManyShapesSelected = createSelector(getSelectedShapesData, (selectedShapesData) => {
  return selectedShapesData.length > 1;
});
