import type { CircuitShape } from 'models/circuit';
import { isCircuitSegment } from './shape-guards';

/**
 * Checks if a given shape is an extended length.
 * @param {CircuitShape} shape - The shape to check.
 * @returns {boolean} - Returns true if the shape is a circuit segment and is extended length (connected to a rack or a stockzone)
 */
export function checkIsExtendedLength(shape: CircuitShape): boolean {
  return !!(isCircuitSegment(shape) && (shape.properties.rack || shape.properties.stockLine));
}
