import type { PropsWithChildren } from 'react';
import React from 'react';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { StandardFade } from './standard-fade';

export function CircularLoadingOnPending({
  pending,
  children,
  fadeFromTop = false,
}: PropsWithChildren<{ pending?: boolean; fadeFromTop?: boolean }>): JSX.Element {
  return pending ? <LargeCirculaProgress /> : <StandardFade top={fadeFromTop}>{children}</StandardFade>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
  })
);

function LargeCirculaProgress(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
