import type { Position } from 'geojson';

/**
 * Compute the area of a polygon (list of points)
 * @param vertices the list of points of the polygon
 * @returns the area
 */
export function calcPolygonArea(vertices: Position[]): number {
  let total = 0;

  for (let i = 0, l = vertices.length; i < l; i++) {
    const addX = vertices[i][0];
    const addY = vertices[i === l - 1 ? 0 : i + 1][1];
    const subX = vertices[i === l - 1 ? 0 : i + 1][0];
    const subY = vertices[i][1];

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
}
