import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { type Csv } from './models';

interface CSVDataProps {
  CSVFile: Csv;
  setImportedCsv: React.Dispatch<React.SetStateAction<Csv[]>>;
}

export function CSVData(props: CSVDataProps): JSX.Element {
  const { CSVFile, setImportedCsv } = props;

  const deleteCSVFileById = (CSVFileId: string): void =>
    setImportedCsv((prev) => prev.filter((file) => file.id !== CSVFileId));

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="body2">
          {CSVFile.name}
        </Typography>
        <Typography sx={{ color: 'grey' }} variant="body2">
          {CSVFile.data.length !== 0 ? (
            <Typography sx={{ color: 'grey' }} variant="body2">
              {CSVFile.data.length} measures
            </Typography>
          ) : (
            <Typography sx={{ color: 'grey' }} variant="body2">
              No data found
            </Typography>
          )}
        </Typography>
      </Box>
      <IconButton onClick={() => deleteCSVFileById(CSVFile.id)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}
