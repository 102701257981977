export function truckNameToMarketingTruckName(truckName: string): string {
  let name = truckName;

  switch (truckName.toLowerCase().trim()) {
    case 'kmatic': {
      name = 'Veeny';
      break;
    }

    case 'lmaticac': {
      name = 'Lowy CB';
      break;
    }

    case 'lmaticLHP': {
      name = 'Lowy';
      break;
    }

    case 'pmatic': {
      name = 'Tuggy';
      break;
    }

    case 'rmatic': {
      name = 'Reachy';
      break;
    }

    case 'tmatic':
    case 'mpe080_long':
    case 'mpe080': {
      name = 'Trucky';
      break;
    }
  }

  return name;
}
