import type { CircuitRack } from 'models/circuit';
import store from 'store';
import { computeLoadsPosition } from 'utils/circuit/racks-compute-load-position';
import type {
  EdgeVDA5050,
  HandlingStation,
  HandlingStationAccessNode,
  HandlingStationGroupVDA5050,
} from './vda5050-model';

interface GenerateHandlingStationGroupFromRackProps {
  rack: CircuitRack;
  navigationGraphId: string;
  circuitEdges: EdgeVDA5050[];
}

interface GenerateHandlingStationGroupFromRackRes {
  handlingStationGroup: HandlingStationGroupVDA5050;
  nodesToRemove: Set<string>;
}

export function generateHandlingStationGroupFromRack(
  props: GenerateHandlingStationGroupFromRackProps
): GenerateHandlingStationGroupFromRackRes {
  const { rack, navigationGraphId, circuitEdges } = props;

  const handlingStations: HandlingStation[] = [];
  const cellTemplates = store.getState().circuit.present.cellTemplates.entities;
  const segments = store.getState().circuit.present.segments.entities;

  const nodesToRemove = new Set<string>();

  rack.properties.columns.forEach((column) => {
    let mapZ = column.startHeight;

    column.cells.forEach((cell, cellIndex) => {
      if (!cell.cellTemplate) {
        // eslint-disable-next-line no-console
        console.warn(`Cell ${cell.id} has no cellTemplate, cell not exported.`);

        return;
      }

      const cellTemplate = cellTemplates[cell.cellTemplate];

      const previousCell = cellIndex >= 1 ? column.cells[cellIndex - 1] : null;
      const nextCell = cellIndex < column.cells.length - 1 ? column.cells[cellIndex + 1] : null;
      mapZ += previousCell ? previousCell.height : 0;

      const nextMapZ = nextCell ? mapZ + nextCell.height : 0;
      const deltaMapZ = nextMapZ - mapZ;

      const availableHeight = deltaMapZ - cell.beamThickness;

      cell.names.forEach((slots, loadsIndex) => {
        const load = cellTemplate.loads[loadsIndex];
        if (!load) {
          // eslint-disable-next-line no-console
          console.warn(`Cell ${cell.id} has no load at index ${loadsIndex}, cell not exported.`);

          return;
        }

        const slotsXInRackFrameOfReference = computeLoadsPosition(load, column.width)
          // we convert the position in the column frame of reference to the rack frame of refence + convert the left position of the load to its center
          .map((x) => column.x + x + load.W / 2);

        const loadWidth = load.W;
        slots.forEach((slot, slotIndex) => {
          const posXSlot = slotsXInRackFrameOfReference[slotIndex];
          const [extendedLengthId] = column.extendedLengthSegments.find(([id, posX]) => posX === posXSlot) || [null];
          if (!extendedLengthId) {
            // eslint-disable-next-line no-console
            console.error(`No extended length segment found for slot ${slot.id} in rack ${rack.id}`);

            return;
          }

          const extendedLengthSegment = segments[extendedLengthId];
          if (!extendedLengthSegment) {
            // eslint-disable-next-line no-console
            console.error(`Segment id ${extendedLengthId} not found`);

            return;
          }

          const extendedLengthPortion = extendedLengthSegment.properties.portions[0];
          if (!extendedLengthPortion) {
            // eslint-disable-next-line no-console
            console.error(`No portion found for segment ${extendedLengthId}`);

            return;
          }

          const mapX = extendedLengthSegment.geometry.coordinates[0][0] / 100;
          const mapY = extendedLengthSegment.geometry.coordinates[0][1] / 100;

          const associatedEdge = circuitEdges.find((edge) => edge.id === extendedLengthPortion.id);
          const startNodeId = associatedEdge?.startNodeId;
          const endNodeId = associatedEdge?.endNodeId;

          if (!endNodeId) {
            // eslint-disable-next-line no-console
            console.error(`No start node found for segment ${extendedLengthId}`);

            return;
          }

          if (startNodeId) nodesToRemove.add(startNodeId);

          const accessNode: HandlingStationAccessNode = {
            navigationGraphId,
            nodeId: endNodeId,
          };

          const newHandlingStation: HandlingStation = {
            id: slot.value,
            width: loadWidth,
            height: availableHeight,
            length: rack.properties.depth,
            mapX,
            mapY,
            mapZ,
            accessNodes: [accessNode],
          };

          handlingStations.push(newHandlingStation);
        });
      });
    });
  });

  const handlingStationGroup: HandlingStationGroupVDA5050 = {
    id: rack.id as string,
    controlled: false,
    justInSequence: false,
    waitingSpots: [],
    stationAutomaticSelectionMode: 'ALWAYS',
    handlingStations,
  };

  return {
    handlingStationGroup,
    nodesToRemove,
  };
}
