import { Box } from '@mui/system';
import { theme } from 'utils/mui-theme';

interface BorderProps {
  children: React.ReactNode;
  side?: 'left' | 'right';
  sx?: React.CSSProperties;
}
export function Border({ children, side = 'left', sx }: BorderProps): JSX.Element {
  const border = `2px solid ${theme.palette.grey[200]}`;
  const spacing = theme.spacing(2);

  const isLeft = side === 'left';
  const isRight = side === 'right';

  return (
    <Box
      component="div"
      sx={{
        paddingLeft: isLeft ? spacing : undefined,
        borderLeft: isLeft ? border : undefined,

        paddingRight: isRight ? spacing : undefined,
        borderRight: isRight ? border : undefined,

        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
