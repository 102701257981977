import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import SaveIcon from '@mui/icons-material/Save';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { closeDialogAction } from 'actions';
import { useCallback, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { setRobotsInitialBatteryLevel } from 'simulation/simulation';
import { useAppDispatch, useAppSelector } from 'store';
import { getRandomIntInclusive } from 'utils/helpers';
import type { RobotEmulation } from './use-set-robots';

interface SettingsDialogProps {
  robotsList: RobotEmulation[];
}

export function UpdateBatteryDialog({ robotsList }: SettingsDialogProps): JSX.Element {
  const dispatch = useAppDispatch();

  const robots = robotsList;

  const robotsBatterySimulation = useAppSelector((state) => state.simulation.robotsBatteryLevel);

  const robotsToUpdate = robots.map((robotToUpdate) => {
    const findRobotToUpdate = robotsBatterySimulation.find((robot) => robot.id === robotToUpdate.ID.toString());

    return {
      id: robotToUpdate.ID.toString(),
      batteryLevel: findRobotToUpdate ? findRobotToUpdate.batteryLevel : 100,
    };
  });

  const [robotsBattery, setRobotsBattery] = useState(robotsToUpdate);

  const handleChangeBatteryLevel = useCallback((robotId: string, newBatteryLevel: number): void => {
    if (newBatteryLevel > 100) {
      newBatteryLevel = 100;
    }

    if (newBatteryLevel < 0) {
      newBatteryLevel = 0;
    }

    if (isNaN(newBatteryLevel)) {
      newBatteryLevel = 0;
    }

    setRobotsBattery((prev) =>
      prev.map((robot) => {
        if (robot.id !== robotId) {
          return robot;
        }

        return {
          ...robot,
          batteryLevel: newBatteryLevel,
        };
      })
    );
  }, []);

  const handleRandomBatteryLevel = useCallback((): void => {
    setRobotsBattery((prev) =>
      prev.map((robot) => {
        const randomBatteryLevel = getRandomIntInclusive(20, 100);

        return {
          ...robot,
          batteryLevel: randomBatteryLevel,
        };
      })
    );
  }, []);

  const handleReset = useCallback((): void => {
    setRobotsBattery((prev) =>
      prev.map((robot) => {
        return {
          ...robot,
          batteryLevel: 100,
        };
      })
    );
  }, []);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleSave = useCallback((): void => {
    dispatch(setRobotsInitialBatteryLevel({ robots: robotsBattery }));

    SnackbarUtils.success(`Battery level updated`);
    handleClose();
  }, [dispatch, handleClose, robotsBattery]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0 }}>Battery simulation configuration</DialogTitle>
      <DialogContent sx={{ marginTop: 5 }}>
        <List>
          <ListItem secondaryAction={<ListItemText>Initial battery level</ListItemText>}></ListItem>
          {robots.map((robot) => (
            <RobotList
              key={robot.serial}
              robot={robot}
              robotsBattery={robotsBattery}
              handleChangeBatteryLevel={handleChangeBatteryLevel}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" endIcon={<BatteryUnknownIcon />} onClick={handleRandomBatteryLevel}>
          Random battery level
        </Button>
        <Button variant="outlined" endIcon={<BatteryFullIcon />} onClick={handleReset}>
          Reset battery level
        </Button>
        <Button variant="outlined" endIcon={<SaveIcon />} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface RobotsListProps {
  robot: RobotEmulation;
  robotsBattery: {
    id: string;
    batteryLevel: number | undefined;
  }[];
  handleChangeBatteryLevel: (robotId: string, newBatteryLevel: number) => void;
}

function RobotList({ robot, robotsBattery, handleChangeBatteryLevel }: RobotsListProps): JSX.Element {
  const findRobotBatteryValue = (robotId: number): number | undefined => {
    return robotsBattery.find((robotBattery) => robotBattery.id === robotId.toString())?.batteryLevel;
  };

  const batteryLevel = findRobotBatteryValue(robot.ID);

  return (
    <ListItem>
      <ListItemIcon>
        <Avatar src={`/img/trucks/${robot.picturePath}-icon.png`} sx={{ width: 30, height: 30 }} />
      </ListItemIcon>
      <ListItemText primary={robot.name} secondary={robot.modelName} />
      <FormControl variant="standard" sx={{ width: 75 }}>
        <Input
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          size="small"
          value={batteryLevel}
          onChange={(e) => handleChangeBatteryLevel(robot.ID.toString(), Number(e.target.value))}
        />
      </FormControl>
    </ListItem>
  );
}
