import { addTurnAction, pointMovedAction, saveCircuitToHistoryAction, segmentMovedAction } from 'actions/circuit';
import { savePointAction } from 'actions/points';
import { saveSegmentAction } from 'actions/segment';
import { snackbarLockedSegmentExtended } from 'components/editor/snackbarLockedSegmentExtended';
import { snackbarUnsnappedPoints } from 'components/editor/snackbarUnsnappedPoints';
import * as d3 from 'd3';
import { LineString } from 'drawings/elements';
import {
  arrowsToString,
  computeArrowsFromLineString,
  findShapeOrientation,
  getClosestPointInSegment,
  offsetPositionLine,
} from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import { gabaritsWorkerProxy } from 'editor/gabarits.worker';
import type { LineString as LineStringGeoJSON, Position } from 'geojson';
import { snap90degLine } from 'librarycircuit/utils/geometry/snap-90-deg-line';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { cloneDeep, isEqual } from 'lodash';
import type { InterestPointProperties, SegmentProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { lineStrokeSize } from 'models/drawings';
import { Tools } from 'models/tools';
import { isShapeInSelectedShapes } from 'multiplayer/globals';
import { batch } from 'react-redux';
import type { LoadedPoint } from 'reducers/circuit/state';
import { CircuitService, setShapeJustMoved } from 'services/circuit.service';
import store from 'store';
import { getGabaritCoords } from 'utils/circuit/get-gabarit-coords';
import { isCircuitSegment } from 'utils/circuit/shape-guards';
import { epsilon } from 'utils/circuit/utils';
import { getConfig } from 'utils/config';

const defaultArrowLength = 10; // cm
const defaultArrowThickness = 7; // cm

const SEGMENT_STROKE_WIDTH = parseFloat(getConfig('editor').elementWidth.segmentStroke);
// eslint-disable-next-line no-console
if (isNaN(SEGMENT_STROKE_WIDTH)) console.error(`Invalid segment stroke width: ${SEGMENT_STROKE_WIDTH}`);

export const showPortionsEventName = 'show-portions';

enum StickingMethod {
  closest,
  positionFactor,
}

export interface SegmentDrawingProperties {
  strokeSize: lineStrokeSize;
  displayGabarit?: boolean;
}

export function segmentLineStrokeWidthToStrokeWidth(size: lineStrokeSize): number {
  switch (size) {
    case 's':
      return SEGMENT_STROKE_WIDTH;
    case 'm':
      return SEGMENT_STROKE_WIDTH * 2;
    case 'l':
      return SEGMENT_STROKE_WIDTH * 4;
    case 'xl':
      return SEGMENT_STROKE_WIDTH * 8;
    default:
      return SEGMENT_STROKE_WIDTH;
  }
}

export class Segment extends LineString {
  public properties: SegmentProperties;

  private lastClickX: number;
  private lastClickY: number;

  private dragging: boolean;

  private strokeWidth: number;

  private strokeSize: lineStrokeSize | undefined;

  private attachedTurnsObsolete: undefined | boolean;

  private computeAttachedTurnsIds(): string[] {
    const circuitState = store.getState().circuit.present;
    const turnsIds = circuitState.turns.ids;
    const turns = circuitState.turns.entities;
    const attachedTurnsIds = turnsIds.filter((turnId) => {
      const turn = turns[turnId];

      return turn.properties.originId === this.id || turn.properties.destinationId === this.id;
    });

    return attachedTurnsIds;
  }

  constructor(
    id: string,
    geometry: LineStringGeoJSON,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    properties: SegmentProperties,
    drawingProperties: SegmentDrawingProperties
  ) {
    super(id, ShapeTypes.SegmentShape, geometry, projection, zoomScale);
    this.properties = properties || ({ twoWay: false, type: ShapeTypes.SegmentShape, name: id } as SegmentProperties);
    this.strokeWidth = segmentLineStrokeWidthToStrokeWidth(drawingProperties.strokeSize);
    this.strokeSize = drawingProperties.strokeSize;

    this.lastClickX = NaN;
    this.lastClickY = NaN;
    this.setLocked(!!this.properties?.locked);
    this.dragging = false;
    this.node.attr('layer-id', properties?.layerId || '');
    this.node.select('[main-shape=true]').style('stroke-width', this.strokeWidth);

    this.showArrows(this.node);

    if (this.properties.rack) {
      this.node.attr('rack-id', this.properties.rack);
    }

    if (this.properties.rackColumn) {
      this.node.attr('rack-column-id', this.properties.rackColumn);
    }

    if (this.properties.wireGuided) {
      this.node.classed('wire-guided', true);
    }

    if (this.properties.stockLine) {
      this.node.attr('stockline-id', this.properties.stockLine);
    }

    const displayGabarit = drawingProperties?.displayGabarit !== false;
    if (this.properties && this.properties.gabarit && this.properties.gabarit.display && displayGabarit) {
      this.showGabarit();
    }

    // for performance reasons, we don't display the segment (just one arrow) if the segment is super small
    const segmentLength = getDistanceBetweenPoints(this.geometry.coordinates[0], this.geometry.coordinates[1]);
    if (segmentLength < epsilon) {
      this.node.select('[main-shape=true]').style('display', 'none');
    }

    const el = this.node.node();

    el?.addEventListener(showPortionsEventName, () => {
      this.showTrafficPortions(true);
    });

    this.showTrafficPortions();
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    const node = super
      .createNode()
      .on('fakeDrag', this.onDrag)
      .on('fakeDragEnd', this.onDragEnd)
      .on('endDraggingMode', () => {
        this.endDraggingMode();
      })
      .style('stroke-width', SEGMENT_STROKE_WIDTH);

    return node;
  }

  private showArrows(
    node: SimpleSelection<SVGGElement, undefined>,
    show = true
  ): SimpleSelection<SVGGElement, undefined> {
    if (!show) return node;

    let segmentAngle: number | undefined = undefined;
    const coords = this.geometry.coordinates;
    const props = this.properties;
    const segmentLength = getDistanceBetweenPoints(coords[0], coords[1]);

    if (segmentLength < epsilon && props && props.rack) {
      const rack = store.getState().circuit.present.racks.entities[props.rack];
      if (rack) {
        const rackAngle = rack.properties.cap;
        segmentAngle = rackAngle - 90;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Rack ${props.rack} of segment ${this.id} not found`);
      }
    }

    let arrowLength = defaultArrowLength;
    if (this.strokeSize === 'l') {
      arrowLength *= 2;
    } else if (this.strokeSize === 'xl') {
      arrowLength *= 4;
    }

    let arrowThickness = defaultArrowThickness;
    if (this.strokeSize === 'l') {
      arrowThickness *= 3;
    } else if (this.strokeSize === 'xl') {
      arrowThickness *= 6;
    }

    const arrows = arrowsToString(
      computeArrowsFromLineString(coords, undefined, arrowLength, arrowThickness, segmentAngle)
    );

    // we update the length of the main segment
    const dx = coords[1][0] - coords[0][0];
    const dy = coords[1][1] - coords[0][1];
    segmentAngle = Math.atan2(dy, dx);
    const endPointMainLine = [
      this.geometry.coordinates[1][0] - Math.cos(segmentAngle) * arrowLength,
      this.geometry.coordinates[1][1] - Math.sin(segmentAngle) * arrowLength,
    ];

    const geometryWithReducedLength = {
      ...this.geometry,
      coordinates: [this.geometry.coordinates[0], endPointMainLine],
    };

    node
      .data([geometryWithReducedLength])
      .select('[main-shape]')
      .attr('d', this.projection as any)
      .exit();

    const dragHandler = d3
      .drag<SVGGElement, undefined>()
      .on('drag', (e, d, n) => this.onDragArrow(e, d, n))
      .on('end', () => this.onDragArrowsEnd());

    node
      .append<SVGGElement>('svg:polygon')
      .attr('id', `arrow-start-${this.id}`)
      .classed('arrow-seg', true)
      .attr('points', arrows[0])
      .call(dragHandler)
      .exit();

    if (segmentLength > epsilon) {
      // if the segment is super small we display just one arrow
      node
        .append<SVGGElement>('svg:polygon')
        .attr('id', `arrow-end-${this.id}`)
        .classed('arrow-seg', true)
        .attr('points', arrows[1])
        .call(dragHandler)
        .exit();
    }

    return node;
  }

  protected onDragArrow(e: undefined, d: number, nodes: d3.ArrayLike<SVGGElement> | { id: string }[]): void {
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    const stateStore = store.getState();

    if (stateStore.tool.activeTool !== Tools.Move) return;

    if (!this.dragging) {
      store.dispatch(saveCircuitToHistoryAction());
      this.startDraggingMode();
      this.node.selectAll('.portion').remove();
    }

    const startArrow = nodes[0].id.split('-')[1] === 'start';
    const movingArrow = startArrow ? 0 : this.geometry.coordinates.length - 1; // if it is the start of the end arrow

    this.geometry = cloneDeep(this.geometry);
    const coordinates = this.geometry.coordinates;

    const deltaX = Math.abs(coordinates[0][0] - coordinates[1][0]);
    const deltaY = Math.abs(coordinates[0][1] - coordinates[1][1]);

    const snap90deg = stateStore.tool.snap90deg || false;
    const lockOrientation = stateStore.tool.lockOrientation || false;

    if (snap90deg) {
      if (deltaX >= epsilon && deltaY >= epsilon) {
        const notMovingArrow = !startArrow ? 0 : coordinates.length - 1;

        this.geometry.coordinates = snap90degLine(coordinates, notMovingArrow);
      }

      if (deltaX > deltaY) {
        coordinates[movingArrow][0] += event.dx;
      } else {
        coordinates[movingArrow][1] += -event.dy;
      }
    } else if (lockOrientation) {
      // when we snap to 90deg we don't need to lock the orientation given that it is already locked in a way
      // we compute the coordinates of the closest point on the line from the mouse position
      const [[newX, newY]] = getClosestPointInSegment([event.x, -event.y], coordinates[0], coordinates[1], false);

      coordinates[movingArrow][0] = newX;
      coordinates[movingArrow][1] = newY;
    } else {
      coordinates[movingArrow][0] += event.dx;
      coordinates[movingArrow][1] += -event.dy;
    }

    this.updateSnappedPoints(StickingMethod.closest);

    super.onDrag({
      isEndPoint: true,
    });
    this.updateArrows(this.node);

    this.node.select(`[id="arrow-${startArrow ? 'start' : 'end'}-${this.id}"]`).classed('dragging', true);

    this.makeAttachedTurnsObsolete();
  }

  private updateArrows(node: SimpleSelection<SVGGElement, undefined>): SimpleSelection<SVGGElement, undefined> {
    let segmentAngle: number | undefined = undefined;
    const coords = this.geometry.coordinates;
    const props = this.properties;
    const segmentLength = getDistanceBetweenPoints(coords[0], coords[1]);

    if (segmentLength < epsilon && props.rack) {
      const rack = store.getState().circuit.present.racks.entities[props.rack];
      if (rack) {
        const rackAngle = rack.properties.cap;
        segmentAngle = rackAngle - 90;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Rack ${props.rack} of segment ${this.id} not found`);
      }
    }

    let arrowLength = defaultArrowLength;
    if (this.strokeSize === 'l') {
      arrowLength *= 2;
    } else if (this.strokeSize === 'xl') {
      arrowLength *= 4;
    }

    let arrowThickness = defaultArrowThickness;
    if (this.strokeSize === 'l') {
      arrowThickness *= 3;
    } else if (this.strokeSize === 'xl') {
      arrowThickness *= 6;
    }

    const arrows = arrowsToString(
      computeArrowsFromLineString(coords, undefined, arrowLength, arrowThickness, segmentAngle)
    );

    // we update the length of the main segment
    const dx = coords[1][0] - coords[0][0];
    const dy = coords[1][1] - coords[0][1];
    segmentAngle = Math.atan2(dy, dx);
    const endPointMainLine = [
      this.geometry.coordinates[1][0] - Math.cos(segmentAngle) * arrowLength,
      this.geometry.coordinates[1][1] - Math.sin(segmentAngle) * arrowLength,
    ];

    const geometryWithReducedLength = {
      ...this.geometry,
      coordinates: [this.geometry.coordinates[0], endPointMainLine],
    };

    node
      .data([geometryWithReducedLength])
      .select('[main-shape]')
      .attr('d', this.projection as any)
      .exit();

    node.selectAll('polygon').attr('points', (point: any, index: number): string => {
      if (segmentLength < epsilon && index > 0) return ''; // if the segment is super small, we just display an unique arrow

      return index < 2 ? arrows[index] : ''; // if by any chance we have more than two points (i.e. arrows) we get rid of them
    });

    return node;
  }

  private onDragArrowsEnd(): void {
    this.dragging = false;

    const segmentMoved =
      this.geometry.coordinates !== store.getState().circuit.present.segments.entities[this.id].geometry.coordinates;
    if (this.locked || !segmentMoved || isShapeInSelectedShapes(this.id)) return;

    setShapeJustMoved(this.id);

    setTimeout(() => {
      this.saveSnappedPoints(segmentMoved);
      store.dispatch(
        segmentMovedAction({
          idSegment: this.id,
          coordinates: this.geometry.coordinates,
        })
      );
    }, 0);

    super.onDragEnd(this.properties);
  }

  /**
   * Update the position of the segment snapped points in the drawing
   * Does not save the position in the store
   * @param stickingMethod how we compute the new position of the points, if we try to keep the same position on the segment or find the closest point
   * @returns void
   */
  private updateSnappedPoints(stickingMethod: StickingMethod): void {
    const storeState = store.getState();
    const points = storeState.circuit.present.points.entities;
    const pointsIds = storeState.circuit.present.points.ids;

    const segCoords = this.geometry.coordinates;
    const start = segCoords[0];
    const end = segCoords[segCoords.length - 1];

    for (const pointId of pointsIds) {
      const point = points[pointId];

      if (
        point.properties &&
        point.properties.segment &&
        point.properties.segment.id &&
        point.properties.segment.id === this.id &&
        !point.properties.locked
      ) {
        const coords = point.geometry.coordinates;
        let translationX: number;
        let translationY: number;
        let newPositionFactor: number | undefined;
        let snappedCoords: Position;

        if (stickingMethod === StickingMethod.closest) {
          [snappedCoords, newPositionFactor] = getClosestPointInSegment(coords, segCoords[0], segCoords[1]);
          translationX = snappedCoords[0] - coords[0];
          translationY = -snappedCoords[1] + coords[1];
        } else if (stickingMethod === StickingMethod.positionFactor) {
          const positionOnSegment = point.properties.segment.position;
          const vectSegment = [end[0] - start[0], end[1] - start[1]];
          snappedCoords = [
            start[0] + vectSegment[0] * positionOnSegment,
            start[1] + vectSegment[1] * positionOnSegment,
          ];

          translationX = snappedCoords[0] - coords[0];
          translationY = -snappedCoords[1] + coords[1];
        } else {
          return;
        }

        const node = d3.select(`g[uuid='${point.id}']`);
        node.style('transform', `translate(${translationX}px, ${translationY}px)`);
        node.attr('data-newCoordX', snappedCoords[0]);
        node.attr('data-newCoordY', snappedCoords[1]);

        if (newPositionFactor !== undefined) node.attr('data-newPositionFactor', newPositionFactor);
      }
    }
  }

  /**
   * Save the position of the snapped points in the store
   */
  private saveSnappedPoints(hasSegmentMoved?: boolean): void {
    const storeState = store.getState();
    const points = storeState.circuit.present.points.entities;
    const pointsIds = storeState.circuit.present.points.ids;

    const start = this.geometry.coordinates[0];
    const end = this.geometry.coordinates[this.geometry.coordinates.length - 1];

    const unsnappedPoints: LoadedPoint[] = [];

    for (const pointId of pointsIds) {
      const point = points[pointId];

      if (
        point.properties &&
        point.properties.segment &&
        point.properties.segment.id &&
        point.properties.segment.id === this.id
      ) {
        if (point.properties.locked && hasSegmentMoved) {
          const properties = { ...point.properties };
          delete properties.segment;

          unsnappedPoints.push(point);

          store.dispatch(
            savePointAction({
              id: point.id,
              properties,
            })
          );

          continue;
        }

        const drawingShape = d3.select(`g[uuid='${point.id}']`);
        if (!drawingShape) return;

        const newPositionFactor = drawingShape.attr('data-newPositionFactor');
        const positionOnSegment = newPositionFactor ? parseFloat(newPositionFactor) : point.properties.segment.position;
        const snappedCoords = [
          parseFloat(drawingShape.attr('data-newCoordX')),
          parseFloat(drawingShape.attr('data-newCoordY')),
        ];
        const orientation = findShapeOrientation([start[0], start[1]], [end[0], end[1]]);

        if (isNaN(snappedCoords[0]) || isNaN(snappedCoords[1])) return;

        const properties: InterestPointProperties = {
          ...point.properties,
          orientation,
          segment: { ...point.properties.segment, position: positionOnSegment },
        };
        const geometry = { ...point.geometry, coordinates: snappedCoords };

        batch(() => {
          store.dispatch(
            savePointAction({
              id: point.id,
              geometry,
              properties,
            })
          );

          store.dispatch(
            pointMovedAction({
              id: point.id as string,
            })
          );
        });
      }
    }

    if (unsnappedPoints.length > 0) {
      snackbarUnsnappedPoints(unsnappedPoints);
    }
  }

  protected onDrag(): void {
    const event: D3DragEventAugmented = d3.event as D3DragEventAugmented;

    const activeTool = store.getState().tool.activeTool;

    // is the segment draggeable? locked segments are not draggeable, and if we have tool addturn or drawsegmentorturn tool, we can create a turn from it, in this very last case the dragging is stopped later in the code
    if (
      isShapeInSelectedShapes(this.id) ||
      (this.locked &&
        activeTool !== Tools.DrawSegmentOrTurn &&
        activeTool !== Tools.AddTurn &&
        !this.properties.stockLine &&
        !this.properties.rack)
    )
      return;

    if (event.detail && event.detail.x && event.detail.y) {
      event.x = event.detail.x as number;
      event.y = event.detail.y as number;
    }

    if (!this.dragging && activeTool === Tools.Move) {
      if (this.locked && event.type === 'drag') return;

      this.startDraggingMode();
      this.node.selectAll('.portion').remove();
    }

    if (
      (activeTool === Tools.AddTurn || activeTool === Tools.DrawSegmentOrTurn || event?.detail?.forceDrag) &&
      (isNaN(this.lastClickX) || (isNaN(this.lastClickY) && this.dragging))
    ) {
      this.lastClickX = event.x;
      this.lastClickY = event.y;
    }

    if (
      (activeTool === Tools.AddTurn || activeTool === Tools.DrawSegmentOrTurn || activeTool === Tools.Move) &&
      !isNaN(this.lastClickX) &&
      !isNaN(this.lastClickY)
    ) {
      if (activeTool === Tools.Move && !event?.sourceEvent?.ctrlKey && !event?.detail?.areTheyConnectingSegments) {
        // we don't draw a preview line
      } else {
        const isPerformanceModeEnabled = store.getState().editor.performanceMode;

        if (!isPerformanceModeEnabled) {
          // drawing the preview is quite resource intensive, we remove it in performance mode
          const x1 = this.lastClickX;
          const y1 = this.lastClickY;
          const x2 = event.x;
          const y2 = event.y;

          const previewEl = this.node.select(`#predraw-${this.id}`);
          if (!previewEl.node()) {
            this.node
              .append<SVGGElement>('svg:line')
              .attr('id', `predraw-${this.id}`)
              .attr('class', 'predraw')
              .attr('x1', x1)
              .attr('y1', y1)
              .attr('x2', x2)
              .attr('y2', y2)
              .exit();
          } else {
            previewEl.attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2);
          }
        }
      }
    }

    if (activeTool !== Tools.Move) return;

    if (!event?.detail?.forceDrag) {
      this.geometry.coordinates = offsetPositionLine(this.geometry.coordinates, event.dx, event.dy);
    }

    super.onDrag();
    this.updateArrows(this.node);
    this.updateSnappedPoints(StickingMethod.positionFactor);

    this.makeAttachedTurnsObsolete();
  }

  protected makeAttachedTurnsObsolete(obsolete = true): void {
    if (this.attachedTurnsObsolete === obsolete) return; // the state has not changed, we don't need to do anything

    // we change the style of the attached turns to let the user know that the turns is obsolete
    const attachedTurnsIds = this.computeAttachedTurnsIds();

    attachedTurnsIds.forEach((turnId) => {
      const el = document.querySelector(`[uuid='${turnId}']`);

      if (el && el instanceof SVGElement) el.style.strokeDasharray = obsolete ? '2' : 'unset';
    });

    this.attachedTurnsObsolete = obsolete;
  }

  protected onDragEnd(): void {
    const stateStore = store.getState();
    const activeTool = stateStore.tool.activeTool;
    const event: D3DragEventAugmented = d3.event as D3DragEventAugmented;

    const segment = store.getState().circuit.present.segments.entities[this.id];
    const hasSegmentMoved = !isEqual(segment.geometry.coordinates, this.geometry.coordinates);

    if (
      isShapeInSelectedShapes(this.id) ||
      (!hasSegmentMoved && !CircuitService.getDrawingReference().isDraggingTurnEnabled && activeTool !== Tools.AddTurn)
    )
      return;

    this.endDraggingMode();

    let x = (event?.sourceEvent?.x || NaN) as number,
      y = (event?.sourceEvent?.y || NaN) as number;
    let destSegmentId = '';

    if (event.detail && event.detail.x && event.detail.y) {
      x = event.detail.x as number;
      y = event.detail.y as number;

      if (event.detail.destSegmentId) destSegmentId = event.detail.destSegmentId as string;
    }

    let els: Element[] = destSegmentId ? [] : document.elementsFromPoint(x, y);
    const forceDrag = !!event?.detail?.forceDrag;

    if (activeTool === Tools.AddTurn || activeTool === Tools.DrawSegmentOrTurn || forceDrag) {
      if (els.length) {
        els = els
          .filter((el) => el.tagName === 'path')
          .map((el) => el.parentElement as Element)
          .filter((el) => el.getAttribute('type') === ShapeTypes.SegmentShape);
      }

      if (els.length || destSegmentId) {
        const origin = this.id;
        const dest = destSegmentId ? destSegmentId : d3.select(els[0]).attr('uuid');

        if (origin === dest) {
          // we prevent turn between the same segment from being created
          this.lastClickX = this.lastClickY = NaN;

          return;
        }

        const originSegment = stateStore.circuit.present.segments.entities[origin];
        const destSegment = stateStore.circuit.present.segments.entities[dest];

        if (originSegment && destSegment) {
          const [closestToOrigin, tClosestToOrigin] = getClosestPointInSegment(
            [this.lastClickX, -this.lastClickY],
            originSegment.geometry.coordinates[0],
            originSegment.geometry.coordinates[originSegment.geometry.coordinates.length - 1]
          );
          const [closestToDest, tClosestToDest] = getClosestPointInSegment(
            [x, -y],
            destSegment.geometry.coordinates[0],
            destSegment.geometry.coordinates[destSegment.geometry.coordinates.length - 1]
          );

          const preventUserAction = !!event?.detail?.preventUserAction;

          if (!preventUserAction) {
            store.dispatch(saveCircuitToHistoryAction());
          }

          store.dispatch(
            addTurnAction({
              origin: {
                id: origin,
                position: tClosestToOrigin,
                orientation: findShapeOrientation(
                  originSegment.geometry.coordinates[0],
                  originSegment.geometry.coordinates[originSegment.geometry.coordinates.length - 1]
                ),
                coordinates: {
                  x: closestToOrigin[0],
                  y: closestToOrigin[1],
                },
                segment: originSegment.geometry.coordinates,
              },
              destination: {
                id: dest,
                position: tClosestToDest,
                orientation: findShapeOrientation(
                  destSegment.geometry.coordinates[0],
                  destSegment.geometry.coordinates[destSegment.geometry.coordinates.length - 1]
                ),
                coordinates: {
                  x: closestToDest[0],
                  y: closestToDest[1],
                },
                segment: destSegment.geometry.coordinates,
              },
            })
          );
        }
      }

      this.lastClickX = NaN;
      this.lastClickY = NaN;
    } else if (activeTool === Tools.Move) {
      const askSegmentsConnectionEvent = new CustomEvent('askSegmentsConnection', {
        detail: {
          segmentId: this.id,
          x: d3.event.x as number,
          y: d3.event.y as number,
        },
      });

      const rootNode = document.querySelector('#root-node-svg');

      if (rootNode) rootNode.dispatchEvent(askSegmentsConnectionEvent);
      // eslint-disable-next-line no-console
      else console.error(`rootNode not found`);

      setShapeJustMoved(this.id);

      if (this.locked) {
        setTimeout(() => {
          this.saveSnappedPoints(hasSegmentMoved);
          store.dispatch(
            segmentMovedAction({
              idSegment: this.id,
              coordinates: this.geometry.coordinates,
            })
          );
          store.dispatch(
            saveSegmentAction({
              id: segment.id,
              properties: {
                ...segment.properties,
                locked: true,
              },
            })
          );

          snackbarLockedSegmentExtended([segment]);

          this.node.selectAll(`#predraw-${this.id}`).remove();
        }, 0);

        return;
      }

      setTimeout(() => {
        this.saveSnappedPoints(hasSegmentMoved);
        store.dispatch(
          segmentMovedAction({
            idSegment: this.id,
            coordinates: this.geometry.coordinates,
          })
        );

        this.node.selectAll(`#predraw-${this.id}`).remove();
      }, 0);
    }

    super.onDragEnd(this.properties);
  }

  protected updateShape(): void {
    this.refreshGeometry();
    this.updateArrows(this.node);
  }

  /**
   * Function triggered when the element is moved while being in the selection
   * But it is not the element directly dragged
   */
  protected translateEnd(): void {
    const segmentMoved =
      this.geometry.coordinates !== store.getState().circuit.present.segments.entities[this.id].geometry.coordinates;

    if (!this.draggeable() || !segmentMoved) return;

    store.dispatch(
      segmentMovedAction({
        idSegment: this.id,
        coordinates: this.geometry.coordinates,
      })
    );
  }

  /**
   * Display the traffic portions
   * Given that it is a low priority, the task is scheduled with a requestIdleCallback
   * Only portions with a trafficType different from Auto are displayed
   */
  private showTrafficPortions(force = false): void {
    if (!this.node?.node()) return;

    const segment = store.getState().circuit.present.segments.entities[this.id];

    segment?.properties?.portions?.forEach((portion) => {
      if (
        portion.trafficType === 'kernel' ||
        portion.trafficType === 'deadend' ||
        portion.trafficType === 'deadend-entry' ||
        portion.trafficType === 'deadend-exit' ||
        force
      ) {
        if (!portion || !portion.points || !portion.points[0] || !portion.points[1]) return;

        const portionLine = this.node.append<SVGElement>('svg:line');

        portionLine
          .attr('portion-id', portion.id)
          .classed('portion', true)
          .style('stroke-width', this.strokeWidth ?? undefined)
          .attr('x1', portion.points[0][0])
          .attr('y1', -portion.points[0][1])
          .attr('x2', portion.points[1][0])
          .attr('y2', -portion.points[1][1]);

        if (portion.trafficType) portionLine.classed(`portion-${portion.trafficType}`, true);
      }
    });
  }

  protected startDraggingMode(): void {
    this.dragging = true;

    this.node.classed('dragging', true);
  }

  protected endDraggingMode(): void {
    this.dragging = false;

    if (this.node) {
      this.node.classed('dragging', false);
      this.node.selectAll(`.predraw`).remove();
    }

    //Make the old turns solid again after the end of the action
    this.makeAttachedTurnsObsolete(false);
  }

  public async showGabarit(): Promise<void> {
    const modelName = this.properties.gabarit?.modelName;
    const gabaritName = this.properties.gabarit?.type;

    if (!modelName || !gabaritName) return;

    const segment = {
      id: this.id,
      geometry: { ...this.geometry },
      properties: { ...this.properties },
    };
    if (!isCircuitSegment(segment)) {
      // eslint-disable-next-line no-console
      console.error(`Segment ${this.id} is not a circuit segment`);

      return;
    }

    const gabaritCoords = getGabaritCoords(gabaritName, modelName);
    if (!gabaritCoords) {
      // eslint-disable-next-line no-console
      console.warn(`Gabarit ${gabaritName} not found for model ${modelName}`);

      return;
    }

    const gabarit = await gabaritsWorkerProxy.generateSegmentGabarit({
      gabaritName,
      modelName,
      segment,
      gabaritCoords,
    });
    if (!gabarit || !gabarit.geometry) return;

    this.node
      .append('svg:defs')
      .append('svg:path')
      .attr('id', `gabarit-${this.id}`)
      .data([gabarit.geometry])
      .attr('d', this.projection as any)
      .classed('gabarit', true)
      .exit();
  }
}

interface D3DragEventAugmented extends d3.D3DragEvent<SVGGElement, any, any> {
  detail?: any;
}
