import { useMemo } from 'react';
import { PreferencesService } from 'utils/preferences';
import { versionCompare } from 'utils/version-compare';

/**
 * Check if the current SDK version is equal to or greater than the minimum required SDK version.
 * @param {string} minimumSdkVersion - The minimum required SDK version to compare against.
 * @returns {boolean} - True if the current SDK version is equal to or greater than the minimum required SDK version, otherwise false.
 */
export function useSdkVersionOk(minimumSdkVersion: string): boolean {
  const isSdkVersionOk = useMemo(() => {
    const sdkVersion = PreferencesService.getSDKVersion();

    if (!sdkVersion || typeof sdkVersion !== 'string') return false;

    return versionCompare(sdkVersion, minimumSdkVersion) >= 0;
  }, [minimumSdkVersion]);

  return isSdkVersionOk;
}
