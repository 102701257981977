import type { CellLoad } from 'models/circuit';

/**
 * Computes the position of the loads in a cell regarding its cell template (position = left side of the load)
 * @param load the selected load
 * @param cellWidth the width of the cell (column; upright to upright) [m]
 * @returns the x positions [m]
 */
export function computeLoadsPosition(load: CellLoad, cellWidth: number): number[] {
  const pos: number[] = [];

  if (!load.N) return pos;

  if (load.center && load.N === 1) {
    const x = cellWidth / 2 - load.W / 2;

    return [x];
  } else if (load.center && load.N !== 1) {
    // eslint-disable-next-line no-console
    console.error(
      `The load has to be centered but this option is available only when there's only one pallet in the cell (load.N = ${load.N})`
    );
  }

  const availableWidth = cellWidth - load.a1 - (load.a2 || load.a1);

  /** the width available but we removed the first and last loads, given that they are sticks to the uprights */
  const availableWidthCenter = availableWidth - 2 * load.W;

  const d = (availableWidthCenter - load.W * (load.N - 2)) / (load.N - 2 + 1);

  pos.push(load.a1);

  const beginCenterSlots = load.a1 + load.W;
  for (let i = 1, l = load.N - 1; i < l; i++) {
    pos.push(beginCenterSlots + i * d + (i - 1) * load.W);
  }

  if (load.N >= 2) {
    pos.push(cellWidth - load.W - (load.a2 || load.a1));
  }

  return pos;
}
