import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import HelpIcon from '@mui/icons-material/Help';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import centroid from '@turf/centroid';
import { polygon as turfPolygon } from '@turf/helpers';
import { openDialogAction } from 'actions';
import {
  askUpdateRackAction,
  clearShapesSelectionAction,
  saveCircuitToHistoryAction,
  selectCircuitShapeAction,
} from 'actions/circuit';
import { saveRackAction, saveRackSuccessAction } from 'actions/racks';
import { saveZoneAction } from 'actions/zones';
import { HelpIconTooltip } from 'components/utils/tooltips';
import * as d3 from 'd3';
import { rotatePolygon2 } from 'drawings/helpers';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { groupBy } from 'lodash';
import { DialogTypes } from 'models';
import type { CircuitRack } from 'models/circuit';
import { RackAnomalyIds, ShapeTypes } from 'models/circuit';
import { isRackAnomalyId } from 'models/circuit.guard';
import { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import type { LoadedRack } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { useDebouncedCallback } from 'use-debounce';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { epsilon, polygonDistanceFrom } from 'utils/circuit/utils';
import { getConfig } from 'utils/config';
import { nbDigitsExtendedLength } from 'utils/extended-length';
import { toDigits } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { MovementArrowButtonsGroup } from '../components/movement-arrows-buttons';
import type { ConveyorFormValues } from '../doors-settings';
import { DoorSettings } from '../doors-settings';
import { PropertiesComponent } from '../properties-component';
import { CheckTrackLessRackButton } from './check-trackless';
import { ExtendedLengthTextfield } from './extended-length-textfield';

const useStyles = makeStyles((theme) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      position: 'absolute',
      right: theme.spacing(1),
      top: '0',
      pointerEvents: 'none',
    },
    card: {
      display: 'flex',
      alignSelf: 'center',
      background: 'white',
      pointerEvents: 'initial',
      zIndex: 401,
      maxHeight: getConfig('editor').properties.maxHeight,
    },
    cardContent: {
      display: 'flex',
      flexFlow: 'column',
      overflow: 'auto',
    },
    formTitle: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formTitleIcon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    formCheckBox: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
    },
    formElement: {
      margin: theme.spacing(1),
      width: '100%',
      textAlign: 'left',
    },
    divider: {
      margin: `16px ${theme.spacing(1)}`,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    leftAlign: {
      textAlign: 'left',
    },
    commentTextarea: {
      marginTop: theme.spacing(1),
    },
    locked: {
      color: 'rgba(0, 0, 0, 0.38)',
      pointerEvents: 'none',
    },
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },
    lineThrough: {
      textDecoration: 'line-through',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    disabled: {
      color: theme.palette.grey[300],
      pointerEvents: 'none',
    },
    helpTooltip: {
      verticalAlign: 'middle',
      cursor: 'help',
      opacity: 0.7,
      maxHeight: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  })
);

export interface RackPropertiesProps {
  rackId: string;
}

//All the shape that have editable orientation should be precised at 4 digits
export const nbDigitsOrientationValue = 4;

export function RackProperties({ rackId }: RackPropertiesProps): JSX.Element {
  const rack = useAppSelector((state) => state.circuit.present.racks.entities[rackId] as LoadedRack | undefined);
  const isStandardMode = useAppSelector((state) => state.local.standardMode);

  const isConveyor = !!rack?.properties.conveyor;

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [rackName, setRackName] = useState(rack?.properties.name);
  const [cap, setCap] = useState(parseFloat(rack?.properties.cap.toFixed(nbDigitsOrientationValue) ?? '-1'));
  const [depth, setDepth] = useState(parseFloat(rack?.properties.depth.toFixed(3) ?? '-1'));
  const [extendedLength, setExtendedLength] = useState(rack?.properties.defaultExtendedLength ?? 0);

  useEffect(() => {
    setRackName(rack?.properties.name.trim());
  }, [rack?.properties.name]);
  useEffect(() => {
    setCap(parseFloat(rack?.properties.cap.toFixed(nbDigitsOrientationValue) ?? '-1'));
  }, [rack?.properties.cap]);
  useEffect(() => {
    setDepth(parseFloat(rack?.properties.depth.toFixed(3) ?? '-1'));
  }, [rack?.properties.depth]);
  useEffect(() => {
    setExtendedLength(rack?.properties.defaultExtendedLength ?? 0);
  }, [rack?.properties.defaultExtendedLength]);

  const locked = !!rack?.properties.locked;

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');

  const [conveyorFormValues, setConveyorFormValues] = useState<ConveyorFormValues>({
    doordAsk: 0,
    doordStop: 0,
    doorDevices: [],
  });
  useEffect(() => {
    if (!rack || !rack.properties || !rack.properties.conveyor) return;

    const formValue: ConveyorFormValues = {
      doordAsk: rack.properties?.conveyor?.access?.dAsk, // useless parameter
      doordStop: rack.properties?.conveyor?.access?.dStop, // useless parameter
      doorDevices: rack.properties?.conveyor?.access?.devices ?? [],
    };

    setConveyorFormValues(formValue);
  }, [rack]);

  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!rack) return;

    let name = rackName;
    if (
      !name ||
      !areAllShapeNamesUnique([name], [rackId], {
        ignoreDuplicatesBefore: true,
      })
    ) {
      name = suggestedName;
    }

    if (isNaN(cap)) {
      // cap is not a number, skipping the update

      if (rack) {
        dispatch(saveRackSuccessAction(rack)); // we dispatch the action to update the rack and remove the loading
      }

      return;
    }

    let coordinates = rack?.geometry.coordinates;
    if (
      rack &&
      coordinates &&
      (Math.abs(cap - rack.properties.cap) > 0.01 || Math.abs(depth - rack.properties.depth) > 0.01)
    ) {
      let actualLength = 0;
      rack.properties.columns.forEach((c) => (actualLength += c.width));
      rack.properties.uprights.forEach((upright) => (actualLength += upright.width));
      actualLength *= 100;

      coordinates = structuredClone(rack.geometry.coordinates);
      if (!coordinates) {
        throw new Error('coordinates is undefined');
      }

      const scaleFactor = depth / rack.properties.depth;

      const center = centroid(turfPolygon(coordinates));
      const centerCoords = center.geometry.coordinates;

      coordinates[0].forEach((point, i) => {
        if (i === 0) return;
        if (i === 1) return;
        if (i === point.length - 1) return;
        if (!coordinates) return;

        let otherPoint: number[];
        if (i === 2) {
          otherPoint = coordinates[0][1];
        } else {
          otherPoint = coordinates[0][0];
        }

        const angle = Math.atan2(point[1] - otherPoint[1], point[0] - otherPoint[0]);
        const d = getDistanceBetweenPoints(point, otherPoint);

        point[0] = otherPoint[0] + d * Math.cos(angle) * scaleFactor;
        point[1] = otherPoint[1] + d * Math.sin(angle) * scaleFactor;
      });

      const deltaAngle = cap - rack.properties.cap;

      coordinates = [rotatePolygon2(centerCoords, coordinates[0], deltaAngle)];
    }

    startTransition(() => {
      if (!rack || !name || !coordinates) return;

      const currentRackState = store.getState().circuit.present.racks.entities[rack.id as string] as
        | LoadedRack
        | undefined;
      if (!currentRackState) {
        // eslint-disable-next-line no-console
        console.error(`Rack ${rack.id} not found in the state`);

        return;
      }

      let columns = currentRackState.properties.columns;
      if (Math.abs(extendedLength - currentRackState.properties.defaultExtendedLength) > epsilon) {
        // default extended length has been changed
        columns = currentRackState.properties.columns.map((column) => {
          // we don't want to update unlinked columns
          if (!column.linkedProperties.extendedLength) return column;

          const newColumn = { ...column };
          newColumn.extendedLength = extendedLength;

          return newColumn;
        });
      }

      const newRack: CircuitRack = {
        ...rack,
        id: rack.id,
        properties: {
          ...currentRackState.properties,
          name,
          cap,
          depth,
          defaultExtendedLength: extendedLength,
          columns,
          conveyor:
            isConveyor && rack.properties.conveyor
              ? {
                  ...rack.properties.conveyor,
                  access: {
                    dAsk: conveyorFormValues.doordAsk,
                    dStop: conveyorFormValues.doordStop,
                    devices: conveyorFormValues.doorDevices,
                    enabled: conveyorFormValues.doorDevices.length > 0,
                  },
                }
              : undefined,
        },
        geometry: {
          ...rack.geometry,
          coordinates,
        },
      };

      dispatch(saveCircuitToHistoryAction());

      dispatch(saveRackAction(newRack));

      // we ask a save rack success action to properly check the names, etc.
      // we don't want to do it everytime because we don't want to check the names if we just moved the rack
      // here we need the computation done by the save action as well as the ones done in the save success
      startTransition(() => {
        dispatch(
          askUpdateRackAction({
            id: newRack.id as string,
            type: 'saveSuccess',
          })
        );
      });
    });
  }, 400);
  const updateProperties = useCallback(() => {
    // we apply a css class to the stockzone of the playground to tell the user that the stock zone will be recomputed
    if (rack?.id) d3.select(`[uuid='${rack.id}']`).classed('outdated', true);

    // and we recompute the stock zone
    updatePropertiesDebounced();
  }, [rack?.id, updatePropertiesDebounced]);

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const nameValue = e.target.value;
      const newName = nameValue.trim();
      if (newName?.length < 50) {
        setRackName(newName);
      }

      startTransition(() => {
        const isNameAlreadyUsed = !areAllShapeNamesUnique([newName], [rackId], {
          ignoreDuplicatesBefore: true,
        });

        if (!newName || isNameAlreadyUsed) {
          setErrorName(true);
          setSuggestedName(
            CircuitService.generateDifferentName(newName, {
              shapeIdsToIgnore: [rackId],
            })
          );
        } else {
          setErrorName(false);
        }

        updatePropertiesDebounced();
      });
    },
    [rackId, updatePropertiesDebounced]
  );
  const setSuggestedNameHandler = useCallback(() => {
    setRackName(suggestedName);
    setErrorName(false);

    updateProperties();
  }, [suggestedName, updateProperties]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorName(false), [rackId]);

  const onCapChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newCap = parseFloat(e.target.value);

      setCap(newCap % 360);

      updateProperties();
    },
    [updateProperties]
  );

  const onDepthChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newDepth = parseFloat(e.target.value);

      const input = e.target;
      const min = parseFloat(input.min);
      const max = parseFloat(input.max);

      if (!isNaN(newDepth) && newDepth >= min && newDepth <= max) {
        setDepth(newDepth);
      }

      updateProperties();
    },
    [updateProperties]
  );

  const onExtendedLengthChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newExtendedLength = parseFloat(e.target.value);

      const input = e.target;
      const min = parseFloat(input.min);

      if (!isNaN(newExtendedLength) && newExtendedLength >= min) {
        setExtendedLength(newExtendedLength);
      }

      updateProperties();
    },
    [updateProperties]
  );

  const handleEditParameters = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.RackEdition,
        payload: undefined,
      })
    );
  }, [dispatch]);

  const [askSaveRack, setAskSaveRack] = useState(false);
  useEffect(() => {
    if (askSaveRack) {
      setAskSaveRack(false);
      updatePropertiesDebounced();
    }
  }, [askSaveRack, updatePropertiesDebounced]);

  const handleMoveBackConveyorZone = useCallback(() => {
    if (!rack) {
      // eslint-disable-next-line no-console
      console.error('No rack found');

      return;
    }

    if (!isConveyor) {
      // eslint-disable-next-line no-console
      console.error('The rack is not a conveyor, this function cannot be called');

      return;
    }

    const zoneId = rack.properties.conveyor?.zone;
    if (!zoneId) {
      // eslint-disable-next-line no-console
      console.error('No conveyor zone found');

      return;
    }

    const zone = store.getState().circuit.present.zones.entities[zoneId];
    if (!zone) {
      // eslint-disable-next-line no-console
      console.error('No zone found');

      return;
    }

    const isZoneLocked = !!zone.properties?.locked;
    if (isZoneLocked) {
      SnackbarUtils.toast(`The associated zone (${zone.properties.name}) is locked, unlock it first to move it.`);

      return;
    }

    const distanceFromTheRack = 0.5; // in metres
    // we create a shape larger than the rack by a specified radius, and then take the envelope of this shape
    const zoneCoordinates = polygonDistanceFrom(rack.geometry.coordinates, distanceFromTheRack * 100);

    dispatch(
      saveZoneAction({
        id: zoneId,
        geometry: {
          type: 'Polygon',
          coordinates: zoneCoordinates,
        },
      })
    );
  }, [dispatch, isConveyor, rack]);

  const anomalies = useMemo(() => {
    if (!rack || !rack.properties || !rack.properties.anomaly || !rack.properties.anomaly.state) return undefined;

    const issues = groupBy(rack.properties.anomaly.issues, (issue) => issue);
    const res: Partial<Record<RackAnomalyIds, number>> = {};

    Object.keys(issues).forEach((key) => {
      res[key] = issues[key].length;
    });

    return res;
  }, [rack]);

  const isProjectLoaded = PreferencesService.arePreferencesFullyLoaded();

  const handleSetExtendedLength = useCallback((newExtendedLength: number) => {
    setExtendedLength(toDigits(newExtendedLength, nbDigitsExtendedLength));
    setAskSaveRack(true);
  }, []);

  return rack ? (
    <PropertiesComponent
      shape={rack}
      shapeId={rackId}
      shapeType={ShapeTypes.RackShape}
      title={isConveyor ? 'Conveyor' : undefined}
    >
      <FormControl error={errorName}>
        <TextField
          className={`${errorName ? ` ${classes.lineThrough}` : ''}`}
          value={rackName}
          onChange={onNameChangeHandler}
          fullWidth
          margin="normal"
          label="Name"
          disabled={locked}
          variant="standard"
        />
        <FormHelperText className={classes.helperText} error id="suggested-name-rack">
          {errorName ? (
            <>
              Name already used, suggested name:{' '}
              <span onClick={setSuggestedNameHandler} className={classes.suggestedName}>
                {suggestedName}
              </span>
            </>
          ) : (
            <></>
          )}
        </FormHelperText>
        <MovementArrowButtonsGroup locked={locked} shape={rack} shapeId={rackId} shapeMovedAction={saveRackAction} />
      </FormControl>

      <ExtendedLengthTextfield
        extendedLength={extendedLength}
        isProjectLoaded={isProjectLoaded}
        locked={locked}
        handleSetExtendedLength={handleSetExtendedLength}
        rack={rack}
        onExtendedLengthChangeHandler={onExtendedLengthChangeHandler}
      />

      <TextField
        type="number"
        value={cap}
        className={classes.formElement}
        onChange={onCapChangeHandler}
        fullWidth
        margin="normal"
        label="Orientation"
        disabled={locked}
        inputProps={{ step: 1 }}
        InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
        variant="standard"
      />

      <TextField
        type="number"
        value={depth}
        className={classes.formElement}
        onChange={onDepthChangeHandler}
        fullWidth
        margin="normal"
        label={
          <>
            Depth{' '}
            <Tooltip title="Distance between both beams of the rack">
              <HelpIcon fontSize="small" />
            </Tooltip>
          </>
        }
        disabled={locked}
        inputProps={{ step: 0.01, min: 0.1, max: 10.0 }}
        InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
        variant="standard"
      />

      <Button
        className={classes.formElement}
        fullWidth
        variant="contained"
        startIcon={<TuneIcon />}
        onClick={handleEditParameters}
        color="primary"
        sx={{
          textTransform: 'none',
        }}
      >
        Edit Parameters
      </Button>

      {isProjectLoaded && ( // we display the button only if the project is loaded, otherwise we cannot compute the trackless turns
        <CheckTrackLessRackButton rack={rack} />
      )}
      {anomalies && (
        <Alert severity={rack.properties?.anomaly?.state === 1 ? 'warning' : 'error'} icon={false}>
          <AlertTitle>Anomalies</AlertTitle>
          <ul style={{ maxHeight: '300px', float: 'left', paddingLeft: '20px' }}>
            {Object.keys(anomalies).map((key: string) => {
              if (!isRackAnomalyId(key)) {
                // eslint-disable-next-line no-console
                console.error(`Unknown anomaly id: ${key}`);

                return undefined;
              }

              return (
                <li key={key}>
                  {translateRackErrorToEnglish(key)} (×{anomalies[key]})
                </li>
              );
            })}
          </ul>
        </Alert>
      )}
      {isConveyor && (
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={10}>
            <Button
              className={classes.formElement}
              fullWidth
              variant="contained"
              onClick={() => {
                const zoneId = rack.properties.conveyor?.zone;
                if (!zoneId) throw new Error('Conveyor has no zone');
                dispatch(clearShapesSelectionAction());
                dispatch(
                  selectCircuitShapeAction({ selectedShapeId: zoneId, selectedShapeType: ShapeTypes.ZoneShape })
                );
              }}
              color="inherit"
              sx={{
                textTransform: 'none',
              }}
            >
              Open the zone settings
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Move the zone around the conveyor" onClick={handleMoveBackConveyorZone}>
              <IconButton>
                <CenterFocusStrongIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {isConveyor && conveyorFormValues && setConveyorFormValues && (
        <Box
          component="div"
          sx={{
            borderLeft: isStandardMode ? `3px solid ${theme.palette.warning.light}` : undefined,
            paddingLeft: isStandardMode ? '6px' : undefined,
          }}
        >
          {isStandardMode && <Alert severity="warning">This feature is not available in standard mode</Alert>}
          <Box
            component="div"
            sx={{
              fontWeight: 'bold',
              marginBottom: theme.spacing(2),
            }}
          >
            Pick / Drop authorization{' '}
            <HelpIconTooltip title="Connect the conveyor to a (or several) device(s). Before picking or dropping, the robot will check that the device(s) are in the proper state(s)." />
          </Box>

          <DoorSettings
            classes={classes}
            formValues={conveyorFormValues}
            setFormValues={setConveyorFormValues}
            locked={locked}
            hideDistances={true}
            setAskSaveShape={setAskSaveRack}
            doorSettingsFor={'conveyor'}
          />
        </Box>
      )}
    </PropertiesComponent>
  ) : (
    <></>
  );
}

function translateRackErrorToEnglish(anomalyId: RackAnomalyIds): string {
  switch (anomalyId) {
    case RackAnomalyIds.cellNotAssigned: {
      return 'Cell not assigned';
    }

    case RackAnomalyIds.cellNotEnoughSpace: {
      return 'Loads width exceed available column width';
    }

    case RackAnomalyIds.cellNotEnoughHeight: {
      return 'Cell height is lower than the maximum load height';
    }

    case RackAnomalyIds.cellNotEnoughMarginLoadUpright: {
      return `There's not enough marging between the load and the upright`;
    }

    case RackAnomalyIds.beamThicknessNotEnough: {
      return `The beam thickness is too small`;
    }

    case RackAnomalyIds.palletOverflowNotEnough: {
      return `The load overflow is too small`;
    }

    case RackAnomalyIds.palletOverflowTooMuch: {
      return `The load overflow is too big`;
    }

    case RackAnomalyIds.uprightWidthNotEnough: {
      return `The upright width is too small`;
    }
  }

  // eslint-disable-next-line no-console
  console.warn(`${anomalyId} has not a proper translation`);

  assert<Equals<typeof anomalyId, never>>();

  return anomalyId;
}
