import { clearShapesSelectionAction } from 'actions/circuit';
import type { EditorDrawing } from 'drawings/editor.drawing';
import { LayerNames } from 'drawings/layers';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import store, { useAppDispatch, useAppSelector } from 'store';
import { MapImageTilesComponent } from './floor-plan-tiles.layer';
import { MapImageComponent } from './floor-plan.layer';
import { LidarLayerComponent } from './lidar.layer';

interface BaseDrawingComponentProps {
  children?: ReactNode;
  axisLayer?: ReactNode;
  drawLayer?: ReactNode;
  drawing: EditorDrawing;
}

export function BaseDrawingComponent(props: BaseDrawingComponentProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(
    (e: React.MouseEvent<SVGGElement>) => {
      if (!e.ctrlKey && !e.shiftKey && !e.altKey && store.getState().local.selectedShapesData.length) {
        dispatch(clearShapesSelectionAction());

        // stop the dragging mode in the segments
        document.querySelector('#root-node-svg')?.dispatchEvent(new CustomEvent('stopDraggingSegments'));
      }
    },
    [dispatch]
  );

  // we have to read this because we have to display the map image on top of the lidar
  // when we want to drag it
  const mapImageSelector = useAppSelector((state) => state.maps.mapImage);
  const mapImagePropertiesOpened = mapImageSelector.openProperties !== undefined;
  const mapImageTiles = useAppSelector((state) => state.maps.mapImageTiles.mapImageTilesData);

  const displayNavigationLidar = useAppSelector((state) => state.local.filters.map);
  const displayObstacleLidar = useAppSelector((state) => state.local.filters.mapObstacle);

  return (
    <svg
      id="root-node-svg"
      width="100%"
      height="100%"
      pointerEvents="visible"
      fontFamily="var(--sans-serif)"
      fontSize="16"
      onClick={handleClick}
    >
      <g className="secondary-translate-container">
        <g className="secondary-zoom-container">
          {!mapImagePropertiesOpened && <MapImageComponent />}
          {mapImageTiles && <MapImageTilesComponent />}
          {displayNavigationLidar && (
            <LidarLayerComponent layerName={LayerNames.BackgroundLidar} drawing={props.drawing} />
          )}
          {displayObstacleLidar && (
            <LidarLayerComponent layerName={LayerNames.ForegroundLidar} drawing={props.drawing} />
          )}
          {mapImagePropertiesOpened && <MapImageComponent />}
        </g>
      </g>
      {props.axisLayer}
      <g className="translate-container">{props.children}</g>
      {props.drawLayer}
    </svg>
  );
}
