import type { Action } from 'redux';

export enum FilterActionTypes {
  changeStateFilter = '[Local] Change State Filter',
  changeOpacityFilter = '[Local] Change Opacity',
  changeMapImageArrayOpacityFilter = '[Local] Change Map Image Array Opacity',
  changeMapImageArrayStateFilter = '[Local] Change Map Image Array State',
  addMapImageFilter = '[Local] Add Map Image',
  removeMapImageFilter = '[Local] Remove Map Image',
  changeMapImageNameFilter = '[Local] Change Map Image Name',
  clearMapImageFilter = '[Local] Clear Map Image',
}

export interface FilterState {
  zones: boolean;
  racks: boolean;
  points: boolean;
  segments: boolean;
  measurers: boolean;
  turns: boolean;
  stockZones: boolean;
  devices: boolean;
  notes: boolean;
  mapImage: boolean;
  map: boolean;
  mapObstacle: boolean;
  mapObstacleOpacity: number; // [0, 1]
  mapImageOpacity: number; // [0, 1]
  mapOpacity: number; // [0, 1]
  gabarit: boolean;
  displayPointNames: boolean;
  displayStockLineNames: boolean;
  extendedLengthTurns: boolean;
  mapImageArray: { name: string; opacity: number; toggle: boolean; isTiles?: boolean }[];
}

export function getFilterDataInitialState(): FilterState {
  return {
    zones: true,
    racks: true,
    points: true,
    segments: true,
    measurers: true,
    turns: true,
    stockZones: true,
    devices: true,
    notes: true,
    mapImage: true,
    map: true,
    mapObstacle: true,
    gabarit: true,
    mapObstacleOpacity: 1,
    mapImageOpacity: 1,
    mapOpacity: 1,
    displayPointNames: false,
    displayStockLineNames: false,
    extendedLengthTurns: false,
    mapImageArray: [],
  };
}

export interface ChangeStateFilter extends Action {
  type: FilterActionTypes.changeStateFilter;
  payload: {
    zones?: boolean;
    racks?: boolean;
    points?: boolean;
    segments?: boolean;
    measurers?: boolean;
    turns?: boolean;
    stockZones?: boolean;
    devices?: boolean;
    notes?: boolean;
    mapImage?: boolean;
    map?: boolean;
    mapObstacle?: boolean;
    gabarit?: boolean;
    displayPointNames?: boolean;
    displayStockLineNames?: boolean;
    extendedLengthTurns?: boolean;
  };
}
export function changeStateFilterAction(payload: ChangeStateFilter['payload']): ChangeStateFilter {
  return { type: FilterActionTypes.changeStateFilter, payload };
}

export interface ChangeOpacityFilter extends Action {
  type: FilterActionTypes.changeOpacityFilter;
  payload: {
    mapImageOpacity?: number; // [0, 1]
    mapOpacity?: number; // [0, 1]
    mapObstacleOpacity?: number; // [0, 1]
  };
}
export function changeOpacityFilterAction(payload: ChangeOpacityFilter['payload']): ChangeOpacityFilter {
  return { type: FilterActionTypes.changeOpacityFilter, payload };
}

export interface ChangeMapImageArrayStateFilter extends Action {
  type: FilterActionTypes.changeMapImageArrayStateFilter;
  payload: {
    toggle: boolean;
    name: string;
  };
}
export function changeMapImageArrayStateFilterAction(
  payload: ChangeMapImageArrayStateFilter['payload']
): ChangeMapImageArrayStateFilter {
  return { type: FilterActionTypes.changeMapImageArrayStateFilter, payload };
}

export interface ChangeMapImageArrayOpacityFilter extends Action {
  type: FilterActionTypes.changeMapImageArrayOpacityFilter;
  payload: {
    opacity: number;
    name: string;
  };
}
export function changeMapImageArrayOpacityFilterAction(
  payload: ChangeMapImageArrayOpacityFilter['payload']
): ChangeMapImageArrayOpacityFilter {
  return { type: FilterActionTypes.changeMapImageArrayOpacityFilter, payload };
}

export interface AddMapImageFilter extends Action {
  type: FilterActionTypes.addMapImageFilter;
  payload: {
    name: string;
    isTiles?: boolean;
  };
}
export function addMapImageFilterAction(payload: AddMapImageFilter['payload']): AddMapImageFilter {
  return { type: FilterActionTypes.addMapImageFilter, payload };
}

export interface RemoveMapImageFilter extends Action {
  type: FilterActionTypes.removeMapImageFilter;
  payload: {
    name: string;
  };
}
export function removeMapImageFilterAction(payload: RemoveMapImageFilter['payload']): RemoveMapImageFilter {
  return { type: FilterActionTypes.removeMapImageFilter, payload };
}

export interface ChangeMapImageNameFilter extends Action {
  type: FilterActionTypes.changeMapImageNameFilter;
  payload: {
    name: string;
    targetName: string;
  };
}
export function changeMapImageNameFilterAction(payload: ChangeMapImageNameFilter['payload']): ChangeMapImageNameFilter {
  return { type: FilterActionTypes.changeMapImageNameFilter, payload };
}

export interface ClearMapImageFilter extends Action {
  type: FilterActionTypes.clearMapImageFilter;
}
export function clearMapImageFilterAction(): ClearMapImageFilter {
  return { type: FilterActionTypes.clearMapImageFilter };
}

type FilterActions =
  | ChangeStateFilter
  | ChangeOpacityFilter
  | ChangeMapImageArrayOpacityFilter
  | ChangeMapImageArrayStateFilter
  | AddMapImageFilter
  | RemoveMapImageFilter
  | ChangeMapImageNameFilter
  | ClearMapImageFilter;

export function filtersReducer(state = getFilterDataInitialState(), action: FilterActions): FilterState {
  if (action.type === FilterActionTypes.changeStateFilter) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === FilterActionTypes.changeOpacityFilter) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === FilterActionTypes.changeMapImageArrayOpacityFilter) {
    const { opacity, name } = action.payload;

    return {
      ...state,
      mapImageArray: state.mapImageArray.map((mapImage) => {
        if (mapImage.name !== name) return mapImage;

        return {
          ...mapImage,
          opacity,
        };
      }),
    };
  }

  if (action.type === FilterActionTypes.changeMapImageArrayStateFilter) {
    const { toggle, name } = action.payload;

    return {
      ...state,
      mapImageArray: state.mapImageArray.map((mapImage) => {
        if (mapImage.name !== name) return mapImage;

        return {
          ...mapImage,
          toggle,
        };
      }),
    };
  }

  if (action.type === FilterActionTypes.addMapImageFilter) {
    const { name, isTiles } = action.payload;

    if (state.mapImageArray.find((mapImage) => mapImage.name === name)) return state; // no-op if already there

    if (isTiles) {
      return {
        ...state,
        mapImageArray: [
          ...state.mapImageArray,
          {
            name,
            opacity: 1,
            toggle: true,
            isTiles: true,
          },
        ],
      };
    }

    return {
      ...state,
      mapImageArray: [
        ...state.mapImageArray,
        {
          name,
          opacity: 1,
          toggle: true,
        },
      ],
    };
  }

  if (action.type === FilterActionTypes.removeMapImageFilter) {
    const { name } = action.payload;

    return {
      ...state,
      mapImageArray: state.mapImageArray.filter((mapImage) => mapImage.name !== name),
    };
  }

  if (action.type === FilterActionTypes.changeMapImageNameFilter) {
    const { name, targetName } = action.payload;

    return {
      ...state,
      mapImageArray: state.mapImageArray.map((mapImage) => {
        if (mapImage.name !== targetName) return mapImage;

        return {
          ...mapImage,
          name,
        };
      }),
    };
  }

  if (action.type === FilterActionTypes.clearMapImageFilter) {
    return {
      ...state,
      mapImageArray: [],
    };
  }

  return {
    ...state,
  };
}
