import type { DrawLayer } from 'drawings/draw.layer';
import type { EditorDrawing } from 'drawings/editor.drawing';
import { LayerNames } from 'drawings/layers';
import { Tools } from 'models/tools';
import { useCallback, useEffect, useRef } from 'react';
import { selectClosestPoint } from 'routes/routes';
import store, { useAppDispatch, useAppSelector } from 'store';

interface DrawLayerComponentProps {
  drawing: EditorDrawing;
}

export function DrawLayerComponent(props: DrawLayerComponentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const ref = useRef<SVGSVGElement | null>(null);
  const drawLayerPointer = props.drawing.layers.get(LayerNames.Draw);

  const activeTool = useAppSelector((state) => state.tool.activeTool);
  const nextSelectRoute = useAppSelector((state) => state.routes.nextClickSelect);

  useEffect(() => {
    let drawLayer: DrawLayer;
    try {
      drawLayer = props.drawing.getLayer<DrawLayer>(LayerNames.Draw);
    } catch (e) {
      return;
    }

    const node = drawLayer.node.node();
    if (!node || !ref.current) return;

    while (ref.current.firstChild) {
      ref.current.removeChild(ref.current.firstChild);
    }

    ref.current.appendChild(node);
  }, [props.drawing, drawLayerPointer]);

  const handleClickDrawLayer = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      let drawLayer: DrawLayer | undefined;
      try {
        drawLayer = props.drawing.getLayer<DrawLayer>(LayerNames.Draw);
      } catch (e) {
        return;
      }

      if (!drawLayer) {
        // eslint-disable-next-line no-console
        console.error('Draw layer not found');

        return;
      }

      const boundingRect = ref.current?.getBoundingClientRect();
      if (!boundingRect) {
        // eslint-disable-next-line no-console
        console.error('Bounding rect not found');

        return;
      }

      const screenX = e.clientX - boundingRect.left;
      const screenY = e.clientY - boundingRect.top;

      const [x, y] = drawLayer.convertScreenCoordinateToSvgCoordinate([screenX, screenY]);

      const circuitState = store.getState().circuit.present;
      const segmentsIds = circuitState.segments.ids;
      const segmentsEntities = circuitState.segments.entities;

      if (activeTool === Tools.Route && nextSelectRoute) {
        dispatch(
          selectClosestPoint({
            x,
            y,
            segmentsIds,
            segmentsEntities,
          })
        );
      }
    },
    [activeTool, dispatch, props.drawing, nextSelectRoute]
  );

  return (
    <g
      ref={ref}
      onClick={handleClickDrawLayer}
      style={{
        cursor: activeTool === Tools.Route && nextSelectRoute ? 'pointer' : undefined,
      }}
      {...{ layer: LayerNames.Draw }}
    ></g>
  );
}
