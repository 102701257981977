import { Alert, AlertTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';

/**
 * Alert component for notifying about isOldDispatcher when it enable but should not be
 * It does not display anything if useOldDispatcher === 0 or is not enable
 * @returns JSX.Element
 */
export function AlertOldDispatcherEnabled(): JSX.Element {
  const [displayAlert, setDisplayAlert] = useState(false);

  useEffect(() => {
    let isOldDispatcherEnabled: boolean | undefined;
    try {
      isOldDispatcherEnabled = !!Number(PreferencesService.getPreferenceValue('general/useOldDispatcher'));
    } catch (e) {}

    if (!isOldDispatcherEnabled) return;

    setDisplayAlert(true);
  }, []);

  if (!displayAlert) {
    return <></>;
  }

  return (
    <Alert
      severity="error"
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      <AlertTitle>Wrong dispatcher configuration</AlertTitle>
      The preference useOldDispatcher should be set to 0 to create and assign tasks in the simulation.
    </Alert>
  );
}
