import { SnackbarUtils } from 'services/snackbar.service';

const minimumStorageQuota = 200000000; // 200 MB

export async function checkStorageQuota(displaySnackbar = true): Promise<void> {
  if (navigator.storage && navigator.storage.estimate) {
    const { quota } = await navigator.storage.estimate();

    if (quota !== undefined && quota < minimumStorageQuota) {
      // eslint-disable-next-line no-console
      console.warn(`Storage quota is ${quota} bytes, which is less than the minimum of ${minimumStorageQuota} bytes.`);

      if (displaySnackbar) {
        SnackbarUtils.warning(
          `You are running low on storage space (${(quota / 1e6).toFixed(
            0
          )} MB free). Please consider making some space.`
        );
      }
    }
  }
}
