import type { DeleteLayer, UpdateLayer } from 'actions/circuit';
import { CircuitActionTypes, updateTurnAction } from 'actions/circuit';
import { chunk } from 'lodash';
import { setBackdropStateAction } from 'reducers/core/reducer';
import type { AppState } from 'reducers/state';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';

export function updateLayer$(actions$: ActionsObservable<any>, state$: StateObservable<AppState>): Observable<void> {
  return actions$.pipe(
    ofType<UpdateLayer>(CircuitActionTypes.UpdateLayer),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const layerId = action.payload.layerId;
      if (action.payload.isDraft !== undefined) {
        const turnIds = state.circuit.present.turns.ids;
        const allTurnsIdsInThisLayer = turnIds.filter((turnId) => {
          const turn = state.circuit.present.turns.entities[turnId];

          return turn?.properties.layerId === layerId;
        });

        const nbTurnsToUpdate = allTurnsIdsInThisLayer.length;
        if (nbTurnsToUpdate) {
          updateTurns(allTurnsIdsInThisLayer);
        }
      }

      return [];
    })
  );
}

/**
 * This function check that if we deleted a hiden layer then it will show a snackbar message.
 *
 */

export function showSnackbarOnHiddenLayerDeletion$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<any> {
  return actions$.pipe(
    ofType<DeleteLayer>(CircuitActionTypes.DeleteLayer),
    withLatestFrom(state$),
    switchMap(([action]) => {
      if (!action.payload.visibility) {
        SnackbarUtils.warning(`The hidden layer '${action.payload.name}' has been successfully deleted.`);
      }

      return [];
    })
  );
}

/**
 * This function updates the turns in the layer.
 * It first chunks the turns to update into groups of 32.
 * Then, for each chunk, it dispatches an updateTurnAction to update the turns in the chunk.
 * It also updates a snackbar with the progress of the updates.
 *
 * @param {string[]} turnsIdsToUpdate - The ids of the turns to update.
 * @returns {Promise<void>} A promise that resolves when all the turns have been updated.
 */

async function updateTurns(turnsIdsToUpdate: string[]): Promise<void> {
  const nbTurnsToUpdate = turnsIdsToUpdate.length;

  // eslint-disable-next-line no-console
  console.log(`Updating ${nbTurnsToUpdate} turns to recompute the connected portions...`);

  const displayBackdrop = nbTurnsToUpdate > 100;
  if (displayBackdrop) {
    store.dispatch(
      setBackdropStateAction({
        newBackdropState: true,
      })
    );
  }

  // we need to update the turns to recompute the portions
  const turnIdsChunk = chunk(turnsIdsToUpdate, 32);

  await new Promise((resolve) => setTimeout(resolve, 500));

  const spanId = `snackbar-${Math.random().toString(36).substring(7)}`;
  const spanId2 = `snackbar-${Math.random().toString(36).substring(7)}`;
  const spanId3 = `snackbar-${Math.random().toString(36).substring(7)}`;
  let nbUpdatedTurns = 0;
  const snackbarKey = SnackbarUtils.toast(
    <>
      <span id={spanId3}>Updating</span>{' '}
      <span id={spanId} style={{ marginLeft: '1ch' }}>
        0
      </span>
      /{nbTurnsToUpdate} turns (<span id={spanId2}>0</span>%)
    </>,
    {
      persist: true,
      variant: 'info',
    }
  );

  for (let i = 0; i < turnIdsChunk.length; i++) {
    const turnIdsOfThisChunk = turnIdsChunk[i];
    store.dispatch(
      updateTurnAction({
        idToUpdate: turnIdsOfThisChunk,
      })
    );

    nbUpdatedTurns += turnIdsOfThisChunk.length;

    const percent = Math.round((nbUpdatedTurns / nbTurnsToUpdate) * 100);
    const span = document.getElementById(spanId);
    const span2 = document.getElementById(spanId2);
    if (span && span2) {
      span.innerHTML = nbUpdatedTurns.toString();
      span2.innerHTML = percent.toString();
    }

    await new Promise((resolve) => setTimeout(resolve, Math.floor(Math.random() * 100) + 10));
  }

  await new Promise((resolve) => setTimeout(resolve, 100));

  const percent = Math.ceil((nbUpdatedTurns / nbTurnsToUpdate) * 100);
  const span = document.getElementById(spanId);
  const span2 = document.getElementById(spanId2);
  const span3 = document.getElementById(spanId3);
  if (span && span2 && span3) {
    span.innerHTML = nbUpdatedTurns.toString();
    span2.innerHTML = percent.toString();
    span3.innerHTML = 'Updated';
  }

  setTimeout(() => SnackbarUtils.closeSnackbar(snackbarKey), 3000);

  if (displayBackdrop) {
    store.dispatch(
      setBackdropStateAction({
        newBackdropState: false,
      })
    );
  }
}
