import { type Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { EditorDrawing } from 'drawings/editor.drawing';
import type { LineString as LineStringGeoJSON, Point, Polygon } from 'geojson';
import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
  ShapeTypes,
} from 'models/circuit';
import type { Lidar, MapImage, MapImageTiles } from 'models/maps';
import { LidarPosition } from 'models/maps';
import type { Tools } from 'models/tools';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CircuitService } from 'services/circuit.service';
import { useAppSelector } from 'store';
import { EditorDrawingComponent } from './editor.drawing';
import { useShortcuts } from './shortcuts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawContainer: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
  })
);

export interface EditorProps {
  backgroundLidar?: Lidar;
  foregroundLidar?: Lidar;
  image?: MapImage;
  imageTiles?: MapImageTiles;
  zones?: CircuitZone[];
  stockZones?: CircuitStockZone[];
  points?: CircuitPoint[];
  segments?: CircuitSegment[];
  measurers?: CircuitMeasurer[];
  turns?: CircuitTurn[];
  racks?: CircuitRack[];
  devices?: CircuitDevice[];
  notes?: CircuitNote[];
  selectedShapeIds: string[];
  activeTool?: Tools;
  onShapeSelected: (selectedShapeId: string, selectedShapeType: ShapeTypes) => void;
  onShapeUnselected: (unselectedShapeId: string, unselectedShapeType: ShapeTypes) => void;
  selectNewTool: (toolName: Tools) => void;
  onDeletionRequired: () => void;
  onShapesSelectionCleared: () => void;
  onRectangleDrawn: (type: string, points: [number, number][]) => void;
  onPointDrawn: (point: [number, number], angle: number) => void;
  onSegmentDrawn: (points: [number, number][]) => void;
  onMeasurerDrawn: (points: [number, number][]) => void;
  onShapeGeometryUpdated: (
    type: string,
    shapeId: string,
    geometry: Polygon | Point | LineStringGeoJSON,
    orientation?: any
  ) => void;
}

export function Editor({
  backgroundLidar,
  foregroundLidar,
  image,
  imageTiles,
  zones,
  stockZones,
  segments,
  measurers,
  turns,
  points,
  racks,
  devices,
  notes,
  selectedShapeIds,
  onShapeSelected,
  onShapeUnselected,
  onShapesSelectionCleared,
  onShapeGeometryUpdated,
  onDeletionRequired,
  activeTool,
  selectNewTool,
  onRectangleDrawn,
  onSegmentDrawn,
  onMeasurerDrawn,
  onPointDrawn,
}: EditorProps): JSX.Element {
  const classes = useStyles();

  const mapOpacity = useAppSelector((state) => state.tool.mapOpacity);

  const obstacleMapOpacity = useAppSelector((state) => state.tool.mapObstacleOpacity);

  const pointsSize = useAppSelector((state) => state.tool.pointsSize);
  const lineStrokeSize = useAppSelector((state) => state.tool.lineStrokeSize);

  const drawingEl = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);

  const drawing = useMemo(() => {
    if (!mounted || drawingEl.current === null) {
      return null;
    }

    return new EditorDrawing(
      drawingEl.current,
      onRectangleDrawn,
      onSegmentDrawn,
      onMeasurerDrawn,
      onPointDrawn,
      onShapeSelected,
      onShapeUnselected,
      onShapesSelectionCleared
    );
  }, [
    mounted,
    onRectangleDrawn,
    onSegmentDrawn,
    onMeasurerDrawn,
    onPointDrawn,
    onShapeUnselected,
    onShapeSelected,
    onShapesSelectionCleared,
  ]);

  useEffect(() => {
    if (drawing && !foregroundLidar && (imageTiles || (image && image.height && !imageTiles))) {
      // for 2D lib center the camera on the map image tiles if there is non lidar map
      // or center the camera on the first map image if there is non lidar map and no map image tiles
      CircuitService.getDrawingReference()?.centerZoomToBackground();
    }
  }, [drawing, foregroundLidar, image, imageTiles]);

  useEffect(() => {
    if (drawing && foregroundLidar) {
      drawing.drawLidar(foregroundLidar, LidarPosition.Foreground, obstacleMapOpacity, true);
    }
  }, [drawing, foregroundLidar, obstacleMapOpacity]);

  useEffect(() => {
    if (drawing && backgroundLidar && !localStorage.drawnBackgroundLidar) {
      drawing.drawLidar(backgroundLidar, LidarPosition.Background, mapOpacity);

      localStorage.drawnBackgroundLidar = true;
    }
  }, [drawing, backgroundLidar, mapOpacity]);
  useEffect(() => {
    if (drawing && backgroundLidar && localStorage.drawnBackgroundLidar) {
      drawing.drawLidar(backgroundLidar, LidarPosition.Background, mapOpacity, true);
    }
  }, [drawing, backgroundLidar, mapOpacity]);

  useEffect(() => {
    if (drawing && activeTool) {
      drawing.setActiveTool(activeTool);
    }
  }, [drawing, activeTool]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useMemo(() => {
    CircuitService.setDrawingReference(drawing);
  }, [drawing]);

  useMemo(() => {
    CircuitService.setOnShapeGeometryUpdatedRef(onShapeGeometryUpdated);
  }, [onShapeGeometryUpdated]);

  useShortcuts({ drawing });

  return (
    <div className={classes.drawContainer} ref={drawingEl}>
      {drawing && (
        <EditorDrawingComponent
          drawing={drawing}
          drawingEl={drawingEl.current}
          onSegmentDrawn={onSegmentDrawn}
          onRectangleDrawn={onRectangleDrawn}
          onMeasurerDrawn={onMeasurerDrawn}
          onPointDrawn={onPointDrawn}
          onShapeSelected={onShapeSelected}
          onShapeUnselected={onShapeUnselected}
          onShapesSelectionCleared={onShapesSelectionCleared}
          pointsSize={pointsSize}
          lineStrokeSize={lineStrokeSize}
        />
      )}
    </div>
  );
}
