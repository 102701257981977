import { Alert, Card, CardContent, CardHeader, MenuItem, Select } from '@mui/material';
import { useMemo } from 'react';
import type { SimulationTask } from 'simulation/simulation';
import store, { useAppSelector } from 'store';
import { canBeUndefined } from 'utils/ts/is-defined';
import type { PerfoMultipleFlowsStats } from './perfo-chart-report';
import { PerfoChart } from './perfo-chart-report';
import type { SimulationReportData } from './report-model';

interface TaskPerformanceAnalysisProps {
  tasksArr: SimulationTask[];
  selectedTaskId: string;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<string>>;
  robotsSimulationData?: SimulationReportData['robots'];
  simulationData?: SimulationReportData;
}

export function TaskPerformanceAnalysis(props: TaskPerformanceAnalysisProps): JSX.Element {
  const { tasksArr, selectedTaskId, setSelectedTaskId, robotsSimulationData, simulationData } = props;

  const selectedTask = useAppSelector((state) => canBeUndefined(state.simulation.tasks[selectedTaskId ?? '']));

  const perfoFlowsStatsForATask = useMemo(() => {
    if (!selectedTaskId) return;
    const task = store.getState().simulation.tasks[selectedTaskId];
    if (!task) return;

    const taskStartDate = task.startDate;
    if (!taskStartDate) return;

    const records = task.records ?? {};
    const recordsKeys = Object.keys(records);
    const sortedRecordsKeys = recordsKeys.sort((a, b) => records[a].timestamp - records[b].timestamp);
    const sortedRecordsKeysByCategory: Record<string, string[]> = {};
    sortedRecordsKeys.forEach((recordId) => {
      const record = records[recordId];
      if (!sortedRecordsKeysByCategory[record.category]) sortedRecordsKeysByCategory[record.category] = [];
      sortedRecordsKeysByCategory[record.category].push(recordId);
    });

    const taskStats: PerfoMultipleFlowsStats[0] = {};
    const recordsIdsToSkip = new Set<number>();
    Object.keys(sortedRecordsKeysByCategory).forEach((category) => {
      const recordsIds = sortedRecordsKeysByCategory[category];
      recordsIds.forEach((recordId, index) => {
        const record = records[recordId];
        const category = record.category;
        const recordID = record.recordID;

        if (recordsIdsToSkip.has(recordID)) return;

        const stepNb = Math.floor(recordsIds.indexOf(recordId) / 2);
        const uniqueCategoryName = `${category}_${stepNb}`;

        const beginEnd = record.beginend;
        let associatedRecord: typeof record | undefined = undefined;
        if (beginEnd === 0) {
          // begin
          for (let i = index + 1; i < recordsIds.length; i++) {
            const record2Id = recordsIds[i];
            const record2 = records[record2Id];
            if (record2.category === category) {
              if (record2.beginend === 1) {
                associatedRecord = record2;
              }

              break;
            }
          }
        } else if (beginEnd === 1) {
          // end
          for (let i = index - 1; i >= 0; i--) {
            const record2Id = recordsIds[i];
            const record2 = records[record2Id];
            if (record2.category === category) {
              if (record2.beginend === 0) {
                associatedRecord = record2;
              }
            }

            break;
          }
        }

        if (associatedRecord) {
          recordsIdsToSkip.add(associatedRecord.recordID);
        }

        if (beginEnd === 0 || (beginEnd === 1 && !associatedRecord)) {
          taskStats[uniqueCategoryName] = {
            label: record.categoryLabel,
            startTimestamp: record.timestamp - taskStartDate,
            endTimestamp: associatedRecord?.timestamp ? associatedRecord.timestamp - taskStartDate : undefined,
          };
        }
      });
    });

    return taskStats;
  }, [selectedTaskId]);

  const nbTasksDone = useMemo(() => {
    let nb = 0;
    tasksArr.forEach((task) => {
      if (task.endDate && task.startDate) nb++;
    });

    return nb;
  }, [tasksArr]);

  return (
    <Card variant="outlined" sx={{ marginTop: '1rem' }}>
      <CardHeader
        title="Task Performance Analysis"
        subheader={
          nbTasksDone > 0 && (
            <Select
              value={selectedTaskId}
              onChange={(e) => setSelectedTaskId(e.target.value)}
              sx={{
                marginBottom: '1rem',
                marginTop: '0.5rem',
              }}
              size="small"
            >
              {(tasksArr ?? []).map((task) => {
                if (!task.startDate || !task.endDate) return undefined;

                return (
                  <MenuItem key={task.id} value={task.id}>
                    #{task.id} - {task.taskName} (
                    {robotsSimulationData?.[task.robotID ? task.robotID - 1 : '']?.name ?? 'N/A'})
                  </MenuItem>
                );
              })}
            </Select>
          )
        }
      />
      <CardContent>
        {selectedTask && perfoFlowsStatsForATask && simulationData && nbTasksDone > 0 && (
          <PerfoChart
            perfoFlowStats={perfoFlowsStatsForATask}
            simulationEpoch={simulationData.simulationEpoch}
            flowName={selectedTask.taskName}
          />
        )}
        {nbTasksDone === 0 && (
          <Alert severity="info">No tasks have been completed yet, thereby there are no tasks to analyze.</Alert>
        )}
      </CardContent>
    </Card>
  );
}
