import React from 'react';
import { AuthCallBack } from 'components/core';
import { FullPageLayout } from 'components/layouts';

interface CallbackPageProps {}

export function CallbackPage(props: CallbackPageProps): JSX.Element {
  return (
    <FullPageLayout>
      <AuthCallBack />
    </FullPageLayout>
  );
}
