import { BALYO_METERS_TO_LIDAR, DISPLAY_UNIT_FACTOR } from 'models/drawings';
import type { Lidar } from 'models/maps';
import type { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import { lfsFileHeader } from 'utils/git';

export interface LidarBounds {
  hasValues: boolean;
  height: number;
  maxValue: number;
  maxX: number;
  maxY: number;
  minValue: number;
  minX: number;
  minY: number;
  width: number;
}

export class MapsService {
  public buildLidar(lines: string[], name = 'undefined'): Observable<Lidar> {
    return new Observable((observer: Observer<Lidar>) => {
      let [minX, minY] = parseLidarLine(lines[0]);
      let [maxX, maxY] = parseLidarLine(lines[0]);
      const coordinates: Lidar['coordinates'] = [];

      if (lines[0].startsWith(lfsFileHeader)) {
        observer.error(`Navigation lidar file corrupted because the LFS files have not been pulled.`);

        return;
      }

      lines.forEach((line, i) => {
        const [x, y, value] = parseLidarLine(line);

        if (isNaN(x) || isNaN(y)) {
          if (i !== lines.length - 1) {
            observer.error(`Navigation lidar file corrupted`);
          }

          return;
        }

        if (x < minX) {
          minX = x;
        }

        if (y < minY) {
          minY = y;
        }

        if (x > maxX) {
          maxX = x;
        }

        if (y > maxY) {
          maxY = y;
        }

        coordinates.push([x, y, value]);
      });

      const width = maxX - minX;
      const height = maxY - minY;

      const lidar: Lidar = {
        width,
        height,
        coordinates,
        name,
      };

      observer.next(lidar);

      return observer.complete();
    });
  }
}

export function parseLidarLine(line: string): [number, number, number?] {
  const [x, y, value] = line.split(';');

  // In order to increase rendering precision, meters are converted to centimeters
  return [
    (parseInt(x, 10) / BALYO_METERS_TO_LIDAR) * DISPLAY_UNIT_FACTOR,
    (parseInt(y, 10) / BALYO_METERS_TO_LIDAR) * DISPLAY_UNIT_FACTOR,
    value ? parseInt(value, 10) : undefined,
  ];
}

export function getLidarBounds(coordinates: Lidar['coordinates'] = []): LidarBounds {
  // Use first coord as reference
  let [minX, minY, minValue = Math.max()] = coordinates[0];
  let [maxX, maxY, maxValue = Math.min()] = coordinates[0];

  for (let i = 0, l = coordinates.length; i < l; ++i) {
    const coord = coordinates[i];
    const x = coord[0];
    const y = coord[1];
    const value = coord[2];

    if (x < minX) {
      minX = x;
    }

    if (y < minY) {
      minY = y;
    }

    if (value != null && value < minValue) {
      minValue = value;
    }

    if (x > maxX) {
      maxX = x;
    }

    if (y > maxY) {
      maxY = y;
    }

    if (value != null && value > maxValue) {
      maxValue = value;
    }
  }

  const width = maxX - minX;
  const height = maxY - minY;
  const hasValues = minValue !== Math.max() && maxValue !== Math.min();

  return { minX, maxX, minY, maxY, width, height, hasValues, minValue, maxValue };
}

/**
 * Return a map url base 64 (and its bounds) from a list of kiwi coordinates which is resize according the number of point by cm you want
 * @param coordinates Coordinates of the map under the kiwi format (each points is 4cm wide)
 * @returns Bounds: bounds of the generated map. Do not forget to resize them (if pointSizeCm is 1, you have to divide the bounds by 6.25 to have the real size in meter)
 */
export function generateMap(
  coordinates: Lidar['coordinates'],
  options?: { opacity: number }
): {
  bounds: { minX: number; minY: number; maxX: number; maxY: number };
  url: string;
} {
  const canvas = document.createElement('canvas');

  let { minX, minY, maxX, maxY } = getLidarBounds(coordinates);

  /*
    Convert data in meters
    1 map txt point <=> 4cm
    R (nbr of point for 1meter) <=> 1m
    R = 1pts * 1m / 4cm which means R = 1 / (4* 0.01)m which means R = 25 pts
  */
  const kiwiPointSizeCm = 4;

  const ratioLidarToMeter = 100;
  const realMinX = minX / ratioLidarToMeter;
  const realMinY = minY / ratioLidarToMeter;
  const realMaxX = maxX / ratioLidarToMeter;
  const realMaxY = maxY / ratioLidarToMeter;

  // Each map txt point is kiwiPointSizeCm cm. We thus compute bounds size for the reduced canvas map (in cm)
  // Thus we can keep a good map quality and reduce the final image
  minX = Math.round(minX / kiwiPointSizeCm);
  minY = Math.round(minY / kiwiPointSizeCm);
  maxX = Math.round(maxX / kiwiPointSizeCm);
  maxY = Math.round(maxY / kiwiPointSizeCm);

  // Compute lidar width and height for the canvas (in cm)
  const width = maxX - minX;
  const height = maxY - minY;

  // Based on max allowed size in browsers,
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#Maximum_canvas_size
  const MAX_DIM_ALLOWED = 15000;
  let ratio = MAX_DIM_ALLOWED / Math.max(width, height);

  if (ratio > 1) ratio = 1;

  const scaledWidth = Math.round(width * ratio);
  const scaledHeight = Math.round(height * ratio);

  // console.log('map width / h N scaled / scale', width, height, scaledWidth, scaledHeight);
  canvas.width = scaledWidth;
  canvas.height = scaledHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('No context to generate map');

  /* We need to transform the map coord to the canvas coord:
  The canvas area is EXACTLY the area of the map. The canvas coord starts at 0.
  We thus need to translate x and y of the map in order for the bottom left of the map to be in (0,0) of the canvas.
  it means: translate X to -minX and Y to -minY.
  since the Y axis of the canvas is directed toward the bottom, we need to set the yAxis to -1 (Yskewing)
  Now, our map is in the negative part of the canvas and will not be displayed. We thus need to translate the map with the height of the map along the Y axis
  in order to be in the positiv part of the canvas. it means translating to -height. the total translateY is thus: - (-minY - height)

  */
  const translateX = -minX;
  const translateY = minY + height;

  // X scaling, X skewing, Y skewing, Y scaling, X translation, Y translation
  ctx.setTransform(ratio, 0, 0, -ratio, translateX * ratio, translateY * ratio);

  // clear the canvas from previsously drawn lidar
  ctx.clearRect(minX, minY, maxX - minX, maxY - minY);

  // Disable the anti aliasing because it renders the map in a bluring way
  ctx.imageSmoothingEnabled = false;

  /*
  Paint the map on the canvas for each coordinates. 
  Each point on the map txt is 4px, so we paint each point on the canvas with a 4px square.
  */
  ctx.fillStyle = '#4c6bc0';
  const opacity = options?.opacity || 1;
  const opaSq = opacity * opacity;
  for (let i = 0, l = coordinates.length; i < l; i++) {
    const coord = coordinates[i];
    if (opacity !== 1) ctx.globalAlpha = Math.log(coord[2] || 1) * opaSq;
    ctx.fillRect(
      Math.round(coord[0] / kiwiPointSizeCm),
      Math.round(coord[1] / kiwiPointSizeCm),
      kiwiPointSizeCm / kiwiPointSizeCm,
      kiwiPointSizeCm / kiwiPointSizeCm
    );
  }

  // Generate url from canvas
  const res = {
    bounds: { minX: realMinX, minY: realMinY, maxX: realMaxX, maxY: realMaxY },
    url: canvas.toDataURL('image/webp'),
  };

  return res;
}
