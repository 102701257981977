/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { closeDialogAction } from 'actions';
import { createCellTemplateSuccessAction, deleteCellTemplateSuccessAction } from 'actions/cell-templates';
import { askUpdateRackAction } from 'actions/circuit';
import { saveRackAction } from 'actions/racks';
import * as d3 from 'd3';
import { addPositionToStation, removePositionFromStation } from 'flows/flows';
import { cloneDeep } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import type { CellAnomaly, RackCell, RackColumn, RackUpright, ShapeTypes, SlotTypes } from 'models/circuit';
import type { SnackbarKey } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import type { LoadedRack } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import useUndoable from 'use-undoable';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { getDefaultConveyorCellTemplate, getDefaultRackCellTemplate } from 'utils/circuit/default-circuit-shapes';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { checkUnicityRackPositionsName, computeRackCoordinates } from 'utils/circuit/racks';
import type { ConvertCellPositionToNameOptions } from 'utils/circuit/racks-naming';
import {
  convertCellPositionToName,
  generatePositionNames,
  isPositionNameNotAlreadyUsed,
  isValidPositionCellName,
} from 'utils/circuit/racks-naming';
import type { BlockDataPositionName } from 'utils/circuit/racks-naming.model';
import { isDefined } from 'utils/ts/is-defined';
import { RackEditionTopBar, zoomRackEdition } from '.';
import { RackEditionCellTemplate } from './rack-edition-celltemplate';
import { RackEditionRackView } from './rack-edition-rackview';

const epsilon = 1e-5;
const nbMaxIter = 1000;

interface SettingsDialogProps {
  closeDialog?: () => void;
  zoomToCellId?: string;
}

/**
 * actions to update the state of the racks (NOT Redux actions)
 * it is used to update the preview and the state of the rack inside the RackEditionDialog
 * A Redux action has to be dispatched to actually update the Redux store
 */
export interface ActionsRack {
  /** function to call to update a column */
  updateColumn: (columnId: string, newState: RackColumn) => void;
  /** function to call to update an upright */
  updateUpright: (uprightIndex: number, newState: RackUpright) => void;
  /** function to call to update all the uprights */
  updateAllUprights: (newState: RackUpright[]) => void;
  /** function to call to update a cell template */
  changeRackEditionMenu: (newEditCellTemplate: null | string) => void;
  /** function to call to delete a certain column in the rack */
  deleteColumn: (columnIndex: number) => void;
  /** function to call to add a column in the rack
   * the column will be copied from the column columnIndex and will be inserted at the columnIndex postion
   * it also add an upright in the same manner
   */
  addColumn: (columnIndex: number) => void;
  /** function to call to change which cell template we will assign next */
  selectCellTemplateToAssign: (cellTemplateId: string | null) => void;
  /** function to call when a cell has been clicked */
  cellClick: (cell: RackCell, column: RackColumn, e?: MouseEvent) => void;
  /** function to call to update a cell template */
  cellTemplateChange: (newValue: boolean) => void;
  /** function to change the number of columns */
  updateNbColumns: (newNbColumns: number) => void;
  /** function to call to update several columns at once */
  updateColumns: (newColumns: RackColumn[]) => void;
  /** function to call to update the rack */
  updateRack: (newRack: LoadedRack) => void;
  /** function to compute the position of the columns */
  computeColumnsPosition: (newColumns: RackColumn[], newUprights?: RackUpright[]) => RackColumn[];
  /** function to toggle if we display the name of the positions or not */
  toggleDisplayPositionNames: () => void;
  /** apply a value to all the columns that have at least one selected cell */
  applyColumnValueToAllSelectedColumns: (
    columnValue: Partial<RackColumn>,
    linkedPropertiesToChange: Partial<RackColumn['linkedProperties']>
  ) => void;
  /** apply a value to all the selected cells */
  applyCellValueToAllSelectedCells: (rackCellNewValues: Partial<RackCell>) => void;
  /** change the name of a cell */
  changeCellName: (cellId: string, newNames: RackCell['names'][0], loadIndex: number) => boolean;
  /* toggle disabled property of a cell */
  toggleCellDisabled: (cellId: string, newNames: RackCell['names'][0], loadIndex: number) => boolean;
  /**
   * Returns data about the selected cells
   *
   * Returns:
   * [0] the cell id
   * [1] the cell abscissa in the rack (x)
   * [2] the cell ordinate in the rack (y)
   */
  getSelectedCells: () => [string, number, number][];
  /** generate and assign a name (string) to cells from a rule */
  assignNameToCells: (
    assignTo: 'selection' | 'all',
    rules: BlockDataPositionName[] | null,
    applyToAllLoads?: boolean
  ) => Promise<void>;

  /**
   * Alter the rack to be a mirror of itself
   * The first column will be the last one, the second one will be the second to last one, etc.
   */
  mirrorRack: () => void;

  validateRack: (close?: boolean) => void;
}

export interface CellAnomaliesDict {
  [k: string]: CellAnomaly[];
}

export type StationPositions = {
  selectedStation: string;
  id: string;
  type: ShapeTypes.PointShape | ShapeTypes.StockZoneShape | ShapeTypes.RackShape | SlotTypes;
};

export type SlotName = {
  id: string;
  value: string;
  user: boolean;
  disabled?: boolean | undefined;
};

let previousRackPastLength = 0;
export default function RackEditionDialog({ closeDialog, zoomToCellId }: SettingsDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const rackId = useAppSelector((state) => state.local.selectedShapesData)[0].id;
  const rackFromStore = useAppSelector((state) => state.circuit.present.racks.entities[rackId]);

  const [rack, setRack, { undo: undoRack, redo: redoRack, canRedo, canUndo, reset: resetRackHistory, past: rackPast }] =
    useUndoable(rackFromStore, { behavior: 'destroyFuture' });

  const cellTemplatesIds = useAppSelector((state) => state.circuit.present.cellTemplates.ids);
  const cellTemplates = useAppSelector((state) => state.circuit.present.cellTemplates.entities);
  const [editCellTemplate, setEditCellTemplate] = useState<null | string>(null); // null = rack edition, template id = cell template edition of the related cell template
  const [selectedCellTemplate, setSelectedCellTemplate] = useState<null | string>(null); // null = no cell template selected, template id = cell template selected
  const columns = rack.properties.columns;
  const uprights = rack.properties.uprights;
  const racks = useAppSelector((state) => state.circuit.present.racks.entities);

  const isConveyor = !!rack.properties.conveyor;

  const [reactRackPending, startTransition] = useTransition();

  const [cellTemplateChanged, setCellTemplateChanged] = useState(false);

  /**
   * Array of coordinates of the selected cell ([x, y])[]
   */
  const [selectedCells, setSelectedCells] = useState<number[][]>([]);
  const [displayPositionNames, setDisplayPositionNames] = useState(true);

  /** the names of all the cells in this rack */
  const cellsNames = useRef<Record<string, string[]>>({});

  const autoZoomToForm = useRef(!zoomToCellId);

  const undoSnackBarKey = useRef<SnackbarKey | undefined>(undefined);

  const stations = useAppSelector((state) => state.flows.stations);

  const [stationPositions, setStationPositions] = useState<StationPositions[]>([]);

  const [userRemovedStationPositions, setUserRemovedStationPositions] = useState(false);

  // Remove unwanted in-between batched actions in the undo/redo history
  useEffect(() => {
    if (rackPast.length > previousRackPastLength + 1) {
      rackPast.splice(previousRackPastLength + 1, rackPast.length - previousRackPastLength);
    }

    previousRackPastLength = rackPast.length;
  }, [rackPast.length]);

  useHotkeys(
    'Ctrl+z',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      // github link to the issue https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1040
      if (event.key.toLowerCase() !== 'z' || editCellTemplate) return;
      if (undoSnackBarKey?.current) SnackbarUtils.closeSnackbar(undoSnackBarKey.current);

      if (!canUndo) {
        undoSnackBarKey.current = SnackbarUtils.toast('Rack undo history is empty', {
          variant: 'info',
          autoHideDuration: 1000,
        });

        return;
      }

      undoRack();
      undoSnackBarKey.current = SnackbarUtils.toast('Undo changes in rack', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
    [editCellTemplate, canUndo]
  );

  useHotkeys(
    'Ctrl+y',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      // github link to the issue https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1040
      if (event.key.toLowerCase() !== 'y' || editCellTemplate) return;
      if (undoSnackBarKey?.current) SnackbarUtils.closeSnackbar(undoSnackBarKey.current);

      if (!canRedo) {
        undoSnackBarKey.current = SnackbarUtils.toast('Rack redo history is empty', {
          variant: 'info',
          autoHideDuration: 1000,
        });

        return;
      }

      redoRack();
      undoSnackBarKey.current = SnackbarUtils.toast('Redo changes in rack', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
    [editCellTemplate, canRedo]
  );

  const handleCellTemplateChanged = useCallback(
    (newValue: boolean) => {
      if (cellTemplateChanged !== newValue) {
        setCellTemplateChanged(newValue);
      }
    },
    [cellTemplateChanged]
  );

  const handleClose = useCallback(
    (e: unknown, reason: string): void => {
      const isRackUnchanged = JSON.stringify(rack) === JSON.stringify(rackFromStore);
      if (isRackUnchanged) {
        dispatch(closeDialogAction());

        return;
      }

      if (reason === 'backdropClick') return;

      confirm({
        title: 'Do you want to discard your changes?',
        description: 'If you close the dialog, your changes will be lost.',
        allowClose: false,
        cancellationText: 'Continue editing',
        confirmationText: 'Close without saving',
        confirmationButtonProps: {
          color: 'error',
          sx: {
            textTransform: 'none',
          },
        },
        cancellationButtonProps: {
          sx: {
            textTransform: 'none',
          },
        },
      })
        .then(() => {
          dispatch(closeDialogAction());
        })
        .catch(() => undefined);
    },

    [confirm, dispatch, rack, rackFromStore]
  );

  const [currentName, setCurrentName] = useState<string>(rack.properties.name);
  const [newRackNameOk, setNewRackNameOk] = useState(true);

  const checkRackName = useCallback(
    (newName: string) => {
      if (!rack) return false;
      const allSlotsNamesOfThisRack = Object.values(cellsNames.current).flatMap((cellNames) => cellNames);
      const newRackNameAndSlotNames = [...allSlotsNamesOfThisRack, newName];
      const isRackNameOk = areAllShapeNamesUnique(newRackNameAndSlotNames, [rack.id as string], {
        ignoreDuplicatesBefore: true,
      });

      return isRackNameOk;
    },
    [rack]
  );

  useEffect(() => {
    setNewRackNameOk(true);

    startTransition(() => {
      const isRackNameOk = checkRackName(currentName);
      if (!isRackNameOk) {
        setNewRackNameOk(false);
      }
    });
  }, [checkRackName, currentName]);

  /** mutate the array passed in argument */
  const computeColumnsPosition = useCallback(
    (newColumns: RackColumn[], newUprights: RackUpright[] = uprights): RackColumn[] => {
      newColumns[0] = {
        ...newColumns[0],
        x: newUprights[0].enabled ? newUprights[0].width : 0,
      };

      for (let i = 1; i < newColumns.length; i++) {
        newColumns[i] = {
          ...newColumns[i],
          x: newColumns[i - 1].x + (newUprights[i].enabled ? newUprights[i].width : 0) + newColumns[i - 1].width,
        };
      }

      return newColumns;
    },
    [uprights]
  );

  const updateColumn = useCallback(
    (columnId: string, newState: RackColumn) => {
      startTransition(() => {
        setRack((state) => {
          const columns = state.properties.columns;

          const columnIndex = columns.findIndex((column) => column.id === columnId);

          if (columnIndex === -1) {
            // eslint-disable-next-line no-console
            console.error(`Column ${columnId} not found in rack ${rackId}`);

            return state;
          }

          const formerWidth = columns[columnIndex].width;
          // const formerNbLevels = columns[columnIndex].nbLevels;
          const formerExtendedLength = columns[columnIndex].extendedLength;
          const formerStartHeight = columns[columnIndex].startHeight;

          let newColumns = [...columns];
          newColumns[columnIndex] = newState;

          if (newState.linkedProperties.width && newColumns[columnIndex].width !== rack.properties.defaultColumnWidth) {
            // when we click on the link button
            newColumns[columnIndex].width = rack.properties.defaultColumnWidth;

            newColumns = computeColumnsPosition(newColumns);
          }

          if (
            newState.linkedProperties.nbLevels &&
            newColumns[columnIndex].nbLevels !== rack.properties.defaultNbLevels
          ) {
            // when we click on the link button
            newColumns[columnIndex].nbLevels = rack.properties.defaultNbLevels;

            newColumns[columnIndex].cells = updateCellsAfterChangeNbLevels(
              newColumns[columnIndex].nbLevels,
              newColumns[columnIndex].cells
            );
          }

          if (newState.linkedProperties.extendedLength) {
            // when we click on the link button
            newColumns[columnIndex].extendedLength = state.properties.defaultExtendedLength;
          }

          if (newState.linkedProperties.startHeight) {
            // when we click on the link button
            newColumns[columnIndex].startHeight = 0;
          }

          if (Math.abs(newState.width - formerWidth) > epsilon) {
            // width of the column changed, we need to recompute the x position of the other columns
            newColumns = computeColumnsPosition(newColumns);
          }

          if (newState.nbLevels !== newColumns[columnIndex].cells.length) {
            const newNbLevels = newState.nbLevels;

            newColumns[columnIndex].cells = updateCellsAfterChangeNbLevels(newNbLevels, newColumns[columnIndex].cells);
          }

          if (Math.abs(newState.extendedLength - formerExtendedLength) > epsilon) {
            newColumns[columnIndex].extendedLength = newState.extendedLength;
          }

          if (Math.abs(newState.startHeight - formerStartHeight) > epsilon) {
            newColumns[columnIndex].startHeight = newState.startHeight;
          }

          return {
            ...state,
            properties: {
              ...state.properties,
              columns: newColumns,
            },
          };
        });
      });
    },
    [computeColumnsPosition, rack, rackId]
  );

  const updateColumns = useCallback(
    (newColumns: RackColumn[]) => {
      // setColumnsToUpdate([...columnsToUpdate, ...newColumns]);
      newColumns.forEach((column) => updateColumn(column.id, column));
    },
    [updateColumn]
  );

  const updateUpright = useCallback(
    (uprightIndex: number, newState: RackUpright) => {
      startTransition(() => {
        setRack((state) => {
          const newUprights = [...state.properties.uprights];
          newUprights[uprightIndex] = newState;

          let newColumns = [...state.properties.columns];
          newColumns = computeColumnsPosition(newColumns, newUprights);

          return {
            ...state,
            properties: {
              ...state.properties,
              uprights: newUprights,
              columns: newColumns,
            },
          };
        });
      });
    },
    [computeColumnsPosition]
  );

  const updateAllUprights = useCallback(
    (newUprights: RackUpright[]) => {
      newUprights.forEach((newUpright, index) => updateUpright(index, newUpright));
    },
    [updateUpright]
  );

  const changeRackEditionMenu = useCallback((newEditCellTemplate: null | string) => {
    setEditCellTemplate(newEditCellTemplate);
  }, []);

  const deleteColumn = useCallback(
    (columnIndex: number, nbToDelete = 1) => {
      let newColumns = [...columns];
      newColumns.splice(columnIndex, nbToDelete);

      const newUprights = [...uprights];
      newUprights.splice(columnIndex, nbToDelete);

      if (newUprights.length !== newColumns.length + 1) {
        // eslint-disable-next-line no-console
        console.error(
          `Incorrect number of uprights in the rack ${rack.id}; nb of columns: ${newColumns.length}, nb of uprights: ${newUprights.length}`
        );
      }

      newColumns = computeColumnsPosition(newColumns, newUprights);

      startTransition(() => {
        setRack({
          ...rack,
          properties: {
            ...rack.properties,
            columns: newColumns,
            uprights: newUprights,
          },
        });
      });
    },
    [columns, computeColumnsPosition, rack, uprights]
  );

  const addColumn = useCallback(
    (columnIndex: number, nbToAdd = 1) => {
      const columnsToAdd: RackColumn[] = [];
      for (let i = 0; i < nbToAdd; i++) {
        const newColumn = cloneDeep(columns[columnIndex !== 0 ? columnIndex - 1 : 0]);
        newColumn.id = generateShapeId();
        newColumn.cells = newColumn.cells.map((cell) => {
          cell.id = generateShapeId();
          cell.cellTemplate = undefined;
          cell.names = [];

          return cell;
        });
        newColumn.extendedLengthSegments = [];

        columnsToAdd.push(newColumn);
      }

      // we also need to insert an upright
      const uprightsToAdd: RackUpright[] = [];
      for (let i = 0; i < nbToAdd; i++) {
        const newUpright = cloneDeep(uprights[columnIndex]);
        newUpright.enabled = true; // a new upright is always enabled

        uprightsToAdd.push(newUpright);
      }

      let newColumns = [...columns];
      newColumns.splice(columnIndex, 0, ...columnsToAdd);

      const newUprights = [...uprights];
      newUprights.splice(columnIndex, 0, ...uprightsToAdd);

      newColumns = computeColumnsPosition(newColumns, newUprights);

      startTransition(() => {
        setRack({
          ...rack,
          properties: {
            ...rack.properties,
            columns: newColumns,
            uprights: newUprights,
          },
        });
      });
    },
    [columns, computeColumnsPosition, rack, uprights]
  );

  const selectCellTemplateToAssign = useCallback((cellTemplateId: string | null) => {
    setSelectedCellTemplate(cellTemplateId);
  }, []);

  const cellClick = useCallback(
    (cell: RackCell, column: RackColumn, e?: MouseEvent) => {
      const cellIndex = column.cells.findIndex((c) => c.id === cell.id);
      const columnIndex = columns.findIndex((c) => c.id === column.id);

      // if a cell templated is selected to be assigned, we (de)assign it to the cell
      if (selectedCellTemplate) {
        const newCell: RackCell = {
          ...cell,
          cellTemplate: cell.cellTemplate === selectedCellTemplate ? undefined : selectedCellTemplate,
          names: [],
        };

        const newCells = [...column.cells];
        newCells[cellIndex] = newCell;
        const newColumn = {
          ...column,
          cells: newCells,
        };

        updateColumn(column.id, newColumn);
      }

      if (e && e.shiftKey && selectedCells.length) {
        // we select all the cells between the selected cell and the clicked cell
        const newSelectedCells = [selectedCells[0]];
        const firstCell = newSelectedCells[0];

        for (
          let i = firstCell[0] < columnIndex ? firstCell[0] : columnIndex;
          i <= (firstCell[0] < columnIndex ? columnIndex : firstCell[0]);
          i++
        ) {
          for (
            let j = firstCell[1] > cellIndex ? firstCell[1] : cellIndex;
            j >= (firstCell[1] > cellIndex ? cellIndex : firstCell[1]);
            j--
          ) {
            newSelectedCells.push([i, j]);
          }
        }

        setSelectedCells(newSelectedCells);

        // we apply the cell template to the selection?
        if (!selectedCellTemplate) return;
        const newColumns = [...columns];
        newSelectedCells.forEach((cellCoords) => {
          const column = newColumns[cellCoords[0]];
          if (!column) return;
          const cell = column.cells[cellCoords[1]];
          if (!cell) return;

          const newCell: RackCell = {
            ...cell,
            cellTemplate: selectedCellTemplate,
          };
          const newCells = [...column.cells];
          newCells[cellCoords[1]] = newCell;
          const newColumn = {
            ...column,
            cells: newCells,
          };

          newColumns[cellCoords[0]] = newColumn;
        });

        startTransition(() => {
          setRack({
            ...rack,
            properties: {
              ...rack.properties,
              columns: newColumns,
            },
          });
        });
      } else if (e && e.ctrlKey) {
        const cellAlreadySelected = selectedCells.some(
          (cellCoords) => cellCoords[0] === columnIndex && cellCoords[1] === cellIndex
        );
        if (cellAlreadySelected) {
          const newSelectedCells = selectedCells.filter(
            (cellCoords) => !(cellCoords[0] === columnIndex && cellCoords[1] === cellIndex)
          );
          setSelectedCells(newSelectedCells);
        } else {
          const newSelectedCells = [...selectedCells, [columnIndex, cellIndex]];
          setSelectedCells(newSelectedCells);
        }
      } else {
        setSelectedCells([[columnIndex, cellIndex]]);
      }
    },
    [columns, rack, selectedCellTemplate, selectedCells, updateColumn]
  );

  const assignToWholeRack = useCallback(() => {
    startTransition(() => {
      setRack((state) => {
        const newColumns: RackColumn[] = state.properties.columns.map((column) => ({
          ...column,
          cells: column.cells.map((cell) => {
            return {
              ...cell,
              names: [],
              cellTemplate: selectedCellTemplate,
            } as RackCell;
          }),
        }));

        return {
          ...state,
          properties: {
            ...state.properties,
            columns: newColumns,
          },
        };
      });

      setSelectedCellTemplate(null);
    });
  }, [selectedCellTemplate]);

  const updateNbColumns = useCallback(
    (newNbColumns: number) => {
      const n = Math.abs(newNbColumns - columns.length);

      if (newNbColumns > columns.length) {
        // then we need to add the missing columns
        addColumn(columns.length - 1, n);
      } else if (newNbColumns < columns.length) {
        // then we need to delete the extra columns
        deleteColumn(columns.length - n, n);
      }
    },
    [addColumn, columns.length, deleteColumn]
  );

  const updateRack = useCallback((newRack: LoadedRack) => {
    // if (newRack.properties.columns !== columns || newRack.properties.uprights !== uprights) {
    //   computeColumnsPosition(newRack.properties.columns);
    // }
    startTransition(() => {
      setRack(newRack);
    });
  }, []);

  const toggleDisplayPositionNames = useCallback(() => {
    startTransition(() => setDisplayPositionNames((s) => !s));
  }, []);

  const applyColumnValueToAllSelectedColumns = useCallback(
    (columnValue: Partial<RackColumn>, linkedPropertiesToChange: Partial<RackColumn['linkedProperties']>) => {
      const selectedColumnsIdx = new Set(selectedCells.map((s) => s[0]));

      const columnsToEdit = columns
        .map((column, index) => {
          if (selectedColumnsIdx.has(index)) {
            const updatedLinkedProperties = {
              startHeight: linkedPropertiesToChange.startHeight ?? column.linkedProperties.startHeight,
              width: linkedPropertiesToChange.width ?? column.linkedProperties.width,
              nbLevels: linkedPropertiesToChange.nbLevels ?? column.linkedProperties.nbLevels,
              extendedLength: linkedPropertiesToChange.extendedLength ?? column.linkedProperties.extendedLength,
            };

            return {
              ...column,
              ...columnValue,
              linkedProperties: updatedLinkedProperties,
            };
          }

          return null;
        })
        .filter(isDefined);

      columnsToEdit.forEach((column) => updateColumn(column.id, column));
    },
    [columns, selectedCells, updateColumn]
  );

  const applyCellValueToAllSelectedCells = useCallback(
    (newRackCellValues: Partial<RackCell>) => {
      const columnsToEdit = columns
        .map((column, indexColumn) => {
          const cellsToEdit = column.cells
            .map((cell, indexCell) => {
              const selectedCell = selectedCells.find((c) => c[0] === indexColumn && c[1] === indexCell);

              if (selectedCell) {
                const newCell: RackCell = {
                  ...cell,
                  ...newRackCellValues,
                };

                return [indexCell, newCell];
              }

              return null;
            })
            .filter((c): c is [number, RackCell] => !!c);

          if (cellsToEdit.length) {
            return {
              ...column,
              cells: column.cells.map((cell, indexCell) => {
                const newCell = cellsToEdit.find((c) => c[0] === indexCell);
                if (newCell) {
                  return newCell[1];
                }

                return cell;
              }),
            };
          }

          return null;
        })
        .filter((c): c is RackColumn => !!c);

      columnsToEdit.forEach((column) => updateColumn(column.id, column));
    },
    [columns, selectedCells, updateColumn]
  );

  const computeAutoRackCellNames = useCallback(
    (r: LoadedRack, newRackName?: string): LoadedRack => {
      const newRack = {
        ...r,
        properties: {
          ...r.properties,
          name: newRackName ?? currentName,
          columns: r.properties.columns.map((column, columnIndex) => ({
            ...column,
            cells: column.cells.map((cell, cellIndex) => {
              if (!cell.cellTemplate) return cell;

              const formerNames = cell.names.map((name) => name.map((n) => (n.user ? n.value : undefined)));
              const cellTemplate = cellTemplates[cell.cellTemplate];

              return {
                ...cell,
                cellTemplate: cellTemplate ? cell.cellTemplate : undefined,
                names: cellTemplate
                  ? generatePositionNames(
                      { ...r, properties: { ...r.properties, name: currentName } },
                      cell,
                      {
                        column: columnIndex,
                        level: cellIndex,
                      },
                      cellTemplate,
                      formerNames
                    ).map((name, i) =>
                      name.map((n, j) => {
                        const formerName = formerNames?.[i]?.[j];
                        const disabled = cell.names?.[i]?.[j]?.disabled || undefined;
                        const id = cell.names?.[i]?.[j]?.id ? cell.names[i][j].id : generateShapeId();

                        return {
                          value: formerName ?? n,
                          user: !!formerName,
                          disabled,
                          id,
                        };
                      })
                    )
                  : [],
              };
            }),
          })),
        },
      };

      return newRack;
    },
    [cellTemplates, currentName]
  );

  useEffect(() => {
    startTransition(() => {
      if (currentName !== rack.properties.name) return;

      startTransition(() => {
        setRack((r) => computeAutoRackCellNames(r));
      });
    });
  }, [cellTemplates]);

  useEffect(() => {
    startTransition(() => {
      columns.forEach((column) => {
        column.cells.forEach((cell) => {
          if (cell.cellTemplate) {
            startTransition(() => {
              setRack((r) => computeAutoRackCellNames(r));
            });
          }
        });
      });
    });
  }, [columns]);

  useEffect(() => {
    if (!currentName || currentName === rack.properties.name) return;

    startTransition(() => {
      setRack((r) => computeAutoRackCellNames(r, currentName));
    });
  }, [currentName]);

  // Reset the rack history when opening the dialog because of the above code which updates the rack at the opening
  useEffect(() => {
    resetRackHistory(rack);
  }, []);

  const changeCellName = useCallback(
    (cellId: string, newNames: RackCell['names'][0], loadIndex: number): boolean => {
      const newNamesValues = newNames.map((name) => name.value);
      const racksArr = Object.values(racks);

      const upToDateRack = computeAutoRackCellNames(rack, currentName);

      const isUnique = isPositionNameNotAlreadyUsed(newNamesValues, upToDateRack, racksArr, cellId, cellsNames.current);

      if (!isUnique) return false;

      startTransition(() => {
        setRack((r) => ({
          ...r,
          properties: {
            ...r.properties,
            columns: r.properties.columns.map((column) => ({
              ...column,
              cells: column.cells.map((cell) => {
                if (cell.id !== cellId) return cell;

                const newNamesCell = [...cell.names];
                newNamesCell[loadIndex] = newNames;

                return {
                  ...cell,
                  names: newNamesCell,
                };
              }),
            })),
          },
        }));
      });

      return true;
    },
    [computeAutoRackCellNames, currentName, rack, racks]
  );

  const toggleCellDisabled = useCallback(
    (cellId: string, newNames: RackCell['names'][0], loadIndex: number): boolean => {
      startTransition(() => {
        setRack((r) => ({
          ...r,
          properties: {
            ...r.properties,
            columns: r.properties.columns.map((column) => ({
              ...column,
              cells: column.cells.map((cell) => {
                if (cell.id !== cellId) return cell;

                const newNamesCell = [...cell.names];
                newNamesCell[loadIndex] = newNames;

                return {
                  ...cell,
                  names: newNamesCell,
                };
              }),
            })),
          },
        }));
      });

      return true;
    },
    []
  );

  const getSelectedCells = useCallback(() => {
    return selectedCells
      .map((c) => {
        const columnIndex = c[0];
        const cellIndex = c[1];
        const cellId = columns[c[0]]?.cells[c[1]]?.id;

        if (columnIndex === undefined || cellIndex === undefined || cellId === undefined) {
          return null;
        }

        return [cellId, columnIndex, cellIndex] as [string, number, number];
      })
      .filter(isDefined);
  }, [columns, selectedCells]);

  const selectedCellsForStation = getSelectedCells();

  const getAllCells = useCallback(() => {
    return columns.flatMap((column) => column.cells.flatMap((cell) => cell.id));
  }, [columns]);

  const allCellsId = useMemo(() => getAllCells(), [getAllCells]);

  const assignNameToCells = useCallback(
    async (
      assignTo: 'selection' | 'all',
      rules: BlockDataPositionName[] | null,
      selectedLoadOnly = true
    ): Promise<void> => {
      const cellsToName =
        assignTo === 'selection' ? getSelectedCells().map((selectedCell) => selectedCell[0]) : getAllCells();
      const cellsToNameSet = new Set(cellsToName);

      let nbNoUniqueNames = 0;
      let nbTooLongNames = 0;

      const newGeneratedNames: string[] = [];

      const racksArr = Object.values(racks);

      const newRack: LoadedRack = {
        ...rack,
        properties: {
          ...rack.properties,
          columns: await Promise.all(
            rack.properties.columns.map(async (column, columnIndex) => {
              await new Promise((resolve) => setTimeout(resolve, 0));

              const newCells = await Promise.all(
                column.cells.map(async (cell, level) => {
                  if (!cellsToNameSet.has(cell.id)) return cell;

                  await new Promise((resolve) => setTimeout(resolve, 0));

                  const cellTemplateId = cell.cellTemplate;
                  if (!cellTemplateId) return cell;
                  const cellTemplate = cellTemplates[cellTemplateId];
                  if (!cellTemplate) return cell;
                  const selectedLoad = cellTemplate.selectedLoad;
                  const loads = cellTemplate.loads;

                  const cellId = cell.id;

                  const names = loads.map((load, loadIndex) => {
                    if (selectedLoadOnly && loadIndex !== selectedLoad) {
                      // if selectedLoadOnly is true, we only generate names for the selected load, otherwise for all the loads
                      return cell.names[loadIndex];
                    }

                    const positionNames = new Array(load.N).fill(0).map((_, palletIndex) => {
                      const props: ConvertCellPositionToNameOptions = {
                        rack,
                        level,
                        cell,
                        palletIndex,
                        columnIndex,
                        load: loads[loadIndex],
                        cellTemplates,
                        cellTemplate,
                      };

                      let isUnique = false;
                      let i = 0;
                      let newName: string;
                      let newNameOriginal = 'error';
                      // we generate the name, then we check if the name is already used, if we
                      // convert the name to theName_0 and check again, if it's still not unique we go for theName_1, theName_2 and so on
                      do {
                        newName =
                          i === 0
                            ? rules
                              ? convertCellPositionToName(rules, props)
                              : generatePositionNames(
                                  rack,
                                  cell,
                                  {
                                    column: columnIndex,
                                    level,
                                  },
                                  cellTemplate
                                )[selectedLoad][palletIndex]
                            : `${newNameOriginal}_${i}`;

                        isUnique = isPositionNameNotAlreadyUsed(
                          [newName],
                          rack,
                          racksArr,
                          cellId,
                          cellsNames.current,
                          newGeneratedNames,
                          {
                            displaySnackbar: false,
                          }
                        );

                        if (!isUnique && i++ === 0) {
                          newNameOriginal = newName;
                          nbNoUniqueNames++;
                        }
                      } while (!isUnique && i < nbMaxIter);

                      if (!isUnique) {
                        // eslint-disable-next-line no-console
                        console.error(`Could not find a unique name for ${newName} after ${nbMaxIter} iterations`);

                        SnackbarUtils.error(`A unique name for ${newName} has not been found.`);
                      }

                      const isValid = isValidPositionCellName(newName);
                      if (!isValid) {
                        nbTooLongNames++;

                        return cell.names?.[loadIndex]?.[palletIndex];
                      }

                      newGeneratedNames.push(newName);

                      const previousId = cell.names?.[loadIndex]?.[palletIndex]?.id;

                      const newPositionName: SlotName = {
                        user: !!rules,
                        value: newName,
                        id: previousId ?? generateShapeId(),
                      };

                      return newPositionName;
                    });

                    return positionNames;
                  });

                  return {
                    ...cell,
                    names,
                  };
                })
              );

              return {
                ...column,
                cells: newCells,
              };
            })
          ),
        },
      };

      startTransition(() => {
        setRack(newRack);
      });

      if (nbNoUniqueNames) {
        // eslint-disable-next-line no-console
        console.warn(
          `There were ${nbNoUniqueNames} names that were not unique, they were renamed to theName_0, theName_1, etc.`
        );

        SnackbarUtils.warning(`${nbNoUniqueNames} names were not unique and were modified`);
      }

      if (nbTooLongNames) {
        // eslint-disable-next-line no-console
        console.warn(
          `There were ${nbTooLongNames} names that were too long, the new rule has not been applied to them`
        );

        SnackbarUtils.warning(`${nbTooLongNames} names were too long and the new rule has not been applied to them`);
      }
    },
    [cellTemplates, getAllCells, getSelectedCells, rack, racks]
  );

  const mirrorRack = useCallback(() => {
    startTransition(() => {
      setRack((r) => {
        let x = 0;

        const newUprights = structuredClone(r.properties.uprights).reverse();

        const newColumns = structuredClone(r.properties.columns)
          .sort((columnA, columnB) => columnA.x - columnB.x)
          .reverse()
          .map((column, columnIndex) => {
            x += newUprights[columnIndex].enabled ? newUprights[columnIndex].width : 0;
            column.x = x;
            x += column.width;

            column.cells = column.cells.map((cell) => {
              cell.names = cell.names.map((name) => {
                name.reverse();

                return name;
              });

              return cell;
            });

            return column;
          });

        // const newColumns = r.properties.columns.map((column, columnIndex) => {
        //   const mirrorIndex = r.properties.columns.length - columnIndex - 1;
        //   const newColumn = structuredClone(r.properties.columns[mirrorIndex]);
        //   const newUpright = r.properties.uprights[mirrorIndex];

        //   x += newUpright.enabled ? newUpright.width : 0;
        //   newColumn.x = x;
        //   x += newColumn.width;

        //   const newCells = newColumn.cells.map((cell) => {
        //     return {
        //       ...cell,
        //       names: cell.names.map((name, slotInCellIndex) => {
        //         // const mirrorSlotInCellIndex = name.length - slotInCellIndex - 1;
        //         // const newName = structuredClone(name[mirrorSlotInCellIndex]);

        //         // return newName;
        //         return structuredClone(name).reverse();
        //       }),
        //     };
        //   });
        //   column.cells = newCells;

        //   return newColumn;
        // });

        // const newUprights = r.properties.uprights.map((upright, uprightIndex) => {
        //   const mirrorIndex = r.properties.uprights.length - uprightIndex - 1;
        //   const newUpright = structuredClone(r.properties.uprights[mirrorIndex]);

        //   return newUpright;
        // });

        return {
          ...r,
          properties: {
            ...r.properties,
            columns: newColumns,

            uprights: newUprights,
          },
        };
      });
    });
  }, []);

  const validateRack = useCallback(
    (close = true) => {
      const isRackUnchanged = JSON.stringify(rack) === JSON.stringify(rackFromStore);

      if (isRackUnchanged && stationPositions.length < 1) {
        if (close) dispatch(closeDialogAction());

        return;
      }

      // first we check that the rack name is ok
      const isRackNameOk = checkRackName(currentName);
      if (!isRackNameOk) {
        SnackbarUtils.warning(`Rack name ${currentName} is already used in the project`);

        // eslint-disable-next-line no-console
        console.warn(`Rack name ${currentName} is already used in the project`);

        return;
      }

      const coordinates = computeRackCoordinates(rack, columns, uprights);

      // and the properties
      const newRack: LoadedRack = {
        ...rack,
        geometry: {
          ...rack.geometry,
          coordinates: [coordinates],
        },
        properties: {
          ...rack.properties,
          name: currentName,
          columns,
          uprights,
        },
      };

      // we remove all the cell templates that are not used anymore
      newRack.properties.columns = newRack.properties.columns.map((column) => {
        return {
          ...column,
          cells: column.cells.map((cell) => {
            if (cell && (!cell?.cellTemplate || cellTemplates[cell.cellTemplate])) {
              return cell;
            }

            return {
              ...cell,
              cellTemplate: undefined,
            };
          }),
        };
      });

      startTransition(() => {
        dispatch(saveRackAction(newRack));

        // we ask a save rack success action to properly check the names, etc.
        // we don't want to do it everytime because we don't want to check the names if we just moved the rack
        // here we need the computation done by the save action as well as the ones done in the save success
        startTransition(() => {
          dispatch(
            askUpdateRackAction({
              id: newRack.id as string,
              type: 'saveSuccess',
            })
          );

          startTransition(() => {
            const positionRackNamesUnicity = checkUnicityRackPositionsName(
              Object.values(store.getState().circuit.present.racks.entities)
            );
            if (!positionRackNamesUnicity) {
              SnackbarUtils.error('Rack positions names are not unique');
            }
          });
        });
      });

      store.getState().circuit._latestUnfiltered = store.getState().circuit.present;

      stationPositions.forEach((position) => {
        if (userRemovedStationPositions) {
          dispatch(
            removePositionFromStation({
              stationId: position.selectedStation,
              positionId: position.id,
            })
          );
        } else {
          const actualStationPositions = stations.find((station) => station.id === position.selectedStation)?.positions;

          const isPositionAlreadyInStation = !!actualStationPositions?.find(
            (actualPosition) => actualPosition.id === position.id
          );

          if (!isPositionAlreadyInStation)
            dispatch(
              addPositionToStation({
                stationId: position.selectedStation,
                position: {
                  id: position.id,
                  type: position.type,
                },
              })
            );
        }
      });

      if (close) dispatch(closeDialogAction());
    },
    [
      cellTemplates,
      checkRackName,
      columns,
      currentName,
      dispatch,
      rack,
      uprights,
      rackFromStore,
      stationPositions,
      userRemovedStationPositions,
    ]
  );

  const actions: ActionsRack = useMemo(() => {
    return {
      updateColumn,
      updateUpright,
      updateAllUprights,
      changeRackEditionMenu,
      deleteColumn,
      addColumn,
      selectCellTemplateToAssign,
      cellClick,
      cellTemplateChange: handleCellTemplateChanged,
      updateNbColumns,
      updateColumns,
      updateRack,
      computeColumnsPosition,
      toggleDisplayPositionNames,
      applyColumnValueToAllSelectedColumns,
      applyCellValueToAllSelectedCells,
      changeCellName,
      toggleCellDisabled,
      getSelectedCells,
      assignNameToCells,
      mirrorRack,
      validateRack,
    };
  }, [
    updateColumn,
    updateUpright,
    updateAllUprights,
    changeRackEditionMenu,
    deleteColumn,
    addColumn,
    selectCellTemplateToAssign,
    cellClick,
    handleCellTemplateChanged,
    updateNbColumns,
    updateColumns,
    updateRack,
    computeColumnsPosition,
    toggleDisplayPositionNames,
    applyColumnValueToAllSelectedColumns,
    applyCellValueToAllSelectedCells,
    changeCellName,
    toggleCellDisabled,
    getSelectedCells,
    assignNameToCells,
    mirrorRack,
  ]);

  // for debug purposes
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Rack updated, rack:', rack.properties.name);

    if (currentName !== rack.properties.name) setCurrentName(rack.properties.name);
  }, [rack]);

  const resetCellTemplateRef = useRef<undefined | (() => void)>(undefined);
  const saveCellTemplateRef = useRef<undefined | (() => void)>(undefined);
  const addCellTemplateRef = useRef<undefined | ((cellTemplateType?: 'rack' | 'conveyor') => void)>(undefined);

  const addCellTemplate = useCallback(
    (cellTemplateType: 'rack' | 'conveyor' = 'rack') => {
      const defaultCellTemplate =
        cellTemplateType === 'conveyor' ? getDefaultConveyorCellTemplate() : getDefaultRackCellTemplate();
      defaultCellTemplate.id = generateShapeId();
      defaultCellTemplate.name = `CellTemplate${Math.floor(Math.random() * 1000)}`;

      dispatch(createCellTemplateSuccessAction(defaultCellTemplate));
    },
    [dispatch]
  );
  addCellTemplateRef.current = addCellTemplate;

  const deleteCellTemplate = useCallback(
    (id: string | null = editCellTemplate) => {
      if (!id) return;

      if (id === editCellTemplate) setEditCellTemplate(null);
      if (id === selectedCellTemplate) setSelectedCellTemplate(null);

      // we dispatch the action to update the store
      dispatch(
        deleteCellTemplateSuccessAction({
          id,
        })
      );
    },
    [dispatch, editCellTemplate, selectedCellTemplate]
  );

  useEffect(() => {
    if (!zoomToCellId) return;

    zoomToCell(zoomToCellId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRackEdition]);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="rack-edition-dialog-title"
      disableEscapeKeyDown
      sx={{
        cursor: reactRackPending ? 'progress !important' : undefined,
        '& *': { cursor: reactRackPending ? 'progress !important' : undefined },
      }}
    >
      <RackEditionTopBar
        currentName={currentName}
        setCurrentName={setCurrentName}
        name={rack.properties.name}
        locked={rack.properties.locked}
        cellTemplates={cellTemplates}
        actions={actions}
        editCellTemplate={editCellTemplate}
        resetCellTemplate={resetCellTemplateRef}
        saveCellTemplate={saveCellTemplateRef}
        addCellTemplate={addCellTemplateRef}
        cellTemplateChanged={cellTemplateChanged}
        displayPositionNames={displayPositionNames}
        isConveyor={isConveyor}
        selectedCellTemplate={selectedCellTemplate}
        rackNameOk={newRackNameOk}
        deleteCellTemplate={deleteCellTemplate}
        cellTemplate={cellTemplates[editCellTemplate ?? '']}
        rack={rack}
        selectedCellsForStation={selectedCellsForStation}
        allCellsId={allCellsId}
        stationPositions={stationPositions}
        setStationPositions={setStationPositions}
        setUserRemovedStationPositions={setUserRemovedStationPositions}
      />
      <DialogContent
        id="rack-edition-dialog-content"
        onScroll={(e) => {
          if (editCellTemplate) {
            const scrollTop = (e.target as HTMLElement).scrollTop;

            const svgCellTemplateViewFront = document.querySelector<HTMLElement>('#cell-template-view-front');
            if (svgCellTemplateViewFront) {
              svgCellTemplateViewFront.style.transform = `translateY(${scrollTop}px)`;
            }

            const svgCellTemplateViewTop = document.querySelector<HTMLElement>('#cell-template-view-top');
            if (svgCellTemplateViewTop) {
              svgCellTemplateViewTop.style.transform = `translateY(${scrollTop}px)`;
            }
          }
        }}
        className={selectedCellTemplate ? 'assignCellTemplate' : ''}
        sx={{ padding: '0 24px' }}
      >
        {/** if editCellTemplate is empty, we edit the rack, otherwise we edit a cellTemplate */}
        {!editCellTemplate ? (
          <RackEditionRackView
            rack={rack}
            cellTemplatesIds={cellTemplatesIds}
            cellTemplates={cellTemplates}
            actions={actions}
            selectedCells={selectedCells}
            autoZoomToForm={autoZoomToForm}
            displayPositionNames={displayPositionNames}
            cellsNames={cellsNames}
          />
        ) : (
          <RackEditionCellTemplate
            key={editCellTemplate}
            cellTemplateId={editCellTemplate}
            cellTemplates={cellTemplates}
            resetCellTemplateRef={resetCellTemplateRef}
            saveCellTemplateRef={saveCellTemplateRef}
            addCellTemplateRef={addCellTemplateRef}
            actions={actions}
          />
        )}

        {selectedCellTemplate && !editCellTemplate && (
          <Alert
            severity="info"
            sx={{ position: 'absolute', bottom: 0, left: 0 }}
            action={
              <Button sx={{ textTransform: 'none' }} onClick={assignToWholeRack} variant="outlined">
                Assign it to the whole rack
              </Button>
            }
          >
            <AlertTitle>Click on a cell to assign {cellTemplates[selectedCellTemplate].name}</AlertTitle>
          </Alert>
        )}
      </DialogContent>
      {!editCellTemplate && (
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button variant="text" id="rack-editor-mouse-coordinates" color="inherit">
              (0.000, 0.000)
            </Button>
            <Button onClick={(e) => handleClose(e, 'cancel-button')} color="secondary">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={() => validateRack()}>
              Validate
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

/**
 * Update the cells of a column after a change of the number of levels of the column
 * @param newNbLevels the new number of levels of the column
 * @param cells the previous cells array of the column
 * @returns the new cells array of the column
 */
export function updateCellsAfterChangeNbLevels(newNbLevels: number, cells: RackCell[]): RackCell[] {
  const newCells = [...cells];

  if (newNbLevels > cells.length) {
    // we increased the number of levels
    const highestCell = cells[cells.length - 1];
    for (let i = 0; i < newNbLevels - cells.length; i++) {
      newCells.push({
        ...highestCell,
        id: generateShapeId(),
        cellTemplate: undefined,
        names: highestCell.names.map((name) =>
          name.map((n) => ({
            value: `${n.value}_${Math.floor(Math.random() * 1000)}`,
            user: false,
            id: generateShapeId(),
          }))
        ),
      });
    }
  } else if (newNbLevels < cells.length) {
    // we decreased the number of levels
    newCells.splice(newNbLevels, cells.length - newNbLevels);
  }

  return newCells;
}

/**
 * Calculate the d3 translate and scale for the bbox to be centered in the parent
 * @param parent the parent svg element to center the bbox in
 * @param Bbox the bounding box of the element to center
 * @returns {translate: number[], scale: number} the translate and scale to apply to the parent
 */
export function calculateD3TransformFromBBox(
  parent: SVGSVGElement,
  Bbox: DOMRect
): { translate: number[]; scale: number } {
  const paddingPercent = 1;
  const fullWidth = parent.viewBox.baseVal.width,
    fullHeight = parent.viewBox.baseVal.height;
  const width = Bbox.width,
    height = Bbox.height;
  const midX = Bbox.x + width / 2,
    midY = Bbox.y + height / 2;

  const scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
  const translate = [
    parent.viewBox.baseVal.x + fullWidth / 2 - scale * midX,
    parent.viewBox.baseVal.y + fullHeight / 2 - scale * midY,
  ];

  return {
    translate,
    scale,
  };
}

/**
 * Zoom the user view to fit the rack
 * @returns void
 */
export function zoomToRack(): void {
  if (!zoomRackEdition) return;

  const root = d3.select('#rack-edition-svg-zoom');
  const rootNode = root.node() as SVGSVGElement;

  if (!rootNode) return;

  const bounds = rootNode.getBBox();
  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  const { translate, scale } = calculateD3TransformFromBBox(parent, bounds);

  d3.select(parent)
    .transition()
    .duration(500)
    .call(zoomRackEdition.transform as any, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
}

/**
 * Zoom the user view to fit the form
 * @param transition {optional} transition duration in ms, by default 0, 0 equals no transition
 * @returns void
 */
export function zoomToForm(transition = 0): void {
  if (!zoomRackEdition) return;

  const root = d3.select('#rack-edition-svg-zoom');
  const rootNode = root.node() as SVGElement;

  if (!rootNode) return;

  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  const scale = 1;
  const translate = [8 * 100, 0 * 100];

  if (transition) {
    d3.select(parent)
      .transition()
      .duration(transition)
      .call(zoomRackEdition.transform as any, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
  } else {
    d3.select(parent).call(
      zoomRackEdition.transform as any,
      d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
    );
  }
}

/**
 * Zoom the user view to fit the specified cell
 * @param cellId the id of the cell to zoom to
 * @returns void
 */
export function zoomToCell(cellId: string): void {
  if (!zoomRackEdition) return;

  const root = d3.select('#rack-edition-svg-zoom');
  const rootNode = root.node() as SVGElement;

  if (!rootNode) return;

  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  const cell = d3.select(`[id="${cellId}"]`);
  if (!cell) return;

  const cellNode = cell.node() as SVGSVGElement;
  if (!cellNode) return;

  const cellBBox = cellNode.getBBox();

  const { translate, scale } = calculateD3TransformFromBBox(parent, cellBBox);

  d3.select(parent).call(
    zoomRackEdition.transform as any,
    d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
  );
}
