import { useCallback, useEffect, useState } from 'react';
import { PreferencesService } from 'utils/preferences';

/**
 * Files where the zone events are defined (kiwi callbacks)
 */
const zoneEventsFile = ['ENVIRONMENT/AGV/zones_callbacks.cpp'];

export interface ZoneEvent {
  name: string;
}

/**
 * React hook to get the available zone events.
 * It reads the zone events from the zoneEventsFile and extracts the function names.
 * The function names are then returned as an array of ZoneEvent objects.
 *
 * @returns {ZoneEvent[]} An array of ZoneEvent objects.
 */
export function useAvailableZoneEvents(): ZoneEvent[] {
  const [availableZoneEvents, setAvailableZoneEvents] = useState<ZoneEvent[]>([]);

  const updateAvailableZoneEvents = useCallback(async () => {
    if (!PreferencesService.arePreferencesFullyLoaded()) return; // only available when a project is opened

    const zoneEventsFilesContent: string[] = [];
    for (const zoneEventsFilePath of zoneEventsFile) {
      try {
        const file = await PreferencesService.getFileByPath(zoneEventsFilePath);
        const fileContent = await file?.text();
        if (!fileContent) continue;

        zoneEventsFilesContent.push(fileContent);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Could not load file ${zoneEventsFilePath}`);
      }
    }

    // pattern to extract the function names
    const pattern = /\bfunction\s+([a-zA-Z_]\w*)\s*\(/g;

    // Find all matches

    const zoneEvents: ZoneEvent[] = [];
    for (const zoneEventsFileContent of zoneEventsFilesContent) {
      const matches = Array.from(zoneEventsFileContent.matchAll(pattern), (match) => match[1]);

      for (const match of matches) {
        zoneEvents.push({ name: match });
      }
    }

    setAvailableZoneEvents(zoneEvents);
  }, []);

  useEffect(() => {
    updateAvailableZoneEvents();
  }, [updateAvailableZoneEvents]);

  return availableZoneEvents;
}
