import type { SetImageHeight } from 'actions';
import { setImageHeightAction } from 'actions';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import type { MapImageScalingSourceData } from 'models/maps';
import { connect } from 'react-redux';
import { selectMapImage } from 'reducers/maps';
import type { AppState } from 'reducers/state';
import type { Dispatch } from 'redux';
import { DialogImageScalingComponent } from './dialog-image-scaling';

interface StateProps {
  imageURL?: string;
  name?: string;
}

export function mapStateToProps(state: AppState): StateProps {
  const mapImage = selectMapImage(state);

  return { imageURL: mapImage?.URL, name: mapImage?.name };
}

interface DispatchProps {
  setImageHeight: (imageHeight: number, name: string, scaling?: MapImageScalingSourceData) => void;
}

export function mapDispatchToProps(dispatch: Dispatch<SetImageHeight>): DispatchProps {
  return {
    setImageHeight: (imageHeight: number, name: string, scaling?: MapImageScalingSourceData) => {
      dispatch(setImageHeightAction({ imageHeight: imageHeight * DISPLAY_UNIT_FACTOR, scaling, name }));
    },
  };
}

export const DialogImageScaling = connect(mapStateToProps, mapDispatchToProps)(DialogImageScalingComponent);
