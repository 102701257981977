import type { CircuitStockZone } from 'models/circuit';
import { roundCoordinates } from 'utils/helpers';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: StockZonesActionTypes,
  creators: {
    create: createStockZoneAction,
    createFailure: createStockZoneFailureAction,
    createSuccess: createStockZoneSuccessAction,
    delete: deleteStockZoneAction,
    deleteFailure: deleteStockZoneFailureAction,
    deleteSuccess: deleteStockZoneSuccessAction,
    loadOne: loadStockZoneAction,
    loadOneFailure: loadStockZoneFailureAction,
    loadOneSuccess: loadStockZoneSuccessAction,
    saveFailure: saveStockZoneFailureAction,
    saveSuccess: saveStockZoneSuccessAction,
    select: selectStockZoneAction,
  },
} = createEntityActions<CircuitStockZone>('StockZones');

const {
  creators: { save },
} = createEntityActions<CircuitStockZone>('StockZones');

export function saveStockZoneAction(payload: Partial<CircuitStockZone> & RoadEditorAction['payload']): SaveStockZone {
  let action = save(payload);
  delete payload.userAction;
  if (action.payload.geometry) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        geometry: {
          ...action.payload.geometry,
          coordinates: action.payload.geometry.coordinates.map((coords) =>
            coords.map((coord) => roundCoordinates(coord))
          ),
        },
      },
    };
  }

  return action;
}

export interface CreateStockZone extends CreateAction<CircuitStockZone> {}
export interface CreateStockZoneFailure extends CreateFailureAction {}
export interface CreateStockZoneSuccess extends CreateSuccessAction<CircuitStockZone> {}
export interface DeleteStockZone extends DeleteAction {}
export interface DeleteStockZoneFailure extends DeleteFailureAction {}
export interface DeleteStockZoneSuccess extends DeleteSuccessAction {}
export interface LoadStockZone extends LoadOneAction {}
export interface LoadStockZoneFailure extends LoadOneFailureAction {}
export interface LoadStockZoneSuccess extends LoadOneSuccessAction<CircuitStockZone> {}
export interface SaveStockZone extends SaveAction<CircuitStockZone> {
  payload: SaveAction<CircuitStockZone>['payload'] & RoadEditorAction['payload'] & { isEditing?: boolean };
}
export interface SaveStockZoneFailure extends SaveFailureAction {}
export interface SaveStockZoneSuccess extends SaveSuccessAction<CircuitStockZone> {}
export interface SelectStockZone extends SelectAction {}

export type StockZoneActions =
  | CreateStockZone
  | CreateStockZoneFailure
  | CreateStockZoneSuccess
  | DeleteStockZone
  | DeleteStockZoneFailure
  | DeleteStockZoneSuccess
  | LoadStockZone
  | LoadStockZoneFailure
  | LoadStockZoneSuccess
  | SaveStockZone
  | SaveStockZoneFailure
  | SaveStockZoneSuccess
  | SelectStockZone;
