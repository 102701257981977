import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import type { CellTemplate } from 'reducers/circuit/state';
import { theme } from 'utils/mui-theme';
import type { FocusInputs, PickOrDrop, ViewCellTemplate } from './rack-edition-celltemplate';
import { RackEditionCellTemplatePreviewFront } from './RackEditionCellTemplatePreviewFront';
import { RackEditionCellTemplatePreviewTop } from './RackEditionCellTemplatePreviewTop';

interface RackEditionCellTemplatePreviewProps {
  /** cell template to be displayed */
  cellTemplate: CellTemplate;
  /** action to update the cell template */
  updateCellTemplate: (newCellTemplate: CellTemplate) => void;
  /** is the front view is currently displayed? */
  frontView: boolean;
  /** is the top view is currently displayed? */
  topView: boolean;
  /** action to change which view will be displayed */
  updateDisplayViewAction?: (newView: { front: boolean; top: boolean }) => void;
  /** which kind of parameters are we currently editing */
  view?: ViewCellTemplate;
  /** which input is currently focused (undefined if none) */
  focusedInputs?: FocusInputs;
  /** changed which input is currently focused */
  onChangeFocusedInputs?: (newState: FocusInputs) => void;
  /** width of the largest load [m] */
  widthTakenByLargestLoads?: number;
  /** do we display the pallets in a pick or drop view */
  pickOrDropMode: PickOrDrop;
}
export function RackEditionCellTemplatePreview({
  cellTemplate,
  updateCellTemplate: updateCellTemplateAction,
  frontView,
  topView,
  updateDisplayViewAction,
  view = 'geometry',
  focusedInputs,
  onChangeFocusedInputs,
  widthTakenByLargestLoads,
  pickOrDropMode,
}: RackEditionCellTemplatePreviewProps): JSX.Element {
  return (
    <>
      <FormGroup
        sx={{
          position: 'absolute',
          right: 0,
          border: `1px solid ${theme.palette.grey[400]}`,
          margin: theme.spacing(3),
          padding: theme.spacing(2),
          background: theme.palette.grey[100],
          borderRadius: theme.shape.borderRadius,
          zIndex: 100,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={frontView}
              onChange={() => updateDisplayViewAction && updateDisplayViewAction({ front: !frontView, top: topView })}
            />
          }
          label="Front View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={topView}
              onChange={() => updateDisplayViewAction && updateDisplayViewAction({ front: frontView, top: !topView })}
            />
          }
          label="Top View"
        />
      </FormGroup>

      {frontView && (
        <RackEditionCellTemplatePreviewFront
          cellTemplate={cellTemplate}
          updateCellTemplateAction={updateCellTemplateAction}
          topViewEnabled={topView}
          view={view}
          focusedInputs={focusedInputs}
          onChangeFocusedInputs={onChangeFocusedInputs}
          widthTakenByLargestLoads={widthTakenByLargestLoads}
          pickOrDropMode={pickOrDropMode}
        />
      )}
      {topView && (
        <RackEditionCellTemplatePreviewTop
          cellTemplate={cellTemplate}
          updateCellTemplateAction={updateCellTemplateAction}
          frontViewEnabled={frontView}
          view={view}
          focusedInputs={focusedInputs}
          onChangeFocusedInputs={onChangeFocusedInputs}
          widthTakenByLargestLoads={widthTakenByLargestLoads}
          pickOrDropMode={pickOrDropMode}
        />
      )}
    </>
  );
}
