import type { Point, Polygon } from '@turf/helpers';
import type { CircuitMeasurer } from 'models/circuit';
import { MeasurementType, ShapeTypes } from 'models/circuit';
import { Measurer } from './editor-elements';
import { getMathematicalProjection, svgCoordsToMathCoords } from './helpers';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();
const previewMeasurerId = 'preview-measurer-fake';

interface DrawPreviewMeasurerProps {
  /** the coordinates of the preview measurer */
  points: [number, number][];
  /**
   * the layer in which we want to draw the preview measurer
   * it is likely the current selected layer
   */
  layerId: string;
  /**
   * if true, it will remove the already drawn previous preview measurer
   * @default true
   */
  removePreviousPreviewMeasurer?: boolean;
}

/**
 * This function draws a preview measurer on the circuit layer with the given points.
 * This function can remove the already drawn previous preview measurer.
 * @param param0
 * @returns void
 */
export function drawPreviewMeasurer({
  points,
  layerId,
  removePreviousPreviewMeasurer = true,
}: DrawPreviewMeasurerProps): void {
  // we create a fake measurer from the given coordinates to draw the preview
  const previewMeasurer: CircuitMeasurer = {
    id: previewMeasurerId,
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [svgCoordsToMathCoords(points[0]), svgCoordsToMathCoords(points[1])],
    },
    properties: {
      layerId,
      type: ShapeTypes.MeasurerShape,
      name: 'Preview Measurer',
      measurementType: MeasurementType.MinimumDistance,
      prio: Infinity,
    },
  };

  if (removePreviousPreviewMeasurer) {
    removePreviousPreviewMeasurers();
  }

  const previewMeasurerEl = new Measurer(
    previewMeasurerId,
    previewMeasurer.geometry,
    projection,
    zoomScale,
    previewMeasurer.properties
  );
  const previewMeasurerNode = previewMeasurerEl.node.node();
  if (!previewMeasurerEl || !previewMeasurerNode) {
    // eslint-disable-next-line no-console
    console.error('Preview Measurer not created');

    return;
  }

  const layerEl = document.querySelector('[layer="circuit"]');
  if (!layerEl) {
    // eslint-disable-next-line no-console
    console.error('Circuit layer not found');

    return;
  }

  layerEl.appendChild(previewMeasurerNode);
}

/**
 * This function removes the already drawn previous preview measurer.
 * (or do nothing if there is no previous preview measurer)
 */
export function removePreviousPreviewMeasurers(): void {
  document.querySelectorAll(`[uuid="${previewMeasurerId}"]`).forEach((el) => el.remove());
}
