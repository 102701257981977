import type { AllGeoJSON } from '@turf/turf';
import * as turf from '@turf/turf';
import { bbox as turfBbox } from '@turf/turf';
import { saveCircuitToHistoryAction } from 'actions/circuit';
import * as d3 from 'd3';
import {
  findVectorsAngle,
  getDimensionsFromCoordinates,
  getNextPointIndex,
  offsetPositionArray,
  replaceCoordsInArray,
  rotatePolygon2,
} from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import type { Polygon } from 'geojson';
import { getUnitaryVector } from 'librarycircuit/utils/geometry/vectors';
import type { ShapeTypes } from 'models/circuit';
import store from 'store';
import { getConfig } from 'utils/config';
import { calcPolygonArea } from 'utils/geometry/area';
import { deepCopy, roundValue } from 'utils/helpers';
import { Shape } from './shape.element';

export interface HandleData {
  x: number;
  y: number;
  isCenter?: boolean;
}

const HANDLE_WIDTH = parseFloat(getConfig('editor').elementWidth.handle);
const MINIMUM_SHAPE_LENGTH = getConfig('editor').minimumShapeLength;
const MINIMUM_AREA = MINIMUM_SHAPE_LENGTH ** 2;

export interface DragEventProps {
  x: number;
  y: number;
}

export class RectangleOrTriangle extends Shape<Polygon> {
  private isDraggingHandle = false;
  private isRotating = false;
  private isScaling = false;
  private draggedHandleIndex: number | null = null;

  constructor(
    id: string,
    type: ShapeTypes,
    geometry: Polygon,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number
  ) {
    super(id, type, geometry, projection, zoomScale);
    this.onHandleDragStart = this.onHandleDragStart.bind(this);
    this.onHandleDragged = this.onHandleDragged.bind(this);
    this.onHandleDragEnd = this.onHandleDragEnd.bind(this);
    this.drawTextRef = this.drawNameText;
    this.updateShapeRef = this.updateShape;
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    return super.createNode().style('stroke-width', getConfig('editor').elementWidth.zoneStroke);
  }

  private clearHandles(): void {
    const handles = this.node.selectAll<SVGGElement, unknown>('circle');
    handles.nodes().forEach((node) => node.remove());
  }

  public setZoomScale(zoomScale: number): void {
    super.setZoomScale(zoomScale);
    this.node.selectAll('circle').attr('r', HANDLE_WIDTH / this.zoomScale);
  }

  public showHandles(show = true): void {
    const handles = this.node.selectAll<SVGGElement, unknown>('circle');

    if (!show) {
      this.clearHandles();

      return;
    }

    const cleanCoords: GeoJSON.Position[][] = Object.assign([], this.geometry.coordinates[0]);

    // Remove last value which is a dup of the first one
    cleanCoords.pop();

    // Compute corner handles

    const cornersData: HandleData[] = cleanCoords.map((coord) => ({
      x: Number(coord[0]),
      y: -coord[1],
    }));

    // Compute middle handles
    const middleHandles: HandleData[] = cornersData.map((corner, i) => {
      const previousCoords = cornersData[getNextPointIndex(i, 1, cornersData.length)];

      return {
        x: Number((corner.x - (corner.x - previousCoords.x) / 2).toFixed(3)),
        y: Number((corner.y - (corner.y - previousCoords.y) / 2).toFixed(3)),
      };
    });

    const wholeHandles: HandleData[] = [];

    // Combine both arrays into one, respecting handles order
    for (let itr = 0; itr < cornersData.length; ++itr) {
      wholeHandles.push(cornersData[itr]);
      wholeHandles.push(middleHandles[itr]);
    }

    const polygon = turf.polygon(this.geometry.coordinates);
    const centroid = turf.centroid(polygon);

    if (centroid.geometry) {
      // y with minus because axis is reversed
      const coords: HandleData = {
        x: centroid.geometry.coordinates[0],
        y: -centroid.geometry.coordinates[1],
        isCenter: true,
      };
      wholeHandles.push(coords);
    }

    const dragHandler = d3
      .drag<SVGCircleElement, any>()
      .on('start', this.onHandleDragStart)
      .on('drag', this.onHandleDragged)
      .on('end', this.onHandleDragEnd);

    handles
      .data(wholeHandles)
      .enter()
      .append<SVGCircleElement>('svg:circle')
      .attr('d', this.projection as any)
      .attr('cx', (d) => {
        return d.x;
      })
      .attr('cy', (d) => {
        return d.y;
      })
      .style('fill', '')
      .style('stroke', '')
      .attr('r', (d) => {
        return HANDLE_WIDTH / this.zoomScale;
      })
      .call(dragHandler)
      .exit();
  }

  protected onHandleDragStart(forceRotating = false): void {
    if (this.locked) return;

    store.dispatch(saveCircuitToHistoryAction());

    this.isDraggingHandle = true;
    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

    if (event.sourceEvent.shiftKey || forceRotating === true) {
      this.isRotating = true;
    } else {
      this.isScaling = true;
    }

    const handles = this.node.selectAll<SVGGElement, HandleData>('circle');
    const handlesData: HandleData[] = handles.data();
    const draggedHandle = handlesData.filter((elt) => elt.x === event.subject.x && elt.y === event.subject.y)[0];
    this.draggedHandleIndex = handlesData.indexOf(draggedHandle);
  }

  protected onHandleDragged(): void {
    if (this.locked) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

    // Fetch handles data
    const handles = this.node.selectAll<SVGGElement, HandleData>('circle');
    const handlesData: HandleData[] = handles.data();

    if (this.draggedHandleIndex !== null) {
      // Retrieve dragged handle
      const draggedHandle = handlesData[this.draggedHandleIndex];
      if (this.isRotating) {
        ///////////////////////////////
        // Rotation
        ///////////////////////////////

        if (draggedHandle.isCenter) {
          // we disable the rotation by the center handle
          return;
        }

        const center = turf.centroid(turf.polygon(this.geometry.coordinates));
        const centerCoords = center.geometry.coordinates;

        const fomerHandleVector = getUnitaryVector({
          start: centerCoords,
          end: [draggedHandle.x, -draggedHandle.y],
        });
        const newHandleVector = getUnitaryVector({
          start: centerCoords,
          end: [event.x, -event.y],
        });

        const deltaAngle = findVectorsAngle(fomerHandleVector, newHandleVector);

        this.geometry.coordinates = [rotatePolygon2(centerCoords, this.geometry.coordinates[0], -deltaAngle)];

        this.refreshGeometry();
        this.clearHandles();
        this.showHandles(this.active);
      }

      if (this.isScaling) {
        ///////////////////////////////
        // Scale
        ///////////////////////////////

        // Compute new coordinates
        const newHandleCoordinates = { x: roundValue(event.x), y: roundValue(event.y) };

        const geometryNewArray = deepCopy<GeoJSON.Position[]>(this.geometry.coordinates);
        let tempCoords: GeoJSON.Position[][];
        if (geometryNewArray[0].length === 5) {
          // it's a rectangle
          if (this.draggedHandleIndex === 8) {
            // central handle
            tempCoords = offsetPositionArray(geometryNewArray, event.dx, event.dy);
          } else {
            // all the other handles
            tempCoords = replaceCoordsInArray(
              [draggedHandle.x, -draggedHandle.y],
              [newHandleCoordinates.x, -newHandleCoordinates.y],
              geometryNewArray
            );
          }
        } else {
          // geometryNewArray[0].length === 4, it's a triangle
          if (this.draggedHandleIndex === 6) {
            // central handle
            tempCoords = offsetPositionArray(geometryNewArray, event.dx, event.dy);
          } else {
            // all the other handles
            tempCoords = geometryNewArray;

            if (this.draggedHandleIndex % 2 === 0) {
              // it's a corner
              const corner = this.draggedHandleIndex / 2;
              const coordsToChange = [corner];

              if (corner === 0) coordsToChange.push(geometryNewArray[0].length - 1);
              else if (corner === geometryNewArray[0].length - 1) coordsToChange.push(0);

              coordsToChange.forEach((index) => {
                tempCoords[0][index] = [newHandleCoordinates.x, -newHandleCoordinates.y];
              });
            } else {
              // it's a line
              const corner = Math.floor(this.draggedHandleIndex / 2);
              const coordsToChange = [corner];
              if (corner === 0) coordsToChange.push(geometryNewArray[0].length - 1);
              if (corner + 1 >= geometryNewArray[0].length - 1) {
                coordsToChange.push(0);
                coordsToChange.push(geometryNewArray[0].length - 1);
              } else {
                coordsToChange.push(corner + 1);
              }

              const dx = event.dx,
                dy = -event.dy;

              coordsToChange.forEach((index) => {
                tempCoords[0][index][0] += dx;
                tempCoords[0][index][1] += dy;
              });
            }
          }
        }

        const [width, height] = getDimensionsFromCoordinates(tempCoords);

        // Ensure the area is not too small (risk of merging corners)
        const areaCm2 = calcPolygonArea(tempCoords[0]);
        if (width >= MINIMUM_SHAPE_LENGTH && height >= MINIMUM_SHAPE_LENGTH && areaCm2 >= MINIMUM_AREA) {
          this.geometry.coordinates = tempCoords;
          this.refreshGeometry();
          this.clearHandles();
          this.showHandles(this.active);
        }
      }
    }
  }

  protected onHandleDragEnd(properties?: DragEventProps): void {
    this.isDraggingHandle = false;
    this.isRotating = false;
    this.isScaling = false;

    this.draggedHandleIndex = null;
    this.callOnGeometryUpdateListener(properties);
    this.clearHandles();
    this.showHandles(this.active);

    this.onDragEnd();
  }

  public setActive(active = true): void {
    super.setActive(active);
    this.showHandles(active);
  }

  protected onDrag(): void {
    // If handle drag in progress we don't want to allow drag whole shape
    if (this.isDraggingHandle) {
      return;
    }

    if (this.locked) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

    this.geometry.coordinates = offsetPositionArray(this.geometry.coordinates, event.dx, event.dy);

    super.onDrag();

    this.clearHandles();
    this.showHandles(this.active);
  }

  protected updateShape(): void {
    this.refreshGeometry();
    this.clearHandles();
    //this.showHandles(this.active); // this takes so much resources and we don't need the handle while dragging...
  }

  protected drawNameText(active?: boolean): void {
    active = active === undefined ? this.active : active;

    const textEl = this.node.select('text.shape-name');
    if (!active) {
      textEl.remove();

      return;
    }

    this.bbox = turfBbox(this.geometry as AllGeoJSON);

    if (!textEl.size()) {
      this.node
        .append('svg:text')
        .attr('x', this.bbox[0])
        .attr('y', -this.bbox[3] - 5)
        .text(this.properties?.name || 'Undefined')
        .classed('shape-name', true)
        .exit();
    } else {
      textEl.attr('x', this.bbox[0]).attr('y', -this.bbox[3] - 5);
    }
  }
}

export class Rectangle extends RectangleOrTriangle {}
export class Triangle extends RectangleOrTriangle {}
