export interface Line {
  start: number[];
  end: number[];
}

/**
 * Calculates the distance between two points.
 * @param {number[]} p1 - The coordinates of the first point, as a two-element array of numbers.
 * @param {number[]} p2 - The coordinates of the second point, as a two-element array of numbers.
 * @returns {number} The distance between the two points.
 */
export function getDistanceBetweenPoints(p1: number[], p2: number[]): number {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];

  return Math.sqrt(dx * dx + dy * dy);
}

function getLineLength(line: Line): number {
  return getDistanceBetweenPoints(line.start, line.end);
}

export function getUnitaryVector(line: Line): number[] {
  const length = getLineLength(line);
  if (length === 0) {
    return [0, 0];
  }

  return [(line.end[0] - line.start[0]) / length, (line.end[1] - line.start[1]) / length];
}
