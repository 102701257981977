import type { CircuitNote } from 'models/circuit';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: NotesActionTypes,
  creators: {
    create: createNoteAction,
    createFailure: createNoteFailureAction,
    createSuccess: createNoteSuccessAction,
    delete: deleteNoteAction,
    deleteFailure: deleteNoteFailureAction,
    deleteSuccess: deleteNoteSuccessAction,
    loadOne: loadNoteAction,
    loadOneFailure: loadNoteFailureAction,
    loadOneSuccess: loadNoteSuccessAction,
    saveFailure: saveNoteFailureAction,
    saveSuccess: saveNoteSuccessAction,
    select: selectNoteAction,
  },
} = createEntityActions<CircuitNote>('Notes');

const {
  creators: { save },
} = createEntityActions<CircuitNote>('Notes');

export function saveNoteAction(payload: Partial<CircuitNote> & RoadEditorAction['payload']): SaveNote {
  const action = save(payload);

  return action;
}

export interface CreateNote extends CreateAction<CircuitNote> {}
export interface CreateNoteFailure extends CreateFailureAction {}
export interface CreateNoteSuccess extends CreateSuccessAction<CircuitNote> {}
export interface DeleteNote extends DeleteAction {}
export interface DeleteNoteFailure extends DeleteFailureAction {}
export interface DeleteNoteSuccess extends DeleteSuccessAction {}
export interface LoadNote extends LoadOneAction {}
export interface LoadNoteFailure extends LoadOneFailureAction {}
export interface LoadNoteSuccess extends LoadOneSuccessAction<CircuitNote> {}
export interface SaveNote extends SaveAction<CircuitNote> {
  payload: SaveAction<CircuitNote>['payload'] & RoadEditorAction['payload'];
}
export interface SaveNoteFailure extends SaveFailureAction {}
export interface SaveNoteSuccess extends SaveSuccessAction<CircuitNote> {}
export interface SelectNote extends SelectAction {}

export type NoteActions =
  | CreateNote
  | CreateNoteFailure
  | CreateNoteSuccess
  | DeleteNote
  | DeleteNoteFailure
  | DeleteNoteSuccess
  | LoadNote
  | LoadNoteFailure
  | LoadNoteSuccess
  | SaveNote
  | SaveNoteFailure
  | SaveNoteSuccess
  | SelectNote;
