import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { DividerProps, SxProps } from '@mui/material';
import { Box, Collapse, Divider, IconButton } from '@mui/material';
import type { Theme } from '@mui/system';
import type { ReactNode } from 'react';
import { useCallback, useRef, useState } from 'react';

interface CollapseMoreProps {
  /** The children of the collapse */
  children: ReactNode;
  /** The label in the divider */
  title?: string | ReactNode;
  /** Wether or not the collapse has a divider, true by default */
  hasDivider?: boolean;
  /** Position of the divider, middle by default */
  dividerPosition?: DividerProps['variant'];
  /** The sx props of the divider */
  sx?: SxProps<Theme>;
  /** The sx props of the collapse */
  sxCollapse?: SxProps<Theme>;
  /** The sx props of the icon */
  sxIconButton?: SxProps<Theme>;

  /** whether or not the component is expanded by default. If not provided, collapsed by default */
  defaultExpended?: boolean;

  /** Whether or not we save the state in the local storage and restore it when we mount the component */
  saveState?: boolean;

  /** Enable unmounting the component when it is collapsed */
  unmountOnExit?: boolean;

  /** Callback when the component is expanded or collapsed */
  onChange?: (expanded: boolean) => void;
}

export function CollapseMore(props: CollapseMoreProps): JSX.Element {
  const {
    children,
    title = 'More',
    dividerPosition = 'middle',
    hasDivider = true,
    saveState = false,
    unmountOnExit,
    onChange,
  } = props;
  let defaultExpended = props.defaultExpended;

  const mounted = useRef(false);
  const localStorageKey = `collapse-more-${title}`;

  if (!mounted.current) {
    mounted.current = true;

    if (saveState) {
      const savedState = localStorage.getItem(localStorageKey);
      if (savedState) {
        defaultExpended = savedState === 'true';
      }
    }
  }

  const [expanded, setExpanded] = useState(defaultExpended ?? false);

  const handleChange = useCallback(() => {
    setExpanded((state) => {
      const newState = !state;

      if (saveState) {
        localStorage.setItem(localStorageKey, newState.toString());
      }

      onChange?.(newState);

      return newState;
    });
  }, [localStorageKey, onChange, saveState]);

  return (
    <>
      {hasDivider ? (
        <Box component="div" sx={props.sx}>
          <Divider variant={dividerPosition} onClick={handleChange} sx={{ cursor: 'pointer' }}>
            {title}{' '}
            <IconButton sx={props.sxIconButton}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Divider>
        </Box>
      ) : (
        <></>
      )}

      <Collapse in={expanded} sx={props.sxCollapse} unmountOnExit={unmountOnExit}>
        {children}
      </Collapse>
    </>
  );
}
