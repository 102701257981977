import type { SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import type { CircuitZone } from 'models/circuit';
import { BroadcastSchema, isBroadcast } from 'models/circuit';
import type { ZoneFormValues } from './zone-properties';

interface BroadcastSelectProps {
  zone: CircuitZone;
  setFormValues: (value: React.SetStateAction<ZoneFormValues>) => void;
  setAskUpdateZone: (value: React.SetStateAction<boolean>) => void;
  formElementStyle?: SxProps<Theme> | undefined;
  zoneFormValues: ZoneFormValues;
}

export function BroadcastSelect(props: BroadcastSelectProps): JSX.Element {
  const { zone, setAskUpdateZone, setFormValues, formElementStyle, zoneFormValues } = props;

  const locked = zone.properties.locked;

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    const value = event.target.value;
    if (!isBroadcast(value)) {
      // eslint-disable-next-line no-console
      console.error(`Invalid broadcast value: ${value}`);

      return;
    }

    setFormValues((prev) => ({
      ...prev,
      broadcast: value,
    }));
    setAskUpdateZone(true);
  };

  /**
   *  @link https://redmine.balyo.com/issues/53658
   */
  const broadcastMinimumSDKVersion = '4.15.1';

  const sdkVersionOk = useSdkVersionOk(broadcastMinimumSDKVersion);
  if (!sdkVersionOk) {
    return <></>;
  }

  const label = (
    <>
      Broadcast Status
      <HelpIconTooltip
        title={
          <>
            Choose to which entity broadcast when a robot enters or exits the zone.
            <ul>
              <li>
                <strong>Both</strong>: broadcast to the Robot Manager and the Robot
              </li>
              <li>
                <strong>Local</strong>: broadcast to the Robot only <em>(default)</em>
              </li>
              <li>
                <strong>External</strong>: broadcast to the Robot Manager only
              </li>
            </ul>
          </>
        }
      />
    </>
  );

  const broadcastValue = zoneFormValues.broadcast;

  return (
    <>
      <br />
      <FormControl sx={formElementStyle} disabled={locked}>
        <InputLabel id="broadcast-select-label" sx={{ transform: 'scale(0.75)' }}>
          {label}
        </InputLabel>
        <Select
          labelId="broadcast-select-label"
          id="broadcast-select"
          label={label}
          onChange={handleChange}
          variant="standard"
          value={broadcastValue}
        >
          {BroadcastSchema.options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
