import { epsilon, fequal } from './utils';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface Framework_Point2D_t {
  x: number;
  y: number;
}

/**
 * Calculates the radius of a bend from three points.
 *
 * This code is from the SDK function getRadiusFrom3Points and converted from C to TypeScript.
 *
 * @param {Object} Pa - The first point.
 * @param {Object} Pb - The second point.
 * @param {Object} Pc - The third point.
 * @returns {number} - The calculated radius.
 */
export function getRadiusFrom3Points(
  Pa: Framework_Point2D_t,
  Pb: Framework_Point2D_t,
  Pc: Framework_Point2D_t
): number {
  let Ax: number, Ay: number, Bx: number, By: number, Cx: number, Cy: number;
  let radius: number = Number.MAX_VALUE;
  // Detect point aligned on Y(Prevent division by zero)
  if (fequal(Pa.y, Pb.y, epsilon) || fequal(Pc.y, Pb.y, epsilon)) {
    Ax = Pa.y;
    Ay = Pa.x;
    Bx = Pb.y;
    By = Pb.x;
    Cx = Pc.y;
    Cy = Pc.x;
  } else {
    Ax = Pa.x;
    Ay = Pa.y;
    Bx = Pb.x;
    By = Pb.y;
    Cx = Pc.x;
    Cy = Pc.y;
  }

  const part1 = (0.5 * (Cx * Cx - Bx * Bx + Cy * Cy - By * By)) / (Cy - By);
  const part2 = (0.5 * (Bx * Bx - Ax * Ax + By * By - Ay * Ay)) / (By - Ay);
  const part3 = (Bx - Ax) / (By - Ay);
  const part4 = (Cx - Bx) / (Cy - By);

  if (Math.abs(part3 - part4) < 0.0001) {
    radius = Number.MAX_VALUE;
  } else {
    const circleX = -(part1 - part2) / (part3 - part4);
    const circleY = -part3 * circleX + part2;

    const radiusA = Math.sqrt((Ax - circleX) * (Ax - circleX) + (Ay - circleY) * (Ay - circleY));
    const radiusB = Math.sqrt((Bx - circleX) * (Bx - circleX) + (By - circleY) * (By - circleY));
    const radiusC = Math.sqrt((Cx - circleX) * (Cx - circleX) + (Cy - circleY) * (Cy - circleY));

    radius = (radiusA + radiusB + radiusC) / 3;
  }

  return radius;
}
