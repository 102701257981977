export function createRackAnalysisObjectStores(db: IDBOpenDBRequest | IDBDatabase): void {
  let dbObject: IDBDatabase;
  if (!('result' in db)) {
    dbObject = db;
  } else {
    dbObject = db.result;
  }

  if (!dbObject.objectStoreNames.contains('saved')) {
    // eslint-disable-next-line no-console
    console.log('Creating object stores for rack analysis (saved)');
    dbObject.createObjectStore('saved');
  }

  if (!dbObject.objectStoreNames.contains('_unsaved')) {
    // eslint-disable-next-line no-console
    console.log('Creating object stores for rack analysis (_unsaved)');
    dbObject.createObjectStore('_unsaved');
  }
}
