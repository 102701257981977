import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LayersIcon from '@mui/icons-material/Layers';
import TuneIcon from '@mui/icons-material/Tune';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Card, CardContent, CardHeader, Collapse, IconButton, List, Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { openDialogAction } from 'actions';
import { clearShapesSelectionAction, updateLayerAction } from 'actions/circuit';
import clsx from 'clsx';
import { DialogTypes } from 'models';
import { ShapeTypes } from 'models/circuit';
import { useCallback, useEffect, useState } from 'react';
import { sortLayersByOrder } from 'reducers/circuit/layers.reducer';
import type { LayerData } from 'reducers/circuit/state';
import { checkPermission } from 'services/check-permission';
import { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';

/**
 * By default only the shapes that are in the currently selected layer are clickable.
 * This array contains the shape types that are clickable in any layer.
 */
const clickableShapeTypesAnyLayer: ShapeTypes[] = [ShapeTypes.RackShape, ShapeTypes.StockZoneShape];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      pointerEvents: 'none',
      marginBottom: theme.spacing(1),
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    cardHeader: {
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    middleFade: {
      opacity: 0.5,
    },
    card: {
      pointerEvents: 'initial',
    },
    cardContent: {
      padding: theme.spacing(1),
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: `${theme.spacing(1)} !important`,
      textAlign: 'left',
    },
    action: {
      marginTop: 0,
    },
    listLayers: {
      padding: 0,
      overflowY: 'auto',
      maxHeight: '80vh',
    },
    folded: {
      maxHeight: 150,
    },
    addLayerButton: {
      marginTop: theme.spacing(0.25),
      textAlign: 'center',
    },
    secondaryActionList: {
      paddingRight: theme.spacing(16),
    },

    listItemDraft: {
      textDecoration: 'line-through',
    },
    secondaryColor: {
      color: theme.palette.primary.main,
    },
    overingDrag: {
      background: `repeating-linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.light} 10px, ${theme.palette.primary.main} 10px, ${theme.palette.primary.main} 20px) !important`,
    },
    dragging: {
      opacity: 0.4,
    },
  })
);
const useStylesListItem = makeStyles((theme) =>
  createStyles({
    listItemDraft: {
      textDecoration: 'line-through',
    },
    secondaryActionList: {
      paddingRight: theme.spacing(16),
    },
    dragging: {
      opacity: 0.4,
    },
  })
);

interface LayerProps {
  id: string;
  layer: LayerData;
  selected?: boolean;
  nestedLevel: number;
}
const LayerComponent = ({ id, layer, selected, nestedLevel }: LayerProps): JSX.Element => {
  const classes = useStylesListItem();
  const dispatch = useAppDispatch();

  const handleChangeVisibility = useCallback(
    (layerId: string): void => {
      dispatch(
        updateLayerAction({
          layerId,
          visibility: !layer.visibility,
          userAction: true,
        })
      );
    },
    [dispatch, layer]
  );

  const handleClickLayer = useCallback(
    (layerId: string): void => {
      dispatch(
        updateLayerAction({
          layerId,
          selectedLayer: layerId,
          visibility: true,
          userAction: true,
        })
      );

      dispatch(clearShapesSelectionAction());
    },
    [dispatch]
  );

  return (
    <ListItem
      button
      selected={selected}
      onClick={!selected ? () => handleClickLayer(id) : undefined}
      classes={{ secondaryAction: classes.secondaryActionList }}
      className={[layer.isDraft ? classes.listItemDraft : null].filter((className) => !!className).join(' ')}
      sx={{ boxShadow: `inset 3px 0px 0px 0px ${layer.color}` }}
    >
      <ListItemText primary={layer.name} style={{ paddingLeft: `${nestedLevel * 20}px` }} />

      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="Show layer"
          onClick={() => handleChangeVisibility(id)}
          title={layer.visibility ? 'Hide' : 'Show'}
          size="large"
        >
          {layer.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const LayersMenu = (): JSX.Element => {
  const alwaysDisplayCommon = useAppSelector((state) => state.tool.alwaysDisplayCommon);
  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const selectedLayer = useAppSelector((state) => state.circuit.present.layers.selectedLayer);
  const classes = useStyles({ selectedLayer, layers });
  const dispatch = useAppDispatch();

  const [showListLayers, setShowListLayers] = useState(true);
  const [mouseOvering, setMouseOvering] = useState(false);
  const [folded, setFolded] = useState(true);

  const handleClickShowLayers = useCallback(
    (e): void => {
      setShowListLayers(!showListLayers);
    },
    [showListLayers]
  );

  const handleClickSettings = useCallback(
    (e) => {
      dispatch(openDialogAction({ type: DialogTypes.Layers, payload: undefined }));
    },
    [dispatch]
  );

  // the following hook inject css to fade the unselected layers and hide the hidden layers
  useEffect(() => {
    document.querySelector('style#dynamic-layers-style')?.remove();

    const elId = 'dynamic-layers-style';
    const selector = `style#${elId}`;
    const selectedEl = document.querySelector(selector);

    const el = selectedEl ? selectedEl : document.createElement('style');
    el.id = elId;

    const cssMakeShapesOutsideLayerInactive = alwaysDisplayCommon
      ? `g[layer-id]:not([layer-id='${selectedLayer}']):not(:where(${clickableShapeTypesAnyLayer
          .map((type) => `[type="${type}"]`)
          .join(', ')}))`
      : `g[layer-id]:not([layer-id='${selectedLayer}'])`;

    const css = `
      ${cssMakeShapesOutsideLayerInactive} {
        pointer-events: none !important;
        opacity: 0.5;
      }
      ${Object.entries(layers)
        .filter(([, layer]) => !layer.visibility)
        .map(
          ([, invisibleLayer]) =>
            `g[layer-id='${invisibleLayer.id}'] {
              display: none;
            }`
        )
        .join('\n')}
        ${Object.values(layers)
          .map(
            (layer) =>
              `g[layer-id='${layer.id}'] {
                color: ${layer.color};
              }`
          )
          .join('\n')}
    `;
    el.textContent = css;

    document.head.append(el);
  }, [alwaysDisplayCommon, layers, selectedLayer]);

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  return (
    <div
      className={clsx(classes.root, {
        [classes.middleFade]: !mouseOvering,
      })}
    >
      <Card
        className={[classes.card].filter((className) => !!className).join(' ')}
        onMouseEnter={() => setMouseOvering(true)}
        onMouseLeave={() => setMouseOvering(false)}
      >
        <CardHeader
          className={classes.cardHeader}
          avatar={<LayersIcon></LayersIcon>}
          sx={{ userSelect: 'none' }}
          title="Layers"
          action={
            <>
              {Object.keys(layers).length > 4 && (
                <IconButton title={folded ? 'Expand' : 'Fold'} onClick={() => setFolded(!folded)} size="large">
                  {folded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                </IconButton>
              )}
              <Tooltip title={!editCircuitPerm ? 'You do not have the permission to edit the circuit' : ''}>
                <Box component="span">
                  <IconButton
                    title="Layer parameters"
                    aria-label="Layers parameters"
                    onClick={handleClickSettings}
                    size="large"
                    disabled={!editCircuitPerm}
                  >
                    <TuneIcon />
                  </IconButton>
                </Box>
              </Tooltip>
              <IconButton
                title={showListLayers ? 'Hide' : 'Show'}
                aria-label="Show layers"
                onClick={handleClickShowLayers}
                size="large"
              >
                {!showListLayers ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
              </IconButton>
            </>
          }
          classes={{ action: classes.action }}
        ></CardHeader>
        <Collapse in={showListLayers} timeout="auto" /* unmountOnExit */>
          <CardContent className={classes.cardContent}>
            <List
              dense={true}
              className={[classes.listLayers, folded ? classes.folded : '']
                .filter((className) => !!className)
                .join(' ')}
            >
              {Object.values(layers)
                .sort(sortLayersByOrder)
                .map((layer) => (
                  <LayerComponent
                    key={layer.id}
                    id={layer.id}
                    layer={layer}
                    selected={selectedLayer === layer.id}
                    nestedLevel={0}
                  ></LayerComponent>
                ))}
            </List>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};
