import type { CircuitRack } from 'models/circuit';
import { roundCoordinates } from 'utils/helpers';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: RackActionTypes,
  creators: {
    create: createRackAction,
    createFailure: createRackFailureAction,
    createSuccess: createRackSuccessAction,
    delete: deleteRackAction,
    deleteFailure: deleteRackFailureAction,
    deleteSuccess: deleteRackSuccessAction,
    loadOne: loadRackAction,
    loadOneFailure: loadRackFailureAction,
    loadOneSuccess: loadRackSuccessAction,
    saveFailure: saveRackFailureAction,
    saveSuccess: saveRackSuccessAction,
    select: selectRackAction,
  },
} = createEntityActions<CircuitRack>('Racks');

const {
  creators: { save },
} = createEntityActions<CircuitRack>('Racks');

export function saveRackAction(payload: Partial<CircuitRack> & RoadEditorAction['payload']): SaveRack {
  let action = save(payload);
  delete payload.userAction;
  if (action.payload.geometry) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        geometry: {
          ...action.payload.geometry,
          coordinates: action.payload.geometry.coordinates.map((coords) =>
            coords.map((coord: number[]) => roundCoordinates(coord))
          ),
        },
      },
    };
  }

  return action;
}

export interface CreateRack extends CreateAction<CircuitRack> {}
export interface CreateRackFailure extends CreateFailureAction {}
export interface CreateRackSuccess extends CreateSuccessAction<CircuitRack> {}
export interface DeleteRack extends DeleteAction {}
export interface DeleteRackFailure extends DeleteFailureAction {}
export interface DeleteRackSuccess extends DeleteSuccessAction {}
export interface LoadRack extends LoadOneAction {}
export interface LoadRackFailure extends LoadOneFailureAction {}
export interface LoadRackSuccess extends LoadOneSuccessAction<CircuitRack> {}
export interface SaveRack extends SaveAction<CircuitRack> {
  payload: SaveAction<CircuitRack>['payload'] & RoadEditorAction['payload'];
}
export interface SaveRackFailure extends SaveFailureAction {}
export interface SaveRackSuccess extends SaveSuccessAction<CircuitRack> {}
export interface SelectRack extends SelectAction {}

export type RackActions =
  | CreateRack
  | CreateRackFailure
  | CreateRackSuccess
  | DeleteRack
  | DeleteRackFailure
  | DeleteRackSuccess
  | LoadRack
  | LoadRackFailure
  | LoadRackSuccess
  | SaveRack
  | SaveRackFailure
  | SaveRackSuccess
  | SelectRack;
