import type { UpdatedShapesAction } from 'epics/circuit.epic';
import { remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import store from 'store';
import type { YArrayEvent } from 'yjs';
import type { YArray } from 'yjs/dist/src/internals';
import { setLastCircuitTransactionOrigin } from '../../../multiplayer/globals';
import { applyUpdatedShapesFromYJSAction } from './../../../actions/circuit';

export const useUpdatedShapesActionsSubscription = (loading: boolean): void => {
  const updatedShapesActionsArray = remoteDoc?.getArray('updatedShapesActions') as YArray<UpdatedShapesAction>;

  const handleUpdate = (event: YArrayEvent<UpdatedShapesAction>): void => {
    const addedShapesActionsSet = event.changes.added.values();

    for (const item of addedShapesActionsSet) {
      const newShapesActionsArray = item.content.getContent() as UpdatedShapesAction[];

      store.dispatch(applyUpdatedShapesFromYJSAction(newShapesActionsArray));
    }
  };

  const waitForLoadingToFinish = (): Promise<void> => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!loading) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  };

  useLayoutEffect(() => {
    const observerHandle = (event: YArrayEvent<UpdatedShapesAction>, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      setLastCircuitTransactionOrigin(isTransactionLocal ? 'local' : 'remote');

      if (!loading) {
        handleUpdate(event);
      } else {
        waitForLoadingToFinish().then(() => handleUpdate(event));
      }
    };

    updatedShapesActionsArray.observe(observerHandle);

    return () => {
      updatedShapesActionsArray.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
};
