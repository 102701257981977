import { Device } from 'drawings/editor-elements/device.element';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitDevice } from 'models/circuit';
import type { pointSize } from 'models/drawings';
import { useEffect, useMemo, useRef } from 'react';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface DeviceComponentProps {
  device: CircuitDevice;
  id: string;
  selected?: boolean;
  size?: pointSize;
}

export function DeviceComponent(props: DeviceComponentProps): JSX.Element {
  const device = props.device;
  const ref = useRef<SVGSVGElement>(null);
  const size = props.size ?? 'small';

  const shape = useMemo(() => {
    if (props.id && device && device.id) {
      return new Device(device.id.toString(), device.geometry, projection, zoomScale, device.properties, {
        size,
        refreshGeometryOnDrag: false,
      });
    }
  }, [device, props.id, size]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();

      // clickable zone
      const clickElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      // clickElement.setAttribute('cx', point.geometry.coordinates[0].toString());
      // clickElement.setAttribute('cy', (-point.geometry.coordinates[1]).toString());
      // clickElement.setAttribute('r', pointTextSizeToPx(size).toString());
      // clickElement.classList.add('select-shape-helper');

      if (node && clickElement) {
        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        node.insertBefore(clickElement, node.firstChild);
        ref.current.appendChild(node);
      }
    }
  }, [device.geometry.coordinates, shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return <g ref={ref}></g>;
}
