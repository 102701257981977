import { memoize } from 'moderndash';
import offsetPolygon from 'offset-polygon';
import { PreferencesService } from 'utils/preferences';

export function getGabaritCoords(gabaritName: string, modelName: string): number[][][] | undefined {
  if (!PreferencesService.arePreferencesFullyLoaded()) return;

  const serialOfThisModel = PreferencesService.getTrucks()?.find((truck) => truck.modelName === modelName);
  if (!serialOfThisModel) return;

  const isOffsetGabarit = gabaritName.startsWith('offset');
  let offsetDistance = 0;
  if (isOffsetGabarit) {
    const regexp = /offset([+-]\d+\.\d+)m/;
    const match = gabaritName.match(regexp);
    if (match) offsetDistance = parseFloat(match[1]);

    gabaritName = gabaritName.split('_')[1];
  }

  let gabarit: string[];
  try {
    gabarit = PreferencesService.getPreferenceValue(gabaritName, serialOfThisModel.serial) as string[];
  } catch (e) {
    return;
  }

  if (!gabarit) return;

  // from ["cpx(1.775,0.176)", "cpx(1.734,0.403)", ...] (string[]) to [[[1.775, 0.176], [1.734, 0.403], ...]] (number[][][])
  let coords = [
    gabarit.map(
      (cpxStr) =>
        cpxStr
          .split('(')[1]
          .replace(')', '')
          .split(',')
          .map((nb) => parseFloat(nb) * 100) // m -> cm
    ),
  ];

  if (isOffsetGabarit) {
    const polygon = coords[0].slice(0, -1).map((point) => ({ x: point[0], y: point[1] }));

    const polygonWithOffset = offsetPolygon(polygon, offsetDistance * 100);

    coords = [polygonWithOffset.map((point) => [point.x, point.y])];
    coords[0].push(coords[0][0]);
  }

  return coords;
}

export const getGabaritCoordsCached = memoize(getGabaritCoords, {
  resolver: (gabaritName: string, modelName: string) => `${gabaritName}_${modelName}`,
  ttl: 10000,
});
