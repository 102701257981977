import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SxProps } from '@mui/material';
import { Collapse, FormControlLabel, FormGroup } from '@mui/material';
import type { Theme } from '@mui/system';
import type { JSXElementConstructor, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'utils/mui-theme';

interface ExpandProps {
  label: string | ReactElement<any, string | JSXElementConstructor<any>>;
  openByDefault?: boolean;
  forceOpenState?: boolean;
  onChange?: (newState: boolean) => void;
  sx?: SxProps<Theme> | undefined;
  children: React.ReactNode;
}
export function Expand({
  label,
  openByDefault = false,
  forceOpenState,
  children,
  sx,
  onChange,
}: ExpandProps): JSX.Element {
  const [open, setOpen] = useState(openByDefault);

  useEffect(() => {
    if (forceOpenState !== undefined) {
      setOpen(forceOpenState);
    }
  }, [forceOpenState]);

  const handleChange = useCallback(() => {
    if (forceOpenState === undefined) {
      setOpen((state) => !state);
    }

    if (onChange) onChange(!open);
  }, [forceOpenState, onChange, open]);

  return (
    <FormGroup
      sx={{
        marginTop: theme.spacing(1),
        ...sx,
      }}
    >
      <FormControlLabel
        onClick={handleChange}
        control={
          <ExpandMoreIcon
            sx={{
              transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        }
        label={label}
        sx={{ marginLeft: theme.spacing(1) }}
      />
      <Collapse in={open}>{children}</Collapse>
    </FormGroup>
  );
}
