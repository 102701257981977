/**
 * Compute the sha1 hash of a string
 * @param txt the string to hash
 * @returns the sha1 hash of the string
 */
export async function text2sha1(txt: string): Promise<string> {
  if (typeof TextEncoder === 'undefined') {
    // eslint-disable-next-line no-console
    console.warn('TextEncoder is not supported');

    return 'sha1 not computed - TextEncoder is not supported';
  }

  if (typeof crypto === 'undefined') {
    // eslint-disable-next-line no-console
    console.warn('crypto is not supported');

    return 'sha1 not computed - crypto is not supported';
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(txt);
  const hash = await crypto.subtle.digest('SHA-1', data);

  return hex(hash);
}

/**
 * Convert an ArrayBuffer to a hex string
 * @param buffer the ArrayBuffer to convert
 * @returns the hex string
 */
function hex(buffer: ArrayBuffer): string {
  const hexCodes: string[] = [];
  const view = new DataView(buffer);
  for (let i = 0; i < view.byteLength; i += 4) {
    // Using getUint32 reduces the number of iterations needed (we process 4 bytes each time)
    const value = view.getUint32(i);
    // toString(16) will give the hex representation of the number without padding
    const stringValue = value.toString(16);
    // We use concatenation and slice for padding
    const padding = '00000000';
    const paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }
  // Join all the hex strings into one

  return hexCodes.join('');
}
