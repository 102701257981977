import { localDoc, remoteDoc } from 'multiplayer/globals';
import { applyUpdate, encodeStateAsUpdate, encodeStateVector } from 'yjs';

export function syncYJSLocalToRemote(): void {
  if (!remoteDoc) return;

  const remoteStateVector = encodeStateVector(remoteDoc);
  const update = encodeStateAsUpdate(localDoc, remoteStateVector);

  /* Temporary downgrade to v1 update beacuse of memory & speed issues */
  // const localDiff = convertUpdateFormatV1ToV2(update);

  applyUpdate(remoteDoc, update, 'local');
  // applyUpdateV2(remoteDoc, localDiff, 'local');
}
