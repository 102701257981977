import { CircuitService } from 'services/circuit.service';

/**
 * Check the next free ID in the circuit.
 * @returns A tuple containing a boolean indicating if the next free ID is available and the new next free ID.
 */
export function checkNextFreeId(): [isCurrentNextFreeIdOk: boolean, newNextFreeId: number] {
  const maxId = CircuitService.getMaxShapeId();
  const currentNextFreeId = window.nextFreeId;

  if (maxId > currentNextFreeId) {
    return [false, maxId + 1];
  }

  return [true, currentNextFreeId];
}
