import { cloneDeep } from 'lodash';
import type { LoadedCellTemplate } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';

/**
 * This function fixes the perception cell template for conveyors that have a number of perception references that does not match the number of pallet in the load (load.N)
 * @link https://redmine.balyo.com/issues/44091
 * @returns a new fixed cellTemplate for conveyor
 */
export function fixPerceptionCellTemplateConveyors(
  cellTemplates: Record<string, LoadedCellTemplate>
): Record<string, LoadedCellTemplate> {
  let cellTemplatesAlreadyModified = false;
  let newCellTemplates = cellTemplates;

  Object.values(cellTemplates).forEach((cellTemplate, key) => {
    // here we're only dealing with conveyors because we know that we've only introduced the concern for conveyors, so we thought we could do without them, optimization
    if (!cellTemplate?.forConveyor) return;

    const loads = cellTemplate.loads;
    loads.forEach((load, loadIndex) => {
      if (load.N === load.references.length) return;

      load.references = ['none'];
      if (!cellTemplatesAlreadyModified) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        newCellTemplates = cloneDeep(newCellTemplates);
        cellTemplatesAlreadyModified = true;
      }

      const newLoad = newCellTemplates[key]?.loads[loadIndex];
      if (!newLoad) {
        // eslint-disable-next-line no-console
        console.error('Error with the new load cell templates');

        return;
      }

      if (load.N < load.references.length) {
        newLoad.references.length = load.N;

        // eslint-disable-next-line no-console
        console.log('There is no reference number so the value is the default value');

        SnackbarUtils.info('Default perception value added');
      } else {
        // eslint-disable-next-line no-console
        console.error('Reference number error');
      }
    });
  });

  return newCellTemplates;
}
