import { updateSegmentsPortionsAction } from 'actions/circuit';
import type { AppState } from 'reducers/state';
import store from 'store';

/**
 * Check that all segments have their portions computed
 * @param segmentsState the segments state
 * @returns true if the circuit is ok
 */
export function checkSegmentsPortions(segmentsState: AppState['circuit']['present']['segments']): boolean {
  const segments = segmentsState.entities;
  const segmentsIds = segmentsState.ids;

  for (const segmentId of segmentsIds) {
    const segment = segments[segmentId];
    const portions = segment.properties.portions;
    if (!portions || portions.length === 0) {
      return false;
    }

    const allPortionsOfThisSegmentOk = portions.every((portion) => {
      return (
        !!portion &&
        !!portion.id &&
        !!portion.points &&
        portion.points.length > 0 &&
        portion.points.every((point) => {
          return !!point;
        })
      );
    });

    if (!allPortionsOfThisSegmentOk) {
      // eslint-disable-next-line no-console
      console.warn(`Segment ${segmentId} has weird portions:`, portions, segment);

      return false;
    }
  }

  return true;
}

/**
 * Recompute the portions of the segments that have weird portions or no portions at all
 * @param segmentsState the segments state
 * @returns the number of segments that have been recomputed
 */
export function recomputeSegmentsPortions(segmentsState: AppState['circuit']['present']['segments']): number {
  const segments = segmentsState.entities;
  const segmentsIds = segmentsState.ids;

  const segmentsIdsToCompute = new Set<string>();

  for (const segmentId of segmentsIds) {
    const segment = segments[segmentId];
    const portions = segment.properties.portions;
    if (!portions || portions.length === 0) {
      segmentsIdsToCompute.add(segmentId);
      continue;
    }

    const allPortionsOfThisSegmentOk = portions.every((portion) => {
      return (
        !!portion &&
        !!portion.id &&
        !!portion.points &&
        portion.points.length > 0 &&
        portion.points.every((point) => {
          return !!point;
        })
      );
    });

    if (!allPortionsOfThisSegmentOk) {
      segmentsIdsToCompute.add(segmentId);
    }
  }

  store.dispatch(
    updateSegmentsPortionsAction({
      segmentsIds: Array.from(segmentsIdsToCompute),
    })
  );

  return segmentsIdsToCompute.size;
}
