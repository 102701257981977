import React from 'react';
import { LargeError } from 'components/core';
import { FullPageLayout } from 'components/layouts';

interface ForbiddenPageProps {}

export function ForbiddenPage(props: ForbiddenPageProps): JSX.Element {
  return (
    <FullPageLayout verticallyCentered>
      <LargeError code={403} />
    </FullPageLayout>
  );
}
