import { updateSegmentsPortionsAction } from 'actions/circuit';
import { saveSegmentAction } from 'actions/segment';
import type { CircuitDevice } from 'models/circuit';
import store from 'store';

export async function computeAgainAllPortions(): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, 0));

  const circuitState = store.getState().circuit.present;
  const segmentsIds = circuitState.segments.ids;
  const segments = circuitState.segments.entities;

  // eslint-disable-next-line no-console
  console.log('Recomputing all the portions of all the segments...');
  // eslint-disable-next-line no-console
  console.log(`${segmentsIds.length} segments found`);

  // eslint-disable-next-line no-console
  console.log('Step 1/2: Deleting all the portions of all the segments...');

  for (let i = 0; i < segmentsIds.length; i++) {
    const segmentId = segmentsIds[i];
    const segment = segments[segmentId];
    if (segment) {
      store.dispatch(
        saveSegmentAction({
          ...segment,
          properties: {
            ...segment.properties,
            portions: [],
          },
        })
      );

      if (i % 100 === 0 && i !== 0) {
        // eslint-disable-next-line no-console
        console.log(
          `STEP 1/2: ${i}/${segmentsIds.length} segments processed (${((i / segmentsIds.length) * 100).toFixed(0)}%)`
        );
        await new Promise((resolve) => setTimeout(resolve, 0));
      }
    }
  }

  // eslint-disable-next-line no-console
  console.log('Step 2/2: Recomputing all the portions of all the segments...');
  for (let i = 0; i < segmentsIds.length; i += 100) {
    const segmentIdsChunk = segmentsIds.slice(i, i + 100);
    store.dispatch(
      updateSegmentsPortionsAction({
        segmentsIds: segmentIdsChunk,
      })
    );

    // eslint-disable-next-line no-console
    console.log(
      `STEP 2/2: ${i}/${segmentsIds.length} segments processed (${((i / segmentsIds.length) * 100).toFixed(0)}%)`
    );
    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  // eslint-disable-next-line no-console
  console.log('Recomputing all the portions of all the segments... done');
}

/**
 * Returns a cpx string understandable by kiwi from a device circuit object
 * @param device the device circuit object
 * @returns the string cpx
 */
export function devicePositionToCpxStr(device: CircuitDevice): string {
  const coordinates = device.geometry.coordinates;

  return `cpx(${(coordinates[0] / 100).toFixed(3)}, ${(coordinates[1] / 100).toFixed(3)})`;
}

/**
 * Convert a cpx position to a position
 * @param strCpx the cpx string (cpx(2, 12) for example)
 * @param isInt do we convert the number to an int or to a float? default value = int
 * @returns the position
 */
export function cpxStrToPosition(strCpx: string, isInt = true): number[] {
  const pos = strCpx
    .split('(')[1]
    .replace(')', '')
    .split(',')
    .map((c) => c.trim())
    .map((c) => (isInt ? parseInt(c, 10) : parseFloat(c)));

  let isValidCpx = pos.length === 2;
  pos.forEach((coord) => {
    if (isNaN(coord)) {
      isValidCpx = false;
    }
  });

  // eslint-disable-next-line no-console
  if (!isValidCpx) console.error(`Invalid cpx string: ${strCpx}`);

  return pos;
}

/**
 * Converts a number to a two-digit string representation.
 * @param nb The number to convert.
 * @returns The two-digit string representation of the number.
 */
export function toTwoDigits(nb: number): string {
  return nb < 10 ? '0' + nb.toString() : nb.toString();
}
