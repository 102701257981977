import type { GabaritProperties } from 'models/circuit';
import store from 'store';

/**
 * Function to get the gabarit of a turn, segment or point
 * @param gabarit - The already existing or undefined gabarit if there is none
 * @param modelsName - All the possible models name for the gabarit
 * @returns The already existing gabarit or defaultGabari from the layer if there is none
 */
export function getGabarit(gabarit: GabaritProperties | undefined, modelsName: string[]): GabaritProperties {
  const selectedLayer = store.getState().circuit.present.layers.selectedLayer;
  const layer = store.getState().circuit.present.layers.layers[selectedLayer];
  const layerDefaultModelName = layer.defaultModel;
  const layerDefaultPattern = layer.defaultPattern;

  const defaultGabarit = {
    display: false,
    modelName: layerDefaultModelName ? layerDefaultModelName : modelsName ? modelsName[0] : '',
    type: layerDefaultPattern ? layerDefaultPattern : 'general/shape',
  };

  return gabarit ? gabarit : defaultGabarit;
}
