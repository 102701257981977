import { useMemo } from 'react';
import { isThereAtLeastOneBattPoint } from 'utils/circuit/points';

/**
 * Custom hook to check if there are any battery points in the circuit.
 * @returns {boolean} - True if there are battery points, false otherwise.
 */
export function useBattPointsInTheCircuit(): boolean {
  const battPointsInTheCircuit = useMemo(() => isThereAtLeastOneBattPoint(), []);

  return battPointsInTheCircuit;
}
