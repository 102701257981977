import { debounce } from 'lodash';
import type { BalyoSimulationPromptMsg } from 'models/simulation';
import store from 'store';
import { displayPromptSnackbar } from './display-prompt';
import { setLoadingState, type SimulationSliceState } from './simulation';

let prefWarningsMessages: BalyoSimulationPromptMsg[] = [];

const prefWarningsRegexp = /\[PREF\] Dictionary preference '(.*)' has already been defined, skipping new definition/;

const displaySnackbarPrefWarningsDebounced = debounce(displaySnackbarPrefWarnings, 1000, {
  trailing: true,
});

/**
 * Checks if the message needs to be displayed in a snackbar.
 *
 * If the message is a preference warning, it will be added to a list of messages to be displayed in a single snackbar.
 * If the message is not a preference warning, it will be displayed immediately.
 *
 * @param payload The message to check
 * @param simulationLoadedOnce Whether the simulation has been loaded at least once
 * @returns Whether the message needs to be displayed in a snackbar
 */
export function checkMessageNeedsSnackbar(payload: BalyoSimulationPromptMsg, simulationLoadedOnce: boolean): boolean {
  const isPrefWarning = prefWarningsRegexp.test(payload.msg);

  if (isPrefWarning) {
    if (simulationLoadedOnce) {
      addPrefWarningsMessage(payload);

      displaySnackbarPrefWarningsDebounced();
    }
  }

  return !isPrefWarning;
}

/**
 * Adds a preference warning message to the list of messages to be displayed in a single snackbar.
 *
 * @param message The preference warning message
 */
function addPrefWarningsMessage(message: BalyoSimulationPromptMsg): void {
  prefWarningsMessages.push(message);
}

/**
 * Displays a snackbar with all preference warning messages.
 */
function displaySnackbarPrefWarnings(): void {
  const prefs: string[] = [];

  prefWarningsMessages.forEach((message) => {
    const pref = message.msg.match(prefWarningsRegexp);
    if (pref) {
      prefs.push(pref[1]);
    }
  });

  const msgJsx = (
    <>
      The following dictionary preferences have already been defined and will be skipped:
      <ul>
        {prefs.map((pref) => (
          <li key={pref}>{pref}</li>
        ))}
      </ul>
    </>
  );
  const msgStr = `The following dictionary preferences have already been defined and will be skipped: ${prefs.join(
    ', '
  )}`;

  // eslint-disable-next-line no-console
  console.warn(msgStr);

  displayPromptSnackbar({
    ...prefWarningsMessages[0],
    severity: 'warning',
    title: 'Dictionary preferences already defined',
    msg: msgJsx,
  });

  prefWarningsMessages = [];
}

function dispatchLoadingSimuationState(simulationLoadingState: SimulationSliceState['loading']): void {
  store.dispatch(setLoadingState(simulationLoadingState));
}

export const dispatchLoadingSimuationStateDebounced = debounce(dispatchLoadingSimuationState, 500, {
  leading: true,
  maxWait: 1000,
});
