import WarningIcon from '@mui/icons-material/Warning';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

interface VersionTooLowChipProps {
  projectOpened: boolean;
  isSdkVersionOk: boolean;
  sdkVersion: string | null | undefined;
  minimumViableSdkVersion: string | undefined;
  NEW4XCoreVersion: number | undefined;
}

const VersionTooLowChip = ({
  projectOpened,
  isSdkVersionOk,
  sdkVersion,
  minimumViableSdkVersion,
  NEW4XCoreVersion,
}: VersionTooLowChipProps): JSX.Element => {
  return (
    <>
      {projectOpened ? (
        <Tooltip
          title={
            isSdkVersionOk
              ? undefined
              : `The SDK version has a known bug with Road Editor. We recommend to update to ${minimumViableSdkVersion} or higher.`
          }
        >
          <Chip
            label={`SDK Version: ${sdkVersion || 'unknown'}`}
            variant="outlined"
            size="small"
            color={isSdkVersionOk ? undefined : 'warning'}
            icon={isSdkVersionOk ? undefined : <WarningIcon />}
            sx={{
              padding: isSdkVersionOk ? undefined : '0 4px',
              backgroundColor: 'white',
            }}
          />
        </Tooltip>
      ) : null}
      {projectOpened ? (
        <Chip
          label={`NEW4X Core Version: ${NEW4XCoreVersion || 'unknown'}`}
          variant="outlined"
          size="small"
          sx={{ backgroundColor: 'white' }}
          color={NEW4XCoreVersion ? undefined : 'warning'}
        />
      ) : null}
    </>
  );
};

export default VersionTooLowChip;
