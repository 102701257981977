import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      textTransform: 'uppercase',
      position: 'relative',
      color: 'transparent!important',
      '&:focus': {
        outline: 'none',
      },
      '&:before': {
        content: 'attr(data-clipped)',
        position: 'absolute',
        top: 0,
        left: 0,
        color: theme.palette.primary.main,
        clipPath: 'polygon(0% 100%, 100% 100%, 100% 40%, 0 60%)',
      },
      '&:after': {
        content: 'attr(data-clipped)',
        position: 'absolute',
        top: 0,
        left: 0,
        color: theme.palette.text.primary,
        clipPath: 'polygon(0 0, 100% 0%, 100% 36%, 0 56%)',
        animation: '$slide 5s infinite',
      },
    },
    message: {},
    '@keyframes slide': {
      '0%': { transform: 'translateX(0)' },
      '50%': { transform: 'translate(-20px, 2%)' },
    },
  })
);

interface StateProps {}

interface DispatchProps {}

export interface LargeErrorProps extends StateProps, DispatchProps {
  code?: number;
  message?: string;
  title?: string;
}

export function LargeError(props: LargeErrorProps): JSX.Element {
  const classes = useStyles();
  const { code } = props;
  const { title, message } = code ? getTexts(code) : props;

  return (
    <div className={classes.container}>
      <Typography variant="h1" data-clipped={title} className={classes.title}>
        {title}
      </Typography>
      <Typography variant="h4" color="inherit" className={classes.message}>
        {message}
      </Typography>
    </div>
  );
}

interface Texts {
  title: string;
  message: string;
}

function getTexts(code: number): Texts {
  switch (code) {
    case 401:
      return { title: 'Unauthorized', message: 'You need to login first' };
    case 403:
      return { title: 'Forbidden', message: 'You are not allowed to access this page' };
    case 404:
      return { title: 'Not found', message: 'Page not found' };
    case 418:
      return { title: "I'm a teapot", message: "I'm most definitely a teapot 🍵" };
    case 501:
      return { title: 'Not implemented', message: 'Page not implemented yet.' };
    default:
      return { title: 'Error', message: 'Unknown error' };
  }
}
