import StandardSchedulerConfig from 'simulation/scheduler/configurations/standard.json';

import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import { schedulerConfigSchema } from 'simulation/scheduler/scheduler-config';

interface AvailableSchedulerConfig {
  /**
   * The name of the configuration
   */
  name: string;
  /** The description to help the user to understand the configuration */
  description: string | JSX.Element;
  /**
   * The content in the format of a JS object of the json configuration
   */
  configuration?: SchedulerConfig;
}

export function getAvailableSchedulerConfig(): AvailableSchedulerConfig[] {
  return [
    {
      name: 'standard',
      description: 'The standard configuration to use by default',
      configuration: schedulerConfigSchema.parse(StandardSchedulerConfig),
    },
    // {
    //   name: 'due date',
    //   description: 'Consider the due date parameter',
    //   configuration: schedulerConfigSchema.parse(DueDateConfig),
    // },
    {
      name: 'custom',
      description: 'Edit manually the configuration parameters (advanced users)',
    },
  ];
}
