import type { ProxyMethods, RemoteObject } from 'comlink';
import { CircuitService } from 'services/circuit.service';
import { PreferencesService } from 'utils/preferences';
import type { Simulation } from './simulation.model';

export const trafficSWALFileName = 'traffic.SWAL';

interface LoadCircuitSimulationProps {
  /** a ref to the circuit pointer */
  circuitPtr?: React.MutableRefObject<number | null>;
}

interface LoadCircuitSimulationRes {
  /** the xml circuit */
  circuitXml?: string;
  /** wether we found an error while generating the xml circuit */
  errorCircuitXml?: boolean;
  /** wether we found an error while initializing the simulation service */
  errorSimulationService?: boolean;
  /** wether we found an error while loading the circuit in the simulation service */
  errorLoadingCircuit?: boolean;
  /** the simulation service */
  simulationService?: RemoteObject<Simulation> & ProxyMethods;
}

/**
 * This function generate an xml circuit from the current store state and loads it in the simulation service
 * @param props the circuit pointer to fill in (optional)
 * @returns the xml circuit and the simulation service (or the errors)
 */
export async function loadCircuitSimulation(props?: LoadCircuitSimulationProps): Promise<LoadCircuitSimulationRes> {
  const generateXML = (await import('utils/export/generate-xml')).generateXML;

  // eslint-disable-next-line no-console
  console.log('Generating the xml file...');

  const [xml] = await (async () => {
    try {
      const xmlTmp = await generateXML({
        zones: CircuitService.zones,
        segments: CircuitService.segments,
        stockZones: CircuitService.stockZones,
        measurers: CircuitService.measurers,
        turns: CircuitService.turns,
        racks: CircuitService.racks,
        points: CircuitService.points,
        pretty: true,
      });

      return xmlTmp;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while generating the xml file', e);

      return [null, null];
    }
  })();

  if (!xml) {
    return {
      errorCircuitXml: true,
    };
  }

  // eslint-disable-next-line no-console
  console.log('XML file generated');

  const simulationService = (await import('services/simulation.service'))?.simulationService;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('Simulation service not loaded');

    return {
      errorSimulationService: true,
    };
  }

  // eslint-disable-next-line no-console
  console.log(`Getting the ${trafficSWALFileName} file...`);
  const trafficSWALFile = await (async () => {
    try {
      const projectDirectory = await PreferencesService.getDirectoryHandle();
      if (!projectDirectory) throw new Error('Project directory handle not found');

      const trafficSWALFileHandle = await projectDirectory.getFileHandle(trafficSWALFileName, { create: false });
      const trafficSWALFileTmp = trafficSWALFileHandle ? await trafficSWALFileHandle.getFile() : null;

      return trafficSWALFileTmp;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Error while getting the ${trafficSWALFileName} file`, e);

      return null;
    }
  })();

  if (trafficSWALFile) {
    // eslint-disable-next-line no-console
    console.log(`Loading the ${trafficSWALFileName} file...`);
    const trafficSWALFileContent = new Uint8Array(await trafficSWALFile.arrayBuffer());

    const trafficSWALFileNamePtr = await simulationService.allocateUTF8(trafficSWALFileName);

    await simulationService._OS_WasmWrapper_writeFile(
      trafficSWALFileNamePtr,
      trafficSWALFileContent.buffer,
      trafficSWALFileContent.byteLength
    );

    // eslint-disable-next-line no-console
    console.log(`${trafficSWALFileName} file loaded`);
  } else {
    // eslint-disable-next-line no-console
    console.warn(`${trafficSWALFileName} file not found`);
  }

  // eslint-disable-next-line no-console
  console.log('Loading the circuit in the simulation service...');
  let circuitPtr: number;
  try {
    // circuitPtr.current = await simulationService.allocateUTF8(xml);
    circuitPtr = await simulationService.allocateUTF8(xml);
    if (props && props.circuitPtr) props.circuitPtr.current = circuitPtr;
    await simulationService._TRACK_WasmWrapper_loadCircuit(circuitPtr);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while loading the circuit in the simulation service', e);

    return {
      errorLoadingCircuit: true,
    };
  }

  return {
    circuitXml: xml,
    simulationService,
  };
}
