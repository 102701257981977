export const BALYO_METERS_TO_LIDAR = 25;
export const DISPLAY_UNIT_FACTOR = 100; // Centimeters

export const UNIT_COUNT_PER_METER = 1000000; // BALYO CIRCUIT

/**
 * Points size accepted in the settings for the tool state
 * @see {isPointSize} ts-auto-guard:type-guard
 */
export type pointSize = 'small' | 'medium' | 'large';
/**
 * Line stroke accepted in the settings for the tool state
 * @see {isLineStrokeSize} ts-auto-guard:type-guard
 */
export type lineStrokeSize = 's' | 'm' | 'l' | 'xl';

/**
 * Return to move tool type accepted in the settings for the tool state
 * @see {isReturnToMoveToolType} ts-auto-guard:type-guard
 */
export type ReturnToMoveToolType = 'always' | 'never' | 'sometimes';
