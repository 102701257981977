import { useDebouncedValue } from '@mantine/hooks';
import { Edit } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { selectToolAction } from 'actions';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { Border } from 'components/utils/border';
import { collapseTransitionDuration } from 'components/utils/constants';
import { setFlow, setSelectedFlowId } from 'flows/flows';
import { Tools } from 'models/tools';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { RobotsList } from './robot-list';

export function FlowAssignationToolbox(): JSX.Element {
  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.RobotsSimulationConfiguration)?.icon;
  }, []);

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '400px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title="Flow Assignation"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <FlowList />
      </CardContent>
    </Card>
  );
}

function FlowList(): JSX.Element {
  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.flows.flows);
  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);
  const [delayedSelectedFlowId] = useDebouncedValue(selectedFlowId, collapseTransitionDuration);

  const handleClickFlow = useCallback(
    (flowId: string) => {
      dispatch(setSelectedFlowId(flowId));
    },
    [dispatch]
  );

  const selectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === selectedFlowId);
  }, [flows, selectedFlowId]);
  const delayedSelectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === delayedSelectedFlowId);
  }, [delayedSelectedFlowId, flows]);

  const displayFlowData = selectedFlowId === delayedSelectedFlowId;

  const handleEditFlow = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, flowId: string) => {
      e.stopPropagation();

      dispatch(setSelectedFlowId(flowId));
      dispatch(selectToolAction({ toolName: Tools.FlowConfiguration }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedFlow && selectedFlow.robotsAssigned === undefined) {
      dispatch(
        setFlow({
          ...selectedFlow,
          robotsAssigned: 'all',
        })
      );
    }
  }, [dispatch, selectedFlow]);

  const handleCreateFlow = useCallback(() => {
    dispatch(selectToolAction({ toolName: Tools.FlowConfiguration }));
  }, [dispatch]);

  return (
    <List sx={{ width: '100%' }}>
      {flows.length === 0 && (
        <>
          <Alert severity="info">No flows created yet.</Alert>
          <Button
            fullWidth
            variant="contained"
            onClick={handleCreateFlow}
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            Open the flow configuration
          </Button>
        </>
      )}

      {flows.map((flow) => {
        return (
          <React.Fragment key={flow.id}>
            <ListItemButton selected={flow.id === selectedFlowId} onClick={() => handleClickFlow(flow.id)}>
              <ListItemText primary={flow.name} />
              <ListItemIcon>
                <Tooltip title={`Edit ${flow.name}`} placement="left">
                  <IconButton size="small" edge="end" onClick={(e) => handleEditFlow(e, flow.id)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </ListItemIcon>
            </ListItemButton>
            {selectedFlow &&
              delayedSelectedFlow &&
              (selectedFlowId === flow.id || delayedSelectedFlowId === flow.id) && (
                <Collapse
                  in={displayFlowData}
                  timeout={collapseTransitionDuration}
                  sx={{
                    marginTop: theme.spacing(1.5),
                  }}
                >
                  <Border>
                    <RobotsList
                      // key={selectedFlowId === flow.id ? delayedSelectedFlow.id : selectedFlow.id}
                      flow={selectedFlowId === flow.id ? delayedSelectedFlow : delayedSelectedFlow}
                      mode="flow-assignation"
                      useCollapse={false}
                    />
                  </Border>
                </Collapse>
              )}
          </React.Fragment>
        );
      })}
    </List>
  );
}
