import type { UserProfile } from 'models';
import { AuthService } from 'services/auth.service';
import { Container } from 'typescript-ioc';

export interface AuthState {
  loggedIn: boolean;
  profile?: UserProfile;
  locale: string;
  token: {
    refreshing: boolean;
    refreshed: boolean;
    expiresAt: number;
  };
}

const initialState: AuthState = {
  loggedIn: false,
  profile: undefined,
  locale: 'en',
  token: {
    refreshing: false,
    refreshed: false,
    expiresAt: 0,
  },
};

export function getInitialStateAuth(): AuthState {
  const authService = Container.get(AuthService);

  const profile = authService.getLocalUserProfile();
  const locale = profile && profile.locale ? profile.locale : initialState.locale;
  const expiresAt = authService.tokenExpiresAt;
  const now = new Date().getTime();
  const token = {
    refreshing: false,
    refreshed: expiresAt > now,
    expiresAt,
  };

  return profile
    ? { loggedIn: true, profile: { ...profile, loaded: true, loading: false }, locale, token }
    : initialState;
}
