import type { CircuitCellTemplate } from 'models/circuit';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: CellTemplateActionTypes,
  creators: {
    create: createCellTemplateAction,
    createFailure: createCellTemplateFailureAction,
    createSuccess: createCellTemplateSuccessAction,
    delete: deleteCellTemplateAction,
    deleteFailure: deleteCellTemplateFailureAction,
    deleteSuccess: deleteCellTemplateSuccessAction,
    loadOne: loadCellTemplateAction,
    loadOneFailure: loadCellTemplateFailureAction,
    loadOneSuccess: loadCellTemplateSuccessAction,
    saveFailure: saveCellTemplateFailureAction,
    saveSuccess: saveCellTemplateSuccessAction,
    select: selectCellTemplateAction,
  },
} = createEntityActions<CircuitCellTemplate>('CellTemplates');

const {
  creators: { save },
} = createEntityActions<CircuitCellTemplate>('CellTemplates');

export function saveCellTemplateAction(
  payload: Partial<CircuitCellTemplate> & RoadEditorAction['payload']
): SaveCellTemplate {
  const action = save(payload);

  return action;
}

export interface CreateCellTemplate extends CreateAction<CircuitCellTemplate> {}
export interface CreateCellTemplateFailure extends CreateFailureAction {}
export interface CreateCellTemplateSuccess extends CreateSuccessAction<CircuitCellTemplate> {}
export interface DeleteCellTemplate extends DeleteAction {}
export interface DeleteCellTemplateFailure extends DeleteFailureAction {}
export interface DeleteCellTemplateSuccess extends DeleteSuccessAction {}
export interface LoadCellTemplate extends LoadOneAction {}
export interface LoadCellTemplateFailure extends LoadOneFailureAction {}
export interface LoadCellTemplateSuccess extends LoadOneSuccessAction<CircuitCellTemplate> {}
export interface SaveCellTemplate extends SaveAction<CircuitCellTemplate> {
  payload: SaveAction<CircuitCellTemplate>['payload'] & RoadEditorAction['payload'];
}
export interface SaveCellTemplateFailure extends SaveFailureAction {}
export interface SaveCellTemplateSuccess extends SaveSuccessAction<CircuitCellTemplate> {}
export interface SelectCellTemplate extends SelectAction {}

export type CellTemplateActions =
  | CreateCellTemplate
  | CreateCellTemplateFailure
  | CreateCellTemplateSuccess
  | DeleteCellTemplate
  | DeleteCellTemplateFailure
  | DeleteCellTemplateSuccess
  | LoadCellTemplate
  | LoadCellTemplateFailure
  | LoadCellTemplateSuccess
  | SaveCellTemplate
  | SaveCellTemplateFailure
  | SaveCellTemplateSuccess
  | SelectCellTemplate;
