import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

export function AuthCallBack(): JSX.Element {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      setRedirect(true);
    }, 5000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <>
      <CircularProgress />
      {redirect && <Redirect to="/" />}
    </>
  );
}
