import { z } from 'zod';

/**
 * The path to the scheduler config file in the project directory
 */
export const schedulerConfigFilePath = 'schedulerConfig.json';

const costSchema = z.object({
  /**
   * The weight of the cost in the cost function.
   * the a parameter in f(x) = a * x + b, where f is the cost function
   */
  weight: z.number(),
  /**
   * The offset of the cost in the cost function.
   * the b parameter in f(x) = a * x + b, where f is the cost function
   */
  offset: z.number(),
  /**
   * The maximum cost that can be considered in the cost function.
   * If the cost is higher than this value, it will be considered as this value.
   */
  costLim: z.number().optional(),
  /** How often the cost is updated [s] */
  costUpdateTime: z.number().optional(),
});

export const schedulerConfigSchema = z.object({
  /**
   * The cost associated to the tasks
   */
  taskCostParameters: z.object({
    /**
     * The cost associated with the priority of the task
     * The higher the cost, the more the scheduler will priorize the task when the priority is high
     */
    priorityCost: costSchema,
    /**
     * The cost associated with the due date of the task
     */
    dueDateCost: costSchema,
    /**
     * The cost associated with the creation date of the task
     * The higher the cost, the more the scheduler will priorize the task when it was created a long time ago
     */
    creationDateCost: costSchema,
  }),
  /**
   * The costs associated to the couples task-robot
   */
  coupleCostParameters: z.object({
    /**
     * The cost associated with the robots being empty (no load on the forks)
     * The higher the cost, the more the scheduler will priorize the robots close to the pick point
     */
    emptyTravelCost: costSchema,
  }),
  /**
   * Scheduler options
   */
  options: z.object({
    /**
     * How many events the scheduler should process at every iteration
     */
    eventToExeCount: z.number().optional(),
  }),
});

export type SchedulerConfig = z.infer<typeof schedulerConfigSchema>;
