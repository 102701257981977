import { centerOfMass, polygon as turfPolygon } from '@turf/turf';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type { CircuitShape, CircuitStockZone, SlotArray } from 'models/circuit';
import type { Scale } from 'models/maps';

/**
 * function that test wether a string is a positive int
 * @param str the string to test
 * @returns wether a string is a positive int
 */
export function isPositiveInt(str: string): boolean {
  const n = Math.floor(Number(str));

  return n !== Infinity && String(n) === str && n >= 0;
}

/**
 * Epsilon value to use to check if two floats are equal
 */
export const epsilon = 1e-5;

/**
 * The regex to validate a cell template load name
 */
export const regexCellTemplateLoadName = /^[a-zA-Z0-9]+$/;

export function cssTransformToPosition(transform: string): number[] {
  return transform
    .split('(')[1]
    .split(')')[0]
    .split(',')
    .map((nb) => parseFloat(nb.replace('px', '')));
}

/**
 * Compute a homotethy of a polygon
 * @param coordinates the coordinates of the polygon to enlarge/reduce
 * @param factor the factor of the homothety
 * @returns the new coordinates after the homothety
 */
export function polygonHomotethy(coordinates: number[][][], factor: number): number[][][] {
  const polygon = turfPolygon(coordinates);

  const center = centerOfMass(polygon);

  const newCoordinates = coordinates[0].map((point) => {
    const x = point[0] - center.geometry.coordinates[0];
    const y = point[1] - center.geometry.coordinates[1];

    return [x * factor + center.geometry.coordinates[0], y * factor + center.geometry.coordinates[1]];
  });

  return [newCoordinates];
}

/**
 * Compute a new larger (or smaller) polygon at a distance `distance` from a polygon
 * @param coordinates the coordinates of the polygon to enlarge/reduce
 * @param distance the distance to add to the polygon
 * @returns the new coordinates after the homothety
 */
export function polygonDistanceFrom(coordinates: number[][][], distance: number): number[][][] {
  const polygon = turfPolygon(coordinates);

  const center = centerOfMass(polygon);

  const distanceFromCenter = getDistanceBetweenPoints(center.geometry.coordinates, coordinates[0][0]);
  const distanceFromCenterPlusDistance = distanceFromCenter + distance;

  const homotethyFactor = distanceFromCenterPlusDistance / distanceFromCenter;

  return polygonHomotethy(coordinates, homotethyFactor);
}

/**
 * Type guard for CircuitShape
 * @param shape - The object to be checked
 * @returns A boolean indicating whether the object is a CircuitShape
 */
export function isCircuitShape(shape: unknown): shape is CircuitShape {
  return (
    !!shape &&
    typeof shape === 'object' &&
    'properties' in shape &&
    'geometry' in shape &&
    !!shape.properties &&
    !!shape.geometry &&
    typeof shape.properties === 'object' &&
    typeof shape.geometry === 'object' &&
    'type' in shape.properties &&
    'coordinates' in shape.geometry
  );
}

/**
 * Checks if two floating point numbers are equal within a certain tolerance.
 * @param num1 - The first number.
 * @param num2 - The second number.
 * @param tolerance - The tolerance within which the numbers are considered equal.
 * @returns True if the numbers are equal within the tolerance, false otherwise.
 */
export function fequal(num1: number, num2: number, tolerance = epsilon): boolean {
  return Math.abs(num1 - num2) < tolerance;
}

/**
 * Type guard for slotArray (to display the good shape in the search shape dialog)
 * @param shape - The object to be checked
 * @returns A boolean indicating whether the object is a SlotArray
 */
export function isSlotArray(shape: unknown): shape is SlotArray {
  return !!shape && typeof shape === 'object' && 'slots' in shape;
}

/**
 * Type guard for stockZone
 * @param shape - The object to be checked
 * @returns A boolean indicating whether the object is a StockZone
 */
export function isStockZone(shape: unknown): shape is CircuitStockZone {
  return (
    !!shape &&
    typeof shape === 'object' &&
    'properties' in shape &&
    !!shape.properties &&
    typeof shape.properties === 'object' &&
    'slots' in shape.properties
  );
}

const zoomLevelToScale: { [key: string]: Scale | undefined } = {
  xxs: 2,
  xs: 4,
  s: 4,
  m: 16,
  l: 32,
  xl: 64,
  xxl: 128,
} as const;

/**
 * Function to find the scale after a zoom action.
 * @param currentZoomLevelClass - the current zoom level
 * @returns The new scale value
 */
export function zoomToScale(currentZoomLevelClass: string): Scale | undefined {
  return zoomLevelToScale[currentZoomLevelClass];
}

/**
 * Function to find the correct coefficient to pre-load tiles.
 * @param scale - the current scale level
 * @returns The coefficient
 */
export function findCoefficientGridMargin(scale: number): number | undefined {
  const scaleToValue: { [key: number]: number | undefined } = {
    2: 1.5,
    4: 2,
    8: 2.5,
    16: 3,
    32: 3.5,
    64: 4,
    128: 4,
  } as const;

  return scaleToValue[scale];
}
