import { Box } from '@mui/material';
import { theme } from 'utils/mui-theme';
import { ListOfTasksMenu } from './listOfTasksMenu';

export const DisplayListOfTasks = (): JSX.Element => {
  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        left: theme.spacing(1),
        bottom: 0,
        pointerEvents: 'none',
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
        }),
      }}
    >
      <Box
        component="div"
        sx={{ position: 'relative', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'flex-end' }}
      >
        <ListOfTasksMenu />
      </Box>
    </Box>
  );
};
