import type { SelectedShapeData } from 'reducers/local/state';
import { CircuitService } from 'services/circuit.service';

/**
 * Function to get all the shape present in a selected layer
 * @param layerId - The id of the selected layer
 * @returns An array with all the id and type of the shapes present in the selected layer
 */
export function getShapes(layerId: string): SelectedShapeData[] {
  return CircuitService.getShapes(layerId).map((shape) => ({
    id: shape.id ? shape.id.toString() : '',
    type: shape.properties.type,
  }));
}
