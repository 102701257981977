import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ConveyorIconSvg } from 'assets/conveyor-icon.svg';
import { ReactComponent as PalletIconSvg } from 'assets/pallet.svg';
import { ReactComponent as RackIconSvg } from 'assets/rack-icon.svg';
import { ReactComponent as StackZoneIconSvg } from 'assets/stack-zone-icon.svg';
import { ReactComponent as StockZoneIconSvg } from 'assets/stock-zone-icon.svg';

const sxColorProperlyBlackAndWhite = {
  '& path[fill="#000000"]': {
    fill: 'currentColor',
  },
  '& path[fill="#ffffff"]': {
    fill: 'white',
  },
  fill: 'none',
};

export function ConveyorIcon(props: SvgIconProps & { colorBlackOnly?: boolean }): JSX.Element {
  /**
   * The following piece of code allow us to color from the specified color only the black parts of the svg, and not the white one
   */
  let props2 = props;
  if (props.colorBlackOnly) {
    props2 = { ...props };
    delete props2.colorBlackOnly;
    props2.sx = {
      ...props2.sx,
      ...sxColorProperlyBlackAndWhite,
    };
  }

  return <SvgIcon component={ConveyorIconSvg} viewBox="0 0 576 576" {...props2} />;
}

export function StockZoneIcon(props: SvgIconProps & { colorBlackOnly?: boolean }): JSX.Element {
  /**
   * The following piece of code allow us to color from the specified color only the black parts of the svg, and not the white one
   */
  let props2 = props;
  if (props.colorBlackOnly) {
    props2 = { ...props };
    delete props2.colorBlackOnly;
    props2.sx = {
      ...props2.sx,
      ...sxColorProperlyBlackAndWhite,
    };
  }

  return <SvgIcon component={StockZoneIconSvg} viewBox="0 0 576 576" {...props2} />;
}

export function StackZoneIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={StackZoneIconSvg} viewBox="0 0 576 576" {...props} />;
}

export function RackIcon(props: SvgIconProps & { colorBlackOnly?: boolean }): JSX.Element {
  /**
   * The following piece of code allow us to color from the specified color only the black parts of the svg, and not the white one
   */
  let props2 = props;
  if (props.colorBlackOnly) {
    props2 = { ...props };
    delete props2.colorBlackOnly;
    props2.sx = {
      ...props2.sx,
      ...sxColorProperlyBlackAndWhite,
    };
  }

  return <SvgIcon component={RackIconSvg} viewBox="0 0 576 576" {...props2} />;
}

export function PalletIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={PalletIconSvg} viewBox="0 0 512 366" {...props} />;
}
