import { FormControl, FormControlLabel, FormHelperText, FormLabel, Switch } from '@mui/material';
import { setLibCirMode } from 'editor/editor';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

const REACT_APP_FEATURE_FLAG_LIBCIR = process.env.REACT_APP_FEATURE_FLAG_LIBCIR === 'true';

export function Experiments(): JSX.Element {
  const dispatch = useAppDispatch();

  const libCirEnabled = useAppSelector((state) => state.editor.enableLibCir);

  const handleChangeLibCir = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        delete localStorage.drawnBackgroundLidar;
      }

      dispatch(
        setLibCirMode({
          enableLibCir: checked,
        })
      );
    },
    [dispatch]
  );

  if (!REACT_APP_FEATURE_FLAG_LIBCIR) return <></>;

  return (
    <FormControl hiddenLabel={true} size="small" fullWidth={true} sx={{ marginTop: theme.spacing(1) }}>
      <FormLabel>Experiments</FormLabel>

      {REACT_APP_FEATURE_FLAG_LIBCIR && (
        <>
          <FormControlLabel
            labelPlacement="start"
            control={<Switch checked={libCirEnabled} onChange={handleChangeLibCir} name="Render 2 (ALPHA)" />}
            label="Render 2 (ALPHA)"
            sx={{
              flexDirection: 'row',
              marginTop: theme.spacing(1),
            }}
          ></FormControlLabel>

          <FormHelperText>
            Render 2 is a rewrite of our rendering engine with a 3D engine. It offers better performance but is still in
            beta. Consider using it for large projects but it might lack a few features and might have some bugs.
          </FormHelperText>
        </>
      )}
    </FormControl>
  );
}
