import { findShapeOrientation } from 'drawings/helpers';
import store from 'store';
import { computeLoadsPosition } from 'utils/circuit/racks-compute-load-position';

interface SlotCoordinates {
  /** x coordinate of the slot [cm] */
  x: number;
  /** y coordinate of the slot [cm] */
  y: number;
  /** angle of the slot [deg] */
  angle: number;
  /** position (x) in the rack frame of reference [m] */
  pos?: number;
}

export function getSlotCoordinates(slotId: string): SlotCoordinates | undefined {
  const circuitState = store.getState().circuit.present;

  const points = circuitState.points.entities;

  const segments = circuitState.segments.entities;
  const segmentsIds = circuitState.segments.ids;

  const racks = circuitState.racks.entities;
  const racksIds = circuitState.racks.ids;

  const cellTemplates = circuitState.cellTemplates.entities;

  /** First case, the slot may be a point */
  const point = points[slotId];
  if (point) {
    return {
      x: point.geometry.coordinates[0] / 100,
      y: point.geometry.coordinates[1] / 100,
      angle: point.properties.orientation,
    };
  }

  /** Second case, the slot may be a stock line connected to a segment */
  for (let i = 0; i < segmentsIds.length; i++) {
    const segment = segments[segmentsIds[i]];

    const stockLine = segment.properties.stockLine;
    if (stockLine === slotId) {
      const x = segment.geometry.coordinates[0][0] / 100;
      const y = segment.geometry.coordinates[0][1] / 100;
      const angle = findShapeOrientation(segment.geometry.coordinates[0], segment.geometry.coordinates[1]);

      return {
        x,
        y,
        angle,
      };
    }
  }

  const columns = racksIds.map((rackId) => racks[rackId].properties.columns).flat();

  /** Third case, the slot may be a rack slot connected to a segment */
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];

    for (let j = 0; j < column.cells.length; j++) {
      const cell = column.cells[j];
      const cellTemplate = cell.cellTemplate ? cellTemplates[cell.cellTemplate] : undefined;

      if (!cellTemplate) continue; // we do not consider cells without cell template

      const names = cell.names;
      const loads = cellTemplate.loads;

      for (let k = 0; k < names.length; k++) {
        const slots = names[k];

        for (let ii = 0; ii < slots.length; ii++) {
          const slot = slots[ii];

          if (slot.id === slotId) {
            if (slot.disabled) continue; // we do not consider disabled slots

            const load = loads[k];
            const positions = computeLoadsPosition(load, column.width).map((x) => column.x + x + load.W / 2);

            const pos = positions[ii];
            const extendedLengthIdAnxPos = column.extendedLengthSegments.find(([id, x]) => x === pos);

            if (!extendedLengthIdAnxPos) return undefined;

            const [extendedLengthId] = extendedLengthIdAnxPos;

            const segment = segments[extendedLengthId];

            const x1 = segment.geometry.coordinates[0][0] / 100;
            const y1 = segment.geometry.coordinates[0][1] / 100;
            const angle = findShapeOrientation(segment.geometry.coordinates[0], segment.geometry.coordinates[1]);

            return {
              x: x1,
              y: y1,
              angle,
              pos,
            };
          }
        }
      }
    }
  }
}
