import type { Action } from 'redux';

export function getCircuitStandardModeInitialState(): boolean {
  return true;
}

enum StandardModeCircuitActionsTypes {
  ChangeMode = '[Local] Change Mode',
}

interface ChangeCircuitStandardModeAction extends Action {
  type: StandardModeCircuitActionsTypes.ChangeMode;
  payload: {
    newMode: boolean;
  };
}
export function ChangeCircuitStandardMode(
  payload: ChangeCircuitStandardModeAction['payload']
): ChangeCircuitStandardModeAction {
  return { type: StandardModeCircuitActionsTypes.ChangeMode, payload };
}

type StandardModeCircuitActions = ChangeCircuitStandardModeAction;

export function circuitStandardModeReducer(
  state = getCircuitStandardModeInitialState(),
  action: StandardModeCircuitActions
): boolean {
  switch (action.type) {
    case StandardModeCircuitActionsTypes.ChangeMode: {
      return action.payload.newMode;
    }
  }

  return state;
}
