import styled from '@emotion/styled';
import { Warning, type SvgIconComponent } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import PreviewIcon from '@mui/icons-material/Preview';
import type { SxProps, Theme, ToggleButtonProps, TooltipProps } from '@mui/material';
import { ToggleButton, Tooltip, tooltipClasses } from '@mui/material';
import { theme } from 'utils/mui-theme';

const HelpIconTextfield = styled(HelpIcon)(({ sx }) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  color: (sx as any)?.color ?? 'rgba(0, 0, 0, 0.54)',
  verticalAlign: 'middle',
  marginLeft: theme.spacing(1),
  cursor: 'help',
}));

const WarningIconTextfield = styled(Warning)(({ sx }) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  color: (sx as any)?.color ?? theme.palette.warning.main,
  verticalAlign: 'middle',
  marginLeft: theme.spacing(1),
  cursor: 'help',
}));

interface HelpIconTooltipProps {
  title:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal;
  sx?: SxProps<Theme>;
  tooltipSx?: SxProps<Theme>;
  Icon?: typeof HelpIconTextfield;
}
export function HelpIconTooltip({ title, sx, Icon = HelpIconTextfield }: HelpIconTooltipProps): JSX.Element {
  return (
    <Tooltip title={title} placement="top" arrow>
      <Icon fontSize="small" sx={sx} />
    </Tooltip>
  );
}

export function WarningIconTooltip(props: HelpIconTooltipProps): JSX.Element {
  return <HelpIconTooltip {...props} Icon={WarningIconTextfield} />;
}

type IconTooltipProps = TooltipPropsWithoutChildren & { icon: SvgIconComponent };
export function IconTooltip(props: IconTooltipProps): JSX.Element {
  return (
    <Tooltip {...props} title={props.title} placement="top" arrow>
      <props.icon
        fontSize="small"
        sx={{ color: 'rgba(0, 0, 0, 0.54)', verticalAlign: 'middle', marginLeft: theme.spacing(1), cursor: 'help' }}
      />
    </Tooltip>
  );
}

export function PreviewHelper(): JSX.Element {
  return <IconTooltip icon={PreviewIcon} title="Only for preview, no actual effect." />;
}

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

type TooltipPropsWithoutChildren = Omit<TooltipProps, 'children'>;
export const CustomWidthHelpIconTooltip = styled(({ className, title, ...props }: TooltipPropsWithoutChildren) => (
  <Tooltip title={title} placement="top" arrow classes={{ popper: className }} {...props}>
    <HelpIconTextfield fontSize="small" />
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export function ToggleButtonWithTooltip(propsExtended: ToggleButtonProps & { title: string }): JSX.Element {
  const { title, ...props } = propsExtended;

  return (
    <Tooltip title={title}>
      <ToggleButton {...props}>{props.children}</ToggleButton>
    </Tooltip>
  );
}
