import { Alert } from '@mui/material';
import { useMemo } from 'react';
import store from 'store';
import { isShapeAvailableToRobot } from 'utils/circuit/is-shape-exported-in-layer-group';
import { theme } from 'utils/mui-theme';
import { getRobotsEmulation } from './use-set-robots';

export function CheckMissingTaxiPoints(): JSX.Element {
  const robots = useMemo(() => getRobotsEmulation(), []);

  const robotsWithTaxi = useMemo(() => {
    const pointsState = store.getState().circuit.present.points;
    const pointsIds = pointsState.ids;
    const points = pointsState.entities;

    return robots.map((robot) => {
      if (!robot.emulationEnabled) {
        return {
          ...robot,
          hasTaxi: null,
          hasTaxiLayerGroup: null,
        };
      }

      const taxiName = `taxi${robot.ID}`;

      const taxiPointId = pointsIds.find((pointId) => {
        const point = points[pointId];

        return point.properties.name === taxiName;
      });
      const taxiPoint = taxiPointId ? points[taxiPointId] : undefined;

      const hasTaxiInTheLayerGroup = taxiPoint ? isShapeAvailableToRobot(taxiPoint, robot.serial) : false;

      return {
        ...robot,
        hasTaxi: !!taxiPoint,
        hasTaxiLayerGroup: hasTaxiInTheLayerGroup,
      };
    });
  }, [robots]);

  const errorTaxiPointsMissing = useMemo(() => {
    return robotsWithTaxi.some((robot) => (!robot.hasTaxi || !robot.hasTaxiLayerGroup) && robot.emulationEnabled);
  }, [robotsWithTaxi]);

  return errorTaxiPointsMissing ? (
    <Alert
      severity="warning"
      sx={{
        marginTop: theme.spacing(1),
        marginBottom: 0,
      }}
    >
      The following robot{robotsWithTaxi.length > 1 ? 's' : ''} are missing taxi points and will not be initialized in
      the simulation.
      <ul>
        {robotsWithTaxi.map((robot) => {
          return !robot.hasTaxi || !robot.hasTaxiLayerGroup ? (
            <li key={robot.ID}>
              {robot.name} (taxi{robot.ID}){' '}
              {!robot.hasTaxiLayerGroup ? '(exists but not in the correct layer group)' : ''}
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
    </Alert>
  ) : (
    <></>
  );
}
