import { ContentCopy } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { closeDialogAction } from 'actions';
import { connectRoom, disconnectRoom } from 'multiplayer/multiplayer';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';

export default function ShareDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const multiplayer = useAppSelector((state) => state.multiplayer.multiplayer);
  const synced = useAppSelector((state) => state.multiplayer.synced);

  const createRoomPermission = useAsyncMemo(async () => {
    return await checkPermission('create:room');
  }, []);

  const getInitialRoomId = useMemo(() => window.location.pathname.split('/')?.[2] || nanoid(), []);
  const [roomId, setRoomId] = useState(getInitialRoomId);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleShareProject = useCallback(
    (): void => {
      if (multiplayer) {
        dispatch(disconnectRoom());
        setRoomId(nanoid());
        window.history.replaceState({}, '', '/editor');
        SnackbarUtils.toast('Disconnected from multiplayer room', { variant: 'error', autoHideDuration: 2000 });

        return;
      }

      SnackbarUtils.toast('Created multiplayer room', { variant: 'success', autoHideDuration: 2000 });
      dispatch(connectRoom({ roomId: roomId }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roomId, multiplayer]
  );

  const handleCopyToClipboard = useCallback((): void => {
    try {
      navigator.clipboard.writeText(window.location.href);
      SnackbarUtils.toast('Copied to clipboard', { variant: 'success', autoHideDuration: 1000 });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      SnackbarUtils.toast('Failed to copy to clipboard', { variant: 'error', autoHideDuration: 1000 });
    }
  }, []);

  useEffect(() => {
    if (!synced) return;

    window.history.replaceState({}, '', `${window.location.origin}/editor/${roomId}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [synced]);

  const createRoomDisabled = !createRoomPermission && !multiplayer;

  return (
    <Dialog
      open={true}
      fullWidth={true}
      aria-labelledby="share-dialog-title"
      onClose={handleClose}
      PaperProps={{ sx: { overflowY: 'unset' } }}
    >
      <DialogTitle
        id="share-dialog-title"
        sx={{
          fontStyle: 'bolder',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Share
        <Tooltip title="Create a room and share the link with others to edit together the project in real time">
          <InfoIcon sx={{ marginLeft: 1 }} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Box component="div" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography>Multiplayer room</Typography>
            {
              <Tooltip title={createRoomDisabled ? `You do not have the authorization to create a room` : ''}>
                <Box component="div">
                  <Button
                    variant="outlined"
                    onClick={handleShareProject}
                    color={multiplayer ? 'error' : 'primary'}
                    disabled={createRoomDisabled}
                  >
                    {multiplayer ? 'Disconnect' : 'Create'}
                  </Button>
                </Box>
              </Tooltip>
            }
          </Box>
          {multiplayer && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextField
                name="Project URL"
                label="Project URL"
                value={synced ? `${window.location.origin}/editor/${roomId}` : ' '}
                variant="filled"
                fullWidth
                disabled
              />
              {!synced ? (
                <Tooltip title="Please wait for the project to finish uploading">
                  <CircularProgress />
                </Tooltip>
              ) : (
                <IconButton aria-label="copy" onClick={handleCopyToClipboard} disabled={!multiplayer}>
                  <ContentCopy />
                </IconButton>
              )}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <Alert severity="info" variant="filled" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        This is an experimental feature in <strong>BETA</strong>
      </Alert>
    </Dialog>
  );
}
