/**
 * Check if a value is in an array
 * @link https://stackoverflow.com/a/74213179/1090255
 * @param values the array to check
 * @param x the value to check
 * @returns whether the value is in the array
 */
export function isInArray<T>(values: readonly T[], x: any): x is T {
  return values.includes(x);
}
