import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import RouterIcon from '@mui/icons-material/Router';
import type { FlagComponent } from 'country-flag-icons/react/3x2';
import { CH, CZ, DE, ES, FR, GB, HU, IT, NL, NO, PL, PT, SV, US } from 'country-flag-icons/react/3x2';

export const delayStopEditBlur = 500; // ms

export type CommunicationMode = 'none' | 'wifi' | 'bridge';
export interface AvailableCommunicationMode {
  value: CommunicationMode;
  label: string;
  icon: JSX.Element;
}
export const availableCommunicationModes: AvailableCommunicationMode[] = [
  {
    value: 'none',
    label: 'Standalone',
    icon: <AirplanemodeActiveIcon />,
  },
  {
    value: 'wifi',
    label: 'Wi-Fi',
    icon: <NetworkWifiIcon />,
  },
  {
    value: 'bridge',
    label: 'Wi-Fi Bridge',
    icon: <RouterIcon />,
  },
];

export type LanguageCode =
  | 'FR'
  | 'EN'
  | 'US'
  | 'IT'
  | 'DE'
  | 'ES'
  | 'CH'
  | 'PT'
  | 'PL'
  | 'SV'
  | 'CZ'
  | 'HU'
  | 'NL'
  | 'NO';
export interface Language {
  code: LanguageCode;
  label: string;
  icon?: FlagComponent;
}
export const availableLanguages: Language[] = [
  {
    code: 'FR',
    label: 'Français',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: FR,
  },
  {
    code: 'EN',
    label: 'English (UK)',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: GB,
  },
  {
    code: 'US',
    label: 'English (US)',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: US,
  },
  {
    code: 'IT',
    label: 'Italiano',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: IT,
  },
  {
    code: 'DE',
    label: 'Deutsch',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: DE,
  },
  {
    code: 'ES',
    label: 'Español',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: ES,
  },
  {
    code: 'CH',
    label: '中文',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: CH,
  },
  {
    code: 'PT',
    label: 'Português',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: PT,
  },
  {
    code: 'PL',
    label: 'Polski',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: PL,
  },
  {
    code: 'SV',
    label: 'Svenska',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: SV,
  },
  {
    code: 'CZ',
    label: 'Čeština',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: CZ,
  },
  {
    code: 'HU',
    label: 'Magyar',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: HU,
  },
  {
    code: 'NL',
    label: 'Nederlands',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: NL,
  },
  {
    code: 'NO',
    label: 'Norsk',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: NO,
  },
];
