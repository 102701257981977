export type TaskState =
  | 'error'
  | 'canceled'
  | 'unlocked'
  | 'prelock'
  | 'locked'
  | 'waiting'
  | 'running'
  | 'done'
  | 'sending'
  | 'cancelling'
  | 'reloading'
  | 'beforestart'
  | 'prerunning'
  | 'unknown';

export function taskStateNumberToStr(taskState: number): TaskState {
  switch (taskState) {
    case -3:
      return 'error';
    case -2:
      return 'canceled';
    case -12:
      return 'unlocked';
    case -11:
      return 'prelock';
    case -1:
      return 'locked';
    case 0:
      return 'waiting';
    case 1:
      return 'running';
    case 2:
      return 'done';
    case 3:
      return 'sending';
    case 4:
      return 'cancelling';
    case 5:
      return 'reloading';
    case 14:
      return 'beforestart';
    case 15:
      return 'prerunning';
    case 99:
      return 'unknown';
    default:
      return 'unknown';
  }
}

export function taskStrToNumber(taskState: TaskState): number {
  switch (taskState) {
    case 'error':
      return -3;
    case 'canceled':
      return -2;
    case 'unlocked':
      return -12;
    case 'prelock':
      return -11;
    case 'locked':
      return -1;
    case 'waiting':
      return 0;
    case 'running':
      return 1;
    case 'done':
      return 2;
    case 'sending':
      return 3;
    case 'cancelling':
      return 4;
    case 'reloading':
      return 5;
    case 'beforestart':
      return 14;
    case 'prerunning':
      return 15;
    case 'unknown':
      return 99;
    default:
      return 99;
  }
}

export interface SimulationErrorResponse {
  errorID: number;
  error: string;
}
