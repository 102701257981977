import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    verticallyCentered: {
      alignItems: 'center',
    },
    fullWidth: {
      width: '100%',
      overflowX: 'hidden',
    },
  })
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FullPageLayout({ children, verticallyCentered = false }): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.verticallyCentered]: verticallyCentered,
      })}
    >
      <div className={classes.fullWidth}>{children}</div>
    </div>
  );
}
