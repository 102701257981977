import type { Dictionary } from '@reduxjs/toolkit';
import {
  DOMImplementation as DOMImplementationXmlDom,
  DOMParser as DOMParserXmlDom,
  XMLSerializer as XMLSerializerXmlDom,
} from '@xmldom/xmldom';
import * as Comlink from 'comlink';
import { findShapeOrientation, pDistance } from 'drawings/helpers';
import type { Position } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import {
  trafficTypeMap,
  type CircuitMeasurer,
  type CircuitPoint,
  type CircuitPortion,
  type CircuitRack,
  type CircuitSegment,
  type CircuitShape,
  type CircuitStockZone,
  type CircuitTurn,
  type CircuitZone,
  type PerceptionReference,
  type TrafficType,
} from 'models/circuit';
import { UNIT_COUNT_PER_METER } from 'models/drawings';
import { nanoid } from 'nanoid';
import type {
  LayerGroupData,
  LayerGroupDataObject,
  LayersDataObject,
  LoadedCellTemplate,
} from 'reducers/circuit/state';
import ErrorCodec from 'serialize-error';
import { SnackbarUtils } from 'services/snackbar.service';
import { coordToCircuit } from 'utils/circuit/coord-to-circuit';
import { getDefaultBeamUpMarginObstacle, getDefaultLoad, getEuroPallet } from 'utils/circuit/default-circuit-shapes';
import { getAllIdsFromShapes } from 'utils/circuit/get-all-ids-from-shapes';
import { computeRackColumnHeight, computeRackLength, computeUprightsGeometry } from 'utils/circuit/racks';
import { computeLoadsPosition } from 'utils/circuit/racks-compute-load-position';
import { epsilon } from 'utils/circuit/utils';
import { text2sha1 } from 'utils/crypto';
import { humanize, toRad } from 'utils/helpers';
import { makePolygonClockwise } from 'utils/polygon';
import { isDefined } from 'utils/ts/is-defined';
import packageJson from '../../../package.json';
import type { LayerGroupModelPreferences } from './layer-group-model-preferences.model';
import { prettifyXml } from './prettify-xml';

/**
 * Serialize the custom errors
 * https://stackoverflow.com/questions/65245540/how-to-throw-catch-custom-error-with-comlink-typescript
 */
interface ThrownValue {
  value: unknown;
}
type SerializedThrownValue = { isError: true; value: ErrorCodec.ErrorObject } | { isError: false; value: unknown };

const throwTransferHandler_: Comlink.TransferHandler<unknown, unknown> | undefined =
  Comlink.transferHandlers.get('throw');

const throwTransferHandler = throwTransferHandler_ as Comlink.TransferHandler<ThrownValue, SerializedThrownValue>;

const throwTransferHandlerCustom: Comlink.TransferHandler<ThrownValue, SerializedThrownValue> = {
  canHandle: throwTransferHandler.canHandle,
  serialize: ({ value }) => {
    let serialized: SerializedThrownValue;
    if (value instanceof Error) {
      serialized = {
        isError: true,
        value: ErrorCodec.serializeError(value),
      };
    } else {
      serialized = { isError: false, value };
    }

    return [serialized, []];
  },
  deserialize: (serialized) => {
    if (serialized.isError && ErrorCodec) {
      const error = ErrorCodec?.deserializeError?.(serialized?.value);
      throw error;
    }

    throw serialized.value;
  },
};

Comlink.transferHandlers.set('throw', throwTransferHandlerCustom);

export class MissingCellTemplateError extends Error {
  public rackId?: string;
  public columnId?: string;
  public cellId?: string;

  constructor(message: string, rackId?: string, columnId?: string, cellId?: string) {
    super(message);
    this.name = 'MissingCellTemplateError';

    this.rackId = rackId;
    this.columnId = columnId;
    this.cellId = cellId;
  }
}

export function isMissingCellTemplateError(error: unknown): error is MissingCellTemplateError {
  return error instanceof Error && error.name === 'MissingCellTemplateError';
}

export interface GenerateXMLWorkerAdditionalData {
  segmentsIdsInTheLayerGroupCommon?: string[];
  pointsIdsInTheLayerGroupCommon?: string[];
  turnsIdsInTheLayerGroupCommon?: string[];

  stocksInOtherLayerGroupThanCommon?: string[];
  racksInOtherLayerGroupThanCommon?: string[];

  turnsNotExportedInAtLeastOneLayerGroup?: string[];
}

/**
 * Generates a Kiwi/Circuit Editor compatible XML circuit file
 * @param zones The zones to include in the XML string
 * @param points The points to include in the XML string
 * @param segments The segments to include in the XML string
 * @param measurers The measurers to include in the XML string
 * @param turns The turns to include in the XML string
 * @param racks The racks to include in the XML string
 * @param pretty Minify the XML if false (P.-S. it looks like Circuit Editor cannot open a minified XML)
 * @returns an array that contains: [0] the XML string, [1] a javascript object with the map of all the ids (geojson ids -> xml ids)
 */
export async function generateXMLWorker({
  zones,
  points,
  segments,
  stockZones,
  measurers,
  turns,
  racks,
  pretty = true,

  otherOptions,

  layerGroups,
  editorLayers,
  projectVersion,
  projectDescription,
  cellTemplates,
  preferencesModel,

  exportMultiplePointType = false,

  inWorker = true,
}: {
  zones?: CircuitZone[];
  points?: CircuitPoint[];
  segments?: CircuitSegment[];
  stockZones?: CircuitStockZone[];
  measurers?: CircuitMeasurer[];
  turns?: CircuitTurn[];
  racks?: CircuitRack[];
  pretty?: boolean;

  layerGroups?: LayerGroupDataObject;
  editorLayers?: LayersDataObject;
  cellTemplates?: Dictionary<LoadedCellTemplate>;

  projectVersion?: string;
  projectDescription?: string;

  preferencesModel?: Record<string, LayerGroupModelPreferences>;

  otherOptions?: {
    defaultMaxOvershoot?: string;
  };

  exportMultiplePointType?: boolean;

  inWorker?: boolean;
}): Promise<[string, Record<string, number>, GenerateXMLWorkerAdditionalData?]> {
  if (!zones) zones = [];
  if (!points) points = [];
  if (!segments) segments = [];
  if (!stockZones) stockZones = [];
  if (!measurers) measurers = [];
  if (!turns) turns = [];
  if (!racks) racks = [];

  if (!layerGroups) layerGroups = {};
  if (!editorLayers) editorLayers = {};
  if (!cellTemplates) cellTemplates = {};
  if (!preferencesModel) preferencesModel = {};

  if (!projectVersion) projectVersion = 'undefined project version';
  if (!projectDescription) projectDescription = 'undefined project description';

  const domParser = new DOMParserXmlDom();

  const defaultMaxOvershoot = otherOptions?.defaultMaxOvershoot ?? '10';
  const additionalData: GenerateXMLWorkerAdditionalData = {
    segmentsIdsInTheLayerGroupCommon: [],
    pointsIdsInTheLayerGroupCommon: [],
    turnsIdsInTheLayerGroupCommon: [],
    stocksInOtherLayerGroupThanCommon: [],
    racksInOtherLayerGroupThanCommon: [],
    turnsNotExportedInAtLeastOneLayerGroup: [],
  };

  // we need to check if all the cells have a cellTemplate assigned
  // because we cannot generate a valid XML without it
  (racks ?? []).forEach((rack) => {
    rack.properties.columns.forEach((column) => {
      column.cells.forEach((cell) => {
        if (!cell.cellTemplate && !cell.disabled) {
          throw new MissingCellTemplateError(
            `Cannot generate a valid XML without a cellTemplate, rack id: ${rack.id}, column id: ${column.id}, cell id: ${cell.id}`,
            rack.id as string,
            column.id,
            cell.id
          );
        }
      });
    });
  });

  const shapes = [
    ...(zones ?? []),
    ...(points ?? []),
    ...(segments ?? []),
    ...(stockZones ?? []),
    ...(measurers ?? []),
    ...(turns ?? []),
    ...(racks ?? []),
  ];
  const allShapesIds = getAllIdsFromShapes(shapes);

  const maxIdShape = allShapesIds.reduce((acc, id) => {
    return Math.max(acc, +(id || 0));
  }, 0);
  let currentId = maxIdShape;
  const mapIds: Record<string, number> = {};

  const unitCountPerMeter = UNIT_COUNT_PER_METER;

  mapIds[currentId] = currentId;
  mapIds[currentId + 1] = currentId + 1;
  const layers: LayerInterface[] = [];

  Object.values(layerGroups).forEach((layerGroup) => {
    layers.push({
      id: currentId++,
      modelName: layerGroup.name,
      crossRoads: [],
      portions: [],
      portionsCE: [],
      destinationPoints: [],
      zones: [],
      measurers: [],
      stockZones: [],
      racks: [],
    });
  });
  const layersNumber: { [key: string]: number } = {};
  layers.forEach((layer, i) => {
    layersNumber[layer.modelName] = i;
  });

  const commonLayers = layers.filter((layer) => layer.modelName === 'Common');
  if (!commonLayers.length) {
    try {
      SnackbarUtils.warning(`A layer group named 'Common' is required to properly work with Kiwi`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  const commonLayer = getLayerGroupCommon(layers, undefined, undefined, layerGroups);

  const allExportedTurnIds = new Set<string>();

  // every turn is a portion as well as a two crossroads
  turns?.forEach((turn) => {
    if (!turn.id || !turn.properties.originId || !turn.properties.destinationId) return;

    if (!editorLayers) editorLayers = {};
    if (!layerGroups) layerGroups = {};

    // we can export the staight turns, it does not make circuit editor crash :)
    // if (turn.properties.drivenBy === 'StraightLine') return;
    const turnId = mapIds[turn.id] || (mapIds[turn.id] = +turn.id);
    const layerGroupsParent = getLayerGroupsParents(layers, editorLayers, turn, layerGroups);

    const segmentOriginId = turn.properties.originId;
    const segmentDestinationId = turn.properties.destinationId;

    const segmentOrigin = segments?.find((s) => s.id === segmentOriginId);
    const segmentDestination = segments?.find((s) => s.id === segmentDestinationId);

    if (!segmentOrigin || !segmentDestination) {
      // eslint-disable-next-line no-console
      console.error(`Origin or destination segment not found for turn ${turn.id}`, {
        segmentOrigin,
        segmentDestination,
        turn,
      });

      return;
    }

    const originProps = segmentOrigin.properties;
    const destProps = segmentDestination.properties;

    const originLayer = editorLayers[originProps.layerId];
    const destLayer = editorLayers[destProps.layerId];

    if (originLayer?.isDraft || destLayer?.isDraft) {
      // we do not export turns if the origin or destination segment is in a draft layer
      return;
    }

    const isOriginExtendedLength = originProps.rack || originProps.rackColumn || originProps.stockLine;
    const isDestExtendedLength = destProps.rack || destProps.rackColumn || destProps.stockLine;

    const layerGroupsSegOrigin = getLayerGroupsParents(layers, editorLayers, segmentOrigin, layerGroups);
    const layerGroupsSegDestination = getLayerGroupsParents(layers, editorLayers, segmentDestination, layerGroups);

    // we want to export the turns only if the origin & destination segments are in the same layer group
    // we compute the intersection of the layer groups of the origin & destination segments & the layer groups of the turn
    // if a segment is an extended length, we want to keep it anyway because we can draw turns between extended length segments and normal segments
    const layerGroupsParentIntersect = layerGroupsParent.filter(
      (l) =>
        (isOriginExtendedLength || layerGroupsSegOrigin.includes(l)) &&
        (isDestExtendedLength || layerGroupsSegDestination.includes(l))
    );

    const layerGroupsParentExcluded = layerGroupsParent.filter((l) => !layerGroupsParentIntersect.includes(l));
    if (layerGroupsParentExcluded.length) {
      additionalData.turnsNotExportedInAtLeastOneLayerGroup?.push(turn.id as string);
    }

    // the turn will not be exported in any layer group, we skip it
    if (!layerGroupsParentIntersect.length) return;

    allExportedTurnIds.add(turn.id as string);

    // we add the portion
    const pts: Position[] = [];
    turn.geometry.coordinates.forEach((point, i) => {
      let cap = turn.properties.coordinateProperties.cap[i] || 0;
      cap = parseFloat(((cap + 360) % 360).toFixed(4));

      pts.push([coordToCircuit(point[0]), coordToCircuit(point[1]), cap] as Position);
    });

    const portionTurn: PortionInterface = {
      id: turnId,
      points: pts,
      isTurn: true,
      stopBeforeTurn: turn.properties.turnType === 'StopBeforeTurn',
      radius: turn.properties.radius,
      locked: false,
      isExtremity: false,
      isWireGuided: false,
      trafficType: turn.properties.trafficType,
    };

    if (turn.properties.maxOvershoot) portionTurn.maxOvershoot = turn.properties.maxOvershoot;

    const turnCEToAdd: PortionCE = {
      id: turnId,
      points: pts,
      locked: false,
      isTurn: true,
      stopBeforeTurn: turn.properties.turnType === 'StopBeforeTurn',
      radius: turn.properties.radius,
      maxOvershoot: turn.properties.maxOvershoot,
      roadEditorId: turn.id as string,
    };

    layerGroupsParentIntersect.forEach((layer) => {
      layer.portions.push(portionTurn);

      layer.portionsCE.push(turnCEToAdd);

      if (layer.modelName === 'Common') {
        additionalData.turnsIdsInTheLayerGroupCommon?.push(turn.id as string);
      }
    });
  });

  // every segment contains at least one portion
  segments?.forEach((segment) => {
    const segmentId = segment.id;
    if (!segmentId) throw new Error('A segment id is missing');

    const id = mapIds[segmentId] || (mapIds[segmentId] = +segmentId);
    // we need to export only the segment portion between the first and last turns connected to the segment
    // so first we retreived the connected turns
    /*
    const connectedTurns = (turns || []).filter(
      (turn) => turn.properties.originId === segment.id || turn.properties.destinationId === segment.id
    );
    // then we list the points (coordinates) of the turns connected to the segment
    let firstPosDist = 1 / 0;
    let firstPoint = segment.geometry.coordinates[0];
    let lastPosDist = 1 / 0;
    let lastPoint = segment.geometry.coordinates[1];
    const points: Position[] = [];
    connectedTurns.forEach((turn) => {
      if (!turn.properties.positionFactorOrigin || !turn.properties.positionFactorDest) return;
 
      if (turn.properties.originId === segment.id) {
        points.push(turn.geometry.coordinates[0]);
      } else if (turn.properties.destinationId === segment.id) {
        points.push(turn.geometry.coordinates[turn.geometry.coordinates.length - 1]);
      }
    });
    // and then we select the first and the last points
    points.forEach((point) => {
      const dFirst = getDistanceBetweenPoints(point, segment.geometry.coordinates[0]);
      if (dFirst < firstPosDist) {
        firstPosDist = dFirst;
        firstPoint = point;
      }
 
      const dLast = getDistanceBetweenPoints(
        point,
        segment.geometry.coordinates[segment.geometry.coordinates.length - 1]
      );
      if (dLast < lastPosDist) {
        lastPosDist = dLast;
        lastPoint = point;
      }
    });
    */
    const segmentCap =
      (findShapeOrientation(
        segment.geometry.coordinates[0],
        segment.geometry.coordinates[segment.geometry.coordinates.length - 1]
      ) +
        360) %
      360;

    if (!editorLayers) editorLayers = {};
    if (!layerGroups) layerGroups = {};

    getLayerGroupsParents(layers, editorLayers, segment, layerGroups).forEach((layer) => {
      if (!segment.id) return;

      if (
        !segment.properties.rack &&
        !segment.properties.rackColumn &&
        !segment.properties.stockLine &&
        layer.modelName === 'Common'
      ) {
        // if it's not an extended length, it's not normal
        additionalData.segmentsIdsInTheLayerGroupCommon?.push(segment.id as string);
      }

      const props = segment.properties;
      const firstPortionId = props.portions[0].id;

      const segmentCEToAdd: PortionCE = {
        id: mapIds[firstPortionId] || (mapIds[firstPortionId] = currentId++),
        points: segment.geometry.coordinates.map((pt) => [coordToCircuit(pt[0]), coordToCircuit(pt[1]), segmentCap]),
        locked: !!segment.properties.locked,
        segmentOriginId: id,
        roadEditorId: segment.id as string,
        isExtendedLength: !!props.stockLine,
        isWireGuided: !!segment.properties.wireGuided,
      };
      layer.portionsCE.push(segmentCEToAdd);

      if (!editorLayers) editorLayers = {};
      if (!layerGroups) layerGroups = {};

      segment.properties.portions.forEach((portion) => {
        // we flag when the portion is connected to only one portion
        // we may delete it later in the export (if not a stockzone or if there's no point on it)
        // we don't flag like that the portion connected to nothing
        let isExtremity = false;
        if (portion.inStart && portion.inStart.length === 1 && (!portion.outEnd || !portion.outEnd.length))
          isExtremity = true;
        if (portion.outEnd && portion.outEnd.length === 1 && (!portion.inStart || !portion.inStart.length))
          isExtremity = true;

        if (portion.stockZoneId) isExtremity = false;

        const portionToAdd: PortionInterface = {
          id: mapIds[portion.id] || (mapIds[portion.id] = +portion.id),
          points: portion.points.map((pt) => [coordToCircuit(pt[0]), coordToCircuit(pt[1]), segmentCap]),
          locked: !!segment.properties.locked,
          segmentOriginId: id,
          isExtremity,
          isWireGuided: !!segment.properties.wireGuided,
        };
        if (portion.stockZoneId)
          portionToAdd.stockZoneId =
            mapIds[portion.stockZoneId] || (mapIds[portion.stockZoneId] = +portion.stockZoneId);
        if (portion.trafficType) portionToAdd.trafficType = portion.trafficType;

        layer.portions.push(portionToAdd);

        if (
          (portion.inStart && portion.inStart.length) ||
          (portion.outStart && portion.outStart.length && portion.turnIdStart)
        ) {
          const turn = turns?.find((t) => t.id === portion.turnIdStart);
          const segmentId = segment.id;
          if (turn && turn.id && segmentId) {
            const isStart = true;

            const turnId = turn.id as string;

            const exportedTurn = allExportedTurnIds.has(turnId);
            // if the turn is not exported, we don't export the associated crossroad

            const turnCap = turn.properties.coordinateProperties.cap;
            const angleInDegree = isStart ? turnCap[0] : turnCap[turnCap.length - 1];
            mapIds[currentId] = currentId;

            const listPortionInput =
              portion.inStart && portion.inStart.length
                ? portion.inStart
                    .map((idStr) =>
                      exportedTurn || idStr !== turnId ? mapIds[idStr] || (mapIds[idStr] = +idStr) : null
                    )
                    .filter(isDefined)
                : [];
            const listPortionOutput =
              portion.outStart && portion.outStart.length
                ? portion.outStart
                    .map((idStr) =>
                      exportedTurn || idStr !== turnId ? mapIds[idStr] || (mapIds[idStr] = +idStr) : null
                    )
                    .filter(isDefined)
                : [];

            const exportCrossRoad = exportedTurn || !!(listPortionInput.length && listPortionOutput.length);

            if (exportCrossRoad) {
              const crossRoad: CrossRoadInterface = {
                id: currentId++,
                listPortionInput,
                listPortionOutput,
                angleInDegree,
                type: 'Start',
                ptInUnit: portion.points[0].map((pt) => coordToCircuit(pt)),
                segments: [mapIds[segmentId] || (mapIds[segmentId] = +segmentId)],
                curves: [mapIds[turn.id] || (mapIds[turn.id] = +turn.id)],
              };

              commonLayer.forEach((c) => {
                c.crossRoads.push(crossRoad);
              });
            }
          }
        }

        if ((portion.inEnd && portion.inEnd.length) || (portion.outEnd && portion.outEnd.length && portion.turnIdEnd)) {
          const turn = turns?.find((t) => t.id === portion.turnIdEnd);
          const segmentId = segment.id;
          if (turn && turn.id && segmentId) {
            const isStart = true;

            const turnId = turn.id as string;

            const exportedTurn = allExportedTurnIds.has(turnId);

            const listPortionInput =
              portion.inEnd && portion.inEnd.length
                ? portion.inEnd
                    .map((idStr) =>
                      exportedTurn || idStr !== turnId ? mapIds[idStr] || (mapIds[idStr] = +idStr) : null
                    )
                    .filter(isDefined)
                : [];
            const listPortionOutput =
              portion.outEnd && portion.outEnd.length
                ? portion.outEnd
                    .map((idStr) =>
                      exportedTurn || idStr !== turnId ? mapIds[idStr] || (mapIds[idStr] = +idStr) : null
                    )
                    .filter(isDefined)
                : [];

            const exportCrossRoad = exportedTurn || !!(listPortionInput.length && listPortionOutput.length);

            // if the turn is not exported, we don't export the associated crossroad
            if (exportCrossRoad) {
              const turnCap = turn.properties.coordinateProperties.cap;
              const angleInDegree = isStart ? turnCap[0] : turnCap[turnCap.length - 1];
              mapIds[currentId] = currentId;
              const crossRoad: CrossRoadInterface = {
                id: currentId++,
                listPortionInput,
                listPortionOutput,
                angleInDegree,
                type: 'End',
                ptInUnit: portion.points[1].map((pt) => coordToCircuit(pt)),
                segments: [mapIds[segmentId] || (mapIds[segmentId] = +segmentId)],
                curves: [mapIds[turn.id] || (mapIds[turn.id] = +turn.id)],
              };

              layer.crossRoads.push(crossRoad);
            }
          } else {
            // eslint-disable-next-line no-console
            console.log(`No end turn found for portion ${portion.id}`); // error -> log, i don't know if it cause a problem, to be removed if useless
          }
        }
      });
    });
  });

  zones?.forEach((zone) => {
    const zoneId = zone.id;
    if (!zoneId) throw new Error(`A zone is missing an id`);
    const id = mapIds[zoneId] || (mapIds[zoneId] = +zoneId);

    const listOfRules: string[] = [];
    const isSupervisorVisible = !!zone.properties.displayHMI;
    const maxSpeedIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'LimitSpeed');
    const doorIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'Door');
    const roofHeightIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'RoofHeight');
    const noParkingIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'NoParking');
    const noBeepIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'NoBeepInside');
    const hornEnteringIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'HornEntering');
    const hornLeavingIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'HornLeaving');
    const hornInsideIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'HornInside');
    const stopEnteringIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'StopEntering');
    const stopLeavingIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'StopLeaving');
    const blackHoleLocalisationIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'BlackHole');
    const curtainInhibitionIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'CurtainInhibitedArea');
    const batteryChargerIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'BatteryCharger');
    const specificBatteryManagementIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'NoHardSafetyInside');
    const limitRobotCountIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'LimitAgvCount');
    const safetyManualAckIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'SafetyManualAck');
    const elevatorNameIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'ElevatorName');
    const floorNameIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'FloorName');

    if (maxSpeedIndex !== -1) listOfRules.push(`(LimitSpeed;${zone.properties.rules[maxSpeedIndex][1]})`);
    if (isSupervisorVisible) listOfRules.push('(IsSupervisorVisible;)');
    if (noBeepIndex !== -1) listOfRules.push('(NoBeepInside;)');
    if (hornEnteringIndex !== -1) listOfRules.push('(HornEntering;)');
    if (hornLeavingIndex !== -1) listOfRules.push('(HornLeaving;)');
    if (hornInsideIndex !== -1) listOfRules.push('(HornInside;)');
    if (stopEnteringIndex !== -1) listOfRules.push(`(StopEntering;${zone.properties.rules[stopEnteringIndex][1]})`);
    if (stopLeavingIndex !== -1) listOfRules.push(`(StopLeaving;${zone.properties.rules[stopLeavingIndex][1]})`);
    if (noParkingIndex !== -1) listOfRules.push('(NoParking;)');
    if (roofHeightIndex !== -1)
      listOfRules.push(
        `(RoofHeight;${(Number(zone.properties.rules[roofHeightIndex][1]) ?? 0) * UNIT_COUNT_PER_METER})`
      );

    if (doorIndex !== -1) listOfRules.push('(Door;)');
    if (floorNameIndex !== -1) listOfRules.push(`(FloorName;${zone.properties.rules[floorNameIndex][1]})`);
    if (elevatorNameIndex !== -1) listOfRules.push(`(ElevatorName;${zone.properties.rules[elevatorNameIndex][1]})`);
    if (curtainInhibitionIndex !== -1)
      listOfRules.push(
        `(CurtainInhibitedArea;${Math.round(
          (Number(zone.properties.rules[curtainInhibitionIndex][1]) ?? 0) * UNIT_COUNT_PER_METER
        )})`
      );
    if (blackHoleLocalisationIndex !== -1) listOfRules.push('(BlackHole;)');
    if (specificBatteryManagementIndex !== -1) listOfRules.push('(NoHardSafetyInside;)');
    if (batteryChargerIndex !== -1) listOfRules.push('(BatteryCharger;)');
    if (limitRobotCountIndex !== -1)
      listOfRules.push(`(LimitAgvCount;${zone.properties.rules[limitRobotCountIndex][1]})`);
    if (safetyManualAckIndex !== -1)
      listOfRules.push(`(SafetyManualAck;${zone.properties.rules[safetyManualAckIndex][1]})`);

    if (!listOfRules.length) listOfRules.push('(Classic;)');

    let polygonPoints = [...zone.geometry.coordinates[0]]
      .map((coord) => coord.map((pos) => coordToCircuit(pos)))
      .slice(0, -1);
    polygonPoints = makePolygonClockwise(polygonPoints);

    const zn: ZoneInterface = {
      id,
      title: zone.properties.name || (zoneId as string),
      polygon: polygonPoints,
      projection: [
        zone.properties.intersectionType.charAt(0).toUpperCase(),
        zone.properties.intersectionType.slice(1).toLowerCase(),
      ].join(''),
      listOfRules,
      locked: !!zone.properties?.locked,
      enterCallback: zone.properties.enterEventName,
      exitCallback: zone.properties.exitEventName,
      broadcast: zone.properties.broadcast,
    };

    if (!editorLayers) editorLayers = {};
    if (!layerGroups) layerGroups = {};

    getLayerGroupsParents(layers, editorLayers, zone, layerGroups).forEach((layer) => layer.zones.push(zn));
  });

  measurers?.forEach((measurer) => {
    const measurerId = measurer.id;
    if (!measurerId) throw new Error(`A measurer is missing an id`);
    const point1Id = nanoid(10);
    const point2Id = nanoid(10);
    const angle =
      (findShapeOrientation(measurer.geometry.coordinates[0], measurer.geometry.coordinates[1]) + 360) % 360;

    const m: MeasurerInterface = {
      measurerPoints: [
        {
          id: mapIds[point1Id] || (mapIds[point1Id] = currentId++),
          ptInUnit: [
            coordToCircuit(measurer.geometry.coordinates[0][0]),
            coordToCircuit(measurer.geometry.coordinates[0][1]),
          ],
          angleInDegree: angle,
        },
        {
          id: mapIds[point2Id] || (mapIds[point2Id] = currentId++),
          ptInUnit: [
            coordToCircuit(measurer.geometry.coordinates[1][0]),
            coordToCircuit(measurer.geometry.coordinates[1][1]),
          ],
          angleInDegree: angle,
        },
      ],
      measurerData: {
        id: mapIds[measurerId] || (mapIds[measurerId] = +measurerId),
        locked: !!measurer.properties.locked,
        points: measurer.geometry.coordinates.map((coord) => {
          return [coordToCircuit(coord[0]), coordToCircuit(coord[1]), parseFloat(angle.toFixed(4))];
        }),
        isExtremity: false,
        isWireGuided: false,
      },
    };

    if (!editorLayers) editorLayers = {};
    if (!layerGroups) layerGroups = {};

    getLayerGroupsParents(layers, editorLayers, measurer, layerGroups).forEach((layer) => layer.measurers.push(m));
  });

  points?.forEach((point) => {
    const pointId = point.id;
    if (!pointId) throw new Error('A point id is missing');

    if (!editorLayers) editorLayers = {};
    if (!layerGroups) layerGroups = {};

    const layerGroupsParent = getLayerGroupsParents(layers, editorLayers, point, layerGroups);

    const pointIds = layerGroupsParent.map((_, i) => (i === 0 ? pointId ?? -1 : currentId++));
    const pointName = point.properties.name;

    const elevatorName = point.properties.elevatorName;
    const floorLevel = point.properties.floorLevel;

    const ids = pointIds.map((id) => mapIds[id] || (mapIds[id] = +id));

    const pt: DesinationPointInterface = {
      id: ids[0],
      type: getXmlPointType(point, exportMultiplePointType),
      title: pointName,
      coords: point.geometry.coordinates,
      minimalScoreInit: point.properties?.minimumInitScore || 1000,
      locked: !!point.properties.locked,
    };

    if (point.properties.segment && point.properties.segment.id && point.properties.segment.position !== undefined) {
      const segmentId = point.properties.segment.id;
      const segmentIdNb: number = mapIds[segmentId] || (mapIds[segmentId] = +segmentId);
      const portionsFromSegment: CircuitPortion[] =
        segments?.find((segment) => segment.id === segmentId)?.properties.portions || [];

      let minDist = 1 / 0;
      let portionMinDist: CircuitPortion | undefined;
      portionsFromSegment.forEach((portion) => {
        const d = pDistance(
          pt.coords[0],
          pt.coords[1],
          portion.points[0][0],
          portion.points[0][1],
          portion.points[1][0],
          portion.points[1][1]
        );
        if (d < minDist) {
          minDist = d;
          portionMinDist = portion;
        }
      });

      pt.portionDataId = portionMinDist
        ? mapIds[portionMinDist.id] || (mapIds[portionMinDist.id] = +portionMinDist.id)
        : segmentIdNb;
      pt.percent = portionMinDist
        ? getDistanceBetweenPoints(pt.coords, portionMinDist.points[0]) /
          getDistanceBetweenPoints(portionMinDist.points[0], portionMinDist.points[1])
        : point.properties.segment.position;
    }

    if (elevatorName) {
      pt.elevatorName = elevatorName;
    }

    if (floorLevel) {
      pt.floorLevel = floorLevel;
    }

    layerGroupsParent.forEach((layer, i) => {
      layer.destinationPoints.push({ ...pt, id: ids[i], title: pointName });

      if (layer.modelName === 'Common') {
        additionalData.pointsIdsInTheLayerGroupCommon?.push(point.id as string);
      }
    });
  });

  stockZones?.forEach((stockZone: CircuitStockZone) => {
    const props = stockZone.properties;
    const extendedLength = props.extendedLength;
    const palletLength = props.palletSize.length;
    const palletWidth = props.palletSize.width;

    const stockLines: StockZoneInterface['stockLines'] = [];

    if (!stockZone.id) {
      // eslint-disable-next-line no-console
      console.error(`Stockzone without id: ${JSON.stringify(stockZone)}`);

      return;
    }

    const stockZoneId = mapIds[stockZone.id] || (mapIds[stockZone.id] = +stockZone.id);

    stockZone.properties.slots.forEach((slotLine, indexSlotLine) => {
      // {
      //   id: stockLineId,
      //   segmentId: stockLineSegmentId,
      //   portionThroughId,
      //   name: `${slotLine.name}-${index}`,
      // }

      const slotLineId = `${slotLine.id}`;
      const stockLineId = slotLineId;

      const segment = (segments ?? []).find((segment) => segment.properties.stockLine === slotLine.id);
      if (!segment) {
        throw new Error(`Segment not found for stock line ${slotLine.id}`);
      }

      const portionSegmentId = segment.properties.portions[0].id;
      const stockLineSegmentId = mapIds[portionSegmentId] || (mapIds[portionSegmentId] = +portionSegmentId);

      const portionId = nanoid(10);
      const portionThroughId = mapIds[portionId] || (mapIds[portionId] = currentId++);

      const angle = toRad(stockZone.properties.cap);

      stockLines.push({
        id: parseInt(stockLineId, 10),
        name: slotLine.name,
        portionThroughId,
        segmentId: stockLineSegmentId,
        numberOfPallets: slotLine.slots.length,
        offsetY: Math.round(
          Math.abs(Math.abs(slotLine.slots[0].geometry[0][1]) - Math.abs(stockZone.geometry.coordinates[0][0][1]))
        ),
      });

      // we create a portion for the stock line, from both extremities of the slots
      const firstSlot = slotLine.slots[0].geometry;
      const slotSample = slotLine.slots[0];

      const pointsPortion = [
        [
          firstSlot[0][0] - (slotSample.slotSize.width * 100 * Math.sin(angle)) / 2,
          firstSlot[0][1] + (slotSample.slotSize.width * 100 * Math.cos(angle)) / 2,
        ],
        [segment.geometry.coordinates[0][0], -segment.geometry.coordinates[0][1]],
      ];

      if (!editorLayers) editorLayers = {};
      if (!layerGroups) layerGroups = {};

      let crossRoadIdToAddInputs: number | undefined;

      let alreadyCountedWarning = false;
      getLayerGroupsParents(layers, editorLayers, stockZone, layerGroups).forEach((layer) => {
        if (layer.modelName !== 'Common' && !alreadyCountedWarning) {
          additionalData.stocksInOtherLayerGroupThanCommon?.push(stockZone.id as string);
          alreadyCountedWarning = true;
        }

        layer.portions.push({
          id: portionThroughId,
          points: pointsPortion.map((coord) => {
            return [
              coordToCircuit(coord[0]),
              -coordToCircuit(coord[1]),
              parseFloat(((360 - stockZone.properties.cap) % 360).toFixed(4)),
            ];
          }),
          locked: true,
          segmentOriginId: null,
          isExtremity: false,
          isWireGuided: false,
        });

        layer.portionsCE.push({
          id: portionThroughId,
          points: pointsPortion.map((coord) => {
            return [
              coordToCircuit(coord[0]),
              -coordToCircuit(coord[1]),
              parseFloat(((360 - stockZone.properties.cap) % 360).toFixed(4)),
            ];
          }),
          locked: true,
          segmentOriginId: portionThroughId,
          stockline: true,
          // isExtremity: false,
        });

        const listPortionInput = [portionThroughId];

        commonLayer.forEach((c) => {
          c.crossRoads.push({
            id: currentId++,
            listPortionInput,
            listPortionOutput: [stockLineSegmentId],
            angleInDegree: stockZone.properties.cap,
            type: 'Start',
            exportComputedPoints: false,
          });
        });

        if (crossRoadIdToAddInputs !== undefined) {
          const crossRoadToAddInput = layer.crossRoads[crossRoadIdToAddInputs];
          if (crossRoadToAddInput) {
            crossRoadToAddInput.listPortionInput.push(portionThroughId);
          }
        }
      });
    });

    const stockZoneCir: StockZoneInterface = {
      id: stockZoneId,
      cap: stockZone.properties.cap,
      enableScan: stockZone.properties.enableScan,
      extendedLength: extendedLength,
      numberOfPallets: stockZone.properties.slots.reduce((acc, slotLine) => acc + slotLine.slots.length, 0),
      palletHeight: 0,
      palletLength: palletLength,
      palletWidth: palletWidth,
      spaceBetweenPallet: stockZone.properties.gap.length,
      stockLines,
      title: props.name,
      polygon: stockZone.geometry.coordinates[0]
        .map((coord) => coord.map((pos) => coordToCircuit(pos)))
        .map((coord) => [coord[0], coord[1]]),
    };

    if (!editorLayers) editorLayers = {};
    if (!layerGroups) layerGroups = {};

    getLayerGroupsParents(layers, editorLayers, stockZone, layerGroups).forEach((layer) =>
      layer.stockZones.push(stockZoneCir)
    );
  });

  racks?.forEach((rack) => {
    const rackId = rack.id;
    if (!rackId) throw new Error('A rack id is missing');
    const rackIdCir = mapIds[rackId] || (mapIds[rackId] = +rackId);

    const props = rack.properties;
    const columns = props.columns;
    const uprights = props.uprights;

    const rackWidth = computeRackLength(columns, uprights);
    const rackDepth = props.depth;
    const uprightsGeometry = computeUprightsGeometry(rack);

    const extendedLengthSegments = columns.flatMap((column) => column.extendedLengthSegments); //.sort((a, b) => a[1] - b[1]);

    const angleInDegree = (props.cap - 90 + 360) % 360;
    const coords = rack.geometry.coordinates[0];

    const rackCir: RackCEInterface = {
      id: rackIdCir,
      angleInDegree,
      locked: !!rack.properties.locked,
      flowDirection: 'LeftToRight',
      title: props.name,
      rackDepth: props.depth,
      // polygon: rack.geometry.coordinates[0].slice(0, -1).map((coord) => coord.map((pos) => coordToCircuit(pos))),
      // polygon: [coords[3], coords[0], coords[1], coords[2], coords[3]].map((coord) => coord.map((pos) => coordToCircuit(pos))) ,
      polygon: [coords[1], coords[2], coords[3], coords[0]].map((coord) => coord.map((pos) => coordToCircuit(pos))),
      exceededLenght: columns[0].extendedLength,
      listOfAccessInfo: columns.flatMap((column) =>
        column.extendedLengthSegments.map((seg) => {
          const columnId = mapIds[column.id] || (mapIds[column.id] = currentId++);
          const portionThroughId = `${seg[0]}-through`;
          const extendedLengthId = mapIds[seg[0]] || (mapIds[seg[0]] = currentId++);
          const segThroughId = mapIds[portionThroughId] || (mapIds[portionThroughId] = currentId++);
          const x = seg[1];

          return [columnId, extendedLengthId, segThroughId, x] as [number, number, number, number];
        })
      ),
      listOfRackData: columns.reduce(
        (acc, column, columnIndex) => {
          // const upright = uprights[index];
          const uprightId = `${column.id}-upright_${columnIndex}`;
          const uprightGeometry = uprightsGeometry[columnIndex];
          const columnHeight = computeRackColumnHeight(column);

          const posInX = uprightGeometry.posInX / 100;

          // front rect for an upright
          const frontRect = {
            left: posInX,
            top: columnHeight,
            right: posInX + (uprightGeometry.enabled ? uprightGeometry.width : 0),
            bottom: 0,
          };

          const uprightCE: RackCEInterface['listOfRackData'][0] = {
            id: mapIds[uprightId] || (mapIds[uprightId] = currentId++),
            type: 'RackUprightExportData',
            polygon: uprightGeometry.polygon.map((point) => point.map((pos) => coordToCircuit(pos))),
            posInX,
            width: uprightGeometry.width,
            rackWidth,
            rackDepth,
            columnHeight,
            frontRect,
          };
          let cellStartHeight = column.startHeight;
          const columnCE: RackCEInterface['listOfRackData'][0] = {
            id: mapIds[column.id] || (mapIds[column.id] = +column.id),
            type: 'RackColumnExportData',
            polygon: [
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
            ], // TMP
            columnHeight,
            startHeight: column.startHeight,
            cellData: column.cells.map((cell, level) => {
              if (!cellTemplates) cellTemplates = {};
              const cellTemplate = cell.cellTemplate ? cellTemplates[cell.cellTemplate] : undefined;
              cellStartHeight += cell.height;

              if (cell.disabled) return []; // disabled cells are not exported
              if (!cellTemplate) return [];

              return (cellTemplate?.loads ?? [getDefaultLoad()]).map((load, loadIndex) => {
                // we get the center of every load in the rack frame of reference
                const loadPos = computeLoadsPosition(load, column.width).map((x) => x + column.x + load.W / 2);

                const slotsCE: CellDataRackCE['slots'] = new Array(load.N).fill(null).map((_, index) => {
                  const pos = loadPos[index];

                  const extendedLengthIndex = extendedLengthSegments.findIndex(([, segX]) => {
                    const p = pos - segX;

                    return Math.abs(p) < epsilon;
                  });

                  if (extendedLengthIndex === -1) {
                    throw new Error(
                      `Unable to find the extended length segment for the load ${JSON.stringify(
                        load,
                        null,
                        2
                      )}} (column: ${column.id}, rack: ${
                        rack.id
                      }) while exporting the xml, extendedLengthSegmentsIds: ${column.extendedLengthSegments.map(
                        (s) => s[0]
                      )}, extendedLengthSegmentsPos: ${column.extendedLengthSegments.map(
                        (s) => s[1]
                      )}, loadPos: ${loadPos}, column.x: ${column.x}`
                    );
                  }

                  const slotId =
                    cell.names?.[loadIndex]?.[index]?.id ?? `${cell.id}-slot_load=${loadIndex}_index=${index}`;
                  const extendedLengthId = extendedLengthSegments[extendedLengthIndex][0];
                  const portionId = `${extendedLengthId}-through`;

                  if (!mapIds[slotId] && !isNaN(parseInt(slotId, 10))) {
                    mapIds[slotId] = parseInt(slotId, 10);
                  }

                  const title = cell.names?.[loadIndex]?.[index]?.value;
                  const slotCE: CellDataRackCE['slots'][0] = {
                    id: mapIds[slotId] || (mapIds[slotId] = currentId++),
                    title: title ?? `unknown_${slotId}_${Math.floor(Math.random() * 1e10)}`,
                    associatedPortionId: mapIds[portionId] || (mapIds[portionId] = currentId++),
                    freespaceDetection: roadFreespaceToCEFreespace(load.references[index]),
                    leftMinGap: load.a1 * UNIT_COUNT_PER_METER,
                    rightMinGap: (load.a2 ?? load.a1) * UNIT_COUNT_PER_METER,
                    active: !cell.disabled && !cell.names?.[loadIndex]?.[index]?.disabled,
                  };

                  if (title === undefined) {
                    // eslint-disable-next-line no-console
                    console.error(
                      `Unable to find the title for the slot ${slotId} (cell: ${cell.id}, rack: ${rack.id}, load: ${loadIndex}) while exporting the xml`
                    );

                    SnackbarUtils.error('A problem happened while exporting at last one name of a rack slot.', {
                      preventDuplicate: true,
                    });
                  }

                  return slotCE;
                });

                const posInX = uprightGeometry.posInX / 100 + (uprightGeometry.enabled ? uprightGeometry.width : 0);
                const startHeight = cellStartHeight - cell.height - (level === 0 ? column.startHeight : 0);
                const cellHeight = cell.height;
                const width = column.width;
                const beamUpMarginValue =
                  cellTemplate.perception.beamUpMarginObstacle ?? getDefaultBeamUpMarginObstacle();
                // front rect for a cell
                const frontRect = {
                  left: posInX,
                  top: startHeight + cellHeight + (level === 0 ? column.startHeight : 0),
                  right: posInX + width,
                  bottom: startHeight,
                };

                return {
                  id: mapIds[cell.id] || (mapIds[cell.id] = +cell.id),
                  approachDistance: cellTemplate ? cellTemplate.approachDistance : 0, // tmp
                  columnId: mapIds[column.id],
                  loadName: load.name,
                  beam: {
                    thickness: level !== 0 ? cell.beamThickness : column.startHeight,
                    distanceToRackFront:
                      cellTemplate && cellTemplate.beamDistanceToRackFront !== undefined
                        ? cellTemplate.beamDistanceToRackFront
                        : 0,
                  },
                  cellHeight: cell.height,
                  detectionSettings: {
                    beam3D: cellTemplate ? cellTemplate.perception.enablePerceptionDrop ?? true : true,
                    freeSpace3D: cellTemplate ? cellTemplate.perception.enablePerceptionDrop ?? true : true,
                    pallet3D: cellTemplate ? cellTemplate.perception.enablePerceptionPick ?? true : true,
                    telemeter: cellTemplate ? cellTemplate.perception.rangefinderDetection ?? true : true,
                    beamUpMarginObstacle: beamUpMarginValue,
                    beamDownMarginObstacle: 0, // tmp
                    depthPalletMargin: 40 / 1000,
                    leftDropUprightAdjust: 0,
                    rightDropUprightAdjust: 0,
                    pickHeightAdjust: 0,
                    dropHeightAdjust: 0,
                    sidePalletMargin: 30 / 1000,
                    underBeamMargin: 150 / 1000,
                  },
                  distancePalletToPallet:
                    (column.width - load.N * load.W - (load.a1 + (load.a2 ?? load.a1))) / (load.N > 1 ? load.N - 1 : 1), // tmp
                  distancePoleToPallet: load.a1,
                  footProtectionOverflow: 0, // tmp
                  numberOfPalletOnDepth: 1,
                  pallet: {
                    length: load.length ?? cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 0,
                    overflow: load.palletOverflow ?? cellTemplate?.palletOverflow ?? getDefaultBeamUpMarginObstacle(),
                    width: load.W,
                  },
                  numberOfPalletOnWidth: load.N,
                  polygon: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ], // TMP
                  frontRect,
                  slots: slotsCE,
                  width: column.width,
                  rackWidth,
                  rackDepth,
                  posInX,
                  startHeight,
                };
              });
            }),
          };

          /** we need to add portions that goes through the slots for every extended length segment */
          column.extendedLengthSegments.forEach((seg) => {
            const segment = segments?.find((s) => s.id === seg[0]);
            if (!segment) {
              // eslint-disable-next-line no-console
              console.error(`segment ${seg[0]} not found`);

              return;
            }

            const portionId = `${segment.id}-through`;
            const portionThroughId = mapIds[portionId] || (mapIds[portionId] = +portionId);

            const rackDepth = rack.properties.depth;
            const angleInDegree = (props.cap - 90 + 360) % 360;
            const cap = toRad(angleInDegree);

            const slotBegin = [
              segment.geometry.coordinates[0][0] - Math.cos(-cap) * rackDepth * 100,
              -segment.geometry.coordinates[0][1] - Math.sin(-cap) * rackDepth * 100,
            ];

            const slotEnd = [segment.geometry.coordinates[0][0], -segment.geometry.coordinates[0][1]];

            const pointsPortion = [
              [slotBegin[0], slotBegin[1]],
              [slotEnd[0], slotEnd[1]],
            ];

            if (!editorLayers) editorLayers = {};
            if (!layerGroups) layerGroups = {};

            let alreadyCountedWarning = false;
            getLayerGroupsParents(layers, editorLayers, rack, layerGroups).forEach((layer) => {
              if (layer.modelName !== 'Common' && !alreadyCountedWarning) {
                additionalData.racksInOtherLayerGroupThanCommon?.push(rack.id as string);
                alreadyCountedWarning = true;
              }

              layer.portions.push({
                id: portionThroughId,
                points: pointsPortion.map((coord) => {
                  return [coordToCircuit(coord[0]), -coordToCircuit(coord[1]), parseFloat(angleInDegree.toFixed(4))];
                }),
                locked: true,
                segmentOriginId: null,
                isExtremity: false,
                isWireGuided: false,
              });

              layer.portionsCE.push({
                id: portionThroughId,
                points: pointsPortion.map((coord) => {
                  return [coordToCircuit(coord[0]), -coordToCircuit(coord[1]), parseFloat(angleInDegree.toFixed(4))];
                }),
                locked: true,
                segmentOriginId: portionThroughId,
                rack: true,
                // isExtremity: false,
              });

              const listPortionInput = [portionThroughId];

              const portionSegmentId = segment.properties.portions[0].id;
              const rackSegmentId = mapIds[portionSegmentId] || (mapIds[portionSegmentId] = +portionSegmentId);

              let crossRoadIdToAddInputs: number | undefined;

              if (!layerGroups) layerGroups = {};
              if (!editorLayers) editorLayers = {};

              commonLayer.forEach((c) => {
                c.crossRoads.push({
                  id: currentId++,
                  listPortionInput,
                  listPortionOutput: [rackSegmentId],
                  angleInDegree: rack.properties.cap,
                  type: 'Start',
                  exportComputedPoints: false,
                });
              });

              if (crossRoadIdToAddInputs !== undefined) {
                const crossRoadToAddInput = layer.crossRoads[crossRoadIdToAddInputs];
                if (crossRoadToAddInput) {
                  crossRoadToAddInput.listPortionInput.push(portionThroughId);
                }
              }
            });
          });

          return [...acc, uprightCE, columnCE];
        },
        [] as RackCEInterface['listOfRackData']
      ),
    };

    // const lastUpright = uprights[uprights.length - 1];
    const lastUprightId = `${columns[columns.length - 1].id}-upright_${uprights.length - 1}`;
    const uprightGeometry = uprightsGeometry[uprights.length - 1];

    const posInX = uprightGeometry.posInX / 100;
    const columnHeight = computeRackColumnHeight(columns[columns.length - 1]);

    // front rect for an upright
    const frontRect = {
      left: posInX - uprightGeometry.width / 2,
      top: columnHeight,
      right: posInX + (uprightGeometry.enabled ? uprightGeometry.width / 2 : 0),
      bottom: 0,
    };

    rackCir.listOfRackData.push({
      id: mapIds[lastUprightId] || (mapIds[lastUprightId] = currentId++),
      type: 'RackUprightExportData',
      polygon: uprightGeometry.polygon.map((point) => point.map((pos) => coordToCircuit(pos))),
      posInX: rackWidth - uprights[uprights.length - 1].width,
      width: uprights[uprights.length - 1].width,
      rackWidth,
      columnHeight,
      frontRect,
    });

    if (!layerGroups) layerGroups = {};
    if (!editorLayers) editorLayers = {};

    getLayerGroupsParents(layers, editorLayers, rack, layerGroups).forEach((layer) => layer.racks.push(rackCir));
  });

  // we need to remove the portions connected to only one side (i.e. only in input or only in output) (except for the portions linked to a stockzones, portions with a point on it)
  layers.forEach((layer) => {
    const extremityPortions = layer.portions.filter((portion) => portion.isExtremity).map((portion) => portion.id);

    extremityPortions.forEach((portionToRemoveId) => {
      // but we need to check that there's no point on this portion!
      for (let j = 0, l = layer.destinationPoints.length; j < l; j++) {
        const point = layer.destinationPoints[j];

        if (point.portionDataId === portionToRemoveId) return;
      }

      const indexPortionToRemove = layer.portions.findIndex((portion) => portion.id === portionToRemoveId);
      // but we need to check it's not a portion connected to a stock zone
      const portionToRemove = layer.portions[indexPortionToRemove];
      if (portionToRemove.stockZoneId) return;

      // it's ok, we can proceed with the deletion
      if (indexPortionToRemove !== -1) {
        layer.portions.splice(indexPortionToRemove, 1);

        // and find the crossroad with this portion to remove the element
        for (let j = 0, l = layer.crossRoads.length; j < l; j++) {
          const crossRoad2 = layer.crossRoads[j];

          if (crossRoad2.listPortionInput.includes(portionToRemoveId)) {
            const index = crossRoad2.listPortionInput.indexOf(portionToRemoveId);
            if (index !== -1) crossRoad2.listPortionInput.splice(index, 1);
            break;
          } else if (crossRoad2.listPortionOutput.includes(portionToRemoveId)) {
            const index = crossRoad2.listPortionOutput.indexOf(portionToRemoveId);
            if (index !== -1) crossRoad2.listPortionOutput.splice(index, 1);
            break;
          }
        }
      }
    });
  });

  // then we output the xml string :)
  const xmlDoc = (inWorker ? new DOMImplementationXmlDom() : new Document().implementation).createDocument(
    null,
    'xml',
    null
  );
  const xml = xmlDoc.createElementNS('', 'ProjectData');

  if (!inWorker) (Element as any).prototype.appendChild = Element.prototype.append;

  xml.setAttribute('xmlns:xsd', 'http://www.w3.org/2001/XMLSchema');
  xml.setAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');

  const kind = xmlDoc.createElement('Kind');
  kind.textContent = 'Project';
  xml.appendChild(kind);
  const idProject = xmlDoc.createElement('Id');
  idProject.textContent = (Math.floor(Math.random() * 10) + 1001).toString();
  xml.appendChild(idProject);
  const circuitEditorVersion = xmlDoc.createElement('Version');
  circuitEditorVersion.textContent = '4.02';
  xml.appendChild(circuitEditorVersion);
  const circuitVersion = xmlDoc.createElement('CircuitVersion');
  circuitVersion.textContent = projectVersion;
  xml.appendChild(circuitVersion);
  const circuitDescription = xmlDoc.createElement('CircuitDescription');
  circuitDescription.textContent = projectDescription;
  xml.appendChild(circuitDescription);
  const projectType = xmlDoc.createElement('ProjectType');
  projectType.textContent = 'Project';
  xml.appendChild(projectType);
  const firstFreeId = xmlDoc.createElement('FirstFreeId');
  firstFreeId.textContent = '1000000'; //(++currentId).toString(); // TMP
  xml.appendChild(firstFreeId);
  const palletWidthInUnit = xmlDoc.createElement('PalletWidthInUnit');
  palletWidthInUnit.textContent = '800000'; // TMP
  xml.appendChild(palletWidthInUnit);
  const palletLengthInUnit = xmlDoc.createElement('PalletLengthInUnit');
  palletLengthInUnit.textContent = '1200000'; // TMP
  xml.appendChild(palletLengthInUnit);
  const currentMapType = xmlDoc.createElement('CurrentMapType');
  currentMapType.textContent = 'FromGeoFile'; // TMP
  xml.appendChild(currentMapType);

  const exportEl = xmlDoc.createElement('Export');
  const sha1El = xmlDoc.createElement('SHA1');
  sha1El.textContent = ''; // the value is replaced later, we always compute the sha1 of the xml circuit without the sha1 element in it
  exportEl.appendChild(sha1El);
  const unitCountPerMeterEl = xmlDoc.createElement('UnitCountPerMeter');
  unitCountPerMeterEl.textContent = unitCountPerMeter.toString();
  exportEl.appendChild(unitCountPerMeterEl);
  const circuitVersionSecond = xmlDoc.createElement('CircuitVersion');
  circuitVersionSecond.textContent = projectVersion;
  exportEl.appendChild(circuitVersionSecond);
  const circuitDescriptionSecond = xmlDoc.createElement('CircuitDescription');
  circuitDescriptionSecond.textContent = projectDescription;
  exportEl.appendChild(circuitDescriptionSecond);
  const allLayer = xmlDoc.createElement('AllLayer');
  layers.forEach((layer) => {
    const layerEl = xmlDoc.createElement('Layer');
    layerEl.setAttribute('Id', layer.id.toString());
    layerEl.setAttribute('ModelName', layer.modelName);

    if (layer.crossRoads.length) {
      const allCrossRoadsEl = xmlDoc.createElement('AllCrossroads');
      allCrossRoadsEl.setAttribute('Count', layer.crossRoads.length.toString());

      layer.crossRoads.forEach((crossRoad) => {
        const crossRoadEl = xmlDoc.createElement('Crossroad');
        crossRoadEl.setAttribute('Id', crossRoad.id.toString());

        const listPortionInput = xmlDoc.createElement('ListPortionInput');
        listPortionInput.textContent = crossRoad.listPortionInput.join(' ');
        const listPortionOutput = xmlDoc.createElement('ListPortionOutput');
        listPortionOutput.textContent = crossRoad.listPortionOutput.join(' ');

        crossRoadEl.appendChild(listPortionInput);
        crossRoadEl.appendChild(listPortionOutput);

        allCrossRoadsEl.appendChild(crossRoadEl);
      });

      layerEl.appendChild(allCrossRoadsEl);
    }

    if (layer.portions.length) {
      const allPortionsEl = xmlDoc.createElement('AllPortions');
      allPortionsEl.setAttribute('Count', layer.portions.length.toString());

      layer.portions.forEach((portion) => {
        const portionEl = xmlDoc.createElement('Portion');
        portionEl.setAttribute('Id', portion.id.toString());
        if (portion.stopBeforeTurn) {
          portionEl.setAttribute('TurnOptionFlag', 'StopBeforeTurn');
        }

        if (portion.trafficType && portion.trafficType !== 'auto') {
          const forceTrafficRule = trafficTypeMap[portion.trafficType] ?? 'Kernel';
          portionEl.setAttribute('ForceRule', forceTrafficRule);
        }

        if (portion.isWireGuided) {
          portionEl.setAttribute('IsWireGuided', 'true');
        }

        const pointsEl = xmlDoc.createElement('Points');
        pointsEl.textContent = portion.points
          .map((point) => point.map((coord, index) => (index === 2 ? coord.toFixed(4) : coord)).join(';'))
          .join(' ');

        portionEl.appendChild(pointsEl);
        allPortionsEl.appendChild(portionEl);
      });
      layerEl.appendChild(allPortionsEl);
    }

    if (layer.stockZones.length) {
      const allStockZonesEl = xmlDoc.createElement('AllStockZones');
      layer.stockZones.forEach((stockZone) => {
        const stockZoneEl = xmlDoc.createElement('StockZone');

        stockZoneEl.setAttribute('Id', stockZone.id.toString());
        stockZoneEl.setAttribute('BalyoTitle', stockZone.title.toString());
        stockZoneEl.setAttribute('Title', stockZone.title.toString());
        stockZoneEl.setAttribute('EnableScan', stockZone.enableScan.toString());
        stockZoneEl.setAttribute('AllowRcmModifications', 'false');
        stockZoneEl.setAttribute('FillingBackgroundRule', 'None');

        const listOfStockLineEl = xmlDoc.createElement('ListOfStockLine');
        for (const stockLine of stockZone.stockLines) {
          const stockLineEl = xmlDoc.createElement('StockLine');
          stockLineEl.setAttribute('Id', stockLine.id.toString());
          stockLineEl.setAttribute('PortionDataId', stockLine.portionThroughId.toString());
          stockLineEl.setAttribute('Title', stockLine.name);
          stockLineEl.setAttribute('NumberOfPallet', stockLine.numberOfPallets.toString());
          stockLineEl.setAttribute(
            'SpaceBetweenPallet',
            (stockZone.spaceBetweenPallet * UNIT_COUNT_PER_METER).toString()
            // '0'
          );

          const palletEl = xmlDoc.createElement('Pallet');
          palletEl.setAttribute('Length', (stockZone.palletLength * UNIT_COUNT_PER_METER).toString());
          palletEl.setAttribute('Width', (stockZone.palletWidth * UNIT_COUNT_PER_METER).toString());
          palletEl.setAttribute('HeightTaken', (stockZone.palletHeight * UNIT_COUNT_PER_METER).toString());
          stockLineEl.appendChild(palletEl);

          listOfStockLineEl.appendChild(stockLineEl);
        }

        stockZoneEl.appendChild(listOfStockLineEl);

        allStockZonesEl.appendChild(stockZoneEl);
      });

      layerEl.appendChild(allStockZonesEl);
    }

    if (layer.racks.length) {
      const allRackZonesEl = xmlDoc.createElement('AllRackZones');

      layer.racks.forEach((rack) => {
        const rackZoneEl = xmlDoc.createElement('RackZone');
        rackZoneEl.setAttribute('Id', rack.id.toString());
        rackZoneEl.setAttribute('BalyoTitle', rack.title.toString());
        rackZoneEl.setAttribute('Title', rack.title.toString());
        rackZoneEl.setAttribute('AngleInDegree', rack.angleInDegree.toString());
        rackZoneEl.setAttribute('FlowDirection', rack.flowDirection.toString());
        rackZoneEl.setAttribute('RackDepth', (rack.rackDepth * UNIT_COUNT_PER_METER).toFixed(0));

        const polygonEl = xmlDoc.createElement('Polygon');
        polygonEl.textContent = rack.polygon.map((coord) => coord.map((coord) => coord.toFixed(0)).join(';')).join(' ');
        rackZoneEl.appendChild(polygonEl);

        const listOfAccessInfoEl = xmlDoc.createElement('ListOfAccessInfo');
        listOfAccessInfoEl.textContent = rack.listOfAccessInfo
          .map((accessInfo) => `${accessInfo[0]};${accessInfo[2]}`)
          .join(' ');
        rackZoneEl.appendChild(listOfAccessInfoEl);

        const listOfRackdataEl = xmlDoc.createElement('ListOfRackData');
        rack.listOfRackData.forEach((rackData) => {
          const rackDataEl = xmlDoc.createElement('RackData');
          // we don't insert the same data wether it's a column or an upright
          if (rackData.cellData) {
            // it's a column
            rackDataEl.setAttribute('xsi:type', 'RackColumnExportData');
            rackDataEl.setAttribute('Id', rackData.id.toString());

            const listOfCellDataEl = xmlDoc.createElement('ListOfCellData');
            rackData.cellData.forEach((cellData, cellDataIndex) => {
              if (!cellData) return;

              const cellDataSample = cellData.find((cell) => cell); // first active cell from the floor (for general properties)
              if (!cellDataSample) return;

              const cellDataEl = xmlDoc.createElement('CellData');
              cellDataEl.setAttribute('Id', cellDataSample.id.toString());
              cellDataEl.setAttribute('NumberOfPalletOnDepth', cellDataSample.numberOfPalletOnDepth.toString());
              cellDataEl.setAttribute('ColumnId', cellDataSample.columnId.toString());
              cellDataEl.setAttribute(
                'ApproachDistance',
                (cellDataSample.approachDistance * UNIT_COUNT_PER_METER).toString()
              );
              cellDataEl.setAttribute(
                'FootProtectionOverflow',
                (cellDataSample.footProtectionOverflow * UNIT_COUNT_PER_METER).toString()
              );

              const listOfCellPatterns = xmlDoc.createElement('ListOfCellPatterns');

              cellData.forEach((cellPattern) => {
                if (!cellPattern) return;

                const cellPatternEl = xmlDoc.createElement('CellPattern');

                const palletEl = xmlDoc.createElement('Pallet');
                palletEl.setAttribute('Length', (cellPattern.pallet.length * UNIT_COUNT_PER_METER).toString());
                palletEl.setAttribute('Width', (cellPattern.pallet.width * UNIT_COUNT_PER_METER).toString());
                palletEl.setAttribute('Overflow', (cellPattern.pallet.overflow * UNIT_COUNT_PER_METER).toString());
                cellPatternEl.appendChild(palletEl);

                const listOfSlotsEl = xmlDoc.createElement('ListOfSlots');
                cellPattern.slots.forEach((slot) => {
                  const slotEl = xmlDoc.createElement('Slot');
                  slotEl.setAttribute('Id', slot.id.toString());
                  slotEl.setAttribute('Title', slot.title);

                  slotEl.setAttribute('AssociatedPortion', slot.associatedPortionId.toString());
                  slotEl.setAttribute('FreespaceDetection', slot.freespaceDetection);
                  slotEl.setAttribute('LeftMinGap', slot.leftMinGap.toString());
                  slotEl.setAttribute('RightMinGap', slot.rightMinGap.toString());
                  if (!slot.active) {
                    slotEl.setAttribute('IsEnabled', 'false');
                  }

                  listOfSlotsEl.appendChild(slotEl);
                });
                cellPatternEl.appendChild(listOfSlotsEl);

                const numberOfPalletInWidthEl = xmlDoc.createElement('NumberOfPalletInWidth');
                numberOfPalletInWidthEl.textContent = cellPattern.numberOfPalletOnWidth.toString();
                cellPatternEl.appendChild(numberOfPalletInWidthEl);

                const distancePalletToPalletEl = xmlDoc.createElement('DistancePalletToPallet');
                distancePalletToPalletEl.textContent = (
                  cellPattern.distancePalletToPallet * UNIT_COUNT_PER_METER
                ).toFixed(0);
                cellPatternEl.appendChild(distancePalletToPalletEl);

                const distanceUprightToPalletEl = xmlDoc.createElement('DistanceUprightToPallet');
                distanceUprightToPalletEl.textContent = (
                  cellPattern.distancePoleToPallet * UNIT_COUNT_PER_METER
                ).toFixed(0);
                cellPatternEl.appendChild(distanceUprightToPalletEl);

                const leftOffsetEl = xmlDoc.createElement('LeftOffset');
                leftOffsetEl.textContent = '0';
                cellPatternEl.appendChild(leftOffsetEl);

                listOfCellPatterns.appendChild(cellPatternEl);
              });
              cellDataEl.appendChild(listOfCellPatterns);

              // the front rect for a cell
              const frontRect = cellDataSample.frontRect;

              const frontRectEl = xmlDoc.createElement('FrontRect');
              frontRectEl.textContent = `${(frontRect.left * UNIT_COUNT_PER_METER).toFixed(0)};${(
                frontRect.top * UNIT_COUNT_PER_METER
              ).toFixed(0)};${(frontRect.right * UNIT_COUNT_PER_METER).toFixed(0)};${(
                frontRect.bottom * UNIT_COUNT_PER_METER
              ).toFixed(0)}`;
              cellDataEl.appendChild(frontRectEl);

              const beamEl = xmlDoc.createElement('Beam');
              beamEl.setAttribute('Thickness', (cellDataSample.beam.thickness * UNIT_COUNT_PER_METER).toString());
              beamEl.setAttribute(
                'DistanceToRackFront',
                (cellDataSample.beam.distanceToRackFront * UNIT_COUNT_PER_METER).toString()
              );
              cellDataEl.appendChild(beamEl);

              const detectionSettingsEl = xmlDoc.createElement('DetectionSettings');
              detectionSettingsEl.setAttribute('Pallet3D', cellDataSample.detectionSettings.pallet3D.toString());
              detectionSettingsEl.setAttribute('Beam3D', cellDataSample.detectionSettings.beam3D.toString());
              detectionSettingsEl.setAttribute('Freespace3D', cellDataSample.detectionSettings.freeSpace3D.toString());
              detectionSettingsEl.setAttribute('Telemeter', cellDataSample.detectionSettings.telemeter.toString());
              detectionSettingsEl.setAttribute(
                'SidePalletMargin',
                (cellDataSample.detectionSettings.sidePalletMargin * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'DepthPalletMargin',
                (cellDataSample.detectionSettings.depthPalletMargin * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'UnderBeamMargin',
                (cellDataSample.detectionSettings.underBeamMargin * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'BeamUpMarginObstacle',
                (cellDataSample.detectionSettings.beamUpMarginObstacle * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'DropHeightAdjust',
                (cellDataSample.detectionSettings.dropHeightAdjust * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'PickHeightAdjust',
                (cellDataSample.detectionSettings.pickHeightAdjust * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'LeftDropUprightAdjust',
                (cellDataSample.detectionSettings.leftDropUprightAdjust * UNIT_COUNT_PER_METER).toString()
              );
              detectionSettingsEl.setAttribute(
                'RightDropUprightAdjust',
                (cellDataSample.detectionSettings.rightDropUprightAdjust * UNIT_COUNT_PER_METER).toString()
              );
              cellDataEl.appendChild(detectionSettingsEl);

              listOfCellDataEl.appendChild(cellDataEl);
            });
            rackDataEl.appendChild(listOfCellDataEl);
          } else {
            // the front rect for an upright
            const frontRect = rackData.frontRect ?? { left: 0, top: 0, right: 0, bottom: 0 };

            // it's an upright
            rackDataEl.setAttribute('xsi:type', 'RackUprightExportData');
            rackDataEl.setAttribute('Id', rackData.id.toString());
            const frontRectEl = xmlDoc.createElement('FrontRect');
            frontRectEl.textContent = frontRectEl.textContent = `${(frontRect.left * UNIT_COUNT_PER_METER).toFixed(
              0
            )};${(frontRect.top * UNIT_COUNT_PER_METER).toFixed(0)};${(frontRect.right * UNIT_COUNT_PER_METER).toFixed(
              0
            )};${(frontRect.bottom * UNIT_COUNT_PER_METER).toFixed(0)}`;
            rackDataEl.appendChild(frontRectEl);
          }

          listOfRackdataEl.appendChild(rackDataEl);
        });
        rackZoneEl.appendChild(listOfRackdataEl);

        allRackZonesEl.appendChild(rackZoneEl);
      });

      layerEl.appendChild(allRackZonesEl);
    }

    if (layer.destinationPoints.length) {
      const allDestinationPointsEl = xmlDoc.createElement('AllDestinationPoints');
      layer.destinationPoints
        .filter((destPoint) => {
          return destPoint.portionDataId;
        })
        .forEach((destPoint) => {
          const destinationPointEl = xmlDoc.createElement('DestinationPoint');
          destinationPointEl.setAttribute('Id', destPoint.id.toString());
          destinationPointEl.setAttribute('PortionDataId', (destPoint.portionDataId || 0).toString());
          destinationPointEl.setAttribute('Percent', (destPoint.percent || 0).toString());
          destinationPointEl.setAttribute('DestinationType', destPoint.type);
          destinationPointEl.setAttribute('Title', destPoint.title);
          if (destPoint.type.includes('Init')) {
            const minimalScoreForInitEl = xmlDoc.createElement('MinimalScoreForInit');
            minimalScoreForInitEl.textContent = destPoint.minimalScoreInit.toString();
            destinationPointEl.appendChild(minimalScoreForInitEl);
          }

          const pointEl = xmlDoc.createElement('Point');
          pointEl.textContent = `${coordToCircuit(destPoint.coords[0])};${coordToCircuit(destPoint.coords[1])}`;
          destinationPointEl.appendChild(pointEl);
          allDestinationPointsEl.appendChild(destinationPointEl);

          if (destPoint.elevatorName) {
            const elevatorNameEl = xmlDoc.createElement('ElevatorName');
            elevatorNameEl.textContent = destPoint.elevatorName;
            destinationPointEl.appendChild(elevatorNameEl);
            allDestinationPointsEl.appendChild(destinationPointEl);
          }

          if (destPoint.floorLevel) {
            const floorLevelEl = xmlDoc.createElement('FloorName');
            floorLevelEl.textContent = destPoint.floorLevel;
            destinationPointEl.appendChild(floorLevelEl);
            allDestinationPointsEl.appendChild(destinationPointEl);
          }
        });

      layerEl.appendChild(allDestinationPointsEl);
    }

    if (layer.zones.length) {
      const allZonesEl = xmlDoc.createElement('AllZones');
      layer.zones.forEach((zone) => {
        const zoneEl = xmlDoc.createElement('Zone');
        zoneEl.setAttribute('Id', zone.id.toString());
        zoneEl.setAttribute('BalyoTitle', zone.title);
        zoneEl.setAttribute('Title', zone.title);
        zoneEl.setAttribute('Projection', zone.projection);
        const listOfRulesEl = xmlDoc.createElement('ListOfRules');
        listOfRulesEl.textContent = zone.listOfRules.join(' ');
        zoneEl.appendChild(listOfRulesEl);
        const polygonEl = xmlDoc.createElement('Polygon');
        polygonEl.textContent = zone.polygon.join(' ').replaceAll(',', ';');
        zoneEl.appendChild(polygonEl);
        if (zone.enterCallback) {
          const enterCallbackEl = xmlDoc.createElement('enterCallback');
          enterCallbackEl.textContent = zone.enterCallback;
          zoneEl.appendChild(enterCallbackEl);
        }

        if (zone.exitCallback) {
          const exitCallbackEl = xmlDoc.createElement('exitCallback');
          exitCallbackEl.textContent = zone.exitCallback;
          zoneEl.appendChild(exitCallbackEl);
        }

        if (zone.broadcast) {
          const broadcastEl = xmlDoc.createElement('Broadcast');
          broadcastEl.textContent = zone.broadcast;
          zoneEl.appendChild(broadcastEl);
        }

        allZonesEl.appendChild(zoneEl);
      });

      layerEl.appendChild(allZonesEl);
    }

    allLayer.appendChild(layerEl);
  });

  exportEl.appendChild(allLayer);
  xml.appendChild(exportEl);

  const maxZOrderEl = xmlDoc.createElement('MaxZOrder');
  maxZOrderEl.textContent = '0';
  xml.appendChild(maxZOrderEl);
  const currentAgvModelEl = xmlDoc.createElement('CurrentAgvModel');
  currentAgvModelEl.textContent = 'P50_FV';
  xml.appendChild(currentAgvModelEl);
  const currentPreferenceCircuitEl = xmlDoc.createElement('CurrentPreferenceCircuit');
  currentPreferenceCircuitEl.textContent = 'circuit.xml';
  xml.appendChild(currentPreferenceCircuitEl);
  const currentGabaritEl = xmlDoc.createElement('CurrentGabarit');
  currentGabaritEl.textContent = 'Traffic';
  xml.appendChild(currentGabaritEl);

  const listOfLayerEl = xmlDoc.createElement('ListOfLayer');
  layers.forEach((layer) => {
    const layerEl = xmlDoc.createElement('LayerData');
    const kindEl = xmlDoc.createElement('Kind');
    kindEl.textContent = 'Layer';
    layerEl.appendChild(kindEl);
    const idEl = xmlDoc.createElement('Id');
    idEl.textContent = layer.id.toString();
    layerEl.appendChild(idEl);
    const zOrderEl = xmlDoc.createElement('ZOrder');
    zOrderEl.textContent = '0';
    layerEl.appendChild(zOrderEl);
    const isMouseAllowedToMoveEl2 = xmlDoc.createElement('IsMouseAllowedToMove');
    isMouseAllowedToMoveEl2.textContent = 'true';
    layerEl.appendChild(isMouseAllowedToMoveEl2);
    const titleEl = xmlDoc.createElement('Title');
    titleEl.textContent = layer.modelName;
    layerEl.appendChild(titleEl);
    const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
    balyoTitleEl.textContent = layer.modelName;
    layerEl.appendChild(balyoTitleEl);
    const turretPosXEl = xmlDoc.createElement('TurretPoseX');
    if (layer.modelName === 'Common') {
      turretPosXEl.textContent = '1';
    } else {
      const turretPoseX =
        preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/turretXPositionForksDown'] ??
        '1.15';
      turretPosXEl.textContent = turretPoseX;
    }

    layerEl.appendChild(turretPosXEl);
    const maxTurretAngleInDegreeEl = xmlDoc.createElement('MaxTurretAngleInDegree');
    if (layer.modelName === 'Common') {
      maxTurretAngleInDegreeEl.textContent = '1.8';
    } else {
      const maxTurretAngle =
        preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/maxTurretAngularVelocity'] ?? '45';

      maxTurretAngleInDegreeEl.textContent = maxTurretAngle;
    }

    layerEl.appendChild(maxTurretAngleInDegreeEl);
    const maxVelYEl = xmlDoc.createElement('MaxVelY');
    maxVelYEl.textContent = '0.3';
    layerEl.appendChild(maxVelYEl);
    const robotOvershootCornerXEl = xmlDoc.createElement('RobotOvershootCorner_X');
    robotOvershootCornerXEl.textContent = layer.modelName === 'Common' ? '1' : '1.5';
    layerEl.appendChild(robotOvershootCornerXEl);
    const robotOvershootCornerYEl = xmlDoc.createElement('RobotOvershootCorner_Y');
    robotOvershootCornerYEl.textContent = layer.modelName === 'Common' ? '0.5' : '0.5';
    layerEl.appendChild(robotOvershootCornerYEl);

    const listOfControlPointDataEl = xmlDoc.createElement('ListOfControlPointData');
    layer.portionsCE.forEach((portion) => {
      if (portion.isTurn) return;

      portion.points.forEach((point, i) => {
        const pointId = `${portion.id}_${i}`;
        let id = mapIds[pointId] || (mapIds[pointId] = currentId++);

        if (portion.stockline && i === 1) {
          const crossRoad = layer.crossRoads.find((cr) => cr.listPortionInput.includes(portion.id));
          if (crossRoad) {
            id = crossRoad.id;
          }
        }

        if (portion.isExtendedLength && i === 0) {
          return;
        }

        const controlPointDataEl = xmlDoc.createElement('ControlPointData');
        const kindEl2 = xmlDoc.createElement('Kind');
        kindEl2.textContent = 'ControlPoint';
        controlPointDataEl.appendChild(kindEl2);
        const idEl2 = xmlDoc.createElement('Id');
        idEl2.textContent = id.toString();
        controlPointDataEl.appendChild(idEl2);
        // nota: if the id contains __, kiwi fails to parse the xml
        // const commentIdEl = xmlDoc.createComment(`Road Editor id: ${pointId}`);
        // controlPointDataEl.appendChild(commentIdEl);
        const zOrderEl2 = xmlDoc.createElement('ZOrder');
        zOrderEl2.textContent = '0';
        controlPointDataEl.appendChild(zOrderEl2);
        if (portion.locked) {
          const lockedEl = xmlDoc.createElement('IsLocked');
          lockedEl.textContent = 'true';
          controlPointDataEl.appendChild(lockedEl);
        }

        //if (!(portion.stockline && i === 0)){
        const isMouseAllowedToMoveEl = xmlDoc.createElement('IsMouseAllowedToMove');
        isMouseAllowedToMoveEl.textContent = 'true';
        controlPointDataEl.appendChild(isMouseAllowedToMoveEl);
        //}
        const titleEl2 = xmlDoc.createElement('Title');
        titleEl2.textContent = id.toString();
        controlPointDataEl.appendChild(titleEl2);
        const balyoTitleEl2 = xmlDoc.createElement('BalyoTitle');
        balyoTitleEl2.textContent = id.toString();
        controlPointDataEl.appendChild(balyoTitleEl2);
        const ptInUnitEl = xmlDoc.createElement('PtInUnit');
        ptInUnitEl.textContent = `${point[0]};${point[1]}`;
        controlPointDataEl.appendChild(ptInUnitEl);
        const angleInDegreeEl = xmlDoc.createElement('AngleInDegree');
        angleInDegreeEl.textContent = parseInt(point[2].toFixed(4), 10).toString();
        controlPointDataEl.appendChild(angleInDegreeEl);

        if (portion.isWireGuided) {
          const isWireGuidedEl = xmlDoc.createElement('IsWireGuided');
          isWireGuidedEl.textContent = 'true';
          controlPointDataEl.appendChild(isWireGuidedEl);
        }

        listOfControlPointDataEl.appendChild(controlPointDataEl);
      });
    });

    layerEl.appendChild(listOfControlPointDataEl);

    const listOfComputedPointDataEl = xmlDoc.createElement('ListOfComputedPointData');
    layer.crossRoads.forEach((crossRoad) => {
      if (crossRoad.exportComputedPoints === false) return;

      if (!crossRoad.ptInUnit) {
        throw new Error(`Missing 'ptInUnit' property for the crossroad ${crossRoad.id}`);
      }

      if (!crossRoad.segments) {
        throw new Error(`Missing 'segments' property for the crossroad ${crossRoad.id}`);
      }

      if (!crossRoad.curves) {
        throw new Error(`Missing 'curves' property for the crossroad ${crossRoad.id}`);
      }

      const computedPointDataEl = xmlDoc.createElement('ComputedPointData');
      const kindEl2 = xmlDoc.createElement('Kind');
      kindEl2.textContent = 'ComputedPoint';
      computedPointDataEl.appendChild(kindEl2);
      const idEl2 = xmlDoc.createElement('Id');
      idEl2.textContent = crossRoad.id.toString();
      computedPointDataEl.appendChild(idEl2);
      const zOrderEl2 = xmlDoc.createElement('ZOrder');
      zOrderEl2.textContent = '0';
      computedPointDataEl.appendChild(zOrderEl2);
      const IsMouseAllowedToMoveEl2 = xmlDoc.createElement('IsMouseAllowedToMove');
      IsMouseAllowedToMoveEl2.textContent = 'true';
      computedPointDataEl.appendChild(IsMouseAllowedToMoveEl2);
      const titleEl2 = xmlDoc.createElement('Title');
      titleEl2.textContent = crossRoad.id.toString();
      computedPointDataEl.appendChild(titleEl2);
      const balyoTitle2 = xmlDoc.createElement('BalyoTitle');
      balyoTitle2.textContent = crossRoad.id.toString();
      computedPointDataEl.appendChild(balyoTitle2);
      const ptInUnitEl = xmlDoc.createElement('PtInUnit');
      ptInUnitEl.textContent = crossRoad.ptInUnit.join(';');
      computedPointDataEl.appendChild(ptInUnitEl);
      const associatedPortionIdEl = xmlDoc.createElement('AssociatedPortionId');
      associatedPortionIdEl.textContent = crossRoad.segments.join(';');
      computedPointDataEl.appendChild(associatedPortionIdEl);
      const associatedCurveIdEl = xmlDoc.createElement('AssociatedCurveId');
      associatedCurveIdEl.textContent = crossRoad.curves.join(';');
      computedPointDataEl.appendChild(associatedCurveIdEl);
      const startEndEl = xmlDoc.createElement('StartEnd');
      startEndEl.textContent = crossRoad.type === 'Start' ? 'End' : 'Start';
      computedPointDataEl.appendChild(startEndEl);
      const angleInDegreeEl = xmlDoc.createElement('AngleInDegree');
      angleInDegreeEl.textContent =
        crossRoad.type === 'Start'
          ? ((crossRoad.angleInDegree + 90) % 360).toFixed(4)
          : crossRoad.angleInDegree.toFixed(4);
      computedPointDataEl.appendChild(angleInDegreeEl);
      const isMoveableEl = xmlDoc.createElement('IsMoveable');
      isMoveableEl.textContent = 'false';
      computedPointDataEl.appendChild(isMoveableEl);

      listOfComputedPointDataEl.appendChild(computedPointDataEl);
    });

    layerEl.appendChild(listOfComputedPointDataEl);

    const listOfPortionDataEl = xmlDoc.createElement('ListOfPortionData');
    layer.portionsCE.forEach((portion) => {
      const portionDataEl = xmlDoc.createElement('PortionData');
      const kindEl2 = xmlDoc.createElement('Kind');
      kindEl2.textContent = 'Portion';
      portionDataEl.appendChild(kindEl2);
      const idEl2 = xmlDoc.createElement('Id');
      idEl2.textContent = portion.id.toString();
      portionDataEl.appendChild(idEl2);
      if (portion.roadEditorId && false) {
        // nota: if the id contains __, kiwi fails to parse the xml
        const commentIdEl = xmlDoc.createComment(`Road Editor id: ${portion.roadEditorId}`);
        portionDataEl.appendChild(commentIdEl);
      }

      if (portion.isTurn) {
        const boundingBoxInUnitEl2 = xmlDoc.createElement('BoundingBoxInUnit');
        boundingBoxInUnitEl2.textContent = `${portion.points[0][0]};${portion.points[0][1]};${
          portion.points[portion.points.length - 1][0]
        };${portion.points[portion.points.length - 1][1]}`;
        portionDataEl.appendChild(boundingBoxInUnitEl2);
      }

      const zOrderEl2 = xmlDoc.createElement('ZOrder');
      zOrderEl2.textContent = '0';
      portionDataEl.appendChild(zOrderEl2);
      if (portion.locked) {
        const lockedEl = xmlDoc.createElement('IsLocked');
        lockedEl.textContent = 'true';
        portionDataEl.appendChild(lockedEl);
      }

      if (!portion.stockline) {
        const IsMouseAllowedToMoveEl2 = xmlDoc.createElement('IsMouseAllowedToMove');
        IsMouseAllowedToMoveEl2.textContent = 'true';
        portionDataEl.appendChild(IsMouseAllowedToMoveEl2);
      }

      const titleEl2 = xmlDoc.createElement('Title');
      titleEl2.textContent = portion.id.toString();
      portionDataEl.appendChild(titleEl2);
      const balyoTitle2 = xmlDoc.createElement('BalyoTitle');
      balyoTitle2.textContent = portion.id.toString();
      portionDataEl.appendChild(balyoTitle2);
      let idLeft: number | undefined;
      if (!portion.isTurn) {
        const leftIdEl = xmlDoc.createElement('LeftId');
        let pointLeftId = `${portion.id}_0`;
        if (portion.stockline) {
          pointLeftId = `${portion.segmentOriginId}_0`;
        } else if (portion.isExtendedLength) {
          const crossRoad = layer.crossRoads.find((cr) => cr.listPortionOutput.includes(portion.id));
          if (crossRoad) {
            idLeft = crossRoad.id;
          }
        }

        if (!idLeft) {
          idLeft = mapIds[pointLeftId] || (mapIds[pointLeftId] = currentId++);
        }

        leftIdEl.textContent = idLeft.toString();
        portionDataEl.appendChild(leftIdEl);
        const rightIdEl = xmlDoc.createElement('RightId');
        let pointRightId = `${portion.id}_1`;
        let idRight = mapIds[pointRightId] || (mapIds[pointRightId] = currentId++);

        if (portion.stockline) {
          const crossRoad = layer.crossRoads.find((cr) => cr.listPortionInput.includes(portion.id));
          if (crossRoad) {
            idRight = crossRoad.id;
          }
        } else if (portion.isExtendedLength) {
          pointRightId = `${portion.id}_1`;
          idRight = mapIds[pointRightId] || (mapIds[pointRightId] = currentId++);
        }

        rightIdEl.textContent = idRight.toString();
        portionDataEl.appendChild(rightIdEl);
      } else {
        const leftIdEl = xmlDoc.createElement('LeftId');
        const rightIdEl = xmlDoc.createElement('RightId');
        layers
          .flatMap((layer) => layer.crossRoads)
          .filter((crossRoad) => {
            return crossRoad.curves ? crossRoad.curves.includes(portion.id) : false;
          })
          .forEach((crossRoad) => {
            if (crossRoad.type === 'End') {
              leftIdEl.textContent = crossRoad.id.toString();
            } else if (crossRoad.type === 'Start') {
              rightIdEl.textContent = crossRoad.id.toString();
            }
          });
        portionDataEl.appendChild(leftIdEl);
        portionDataEl.appendChild(rightIdEl);
      }

      if (!portion.isTurn) {
        const isRigidEl = xmlDoc.createElement('IsRigid');
        isRigidEl.textContent = 'true';
        portionDataEl.appendChild(isRigidEl);
      }

      const criteriumForValidationEl = xmlDoc.createElement('CriteriumForValidation');
      criteriumForValidationEl.textContent = portion.isTurn ? 'OptimizeKiwiByRadius' : 'OptimizeSpeed';
      portionDataEl.appendChild(criteriumForValidationEl);
      if (portion.isWireGuided) {
        const isWireGuidedEl = xmlDoc.createElement('IsWireGuided');
        isWireGuidedEl.textContent = 'true';
        portionDataEl.appendChild(isWireGuidedEl);
      }

      const radiusEl = xmlDoc.createElement('Radius');
      radiusEl.textContent = (portion.radius || 1).toString();
      portionDataEl.appendChild(radiusEl);
      const maxOvershootEl = xmlDoc.createElement('MaxOvershoot');
      maxOvershootEl.textContent =
        portion && portion.maxOvershoot ? portion.maxOvershoot.toString() : defaultMaxOvershoot;
      portionDataEl.appendChild(maxOvershootEl);
      const startPointOffsetEl = xmlDoc.createElement('StartPointOffset');
      startPointOffsetEl.textContent = '0';
      portionDataEl.appendChild(startPointOffsetEl);
      const allPointsEl = xmlDoc.createElement('AllPoints');
      const kindEl3 = xmlDoc.createElement('Kind');
      kindEl3.textContent = 'PortionDataPoints';
      allPointsEl.appendChild(kindEl3);
      const idEl3 = xmlDoc.createElement('Id');
      idEl3.textContent = portion.id.toString();
      allPointsEl.appendChild(idEl3);
      const boundingBoxInUnitEl = xmlDoc.createElement('BoundingBoxInUnit');
      boundingBoxInUnitEl.textContent = `${portion.points[0][0]};${portion.points[0][1]};${
        portion.points[portion.points.length - 1][0]
      };${portion.points[portion.points.length - 1][1]}`;
      allPointsEl.appendChild(boundingBoxInUnitEl);
      if (portion.isTurn) {
        const validationEl = xmlDoc.createElement('Validation');
        const criteriumEl = xmlDoc.createElement('Criterium');
        criteriumEl.textContent = 'OptimizeKiwiByRadius';
        validationEl.appendChild(criteriumEl);
        allPointsEl.appendChild(validationEl);
      }

      const pointCollectionInUnitEl = xmlDoc.createElement('PointCollectionInUnit');
      pointCollectionInUnitEl.textContent = `${portion.points[0][0]};${portion.points[0][1]} ${
        portion.points[portion.points.length - 1][0]
      };${portion.points[portion.points.length - 1][1]}`;
      pointCollectionInUnitEl.textContent = portion.points
        .map((point) => {
          let pt = [...point];
          pt[2] *= UNIT_COUNT_PER_METER / 100;
          pt = pt.map((val) => Math.round(val));

          return pt.join(';');
        })
        .join(' ');
      allPointsEl.appendChild(pointCollectionInUnitEl);
      const deltaX = portion.points[portion.points.length - 1][0] - portion.points[0][0];
      const deltaY = portion.points[portion.points.length - 1][1] - portion.points[0][1];
      const pointCollectionDerivedInUnitEl = xmlDoc.createElement('PointCollectionDerivedInUnit');
      if (!portion.isTurn) pointCollectionDerivedInUnitEl.textContent = `${deltaX};${deltaY} ${deltaX};${deltaY}`;
      allPointsEl.appendChild(pointCollectionDerivedInUnitEl);

      portionDataEl.appendChild(allPointsEl);
      const listOfIndexOfForceRuleEl = xmlDoc.createElement('ListOfIndexOfForceRule');
      let portionsKiwi: PortionInterface[] = [];
      if (portion.segmentOriginId) {
        portionsKiwi = layer.portions.filter((p) => p.segmentOriginId === portion.segmentOriginId);
      } else if (portion.isTurn) {
        portionsKiwi = layer.portions.filter((p) => p.id === portion.id);
      }

      portionsKiwi.forEach((portionKiwi) => {
        if (portionKiwi.trafficType && portionKiwi.trafficType !== 'auto') {
          let segment: CircuitSegment | undefined = undefined;
          const isPortionASegment = !!portionKiwi.segmentOriginId;

          if (isPortionASegment) {
            segment = (segments || []).find((seg) =>
              seg.id && mapIds[seg.id] ? mapIds[seg.id] === portionKiwi.segmentOriginId : false
            );
            if (!segment) {
              // eslint-disable-next-line no-console
              console.warn(
                `No segment found when looking for the segment '${portionKiwi.segmentOriginId}' (export xml id)`
              );

              return;
            }
          }

          const indexPortionInSegment = segment
            ? segment.properties.portions.findIndex((p) => (mapIds[p.id] ? mapIds[p.id] === portionKiwi.id : false))
            : 0;
          if (indexPortionInSegment < 0) {
            // eslint-disable-next-line no-console
            console.warn(
              `No portion found when looking for the portion '${portionKiwi.id}' in the segment '${portionKiwi.segmentOriginId}' (export xml id)`
            );

            return;
          }

          const portionForceRuleTupleEl = xmlDoc.createElement('PortionForceRuleTuple');
          const ruleEl = xmlDoc.createElement('Rule');
          const kiwiTrafficType = trafficTypeMap[portionKiwi.trafficType] ?? 'Kernel';
          ruleEl.textContent = kiwiTrafficType;
          portionForceRuleTupleEl.appendChild(ruleEl);
          const portionIdOwnerEl = xmlDoc.createElement('PortionIdOwner');
          portionIdOwnerEl.textContent = (portionKiwi.segmentOriginId || 0).toString();
          portionForceRuleTupleEl.appendChild(portionIdOwnerEl);
          const startPointEl = xmlDoc.createElement('StartPoint');
          let xyEl = xmlDoc.createElement('XY');
          xyEl.textContent = `${portionKiwi.points[0][0]};${portionKiwi.points[0][1]}`;
          startPointEl.appendChild(xyEl);
          portionForceRuleTupleEl.appendChild(startPointEl);
          const endPointEl = xmlDoc.createElement('EndPoint');
          xyEl = xmlDoc.createElement('XY');
          xyEl.textContent = `${portionKiwi.points[1][0]};${portionKiwi.points[1][1]}`;
          endPointEl.appendChild(xyEl);
          portionForceRuleTupleEl.appendChild(endPointEl);
          const indexEl = xmlDoc.createElement('Index');
          indexEl.textContent = indexPortionInSegment.toString();
          portionForceRuleTupleEl.appendChild(indexEl);
          listOfIndexOfForceRuleEl.appendChild(portionForceRuleTupleEl);
        }
      });

      portionDataEl.appendChild(listOfIndexOfForceRuleEl);
      const turnOptionFlagEl = xmlDoc.createElement('TurnOptionFlag');
      turnOptionFlagEl.textContent = portion.stopBeforeTurn ? 'StopBeforeTurn' : 'Normal';
      portionDataEl.appendChild(turnOptionFlagEl);

      listOfPortionDataEl.appendChild(portionDataEl);
    });
    layerEl.appendChild(listOfPortionDataEl);

    const listOfZoneDataEl = xmlDoc.createElement('ListOfZoneData');
    layer.zones.forEach((zone) => {
      const zoneDataEl = xmlDoc.createElement('ZoneData');
      const kindEl2 = xmlDoc.createElement('Kind');
      kindEl2.textContent = 'Zone';
      zoneDataEl.appendChild(kindEl2);
      const idEl2 = xmlDoc.createElement('Id');
      idEl2.textContent = zone.id.toString();
      zoneDataEl.appendChild(idEl2);
      const boundingBoxInUnitEl2 = xmlDoc.createElement('BoundingBoxInUnit');
      boundingBoxInUnitEl2.textContent = `${zone.polygon[0][0]};${zone.polygon[0][1]};${
        zone.polygon[zone.polygon.length - 1][0]
      };${zone.polygon[zone.polygon.length - 1][1]}`;
      zoneDataEl.appendChild(boundingBoxInUnitEl2);
      const zOrderEl2 = xmlDoc.createElement('ZOrder');
      zOrderEl2.textContent = '0';
      zoneDataEl.appendChild(zOrderEl2);
      if (zone.locked) {
        const lockedEl = xmlDoc.createElement('IsLocked');
        lockedEl.textContent = 'true';
        zoneDataEl.appendChild(lockedEl);
      }

      const isMouseAllowedToMoveEl3 = xmlDoc.createElement('IsMouseAllowedToMove');
      isMouseAllowedToMoveEl3.textContent = 'true';
      zoneDataEl.appendChild(isMouseAllowedToMoveEl3);
      const titleEl2 = xmlDoc.createElement('Title');
      titleEl2.textContent = zone.title;
      zoneDataEl.appendChild(titleEl2);
      const balyoTitleEl2 = xmlDoc.createElement('BalyoTitle');
      balyoTitleEl2.textContent = zone.id.toString();
      zoneDataEl.appendChild(balyoTitleEl2);
      const polygonInUnitEl = xmlDoc.createElement('PolygonInUnit');
      polygonInUnitEl.textContent = zone.polygon.map((coord) => coord.join(';')).join(' ');
      zoneDataEl.appendChild(polygonInUnitEl);
      const listOfRulesEl = xmlDoc.createElement('ListOfRules');
      listOfRulesEl.textContent = zone.listOfRules.join(' ');
      zoneDataEl.appendChild(listOfRulesEl);
      const textPosEl = xmlDoc.createElement('TextPos');
      textPosEl.textContent = 'Center';
      zoneDataEl.appendChild(textPosEl);
      const zoneProjectionEl = xmlDoc.createElement('ZoneProjection');
      zoneProjectionEl.textContent = zone.projection;
      zoneDataEl.appendChild(zoneProjectionEl);

      listOfZoneDataEl.appendChild(zoneDataEl);
    });
    layerEl.appendChild(listOfZoneDataEl);
    const listOfZoneStockDataEl = xmlDoc.createElement('ListOfZoneStockData');
    layer.stockZones.forEach((stockZone) => {
      const zoneStockDataEl = xmlDoc.createElement('ZoneStockData');
      const kindEl = xmlDoc.createElement('Kind');
      kindEl.textContent = 'ZoneStock';
      zoneStockDataEl.appendChild(kindEl);
      const idEl = xmlDoc.createElement('Id');
      idEl.textContent = stockZone.id.toString();
      zoneStockDataEl.appendChild(idEl);
      const boundingBoxInUnitEl = xmlDoc.createElement('BoundingBoxInUnit');
      boundingBoxInUnitEl.textContent = `${stockZone.polygon[0][0]};${-stockZone.polygon[0][1]};${
        stockZone.polygon[stockZone.polygon.length - 1][0]
      };${-stockZone.polygon[stockZone.polygon.length - 1][1]}`;
      zoneStockDataEl.appendChild(boundingBoxInUnitEl);
      const zOrderEl = xmlDoc.createElement('ZOrder');
      zOrderEl.textContent = '0';
      zoneStockDataEl.appendChild(zOrderEl);
      const isLockedEl = xmlDoc.createElement('IsLocked');
      isLockedEl.textContent = 'true';
      zoneStockDataEl.appendChild(isLockedEl);
      const isMouseAllowedToMoveEl = xmlDoc.createElement('IsMouseAllowedToMove');
      isMouseAllowedToMoveEl.textContent = 'true';
      zoneStockDataEl.appendChild(isMouseAllowedToMoveEl);
      const titleEl = xmlDoc.createElement('Title');
      titleEl.textContent = stockZone.title;
      zoneStockDataEl.appendChild(titleEl);
      const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
      balyoTitleEl.textContent = stockZone.id.toString();
      zoneStockDataEl.appendChild(balyoTitleEl);
      const polygonInUnitEl = xmlDoc.createElement('PolygonInUnit');
      polygonInUnitEl.textContent = stockZone.polygon.map((coord) => coord.join(';')).join(' ');
      zoneStockDataEl.appendChild(polygonInUnitEl);
      if (Math.abs(stockZone.cap) > 0.001) {
        const angleInDegreeEl = xmlDoc.createElement('AngleInDegree');
        angleInDegreeEl.textContent = humanize(stockZone.cap);
        zoneStockDataEl.appendChild(angleInDegreeEl);
      }

      const fillingBackgroundRuleEl = xmlDoc.createElement('FillingBackgroundRule');
      fillingBackgroundRuleEl.textContent = 'None';
      zoneStockDataEl.appendChild(fillingBackgroundRuleEl);
      const textPosEl = xmlDoc.createElement('TextPos');
      textPosEl.textContent = 'Center';
      zoneStockDataEl.appendChild(textPosEl);
      const listOfOffsetEl = xmlDoc.createElement('ListOfOffset');
      stockZone.stockLines.forEach((stockLine) => {
        const longEl = xmlDoc.createElement('long');
        longEl.textContent = (stockLine.offsetY * UNIT_COUNT_PER_METER).toString();
        listOfOffsetEl.appendChild(longEl);
      });
      zoneStockDataEl.appendChild(listOfOffsetEl);

      const lengthOfPalletInUnitEl = xmlDoc.createElement('LengthOfPalletInUnit');
      lengthOfPalletInUnitEl.textContent = (stockZone.palletLength * UNIT_COUNT_PER_METER).toString();
      const widthOfPalletInUnitEl = xmlDoc.createElement('WidthOfPalletInUnit');
      widthOfPalletInUnitEl.textContent = (stockZone.palletWidth * UNIT_COUNT_PER_METER).toString();
      const heightTakenPalletEl = xmlDoc.createElement('HeightTakenPallet');
      heightTakenPalletEl.textContent = (stockZone.palletHeight * UNIT_COUNT_PER_METER).toString();
      const spaceBetweenPalletInUnitEl = xmlDoc.createElement('SpaceBetweenPalletsInUnit');
      spaceBetweenPalletInUnitEl.textContent = (stockZone.spaceBetweenPallet * UNIT_COUNT_PER_METER).toString();

      const listOfStockLineDataEl = xmlDoc.createElement('ListOfStockLineData');
      stockZone.stockLines.forEach((stockLine) => {
        const stockLineData = xmlDoc.createElement('StockLineData');
        const kindEl2 = xmlDoc.createElement('Kind');
        kindEl2.textContent = 'StockLine';
        stockLineData.appendChild(kindEl2);
        const idEl2 = xmlDoc.createElement('Id');
        idEl2.textContent = stockLine.id.toString();
        stockLineData.appendChild(idEl2);
        const zOrderEl2 = xmlDoc.createElement('ZOrder');
        zOrderEl2.textContent = '0';
        stockLineData.appendChild(zOrderEl2);
        const isMouseAllowedToMove2 = xmlDoc.createElement('IsMouseAllowedToMove');
        isMouseAllowedToMove2.textContent = 'true';
        stockLineData.appendChild(isMouseAllowedToMove2);
        const titleEl2 = xmlDoc.createElement('Title');
        titleEl2.textContent = stockLine.name;
        stockLineData.appendChild(titleEl2);
        const balyoTitleEl2 = xmlDoc.createElement('BalyoTitle');
        balyoTitleEl2.textContent = stockLine.name;
        stockLineData.appendChild(balyoTitleEl2);
        const idZoneStockEl = xmlDoc.createElement('IdZoneStock');
        idZoneStockEl.textContent = stockZone.id.toString();
        stockLineData.appendChild(idZoneStockEl);
        stockLineData.appendChild(heightTakenPalletEl);
        const numberOfPalletEl = xmlDoc.createElement('NumberOfPallet');
        numberOfPalletEl.textContent = stockLine.numberOfPallets.toString();
        stockLineData.appendChild(numberOfPalletEl.cloneNode(true));
        stockLineData.appendChild(lengthOfPalletInUnitEl.cloneNode(true));
        stockLineData.appendChild(widthOfPalletInUnitEl.cloneNode(true));
        const endOffsetInUnitEl = xmlDoc.createElement('EndOffsetInUnit');
        endOffsetInUnitEl.textContent = '0';
        stockLineData.appendChild(endOffsetInUnitEl);
        const portionDataId = xmlDoc.createElement('PortionDataId');
        portionDataId.textContent = stockLine.portionThroughId.toString();
        stockLineData.appendChild(portionDataId);
        const isEnabledEl = xmlDoc.createElement('IsEnabled');
        isEnabledEl.textContent = 'true';
        stockLineData.appendChild(isEnabledEl);

        stockLineData.appendChild(spaceBetweenPalletInUnitEl.cloneNode(true));
        listOfStockLineDataEl.appendChild(stockLineData);
      });
      zoneStockDataEl.appendChild(listOfStockLineDataEl.cloneNode(true));
      zoneStockDataEl.appendChild(widthOfPalletInUnitEl.cloneNode(true));
      zoneStockDataEl.appendChild(lengthOfPalletInUnitEl.cloneNode(true));
      const heightTakenPalletEl2 = xmlDoc.createElement('HeightTakenPalletInUnit');
      heightTakenPalletEl2.textContent = heightTakenPalletEl.textContent;
      zoneStockDataEl.appendChild(heightTakenPalletEl2);
      const flowDirectionEl = xmlDoc.createElement('FlowDirection');
      flowDirectionEl.textContent = 'LeftToRight';
      zoneStockDataEl.appendChild(flowDirectionEl);
      const isStockLineLockedEl = xmlDoc.createElement('IsStockLineLocked');
      isStockLineLockedEl.textContent = 'true';
      zoneStockDataEl.appendChild(isStockLineLockedEl);
      const spaceBetweenStockLinesInUnitEl = xmlDoc.createElement('SpaceBetweenStockLinesInUnit');
      spaceBetweenStockLinesInUnitEl.textContent = '0';
      zoneStockDataEl.appendChild(spaceBetweenStockLinesInUnitEl);
      zoneStockDataEl.appendChild(spaceBetweenPalletInUnitEl);
      const enableScanEl = xmlDoc.createElement('EnableScan');
      enableScanEl.textContent = stockZone.enableScan.toString();
      zoneStockDataEl.appendChild(enableScanEl);
      const numberMaxOfPalletsOnLine = xmlDoc.createElement('NumberMaxOfPalletsOnLine');
      numberMaxOfPalletsOnLine.setAttribute('xsi:nil', 'true');
      zoneStockDataEl.appendChild(numberMaxOfPalletsOnLine);
      const exceededLenghtEl = xmlDoc.createElement('ExceededLenght');
      exceededLenghtEl.textContent = (stockZone.extendedLength * UNIT_COUNT_PER_METER).toString();
      zoneStockDataEl.appendChild(exceededLenghtEl);
      const listOfExtendedPortionEl = xmlDoc.createElement('ListOfExtentedPortion');
      listOfExtendedPortionEl.textContent = stockZone.stockLines
        .map((stockLine) => `${stockZone.id};${stockLine.segmentId};${stockLine.offsetY};${stockLine.portionThroughId}`)
        .join(' ');
      zoneStockDataEl.appendChild(listOfExtendedPortionEl);

      listOfZoneStockDataEl.appendChild(zoneStockDataEl);
    });
    layerEl.appendChild(listOfZoneStockDataEl);

    const listOfZoneRackDataEl = xmlDoc.createElement('ListOfZoneRackData');
    layer.racks.forEach((rack) => {
      const zoneRackDataEl = xmlDoc.createElement('ZoneRackData');

      const kindEl = xmlDoc.createElement('Kind');
      kindEl.textContent = 'ZoneRack';
      zoneRackDataEl.appendChild(kindEl);
      const idEl = xmlDoc.createElement('Id');
      idEl.textContent = rack.id.toString();
      zoneRackDataEl.appendChild(idEl);
      const boundingBoxInUnitEl = xmlDoc.createElement('BoundingBoxInUnit');
      boundingBoxInUnitEl.textContent = `${rack.polygon[0][0]};${rack.polygon[0][1]};${
        rack.polygon[rack.polygon.length - 1][0]
      };${rack.polygon[rack.polygon.length - 1][1]}`;
      zoneRackDataEl.appendChild(boundingBoxInUnitEl);
      const zOrderEl = xmlDoc.createElement('ZOrder');
      zOrderEl.textContent = '0';
      zoneRackDataEl.appendChild(zOrderEl);
      if (rack.locked) {
        const isLockedEl = xmlDoc.createElement('IsLocked');
        isLockedEl.textContent = 'true';
        zoneRackDataEl.appendChild(isLockedEl);
      }

      const isMouseAllowedToMoveEl = xmlDoc.createElement('IsMouseAllowedToMove');
      isMouseAllowedToMoveEl.textContent = 'true';
      zoneRackDataEl.appendChild(isMouseAllowedToMoveEl);
      const titleEl = xmlDoc.createElement('Title');
      titleEl.textContent = rack.title;
      zoneRackDataEl.appendChild(titleEl);
      const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
      balyoTitleEl.textContent = rack.title;
      zoneRackDataEl.appendChild(balyoTitleEl);
      const polygonInUnitEl = xmlDoc.createElement('PolygonInUnit');
      polygonInUnitEl.textContent = rack.polygon.map((coord) => coord.join(';')).join(' ');
      zoneRackDataEl.appendChild(polygonInUnitEl);
      const angleInDegreeEl = xmlDoc.createElement('AngleInDegree');
      angleInDegreeEl.textContent = rack.angleInDegree.toString();
      zoneRackDataEl.appendChild(angleInDegreeEl);
      const flowDirectionEl = xmlDoc.createElement('FlowDirection');
      flowDirectionEl.textContent = rack.flowDirection;
      zoneRackDataEl.appendChild(flowDirectionEl);
      const listOfAccessEl = xmlDoc.createElement('ListOfAccess');
      listOfAccessEl.textContent = rack.listOfAccessInfo
        .map(
          (access, index) => `${rack.id};${access[0]};1;${(access[3] * UNIT_COUNT_PER_METER).toFixed(0)};${access[2]}`
        )
        .join(' ');
      zoneRackDataEl.appendChild(listOfAccessEl);
      const listOfExtentedPortionEl = xmlDoc.createElement('ListOfExtentedPortion');
      listOfExtentedPortionEl.textContent = rack.listOfAccessInfo
        .map(
          (access, index) => `${rack.id};${access[1]};1;${(access[3] * UNIT_COUNT_PER_METER).toFixed(0)};${access[2]}`
        )
        .join(' ');
      zoneRackDataEl.appendChild(listOfExtentedPortionEl);

      const listOfZoneBaseDataEl = xmlDoc.createElement('ListOfZoneBaseData');
      rack.listOfRackData.forEach((rackData) => {
        const zoneBaseDataEl = xmlDoc.createElement('ZoneBaseData');
        zoneBaseDataEl.setAttribute('xsi:type', rackData.cellData ? 'RackColumnData' : 'RackUprightData');

        const kindEl = xmlDoc.createElement('Kind');
        kindEl.textContent = rackData.cellData ? 'RackColumn' : 'RackUpright';
        zoneBaseDataEl.appendChild(kindEl);

        const idEl = xmlDoc.createElement('Id');
        idEl.textContent = rackData.id.toString();
        zoneBaseDataEl.appendChild(idEl);

        if (!rackData.cellData) {
          const boundingBoxInUnitEl = xmlDoc.createElement('BoundingBoxInUnit');
          boundingBoxInUnitEl.textContent = `${rackData.polygon[0][0]};${rackData.polygon[0][1]};${
            rackData.polygon[rackData.polygon.length - 1][0]
          };${rackData.polygon[rackData.polygon.length - 1][1]}`;
          zoneBaseDataEl.appendChild(boundingBoxInUnitEl);
        }

        const zOrderEl = xmlDoc.createElement('ZOrder');
        zOrderEl.textContent = '0';
        zoneBaseDataEl.appendChild(zOrderEl);

        const isMouseAllowedToMoveEl = xmlDoc.createElement('IsMouseAllowedToMove');
        isMouseAllowedToMoveEl.textContent = 'true';
        zoneBaseDataEl.appendChild(isMouseAllowedToMoveEl);

        const titleEl = xmlDoc.createElement('Title');
        titleEl.textContent = rackData.id.toString();
        zoneBaseDataEl.appendChild(titleEl);

        const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
        balyoTitleEl.textContent = rackData.id.toString();
        zoneBaseDataEl.appendChild(balyoTitleEl);

        const polygonInUnitEl = xmlDoc.createElement('PolygonInUnit');
        if (!rackData.cellData) {
          polygonInUnitEl.textContent = rackData.polygon.map((coord) => coord.join(';')).join(' ');
        }

        zoneBaseDataEl.appendChild(polygonInUnitEl);

        if (rackData.cellData) {
          const listOfCellDataEl = xmlDoc.createElement('ListOfCellData');
          rackData.cellData.forEach((cellData, indexCellData) => {
            const cellDataSample = cellData.find((cell) => cell); // first active cell from the floor (for general properties)
            if (!cellDataSample) return;

            const rackCellDataEl = xmlDoc.createElement('RackCellData');

            const kindEl = xmlDoc.createElement('Kind');
            kindEl.textContent = 'RackCell';
            rackCellDataEl.appendChild(kindEl);

            const idEl = xmlDoc.createElement('Id');
            idEl.textContent = cellDataSample.id.toString();
            rackCellDataEl.appendChild(idEl);

            const boundingBoxInUnitEl = xmlDoc.createElement('BoundingBoxInUnit');
            boundingBoxInUnitEl.textContent =
              '9223372036854775807;-9223372036854775808;-9223372036854775808;9223372036854775807';
            rackCellDataEl.appendChild(boundingBoxInUnitEl);

            const zOrderEl = xmlDoc.createElement('ZOrder');
            zOrderEl.textContent = '0';
            rackCellDataEl.appendChild(zOrderEl);

            const isMouseAllowedToMoveEl = xmlDoc.createElement('IsMouseAllowedToMove');
            isMouseAllowedToMoveEl.textContent = 'true';
            rackCellDataEl.appendChild(isMouseAllowedToMoveEl);

            const titleEl = xmlDoc.createElement('Title');
            titleEl.textContent = indexCellData.toString();
            rackCellDataEl.appendChild(titleEl);

            const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
            balyoTitleEl.textContent = `0:${indexCellData.toString()}`;
            rackCellDataEl.appendChild(balyoTitleEl);

            const polygonInUnitEl = xmlDoc.createElement('PolygonInUnit');
            rackCellDataEl.appendChild(polygonInUnitEl);

            const numberOfPalletOnDepthEl = xmlDoc.createElement('NumberOfPalletOnDepth');
            numberOfPalletOnDepthEl.textContent = cellDataSample.numberOfPalletOnDepth.toString();
            rackCellDataEl.appendChild(numberOfPalletOnDepthEl);

            const beamThicknessEl = xmlDoc.createElement('BeamThickness');
            beamThicknessEl.textContent = (cellDataSample.beam.thickness * UNIT_COUNT_PER_METER).toFixed(0);
            rackCellDataEl.appendChild(beamThicknessEl);

            const beamDistanceToRackFrontEl = xmlDoc.createElement('BeamDistanceToRackFront');
            beamDistanceToRackFrontEl.textContent = (
              cellDataSample.beam.distanceToRackFront * UNIT_COUNT_PER_METER
            ).toFixed(0);
            rackCellDataEl.appendChild(beamDistanceToRackFrontEl);

            const columnIdEl = xmlDoc.createElement('ColumnId');
            columnIdEl.textContent = cellDataSample.columnId.toString();
            rackCellDataEl.appendChild(columnIdEl);

            const frontRect = cellDataSample.frontRect;

            const frontRectEl = xmlDoc.createElement('FrontRect');
            frontRectEl.textContent = frontRectEl.textContent = `${(frontRect.left * UNIT_COUNT_PER_METER).toFixed(
              0
            )};${(frontRect.top * UNIT_COUNT_PER_METER).toFixed(0)};${(frontRect.right * UNIT_COUNT_PER_METER).toFixed(
              0
            )};${(frontRect.bottom * UNIT_COUNT_PER_METER).toFixed(0)}`;
            rackCellDataEl.appendChild(frontRectEl);

            const approachDistanceEl = xmlDoc.createElement('ApproachDistance');
            approachDistanceEl.textContent = (cellDataSample.approachDistance * UNIT_COUNT_PER_METER).toString();
            rackCellDataEl.appendChild(approachDistanceEl);

            const footProtectionOverflowEl = xmlDoc.createElement('FootProtectionOverflow');
            footProtectionOverflowEl.textContent = (
              cellDataSample.footProtectionOverflow * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(footProtectionOverflowEl);

            const dicoCellPatternToListOfPalletSerializableEl = xmlDoc.createElement(
              'DicoCellPatternToListOfPalletSerializable'
            );
            cellData.forEach((cellPattern, cellPatternIndex) => {
              if (!cellPattern) return;

              const serializeableKeyValueOfCellPatternObservableCollectionWithRangeOfRackPalletDataEl =
                xmlDoc.createElement('SerializeableKeyValueOfCellPatternObservableCollectionWithRangeOfRackPalletData');

              const keyEl = xmlDoc.createElement('Key');
              const numberOfPalletEl = xmlDoc.createElement('NumberOfPallet');
              numberOfPalletEl.textContent = cellPattern.numberOfPalletOnWidth.toString();
              keyEl.appendChild(numberOfPalletEl);

              const palletWidthEl = xmlDoc.createElement('PalletWidth');
              palletWidthEl.textContent = (cellPattern.pallet.width * UNIT_COUNT_PER_METER).toFixed(0);
              keyEl.appendChild(palletWidthEl);

              const palletLengthEl = xmlDoc.createElement('PalletLength');
              palletLengthEl.textContent = (cellPattern.pallet.length * UNIT_COUNT_PER_METER).toFixed(0);
              keyEl.appendChild(palletLengthEl);

              const palletOverflowEl = xmlDoc.createElement('PalletOverflow');
              palletOverflowEl.textContent = (cellPattern.pallet.overflow * UNIT_COUNT_PER_METER).toFixed(0);
              keyEl.appendChild(palletOverflowEl);

              const nameEl = xmlDoc.createElement('Name');
              nameEl.textContent = cellPattern.loadName;
              keyEl.appendChild(nameEl);

              const valueEl = xmlDoc.createElement('Value');

              cellPattern.slots.forEach((slot, slotIndex) => {
                const rackPalletDataEl = xmlDoc.createElement('RackPalletData');
                const idEl = xmlDoc.createElement('Id');
                idEl.textContent = cellPattern.id.toString();
                rackPalletDataEl.appendChild(idEl);

                const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
                balyoTitleEl.textContent = slot.title;
                rackPalletDataEl.appendChild(balyoTitleEl);

                const accessInfoIdEl = xmlDoc.createElement('AccessInfoId');
                accessInfoIdEl.textContent = slot.id.toString();
                rackPalletDataEl.appendChild(accessInfoIdEl);

                const freespaceDetectionEl = xmlDoc.createElement('FreespaceDetection');
                freespaceDetectionEl.textContent = slot.freespaceDetection;
                rackPalletDataEl.appendChild(freespaceDetectionEl);

                const leftMinGapEl = xmlDoc.createElement('LeftMinGap');
                leftMinGapEl.textContent = slot.leftMinGap.toFixed(0);
                rackPalletDataEl.appendChild(leftMinGapEl);

                const rightMinGapEl = xmlDoc.createElement('RightMinGap');
                rightMinGapEl.textContent = slot.rightMinGap.toFixed(0);
                rackPalletDataEl.appendChild(rightMinGapEl);

                if (!slot.active) {
                  const isEnabledEl = xmlDoc.createElement('IsEnabled');
                  isEnabledEl.textContent = 'false';
                  rackPalletDataEl.appendChild(isEnabledEl);
                }

                valueEl.appendChild(rackPalletDataEl);
              });

              serializeableKeyValueOfCellPatternObservableCollectionWithRangeOfRackPalletDataEl.appendChild(keyEl);
              serializeableKeyValueOfCellPatternObservableCollectionWithRangeOfRackPalletDataEl.appendChild(valueEl);

              dicoCellPatternToListOfPalletSerializableEl.appendChild(
                serializeableKeyValueOfCellPatternObservableCollectionWithRangeOfRackPalletDataEl
              );
            });
            rackCellDataEl.appendChild(dicoCellPatternToListOfPalletSerializableEl);

            const dicoCellPatternToPalletIntervalSerializableEl = xmlDoc.createElement(
              'DicoCellPatternToPalletIntervalSerializable'
            );
            cellData.forEach((cellPattern, cellPatternIndex) => {
              if (!cellPattern) return;

              const serializeableKeyValueOfCellPatternPalletIntervalsEl = xmlDoc.createElement(
                'SerializeableKeyValueOfCellPatternPalletIntervals'
              );

              const keyEl = xmlDoc.createElement('Key');

              const numberOfPalletEl = xmlDoc.createElement('NumberOfPallet');
              numberOfPalletEl.textContent = cellPattern.numberOfPalletOnWidth.toString();
              keyEl.appendChild(numberOfPalletEl);

              const palletWidthEl = xmlDoc.createElement('PalletWidth');
              palletWidthEl.textContent = (cellPattern.pallet.width * UNIT_COUNT_PER_METER).toFixed(0);
              keyEl.appendChild(palletWidthEl);

              const palletLengthEl = xmlDoc.createElement('PalletLength');
              palletLengthEl.textContent = (cellPattern.pallet.length * UNIT_COUNT_PER_METER).toFixed(0);
              keyEl.appendChild(palletLengthEl);

              const palletOveflowEl = xmlDoc.createElement('PalletOveflow');
              palletOveflowEl.textContent = (cellPattern.pallet.overflow * UNIT_COUNT_PER_METER).toFixed(0);
              keyEl.appendChild(palletOveflowEl);

              const nameEl = xmlDoc.createElement('Name');
              nameEl.textContent = cellPattern.loadName;
              keyEl.appendChild(nameEl);

              const valueEl = xmlDoc.createElement('Value');

              const distancePalletToPalletEl = xmlDoc.createElement('DistancePalletToPallet');
              distancePalletToPalletEl.textContent = (
                cellPattern.distancePalletToPallet * UNIT_COUNT_PER_METER
              ).toFixed(0);
              valueEl.appendChild(distancePalletToPalletEl);

              const distanceUprightToPalletEl = xmlDoc.createElement('DistanceUprighToPallet');
              distanceUprightToPalletEl.textContent = (cellPattern.distancePoleToPallet * UNIT_COUNT_PER_METER).toFixed(
                0
              );
              valueEl.appendChild(distanceUprightToPalletEl);

              const leftOffsetEl = xmlDoc.createElement('LeftOffset');
              leftOffsetEl.textContent = '0';
              valueEl.appendChild(leftOffsetEl);

              serializeableKeyValueOfCellPatternPalletIntervalsEl.appendChild(keyEl);
              serializeableKeyValueOfCellPatternPalletIntervalsEl.appendChild(valueEl);

              dicoCellPatternToPalletIntervalSerializableEl.appendChild(
                serializeableKeyValueOfCellPatternPalletIntervalsEl
              );
            });

            const pallet3DEl = xmlDoc.createElement('Pallet3D');
            pallet3DEl.textContent = cellDataSample.detectionSettings.pallet3D.toString();
            rackCellDataEl.appendChild(pallet3DEl);

            const beam3DEl = xmlDoc.createElement('Beam3D');
            beam3DEl.textContent = cellDataSample.detectionSettings.beam3D.toString();
            rackCellDataEl.appendChild(beam3DEl);

            const freeSpace3DEl = xmlDoc.createElement('Freespace3D');
            freeSpace3DEl.textContent = cellDataSample.detectionSettings.freeSpace3D.toString();
            rackCellDataEl.appendChild(freeSpace3DEl);

            const telemeterEl = xmlDoc.createElement('Telemeter');
            telemeterEl.textContent = cellDataSample.detectionSettings.telemeter.toString();
            rackCellDataEl.appendChild(telemeterEl);

            const sidePalletMarginEl = xmlDoc.createElement('SidePalletMargin');
            sidePalletMarginEl.textContent = (
              cellDataSample.detectionSettings.sidePalletMargin * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(sidePalletMarginEl);

            const depthPalletMarginEl = xmlDoc.createElement('DepthPalletMargin');
            depthPalletMarginEl.textContent = (
              cellDataSample.detectionSettings.depthPalletMargin * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(depthPalletMarginEl);

            const underBeamMarginEl = xmlDoc.createElement('UnderBeamMargin');
            underBeamMarginEl.textContent = (
              cellDataSample.detectionSettings.underBeamMargin * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(underBeamMarginEl);

            const beamUpMarginObstacleEl = xmlDoc.createElement('BeamUpMarginObstacle');
            beamUpMarginObstacleEl.textContent = (
              cellDataSample.detectionSettings.beamUpMarginObstacle * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(beamUpMarginObstacleEl);

            const dropHeightAdjustEl = xmlDoc.createElement('DropHeightAdjust');
            dropHeightAdjustEl.textContent = (
              cellDataSample.detectionSettings.dropHeightAdjust * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(dropHeightAdjustEl);

            const pickHeightAdjustEl = xmlDoc.createElement('PickHeightAdjust');
            pickHeightAdjustEl.textContent = (
              cellDataSample.detectionSettings.pickHeightAdjust * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(pickHeightAdjustEl);

            const leftDropUprightAdjustEl = xmlDoc.createElement('LeftDropUprightAdjust');
            leftDropUprightAdjustEl.textContent = (
              cellDataSample.detectionSettings.leftDropUprightAdjust * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(leftDropUprightAdjustEl);

            const rightDropUprightAdjustEl = xmlDoc.createElement('RightDropUprightAdjust');
            rightDropUprightAdjustEl.textContent = (
              cellDataSample.detectionSettings.rightDropUprightAdjust * UNIT_COUNT_PER_METER
            ).toString();
            rackCellDataEl.appendChild(rightDropUprightAdjustEl);

            listOfCellDataEl.appendChild(rackCellDataEl);
          });
          zoneBaseDataEl.appendChild(listOfCellDataEl);

          const totalHeightEl = xmlDoc.createElement('TotalHeight');
          totalHeightEl.textContent = ((rackData.columnHeight ?? 0) * UNIT_COUNT_PER_METER).toFixed(0);
          zoneBaseDataEl.appendChild(totalHeightEl);

          const ownerIdEl = xmlDoc.createElement('OwnerId');
          ownerIdEl.textContent = rack.id.toString();
          zoneBaseDataEl.appendChild(ownerIdEl);
        } else {
          const frontRect = rackData.frontRect ?? { left: 0, top: 0, right: 0, bottom: 0 };

          const frontRectEl = xmlDoc.createElement('FrontRect');
          frontRectEl.textContent = frontRectEl.textContent = `${(frontRect.left * UNIT_COUNT_PER_METER).toFixed(0)};${(
            frontRect.top * UNIT_COUNT_PER_METER
          ).toFixed(0)};${(frontRect.right * UNIT_COUNT_PER_METER).toFixed(0)};${(
            frontRect.bottom * UNIT_COUNT_PER_METER
          ).toFixed(0)}`;
          zoneBaseDataEl.appendChild(frontRectEl);
        }

        listOfZoneBaseDataEl.appendChild(zoneBaseDataEl);
      });
      zoneRackDataEl.appendChild(listOfZoneBaseDataEl);

      const exceededLenghtEl = xmlDoc.createElement('ExceededLenght');
      exceededLenghtEl.textContent = (rack.exceededLenght * UNIT_COUNT_PER_METER).toString();
      zoneRackDataEl.appendChild(exceededLenghtEl);

      const cellPatternsEl = xmlDoc.createElement('CellPatterns');
      // const allLoads = Object.values(cellTemplates).flatMap((cellTemplate) => cellTemplate.loads);
      // allLoads.forEach((load) => {
      //   const cellPatternEl = xmlDoc.createElement('CellPattern');
      //   const numberOfPalletEl = xmlDoc.createElement('NumberOfPallet');
      //   numberOfPalletEl.textContent = load.N.toString();
      //   cellPatternEl.appendChild(numberOfPalletEl);

      //   const palletWidthEl = xmlDoc.createElement('PalletWidth');
      //   palletWidthEl.textContent = (load.W * UNIT_COUNT_PER_METER).toString();
      //   cellPatternEl.appendChild(palletWidthEl);

      // });
      rack.listOfRackData.forEach((rackData) => {
        if (!rackData.cellData) return;

        const cellPatterns: Record<string, CellDataRackCE> = {};
        rackData.cellData
          .flatMap((cellDataArr) => cellDataArr)
          .forEach((cellData) => {
            if (!cellData) return;

            cellPatterns[cellData.loadName] = cellData;
          });

        Object.values(cellPatterns).forEach((cellData) => {
          const cellPatternEl = xmlDoc.createElement('CellPattern');
          const numberOfPalletEl = xmlDoc.createElement('NumberOfPallet');
          numberOfPalletEl.textContent = cellData.numberOfPalletOnWidth.toString();
          cellPatternEl.appendChild(numberOfPalletEl);

          const palletWidthEl = xmlDoc.createElement('PalletWidth');
          palletWidthEl.textContent = (cellData.pallet.width * UNIT_COUNT_PER_METER).toString();
          cellPatternEl.appendChild(palletWidthEl);

          const palletLengthEl = xmlDoc.createElement('PalletLength');
          palletLengthEl.textContent = (cellData.pallet.length * UNIT_COUNT_PER_METER).toString();
          cellPatternEl.appendChild(palletLengthEl);

          const palletOverflowEl = xmlDoc.createElement('PalletOverflow');
          palletOverflowEl.textContent = (cellData.pallet.overflow * UNIT_COUNT_PER_METER).toString();
          cellPatternEl.appendChild(palletOverflowEl);

          const nameEl = xmlDoc.createElement('Name');
          nameEl.textContent = cellData.loadName;
          cellPatternEl.appendChild(nameEl);

          cellPatternsEl.appendChild(cellPatternEl);
        });
      });
      zoneRackDataEl.appendChild(cellPatternsEl);

      listOfZoneRackDataEl.appendChild(zoneRackDataEl);
    });
    layerEl.appendChild(listOfZoneRackDataEl);

    const listOfDestinationPointDataEl = xmlDoc.createElement('ListOfDestinationPointData');
    layer.destinationPoints.forEach((point) => {
      const destinationPointDataEl = xmlDoc.createElement('DestinationPointData');
      const kindEl2 = xmlDoc.createElement('Kind');
      kindEl2.textContent = 'DestinationPoint';
      destinationPointDataEl.appendChild(kindEl2);
      const idEl2 = xmlDoc.createElement('Id');
      idEl2.textContent = point.id.toString();
      destinationPointDataEl.appendChild(idEl2);
      const zOrderEl2 = xmlDoc.createElement('ZOrder');
      zOrderEl2.textContent = '0';
      destinationPointDataEl.appendChild(zOrderEl2);
      if (point.locked) {
        const lockedEl = xmlDoc.createElement('IsLocked');
        lockedEl.textContent = 'true';
        destinationPointDataEl.appendChild(lockedEl);
      }

      const isMouseAllowedToMoveEl3 = xmlDoc.createElement('IsMouseAllowedToMove');
      isMouseAllowedToMoveEl3.textContent = 'true';
      destinationPointDataEl.appendChild(isMouseAllowedToMoveEl3);
      const titleEl2 = xmlDoc.createElement('Title');
      titleEl2.textContent = point.title;
      destinationPointDataEl.appendChild(titleEl2);
      const balyoTitleEl2 = xmlDoc.createElement('BalyoTitle');
      balyoTitleEl2.textContent = point.id.toString();
      destinationPointDataEl.appendChild(balyoTitleEl2);
      const ptInUnitEl = xmlDoc.createElement('PtInUnit');
      ptInUnitEl.textContent = `${coordToCircuit(point.coords[0])};${coordToCircuit(point.coords[1])}`;
      destinationPointDataEl.appendChild(ptInUnitEl);
      if (point.portionDataId && point.percent !== undefined) {
        const portionDataIdEl = xmlDoc.createElement('PortionDataId');
        portionDataIdEl.textContent = point.portionDataId.toString();
        destinationPointDataEl.appendChild(portionDataIdEl);
        const percentEl = xmlDoc.createElement('Percent');
        percentEl.textContent = point.percent.toString();
        destinationPointDataEl.appendChild(percentEl);
      }

      const destinationTypeEl = xmlDoc.createElement('DestinationType');
      destinationTypeEl.textContent = point.type;
      destinationPointDataEl.appendChild(destinationTypeEl);
      const minimalScoreForInitEl = xmlDoc.createElement('MinimalScoreForInit');
      minimalScoreForInitEl.textContent = point.minimalScoreInit.toString();
      destinationPointDataEl.appendChild(minimalScoreForInitEl);

      listOfDestinationPointDataEl.appendChild(destinationPointDataEl);
    });
    layerEl.appendChild(listOfDestinationPointDataEl);
    const listOfGuideDataEl = xmlDoc.createElement('ListOfGuideData');
    layerEl.appendChild(listOfGuideDataEl);
    const listOfMeasurerPointDataEl = xmlDoc.createElement('ListOfMeasurerPointData');
    layer.measurers.forEach((measurer) => {
      measurer.measurerPoints.forEach((measurerPoint) => {
        const controlPointDataEl = xmlDoc.createElement('ControlPointData');

        const kindEl = xmlDoc.createElement('Kind');
        kindEl.textContent = 'MeasurerPoint';
        controlPointDataEl.appendChild(kindEl);
        const idEl = xmlDoc.createElement('Id');
        idEl.textContent = measurerPoint.id.toString();
        controlPointDataEl.appendChild(idEl);
        const zOrderEl = xmlDoc.createElement('ZOrder');
        zOrderEl.textContent = '0';
        controlPointDataEl.appendChild(zOrderEl);
        if (measurer.measurerData.locked) {
          const isLockedEl = xmlDoc.createElement('IsLocked');
          isLockedEl.textContent = 'true';
          controlPointDataEl.appendChild(isLockedEl);
        }

        const isMouseAllowedToMoveEl = xmlDoc.createElement('IsMouseAllowedToMove');
        isMouseAllowedToMoveEl.textContent = 'true';
        controlPointDataEl.appendChild(isMouseAllowedToMoveEl);
        const titleEl = xmlDoc.createElement('Title');
        titleEl.textContent = measurerPoint.id.toString();
        controlPointDataEl.appendChild(titleEl);
        const balyoTitleEl = xmlDoc.createElement('BalyoTitle');
        balyoTitleEl.textContent = measurerPoint.id.toString();
        controlPointDataEl.appendChild(balyoTitleEl);
        const ptInUnitEl = xmlDoc.createElement('PtInUnit');
        ptInUnitEl.textContent = `${measurerPoint.ptInUnit[0]};${measurerPoint.ptInUnit[1]}`;
        controlPointDataEl.appendChild(ptInUnitEl);
        const angleInDegreeEl = xmlDoc.createElement('AngleInDegree');
        angleInDegreeEl.textContent = measurerPoint.angleInDegree.toFixed(4);
        controlPointDataEl.appendChild(angleInDegreeEl);

        listOfMeasurerPointDataEl.appendChild(controlPointDataEl);
      });
    });
    layerEl.appendChild(listOfMeasurerPointDataEl);
    const listOfMeasurerDataEl = xmlDoc.createElement('ListOfMeasurerData');
    layer.measurers.forEach((measurer) => {
      const portion = measurer.measurerData;
      const portionDataEl = xmlDoc.createElement('PortionData');
      const kindEl2 = xmlDoc.createElement('Kind');
      kindEl2.textContent = 'Measurer';
      portionDataEl.appendChild(kindEl2);
      const idEl2 = xmlDoc.createElement('Id');
      idEl2.textContent = portion.id.toString();
      portionDataEl.appendChild(idEl2);
      if (portion.isTurn) {
        const boundingBoxInUnitEl2 = xmlDoc.createElement('BoundingBoxInUnit');
        boundingBoxInUnitEl2.textContent = `${portion.points[0][0]};${portion.points[0][1]};${
          portion.points[portion.points.length - 1][0]
        };${portion.points[portion.points.length - 1][1]}`;
        portionDataEl.appendChild(boundingBoxInUnitEl2);
      }

      const zOrderEl2 = xmlDoc.createElement('ZOrder');
      zOrderEl2.textContent = '0';
      portionDataEl.appendChild(zOrderEl2);
      if (portion.locked) {
        const lockedEl = xmlDoc.createElement('IsLocked');
        lockedEl.textContent = 'true';
        portionDataEl.appendChild(lockedEl);
      }

      const IsMouseAllowedToMoveEl2 = xmlDoc.createElement('IsMouseAllowedToMove');
      IsMouseAllowedToMoveEl2.textContent = 'true';
      portionDataEl.appendChild(IsMouseAllowedToMoveEl2);

      const titleEl2 = xmlDoc.createElement('Title');
      titleEl2.textContent = portion.id.toString();
      portionDataEl.appendChild(titleEl2);
      const balyoTitle2 = xmlDoc.createElement('BalyoTitle');
      balyoTitle2.textContent = portion.id.toString();
      portionDataEl.appendChild(balyoTitle2);
      const leftIdEl = xmlDoc.createElement('LeftId');
      const rightIdEl = xmlDoc.createElement('RightId');
      leftIdEl.textContent = measurer.measurerPoints[0].id.toString();
      rightIdEl.textContent = measurer.measurerPoints[1].id.toString();
      portionDataEl.appendChild(leftIdEl);
      portionDataEl.appendChild(rightIdEl);

      const isRigidEl = xmlDoc.createElement('IsRigid');
      isRigidEl.textContent = 'true';
      portionDataEl.appendChild(isRigidEl);

      const criteriumForValidationEl = xmlDoc.createElement('CriteriumForValidation');
      criteriumForValidationEl.textContent = 'OptimizeSpeed';
      portionDataEl.appendChild(criteriumForValidationEl);
      const radiusEl = xmlDoc.createElement('Radius');
      radiusEl.textContent = '1';
      portionDataEl.appendChild(radiusEl);
      const maxOvershootEl = xmlDoc.createElement('MaxOvershoot');
      maxOvershootEl.textContent = defaultMaxOvershoot;
      portionDataEl.appendChild(maxOvershootEl);
      const startPointOffsetEl = xmlDoc.createElement('StartPointOffset');
      startPointOffsetEl.textContent = '0';
      portionDataEl.appendChild(startPointOffsetEl);
      const allPointsEl = xmlDoc.createElement('AllPoints');
      const kindEl3 = xmlDoc.createElement('Kind');
      kindEl3.textContent = 'PortionDataPoints';
      allPointsEl.appendChild(kindEl3);
      const idEl3 = xmlDoc.createElement('Id');
      idEl3.textContent = portion.id.toString();
      allPointsEl.appendChild(idEl3);
      const boundingBoxInUnitEl = xmlDoc.createElement('BoundingBoxInUnit');
      boundingBoxInUnitEl.textContent = `${portion.points[0][0]};${portion.points[0][1]};${
        portion.points[portion.points.length - 1][0]
      };${portion.points[portion.points.length - 1][1]}`;
      allPointsEl.appendChild(boundingBoxInUnitEl);

      const pointCollectionInUnitEl = xmlDoc.createElement('PointCollectionInUnit');
      pointCollectionInUnitEl.textContent = `${portion.points[0][0]};${portion.points[0][1]} ${
        portion.points[portion.points.length - 1][0]
      };${portion.points[portion.points.length - 1][1]}`;
      pointCollectionInUnitEl.textContent = portion.points
        .map((point) => {
          let pt = [...point];
          pt[2] *= UNIT_COUNT_PER_METER / 100;
          pt = pt.map((val) => Math.round(val));

          return pt.join(';');
        })
        .join(' ');
      allPointsEl.appendChild(pointCollectionInUnitEl);
      const deltaX = portion.points[portion.points.length - 1][0] - portion.points[0][0];
      const deltaY = portion.points[portion.points.length - 1][1] - portion.points[0][1];
      const pointCollectionDerivedInUnitEl = xmlDoc.createElement('PointCollectionDerivedInUnit');
      if (!portion.isTurn) pointCollectionDerivedInUnitEl.textContent = `${deltaX};${deltaY} ${deltaX};${deltaY}`;
      allPointsEl.appendChild(pointCollectionDerivedInUnitEl);

      portionDataEl.appendChild(allPointsEl);
      const listOfIndexOfForceRuleEl = xmlDoc.createElement('ListOfIndexOfForceRule');
      portionDataEl.appendChild(listOfIndexOfForceRuleEl);
      const turnOptionFlagEl = xmlDoc.createElement('TurnOptionFlag');
      turnOptionFlagEl.textContent = 'Normal';
      portionDataEl.appendChild(turnOptionFlagEl);

      listOfMeasurerDataEl.appendChild(portionDataEl);
    });
    layerEl.appendChild(listOfMeasurerDataEl);
    const agvModelEl = xmlDoc.createElement('AgvModel');
    const turretPositionEl = xmlDoc.createElement('TurretPosition');
    turretPositionEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(turretPositionEl);
    const cantonnementPattern = preferencesModel?.[layer.modelName]?.['cantonnement/pattern'] ?? [];

    const cantonementEl = domParser.parseFromString(
      layer.modelName === 'Common' || !cantonnementPattern.length
        ? '<Cantonment />'
        : `<Cantonment>${cantonnementPattern
            .map((strCpx) => strCpx.split('(')[1].replace(')', '').split(','))
            .map((point) => `<Point><X>${point[0].trim()}</X><Y>${point[1].trim()}</Y></Point>`)
            .join('')}</Cantonment>`,
      'application/xml'
    );
    agvModelEl.appendChild(cantonementEl.documentElement);
    const emptyPattern = preferencesModel?.[layer.modelName]?.['safety/pattern/Fempty'] ?? [];

    const emptyEl = domParser.parseFromString(
      layer.modelName === 'Common' || !emptyPattern.length
        ? '<Empty />'
        : `<Empty>${emptyPattern
            .map((strCpx) => strCpx.split('(')[1].replace(')', '').split(','))
            .map((point) => `<Point><X>${point[0].trim()}</X><Y>${point[1].trim()}</Y></Point>`)
            .join('')}</Empty>`,
      'application/xml'
    );
    agvModelEl.appendChild(emptyEl.documentElement);
    const carryPattern = preferencesModel?.[layer.modelName]?.['safety/pattern/Fcarry'] ?? [];

    const carryEl = domParser.parseFromString(
      layer.modelName === 'Common' || !carryPattern.length
        ? '<Carry />'
        : `<Carry>${carryPattern
            .map((strCpx) => strCpx.split('(')[1].replace(')', '').split(','))
            .map((point) => `<Point><X>${point[0].trim()}</X><Y>${point[1].trim()}</Y></Point>`)
            .join('')}</Carry>`,
      'application/xml'
    );
    agvModelEl.appendChild(carryEl.documentElement);
    const shapePattern = preferencesModel?.[layer.modelName]?.['general/shape'] ?? [];

    const shapeEl = domParser.parseFromString(
      layer.modelName === 'Common' || !shapePattern.length
        ? '<Shape />'
        : `<Shape>${shapePattern
            .map((strCpx) => strCpx.split('(')[1].replace(')', '').split(','))
            .map((point) => `<Point><X>${point[0].trim()}</X><Y>${point[1].trim()}</Y></Point>`)
            .join('')}</Shape>`,
      'application/xml'
    );
    agvModelEl.appendChild(shapeEl.documentElement);

    const maxTurretAngle =
      preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/maxTurretAngle'] ?? '';

    const maxTurretAngleInDegreeEl2 = xmlDoc.createElement('MaxTurretAngleInDegree');
    maxTurretAngleInDegreeEl2.textContent = layer.modelName === 'Common' || !maxTurretAngle ? '-90' : maxTurretAngle;
    agvModelEl.appendChild(maxTurretAngleInDegreeEl2);
    const minTurretAngle =
      preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/minTurretAngle'] ?? '';

    const minTurretAngleInDegreeEl2 = xmlDoc.createElement('MinTurretAngleInDegree');
    minTurretAngleInDegreeEl2.textContent = layer.modelName === 'Common' || !minTurretAngle ? '90' : minTurretAngle;
    agvModelEl.appendChild(minTurretAngleInDegreeEl2);
    const maxTurretSpeed =
      preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/maxTurretAngularVelocity'] ?? '';

    const maxTurretSpeedEl = xmlDoc.createElement('MaxTurretSpeed');
    maxTurretSpeedEl.textContent = layer.modelName === 'Common' || !maxTurretSpeed ? '1.8' : maxTurretSpeed;
    agvModelEl.appendChild(maxTurretSpeedEl);
    const maxDistanceToWheels =
      preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/maxDistanceToWheels'] ?? '';

    const maxDistanceToWheelsEl = xmlDoc.createElement('MaxDistanceToWheels');
    if (layer.modelName === 'Common' || !maxDistanceToWheels) maxDistanceToWheelsEl.setAttribute('xsi:nil', 'true');
    else maxDistanceToWheelsEl.textContent = maxDistanceToWheels;
    agvModelEl.appendChild(maxDistanceToWheelsEl);
    const maxVelXEl = xmlDoc.createElement('MaxVelX');
    maxVelXEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(maxVelXEl);
    const maxVelYEl2 = xmlDoc.createElement('MaxVelY');
    maxVelYEl2.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(maxVelYEl2);
    const maxVelZEl = xmlDoc.createElement('MaxVelZ');
    maxVelZEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(maxVelZEl);
    const turretPoseXEl = xmlDoc.createElement('TurretPoseX');
    if (layer.modelName === 'Common') turretPoseXEl.setAttribute('xsi:nil', 'true');
    else {
      const turretPoseX =
        preferencesModel?.[layer.modelName]?.['trajectoryDriver/robotKinematicsModel/turretXPositionForksDown'] ?? '';

      turretPoseXEl.textContent = turretPoseX;
    }

    agvModelEl.appendChild(turretPoseXEl);
    const listOfLaserEl = xmlDoc.createElement('ListOfLaser');
    agvModelEl.appendChild(listOfLaserEl);
    const frontDSlowCarryEl = xmlDoc.createElement('FrontDSlowCarry');
    if (layer.modelName === 'Common') frontDSlowCarryEl.textContent = '0';
    else frontDSlowCarryEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(frontDSlowCarryEl);
    const frontDStopCarryEl = xmlDoc.createElement('FrontDStopCarry');
    if (layer.modelName === 'Common') frontDStopCarryEl.textContent = '0';
    else frontDStopCarryEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(frontDStopCarryEl);
    const rearDSlowCarryEl = xmlDoc.createElement('RearDSlowCarry');
    if (layer.modelName === 'Common') rearDSlowCarryEl.textContent = '0';
    else rearDSlowCarryEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(rearDSlowCarryEl);
    const rearDStopCarryEl = xmlDoc.createElement('RearDStopCarry');
    if (layer.modelName === 'Common') rearDStopCarryEl.textContent = '0';
    else rearDStopCarryEl.setAttribute('xsi:nil', 'true');
    agvModelEl.appendChild(rearDStopCarryEl);
    const agvNameEl = xmlDoc.createElement('AgvName');
    agvNameEl.textContent = layer.modelName;
    agvModelEl.appendChild(agvNameEl);

    layerEl.appendChild(agvModelEl);

    listOfLayerEl.appendChild(layerEl);
  });
  xml.appendChild(listOfLayerEl);

  let projectDataStr = new XMLSerializerXmlDom().serializeToString(xml);
  projectDataStr = prettifyXml(projectDataStr);
  const sha1 = await text2sha1(projectDataStr);
  sha1El.textContent = sha1;
  xmlDoc.documentElement.appendChild(xml);

  let xmlStr = new XMLSerializerXmlDom().serializeToString(xmlDoc);
  xmlStr = xmlStr.replace('</xml>', '');
  xmlStr = xmlStr.replace(
    '<xml>',
    `<?xml version="1.0" encoding="utf-8"?><!-- File generated by Road Editor (v${packageJson.version}) -->`
  );
  xmlStr = xmlStr.replaceAll('/>', ' />');
  if (pretty) {
    xmlStr = prettifyXml(xmlStr);
  }

  return [xmlStr, mapIds, additionalData];
}

export interface CrossRoadInterface {
  id: number;
  listPortionInput: number[];
  listPortionOutput: number[];
  type: 'Start' | 'End';
  angleInDegree: number;
  ptInUnit?: Position;
  segments?: number[];
  curves?: number[];
  exportComputedPoints?: boolean;
}

export interface PortionInterface {
  id: number;
  points: Position[]; // [x, y, cap][]
  isTurn?: boolean;
  stopBeforeTurn?: boolean;
  radius?: number;
  maxOvershoot?: number;
  trafficType?: TrafficType;
  locked: boolean;

  segmentOriginId?: number | null;
  stockZoneId?: number;

  isWireGuided: boolean;

  isExtremity: boolean;
}

/**
 * The portion interface are the portions made for Kiwi
 * They are quite small and split between every turn to make a graph
 * For Circuit Editor, it is the real segments
 */
export interface PortionCE {
  id: number;
  points: Position[]; // [x, y, cap][]
  isTurn?: boolean;
  stopBeforeTurn?: boolean;
  radius?: number;
  maxOvershoot?: number;
  locked: boolean;

  segmentOriginId?: number | null | string;
  stockline?: boolean;
  rack?: boolean;

  isExtendedLength?: boolean;

  isWireGuided?: boolean;

  roadEditorId?: string;
}

export interface DesinationPointInterface {
  id: number;
  portionDataId?: number;
  percent?: number;
  type: string;
  title: string;
  coords: Position;
  minimalScoreInit: number;
  locked: boolean;
  elevatorName?: string;
  floorLevel?: string;
}

export interface ZoneInterface {
  id: number;
  title: string;
  projection: string; // 'Point' | 'Gabarit
  polygon: Position[];
  listOfRules: string[];
  locked: boolean;
  enterCallback?: string;
  exitCallback?: string;
  broadcast?: string;
}

export interface MeasurerInterface {
  measurerPoints: {
    id: number;
    ptInUnit: Position;
    angleInDegree: number;
  }[];
  measurerData: PortionInterface;
}

export interface RackCEInterface {
  id: number;
  title: string;
  locked: boolean;
  angleInDegree: number;
  flowDirection: 'LeftToRight' | 'RightToLeft';
  rackDepth: number;
  exceededLenght: number;
  polygon: Position[];
  /**
   * [0]: id of the rack data (column or upright)
   * [1]: id of the connected portion of the rackdata)
   * [2]: id of the portion that goes through the (slot of the) rack and is connected to the extended length
   * [3]: position of the extended length in x in the rack frame of reference
   * */
  listOfAccessInfo: [number, number, number, number][];
  listOfRackData: {
    type: 'RackUprightExportData' | 'RackColumnExportData';
    id: number;
    polygon: Position[];
    frontRect?: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    };
    columnHeight?: number;
    posInX?: number;
    width?: number;
    rackWidth?: number;
    rackDepth?: number;
    startHeight?: number;

    cellData?: CellDataRackCE[][];
  }[];
}

export interface CellDataRackCE {
  id: number;
  loadName: string;
  cellHeight: number;
  numberOfPalletOnWidth: number;
  numberOfPalletOnDepth: number;
  distancePalletToPallet: number;
  distancePoleToPallet: number;
  columnId: number;
  approachDistance: number;
  footProtectionOverflow: number;
  /** frontRect */
  polygon: Position[];
  frontRect: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  };
  pallet: {
    length: number;
    width: number;
    overflow: number;
  };
  beam: {
    thickness: number;
    distanceToRackFront: number;
  };
  detectionSettings: {
    pallet3D: boolean;
    beam3D: boolean;
    freeSpace3D: boolean;
    telemeter: boolean;
    sidePalletMargin: number;
    depthPalletMargin: number;
    underBeamMargin: number;
    beamUpMarginObstacle: number;
    pickHeightAdjust: number;
    dropHeightAdjust: number;
    leftDropUprightAdjust: number;
    rightDropUprightAdjust: number;
  };
  slots: {
    id: number;
    title: string;
    associatedPortionId: number;
    freespaceDetection:
      | 'LeftReference'
      | 'RightReference'
      | 'MobileLocation'
      | 'Mobile_NoBeam'
      | 'SymmetricalReference'
      | 'Symmetrical_NoBeam';
    leftMinGap: number;
    rightMinGap: number;
    active: boolean;
  }[];
  posInX: number;
  startHeight: number;
  width: number;
}

export interface StockZoneInterface {
  id: number;
  polygon: Position[];
  extendedLength: number;
  title: string;
  enableScan: boolean;
  numberOfPallets: number;
  spaceBetweenPallet: number;
  palletLength: number;
  palletWidth: number;
  palletHeight: number;
  stockLines: {
    id: number;
    segmentId: number;
    portionThroughId: number;
    name: string;
    numberOfPallets: number;
    offsetY: number;
  }[];
  cap: number;
}

export interface LayerInterface {
  id: number;
  modelName: string;
  crossRoads: CrossRoadInterface[];
  portions: PortionInterface[];
  portionsCE: PortionCE[];
  destinationPoints: DesinationPointInterface[];
  zones: ZoneInterface[];
  measurers: MeasurerInterface[];
  stockZones: StockZoneInterface[];
  racks: RackCEInterface[];
}

export interface SimplifiedLayerInterface {
  id: number;
  modelName: string;
}

export function getLayerGroupsParents(
  layersToExport: (LayerInterface | SimplifiedLayerInterface)[],
  layers: LayersDataObject,
  shape: CircuitShape,
  layerGroups: LayerGroupDataObject
): LayerInterface[] {
  if (!layers[shape.properties.layerId]) {
    const layersList = Object.values(layers).map((layer) => `${layer.id} (${layer.name})`);
    throw new Error(
      `Layer ${shape.properties.layerId} not found / shapeId: ${shape.id} / layers: ${layersList.join(
        ', '
      )} / shape: ${JSON.stringify(shape, null, 2)}`
    );
  }

  if (layers[shape.properties.layerId].isDraft) return []; // we don't export the shape if its layer is a draft

  const layerGroupsArr = Object.values(layerGroups);

  const parents = new Set<LayerGroupData>();
  const childrenToCheck = new Set<string>();
  childrenToCheck.add(shape.properties.layerId);

  while (childrenToCheck.size) {
    const childIdToCheck = childrenToCheck.keys().next().value as string;
    childrenToCheck.delete(childIdToCheck);

    layerGroupsArr.forEach((layerGroup) => {
      if (layerGroup.children.includes(childIdToCheck)) {
        parents.add(layerGroup);
        childrenToCheck.add(layerGroup.id);
      }
    });
  }

  const toExport: LayerInterface[] = Array.from(parents)
    .map((layerGroupToExport) => layersToExport.find((layer) => layer.modelName === layerGroupToExport.name))
    .filter((el) => el !== undefined) as LayerInterface[];

  return toExport;
}

export function getLayerGroupCommon(
  layersToExport: LayerInterface[],
  layers: LayersDataObject | undefined,
  shape: CircuitShape | undefined,
  layerGroups: LayerGroupDataObject
): LayerInterface[] {
  const res: LayerInterface[] = [];
  const layerGroupsArr = Object.values(layerGroups);

  for (const layerGroup of layerGroupsArr) {
    if (layerGroup.name === 'Common') {
      const layer = layersToExport.find((layer) => layer.modelName === layerGroup.name);
      if (layer) {
        res.push(layer);

        return res;
      }
    }
  }

  return res;
}

function roadFreespaceToCEFreespace(
  roadFreespace: PerceptionReference
): CellDataRackCE['slots'][0]['freespaceDetection'] {
  switch (roadFreespace) {
    case 'left': {
      return 'LeftReference';
    }

    case 'right': {
      return 'RightReference';
    }

    case 'none': {
      return 'MobileLocation';
    }

    case 'symmetrical': {
      return 'SymmetricalReference';
    }
  }

  // eslint-disable-next-line no-console
  console.warn('roadFreespaceToCEFreespace: unknown roadFreespace type -', roadFreespace);

  return 'MobileLocation';
}

/**
 * Increment the name number
 * @param name the name to increment
 * @returns the incremented name
 */
export function generateNewUniqueName(name: string): string {
  const separator = '-';
  const nameParts = name.split(separator);
  const lastPart = nameParts[nameParts.length - 1];
  const lastPartNumber = parseInt(lastPart, 10);
  if (isNaN(lastPartNumber)) {
    return `${name}${separator}1`;
  }

  return nameParts.slice(0, nameParts.length - 1).join(separator) + `${separator}${lastPartNumber + 1}`;
}

/**
 * Return the point type in XML format
 * https://redmine.balyo.com/issues/42354
 * @param point a circuit point
 * @param exportMultiplePointType whether or not we export multiple point type
 * @returns the point type in string format
 */
function getXmlPointType(point: CircuitPoint, exportMultiplePointType = false): string {
  if (!exportMultiplePointType) {
    // if we don't export multiple point type, we guess the best point type to return
    return point.properties.isInit
      ? 'Init'
      : point.properties.isBattery
        ? 'Charger'
        : point.properties.isTaxi
          ? 'Taxi'
          : 'Normal';
  }

  const pointTypes: string[] = [];
  if (point.properties.isInit) pointTypes.push('Init');
  if (point.properties.isBattery) pointTypes.push('Charger');
  if (point.properties.isTaxi) pointTypes.push('Taxi');
  if (point.properties.isTeleportation) pointTypes.push('Teleportation');

  if (!pointTypes.length) {
    return 'Normal';
  }

  return pointTypes.join(';');
}

Comlink.expose({ generateXMLWorker });
