import { Box } from '@mui/material';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { LayersMenu } from './layers-menu';
import { LayersShortcuts } from './layers-shortcuts';

export const LayersBottomLeft = (): JSX.Element => {
  const showDynamicShortcuts = useAppSelector((state) => state.local.showDynamicShortcuts);

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        left: theme.spacing(1),
        bottom: 0,
        pointerEvents: 'none',
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
        }),
      }}
    >
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'flex-end' }}>
        <LayersMenu />
        {showDynamicShortcuts ? <LayersShortcuts /> : null}
      </Box>
    </Box>
  );
};
