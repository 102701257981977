import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { convertBase } from 'utils/circuit/default-circuit-shapes';
import type { SlotArray } from './../../../models/circuit';
import { isStocksVariableName } from './stock-zone-naming.guard';

export type FormatingType = 'number' | 'letter';
export type FormatingDirectionType = 'ltr' | 'rtl';

const nbMaxCharCellName = 32;

/**
 * The array with all the custom variable names that describes a string
 */
export const stringVariableNames = ['@stockZoneName!'] as const;

/**
 * The array with all the custom variable names that describes a number
 */
export const numberVariableNames = ['@stockLineNumber!'] as const;

export const variablesNames = [...stringVariableNames, ...numberVariableNames] as const;

/**
 * Is it a number variable name?
 *
 * @see {isStringVariableName} ts-auto-guard:type-guard
 */
export type StringVariableName = (typeof stringVariableNames)[number];
/**
 * Is it a string variable name?
 *
 * @see {isNumberVariableName} ts-auto-guard:type-guard
 */
export type NumberVariableName = (typeof numberVariableNames)[number];

/**
 * Is it a variable name?
 *
 * @see {isStocksVariableName} ts-auto-guard:type-guard
 */
export type VariablesNameStock = StringVariableName | NumberVariableName;

/**
 * The formatting parameters for a variable in a rack position name
 */
export interface StocksVariableParams {
  /** whether we output the variable in numbers (base 10) or in letters (base 26) */
  formatting: FormatingType;
  /** whether we output the variable from the left (0, 1, ..., end) to the right (end, end-1, ..., 0) or from the right to the left */
  formattingDirection: FormatingDirectionType;
  /** the minimum number of characters outputed (ex: 22 with nbCharacters = 4 will output '0022') */
  nbCharacters: number;
  /** the character that fill the  outputed, ex: if its A then => (ex: 22 with fillCharacter = 4 will output 'AA22') */
  fillCharacter: string;
  /** from which number the variable begins */
  startAt: number;
  /** the step increamented between every step (startAt, startAt + step, startAt + 2*step, ..., end) */
  step: number;
}

/**
 * The formatting parameters for a rack position name
 *
 * @see {isBlockDataSlotLineName} ts-auto-guard:type-guard
 */
export interface BlockDataSlotLineName {
  /**
   * char = string (ex: hello)
   * nbVariable = number variable (ex: index of the column)
   * strVariable = string variable (ex: rack name)
   */
  type: 'char' | 'nbVariable' | 'strVariable';
  value: string;
  params?: StocksVariableParams;
}
/**
 * @see {isBlockDataSlotLineNames} ts-auto-guard:type-guard
 */
export type BlockDataSlotLineNames = BlockDataSlotLineName[];

/**
 * Apply a rule to a position to generate a rack position name
 * @param rules the rules entered by the user
 * @param props data about the position to convert the variable to generated strings
 * @returns the generated cell position name
 */
export function convertSlotLineToName(
  rules: BlockDataSlotLineName[],
  props: { stockZoneName: string; slots: SlotArray[]; slotIndex: number }
): string {
  const { stockZoneName, slots, slotIndex } = props;

  return rules
    .map((rule) => {
      if (rule.type === 'char') {
        return rule.value;
      }

      const formattingParams = rule.params as StocksVariableParams;
      const { formattingDirection, startAt, step } = formattingParams;

      if (rule.type === 'nbVariable' || rule.type === 'strVariable') {
        const value = rule.value;
        if (isStocksVariableName(value)) {
          if (value === '@stockZoneName!') {
            return stockZoneName;
          } else if (value === '@stockLineNumber!') {
            const val =
              formattingDirection === 'ltr'
                ? slotIndex * step + startAt
                : (slots.length - 1 - slotIndex) * step + startAt;

            return slotLineNameConvertNumberToFormattedStr(val, formattingParams);
          }

          assert<Equals<typeof value, never>>();

          // eslint-disable-next-line no-console
          console.error('Variable name not handled', value);
        } else {
          // eslint-disable-next-line no-console
          console.error('Unknown variable name', rule.value);

          return '';
        }
      }

      // eslint-disable-next-line no-console
      console.error('Unknown type', rule);

      return '';
    })
    .join('');
}

/**
 * Convert a position name variable in number to a formatted string
 *
 * @param nb the position name variable in number
 * @param formattingParams the formatting parameters
 * @param fillWith (optional) if the number of characters taken by the formatted string is not long enough, we fill these characters with this value
 * @returns the formatted string
 */
export function slotLineNameConvertNumberToFormattedStr(
  nb: number,
  formattingParams: StocksVariableParams,
  fillWith?: string
): string {
  fillWith = fillWith ?? formattingParams.fillCharacter;

  let str: string;
  if (formattingParams.formatting === 'letter') {
    // convert from base 10 (numbers) to base 26 (letters)
    str = convertBase(nb.toString(), 10, 26);
  } else {
    // keep it in base 10
    str = nb.toString();
  }

  const nbMissingChars = formattingParams.nbCharacters - str.length;

  const res = nbMissingChars > 0 ? `${fillWith.repeat(nbMissingChars)}${str}` : str;

  return res;
}

/**
 * Check if a position cell name is valid or not
 * It does not check the unicity here
 * @param name the position cell name to check
 * @returns wether it is valid or not
 */
export function isValidSlotLineName(name: string): boolean {
  return name.length > 0 && name.length < nbMaxCharCellName;
}
