import { bboxPolygon, bbox as turfBbox, polygon as turfPolygon } from '@turf/turf';
import type { AddSegment } from 'actions/circuit';
import {
  AddMultipleSegmentsAction,
  addLayerAction,
  deleteMultipleShapesAction,
  updateTurnAction,
} from 'actions/circuit';
import { createPointSuccessAction } from 'actions/points';
import { deleteSegmentAction } from 'actions/segment';
import { createStockZoneSuccessAction } from 'actions/stock-zones';
import { createTurnSuccessAction, deleteTurnAction } from 'actions/turns';
import { createZoneSuccessAction } from 'actions/zones';
import { findShapeOrientation } from 'drawings/helpers';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type { CircuitTurn, Size3D, ZoneRule } from 'models/circuit';
import { Intersection, PatternTypes, ShapeTypes, isZoneRuleName } from 'models/circuit';
import { UNIT_COUNT_PER_METER } from 'models/drawings';
import { batch } from 'react-redux';
import { CircuitService, getMaxDisplayPriority } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { getDefaultdAsk, getDefaultdStop } from 'utils/circuit/default-circuit-shapes';
import { getNextFreeId } from 'utils/circuit/next-free-id';
import { toRad } from 'utils/helpers';
import { parseXmlString } from 'utils/preferences';
import type { CreateSuccessAction, DeleteAction } from 'utils/redux';

/**
 * The purpose of this function is to import a circuit from an XML file
 * Currently it supports:
 * - the creation of new layers
 * - the import of the segments
 * - the import of the turns (except those defined by a start point)
 * - the import of the zones
 * - the import of the points
 * @param circuitXMLStr the circuit xml string to import
 */
export async function importCircuitXML(circuitXMLStr: string): Promise<void> {
  const circuit = parseXmlString(circuitXMLStr);

  const layers = circuit.querySelectorAll('Layer');

  const isCommonAlreadyExists = Object.values(store.getState().circuit.present.layers.layers).some(
    (layer) => layer.name === 'Common'
  );

  const portionData = circuit.querySelector('ListOfPortionData');
  if (!portionData) {
    throw new Error('No portion data found');
  }

  const portionsData = circuit.querySelectorAll('PortionData');
  const computedPointsData = Array.from(circuit.querySelectorAll('ComputedPointData'));

  const segmentsToAdd: AddSegment['payload'][] = [];
  const turnsToAdd: CreateSuccessAction<CircuitTurn>['payload'][] = [];

  const layerIdsMap: Record<string, string> = {};

  const CEIdToRoadId: Record<string, string> = {};

  const nbTurnsStartPointSkipped = 0;

  const nbTotalZones = Array.from(circuit.querySelectorAll('Zone')).length;
  const nbTotalPoints = Array.from(circuit.querySelectorAll('Point')).length;
  const nbTotalStockZones = Array.from(circuit.querySelectorAll('StockZone')).length;

  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    const layerName = layer.getAttribute('ModelName');

    if (!layerName) continue;
    if (isCommonAlreadyExists && layerName === 'Common') continue; // we don't create the common layer if it already exists

    const nextFreeId = getNextFreeId();

    store.dispatch(
      addLayerAction({
        id: nextFreeId.toString(),
        name: layerName,
        visibility: true,
        color: '#964B00',
      })
    );
    layerIdsMap[layerName] = nextFreeId.toString();
  }

  for (let j = 0, iteration = 0; j < portionsData.length + 1; j++) {
    // we have to loop twice, first we process the segments and once we have all the segments, we loop again to add the turns
    if (j === portionsData.length) {
      if (iteration === 0) {
        iteration = 1;
        j = 0;
        continue;
      } else {
        break;
      }
    }

    const portionData = portionsData[j];

    const portionId = portionData.querySelector('Id')?.innerHTML;
    if (!portionId) {
      // eslint-disable-next-line no-console
      console.warn(`No portion id found`);
      continue;
    }

    const portionKind = portionData.querySelector('Kind')?.innerHTML;
    if (portionKind === 'Portion') {
      const criteriumForValidation = portionData.querySelector('CriteriumForValidation')?.innerHTML;
      if (iteration === 0 && criteriumForValidation === 'OptimizeSpeed') {
        // it's a segment (or at least a part of a segment)
        const coords = portionData.querySelector('PointCollectionInUnit')?.innerHTML?.split(' ');
        if (!coords) {
          // eslint-disable-next-line no-console
          console.warn(`No coords found for portion ${portionId}`);
          continue;
        }

        const listOfPortionDataEl = portionData.parentElement;
        const layerDataEl = listOfPortionDataEl?.parentElement;
        if (!layerDataEl) {
          // eslint-disable-next-line no-console
          console.warn(`No layer found for portion ${portionId}`, {
            portionData,
            listOfPortionDataEl,
            layerDataEl,
          });
          continue;
        }

        const layerName = layerDataEl.querySelector('Title')?.innerHTML;
        if (!layerName) {
          // eslint-disable-next-line no-console
          console.warn(`No layer name found for portion ${portionId}`);
          continue;
        }
        // if (!layerName || layerName === 'Common') continue; // we ignore the elements of the common layer

        const points = coords.map((point) =>
          point.split(';').map((coord) => (parseInt(coord, 10) / UNIT_COUNT_PER_METER) * 100)
        );

        const nextFreeId = getNextFreeId().toString();

        const timestamp = Date.now();

        segmentsToAdd.push({
          layerId: layerIdsMap[layerName],
          coord: [points[0], points[1]],
          id: nextFreeId,
          name: `import_${timestamp}_${j}_${Math.floor(Math.random() * 100)}`,
        });

        CEIdToRoadId[portionId] = nextFreeId;
      } else if (
        iteration === 1 &&
        (criteriumForValidation === 'OptimizeKiwiByRadius' || criteriumForValidation === 'OptimizeKiwiByStartPoint')
      ) {
        // it's a turn
        const coords = portionData.querySelector('PointCollectionInUnit')?.innerHTML?.split(' ');
        if (!coords) {
          // eslint-disable-next-line no-console
          console.warn(`No coords found for portion ${portionId}`);
          continue;
        }

        const isStartPointTurn = criteriumForValidation === 'OptimizeKiwiByStartPoint';

        const portionEl = circuit.querySelector(`Portion[Id="${portionId}"]`);
        const allPortionsEl = portionEl?.parentElement;
        const layerEl = allPortionsEl?.parentElement;
        if (!layerEl) {
          // eslint-disable-next-line no-console
          console.warn(`No layer found for portion ${portionId}`);
          continue;
        }

        const layerName = layerEl.getAttribute('ModelName');
        if (!layerName) {
          // eslint-disable-next-line no-console
          console.warn(`No layer name found for portion ${portionId}`);
          continue;
        }

        const maxOvershoot = portionData?.querySelector('MaxOvershoot')?.innerHTML;
        const turnRadius = portionData?.querySelector('Radius')?.innerHTML;
        const startPointOffset = portionData?.querySelector('StartPointOffset')?.innerHTML;
        const title = portionData?.querySelector('Title')?.innerHTML;
        const turnOptionFlag = portionData?.querySelector('TurnOptionFlag')?.innerHTML;

        if (!maxOvershoot || !turnRadius || !title || !turnOptionFlag) {
          // eslint-disable-next-line no-console
          console.warn(`No maxOvershoot or turnRadius or title found for portion ${portionId}`, {
            maxOvershoot,
            turnRadius,
            startPointOffset,
            title,
            turnOptionFlag,
          });
          continue;
        }

        const isStopBeforeTurn = turnOptionFlag === 'StopBeforeTurn';

        const computedPoints = computedPointsData.filter((pt) => {
          const associatedCurveId = pt.querySelector('AssociatedCurveId')?.innerHTML;

          return associatedCurveId === portionId;
        });

        let srcId = computedPoints
          .find((pt) => {
            const startEnd = pt.querySelector('StartEnd')?.innerHTML;

            return startEnd === 'Start';
          })
          ?.querySelector('AssociatedPortionId')?.innerHTML;
        const destId = computedPoints
          .find((pt) => {
            const startEnd = pt.querySelector('StartEnd')?.innerHTML;

            return startEnd === 'End';
          })
          ?.querySelector('AssociatedPortionId')?.innerHTML;

        if (!srcId) {
          const portionDataOfTurn = Array.from(circuit.querySelectorAll('PortionData')).find((portionData) => {
            return portionData?.querySelector('Id')?.innerHTML === portionId;
          });
          srcId = portionDataOfTurn?.querySelector('LeftId')?.innerHTML;
        }

        if (!srcId || !destId) {
          // eslint-disable-next-line no-console
          console.warn(`No src or dst id found for portion ${portionId}`, {
            srcId,
            destId,
            computedPoints,
          });
          continue;
        }

        const points = coords.map((point) =>
          point.split(';').map((coord) => (parseInt(coord, 10) / UNIT_COUNT_PER_METER) * 100)
        );

        const nextFreeId = getNextFreeId().toString();

        const originId = CEIdToRoadId[srcId];
        const destinationId = CEIdToRoadId[destId];

        const turnToAdd: CircuitTurn = {
          id: nextFreeId,
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: points,
          },
          properties: {
            layerId: layerIdsMap[layerName],
            originId,
            destinationId,
            maxOvershoot: parseFloat(maxOvershoot),
            prio: getMaxDisplayPriority(),
            radius: !isStartPointTurn ? parseFloat(turnRadius) : undefined,
            startPointOffset: startPointOffset ? parseFloat(startPointOffset) : 0,
            type: ShapeTypes.TurnShape,
            turnType: isStopBeforeTurn ? 'StopBeforeTurn' : 'Normal',
            name: title,
            drivenBy: isStartPointTurn ? 'StartPoint' : 'Radius',
            coordinateProperties: {
              cap: [],
            },
            positionFactorOrigin: 0,
            positionFactorDest: 0,
          },
        };

        turnsToAdd.push(turnToAdd);
      } else {
        if (
          criteriumForValidation !== 'OptimizeSpeed' &&
          criteriumForValidation !== 'OptimizeKiwiByRadius' &&
          criteriumForValidation !== 'OptimizeKiwiByStartPoint'
        ) {
          // eslint-disable-next-line no-console
          console.log('Unknown criterium for validation', criteriumForValidation);
        }
      }
    } else if (portionKind === 'Measurer') {
      // we don't import measurers yet
    } else {
      // eslint-disable-next-line no-console
      console.warn('Unknown portion kind', portionKind);
    }
  }

  // eslint-disable-next-line no-console
  console.log(`Adding ${segmentsToAdd.length} segments...`);

  const nbSegmentsToAdd = segmentsToAdd.length;
  let nbTurnsToAdd = turnsToAdd.length;

  const snackBarId = SnackbarUtils.toast(
    <span>
      Importing <span id="import-xml-nb-seg">0</span>/{nbSegmentsToAdd} segments (
      <span id="import-xml-nb-percents">0</span>%)
      <br />
      Importing <span id="import-xml-nb-stockzones">0</span>/{nbTotalStockZones} stock zones (
      <span id="import-xml-nb-percents-stockzones">0</span>%)
      <br />
      Importing <span id="import-xml-nb-turns">0</span>/{nbTurnsToAdd} turns (
      <span id="import-xml-nb-turns-percents">0</span>%)
      <br />
      Updating <span id="import-xml-nb-updates-turns">0</span>/{turnsToAdd.length} turns (
      <span id="import-xml-nb-updates-percents-turns">0</span>%)
      <br />
      Importing <span id="import-xml-nb-zones">0</span>/{nbTotalZones} zones (
      <span id="import-xml-nb-percents-zones">0</span>%)
      <br />
      Importing <span id="import-xml-nb-points">0</span>/{nbTotalPoints} points (
      <span id="import-xml-nb-percents-points">0</span>%)
    </span>,
    { persist: true, variant: 'info' }
  );

  await new Promise((resolve) => setTimeout(resolve, 100));

  const xmlNbSegEl = document.querySelector('#import-xml-nb-seg') as HTMLElement;
  const xmlNbPercentsEl = document.querySelector('#import-xml-nb-percents') as HTMLElement;
  const xmlNbTurnsEl = document.querySelector('#import-xml-nb-turns') as HTMLElement;
  const xmlNbTurnsPercentsEl = document.querySelector('#import-xml-nb-turns-percents') as HTMLElement;
  const xmlNbUpdatesTurnsEl = document.querySelector('#import-xml-nb-updates-turns') as HTMLElement;
  const xmlNbUpdatesPercentsTurnsEl = document.querySelector('#import-xml-nb-updates-percents-turns') as HTMLElement;
  const xmlNbStockZonesEl = document.querySelector('#import-xml-nb-stockzones') as HTMLElement;
  const xmlNbStockZonesPercentsEl = document.querySelector('#import-xml-nb-percents-stockzones') as HTMLElement;
  const xmlNbZonesEl = document.querySelector('#import-xml-nb-zones') as HTMLElement;
  const xmlNbZonesPercentsEl = document.querySelector('#import-xml-nb-percents-zones') as HTMLElement;
  const xmlNbPointsEl = document.querySelector('#import-xml-nb-points') as HTMLElement;
  const xmlNbPointsPercentsEl = document.querySelector('#import-xml-nb-percents-points') as HTMLElement;

  for (let i = 0; i < segmentsToAdd.length; i++) {
    const segmentsToAddLocal: typeof segmentsToAdd = [];
    let j = 0;
    const nbSteps = 100 + Math.floor(Math.random() * 200);
    for (j = i; j < segmentsToAdd.length && j < i + nbSteps; j++) {
      segmentsToAddLocal.push(segmentsToAdd[j]);
    }

    i = j;
    store.dispatch(AddMultipleSegmentsAction(segmentsToAddLocal));

    const txt = `Added ${i}/${segmentsToAdd.length} segments (${Math.round((i / segmentsToAdd.length) * 100)}%)`;
    // eslint-disable-next-line no-console
    console.log(txt);

    if (xmlNbSegEl) xmlNbSegEl.innerHTML = i.toString();
    if (xmlNbPercentsEl) xmlNbPercentsEl.innerHTML = Math.round((i / segmentsToAdd.length) * 100).toString();

    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  const commonLayerId = Object.values(store.getState().circuit.present.layers.layers).find(
    (layer) => layer.name === 'Common'
  )?.id;

  let nbStockZonesImported = 0;
  {
    // from now we import stock zones
    const stockZones = Array.from(circuit.querySelectorAll('StockZone'));

    if (stockZones.length) {
      let nbStockZonesFailedImport = 0;

      if (!commonLayerId) {
        nbStockZonesFailedImport = stockZones.length;
        // eslint-disable-next-line no-console
        console.warn('Common layer not found while creating the stock zone');

        return;
      }

      const segmentsIdsToDelete = new Set<string>();
      for (const stockZoneEl of stockZones) {
        const name = stockZoneEl.getAttribute('Title');
        const idCE = stockZoneEl.getAttribute('Id');
        const enableScan = stockZoneEl.getAttribute('EnableScan');

        const stockLines = Array.from(stockZoneEl.querySelectorAll('StockLine'));

        const firstAssociatedPortionId = stockLines[0].getAttribute('PortionDataId');
        if (!name || !idCE || !enableScan || !firstAssociatedPortionId) {
          nbStockZonesFailedImport++;
          // eslint-disable-next-line no-console
          console.warn('Stock zone is missing some attributes', { name, idCE, enableScan, firstAssociatedPortionId });

          return;
        }

        const segmentIdOfFirstPortion = CEIdToRoadId[firstAssociatedPortionId];
        const segmentOfFirstPortion = store.getState().circuit.present.segments.entities[segmentIdOfFirstPortion];

        if (!segmentOfFirstPortion) {
          nbStockZonesFailedImport++;
          // eslint-disable-next-line no-console
          console.warn('Segment not found while creating the stock zone', {
            name,
            idCE,
            enableScan,
            firstAssociatedPortionId,
          });

          continue;
        }

        const segmentOfFirstPortionLength = getDistanceBetweenPoints(
          segmentOfFirstPortion.geometry.coordinates[0],
          segmentOfFirstPortion.geometry.coordinates[1]
        );

        const orientation = -findShapeOrientation(
          segmentOfFirstPortion.geometry.coordinates[0],
          segmentOfFirstPortion.geometry.coordinates[1]
        );

        const firstPallet = stockLines[0].querySelector('Pallet');
        const palletWidthCE = firstPallet?.getAttribute('Width') ?? '0';
        const palletLengthCE = firstPallet?.getAttribute('Length') ?? '0';
        const palletWidth = parseFloat(palletWidthCE) / 1000000; // m
        const palletLength = parseFloat(palletLengthCE) / 1000000; // m

        const stockZoneLength = parseInt(stockLines[0].getAttribute('NumberOfPallet') ?? '1', 10);
        const stockZoneWidth = stockLines.length;

        stockLines.forEach((stockLineEl) => {
          const idCE = stockLineEl.getAttribute('PortionDataId');
          const idRoad = idCE ? CEIdToRoadId[idCE] : undefined;
          if (idRoad) segmentsIdsToDelete.add(idRoad);
        });

        const newStockZone = await CircuitService.createGeoJSONStockZone(
          [
            [0, 0],
            [100, 100],
          ],
          'StockZone',
          commonLayerId,
          orientation
        ).toPromise();

        newStockZone.properties.name = name;
        newStockZone.properties.cap = orientation;
        newStockZone.properties.enableScan = enableScan === 'true';
        newStockZone.properties.extendedLength = 0;
        newStockZone.properties.palletSize = {
          width: palletWidth,
          length: palletLength,
        };
        newStockZone.properties.slotSize = structuredClone(newStockZone.properties.palletSize);
        newStockZone.properties.width = stockZoneWidth;
        newStockZone.properties.length = stockZoneLength;
        newStockZone.properties.pattern = PatternTypes.uniformDistribution;

        const secondStockLine = stockLines[1];
        let gapBetweenStockLines = 0.4; // m
        if (secondStockLine) {
          const secondAssociatedPortionId = secondStockLine.getAttribute('PortionDataId');
          if (secondAssociatedPortionId) {
            const segmentIdSecondPortion = CEIdToRoadId[secondAssociatedPortionId];
            const segmentSecondPortion = store.getState().circuit.present.segments.entities[segmentIdSecondPortion];
            if (segmentSecondPortion) {
              const coords1 = segmentOfFirstPortion.geometry.coordinates;
              const coords2 = segmentSecondPortion.geometry.coordinates;
              const distance = getDistanceBetweenPoints(coords1[0], coords2[0]) / 100; // m

              gapBetweenStockLines = distance - newStockZone.properties.slotSize.width;
            }
          }
        }

        let spaceBetweenPalletsGlobal = 0;
        stockLines.forEach((stockLineEl, index) => {
          const spaceBetweenPalletsStr = stockLineEl.getAttribute('SpaceBetweenPallet');
          const spaceBetweenPallets = spaceBetweenPalletsStr ? parseFloat(spaceBetweenPalletsStr) / 1000000 : 0;

          if (index === 0) spaceBetweenPalletsGlobal = spaceBetweenPallets;
        });

        const originFirstPortion = segmentOfFirstPortion.geometry.coordinates[0];
        const angle = toRad(orientation);
        const origin = [
          originFirstPortion[0] - ((palletWidth * 100) / 2) * Math.sin(angle),
          originFirstPortion[1] + ((palletWidth * 100) / 2) * Math.cos(angle),
        ];

        const newCoordinates = [origin, origin, origin, origin, origin];

        const gap: Size3D = {
          width: gapBetweenStockLines,
          length: spaceBetweenPalletsGlobal,
        };
        newStockZone.properties.gap = gap;

        let newSlots = CircuitService.generateStockZoneSlots(
          newCoordinates,
          newStockZone.properties.slotSize,
          gap,
          newStockZone.properties.length,
          newStockZone.properties.width,
          newStockZone.properties.palletTypes,
          undefined, // palletPosition,
          newStockZone.properties.palletSize,
          undefined, // newStockZone.properties.tolerancePosition,
          orientation,
          undefined,
          undefined, // newStockZone.properties.customGapSlots, // undefined,
          undefined, // newStockZone.properties.customGapLines, // undefined,
          name
        );

        newSlots = CircuitService.computeSlotsGeometry(newSlots, orientation, true);

        let minX = 1 / 0;
        let maxX = -1 / 0;
        let minY = 1 / 0;
        let maxY = -1 / 0;
        for (let i = 0, slotLinesLastIndex = newSlots[0].slots.length - 1; i < newSlots.length; i++) {
          const firstSlotInLine = newSlots[i].slots[0];
          if (firstSlotInLine.slotPosition.x < minX) {
            minX = firstSlotInLine.slotPosition.x;
          }

          const lastSlotInLine = newSlots[i].slots[slotLinesLastIndex];
          const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + lastSlotInLine.slotSize.length * 100;
          if (maxXLastSlotInLine > maxX) {
            maxX = maxXLastSlotInLine;
          }
        }

        minY = -newSlots[0].slots[0].slotPosition.y;
        maxY =
          -newSlots[newSlots.length - 1].slots[0].slotPosition.y -
          newSlots[newSlots.length - 1].slots[0].slotSize.width * 100;

        const zonePosX = minX;
        const zonePosY = minY;

        const zoneWidth = Math.abs(maxY - minY) / 100;
        let zoneLength = Math.abs(maxX - minX) / 100;

        const stockZoneMargin = segmentOfFirstPortionLength / 100 - zoneLength;
        newStockZone.properties.stockZoneMargin = stockZoneMargin;

        zoneLength += stockZoneMargin;

        const theta = toRad(orientation);
        const coords = [
          [
            [zonePosX, zonePosY],
            [zonePosX + zoneLength * 100 * Math.cos(theta), zonePosY - zoneLength * 100 * Math.sin(theta)],
            [
              zonePosX + zoneLength * 100 * Math.cos(theta) - zoneWidth * 100 * Math.sin(theta),
              zonePosY - zoneLength * 100 * Math.sin(theta) - zoneWidth * 100 * Math.cos(theta),
            ],
            [zonePosX - Math.sin(theta) * zoneWidth * 100, zonePosY - Math.cos(theta) * zoneWidth * 100],
            [zonePosX, zonePosY],
          ],
        ];

        newStockZone.properties.slots = newSlots;

        newStockZone.geometry.coordinates = coords;

        const allStockZonesData = Array.from(circuit.querySelectorAll('ZoneStockData'));
        const stockZoneData = allStockZonesData.find((stockZoneDataEl) => {
          const idCEInDoc = stockZoneDataEl.querySelector('Id')?.textContent;

          return idCEInDoc === idCE;
        });
        if (!stockZoneData) {
          nbStockZonesFailedImport++;
          // eslint-disable-next-line no-console
          console.error('Stock zone data not found', { idCE });
          continue;
        }

        const extendedLengthTxt = stockZoneData.querySelector('ExceededLenght')?.textContent;
        if (!extendedLengthTxt) {
          nbStockZonesFailedImport++;
          // eslint-disable-next-line no-console
          console.error('ExceededLenght of stock zone data not found', { idCE });
          continue;
        }

        const extendedLength = parseFloat(extendedLengthTxt) / 10000 / 100; // m

        newStockZone.properties.extendedLength = extendedLength;

        store.dispatch(createStockZoneSuccessAction(newStockZone));

        nbStockZonesImported++;

        xmlNbStockZonesEl.innerHTML = nbStockZonesImported.toString();
        xmlNbStockZonesPercentsEl.innerHTML = Math.round((nbStockZonesImported / stockZones.length) * 100).toString();

        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      const listOfExtentedPortions = new Set(
        Array.from(circuit.querySelectorAll('ListOfExtentedPortion'))
          .map((el) => el.textContent)
          .map((txt) => txt?.split(' '))
          .flatMap((arr) => arr?.map((txt) => txt.split(';')))
          .map((arr) => (arr ? [arr[1], arr[3]] : []))
          .flat()
      );
      listOfExtentedPortions.forEach((portionIdXml) => {
        const portionIdRoad = CEIdToRoadId[portionIdXml];
        if (portionIdRoad) segmentsIdsToDelete.add(portionIdRoad);
      });

      if (segmentsIdsToDelete.size) {
        const deleteSegmentActions: DeleteAction[] = [];

        segmentsIdsToDelete.forEach((segmentIdToSelete) => {
          deleteSegmentActions.push(
            deleteSegmentAction({
              id: segmentIdToSelete,
            })
          );
        });

        store.dispatch(
          deleteMultipleShapesAction({
            actions: deleteSegmentActions,
            force: true,
          })
        );
      }

      if (nbStockZonesFailedImport) {
        SnackbarUtils.error(`Failed to import ${nbStockZonesFailedImport} stock zones`);
      }
    }
  }

  const storeState = store.getState();
  for (let i = 0; i < turnsToAdd.length; i++) {
    const turnsToAddLocal: typeof turnsToAdd = [];
    let j = 0;
    const nbSteps = 10 + Math.floor(Math.random() * 20);
    for (j = i; j < turnsToAdd.length && j < i + nbSteps; j++) {
      const turnToAdd = turnsToAdd[j];

      turnsToAddLocal.push(turnToAdd);
    }

    i = j;
    const nbTurnsToCreate = turnsToAddLocal.length;
    const nbTurnsBefore = store.getState().circuit.present.turns.ids.length;
    batch(() => {
      for (const turnToAdd of turnsToAddLocal) {
        store.dispatch(createTurnSuccessAction(turnToAdd));
      }
    });
    const nbTurnsAfter = store.getState().circuit.present.turns.ids.length;
    const delta = nbTurnsAfter - nbTurnsBefore - nbTurnsToCreate;
    if (delta !== 0) {
      // eslint-disable-next-line no-console
      console.warn(`${delta} turns failed to be created`, {
        nbTurnsToCreated: nbTurnsToCreate,
        nbTurnsBefore,
        nbTurnsAfter,
        turnsToAddLocal,
      });
    }

    const txt = `Added ${i}/${turnsToAdd.length} turns (${Math.round((i / turnsToAdd.length) * 100)}%)`;
    // eslint-disable-next-line no-console
    console.log(txt);

    if (xmlNbTurnsEl) xmlNbTurnsEl.innerHTML = i.toString();
    if (xmlNbTurnsPercentsEl) xmlNbTurnsPercentsEl.innerHTML = Math.round((i / turnsToAdd.length) * 100).toString();

    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  // i am not sure that we have to recompute the turns but let's do it i fell like it will avoid us problems
  for (let i = 0; i < turnsToAdd.length; i++) {
    const turnsToUpdateLocal: typeof turnsToAdd = [];
    const turnsToDeleteLocal: typeof turnsToAdd = [];
    let j = 0;
    const nbSteps = 10 + Math.floor(Math.random() * 30);
    for (j = i; j < turnsToAdd.length && j < i + nbSteps; j++) {
      const turnToAdd = turnsToAdd[j];
      const turn = store.getState().circuit.present.turns.entities[turnToAdd.id as string];
      if (!turn) {
        // eslint-disable-next-line no-console
        console.warn(`Turn ${turnToAdd.id} not found in the store, skipping the update`, turnToAdd);
        nbTurnsToAdd--;
        continue;
      }

      let origin = storeState.circuit.present.segments.entities[turn.properties.originId ?? ''];
      let destination = storeState.circuit.present.segments.entities[turn.properties.destinationId ?? ''];

      const distThreshold = 1; // m
      if (!origin && commonLayerId) {
        const closestSegment = CircuitService.getBestMatchingSegmentForTurn(
          turn.geometry.coordinates[0],
          commonLayerId,
          {
            distThreshold,
          }
        );
        if (closestSegment) origin = { ...closestSegment, loaded: true };
      }

      if (!destination && commonLayerId) {
        const closestSegment = CircuitService.getBestMatchingSegmentForTurn(
          turn.geometry.coordinates.at[-1],
          commonLayerId,
          {
            distThreshold,
          }
        );
        if (closestSegment) destination = { ...closestSegment, loaded: true };
      }

      if (!origin || !destination || !origin.id || !destination.id) {
        turnsToDeleteLocal.push(turnToAdd);
        nbTurnsToAdd--;
        continue;
      }

      turnsToUpdateLocal.push({
        ...turnsToAdd[j],
        properties: {
          ...turnsToAdd[j].properties,
          originId: origin.id as string,
          destinationId: destination.id as string,
        },
      });
    }

    i = j;

    turnsToDeleteLocal.forEach((turnToDelete) => {
      store.dispatch(
        deleteTurnAction({
          id: turnToDelete.id as string,
        })
      );
    });

    turnsToUpdateLocal.forEach((turnToUpdate) => {
      if (!turnToUpdate.properties.originId || !turnToUpdate.properties.destinationId) {
        // eslint-disable-next-line no-console
        console.error('Turn has no origin or destination', turnToUpdate);

        return;
      }

      store.dispatch(
        updateTurnAction({
          idToUpdate: turnToUpdate.id as string,
          destinationId: turnToUpdate.properties.destinationId,
          originId: turnToUpdate.properties.originId,
        })
      );
    });

    const txt = `Updating ${i}/${turnsToAdd.length} turns (${Math.round((i / turnsToAdd.length) * 100)}%)`;
    // eslint-disable-next-line no-console
    console.log(txt);

    if (xmlNbUpdatesTurnsEl) xmlNbUpdatesTurnsEl.innerHTML = i.toString();
    if (xmlNbUpdatesPercentsTurnsEl)
      xmlNbUpdatesPercentsTurnsEl.innerHTML = Math.round((i / turnsToAdd.length) * 100).toString();

    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  {
    const turnsIds = store.getState().circuit.present.turns.ids;
    const turnsEntities = store.getState().circuit.present.turns.entities;

    turnsIds.forEach((turnId) => {
      const turn = turnsEntities[turnId];

      if (!turn) return;

      if (!turn.properties.originId || !turn.properties.destinationId) {
        // eslint-disable-next-line no-console
        console.warn(`Turn ${turn.id} has no origin or destination, deleting it`, turn);
        store.dispatch(
          deleteTurnAction({
            id: turn.id as string,
          })
        );

        return;
      }

      const coordinatedCap = turn.properties.coordinateProperties.cap;
      if (!coordinatedCap || !coordinatedCap.length) {
        // eslint-disable-next-line no-console
        console.warn(`Turn ${turn.id} has no coordinated cap, updating it again`, turn);

        store.dispatch(
          updateTurnAction({
            idToUpdate: turn.id as string,
          })
        );
      }
    });
  }

  const deltaTurns = turnsToAdd.length - nbTurnsToAdd;
  if (deltaTurns > 0) {
    // eslint-disable-next-line no-console
    console.warn(`We failed to import ${deltaTurns} turns`);
    SnackbarUtils.warning(`The import of ${deltaTurns} turns failed`);
  }

  if (nbTurnsStartPointSkipped > 0) {
    // eslint-disable-next-line no-console
    console.log(`We skipped ${nbTurnsStartPointSkipped} turns driven by start point (not supported yet)`);
    SnackbarUtils.info(`We skipped ${nbTurnsStartPointSkipped} turns driven by start point (not supported yet)`);
  }

  let nbZonesImported = 0;
  let nbZonesFailedImport = 0;
  let nbZonesModified = 0;

  // from now, we import zones
  const layersEl = Array.from(circuit.querySelectorAll('Layer'));
  for (const layerEl of layersEl) {
    const layerName = layerEl.getAttribute('ModelName');
    const zones = Array.from(layerEl.querySelectorAll('Zone'));
    for (const zoneEl of zones) {
      const name = zoneEl.getAttribute('Title');
      const projection = zoneEl.getAttribute('Projection');
      const polygon = zoneEl.querySelector('Polygon')?.innerHTML;
      const rulesStr = zoneEl.querySelector('ListOfRules')?.innerHTML;

      if (!name || !projection || !polygon || !rulesStr) {
        nbZonesFailedImport++;
        // eslint-disable-next-line no-console
        console.warn('Zone is missing some attributes', { name, projection, polygon, rules: rulesStr });

        return;
      }

      let coordinates = polygon.split(' ').map((point) => point.split(';').map((coord) => parseFloat(coord) / 1e4));
      coordinates.push(coordinates[0]); // in geojson we close the polygon
      if (coordinates.length !== 5 && coordinates.length !== 4) {
        // then it's not a rectangle or a triangle, road editor only supports rectangle & triangle
        // so either we skip it either we convert it to a rectangle
        // let's convert it to a rectangle by computing its bounding box

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const bbox = bboxPolygon(turfBbox(turfPolygon([coordinates])));
        coordinates = bbox.geometry.coordinates[0];

        nbZonesModified++;
      }

      const layerId = Object.values(store.getState().circuit.present.layers.layers).find(
        (layer) => layer.name === layerName
      )?.id;

      if (!layerId) {
        nbZonesFailedImport++;
        // eslint-disable-next-line no-console
        console.warn('Layer not found while creating the zone', { layerName, zoneName: name });

        return;
      }

      const rules = rulesStr
        .split(' ')
        .map((rule) => {
          if (rule === '(Classic;)') return undefined; // zone with no rules we skip

          const splittedRule = rule.split(';');
          const ruleName = splittedRule[0].slice(1);
          const ruleParamStr = splittedRule[1].slice(0, -1);
          const ruleParam = parseFloat(ruleParamStr);

          if (!isZoneRuleName(ruleName)) {
            if (ruleName !== 'IsSupervisorVisible') {
              // we know we don't support this rule

              // eslint-disable-next-line no-console
              console.error('Unknown rule name', ruleName);
            }

            return undefined;
          }

          return ruleParamStr ? [ruleName, ruleParam] : [ruleName];
        })
        .filter((rule) => !!rule) as ZoneRule;

      const isDoor = rules.some((rule) => rule[0] === 'Door');

      const newZone = await CircuitService.createGeoJSONZone(coordinates, 'Rectangle', layerId).toPromise();

      newZone.geometry.coordinates = [coordinates];
      newZone.properties.name = name;
      newZone.properties.rules = rules;
      newZone.properties.intersectionType =
        projection === 'Point' ? Intersection.PointIntersection : Intersection.GabaritIntersection;

      if (isDoor) {
        newZone.properties.door = { enabled: true, dAsk: getDefaultdAsk(), dStop: getDefaultdStop(), devices: [] };
        newZone.properties.intersectionType = Intersection.GabaritIntersection;
      }

      store.dispatch(createZoneSuccessAction(newZone));

      nbZonesImported++;

      xmlNbZonesEl.innerHTML = nbZonesImported.toString();
      xmlNbZonesPercentsEl.innerHTML = Math.round((nbZonesImported / nbTotalZones) * 100).toString();

      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  if (nbZonesFailedImport) {
    SnackbarUtils.error(`Failed to import ${nbZonesFailedImport} zones`);
  }

  if (nbZonesModified) {
    SnackbarUtils.warning(`Modified ${nbZonesModified} zones to be rectangles`);
  }

  let nbPointsFailedImport = 0;
  let nbPointsImported = 0;

  const layersDataEl = Array.from(circuit.querySelectorAll('LayerData'));
  for (const layerDataEl of layersDataEl) {
    const layerName = layerDataEl.querySelector('BalyoTitle')?.innerHTML;

    if (!layerName) {
      nbPointsFailedImport++;
      // eslint-disable-next-line no-console
      console.warn('Name of the LayerData is not found', { layerName });
      continue;
    }

    const layerId = Object.values(store.getState().circuit.present.layers.layers).find(
      (layer) => layer.name === layerName
    )?.id;
    if (!layerId) {
      nbPointsFailedImport++;
      // eslint-disable-next-line no-console
      console.warn('Layer not found while creating the point', { layerName });
      continue;
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    const points = Array.from(layerDataEl.querySelectorAll('DestinationPointData'));
    for (const pointEl of points) {
      const id = pointEl.querySelector('Id')?.innerHTML;
      const name = pointEl.querySelector('Title')?.innerHTML;
      const ptInUnit = pointEl.querySelector('PtInUnit')?.innerHTML;
      const type = pointEl.querySelector('DestinationType')?.innerHTML;

      if (!id || !name || !ptInUnit || !type) {
        nbPointsFailedImport++;
        // eslint-disable-next-line no-console
        console.warn('Point is missing some attributes', { id, name, ptInUnit, type });
        continue;
      }

      const portionDataId = pointEl.querySelector('PortionDataId')?.innerHTML;
      const percentPortion = pointEl.querySelector('Percent')?.innerHTML;
      const initScore = pointEl.querySelector('MinimalScoreForInit')?.innerHTML;

      const coordinates = ptInUnit.split(';').map((coord) => parseFloat(coord) / 1e4);

      const newPoint = await CircuitService.createGeoJSONPoint(coordinates, 0, layerId).toPromise();

      newPoint.properties.name = name;

      if (type === 'Normal') {
        // normal point, we don't add any property
      } else if (type === 'Init') {
        newPoint.properties.isInit = true;
        newPoint.properties.minimumInitScore = initScore ? parseInt(initScore, 10) : 100;
      } else if (type === 'Taxi') {
        newPoint.properties.isTaxi = true;
      } else if (type === 'Charger') {
        newPoint.properties.isBattery = true;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Point type is not supported (normal point created instead)', { id, name, ptInUnit, type });
      }

      if (portionDataId && percentPortion) {
        // this point is snapped on a segment
        const segmentId = CEIdToRoadId[portionDataId];
        if (!segmentId) {
          nbPointsFailedImport++;
          // eslint-disable-next-line no-console
          console.warn('Segment not found while creating the point', { id, name, ptInUnit, type });
          continue;
        }

        const positionFactorOrigin = parseFloat(percentPortion);
        if (isNaN(positionFactorOrigin)) {
          nbPointsFailedImport++;
          // eslint-disable-next-line no-console
          console.warn('Segment not found while creating the point, issue with the position factor origin', {
            id,
            name,
            ptInUnit,
            type,
          });
          continue;
        }

        newPoint.properties.segment = {
          id: segmentId,
          position: parseFloat(percentPortion),
        };

        // we also need to compute the orientation of the point = the angle of the segment
        const segment = store.getState().circuit.present.segments.entities[segmentId];
        if (segment) {
          const coords = segment.geometry.coordinates;
          const computedAngle = findShapeOrientation([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
          const normalizedAngle = (computedAngle + 360) % 360;

          newPoint.properties.orientation = normalizedAngle;
        } else {
          // eslint-disable-next-line no-console
          console.log(`Segment ${segmentId} not found, point orientation not computed`);
        }
      }

      store.dispatch(createPointSuccessAction(newPoint));

      nbPointsImported++;

      xmlNbPointsEl.innerHTML = nbPointsImported.toString();
      xmlNbPointsPercentsEl.innerHTML = Math.round((nbPointsImported / points.length) * 100).toString();

      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  if (nbPointsFailedImport) {
    SnackbarUtils.error(`Failed to import ${nbPointsFailedImport} points`);
  }

  await new Promise((resolve) => setTimeout(resolve, 500));

  const txtSuccess = (
    <>
      Successfully added {nbSegmentsToAdd}/{nbSegmentsToAdd} segments (processed 100%)
      <br />
      Successfully added {nbTurnsToAdd}/{turnsToAdd.length} turns (processed 100%)
      <br />
      Successfully updated {nbTurnsToAdd} turns (processed 100%)
      <br />
      Successfully added {nbZonesImported}/{nbTotalZones} zones (processed 100%)
      <br />
      Successfully added {nbPointsImported}/{nbTotalPoints} points (processed 100%)
      <br />
      Successfully added {nbStockZonesImported}/{nbTotalStockZones} stock zones (processed 100%)
    </>
  );
  if (snackBarId) SnackbarUtils.closeSnackbar(snackBarId);
  SnackbarUtils.toast(txtSuccess, { variant: 'success', persist: true });

  await new Promise((resolve) => setTimeout(resolve, 500));
}
