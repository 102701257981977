import { Chip } from '@mui/material';
import React from 'react';
import { isVariableName } from 'utils/circuit/racks-naming.guard';
import type { BlockDataPositionName, VariableParams, VariablesName } from 'utils/circuit/racks-naming.model';
import { theme } from 'utils/mui-theme';
import { ChipVariableName } from './rack-edition/rack-edition-rename-positions-menu';
import type {
  BlockDataSlotLineName,
  StocksVariableParams,
  VariablesNameStock,
} from './stock-zone-settings/stock-zone-naming';
import { isStocksVariableName } from './stock-zone-settings/stock-zone-naming.guard';
import { ChipVariableNameStock } from './stock-zone-settings/stock-zone-settings-rename-stocklines';

export type HandleChangeVariableStockZone = (
  newVariableParams: StocksVariableParams,
  index: number,
  value: VariablesNameStock
) => void;
export type HandleChangeVariableRack = (newVariableParams: VariableParams, index: number, value: VariablesName) => void;

export interface PositionNameAdornmentProps {
  valuesTextField: BlockDataPositionName[] | BlockDataSlotLineName[];
  setValuesTextField: React.Dispatch<React.SetStateAction<BlockDataPositionName[] | BlockDataSlotLineName[]>>;
  deleteValue: (cursor?: number) => void;
  posCursor: number;
  setPosCursor: React.Dispatch<React.SetStateAction<number>>;
  setName: (name: string) => void;

  startOrEnd: 'start' | 'end';

  handleChangeVariable: HandleChangeVariableRack | HandleChangeVariableStockZone;

  handleAddNewValue?: (e?: React.SyntheticEvent<Element, Event>, valuesToConsider?: BlockDataPositionName[]) => void;
  inputRef?: HTMLInputElement;
}
export function PositionNameAdornment(props: PositionNameAdornmentProps): JSX.Element {
  const {
    valuesTextField,
    deleteValue,
    posCursor,
    setPosCursor,
    setValuesTextField,
    setName,
    startOrEnd,
    handleChangeVariable,
  } = props;

  return (
    <>
      {valuesTextField.map((block, index) => {
        if (startOrEnd === 'start' && index >= posCursor) return <React.Fragment key={index}></React.Fragment>;
        if (startOrEnd === 'end' && index < posCursor) return <React.Fragment key={index}></React.Fragment>;

        if (block.type === 'nbVariable' || block.type === 'strVariable') {
          if (isVariableName(block.value)) {
            return (
              <ChipVariableName
                key={`${block.value}-${index}`}
                index={index}
                value={block.value}
                blockData={block}
                deleteVariable={() => deleteValue(index)}
                handleChangeVariable={handleChangeVariable as HandleChangeVariableRack}
              />
            );
          }

          if (isStocksVariableName(block.value)) {
            return (
              <ChipVariableNameStock
                key={`${block.value}-${index}`}
                index={index}
                value={block.value}
                blockData={block}
                deleteVariable={() => deleteValue(index)}
                handleChangeVariable={handleChangeVariable as HandleChangeVariableStockZone}
              />
            );
          }

          // eslint-disable-next-line no-console
          console.error(`Variable name is not valid: ${block.value}`);

          return <React.Fragment key={index}></React.Fragment>;
        }

        return (
          <Chip
            key={`${block.value}-${index}`}
            onClick={(e) => {
              const blockValue = block.value;
              if (props.inputRef && props.inputRef.value !== '') {
                props.inputRef.blur();
              }

              let secondValue = index;
              setValuesTextField((v) => {
                secondValue = v.indexOf(block);
                const nv = [...v];
                nv.splice(secondValue, 1);

                return nv;
              });
              setPosCursor((prev) => {
                return secondValue;
              });

              setName(blockValue);
            }}
            label={block.value}
            variant="outlined"
            sx={{
              marginRight: theme.spacing(0.25),
              marginLeft: theme.spacing(0.25),
            }}
          />
        );
      })}
    </>
  );
}
