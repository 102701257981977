import type { Action } from 'redux';

export enum CoreActionTypes {
  Init = '[Core] Init',
}

export interface Init extends Action {
  type: CoreActionTypes.Init;
  payload: Record<string, never>;
}

export function initAction(): Init {
  return { type: CoreActionTypes.Init, payload: {} };
}

export type CoreActionsUnion = Init;
