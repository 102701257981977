import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { BboxLibCir } from 'librarycircuit/camera-actions';
import type { Vector3 } from 'three';

function getInitState(): { position: Vector3 | BboxLibCir | null; animated?: boolean } {
  return { position: null, animated: true };
}

const initialState = getInitState();

const cameraPositionSlice = createSlice({
  name: 'cameraPosition',
  initialState,
  reducers: {
    setCameraPosition: (
      state,
      action: PayloadAction<{ position: Vector3 | BboxLibCir | null; animated?: boolean }>
    ) => {
      return { ...action.payload, animated: action.payload.animated ?? true };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCameraPosition } = cameraPositionSlice.actions;

export const cameraPositionReducer = cameraPositionSlice.reducer;
