import { saveRackAction } from 'actions/racks';
import store from 'store';
import { canBeUndefined } from 'utils/ts/is-defined';
import { updateStockZone } from './stockzones';

export async function computeAgainAllRacks(): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, 0));

  const circuitState = store.getState().circuit.present;
  const racksIds = circuitState.racks.ids;
  const racks = circuitState.racks.entities;

  // eslint-disable-next-line no-console
  console.log('Recomputing all the racks...');
  // eslint-disable-next-line no-console
  console.log(`${racksIds.length} racks found`);

  for (let i = 0; i < racksIds.length; i++) {
    const rackId = racksIds[i];
    const rack = canBeUndefined(racks[rackId]);

    if (!rack) {
      // eslint-disable-next-line no-console
      console.error(`Rack with id ${rackId} not found`);
      continue;
    }

    store.dispatch(
      saveRackAction({
        id: rackId,
      })
    );

    if (i % 100 === 0 || i === racksIds.length - 1) {
      // eslint-disable-next-line no-console
      console.log(`${i}/${racksIds.length} racks processed (${((i / racksIds.length) * 100).toFixed(0)}%)`);
      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  // eslint-disable-next-line no-console
  console.log('All racks recomputed');
}

export async function computeAgainAllStockZones(): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, 0));

  const circuitState = store.getState().circuit.present;
  const stockZonesIds = circuitState.stockZones.ids;
  const stockZones = circuitState.stockZones.entities;

  // eslint-disable-next-line no-console
  console.log('Recomputing all the stock zones...');
  // eslint-disable-next-line no-console
  console.log(`${stockZonesIds.length} stock zones found`);

  for (let i = 0; i < stockZonesIds.length; i++) {
    const stockZoneId = stockZonesIds[i];
    const stockZone = canBeUndefined(stockZones[stockZoneId]);

    if (!stockZone) {
      // eslint-disable-next-line no-console
      console.error(`Stock zone with id ${stockZoneId} not found`);
      continue;
    }

    updateStockZone(stockZone, {
      forceUpdateCap: true,
      forceUpdateSlots: true,
    });

    if (i % 100 === 0 || i === stockZonesIds.length - 1) {
      // eslint-disable-next-line no-console
      console.log(
        `${i}/${stockZonesIds.length} stock zones processed (${((i / stockZonesIds.length) * 100).toFixed(0)}%)`
      );
      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  // eslint-disable-next-line no-console
  console.log('All stock zones recomputed');
}
