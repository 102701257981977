import store from 'store';

/**
 * This function is called when the store changes.
 */
export function onChangeStore(): void {
  const projectName = store.getState().project.projectName;

  if (projectName) {
    sessionStorage.unsavedChanges = true;
  }
}
