import { Alert } from '@mui/material';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useCallback, useMemo } from 'react';

export const recordKeywordToColor = {
  FORKS: '#D55E00',
  TASK: '#0072B2',
  MISS: '#009E73',
  NAV: '#F0E442',
  RPK: '#E69F00',
  '3D': '#56B4E9',
  WAIT: '#A8F1FF',
  UNK: '#CC79A7',
} as const;

type CustomSeriesRenderItemParamsCoordSys = Parameters<echarts.CustomSeriesRenderItem>[0]['coordSys'];
/**
 * Needed because the type definition is missing some properties
 * @link https://github.com/apache/echarts/pull/17636#issuecomment-1419159890
 */
interface CustomSeriesRenderItemParamsCoordSysCustom extends CustomSeriesRenderItemParamsCoordSys {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface PerfoFlowStats {
  [category: string]: {
    label: string;
    startTimestamp: number;
    endTimestamp?: number;
  };
}

export interface PerfoMultipleFlowsStats {
  [flowName: string]: PerfoFlowStats;
}

interface PerfoChartProps {
  flowName: string;
  perfoFlowStats: PerfoFlowStats;
  simulationEpoch: number;
}
export function PerfoChart(props: PerfoChartProps): JSX.Element {
  const { perfoFlowStats, flowName } = props;

  const latestEventTimestamp = useMemo(() => {
    // the max value for the scale
    let latestTimestamp = 0;
    for (const category in perfoFlowStats) {
      const categoryStats = perfoFlowStats[category];
      latestTimestamp = Math.max(latestTimestamp, categoryStats.endTimestamp ?? categoryStats.startTimestamp);
    }

    return latestTimestamp;
  }, [perfoFlowStats]);

  const renderItem = useCallback(
    (
      params: echarts.CustomSeriesRenderItemParams,
      api: echarts.CustomSeriesRenderItemAPI
    ): echarts.CustomSeriesRenderItemReturn => {
      const categoryIndex = api.value(0);
      const start = api.coord([api.value(1), categoryIndex]);
      const end = api.coord([api.value(2), categoryIndex]);
      const height = api.size?.([0, 1])[1] * 0.3 || 0;
      const coordSys = params.coordSys as CustomSeriesRenderItemParamsCoordSysCustom;
      const color = api.value(4);
      const colorStr = typeof color === 'string' ? color : undefined;

      const rectShape = echarts.graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height,
        },
        {
          x: coordSys.x,
          y: coordSys.y,
          width: coordSys.width,
          height: coordSys.height,
        }
      );

      return {
        type: 'rect',
        shape: rectShape,
        transition: ['shape'],
        style: {
          fill: colorStr,
        },
      };
    },
    []
  );

  const flowStats = perfoFlowStats;
  const flowStatsCategories = Object.keys(flowStats).sort((flowStatsCategoryA, flowStatsCategoryB) => {
    const categoryStatsA = flowStats[flowStatsCategoryA];
    const categoryStatsB = flowStats[flowStatsCategoryB];

    return -categoryStatsA.startTimestamp + categoryStatsB.startTimestamp;
  });

  const yLabels = useMemo(() => {
    return flowStatsCategories.map((categoryName) => {
      const categoryStats = flowStats[categoryName];

      return categoryStats.label;
    });
  }, [flowStats, flowStatsCategories]);

  const options = useMemo(() => {
    const opt: echarts.EChartsOption = {
      title: {
        text: `${flowName}`,
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: echarts.TooltipComponentFormatterCallbackParams) => {
          if (Array.isArray(params)) return '';

          const name = params.name;
          const start: unknown = params?.value?.[1];
          const end: unknown = params?.value?.[2];
          const duration: unknown = params?.value?.[3];

          if (typeof start !== 'number' || typeof end !== 'number' || typeof duration !== 'number') return '';

          return `${params.marker}${name}<br /><b>Duration: ${duration.toFixed(
            1
          )}s</b><br /><br />Start: ${start.toFixed(1)}</b>s${duration > 0 ? `<br />End: ${end.toFixed(1)}s` : ''}`;
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '50px',
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
        },
        {
          type: 'inside',
          filterMode: 'weakFilter',
        },
      ],
      xAxis: {
        type: 'value',
        min: 0,
        max: Math.ceil(latestEventTimestamp),
        scale: true,
        axisLabel: {
          formatter: (value) => {
            return `${value}s`;
          },
        },
      },
      yAxis: {
        type: 'category',
        data: yLabels,
        splitLine: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          rotate: 0,
        },
      },

      series: [
        {
          type: 'custom',
          renderItem,
          encode: {
            x: [1, 2],
            y: 0,
          },
          itemStyle: {
            opacity: 0.8,
          },
          data: flowStatsCategories.map((categoryName, index) => {
            const categoryStats = flowStats[categoryName];
            const extractHeader = categoryStats.label.match(/\[(.*?)\]/);

            const header = extractHeader ? extractHeader[1] : undefined;
            const color = (header ? (recordKeywordToColor[header] as string | undefined) : undefined) ?? '#000000';

            return {
              name: categoryStats.label,
              value: [
                index,
                categoryStats.startTimestamp,
                categoryStats.endTimestamp ? categoryStats.endTimestamp : categoryStats.startTimestamp + 1,
                categoryStats.endTimestamp ? categoryStats.endTimestamp - categoryStats.startTimestamp : 0,
                color,
              ],
              itemStyle: {
                color,
              },
            };
          }),
        },
      ],
    };

    return opt;
  }, [flowName, flowStats, flowStatsCategories, latestEventTimestamp, renderItem, yLabels]);

  if (!flowStatsCategories.length) {
    return <Alert severity="info">No data available for this task</Alert>;
  }

  return (
    <ReactECharts
      key={flowName}
      option={options}
      style={{
        height: flowStatsCategories.length * 25,
      }}
    />
  );
}
