import * as d3 from 'd3';
import type { SimpleSelection } from 'drawings/shared';
import type { Shape } from '../elements';
import { BaseLayer } from './base.layer';

export class SVGLayer extends BaseLayer<undefined> {
  protected shapes: Map<string, Shape> = new Map();
  private shapeAddedListeners: ((shape: Shape) => void)[] = [];

  public addShape(shape: Shape): void {
    this.shapes.set(shape.id, shape);
    this.node.append(() => shape.node.node());
    this.callOnShapeAddedListeners(shape);
  }

  public clearLayer(): void {
    this.shapes.clear();
    this.node.selectAll('g').remove();
  }

  public deleteShape(shape: Shape): void {
    this.shapes.delete(shape.id);
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    // return d3.create<SVGGElement>('svg:g').attr('layer', this.name);
    return d3.create<SVGGElement>('svg:g');
  }

  protected callOnShapeAddedListeners(shape: Shape): void {
    this.shapeAddedListeners.forEach((listener) => listener(shape));
  }

  public onShapeAdded(fn: (shape: Shape) => void): void {
    this.shapeAddedListeners.push(fn);
  }
}
