import type { CircuitActions } from 'actions/circuit';
import { CircuitActionTypes } from 'actions/circuit';
import { produce } from 'immer';
import { CircuitService } from 'services/circuit.service';
import { getNextFreeId } from 'utils/circuit/next-free-id';
import { theme } from 'utils/mui-theme';
import type { LayerData, LayerGroupData, LayersDataContainer } from './state';

export function getLayersInitialState(): LayersDataContainer {
  const layerGroupCommon: LayerGroupData = {
    id: getNextFreeId().toString(),
    name: 'Common',
    children: [],
  };
  const layerGroupRobots: LayerGroupData = {
    id: getNextFreeId().toString(),
    name: 'Robots',
    children: [],
  };

  const initialCommon: LayerData = {
    id: getNextFreeId().toString(),
    name: 'Layer1',
    visibility: true,
    isDraft: false,
    color: theme.palette.secondary.main,
    order: 0,
  };
  layerGroupCommon.children = [initialCommon.id];
  const initialRobots: LayerData = {
    id: getNextFreeId().toString(),
    name: 'Layer2',
    visibility: true,
    isDraft: false,
    color: theme.palette.primary.main,
    order: 1,
  };
  layerGroupRobots.children = [initialRobots.id];

  return {
    selectedLayer: initialRobots.id,
    editParametersLayer: null,
    layers: {
      [initialCommon.id]: initialCommon,
      [initialRobots.id]: initialRobots,
    },
    layerGroups: {
      [layerGroupCommon.id]: layerGroupCommon,
      [layerGroupRobots.id]: layerGroupRobots,
    },
  };
}

export function layersReducer(state = getLayersInitialState(), action: CircuitActions): LayersDataContainer {
  switch (action.type) {
    case CircuitActionTypes.UpdateLayer: {
      let newState = { ...state };
      const payload = action.payload;
      const layerId = payload.layerId;
      const layer: LayerData = newState.layers[layerId];

      if (layer) {
        if (payload.visibility !== undefined) {
          newState = {
            ...newState,
            layers: {
              ...newState.layers,
              [layer.id]: {
                ...newState.layers[layerId],
                visibility: payload.visibility,
              },
            },
          };
        }

        if (payload.selectedLayer !== undefined) {
          newState = {
            ...newState,
            selectedLayer: payload.selectedLayer,
          };
        }

        if (payload.name !== undefined) {
          if (!payload.name.length) payload.name = CircuitService.generateLayerName();

          newState = {
            ...newState,
            layers: {
              ...newState.layers,
              [layer.id]: {
                ...newState.layers[layerId],
                name: payload.name,
              },
            },
          };
        }

        if (payload.isDraft !== undefined) {
          newState = {
            ...newState,
            layers: {
              ...newState.layers,
              [layer.id]: {
                ...newState.layers[layerId],
                isDraft: payload.isDraft,
              },
            },
          };
        }

        if (payload.color !== undefined) {
          newState = {
            ...newState,
            layers: {
              ...newState.layers,
              [layer.id]: {
                ...newState.layers[layerId],
                color: payload.color,
              },
            },
          };
        }

        if (payload.defaultModel !== undefined) {
          newState = {
            ...newState,
            layers: {
              ...newState.layers,
              [layer.id]: {
                ...newState.layers[layerId],
                defaultModel: payload.defaultModel,
              },
            },
          };
        }

        if (payload.defaultPattern !== undefined) {
          newState = {
            ...newState,
            layers: {
              ...newState.layers,
              [layer.id]: {
                ...newState.layers[layerId],
                defaultPattern: payload.defaultPattern,
              },
            },
          };
        }
      }

      return newState;
    }

    case CircuitActionTypes.UpdateLayerGroup: {
      let newState = { ...state };
      const payload = action.payload;
      const layerGroupId = payload.layerGroupId;
      const layerGroup: LayerGroupData = newState.layerGroups[layerGroupId];

      if (payload.name !== undefined) {
        if (!payload.name.length) payload.name = `LayerGroup-${Math.floor(Math.random() * 1000)}`;

        newState = {
          ...newState,
          layerGroups: {
            ...newState.layerGroups,
            [layerGroupId]: {
              ...layerGroup,
              name: payload.name,
            },
          },
        };
      }

      if (payload.children !== undefined) {
        newState = {
          ...newState,
          layerGroups: {
            ...newState.layerGroups,
            [layerGroupId]: {
              ...layerGroup,
              children: payload.children,
            },
          },
        };
      }

      return newState;
    }

    case CircuitActionTypes.ImportLayers: {
      const layers = action.payload.layers;
      const layerGroups = action.payload.layerGroups;
      if (!layers || !layerGroups) return state;
      const layersIds = Object.keys(layers);
      if (!layersIds.length) return state;

      const selectedLayer = layersIds[0];

      return {
        selectedLayer,
        layers,
        layerGroups,
      };
    }

    case CircuitActionTypes.AddLayer: {
      const id = action.payload.id;
      const name = action.payload.name || `Error_${Math.floor(Math.random() * 1000)}`;
      const visibility = action.payload.visibility || false;
      const color = action.payload.color || 'yellow';

      // the new order = the max order + 1
      const order =
        Object.values(state.layers).reduce((acc, layer) => {
          if (layer.order > acc) return layer.order;

          return acc;
        }, 0) + 1;

      return {
        ...state,
        selectedLayer: id,
        layers: {
          ...state.layers,
          [id]: {
            id,
            name,
            visibility,
            color,
            order,
          },
        },
      };
    }

    case CircuitActionTypes.AddLayerGroup: {
      const id = action.payload.id;
      const nameLayerGroup = action.payload.name || `Error_${Math.floor(Math.random() * 1000)}`;

      return {
        ...state,
        layerGroups: {
          ...state.layerGroups,
          [id]: {
            id,
            name: nameLayerGroup,
            children: [],
          },
        },
      };
    }

    case CircuitActionTypes.DeleteLayer: {
      return produce(state, (draftState) => {
        const layerGroupsArr = Object.values(state.layerGroups);
        layerGroupsArr.forEach((layerGroup) => {
          const index = layerGroup.children.indexOf(action.payload.layerId);
          if (index !== -1) {
            draftState.layerGroups[layerGroup.id].children.splice(index, 1);
          }
        });

        delete draftState.layers[action.payload.layerId];
      });
    }

    case CircuitActionTypes.DeleteLayerGroup: {
      return produce(state, (draftState) => {
        delete draftState.layerGroups[action.payload.layerGroupId];
      });
    }

    case CircuitActionTypes.ChangeDisplayStateParameters: {
      const display = action.payload.newState;

      if (display) {
        if (!action.payload.layerId) throw new Error('layerId missing');

        return {
          ...state,
          editParametersLayer: { id: action.payload.layerId, type: action.payload.type || 'layer' },
        };
      }

      return {
        ...state,
        editParametersLayer: undefined,
      };
    }

    case CircuitActionTypes.ChangeLayersOrder: {
      const layerId = action.payload.layerId;
      const changeIndex = action.payload.changeIndex;

      const layersArr = Object.values(state.layers).sort(sortLayersByOrder);
      const newOrder = state.layers[layerId].order + changeIndex;

      const otherLayer = layersArr.find((layer) => layer.order === newOrder);

      const newState = {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            order: newOrder,
          },
        },
      };

      if (otherLayer) {
        newState.layers[otherLayer.id] = {
          ...state.layers[otherLayer.id],
          order: state.layers[layerId].order,
        };
      }

      const newLayersArr = Object.values(newState.layers);
      const existingOrders = new Set(newLayersArr.map((layer) => layer.order));
      if (existingOrders.size !== newLayersArr.length) {
        // duplicate orders, we reassign an order to every layer
        newLayersArr.sort(sortLayersByOrder).forEach((layer, index) => {
          newState.layers[layer.id].order = index;
        });
      }

      return newState;
    }

    default:
      return state;
  }
}

export function sortLayersByOrder(layerA: LayerData, layerB: LayerData): number {
  return layerA.order - layerB.order;
}
