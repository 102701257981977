import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { removeElementsFromStations } from './flows';
import { positionIdToPositionName } from './position-id-to-position-name';

/**
 * Retrieves the missing positions in stations.
 *
 * @returns An array of strings representing the IDs of the missing positions.
 */
export function getMissingPositionsInStations(): string[] {
  const stations = store.getState().flows.stations;

  const allPositions = stations.flatMap((station) => station.positions);

  const positionsIdsAlreadyChecked = new Map<string, boolean>();
  allPositions.forEach((position) => {
    positionsIdsAlreadyChecked.set(position.id, false);
  });

  const missingPositions: string[] = [];

  allPositions.forEach((position) => {
    if (!positionsIdsAlreadyChecked.get(position.id)) {
      positionsIdsAlreadyChecked.set(position.id, true);
      const positionName = positionIdToPositionName(position.id, position.type);
      if (!positionName || positionName === 'unknown') {
        missingPositions.push(position.id);
      }
    }
  });

  return missingPositions;
}

/**
 * Deletes the missing positions from stations.
 */
export function deleteMissingPositionsInStations(): void {
  const missingPositions = getMissingPositionsInStations();

  if (missingPositions.length > 0) {
    store.dispatch(removeElementsFromStations(missingPositions));

    SnackbarUtils.info(
      `${missingPositions.length} missing position${
        missingPositions.length > 1 ? 's have' : ' has'
      } been removed from their associated flows.`
    );
  }
}
