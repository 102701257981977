import { CollapseMore } from 'components/utils/collapse-more';
import { ImportSimulationConfigBtn } from './import-simulation-config';
import { SelectBalyoSimulation } from './select-balyo-simulation';

interface SimulationSettingsSectionProps {
  projectOpened: boolean;
}
export function SimulationSettingsSection(props: SimulationSettingsSectionProps): JSX.Element {
  const { projectOpened } = props;

  return (
    <CollapseMore title="Simulation settings">
      {projectOpened && <ImportSimulationConfigBtn />} <SelectBalyoSimulation />
    </CollapseMore>
  );
}
