import { Zone } from 'drawings/editor-elements';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitZone } from 'models/circuit';
import { useEffect, useMemo, useRef } from 'react';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface ZoneComponentProps {
  zone: CircuitZone;
  id: string;
  selected?: boolean;
}

export function ZoneComponent(props: ZoneComponentProps): JSX.Element {
  const zone = props.zone;
  const ref = useRef<SVGSVGElement>(null);

  const shape = useMemo(() => {
    if (props.id && zone && zone.id) {
      return new Zone(zone.id.toString(), zone.geometry, projection, zoomScale, zone.properties);
    }
  }, [props.id, zone]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();
      if (node) {
        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        ref.current.appendChild(node);
      }
    }
  }, [shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return <g ref={ref}></g>;
}
