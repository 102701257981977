import { AccessTime, Delete, FileDownload, FileUpload } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type {
  NewTaskDispatcherWithTimeAndSerialsAndPrev,
  NewTasksDispatcherWithTime,
  ReplayTrigger,
} from 'models/simulation';
import { useCallback, useMemo } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addTasksToReplayTrigger, removeTaskFromReplayTrigger } from 'simulation/triggers';
import store, { useAppDispatch, useAppSelector } from 'store';
import { getAllPositions } from 'utils/circuit/get-all-positions';
import { theme } from 'utils/mui-theme';
import { formatTime } from 'utils/time';
import { isDefined } from 'utils/ts/is-defined';

const csvSeparator = ';';
const csvFormat = `ID CSV${csvSeparator}Flow Name${csvSeparator}Source${csvSeparator}Destination${csvSeparator}Robot Serials${csvSeparator}Start time (optional)${csvSeparator}Prev`;

interface ReplayTriggerConfigurationProps {
  trigger: ReplayTrigger;
}

export function ReplayTriggerConfiguration(props: ReplayTriggerConfigurationProps): JSX.Element {
  const { trigger } = props;

  return (
    <Border
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <InportTasksCsvBtn trigger={trigger} />

      {trigger.tasks.length > 0 && (
        <ListTaskReplayTrigger
          trigger={trigger}
          sx={{
            marginTop: theme.spacing(1),
          }}
        />
      )}

      {trigger.tasks.length > 0 && (
        <DownloadTasksCsvBtn
          trigger={trigger}
          sx={{
            marginTop: theme.spacing(1),
          }}
        />
      )}
    </Border>
  );
}

interface InportTasksCsvBtnProps {
  /** The ReplayTrigger object containing the tasks to be imported */
  trigger: ReplayTrigger;
  /** The separator character to be used in the CSV file, defaults to a predefined separator if not provided */
  separator?: string;
  /** The format string for the CSV file header, defaults to a predefined format if not provided */
  format?: string;
}
function InportTasksCsvBtn(props: InportTasksCsvBtnProps): JSX.Element {
  const { trigger, separator = csvSeparator, format = csvFormat } = props;

  const dispatch = useAppDispatch();

  const handleImportTasks = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }

      let nbLinesIssues = 0;

      const allPositions = getAllPositions({
        selectedPositionType: 'all',
      });

      const reader = new FileReader();
      reader.onload = () => {
        const csv = reader.result as string;
        const lines = csv.split('\n');

        //if the csv imported is based on the old pattern
        const isCsvWithoutIdAndPrev = lines[0].split(separator)[0] === 'Flow Name';

        const tasks: NewTaskDispatcherWithTimeAndSerialsAndPrev[] = lines
          .map((line, index) => {
            if (index === 0) return null;

            const newLine = isCsvWithoutIdAndPrev ? ';' + line + ';' : line;

            const [id, flowName, sourceName, destName, robotSerials, startTime, prev] = newLine.split(separator);

            if (!flowName || !sourceName || !destName) {
              nbLinesIssues++;

              return null;
            }

            const time = startTime ? parseInt(startTime, 10) : 0;
            const robotSerialsArray = robotSerials
              .split(',')
              .map((serial) => serial.trim())
              .filter((serial) => serial.length > 0);

            const src = allPositions.find((pos) => pos.name === sourceName);
            const dst = allPositions.find((pos) => pos.name === destName);

            if (!src) {
              // eslint-disable-next-line no-console
              console.warn(`Source position "${sourceName}" not found`);
              nbLinesIssues++;

              return null;
            }

            if (!dst) {
              // eslint-disable-next-line no-console
              console.warn(`Destination position "${destName}" not found`);
              nbLinesIssues++;

              return null;
            }

            const isWhitespaceString = (str: string): boolean => !str.replace(/\s/g, '').length;
            const isPrevExist = !!(prev && prev !== '\r' && !isWhitespaceString(prev));

            return {
              id: id && id !== '' ? id : index.toString(),
              flowName,
              src: parseInt(src.id, 10),
              dst: parseInt(dst.id, 10),
              time,
              startPoint: src.name,
              destinationPoint: dst.name,
              serials: robotSerialsArray.length > 0 ? robotSerialsArray : undefined,
              prev: isPrevExist,
            };
          })
          .filter(isDefined);

        const sortedTasksByTime = tasks.sort((a, b) => a.time - b.time);

        const formatedTasks: (NewTaskDispatcherWithTimeAndSerialsAndPrev | NewTasksDispatcherWithTime)[] = [];
        let currentTasks: NewTaskDispatcherWithTimeAndSerialsAndPrev[] = [];

        for (let i = 0; i < sortedTasksByTime.length; i++) {
          const currentItem = sortedTasksByTime[i];

          if (currentTasks.length === 0 || currentItem.prev) {
            currentTasks.push(currentItem);
          } else {
            formatedTasks.push({
              tasks: currentTasks,
              time: currentTasks[currentTasks.length - 1].time,
            });
            currentTasks = [currentItem];
          }
        }

        if (currentTasks.length > 0) {
          formatedTasks.push({
            tasks: currentTasks,
            time: currentTasks[currentTasks.length - 1].time,
          });
        }

        dispatch(addTasksToReplayTrigger({ triggerId: trigger.id, tasks: formatedTasks }));

        if (tasks.length > 0) {
          SnackbarUtils.success(
            `${tasks.length} tasks imported${
              nbLinesIssues > 0 ? ` (${nbLinesIssues} lines with issues and not imported)` : ''
            }`
          );
        } else if (nbLinesIssues > 0) {
          SnackbarUtils.error(`All lines had issues and not imported (${nbLinesIssues} lines)`);
        } else if (tasks.length === 0) {
          SnackbarUtils.warning(`Empty file, no tasks imported`);
        }

        e.target.value = '';
      };

      reader.readAsText(file);
    },
    [dispatch, separator, trigger.id]
  );

  return (
    <Stack direction="row" spacing={1}>
      <Button component="label" variant="contained" color="inherit" startIcon={<FileUpload />} fullWidth>
        Import tasks from CSV
        <input type="file" hidden accept="text/csv" onChange={handleImportTasks} />
      </Button>
      <HelpIconTooltip
        sx={{
          fontSize: '1.5em',
          position: 'relative',
          top: '0.25em',
        }}
        title={
          <>
            CSV format must complies with the following format:
            <br />
            {format}
            <br />
            <ul>
              <li>
                <strong>Flow Name</strong>: Name of an existing flow
              </li>
              <li>
                <strong>Source</strong>: Name of the source position (slot, point, rack, stock zone, stock line)
              </li>
              <li>
                <strong>Destination</strong>: Name of the destination point (slot, point, rack, stock zone, stock line)
              </li>
              <li>
                <strong>Robot Serials</strong>: Serials of the robots to use (separated by a comma), empty = all robots
                allowed
              </li>
              <li>
                <strong>Start time</strong>: Duration after the task needs to be created after the beginning of the
                simulation, in seconds (optional, 0 if omitted)
              </li>
            </ul>
          </>
        }
      />
    </Stack>
  );
}

interface ListTaskReplayTriggerProps {
  /** The trigger object containing information about the replay trigger */
  trigger: ReplayTrigger;
  /** Optional style properties to apply to the component */
  sx?: SxProps<Theme>;
}
function ListTaskReplayTrigger(props: ListTaskReplayTriggerProps): JSX.Element {
  const { trigger, sx } = props;

  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.flows.flows);

  const handleDeleteTask = useCallback(
    (taskIndex: number) => {
      dispatch(removeTaskFromReplayTrigger({ triggerId: trigger.id, taskIndex }));
    },
    [dispatch, trigger.id]
  );

  const flowNamesWithIssues = useMemo(() => {
    const set = new Set<string>();
    const availableFlowNames = new Set(flows.map((flow) => flow.name));

    trigger.tasks.forEach((task) => {
      const flowNames = 'tasks' in task ? task.tasks.map((t) => t.flowName) : [task.flowName];
      flowNames.forEach((flowName) => {
        if (!availableFlowNames.has(flowName)) {
          set.add(flowName);
        }
      });
    });

    return set;
  }, [flows, trigger.tasks]);

  const sxFlowNameIssue = useMemo(() => {
    return {
      color: theme.palette.error.main,
      textDecoration: 'underline dotted',
    };
  }, []);

  const maxNbTasks = 100;

  return (
    <List dense sx={sx}>
      {trigger.tasks.map((task, index) => {
        if (index >= maxNbTasks) return null;

        const flowNames = 'tasks' in task ? task.tasks.map((t) => t.flowName) : [task.flowName];
        const flowNamesIssue = flowNames.map((flowName) => flowNamesWithIssues.has(flowName));

        return (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTask(index)}>
                <Delete fontSize="small" />
              </IconButton>
            }
          >
            <ListItemText
              primary={flowNames.map((flowName, i) => {
                const thisTask = 'tasks' in task ? task.tasks[i] : task;
                const flowNameIssue = flowNamesIssue[i];

                return (
                  <Box
                    component="div"
                    sx={flowNameIssue ? sxFlowNameIssue : undefined}
                    title={flowNameIssue ? `Flow "${thisTask.flowName}" does not exist` : undefined}
                  >
                    {thisTask.flowName} / {thisTask.startPoint} → {thisTask.destinationPoint}
                  </Box>
                );
              })}
              secondary={
                <>
                  <AccessTime
                    sx={{
                      position: 'relative',
                      top: '0.125em',
                      marginRight: theme.spacing(0.5),
                      fontSize: '1em',
                    }}
                  />
                  {task.time.toFixed(0)} s
                </>
              }
            />
          </ListItem>
        );
      })}

      {trigger.tasks.length >= maxNbTasks && (
        <ListItem>
          <ListItemText primary="..." secondary={`Only the first ${maxNbTasks} tasks are displayed`} />
        </ListItem>
      )}
    </List>
  );
}

interface DownloadTasksCsvBtnProps {
  /** The ReplayTrigger object containing the tasks to be downloaded */
  trigger: ReplayTrigger;
  /** The separator character to be used in the CSV file, defaults to a predefined separator if not provided */
  separator?: string;
  /** The format string for the CSV file header, defaults to a predefined format if not provided */
  format?: string;
  /** The sx props to be applied to the Button component */
  sx?: SxProps<Theme>;
}
function DownloadTasksCsvBtn(props: DownloadTasksCsvBtnProps): JSX.Element {
  const { trigger, separator = csvSeparator, format = csvFormat, sx } = props;

  const handleDownloadTasks = useCallback(() => {
    const projectName = store.getState().project.projectName;

    const tasks = trigger.tasks.flatMap((task) =>
      'tasks' in task
        ? task.tasks.map((t, i) => ({
            ...t,
            time: task.time,
            //if i !==0, this means that he there is a task to be done first
            prev: i !== 0,
          }))
        : task
    );

    const lines = tasks.map((task, i) => {
      const robotSerials = task.serials ? task.serials.join(',') : '';
      const time = task.time;
      const id = i + 1;
      let prev: number | undefined = undefined;

      if (task.prev) {
        prev = id - 1;
      }

      return `${id}${separator}${task.flowName}${separator}${task.startPoint}${separator}${
        task.destinationPoint
      }${separator}${robotSerials}${separator}${time}${prev ? `${separator}${prev}` : ''}`;
    });

    const csv = [format, ...lines].join('\n');

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `tasks_${projectName ?? 'unknown'}_${formatTime(new Date())}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [format, separator, trigger.tasks]);

  return (
    <Button variant="contained" color="inherit" startIcon={<FileDownload />} onClick={handleDownloadTasks} sx={sx}>
      Download tasks as CSV
    </Button>
  );
}
