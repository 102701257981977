import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { zoomToShape } from 'utils/circuit/zoom-to-shape';
import type { MissingCellTemplateError } from 'utils/export/generate-xml.worker';

export function manageMissingCellTemplateError(e: MissingCellTemplateError): void {
  // eslint-disable-next-line no-console
  console.log('Error during the xml generation due to a cell template missing in a rack', e);

  const rackId = e.rackId;
  const rackName = rackId ? store.getState().circuit.present.racks.entities[rackId]?.properties.name : undefined;

  if (!rackId || !rackName) {
    // eslint-disable-next-line no-console
    console.error('Missing rack id or name', e);

    SnackbarUtils.error(`The generation of the XML file failed. Please contact the support to get help.`);

    return;
  }

  const snackBarRef = SnackbarUtils.toast(
    <Box component="div">
      The rack{' '}
      <Box component={'span'} sx={{ fontWeight: 'bold' }}>
        {rackName}
      </Box>{' '}
      is missing a cell template.
      <br />
      Please add it to be able to export the project.
    </Box>,
    {
      action: (
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              zoomToShape(rackId);

              SnackbarUtils.closeSnackbar(snackBarRef);
            }}
            sx={{
              textTransform: 'none',
            }}
          >
            Zoom to {rackName}
          </Button>
        </>
      ),
      autoHideDuration: 10 * 1000,
    }
  );
}
