import * as d3 from 'd3';
import type { SimpleSelection } from 'drawings/shared';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import { getConfig } from 'utils/config';
import { LayerNames } from './shared';
import { SVGLayer } from './svg.layer';

const TICKS_LABELS_SPACING_FROM_EDGES = parseInt(getConfig('editor').grid.tickLabelsSpacingFromEdges, 10);

enum AxisDirections {
  X = 'x',
  Y = 'y',
}

interface AxisProperties {
  domainEnd: number;
  rangeEnd: number;
  axisFunction: <Domain extends d3.AxisDomain>(scale: d3.AxisScale<Domain>) => d3.Axis<Domain>;
  ticks: number;
  ticksSize: number;
  ticksPadding: number;
}

class Axis {
  public readonly node: SimpleSelection<SVGGElement, undefined>;
  private lastTransformEvent: d3.ZoomTransform | undefined;
  private direction: AxisDirections;
  private properties: AxisProperties | undefined;
  private scale: d3.ScaleLinear<number, number> | any;
  private axis:
    | d3.Axis<
        | number
        | {
            valueOf(): number;
          }
      >
    | any;
  constructor(direction: AxisDirections, width: number, height: number) {
    this.direction = direction;
    this.updateGrid(width, height);
    this.node = this.createNode();
    this.makeTicksStyle();
  }

  private handlePropertiesChanged(): void {
    if (!this.properties) {
      return;
    }

    this.scale = d3.scaleLinear().domain([0, this.properties.domainEnd]).range([0, this.properties.rangeEnd]);

    this.axis = this.properties
      .axisFunction(this.scale)
      .ticks(this.properties.ticks)
      .tickSize(this.properties.ticksSize)
      .tickFormat((d) => `${parseFloat((Number(d) / DISPLAY_UNIT_FACTOR).toFixed(3))}`)
      .tickPadding(TICKS_LABELS_SPACING_FROM_EDGES - this.properties.ticksPadding);
  }

  private createNode(): SimpleSelection<SVGGElement, undefined> {
    return d3
      .create<SVGGElement>('svg:g')
      .attr('direction', this.direction)
      .attr('pointer-events', 'none')
      .call(this.axis);
  }

  public updateGrid(width: number, height: number): void {
    if (this.direction === AxisDirections.X) {
      this.properties = {
        domainEnd: width,
        rangeEnd: width,
        axisFunction: d3.axisBottom,
        ticks: (width / height) * 10,
        ticksSize: height,
        ticksPadding: height,
      };
    } else {
      this.properties = {
        domainEnd: -height,
        rangeEnd: height,
        axisFunction: d3.axisRight,
        ticks: 10,
        ticksSize: width,
        ticksPadding: width,
      };
    }

    this.handlePropertiesChanged();
    if (this.node) {
      this.node.call(this.axis);
      this.makeTicksStyle();
    }

    if (this.lastTransformEvent) {
      this.rescale(this.lastTransformEvent);
    }
  }

  private makeTicksStyle(): void {
    this.node.selectAll('.tick text').attr('font-size', '15').attr('fill', 'grey');

    this.node.selectAll('.tick line').attr('stroke', '#ebebeb');
    this.node.selectAll('path').attr('stroke', 'none');
  }

  public rescale(transformEvent: d3.ZoomTransform): void {
    if (this.direction === AxisDirections.X) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.node.call(this.axis.scale(transformEvent.rescaleX(this.scale)));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.node.call(this.axis.scale(transformEvent.rescaleY(this.scale)));
    }

    this.lastTransformEvent = transformEvent;
    this.makeTicksStyle();
  }
}

export class AxisLayer extends SVGLayer {
  private xAxis: Axis;
  private yAxis: Axis;

  constructor(width: number, height: number) {
    super(LayerNames.Axis);
    this.xAxis = new Axis(AxisDirections.X, width, height);
    this.yAxis = new Axis(AxisDirections.Y, width, height);

    this.node.append(() => this.xAxis.node.node());
    this.node.append(() => this.yAxis.node.node());
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    return d3.create<SVGGElement>('svg:g').attr('axis', '');
  }

  public rescale(transformEvent: d3.ZoomTransform): void {
    this.xAxis.rescale(transformEvent);
    this.yAxis.rescale(transformEvent);
  }

  public updateDimensions(width: number, height: number): void {
    this.xAxis.updateGrid(width, height);
    this.yAxis.updateGrid(width, height);
  }
}
