import { getClosestPointInSegment } from 'librarycircuit/utils/utils';

export const nMinEdges = 3;
export const nMaxEdges = 4;

interface ZoneAddEdgeProps {
  /** Coords of the zone (m) */
  coords: [number, number][];
  /** A point near the zone (usually entered by the user) */
  referencePoint: [number, number];
}

/**
 *  Add an edge to the zone near the referencePoint given
 * @param props
 * @returns new coords of the zone (m)
 */
export function zoneAddEdge(props: ZoneAddEdgeProps): [number, number][] | undefined {
  const { coords, referencePoint } = props;

  // get the closest point which is on the segments between the edges
  const nEdges = coords.length;

  /** A zone should be compose of at least 3 edges */
  if (nEdges >= nMaxEdges + 1) {
    // + 1 because the last point === the first point
    // eslint-disable-next-line no-console
    console.warn('Can not have more than 4 edges for zones currently');
    // SnackbarUtils.warning('Can not have more than 4 edges');

    return undefined;
  }

  let newPoint: [number, number] | undefined = undefined;
  let minDist2 = Infinity;
  let iNewPoint: number | undefined = undefined;
  // Only loop until length -1 because the last point === the first point
  for (let i = 0; i < nEdges - 1; i++) {
    const edgeStart = coords[i];
    const edgeEnd = coords[i + 1];

    const [closestPoint] = getClosestPointInSegment(referencePoint, edgeStart, edgeEnd);
    const dist2 = (closestPoint[0] - referencePoint[0]) ** 2 + (closestPoint[1] - referencePoint[1]) ** 2;
    if (dist2 < minDist2) {
      newPoint = closestPoint as [number, number];
      iNewPoint = i + 1; // +1 because we want to add the new point between the edge i and i+1
      minDist2 = dist2;
    }
  }

  if (!newPoint || iNewPoint === undefined) {
    // eslint-disable-next-line no-console
    console.warn('Could not find a new point', coords, referencePoint);
    // SnackbarUtils.warning('Could not find a place to add a point to the zone');

    return undefined;
  }

  // Add the new point while making sure the first point is the same as the last point
  const newCoords = coords.slice();
  newCoords.splice(iNewPoint, 0, newPoint);

  return newCoords;
}

/**
 *  Remove an edge to the zone near the referencePoint given
 * @param props
 * @returns new coords of the zone (m)
 */
export function zoneRemoveEdge(props: ZoneAddEdgeProps): [number, number][] | undefined {
  const { coords, referencePoint } = props;
  const nEdges = coords.length;

  // polygones should have at least 3 edges, so do not remove more
  if (nEdges <= nMinEdges + 1) {
    // +1 because the last point === the first point
    // eslint-disable-next-line no-console
    console.warn('Can not have less than 3 edges');
    // SnackbarUtils.warning('Can not have less than 3 edges');

    return undefined;
  }

  // Get the minimum distance/index between the reference point and the edges
  let iClosestEdge: number | undefined = 0;
  let minDist2 = Infinity;
  // Only loop until length -1 because the last point === the first point
  for (let i = 0; i < nEdges - 1; i++) {
    const dist2 = (coords[i][0] - referencePoint[0]) ** 2 + (coords[i][1] - referencePoint[1]) ** 2;
    if (dist2 < minDist2) {
      iClosestEdge = i;
      minDist2 = dist2;
    }
  }

  const newCoords = coords.filter((_, i) => i !== iClosestEdge);
  // Make sure the first point is the same as the last point (while keeping the same size)
  const isFirstOrLastPoint = iClosestEdge === 0 || iClosestEdge === nEdges - 1;
  if (isFirstOrLastPoint) {
    newCoords[newCoords.length - 1] = newCoords[0];
  }

  return newCoords;
}
