import type { CircuitZone } from 'models/circuit';
import { roundCoordinates } from 'utils/helpers';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: ZonesActionTypes,
  creators: {
    create: createZoneAction,
    createFailure: createZoneFailureAction,
    createSuccess: createZoneSuccessAction,
    delete: deleteZoneAction,
    deleteFailure: deleteZoneFailureAction,
    deleteSuccess: deleteZoneSuccessAction,
    loadOne: loadZoneAction,
    loadOneFailure: loadZoneFailureAction,
    loadOneSuccess: loadZoneSuccessAction,
    saveFailure: saveZoneFailureAction,
    saveSuccess: saveZoneSuccessAction,
    select: selectZoneAction,
  },
} = createEntityActions<CircuitZone>('Zones');

const {
  creators: { save },
} = createEntityActions<CircuitZone>('Zones');

export function saveZoneAction(payload: Partial<CircuitZone> & RoadEditorAction['payload']): SaveZone {
  let action = save(payload);
  if (action.payload.geometry) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        geometry: {
          ...action.payload.geometry,
          coordinates: action.payload.geometry.coordinates.map((coords) =>
            coords.map((coord) => roundCoordinates(coord))
          ),
        },
      },
    };
  }

  return action;
}

export interface CreateZone extends CreateAction<CircuitZone> {}
export interface CreateZoneFailure extends CreateFailureAction {}
export interface CreateZoneSuccess extends CreateSuccessAction<CircuitZone> {}
export interface DeleteZone extends DeleteAction {}
export interface DeleteZoneFailure extends DeleteFailureAction {}
export interface DeleteZoneSuccess extends DeleteSuccessAction {}
export interface LoadZone extends LoadOneAction {}
export interface LoadZoneFailure extends LoadOneFailureAction {}
export interface LoadZoneSuccess extends LoadOneSuccessAction<CircuitZone> {}
export interface SaveZone extends SaveAction<CircuitZone> {}
export interface SaveZoneFailure extends SaveFailureAction {}
export interface SaveZoneSuccess extends SaveSuccessAction<CircuitZone> {}
export interface SelectZone extends SelectAction {}

export type ZoneActions =
  | CreateZone
  | CreateZoneFailure
  | CreateZoneSuccess
  | DeleteZone
  | DeleteZoneFailure
  | DeleteZoneSuccess
  | LoadZone
  | LoadZoneFailure
  | LoadZoneSuccess
  | SaveZone
  | SaveZoneFailure
  | SaveZoneSuccess
  | SelectZone;
