import type { Awareness } from 'y-protocols/awareness';
import type { WebsocketProvider } from 'y-websocket';
import { Doc } from 'yjs';

/* Maximum number of concurrent editing users as we have to create a int32 id which can't excede the max value 2,147,483,647. 
The first 2 digits are used for the sessionId starting with 01 as 00 is reserved for non-multiplayer changes.
Each user can have up to 99,999,999 shapes created.
The shapeId ranges from 0 to 2,099,999,999.
shapeId is built as follow : `{sessionId}{nextFreeId}` */
export const MAX_EDITING_USERS = 20;

export const USER_COLORS = [
  '#1abc9c',
  '#3498db',
  '#9b59b6',
  '#e67e22',
  '#e74c3c',
  '#f1c40f',
  '#2ecc71',
  '#2980b9',
  '#8e44ad',
  '#d35400',
  '#c0392b',
  '#f39c12',
  '#27ae60',
  '#2c3e50',
  '#ED1C24',
  '#F15A24',
  '#F7931E',
  '#FFC20E',
  '#FFF200',
  '#8DC63F',
  '#39B54A',
  '#00A651',
  '#00AEEF',
  '#0072C6',
  '#0054A6',
  '#2E3192',
  '#662D91',
  '#912F8B',
  '#EC008C',
  '#ED145B',
];

/* Throttle the data you send to the other users so you don't spam them of your awareness info (cursor ...) */
export const THROTTLE = 25; // ms

/* YJS Globals */
export const localDoc = new Doc();

export let remoteDoc: Doc | undefined;
export const setRemoteDoc = (value?: Doc): void => {
  remoteDoc = value;
};

export let provider: WebsocketProvider | undefined;
export const setProvider = (value?: WebsocketProvider): void => {
  provider = value;
};

export let awareness: Awareness | undefined;
export const setAwareness = (value?: Awareness): void => {
  awareness = value;
};

export let lastCircuitTransactionOrigin: string | undefined;
export const setLastCircuitTransactionOrigin = (value?: string): void => {
  lastCircuitTransactionOrigin = value;
};

export let clearedHistoryDueToMultiplayerChanges = false;
export const setClearedHistoryDueToMultiplayerChanges = (value: boolean): void => {
  clearedHistoryDueToMultiplayerChanges = value;
};

export let projectHost = false;
export const setProjectHost = (value: boolean): void => {
  projectHost = value;
};

export let stopSendingInstallXML = false;
export const setStopSendingInstallXML = (value: boolean): void => {
  stopSendingInstallXML = value;
};

export interface UsersHighlight {
  usersHighlight: {
    id: string;
    color: string;
    selectedShapesId: string[];
  }[];
}

export let formerSelectedShapes: UsersHighlight['usersHighlight'] = [];
export const setFormerSelectedShapes = (value: UsersHighlight['usersHighlight']): void => {
  formerSelectedShapes = value;
};

export const isShapeInSelectedShapes = (shapeId: string): boolean =>
  formerSelectedShapes.some((user) => user.selectedShapesId.includes(shapeId));
