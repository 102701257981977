import { Segment } from 'drawings/editor-elements';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitSegment } from 'models/circuit';
import type { lineStrokeSize } from 'models/drawings';
import { useEffect, useMemo, useRef } from 'react';

interface SegmentProps {
  segment: CircuitSegment;
  id: string;
  selected?: boolean;
  strokeSize?: lineStrokeSize;
  displayGabarit?: boolean;
}

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

export function SegmentComponent(props: SegmentProps): JSX.Element {
  const segment = props.segment;
  const ref = useRef<SVGSVGElement>(null);

  const shape = useMemo(() => {
    if (props.id && segment && segment.id) {
      return new Segment(segment.id.toString(), segment.geometry, projection, zoomScale, segment.properties, {
        strokeSize: props.strokeSize ?? 's',
        displayGabarit: props.displayGabarit,
      });
    }
  }, [props.id, props.strokeSize, segment, props.displayGabarit]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();
      const clickElement = node?.querySelector('[main-shape]')?.cloneNode() as SVGElement;

      if (node && clickElement) {
        // this element is a transparent element to handle clicks
        // on a larger area than juste the shape
        clickElement.removeAttribute('main-shape');
        clickElement.style.removeProperty('stroke-width');
        clickElement.classList.add('select-shape-helper');

        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        if (!shape.properties.rack) {
          node.insertBefore(clickElement, node.firstChild);
        }

        ref.current.appendChild(node);
      }
    }
  }, [shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return <g ref={ref}></g>;
}
