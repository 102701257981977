import { centerOfMass, polygon } from '@turf/turf';
import type { CircuitStockZone } from 'models/circuit';
import store from 'store';
import { toRad } from 'utils/helpers';
import { canBeUndefined } from 'utils/ts/is-defined';
import type {
  EdgeVDA5050,
  HandlingStation,
  HandlingStationAccessNode,
  HandlingStationGroupVDA5050,
} from './vda5050-model';

interface GenerateHandlingStationGroupFromStockZoneProps {
  stockZone: CircuitStockZone;
  navigationGraphId: string;
  circuitEdges: EdgeVDA5050[];
}

interface GenerateHandlingStationGroupFromStockZoneRes {
  handlingStationGroup: HandlingStationGroupVDA5050;
  nodesToRemove: Set<string>;
}

const defaultStockZoneAvailableHeight = 5.0; // [m]

export function generateHandlingStationGroupFromStockZone(
  props: GenerateHandlingStationGroupFromStockZoneProps
): GenerateHandlingStationGroupFromStockZoneRes {
  const { stockZone, navigationGraphId, circuitEdges } = props;

  const handlingStations: HandlingStation[] = [];
  const segmentsIds = store.getState().circuit.present.segments.ids;
  const segments = store.getState().circuit.present.segments.entities;

  const nodesToRemove = new Set<string>();

  const theta = toRad(stockZone.properties.cap);

  stockZone.properties.slots.forEach((stockLine) => {
    const extendedLengthId = segmentsIds.find((segmentId) => {
      const segment = canBeUndefined(segments[segmentId]);

      return segment?.properties.stockLine === stockLine.id;
    });

    if (!extendedLengthId) {
      // eslint-disable-next-line no-console
      console.warn(`No extended length found for stockLine ${stockLine.id}`);

      return;
    }

    const extendedLengthSegment = segments[extendedLengthId];

    stockLine.slots.forEach((slot, i) => {
      if (slot.disabled) return;

      const polygonGeometrySlot = polygon([[...slot.geometry, slot.geometry[0]]]);
      const centerOfSlot = centerOfMass(polygonGeometrySlot).geometry.coordinates.map((c) => c / 100); // [m]

      const d = slot.slotSize.length / 2;

      const mapX = centerOfSlot[0] + d * Math.cos(theta);
      const mapY = -(centerOfSlot[1] - d * Math.sin(theta));
      const mapZ = 0;

      const extendedLengthPortion = extendedLengthSegment.properties.portions[0];
      if (!extendedLengthPortion) {
        // eslint-disable-next-line no-console
        console.error(`No portion found for segment ${extendedLengthId}`);

        return;
      }

      const associatedEdge = circuitEdges.find((edge) => {
        return edge.id === extendedLengthPortion.id;
      });
      const startNodeId = associatedEdge?.startNodeId;
      const endNodeId = associatedEdge?.endNodeId;

      if (!endNodeId) {
        // eslint-disable-next-line no-console
        console.error(`No start node found for segment ${extendedLengthId}`, {
          associatedEdge,
          extendedLengthPortion,
          circuitEdges,
        });

        return;
      }

      if (startNodeId) nodesToRemove.add(startNodeId);

      const accessNode: HandlingStationAccessNode = {
        navigationGraphId,
        nodeId: endNodeId,
      };

      const newHandlingStation: HandlingStation = {
        id: `${stockLine.name}:${i}`,
        width: slot.slotSize.width,
        height: slot.slotSize.height || defaultStockZoneAvailableHeight,
        length: slot.slotSize.length,
        mapX,
        mapY,
        mapZ,
        accessNodes: [accessNode],
      };

      handlingStations.push(newHandlingStation);
    });
  });

  const handlingStationGroup: HandlingStationGroupVDA5050 = {
    id: stockZone.id as string,
    controlled: false,
    justInSequence: false,
    waitingSpots: [],
    stationAutomaticSelectionMode: 'ALWAYS',
    handlingStations,
  };

  return {
    handlingStationGroup,
    nodesToRemove,
  };
}
