import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import type { AvailableModelsUnion } from './models';

export function robotNameToUrl(robotName: AvailableModelsUnion): string {
  switch (robotName) {
    case 'lowy': {
      return 'https://www.balyo.com/agv-stackers/lowy';
    }

    case 'lowy CB': {
      return 'https://www.balyo.com/agv-counterbalanced-stackers/lowy-cb';
    }

    case 'veeny': {
      return 'https://www.balyo.com/agv-vna/veeny';
    }

    case 'reachy': {
      return 'https://www.balyo.com/agv-reach-trucks/reachy';
    }

    case 'tuggy': {
      return 'https://www.balyo.com/agv-tuggers/tuggy';
    }

    case 'trucky': {
      return 'https://www.balyo.com/agv-pallet-trucks/trucky';
    }
  }

  assert<Equals<typeof robotName, never>>();

  return 'unknown';
}

export function robotNameToDescription(robotName: AvailableModelsUnion): string {
  switch (robotName) {
    case 'lowy': {
      return 'The stacker';
    }

    case 'lowy CB': {
      return 'The counterbalanced stacker';
    }

    case 'veeny': {
      return 'The VNA';
    }

    case 'reachy': {
      return 'The reach truck';
    }

    case 'tuggy': {
      return 'The tugger';
    }

    case 'trucky': {
      return 'The pallet truck';
    }
  }

  assert<Equals<typeof robotName, never>>();

  return 'unknown';
}
