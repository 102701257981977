import type { AuthActionsUnion } from 'actions';
import { AuthActionTypes } from 'actions';
import type { AuthState } from './state';
import { getInitialStateAuth } from './state';

export function authReducer(state = getInitialStateAuth(), action: AuthActionsUnion): AuthState {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess: {
      const { expiresAt } = action.payload;

      return { ...state, loggedIn: true, token: { ...state.token, expiresAt, refreshed: true, refreshing: false } };
    }

    case AuthActionTypes.Logout: {
      return { ...getInitialStateAuth() };
    }

    case AuthActionTypes.LoadUserProfile: {
      return { ...state, profile: { ...state.profile, loaded: false, loading: true } };
    }

    case AuthActionTypes.LoadUserProfileSuccess: {
      const locale = action.payload.profile.locale || state.locale;

      return { ...state, locale, profile: { ...action.payload.profile, loaded: true, loading: false } };
    }

    case AuthActionTypes.LoadUserProfileFailure: {
      return { ...state, profile: { loaded: false, loading: false } };
    }

    case AuthActionTypes.RefreshToken: {
      return { ...state, token: { ...state.token, refreshed: false, refreshing: true } };
    }

    case AuthActionTypes.RefreshTokenSuccess: {
      const { expiresAt } = action.payload;

      return { ...state, token: { ...state.token, expiresAt, refreshed: true, refreshing: false } };
    }

    case AuthActionTypes.RefreshTokenFailure: {
      return { ...state, token: { ...state.token, refreshed: false, refreshing: false } };
    }

    default:
      return state;
  }
}

export const getLocale = (state: AuthState): AuthState['locale'] => state.locale;
export const getLoggedIn = (state: AuthState): AuthState['loggedIn'] => state.loggedIn;
export const getProfile = (state: AuthState): AuthState['profile'] => state.profile;
export const getToken = (state: AuthState): AuthState['token'] => state.token;
