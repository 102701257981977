import { localDoc, remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { applyUpdate } from 'yjs';

export const useSyncYJSRemoteToLocal = (): void => {
  useLayoutEffect(() => {
    const eventHandle = (update, origin): void => {
      const isTransactionLocal = origin === 'local';
      if (isTransactionLocal) return;

      /* Temporary downgrade to v1 update beacuse of memory & speed issues */
      // applyUpdateV2(localDoc, update);
      applyUpdate(localDoc, update);
    };

    // remoteDoc?.on('updateV2', eventHandle);
    remoteDoc?.on('update', eventHandle);

    return () => {
      // remoteDoc?.off('updateV2', eventHandle);
      remoteDoc?.off('update', eventHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
