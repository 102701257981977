import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Divider, InputAdornment, Menu, MenuItem, Switch, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { textHelperDisabledStandardMode } from 'components/utils/standard-mode-helper';
import type { FootProtection, RackUpright } from 'models/circuit';
import React, { useCallback, useState } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import type { ActionsRack } from '.';

interface FootProtectionMenProps {
  /** is the menu open? */
  open: boolean;
  /** function to open or close the menu */
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** the anchor element to attach the menu */
  anchorEl: SVGSVGElement | null;
  /** the anchor origin */
  actions: ActionsRack;
  /** index of the upright in the rack.uprights array */
  index: number;
  /** upright data */
  upright: RackUpright;
  /** uprights data of the rack */
  uprights: RackUpright[];
  /** is the foot protection enabled? */
  footProtectionEnabled: boolean;
  /** reference value for the linked foot protections */
  defaultFootProtection: FootProtection;
}
export function FootProtectionMenu({
  open,
  setOpen,
  anchorEl,
  actions,
  index,
  upright,
  footProtectionEnabled,
  defaultFootProtection,
}: FootProtectionMenProps): JSX.Element {
  const standardMode = useAppSelector((state) => state.local.standardMode);

  const linked = upright.linkedFootProtection;

  const [widthTmp, setWidthTmp] = useState(
    ((linked ? defaultFootProtection.width : upright.footProtection ? upright.footProtection.width : 0) * 1000).toFixed(
      0
    )
  );
  const [heightTmp, setHeightTmp] = useState(
    (
      (linked ? defaultFootProtection.height || 0 : upright.footProtection ? upright.footProtection.height : 0) * 1000
    ).toFixed(0)
  );
  const [overflowTmp, setOverflowTmp] = useState(
    (
      (linked ? defaultFootProtection.overflow || 0 : upright.footProtection ? upright.footProtection.overflow : 0) *
      1000
    ).toFixed(0)
  );

  const updateFootProtection = useCallback(() => {
    actions.updateUpright(index, {
      ...upright,
      footProtection: footProtectionEnabled
        ? {
            ...upright.footProtection,
            enabled: true,
            width: parseFloat(widthTmp) / 1000,
            height: parseFloat(heightTmp) / 1000,
            overflow: parseFloat(overflowTmp) / 1000,
          }
        : undefined,
    });
  }, [actions, footProtectionEnabled, heightTmp, index, overflowTmp, upright, widthTmp]);

  const handleBlurNumberField = useCallback(
    (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, fallbackValue: number) => {
      const newValue = parseFloat(value) / 1000;
      if (isNaN(newValue)) {
        setValue((fallbackValue * 1000).toFixed(0));
      }

      updateFootProtection();
    },
    [updateFootProtection]
  );
  const handleKeyPressNumberField = useCallback(
    (
      e: React.KeyboardEvent<HTMLDivElement>,
      value: string,
      setValue: React.Dispatch<React.SetStateAction<string>>,
      fallbackValue: number
    ) => {
      if (e.key === 'Enter' && !isNaN(parseFloat(value))) {
        setValue(parseFloat(value).toFixed(0));
        updateFootProtection();
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        setValue((fallbackValue * 1000).toFixed(0));
        setTimeout(() => (e.target as HTMLInputElement).blur(), 0);
      }
    },
    [updateFootProtection]
  );

  return (
    <Menu
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      onClose={() => setOpen(false)}
    >
      <MenuItem
        disabled={standardMode}
        onClick={() => {
          actions.updateUpright(index, {
            ...upright,
            linkedFootProtection: !upright.linkedFootProtection,
            footProtection: {
              enabled: !!footProtectionEnabled,
              width: upright?.footProtection?.width ?? upright.width * 2,
              height: upright?.footProtection?.height ?? upright.width * 2,
              overflow: 0.1,
            },
          });
        }}
        sx={{
          justifyContent: 'space-between',
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          fontWeight: 'bold',
        }}
        dense
      >
        Foot Protection
        <IconButton sx={{ float: 'right' }}>{upright.linkedFootProtection ? <LinkIcon /> : <LinkOffIcon />}</IconButton>
        {standardMode && (
          <Typography variant="caption">
            <p>{textHelperDisabledStandardMode}</p>
          </Typography>
        )}
      </MenuItem>
      <Divider variant="middle" sx={{ margin: 0 }} />
      <MenuItem
        disabled={upright.linkedFootProtection || standardMode}
        onClick={() => {
          upright.footProtection &&
            actions.updateUpright(index, {
              ...upright,
              footProtection: {
                ...upright.footProtection,
                enabled: !footProtectionEnabled,
              },
            });
        }}
        sx={{ justifyContent: 'space-between', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
      >
        Enable
        <Switch checked={!!footProtectionEnabled} />
      </MenuItem>
      <MenuItem
        disabled={upright.linkedFootProtection || standardMode}
        sx={{ justifyContent: 'space-between', paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1) }}
      >
        <TextField
          label="Width"
          type="number"
          value={widthTmp}
          fullWidth
          margin="none"
          size="small"
          onChange={(e) => setWidthTmp(e.target.value)}
          disabled={!footProtectionEnabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
          }}
          inputProps={{
            step: 10,
            min: 0,
          }}
          onBlur={() => handleBlurNumberField(widthTmp, setWidthTmp, upright.footProtection?.width ?? 0)}
          onKeyDown={(e) => handleKeyPressNumberField(e, widthTmp, setWidthTmp, upright.footProtection?.width ?? 0)}
        />
      </MenuItem>
      <MenuItem
        disabled={upright.linkedFootProtection || standardMode}
        sx={{ justifyContent: 'space-between', paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1) }}
      >
        <TextField
          label="Height"
          type="number"
          value={heightTmp}
          fullWidth
          margin="none"
          size="small"
          onChange={(e) => setHeightTmp(e.target.value)}
          disabled={!footProtectionEnabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
          }}
          inputProps={{
            step: 10,
            min: 0,
          }}
          onBlur={() => handleBlurNumberField(heightTmp, setHeightTmp, upright.footProtection?.height ?? 0)}
          onKeyDown={(e) => handleKeyPressNumberField(e, heightTmp, setHeightTmp, upright.footProtection?.height ?? 0)}
        />
      </MenuItem>
      <MenuItem
        disabled={upright.linkedFootProtection || standardMode}
        sx={{ justifyContent: 'space-between', paddingRight: theme.spacing(1), paddingLeft: theme.spacing(1) }}
      >
        <TextField
          label="Overflow"
          type="number"
          value={overflowTmp}
          fullWidth
          margin="none"
          size="small"
          onChange={(e) => setOverflowTmp(e.target.value)}
          disabled={!footProtectionEnabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
          }}
          inputProps={{
            step: 10,
            min: 0,
          }}
          onBlur={() => handleBlurNumberField(overflowTmp, setOverflowTmp, upright.footProtection?.overflow ?? 0)}
          onKeyDown={(e) =>
            handleKeyPressNumberField(e, overflowTmp, setOverflowTmp, upright.footProtection?.overflow ?? 0)
          }
        />
      </MenuItem>
    </Menu>
  );
}
