import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { saveNoteAction, selectNoteAction } from 'actions/notes';
import { getSvgFromIcon } from 'components/utils/generate-jsx';
import * as d3 from 'd3';
import { Shape } from 'drawings/elements/shape.element';
import { offsetPosition } from 'drawings/helpers';
import type { Point } from 'geojson';
import type { NoteProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { pointSize } from 'models/drawings';
import store from 'store';
import { cssTransformToPosition } from 'utils/circuit/utils';

const noteIconSvg = getSvgFromIcon(SpeakerNotesIcon);

export interface NoteDrawingProperties {
  size: pointSize;
  refreshGeometryOnDrag?: boolean;
}

export class Note extends Shape<Point> {
  public properties: NoteProperties;

  private translating = false;

  constructor(
    id: string,
    geometry: Point,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    properties: NoteProperties,
    drawingProperties: NoteDrawingProperties
  ) {
    super(id, ShapeTypes.NoteShape, geometry, projection, zoomScale);
    this.properties = properties;

    this.setLocked(!!this.properties?.locked);

    if (this.properties.displayMode === 'icon' || this.properties.name === '') {
      this.drawNote();
    } else if (this.properties.displayMode === 'text') {
      this.drawName();
    } else {
      // eslint-disable-next-line no-console
      console.error(`Unhandled display mode: ${this.properties.displayMode}`); // ici on peut mettre une assertion tsafe en plus pour avoir une erreur au build time au lieu du run time
    }

    this.updateShapeRef = this.updateShape;
    this.node.attr('layer-id', properties?.layerId || '');
  }

  public drawNote(): void {
    const node = this.node;

    const noteWidth = 20;
    const noteHeight = 20;

    node
      .append('svg')
      .attr('main-shape-outline', true)
      .attr('viewBox', noteIconSvg?.querySelector('svg')?.getAttribute('viewBox') || '')
      .attr('x', this.geometry.coordinates[0] - noteWidth / 2)
      .attr('y', -this.geometry.coordinates[1] - noteHeight)
      .attr('width', noteWidth)
      .attr('height', noteHeight)
      .append('path')
      .attr('d', noteIconSvg?.querySelector('path')?.getAttribute('d') || '')
      .attr('main-shape-outline', true);
  }

  private drawName(): void {
    const fontSize = this.properties.size;

    const text = this.properties.name;
    const lines = text.split('\n');

    const textElem = this.node
      .append('text')
      .attr('x', this.geometry.coordinates[0])
      .attr('y', -this.geometry.coordinates[1])
      .classed('Note', true)
      .attr('text-anchor', 'middle')
      .style('font-size', fontSize);

    if (lines.length === 1) {
      // just one line we can use the text element
      textElem.text(text);
    } else {
      // multiple lines, we need to use the tspan element
      const spaceBetweenLines = 2;

      lines.forEach((line, i) => {
        textElem
          .append('tspan')
          .attr('x', this.geometry.coordinates[0])
          .attr('dy', i === 0 ? 0 : fontSize + spaceBetweenLines)
          // .attr('dy', i * (fontSize + spaceBetweenLines))
          .text(line);
      });
    }
  }

  protected onDrag(): void {
    if (!this.draggeable()) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    const dx = event.dx;
    const dy = event.dy;

    // we update the coordinates
    this.geometry.coordinates = offsetPosition(this.geometry.coordinates, dx, dy);

    // as well as the shape position
    const transform = this.node?.style('transform') as string | undefined | null;
    if (transform === 'none' || !transform) {
      this.node.style('transform', `translate(${dx}px, ${dy}px)`);
    } else {
      const newTransform = cssTransformToPosition(transform);
      newTransform[0] += event.dx;
      newTransform[1] += event.dy;
      this.node.style('transform', `translate(${newTransform[0]}px, ${newTransform[1]}px)`);
    }

    super.onDrag();
  }

  protected onDragEnd(): void {
    const transform = this.node?.style('transform') as string | undefined | null;
    if (transform === 'none' || !transform) {
      // we just clicked on it, we select it
      store.dispatch(
        selectNoteAction({
          id: this.id,
        })
      );
    } else {
      // we actually dragged the shape, we save the new position
      store.dispatch(
        saveNoteAction({
          id: this.id,
          geometry: this.geometry,
        })
      );
    }

    super.onDragEnd(this.properties);
  }

  protected translateEnd(): void {
    if (!this.draggeable()) return;

    store.dispatch(
      saveNoteAction({
        id: this.id,
        geometry: this.geometry,
      })
    );
  }

  protected updateShape(): void {
    this.refreshGeometry();
  }
}
