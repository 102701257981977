import type { ComboxVersionType, DeviceType } from './circuit';

export const deviceMinFrequency = 100; // ms, value from the SDK code
export const deviceMaxFrequency = 2000; // ms, value from the SDK code
export const deviceStepFrequency = deviceMinFrequency; // ms, value from the SDK code

export interface DeviceData {
  nbPinsIn?: number | 'user'; // 'user' = defined by the user
  nbPinsOut?: number | 'user';
  inputCustomLabels?: string[]; // to create special labels for inputs
  outputCustomLabels?: string[]; // to create special labels for outputs
}

export const devicesData: Record<DeviceType, DeviceData | Record<ComboxVersionType, DeviceData>> = {
  '3BTCombox': {
    nbPinsIn: 3,
    nbPinsOut: 3,
  },
  IOECombox: {
    nbPinsIn: 6,
    nbPinsOut: 6,
  },
  mobileDockingStation: {
    nbPinsIn: 0,
    nbPinsOut: 0,
  },
  modbusDevice: {
    nbPinsIn: 'user',
    nbPinsOut: 'user',
  },
  smartCharger: {
    nbPinsIn: 0,
    nbPinsOut: 0,
  },
  comboxGen2: {
    Standard: {
      nbPinsIn: 2,
      nbPinsOut: 2,
    },
    Extended: {
      nbPinsIn: 8,
      nbPinsOut: 8,
    },
    Button: {
      nbPinsIn: 5,
      nbPinsOut: 2,
      inputCustomLabels: ['Input 1', 'Input 2', 'Red Button (left)', 'Green Button (middle)', 'Blue Button (right)'],
      outputCustomLabels: ['Output 1', 'Output 2'],
    },
  },
};
