import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, Tooltip } from '@mui/material';
import type { OptionsObject, SnackbarKey, WithSnackbarProps } from 'notistack';
import { useSnackbar } from 'notistack';

export const SNACKBAR_DELAY = 10000;

export enum SnackbarVariants {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  useSnackbarRef = useSnackbar();

  return null;
};

type MsgType = React.ReactNode | string;

export const SnackbarUtils = {
  success(msg: MsgType, options: OptionsObject = {}): SnackbarKey {
    return this.toast(msg, { autoHideDuration: SNACKBAR_DELAY, ...options, variant: 'success' });
  },
  warning(msg: MsgType, options: OptionsObject = {}): SnackbarKey {
    return this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: MsgType, options: OptionsObject = {}): SnackbarKey {
    return this.toast(msg, { autoHideDuration: SNACKBAR_DELAY, ...options, variant: 'info' });
  },
  error(msg: MsgType, options: OptionsObject = {}): SnackbarKey {
    return this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg: MsgType, options: OptionsObject = {}, dismiss = true): SnackbarKey {
    if (!useSnackbarRef) {
      // eslint-disable-next-line no-console
      console.warn('SnackbarUtilsConfigurator not found');

      return 0;
    }

    return useSnackbarRef.enqueueSnackbar(msg, {
      autoHideDuration: SNACKBAR_DELAY,
      ...options,
      action: (key) => (
        <>
          {options &&
            options.action &&
            (typeof options.action === 'function'
              ? (options.action as (key: React.ReactText) => React.ReactNode)(key)
              : options.action)}
          {key !== null && dismiss && <DismissSnackBarButton snackKey={key} />}
        </>
      ),
    });
  },
  debouncedToast: debounceToast,
  closeSnackbar(key?: SnackbarKey): void {
    useSnackbarRef.closeSnackbar(key);
  },
};

export function DismissSnackBarButton({ snackKey }: { snackKey: SnackbarKey }): JSX.Element {
  return (
    <Tooltip title="Dismiss">
      <IconButton
        onClick={() => {
          SnackbarUtils.closeSnackbar(snackKey);
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
    </Tooltip>
  );
}

/**
 * Calculates the time to wait until display the same snackbar to avoid displaying same snackbars.
 * @param msg the message for the snackbar
 * @param key  - the key for the snackbar
 * @param OptionObject - optional conditions that we added
 * @returns {resetWaitTime} a time to wait until next same snackbar
 */
const resetWaitTime: Record<string, number> = {};
function debounceToast(msg: MsgType, key: string, options: OptionsObject = {}, maxWait = 1000): void {
  const now = Date.now();
  if (!resetWaitTime[key] || now > resetWaitTime[key] + maxWait) {
    SnackbarUtils.toast(msg, options);

    resetWaitTime[key] = now;
  }
}
