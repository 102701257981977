import type { CircuitActions } from 'actions/circuit';
import { CircuitActionTypes } from 'actions/circuit';
import type { CircuitShape } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { isShapeInSelectedShapes } from 'multiplayer/globals';
import type { SelectedShapesData } from './state';

export function getSelectedShapesDataInitialState(): SelectedShapesData {
  return [];
}

/**
 * Check if a shape can be inserted into the selection
 * @param shapeId  the circuit shape id
 * @param shapeType the type of the shape
 * @returns if it can be selected
 */
function isShapeSelectable(shapeId: string, shapeType: ShapeTypes, shape?: CircuitShape): boolean {
  if (isShapeInSelectedShapes(shapeId)) return false;

  // only segments linked to a rack are not selectable
  if (shapeType !== ShapeTypes.SegmentShape) {
    return true;
  }

  return !(shape && shape.properties && shape.properties.type === ShapeTypes.SegmentShape && shape.properties.rack);
}

export function selectedShapesDataReducer(
  state = getSelectedShapesDataInitialState(),
  action: CircuitActions
): SelectedShapesData {
  switch (action.type) {
    case CircuitActionTypes.SelectShape: {
      const { selectedShapeId, selectedShapeType, shape } = action.payload;
      const isShapeAlreadyInSelection = !!state.find((s) => s.id === selectedShapeId);
      const selectable = isShapeSelectable(selectedShapeId, selectedShapeType, shape);

      if (isShapeAlreadyInSelection || !selectable) {
        return state;
      }

      return [...state, { id: selectedShapeId, type: selectedShapeType }];
    }

    case CircuitActionTypes.SelectMultipleShapes: {
      const payload = action.payload;

      const newState = [...state];

      // we add the shape to the selection if it is not already in the selection
      payload.forEach((shape) => {
        const isShapeAlreadyInSelection = !!state.find((s) => s.id === shape.id);
        const selectable = isShapeSelectable(shape.id, shape.type, shape.shape);

        if (!isShapeAlreadyInSelection && selectable) {
          newState.push({ id: shape.id, type: shape.type });
        }
      });

      return newState;
    }

    case CircuitActionTypes.UnselectShape: {
      const { unselectedShapeId } = action.payload;

      return state.filter((val) => val.id !== unselectedShapeId);
    }

    case CircuitActionTypes.UnselectSeveralShapes: {
      const { unselectedShapes } = action.payload;

      const shapesIdsToUnselect = new Set(unselectedShapes.map((shape) => shape.id));

      const newState = state.filter((selectedShapeData) => !shapesIdsToUnselect.has(selectedShapeData.id));

      return newState;
    }

    case CircuitActionTypes.ClearShapesSelection: {
      return [];
    }

    default:
      return state;
  }
}
