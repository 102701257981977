import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { openDialogAction } from 'actions';
import { DialogTypes } from 'models';
import type { LoadedPoint } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';

export function snackbarUnsnappedPoints(points: LoadedPoint[]): void {
  const message = points.length > 1 ? 'points have been' : 'point has been';

  const selectAllFilteredShapes = (): void => {
    store.dispatch(
      openDialogAction({
        type: DialogTypes.SearchShapeResultSelection,
        payload: {
          shapes: points,
        },
      })
    );
  };

  SnackbarUtils.info(
    <Box component="span">
      {`${points.length} ${message} unsnapped`}
      <Button color="primary" variant="contained" sx={{ marginLeft: 2 }} onClick={selectAllFilteredShapes}>
        Show
      </Button>
    </Box>
  );
}
