import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { openDialogAction } from 'actions';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { DialogTypes } from 'models';
import { Tools } from 'models/tools';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';
import { RobotsList } from './simulation/robot-list';
import { useRobotsEmulation } from './simulation/use-set-robots';

export function RobotsSimulationConfigurationToolbox(): JSX.Element {
  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.RobotsSimulationConfiguration)?.icon;
  }, []);

  const dispatch = useAppDispatch();

  const [robots] = useRobotsEmulation();

  const handleOpenUpdateBattery = useCallback(() => {
    dispatch(openDialogAction({ type: DialogTypes.UpdateBattery, payload: { robotsList: robots } }));
  }, [dispatch, robots]);

  const handleOpenUpdateOPCThresholds = useCallback(() => {
    dispatch(openDialogAction({ type: DialogTypes.UpdateOPCThresholds, payload: { robotsList: robots } }));
  }, [dispatch, robots]);

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '400px',
        maxHeight: '95%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title="Robots Simulation Configuration"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
          overflowY: 'auto',
        }}
      >
        <RobotsList useCollapse={false} />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" endIcon={<BatteryCharging90Icon />} onClick={handleOpenUpdateOPCThresholds}>
          Configure Charging Strategy
        </Button>
        <Button variant="outlined" endIcon={<Battery90Icon />} onClick={handleOpenUpdateBattery}>
          Update battery level
        </Button>
      </CardActions>
    </Card>
  );
}
