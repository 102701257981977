import { Box } from '@mui/system';
import type { union } from '@turf/turf';
import type { CircuitPoint, CircuitSegment, CircuitTurn } from 'models/circuit';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import type { generateGabaritPolygon } from 'utils/circuit/gabarits';
import { getGabaritCoords } from 'utils/circuit/get-gabarit-coords';
import { isCircuitPoint, isCircuitSegment, isCircuitTurn } from 'utils/circuit/shape-guards';
import { gabaritsWorkerProxy } from './gabarits.worker';

interface GenerateGabaritsLayerParams {
  layerId: string;
  modelName: string;
  gabaritName: string;
  displaySnackbar?: boolean;
  returnAllGabarits?: boolean;
  returnMergedGabarit?: boolean;
}

interface GenerateGabaritsLayerResult {
  gabarits?: ReturnType<typeof generateGabaritPolygon>[];
  mergedPolygon?: ReturnType<typeof union>;
}

export async function generateGabaritsLayer(
  params: GenerateGabaritsLayerParams
): Promise<GenerateGabaritsLayerResult | undefined> {
  const {
    layerId,
    modelName,
    gabaritName,
    displaySnackbar = true,
    returnAllGabarits = false,
    returnMergedGabarit = true,
  } = params;

  const circuitState = store.getState().circuit.present;
  const layers = circuitState.layers.layers;
  const layer = layers[layerId];

  if (!layer) {
    // eslint-disable-next-line no-console
    console.error(`Layer ${layerId} not found`);

    return;
  }

  const pointsIds = circuitState.points.ids;
  const points = circuitState.points.entities;

  const segmentsIds = circuitState.segments.ids;
  const segments = circuitState.segments.entities;

  const turnsIds = circuitState.turns.ids;
  const turns = circuitState.turns.entities;

  const shapes: (CircuitSegment | CircuitTurn | CircuitPoint)[] = [];

  pointsIds.forEach((pointId) => {
    const point = points[pointId];
    if (point.properties.layerId === layerId) {
      shapes.push(point);
    }
  });

  segmentsIds.forEach((segmentId) => {
    const segment = segments[segmentId];
    if (segment.properties.layerId === layerId) {
      shapes.push(segment);
    }
  });

  turnsIds.forEach((turnId) => {
    const turn = turns[turnId];
    if (turn.properties.layerId === layerId) {
      shapes.push(turn);
    }
  });

  const gabaritGenerationPercentsId = 'gabarit-generation-percents';
  const generatingGabaritsSnackbarId = displaySnackbar
    ? SnackbarUtils.info(
        <Box component="span">
          Generating gabarits...{' '}
          <Box component="span" id={gabaritGenerationPercentsId}>
            0
          </Box>
          %
        </Box>,
        {
          persist: true,
        }
      )
    : undefined;

  const gabaritCoords = getGabaritCoords(gabaritName, modelName);
  if (!gabaritCoords) {
    // eslint-disable-next-line no-console
    console.warn(`Gabarit ${gabaritName} not found`);

    return;
  }

  const gabarits: ReturnType<typeof generateGabaritPolygon>[] = [];

  let lastUpdate = Date.now();
  const updateEveryMs = 500;
  for (let i = 0; i < shapes.length; i++) {
    const shape = shapes[i];
    let gabarit: ReturnType<typeof generateGabaritPolygon> | undefined;

    if (isCircuitSegment(shape)) {
      gabarit = await gabaritsWorkerProxy.generateSegmentGabarit({
        segment: shape,
        modelName,
        gabaritName,
        gabaritCoords,
      });
    } else if (isCircuitTurn(shape)) {
      gabarit = await gabaritsWorkerProxy.generateTurnGabarit({
        turn: shape,
        modelName,
        gabaritName,
        gabaritCoords,
      });
    } else if (isCircuitPoint(shape)) {
      gabarit = await gabaritsWorkerProxy.generatePointGabarit({
        point: shape,
        modelName,
        gabaritName,
        gabaritCoords,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Unknown shape type', shape);

      assert<Equals<typeof shape, never>>();
    }

    gabarits.push(gabarit);

    if (displaySnackbar) {
      const isTheLast = i === shapes.length - 1;
      if (Date.now() - lastUpdate > updateEveryMs || isTheLast) {
        const percent = Math.round((i / shapes.length) * 100);
        const percentElement = document.getElementById(gabaritGenerationPercentsId);
        if (percentElement) {
          percentElement.innerHTML = percent.toString();
        }

        lastUpdate = Date.now();
      }
    }
  }

  if (displaySnackbar) {
    setTimeout(() => {
      SnackbarUtils.closeSnackbar(generatingGabaritsSnackbarId);
    }, 500);
  }

  let mergedPolygon: ReturnType<typeof union> | undefined;
  if (returnMergedGabarit) {
    const mergingGabaritsSnackbarId = displaySnackbar
      ? SnackbarUtils.info('Merging gabarits...', {
          persist: true,
        })
      : undefined;

    // eslint-disable-next-line no-console
    console.log('Merging gabarits...');
    mergedPolygon = await gabaritsWorkerProxy.mergeAllGabarits(gabarits);

    // eslint-disable-next-line no-console
    console.log('Gabarits merged');

    if (displaySnackbar) {
      SnackbarUtils.closeSnackbar(mergingGabaritsSnackbarId);
    }
  }

  return {
    gabarits: returnAllGabarits ? gabarits : undefined,
    mergedPolygon,
  };
}
