import TuneIcon from '@mui/icons-material/Tune';
import { Button, FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { openDialogAction } from 'actions';
import { saveCircuitToHistoryAction } from 'actions/circuit';
import { saveStockZoneAction } from 'actions/stock-zones';
import {
  convertSlotLineToName,
  isValidSlotLineName,
  type BlockDataSlotLineName,
} from 'components/editor/stock-zone-settings/stock-zone-naming';
import { HelpIconTooltip } from 'components/utils/tooltips';
import * as d3 from 'd3';
import { DialogTypes } from 'models';
import type { CircuitStockZone } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import type { LoadedStockZone } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { updateStockZone } from 'utils/circuit/stockzones';
import { PALLET_DETECTION_SCAN_TRAINING } from 'utils/config';
import { nbDigitsExtendedLength } from 'utils/extended-length';
import { toDigits } from 'utils/helpers';
import { PreferencesService } from 'utils/preferences';
import { MovementArrowButtonsGroup } from './components/movement-arrows-buttons';
import { PropertiesComponent } from './properties-component';
import { ExtendedLengthTextfield } from './rack-properties/extended-length-textfield';
import { nbDigitsOrientationValue } from './rack-properties/rack-properties';

const useStyles = makeStyles((theme) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  createStyles({
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },
  })
);

const nbMaxIter = 1000;

export function assignNameToStockLines(
  assignTo: 'selection' | 'all',
  rules: BlockDataSlotLineName[] | null,
  selectedLoadOnly = true,
  onlyNotUserDefinedNames: boolean,
  nameToUse: string,
  stockZone: LoadedStockZone | CircuitStockZone,
  stockZoneName: string
): void {
  let nbNoUniqueNames = 0;
  let nbTooLongNames = 0;
  const newGeneratedNames: string[] = [];

  const slots = stockZone?.properties.slots;

  const newSlots = slots?.map((stockLine, stockLineIndex) => {
    if (onlyNotUserDefinedNames && stockLine.userEditedName) return stockLine;

    let isUnique = false;
    let i = 0;
    let newName: string;
    let newNameOriginal = 'error';
    // we generate the name, then we check if the name is already used, if we
    // convert the name to theName_0 and check again, if it's still not unique we go for theName_1, theName_2 and so on
    do {
      let rulesToUse: typeof rules | undefined;
      if (!rules && nameToUse) {
        rulesToUse = [
          {
            type: 'char',
            value: `${nameToUse}-${stockLineIndex}${i !== 0 ? `_${i}` : ''}`,
          },
        ];
      }

      const expectedRules = rules || rulesToUse;
      newName =
        i === 0
          ? expectedRules
            ? convertSlotLineToName(expectedRules, {
                stockZoneName: nameToUse || stockZoneName,
                slots,
                slotIndex: stockLineIndex,
              })
            : stockZone?.properties.slots[stockLineIndex].name
          : `${newNameOriginal}_${i}`;

      if (rules) {
        isUnique =
          areAllShapeNamesUnique([newName], undefined, { ignoreDuplicatesBefore: true }) &&
          !newGeneratedNames.includes(newName);
      } else if (newName === stockLine.name) {
        isUnique = true;
      } else {
        const otherNamesToConsider = slots.map((stockLine) => stockLine.name);
        isUnique =
          areAllShapeNamesUnique([newName], undefined, { ignoreDuplicatesBefore: true }, otherNamesToConsider) &&
          !newGeneratedNames.includes(newName);
      }

      stockLine.userEditedName = !!rules;

      if (!isUnique && i++ === 0) {
        newNameOriginal = newName;
        nbNoUniqueNames++;
      }
    } while (!isUnique && i < nbMaxIter);

    if (!isUnique) {
      // eslint-disable-next-line no-console
      console.error(`Could not find a unique name for ${newName} after ${nbMaxIter} iterations`);
    }

    const isValid = isValidSlotLineName(newName);

    if (!isValid) {
      nbTooLongNames++;

      return stockLine;
    }

    newGeneratedNames.push(newName);

    return { ...stockLine, name: newName };
  });

  startTransition(() => {
    updateStockZone(stockZone, {
      name: nameToUse ?? undefined,
      slots: newSlots,
    });
  });

  if (nbNoUniqueNames) {
    // eslint-disable-next-line no-console
    console.warn(
      `There were ${nbNoUniqueNames} names that were not unique, they were renamed to theName_0, theName_1, etc.`
    );

    SnackbarUtils.warning(`${nbNoUniqueNames} names were not unique and were modified`);
  }

  if (nbTooLongNames) {
    // eslint-disable-next-line no-console
    console.warn(`There were ${nbTooLongNames} names that were too long, the new rule has not been applied to them`);

    SnackbarUtils.warning(`${nbTooLongNames} names were too long and the new rule has not been applied to them`);
  }
}

export interface StockZonePropertiesProps {
  stockZoneId: string;
}

export function StockZoneProperties({ stockZoneId }: StockZonePropertiesProps): JSX.Element {
  const stockZone = useAppSelector(
    (state) => state.circuit.present.stockZones.entities[stockZoneId] as LoadedStockZone | undefined
  );

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [stockZoneName, setStockZoneName] = useState(stockZone?.properties.name);
  const [cap, setCap] = useState(parseFloat(stockZone?.properties.cap.toFixed(nbDigitsOrientationValue) ?? '-1'));
  const [extendedLength, setExtendedLength] = useState(
    parseFloat(stockZone?.properties.extendedLength.toFixed(3) ?? '-1')
  );
  const [stockZoneMargin, setStockZoneMargin] = useState(
    parseFloat((stockZone?.properties.stockZoneMargin ?? 0).toFixed(3) ?? '0')
  );

  const locked = !!stockZone?.properties.locked;

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');

  useEffect(() => {
    setStockZoneName(stockZone?.properties.name);
  }, [stockZone?.properties.name]);
  useEffect(() => {
    setCap(parseFloat(stockZone?.properties.cap.toFixed(nbDigitsOrientationValue) ?? '-1'));
  }, [stockZone?.properties.cap]);
  useEffect(() => {
    setStockZoneMargin(parseFloat((stockZone?.properties.stockZoneMargin ?? 0).toFixed(3) ?? '0'));
  }, [stockZone?.properties.stockZoneMargin]);

  /**
   * this function recomputes the attributes of the stock zone and dispatch a save action
   * it is debounced to avoid too many actions while changing the values of the inputs
   */
  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!stockZone) return;

    let name = stockZoneName;
    if (
      !name ||
      !areAllShapeNamesUnique([name], [stockZoneId], {
        ignoreDuplicatesBefore: true,
      })
    ) {
      name = suggestedName;
    }

    dispatch(saveCircuitToHistoryAction());
    updateStockZone(stockZone, {
      name: name ?? undefined,
      cap: cap ?? undefined,
      extendedLength: extendedLength ?? undefined,
      stockZoneMargin: stockZoneMargin ?? undefined,
    });
  }, 500);

  const updateProperties = useCallback(() => {
    // we apply a css class to the stockzone of the playground to tell the user that the stock zone will be recomputed
    if (stockZone?.id) d3.select(`[uuid='${stockZone.id}']`).classed('outdated', true);
    if (stockZone) {
      const action = saveStockZoneAction({
        id: stockZone.id,
        isEditing: true,
      });
      store.dispatch(action);
    }

    // and we recompute the stock zone
    updatePropertiesDebounced();
  }, [stockZone, updatePropertiesDebounced]);

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newName = e.target.value.trim();
      if (newName?.length < 50) {
        setStockZoneName(newName);
      }

      // shape names must be unique, nothings check that we don't fill the store with two shapes with the same name
      startTransition(() => {
        const isNameAlreadyUsed = !areAllShapeNamesUnique([newName], [stockZoneId], {
          ignoreDuplicatesBefore: true,
        });

        if (!newName || isNameAlreadyUsed) {
          setErrorName(true);
          setSuggestedName(CircuitService.generateDifferentName(newName, { shapeIdsToIgnore: [stockZoneId] }));
        } else {
          setErrorName(false);
        }

        updatePropertiesDebounced();
      });
    },
    [stockZoneId, updatePropertiesDebounced]
  );
  /** we compute a name suggestion if the name is already used */
  const setSuggestedNameHandler = useCallback(() => {
    setStockZoneName(suggestedName);
    setErrorName(false);

    updateProperties();
  }, [suggestedName, updateProperties]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorName(false), [stockZoneId]);

  const updateStockLineNames = useCallback(() => {
    if (stockZone && stockZoneName) {
      assignNameToStockLines(
        'all',
        null,
        undefined,
        true,
        errorName ? suggestedName : stockZoneName,
        stockZone,
        stockZoneName
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('stockZone or stockZoneName not found');
    }
  }, [errorName, stockZone, stockZoneName, suggestedName]);

  const onCapChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newCap = parseFloat(e.target.value);

      setCap(newCap % 360);

      updateProperties();
    },
    [updateProperties]
  );

  const onExtendedLengthChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | number) => {
      const value = typeof e === 'number' ? e.toString() : e.target.value;
      const newExtendedLength = parseFloat(parseFloat(value).toFixed(3));

      setExtendedLength(newExtendedLength);

      updateProperties();
    },
    [updateProperties]
  );

  const handleSetExtendedLength = useCallback(
    (newExtendedLength: number) => {
      setExtendedLength(toDigits(newExtendedLength, nbDigitsExtendedLength));
      updateProperties();
    },
    [updateProperties]
  );

  const onStockZoneMarginChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newStockZoneMargin = parseFloat(parseFloat(e.target.value).toFixed(3));

      setStockZoneMargin(newStockZoneMargin);

      updateProperties();
    },
    [updateProperties]
  );

  const handleEditParameters = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.StockZoneSettings,
        payload: undefined,
      })
    );
  }, [dispatch]);

  const isProjectLoaded = PreferencesService.arePreferencesFullyLoaded();

  return stockZone ? (
    <PropertiesComponent shape={stockZone} shapeId={stockZoneId} shapeType={ShapeTypes.StockZoneShape}>
      <FormControl error={errorName}>
        <TextField
          value={stockZoneName}
          onChange={onNameChangeHandler}
          onBlur={updateStockLineNames}
          fullWidth
          margin="normal"
          label="Name"
          disabled={locked}
          variant="standard"
          sx={{
            textDecorations: errorName ? 'line-through' : 'none',
          }}
        />
        <FormHelperText className={classes.helperText} error id="suggested-name-rack">
          {errorName ? (
            <>
              Name already used, suggested name:{' '}
              <span
                onClick={() => {
                  setSuggestedNameHandler();
                  updateStockLineNames();
                }}
                className={classes.suggestedName}
              >
                {suggestedName}
              </span>
            </>
          ) : (
            <></>
          )}
        </FormHelperText>
      </FormControl>

      <MovementArrowButtonsGroup
        locked={locked}
        shape={stockZone}
        shapeId={stockZoneId}
        shapeMovedAction={updateStockZone}
      />

      <TextField
        type="number"
        value={cap}
        onChange={onCapChangeHandler}
        fullWidth
        margin="normal"
        label="Orientation"
        disabled={locked}
        inputProps={{ step: 1 }}
        InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
        variant="standard"
      />

      <ExtendedLengthTextfield
        extendedLength={extendedLength}
        isProjectLoaded={isProjectLoaded}
        locked={locked}
        handleSetExtendedLength={handleSetExtendedLength}
        onExtendedLengthChangeHandler={onExtendedLengthChangeHandler}
      />

      <TextField
        type="number"
        value={stockZoneMargin}
        onChange={onStockZoneMarginChangeHandler}
        fullWidth
        margin="normal"
        label={
          <>
            Stock zone margin
            <HelpIconTooltip
              title={
                <>
                  Allow to enlarge the scanning area of the stockzone to optimize pallet detection
                  <br />
                  <a target="_blank" href={PALLET_DETECTION_SCAN_TRAINING} rel="noreferrer">
                    Associated training
                  </a>
                </>
              }
            />
          </>
        }
        disabled={locked}
        inputProps={{ step: 0.01, min: 0 }}
        InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
        variant="standard"
      />

      <Button fullWidth variant="contained" startIcon={<TuneIcon />} onClick={handleEditParameters} color="primary">
        Edit Parameters
      </Button>
    </PropertiesComponent>
  ) : (
    <></>
  );
}
