export enum Tools {
  Move = 'Move',
  DrawZone = 'Draw Zone',
  DrawPoint = 'Draw Point',
  AddNote = 'Add Note',
  DrawSegment = 'Draw Segment',
  DrawMeasurer = 'Draw Measurer',
  AddTurn = 'Add Turn',
  DrawShape = 'Draw Shape',
  DrawSegmentOrTurn = 'Draw Segment or Turn',
  DrawStockZone = 'Draw Stock Zone',
  DrawRack = 'Draw Rack',
  DrawConveyor = 'Draw Conveyor',
  AddDevice = 'Add Device',
  Route = 'Route',
  DisplayTraffic = 'Display Traffic',
  SimulationConfiguration = 'Simulation Configuration',
  FlowConfiguration = 'Flow Configuration',
  StationsConfiguration = 'Stations Configuration',
  RobotsSimulationConfiguration = 'Robots Simulation Configuration',
  TriggersConfiguration = 'Triggers Configuration',
  FlowAssignation = 'Flow Assignation',
  SchedulerConfiguration = 'Scheduler Configuration',
  CustomStepsEditor = 'Custom Steps Editor',
}
