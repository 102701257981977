import { cloneDeep } from 'lodash';
import type { GeoJsonCircuit, CircuitShape } from 'models/circuit';
import {
  getDefaultCellTemplatePerceptionProperties,
  generateUniqueLoadName,
} from 'utils/circuit/default-circuit-shapes';

/**
 * Function to convert a circuit from the 1.11 version to the 1.12.0 version
 *
 * List of changes made:
 * - circuit.features are now layers that contain shapes as features, the shapes were previously in the circuit.features
 * - add the missing perception properties
 *
 * @param circuit
 * @returns the upgraded circuit
 */
export function upgrade1_12_0(circuit: any): GeoJsonCircuit {
  // eslint-disable-next-line no-console
  console.log('Upgrading circuit to 1.12.0');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newCircuit = cloneDeep(circuit);

  // circuit.features are now layers that contain shapes as features, the shapes were previously in the circuit.features
  const shapes = circuit.features as unknown as CircuitShape[];
  const layers = circuit.properties.layers.layers as unknown as GeoJsonCircuit['properties']['layers']['layers'];
  const layerFeatures = Object.values(layers).map((layer) => ({
    id: layer.id,
    type: 'FeatureCollection',
    features: shapes.filter((shape) => shape.properties.layerId === layer.id),
    properties: {},
  }));

  newCircuit.features = layerFeatures;

  // ------
  // add the missing perception properties

  const defautPerceptionProperties = getDefaultCellTemplatePerceptionProperties();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  Object.values(newCircuit.properties.cellTemplates).forEach((cellTemplate: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newCellTemplate = cloneDeep(cellTemplate);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const perception = newCellTemplate.perception;

    for (const key in defautPerceptionProperties) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      perception[key] = perception[key] ?? defautPerceptionProperties[key];
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newCellTemplate.perception = perception;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newCircuit.properties.cellTemplates[newCellTemplate.id] = newCellTemplate;
  });

  // ------

  newCircuit.properties.roadEditorVersion = '1.12.0';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return newCircuit;
}

/**
 * Function to convert a circuit from the 1.12.0 version to the 1.12.1 version
 *
 * List of changes made:
 * - Add the field “name of the loads”* (rename is necessary) in the cell template
 *
 * @param circuit
 * @returns the upgraded circuit
 */
export function upgrade1_12_1(circuit: any): GeoJsonCircuit {
  // eslint-disable-next-line no-console
  console.log('Upgrading circuit to 1.12.1');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newCircuit = cloneDeep(circuit) as GeoJsonCircuit;

  const cellTemplates = Object.values(newCircuit.properties.cellTemplates ?? {});
  const cellTemplateNames = cellTemplates.map((cellTemplate) => cellTemplate.name);

  Object.values(cellTemplates).forEach((cellTemplate) => {
    cellTemplate.loads.forEach((load) => {
      if (load.name === undefined) {
        load.name = generateUniqueLoadName(cellTemplateNames);

        cellTemplateNames.push(load.name);
      }
    });
  });

  // ---------

  newCircuit.properties.roadEditorVersion = '1.12.1';

  return newCircuit;
}
