import { NearMe, Signpost } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import BadgeIcon from '@mui/icons-material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FontDownloadOffIcon from '@mui/icons-material/FontDownloadOff';
import HighlightIcon from '@mui/icons-material/Highlight';
import OpacityIcon from '@mui/icons-material/Opacity';
import RampRightIcon from '@mui/icons-material/RampRight';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import StraightIcon from '@mui/icons-material/Straight';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import type { AccordionSummaryProps, Theme } from '@mui/material';
import {
  AppBar,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slider,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import type { AccordionProps } from '@mui/material/Accordion';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Badge from '@mui/material/Badge';
import LinearProgress from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import * as Sentry from '@sentry/react';
import { openDialogAction, selectToolAction, toggleStateHightlightTurns } from 'actions';
import { clearShapesSelectionAction, removeAllGabaritsAction } from 'actions/circuit';
import appLogoA from 'assets/Balyo-A.svg';
import appLogoFull from 'assets/balyo-logo.png';
import clsx from 'clsx';
import { ExportCircuitComponent } from 'components/export/export-circuit';
import Users from 'components/presence/users';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import * as d3 from 'd3';
import { setEditorMode } from 'editor/editor';
import { isEditorMode } from 'editor/model';
import { countBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import type { UserProfile } from 'models';
import { DialogTypes } from 'models';
import { ShapeTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import { localDoc, projectHost } from 'multiplayer/globals';
import React, { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import type { ChangeStateFilter } from 'reducers/local/filters.reducer';
import {
  changeMapImageArrayOpacityFilterAction,
  changeMapImageArrayStateFilterAction,
  changeOpacityFilterAction,
  changeStateFilterAction,
} from 'reducers/local/filters.reducer';
import { checkPermission } from 'services/check-permission';
import { getLoadedCircuitName, loadProject } from 'services/project';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { useAsyncMemo } from 'use-async-memo';
import { useDebouncedCallback } from 'use-debounce';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { TrucksChips } from './trucks-chip';

const widthRobotChip = 220; // px

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'relative',
      zIndex: theme.zIndex.appBar,
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'unset',
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'center',
    },
    menuButton: {
      position: 'absolute',
      left: 14,
    },
    menuTitle: {
      position: 'absolute',
      left: 108,
    },
    logo: {
      marginTop: 8,
      userSelect: 'none',
      pointerEvents: 'none',
    },
    topRight: {
      position: 'absolute',
      right: 8,
      display: 'flex',
      alignItems: 'center',
    },
    menuIcon: {
      marginLeft: 8,
    },
    menuLoading: {
      marginBottom: '-4px',
    },
    menuActions: {
      display: 'flex',
    },
    profile: {
      padding: '4px 0',
    },
    notificationContainer: {
      marginRight: theme.spacing(3),
    },
    menuDivider: {
      marginTop: theme.spacing(0),
    },
  })
);

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  ({ theme }) => ({
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface MenuBarProps {
  appOnline: boolean;
  loggedIn: boolean;
  login: () => void;
  logout: () => void;
  profile?: UserProfile;
  projectName?: string;
}

export function MenuBar({ login, logout, loggedIn, profile, appOnline }: MenuBarProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector((state) => state.editor.editorMode);
  const multiplayer = useAppSelector((state) => state.multiplayer.multiplayer);

  const isLoading = false; // useAppSelector((state) => state.core.loading);

  const handleShareClick = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.Share,
        payload: undefined,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (appOnline && profile && profile.loaded && profile) {
      const info: { username: string; email?: string } = {
        username: profile.nickname,
      };
      if (profile.email) info.email = profile.email;

      Sentry.setUser(info);

      if (!window.dataLayer) {
        window.dataLayer = [];
      }

      window.dataLayer.push({ ...info, event: 'profile_loaded' });
    } else if (!appOnline) {
      Sentry.setUser({
        username: 'dev',
      });
    }
  }, [appOnline, profile]);

  const [projectName, setProjectName] = useState('');
  const [circuitName, setCircuitName] = useState('');
  const [circuitInPref, setCircuitInPref] = useState('');
  const [trucksPictures, setTrucksPictures] = useState<[string, number][]>([]);
  const [availableCircuits, setAvailableCircuits] = useState<string[] | null>(null);

  useEffect(() => {
    window.addEventListener('projectLoaded', () => {
      if (PreferencesService.projectName) {
        setProjectName(PreferencesService.projectName);

        try {
          setCircuitInPref(PreferencesService.getCircuitName().replace('.geojson', ''));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('Cannot get the circuit name from the preferences', e);
        }

        try {
          const trucksPicturePath = PreferencesService.getPreferenceValue('trucksPicturePath') as string[];
          setTrucksPictures(Object.entries(countBy(trucksPicturePath)));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Cannot read the trucksPicturePath', e);

          SnackbarUtils.warning(`The install preference "trucksPicturePath" has not been properly configured.`);
        }
      }

      const avCir = PreferencesService.getAvailableCircuitsName();
      setAvailableCircuits(avCir);

      if (store.getState().multiplayer.multiplayer && !projectHost) {
        const remoteCircuitName = localDoc.getMap('pref').get('circuitName') as string | undefined;
        const remoteProjectName = localDoc.getMap('pref').get('projectName') as string | undefined;

        if (remoteCircuitName && remoteProjectName) {
          setCircuitName(remoteCircuitName);
          setProjectName(remoteProjectName);

          document.title = `${remoteCircuitName} (${remoteProjectName})`;

          return;
        }

        // eslint-disable-next-line no-console
        console.log('Error getting remote project name or circuit name');

        return;
      }

      try {
        const cirName = getLoadedCircuitName().replace('.geojson', '');
        if (cirName) {
          setCircuitName(cirName);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Cannot get the circuit name', e);

        SnackbarUtils.error(`The install preference "circuitName" has not been properly configured.`);

        if (avCir && avCir.length) {
          setCircuitName(avCir[0]);
        }
      }
    });
  }, []);

  const [hideLogo, setHideLogo] = useState(
    window.matchMedia
      ? window.matchMedia(
          `(max-width: ${980 + (trucksPictures.length > 1 ? (trucksPictures.length - 1) * widthRobotChip : 0)}px)`
        ).matches
      : false
  );

  // could be reworked with the useMediaQueries hook
  useEffect(() => {
    const media = window.matchMedia
      ? window.matchMedia(
          `(max-width: ${980 + (trucksPictures.length > 1 ? (trucksPictures.length - 1) * widthRobotChip : 0)}px)`
        )
      : undefined;

    if (!media) return;

    function onChangeMedia(e: MediaQueryListEvent): void {
      setHideLogo(e.matches);
    }

    media.addEventListener('change', onChangeMedia);

    return () => {
      media.removeEventListener('change', onChangeMedia);
    };
  }, [trucksPictures.length]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        role="banner"
        sx={{
          outline: `2px solid ${editorMode === 'circuit' ? theme.palette.primary.main : theme.palette.secondary.main}`,
          backgroundColor: '#fff !important',
        }}
      >
        <Toolbar className={classes.toolBar}>
          <div className={clsx(classes.menuButton)}>
            <OpenProjectSettingsBtn />
            <SelectCircuitChip
              circuitName={circuitName || 'Draft Mode'}
              projectName={projectName || 'No Project'}
              availableCircuits={availableCircuits ?? []}
              circuitInPref={circuitInPref}
              setCircuitInPref={setCircuitInPref}
              projectLoaded={!!projectName}
            />
            <TrucksChips trucksPictures={trucksPictures} maxModelsEnableDenseMode={3} />
          </div>

          <Stack>
            <picture
              id="app-logo-full"
              className={classes.logo}
              style={{
                display: hideLogo ? 'none' : undefined,
              }}
            >
              <source
                srcSet={appLogoFull}
                media={`(min-width: ${
                  1150 + (trucksPictures.length > 1 ? (trucksPictures.length - 1) * widthRobotChip : 0)
                }px)`}
                height="48px"
              />
              <img src={appLogoA} height="48px" alt="app logo" />
            </picture>
          </Stack>

          <div className={classes.topRight}>
            {process.env.REACT_APP_COLLABORATIVE_FEATURE_ENABLED === 'true' ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: theme.spacing(2),
                  marginRight: theme.spacing(4),
                }}
              >
                {multiplayer ? <Users /> : null}
                <Button variant="contained" onClick={handleShareClick}>
                  Share
                </Button>
              </Box>
            ) : null}
            <EditorModeButton />
            <ShapeFilters />
            <Tooltip placement="top" title="Settings">
              <Button
                component="span"
                onClick={() => dispatch(openDialogAction({ type: DialogTypes.Settings, payload: undefined }))}
                color="inherit"
              >
                <SettingsIcon />
              </Button>
            </Tooltip>
            <ExportCircuitComponent />
            {appOnline && (
              <Button color="inherit" onClick={loggedIn ? logout : login}>
                {loggedIn ? 'Logout' : 'Login'}
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {isLoading && <LinearProgress className={classes.menuLoading}></LinearProgress>}
    </div>
  );
}

interface SelectCircuitChipProps {
  projectName: string;
  circuitName: string;
  availableCircuits: string[];
  circuitInPref?: string;
  setCircuitInPref?: (circuitName: string) => void;
  projectLoaded?: boolean;
}
function SelectCircuitChip({
  projectName,
  circuitName,
  availableCircuits,
  circuitInPref,
  setCircuitInPref,
  projectLoaded = false,
}: SelectCircuitChipProps): JSX.Element {
  const draft = projectName === 'No Project' && circuitName === 'Draft Mode';
  const confirm = useConfirm();

  function displayProjectHostOnlySnackBar(): void {
    SnackbarUtils.closeSnackbar();
    SnackbarUtils.warning('Only the project host can change the circuit');
  }

  function displayCircuitSharedSnackBar(): void {
    SnackbarUtils.closeSnackbar();
    SnackbarUtils.warning('A circuit is already shared, please create another room if you want to share another one');
  }

  const [openMenuAvailableCir, setOpenMenuAvailableCir] = useState(false);
  const [anchorProjectName, setAnchorProjectName] = useState<null | HTMLElement>(null);
  const handleOpenMenuAvailableCir = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorProjectName(e.currentTarget);
    setOpenMenuAvailableCir(true);
  }, []);
  const handleCloseMenuAvailableCir = useCallback(() => {
    setOpenMenuAvailableCir(false);
  }, []);

  const loadNewCircuit = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      const newCirName = e.currentTarget.innerText;

      const isNewCircuit = !!e.currentTarget.dataset.newCircuitBtn;
      const circuitNamePlaceholder = `${PreferencesService.projectName}_${Math.floor(Math.random() * 100)}`;
      let newCircuitName = circuitNamePlaceholder;
      let isChecked = false;

      const title = isNewCircuit ? 'A new circuit will be created' : `Are you sure you want to load '${newCirName}'?`;
      const content = isNewCircuit ? (
        <>
          The circuit{' '}
          <TextField
            variant="standard"
            defaultValue={circuitNamePlaceholder}
            size="small"
            onChange={(e) => {
              newCircuitName = e.target.value;
            }}
          />{' '}
          will be created.
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  isChecked = e.target.checked;
                }}
              />
            }
            label="Set as default circuit"
          />
        </>
      ) : undefined;

      confirm({ title, content, confirmationButtonProps: { autoFocus: true }, allowClose: false })
        .then(async () => {
          const cirNameToLoad = isNewCircuit ? newCircuitName.replace(/\s/g, '') : newCirName;
          loadProject({ circuitName: `${cirNameToLoad}.geojson`, newCircuit: isNewCircuit });

          if (isNewCircuit && isChecked) {
            let ok = false;
            try {
              [ok] = await PreferencesService.setPreferenceValue('general/circuitFileName', `${cirNameToLoad}.xml`);
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn('error setting default circuit', e);
            }

            if (ok) {
              SnackbarUtils.success(`Default circuit set to '${cirNameToLoad}'`);
              if (setCircuitInPref) setCircuitInPref(cirNameToLoad);
            } else {
              SnackbarUtils.warning(`Error setting default circuit to '${cirNameToLoad}'`);
            }
          }

          if (store.getState().multiplayer.multiplayer) {
            const localPrefMap = localDoc.getMap('pref');
            localPrefMap.set('circuitName', cirNameToLoad);
            syncYJSLocalToRemote();
          }

          handleCloseMenuAvailableCir();
        })
        .catch(() => undefined);
    },
    [confirm, handleCloseMenuAvailableCir, setCircuitInPref]
  );

  const handleSetDefaultCircuit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, newDefaultCircuit: string) => {
      e.stopPropagation();

      handleCloseMenuAvailableCir();

      try {
        await confirm({
          title: `Do you want to set '${newDefaultCircuit}' as the default circuit?`,
          allowClose: false,
        });
      } catch (e) {
        return;
      }

      const newDefaultCircuitWithoutXml = newDefaultCircuit.replace('.xml', '');

      let ok = false;
      try {
        [ok] = await PreferencesService.setPreferenceValue('general/circuitFileName', newDefaultCircuit);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('error setting default circuit', e);
      }

      if (ok) {
        SnackbarUtils.success(`Default circuit set to '${newDefaultCircuitWithoutXml}'`);
        if (setCircuitInPref) setCircuitInPref(newDefaultCircuitWithoutXml);
      } else {
        SnackbarUtils.warning(`Error setting default circuit to '${newDefaultCircuitWithoutXml}'`);
      }
    },
    [confirm, handleCloseMenuAvailableCir, setCircuitInPref]
  );

  const loadProjectComp = useCallback(async () => {
    if (store.getState().multiplayer.multiplayer && !draft) {
      displayCircuitSharedSnackBar();

      return;
    }

    try {
      const projectLoaded = await loadProject({ keepDirectoryHandle: false });

      if (projectLoaded) {
        setOpenMenuAvailableCir(false);
      }
    } catch (e) {
      if (e instanceof Error && e.name === 'AbortError') {
        // eslint-disable-next-line no-console
        console.log('User closed the dialog to load a new project');
      } else if (e instanceof Error) {
        throw e;
      }
    }
  }, [draft]);

  useHotkeys(
    'Ctrl+O',
    (event, hotkeysEvent) => {
      event.preventDefault();

      loadProjectComp();
    },
    []
  );

  const isHost = projectLoaded && projectHost;

  const openProjectPerm = useAsyncMemo(() => {
    return checkPermission('open:project');
  }, []);

  const openProjectOnline = !draft && store.getState().multiplayer.multiplayer;
  const disabledOpenProject = !openProjectPerm || openProjectOnline;

  return (
    <>
      <Chip
        id={'project-and-circuit-name'}
        label={`${projectName}${circuitName ? ` / ${circuitName}` : ''}`}
        onClick={handleOpenMenuAvailableCir}
        sx={{
          marginRight: '10px',
          marginLeft: '5px',
          '@media (max-width:680px)': {
            display: 'none',
          },
        }}
      />
      <Menu open={openMenuAvailableCir} onClose={handleCloseMenuAvailableCir} anchorEl={anchorProjectName}>
        {availableCircuits.map((availableCirName, index) => {
          return projectHost ? (
            <MenuItem
              onClick={circuitName !== availableCirName ? (e) => loadNewCircuit(e) : () => undefined}
              key={`${availableCirName}-${index}`}
              selected={circuitName === availableCirName}
            >
              <ListItemIcon>{circuitInPref === availableCirName ? <StarIcon /> : undefined}</ListItemIcon>
              <ListItemText>{availableCirName}</ListItemText>
              {circuitName === availableCirName && circuitInPref !== availableCirName && (
                <Tooltip title="Set as default circuit">
                  <IconButton onClick={(e) => handleSetDefaultCircuit(e, `${availableCirName}.xml`)}>
                    <SwitchAccessShortcutIcon />
                  </IconButton>
                </Tooltip>
              )}
            </MenuItem>
          ) : (
            <Box
              component="div"
              key={`${availableCirName}-${index}`}
              onClick={() => displayProjectHostOnlySnackBar()}
              sx={{ cursor: 'pointer' }}
            >
              <MenuItem disabled selected={circuitName === availableCirName}>
                <ListItemIcon>{circuitInPref === availableCirName ? <StarIcon /> : undefined}</ListItemIcon>
                <ListItemText>{availableCirName}</ListItemText>
                {circuitName === availableCirName && circuitInPref !== availableCirName && (
                  <Tooltip title="Set as main project circuit">
                    <IconButton>
                      <SwitchAccessShortcutIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </MenuItem>
            </Box>
          );
        })}

        {availableCircuits.length > 0 && <Divider />}

        {projectLoaded && [
          <Box
            component="div"
            sx={{ cursor: 'pointer' }}
            onClick={!isHost ? displayProjectHostOnlySnackBar : undefined}
            key="create-new-circuit-box-btn"
          >
            <MenuItem onClick={loadNewCircuit} key="create-new-circuit" data-new-circuit-btn disabled={!isHost}>
              <ListItemIcon>
                <AddCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>New circuit</ListItemText>
            </MenuItem>
          </Box>,
          <Divider key="divider-new-circuit" />,
        ]}

        <Tooltip title={!openProjectPerm ? 'You do not have the permission to open a project' : undefined}>
          <Box component="span">
            <MenuItem
              data-testid="open project"
              disabled={disabledOpenProject}
              onClick={openProjectOnline ? () => displayCircuitSharedSnackBar() : (e) => loadProjectComp()}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemIcon>
                <FolderOpenIcon />
              </ListItemIcon>
              <ListItemText>
                Open Project <kbd>CTRL+O</kbd>
              </ListItemText>
            </MenuItem>
          </Box>
        </Tooltip>
      </Menu>
    </>
  );
}

function ShapeFilters(): JSX.Element {
  const dispatch = useAppDispatch();

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const handleClickButtonFilter = useCallback(() => {
    setFilterMenuOpen(true);
  }, []);
  const handleCloseMenuFilter = useCallback(() => {
    setFilterMenuOpen(false);
  }, []);

  const displayZones = useAppSelector((state) => state.local.filters.zones);
  const displayNotes = useAppSelector((state) => state.local.filters.notes);
  const displaySegments = useAppSelector((state) => state.local.filters.segments);
  const displayPoints = useAppSelector((state) => state.local.filters.points);
  const displayTurns = useAppSelector((state) => state.local.filters.turns);
  const displayMeasurers = useAppSelector((state) => state.local.filters.measurers);
  const displayStockZones = useAppSelector((state) => state.local.filters.stockZones);
  const displayRacks = useAppSelector((state) => state.local.filters.racks);
  const displayDevices = useAppSelector((state) => state.local.filters.devices);

  const displayMap = useAppSelector((state) => state.local.filters.map);
  const displayMapObstacle = useAppSelector((state) => state.local.filters.mapObstacle);
  const displayMapImage = useAppSelector((state) => state.local.filters.mapImage);

  const mapOpacity = useAppSelector((state) => state.local.filters.mapOpacity * 100);
  const mapObstacleOpacity = useAppSelector((state) => state.local.filters.mapObstacleOpacity * 100);
  const mapImageOpacity = useAppSelector((state) => state.local.filters.mapImageOpacity * 100);

  const mapImageArray = useAppSelector((state) => state.local.filters.mapImageArray);

  const [mapOpacityRealTime, setMapOpacityRealTime] = useState(mapOpacity);
  const [mapObstacleOpacityRealTime, setMapObstacleOpacityRealTime] = useState(mapObstacleOpacity);
  const [mapImageOpacityRealTime, setMapImageOpacityRealTime] = useState(mapImageOpacity);

  const isLibCirEnabled = useAppSelector((state) => state.editor.enableLibCir);
  const debounceDelay = isLibCirEnabled ? 50 : 500;
  const maxWait = isLibCirEnabled ? 50 : undefined;

  useEffect(() => {
    setMapOpacityRealTime(mapOpacity);
  }, [mapOpacity]);
  useEffect(() => {
    setMapObstacleOpacityRealTime(mapObstacleOpacity);
  }, [mapObstacleOpacity]);
  useEffect(() => {
    setMapImageOpacityRealTime(mapImageOpacity);
  }, [mapImageOpacity]);

  const displayGabarit = useAppSelector((state) => state.local.filters.gabarit);

  const displayPointsNames: boolean = useAppSelector((state) => state.local.filters.displayPointNames);

  const extendedLengthTurns: boolean = useAppSelector((state) => state.local.filters.extendedLengthTurns);

  const handleAllPointsNames = useCallback(
    (value: boolean) => {
      const payload: ChangeStateFilter['payload'] = {};
      payload.displayPointNames = value;
      dispatch(changeStateFilterAction(payload));
    },
    [dispatch]
  );
  const handleExtendedLengthTurns = useCallback(
    (value: boolean) => {
      const payload: ChangeStateFilter['payload'] = {};
      payload.extendedLengthTurns = value;
      dispatch(changeStateFilterAction(payload));
    },
    [dispatch]
  );

  const handleChangeDisplay = useCallback(
    (
      type: ShapeTypes | 'Map' | 'MapImage' | 'MapObstacle' | 'Gabarit',
      eventOrValue: boolean | React.ChangeEvent<HTMLInputElement>
    ) => {
      const newVal = typeof eventOrValue === 'boolean' ? eventOrValue : !!eventOrValue.target.checked;
      const payload: ChangeStateFilter['payload'] = {};

      switch (type) {
        case ShapeTypes.ZoneShape: {
          payload.zones = newVal;
          break;
        }

        case ShapeTypes.NoteShape: {
          payload.notes = newVal;
          break;
        }

        case ShapeTypes.SegmentShape: {
          payload.segments = newVal;
          break;
        }

        case ShapeTypes.PointShape: {
          payload.points = newVal;
          break;
        }

        case ShapeTypes.RackShape: {
          payload.racks = newVal;
          break;
        }

        case ShapeTypes.TurnShape: {
          payload.turns = newVal;
          break;
        }

        case ShapeTypes.MeasurerShape: {
          payload.measurers = newVal;
          break;
        }

        case ShapeTypes.StockZoneShape: {
          payload.stockZones = newVal;
          break;
        }

        case ShapeTypes.DeviceShape: {
          payload.devices = newVal;
          break;
        }

        case 'Map': {
          payload.map = newVal;
          break;
        }

        case 'MapObstacle': {
          payload.mapObstacle = newVal;
          break;
        }

        case 'MapImage': {
          payload.mapImage = newVal;
          break;
        }

        case 'Gabarit': {
          payload.gabarit = newVal;
          break;
        }

        default: {
          // eslint-disable-next-line no-console
          console.error(`Unknown type ${type}`);

          // ts error triggered if we add a type but not in the switch
          assert<Equals<typeof type, never>>();
        }
      }

      dispatch(changeStateFilterAction(payload));
    },
    [dispatch]
  );

  const handleChangeMapOpacityTriggerAction = useDebouncedCallback(
    () => {
      dispatch(
        changeOpacityFilterAction({
          mapOpacity: mapOpacityRealTime / 100,
        })
      );
    },
    debounceDelay,
    { maxWait }
  );

  const handleChangeMapOpacity = useCallback(
    (e, newVal: number | number[]) => {
      setMapOpacityRealTime(newVal as number);

      /**
       * This is used because the update of the store is debounced, here we update the opacity in real time
       */
      if (!isLibCirEnabled) {
        d3.select('[layer=background-lidar]').style('opacity', (newVal as number) / 100);
      }

      handleChangeMapOpacityTriggerAction();
    },
    [handleChangeMapOpacityTriggerAction, isLibCirEnabled]
  );

  const handleChangeMapObstacleOpacityTriggerAction = useDebouncedCallback(
    () => {
      dispatch(
        changeOpacityFilterAction({
          mapObstacleOpacity: mapObstacleOpacityRealTime / 100,
        })
      );
    },
    debounceDelay,
    { maxWait }
  );

  const handleChangeObstacleOpacity = useCallback(
    (e, newVal: number | number[]) => {
      setMapObstacleOpacityRealTime(newVal as number);

      /**
       * This is used because the update of the store is debounced, here we update the opacity in real time
       */
      if (!isLibCirEnabled) {
        d3.select('[layer=foreground-lidar]').style('opacity', (newVal as number) / 100);
      }

      handleChangeMapObstacleOpacityTriggerAction();
    },
    [handleChangeMapObstacleOpacityTriggerAction, isLibCirEnabled]
  );

  const handleChangeMapImageArrayDisplay = useCallback(
    (toggle: boolean, name: string) => {
      dispatch(
        changeMapImageArrayStateFilterAction({
          toggle,
          name,
        })
      );
    },
    [dispatch]
  );

  const handleChangeMapImageOpacityTriggerAction = useDebouncedCallback(
    () => {
      dispatch(
        changeOpacityFilterAction({
          mapImageOpacity: mapImageOpacityRealTime / 100,
        })
      );
    },
    debounceDelay,
    { maxWait }
  );

  const handleChangeMapImageOpacity = useCallback(
    (e, newVal: number | number[]) => {
      setMapImageOpacityRealTime(newVal as number);

      /**
       * This is used because the update of the store is debounced, here we update the opacity in real time
       */
      if (!isLibCirEnabled) {
        d3.select('[layer=floor-plan]').style('opacity', (newVal as number) / 100);
      }

      handleChangeMapImageOpacityTriggerAction();
    },
    [handleChangeMapImageOpacityTriggerAction, isLibCirEnabled]
  );

  const [mapImageOpacityObj, setMapImageOpacityObj] = useState<{ [mapImageName: string]: { value: number } }>({});

  useEffect(() => {
    const mapImageOpacityObjTemp: { [mapImageName: string]: { value: number } } = {};
    mapImageArray.forEach((mapImage) => {
      mapImageOpacityObjTemp[mapImage.name] = {
        value: mapImage.opacity * 100,
      };
    });

    setMapImageOpacityObj(mapImageOpacityObjTemp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMenuOpen]);

  const handleChangeMapImageArrayOpacityTriggerAction = useDebouncedCallback(
    (name: string, opacity: number) => {
      dispatch(
        changeMapImageArrayOpacityFilterAction({
          name,
          opacity,
        })
      );
    },
    debounceDelay,
    { maxWait }
  );

  const handleChangeMapImageArrayOpacity = useCallback(
    (name: string, newVal: number | number[], isTiles: boolean | undefined) => {
      setMapImageOpacityObj((prev) => ({
        ...prev,
        [name]: {
          value: newVal as number,
        },
      }));

      /**
       * This is used because the update of the store is debounced, here we update the opacity in real time
       */
      if (!isLibCirEnabled) {
        const selector = isTiles ? `[layer=floor-plan-tiles]` : `[layer=floor-plan] [name="${name}"]`;
        d3.select(selector).style('opacity', (newVal as number) / 100);
      }

      handleChangeMapImageArrayOpacityTriggerAction(name, (newVal as number) / 100);
    },
    [handleChangeMapImageArrayOpacityTriggerAction, isLibCirEnabled]
  );

  const visualAvailable: boolean = useAppSelector((state) => state.tool.highlightTurns);
  const handleVisualTurn = useCallback(() => {
    dispatch(toggleStateHightlightTurns());
  }, [dispatch]);

  const confirm = useConfirm();

  const handleDeleteAllGabarits = useCallback(async () => {
    try {
      await confirm({ title: `Are you sure you want to remove all gabarits?`, allowClose: false });
    } catch (e) {
      return;
    }

    dispatch(removeAllGabaritsAction(undefined));
  }, [dispatch, confirm]);

  // objet to keep track of the opened panel'
  type PanelOpen = 'shapePanel' | 'mapPanel' | 'none';

  const [expanded, setExpanded] = React.useState<PanelOpen>('shapePanel');

  const handleChangeAccordion =
    (panelName: PanelOpen) =>
    (event: React.SyntheticEvent, newState: boolean): void => {
      setExpanded((prev) => {
        if (panelName === prev) {
          return 'none';
        }

        return panelName;
      });
    };

  const filterStates = [
    displayZones,
    displayNotes,
    displaySegments,
    displayPoints,
    displayTurns,
    displayMeasurers,
    displayStockZones,
    displayRacks,
    displayDevices,
    displayMap,
    displayMapObstacle,
    displayMapImage,
    displayGabarit,
  ];

  const filteroffSwitchesCount = filterStates.filter((state) => !state).length;
  const checkColor = grey[600];
  const unCheckColor = grey[900];
  const displayStockLineNames = useAppSelector((state) => state.local.filters.displayStockLineNames);
  const handleStockLineNames = useCallback(
    (value: boolean) => {
      const payload: ChangeStateFilter['payload'] = {};
      payload.displayStockLineNames = value;
      dispatch(changeStateFilterAction(payload));
    },
    [dispatch]
  );

  return (
    <>
      <Tooltip placement="top" title="Display filter">
        <Button
          aria-controls="menu-filter"
          aria-haspopup="true"
          id="button-filter"
          onClick={handleClickButtonFilter}
          color="inherit"
        >
          <Badge badgeContent={filteroffSwitchesCount} color="primary">
            <FilterListIcon />
          </Badge>
        </Button>
      </Tooltip>
      <Menu
        id="menu-filter"
        open={filterMenuOpen}
        anchorEl={document.querySelector('#button-filter')}
        onClose={handleCloseMenuFilter}
        MenuListProps={{ dense: true }}
        sx={{ maxWidth: '400px' }}
      >
        <Accordion expanded={expanded === 'shapePanel'} onChange={handleChangeAccordion('shapePanel')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Shapes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay(ShapeTypes.ZoneShape, !displayZones);
              }}
            >
              <Switch
                checked={displayZones}
                onChange={(e) => handleChangeDisplay(ShapeTypes.ZoneShape, e)}
                name="Zones"
              />{' '}
              Zones
            </MenuItem>
            <Box component="div" sx={{ display: 'flex' }}>
              <Box component="div" sx={{ width: '100%' }}>
                <MenuItem
                  onClick={(e) => {
                    handleChangeDisplay(ShapeTypes.PointShape, !displayPoints);
                  }}
                >
                  <Switch
                    checked={displayPoints}
                    onChange={(e) => handleChangeDisplay(ShapeTypes.PointShape, e)}
                    name="Points"
                  />{' '}
                  Points
                </MenuItem>
              </Box>
              <Tooltip title={displayPointsNames ? 'Disable all points names' : 'Enable all points names '}>
                <Box component="div" sx={{ paddingRight: '10px' }}>
                  <IconButton
                    disabled={!displayPoints}
                    onClick={() => {
                      handleAllPointsNames(!displayPointsNames);
                    }}
                    sx={{ color: !displayPointsNames ? unCheckColor : checkColor }}
                  >
                    {displayPointsNames ? <FontDownloadIcon /> : <FontDownloadOffIcon />}
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
            <Box component="div" sx={{ display: 'flex' }}>
              <Box component="div" sx={{ width: '100%' }}>
                <MenuItem
                  onClick={(e) => {
                    handleChangeDisplay(ShapeTypes.SegmentShape, !displaySegments);
                  }}
                >
                  <Switch
                    checked={displaySegments}
                    onChange={(e) => handleChangeDisplay(ShapeTypes.SegmentShape, e)}
                    name="Segments"
                  />{' '}
                  Segments
                </MenuItem>
              </Box>
              <Tooltip
                title={
                  extendedLengthTurns
                    ? 'Display extended length + turns connected '
                    : 'Hide extended length + turns connected'
                }
              >
                <Box component="div" sx={{ paddingRight: '10px' }}>
                  <IconButton
                    disabled={!displaySegments}
                    sx={{ color: !extendedLengthTurns ? unCheckColor : checkColor }}
                    onClick={() => {
                      handleExtendedLengthTurns(!extendedLengthTurns);
                    }}
                  >
                    {extendedLengthTurns ? <StraightIcon /> : <RampRightIcon />}
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay(ShapeTypes.MeasurerShape, !displayMeasurers);
              }}
            >
              <Switch
                checked={displayMeasurers}
                onChange={(e) => {
                  handleChangeDisplay(ShapeTypes.MeasurerShape, e);
                }}
                name="Measurers"
              />{' '}
              Measurers
            </MenuItem>
            <Box component="div" sx={{ display: 'flex' }}>
              <Box component="div" sx={{ width: '100%' }}>
                <MenuItem
                  onClick={(e) => {
                    handleChangeDisplay(ShapeTypes.TurnShape, !displayTurns);
                  }}
                >
                  <Switch
                    checked={displayTurns}
                    onChange={(e) => handleChangeDisplay(ShapeTypes.TurnShape, e)}
                  ></Switch>{' '}
                  Turns
                </MenuItem>
              </Box>
              <Box component="div" sx={{ paddingRight: '10px' }}>
                <Tooltip title="Toggle the highlight turns mode [H]">
                  <IconButton disabled={!displayTurns} color="inherit" onClick={handleVisualTurn}>
                    <HighlightIcon sx={{ color: visualAvailable ? unCheckColor : checkColor }}></HighlightIcon>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box component="div" sx={{ display: 'flex' }}>
              <Box component="div" sx={{ width: '100%' }}>
                <MenuItem
                  onClick={(e) => {
                    handleChangeDisplay(ShapeTypes.StockZoneShape, !displayStockZones);
                  }}
                >
                  <Switch
                    checked={displayStockZones}
                    onChange={(e) => handleChangeDisplay(ShapeTypes.StockZoneShape, e)}
                    name="Stock Zones"
                  />{' '}
                  Stock Zones
                </MenuItem>
              </Box>
              <Tooltip title={displayStockLineNames ? 'Hide all stock line names' : 'Display all stock line names '}>
                <Box component="div" sx={{ pr: '10px' }}>
                  <IconButton
                    disabled={!displayStockZones}
                    sx={{ color: !displayStockLineNames ? unCheckColor : checkColor }}
                    onClick={() => {
                      handleStockLineNames(!displayStockLineNames);
                    }}
                  >
                    {displayStockLineNames ? <FontDownloadIcon /> : <FontDownloadOffIcon />}
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay(ShapeTypes.RackShape, !displayRacks);
              }}
            >
              <Switch checked={displayRacks} onChange={(e) => handleChangeDisplay(ShapeTypes.RackShape, e)}></Switch>{' '}
              Racks
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay(ShapeTypes.DeviceShape, !displayDevices);
              }}
            >
              <Switch
                checked={displayDevices}
                onChange={(e) => handleChangeDisplay(ShapeTypes.DeviceShape, e)}
              ></Switch>{' '}
              Devices
            </MenuItem>
            <Box component="div" sx={{ display: 'flex' }}>
              <Box component="div" sx={{ width: '100%' }}>
                <MenuItem
                  onClick={(e) => {
                    handleChangeDisplay('Gabarit', !displayGabarit);
                  }}
                >
                  <Switch checked={displayGabarit} onChange={(e) => handleChangeDisplay('Gabarit', e)}></Switch>
                  Gabarits
                </MenuItem>
              </Box>
              <Box component="div" sx={{ paddingRight: '10px' }}>
                <Tooltip title="Delete all gabarits">
                  <IconButton onClick={handleDeleteAllGabarits} size="large">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay(ShapeTypes.NoteShape, !displayNotes);
              }}
            >
              <Switch
                checked={displayNotes}
                onChange={(e) => {
                  handleChangeDisplay(ShapeTypes.NoteShape, e);
                }}
                name="Notes"
              />
              Notes
            </MenuItem>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'mapPanel'} onChange={handleChangeAccordion('mapPanel')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Maps</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay('Map', !displayMap);
              }}
            >
              <Switch checked={displayMap} onChange={(e) => handleChangeDisplay('Map', e)}></Switch> Navigation Map
            </MenuItem>
            <Collapse in={displayMap} timeout="auto" sx={{ flexShrink: 0 }}>
              <Tooltip title="Navigation Lidar Map opacity">
                <div>
                  <MenuItem disabled={!displayMap} style={!displayMap ? { pointerEvents: 'none' } : {}}>
                    <Slider value={mapOpacityRealTime} onChange={handleChangeMapOpacity} min={0} max={100} />
                    <OpacityIcon style={{ marginLeft: '20px' }}></OpacityIcon>
                  </MenuItem>
                </div>
              </Tooltip>
            </Collapse>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay('MapObstacle', !displayMapObstacle);
              }}
            >
              <Switch checked={displayMapObstacle} onChange={(e) => handleChangeDisplay('MapObstacle', e)}></Switch>
              Obstacle Lidar Map
            </MenuItem>
            <Collapse in={displayMapObstacle} timeout="auto" sx={{ flexShrink: 0 }}>
              <Tooltip title="Obstacle map opacity">
                <div>
                  <MenuItem disabled={!displayMapObstacle} style={!displayMapObstacle ? { pointerEvents: 'none' } : {}}>
                    <Slider
                      value={mapObstacleOpacityRealTime}
                      onChange={handleChangeObstacleOpacity}
                      min={0}
                      max={100}
                    />
                    <OpacityIcon style={{ marginLeft: '20px' }}></OpacityIcon>
                  </MenuItem>
                </div>
              </Tooltip>
            </Collapse>
            <MenuItem
              onClick={(e) => {
                handleChangeDisplay('MapImage', !displayMapImage);
              }}
            >
              <Switch checked={displayMapImage} onChange={(e) => handleChangeDisplay('MapImage', e)}></Switch> Layout
              Image{mapImageArray.length > 1 ? 's' : ''}
            </MenuItem>
            <Collapse in={displayMapImage} timeout="auto" sx={{ flexShrink: 0 }}>
              <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
                <MenuItem disabled={!displayMapImage} style={!displayMapImage ? { pointerEvents: 'none' } : {}}>
                  <Tooltip title="Layout Image Opacity">
                    <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <Slider
                        value={mapImageOpacityRealTime}
                        onChange={handleChangeMapImageOpacity}
                        min={0}
                        max={100}
                      />
                      <OpacityIcon style={{ marginLeft: '20px' }}></OpacityIcon>
                    </Box>
                  </Tooltip>
                </MenuItem>
                {mapImageArray.length ? (
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: theme.spacing(2),
                    }}
                  >
                    <Divider orientation="vertical" flexItem />
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      {mapImageArray.map((mapImage, mapImageIndex) => {
                        return (
                          <Box
                            component="div"
                            key={`${mapImage.name}-filter-${mapImageIndex}`}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: 'block',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <Switch
                                checked={mapImage.toggle}
                                onChange={(e) => handleChangeMapImageArrayDisplay(e.target.checked, mapImage.name)}
                              ></Switch>{' '}
                              <Tooltip title={mapImage.name.split('.')[0]?.split('_S')[0]}>
                                <span>{mapImage.name.split('.')[0]?.split('_S')[0]}</span>
                              </Tooltip>
                            </MenuItem>
                            <Collapse in={mapImage.toggle} timeout="auto" sx={{ flexShrink: 0 }}>
                              <Tooltip title="Layout Image Opacity">
                                <MenuItem sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                  <Slider
                                    value={mapImageOpacityObj[mapImage.name]?.value || 0}
                                    onChange={(e, value) =>
                                      handleChangeMapImageArrayOpacity(mapImage.name, value as number, mapImage.isTiles)
                                    }
                                    min={0}
                                    max={100}
                                  />
                                  <OpacityIcon sx={{ marginLeft: '20px' }}></OpacityIcon>
                                </MenuItem>
                              </Tooltip>
                            </Collapse>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Collapse>
          </AccordionDetails>
        </Accordion>
      </Menu>
    </>
  );
}

function OpenProjectSettingsBtn(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.ProjectSettings,
        payload: undefined,
      })
    );
  }, [dispatch]);

  const openProjectPerm = useAsyncMemo(() => {
    return checkPermission('open:project');
  }, []);

  return (
    <Tooltip
      title={openProjectPerm ? 'Open Project Settings' : 'You do not have the permission to open a project'}
      disableInteractive
    >
      <Box component="span">
        <IconButton onClick={handleClick} disabled={!openProjectPerm}>
          <BadgeIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

function EditorModeButton(): JSX.Element {
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector((state) => state.editor.editorMode);
  const simulation = useAppSelector((state) => state.simulation);

  const handleChangeEditorMode = useCallback(
    (e: React.MouseEvent<HTMLElement>, newMode: string | null) => {
      if (newMode === null) return;
      if (!isEditorMode(newMode)) {
        // eslint-disable-next-line no-console
        console.error(`Invalid editor mode: ${newMode}`);

        return;
      }

      if (simulation.hasSimulationStarted) {
        dispatch(openDialogAction({ type: DialogTypes.ExitRunningSimulation, payload: { newToolOrMode: newMode } }));
      } else {
        // We don't want to be able to select the previous tool of another editor mode hence the dispatch of the same action twice
        dispatch(
          selectToolAction({
            toolName: Tools.Move,
          })
        );

        dispatch(
          setEditorMode({
            editorMode: newMode,
          })
        );

        if (newMode !== 'circuit') {
          dispatch(clearShapesSelectionAction());
        }
      }
    },
    [dispatch, simulation.hasSimulationStarted]
  );

  return (
    <ToggleButtonGroup value={editorMode} exclusive onChange={handleChangeEditorMode} size="small">
      <ToggleButton value="circuit">
        <Tooltip placement="top" title={'Circuit Modification'}>
          <DriveFileRenameOutlineIcon color={editorMode === 'circuit' ? 'primary' : undefined} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="traffic">
        <Tooltip placement="top" title={'Traffic Modification'}>
          <Signpost color={editorMode === 'traffic' ? 'primary' : undefined} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="flow">
        <Tooltip placement="top" title={'Flow Configuration'}>
          <Box
            component="span"
            sx={{
              display: 'flex',
            }}
          >
            <NearMe color={editorMode === 'flow' ? 'primary' : undefined} />
          </Box>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
