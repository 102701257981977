import { Dashboard } from '@mui/icons-material';
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useCallback, useState } from 'react';
import { AislesOptionsDialog } from './aisles-dialog';

export function DetectAislesBtn(): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpenAislesOptions = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <ListItem>
      <ListItemIcon>
        <Dashboard />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            <Button onClick={handleOpenAislesOptions} variant="outlined">
              Aisles Options
            </Button>
            <AislesOptionsDialog open={open} setOpen={setOpen} />
          </>
        }
        secondary={
          <>
            Create or update zones for rack aisles
            <HelpIconTooltip
              title="Open a menu to choose on which layer(s) create or display zones/middle segments for rack ailes."
              sx={{
                fontSize: '1rem',
              }}
            />
          </>
        }
      />
    </ListItem>
  );
}
