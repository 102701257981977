/**
 * Deeply merges two objects by summing or subtracting their numeric values. If a key exists in both objects:
 * - and its value is an object, it recursively merges them.
 * - and its value is a number, it sums up the values.
 * Non-numeric values in the second object are ignored.
 *
 * @link https://stackoverflow.com/a/47559788/1090255
 *
 * @param {Record<string, any>} obj1 - The first object to merge.
 * @param {Record<string, any>} obj2 - The second object to merge, its numeric values are added to `obj1`'s values.
 * @param substraction - Boolean to know if we want to substracting the numeric values of the two objects.
 * @returns {Record<string, any>} A new object with the deep merged values of `obj1` and `obj2`.
 */
export const deepMerge = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  substraction?: boolean
): Record<string, any> => {
  return Object.keys(obj1).reduce((acc: Record<string, any>, key: string) => {
    if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
      acc[key] = deepMerge(obj1[key], obj2[key], substraction);
    } else if (obj2.hasOwnProperty(key) && !isNaN(parseFloat(obj2[key]))) {
      acc[key] = !substraction
        ? (parseFloat(obj1[key]) || 0) + (parseFloat(obj2[key]) || 0)
        : (parseFloat(obj1[key]) || 0) - (parseFloat(obj2[key]) || 0);
    }

    return acc;
  }, {});
};
