import { List, Typography } from '@mui/material';
import { Tools } from 'models/tools';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

export const LayersShortcuts = (): JSX.Element => {
  const tool = useAppSelector((state) => state.tool.activeTool);
  const lastTool = useAppSelector((state) => state.tool.lastActiveTool);

  const flowMode = useAppSelector((state) => state.editor.editorMode === 'flow');

  const shortcuts = useMemo(() => {
    const baseShortcuts = [['[Space]', `Previous Tool : ${lastTool}`]];

    if (tool !== Tools.Move) {
      baseShortcuts.unshift(['[Escape]', 'Move']);
    }

    if (flowMode) return baseShortcuts;

    baseShortcuts.push(['(Hover) [L] / [U]', 'Lock / Unlock shape']);

    if (tool === Tools.Move) {
      baseShortcuts.unshift(
        ['(Hover) [F] / [B]', 'Bring to front / to back'],
        ['[Ctrl] + Click', 'Select multiple shapes'],
        ['[Alt] + Click', 'Select nearest segment']
      );
    }

    if ([Tools.DrawSegmentOrTurn, Tools.AddTurn].includes(tool)) {
      baseShortcuts.unshift(['[Ctrl] + Drag', 'Connect 2 segments']);
    }

    return baseShortcuts;
  }, [tool, lastTool, flowMode]);

  return (
    <List
      sx={{
        pointerEvents: 'none',
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
        }),
        textAlign: 'start',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        paddingBottom: 0,
        marginBottom: theme.spacing(1),
        color: theme.palette.grey[700],
        userSelect: 'none',
      }}
      dense={true}
    >
      {shortcuts.map(([shortcut, description]) => (
        <Typography variant="body2" key={`${shortcut}-${description}`}>
          <b>{shortcut}</b> {description}
        </Typography>
      ))}
    </List>
  );
};
