/**
 * Take a list of points and return these points in the clockwise direction
 * @param points the list of points
 * @returns the list of points in the clockwise direction
 */
export function makePolygonClockwise(points: number[][]): number[][] {
  const center = points.reduce(
    (acc, [x, y]) => {
      acc.x += x / points.length;
      acc.y += y / points.length;

      return acc;
    },
    { x: 0, y: 0 }
  );

  const angles = points.map(([x, y]) => {
    return { x, y, angle: (Math.atan2(y - center.y, x - center.x) * 180) / Math.PI };
  });

  const pointsSorted = angles.sort((a, b) => b.angle - a.angle);

  return pointsSorted.map(({ x, y }) => [x, y]);
}
