/* eslint-disable react-hooks/exhaustive-deps */
import { throttle } from 'lodash';
import { unsetFollowUser } from 'multiplayer/multiplayer';
import { useCallback, useEffect, useState } from 'react';
import type { UserProfileData } from 'shared';
import store from 'store';
import type { Awareness } from 'y-protocols/awareness';
import { THROTTLE, localDoc } from '../../multiplayer/globals';
import type { UserPresence } from '../../multiplayer/types';
import { Cursor } from './cursor';

interface CursorsProps {
  awareness: Awareness;
  users: Map<number, UserPresence>;
  followClientId?: number;
}

type CustomEventListener = Parameters<Document['addEventListener']>[1];
type PositionEvent = CustomEvent<{ X: number; Y: number }>;
type ZoomEvent = CustomEvent<{ zoomScale: number }>;

function isPositionEvent(e: CustomEvent): e is PositionEvent {
  return 'X' in e.detail && 'Y' in e.detail;
}

function isZoomEvent(e: CustomEvent): e is ZoomEvent {
  return 'zoomScale' in e.detail;
}

export function Cursors({ awareness, users, followClientId }: CursorsProps): JSX.Element {
  const [globalZoom, setGlobalZoom] = useState(1);

  // When the user moves their pointer, update their position in the awareness map
  const handlePointMove: CustomEventListener = useCallback(
    throttle((e) => {
      awareness.setLocalStateField('p', [+(e.detail.X * 100).toFixed(4), +(e.detail.Y * -100).toFixed(4)]);
    }, THROTTLE),
    []
  );

  // When the user translates, update their position in the awareness map
  const handleTranslate = useCallback(
    throttle((e) => {
      if (!isPositionEvent(e)) return;
      awareness.setLocalStateField('t', [+e.detail.X.toFixed(4), +e.detail.Y.toFixed(4)]);
    }, THROTTLE),
    []
  );

  // When the user zooms, update their zoom in the awareness map
  const handleZoom = useCallback(
    throttle((e) => {
      if (!isZoomEvent(e)) return;
      awareness.setLocalStateField('z', +e.detail.zoomScale.toFixed(4));
    }, THROTTLE),
    []
  );

  const translateListener: CustomEventListener = (e): void => {
    if (!isPositionEvent(e)) return;
    handleTranslate(e);

    /* When following another user view, if the current user translate, unfollow */
    if (!followClientId) return;

    const translate = users.get(followClientId)?.t;
    if (!translate || e.detail.X !== translate[0] || e.detail.Y !== translate[1]) {
      store.dispatch(unsetFollowUser());
    }
  };

  const zoomListener: CustomEventListener = (e): void => {
    if (!isZoomEvent(e)) return;

    setGlobalZoom(e.detail.zoomScale);
    handleZoom(e);

    /* When following another user view, if the current user zoom, unfollow */
    if (!followClientId) return;

    const zoom = users.get(followClientId)?.z;
    if (e.detail.zoomScale !== zoom) {
      store.dispatch(unsetFollowUser());
    }
  };

  useEffect(() => {
    document.addEventListener('mousePositionUpdated', handlePointMove);

    return () => {
      document.removeEventListener('mousePositionUpdated', handlePointMove);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('translateUpdated', translateListener);

    return () => {
      document.removeEventListener('translateUpdated', translateListener);
    };
  }, [translateListener]);

  useEffect(() => {
    document.addEventListener('zoomUpdated', zoomListener);

    return () => {
      document.removeEventListener('zoomUpdated', zoomListener);
    };
  }, [zoomListener]);

  return (
    <g className="cursors">
      {Array.from(users.entries()).map(([key, value]) => {
        if (key === awareness.clientID) return null;

        const profilesMap = localDoc.getMap<UserProfileData>('profiles');
        const profile = profilesMap.get(key.toString());
        const inactiveMap = localDoc.getMap('inactive');
        const inactive = inactiveMap.has(key.toString());

        if (!profile) return null;

        /* Used to compensate the cursor zoom when following another user */
        let zoom: number | undefined;
        if (followClientId) {
          zoom = users.get(followClientId)?.z;
        }

        return (
          <Cursor
            key={key}
            followClientId={followClientId}
            zoom={zoom}
            globalZoom={+globalZoom}
            color={value.c}
            points={value.p}
            name={profile.name}
            inactive={inactive}
          />
        );
      })}
    </g>
  );
}
