import type { CircuitPoint } from 'models/circuit';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';

export const {
  types: PointsActionTypes,
  creators: {
    createFailure: createPointFailureAction,
    createSuccess: createPointSuccessAction,
    delete: deletePointAction,
    deleteFailure: deletePointFailureAction,
    deleteSuccess: deletePointSuccessAction,
    loadOne: loadPointAction,
    loadOneFailure: loadPointFailureAction,
    loadOneSuccess: loadPointSuccessAction,
    saveFailure: savePointFailureAction,
    saveSuccess: savePointSuccessAction,
    select: selectPointAction,
  },
} = createEntityActions<CircuitPoint>('Points');

const {
  creators: { save },
} = createEntityActions<CircuitPoint>('Points');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function savePointAction(payload: any): SavePoint {
  delete payload.userAction;
  let action = save(payload);
  delete payload.userAction;

  if (action.payload.properties) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        properties: {
          ...action.payload.properties,
          orientation: action.payload.properties?.orientation,
        },
      },
    };
  }

  return action;
}

export interface CreatePoint extends CreateAction<CircuitPoint> {}
export interface CreatePointFailure extends CreateFailureAction {}
export interface CreatePointSuccess extends CreateSuccessAction<CircuitPoint> {}
export interface DeletePoint extends DeleteAction {}
export interface DeletePointFailure extends DeleteFailureAction {}
export interface DeletePointSuccess extends DeleteSuccessAction {}
export interface LoadPoint extends LoadOneAction {}
export interface LoadPointFailure extends LoadOneFailureAction {}
export interface LoadPointSuccess extends LoadOneSuccessAction<CircuitPoint> {}
export interface SavePoint extends SaveAction<CircuitPoint> {}
export interface SavePointFailure extends SaveFailureAction {}
export interface SavePointSuccess extends SaveSuccessAction<CircuitPoint> {}
export interface SelectPoint extends SelectAction {}

export type PointActions =
  | CreatePoint
  | CreatePointFailure
  | CreatePointSuccess
  | DeletePoint
  | DeletePointFailure
  | DeletePointSuccess
  | LoadPoint
  | LoadPointFailure
  | LoadPointSuccess
  | SavePoint
  | SavePointFailure
  | SavePointSuccess
  | SelectPoint;
