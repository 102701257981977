/**
 * Returns -1 if aVersionStr is less than bVersionStr, 0 if they are equal, and 1 if aVersionStr is greater than bVersionStr.
 * @param aVersionStr the version string to compare
 * @param bVersionStr the version string to compare
 * @returns the code number to return
 */
export function versionCompare(aVersionStr: string, bVersionStr: string): -1 | 0 | 1 {
  const aVersion = aVersionStr.split('.').map((v) => parseInt(v, 10));
  const bVersion = bVersionStr.split('.').map((v) => parseInt(v, 10));

  if ((aVersion[0] ?? 0) < (bVersion[0] ?? 0)) return -1;
  if ((aVersion[0] ?? 0) > (bVersion[0] ?? 0)) return 1;
  if ((aVersion[1] ?? 0) < (bVersion[1] ?? 0)) return -1;
  if ((aVersion[1] ?? 0) > (bVersion[1] ?? 0)) return 1;
  if ((aVersion[2] ?? 0) < (bVersion[2] ?? 0)) return -1;
  if ((aVersion[2] ?? 0) > (bVersion[2] ?? 0)) return 1;
  if ((aVersion[3] ?? 0) < (bVersion[3] ?? 0)) return -1;
  if ((aVersion[3] ?? 0) > (bVersion[3] ?? 0)) return 1;

  return 0;
}
