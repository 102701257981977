import { useState, useEffect, useMemo } from 'react';
import type { ValidationError, Schema, SchemaDescription as YupSchemaDescription } from 'yup';
import type { Dictionary } from 'shared';

interface ControlProps {
  error: boolean;
  helperText: string;
  onBlur: React.EventHandler<any>;
}

interface ArraySchemaDescription extends YupSchemaDescription {
  type: 'array';
  innerType: SchemaDescription;
}

interface NotArraySchemaDescription extends YupSchemaDescription {
  type: 'object' | 'string' | 'number' | 'boolean' | 'mixed';
}

type SchemaDescription = ArraySchemaDescription | NotArraySchemaDescription;

export function useYupValidation<T>(schema: Schema<T>, formValue: T): [Dictionary<ControlProps>, boolean] {
  const [validationErrors, setValidationErrors] = useState<Dictionary<ValidationError>>({});
  const [isValid, setIsValid] = useState(true);
  const [touchedFields, setTouchedFields] = useState<Dictionary<boolean>>({});

  const schemaDescription = useMemo(() => schema.describe() as SchemaDescription, [schema]);
  const fieldNames = useMemo(() => getFlatSchemaPaths(schemaDescription, formValue), [schemaDescription, formValue]);

  const controlProps = useMemo(
    () =>
      fieldNames.reduce((result, fieldName) => {
        const isTouched = touchedFields[fieldName];
        const error = validationErrors[fieldName];

        result[fieldName] = {
          onBlur: () => setTouchedFields((state) => ({ ...state, [fieldName]: true })),
          error: isTouched && !!error,
          helperText: isTouched && error && error.message,
        };

        return result;
      }, {}),
    [fieldNames, touchedFields, validationErrors, setTouchedFields]
  );

  useEffect(() => {
    try {
      schema.validateSync(formValue, { abortEarly: false });
      setIsValid(true);
      setValidationErrors({});
    } catch (ex) {
      const validationError = ex as ValidationError;

      setValidationErrors(
        validationError.inner.reduce((result, error) => {
          result[error.path] = error;

          return result;
        }, {})
      );
      setIsValid(false);
    }
  }, [schema, formValue, setIsValid, setValidationErrors]);

  return [controlProps, isValid];
}

function getFlatSchemaPaths(description: SchemaDescription, formValue: any, basePath = ''): string[] {
  const prefix = basePath ? basePath + '.' : '';

  if (description.type === 'array') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const length = formValue ? formValue.length || 0 : 0;
    const names: string[] = basePath ? [basePath] : [];

    if (isNestedType(description.innerType.type)) {
      const template = getFlatSchemaPaths(description.innerType, formValue ? formValue[0] : undefined, basePath);

      for (let i = 0; i < length; i++) {
        template.forEach((item) => names.push(`${prefix}[${i}].${item}`));
      }
    } else {
      for (let i = 0; i < length; i++) {
        names.push(`${prefix}[${i}]`);
      }
    }

    return names;
  } else if (description.type === 'object') {
    return Object.keys(description.fields).reduce((result, fieldName) => {
      const field: any = description.fields[fieldName];

      if (isNestedType(field.type)) {
        return result.concat(
          getFlatSchemaPaths(field, formValue ? formValue[fieldName] : undefined, prefix + fieldName)
        );
      }

      result.push(prefix + fieldName);

      return result;
    }, [] as string[]);
  }

  return [];
}

function isNestedType(type: string): boolean {
  return type === 'array' || type === 'object';
}
