import type { PaletteColorOptions } from '@mui/material';

export const balyoBlue: PaletteColorOptions = {
  main: '#135ba0',
  light: '#5587d2',
  dark: '#003271',
  contrastText: '#ffffff',
};
export const balyoRed: PaletteColorOptions = {
  main: '#f04338',
  light: '#ff7863',
  dark: '#b6000f',
  contrastText: '#000000',
};

export const balyoGradient = [
  '#135ba0',
  '#1589a7',
  '#18ada1',
  '#1bb47c',
  '#1dbb54',
  '#20c12a',
  '#48c823',
  '#7ccf26',
  '#b2d52a',
  '#dcce2d',
  '#e3a131',
  '#e97334',
  '#f04338',
];

export const gradientB2R = [
  '#00429d',
  '#2451a4',
  '#3761ab',
  '#4771b2',
  '#5681b9',
  '#6492c0',
  '#73a2c6',
  '#82b3cd',
  '#93c4d2',
  '#a5d5d8',
  '#b9e5dd',
  '#d3f4e0',
  '#ffffe0',
  '#ffe9d0',
  '#ffd3bf',
  '#ffbcaf',
  '#ffa59e',
  '#fc8d8e',
  '#f4777f',
  '#ea6272',
  '#dd4c65',
  '#cf3759',
  '#be214d',
  '#aa0b43',
  '#93003a',
];
