import { FormControl, FormControlLabel, FormHelperText, FormLabel, Switch } from '@mui/material';
import { setPerformanceMode } from 'editor/editor';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

export function PerformanceModeSwitch(): JSX.Element {
  const performanceMode = useAppSelector((state) => state.editor.performanceMode);
  const dispatch = useAppDispatch();

  const handleChangePerfMode = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(
        setPerformanceMode({
          performanceMode: checked,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <FormControl
        hiddenLabel
        size="small"
        fullWidth
        sx={{
          marginTop: theme.spacing(1),
        }}
      >
        <FormLabel>Performance Mode</FormLabel>

        <FormControlLabel
          labelPlacement="start"
          control={<Switch checked={performanceMode} onChange={handleChangePerfMode} name="Performance Mode" />}
          label="Performance Mode"
          sx={{
            flexDirection: 'row',
          }}
        ></FormControlLabel>

        <FormHelperText>
          This mode will disable some features to improve performance. It is recommended to enable this mode when
          working with large projects.
        </FormHelperText>
      </FormControl>
    </>
  );
}
