import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

/**
 * Let's use a colorblind friendly palette
 * Let's use the Bang Wong one
 */
export const cantonsColors = {
  noStopCantons: '#D55E00',
  noStopZoneCantons: '#0072B2',
  currentCantons: '#009E73',
  linkedByCollisionCantons: '#F0E442',
  linkedByDeadendCantons: '#E69F00',
  linkedByZoneCantons: '#CC79A7',
  manualCantons: '#000000',

  truckPattern: '#56B4E9',

  manoeuver: '#A701C1',
  kernelNoStop: '#7DE079',
  neutral: '#A8F1FF',
  blocked: '#D67ACC',

  occupiedCantons: '#f04338',
  reservedCantons: '#E69F00',
};

/**
 * List of cantons with their start and end coordinates
 * The key is the canton id
 */
export type CantonsDict = Record<
  string,
  | {
      start: {
        x: number; // [m]
        y: number; // [m]
      };
      end: {
        x: number; // [m]
        y: number; // [m]
      };
      /** Robot index that generated this canton */
      robotIndex?: number;
    }
  | undefined
>;

export interface TrafficSliceState {
  noStopCantons: CantonsDict;
  noStopZoneCantons: CantonsDict;
  currentCantons: CantonsDict;
  linkedByCollision: CantonsDict;
  linkedByDeadend: CantonsDict;
  linkedByZone: CantonsDict;
  manualCantons: CantonsDict;

  manoeuver: CantonsDict;
  kernelNoStop: CantonsDict;
  neutral: CantonsDict;
  blocked: CantonsDict;

  /**
   * Occupied cantons by the robots during the simulation
   */
  occupiedCantons: CantonsDict;

  /**
   * Reserved cantons by the robots during the simulation
   */
  reservedCantons: CantonsDict;

  truckPatterns: {
    x: number;
    y: number;
  }[][];

  display: {
    noStopCantons: boolean;
    noStopZoneCantons: boolean;
    currentCantons: boolean;
    linkedByCollision: boolean;
    linkedByDeadend: boolean;
    linkedByZone: boolean;
    manualCantons: boolean;

    manoeuver: boolean;
    kernelNoStop: boolean;
    neutral: boolean;
    blocked: boolean;

    occupiedCantons: boolean;
    reservedCantons: boolean;

    truckPattern: boolean;

    keepDisplayOnLeave: boolean;
  };
}

const localStorageKeepDisplayOnLeaveKey = 'traffic-keepDisplayOnLeave';

const initialState: TrafficSliceState = {
  noStopCantons: {},
  noStopZoneCantons: {},
  currentCantons: {},
  linkedByCollision: {},
  linkedByDeadend: {},
  linkedByZone: {},
  manualCantons: {},
  truckPatterns: [],
  manoeuver: {},
  kernelNoStop: {},
  neutral: {},
  blocked: {},

  occupiedCantons: {},
  reservedCantons: {},

  display: {
    noStopCantons: false,
    noStopZoneCantons: false,
    currentCantons: true,
    linkedByCollision: true,
    linkedByDeadend: false,
    linkedByZone: false,
    manualCantons: true,
    manoeuver: false,
    kernelNoStop: false,
    neutral: false,
    blocked: false,
    truckPattern: true,
    keepDisplayOnLeave: localStorage.getItem(localStorageKeepDisplayOnLeaveKey) === 'true',
    occupiedCantons: true,
    reservedCantons: true,
  },
};

const trafficSlicePrivate = createSlice({
  initialState,
  name: 'traffic',
  reducers: {
    addNoStopCanton: (state, action: PayloadAction<CantonsDict>) => {
      state.noStopCantons = { ...action.payload };
    },
    addNoStopZoneCanton: (state, action: PayloadAction<CantonsDict>) => {
      state.noStopZoneCantons = { ...action.payload };
    },

    setCurrentCantons: (state, action: PayloadAction<CantonsDict>) => {
      state.currentCantons = action.payload;
    },
    setLinkedByCollision: (state, action: PayloadAction<CantonsDict>) => {
      state.linkedByCollision = action.payload;
    },
    setLinkedByDeadend: (state, action: PayloadAction<CantonsDict>) => {
      state.linkedByDeadend = action.payload;
    },
    setLinkedByZone: (state, action: PayloadAction<CantonsDict>) => {
      state.linkedByZone = action.payload;
    },
    setManualCantons: (state, action: PayloadAction<CantonsDict>) => {
      state.manualCantons = action.payload;
    },
    setTruckPattern: (state, action: PayloadAction<{ x: number; y: number }[]>) => {
      state.truckPatterns = [action.payload];
    },
    setManoeuver: (state, action: PayloadAction<CantonsDict>) => {
      state.manoeuver = action.payload;
    },
    setKernelNoStop: (state, action: PayloadAction<CantonsDict>) => {
      state.kernelNoStop = action.payload;
    },
    setNeutral: (state, action: PayloadAction<CantonsDict>) => {
      state.neutral = action.payload;
    },
    setBlocked: (state, action: PayloadAction<CantonsDict>) => {
      state.blocked = action.payload;
    },
    setKeepDisplayOnLeave: (state, action: PayloadAction<boolean>) => {
      state.display.keepDisplayOnLeave = action.payload;

      localStorage.setItem(localStorageKeepDisplayOnLeaveKey, action.payload.toString());
    },
    setOccupiedCantons: (state, action: PayloadAction<CantonsDict>) => {
      state.occupiedCantons = action.payload;
    },
    setReservedCantons: (state, action: PayloadAction<CantonsDict>) => {
      state.reservedCantons = action.payload;
    },

    setCantonsAtPoint: (
      state,
      action: PayloadAction<
        Partial<{
          currentCanton: CantonsDict;
          linkedByCollision: CantonsDict;
          linkedByDeadend: CantonsDict;
          linkedByZone: CantonsDict;
          manualCantons: CantonsDict;
          pattern: { x: number; y: number }[];
        }>
      >
    ) => {
      const payload = action.payload;

      state.currentCantons = payload.currentCanton ?? {};
      state.linkedByCollision = payload.linkedByCollision ?? {};
      state.linkedByDeadend = payload.linkedByDeadend ?? {};
      state.linkedByZone = payload.linkedByZone ?? {};
      state.manualCantons = payload.manualCantons ?? {};
      state.truckPatterns = [payload.pattern ?? []];
    },
    setMultipleCantonsAtPoint: (
      state,
      action: PayloadAction<
        Partial<{
          currentCanton: CantonsDict;
          linkedByCollision: CantonsDict;
          linkedByDeadend: CantonsDict;
          linkedByZone: CantonsDict;
          manualCantons: CantonsDict;
          pattern: { x: number; y: number }[];
          manoeuver: CantonsDict;
          kernelNoStop: CantonsDict;
          neutral: CantonsDict;
          blocked: CantonsDict;
        }>[]
      >
    ) => {
      const payload = action.payload;

      state.currentCantons = {};
      state.linkedByCollision = {};
      state.linkedByDeadend = {};
      state.linkedByZone = {};
      state.manualCantons = {};
      state.truckPatterns = [];
      state.manoeuver = {};
      state.kernelNoStop = {};
      state.neutral = {};
      state.blocked = {};

      payload.forEach((element) => {
        state.currentCantons = { ...state.currentCantons, ...(element?.currentCanton ?? {}) };
        state.linkedByCollision = { ...state.linkedByCollision, ...(element?.linkedByCollision ?? {}) };
        state.linkedByDeadend = { ...state.linkedByDeadend, ...(element?.linkedByDeadend ?? {}) };
        state.linkedByZone = { ...state.linkedByZone, ...(element?.linkedByZone ?? {}) };
        state.manualCantons = { ...state.manualCantons, ...(element?.manualCantons ?? {}) };
        state.manoeuver = { ...state.manoeuver, ...(element?.manoeuver ?? {}) };
        state.kernelNoStop = { ...state.kernelNoStop, ...(element?.kernelNoStop ?? {}) };
        state.neutral = { ...state.neutral, ...(element?.neutral ?? {}) };
        state.blocked = { ...state.blocked, ...(element?.blocked ?? {}) };
        if (element.pattern) {
          state.truckPatterns.push(element.pattern);
        }
      });
    },

    setDeadendsAtPoint: (
      state,
      action: PayloadAction<
        Partial<{
          manoeuver: CantonsDict;
          kernelNoStop: CantonsDict;
          neutral: CantonsDict;
          blocked: CantonsDict;
        }>
      >
    ) => {
      const payload = action.payload;

      state.manoeuver = payload.manoeuver ?? {};
      state.kernelNoStop = payload.kernelNoStop ?? {};
      state.neutral = payload.neutral ?? {};
      state.blocked = payload.blocked ?? {};
    },

    setCantonsDisplay: (state, action: PayloadAction<Partial<TrafficSliceState['display']>>) => {
      const payload = action.payload;

      state.display.noStopCantons = payload.noStopCantons ?? state.display.noStopCantons;
      state.display.noStopZoneCantons = payload.noStopZoneCantons ?? state.display.noStopZoneCantons;
      state.display.currentCantons = payload.currentCantons ?? state.display.currentCantons;
      state.display.linkedByCollision = payload.linkedByCollision ?? state.display.linkedByCollision;
      state.display.linkedByDeadend = payload.linkedByDeadend ?? state.display.linkedByDeadend;
      state.display.linkedByZone = payload.linkedByZone ?? state.display.linkedByZone;
      state.display.manualCantons = payload.manualCantons ?? state.display.manualCantons;
      state.display.truckPattern = payload.truckPattern ?? state.display.truckPattern;
      state.display.manoeuver = payload.manoeuver ?? state.display.manoeuver;
      state.display.kernelNoStop = payload.kernelNoStop ?? state.display.kernelNoStop;
      state.display.neutral = payload.neutral ?? state.display.neutral;
      state.display.blocked = payload.blocked ?? state.display.blocked;
      state.display.occupiedCantons = payload.occupiedCantons ?? state.display.occupiedCantons;
      state.display.reservedCantons = payload.reservedCantons ?? state.display.reservedCantons;
    },

    clearAllCantons: (state) => {
      // we remove all the cantons but we keep the display settings
      const display = state.display;

      return {
        ...initialState,
        display,
      };
    },
  },
});

export const trafficSlice = {
  reducer: trafficSlicePrivate.reducer,
  name: trafficSlicePrivate.name,
};

export const {
  addNoStopCanton,
  addNoStopZoneCanton,
  clearAllCantons,
  setCantonsAtPoint,
  setCantonsDisplay,
  setDeadendsAtPoint,
  setKeepDisplayOnLeave,
  setOccupiedCantons,
  setReservedCantons,
  setMultipleCantonsAtPoint,
} = trafficSlicePrivate.actions;
