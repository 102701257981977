/**
 * Get a random item from a set
 * @param set the set to get a random item from
 * @returns the random item
 */
export function getRandomItem<T>(set: Set<T>): T {
  return Array.from(set)[Math.floor(Math.random() * set.size)];
}

/**
 * Compare two sets for equality
 * From stackoverflow https://stackoverflow.com/a/31129384/1090255
 * @param xs one set
 * @param ys the other set
 * @returns whether the sets are equal
 */
export function eqSet(xs: Set<unknown>, ys: Set<unknown>): boolean {
  return xs.size === ys.size && [...xs].every((x) => ys.has(x));
}
