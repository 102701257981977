import { CircularProgress, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import type { SimulationSliceState } from 'simulation/simulation';

interface LoadingSimulationIndicatorProps {
  loadingState: SimulationSliceState['loading'];
}

export function LoadingSimulationIndicator(props: LoadingSimulationIndicatorProps): JSX.Element {
  const { loadingState } = props;

  const [lastInitializedIndicator, setLastInitializedIndicator] = useState<number | undefined>(undefined);

  const briefCounter = loadingState?.stepNb ?? 0;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        marginTop: (theme) => theme.spacing(2),
        marginLeft: (theme) => theme.spacing(2),
        marginRight: (theme) => theme.spacing(2),
      }}
    >
      {Array.from({ length: briefCounter + 1 }).map((_, i) => {
        const isLast = i === briefCounter;
        const title = isLast ? loadingState?.brief : undefined;

        const isIndeterminated =
          (i === 0 && briefCounter === 0) || (i === briefCounter && loadingState?.percent === undefined);
        let value: number | undefined = undefined;
        if (!isIndeterminated) {
          if (isLast) {
            value = (loadingState?.percent ?? 0) * 100;
          } else {
            value = 100;
          }
        }

        if (!lastInitializedIndicator || lastInitializedIndicator < i) {
          // this block is used to set the initial value to 0 and then set the real value, for animating purposes
          value = 0;
          setTimeout(() => setLastInitializedIndicator((prev) => (prev === undefined || i > prev ? i : prev)), 0);
        }

        return (
          <Tooltip title={title} key={`loading-circle-tooltip-${i}`} open={!!title} arrow placement="top">
            <CircularProgress
              key={`loading-circle-${i}`}
              size={15}
              variant={isIndeterminated ? 'indeterminate' : 'determinate'}
              value={value}
            />
          </Tooltip>
        );
      })}
    </Stack>
  );
}
