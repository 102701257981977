import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TitleIcon from '@mui/icons-material/Title';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import type { SelectChangeEvent } from '@mui/material';
import {
  Badge,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Stack } from '@mui/system';
import { animated, useSpring } from '@react-spring/web';
import { closeDialogAction } from 'actions';
import { changeLockStateAction } from 'actions/circuit';
import { deleteStockZoneAction, saveStockZoneAction } from 'actions/stock-zones';
import PalletSVG from 'assets/pallet-top-view.svg';
import { ToggleButtonDisabledInStandard, textHelperDisabledStandardMode } from 'components/utils/standard-mode-helper';
import { ToggleButtonWithTooltip } from 'components/utils/tooltips';
import * as d3 from 'd3';
import { copySelectionAction } from 'drawings/copy-paste';
import { cloneDeep } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import type {
  CircuitStockZone,
  PalletPosition,
  Size3D,
  SlotArray,
  StockZoneProperties,
  fillOrEmptyStrategy,
} from 'models/circuit';
import { PatternTypes, ReferenceMethodX, ReferenceMethodY, ShapeTypes } from 'models/circuit';
import React, { startTransition, useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import type { SelectedShapeData } from 'reducers/local/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { computeNbLoadsInStockZone } from 'utils/circuit/compute-nb-loads';
import { getEuroPallet } from 'utils/circuit/default-circuit-shapes';
import { updateStockZone } from 'utils/circuit/stockzones';
import { gradientB2R } from 'utils/colors/colors';
import { humanize, toRad } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { convertSlotLineToName, isValidSlotLineName, type BlockDataSlotLineName } from './stock-zone-naming';
import { RenameStocklinesMenu } from './stock-zone-settings-rename-stocklines';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const WIDTH_FIRST_TEXTFIELD = 50;
const HEIGHT_TEXTFIELD = 30;
const nbMaxIter = 1000;

/**
State for zoom feature
 */
export let zoomStockZoneEdition: d3.ZoomBehavior<Element, unknown> | undefined;
export function setZoomStockZoneEdition(zoom: d3.ZoomBehavior<Element, unknown>): void {
  zoomStockZoneEdition = zoom;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    floatRight: {
      float: 'right',
    },
    listHeaderBg: {
      background: theme.palette.common.white,
    },
    selectDistributionButtons: {
      textAlign: 'center',
    },
    dialogTitle: {
      paddingBottom: '0',
    },
    smallSpaceTop: {
      marginTop: '20px',
    },
    visualizePallet: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      flexGrow: 4,
      maxWidth: '500px',
    },
    center: {
      textAlign: 'center',
    },
    burger: {
      flexWrap: 'nowrap',
    },
    toggleButtonDense: {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
    textFieldWidth: {
      maxWidth: '200px',
    },
    greyEvenChildren: {
      '& li:nth-child(2n)': {
        background: 'rgba(0, 0, 0, 0.02)',
      },
    },
    iconNormalHeight: {
      marginTop: '5px',
    },
    disabled: {
      color: theme.palette.grey[300],
      pointerEvents: 'none',
    },
    positionGap: {
      textAlign: 'left',
      verticalAlign: 'middle',
      width: '100%',
      padding: 0,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '250px',
      minWidth: '200px',
    },
    chip: {
      margin: 2,
    },
    textFieldPallet: {
      textAlign: 'center',
      maxWidth: '500px',
    },
    textFieldBetweenLines: {
      textAlign: 'center',
      flexGrow: 12,
    },
    textFieldBetweenSlots: {
      textAlign: 'center',
      width: `${WIDTH_FIRST_TEXTFIELD}px`,
      height: `${HEIGHT_TEXTFIELD}px`,
      overflow: 'visible',
      padding: 0,
    },
    palletSvgIcon: {
      cursor: 'pointer',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      pointerEvents: 'bounding-box' as any,

      '&[data-disabled=true] path': {
        filter: 'grayscale(100%)',
      },
    },
    palletToDisplaySelect: {
      width: '200px',
    },
    marginAuto: {
      margin: 'auto',
    },
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },
  })
);

enum PropagationMethod {
  offset = 'offset',
  clearance = 'clearance',
}

enum SelectionTypes {
  uniform = 'uniform',
  line = 'line',
  column = 'column',
  custom = 'custom',
}

const epsilon = 1e-5;

/**
 * actions to update the state of the stockzone (NOT Redux actions)
 * it is used to update the preview and the state of the rack inside the StockZoneSettings
 * A Redux action has to be dispatched to actually update the Redux store
 */
export interface ActionsStockZone {
  /** generate and assign a name (string) to cells from a rule */
  assignNameToStockLines: (
    assignTo: 'selection' | 'all',
    rules: BlockDataSlotLineName[] | null,
    applyToAllLoads?: boolean,
    options?: {
      onlyNotUserDefinedNames: boolean;
      nameToUse: string;
    }
  ) => void;
  /** update the stock zone's slots */
  setSlots: React.Dispatch<React.SetStateAction<SlotArray[]>>;
  /** retrieve the current state of the curretly being edited stock zone */
  getStockZoneName: () => string;
}

export default function StockZoneSettingsDialog(): JSX.Element {
  const stockZoneId = useMemo(() => {
    return (
      store
        .getState()
        .local.selectedShapesData.find((shape) => shape.type === ShapeTypes.StockZoneShape) as SelectedShapeData
    ).id;
  }, []);
  const stockZone = useAppSelector((state) => state.circuit.present.stockZones.entities[stockZoneId]);

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const confirm = useConfirm();

  const [customGapSlots, setCustomGapSlots] = useState(stockZone.properties.customGapSlots || ([[]] as number[][])); // just to intialize the type
  const [customGapLines, setCustomGapLines] = useState(stockZone.properties.customGapLines || ([] as number[])); // just to intialize the type
  const [locked, setLocked] = useState(!!stockZone?.properties?.locked);

  const [availablePallets] = useState(
    PreferencesService.pallets.length ? PreferencesService.pallets : [getEuroPallet()]
  );
  const [selectedPallets, setSelectedPallets] = useState(stockZone.properties.palletTypes);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleDelete = useCallback((): void => {
    confirm({
      title: 'Stock zone deletion',
      description: `Are you sure you want to delete the stock zone ${stockZone.properties.name}?`,
      allowClose: false,
    })
      .then(() => {
        dispatch(deleteStockZoneAction({ id: stockZoneId, userAction: true }));
        handleClose();
      })
      .then(() => undefined);
  }, [confirm, dispatch, handleClose, stockZone.properties.name, stockZoneId]);

  const [patternType, setPatternType] = useState<PatternTypes>(stockZone.properties.pattern);
  const [selectionType, setSelectionType] = useState<SelectionTypes>(SelectionTypes.uniform);

  const [nbLines, setNbLines] = useState(stockZone.properties.width);
  const [nbSlotsPerLine, setNbSlotsPerLine] = useState(stockZone.properties.length);
  const [nbLinesTmp, setNbLinesTmp] = useState<number | null>(null);
  const [nbSlotsPerLineTmp, setNbSlotsPerLineTmp] = useState<number | null>(null);

  const [enableScan, setEnableScan] = useState(stockZone.properties.enableScan);
  const [fillStrategy, setFillStrategy] = useState(stockZone.properties.fillStrategy);
  const [emptyStrategy, setEmptyStrategy] = useState(stockZone.properties.emptyStrategy);
  const [showScanMenu, setShowScanMenu] = useState(false);
  const [showPalletMenu, setShowPalletMenu] = useState(false);
  const [showSlotDefinition, setShowSlotDefinition] = useState(false);
  const [showDistributionPattern, setShowDistributionPattern] = useState(true);

  const [selection, setSelection] = useState<boolean[][]>(
    Array(nbLines)
      .fill(undefined)
      .map(() => Array(nbSlotsPerLine).fill(true) as boolean[])
  );

  const handleChangeSelectionType = useCallback(
    (e: React.MouseEvent<HTMLElement>, newSelectionType: SelectionTypes | null) => {
      const el = e.target as HTMLElement;
      if (el.dataset.disabled) {
        return;
      }

      if (newSelectionType) {
        setSelectionType(newSelectionType);

        const newValue = newSelectionType === SelectionTypes.uniform;

        setSelection(
          Array(nbLines)
            .fill(undefined)
            .map(() => Array(nbSlotsPerLine).fill(newValue) as boolean[])
        );
      }
    },
    [nbLines, nbSlotsPerLine]
  );

  const [referenceSlotMethodX, setReferenceSlotMethodX] = useState(stockZone.properties.referencePosX);
  const [referenceSlotMethodY, setReferenceSlotMethodY] = useState(stockZone.properties.referencePosY);

  const handleChangeSlotMethodX = useCallback(
    (event: React.MouseEvent<HTMLElement>, newSlotMethod: ReferenceMethodX | null) => {
      if (newSlotMethod) setReferenceSlotMethodX(newSlotMethod);
    },
    []
  );
  const handleChangeSlotMethodY = useCallback(
    (event: React.MouseEvent<HTMLElement>, newSlotMethod: ReferenceMethodY | null) => {
      if (newSlotMethod) setReferenceSlotMethodY(newSlotMethod);
    },
    []
  );

  const [referenceOffsetX, setReferenceOffsetX] = useState(stockZone.properties.referenceOffsetX * 100); // cm
  const [referenceOffsetY, setReferenceOffsetY] = useState(stockZone.properties.referenceOffsetY * 100); // cm

  const handleChangeReferenceOffsetX = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newOffset = parseFloat(event.target.value);
    if (!isNaN(newOffset)) setReferenceOffsetX(newOffset);
  }, []);
  const handleChangeReferenceOffsetY = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newOffset = parseFloat(event.target.value);
    if (!isNaN(newOffset)) setReferenceOffsetY(newOffset);
  }, []);

  const minimumValueOffsetX = 0; // cm / to change or to be entered in a special file?
  const minimumValueOffsetY = 0; // cm / to hange or to be entered in a special file?

  const [nbLinesPerGroup, setNbLinesPerGroup] = useState(stockZone.properties.nbLinesPerGroup || 3);
  const [nbSlotsPerGroup, setNbSlotsPerGroup] = useState(
    stockZone.properties.nbSlotsPerGroup || stockZone.properties.length
  );
  const [nbLinesPerGroupTmp, setNbLinesPerGroupTmp] = useState<number | null>(null);
  const [nbSlotsPerGroupTmp, setNbSlotsPerGroupTmp] = useState<number | null>(null);

  const [slots, setSlots] = useState(stockZone.properties.slots);

  const [spaceBetweenLines, setSpaceBetweenLines] = useState(stockZone.properties.gap.width * 100);
  const [spaceBetweenSlots, setSpaceBetweenSlots] = useState(stockZone.properties.gap.length * 100);
  const [spaceBetweenLinesTmp, setSpaceBetweenLinesTmp] = useState<number | null>(null);
  const [spaceBetweenSlotsTmp, setSpaceBetweenSlotsTmp] = useState<number | null>(null);

  const [spaceBetweenLinePatterns, setSpaceBetweenLinePatterns] = useState(
    stockZone.properties.spaceBetweenLinePatterns || spaceBetweenLines
  );
  const [spaceBetweenSlotPatterns, setSpaceBetweenSlotPatterns] = useState(
    stockZone.properties.spaceBetweenSlotPatterns || spaceBetweenSlots
  );
  const [spaceBetweenLinePatternsTmp, setSpaceBetweenLinePatternsTmp] = useState<number | null>(null);
  const [spaceBetweenSlotPatternsTmp, setSpaceBetweenSlotPatternsTmp] = useState<number | null>(null);

  const [slotLength, setSlotLength] = useState(stockZone.properties.slotSize.length * 1000);
  const [slotWidth, setSlotWidth] = useState(stockZone.properties.slotSize.width * 1000);
  const [cap] = useState(stockZone.properties.cap);

  const [slotLengthTmp, setSlotLengthTmp] = useState(slotLength);
  const [slotWidthTmp, setSlotWidthTmp] = useState(slotWidth);

  const palletSize: Size3D = useMemo(
    () => ({
      length: slotLength / 1000,
      width: slotWidth / 1000,
    }),
    [slotWidth, slotLength]
  );
  const slotSize: Size3D = useMemo(
    () => ({
      length: slotLength / 1000,
      width: slotWidth / 1000,
    }),
    [slotLength, slotWidth]
  );

  const computeSlotPosition = useCallback(() => {
    const slotSample = stockZone.properties.slots[0].slots[0];
    const slotSize: Size3D = {
      length: slotLength / 1000, // mm -> m
      width: slotWidth / 1000, // mm -> m
    };

    const gap: Size3D = { length: spaceBetweenSlots / 100, width: spaceBetweenLines / 100 };
    const palletTypes = selectedPallets;
    const palletPosition: PalletPosition = {
      offsetX: referenceOffsetX / 100, // cm -> m
      offsetY: referenceOffsetY / 100, // cm -> m
      referenceX: referenceSlotMethodX,
      referenceY: referenceSlotMethodY,
    };
    const tolerancePosition = slotSample.tolerancePosition;

    const newSlots = CircuitService.generateStockZoneSlots(
      stockZone.geometry.coordinates[0],
      slotSize,
      gap,
      nbSlotsPerLine,
      nbLines,
      palletTypes,
      palletPosition,
      palletSize,
      tolerancePosition,
      cap,
      slots,
      customGapSlots,
      customGapLines,
      stockZone.properties.name
    );

    setSlots(newSlots);
  }, [
    cap,
    customGapLines,
    customGapSlots,
    nbLines,
    nbSlotsPerLine,
    palletSize,
    referenceOffsetX,
    referenceOffsetY,
    referenceSlotMethodX,
    referenceSlotMethodY,
    selectedPallets,
    slotLength,
    slotWidth,
    slots,
    spaceBetweenLines,
    spaceBetweenSlots,
    stockZone.geometry.coordinates,
    stockZone.properties.name,
    stockZone.properties.slots,
  ]);
  const computeSlotPositionDebounced = useDebouncedCallback(computeSlotPosition, 500);

  const computeRepetitivePatternSlots = useCallback(
    (n: number = nbSlotsPerGroup) => {
      if (patternType !== PatternTypes.repetitivePattern) return;

      const patternGapSlots = customGapSlots.map((slotLine) =>
        slotLine.map((slot, index) => (index !== 0 ? spaceBetweenSlots : 0))
      );

      for (let i = n; i < nbSlotsPerLine; i += n) {
        for (let j = 0; j < nbLines; j++) {
          if (patternGapSlots && patternGapSlots[j] && patternGapSlots[j][i] !== undefined) {
            patternGapSlots[j][i] = spaceBetweenSlotPatterns;
          }
        }
      }

      setCustomGapSlots(patternGapSlots);
    },
    [customGapSlots, nbLines, nbSlotsPerGroup, nbSlotsPerLine, patternType, spaceBetweenSlotPatterns, spaceBetweenSlots]
  );
  const computeRepetitivePatternSlotsDebounced = useDebouncedCallback(computeRepetitivePatternSlots, 100);
  const computeRepetitivePatternLines = useCallback(
    (n: number = nbLinesPerGroup) => {
      if (patternType !== PatternTypes.repetitivePattern) return;

      const patternGapLines = customGapLines.map((slot) => spaceBetweenLines);

      for (let i = n; i < nbLines; i += n) {
        patternGapLines[i] = spaceBetweenLinePatterns;
      }

      patternGapLines[0] = 0;
      setCustomGapLines(patternGapLines);
    },
    [customGapLines, nbLines, nbLinesPerGroup, patternType, spaceBetweenLinePatterns, spaceBetweenLines]
  );
  const computeRepetitivePatternLinesDebounced = useDebouncedCallback(computeRepetitivePatternLines, 50);

  const computeCustomGapSlots = useCallback(() => {
    const customGapTmp = customGapSlots ? [...customGapSlots] : [];
    const matrix = Array(nbLines)
      .fill(undefined)
      .map(() => Array(nbSlotsPerLine).fill(spaceBetweenSlots) as number[]);
    for (let i = 0; i < matrix.length; i++) {
      customGapTmp[i] = customGapSlots[i] ? [...customGapSlots[i]] : [];
      for (let j = 0; j < matrix[i].length; j++) {
        if (customGapTmp[i][j] === undefined) {
          customGapTmp[i][j] = j !== 0 ? matrix[i][j] : 0;
        }
      }

      customGapTmp[i].length = matrix[i].length;
    }

    customGapTmp.length = matrix.length;

    setCustomGapSlots(customGapTmp);

    if (patternType === PatternTypes.repetitivePattern) {
      computeRepetitivePatternSlotsDebounced();
    }

    computeSlotPositionDebounced();
  }, [
    computeRepetitivePatternSlotsDebounced,
    computeSlotPositionDebounced,
    customGapSlots,
    nbLines,
    nbSlotsPerLine,
    patternType,
    spaceBetweenSlots,
  ]);
  const computeCustomGapSlotsDebounced = useDebouncedCallback(computeCustomGapSlots, 100);

  const computeCustomGapLines = useCallback(() => {
    const customGapTmp = customGapLines ? [...customGapLines] : [];
    const matrix = [...(Array(nbLines + 1).fill(spaceBetweenLines) as number[])];
    for (let i = 0; i < matrix.length; i++) {
      customGapTmp[i] = customGapLines[i] !== undefined ? customGapLines[i] : matrix[i];
    }

    customGapTmp.length = matrix.length;

    customGapTmp[0] = 0;
    setCustomGapLines(customGapTmp);

    if (patternType === PatternTypes.repetitivePattern) {
      computeRepetitivePatternLinesDebounced();
    }

    computeSlotPositionDebounced();
  }, [
    computeRepetitivePatternLinesDebounced,
    computeSlotPositionDebounced,
    customGapLines,
    nbLines,
    patternType,
    spaceBetweenLines,
  ]);
  const computeCustomGapLinesDebounced = useDebouncedCallback(computeCustomGapLines, 100);

  const handleChangeNbLines = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newNbLines = parseFloat(event.target.value);
      setNbLinesTmp(newNbLines);

      if (!isNaN(newNbLines) && newNbLines >= 1) {
        setNbLines(newNbLines);
        computeCustomGapSlotsDebounced();

        computeSlotPositionDebounced();
      }
    },
    [computeCustomGapSlotsDebounced, computeSlotPositionDebounced]
  );
  const handleChangeSlotsPerLine = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSlotsPerLine = parseFloat(event.target.value);
      setNbSlotsPerLineTmp(newSlotsPerLine);

      if (!isNaN(newSlotsPerLine) && newSlotsPerLine >= 1) {
        setNbSlotsPerLine(newSlotsPerLine);
        computeCustomGapLinesDebounced();
        computeRepetitivePatternSlotsDebounced();
      }
    },
    [computeCustomGapLinesDebounced, computeRepetitivePatternSlotsDebounced]
  );
  const handleChangeNbLinesPerGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newNbLines = parseFloat(event.target.value);
      setNbLinesPerGroupTmp(newNbLines);

      if (!isNaN(newNbLines) && newNbLines >= 1) {
        setNbLinesPerGroup(newNbLines);
        computeRepetitivePatternLinesDebounced();
      }
    },
    [computeRepetitivePatternLinesDebounced]
  );
  const handleChangeNbSlotsPerGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSlotsPerLine = parseFloat(event.target.value);
      setNbSlotsPerGroupTmp(newSlotsPerLine);

      if (!isNaN(newSlotsPerLine) && newSlotsPerLine >= 1) {
        setNbSlotsPerGroup(newSlotsPerLine);
        computeRepetitivePatternSlotsDebounced();
      }
    },
    [computeRepetitivePatternSlotsDebounced]
  );

  const [linePropagationMethod, setLinePropagationMethod] = useState(PropagationMethod.clearance);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeLinePropagationMethod = useCallback(
    (event: React.MouseEvent<HTMLElement>, newPropagationMethod: PropagationMethod | null) => {
      if (!newPropagationMethod) return;

      setLinePropagationMethod(newPropagationMethod);

      if (newPropagationMethod === PropagationMethod.offset)
        setSpaceBetweenLines(spaceBetweenLines + palletSize.width * 100);
      if (newPropagationMethod === PropagationMethod.clearance)
        setSpaceBetweenLines(spaceBetweenLines - palletSize.width * 100);
    },
    [palletSize.width, spaceBetweenLines]
  );
  const [slotPropagationMethod, setSlotPropagationMethod] = useState(PropagationMethod.clearance);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeSlotPropagationMethod = useCallback(
    (event: React.MouseEvent<HTMLElement>, newPropagationMethod: PropagationMethod | null) => {
      if (!newPropagationMethod) return;

      setSlotPropagationMethod(newPropagationMethod);

      if (newPropagationMethod === PropagationMethod.offset)
        setSpaceBetweenSlots(spaceBetweenSlots + palletSize.length * 100);
      if (newPropagationMethod === PropagationMethod.clearance)
        setSpaceBetweenSlots(spaceBetweenSlots - palletSize.length * 100);
    },
    [palletSize.length, spaceBetweenSlots]
  );

  const displayedValueSpaceBetweenLines = useMemo(() => {
    if (linePropagationMethod === PropagationMethod.clearance) return spaceBetweenLines;

    return spaceBetweenLines - palletSize.width * 100;
  }, [linePropagationMethod, palletSize.width, spaceBetweenLines]);
  const displayedValueSpaceBetweenSlots = useMemo(() => {
    if (slotPropagationMethod === PropagationMethod.clearance) return spaceBetweenSlots;

    return spaceBetweenSlots - palletSize.length * 100;
  }, [palletSize.length, slotPropagationMethod, spaceBetweenSlots]);

  const minimumValueSpaceBetweenLines = 25; // cm / to change or to be entered in a special file?
  const minimumValueSpaceBetweenSlots = 50; // cm / to change or to be entered in a special file?
  const minimumValueSpaceEitherLinesOrSlots = 50; // cm

  const handleChangeSpaceBetweenLines = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSpace = parseFloat(event.target.value);
      setSpaceBetweenLinesTmp(newSpace);

      if (!isNaN(newSpace)) {
        setSpaceBetweenLines(newSpace);
        if (patternType === PatternTypes.uniformDistribution) setCustomGapLines([]);

        computeCustomGapLinesDebounced();
      }
    },
    [computeCustomGapLinesDebounced, patternType]
  );
  const handleChangeSpaceBetweenSlots = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSpace = parseFloat(event.target.value);
      setSpaceBetweenSlotsTmp(newSpace);

      if (!isNaN(newSpace)) {
        setSpaceBetweenSlots(newSpace);
        if (patternType === PatternTypes.uniformDistribution) setCustomGapSlots([[]]);

        computeCustomGapSlotsDebounced();
      }
    },
    [computeCustomGapSlotsDebounced, patternType]
  );
  const handleChangeSpaceBetweenLinePatterns = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSpace = parseFloat(event.target.value);
      setSpaceBetweenLinePatternsTmp(newSpace);

      if (!isNaN(newSpace)) {
        setSpaceBetweenLinePatterns(newSpace);
        computeRepetitivePatternLinesDebounced();

        computeSlotPositionDebounced();
      }
    },
    [computeRepetitivePatternLinesDebounced, computeSlotPositionDebounced]
  );
  const handleChangeSpaceBetweenSlotPatterns = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSpace = parseFloat(event.target.value);
      setSpaceBetweenSlotPatternsTmp(newSpace);

      if (!isNaN(newSpace)) {
        setSpaceBetweenSlotPatterns(newSpace);
        computeRepetitivePatternSlotsDebounced();

        computeSlotPositionDebounced();
      }
    },
    [computeRepetitivePatternSlotsDebounced, computeSlotPositionDebounced]
  );

  const [name, setName] = useState(stockZone.properties.name);

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');

  /**
   * this function recomputes the attributes of the stock zone and dispatch a save action
   * it is debounced to avoid too many actions while changing the values of the inputs
   */
  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!stockZone) return;

    let stockZoneName = name;
    if (
      !stockZoneName ||
      !areAllShapeNamesUnique([stockZoneName], [stockZoneId], {
        ignoreDuplicatesBefore: true,
      })
    ) {
      stockZoneName = suggestedName;
    }

    updateStockZone(stockZone, {
      name: name ?? undefined,
      cap: cap ?? undefined,
      extendedLength: undefined,
      stockZoneMargin: undefined,
    });
  }, 500);

  const assignNameToStockLines = useCallback(
    (
      assignTo: 'selection' | 'all',
      rules: BlockDataSlotLineName[] | null,
      selectedLoadOnly = true,
      options = {
        onlyNotUserDefinedNames: false,
        nameToUse: '',
      }
    ): void => {
      let nbNoUniqueNames = 0;
      let nbTooLongNames = 0;
      const newGeneratedNames: string[] = [];

      const { onlyNotUserDefinedNames, nameToUse } = options;

      const newSlots = slots.map((stockLine, stockLineIndex) => {
        if (onlyNotUserDefinedNames && stockLine.userEditedName) return stockLine;

        let isUnique = false;
        let i = 0;
        let newName: string;
        let newNameOriginal = 'error';
        // we generate the name, then we check if the name is already used, if we
        // convert the name to theName_0 and check again, if it's still not unique we go for theName_1, theName_2 and so on
        do {
          let rulesToUse: typeof rules | undefined;
          if (!rules && nameToUse) {
            rulesToUse = [
              {
                type: 'char',
                value: `${nameToUse}-${stockLineIndex}${i !== 0 ? `_${i}` : ''}`,
              },
            ];
          }

          const expectedRules = rules || rulesToUse;
          newName =
            i === 0
              ? expectedRules
                ? convertSlotLineToName(expectedRules, {
                    stockZoneName: nameToUse || (name ?? stockZone.properties.name),
                    slots,
                    slotIndex: stockLineIndex,
                  })
                : stockZone.properties.slots[stockLineIndex].name
              : `${newNameOriginal}_${i}`;

          if (rules) {
            isUnique =
              areAllShapeNamesUnique([newName], undefined, { ignoreDuplicatesBefore: true }) &&
              !newGeneratedNames.includes(newName);
          } else if (newName === stockLine.name) {
            isUnique = true;
          } else {
            const otherNamesToConsider = slots.map((stockLine) => stockLine.name);
            isUnique =
              areAllShapeNamesUnique([newName], undefined, { ignoreDuplicatesBefore: true }, otherNamesToConsider) &&
              !newGeneratedNames.includes(newName);
          }

          stockLine.userEditedName = !!rules;

          if (!isUnique && i++ === 0) {
            newNameOriginal = newName;
            nbNoUniqueNames++;
          }
        } while (!isUnique && i < nbMaxIter);

        if (!isUnique) {
          // eslint-disable-next-line no-console
          console.error(`Could not find a unique name for ${newName} after ${nbMaxIter} iterations`);
        }

        const isValid = isValidSlotLineName(newName);

        if (!isValid) {
          nbTooLongNames++;

          return stockLine;
        }

        newGeneratedNames.push(newName);

        return { ...stockLine, name: newName };
      });

      startTransition(() => {
        setSlots(newSlots);
      });

      if (nbNoUniqueNames) {
        // eslint-disable-next-line no-console
        console.warn(
          `There were ${nbNoUniqueNames} names that were not unique, they were renamed to theName_0, theName_1, etc.`
        );

        SnackbarUtils.warning(`${nbNoUniqueNames} names were not unique and were modified`);
      }

      if (nbTooLongNames) {
        // eslint-disable-next-line no-console
        console.warn(
          `There were ${nbTooLongNames} names that were too long, the new rule has not been applied to them`
        );

        SnackbarUtils.warning(`${nbTooLongNames} names were too long and the new rule has not been applied to them`);
      }
    },

    [slots, name, stockZone]
  );

  const updateProperties = useCallback(() => {
    // and we recompute the stock zone
    updatePropertiesDebounced();
  }, [updatePropertiesDebounced]);

  const handleChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      if (newName.length < 100) {
        setName(newName);
      }

      const otherNamesToConsider = slots.map((stockLine) => stockLine.name);

      // shape names must be unique, nothings check that we don't fill the store with two shapes with the same name
      startTransition(() => {
        const isNameAlreadyUsed = !areAllShapeNamesUnique(
          [newName],
          [stockZoneId],
          {
            ignoreDuplicatesBefore: true,
            checkStockLinesStockZonesIgnored: false,
          },
          otherNamesToConsider
        );

        if (!newName || isNameAlreadyUsed) {
          setErrorName(true);
          setSuggestedName(CircuitService.generateDifferentName(newName, { shapeIdsToIgnore: [stockZoneId] }));
        } else {
          setErrorName(false);
        }

        updatePropertiesDebounced();
      });
    },
    [slots, stockZoneId, updatePropertiesDebounced]
  );

  /** we compute a name suggestion if the name is already used */
  const setSuggestedNameHandler = useCallback(() => {
    setName(suggestedName);
    setErrorName(false);

    updateProperties();
  }, [suggestedName, updateProperties]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorName(false), [stockZoneId]);

  const updateStockLineNames = useCallback(() => {
    assignNameToStockLines('all', null, undefined, {
      onlyNotUserDefinedNames: true,
      nameToUse: errorName ? suggestedName : name,
    });
  }, [assignNameToStockLines, errorName, name, suggestedName]);

  const [extendedLength] = useState(stockZone.properties.extendedLength * 100);

  const handleChangeSlotLength = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSlotLength = parseFloat(event.target.value) / 1000 || getEuroPallet().palletLength;

      if (!isNaN(newSlotLength) && newSlotLength >= 0) {
        const slotsCopy = cloneDeep(slots);

        for (let y = 0; y < selection.length; y++) {
          for (let x = 0; x < selection[y].length; x++) {
            if (selection[y][x]) {
              // if the slot is selected
              slotsCopy[y].slots[x].slotSize.length = newSlotLength;
            }
          }
        }

        setSlots(slotsCopy);

        if (selectionType === SelectionTypes.uniform) {
          setSlotLength(newSlotLength * 1000);
        }

        computeSlotPositionDebounced();
        setSlotLengthTmp(0);
      }
    },
    [computeSlotPositionDebounced, selection, selectionType, slots]
  );
  const handleChangeSlotLengthTmp = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newSlotLength = parseFloat(event.target.value) || getEuroPallet().palletLength;

    setSlotLengthTmp(newSlotLength);
  }, []);
  const handleEnterSlotLengthTmp = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (!isNaN(slotLengthTmp) && slotLengthTmp >= 0) {
          const slotsCopy = cloneDeep(slots);

          for (let y = 0; y < selection.length; y++) {
            for (let x = 0; x < selection[y].length; x++) {
              if (selection[y][x]) {
                // if the slot is selected
                slotsCopy[y].slots[x].slotSize.length = slotLengthTmp / 1000;
              }
            }
          }

          setSlots(slotsCopy);

          if (selectionType === SelectionTypes.uniform) {
            setSlotLength(slotLengthTmp * 1000);
          }

          computeSlotPositionDebounced();
          setSlotLengthTmp(0);
        }
      }
    },
    [computeSlotPositionDebounced, selection, selectionType, slotLengthTmp, slots]
  );
  const handleChangeSlotWidth = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSlotWidth = parseFloat(event.target.value) / 1000 || getEuroPallet().palletWidth;
      if (!isNaN(newSlotWidth) && newSlotWidth >= 0) {
        const slotsCopy = cloneDeep(slots);

        for (let y = 0; y < selection.length; y++) {
          for (let x = 0; x < selection[y].length; x++) {
            if (selection[y][x]) {
              // if the slot is selected
              slotsCopy[y].slots[x].slotSize.width = newSlotWidth;
            }
          }

          setSlots(slotsCopy);

          if (selectionType === SelectionTypes.uniform) {
            setSlotWidth(newSlotWidth * 1000);
          }

          computeSlotPositionDebounced();
          setSlotWidthTmp(0);
        }
      }
    },
    [computeSlotPositionDebounced, selection, selectionType, slots]
  );
  const handleChangeSlotWidthTmp = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newSlotWidth = parseFloat(event.target.value) || getEuroPallet().palletWidth;

    setSlotWidthTmp(newSlotWidth);
  }, []);
  const handleEnterSlotWidthTmp = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (!isNaN(slotWidthTmp) && slotWidthTmp >= 0) {
          const slotsCopy = cloneDeep(slots);

          for (let y = 0; y < selection.length; y++) {
            for (let x = 0; x < selection[y].length; x++) {
              if (selection[y][x]) {
                // if the slot is selected
                slotsCopy[y].slots[x].slotSize.width = slotWidthTmp / 1000;
              }
            }
          }

          setSlots(slotsCopy);

          if (selectionType === SelectionTypes.uniform) {
            setSlotWidth(slotWidthTmp * 1000);
          }

          computeSlotPositionDebounced();
          setSlotLengthTmp(0);
        }
      }
    },
    [computeSlotPositionDebounced, selection, selectionType, slotWidthTmp, slots]
  );

  const [displayPallets, setDisplayPallets] = useState(!!stockZone.properties.displayPallets);
  const handleDisplayPallets = useCallback(() => {
    setDisplayPallets(!displayPallets);
  }, [displayPallets]);

  const [palletToDisplay, setPalletToDisplay] = useState(stockZone.properties.palletToDisplay || '');
  const handleChangePalletToDisplay = useCallback((event: SelectChangeEvent<string>) => {
    setPalletToDisplay(event.target.value);
  }, []);

  const handleChangeCustomGapSlots = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (locked) return;
      if (patternType === PatternTypes.uniformDistribution) return;

      const el = e.target as HTMLInputElement;
      const x = parseInt(el.dataset.x as string, 10);
      const y = parseInt(el.dataset.y as string, 10);

      let customGapTmp = [...customGapSlots];
      customGapTmp[y] = [...customGapSlots[y]];
      customGapTmp[y][x] = parseFloat(el.value);
      if (isNaN(customGapTmp[y][x])) {
        // eslint-disable-next-line no-console
        console.warn(`customGapTmp[${y}][${x}] is NaN, value set to ${minimumValueSpaceEitherLinesOrSlots} cm`);
        customGapTmp[y][x] = minimumValueSpaceEitherLinesOrSlots;
      }

      if (patternType === PatternTypes.relatedDistribution) {
        const value = customGapTmp[y][x];
        customGapTmp = customGapTmp.map((customGapLine) => {
          customGapLine[x] = value;

          return customGapLine;
        });
      }

      if (!isNaN(customGapTmp[y][x])) {
        setCustomGapSlots(customGapTmp);
      }
    },
    [customGapSlots, locked, patternType]
  );

  const handleChangeCustomGapLines = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (locked) return;
      if (patternType === PatternTypes.uniformDistribution) return;

      const el = e.target as HTMLInputElement;
      // const x = parseInt(el.dataset.x as string, 10);
      const y = parseInt(el.dataset.y as string, 10);

      const customGapTmp = [...customGapLines];
      customGapTmp[y] = parseFloat(el.value);
      if (isNaN(customGapTmp[y])) {
        // eslint-disable-next-line no-console
        console.warn(`customGapTmp[${y}] is NaN, value set to ${minimumValueSpaceEitherLinesOrSlots} cm`);
        customGapTmp[y] = minimumValueSpaceEitherLinesOrSlots;
      }

      if (!isNaN(customGapTmp[y])) {
        setCustomGapLines(customGapTmp);
      }
    },
    [customGapLines, locked, patternType]
  );

  const handleShowScanMenu = useCallback(() => {
    setShowScanMenu(!showScanMenu);
    setShowDistributionPattern(false);
    setShowSlotDefinition(false);
  }, [showScanMenu]);
  const handleEnableScan = useCallback(() => {
    setEnableScan(!enableScan);
  }, [enableScan]);
  const handleChangeFillStrategy = useCallback((e: SelectChangeEvent<number>) => {
    setFillStrategy(e.target.value as fillOrEmptyStrategy);
  }, []);
  const handleChangeEmptyStrategy = useCallback((e: SelectChangeEvent<number>) => {
    setEmptyStrategy(e.target.value as fillOrEmptyStrategy);
  }, []);

  const applyChange = useCallback(() => {
    if (!name || !name.length) {
      setName(stockZone.properties.name);
      setTimeout(applyChange, 100);

      return;
    }

    const slotSample = stockZone.properties.slots[0].slots[0];
    const slotSize: Size3D = {
      length: slotLength / 1000, // mm -> m
      width: slotWidth / 1000, // mm -> m
    };

    const length = nbSlotsPerLine;
    const width = nbLines;

    const palletTypes = selectedPallets;
    const palletPosition: PalletPosition = {
      offsetX: referenceOffsetX / 100, // cm -> m
      offsetY: referenceOffsetY / 100, // cm -> m
      referenceX: referenceSlotMethodX,
      referenceY: referenceSlotMethodY,
    };

    const tolerancePosition = slotSample.tolerancePosition;
    const gap: Size3D = { length: spaceBetweenSlots / 100, width: spaceBetweenLines / 100 };
    let newSlots = CircuitService.generateStockZoneSlots(
      stockZone.geometry.coordinates[0],
      slotSize,
      gap,
      length,
      width,
      palletTypes,
      palletPosition,
      palletSize,
      tolerancePosition,
      cap,
      slots,
      customGapSlots,
      customGapLines,
      name,
      {
        lineNameOnlyIfNotUserGenerated: true,
      }
    );

    let minX = 1 / 0;
    let maxX = -1 / 0;
    let minY = 1 / 0;
    let maxY = -1 / 0;
    for (let i = 0, slotLinesLastIndex = newSlots[0].slots.length - 1; i < newSlots.length; i++) {
      const firstSlotInLine = newSlots[i].slots[0];
      if (firstSlotInLine.slotPosition.x < minX) {
        minX = firstSlotInLine.slotPosition.x;
      }

      const lastSlotInLine = newSlots[i].slots[slotLinesLastIndex];
      const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + lastSlotInLine.slotSize.length * 100;
      if (maxXLastSlotInLine > maxX) {
        maxX = maxXLastSlotInLine;
      }
    }

    minY = -newSlots[0].slots[0].slotPosition.y;
    maxY =
      -newSlots[slots.length - 1].slots[0].slotPosition.y - newSlots[slots.length - 1].slots[0].slotSize.width * 100;

    const zonePosX = minX;
    const zonePosY = minY;

    const zoneWidth = Math.abs(maxY - minY) / 100;
    const zoneLength = Math.abs(maxX - minX) / 100;

    const theta = toRad(cap);
    const coords = [
      [
        [zonePosX, zonePosY],
        [zonePosX + zoneLength * 100 * Math.cos(theta), zonePosY - zoneLength * 100 * Math.sin(theta)],
        [
          zonePosX + zoneLength * 100 * Math.cos(theta) - zoneWidth * 100 * Math.sin(theta),
          zonePosY - zoneLength * 100 * Math.sin(theta) - zoneWidth * 100 * Math.cos(theta),
        ],
        [zonePosX - Math.sin(theta) * zoneWidth * 100, zonePosY - Math.cos(theta) * zoneWidth * 100],
        [zonePosX, zonePosY],
      ],
    ];

    newSlots = CircuitService.computeSlotsGeometry(newSlots, cap);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const disabledSlotsEls = Array.from(
      document.querySelectorAll('[data-slot-stock-zone][data-disabled=true]')
    ) as HTMLElement[];
    disabledSlotsEls.forEach((disabledSlotEl) => {
      const x = parseInt(disabledSlotEl.dataset.x || 'NaN', 10);
      const y = parseInt(disabledSlotEl.dataset.y || 'NaN', 10);

      if (!isNaN(x) && !isNaN(y)) {
        if (newSlots && newSlots[y] && newSlots[y].slots[x]) {
          newSlots[y].slots[x].disabled = true;
        } else {
          // eslint-disable-next-line no-console
          console.error(`slots[${y}].slots[${x}] does not exist`, 'slots:', { ...newSlots });
        }
      } else {
        throw new Error(`NaN generated, x: ${x}, y: ${y}, slots: ${{ ...newSlots }}`);
      }
    });

    const properties: StockZoneProperties = {
      ...stockZone.properties,
      name,
      cap,
      length,
      width,
      slots: newSlots,
      slotSize,
      palletSize,
      pattern: patternType,
      extendedLength: extendedLength / 100,
      gap: {
        length: spaceBetweenSlots / 100,
        width: spaceBetweenLines / 100,
      },
      displayPallets,
      palletToDisplay,
      referencePosX: referenceSlotMethodX,
      referenceOffsetX,
      referencePosY: referenceSlotMethodY,
      referenceOffsetY,

      customGapSlots,
      customGapLines,

      nbLinesPerGroup,
      nbSlotsPerGroup,
      spaceBetweenLinePatterns,
      spaceBetweenSlotPatterns,

      enableScan,
      emptyStrategy,
      fillStrategy,

      locked,
    };

    if (patternType === PatternTypes.uniformDistribution) {
      delete properties.customGapLines;
      delete properties.customGapSlots;
    }

    if (patternType !== PatternTypes.repetitivePattern) {
      delete properties.nbLinesPerGroup;
      delete properties.nbSlotsPerGroup;
      delete properties.spaceBetweenLinePatterns;
      delete properties.spaceBetweenSlotPatterns;
    }

    dispatch(
      saveStockZoneAction({
        id: stockZoneId,
        geometry: {
          ...stockZone.geometry,
          coordinates: coords,
        },
        properties,
      })
    );

    handleClose();
  }, [
    cap,
    customGapLines,
    customGapSlots,
    dispatch,
    displayPallets,
    emptyStrategy,
    enableScan,
    extendedLength,
    fillStrategy,
    handleClose,
    locked,
    name,
    nbLines,
    nbLinesPerGroup,
    nbSlotsPerGroup,
    nbSlotsPerLine,
    palletSize,
    palletToDisplay,
    patternType,
    referenceOffsetX,
    referenceOffsetY,
    referenceSlotMethodX,
    referenceSlotMethodY,
    selectedPallets,
    slotLength,
    slotWidth,
    slots,
    spaceBetweenLinePatterns,
    spaceBetweenLines,
    spaceBetweenSlotPatterns,
    spaceBetweenSlots,
    stockZone.geometry,
    stockZone.properties,
    stockZoneId,
  ]);

  const [springStyleCopyButton, animateStyleCopyButton] = useSpring(
    {
      from: { rotateZ: 0 },
    },
    []
  );

  const handleChangePatternType = useCallback(
    async (e: React.MouseEvent<HTMLElement>, newPatternType: PatternTypes) => {
      const el = e.target as HTMLElement;
      if (el.dataset.disabled) {
        return;
      }

      if (newPatternType === PatternTypes.uniformDistribution) {
        try {
          await confirm({
            description:
              'Switching to the uniform distribution will lose the values entered, are you sure to continue?',
            allowClose: false,
          });
        } catch (e) {
          return;
        }
      }

      if (newPatternType) setPatternType(newPatternType);

      if (newPatternType === PatternTypes.uniformDistribution) {
        setCustomGapSlots([[]]);
        setCustomGapLines([]);
      } else if (newPatternType === PatternTypes.relatedDistribution) {
        const newCustomGapSlots = customGapSlots.map((slotLine) =>
          slotLine.map((slot, i) => (i !== 0 ? customGapSlots[0][i] : 0))
        );
        setCustomGapSlots(newCustomGapSlots);
      } else if (newPatternType === PatternTypes.repetitivePattern) {
        computeRepetitivePatternSlotsDebounced();
      } else if (newPatternType === PatternTypes.customDistribution) {
        computeCustomGapSlotsDebounced();
        computeCustomGapLinesDebounced();
      }

      computeSlotPositionDebounced();
    },
    [
      computeCustomGapLinesDebounced,
      computeCustomGapSlotsDebounced,
      computeRepetitivePatternSlotsDebounced,
      computeSlotPositionDebounced,
      confirm,
      customGapSlots,
    ]
  );

  const onCopyHandler = useCallback(
    (event) => {
      dispatch(copySelectionAction());

      animateStyleCopyButton({
        to: { rotateZ: 360 },
        reset: true,
      });
    },
    [animateStyleCopyButton, dispatch]
  );

  const [springStyleLockButton, animateStyleLockButton] = useSpring(
    {
      from: { rotateY: 0 },
      config: { duration: 150 },
    },
    []
  );
  const onLockHandler = useCallback(() => {
    const newLockedValue = !locked;
    setLocked(newLockedValue);

    animateStyleLockButton.start({
      to: { rotateY: 90 },
      onRest: () => {
        animateStyleLockButton.start({
          to: { rotateY: 0 },
        });
      },
    });

    dispatch(
      changeLockStateAction({
        idShape: stockZoneId,
        newLockState: newLockedValue,
        shapeType: ShapeTypes.StockZoneShape,
        userAction: true,
      })
    );
  }, [animateStyleLockButton, dispatch, locked, stockZoneId]);

  const [showPalletPosition, setShowPalletPosition] = useState(false);
  const handleClickShowPalletPosition = useCallback(() => {
    setShowPalletPosition(!showPalletPosition);
  }, [showPalletPosition]);

  const handleClickShowPalletMenu = useCallback(() => {
    setShowPalletMenu(!showPalletMenu);
    setShowDistributionPattern(false);
    setShowSlotDefinition(false);
  }, [showPalletMenu]);
  const handleClickSlotDefinition = useCallback(() => {
    setShowSlotDefinition(!showSlotDefinition);
    setShowScanMenu(false);
    setShowDistributionPattern(false);
  }, [showSlotDefinition]);
  const handleClickDistributionPattern = useCallback(() => {
    setShowDistributionPattern(!showDistributionPattern);
    setShowScanMenu(false);
    setShowSlotDefinition(false);
  }, [showDistributionPattern]);

  const handleChangeSelectPallets = useCallback(
    (event: SelectChangeEvent<string[]>): void => {
      const newSelectedPallets = event.target.value as string[];
      setSelectedPallets(newSelectedPallets);

      if (!newSelectedPallets.includes(palletToDisplay)) {
        setPalletToDisplay('');
      }
    },
    [palletToDisplay]
  );

  /*
  useEffect(() => {
    if (
      patternType === PatternTypes.repetitivePattern &&
      !isNaN(nbLines) &&
      !isNaN(nbLinesPerGroup) &&
      !isNaN(spaceBetweenLines) &&
      !isNaN(spaceBetweenLinePatterns)
    ) {
      computeRepetitivePatternLines();
    }
  }, [
    computeRepetitivePatternLines,
    nbLines,
    nbLinesPerGroup,
    patternType,
    spaceBetweenLinePatterns,
    spaceBetweenLines,
  ]);
  useEffect(() => {
    if (
      patternType === PatternTypes.repetitivePattern &&
      !isNaN(nbSlotsPerLine) &&
      !isNaN(nbSlotsPerGroup) &&
      !isNaN(spaceBetweenSlots) &&
      !isNaN(spaceBetweenSlotPatterns)
    ) {
      computeRepetitivePatternSlots();
    }
  }, [
    computeRepetitivePatternSlots,
    nbSlotsPerGroup,
    nbSlotsPerLine,
    patternType,
    spaceBetweenSlotPatterns,
    spaceBetweenSlots,
  ]);
  */

  const handleClickPallet = useCallback(
    (e: React.MouseEvent, x: number, y: number) => {
      let newSelection = cloneDeep(selection);

      if (newSelection && newSelection[y] && newSelection[y][x] !== undefined) {
        const previousState = newSelection[y][x];
        const ctrklKey = e.ctrlKey;

        if (selectionType === SelectionTypes.uniform) {
          newSelection = newSelection.map((line) => line.map((value) => true));
        } else if (selectionType === SelectionTypes.line) {
          if (!ctrklKey) newSelection = newSelection.map((line) => line.map((value) => false));

          newSelection[y] = newSelection[y].map((slot) => !previousState);
        } else if (selectionType === SelectionTypes.column) {
          if (!ctrklKey) newSelection = newSelection.map((line) => line.map((value) => false));

          newSelection = newSelection.map((line) => {
            line[x] = !previousState;

            return line;
          });
        } else if (selectionType === SelectionTypes.custom) {
          if (!ctrklKey) newSelection = newSelection.map((line) => line.map((value) => false));

          newSelection[y][x] = !previousState;
        }

        setSelection(newSelection);
      }
    },
    [selection, selectionType]
  );

  const handleClickDisableState = useCallback(
    (toDisable: boolean) => {
      const slotsCopy = cloneDeep(slots);

      for (let y = 0; y < selection.length; y++) {
        for (let x = 0; x < selection[y].length; x++) {
          if (selection[y][x]) {
            // if the slot is selected
            if (toDisable) slotsCopy[y].slots[x].disabled = toDisable;
            else delete slotsCopy[y].slots[x].disabled;
          }
        }
      }

      setSlots(slotsCopy);
    },
    [selection, slots]
  );

  const slotLengthSelection = useMemo(() => {
    let value: number | undefined | null = undefined;
    for (let y = 0; y < selection.length; y++) {
      for (let x = 0; x < selection[y].length; x++) {
        if (selection[y][x] && slots[y]?.slots[x]) {
          if (value === undefined) value = slots[y].slots[x].slotSize.length;
          else if (Math.abs(value - slots[y].slots[x].slotSize.length) < epsilon) continue;
          else return null;
        }
      }
    }

    return value ? value * 1000 : undefined; // m -> mm
  }, [selection, slots]);
  const slotWidthSelection = useMemo(() => {
    let value: number | undefined | null = undefined;
    for (let y = 0; y < selection.length; y++) {
      for (let x = 0; x < selection[y].length; x++) {
        if (selection[y][x] && slots[y]?.slots[x]) {
          if (value === undefined) value = slots[y].slots[x].slotSize.width;
          else if (Math.abs(value - slots[y].slots[x].slotSize.width) < epsilon) continue;
          else return null;
        }
      }
    }

    return value ? value * 1000 : undefined; // m -> mm
  }, [selection, slots]);

  useEffect(() => {
    if (nbLines !== slots.length || nbSlotsPerLine !== slots[0].slots.length) {
      const slotSample = stockZone.properties.slots[0].slots[0];
      const gap: Size3D = { length: spaceBetweenSlots / 100, width: spaceBetweenLines / 100 };
      const palletTypes = selectedPallets;
      const palletPosition: PalletPosition = {
        offsetX: referenceOffsetX / 100, // cm -> m
        offsetY: referenceOffsetY / 100, // cm -> m
        referenceX: referenceSlotMethodX,
        referenceY: referenceSlotMethodY,
      };
      const tolerancePosition = slotSample.tolerancePosition;

      const newSlots = CircuitService.generateStockZoneSlots(
        stockZone.geometry.coordinates[0],
        slotSize,
        gap,
        nbSlotsPerLine,
        nbLines,
        palletTypes,
        palletPosition,
        palletSize,
        tolerancePosition,
        cap,
        slots,
        customGapSlots,
        customGapLines,
        stockZone.properties.name
      );

      setSlots(newSlots);
    }

    if (selectionType === SelectionTypes.uniform) {
      const newSelection = Array(nbLines)
        .fill(undefined)
        .map(() => Array(nbSlotsPerLine).fill(true) as boolean[]);

      setSelection(newSelection);
    }
  }, [
    cap,
    customGapLines,
    customGapSlots,
    nbLines,
    nbSlotsPerLine,
    palletSize,
    referenceOffsetX,
    referenceOffsetY,
    referenceSlotMethodX,
    referenceSlotMethodY,
    selectedPallets,
    selectionType,
    slotLength,
    slotSize,
    slotWidth,
    slots,
    spaceBetweenLines,
    spaceBetweenSlots,
    stockZone.geometry.coordinates,
    stockZone.properties.gap,
    stockZone.properties.name,
    stockZone.properties.palletSize,
    stockZone.properties.palletTypes,
    stockZone.properties.slotSize,
    stockZone.properties.slots,
  ]);

  /*
  let minX = 1 / 0;
    let maxX = -1 / 0;
    let minY = 1 / 0;
    let maxY = -1 / 0;
    for (let i = 0, slotLinesLastIndex = newSlots[0].slots.length - 1; i < newSlots.length; i++) {
      const firstSlotInLine = newSlots[i].slots[0];
      if (firstSlotInLine.slotPosition.x < minX) {
        minX = firstSlotInLine.slotPosition.x;
      }

      const lastSlotInLine = newSlots[i].slots[slotLinesLastIndex];
      const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + slotSize.length * 100;
      if (maxXLastSlotInLine > maxX) {
        maxX = maxXLastSlotInLine;
      }
    }

    minY = -newSlots[0].slots[0].slotPosition.y;
    maxY = -newSlots[slots.length - 1].slots[0].slotPosition.y - slotSize.width * 100;
  */
  const [minX, setMinX] = useState(1 / 0);
  const [maxX, setMaxX] = useState(-1 / 0);
  const [minY, setMinY] = useState(1 / 0);
  const [maxY, setMaxY] = useState(-1 / 0);
  useEffect(() => {
    let minXtmp = 1 / 0;
    let maxXtmp = -1 / 0;
    let minYtmp = 1 / 0;
    let maxYtmp = -1 / 0;

    for (let i = 0, slotLinesLastIndex = slots[0].slots.length - 1; i < slots.length; i++) {
      const firstSlotInLine = slots[i].slots[0];
      if (firstSlotInLine.slotPosition.x < minXtmp) {
        minXtmp = firstSlotInLine.slotPosition.x;
      }

      const lastSlotInLine = slots[i].slots[slotLinesLastIndex];
      const maxXLastSlotInLine = lastSlotInLine.slotPosition.x + lastSlotInLine.slotSize.length * 100;
      if (maxXLastSlotInLine > maxXtmp) {
        maxXtmp = maxXLastSlotInLine;
      }
    }

    minYtmp = -slots[0].slots[0].slotPosition.y;
    maxYtmp = -slots[slots.length - 1].slots[0].slotPosition.y - slots[slots.length - 1].slots[0].slotSize.width * 100;

    setMinX(minXtmp - WIDTH_FIRST_TEXTFIELD); // space for the text field
    setMaxX(maxXtmp);
    setMinY(-minYtmp);
    setMaxY(-maxYtmp);
  }, [slots]);

  const slotsBadge = useMemo(() => {
    const maxNb = gradientB2R.length;
    const refNb = hashStr2Nb(
      `${stockZone.properties.palletTypes.toString()}${JSON.stringify(palletSize)}${JSON.stringify(slotSize)}`
    );

    const badges: (string | undefined)[][] = slots.map((slotLine) => {
      return slotLine.slots
        .map((slot) => {
          return hashStr2Nb(
            `${slot.palletTypes ? slot.palletTypes.toString() : ''}${JSON.stringify(slot.palletSize)}${JSON.stringify(
              slot.slotSize
            )}`
          );
        })

        .map((nb) => (nb === refNb ? undefined : nb))
        .map((nb) => (nb ? gradientB2R[nb % maxNb] : undefined));
    });

    return badges;
  }, [palletSize, slotSize, slots, stockZone.properties.palletTypes]);

  const [showLineStockNames, setShowLineStockNames] = useState(true);
  const handleClickShowLineStockNames = useCallback(() => {
    setShowLineStockNames(!showLineStockNames);
  }, [showLineStockNames]);

  const standardMode = useAppSelector((state) => state.local.standardMode);

  const nbSlots = useMemo(() => {
    return computeNbLoadsInStockZone(slots);
  }, [slots]);

  const [forceDisplayPreview, setForceDisplayPreview] = useState(false);

  const [openMenuRenameStockline, setOpenMenuRenameStockline] = useState(false);
  const handleClickRenameStocklines = useCallback(() => {
    setOpenMenuRenameStockline(true);
  }, []);
  const handleCloseMenuRenameStockline = useCallback(() => {
    setOpenMenuRenameStockline(false);
  }, []);

  const renameStocklineIconRef = useRef<null | HTMLElement>(null);

  const actions: ActionsStockZone = useMemo(() => {
    const getStockZoneName = (): string => name;

    return { assignNameToStockLines, setSlots, getStockZoneName };
  }, [assignNameToStockLines, name]);

  const handleClickResetZoom = useCallback(() => {
    if (!zoomStockZoneEdition) return;

    zoomToStockZone();
  }, []);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="xl" onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <FormControl>
          <TextField
            type="text"
            value={name}
            onChange={handleChangeName}
            onBlur={updateStockLineNames}
            disabled={locked}
            variant="outlined"
            //label="Name"
            size="small"
            style={{ width: `${name.length + 3}ch` }}
            title="Name of the stock zone"
          />
          <FormHelperText style={{ margin: 0 }} className={classes.helperText} error id="suggested-name-rack">
            {errorName ? (
              <>
                Name already used, suggested name:{' '}
                <span
                  onClick={() => {
                    setSuggestedNameHandler();
                    updateStockLineNames();
                  }}
                  className={classes.suggestedName}
                >
                  {suggestedName}
                </span>
              </>
            ) : (
              <></>
            )}
          </FormHelperText>
        </FormControl>

        <Stack className={classes.floatRight} direction="row" alignItems="center" justifyContent="center" spacing={1}>
          <IconButton onClick={handleDelete} title="Delete the stock zone" size="large">
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={onLockHandler}
            title={locked ? 'Unlock' : 'Lock'}
            size="large"
            sx={{ padding: '0px 12px' }}
          >
            {locked ? (
              <animated.span style={springStyleLockButton} className={classes.iconNormalHeight}>
                <LockIcon />
              </animated.span>
            ) : (
              <animated.span style={springStyleLockButton} className={classes.iconNormalHeight}>
                <LockOpenIcon />
              </animated.span>
            )}
          </IconButton>
          <IconButton onClick={onCopyHandler} title="Copy" size="large" sx={{ padding: '0px 12px' }}>
            <animated.span style={springStyleCopyButton} className={classes.iconNormalHeight}>
              <FileCopyIcon />
            </animated.span>
          </IconButton>
          <IconButton onClick={handleClose} title="Close" size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={12} md={7} lg={5}>
            <List dense={true} className={classes.greyEvenChildren}>
              <ListSubheader
                className={classes.listHeaderBg}
                onClick={handleClickDistributionPattern}
                style={{ cursor: 'pointer' }}
              >
                <IconButton
                  title={showDistributionPattern ? 'Hide' : 'Show'}
                  aria-label="Show Distribution Pattern Menu"
                  size="large"
                >
                  {!showDistributionPattern ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                </IconButton>
                Distribution Pattern
              </ListSubheader>
              <Collapse in={showDistributionPattern} timeout="auto">
                <ListItem className={classes.selectDistributionButtons}>
                  <ToggleButtonGroup
                    size="small"
                    value={patternType}
                    exclusive
                    onChange={handleChangePatternType}
                    aria-label="Pattern type"
                  >
                    <ToggleButtonWithTooltip
                      disabled={locked}
                      value={PatternTypes.uniformDistribution}
                      aria-label="uniform distribution"
                      title="The gap between pallets are all the same"
                    >
                      Uniform Distribution
                    </ToggleButtonWithTooltip>
                    <ToggleButtonWithTooltip
                      disabled={locked}
                      value={PatternTypes.repetitivePattern}
                      aria-label="repetitive pattern"
                      title="Create a pattern and repeat it"
                    >
                      Repetitive Pattern
                    </ToggleButtonWithTooltip>
                    <ToggleButtonDisabledInStandard
                      standardMode={standardMode}
                      disabled={locked}
                      value={PatternTypes.relatedDistribution}
                      aria-label="related distribution"
                      title="Select the gap for every line and every column"
                    >
                      Line/Column related distribution
                    </ToggleButtonDisabledInStandard>
                    <ToggleButtonDisabledInStandard
                      standardMode={standardMode}
                      disabled={locked}
                      value={PatternTypes.customDistribution}
                      aria-label="custom distribution"
                      title="Select the gap between every pallets"
                    >
                      Custom Distribution
                    </ToggleButtonDisabledInStandard>
                  </ToggleButtonGroup>
                </ListItem>

                <ListItem>
                  <ListItemText primary="Number of lines"></ListItemText>
                  <ListItemSecondaryAction>
                    <TextField
                      type="number"
                      inputProps={{ min: 1, step: 1 }}
                      value={nbLinesTmp !== null ? nbLinesTmp : nbLines}
                      onChange={handleChangeNbLines}
                      className={classes.textFieldWidth}
                      disabled={locked}
                      onBlur={() => setNbLinesTmp(null)}
                      variant="standard"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Number of slots per line"></ListItemText>
                  <ListItemSecondaryAction>
                    <TextField
                      type="number"
                      inputProps={{ min: 1, step: 1 }}
                      value={nbSlotsPerLineTmp !== null ? nbSlotsPerLineTmp : nbSlotsPerLine}
                      onChange={handleChangeSlotsPerLine}
                      className={classes.textFieldWidth}
                      disabled={locked}
                      onBlur={() => setNbSlotsPerLineTmp(null)}
                      variant="standard"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                {/*
              <ListItem>
                <ListItemText primary="Line Propagation Method"></ListItemText>
                <ListItemSecondaryAction>
                  <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={linePropagationMethod}
                    onChange={handleChangeLinePropagationMethod}
                  >
                    <ToggleButton
                      disabled={locked}
                      value={PropagationMethod.offset}
                      className={classes.toggleButtonDense}
                    >
                      Offset
                    </ToggleButton>
                    <ToggleButton
                      disabled={locked}
                      value={PropagationMethod.clearance}
                      className={classes.toggleButtonDense}
                    >
                      Clearance
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ListItemSecondaryAction>
              </ListItem>
              */}
                <Collapse
                  in={
                    patternType === PatternTypes.uniformDistribution || patternType === PatternTypes.repetitivePattern
                  }
                  timeout="auto"
                >
                  <ListItem>
                    <ListItemText
                      primary="Space between lines"
                      secondary={
                        displayedValueSpaceBetweenLines < minimumValueSpaceBetweenLines
                          ? `⚠️ Under the safety limit (${minimumValueSpaceBetweenLines} cm)`
                          : null
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">L1 = </InputAdornment>,
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        inputProps={{ min: 0, step: 0.5 }}
                        value={spaceBetweenLinesTmp !== null ? spaceBetweenLinesTmp : humanize(spaceBetweenLines)}
                        onChange={handleChangeSpaceBetweenLines}
                        error={spaceBetweenLines < minimumValueSpaceBetweenLines}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        onBlur={() => setSpaceBetweenLinesTmp(null)}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {/*
              <ListItem>
                <ListItemText primary="Slot Propagation Method"></ListItemText>
                <ListItemSecondaryAction>
                  <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={slotPropagationMethod}
                    onChange={handleChangeSlotPropagationMethod}
                  >
                    <ToggleButton
                      disabled={locked}
                      value={PropagationMethod.offset}
                      className={classes.toggleButtonDense}
                    >
                      Offset
                    </ToggleButton>
                    <ToggleButton
                      disabled={locked}
                      value={PropagationMethod.clearance}
                      className={classes.toggleButtonDense}
                    >
                      Clearance
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ListItemSecondaryAction>
              </ListItem>
              */}
                  <ListItem>
                    <ListItemText
                      primary="Space between slots"
                      secondary={
                        displayedValueSpaceBetweenSlots < minimumValueSpaceBetweenSlots
                          ? `⚠️ Under the safety limit (${minimumValueSpaceBetweenSlots} cm)`
                          : null
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">L2 = </InputAdornment>,
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        inputProps={{ min: 0, step: 0.5 }}
                        value={spaceBetweenSlotsTmp !== null ? spaceBetweenSlotsTmp : humanize(spaceBetweenSlots)}
                        onChange={handleChangeSpaceBetweenSlots}
                        error={spaceBetweenSlots < minimumValueSpaceBetweenSlots}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        onBlur={() => setSpaceBetweenSlotsTmp(null)}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {displayedValueSpaceBetweenSlots < minimumValueSpaceEitherLinesOrSlots &&
                  displayedValueSpaceBetweenLines < minimumValueSpaceEitherLinesOrSlots &&
                  (nbLines > 1 || nbSlots > 1) ? (
                    <ListItem>
                      <ListItemText
                        secondary={`⚠️ People have no sufficient space as escape route between the pallets, so remember to keep at least ${minimumValueSpaceEitherLinesOrSlots} cm clearance with the closest obstacle behind.`}
                      ></ListItemText>
                    </ListItem>
                  ) : null}
                </Collapse>

                <Collapse in={patternType === PatternTypes.repetitivePattern} timeout="auto">
                  <ListSubheader className={classes.listHeaderBg}>Pattern Split</ListSubheader>

                  <ListItem>
                    <ListItemText primary="Number of lines per group"></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        inputProps={{ min: 1, step: 1 }}
                        value={nbLinesPerGroupTmp !== null ? nbLinesPerGroupTmp : nbLinesPerGroup}
                        onChange={handleChangeNbLinesPerGroup}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        onBlur={() => setNbLinesPerGroupTmp(null)}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Number of slots per group"
                      secondary={standardMode ? textHelperDisabledStandardMode : undefined}
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        inputProps={{ min: 1, step: 1 }}
                        value={nbSlotsPerGroupTmp !== null ? nbSlotsPerGroupTmp : nbSlotsPerGroup}
                        onChange={handleChangeNbSlotsPerGroup}
                        className={classes.textFieldWidth}
                        disabled={locked || standardMode}
                        onBlur={() => setNbSlotsPerGroupTmp(null)}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Space between line patterns"></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        inputProps={{ min: 0, step: 0.5 }}
                        value={
                          spaceBetweenLinePatternsTmp !== null
                            ? spaceBetweenLinePatternsTmp
                            : humanize(spaceBetweenLinePatterns)
                        }
                        onChange={handleChangeSpaceBetweenLinePatterns}
                        //error={spaceBetweenLines < minimumValueSpaceBetweenLines}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        onBlur={() => setSpaceBetweenLinePatternsTmp(null)}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Space between slot patterns"
                      secondary={standardMode ? textHelperDisabledStandardMode : undefined}
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        inputProps={{ min: 0, step: 0.5 }}
                        value={
                          spaceBetweenSlotPatternsTmp !== null
                            ? spaceBetweenSlotPatternsTmp
                            : humanize(spaceBetweenSlotPatterns)
                        }
                        onChange={handleChangeSpaceBetweenSlotPatterns}
                        //error={spaceBetweenLines < minimumValueSpaceBetweenLines}
                        className={classes.textFieldWidth}
                        disabled={locked || standardMode}
                        onBlur={() => setSpaceBetweenSlotPatternsTmp(null)}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Collapse>
              </Collapse>

              <ListSubheader
                className={classes.listHeaderBg}
                onClick={handleClickSlotDefinition}
                style={{ cursor: 'pointer' }}
              >
                <IconButton
                  title={showSlotDefinition ? 'Hide' : 'Show'}
                  aria-label="Show Slot Definition Menu"
                  size="large"
                >
                  {!showSlotDefinition ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                </IconButton>
                Slot Definition
              </ListSubheader>
              <Collapse in={showSlotDefinition} timeout="auto">
                <ListItem className={classes.selectDistributionButtons}>
                  <ToggleButtonGroup
                    size="small"
                    value={selectionType}
                    exclusive
                    onChange={handleChangeSelectionType}
                    aria-label="Selection type"
                    classes={{ root: classes.marginAuto }}
                  >
                    <ToggleButton disabled={locked} value={SelectionTypes.uniform} aria-label="uniform">
                      Uniform
                    </ToggleButton>
                    <ToggleButtonDisabledInStandard
                      standardMode={standardMode}
                      disabled={locked}
                      value={SelectionTypes.line}
                      aria-label="line"
                    >
                      Line
                    </ToggleButtonDisabledInStandard>
                    <ToggleButtonDisabledInStandard
                      standardMode={standardMode}
                      disabled={locked}
                      value={SelectionTypes.column}
                      aria-label="column"
                    >
                      Column
                    </ToggleButtonDisabledInStandard>
                    <ToggleButtonDisabledInStandard
                      standardMode={standardMode}
                      disabled={locked}
                      value={SelectionTypes.custom}
                      aria-label="custom"
                    >
                      Custom
                    </ToggleButtonDisabledInStandard>
                  </ToggleButtonGroup>
                </ListItem>
                <ListItem disabled={locked}>
                  <ListItemText
                    primary="Selection"
                    secondary={standardMode ? textHelperDisabledStandardMode : undefined}
                  ></ListItemText>
                  <ListItemSecondaryAction>
                    <ButtonGroup
                      disabled={locked || standardMode || !recursiveSome(selection)}
                      color="primary"
                      size="small"
                      aria-label="enable or disable the selected slots"
                    >
                      <Button onClick={() => handleClickDisableState(false)}>Enable</Button>
                      <Button onClick={() => handleClickDisableState(true)}>Disable</Button>
                    </ButtonGroup>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disabled={locked}>
                  <ListItemText primary="Slot Length"></ListItemText>
                  <ListItemSecondaryAction>
                    {slotLengthSelection ? (
                      <TextField
                        type="number"
                        value={slotLengthSelection ? humanize(slotLengthSelection) : ''}
                        onChange={handleChangeSlotLength}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        inputProps={{ step: 10 }}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        variant="standard"
                      />
                    ) : (
                      <TextField
                        type="number"
                        value={slotLengthTmp ? humanize(slotLengthTmp) : ''}
                        onChange={handleChangeSlotLengthTmp}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        inputProps={{ step: 10 }}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        onKeyPress={handleEnterSlotLengthTmp}
                        variant="standard"
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disabled={locked}>
                  <ListItemText primary="Slot Width"></ListItemText>
                  <ListItemSecondaryAction>
                    {slotWidthSelection ||
                    (selectionType !== SelectionTypes.uniform && selectionType !== SelectionTypes.line) ? (
                      <TextField
                        type="number"
                        value={slotWidthSelection ? humanize(slotWidthSelection) : ''}
                        onChange={handleChangeSlotWidth}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        inputProps={{ step: 10 }}
                        className={classes.textFieldWidth}
                        disabled={
                          locked ||
                          !slotWidthSelection ||
                          (selectionType !== SelectionTypes.uniform && selectionType !== SelectionTypes.line)
                        }
                        variant="standard"
                      />
                    ) : (
                      <TextField
                        type="number"
                        value={slotWidthTmp ? humanize(slotWidthTmp) : ''}
                        onChange={handleChangeSlotWidthTmp}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                        inputProps={{ step: 10 }}
                        className={classes.textFieldWidth}
                        disabled={
                          locked || (selectionType !== SelectionTypes.uniform && selectionType !== SelectionTypes.line)
                        }
                        onKeyPress={handleEnterSlotWidthTmp}
                        variant="standard"
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListSubheader
                  className={classes.listHeaderBg}
                  onClick={handleClickShowPalletMenu}
                  style={{ cursor: 'pointer', marginLeft: '20px', display: 'none' }}
                >
                  <IconButton title={showPalletMenu ? 'Hide' : 'Show'} aria-label="Show Pallet Menu" size="large">
                    {!showPalletMenu ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                  </IconButton>
                  Pallets
                </ListSubheader>
                <Collapse in={showPalletMenu} timeout="auto" style={{ marginLeft: '20px', display: 'none' }}>
                  <ListItem disabled={locked}>
                    <ListItemText primary="Display"></ListItemText>
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        onChange={handleDisplayPallets}
                        checked={displayPallets}
                        inputProps={{ 'aria-labelledby': 'display the pallets' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem disabled={locked}>
                    <ListItemText primary="Accepted pallets"></ListItemText>
                    <FormControl>
                      <Select
                        id="accepted-pallets"
                        multiple
                        value={selectedPallets}
                        onChange={handleChangeSelectPallets}
                        input={<Input id="select-accepted-pallets" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                        displayEmpty
                        variant="standard"
                      >
                        {availablePallets.map(({ name }) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem disabled={locked}>
                    <ListItemText primary="Pallet to display"></ListItemText>
                    <FormControl>
                      <Select
                        id="pallet-to-display"
                        value={palletToDisplay}
                        onChange={handleChangePalletToDisplay}
                        displayEmpty
                        className={classes.palletToDisplaySelect}
                        variant="standard"
                      >
                        <MenuItem value="" key="none">
                          None
                        </MenuItem>
                        {selectedPallets.map((selectedPallet) => (
                          <MenuItem value={selectedPallet} key={selectedPallet}>
                            {selectedPallet}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ListItem>
                </Collapse>

                <ListSubheader
                  className={classes.listHeaderBg}
                  onClick={handleClickShowPalletPosition}
                  style={{ cursor: 'pointer', marginLeft: '20px', display: 'none' }}
                >
                  <IconButton
                    title={showPalletPosition ? 'Hide' : 'Show'}
                    aria-label="Show the pallet position menu"
                    size="large"
                  >
                    {!showPalletPosition ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                  </IconButton>
                  Pallet Position
                </ListSubheader>
                <Collapse in={showPalletPosition} timeout="auto" style={{ marginLeft: '20px', display: 'none' }}>
                  <ListItem disabled={locked}>
                    <ListItemText primary="Pallet position (X)"></ListItemText>
                    <ListItemSecondaryAction>
                      <ToggleButtonGroup
                        size="small"
                        exclusive
                        value={referenceSlotMethodX}
                        onChange={handleChangeSlotMethodX}
                      >
                        <ToggleButton
                          disabled={locked}
                          value={ReferenceMethodX.frontEdge}
                          className={classes.toggleButtonDense}
                        >
                          {ReferenceMethodX.frontEdge}
                        </ToggleButton>
                        <ToggleButton
                          disabled={locked}
                          value={ReferenceMethodX.rearEdge}
                          className={classes.toggleButtonDense}
                        >
                          {ReferenceMethodX.rearEdge}
                        </ToggleButton>
                        <ToggleButton
                          disabled={locked}
                          value={ReferenceMethodX.center}
                          className={classes.toggleButtonDense}
                        >
                          {ReferenceMethodX.center}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem disabled={locked}>
                    <ListItemText
                      primary="Pallet Offset (X)"
                      secondary={referenceOffsetX < minimumValueOffsetX ? '⚠️ Under the safety limit' : null}
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">cm</InputAdornment> }}
                        inputProps={{ min: 0, step: 0.5 }}
                        value={referenceOffsetX}
                        error={referenceOffsetX < minimumValueOffsetX}
                        onChange={handleChangeReferenceOffsetX}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disabled={locked}>
                    <ListItemText primary="Pallet Position (Y)"></ListItemText>
                    <ListItemSecondaryAction>
                      <ToggleButtonGroup
                        size="small"
                        exclusive
                        value={referenceSlotMethodY}
                        onChange={handleChangeSlotMethodY}
                      >
                        <ToggleButton
                          disabled={locked}
                          value={ReferenceMethodY.leftEdge}
                          className={classes.toggleButtonDense}
                        >
                          {ReferenceMethodY.leftEdge}
                        </ToggleButton>
                        <ToggleButton
                          disabled={locked}
                          value={ReferenceMethodY.rightEdge}
                          className={classes.toggleButtonDense}
                        >
                          {ReferenceMethodY.rightEdge}
                        </ToggleButton>
                        <ToggleButton
                          disabled={locked}
                          value={ReferenceMethodY.center}
                          className={classes.toggleButtonDense}
                        >
                          {ReferenceMethodY.center}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem disabled={locked}>
                    <ListItemText
                      primary="Pallet Offset (Y)"
                      secondary={referenceOffsetY < minimumValueOffsetY ? '⚠️ Under the safety limit' : null}
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <TextField
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">cm</InputAdornment> }}
                        inputProps={{ min: 0, step: 0.5 }}
                        value={referenceOffsetY}
                        error={referenceOffsetY < minimumValueOffsetY}
                        onChange={handleChangeReferenceOffsetY}
                        className={classes.textFieldWidth}
                        disabled={locked}
                        variant="standard"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Collapse>
              </Collapse>
              <ListSubheader
                className={classes.listHeaderBg}
                onClick={handleShowScanMenu}
                style={{ cursor: 'pointer' }}
              >
                <IconButton title={showScanMenu ? 'Hide' : 'Show'} aria-label="Show Scan Properties Menu" size="large">
                  {!showScanMenu ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                </IconButton>
                Scan
              </ListSubheader>
              <Collapse in={showScanMenu} timeout="auto">
                <ListItem disabled={locked}>
                  <ListItemText primary="Enable"></ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={handleEnableScan}
                      checked={enableScan}
                      inputProps={{ 'aria-labelledby': 'enable scan' }}
                      disabled={locked}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disabled={locked}>
                  <ListItemText
                    primary="Fill strategy"
                    secondary={standardMode ? textHelperDisabledStandardMode : undefined}
                  ></ListItemText>
                  <FormControl>
                    <Select
                      id="scan-fill-strategy"
                      value={fillStrategy}
                      onChange={handleChangeFillStrategy}
                      input={<Input id="scan-fill-strategy" />}
                      variant="standard"
                      disabled={locked || standardMode}
                    >
                      <MenuItem value={1}>Furthest</MenuItem>
                      <MenuItem value={2}>Nearest</MenuItem>
                      <MenuItem value={3}>Equality</MenuItem>
                      <MenuItem value={6}>Custom</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem disabled={locked}>
                  <ListItemText
                    primary="Empty strategy"
                    secondary={standardMode ? textHelperDisabledStandardMode : undefined}
                  ></ListItemText>
                  <FormControl>
                    <Select
                      id="scan-empty-strategy"
                      value={emptyStrategy}
                      onChange={handleChangeEmptyStrategy}
                      input={<Input id="scan-empty-strategy" />}
                      variant="standard"
                      disabled={locked || standardMode}
                    >
                      <MenuItem value={1}>Furthest</MenuItem>
                      <MenuItem value={2}>Nearest</MenuItem>
                      <MenuItem value={3}>Equality</MenuItem>
                      <MenuItem value={6}>Custom</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
              </Collapse>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={7} style={{ overflow: 'hidden' }}>
            <ListSubheader className={classes.listHeaderBg}>
              Preview →
              <IconButton
                title="Set back the zoom to default value"
                aria-label="Restore the default zoom factor"
                onClick={() => handleClickResetZoom()}
                size="large"
                style={{ marginLeft: 10 }}
              >
                <ZoomOutMapIcon />
              </IconButton>
              <IconButton
                title={`${showLineStockNames ? 'Hide' : 'Show'} the line stock names`}
                onClick={handleClickShowLineStockNames}
                color={showLineStockNames ? 'primary' : 'default'}
                size="large"
              >
                <TitleIcon />
              </IconButton>
              <Tooltip title="Rename stocklines">
                <IconButton onClick={handleClickRenameStocklines}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={<EditIcon fontSize="small" color={openMenuRenameStockline ? 'primary' : undefined} />}
                    ref={renameStocklineIconRef}
                  >
                    <TitleIcon color={openMenuRenameStockline ? 'primary' : undefined} />
                  </Badge>
                </IconButton>
              </Tooltip>
              {renameStocklineIconRef.current && openMenuRenameStockline ? (
                <RenameStocklinesMenu
                  anchorEl={renameStocklineIconRef.current}
                  open={openMenuRenameStockline}
                  onClose={handleCloseMenuRenameStockline}
                  actions={actions}
                />
              ) : (
                <></>
              )}
              <Box
                component="div"
                sx={{
                  float: 'right',
                }}
              >
                {nbSlots} slot{nbSlots !== 0 ? 's' : ''}
              </Box>
            </ListSubheader>
            <div className={classes.smallSpaceTop} style={{ transformOrigin: 'top center' }}>
              {(nbLines < 30 && nbSlotsPerLine < 30) || forceDisplayPreview ? (
                <StockZoneVisualize
                  nbLines={nbLines}
                  nbSlotsPerLine={nbSlotsPerLine}
                  spaceBetweenLines={displayedValueSpaceBetweenLines}
                  spaceBetweenSlots={displayedValueSpaceBetweenSlots}
                  patternType={patternType}
                  customGapSlots={customGapSlots}
                  customGapLines={customGapLines}
                  handleChangeCustomGapSlots={handleChangeCustomGapSlots}
                  handleChangeCustomGapLines={handleChangeCustomGapLines}
                  slots={slots}
                  selection={selection}
                  handleClickPallet={handleClickPallet}
                  minX={minX}
                  maxX={maxX}
                  minY={minY}
                  maxY={maxY}
                  computeSlotPosition={computeSlotPosition}
                  badges={slotsBadge}
                  showLineStockNames={showLineStockNames}
                  actions={actions}
                  stockZoneId={stockZoneId}
                  stockZone={stockZone}
                />
              ) : (
                <Box component="div">
                  <Box component="span">Preview disabled for large stock zones</Box>
                  <br />
                  <Button
                    onClick={() => setForceDisplayPreview(true)}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    Display preview anyway
                  </Button>
                </Box>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Close
        </Button>
        <Button onClick={applyChange} color="primary" variant="contained" disabled={!!errorName}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface PalletStockZoneVisualizeProps {
  first: boolean;
  last: boolean;
  spaceBetweenSlots: number;
  patternType: PatternTypes;

  x: number;
  y: number;
  customGapSlots: number[][];
  handleChangeCustomGapSlots: (e: React.ChangeEvent<HTMLInputElement>) => void;
  slots: SlotArray[];
  nbLines: number;
  selection: boolean[][];
  handleClickPallet: (e: React.MouseEvent, x: number, y: number) => void;
  computeSlotPosition: () => void;
  badges: (string | undefined)[][];
}

function LineStockZoneVisualize({
  first,
  last,
  spaceBetweenSlots,
  patternType,
  x,
  y,
  customGapSlots,
  handleChangeCustomGapSlots,
  slots,
  nbLines,
  selection,
  handleClickPallet,
  computeSlotPosition,
  badges,
}: PalletStockZoneVisualizeProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <foreignObject
        x={
          slots && slots[y] && slots[y].slots[x - 1]
            ? slots[y].slots[x - 1].slotPosition.x + slots[y].slots[x - 1].slotSize.length * 100
            : slots[y] && slots[y].slots[0].slotPosition.x - WIDTH_FIRST_TEXTFIELD
        }
        y={
          slots && slots[y] && slots[y].slots[x]
            ? slots[y].slots[x].slotPosition.y + (slots[y].slots[x].slotSize.width * 100) / 2 - HEIGHT_TEXTFIELD / 2
            : 0
        }
        width="1000"
        height="100"
      >
        <TextField
          value={`${
            customGapSlots && customGapSlots[y] && customGapSlots[y][x] !== undefined
              ? humanize(customGapSlots[y][x])
              : x !== 0
                ? humanize(spaceBetweenSlots)
                : 0
          }`}
          onChange={handleChangeCustomGapSlots}
          variant="standard"
          inputProps={{
            className: classes.textFieldBetweenSlots,
            'data-x': x,
            'data-y': y,
            style: {
              width: `${
                slots[y] && slots[y].slots[x] && slots[y].slots[x - 1]
                  ? slots[y].slots[x].slotPosition.x -
                    slots[y].slots[x - 1].slotPosition.x -
                    slots[y].slots[x - 1].slotSize.length * 100
                  : WIDTH_FIRST_TEXTFIELD
              }px`,
            },
          }}
          InputProps={{ disableUnderline: true }}
          disabled={
            patternType === PatternTypes.uniformDistribution ||
            patternType === PatternTypes.repetitivePattern ||
            (patternType === PatternTypes.relatedDistribution && !first)
          }
          onBlur={computeSlotPosition}
        />
      </foreignObject>

      <image
        href={PalletSVG}
        x={
          slots && slots[y] && slots[y].slots[x]
            ? slots[y].slots[x].slotPosition.x
            : slots[y - 1] && slots[y - 1].slots[x]
              ? slots[y - 1].slots[x].slotPosition.x
              : 0
        }
        y={slots && slots[y] && slots[y].slots[x] ? slots[y].slots[x].slotPosition.y : 0}
        width={slots && slots[y] && slots[y].slots[x] ? slots[y].slots[x].slotSize.length * 100 : 0}
        height={slots && slots[y] && slots[y].slots[x] ? slots[y].slots[x].slotSize.width * 100 : 0}
        data-slot-stock-zone
        className={classes.palletSvgIcon}
        data-x={x}
        data-y={y}
        data-disabled={slots && slots[y] && slots[y].slots[x] && !!slots[y].slots[x].disabled}
        onClick={(e) => handleClickPallet(e, x, y)}
        preserveAspectRatio="none"
      />
      {slots && slots[y] && slots[y].slots[x] && badges[y][x] ? (
        <circle
          cx={slots[y].slots[x].slotPosition.x}
          cy={slots[y].slots[x].slotPosition.y}
          r="10"
          fill={badges[y][x]}
        />
      ) : undefined}
    </>
  );
}

interface StockZoneVisualizeProps {
  nbLines: number;
  nbSlotsPerLine: number;
  spaceBetweenLines: number;
  spaceBetweenSlots: number;
  patternType: PatternTypes;

  customGapSlots: number[][];
  customGapLines: number[];
  handleChangeCustomGapSlots: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCustomGapLines: (e: React.ChangeEvent<HTMLInputElement>) => void;
  slots: SlotArray[];
  selection: boolean[][];
  handleClickPallet: (e: React.MouseEvent, x: number, y: number) => void;
  computeSlotPosition: () => void;

  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  badges: (string | undefined)[][];

  showLineStockNames: boolean;

  actions: ActionsStockZone;

  stockZoneId: string;
  stockZone: CircuitStockZone;
}

function StockZoneVisualize({
  nbLines,
  nbSlotsPerLine,
  spaceBetweenLines,
  spaceBetweenSlots,
  patternType,
  customGapSlots,
  customGapLines,
  handleChangeCustomGapSlots,
  handleChangeCustomGapLines,
  slots,
  selection,
  handleClickPallet,
  minX,
  maxX,
  minY,
  maxY,
  computeSlotPosition,
  badges,
  showLineStockNames,
  actions,
  stockZoneId,
  stockZone,
}: StockZoneVisualizeProps): JSX.Element {
  const offsetYAxe = 10;
  const offsetXAxe = 10;
  const marginRight = 5 + 2 * offsetXAxe;
  const marginTop = offsetYAxe * 2;

  const realMinX = minX + WIDTH_FIRST_TEXTFIELD;

  const widthStockZone = maxX - realMinX;
  const heightStockZone = maxY - minY;

  const widthTextFieldAxe = 100;

  /** enable the zoom with d3 */

  useEffect(() => {
    const svg = d3.select('#stock-zone-edition-svg');
    const zoomContainer = svg.select('#stock-zone-edition-svg-zoom');

    setZoomStockZoneEdition(
      d3.zoom().on('zoom', () => {
        zoomContainer.attr('transform', d3.event.transform);
      })
    );

    svg.call(zoomStockZoneEdition as any).on('dblclick.zoom', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setZoomStockZoneEdition, zoomStockZoneEdition]);

  // prevent from rendering if the stock zone is not initialized yet, can happen on the first render
  if (!isFinite(minX) || !isFinite(maxX) || !isFinite(minY) || !isFinite(maxY)) {
    return <>...</>;
  }

  return (
    <>
      <svg
        id="stock-zone-edition-svg"
        width="1000"
        height="1000"
        viewBox={`${minX} ${minY - marginTop} ${Math.abs(maxX - minX + marginRight)} ${Math.abs(
          maxY - minY + marginTop
        )}`}
        style={{ width: '100%', maxHeight: '50vh', overflow: 'visible' }}
      >
        <defs>
          <marker
            id="arrow-axe"
            viewBox="0 0 10 10"
            refX="8"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
            fill={theme.palette.primary.main}
          >
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
        </defs>

        <g id="stock-zone-edition-svg-zoom">
          <foreignObject
            x={(realMinX + maxX) / 2 - widthTextFieldAxe / 2}
            y={minY - 3 * offsetYAxe}
            width="1000"
            height="1000"
            className="overflow-visible"
          >
            <TextField
              value={`${(widthStockZone / 100).toFixed(2)} m`}
              variant="standard"
              inputProps={{
                style: {
                  textAlign: 'center',
                  height: `${offsetYAxe}px`,
                  color: theme.palette.primary.main,
                  width: `${widthTextFieldAxe}px`,
                },
              }}
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
          </foreignObject>
          <foreignObject
            x={maxX - widthTextFieldAxe / 2 + offsetXAxe * 2}
            y={(maxY + minY) / 2 - offsetYAxe}
            width="1000"
            height="1000"
            style={{ overflow: 'visible' }}
          >
            <TextField
              value={`${(heightStockZone / 100).toFixed(2)} m`}
              variant="standard"
              inputProps={{
                style: {
                  textAlign: 'center',
                  height: `${offsetYAxe}px`,
                  color: theme.palette.primary.main,
                  width: `${widthTextFieldAxe}px`,
                  transform: 'rotate(-90deg)',
                },
              }}
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
          </foreignObject>

          <line
            x1={realMinX}
            x2={maxX}
            y1={minY - offsetYAxe}
            y2={minY - offsetYAxe}
            stroke={theme.palette.primary.main}
            markerStart="url(#arrow-axe)"
            markerEnd="url(#arrow-axe)"
          />
          <line
            x1={maxX + offsetXAxe}
            x2={maxX + offsetXAxe}
            y1={minY}
            y2={maxY}
            stroke={theme.palette.primary.main}
            markerStart="url(#arrow-axe)"
            markerEnd="url(#arrow-axe)"
          />

          {[...(Array(nbLines) as undefined[])].map((x2, j) => {
            return (
              <RowStockZoneVisualize
                key={j}
                last={j === nbLines - 1 || j === 0}
                first={j === 0}
                nbSlotsPerLine={nbSlotsPerLine}
                spaceBetweenLines={spaceBetweenLines}
                spaceBetweenSlots={spaceBetweenSlots}
                patternType={patternType}
                y={j}
                customGapSlots={customGapSlots}
                customGapLines={customGapLines}
                handleChangeCustomGapSlots={handleChangeCustomGapSlots}
                handleChangeCustomGapLines={handleChangeCustomGapLines}
                slots={slots}
                nbLines={nbLines}
                selection={selection}
                handleClickPallet={handleClickPallet}
                computeSlotPosition={computeSlotPosition}
                badges={badges}
                showLineStockNames={showLineStockNames}
                actions={actions}
                stockLineId={slots[j]?.id}
                stockZoneId={stockZoneId}
                stockZone={stockZone}
              />
            );
          })}
        </g>
      </svg>
    </>
  );
}

interface RowStockZoneVisualizeProps {
  first: boolean;
  last: boolean;
  nbSlotsPerLine: number;
  spaceBetweenLines: number;
  spaceBetweenSlots: number;
  patternType: PatternTypes;

  y: number;
  customGapSlots: number[][];
  customGapLines: number[];
  handleChangeCustomGapSlots: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCustomGapLines: (e: React.ChangeEvent<HTMLInputElement>) => void;
  slots: SlotArray[];
  nbLines: number;
  selection: boolean[][];
  handleClickPallet: (e: React.MouseEvent, x: number, y: number) => void;
  computeSlotPosition: () => void;
  badges: (string | undefined)[][];

  showLineStockNames: boolean;

  actions: ActionsStockZone;

  stockLineId: string;
  stockZoneId: string;
  stockZone: CircuitStockZone;
}
function RowStockZoneVisualize({
  first,
  last,
  nbSlotsPerLine,
  spaceBetweenLines,
  spaceBetweenSlots,
  patternType,
  y,
  customGapSlots,
  customGapLines,
  handleChangeCustomGapSlots,
  handleChangeCustomGapLines,
  slots,
  nbLines,
  selection,
  handleClickPallet,
  computeSlotPosition,
  badges,
  showLineStockNames,
  actions,
  stockLineId,
  stockZoneId,
  stockZone,
}: RowStockZoneVisualizeProps): JSX.Element {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);

  let heightTextField =
    slots && slots[y - 1] && slots[y - 1].slots[0] && slots[y] && slots[y].slots[0]
      ? slots[y].slots[0].slotPosition.y -
        slots[y - 1].slots[0].slotPosition.y -
        slots[y - 1].slots[0].slotSize.width * 100
      : spaceBetweenLines;
  const posY =
    slots && slots[y - 1] && slots[y - 1].slots[0]
      ? slots[y - 1].slots[0].slotPosition.y + slots[y - 1].slots[0].slotSize.width * 100
      : 0;
  const posX =
    slots && slots[y] && slots[y].slots[0]
      ? slots[y].slots[0].slotPosition.x - (heightTextField >= 20 ? 0 : WIDTH_FIRST_TEXTFIELD)
      : slots && slots[y - 1] && slots[y - 1].slots[0]
        ? slots[y - 1] && slots[y - 1].slots[0].slotPosition.x
        : 0; // for display otherwise we cannot access the textfield

  if (heightTextField < 20) heightTextField = 20;

  const posXLineName =
    slots && slots[y] && slots[y].slots[0] && slots[y].slots[slots[y].slots.length - 1]
      ? (slots[y].slots[0].slotPosition.x + slots[y].slots[slots[y].slots.length - 1].slotPosition.x) / 2
      : 0;
  const posYLineName = slots && slots[y] && slots[y].slots[0] ? slots[y].slots[0].slotPosition.y : 0;

  const [lineStockName, setLineStockName] = useState(slots && slots[y] && slots[y].name ? slots[y].name : '');
  // const warningSpaceStartOrEnd = lineStockName.startsWith(' ') || lineStockName.endsWith(' ');
  // const valueToDisplay = lineStockName.replaceAll(' ', '\u00A0');

  useEffect(() => {
    setLineStockName(slots && slots[y] && slots[y].name ? slots[y].name : '');
  }, [slots, y]);

  const posXArrowLine =
    slots && slots[y] && slots[y].slots[slots[y].slots.length - 1]
      ? slots[y].slots[slots[y].slots.length - 1].slotPosition.x +
        slots[y].slots[slots[y].slots.length - 1].slotSize.length * 100
      : 0;
  const posYArrowLine =
    slots && slots[y] && slots[y].slots[slots[y].slots.length - 1]
      ? slots[y].slots[slots[y].slots.length - 1].slotPosition.y +
        (slots[y].slots[slots[y].slots.length - 1].slotSize.width * 100) / 2
      : 0;
  const arrowLineWidth = 8;
  const arrowLineHeight = 8;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isRenameStocklineModelOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const [errorLineName, setErrorLineName] = useState(false);
  const deferredError = useDeferredValue(errorLineName);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleChangeNameLine = useCallback(() => {
    if (!inputRef.current) return;
    const newVal = inputRef.current.value;

    const shapesIdsToIgnore = [stockLineId, stockZoneId];
    const otherNamesToConsider = slots
      .filter((stockLine) => stockLine.id !== stockLineId)
      .map((stockLine) => stockLine.name);

    const isNameAlreadyUsed = !areAllShapeNamesUnique(
      [newVal],
      shapesIdsToIgnore,
      {
        ignoreDuplicatesBefore: true,
      },
      otherNamesToConsider
    );

    if (!newVal || isNameAlreadyUsed) {
      setErrorLineName(true);
    } else {
      if (newVal.length <= 50) {
        actions.setSlots((stockLines) => {
          const newStockLines = stockLines.map((stockLine) => {
            if (stockLine.id === stockLineId) {
              return {
                ...stockLine,
                name: newVal,
                userEditedName: true,
              };
            }

            return stockLine;
          });

          return newStockLines;
        });
      }

      handleCloseModal();
    }
  }, [actions, handleCloseModal, slots, stockLineId, stockZoneId]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorLineName(false), [stockLineId]);
  const warningSpaceStartOrEnd = lineStockName.startsWith(' ') || lineStockName.endsWith(' ');
  const valueToDisplay = lineStockName.replaceAll(' ', '\u00A0');

  return (
    <>
      {!first ? (
        <foreignObject x={posX} y={posY} width="1000" height="1000">
          <TextField
            value={`${
              customGapLines && customGapLines[y] !== undefined
                ? humanize(customGapLines[y])
                : humanize(spaceBetweenLines)
            }`}
            variant="standard"
            inputProps={{
              className: classes.positionGap,
              'data-y': y,
              style: {
                height: `${heightTextField}px`,
              },
            }}
            InputProps={{ disableUnderline: true }}
            disabled={
              patternType === PatternTypes.uniformDistribution || patternType === PatternTypes.repetitivePattern
            }
            onChange={handleChangeCustomGapLines}
            onBlur={computeSlotPosition}
          />
        </foreignObject>
      ) : null}
      {[...(Array(nbSlotsPerLine) as undefined[])].map((x1, i) => (
        <LineStockZoneVisualize
          key={i}
          last={i === nbSlotsPerLine - 1}
          first={first}
          spaceBetweenSlots={spaceBetweenSlots}
          patternType={patternType}
          x={i}
          y={y}
          customGapSlots={customGapSlots}
          handleChangeCustomGapSlots={handleChangeCustomGapSlots}
          slots={slots}
          nbLines={nbLines}
          selection={selection}
          handleClickPallet={handleClickPallet}
          computeSlotPosition={computeSlotPosition}
          badges={badges}
        />
      ))}
      <svg
        x={posXArrowLine}
        y={posYArrowLine - arrowLineHeight / 2}
        className="test"
        viewBox="0 0 100 100"
        width={arrowLineWidth}
        height={arrowLineHeight}
      >
        <path d="M 95,50 5,95 5,5 z" fill={theme.palette.primary.main} />
      </svg>
      <foreignObject
        x={posXLineName}
        y={posYLineName}
        width="1000"
        height="1000"
        style={{ display: showLineStockNames ? 'block' : 'none', pointerEvents: 'none' }}
      >
        <TextField
          id={`linename_${slots && slots[y] ? slots[y].id : 'unknown'}`}
          value={valueToDisplay}
          variant="standard"
          inputProps={{
            style: {
              height: `${heightTextField}px`,
              background: 'rgba(255, 255, 255, 0.8)',
            },
            size: lineStockName.length > 2 ? lineStockName.length : 2,
          }}
          InputProps={{ disableUnderline: true }}
          sx={{ pointerEvents: 'all' }}
          onClick={isRenameStocklineModelOpen}
        />
        <React.Fragment key={lineStockName}>
          {warningSpaceStartOrEnd && (
            <Tooltip title="Names cannot start or end with spaces" arrow>
              <text
                textAnchor="middle"
                style={{
                  fontSize: '20px',
                }}
              >
                ⚠
              </text>
            </Tooltip>
          )}
        </React.Fragment>
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FormControl>
              <TextField
                label="Edit stockline name"
                fullWidth
                variant="outlined"
                defaultValue={lineStockName}
                inputRef={inputRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleChangeNameLine} size="small">
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errorLineName}
                helperText={errorLineName ? <Collapse in={deferredError}>Name already used</Collapse> : undefined}
              />
            </FormControl>
          </DialogContent>
        </Dialog>
      </foreignObject>
    </>
  );
}

/**
 * Computes if any element of a child array has a truthy value
 * @param arr1 array that contains an array
 * @returns true or false
 */
function recursiveSome(arr1: any[][]): boolean {
  return arr1.some((arr2) => arr2.some((el) => !!el));
}

/**
 * Computes a number from a string like a hash
 * @param str string to compute the hash
 * @returns the """hash"""
 */
export function hashStr2Nb(str: string): number {
  let sum = 0;

  for (let i = 0, l = str.length; i < l; i++) {
    sum += str.charCodeAt(i);
  }

  return sum;
}

/**
 * Reset the zoom
 * @returns void
 */
export function zoomToStockZone(): void {
  if (!zoomStockZoneEdition) return;

  const root = d3.select('#stock-zone-edition-svg-zoom');
  const rootNode = root.node() as SVGElement;

  if (!rootNode) return;

  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  d3.select(parent)
    .transition()
    .duration(500)
    .call(zoomStockZoneEdition.transform as any, d3.zoomIdentity);
}
