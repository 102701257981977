import type { DialogActionsUnion } from 'actions';
import { DialogActionTypes } from 'actions';
import { DialogTypes } from 'models';
import type { DialogState } from './state';

function getInitialState(): DialogState {
  const roomId = !!window.location.pathname.split('/')[2];
  if (window.__TEST__ || roomId) return { open: false, type: DialogTypes.None };

  return { open: true, type: DialogTypes.Welcome };
}

export function dialogReducer(state = getInitialState(), action: DialogActionsUnion): DialogState {
  if (state.open && state.type === DialogTypes.None) {
    // eslint-disable-next-line no-console
    console.error('Dialog is open but type is None');
  }

  switch (action.type) {
    case DialogActionTypes.OpenDialog: {
      const { payload } = action;
      const newState = { ...state, ...payload, open: true };
      delete newState.payload;

      if (payload && payload.payload) {
        newState.data = {
          ...newState.data,
          ...payload.payload,
        };
      }

      // protect from closing the access required dialog without the proper rights; we do nothing if we do not switch from access required to the welcome dialog
      if (state.type === DialogTypes.AccessRequired && state.open && newState.type !== DialogTypes.Welcome) {
        return state;
      }

      return newState;
    }

    case DialogActionTypes.CloseDialog: {
      return { ...state, type: DialogTypes.None, data: undefined, open: false };
    }

    default:
      return state;
  }
}

export const getOpen = (state: DialogState): DialogState['open'] => state.open;
export const getType = (state: DialogState): DialogState['type'] => state.type;
export const getData = (state: DialogState): DialogState['data'] => state.data;
