export interface PathFlagCheckbox {
  label: string;
  value: number;
  checked?: boolean;
}

export const pathFlagCheckboxesInitial: PathFlagCheckbox[] = [
  { value: 1, label: 'Backward forbidden' },
  { value: 2, label: 'Backward forbidden on kernel' },
  { value: 4, label: 'Forward forbidden' },
  { value: 8, label: 'Forward forbidden on kernel' },
  { value: 16, label: 'Leave kernel only at end' },
  { value: 32, label: 'Backward only at start/end' },
  { value: 64, label: 'Reverse cost is equal to forward' },
  { value: 128, label: 'Backward forbidden on start' },
  { value: 256, label: 'Forward forbidden on deadend' },
];

/**
 * Compute the path flag from the checkboxes
 * @param checkboxes the checkboxes
 * @returns the computed pathflag = the sum of the values of the checked checkboxes
 */
export function checkboxesToPathFlag(checkboxes: PathFlagCheckbox[]): number {
  return checkboxes.reduce((acc, checkbox) => acc + (checkbox.checked ? checkbox.value : 0), 0);
}

/**
 * Compute the path flags checkboxes from the path flag
 * @param pathFlag the path flag
 * @param pathFlagCheckboxes the initial checkboxes
 * @returns the computed checkboxes
 */
export function pathFlagToCheckboxes(pathFlag: number, pathFlagCheckboxes: PathFlagCheckbox[]): PathFlagCheckbox[] {
  let counter = pathFlag;
  const pathFlagCheckboxesReversed = [...pathFlagCheckboxes].reverse();

  return pathFlagCheckboxesReversed
    .map((checkbox) => {
      const checked = counter >= checkbox.value;
      if (checked) counter -= checkbox.value;

      return {
        ...checkbox,
        checked,
      };
    })
    .reverse();
}
