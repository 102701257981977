import { StockZone } from 'drawings/editor-elements';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitStockZone } from 'models/circuit';
import { useEffect, useMemo, useRef } from 'react';
import type { StockManagerRecord } from 'simulation/simulation';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface StockZoneComponentProps {
  stockZone: CircuitStockZone;
  id: string;
  selected?: boolean;
  stock?: StockManagerRecord;
}

export function StockZoneComponent(props: StockZoneComponentProps): JSX.Element {
  const stockZone = props.stockZone;
  const ref = useRef<SVGSVGElement>(null);

  const shape = useMemo(() => {
    if (props.id && stockZone && stockZone.id) {
      return new StockZone(stockZone.id.toString(), stockZone.geometry, projection, zoomScale, stockZone.properties, {
        stock: props.stock,
      });
    }
  }, [props.id, props.stock, stockZone]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();
      if (node) {
        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        ref.current.appendChild(node);
      }
    }
  }, [shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return <g ref={ref}></g>;
}
