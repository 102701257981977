import { Delete as DeleteIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Alert, Box, Button, Card, CardContent, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  changeDisplayStateMapImagePropertiesAction,
  clearMapImageAction,
  importMapImageAction,
  openDialogAction,
  updateMapImagePropertiesAction,
} from 'actions';
import { useConfirm } from 'material-ui-confirm';
import { DialogTypes } from 'models';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import type { MapImageScalingSourceData } from 'models/maps';
import React, { useCallback, useEffect, useState } from 'react';
import { changeMapImageNameFilterAction, removeMapImageFilterAction } from 'reducers/local/filters.reducer';
import type { RootState } from 'store';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      position: 'absolute',
      right: theme.spacing(1),
      top: '0',
      pointerEvents: 'none',
    },
    card: {
      display: 'flex',
      alignSelf: 'center',
      background: 'white',
      pointerEvents: 'initial',
      zIndex: 401,
      maxHeight: getConfig('editor').properties.maxHeight,
    },
    cardContent: {
      display: 'flex',
      flexFlow: 'column',
      overflow: 'auto',
    },
    formTitle: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    formTitleIcon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    formCheckBox: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
    },
    formElement: {
      margin: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: `16px ${theme.spacing(1)}`,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    leftAlign: {
      textAlign: 'left',
    },
    margin: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    fadedTooltip: {
      color: theme.palette.grey[400],
      verticalAlign: 'middle',
    },
    centerAlign: {
      textAlign: 'center',
    },
    fitContent: {
      maxWidth: 'fit-content',
    },
    inputMapImage: {
      display: 'none',
    },
  })
);

export const MapImagePropertiesComponent = (): JSX.Element => {
  const mapImagesSelector = useAppSelector((state: RootState) => state.maps.mapImage.mapImages);
  const openedPropertiesSelector = useAppSelector((state: RootState) => state.maps.mapImage.openProperties) as number;
  const mapImage = mapImagesSelector?.[openedPropertiesSelector];

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const [offsetX, setOffsetX] = useState(mapImage?.x || 0);
  const [offsetY, setOffsetY] = useState(mapImage?.y || 0);
  const [height, setHeight] = useState(mapImage?.height || 0);
  const name = mapImage?.name || '';

  const [offsetXtmp, setOffsetXTmp] = useState<string | null>(null);
  const [offsetYtmp, setOffsetYTmp] = useState<string | null>(null);

  const eraseMapImage = useCallback((): void => {
    confirm({
      title: 'Delete the layout image?',
      allowClose: false,
    })
      .then(() => {
        dispatch(clearMapImageAction({ name }));
        dispatch(removeMapImageFilterAction({ name }));
      })
      .catch(() => undefined);
  }, [confirm, dispatch, name]);

  const closeProperties = useCallback((): void => {
    dispatch(changeDisplayStateMapImagePropertiesAction({ openIndex: undefined }));
  }, [dispatch]);

  const handleUpdateOffset = useCallback((): void => {
    dispatch(
      updateMapImagePropertiesAction({
        properties: {
          ...mapImage,
          x: offsetX * DISPLAY_UNIT_FACTOR || 0,
          y: offsetY * DISPLAY_UNIT_FACTOR || 0,
          height: height || 0,
          name: mapImage?.name || '',
          URL: mapImage?.URL || '',
        },
        targetName: mapImage?.name || '',
      })
    );
  }, [dispatch, height, mapImage, offsetX, offsetY]);

  const handleUpdateOffsetDebounced = useDebouncedCallback(handleUpdateOffset, 500);

  const handleChangeX = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newX: number = parseFloat(e.target.value);

      if (!isNaN(newX)) {
        setOffsetX(newX);
        setOffsetXTmp(null);

        handleUpdateOffsetDebounced();
      } else {
        setOffsetXTmp(e.target.value);
      }
    },
    [handleUpdateOffsetDebounced]
  );

  const handleChangeY = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newY: number = parseFloat(e.target.value);

      if (!isNaN(newY)) {
        setOffsetY(newY);
        setOffsetYTmp(null);

        handleUpdateOffsetDebounced();
      } else {
        setOffsetYTmp(e.target.value);
      }

      handleUpdateOffsetDebounced();
    },
    [handleUpdateOffsetDebounced]
  );

  const handleBlurX = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOffsetXTmp(null);
  }, []);
  const handleBlurY = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOffsetYTmp(null);
  }, []);

  const handleChangeHeight = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newHeight: number = parseFloat(e.target.value) * 100;

      if (!isNaN(newHeight)) setHeight(newHeight);
      handleUpdateOffsetDebounced();
    },
    [handleUpdateOffsetDebounced]
  );

  // the following useEffect update the height because the compoent is loaded before the store update
  useEffect(() => {
    if (mapImage?.height) setHeight(mapImage.height);
  }, [mapImage?.height]);
  useEffect(() => {
    if (mapImage?.x) setOffsetX(mapImage.x / DISPLAY_UNIT_FACTOR);
  }, [mapImage?.x]);
  useEffect(() => {
    if (mapImage?.y) setOffsetY(mapImage.y / DISPLAY_UNIT_FACTOR);
  }, [mapImage?.y]);

  const uploadMapImage = useCallback(
    (file: File) => {
      if (file) {
        const imageURL = window.URL.createObjectURL(file);
        let name = file.name;

        if (
          store.getState().maps.mapImage.mapImages?.length &&
          store.getState().maps.mapImage.mapImages?.some((mapImage) => mapImage.name === name)
        ) {
          const indexOfExtension = name.lastIndexOf('.');
          const fileName = name.substring(0, indexOfExtension);
          const extension = name.substring(indexOfExtension);

          name = `${fileName} t.${new Date().getTime()}${extension}`;
        }

        file.arrayBuffer().then((buffer) => {
          const blob = new Blob([buffer]);
          const image = new Image();
          image.src = URL.createObjectURL(blob);
          image.onload = () => {
            const originalHeight = image.height;
            const originalWidth = image.width;

            dispatch(importMapImageAction({ imageURL, name, originalHeight, originalWidth }));
          };
        });
      }
    },
    [dispatch]
  );

  const onChangeNewImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0]) {
        uploadMapImage(files[0]);
      }
    },
    [uploadMapImage]
  );

  const onChangeReplaceImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0] && mapImage) {
        const file = files[0];
        const imageURL = window.URL.createObjectURL(file);
        const name = file.name;

        dispatch(
          updateMapImagePropertiesAction({
            properties: {
              ...mapImage,
              x: offsetX * DISPLAY_UNIT_FACTOR,
              y: offsetY * DISPLAY_UNIT_FACTOR,
              height: height || 0,
              name,
              URL: imageURL,
            },
            targetName: mapImage.name,
          })
        );

        dispatch(changeMapImageNameFilterAction({ name, targetName: mapImage.name }));
      }
    },
    [dispatch, height, mapImage, offsetX, offsetY]
  );

  const onClickNewImage = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = ''; //clean input to allow second upload of same file
  }, []);

  const triggerClickChangeMapImg = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-call
    (document.querySelector('#import-map-image-input-props') as any)?.click();
  }, []);

  const triggerClickReplaceMapImg = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-call
    (document.querySelector('#replace-map-image-input-props') as any)?.click();
  }, []);

  const mapImageScaling = useAppSelector(
    (state) => state.maps.mapImage?.mapImages?.[openedPropertiesSelector]?.scaling
  );

  return openedPropertiesSelector === undefined || !mapImage?.URL || !mapImage?.height ? (
    <></>
  ) : (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Alert severity="info">While this is open, moving shapes is disabled</Alert>
          <div className={classes.formTitle}>
            <Box
              component="span"
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '400px' }}
              title={mapImage?.name}
            >
              {mapImage?.name || 'Layout Image'}
            </Box>
            <Box component="div">
              <Tooltip title="Delete">
                <IconButton onClick={eraseMapImage} className={classes.marginLeft} size="large">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close Menu">
                <IconButton onClick={closeProperties} className={classes.marginRight} size="large">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </div>

          <TextField
            id="input-x-map-properties"
            className={classes.formElement}
            value={offsetXtmp === null ? offsetX : offsetXtmp}
            onChange={handleChangeX}
            onBlur={handleBlurX}
            fullWidth
            margin="dense"
            type="number"
            label="Abcissa offset (x)"
            inputProps={{
              'aria-label': 'Abcissa offset',
              step: 0.01,
            }}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
            variant="standard"
          />
          <TextField
            id="input-y-map-properties"
            className={classes.formElement}
            value={offsetYtmp || offsetY}
            onChange={handleChangeY}
            onBlur={handleBlurY}
            fullWidth
            margin="dense"
            type="number"
            label="Ordinate offset (y)"
            inputProps={{
              'aria-label': 'Ordinate offset',
              step: 0.01,
            }}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
            variant="standard"
          />
          <TextField
            id="input-height-map-properties"
            className={classes.formElement}
            value={height / 100}
            onChange={handleChangeHeight}
            fullWidth
            margin="dense"
            type="number"
            label="Height"
            inputProps={{
              'aria-label': 'Height',
              step: 0.01,
            }}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
            style={{ display: 'none' }}
            variant="standard"
          />
          <br />
          <div>
            <input
              type="file"
              onChange={onChangeNewImage}
              onClick={onClickNewImage}
              className={classes.inputMapImage}
              id="import-map-image-input-props"
              accept="image/*"
            />
            <label htmlFor="import-map-image-input-props">
              <Button
                variant="contained"
                onClick={triggerClickChangeMapImg}
                color="inherit"
                startIcon={<FileUploadIcon />}
                fullWidth
              >
                Import A New Layout Image
              </Button>
            </label>
          </div>
          <div>
            <Button
              variant="contained"
              color="inherit"
              startIcon={<DisplaySettingsIcon />}
              sx={{ marginTop: theme.spacing(1) }}
              fullWidth
              onClick={() => {
                dispatch(
                  openDialogAction({
                    type: DialogTypes.ImageScaling,
                    payload: (mapImageScaling
                      ? {
                          distance: mapImageScaling.distance,
                          points: mapImageScaling.points,
                        }
                      : undefined) as MapImageScalingSourceData | undefined,
                  })
                );
              }}
            >
              Rescale the Layout Image
            </Button>
          </div>
          <div>
            <input
              type="file"
              onChange={onChangeReplaceImage}
              onClick={onClickNewImage}
              className={classes.inputMapImage}
              id="replace-map-image-input-props"
              accept="image/*"
            />
            <label htmlFor="replace-map-image-input-props">
              <Button
                variant="contained"
                color="inherit"
                onClick={triggerClickReplaceMapImg}
                startIcon={<EditIcon />}
                sx={{ marginTop: theme.spacing(1) }}
                fullWidth
              >
                Replace The Layout Image
              </Button>
            </label>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
