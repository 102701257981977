import store from 'store';
import { getCircuitShapes } from './get-shapes';
import { isCircuitNote } from './shape-guards';

interface AreAllShapeNamesUniqueOptions {
  /**
   * If true, will ignore duplicates before the new names to add.
   */
  ignoreDuplicatesBefore?: boolean;
  /**
   * Will check the stocklines names for stockzones that are in shapesIdsToIgnore
   */
  checkStockLinesStockZonesIgnored?: boolean;
}

/**
 * Checks if all shape names in the circuit are unique.
 * @param newNamesToAdd - Optional array of new names to add.
 * @param shapesIdsToIgnore - Optional array of shape ids to ignore.
 * @param options - Optional options.
 * @param otherNamesToConsider - Optional list of other names to consider in the list names
 * @returns True if all shape names are unique, false otherwise.
 */
export function areAllShapeNamesUnique(
  newNamesToAdd?: string[],
  shapesIdsToIgnore?: string[],
  options: AreAllShapeNamesUniqueOptions = {
    ignoreDuplicatesBefore: false,
    checkStockLinesStockZonesIgnored: true,
  },
  otherNamesToConsider: string[] = []
): boolean {
  const storeState = store.getState();
  const circuitState = storeState.circuit.present;

  const shapesIdsToIgnoreSet = new Set(shapesIdsToIgnore);

  const allShapes = getCircuitShapes();
  const allShapesWithoutNotes = allShapes.filter((shape) => !isCircuitNote(shape)); // we don't mind having notes with the same name
  let allShapesNames = allShapesWithoutNotes
    .filter((shape) => !shapesIdsToIgnoreSet.has(shape.id as string))
    .map((shape) => shape.properties.name)
    .filter((name): name is string => name !== undefined);

  allShapesNames.push(...otherNamesToConsider);

  if (options.ignoreDuplicatesBefore) {
    allShapesNames = Array.from(new Set(allShapesNames));
  }

  const shapeNamesArr = allShapesNames;
  if (newNamesToAdd && newNamesToAdd.length) shapeNamesArr.push(...newNamesToAdd);
  const shapeNamesSet = new Set(shapeNamesArr);

  // first test if there are duplicates
  if (shapeNamesArr.length !== shapeNamesSet.size) {
    let isUnique = false;
    if (options.ignoreDuplicatesBefore && newNamesToAdd && newNamesToAdd.length) {
      let newNameInDuplicates = false;
      const duplicates = shapeNamesArr.filter((name, index) => shapeNamesArr.indexOf(name) !== index);
      for (let i = 0; i < newNamesToAdd.length; i++) {
        const newName = newNamesToAdd[i];
        if (duplicates.includes(newName)) {
          newNameInDuplicates = true;
          break;
        }
      }

      if (!newNameInDuplicates) {
        isUnique = true;
      }
    }

    return isUnique;
  }

  const racksIds = circuitState.racks.ids;
  const racks = circuitState.racks.entities;
  const stockZonesIds = circuitState.stockZones.ids;
  const stockZones = circuitState.stockZones.entities;

  for (let i = 0; i < racksIds.length; i++) {
    const rack = racks[racksIds[i]];
    if (shapesIdsToIgnoreSet.has(rack.id as string)) continue;

    for (let j = 0; j < rack.properties.columns.length; j++) {
      const column = rack.properties.columns[j];

      for (let k = 0; k < column.cells.length; k++) {
        const cell = column.cells[k];

        for (let l = 0; l < cell.names.length; l++) {
          const slots = cell.names[l];
          for (let m = 0; m < slots.length; m++) {
            const slot = slots[m];
            if (shapeNamesSet.has(slot.value)) {
              if (options.ignoreDuplicatesBefore && newNamesToAdd && newNamesToAdd.length) {
                if (newNamesToAdd.includes(slot.value)) {
                  return false;
                }
              } else {
                return false;
              }
            }

            shapeNamesSet.add(slot.value);
          }
        }
      }
    }
  }

  for (let i = 0; i < stockZonesIds.length; i++) {
    const stockZone = stockZones[stockZonesIds[i]];

    if (options.checkStockLinesStockZonesIgnored && shapesIdsToIgnoreSet.has(stockZone.id as string)) continue;

    for (let j = 0; j < stockZone.properties.slots.length; j++) {
      const stockLine = stockZone.properties.slots[j];

      const stockLineName = stockLine.name;

      if (shapesIdsToIgnoreSet.has(stockLine.id)) continue;

      if (shapeNamesSet.has(stockLineName)) {
        if (options.ignoreDuplicatesBefore && newNamesToAdd && newNamesToAdd.length) {
          if (newNamesToAdd.includes(stockLineName)) {
            return false;
          }
        } else {
          return false;
        }
      }

      shapeNamesSet.add(stockLineName);
    }
  }

  return true;
}
