import { updateTurnAction } from 'actions/circuit';
import * as d3 from 'd3';
import { LineString } from 'drawings/elements';
import { getClosestPointInSegment, offsetPositionArray } from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import { gabaritsWorkerProxy } from 'editor/gabarits.worker';
import type { LineString as LineStringGeoJSON } from 'geojson';
import type { TurnProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { lineStrokeSize } from 'models/drawings';
import { Tools } from 'models/tools';
import store from 'store';
import { getGabaritCoordsCached } from 'utils/circuit/get-gabarit-coords';
import { isSegmentAnExtendedLength } from 'utils/circuit/is-segment-an-extended-length';
import { isCircuitTurn } from 'utils/circuit/shape-guards';
import { getConfig } from 'utils/config';

export const showPortionsTurnEventName = 'show-portions';

const octagonCoords = [
  [
    [-2, -4.7],
    [2, -4.7],
    [5, -2],
    [5, 2],
    [2, 5],
    [-2, 5],
    [-4.7, 2],
    [-4.7, -2],
  ],
];

const TURN_STROKE_WIDTH = parseFloat(getConfig('editor').elementWidth.turnStroke);

export interface TurnDrawingProperties {
  strokeSize: lineStrokeSize;
  displayGabarit?: boolean;
}

export function turnStrokeWidthToStrokeWidth(size: lineStrokeSize): number {
  switch (size) {
    case 's':
      return TURN_STROKE_WIDTH;
    case 'm':
      return TURN_STROKE_WIDTH * 2;
    case 'l':
      return TURN_STROKE_WIDTH * 4;
    case 'xl':
      return TURN_STROKE_WIDTH * 8;
  }
}

export class Turn extends LineString {
  public properties: TurnProperties;

  private strokeWidth: number;

  constructor(
    id: string,
    geometry: LineStringGeoJSON,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    properties: TurnProperties,
    drawingProperties: TurnDrawingProperties
  ) {
    super(id, ShapeTypes.TurnShape, geometry, projection, zoomScale);

    this.properties = properties || ({} as TurnProperties);
    this.strokeWidth = turnStrokeWidthToStrokeWidth(drawingProperties.strokeSize);

    const isConnectedToExtendedLength =
      (this.properties.originId && isSegmentAnExtendedLength(this.properties.originId)) ||
      (this.properties.destinationId && isSegmentAnExtendedLength(this.properties.destinationId));

    if (properties?.drivenBy === 'StartPoint' && !isConnectedToExtendedLength) {
      this.node = this.showDragRef(this.node);
    }

    const displayGabarit = drawingProperties?.displayGabarit !== false;
    if (this.properties && this.properties.gabarit && this.properties.gabarit.display && displayGabarit) {
      this.showGabarit();
    }

    if (isConnectedToExtendedLength) {
      this.node.attr('extended-length-turn', true);
    }

    if (this.properties && this.properties.turnType === 'StopBeforeTurn') {
      this.showStopBeforeTurn();
    }

    this.node.attr('layer-id', properties.layerId);
    this.node.select('[main-shape]').style('stroke-width', this.strokeWidth);

    this.showTrafficPortions();
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    // this.geometry.coordinates = this.geometry.coordinates.filter((coord) => coord);

    const node = super
      .createNode()
      .style('stroke-width', getConfig('editor').elementWidth.turnStroke)
      .style('pointer-events', 'stroke');

    return node;
  }

  public async showGabarit(): Promise<void> {
    const modelName = this.properties.gabarit?.modelName;
    const gabaritName = this.properties.gabarit?.type;

    if (!modelName || !gabaritName) return;

    const geometry = store.getState().circuit.present.turns.entities[this.id]?.geometry ?? this.geometry;

    const turn = {
      id: this.id,
      geometry,
      properties: this.properties,
    };
    if (!isCircuitTurn(turn)) {
      // eslint-disable-next-line no-console
      console.error(`Turn ${this.id} is not a circuit turn`);

      return;
    }

    const gabaritCoords = getGabaritCoordsCached(gabaritName, modelName);
    if (!gabaritCoords) return;

    const gabarit = await gabaritsWorkerProxy.generateTurnGabarit({
      turn,
      gabaritName,
      modelName,
      gabaritCoords,
    });
    if (!gabarit || !gabarit.geometry) return;

    this.node
      .append('svg:defs')
      .append('svg:path')
      .attr('id', `gabarit-${this.id}`)
      .data([gabarit.geometry])
      .attr('d', this.projection as any)
      .classed('gabarit', true)
      .exit();
  }

  public setZoomScale(zoomScale: number): void {
    super.setZoomScale(zoomScale);
  }

  protected onDrag(): void {
    return;
  }

  protected onDragEnd(): void {
    return;
  }

  private showStopBeforeTurn(): void {
    const isPerformanceModeEnabled = store.getState().editor.performanceMode;
    if (isPerformanceModeEnabled) return; // we do not show the stop before turn in performance mode

    const center = this.geometry.coordinates[Math.floor(this.geometry.coordinates.length / 2)];
    const coords = offsetPositionArray(octagonCoords, center[0], center[1]);

    this.node
      .append<SVGElement>('svg:polygon')
      .attr('points', coords[0].toString())
      .classed('stop-before-turn-icon', true);
  }

  private showDragRef(
    node: SimpleSelection<SVGGElement, undefined>,
    show = true
  ): SimpleSelection<SVGGElement, undefined> {
    if (!show) return node;

    const dragHandler = d3
      .drag<SVGGElement, undefined>()
      .on('drag', (e, d, n) => this.onDragRef(e, d, n))
      .on('end', (e, d, n) => this.onDragRefEnd(e, d, n));

    node
      .append<SVGElement>('svg:circle')
      .attr('id', `turn-drag-${this.id}`)
      .attr('cx', this.geometry.coordinates[0][0])
      .attr('cy', -this.geometry.coordinates[0][1])
      .attr('r', 4)
      .attr('stroke', 'black')
      .attr('stroke-width', 0.2)
      .attr('fill', 'green')
      //.style('cursor', 'grab')
      .style('opacity', '0.8')
      .style('pointer-events', 'fill')
      .call(dragHandler as any)
      .exit();

    return node;
  }

  protected onDragRef(e: undefined, d: number, nodes: SVGGElement[] | d3.ArrayLike<SVGGElement>): void {
    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    const stateStore = store.getState();

    if (stateStore.tool.activeTool !== Tools.Move) return;

    const dragElem = d3.select(nodes[0]);

    const segmentId = (this.properties || ({} as TurnProperties)).originId;
    if (!segmentId) {
      // eslint-disable-next-line no-console
      console.error(`No segment id for turn ${this.id}`);

      return;
    }

    const segment = stateStore.circuit.present.segments.entities[segmentId];
    const coordsSegment = segment.geometry.coordinates;

    const coordsEvent = [event.x, -event.y];
    const [snappedCoord, positionOnSegment] = getClosestPointInSegment(
      coordsEvent,
      coordsSegment[0],
      coordsSegment[1],
      !!segment.properties.locked
    );

    dragElem.attr('cx', snappedCoord[0].toString()).attr('cy', (-snappedCoord[1]).toString());

    super.onDrag();

    const performanceModeEnabled = stateStore.editor.performanceMode;
    if (!performanceModeEnabled && !isNaN(positionOnSegment)) {
      /**
       * Real time preview when you drag a u-turn
       */
      const node = this.node.node();
      if (!node) return;

      const mainShape = node.querySelector('[main-shape]');
      if (!mainShape) return;

      const [dx, dy] = [
        snappedCoord[0] - this.geometry.coordinates[0][0],
        snappedCoord[1] - this.geometry.coordinates[0][1],
      ];
      mainShape.setAttribute('transform', `translate(${dx},${-dy})`);
    }
  }

  protected onDragRefEnd(e: undefined, d: number, nodes: SVGGElement[] | d3.ArrayLike<SVGGElement>): void {
    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    const stateStore = store.getState();

    if (stateStore.tool.activeTool !== Tools.Move) return;

    const segmentId = this.properties.originId;

    if (!segmentId) {
      // eslint-disable-next-line no-console
      console.error(`No origin id for turn ${this.id}`);

      return;
    }

    const segment = stateStore.circuit.present.segments.entities[segmentId];
    const coordsSegment = segment.geometry.coordinates;

    const coordsEvent = [event.x, -event.y];
    const [, positionOnSegment] = getClosestPointInSegment(
      coordsEvent,
      coordsSegment[0],
      coordsSegment[1],
      !!segment.properties.locked
    );

    if (isNaN(positionOnSegment)) {
      // eslint-disable-next-line no-console
      console.error(`No position on segment for turn ${this.id}`);

      return;
    }

    store.dispatch(
      updateTurnAction({
        idToUpdate: this.id,
        positionFactorOrigin: positionOnSegment,
      })
    );
  }

  /**
   * Display the traffic portion of the turn
   * Given that it is a low priority, the task is scheduled with a requestIdleCallback
   * Only portions with a trafficType different from Auto are displayed
   */
  private showTrafficPortions(): void {
    const turn = store.getState().circuit.present.turns.entities[this.id];
    const trafficType = turn?.properties?.trafficType;

    if (
      trafficType === 'kernel' ||
      trafficType === 'deadend' ||
      trafficType === 'deadend-entry' ||
      trafficType === 'deadend-exit'
    ) {
      if (trafficType) this.node.select('[main-shape]').classed(`portion-${trafficType}`, true);
    }
  }
}
