/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { clearMapImageAction } from 'actions';
import * as d3 from 'd3';
import { MapScalingDrawing, zoomPointerMapScaling } from 'drawings/map-scaling/map-scaling.drawing';
import { useYupValidation } from 'hooks';
import { type MapImageScalingSourceData } from 'models/maps';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { removeMapImageFilterAction } from 'reducers/local/filters.reducer';
import store from 'store';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { schema } from './schema';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {},
    formElement: {
      width: 'auto',
      margin: theme.spacing(1),
    },
    modalStep: {
      margin: theme.spacing(1),
      fontWeight: 500,
    },
    drawContainer: {
      height: '400px',
      width: 'auto',
      overflow: 'hidden',
    },
    errorWrapper: {
      maxWidth: '100%',
      margin: `${theme.spacing(1)} auto`,
      background: 'rgba(255,0,0,0.2)',
      color: 'red',
      padding: theme.spacing(1),
      borderRadius: '2px',
      fontSize: '11px',
      border: '1px solid #ff00003b',
    },
  })
);

export interface DialogTakeOveMonitoringUserProps {
  imageURL?: string;
  closeDialog: () => void;
  setImageHeight: (imageHeight: number, name: string, scaling?: MapImageScalingSourceData) => void;
  initialScaling?: MapImageScalingSourceData;
}

interface FormValueProps {
  distance: string;
}

export type DefiningType = 'width' | 'height' | 'custom';

export function DialogImageScalingComponent({
  imageURL,
  closeDialog,
  setImageHeight,
  initialScaling,
}: DialogTakeOveMonitoringUserProps): JSX.Element | null {
  const classes = useStyles();

  const elementId = 'map-image-scaling-root-node';

  const name = store.getState().maps.mapImage.mapImages?.[store.getState().maps.mapImage.openProperties ?? 0]
    .name as string;
  const [imageWidth, setImageWidth] = useState<number | undefined>();
  const [definingType, setDefiningType] = useState<DefiningType>('custom');

  const drawingEl = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  const [formValue, setFormValue] = useState<FormValueProps>({ distance: initialScaling?.distance.toString() ?? '' });
  const [yupValidation, isFormValid] = useYupValidation(schema, formValue);
  const [isError, setIsError] = useState<boolean>(false);

  const [imgLoaded, setImgLoaded] = useState(false);

  const drawing = useMemo(() => {
    if (!mounted || drawingEl.current === null) {
      return null;
    }

    return new MapScalingDrawing(drawingEl.current, setDefiningType);
  }, [mounted]);

  const positionReset = useCallback(
    async (duration = 1000): Promise<void> => {
      if (zoomPointerMapScaling) {
        const el = document.getElementById(elementId);
        if (!el) {
          // eslint-disable-next-line no-console
          console.error(`Element with id ${elementId} not found`);

          return;
        }

        const dateStartLoading = new Date().getTime();
        const imgLoadingTimeout = 30 * 1000;
        while (!drawing?.getImageHeight()) {
          // we wait for the image to be loaded before computing its scaling
          if (Date.now() - dateStartLoading > imgLoadingTimeout)
            throw new Error(`Image ${elementId} not loaded, aborting`);

          await new Promise((resolve) => setTimeout(resolve, 100));
        }

        setImgLoaded(true);

        const viewHeight = el?.clientHeight ?? 1;
        const viewWidth = el?.clientWidth ?? 1;
        const drawingHeight = drawing?.getImageHeight() ?? 1;
        const drawingWidth = drawing?.getImageWidth() ?? 1;
        const scaling =
          viewHeight / drawingHeight > viewWidth / drawingWidth ? viewWidth / drawingWidth : viewHeight / drawingHeight;

        d3.select('#map-image-scaling-root-node')
          .transition()
          .duration(duration)
          .call(zoomPointerMapScaling.transform as any, d3.zoomIdentity.scale(scaling));
      }
    },
    [drawing]
  );

  useEffect(() => {
    // if we have an initial scaling, we need initialize the points to these values
    if (drawing && initialScaling) {
      if (!drawing.getPoints()) {
        const points = initialScaling.points;
        if (points.length === 2) {
          drawing.setPoints(points as [number, number][]);
        } else {
          throw new Error('Invalid initial scaling points');
        }
      }
    }
  }, [drawing, initialScaling]);

  useEffect(() => {
    if (imageURL && !imageWidth) {
      const img = new Image();
      img.src = imageURL;
      img.onload = function (event) {
        setImageWidth(img.width);
      };
    }
  }, [imageURL, imageWidth]);

  useEffect(() => {
    if (drawing && imageURL && imageWidth) {
      drawing.drawMapImage(imageURL, imageWidth);
      positionReset(0);
    }
  }, [drawing, imageURL, imageWidth, positionReset]);

  const handleDefineHeight = useCallback(() => {
    if (!drawing || definingType === 'height') return;
    const imageHeight = drawing.getImageHeight();
    if (!imageHeight) return;

    setDefiningType('height');
    drawing.setPoints([
      [0, 0],
      [0, imageHeight],
    ]);
  }, [drawing, definingType]);

  const handleDefineWidth = useCallback(() => {
    if (!drawing || definingType === 'width') return;

    const scaledImageWidth = drawing.getImageWidth();
    if (!scaledImageWidth) return;

    setDefiningType('width');
    drawing.setPoints([
      [0, 0],
      [scaledImageWidth, 0],
    ]);
  }, [drawing, definingType]);

  const onConfirmed = useCallback(() => {
    if (drawing) {
      const heightPixel = drawing.getImageHeight();
      const distanceInPixel = drawing.getPointsDistance();
      if (formValue && heightPixel && distanceInPixel) {
        const distance = parseFloat(formValue.distance);
        const heightMeter = (distance * heightPixel) / distanceInPixel;
        const points = drawing.getPoints();
        const scaling: MapImageScalingSourceData | undefined = points
          ? {
              distance,
              points,
            }
          : undefined;

        setImageHeight(heightMeter, name, scaling);
        closeDialog();
      } else {
        setIsError(() => true);
      }
    }
  }, [closeDialog, formValue, setImageHeight, drawing, name]);

  const onMeterChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setFormValue((state) => ({
        distance: event.target.value,
      }));

      d3.select('.length-measurer-text').text(`${event.target.value}m`);
    },
    [setFormValue]
  );

  const onUseRef = useCallback(
    (el: HTMLDivElement | null) => {
      if (el) {
        drawingEl.current = el;
        setMounted(true);
      }
    },
    [setMounted]
  );

  const translateLayoutImageScalingView = useCallback((x: number, y: number): void => {
    if (!zoomPointerMapScaling) return;

    const root = d3.select('#map-image-scaling-main-node');
    const rootNode = root.node();

    if (!(rootNode instanceof SVGGraphicsElement)) {
      // eslint-disable-next-line no-console
      console.error('rootNode is not an instance of SVGGraphicsElement');

      return;
    }

    const transform = d3.zoomTransform(rootNode);

    d3.select('#map-image-scaling-root-node').call(
      zoomPointerMapScaling.transform as any,
      d3.zoomIdentity.translate(transform.x + x, transform.y + y).scale(transform.k)
    );
  }, []);

  useHotkeys(
    'left',
    (event, hotkeysEvent): void => {
      translateLayoutImageScalingView(getConfig('editor').zoom.translateArrowKeys, 0);
    },
    []
  );

  useHotkeys(
    'right',
    (event, hotkeysEvent): void => {
      translateLayoutImageScalingView(-getConfig('editor').zoom.translateArrowKeys, 0);
    },
    []
  );

  useHotkeys(
    'down',
    (event, hotkeysEvent): void => {
      translateLayoutImageScalingView(0, -getConfig('editor').zoom.translateArrowKeys);
    },
    []
  );

  useHotkeys(
    'up',
    (event, hotkeysEvent): void => {
      translateLayoutImageScalingView(0, getConfig('editor').zoom.translateArrowKeys);
    },
    []
  );

  const isLoading = imageWidth === undefined || !imgLoaded;

  return (
    <Dialog
      open={true}
      onClose={() => {
        store.dispatch(clearMapImageAction({ name }));
        store.dispatch(removeMapImageFilterAction({ name }));
        closeDialog();
      }}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onContextMenu={(e) => e.preventDefault()}
      fullWidth
    >
      <DialogTitle>
        <>
          Layout Image Scaling
          <Tooltip title="Reset zoom">
            <IconButton onClick={() => positionReset()}>
              <ZoomOutMapIcon />
            </IconButton>
          </Tooltip>
        </>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box component="div" sx={{ display: 'flex', columnGap: theme.spacing(1), paddingBottom: theme.spacing(2) }}>
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
            <h4 className={classes.modalStep} id="alert-dialog-title">
              1. Chose two points on your map or position the point as the image
            </h4>
            <Typography variant="body2" color="textSecondary">
              <b>[Shift] + Drag</b> Align the dragged point with the other point
            </Typography>
          </Box>
          <ButtonGroup>
            <Button variant={definingType === 'height' ? 'contained' : 'outlined'} onClick={handleDefineHeight}>
              height
            </Button>
            <Button variant={definingType === 'width' ? 'contained' : 'outlined'} onClick={handleDefineWidth}>
              width
            </Button>
          </ButtonGroup>
        </Box>

        {isLoading && <Typography variant="caption">Loading...</Typography>}

        <div className={classes.drawContainer} ref={(el) => onUseRef(el)} />

        <h4 className={classes.modalStep}>2. Enter the distance between the two points</h4>
        <TextField
          {...yupValidation.distance}
          className={classes.formElement}
          value={formValue.distance}
          onChange={onMeterChangeHandler}
          fullWidth
          margin="normal"
          label="Distance in meter"
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          inputProps={{ autoComplete: 'off' }}
          id="layout-image-distance"
        />
        {isError && (
          <div className={classes.errorWrapper}>
            <u>To submit, please chose two points on the map</u>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirmed} color="primary" variant="contained" disabled={!isFormValid}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
