import { debounce } from 'lodash';
import type { Tools } from 'models/tools';
import type { ToolState } from 'reducers/tools';
import type { Action } from 'redux';
import store from 'store';

export enum ToolActionTypes {
  SelectTool = '[Tool] Select Tool',
  Select90DegSnap = '[Tool] Select 90 degrees snap',
  UpdateMapOpacity = '[Tool] Update map opacity',
  UpdateObstacleOpacity = '[Tool] Update obstacle map opacity',
  SelectPreviousTool = '[Tool] Select Previous Tool',
  UpdateSelectStrokeWidth = '[Tool] Update select stroke width',
  UpdatePointsSize = '[Tool] Update points size',
  UpdateLineStrokeSize = '[Tool] Update line stroke size',
  ChangeReturnToMoveTool = '[Tool] Change return to move tool rule',
  ChangeStateHightlightTurns = '[Tool] Change state highlight turns',
  ToggleStateHightlightTurns = '[Tool] Toggle state highlight turns',
  ChangeStateLockOrientation = '[Tool] Change state lock orientation',
  ChangeAlwaysDisplayCommon = '[Tool] Change always display common',
  ChangeDisplayBBoxSelection = '[Tool] Change display bbox selection',
  UpdateTools = '[Tool] Update tools',
  UpdateToolsAuth = '[Tool] Update tools auth',
  UpdateZoomLevel = '[Tool] Update zoom level',
  UpdateGridCoordinate = '[Tool] Update the coordinate of the grid to display the good number of tiles',
}

export enum SecondaryToolActionTypes {
  SelectSecondaryTool = '[Tool] Select secondary tool',
}

export interface SelectTool extends Action {
  type: ToolActionTypes.SelectTool;
  payload: { toolName?: Tools };
}

export function selectToolAction(payload: SelectTool['payload']): SelectTool {
  return { type: ToolActionTypes.SelectTool, payload };
}

export interface SelectPreviousTool extends Action {
  type: ToolActionTypes.SelectPreviousTool;
  payload?: undefined;
}
export function selectPreviousTool(): SelectPreviousTool {
  return { type: ToolActionTypes.SelectPreviousTool };
}

export interface ChangeReturnToMoveTool extends Action {
  type: ToolActionTypes.ChangeReturnToMoveTool;
  payload: { returnToMoveTool: ToolState['returnToMoveTool'] };
}
export function changeReturnToMoveTool(payload: ChangeReturnToMoveTool['payload']): ChangeReturnToMoveTool {
  return { type: ToolActionTypes.ChangeReturnToMoveTool, payload };
}

export interface ChangeStateHightlightTurns extends Action {
  type: ToolActionTypes.ChangeStateHightlightTurns;
  payload: { highlightTurns: ToolState['highlightTurns'] };
}
export function changeStateHightlightTurns(payload: ChangeStateHightlightTurns['payload']): ChangeStateHightlightTurns {
  return { type: ToolActionTypes.ChangeStateHightlightTurns, payload };
}

export interface ToggleStateHightlightTurns extends Action {
  type: ToolActionTypes.ToggleStateHightlightTurns;
  payload: undefined;
}
export function toggleStateHightlightTurns(): ToggleStateHightlightTurns {
  return { type: ToolActionTypes.ToggleStateHightlightTurns, payload: undefined };
}

export interface ChangeLockOrientation extends Action {
  type: ToolActionTypes.ChangeStateLockOrientation;
  payload: { lockOrientation: ToolState['lockOrientation'] };
}

export function changeLockOrientation(payload: ChangeLockOrientation['payload']): ChangeLockOrientation {
  return { type: ToolActionTypes.ChangeStateLockOrientation, payload };
}

export interface ChangeAlwaysDisplayCommon extends Action {
  type: ToolActionTypes.ChangeAlwaysDisplayCommon;
  payload: { alwaysDisplayCommon: ToolState['alwaysDisplayCommon'] };
}

export function changeAlwaysDisplayCommon(payload: ChangeAlwaysDisplayCommon['payload']): ChangeAlwaysDisplayCommon {
  return { type: ToolActionTypes.ChangeAlwaysDisplayCommon, payload };
}

export interface ChangeDisplayBBoxSelection extends Action {
  type: ToolActionTypes.ChangeDisplayBBoxSelection;
  payload: { displayBBoxSelection: ToolState['displayBBoxSelection'] };
}

export function changeDisplayBBoxSelection(payload: ChangeDisplayBBoxSelection['payload']): ChangeDisplayBBoxSelection {
  return { type: ToolActionTypes.ChangeDisplayBBoxSelection, payload };
}

export interface UpdateTools extends Action {
  type: ToolActionTypes.UpdateTools;
  payload: {
    circuitTools?: ToolState['circuitTools'];
    flowTools?: ToolState['flowTools'];
    trafficTools?: ToolState['trafficTools'];
  };
}

export function updateTools(payload: UpdateTools['payload']): UpdateTools {
  return { type: ToolActionTypes.UpdateTools, payload };
}

export interface UpdateToolsAuth extends Action {
  type: ToolActionTypes.UpdateToolsAuth;
  payload: { circuitTools?: ToolState['circuitTools']; flowTools?: ToolState['flowTools'] };
}

export function updateToolsAuth(payload: UpdateToolsAuth['payload']): UpdateToolsAuth {
  return { type: ToolActionTypes.UpdateToolsAuth, payload };
}

export interface UpdateZoomLevel extends Action {
  type: ToolActionTypes.UpdateZoomLevel;
  payload: { zoomLevel?: ToolState['zoomLevel'] };
}

export function updateZoomLevel(payload: UpdateZoomLevel['payload']): UpdateZoomLevel {
  return { type: ToolActionTypes.UpdateZoomLevel, payload };
}

export interface UpdateGridCoordinate extends Action {
  type: ToolActionTypes.UpdateGridCoordinate;
  payload: { gridCoordinate: ToolState['gridCoordinate'] };
}

export function updateGridCoordinateAction(payload: UpdateGridCoordinate['payload']): UpdateGridCoordinate {
  return { type: ToolActionTypes.UpdateGridCoordinate, payload };
}

export function updateGridCoordinate(payload: UpdateGridCoordinate['payload']): UpdateGridCoordinate {
  return store.dispatch(updateGridCoordinateAction(payload));
}

export const updateGridCoordinateThrottled = debounce(updateGridCoordinate, 500, {
  trailing: true,
  maxWait: 2000,
});

export type ToolActions =
  | SelectTool
  | Change90degSnap
  | SelectSecondaryTool
  | UpdateMapOpacity
  | UpdateObstacleOpacity
  | SelectPreviousTool
  | UpdateSelectStrokeWidth
  | UpdatePointsSize
  | ChangeReturnToMoveTool
  | UpdateLineStrokeSize
  | ChangeStateHightlightTurns
  | ToggleStateHightlightTurns
  | ChangeLockOrientation
  | ChangeAlwaysDisplayCommon
  | ChangeDisplayBBoxSelection
  | UpdateTools
  | UpdateToolsAuth
  | UpdateZoomLevel
  | UpdateGridCoordinate;

export interface Change90degSnap extends Action {
  type: ToolActionTypes.Select90DegSnap;
  payload: { snap90deg?: boolean };
}

export function change90DegSnapAction(payload: Change90degSnap['payload']): Change90degSnap {
  return { type: ToolActionTypes.Select90DegSnap, payload };
}

export interface SelectSecondaryTool extends Action {
  type: SecondaryToolActionTypes;
  payload: {
    turnType?: 'Normal' | 'StopBeforeTurn' | 'Tesseract';
    zoneType?: 'Rectangle' | 'Triangle';
    stockZoneType?: 'Conveyor' | 'StockZone' | 'StackZone';
  };
}

export function selectSecondaryToolAction(payload: SelectSecondaryTool['payload']): SelectSecondaryTool {
  return { type: SecondaryToolActionTypes.SelectSecondaryTool, payload };
}

export interface UpdateMapOpacity extends Action {
  type: ToolActionTypes.UpdateMapOpacity;
  payload: { mapOpacity: number };
}

export function updateMapOpacityAction(payload: UpdateMapOpacity['payload']): UpdateMapOpacity {
  return { type: ToolActionTypes.UpdateMapOpacity, payload };
}

export interface UpdateObstacleOpacity extends Action {
  type: ToolActionTypes.UpdateObstacleOpacity;
  payload: { mapObstacleOpacity: number };
}

export function updateObstacleOpacityAction(payload: UpdateObstacleOpacity['payload']): UpdateObstacleOpacity {
  return { type: ToolActionTypes.UpdateObstacleOpacity, payload };
}

export interface UpdateSelectStrokeWidth extends Action {
  type: ToolActionTypes.UpdateSelectStrokeWidth;
  payload: { selectStrokeWidth: number };
}

export function updateSelectStrokeWidthAction(payload: UpdateSelectStrokeWidth['payload']): UpdateSelectStrokeWidth {
  return { type: ToolActionTypes.UpdateSelectStrokeWidth, payload };
}

export interface UpdatePointsSize extends Action {
  type: ToolActionTypes.UpdatePointsSize;
  payload: { pointsSize: string };
}

export function updatePointsSizeAction(payload: UpdatePointsSize['payload']): UpdatePointsSize {
  return { type: ToolActionTypes.UpdatePointsSize, payload };
}

export interface UpdateLineStrokeSize extends Action {
  type: ToolActionTypes.UpdateLineStrokeSize;
  payload: { lineStrokeSize: string };
}

export function updateLineStrokeSizeAction(payload: UpdateLineStrokeSize['payload']): UpdateLineStrokeSize {
  return { type: ToolActionTypes.UpdateLineStrokeSize, payload };
}
