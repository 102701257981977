import center from '@turf/center';
import ecStat from 'echarts-stat';
import { getSlotCoordinates } from 'flows/get-slot-coordinates';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/utils';
import { memoize } from 'moderndash';
import store from 'store';
import { getAllRackPositions } from 'utils/circuit/racks';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import type { LinearRegressionResult } from './chart-measures';
import type { RobotMeasures, RobotsMeasuresInfo, SlotsWeight } from './models';

/* Top offset value to apply to each table cell of the second row for sticky header */
export const secondRowStickyHeaderTopOffset = '37px';

/**
 * Convert a meter value to a millimeter value
 * @param meter Value in meter
 * @returns Value in millimeter
 */
export function convertToDisplayUnitRackAnalysis(meter: number): number {
  return Math.round(meter * 1000);
}

/**
 * Give a color of criticity to the measure
 * @param value in number
 * @param tolerance in number
 * @returns String that represent a color
 */
export function getMeasureColor(value: number, tolerance: number): string {
  const absoluteValue = Math.abs(value);

  if (absoluteValue > tolerance * 0.5) return theme.palette.error.main;
  if (absoluteValue > tolerance * 0.25) return theme.palette.warning.light;

  return 'auto';
}

/**
 * Convert a CSV date string to a timestamp
 * @param date - The date string in the format YYYYMMDDHHmmssSSS
 * @returns The timestamp representation of the date string
 */
export function convertCsvDateToTimestampRackAnalysis(date: string): number {
  const year = +date.substring(0, 4);
  const month = +date.substring(4, 6) - 1; // Months are zero-based in JavaScript
  const day = +date.substring(6, 8);
  const hours = +date.substring(9, 11);
  const minutes = +date.substring(11, 13);
  const seconds = +date.substring(13, 15);
  const milliseconds = +date.substring(15);

  return new Date(year, month, day, hours, minutes, seconds, milliseconds).getTime();
}

/**
 * Convert a CSV string outputed by the Balyo rack analysis tool to js object
 * @param data - The CSV string
 * @returns The js representation of the CSV string
 */
export function rackAnalysisCSVToJson(data: string): Record<string, string>[] {
  let delimiter = ',';

  // Check if the ';' delimiter exists in the data
  if (data.includes(';')) {
    delimiter = ';';
  }

  const titles = data.slice(0, data.indexOf('\n')).split(delimiter);

  // Custom function to handle splitting quoted values (ex: "(22.2,20.1)") as we don't want to split on the comma inside the quotes
  const splitWithQuotes = (input: string, separator: string): string[] => {
    const result: string[] = [];
    let withinQuotes = false;
    let currentItem = '';

    for (let i = 0; i < input.length; i++) {
      const currentChar = input[i];

      if (currentChar === '"') {
        withinQuotes = !withinQuotes;
      } else if (currentChar === separator && !withinQuotes) {
        result.push(currentItem.trim());
        currentItem = '';
      } else {
        currentItem += currentChar;
      }
    }

    result.push(currentItem.trim());

    return result;
  };

  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map((v) => {
      const values = splitWithQuotes(v, delimiter);

      const obj: Record<string, string> = {};
      titles.forEach((title, index) => {
        obj[title] = values[index];
      });

      return obj;
    });
}

/**
 * Sort function to sort the rack analysis measures by rack name, slot name and robot name
 * @param a - The first row to compare
 * @param b - The second row to compare
 * @returns -1 if a should come before b, 1 if b should come before a, 0 if they are equal
 */
export function rackAnalysisMeasureSort(a: RobotMeasures[0], b: RobotMeasures[0]): number {
  // Compare by Rack Name
  const rackComparison = a['Rack Name'].localeCompare(b['Rack Name']);
  if (rackComparison !== 0) {
    return rackComparison;
  }

  // If Rack Name is the same, compare by Slot Name
  const slotComparison = a['Slot Name'].localeCompare(b['Slot Name']);
  if (slotComparison !== 0) {
    return slotComparison;
  }

  // If Slot Name is also the same, compare by Robot Name
  return a['Serial'].localeCompare(b['Serial']);
}

/**
 * Get the weight of each slot based on the number of robot that measured it
 * @param robotsMeasuresInfo - The measures info of each robot (disabled or not, measures, slots count)
 * @returns The weight of each slot
 */
export function getSlotsWeight(robotsMeasuresInfo: RobotsMeasuresInfo): SlotsWeight {
  const slotWeight: SlotsWeight = {};

  Object.values(robotsMeasuresInfo).forEach((robot) => {
    if (robot.disabled) return;

    const alreadyWeightedSlot = {};

    robot.measures.forEach((measure) => {
      const slotName = measure?.['Slot Name'];
      if (alreadyWeightedSlot[slotName]) return;

      alreadyWeightedSlot[slotName] = true;

      if (!slotWeight[slotName]) {
        slotWeight[slotName] = 0;
      }

      slotWeight[slotName] += 1;
    });
  });

  return slotWeight;
}

/**
 * Get the mean of the measures of each robot
 * @param robotsMeasuresInfo - The measures info of each robot (disabled or not, measures, slots count)
 * @param slotsWeight - The weight of each slot based on the number of robot that measured it
 * @returns The mean of the measures in (x, y, z) of each robot
 */
export function getRobotMeasuresMean(
  measures: RobotMeasures,
  slotsWeight: SlotsWeight
): { x: number; y: number; z: number } {
  const temp = {
    x: { value: 0, count: 0 },
    y: { value: 0, count: 0 },
    z: { value: 0, count: 0 },
  };

  measures.forEach((measure) => {
    const slotName = measure?.['Slot Name'];

    const weight = slotsWeight?.[slotName] || 1;

    const errorInX = +measure?.['Offset on beam position (X)'];
    const errorInY = +measure?.['Offset on Reference position (Y)'];
    const errorInZ = +measure?.['Offset on beam height (Z)'];

    const errorInXIsNumber = errorInX !== 0 && !isNaN(errorInX);
    const errorInYIsNumber = errorInY !== 0 && !isNaN(errorInY);
    const errorInZIsNumber = errorInZ !== 0 && !isNaN(errorInZ);

    if (!errorInXIsNumber && !errorInZIsNumber) {
      // eslint-disable-next-line no-console
      console.log('Error in X and Z are not numbers, skipping...');

      return;
    }

    if (!errorInXIsNumber) {
      // eslint-disable-next-line no-console
      console.warn(`Error in X is not a number (${errorInX})`);
    } else {
      temp.x.value += errorInX * weight;
      temp.x.count += weight;
    }

    if (!errorInYIsNumber) {
      // eslint-disable-next-line no-console
      console.warn(`Error in Y is not a number (${errorInY})`);
    } else {
      temp.y.value += errorInY * weight;
      temp.y.count += weight;
    }

    if (!errorInZIsNumber) {
      // eslint-disable-next-line no-console
      console.warn(`Error in Z is not a number (${errorInZ})`);
    } else {
      temp.z.value += errorInZ * weight;
      temp.z.count += weight;
    }
  });

  const mean = {
    x: temp.x.value && temp.x.count ? temp.x.value / temp.x.count : 0,
    y: temp.y.value && temp.y.count ? temp.y.value / temp.y.count : 0,
    z: temp.z.value && temp.z.count ? temp.z.value / temp.z.count : 0,
  };

  return mean;
}

/**
 * Get the standard deviation of the measures of each robot
 * @param robotsMeasuresInfo - The measures info of each robot (disabled or not, measures, slots count)
 * @param slotsWeight - The weight of each slot based on the number of robot that measured it
 * @param mean - The mean of the measures in (x, y, z) of each robot
 * @returns The standard deviation of the measures in (x, y, z) of each robot
 */

export function getRobotMeasuresStandardDeviation(
  measures: RobotMeasures,
  slotsWeight: SlotsWeight,
  mean: { x: number; y: number; z: number }
): { x: number; y: number; z: number } {
  const temp = {
    x: { value: 0, count: 0 },
    y: { value: 0, count: 0 },
    z: { value: 0, count: 0 },
  };

  measures.forEach((measure) => {
    const slotName = measure?.['Slot Name'];

    const weight = slotsWeight?.[slotName] || 1;

    const errorInX = +measure?.['Offset on beam position (X)'];
    const errorInY = +measure?.['Offset on Reference position (Y)'];
    const errorInZ = +measure?.['Offset on beam height (Z)'];

    const errorInXIsNumber = errorInX !== 0 && !isNaN(errorInX);
    const errorInYIsNumber = errorInY !== 0 && !isNaN(errorInY);
    const errorInZIsNumber = errorInZ !== 0 && !isNaN(errorInZ);

    if (!errorInXIsNumber && !errorInZIsNumber) {
      // eslint-disable-next-line no-console
      console.warn('Error in X and Z are not numbers');

      return;
    }

    if (!errorInXIsNumber) {
      // eslint-disable-next-line no-console
      console.warn(`Error in X is not a number (${errorInX})`);
    } else {
      temp.x.value += (errorInX - mean.x) ** 2;
      temp.x.count += weight;
    }

    if (!errorInYIsNumber) {
      // eslint-disable-next-line no-console
      console.warn(`Error in Y is not a number (${errorInY})`);
    } else {
      temp.y.value += (errorInY - mean.y) ** 2;
      temp.y.count += weight;
    }

    if (!errorInZIsNumber) {
      // eslint-disable-next-line no-console
      console.warn(`Error in Z is not a number (${errorInZ})`);
    } else {
      temp.z.value += (errorInZ - mean.z) ** 2;
      temp.z.count += weight;
    }
  });

  const sd = {
    x: temp.x.value && temp.x.count ? Math.sqrt(temp.x.value / temp.x.count) : 0,
    y: temp.y.value && temp.y.count ? Math.sqrt(temp.y.value / temp.y.count) : 0,
    z: temp.z.value && temp.z.count ? Math.sqrt(temp.z.value / temp.z.count) : 0,
  };

  return sd;
}

const getAllRackPositionsMemoized = memoize(getAllRackPositions, {
  ttl: 100,
});

interface DataForLinearRegression {
  dataList: RobotMeasures;
  rackName?: string;
}

export type MeasureDataPointWithId = [number, number, string];

interface ComputedLinearRegressionFromMeasures {
  /** Minimum value in X for expected points [m] */
  minExpectedX: number;
  /** Maximum value in X for expected points [m] */
  maxExpectedX: number;
  /** Minimum value in Y for expected points [m] */
  minExpectedY: number;
  /** Maximum value in Y for expected points [m] */
  maxExpectedY: number;
  /** Expected measures with their id */
  expectedMeasures: MeasureDataPointWithId[];
  /** Actual measures with their id */
  actualMeasures: MeasureDataPointWithId[];
  /** Linear regression result for the actual measures */
  linearRegressionActualData: LinearRegressionResult;
  /** Delta rack angle in degrees */
  deltaRackAngleRad: number;
  /** Data points */
  dataPoints: DataPoint[];
  /** Delta X */
  deltaX: number;
}

interface DataPoint {
  id: string;
  date: string;
  slotName: string;
  slotId: string;
  expectedX: number;
  expectedY: number;
  actualX: number;
  actualY: number;
  errorInX: number;
  errorInY: number;
  serial: string;
  rackName: string;
  rackLength: number;
}

export function computeLinearRegressionFromMeasures(
  props: DataForLinearRegression
): ComputedLinearRegressionFromMeasures {
  const { dataList, rackName: rackNameToProcess } = props;

  const racks = store.getState().circuit.present.racks.entities;

  const allRacksPositions = getAllRackPositionsMemoized();

  const { dataPoints, rackLength } = (() => {
    const newDataPoints: (DataPoint | null)[] = dataList
      .filter((v) => {
        if (!rackNameToProcess) return true;

        const rackName = v['Rack Name'];

        return rackName === rackNameToProcess;
      })
      .map((v) => {
        const slotName = v['Slot Name'];

        const slot =
          slotName &&
          allRacksPositions.find((rackPosition) => {
            return rackPosition.value === slotName;
          });

        const slotId = slot && slot?.id;

        if (!slotId) {
          // eslint-disable-next-line no-console
          console.warn(`Missing slot id for ${slotName}`);

          return null;
        }

        const slotPosition = getSlotCoordinates(slotId);

        if (!slotPosition) {
          // eslint-disable-next-line no-console
          console.warn(`Missing slot position for ${slotName} (${slotId})`);

          return null;
        }

        const rack = racks[slot.rackId];
        const rackCenterPoint = center(rack);

        if (!rackCenterPoint) {
          // eslint-disable-next-line no-console
          console.warn(`Failed to compute the  rack center point for ${slotName} (${slotId})`);

          return null;
        }

        const rackDimensions = [
          getDistanceBetweenPoints(rack.geometry.coordinates[0][0], rack.geometry.coordinates[0][1]),
          getDistanceBetweenPoints(rack.geometry.coordinates[0][1], rack.geometry.coordinates[0][2]),
        ];
        const rackLength = Math.max(...rackDimensions) / 100; // [m]

        const pos = slotPosition.pos;
        if (pos === undefined) {
          // eslint-disable-next-line no-console
          console.warn(`Missing slot position for ${slotName} (${slotId})`);

          return null;
        }

        const distance = pos - rackLength / 2;

        const expectedY = distance;
        const expectedX = 0;

        const errorInY = parseFloat(v['Offset on Reference position (Y)']);
        const errorInX = parseFloat(v['Offset on beam position (X)']);
        const date = v.Date;
        const serial = v.Serial;
        const rackName = v['Rack Name'];

        const actualY = expectedY + errorInY;
        const actualX = expectedX + errorInX;

        const id = `${date}-${slotName}-${serial}`;

        return {
          id,
          date,
          slotName,
          slotId,
          expectedX,
          expectedY,
          actualX,
          actualY,
          errorInX,
          errorInY,
          serial,
          rackName,
          rackLength,
        };
      });

    return { dataPoints: newDataPoints, rackLength: newDataPoints[0]?.rackLength };
  })();

  const { minExpectedX, maxExpectedX, minExpectedY, maxExpectedY } = (() => {
    const minExpectedX = Math.min(...dataPoints.map((v) => v?.expectedX).filter(isDefined));
    const maxExpectedX = Math.max(...dataPoints.map((v) => v?.expectedX).filter(isDefined));
    const minExpectedY = -(rackLength ?? 100) / 2;
    const maxExpectedY = (rackLength ?? 100) / 2;

    return { minExpectedX, maxExpectedX, minExpectedY, maxExpectedY };
  })();

  const expectedMeasures = (() => {
    return dataPoints
      .map((dataPoint) => {
        if (!dataPoint) return null;

        return [dataPoint.expectedY, dataPoint.expectedX, dataPoint.id] as MeasureDataPointWithId;
      })
      .filter(isDefined);
  })();

  const actualMeasures = (() => {
    return dataPoints
      .map((dataPoint) => {
        if (!dataPoint) return null;

        return [dataPoint.actualY, dataPoint.actualX, dataPoint.id] as MeasureDataPointWithId;
      })
      .filter(isDefined);
  })();

  const linearRegressionActualData = (() => {
    const pts = actualMeasures.map((v) => [v[0], v[1]]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return (ecStat as any).regression('linear', pts) as LinearRegressionResult;
  })();

  const deltaRackAngleRad = (() => {
    return Math.atan(linearRegressionActualData.parameter.gradient);
  })();

  const deltaX = (() => {
    return linearRegressionActualData.parameter.intercept;
  })();

  return {
    minExpectedX,
    maxExpectedX,
    minExpectedY,
    maxExpectedY,
    expectedMeasures,
    actualMeasures,
    linearRegressionActualData,
    deltaRackAngleRad,
    dataPoints: dataPoints.filter(isDefined),
    deltaX,
  };
}
