import { versionCompare } from './version-compare';

interface IncompatibleVersion {
  /** The version with a known bug */
  bugVersion: string;
  /** The version that fixes the bug */
  fixVersion: string;
}

/**
 * Add an entry in this list to add an incompatible version if there's a known bug
 */
const incompatibleSDKVersions: IncompatibleVersion[] = [
  {
    bugVersion: '4.12.4.1',
    fixVersion: '4.12.4.2',
  },
];

/**
 * Check if a version is incompatible with the current version
 * @param currentVersion the current version of the SDK
 * @returns wether the version is incompatible or not
 */
export function checkNeedUpgradeSDK(currentVersion: string): boolean {
  const cirVersion = currentVersion.split('.').map((v) => parseInt(v, 10));

  for (const version of incompatibleSDKVersions) {
    const toVersion = version.bugVersion.split('.').map((v) => parseInt(v, 10));

    /**
     * It works with versions formatted like that: x.y.z.patch where x, y, z, and patch are numbers
     * If x is lower than the current version, the circuit is not up to date
     * If it is equals, we check the y number
     * And then the z version
     */
    if (cirVersion[0] < toVersion[0]) return true;
    if (cirVersion[0] === toVersion[0] && cirVersion[1] < toVersion[1]) return true;
    if (cirVersion[0] === toVersion[0] && cirVersion[1] === toVersion[1] && cirVersion[2] < toVersion[2]) return true;
    if (
      cirVersion[0] === toVersion[0] &&
      cirVersion[1] === toVersion[1] &&
      cirVersion[2] === toVersion[2] &&
      (!cirVersion[3] || cirVersion[3] <= toVersion[3])
    )
      return true;
  }

  return false;
}

/**
 * Get the minimal version of the SDK that is compatible with the current Road Editor version
 * @returns the minimal version of the SDK that is compatible with the current Road Editor version
 */
export function getMinimumViableSdkVersion(): string {
  const sortedVersions = incompatibleSDKVersions.sort((a, b) => {
    return versionCompare(a.fixVersion, b.fixVersion);
  });

  return sortedVersions[sortedVersions.length - 1].fixVersion;
}
