import type { GridCoordinate, MapTilesAttr } from 'components/editor/floor-plan-tiles.layer';
import type { ErrorMessage } from 'fetchMapTiles';

/**
 * Check the tiles that collide with the window depend on the scale.
 * @param window - The coordinates of the window
 * @param tile - The tile to display
 * @param width - The width of the tile in px
 * @param gridMargin - The margin of the window to pre-load tiles
 * @param coefficientGridMargin - The coefficient to extend or not the gridMargin depend on the scale
 * @returns The tile if it collide with the window
 */
export function windowCollision(
  window: GridCoordinate,
  tile: MapTilesAttr,
  width: number,
  margin: number
): MapTilesAttr | undefined {
  // Cache window and tile properties
  const windowX0 = window.x0 - margin;
  const windowY0 = -window.y0 - margin;
  const windowX1 = window.x1 + margin;
  const windowY1 = -window.y1 + margin;

  const tileX = tile.x;
  const tileY = tile.y;
  const tileWidth = width;
  const tileHeight = tile.height;

  // Calculate the bounding box of both the window and the tile
  const windowLeft = Math.min(windowX0, windowX1);
  const windowRight = Math.max(windowX0, windowX1);
  const windowTop = Math.min(windowY0, windowY1);
  const windowBottom = Math.max(windowY0, windowY1);

  const tileLeft = tileX;
  const tileRight = tileX + tileWidth;
  const tileTop = tileY;
  const tileBottom = tileY + tileHeight;

  // Check if there is an overlap between the bounding boxes
  const collision = !(
    tileLeft > windowRight ||
    tileRight < windowLeft ||
    tileTop > windowBottom ||
    tileBottom < windowTop
  );

  if (collision) {
    return tile;
  }
}

/**
 * Type guard for map tiles errorMessage
 * @param response - The response from mapEditor server
 * @returns A boolean indicating whether the object is an ErrorMessage
 */
export function isErrorMessage(response: any): response is ErrorMessage {
  return (
    [
      'Project not found',
      'Invalid parameter: layer',
      'File not found',
      'Bad descriptor format',
      'Forbidden',
      'Server error',
    ].indexOf(response) !== -1
  );
}
