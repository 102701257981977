/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import type { Reducer } from 'redux';
import type { Action } from '../shared';
import { disableActions, reduceReducers } from '../shared';
import type { EntityState, Entity } from './state';
import type { EntityStateAdapter } from './adapter';
import { createEntityActions } from './actions';

export type EntityStateReducer<T extends Entity, S extends EntityState<T>> = Reducer<S, Action>;

interface CreateEntityStateReducerOptions<S> {
  disabledActionTypes?: string[];
  reducers?: Reducer<S, Action>[];
}

export function createEntityStateReducer<T extends Entity, S extends EntityState<T>>(
  tag: string,
  adapter: EntityStateAdapter<T, S>,
  getInitialState: () => S,
  options: CreateEntityStateReducerOptions<S> = {}
): EntityStateReducer<T, S> {
  const { disabledActionTypes = [], reducers = [] } = options;

  const {
    Create: CREATE,
    CreateFailure: CREATE_FAILURE,
    CreateSuccess: CREATE_SUCCESS,
    Delete: DELETE,
    DeleteFailure: DELETE_FAILURE,
    DeleteSuccess: DELETE_SUCCESS,
    DeleteSuccessMany: DELETE_SUCCESS_MANY,
    EnsureMany: ENSURE_MANY,
    EnsureOne: ENSURE_ONE,
    LoadMany: LOAD_MANY,
    LoadManyFailure: LOAD_MANY_FAILURE,
    LoadManySuccess: LOAD_MANY_SUCCESS,
    LoadOne: LOAD_ONE,
    LoadOneFailure: LOAD_ONE_FAILURE,
    LoadOneSuccess: LOAD_ONE_SUCCESS,
    Save: SAVE,
    SaveMany: SAVE_MANY,
    SaveManySuccess: SAVE_MANY_SUCCESS,
    SaveFailure: SAVE_FAILURE,
    SaveSuccess: SAVE_SUCCESS,
    Select: SELECT,
  } = disableActions(createEntityActions<T>(tag).types, disabledActionTypes);

  function entityReducer(state: S = getInitialState(), action: Action): S {
    switch (action.type) {
      case SELECT: {
        return { ...state, selectedId: action.payload.id };
      }

      case ENSURE_ONE: {
        const { id } = action.payload;
        const { ensuredCount = 0 } = state.entities[id] || {};

        return adapter.upsertOne(adapter.writeId({ ensuredCount: ensuredCount + 1 } as any, id), state);
      }

      case ENSURE_MANY: {
        const { ids } = action.payload;
        const changes = ids.map((id: string) => {
          const { ensuredCount = 0 } = state.entities[id] || {};

          return adapter.writeId({ ensuredCount: ensuredCount + 1 } as any, id);
        });

        return adapter.upsertMany(changes, state);
      }

      case LOAD_ONE: {
        return adapter.addOne(
          { ...action.payload, loading: true, loaded: false, updating: true, updated: false },
          state
        );
      }

      case LOAD_MANY: {
        const { ids } = action.payload;

        return adapter.updateMany(
          ids.map((id: string) => ({
            id,
            changes: { loading: true, loaded: false, updating: true, updated: false, error: undefined },
          })),
          state
        );
      }

      case LOAD_ONE_SUCCESS: {
        const entity = action.payload;

        const id = adapter.selectId(entity);
        const changes = { ...entity, loading: false, loaded: true, updating: false, updated: true };

        return adapter.updateOne({ id, changes }, state);
      }

      case LOAD_MANY_SUCCESS: {
        return adapter.updateMany(
          action.payload.map((entity) => {
            const id = adapter.selectId(entity);

            return {
              id,
              changes: { ...entity, loading: false, loaded: true, updating: false, updated: true },
            };
          }),
          state
        );
      }

      case LOAD_ONE_FAILURE: {
        const { id } = action.payload;
        const changes = { ...action.payload, loading: false, loaded: false, updating: false, updated: false };

        return adapter.updateOne({ id, changes }, state);
      }

      case LOAD_MANY_FAILURE: {
        const { ids, error } = action.payload;

        return adapter.updateMany(
          ids.map((id: string) => ({
            id,
            changes: { error, loading: false, loaded: false, updating: false, updated: false },
          })),
          state
        );
      }

      case DELETE:
      case SAVE: {
        const entity = action.payload;

        const id = adapter.selectId(entity);
        const changes = { ...entity, loading: false, loaded: true, updating: true, updated: false, error: undefined };

        return adapter.updateOne({ id, changes }, state);
      }

      case SAVE_MANY: {
        const entities = action.payload;

        const changes = entities.map((entity) => {
          const id = adapter.selectId(entity);

          return {
            id,
            changes: { ...entity, loading: false, loaded: true, updating: true, updated: false, error: undefined },
          };
        });

        return adapter.updateMany(changes, state);
      }

      case SAVE_MANY_SUCCESS: {
        const entities = action.payload;

        const changes = entities.map((entity) => {
          const id = adapter.selectId(entity);

          return {
            id,
            changes: { ...entity, loading: false, loaded: true, updating: false, updated: true },
          };
        });

        return adapter.updateMany(changes, state);
      }

      case SAVE_SUCCESS: {
        const entity = action.payload;

        const id = adapter.selectId(entity);
        const changes = { ...entity, loading: false, loaded: true, updating: false, updated: true };

        return adapter.updateOne({ id, changes }, state);
      }

      case DELETE_FAILURE:
      case SAVE_FAILURE: {
        const { id } = action.payload;
        const changes = { ...action.payload, loading: false, loaded: true, updating: false, updated: false };

        return adapter.updateOne({ id, changes }, state);
      }

      case DELETE_SUCCESS: {
        const { id } = action.payload;

        return adapter.removeOne(id, state);
      }

      case DELETE_SUCCESS_MANY: {
        const { ids } = action.payload;

        return adapter.removeMany(ids, state);
      }

      case CREATE: {
        return { ...state, creationForm: { error: undefined, pending: true } };
      }

      case CREATE_FAILURE: {
        const { error } = action.payload;

        return { ...state, creationForm: { error, pending: false } };
      }

      case CREATE_SUCCESS: {
        const payload = action.payload;
        const entity = { ...payload, loading: false, loaded: true, updating: false, updated: true };

        return adapter.addOne(entity, { ...state, creationForm: { error: undefined, pending: false } });
      }

      case '💀':
      default:
        return state;
    }
  }

  reducers.unshift(entityReducer);

  return reduceReducers(reducers);
}
