import { Measurer } from 'drawings/editor-elements';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitMeasurer } from 'models/circuit';
import { useEffect, useMemo, useRef } from 'react';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface MeasurerComponentProps {
  measurer: CircuitMeasurer;
  id: string;
  selected?: boolean;
}

export function MeasurerComponent(props: MeasurerComponentProps): JSX.Element {
  const measurer = props.measurer;
  const ref = useRef<SVGSVGElement>(null);

  const shape = useMemo(() => {
    if (props.id && measurer && measurer.id) {
      return new Measurer(measurer.id.toString(), measurer.geometry, projection, zoomScale, measurer.properties);
    }
  }, [props.id, measurer]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();
      const clickElement = node?.querySelector('[main-shape]')?.cloneNode() as SVGElement;

      if (node && clickElement) {
        // this element is a transparent element to handle clicks
        // on a larger area than juste the shape
        clickElement.removeAttribute('main-shape');
        clickElement.style.strokeDasharray = 'none';
        clickElement.classList.add('select-shape-helper');

        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        node.insertBefore(clickElement, node.firstChild);
        ref.current.appendChild(node);
      }
    }
  }, [shape]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return <g ref={ref}></g>;
}
