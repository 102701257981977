import { Chip, Dialog, DialogContent, DialogTitle, List, useTheme } from '@mui/material';
import { closeDialogAction } from 'actions';
import { CollapseMore } from 'components/utils/collapse-more';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { useAppDispatch } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { checkNeedUpgradeSDK, getMinimumViableSdkVersion } from 'utils/incompatible-versions';
import { PreferencesService } from 'utils/preferences';
import { CircuitVersionAndDescription } from './circuit-version-and-description';
import { DetectAislesBtn } from './detect-aisles-btn';
import { EditCommunicationMode } from './edit-communication-mode';
import { EditLanguage } from './edit-language';
import { EditLidarMap } from './edit-lidar-map';
import EditSupervisorIP from './edit-supervisor-ip';
import { EnableDeadlockSolverSwitch } from './enable-deadlock-solver-switch';
import { ExportAllSlotsBtn } from './export-all-slots-btn';
import { FireAlarm } from './fire-alarm';
import { ImportMapImageListItem } from './import-map-image-list-item';
import { ManageDevicePreferences } from './manage-device-preferences';
import { MaxHeightItemsPreferences } from './max-height-items-preferences';
import { RackAnalysisBtn } from './rack-analysis-btn';
import { SimulationSettingsSection } from './simulation-settings';
import { TranslateRotateWholeCircuitBtns } from './translate-rotate-whole-circuit-btns';
import VersionTooLowChip from './version-too-low-chip';

interface ProjectSettingsDialogProps {
  closeDialog?: () => void;
}
export function ProjectSettingsDialog(props: ProjectSettingsDialogProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  /* Project opened is used as a component refresh when it is incremented */
  const [projectOpened, setProjectOpened] = useState(PreferencesService.arePreferencesFullyLoaded() ? 1 : 0);

  /* Increment the project opened state when there is a remote pref update so that you don't have to close & re-open the dialog to have the data updated */
  useEffect(() => {
    const listener = (): void => {
      setProjectOpened((prev) => prev + 1);
    };

    document.addEventListener('update-pref-remote', listener);

    return () => document.removeEventListener('update-pref-remote', listener);
  }, []);

  const [open, setOpen] = useState(true);

  const handleClose = useCallback((): void => {
    setOpen(false);

    setTimeout(() => {
      dispatch(closeDialogAction());
    }, theme.transitions.duration.leavingScreen);
  }, [dispatch, theme.transitions.duration.leavingScreen]);

  const projectName = useMemo(() => {
    return projectOpened ? PreferencesService.getProjectName() : undefined;
  }, [projectOpened]);

  const sdkVersion = useMemo(() => {
    return projectOpened ? PreferencesService.getSDKVersion() : undefined;
  }, [projectOpened]);

  const isSdkVersionOk = useMemo(() => {
    return !sdkVersion || typeof sdkVersion !== 'string' ? true : !checkNeedUpgradeSDK(sdkVersion);
  }, [sdkVersion]);

  const minimumViableSdkVersion = useMemo(() => {
    return !isSdkVersionOk ? getMinimumViableSdkVersion() : undefined;
  }, [isSdkVersionOk]);

  const NEW4XCoreVersion = useMemo(() => {
    return projectOpened ? PreferencesService.getNEW4XCoreVersion() : undefined;
  }, [projectOpened]);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  const displayManageDevicePreference = !!projectName;
  const displayAdvancedParameters = displayManageDevicePreference;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="project-settings-title">
      <DialogTitle
        id="project-settings-title"
        sx={{ backgroundColor: 'white', zIndex: 1, paddingBottom: 0, marginBottom: '16px' }}
      >
        Project Settings {!!projectName && <Chip label={projectName} sx={{ float: 'right' }} />}
      </DialogTitle>

      <DialogContent
        sx={{
          flexDirection: 'row',
          columnGap: '8px',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          zIndex: 10,
          overflowY: 'unset',
        }}
      >
        <VersionTooLowChip
          projectOpened={!!projectOpened}
          isSdkVersionOk={isSdkVersionOk}
          sdkVersion={sdkVersion}
          minimumViableSdkVersion={minimumViableSdkVersion}
          NEW4XCoreVersion={NEW4XCoreVersion}
        />
      </DialogContent>
      <DialogContent sx={{ marginTop: '-70px' }}>
        <List>
          <CircuitVersionAndDescription />

          <EditSupervisorIP editProjectPerm={editProjectPerm} projectOpened={!!projectOpened} />

          <EditCommunicationMode projectOpened={!!projectOpened} />

          <EditLanguage projectOpened={!!projectOpened} />

          <EditLidarMap projectOpened={!!projectOpened} editProjectPerm={editProjectPerm} />

          <ImportMapImageListItem />

          {projectOpened ? <MaxHeightItemsPreferences projectOpened={projectOpened} /> : null}

          <DetectAislesBtn />

          {projectOpened ? <FireAlarm projectOpened={projectOpened} /> : null}

          {projectOpened ? <SimulationSettingsSection projectOpened={!!projectOpened} /> : null}

          {displayAdvancedParameters && (
            <CollapseMore title="Advanced User">
              {displayManageDevicePreference && <ManageDevicePreferences />}

              <EnableDeadlockSolverSwitch projectOpened={!!projectOpened} />

              <TranslateRotateWholeCircuitBtns />

              <RackAnalysisBtn />

              <ExportAllSlotsBtn />
            </CollapseMore>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}
