import React from 'react';
import { LargeError } from 'components/core';
import { FullPageLayout } from 'components/layouts';

interface UnauthorizedPageProps {}

export function UnauthorizedPage(props: UnauthorizedPageProps): JSX.Element {
  return (
    <FullPageLayout verticallyCentered>
      <LargeError code={401} />
    </FullPageLayout>
  );
}
