export interface MouseData {
  /** x coordinate of mouse (meters) */
  X: number;
  /** y coordinate of mouse (meters) */
  Y: number;
  /** x coordinate of previous mouse position (meters) */
  previousX: number;
  /** y coordinate of last mouse position (meters) */
  previousY: number;
  /** x coordinate of last click (meters) */
  lastClickX: number;
  /** y coordinate of last click (meters) */
  lastClickY: number;
  /** distance between last click X and current mouse position X (meters) */
  dx: number;
  /** distance between last click Y and current mouse position Y (meters) */
  dy: number;
  /** distance between last click and current mouse position (meters) */
  d: number;
  /** labels to display in the mouse position indicator */
  labelsToDisplay: string[];
}

export interface MousePosition {
  /** x coordinate of mouse */
  x: number;
  /** y coordinate of mouse */
  y: number;
}

/**
 * Global variable to access the mouse position from anywhere in the code
 */
export const mouseData: MouseData = {
  X: 0,
  Y: 0,
  previousX: 0,
  previousY: 0,
  lastClickX: 0,
  lastClickY: 0,
  dx: 0,
  dy: 0,
  d: 0,

  labelsToDisplay: ['X', 'Y', 'lastClickX', 'lastClickY', 'd', 'dx', 'dy'],
};
