import { useDebouncedValue } from '@mantine/hooks';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { formatSecondsToHHMMSS } from 'utils/time';
import { isDefined } from 'utils/ts/is-defined';
import { taskStatusColor, type TaskSchedulerInfo } from './scheduler/task-scheduler-info';
import { taskStateNumberToStr } from './states';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}:nth-of-type(odd)`]: {
    backgroundColor: theme.palette.grey[100],
  },
  [`& .${gridClasses.row}:nth-of-type(even)`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`.${gridClasses.columnHeaders}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  '& .task-status': {
    textTransform: 'capitalize',
  },
  [`& .${gridClasses.row}:has(.running)`]: {
    boxShadow: `${taskStatusColor['running']} 3px 0px 0px 0px inset`,
  },
  [`& .${gridClasses.row}:has(.waiting)`]: {
    boxShadow: `${taskStatusColor['waiting']} 3px 0px 0px 0px inset`,
  },
  [`& .${gridClasses.row}:has(.done)`]: {
    boxShadow: `${taskStatusColor['done']} 3px 0px 0px 0px inset`,
  },
}));

interface TaskTableProps {
  tasksArr: TaskSchedulerInfo[];
}

const allAvailableTasksStatus = Array.from(
  new Set(
    new Array(100)
      .fill(0)
      .map((_, i) => i - 10)
      .map((i) => taskStateNumberToStr(i))
  )
);

export default function TaskTable({ tasksArr }: TaskTableProps): JSX.Element {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 75, type: 'number' },
    { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: allAvailableTasksStatus },
    { field: 'taskName', headerName: 'Task Name', type: 'string' },
    { field: 'stepLabel', headerName: 'Step', type: 'string' },
    { field: 'source', headerName: 'Source' },
    { field: 'destination', headerName: 'Destination' },
    { field: 'duration', headerName: 'Duration', type: 'string' },
    { field: 'robotId', headerName: 'Robot' },
    { field: 'robotAuthorised', headerName: 'Authorized Robots' },
    { field: 'waitingTime', headerName: 'Waiting Time', type: 'string' },
    { field: 'priority', headerName: 'Priority', type: 'number' },
    { field: 'dueDate', headerName: 'Due Date', type: 'string' },
  ];

  return (
    <StripedDataGrid
      columns={columns}
      rows={tasksArr}
      disableRowSelectionOnClick
      getCellClassName={(params) => {
        if (params.field === 'status' && params.value) return `task-status ${params.value.toString()}`;

        return '';
      }}
      sx={{ maxHeight: 'inherit' }}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
    ></StripedDataGrid>
  );
}

export const ListOfTasksMenu = (): JSX.Element => {
  const tasks = useAppSelector((state) => state.simulation.tasks);
  const robots = useAppSelector((state) => state.robots.robots);
  const flows = useAppSelector((state) => state.flows.flows);

  const simulationTime = useAppSelector((state) => state.simulation.simulatedTime);
  const simulationStartEpoch = useAppSelector((state) => state.simulation.startEpochTime);

  const collapseTime = theme.transitions.duration.short;

  /**
   * When the task is done after this duration, it will be skipped from the list of tasks
   * [s]
   */
  const durationSkipDoneTasks = 300; // 5 minutes

  const tasksArr: TaskSchedulerInfo[] = useMemo(
    () =>
      Object.entries(tasks)
        .map(([taskId, task]) => {
          const nowInTasksFrameOfReference = simulationStartEpoch + simulationTime;

          const status = taskStateNumberToStr(task.state);

          if (status === 'done' && task.endDate !== undefined) {
            const doneFor = nowInTasksFrameOfReference - task.endDate;
            if (doneFor > durationSkipDoneTasks) {
              return undefined;
            }
          }

          let duration: number | undefined = undefined;
          if (task.startDate !== undefined && task.endDate !== undefined) {
            duration =
              task.startDate === 0
                ? 0
                : task.startDate !== 0 && task.endDate !== 0
                  ? task.endDate - task.startDate
                  : nowInTasksFrameOfReference - task.startDate;
          }

          let waitingTime = 0;
          if (task.creationDate !== undefined && task.startDate !== undefined) {
            waitingTime =
              task.startDate === 0
                ? nowInTasksFrameOfReference - task.creationDate
                : task.startDate - task.creationDate;
          }

          const robot = robots.find((robot) => robot.id === task.robotID);
          const robotName = robot?.name;

          const flow = flows.find((flow) => flow.name === task.taskName);

          const robotsAuthorisedName = task.robotAuthorised
            ?.map((id) => {
              const robAuthorized = robots.find((robot) => robot.id === id);
              const robotAuthorizedSerial = robAuthorized?.serial;

              if (
                flow &&
                flow.robotsAssigned !== 'all' &&
                (!robotAuthorizedSerial || !flow.robotsAssigned?.includes(robotAuthorizedSerial))
              ) {
                return null;
              }

              const robotName = robots.find((robot) => robot.id === id)?.name;

              return robotName;
            })
            .filter(isDefined);

          const source = task.startPoint ?? (task.src ? positionIdToPositionName(task.src.toString(), 'all') : '');
          const destination =
            task.destinationPoint ?? (task.dst ? positionIdToPositionName(task.dst.toString(), 'all') : '');

          const dueDate = task.dueDate;

          return {
            id: task.id,
            taskName: task.taskName,
            source: source,
            destination: destination,
            duration: duration && duration > 0 ? formatSecondsToHHMMSS(duration) : '',
            robotId: robotName ?? '',
            robotAuthorised: robotsAuthorisedName ? robotsAuthorisedName.join(', ') : '',
            waitingTime: waitingTime > 0 ? formatSecondsToHHMMSS(waitingTime) : '',
            priority: task.priority,
            dueDate: dueDate && dueDate > 0 ? formatSecondsToHHMMSS(dueDate) : '',
            status,
            stepLabel: task.stepLabel !== 'Init' ? task.stepLabel ?? '' : '',
          };
        })
        .filter(isDefined),
    [flows, robots, simulationStartEpoch, simulationTime, tasks]
  );

  const [showTasksList, setShowTasksList] = useState(false);
  const [showTasksListDebounced] = useDebouncedValue(showTasksList, collapseTime);
  const [folded, setFolded] = useState(true);

  const handleClickShowLayers = useCallback((): void => {
    setShowTasksList(!showTasksList);
  }, [showTasksList]);

  return (
    <div style={{ pointerEvents: 'none', marginBottom: theme.spacing(2) }}>
      <Card sx={{ pointerEvents: 'initial' }}>
        <CardHeader
          avatar={<FormatListBulletedIcon></FormatListBulletedIcon>}
          sx={{ userSelect: 'none', paddingBottom: theme.spacing(0.5), paddingTop: theme.spacing(0.5), marginTop: 0 }}
          title="List of waiting and ongoing tasks"
          action={
            <>
              {Object.keys(tasks).length > 4 && (
                <IconButton title={folded ? 'Expand' : 'Fold'} onClick={() => setFolded(!folded)} size="large">
                  {folded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                </IconButton>
              )}

              <IconButton
                title={showTasksList ? 'Hide' : 'Show'}
                aria-label="Show layers"
                onClick={handleClickShowLayers}
                size="large"
              >
                {!showTasksList ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
              </IconButton>
            </>
          }
        ></CardHeader>
        <Collapse in={showTasksList} timeout={collapseTime}>
          <CardContent
            sx={{
              padding: theme.spacing(1),
              marginTop: 0,
              paddingTop: 0,
              paddingBottom: `${theme.spacing(1)} !important`,
              textAlign: 'left',
            }}
          >
            <Box
              component="div"
              sx={{ padding: 0, maxHeight: folded ? '250px' : '76vh', maxWidth: '60vw', width: '60vw' }}
            >
              {showTasksList || showTasksListDebounced ? <TaskTable tasksArr={tasksArr}></TaskTable> : null}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};
