import { SquareOutlined } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {
  ButtonGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { animated, useSpring } from '@react-spring/web';
import type { Position } from '@turf/helpers';
import {
  attachMeasurerAction,
  detachMeasurerAction,
  saveCircuitToHistoryAction,
  updateMeasurerAction,
} from 'actions/circuit';
import { saveMeasurerAction } from 'actions/measurers';
import { RackIcon, StockZoneIcon } from 'components/core/icons';
import * as d3 from 'd3';
import { findShapeOrientation, getClosestPointAndLineInPolygon, pDistance } from 'drawings/helpers';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { cloneDeep } from 'lodash';
import type { CircuitMeasurer, GabaritProperties } from 'models/circuit';
import { MeasurementType, ShapeTypes } from 'models/circuit';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type {
  LoadedMeasurer,
  LoadedPoint,
  LoadedRack,
  LoadedSegment,
  LoadedStockZone,
  LoadedZone,
} from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import type { RootState } from 'store';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { useDebouncedCallback } from 'use-debounce';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import {
  isCircuitPoint,
  isCircuitRack,
  isCircuitSegment,
  isCircuitStockZone,
  isCircuitZone,
} from 'utils/circuit/shape-guards';
import { humanize, integerInputIfNeeded, toRad } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import { MovementArrowButtonsGroup } from '../components/movement-arrows-buttons';
import { PropertiesComponent } from '../properties-component';
import { nbDigitsOrientationValue } from '../rack-properties/rack-properties';

const attacheMeasurerTooltipText = 'The yellow arrow will be attached to the closest element (segment or point).';
const detachMeasurerTooltipText = 'The arrow will not be attached anymore. You will be able to move it freely.';

const useStyles = makeStyles((theme) =>
  createStyles({
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },
    inputExtremitiesCoordinates: {
      maxWidth: '100px',
    },
  })
);

type extremityPos = 'X1' | 'Y1' | 'X2' | 'Y2';

interface MeasurerPropertiesProps {
  measurerId: string;
}
export interface MeasurersCoordinatesForm {
  coordinates: Position[];
}
export interface MeasurersPropertiesFormValue {
  name: string;
  type: ShapeTypes.MeasurerShape;
  orientation: number;
  isTaxi: boolean;
  isInit: boolean;
  isBattery: boolean;
  height: any;
  minimumInitScore?: number;
  layerId: string;
  gabarit?: GabaritProperties;
  prio: number;
}
export interface SnappedButtonProps {
  measurer: CircuitMeasurer;
  changeFormCoordinatesValue: (
    value: (MeasurersCoordinatesForm: MeasurersCoordinatesForm) => MeasurersCoordinatesForm
  ) => void;
  changeFormPropertiesValue: (value: (value: MeasurersPropertiesFormValue) => MeasurersPropertiesFormValue) => void;
  disabled?: boolean;
}

export const MeasurerProperties = (
  { measurerId }: MeasurerPropertiesProps,
  { changeFormCoordinatesValue, changeFormPropertiesValue }: SnappedButtonProps
): JSX.Element => {
  const measurer = useAppSelector(
    (state: RootState) => state.circuit.present.measurers.entities[measurerId] as LoadedMeasurer | undefined
  );
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [measurerName, setMeasurerName] = useState(measurer?.properties.name || '');

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');
  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!measurer) return;

    let name = measurerName;
    if (
      !name ||
      !areAllShapeNamesUnique([name], [measurerId], {
        ignoreDuplicatesBefore: true,
      })
    ) {
      name = suggestedName;
    }

    dispatch(
      saveMeasurerAction({
        id: measurer.id,
        properties: {
          ...measurer.properties,
          name,
        },
        userAction: true,
      })
    );
  }, 400);

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newName = e.target.value;
      if (newName?.length < 50) {
        setMeasurerName(newName);
      }

      startTransition(() => {
        const isNameAlreadyUsed = !areAllShapeNamesUnique([newName], [measurerId], {
          ignoreDuplicatesBefore: true,
        });

        if (!newName || isNameAlreadyUsed) {
          setErrorName(true);
          setSuggestedName(CircuitService.generateDifferentName(newName, { shapeIdsToIgnore: [measurerId] }));
        } else {
          setErrorName(false);
        }

        updatePropertiesDebounced();
      });
    },
    [measurerId, updatePropertiesDebounced]
  );
  const setSuggestedNameHandler = useCallback(() => {
    setMeasurerName(suggestedName);
    setErrorName(false);

    updatePropertiesDebounced();
  }, [suggestedName, updatePropertiesDebounced]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorName(false), [measurerId]);

  const locked = !!measurer?.properties?.locked;

  const handleChangeMeasurementType = useCallback(
    (e) => {
      if (!measurer) return;
      const newVal = e.target.value as MeasurementType;

      store.dispatch(saveCircuitToHistoryAction());

      dispatch(
        saveMeasurerAction({
          id: measurerId,
          properties: {
            ...measurer.properties,
            measurementType: newVal,
          },
        })
      );

      dispatch(
        updateMeasurerAction({
          measurerId,
        })
      );
    },
    [dispatch, measurer, measurerId]
  );

  const handleChangeShowLength = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (!measurer) return;
      const newVal = e.target.checked;
      dispatch(
        saveMeasurerAction({
          id: measurerId,
          properties: {
            ...measurer.properties,
            showLength: newVal,
          },
          userAction: true,
        })
      );
    },
    [dispatch, measurer, measurerId]
  );

  const handleChangeActGuide = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (!measurer) return;
      const newActValue = e.target.checked;
      dispatch(
        saveMeasurerAction({
          id: measurerId,
          properties: {
            ...measurer.properties,
            guide: newActValue,
          },
          userAction: true,
        })
      );
    },
    [dispatch, measurer, measurerId]
  );

  const measurerAngle = useMemo(() => {
    if (!measurer) return;
    const coords = measurer.geometry.coordinates;
    const computedAngle = findShapeOrientation([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
    const normalizeAngle = (computedAngle + 360) % 360;

    return integerInputIfNeeded(normalizeAngle, nbDigitsOrientationValue);
  }, [measurer]);

  const [newMeasurerAngle, setNewMeasurerAngle] = useState(measurerAngle);

  // update angle on measurer properties change
  useEffect(() => {
    setNewMeasurerAngle(measurerAngle);
  }, [measurerAngle]);

  const handleMeasurerAngleChange = useCallback((e) => {
    setNewMeasurerAngle(e.target.value);
  }, []);

  const link0 = measurer?.properties.link0;
  const link1 = measurer?.properties.link1;

  const nbConnections = (link0 ? 1 : 0) + (link1 ? 1 : 0);

  const isConnectedToPoint = link0?.type === ShapeTypes.PointShape || link1?.type === ShapeTypes.PointShape;
  const isConnectedToSegment = link0?.type === ShapeTypes.SegmentShape || link1?.type === ShapeTypes.SegmentShape;
  const isConnectedToRack = link0?.type === ShapeTypes.RackShape || link1?.type === ShapeTypes.RackShape;
  const isConnectedToZone = link0?.type === ShapeTypes.ZoneShape || link1?.type === ShapeTypes.ZoneShape;
  const isConnectedToStockZone = link0?.type === ShapeTypes.StockZoneShape || link1?.type === ShapeTypes.StockZoneShape;
  const centerToCenter = measurer?.properties.measurementType === MeasurementType.Center2Center;
  const editableOrientation =
    nbConnections === 0 ||
    (nbConnections === 1 && isConnectedToPoint) ||
    (nbConnections === 1 && isConnectedToSegment && centerToCenter) ||
    (nbConnections === 1 && isConnectedToRack && centerToCenter) ||
    (nbConnections === 1 && isConnectedToZone && centerToCenter) ||
    (nbConnections === 1 && isConnectedToStockZone && centerToCenter);

  const updateMeasurerAngleChange = useCallback(() => {
    if (!measurer || !newMeasurerAngle || !measurerAngle) return;

    const inputAngle = parseFloat(newMeasurerAngle);
    const measurerComputeAngle = (inputAngle + 360) % 360;
    const measurerInputAngleRad = toRad(measurerComputeAngle);

    // Define the coordinates for first measurer
    const coords = measurer.geometry.coordinates;
    const x1 = coords[0][0];
    const y1 = coords[0][1];
    const x2 = coords[1][0];
    const y2 = coords[1][1];

    const measurerLength = getDistanceBetweenPoints([x1, y1], [x2, y2]);

    let newCord: number[][];
    if (link0) {
      // We compute the new end point of the measurer thanks to the new angle entered by the user ( if point 1 is fixed)
      const x3 = x1 + Math.cos(measurerInputAngleRad) * measurerLength;
      const y3 = y1 + Math.sin(measurerInputAngleRad) * measurerLength;
      const cord0 = [
        [x1, y1],
        [x3, y3],
      ];
      newCord = cord0;
    } else if (link1) {
      // We compute the new end point of the measurer thanks to the new angle entered by the user( if point2 is fixed )
      const x4 = x2 + Math.cos(measurerInputAngleRad + Math.PI) * measurerLength;
      const y4 = y2 + Math.sin(measurerInputAngleRad + Math.PI) * measurerLength;
      const cordA = [
        [x4, y4],
        [x2, y2],
      ];
      newCord = cordA;
    } else {
      // calculations to find the middle point of the first measurer
      const prevAngleRad = toRad(parseFloat(measurerAngle));

      const xM = x1 + Math.cos(prevAngleRad) * (measurerLength / 2);
      const yM = y1 + Math.sin(prevAngleRad) * (measurerLength / 2);

      // calculations to find the start point of the new rotated measurer
      const x6 = xM + Math.cos(measurerInputAngleRad + Math.PI) * (measurerLength / 2);
      const y6 = yM + Math.sin(measurerInputAngleRad + Math.PI) * (measurerLength / 2);

      // calculations to find the end point of the new rotated measurer
      const x5 = xM + Math.cos(measurerInputAngleRad) * (measurerLength / 2);
      const y5 = yM + Math.sin(measurerInputAngleRad) * (measurerLength / 2);
      const cordB = [
        [x6, y6],
        [x5, y5],
      ];
      newCord = cordB;
    }

    if (!isNaN(measurerComputeAngle)) {
      dispatch(saveCircuitToHistoryAction());
      dispatch(
        saveMeasurerAction({
          id: measurerId,
          geometry: { ...measurer.geometry, coordinates: newCord },
        })
      );
      dispatch(
        updateMeasurerAction({
          measurerId,
        })
      );
    } else {
      setNewMeasurerAngle(measurerAngle);
    }
  }, [dispatch, newMeasurerAngle, measurer, measurerAngle, measurerId, link0, link1]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        updateMeasurerAngleChange();
      } else if (event.key === 'Escape') {
        setNewMeasurerAngle(measurerAngle);
      }
    },
    [measurerAngle, updateMeasurerAngleChange]
  );

  const [x1, setX1] = useState(humanize((measurer?.geometry?.coordinates[0][0] ?? -1) / 100));
  const [y1, setY1] = useState(humanize((measurer?.geometry?.coordinates[0][1] ?? -1) / 100));
  const [x2, setX2] = useState(humanize((measurer?.geometry?.coordinates[1][0] ?? -1) / 100));
  const [y2, setY2] = useState(humanize((measurer?.geometry?.coordinates[1][1] ?? -1) / 100));

  const handleChangeExtremity = useCallback((label: extremityPos, value: string) => {
    if (label === 'X1') setX1(value);
    else if (label === 'Y1') setY1(value);
    else if (label === 'X2') setX2(value);
    else if (label === 'Y2') setY2(value);
  }, []);

  const handleEnterExtremity = useCallback(
    (label: extremityPos, e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && measurer) {
        const newCoordinates = cloneDeep(measurer.geometry.coordinates);
        if (label === 'X1') newCoordinates[0][0] = parseFloat(x1) * 100;
        else if (label === 'Y1') newCoordinates[0][1] = parseFloat(y1) * 100;
        else if (label === 'X2') newCoordinates[1][0] = parseFloat(x2) * 100;
        else if (label === 'Y2') newCoordinates[1][1] = parseFloat(y2) * 100;

        if (
          measurer &&
          !isNaN(newCoordinates[0][0]) &&
          !isNaN(newCoordinates[0][1]) &&
          !isNaN(newCoordinates[1][0]) &&
          !isNaN(newCoordinates[1][1])
        ) {
          dispatch(
            saveMeasurerAction({
              ...measurer,
              geometry: {
                ...measurer.geometry,
                coordinates: newCoordinates,
              },
              userAction: true,
            })
          );
        }
      }
    },
    [dispatch, measurer, x1, x2, y1, y2]
  );

  const handleBlurExtremity = useCallback(
    (label: extremityPos) => {
      if (!measurer || !measurer.geometry || !measurer.geometry.coordinates) return;

      if (label === 'X1') setX1(humanize(measurer.geometry.coordinates[0][0] / 100));
      else if (label === 'Y1') setY1(humanize(measurer.geometry.coordinates[0][1] / 100));
      else if (label === 'X2') setX2(humanize(measurer.geometry.coordinates[1][0] / 100));
      else if (label === 'Y2') setY2(humanize(measurer.geometry.coordinates[1][1] / 100));
    },
    [measurer]
  );

  useEffect(() => {
    if (!measurer?.geometry?.coordinates) return;

    setX1(humanize(measurer.geometry.coordinates[0][0] / 100));
    setY1(humanize(measurer.geometry.coordinates[0][1] / 100));
    setX2(humanize(measurer.geometry.coordinates[1][0] / 100));
    setY2(humanize(measurer.geometry.coordinates[1][1] / 100));
  }, [measurer?.geometry?.coordinates]);

  const coords = measurer?.geometry.coordinates;
  let initialMeasurerLength: number;
  if (coords) {
    const length = getDistanceBetweenPoints([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
    const lengthInMetter = length / 100;
    const lengthRound = lengthInMetter.toFixed(3);
    initialMeasurerLength = Number(lengthRound);
  } else {
    initialMeasurerLength = 0;
    initialMeasurerLength = 0;
  }

  const [measurerLength, setMeasurerLength] = useState(initialMeasurerLength);

  const onLengthChange = useCallback((value: number): void => {
    const newLength = value;
    setMeasurerLength(newLength);
  }, []);

  const handleEnterNewLength = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && measurer && newMeasurerAngle) {
        const coords = measurer.geometry.coordinates;
        const newCoordinates = cloneDeep(measurer.geometry.coordinates);

        const inputAngle = parseFloat(newMeasurerAngle);
        const measurerComputeAngle = (inputAngle + 360) % 360;
        const measurerInputAngleRad = toRad(measurerComputeAngle);

        const isAttached0 = !!measurer.properties.link0;
        const isAttached1 = !!measurer.properties.link1;
        const isBothFree = !isAttached0 && !isAttached1;

        let endPointToMove: 0 | 1 | undefined = undefined;

        if (isAttached0 || isBothFree) {
          endPointToMove = 1;
        } else if (isAttached1) {
          endPointToMove = 0;
        }

        if (endPointToMove === undefined) return;

        const baseEndPoint = endPointToMove === 0 ? 1 : 0;
        const direction = endPointToMove === 0 ? 1 : -1;

        newCoordinates[endPointToMove][0] =
          coords[baseEndPoint][0] + direction * Math.cos(measurerInputAngleRad + Math.PI) * (measurerLength * 100);
        newCoordinates[endPointToMove][1] =
          coords[baseEndPoint][1] + direction * Math.sin(measurerInputAngleRad + Math.PI) * (measurerLength * 100);

        if (
          measurer &&
          !isNaN(newCoordinates[0][0]) &&
          !isNaN(newCoordinates[0][1]) &&
          !isNaN(newCoordinates[1][0]) &&
          !isNaN(newCoordinates[1][1])
        ) {
          dispatch(
            saveMeasurerAction({
              ...measurer,
              geometry: {
                ...measurer.geometry,
                coordinates: newCoordinates,
              },
              userAction: true,
            })
          );
        }
      }
    },
    [dispatch, measurer, measurerLength, newMeasurerAngle]
  );

  useEffect(() => {
    if (!measurer?.geometry?.coordinates) return;

    const coords = measurer.geometry.coordinates;

    const length = getDistanceBetweenPoints([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
    const lengthInMetter = length / 100;
    const lengthRound = Number(lengthInMetter.toFixed(3));

    setMeasurerLength(lengthRound);
  }, [measurer?.geometry?.coordinates]);

  const isAttached0 = !!measurer?.properties.link0;
  const isAttached1 = !!measurer?.properties.link1;

  const lockedLength = !!measurer?.properties.lockedLength;
  const disableLockedLengthInput = lockedLength || (isAttached0 && isAttached1);

  const stateStore = store.getState();
  const lockOrientation = stateStore.tool.lockOrientation || false;

  const [springStyleLockButton, animateStyleLockButton] = useSpring(
    {
      from: { rotateY: 0 },
      config: { duration: 150 },
    },
    []
  );

  const onLockHandler = useCallback(() => {
    const newLockedValue = !lockedLength;

    animateStyleLockButton.start({
      to: { rotateY: 90 },
      onRest: () => {
        animateStyleLockButton.start({
          to: { rotateY: 0 },
        });
      },
    });

    if (measurer) {
      dispatch(
        saveMeasurerAction({
          ...measurer,
          properties: {
            ...measurer.properties,
            lockedLength: newLockedValue,
          },
          userAction: true,
        })
      );
    }
  }, [animateStyleLockButton, dispatch, lockedLength, measurer]);

  return !measurerId || !measurer ? (
    <></>
  ) : (
    <PropertiesComponent shape={measurer} shapeId={measurerId} shapeType={ShapeTypes.MeasurerShape}>
      <FormControl error={errorName} fullWidth>
        <TextField
          value={measurerName}
          onChange={onNameChangeHandler}
          label="Name"
          disabled={locked}
          variant="standard"
          sx={{
            textDecoration: errorName ? 'line-through' : undefined,
          }}
        />
        <FormHelperText className={classes.helperText} error id="suggested-name-measurer">
          {errorName ? (
            <>
              Name already used, suggested name:{' '}
              <span onClick={setSuggestedNameHandler} className={classes.suggestedName}>
                {suggestedName}
              </span>
            </>
          ) : (
            <></>
          )}
        </FormHelperText>
      </FormControl>
      <MovementArrowButtonsGroup
        shape={measurer}
        shapeId={measurerId}
        shapeMovedAction={saveMeasurerAction}
        locked={locked || nbConnections > 0}
      />
      <Stack direction="row" spacing={1}>
        <TextField
          value={measurerLength}
          onChange={(e) => onLengthChange(Number(e.target.value))}
          onKeyPress={(e) => handleEnterNewLength(e)}
          label="Length"
          type="number"
          disabled={locked || disableLockedLengthInput}
          variant="standard"
          InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
        />
        <Tooltip title={lockedLength ? 'Unlock' : 'Lock'}>
          {lockedLength || locked ? (
            <IconButton
              onClick={onLockHandler}
              sx={{
                marginLeft: theme.spacing(1),
                pointerEvents: locked ? 'none' : undefined,
              }}
              size="large"
            >
              <animated.span style={springStyleLockButton}>
                <LockIcon fontSize="small" />
              </animated.span>
            </IconButton>
          ) : (
            <IconButton onClick={onLockHandler} sx={{ marginLeft: theme.spacing(1) }} size="large">
              <animated.span style={springStyleLockButton}>
                <LockOpenIcon fontSize="small" />
              </animated.span>
            </IconButton>
          )}
        </Tooltip>
      </Stack>
      <SnappedButtons
        measurerId={measurerId}
        measurer={measurer}
        disabled={false}
        changeFormCoordinatesValue={changeFormCoordinatesValue}
        changeFormPropertiesValue={changeFormPropertiesValue}
      />
      <Stack direction="row" gap={2}>
        <TextField
          label="X1"
          type="number"
          value={x1}
          onChange={(e) => handleChangeExtremity('X1', e.target.value)}
          onKeyPress={(e) => handleEnterExtremity('X1', e)}
          onBlur={(e) => handleBlurExtremity('X1')}
          size="small"
          inputProps={{
            sx: {
              maxWidth: '100px',
            },
            step: 0.01,
          }}
          disabled={locked || disableLockedLengthInput || isAttached0 || lockOrientation}
          variant="standard"
          fullWidth
        ></TextField>
        <TextField
          label="Y1"
          type="number"
          value={y1}
          onChange={(e) => handleChangeExtremity('Y1', e.target.value)}
          onKeyPress={(e) => handleEnterExtremity('Y1', e)}
          onBlur={(e) => handleBlurExtremity('Y1')}
          size="small"
          inputProps={{
            sx: {
              maxWidth: '100px',
            },
            step: 0.01,
          }}
          disabled={locked || disableLockedLengthInput || isAttached0 || lockOrientation}
          variant="standard"
          fullWidth
        ></TextField>
      </Stack>
      <Stack direction="row" gap={2}>
        <TextField
          label="X2"
          type="number"
          value={x2}
          onChange={(e) => handleChangeExtremity('X2', e.target.value)}
          onKeyPress={(e) => handleEnterExtremity('X2', e)}
          onBlur={(e) => handleBlurExtremity('X2')}
          size="small"
          inputProps={{
            sx: {
              maxWidth: '100px',
            },
            step: 0.01,
          }}
          disabled={locked || disableLockedLengthInput || isAttached1 || lockOrientation}
          variant="standard"
          fullWidth
        ></TextField>
        <TextField
          label="Y2"
          type="number"
          value={y2}
          onChange={(e) => handleChangeExtremity('Y2', e.target.value)}
          onKeyPress={(e) => handleEnterExtremity('Y2', e)}
          onBlur={(e) => handleBlurExtremity('Y2')}
          size="small"
          inputProps={{
            sx: {
              maxWidth: '100px',
            },
            step: 0.01,
          }}
          disabled={locked || disableLockedLengthInput || isAttached1 || lockOrientation}
          variant="standard"
          fullWidth
        ></TextField>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="measurement-type" sx={{ transform: 'scale(0.75)' }}>
          Measurement type
        </InputLabel>
        <Select
          labelId="measurement-type"
          label="Measurement type"
          id="measurement-type-select"
          value={measurer.properties.measurementType}
          onChange={handleChangeMeasurementType}
          variant="standard"
          fullWidth
          disabled={locked}
        >
          <MenuItem value={MeasurementType.MinimumDistance}>Minimum Distance</MenuItem>
          <MenuItem value={MeasurementType.Center2Center}>Center to Center</MenuItem>
        </Select>
      </FormControl>
      <Stack alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={!!measurer.properties.showLength || measurer.properties.showLength === undefined}
              onChange={handleChangeShowLength}
              name="showLength"
              color="primary"
              disabled={locked}
            />
          }
          label="Display Length"
          sx={{ display: 'flex', direction: 'row-reverse' }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={!!measurer.properties.guide}
              onChange={handleChangeActGuide}
              name="ActAsAGuide"
              color="primary"
              disabled={locked}
            />
          }
          label="Act as a guide"
        />
      </Stack>

      <TextField
        type="number"
        value={newMeasurerAngle}
        onChange={handleMeasurerAngleChange}
        onBlur={updateMeasurerAngleChange}
        onKeyDown={handleKeyDown}
        fullWidth
        label="Orientation"
        disabled={locked || !editableOrientation}
        InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
        variant="standard"
      />
    </PropertiesComponent>
  );
};

interface CloseShapeData {
  distance: number;
  shape: LoadedSegment | LoadedPoint | LoadedRack | LoadedZone | LoadedStockZone;
  orientation?: number;
}

// attached and detached properties
export function SnappedButtons(props: SnappedButtonProps & MeasurerPropertiesProps): JSX.Element {
  const { measurer, measurerId, disabled } = props;
  const dispatch = useAppDispatch();

  const [openMenu, setOpenMenu] = useState<false | 0 | 1>(false);
  const attached = useRef(false);
  const measurerFirstEndPoint = 0;
  const measurerSecondEndPoint = 1;

  const segmentsIds = useAppSelector((state) => state.circuit.present.segments.ids);
  const pointsIds = useAppSelector((state) => state.circuit.present.points.ids);
  const racksIds = useAppSelector((state) => state.circuit.present.racks.ids);
  const zonesIds = useAppSelector((state) => state.circuit.present.zones.ids);
  const stockZonesIds = useAppSelector((state) => state.circuit.present.stockZones.ids);
  const segments = useAppSelector((state) => state.circuit.present.segments.entities);
  const points = useAppSelector((state) => state.circuit.present.points.entities);
  const racks = useAppSelector((state) => state.circuit.present.racks.entities);
  const zones = useAppSelector((state) => state.circuit.present.zones.entities);
  const stockZones = useAppSelector((state) => state.circuit.present.stockZones.entities);

  const menuTransitionDuration = 250; // ms

  /**
   * This ref is used to prevent the segment emphasization when the menu is closing
   * when we click on a segment in the list, the list updates
   * and the user mouse overs a new segment while the menu is closing
   * this cause the onMouseEnter event to be triggered and thereby the change of the stroeDasharray property of the other segment
   */
  const allowEmphasizeShape = useRef(true);

  const [nearestShapes, setNearestShapes] = useState<CloseShapeData[]>([]);

  const computeNearestShapes = useCallback(
    (endPoint: 0 | 1) => {
      const snapDistanceThreshold = 5.0; // in meters
      const maxNbShapes = 10;

      // to convert in cm
      const measurerCoords = measurer.geometry.coordinates;
      const x1 = measurerCoords[endPoint][0];
      const y1 = measurerCoords[endPoint][1];

      const shapesByDistance = [...segmentsIds, ...pointsIds, ...racksIds, ...zonesIds, ...stockZonesIds]
        .map((shapeId) => {
          if ([measurer.properties.link0?.id, measurer.properties.link1?.id].includes(shapeId)) return undefined;

          // compute the distance between the measurer and the segments
          const segment = segments[shapeId];

          if (segment) {
            const segCoords = segment.geometry.coordinates;
            const start1 = segCoords[0];
            const end1 = segCoords[1];

            const d = pDistance(x1, y1, start1[0], start1[1], end1[0], end1[1]); // in cm

            return { distance: d / 100, shape: segment };
          }

          const rack = racks[shapeId];

          if (rack) {
            const closestPoint = getClosestPointAndLineInPolygon(measurer.geometry.coordinates[endPoint], rack).point;

            const d = pDistance(
              closestPoint[0],
              closestPoint[1],
              measurerCoords[0][0],
              measurerCoords[0][1],
              measurerCoords[1][0],
              measurerCoords[1][1]
            );

            return { distance: d / 100, shape: rack };
          }

          const zone = zones[shapeId];

          if (zone) {
            const closestPoint = getClosestPointAndLineInPolygon(measurer.geometry.coordinates[endPoint], zone).point;

            const d = pDistance(
              closestPoint[0],
              closestPoint[1],
              measurerCoords[0][0],
              measurerCoords[0][1],
              measurerCoords[1][0],
              measurerCoords[1][1]
            ); // in cm

            return { distance: d / 100, shape: zone };
          }

          const stockZone = stockZones[shapeId];

          if (stockZone) {
            const closestPoint = getClosestPointAndLineInPolygon(
              measurer.geometry.coordinates[endPoint],
              stockZone
            ).point;

            const d = pDistance(
              closestPoint[0],
              closestPoint[1],
              measurerCoords[0][0],
              measurerCoords[0][1],
              measurerCoords[1][0],
              measurerCoords[1][1]
            ); // in cm

            return { distance: d / 100, shape: stockZone };
          }

          const point = points[shapeId];
          if (!point) {
            throw new Error(`point ${shapeId} not found`);
          } else {
            const ptCoords = point.geometry.coordinates;

            const d = getDistanceBetweenPoints([x1, y1], ptCoords);

            return { distance: d / 100, shape: point };
          }
        })
        .filter(isDefined)
        .filter((s) => s.distance < snapDistanceThreshold) // keep only the shapes that are close enough
        .sort((a, b) => a.distance - b.distance) // sort by distance
        .filter((_, i) => i < maxNbShapes) // keep only a certain maximum number of shapes
        .map(({ distance, shape }) => {
          // compute the orientation of the shapes

          let normalizedAngle: number | undefined = undefined;
          if (isCircuitSegment(shape)) {
            const coords = shape.geometry.coordinates;
            const computedAngle = findShapeOrientation([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
            normalizedAngle = (computedAngle + 360) % 360;
          }

          return {
            distance,
            shape,
            orientation: normalizedAngle,
          };
        });
      setNearestShapes(shapesByDistance);

      return shapesByDistance;
    },
    [
      measurer.geometry.coordinates,
      measurer.properties.link0?.id,
      measurer.properties.link1?.id,
      segmentsIds,
      pointsIds,
      racksIds,
      zonesIds,
      stockZonesIds,
      segments,
      racks,
      zones,
      stockZones,
      points,
    ]
  );

  const handleCloseMenu = useCallback(() => {
    allowEmphasizeShape.current = false;
    setOpenMenu(false);

    setTimeout(() => {
      allowEmphasizeShape.current = true;
    }, menuTransitionDuration);
  }, []);

  const handleOverReattachShape = useCallback((shapeId: string) => {
    if (!allowEmphasizeShape.current) {
      return;
    }

    startTransition(() => {
      /**
       * The following lines apply a class to the segment that is hovered
       * The idea is to emphasize the segment that will be connected to the turn
       */
      document.querySelector(`g[uuid="${shapeId}"]`)?.classList.add('highlighted-shape-attach');
      document.querySelector('#root')?.classList.add('enable-highlighted-shape-attach');
    });
  }, []);

  const handleOutReattachShape = useCallback((shapeId: string) => {
    // we remove the previously added class
    document.querySelector('#root')?.classList.remove('enable-highlighted-shape-attach');

    document
      .querySelectorAll('.highlighted-shape-attach')
      .forEach((el) => el.classList.remove('highlighted-shape-attach'));
  }, []);

  const handleButtonAttachEndPoint = useCallback(
    (endPoint: 0 | 1) => {
      if (!measurer) return;

      if (measurer.properties[`link${endPoint}`]) {
        dispatch(
          detachMeasurerAction({
            measurerId,
            endPoint,
          })
        );

        setTimeout(() => {
          CircuitService.getDrawingReference()?.getCircuitLayer().updateShapeProperties(measurerId);
        }, 0);
      } else {
        dispatch(
          attachMeasurerAction({
            endPoint,
            measurerId,
          })
        );
      }
    },
    [dispatch, measurer, measurerId]
  );

  const dropDownAnchorEl0 = useRef<HTMLElement | null>(null);
  const dropDownAnchorEl1 = useRef<HTMLElement | null>(null);
  const [selectedMenuOption, setSelectedMenuOption] = useState<0 | 1>(0);

  const menuAnchorEl = selectedMenuOption === 0 ? dropDownAnchorEl0.current : dropDownAnchorEl1.current;

  const handleButtonAttachEndPointTest = useCallback(
    (option: 0 | 1) => {
      setSelectedMenuOption(option);
      computeNearestShapes(option);
      setOpenMenu(option);
    },
    [computeNearestShapes]
  );

  const selectedAttachPoint = useRef<0 | 1 | undefined>();
  const handleSnapShape = useCallback(
    (shapeId: string) => {
      if (!measurer) return;
      handleCloseMenu();
      if (selectedMenuOption === measurerFirstEndPoint) {
        selectedAttachPoint.current = 0;
      } else if (selectedMenuOption === measurerSecondEndPoint) {
        selectedAttachPoint.current = 1;
      }

      const shape = segments[shapeId] ?? points[shapeId] ?? racks[shapeId] ?? zones[shapeId] ?? stockZones[shapeId];
      if (!shape) {
        // eslint-disable-next-line no-console
        console.error(`Shape of id ${shapeId} not found`);

        return;
      }

      handleOutReattachShape(shapeId);
      if (!shape) {
        // eslint-disable-next-line no-console
        console.warn(`Could not find segment ${shapeId}`);

        return;
      }

      dispatch(
        attachMeasurerAction({
          endPoint: selectedAttachPoint.current as 0 | 1,
          measurerId,
          shapeToAttach: shapeId,
        })
      );
      attached.current = true;
    },
    [
      dispatch,
      handleCloseMenu,
      handleOutReattachShape,
      measurer,
      measurerId,
      points,
      segments,
      racks,
      zones,
      stockZones,
      selectedMenuOption,
    ]
  );

  const changeHighlightStatusEndPoint = useCallback(
    (endPoint: 0 | 1, newState: boolean) => {
      d3.select(`#arrow-${endPoint === 0 ? 'start' : 'end'}-${measurerId}`).classed('highlighted', newState);
    },
    [measurerId]
  );

  const isAttached0 = !!measurer.properties.link0;
  const isAttached1 = !!measurer.properties.link1;
  const text0 = isAttached0 ? 'Detach' : 'Attach';
  const color0 = isAttached0 ? 'primary' : 'inherit';
  const text1 = isAttached1 ? 'Detach' : 'Attach';
  const color1 = isAttached1 ? 'primary' : 'inherit';

  const locked = measurer.properties.locked;
  const lockedLength = measurer.properties.lockedLength;

  return (
    <>
      <Stack direction="row" gap={1}>
        <Tooltip title={locked ? null : !isAttached0 ? attacheMeasurerTooltipText : detachMeasurerTooltipText}>
          <ButtonGroup
            sx={{ textDecoration: 'none' }}
            onMouseEnter={() => changeHighlightStatusEndPoint(0, true)}
            onMouseLeave={() => changeHighlightStatusEndPoint(0, false)}
            disabled={locked || (isAttached1 && lockedLength)}
            fullWidth
          >
            <Button
              color={color0}
              variant="contained"
              sx={{ textDecoration: 'none' }}
              size="small"
              onClick={() => handleButtonAttachEndPoint(0)}
              fullWidth
            >
              {text0}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => handleButtonAttachEndPointTest(0)}
              ref={(el) => (dropDownAnchorEl0.current = el)}
              disabled={disabled}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Tooltip>
        <Tooltip title={locked ? null : !isAttached1 ? attacheMeasurerTooltipText : detachMeasurerTooltipText}>
          <ButtonGroup
            sx={{ textDecoration: 'none' }}
            onMouseEnter={() => changeHighlightStatusEndPoint(1, true)}
            onMouseLeave={() => changeHighlightStatusEndPoint(1, false)}
            disabled={locked || (isAttached0 && lockedLength)}
            fullWidth
          >
            <Button
              color={color1}
              variant="contained"
              sx={{ textDecoration: 'none' }}
              size="small"
              onClick={() => handleButtonAttachEndPoint(1)}
              fullWidth
            >
              {text1}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => handleButtonAttachEndPointTest(1)}
              ref={(el) => (dropDownAnchorEl1.current = el)}
              disabled={disabled}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={menuAnchorEl}
        open={openMenu !== false}
        onClose={handleCloseMenu}
        transitionDuration={menuTransitionDuration}
      >
        {nearestShapes.length > 0 ? (
          [
            <MenuItem disabled key="nearest-shapes">
              <ListItemText>Nearest shapes</ListItemText>
            </MenuItem>,
            nearestShapes.map((shapeData) => {
              const shapeId = shapeData.shape.id;
              if (typeof shapeId !== 'string') return null;

              return (
                <MenuItem
                  key={shapeId ?? 'Shape id didnt found'}
                  onClick={() => handleSnapShape(shapeId)}
                  onMouseOver={() => handleOverReattachShape(shapeId)}
                  onMouseOut={() => handleOutReattachShape(shapeId)}
                >
                  <ListItemIcon>
                    <ShapeToAttachIcon shapeData={shapeData} />
                  </ListItemIcon>
                </MenuItem>
              );
            }),
          ]
        ) : (
          <MenuItem disabled>
            <ListItemText>No close shapes</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

interface ShapeToAttachIconProps {
  shapeData: CloseShapeData;
}
function ShapeToAttachIcon({ shapeData }: ShapeToAttachIconProps): JSX.Element {
  const shape = shapeData.shape;

  if (isCircuitSegment(shape)) {
    const layers = store.getState().circuit.present.layers.layers;
    const color = layers[shape.properties.layerId]?.color ?? '#000000';

    return (
      <>
        <ListItemIcon>
          <ArrowRightAltIcon
            sx={{
              transform: `rotate(${-(shapeData?.orientation ?? 0)}deg)`,
              color,
            }}
          />
        </ListItemIcon>
        <ListItemText>{shapeData.distance.toFixed(2)} m</ListItemText>
      </>
    );
  } else if (isCircuitPoint(shape)) {
    const layers = store.getState().circuit.present.layers.layers;
    const color = layers[shape.properties.layerId]?.color ?? '#000000';

    return (
      <>
        <ListItemIcon>
          <RadioButtonCheckedIcon sx={{ color }} />
        </ListItemIcon>
        <ListItemText>{shapeData.distance.toFixed(2)} m</ListItemText>
      </>
    );
  } else if (isCircuitRack(shape)) {
    const layers = store.getState().circuit.present.layers.layers;
    const color = layers[shape.properties.layerId]?.color ?? '#000000';

    return (
      <>
        <ListItemIcon>
          <RackIcon sx={{ color }} />
        </ListItemIcon>
        <ListItemText>{shapeData.distance.toFixed(2)} m</ListItemText>
      </>
    );
  } else if (isCircuitZone(shape)) {
    const layers = store.getState().circuit.present.layers.layers;
    const color = layers[shape.properties.layerId]?.color ?? '#000000';

    return (
      <>
        <ListItemIcon>
          <SquareOutlined sx={{ color, borderRadius: 10 }} />
        </ListItemIcon>
        <ListItemText>{shapeData.distance.toFixed(2)} m</ListItemText>
      </>
    );
  } else if (isCircuitStockZone(shape)) {
    const layers = store.getState().circuit.present.layers.layers;
    const color = layers[shape.properties.layerId]?.color ?? '#000000';

    return (
      <>
        <ListItemIcon>
          <StockZoneIcon sx={{ color }} />
        </ListItemIcon>
        <ListItemText>{shapeData.distance.toFixed(2)} m</ListItemText>
      </>
    );
  }

  assert<Equals<typeof shape, never>>();

  return <></>;
}
