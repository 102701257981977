/**
 * Remove keys for undefined values of an objet
 * This function *mutates* the object
 *
 * Do not throw but is no-op and returns the original value if the provided parameter is not an object
 *
 * @param obj The object to remove keys
 * @returns The mutated (cleaned) object
 */
export function removeUndefinedKeys<T>(obj: T): T {
  if (typeof obj !== 'object' || !obj) return obj;

  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (obj[key] === undefined) delete obj[key];
  });

  return obj;
}
