/**
 * Get the width of the text in an input field
 */
export const getInputValueWidth = (function () {
  // https://stackoverflow.com/a/49982135/104380
  function copyNodeStyle(sourceNode: HTMLElement, targetNode: HTMLElement): void {
    const computedStyle = window.getComputedStyle(sourceNode);
    Array.from(computedStyle).forEach((key) =>
      targetNode.style.setProperty(key, computedStyle.getPropertyValue(key), computedStyle.getPropertyPriority(key))
    );
  }

  function createInputMeassureElm(inputelm: HTMLInputElement): HTMLElement {
    // create a dummy input element for measurements
    const meassureElm = document.createElement('span');
    // copy the read input's styles to the dummy input
    copyNodeStyle(inputelm, meassureElm);

    // set hard-coded styles needed for propper meassuring
    meassureElm.style.width = 'auto';
    meassureElm.style.position = 'absolute';
    meassureElm.style.left = '-9999px';
    meassureElm.style.top = '-9999px';
    meassureElm.style.whiteSpace = 'pre';

    meassureElm.textContent = inputelm.value || '';

    // add the meassure element to the body
    document.body.appendChild(meassureElm);

    return meassureElm;
  }

  return function (input: HTMLInputElement) {
    return createInputMeassureElm(input).offsetWidth;
  };
})();
