import type { CircuitMeasurer } from 'models/circuit';
import { roundCoordinates } from 'utils/helpers';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: MeasurerActionTypes,
  creators: {
    createFailure: createMeasurerFailureAction,
    createSuccess: createMeasurerSuccessAction,
    delete: deleteMeasurerAction,
    deleteFailure: deleteMeasurerFailureAction,
    deleteSuccess: deleteMeasurerSuccessAction,
    loadOne: loadMeasurerAction,
    loadOneFailure: loadMeasurerFailureAction,
    loadOneSuccess: loadMeasurerSuccessAction,
    saveFailure: saveMeasurerFailureAction,
    saveSuccess: saveMeasurerSuccessAction,
    select: selectMeasurerAction,
  },
} = createEntityActions<CircuitMeasurer>('Measurers');

const {
  creators: { save },
} = createEntityActions<CircuitMeasurer>('Measurers');

export function saveMeasurerAction(payload: Partial<CircuitMeasurer> & RoadEditorAction['payload']): SaveMeasurer {
  let action = save(payload);
  delete payload.userAction;
  if (action.payload.geometry) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        geometry: {
          ...action.payload.geometry,
          coordinates: action.payload.geometry.coordinates.map((coords) => roundCoordinates(coords)),
        },
      },
    };
  }

  return action;
}

export interface CreateMeasurer extends CreateAction<CircuitMeasurer> {}
export interface CreateMeasurerFailure extends CreateFailureAction {}
export interface CreateMeasurerSuccess extends CreateSuccessAction<CircuitMeasurer> {}
export interface DeleteMeasurer extends DeleteAction {}
export interface DeleteMeasurerFailure extends DeleteFailureAction {}
export interface DeleteMeasurerSuccess extends DeleteSuccessAction {}
export interface LoadMeasurer extends LoadOneAction {}
export interface LoadMeasurerFailure extends LoadOneFailureAction {}
export interface LoadMeasurerSuccess extends LoadOneSuccessAction<CircuitMeasurer> {}
export interface SaveMeasurer extends SaveAction<CircuitMeasurer> {}
export interface SaveMeasurerFailure extends SaveFailureAction {}
export interface SaveMeasurerSuccess extends SaveSuccessAction<CircuitMeasurer> {}
export interface SelectMeasurer extends SelectAction {}

export type MeasurerActions =
  | CreateMeasurer
  | CreateMeasurerFailure
  | CreateMeasurerSuccess
  | DeleteMeasurer
  | DeleteMeasurerFailure
  | DeleteMeasurerSuccess
  | LoadMeasurer
  | LoadMeasurerFailure
  | LoadMeasurerSuccess
  | SaveMeasurer
  | SaveMeasurerFailure
  | SaveMeasurerSuccess
  | SelectMeasurer;
