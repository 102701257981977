import { createSelector } from 'reselect';
import type { AppState } from '../state';
import { getLoggedIn, getProfile, getLocale, getToken } from './reducer';
import type { AuthState } from './state';

function authSelector(state: AppState): AuthState {
  return state.auth;
}

export const selectUserLoggedIn = createSelector(authSelector, getLoggedIn);
export const selectUserProfile = createSelector(authSelector, getProfile);
export const selectUserLocale = createSelector(authSelector, getLocale);
export const selectTokenStatus = createSelector(authSelector, getToken);
