import { TableCell, TableRow, tableCellClasses } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from 'utils/mui-theme';

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[50],
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const StyledTableRowFlowTimes = styled(TableRow)(() => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: theme.palette.grey[400],
  },
  '&:nth-of-type(4n+2)': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:nth-of-type(4n+3)': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:nth-of-type(4n+4)': {
    backgroundColor: theme.palette.grey[300],
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const styledTableCellColor = theme.palette.primary.contrastText;
export const StyledTableCell = styled(TableCell)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    color: (sx && 'color' in sx ? sx?.color : undefined) ?? styledTableCellColor,
  },
}));
