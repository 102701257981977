import { useEffect, useState } from 'react';

/**
 * Detects if Sentry is blocked.
 * @returns {Promise<boolean>} A promise that resolves to true if Sentry is blocked, false otherwise.
 */
function detectSentryBlocked(): Promise<boolean> {
  const sentryUrl = `https://o1127159.ingest.sentry.io/api/6417371/envelope/?sentry_key=8f845bb769e24d9eb37a25bfd63b9c96&sentry_version=7&sentry_client=sentry.javascript.react%2F7.60.0`;

  return new Promise((resolve, reject) => {
    fetch(sentryUrl, {
      method: 'POST',
      mode: 'no-cors',
    })
      .then((response) => {
        resolve(false);
      })
      .catch((error) => {
        resolve(true);
      });
  });
}

/**
 * A hook that detects if Sentry is blocked.
 * @returns {boolean} A boolean that is true if Sentry is blocked, false otherwise.
 */
export function useDetectSentryBlocked(): boolean {
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    detectSentryBlocked().then((blocked) => {
      setIsBlocked(blocked);
    });
  }, []);

  return isBlocked;
}
