import type { ProxyMethods, RemoteObject } from 'comlink';
import type { CantonAtPoint, DeadendsAtPoint } from 'models/circuit';
import { isCantonAtPoint, isDeadendsAtPoint } from 'models/circuit.guard';
import { isRobotOccupation } from 'models/simulation.guard';
import type { Robot } from 'robots/robots';
import type { Itinerary } from 'routes/routes';
import store from 'store';
import type { CantonsDict } from 'traffic/traffic';
import { computeDistanceListOfPositions, simplifyListOfPositions } from 'utils/circuit/simplify-itinerary';
import { canBeUndefined } from 'utils/ts/is-defined';
import type { Simulation } from './simulation.model';

export interface DisplayTrafficOptions {
  simulationService: RemoteObject<Simulation> & ProxyMethods;
  minimumDistanceBetweenPointsRobotItinerary: number;
  robots: Robot[];
}

export const displayTraffic = async (
  robot: Robot,
  options: DisplayTrafficOptions
): Promise<[Itinerary | undefined, CantonsDict, CantonsDict, Robot]> => {
  const simulationService = options.simulationService;
  const minimumDistanceBetweenPointsRobotItinerary = options.minimumDistanceBetweenPointsRobotItinerary;
  const robots = options.robots;

  let itineraries: Itinerary | undefined = undefined;

  const occupiedCantons: CantonsDict = {};
  const reservedCantons: CantonsDict = {};

  let modifiedRobot = { ...robot };

  if (simulationService) {
    const robotOccupationStrPtr = await simulationService._TRAFFIC_WasmWrapper_getRobotOccupation(robot.id);
    const robotOccupationStr = robotOccupationStrPtr ? await simulationService.UTF8ToString(robotOccupationStrPtr) : '';
    let robotOccupation: unknown | undefined;
    try {
      robotOccupation = JSON.parse(robotOccupationStr) as unknown;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while parsing the robot occupation', e, robotOccupationStr);
    }

    if (robotOccupation) {
      if (isRobotOccupation(robotOccupation)) {
        if (robotOccupation.checkDescription) {
          const description = robotOccupation.checkDescription;
          const isRobotStopped = description.startsWith('Stop');

          if (!modifiedRobot.trafficData) {
            modifiedRobot = {
              ...modifiedRobot,
              trafficData: {},
            };
          }

          const splitDescription = description.split('|');
          const info = splitDescription[2];

          modifiedRobot = {
            ...modifiedRobot,
            trafficData: {
              ...modifiedRobot.trafficData,
              otherRobotBlocking: undefined,
              info: info,
            },
          };

          if (isRobotStopped) {
            const otherRobotId = parseInt(splitDescription[1], 10);
            const otherRobotName = robots.find((r) => r.id === otherRobotId)?.name;

            modifiedRobot = {
              ...modifiedRobot,
              trafficData: {
                ...modifiedRobot.trafficData,
                otherRobotBlocking: otherRobotName,
              },
            };
          }
        }

        if (robotOccupation.itinerary) {
          const simplifiedItinerary = simplifyListOfPositions(
            robotOccupation.itinerary,
            minimumDistanceBetweenPointsRobotItinerary
          );
          const distance = computeDistanceListOfPositions(robotOccupation.itinerary);

          itineraries = {
            id: `${robot.name}-itinerary-${robotOccupation.itinerary.at(-1)?.x}-${robotOccupation.itinerary.at(-1)?.y}`,
            error: 'OK',
            errorId: 0,
            positions: simplifiedItinerary.map((position) => ({
              x: position.x * 100,
              y: -position.y * 100,
            })),
            distance: distance * 100,
          };
        }

        const occupiedCantonsKeys = robotOccupation.occupiedCantons
          ? Object.keys(robotOccupation.occupiedCantons)
          : null;
        if (occupiedCantonsKeys && occupiedCantonsKeys.length && robotOccupation.occupiedCantons) {
          for (let j = 0; j < occupiedCantonsKeys.length; j++) {
            const cantonId = occupiedCantonsKeys[j];
            const canton = robotOccupation.occupiedCantons[cantonId];
            if (canton) {
              occupiedCantons[cantonId] = canton;
            }
          }
        }

        const reservedCantonsKeys = robotOccupation.reservedCantons
          ? Object.keys(robotOccupation.reservedCantons)
          : null;
        if (reservedCantonsKeys && reservedCantonsKeys.length && robotOccupation.reservedCantons) {
          for (let j = 0; j < reservedCantonsKeys.length; j++) {
            const cantonId = reservedCantonsKeys[j];
            const canton = robotOccupation.reservedCantons[cantonId];
            if (canton) {
              reservedCantons[cantonId] = canton;
            }
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('robotOccupation is not a RobotOccupation', robotOccupation);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('robotOccupation is undefined', robotOccupation);
    }
  }

  return [itineraries, occupiedCantons, reservedCantons, modifiedRobot];
};

interface DisplayAdvancedTrafficOptions {
  simulationService: RemoteObject<Simulation> & ProxyMethods;
}

export const displayAdvancedTraffic = async (
  robot: Robot,
  options: DisplayAdvancedTrafficOptions
): Promise<(CantonAtPoint & DeadendsAtPoint) | null> => {
  const { simulationService } = options;
  const { x, y, heading } = robot.position;

  const layerGroups = store.getState().circuit.present.layers.layerGroups;

  const layerGroupId = robot.layerGroup;
  const layerGroup = canBeUndefined(layerGroups[layerGroupId]);

  if (!layerGroup) {
    // eslint-disable-next-line no-console
    console.error('Layer group not found', layerGroupId);

    return null;
  }

  const layerGroupName = layerGroup.name;
  const layerNamePtr = await simulationService.allocateUTF8(layerGroupName);

  let cantonDataPtr: number | null = null;
  try {
    cantonDataPtr = await simulationService._TRAFFIC_WasmWrapper_getCanton(layerNamePtr, x, y, heading);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while getting the canton', e);

    return null;
  }

  const cantonDataStr = cantonDataPtr ? await simulationService.UTF8ToString(cantonDataPtr) : null;
  const cantonData = ((): unknown => {
    try {
      return JSON.parse(cantonDataStr || '');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while parsing the canton JSON', e);

      return '';
    }
  })();

  let deadendsDataPtr: number | null = null;
  try {
    deadendsDataPtr = await simulationService._TRAFFIC_WasmWrapper_getDeadend(layerNamePtr, x, y, heading);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while getting the deadends', e);

    return null;
  }

  const deadendsDataStr = deadendsDataPtr ? await simulationService.UTF8ToString(deadendsDataPtr) : null;

  const deadendsData = ((): unknown => {
    try {
      return JSON.parse(deadendsDataStr || '');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while parsing the deadends JSON', e);

      return '';
    }
  })();

  await simulationService._OS_WasmWrapper_free(layerNamePtr);

  if (!isCantonAtPoint(cantonData)) {
    // eslint-disable-next-line no-console
    console.error('Error while getting the canton: wrong types', cantonData);

    return null;
  } else if (!isDeadendsAtPoint(deadendsData)) {
    // eslint-disable-next-line no-console
    console.error('Error while getting the deadends: wrong types', deadendsData);

    return null;
  }

  return {
    ...cantonData,
    ...deadendsData,
  };
};
