import { writeToFile } from 'utils/preferences';

export function generatePrefXmlArray(
  specificDirHandle: FileSystemDirectoryHandle,
  xmlDocuments: { name: string; document: Document }[]
): Promise<void>[] {
  const XMLSavePromiseArray: Promise<void>[] = [];
  const serializer = new XMLSerializer();

  xmlDocuments.forEach(async ({ name, document }) => {
    const xmlFilename = `${name}.xml`;
    let xmlString = serializer.serializeToString(document);

    // Add a newline before the pref element
    xmlString = xmlString.replace('></Preferences>', '>\n</Preferences>').replace('<Preferences ', '\n<Preferences ');

    // Add the XML header if it's not already there
    if (!xmlString.startsWith('<?xml version="1.0" encoding="utf-8"?>')) {
      xmlString = `<?xml version="1.0" encoding="utf-8"?>${xmlString}`;
    }

    const XMLBlob = new Blob([xmlString], { type: 'text/xml', endings: 'native' });
    const fileHandleXML = await specificDirHandle.getFileHandle(xmlFilename, { create: true });

    const XMLSavePromise = writeToFile(fileHandleXML, XMLBlob);

    XMLSavePromiseArray.push(XMLSavePromise);
  });

  return XMLSavePromiseArray;
}
