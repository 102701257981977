import { SnackbarUtils } from 'services/snackbar.service';
import { PreferencesService } from './preferences';

/**
 * Function to check the uniqueness of truck IPs
 * @returns A boolean indicating whether the truck IPs are unique
 */
export function checkUnicityTrucksIps(): boolean {
  if (!PreferencesService.arePreferencesFullyLoaded()) return false;

  try {
    const IPs = PreferencesService.getPreferenceValue('IP_trucks');

    if (!Array.isArray(IPs)) return true;

    const uniqueIPs = new Set(IPs);

    return uniqueIPs.size === IPs.length;
  } catch (e) {
    return true;
  }
}

/**
 * Function to check the uniqueness of truck IPs and display a snackbar if they are not unique
 * @returns A boolean indicating whether the truck IPs are unique
 */
export function checkUnicityTrucksIpsAndDisplayError(): boolean {
  if (!PreferencesService.arePreferencesFullyLoaded()) return false;

  const unicity = checkUnicityTrucksIps();

  if (!unicity) {
    const msg = 'They are duplicates in the trucks IPs. Please check the preferences.';
    // eslint-disable-next-line no-console
    console.warn(msg);
    SnackbarUtils.warning(msg);
  }

  return unicity;
}
