import store from 'store';

/**
 * Checks if there is at least one battery point in the circuit.
 * @returns {boolean} - True if there is at least one battery point, false otherwise.
 */
export function isThereAtLeastOneBattPoint(): boolean {
  const circuitState = store.getState().circuit.present;
  const pointsIds = circuitState.points.ids;
  const points = circuitState.points.entities;

  return pointsIds.some((pointId) => {
    const point = points[pointId];

    return point.properties.isBattery;
  });
}
