import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EvStationIcon from '@mui/icons-material/EvStation';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import type { SelectChangeEvent } from '@mui/material';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { findShapeOrientation, getClosestPointInSegment, pDistance } from 'drawings/helpers';
import { useYupValidation } from 'hooks';
import { isEqual } from 'lodash';
import type { CircuitPoint, GabaritProperties, SegmentDataInPoint } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircuitService } from 'services/circuit.service';
import type { OPCCharger, OPCConfiguration } from 'simulation/opc';
import { getOpcConfig, saveOpcConfigurationOnDisk } from 'simulation/opc';
import { useAppSelector } from 'store';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { getGabarit } from 'utils/circuit/get-gabarit';
import { integerInputIfNeeded } from 'utils/helpers';
import { PreferencesService, getPreferenceValue } from 'utils/preferences';
import { isDefined } from 'utils/ts/is-defined';
import { GabaritSelect } from '../components/gabarit-select';
import { TeleporterInput } from '../components/teleporter-input';
import { PropertiesComponent } from '../properties-component';
import { nbDigitsOrientationValue } from '../rack-properties/rack-properties';
import { schemaCoordinates, schemaProperties } from './schema';

const useStyles = makeStyles((theme) =>
  createStyles({
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },

    helpTooltip: {
      verticalAlign: 'middle',
      cursor: 'help',
      opacity: 0.7,
      maxHeight: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  })
);

export interface PointPropertiesProps {
  point?: CircuitPoint;
  onSubmit: (payload: Partial<CircuitPoint>) => void;
  onDelete: (payload: string) => void;
}

export function PointPropertiesComponent({ point, onSubmit, onDelete }: PointPropertiesProps): JSX.Element | null {
  const pointId = point?.id;

  if (!point || !pointId) {
    return null;
  }

  return <PointPropertiesForm key={pointId} point={point} pointId={pointId} onSubmit={onSubmit} onDelete={onDelete} />;
}

interface PointPropertiesFormProps {
  point: CircuitPoint;
  pointId: number | string;
  onSubmit: (payload: Partial<CircuitPoint>) => void;
  onDelete: (payload: string) => void;
}

export interface PointPropertiesFormValue {
  name: string;
  type: ShapeTypes.PointShape;
  orientation: number;
  isTaxi: boolean;
  isInit: boolean;
  isBattery: boolean;
  isTeleportation: boolean;
  height: any;
  minimumInitScore?: number;
  layerId: string;
  gabarit?: GabaritProperties;
  prio: number;
  segment?: SegmentDataInPoint;
  batteryType?: string;
  floorLevel?: string;
  elevatorName?: string;
}

interface PointsCoordinatesForm {
  coordinates: [number, number];
}

function PointPropertiesForm({ point, pointId, onSubmit, onDelete }: PointPropertiesFormProps): JSX.Element | null {
  const classes = useStyles();

  const [formId, setFormId] = useState<number | string>(pointId);
  const [formCoordinates, setRealFormCoordinates] = useState<PointsCoordinatesForm>({
    coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
  });
  const [hasXCoordinatesBeenModified, setHasXCoordinatesBeenModified] = useState(false);
  const [hasYCoordinatesBeenModified, setHasYCoordinatesBeenModified] = useState(false);
  const setFormCoordinates = useCallback((newCoords: React.SetStateAction<PointsCoordinatesForm>) => {
    setRealFormCoordinates(newCoords);
  }, []);
  const [formProperties, setFormProperties] = useState<PointPropertiesFormValue>(point.properties);

  const [formIsDirty, setFormIsDirty] = useState(false);

  const [yupControlCoordinatesProps] = useYupValidation(schemaCoordinates, formCoordinates);
  const [yupControlPropertiesProps] = useYupValidation(schemaProperties, formProperties);

  const [displayElevatorOptions, setDisplayElevatorOptions] = useState(false);

  useEffect(() => {
    let isUseElevatorEnabled: boolean | undefined;
    try {
      isUseElevatorEnabled = !!Number(PreferencesService.getPreferenceValue('general/useElevator'));
    } catch (e) {}

    if (!isUseElevatorEnabled) return;

    setDisplayElevatorOptions(true);
  }, []);

  const [opcData, setOpcData] = useState<OPCConfiguration | undefined>(undefined);

  const getOpcData = async (): Promise<void> => {
    const opcConfig = await getOpcConfig();

    setOpcData(opcConfig);
  };

  useEffect(() => {
    getOpcData();
  }, []);

  const defaultBatteryType = useMemo(() => {
    if (opcData) {
      const linkedChargerName = Object.keys(opcData.chargers).find((charger) => charger.includes(formProperties.name));

      if (linkedChargerName) {
        return opcData.chargers[linkedChargerName].chargerType;
      }
    }

    return 'none';
  }, [opcData, formProperties.name]);

  const trucks = PreferencesService.getTrucks();
  const allBatteryTypesSet = new Set<string>();

  trucks.forEach((truck) => {
    try {
      const truckBatteryType = getPreferenceValue('battery/batteryType', truck.serial);
      if (typeof truckBatteryType !== 'string') {
        // eslint-disable-next-line no-console
        console.error(`Could not find battery type for truck ${truck.serial}`);

        return;
      }

      allBatteryTypesSet.add(truckBatteryType);
    } catch (e) {}
  });

  const allBatteryTypes = Array.from(allBatteryTypesSet);

  const changeFormCoordinatesValue = useCallback(
    (value: (PointsCoordinatesForm: PointsCoordinatesForm) => PointsCoordinatesForm) => {
      setFormIsDirty(true);

      setFormCoordinates(value);
    },
    [setFormCoordinates, setFormIsDirty]
  );

  const changeFormPropertiesValue = useCallback(
    (value: (value: PointPropertiesFormValue) => PointPropertiesFormValue) => {
      setFormIsDirty(true);

      setFormProperties(value);
    },
    [setFormProperties, setFormIsDirty]
  );

  useEffect(() => {
    if (
      formIsDirty &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      isEqual(point.properties, formProperties) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      isEqual(
        {
          coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
        },
        formCoordinates
      )
    ) {
      setFormIsDirty(false);
    }
  }, [point, formProperties, formCoordinates, formIsDirty]);

  useEffect(() => {
    if (pointId !== formId) {
      setFormId(() => pointId);
      setFormCoordinates(() => ({
        coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
      }));
    } else {
      if (
        !formIsDirty &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        !isEqual(
          {
            coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
          },
          formCoordinates
        )
      ) {
        setFormCoordinates(() => ({
          coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
        }));
      }
    }
  }, [point, pointId, formCoordinates, setFormCoordinates, formIsDirty, formId, setFormId]);

  useEffect(() => {
    if (pointId !== formId) {
      setFormId(() => pointId);
      setFormProperties(() => point.properties);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (!formIsDirty && !isEqual(point.properties, formProperties)) {
        setFormProperties(() => point.properties);
      }
    }
  }, [point, pointId, formProperties, setFormProperties, formIsDirty, formId, setFormId]);

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');
  const onNameChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const newName = event.target.value;
      changeFormPropertiesValue((state) => ({ ...state, name: newName }));

      startTransition(() => {
        const isNameAlreadyUsed = !areAllShapeNamesUnique([newName], [pointId as string], {
          ignoreDuplicatesBefore: true,
        });

        if (!newName || isNameAlreadyUsed) {
          setErrorName(true);
          setSuggestedName(CircuitService.generateDifferentName(newName, { shapeIdsToIgnore: [pointId as string] }));
        } else {
          setErrorName(false);
        }
      });
    },
    [changeFormPropertiesValue, pointId]
  );
  const setSuggestedNameHandler = useCallback(() => {
    changeFormPropertiesValue((state) => ({ ...state, name: suggestedName }));
    setErrorName(false);
  }, [changeFormPropertiesValue, suggestedName]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorName(false), [pointId]);

  useEffect(() => {
    if (formIsDirty && schemaProperties.isValidSync(formProperties) && schemaCoordinates.isValidSync(formCoordinates)) {
      const propertiesForm = { ...formProperties, ...schemaProperties.cast(formProperties) };
      const coordinatesForm = { ...formCoordinates, ...schemaCoordinates.cast(formCoordinates) };

      if (!coordinatesForm.coordinates) {
        // eslint-disable-next-line no-console
        console.error('coordinatesForm.coordinates is undefined');

        return;
      }

      if (
        !areAllShapeNamesUnique([propertiesForm.name], [pointId as string], {
          ignoreDuplicatesBefore: true,
        })
      )
        return;

      onSubmit({
        id: formId,
        properties: propertiesForm,
        geometry: { type: 'Point', coordinates: [coordinatesForm.coordinates[0], coordinatesForm.coordinates[1]] },
      });

      setFormIsDirty(false);
    }
  }, [
    formProperties,
    formCoordinates,
    onSubmit,
    formId,
    formIsDirty,
    pointId,
    hasXCoordinatesBeenModified,
    hasYCoordinatesBeenModified,
  ]);

  const onXCoordChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      changeFormCoordinatesValue((state: PointsCoordinatesForm) => ({
        ...state,
        coordinates: [parseFloat(event.target.value) || 0, state.coordinates[1]] as [number, number],
      }));
      setHasXCoordinatesBeenModified(true);
    },
    [changeFormCoordinatesValue]
  );

  const onYCoordChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      changeFormCoordinatesValue((state: PointsCoordinatesForm) => ({
        ...state,
        coordinates: [state.coordinates[0], parseFloat(event.target.value) || 0] as [number, number],
      }));
      setHasYCoordinatesBeenModified(true);
    },
    [changeFormCoordinatesValue]
  );

  const onOrientationChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const inputValue = parseFloat(event.currentTarget.value);
      let newValue = parseFloat(integerInputIfNeeded(inputValue, nbDigitsOrientationValue));

      if (event.currentTarget.value === '') newValue = 0;

      changeFormPropertiesValue((state) => ({ ...state, orientation: newValue }));
    },
    [changeFormPropertiesValue]
  );

  const onMinimumInitScoreChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      changeFormPropertiesValue((state) => ({ ...state, minimumInitScore: parseInt(e.target.value, 10) || 0 }));
    },
    [changeFormPropertiesValue]
  );

  const handleIsTaxiChange = useCallback(
    (event) => changeFormPropertiesValue((state) => ({ ...state, isTaxi: !state.isTaxi })),
    [changeFormPropertiesValue]
  );

  const handleIsInitChange = useCallback(
    (event) => {
      changeFormPropertiesValue((state) => {
        const newInitState = !state.isInit;
        const newState = { ...state, isInit: newInitState };
        if (!newInitState) delete newState.minimumInitScore;

        return newState;
      });
    },
    [changeFormPropertiesValue]
  );

  const createNewOpcCharger = useCallback(
    (batteryType: string): OPCCharger => {
      const dockingType: OPCCharger['dockingType'] = batteryType.toLowerCase().includes('lead') ? 'MANUAL' : 'AUTO';

      const robots = getRobotsEmulation();

      const robotAuth: number[] = robots
        .map((robot) => {
          if (batteryType.includes(robot.batteryModel)) {
            return robot.ID;
          }

          return undefined;
        })
        .filter(isDefined);

      const newCharger: OPCCharger = {
        destPoints: [formProperties.name],
        chargerType: batteryType,
        dockingType: dockingType,
        robotAuth: robotAuth,
      };

      return newCharger;
    },
    [formProperties.name]
  );

  const handleIsBatteryChange = useCallback(
    (event) => {
      const newIsBattery = !formProperties.isBattery;

      const newBatteryType = newIsBattery ? allBatteryTypes[0] : undefined;

      if (opcData) {
        const newOPCData = { ...opcData };

        if (formProperties.batteryType) {
          const chargerName = `${formProperties.batteryType}_${formProperties.name}`;

          const batteryPointToRemove = opcData.chargers[chargerName];

          if (batteryPointToRemove) {
            delete newOPCData.chargers[chargerName];
          }
        } else {
          const chargerName = `${allBatteryTypes[0]}_${formProperties.name}`;

          const newCharger = createNewOpcCharger(allBatteryTypes[0]);

          newOPCData.chargers[chargerName] = newCharger;
        }

        saveOpcConfigurationOnDisk(newOPCData);
      }

      changeFormPropertiesValue((state) => ({ ...state, isBattery: newIsBattery, batteryType: newBatteryType }));
    },

    [
      allBatteryTypes,
      changeFormPropertiesValue,
      createNewOpcCharger,
      formProperties.batteryType,
      formProperties.isBattery,
      formProperties.name,
      opcData,
    ]
  );

  const handleChangeGabarit = useCallback(
    (newGabarit: GabaritProperties) => {
      changeFormPropertiesValue((state) => ({
        ...state,
        gabarit: newGabarit,
      }));

      // startTransition(() => dispatch(savePointAction({ idToUpdate: turnId, gabarit: newGabarit, userAction: true })));
    },
    [changeFormPropertiesValue]
  );

  const locked = !!point?.properties?.locked;
  const isSnapped = !!point?.properties?.segment;

  const prefsLoaded = PreferencesService.arePreferencesFullyLoaded();
  const modelsName = useMemo(() => PreferencesService.getModelNames(), []);

  const gabarit = useMemo(
    () => getGabarit(point?.properties.gabarit, modelsName),
    [modelsName, point?.properties.gabarit]
  );

  return (
    <PropertiesComponent shape={point} shapeId={pointId as string} shapeType={ShapeTypes.PointShape}>
      <FormControl>
        <TextField
          {...yupControlPropertiesProps.name}
          value={formProperties.name}
          onChange={onNameChangeHandler}
          fullWidth
          label="Name"
          disabled={locked}
          error={errorName}
          variant="standard"
          sx={{
            textDecoration: errorName ? 'line-through' : undefined,
          }}
        />
        <FormHelperText className={classes.helperText} error id="suggested-name-point">
          {errorName ? (
            <>
              Name already used, suggested name:{' '}
              <span onClick={setSuggestedNameHandler} className={classes.suggestedName}>
                {suggestedName}
              </span>
            </>
          ) : (
            <></>
          )}
        </FormHelperText>
      </FormControl>
      <Stack direction="row" gap={1} sx={{ width: '300px' }}>
        <TextField
          {...yupControlCoordinatesProps.coordinates[0]}
          value={
            hasXCoordinatesBeenModified
              ? formCoordinates.coordinates[0]
              : Number(formCoordinates.coordinates[0]).toFixed(3)
          }
          onChange={onXCoordChangeHandler}
          label="X"
          type="number"
          inputProps={{ step: 0.01 }}
          disabled={locked || isSnapped}
          variant="standard"
          fullWidth
        />
        <TextField
          {...yupControlCoordinatesProps.coordinates[1]}
          value={
            hasYCoordinatesBeenModified
              ? formCoordinates.coordinates[1]
              : Number(formCoordinates.coordinates[1]).toFixed(3)
          }
          onChange={onYCoordChangeHandler}
          label="Y"
          type="number"
          inputProps={{ step: 0.01 }}
          disabled={locked || isSnapped}
          variant="standard"
          fullWidth
        />
      </Stack>
      <TextField
        value={formProperties.orientation.toString()}
        onChange={onOrientationChangeHandler}
        fullWidth
        label={
          <>
            Orientation{' '}
            <Tooltip
              className={classes.helpTooltip}
              title="Orientation of the point. Not used in this current version of Road Editor."
            >
              <HelpIcon />
            </Tooltip>
          </>
        }
        type="number"
        inputProps={{ min: -180, max: 180, step: 1 }}
        InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
        disabled={locked || isSnapped}
        variant="standard"
      />

      <SnappedButton
        point={point}
        changeFormCoordinatesValue={changeFormCoordinatesValue}
        changeFormPropertiesValue={changeFormPropertiesValue}
        disabled={locked}
      />

      <List
        sx={{
          color: locked ? 'rgba(0, 0, 0, 0.38)' : undefined,
          pointerEvents: locked ? 'none' : undefined,
          padding: 0,
        }}
      >
        <ListItemButton onClick={handleIsTaxiChange}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-taxi"
            primary={
              <>
                Taxi <HelpIconTooltip title="The robots will go to this position when it has nothing to do." />
              </>
            }
          />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={formProperties.isTaxi}
              inputProps={{ 'aria-labelledby': 'switch-list-label-taxi' }}
              disabled={locked}
              tabIndex={-1}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton onClick={handleIsInitChange}>
          <ListItemIcon>
            <GpsFixedIcon />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-taxi"
            primary={
              <>
                Init <HelpIconTooltip title="Position where people can initialize the position of the robots." />
              </>
            }
          />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={formProperties.isInit}
              inputProps={{ 'aria-labelledby': 'switch-list-label-init' }}
              disabled={locked}
              tabIndex={-1}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        <Collapse in={formProperties.isInit} timeout="auto" unmountOnExit>
          <Border>
            <TextField
              value={formProperties.minimumInitScore || 1000}
              onChange={onMinimumInitScoreChangeHandler}
              fullWidth
              label={
                <>
                  Minimum Init Score{' '}
                  <HelpIconTooltip title=' title="Add a minimum initialization score to reach to consider the initialization process as valid. When a robot starts an initialization process, it computes a confidence score. If too low, the robot may initialize at a wrong location. Prefer a high initialization score."' />
                </>
              }
              type="number"
              inputProps={{ step: 1, min: 0 }}
              disabled={locked || !formProperties.isInit}
              InputLabelProps={{ shrink: true }}
              variant="standard"
            />
          </Border>
        </Collapse>
        <ListItemButton onClick={handleIsBatteryChange}>
          <ListItemIcon>
            <EvStationIcon />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-batt"
            primary={
              <>
                Battery{' '}
                <HelpIconTooltip title="Position where the robots go when their battery level is low. It can be a charger (LTO/TPPL) or a position close to a lead acid battery (table change)." />
              </>
            }
          />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={formProperties.isBattery}
              inputProps={{ 'aria-labelledby': 'switch-list-label-batt' }}
              disabled={locked}
              tabIndex={-1}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        <Collapse in={formProperties.isBattery} timeout="auto" unmountOnExit>
          <Border>
            <BatteryPointInput
              formProperties={formProperties}
              opcData={opcData}
              createNewOpcCharger={createNewOpcCharger}
              changeFormPropertiesValue={changeFormPropertiesValue}
              defaultBatteryType={defaultBatteryType}
              allBatteryTypes={allBatteryTypes}
            />
          </Border>
        </Collapse>
        {displayElevatorOptions && (
          <TeleporterInput
            formProperties={formProperties}
            changeFormPropertiesValue={changeFormPropertiesValue}
            locked={locked}
          />
        )}
      </List>
      <GabaritSelect
        // formControlClassName={classes.formControl}
        handleChangeGabarit={handleChangeGabarit}
        gabarit={gabarit}
        disabled={!prefsLoaded || !modelsName.length}
      />
    </PropertiesComponent>
  );
}

interface SnappedButtonProps {
  point: CircuitPoint;
  changeFormCoordinatesValue: (value: (PointsCoordinatesForm: PointsCoordinatesForm) => PointsCoordinatesForm) => void;
  changeFormPropertiesValue: (value: (value: PointPropertiesFormValue) => PointPropertiesFormValue) => void;
  disabled?: boolean;
}
function SnappedButton(props: SnappedButtonProps): JSX.Element {
  const { point, changeFormCoordinatesValue, changeFormPropertiesValue, disabled } = props;

  const [hovering, setHovering] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const segmentsIds = useAppSelector((state) => state.circuit.present.segments.ids);
  const segments = useAppSelector((state) => state.circuit.present.segments.entities);

  const isSnapped = !!point.properties.segment;
  const pointSegmentId = point.properties?.segment?.id;

  const text = isSnapped ? (hovering ? 'Unsnap' : 'Snapped') : 'Not snapped';
  const color = isSnapped ? (hovering ? 'secondary' : 'primary') : 'inherit';

  const menuTransitionDuration = 250; // ms
  const strokeDasharrayClassName = 'point-dragging-over';

  /**
   * This ref is used to prevent the segment emphasization when the menu is closing
   * when we click on a segment in the list, the list updates
   * and the user mouse overs a new segment while the menu is closing
   * this cause the onMouseEnter event to be triggered and thereby the change of the stroeDasharray property of the other segment
   */
  const allowEmphasizeSegment = useRef(true);

  const onMouseEnter = useCallback(() => {
    setHovering(true);

    if (isSnapped && pointSegmentId) {
      const associatedSegmentEl = document.querySelector(`[uuid='${pointSegmentId}']`);
      if (associatedSegmentEl && associatedSegmentEl instanceof SVGElement) {
        associatedSegmentEl.classList.add(strokeDasharrayClassName);
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Could not find segment element ${associatedSegmentEl}`);
      }
    }
  }, [isSnapped, pointSegmentId]);

  const onMouseLeave = useCallback(() => {
    setHovering(false);

    if (isSnapped && pointSegmentId) {
      const associatedSegmentEl = document.querySelector(`[uuid='${pointSegmentId}']`);
      if (associatedSegmentEl && associatedSegmentEl instanceof SVGElement) {
        associatedSegmentEl.classList.remove(strokeDasharrayClassName);
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Could not find segment element ${associatedSegmentEl}`);
      }
    }
  }, [isSnapped, pointSegmentId]);

  const handleClick = useCallback(() => {
    if (isSnapped) {
      // remove the stroke dash array
      onMouseLeave();

      changeFormPropertiesValue((prev) => ({
        ...prev,
        segment: undefined,
      }));
    }
  }, [changeFormPropertiesValue, isSnapped, onMouseLeave]);

  const nearestSegments = useMemo(() => {
    const snapDistanceThreshold = 5.0; // in meters
    const maxNbSegments = 5;

    const ptCoords = point.geometry.coordinates.map((c) => c * 100); // in cm

    const ptLayerId = point.properties.layerId;

    const segmentsByDistance = segmentsIds
      .filter((segmentId) => {
        // only the segments of the same layer
        const segment = segments[segmentId];

        return segment.properties.layerId === ptLayerId;
      })
      .map((segmentId) => {
        // compute the distance between the point and the segments
        const segment = segments[segmentId];
        const segCoords = segment.geometry.coordinates;
        const start = segCoords[0];
        const end = segCoords[1];

        const d = pDistance(ptCoords[0], ptCoords[1], start[0], start[1], end[0], end[1]); // in cm

        return { distance: d / 100, segment };
      })
      .filter((s) => s.distance < snapDistanceThreshold) // keep only the segments that are close enough
      .sort((a, b) => a.distance - b.distance) // sort by distance
      .filter((_, i) => i < maxNbSegments) // keep only a certain maximum number of segments
      .map(({ distance, segment }) => {
        // compute the orientation of the segments
        const coords = segment.geometry.coordinates;
        const computedAngle = findShapeOrientation([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
        const normalizedAngle = (computedAngle + 360) % 360;

        return {
          distance,
          segment,
          orientation: normalizedAngle,
        };
      });

    return segmentsByDistance;
  }, [point.geometry.coordinates, point.properties.layerId, segments, segmentsIds]);

  const handleCloseMenu = useCallback(() => {
    allowEmphasizeSegment.current = false;
    setOpenMenu(false);

    setTimeout(() => {
      allowEmphasizeSegment.current = true;
    }, menuTransitionDuration);
  }, []);

  const handleSnapPoint = useCallback(
    (segmentId: string) => {
      handleCloseMenu();

      const segment = segments[segmentId];
      if (!segment) {
        // eslint-disable-next-line no-console
        console.warn(`Could not find segment ${segmentId}`);

        return;
      }

      const ptCoords = point.geometry.coordinates.map((c) => c * 100); // in cm
      const start = segment.geometry.coordinates[0];
      const end = segment.geometry.coordinates[1];

      const [snappedCoords, positionOnSegment] = getClosestPointInSegment(ptCoords, start, end);

      const coords = segment.geometry.coordinates;
      const computedAngle = findShapeOrientation([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
      const normalizedAngle = (computedAngle + 360) % 360;

      changeFormCoordinatesValue((prev) => ({
        ...prev,
        coordinates: snappedCoords.map((c) => c / 100) as [number, number],
      }));
      changeFormPropertiesValue((prev) => ({
        ...prev,
        orientation: normalizedAngle,
        segment: {
          id: segmentId,
          position: positionOnSegment,
        },
      }));
    },
    [changeFormCoordinatesValue, changeFormPropertiesValue, handleCloseMenu, point.geometry.coordinates, segments]
  );

  const handleMouseNearSegment = useCallback((segmentId: string, active: boolean) => {
    if (active && !allowEmphasizeSegment.current) return;

    const segmentEl = document.querySelector(`[uuid='${segmentId}']`);
    if (segmentEl && segmentEl instanceof SVGElement) {
      if (active) {
        segmentEl.classList.add(strokeDasharrayClassName);
      } else {
        segmentEl.classList.remove(strokeDasharrayClassName);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Could not find segment element ${segmentEl}`);
    }
  }, []);

  const dropDownAnchorEl = useRef<HTMLElement | null>(null);

  return (
    <>
      <ButtonGroup sx={{ textDecoration: 'none' }}>
        <Button
          color={color}
          variant="contained"
          sx={{ textDecoration: 'none' }}
          size="small"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          disabled={disabled || !isSnapped}
          onClick={handleClick}
          fullWidth
        >
          {text}
        </Button>
        <Button
          size="small"
          variant="contained"
          ref={(el) => (dropDownAnchorEl.current = el)}
          onClick={() => setOpenMenu(true)}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={dropDownAnchorEl.current}
        open={openMenu}
        onClose={handleCloseMenu}
        transitionDuration={menuTransitionDuration}
      >
        {nearestSegments.length > 0 ? (
          [
            <MenuItem disabled key="nearest-segments">
              <ListItemText>Nearest segments</ListItemText>
            </MenuItem>,
            nearestSegments.map((segData) => (
              <MenuItem
                key={segData.segment.id}
                selected={segData.segment.id === pointSegmentId}
                onClick={() => typeof segData.segment.id === 'string' && handleSnapPoint(segData.segment.id)}
                onMouseEnter={() =>
                  typeof segData.segment.id === 'string' && handleMouseNearSegment(segData.segment.id, true)
                }
                onMouseLeave={() =>
                  typeof segData.segment.id === 'string' && handleMouseNearSegment(segData.segment.id, false)
                }
              >
                <ListItemIcon>
                  <ArrowRightAltIcon
                    sx={{
                      transform: `rotate(${-segData.orientation}deg)`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText>{segData.distance.toFixed(2)} m</ListItemText>
              </MenuItem>
            )),
          ]
        ) : (
          <MenuItem disabled>
            <ListItemText>No close segments</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

interface BatteryPointInputProps {
  formProperties: PointPropertiesFormValue;
  opcData: OPCConfiguration | undefined;
  changeFormPropertiesValue: (value: (value: PointPropertiesFormValue) => PointPropertiesFormValue) => void;
  createNewOpcCharger: (batteryType: string) => OPCCharger;
  defaultBatteryType: string;
  allBatteryTypes: string[];
}

function BatteryPointInput({
  formProperties,
  opcData,
  changeFormPropertiesValue,
  createNewOpcCharger,
  defaultBatteryType,
  allBatteryTypes,
}: BatteryPointInputProps): JSX.Element {
  const handleChangeBatteryType = useCallback(
    (event: SelectChangeEvent) => {
      const newChargerType = event.target.value;

      if (opcData) {
        const newOPCData = { ...opcData };

        const actualChargerName = `${formProperties.batteryType ?? defaultBatteryType}_${formProperties.name}`;

        const batteryPointToRemove = opcData.chargers[actualChargerName];

        if (batteryPointToRemove) {
          delete newOPCData.chargers[actualChargerName];
        }

        const newChargerName = `${newChargerType}_${formProperties.name}`;

        const newCharger = createNewOpcCharger(newChargerType);

        newOPCData.chargers[newChargerName] = newCharger;

        saveOpcConfigurationOnDisk(newOPCData);
      }

      changeFormPropertiesValue((state) => ({
        ...state,
        batteryType: newChargerType,
      }));
    },
    [
      changeFormPropertiesValue,
      createNewOpcCharger,
      defaultBatteryType,
      formProperties.batteryType,
      formProperties.name,
      opcData,
    ]
  );

  const disabledInput = !formProperties.batteryType && allBatteryTypes.length < 1 && defaultBatteryType === 'none';

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink={true}>
        Select Battery Type <HelpIconTooltip title="Select a battery type for this battery point." />
      </InputLabel>
      <Select
        value={formProperties.batteryType ?? defaultBatteryType}
        onChange={handleChangeBatteryType}
        disabled={disabledInput}
      >
        {allBatteryTypes.map((batteryType) => (
          <MenuItem key={batteryType} value={batteryType}>
            {batteryType}
          </MenuItem>
        ))}
      </Select>
      {disabledInput && <FormHelperText>No battery available</FormHelperText>}
    </FormControl>
  );
}
