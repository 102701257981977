import { Help } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoopIcon from '@mui/icons-material/Loop';
import { Button, Checkbox, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { saveCircuitToHistoryAction, segmentMovedAction } from 'actions/circuit';
import { saveSegmentAction } from 'actions/segment';
import { showPortionsEventName } from 'drawings/editor-elements';
import { findShapeOrientation } from 'drawings/helpers';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { cloneDeep } from 'lodash';
import type { CircuitSegment, GabaritProperties, TrafficType } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import React, { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import type { LoadedSegment } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import type { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store';
import { getGabarit } from 'utils/circuit/get-gabarit';
import { humanize, integerInputIfNeeded, toRad } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { GabaritSelect } from '../components/gabarit-select';
import { MovementArrowButtonsGroup } from '../components/movement-arrows-buttons';
import { TrafficTypeSelect } from '../components/traffic-type-select';
import { PropertiesComponent } from '../properties-component';
import { nbDigitsOrientationValue } from '../rack-properties/rack-properties';
import { UpdateMiddleAisleSegmentBtn } from './update-middle-aisle-segment-btn';

const useStyles = makeStyles((theme) =>
  createStyles({
    inputExtremitiesCoordinates: {
      maxWidth: '100px',
    },
  })
);

interface SegmentPropertiesProps {
  segmentId: string;
}

type extremityPos = 'X1' | 'Y1' | 'X2' | 'Y2';

export const SegmentPropertiesComponent = ({ segmentId }: SegmentPropertiesProps): JSX.Element => {
  const segment = useAppSelector(
    (state: RootState) => state.circuit.present.segments.entities[segmentId] as LoadedSegment | undefined
  );

  const segmentAngle = useMemo(() => {
    if (!segment) return;
    const coords = segment.geometry.coordinates;
    const computedAngle = findShapeOrientation([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]);
    const normalizedAngle = (computedAngle + 360) % 360;

    return integerInputIfNeeded(normalizedAngle, nbDigitsOrientationValue);
  }, [segment]);

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [newAngle, setNewAngle] = useState(segmentAngle);

  // update angle on segment properties change
  useEffect(() => {
    setNewAngle(segmentAngle);
  }, [segmentAngle]);

  const handleAngleChange = useCallback((e) => {
    setNewAngle(e.target.value);
  }, []);
  const updateAngleChange = useCallback(() => {
    if (!segment || !newAngle) return;

    const inputAngle = parseFloat(newAngle);
    const computedAngle = (inputAngle + 360) % 360;
    const inputAngleRad = toRad(computedAngle);

    // Define the coordinates for first segment
    const coords = segment.geometry.coordinates;
    const x1 = coords[0][0];
    const y1 = coords[0][1];
    const x2 = coords[1][0];
    const y2 = coords[1][1];

    const segLength = getDistanceBetweenPoints([x1, y1], [x2, y2]);

    // We compute the new end point of the segment thanks to the new angle entered by the user
    const x3 = x1 + Math.cos(inputAngleRad) * segLength;
    const y3 = y1 + Math.sin(inputAngleRad) * segLength;

    const newCoords = [
      [x1, y1],
      [x3, y3],
    ];

    if (!isNaN(computedAngle)) {
      dispatch(saveCircuitToHistoryAction());
      dispatch(
        segmentMovedAction({
          idSegment: segmentId,
          coordinates: newCoords,
        })
      );
    } else {
      setNewAngle(segmentAngle);
    }
  }, [newAngle, dispatch, segment, segmentAngle, segmentId]);

  const modelsName = useMemo(() => PreferencesService.getModelNames(), []);

  const gabarit = useMemo(
    () => getGabarit(segment?.properties.gabarit, modelsName),
    [modelsName, segment?.properties.gabarit]
  );

  const prefsLoaded = PreferencesService.arePreferencesFullyLoaded();

  const [comment, setComment] = useState(
    segment && segment.properties && segment.properties.comments && segment.properties.comments.length
      ? segment.properties.comments[0]
      : ''
  );

  const [x1, setX1] = useState(humanize((segment?.geometry?.coordinates[0][0] ?? -1) / 100));
  const [y1, setY1] = useState(humanize((segment?.geometry?.coordinates[0][1] ?? -1) / 100));
  const [x2, setX2] = useState(humanize((segment?.geometry?.coordinates[1][0] ?? -1) / 100));
  const [y2, setY2] = useState(humanize((segment?.geometry?.coordinates[1][1] ?? -1) / 100));

  const [wireGuided, setWireGuided] = useState(!!segment?.properties?.wireGuided);

  const handleChangeWireGuided = useCallback(() => {
    setWireGuided((state) => !state);
  }, []);
  useEffect(() => {
    if (!segment || !segment.properties) return;

    if (wireGuided !== !!segment?.properties?.wireGuided) {
      startTransition(() => {
        dispatch(
          saveSegmentAction({
            id: segmentId,
            properties: {
              ...segment.properties,
              wireGuided: wireGuided || undefined,
            },
          })
        );
      });
    }
  }, [dispatch, segment, segment?.properties, segmentId, wireGuided]);

  const handleCommentChange = useCallback(
    (e): void => {
      const val = comment;

      if (segment)
        dispatch(
          saveSegmentAction({
            ...segment,
            properties: {
              ...segment.properties,
              comments: [val],
            },
            userAction: true,
          })
        );
    },
    [comment, dispatch, segment]
  );

  const handleChangeExtremity = useCallback((label: extremityPos, value: string) => {
    if (label === 'X1') setX1(value);
    else if (label === 'Y1') setY1(value);
    else if (label === 'X2') setX2(value);
    else if (label === 'Y2') setY2(value);
  }, []);

  const handleEnterExtremity = useCallback(
    (label: extremityPos, e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && segment) {
        const newCoordinates = cloneDeep(segment.geometry.coordinates);
        if (label === 'X1') newCoordinates[0][0] = parseFloat(x1) * 100;
        else if (label === 'Y1') newCoordinates[0][1] = parseFloat(y1) * 100;
        else if (label === 'X2') newCoordinates[1][0] = parseFloat(x2) * 100;
        else if (label === 'Y2') newCoordinates[1][1] = parseFloat(y2) * 100;

        if (
          segment &&
          !isNaN(newCoordinates[0][0]) &&
          !isNaN(newCoordinates[0][1]) &&
          !isNaN(newCoordinates[1][0]) &&
          !isNaN(newCoordinates[1][1])
        ) {
          dispatch(saveCircuitToHistoryAction());

          dispatch(
            segmentMovedAction({
              idSegment: segmentId,
              coordinates: newCoordinates,
            })
          );
        }
      }
    },
    [dispatch, segment, segmentId, x1, x2, y1, y2]
  );

  const handleBlurExtremity = useCallback(
    (label: extremityPos) => {
      if (!segment || !segment.geometry || !segment.geometry.coordinates) return;

      if (label === 'X1') setX1(humanize(segment.geometry.coordinates[0][0] / 100));
      else if (label === 'Y1') setY1(humanize(segment.geometry.coordinates[0][1] / 100));
      else if (label === 'X2') setX2(humanize(segment.geometry.coordinates[1][0] / 100));
      else if (label === 'Y2') setY2(humanize(segment.geometry.coordinates[1][1] / 100));
    },
    [segment]
  );

  useEffect(() => {
    if (!segment?.geometry?.coordinates) return;

    setX1(humanize(segment.geometry.coordinates[0][0] / 100));
    setY1(humanize(segment.geometry.coordinates[0][1] / 100));
    setX2(humanize(segment.geometry.coordinates[1][0] / 100));
    setY2(humanize(segment.geometry.coordinates[1][1] / 100));
  }, [segment?.geometry?.coordinates]);

  const locked = !!segment?.properties?.locked;

  const handleFlipSegment = useCallback(() => {
    if (!segment) return;

    const newCoordinates = [...segment.geometry.coordinates].reverse();

    dispatch(
      saveSegmentAction({
        ...segment,
        geometry: {
          ...segment.geometry,
          coordinates: newCoordinates,
        },
        userAction: true,
      })
    );

    dispatch(
      segmentMovedAction({
        idSegment: segmentId,
        coordinates: newCoordinates,
      })
    );
  }, [dispatch, segment, segmentId]);

  const [anchorElPortion, setAnchorElPortion] = useState<HTMLButtonElement | null>(null);
  const handleClickPortionChangeTrafficType = useCallback(
    (e: React.MouseEvent<HTMLElement>, portionId: string | undefined) => {
      const el = e.target as HTMLElement;

      if (el && segment) {
        const trafficType = el.innerText.toLowerCase() as TrafficType;

        if (trafficType && portionId) {
          const newPortions = [...segment.properties.portions].map((p) => {
            if (p.id === portionId) {
              p.trafficType = trafficType;
              if (p.trafficType === 'auto') delete p.trafficType;
            }

            return p;
          });

          dispatch(
            saveSegmentAction({
              ...segment,
              properties: {
                ...segment.properties,
                portions: newPortions,
              },
              userAction: true,
            })
          );
        }
      }

      setAnchorElPortion(null);
    },
    [dispatch, segment]
  );

  const changeHighlightStatusPortion = useCallback(
    (e: React.MouseEvent<HTMLElement>, portionId: string | undefined) => {
      if (!portionId) {
        // eslint-disable-next-line no-console
        console.log('No portion id to highlight');

        return;
      }

      const el = e.target;

      if (el && segment && el instanceof HTMLElement) {
        const trafficType = el.innerText.toLowerCase() as TrafficType;

        const segmentEl = document.querySelector(`[uuid="${segment.id}"]`);
        if (!segmentEl) {
          // eslint-disable-next-line no-console
          console.error(`No svg element found for segment ${segment.id}`);

          return;
        }

        const allPortionsEls = Array.from(segmentEl.querySelectorAll('.portion'));
        allPortionsEls.forEach((portionEl) => {
          portionEl.remove();
        });

        segmentEl.dispatchEvent(new Event(showPortionsEventName));

        const portionEl = segmentEl.querySelector(`[portion-id="${portionId}"]`);
        if (!portionEl) {
          // eslint-disable-next-line no-console
          console.log(`Portion ${portionId} not found in the svg for ${segment.id}`);

          return;
        }

        const originPortionTrafic = segment.properties.portions.find((portion) => portion.id === portionId)
          ?.trafficType;

        if (e.type === 'mouseleave' && originPortionTrafic) {
          portionEl.classList.add(`portion-${originPortionTrafic}`);
          portionEl.classList.add('portion');

          return;
        }

        if (originPortionTrafic) {
          portionEl.classList.replace(`portion-${originPortionTrafic}`, `portion-${trafficType}`);
          portionEl.classList.add('portion');

          return;
        }

        if (e.type === 'mouseleave' && !originPortionTrafic) {
          portionEl.classList.add(`portion-auto`);
          portionEl.classList.add('portion');

          return;
        }

        portionEl.classList.add(`portion-${trafficType}`);
        portionEl.classList.add('portion');
      }
    },
    [segment]
  );

  const handleChangeGabarit = useCallback(
    (newGabarit: GabaritProperties) => {
      if (segment) {
        startTransition(() => {
          dispatch(
            saveSegmentAction({
              ...segment,
              properties: {
                ...segment.properties,
                gabarit: newGabarit,
              },
              userAction: true,
            })
          );
        });
      }
    },
    [dispatch, segment]
  );

  const handleCopyToClipboard = useCallback((value): void => {
    try {
      navigator.clipboard.writeText(value);
      SnackbarUtils.toast('Copied to clipboard', { variant: 'success', autoHideDuration: 1000 });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      SnackbarUtils.toast('Failed to copy to clipboard', { variant: 'error', autoHideDuration: 1000 });
    }
  }, []);

  return !segment || !segmentId ? (
    <></>
  ) : (
    <PropertiesComponent shape={segment} shapeId={segmentId} shapeType={ShapeTypes.SegmentShape}>
      <Stack>
        <MovementArrowButtonsGroup
          locked={locked}
          shape={segment}
          shapeId={segmentId}
          shapeMovedAction={segmentMovedAction}
        />
        <Button
          onClick={handleFlipSegment}
          color="primary"
          variant="outlined"
          startIcon={<LoopIcon />}
          size="small"
          disabled={locked}
        >
          Flip
        </Button>
      </Stack>

      <Stack gap={1}>
        <Stack flexDirection="row" gap={1}>
          <TextField
            label="X1"
            type="number"
            value={x1}
            sx={{ paddingBottom: '4px' }}
            onChange={(e) => handleChangeExtremity('X1', e.target.value)}
            onKeyPress={(e) => handleEnterExtremity('X1', e)}
            onBlur={(e) => handleBlurExtremity('X1')}
            size="small"
            inputProps={{ className: classes.inputExtremitiesCoordinates, step: 0.01 }}
            disabled={locked}
            variant="standard"
            fullWidth
          ></TextField>
          <IconButton
            size="small"
            sx={{ marginRight: 2 }}
            onClick={() => {
              handleCopyToClipboard(x1);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>

          <TextField
            label="Y1"
            type="number"
            value={y1}
            sx={{ paddingBottom: '4px' }}
            onChange={(e) => handleChangeExtremity('Y1', e.target.value)}
            onKeyPress={(e) => handleEnterExtremity('Y1', e)}
            onBlur={(e) => handleBlurExtremity('Y1')}
            size="small"
            inputProps={{ className: classes.inputExtremitiesCoordinates, step: 0.01 }}
            disabled={locked}
            variant="standard"
            fullWidth
          ></TextField>
          <IconButton
            size="small"
            onClick={() => {
              handleCopyToClipboard(y1);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" gap={1}>
          <TextField
            label="X2"
            type="number"
            value={x2}
            onChange={(e) => handleChangeExtremity('X2', e.target.value)}
            onKeyPress={(e) => handleEnterExtremity('X2', e)}
            onBlur={(e) => handleBlurExtremity('X2')}
            size="small"
            inputProps={{ className: classes.inputExtremitiesCoordinates, step: 0.01 }}
            disabled={locked}
            variant="standard"
            fullWidth
          ></TextField>
          <IconButton
            size="small"
            sx={{ marginRight: 2 }}
            onClick={() => {
              handleCopyToClipboard(x2);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>

          <TextField
            label="Y2"
            type="number"
            value={y2}
            onChange={(e) => handleChangeExtremity('Y2', e.target.value)}
            onKeyPress={(e) => handleEnterExtremity('Y2', e)}
            onBlur={(e) => handleBlurExtremity('Y2')}
            size="small"
            inputProps={{ className: classes.inputExtremitiesCoordinates, step: 0.01 }}
            disabled={locked}
            variant="standard"
            fullWidth
          ></TextField>
          <IconButton
            size="small"
            onClick={() => {
              handleCopyToClipboard(y2);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>

      <Stack>
        <FormGroup sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', marginLeft: theme.spacing(1) }}>
          <FormControlLabel
            onChange={handleChangeWireGuided}
            control={<Checkbox checked={wireGuided} />}
            label={
              <>
                Wire guided
                <Tooltip
                  title="Enable the line following feature for VNAs"
                  style={{ verticalAlign: 'middle', marginLeft: theme.spacing(1) }}
                >
                  <Help fontSize="small"></Help>
                </Tooltip>
              </>
            }
            disabled={locked}
          />
        </FormGroup>

        <Typography variant="body1">
          Length: <SegmentLengthElement segment={segment}></SegmentLengthElement>m
        </Typography>
      </Stack>

      <TextField
        type="number"
        value={newAngle}
        onChange={handleAngleChange}
        onBlur={updateAngleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            updateAngleChange();
          } else if (e.key === 'Escape') {
            setNewAngle(segmentAngle);
          }
        }}
        fullWidth
        label="Orientation"
        disabled={locked}
        InputProps={{
          endAdornment: <InputAdornment position="end">deg</InputAdornment>,
        }}
        variant="standard"
      />

      {segment.properties.aisle?.aisleId ? <UpdateMiddleAisleSegmentBtn segment={segment} /> : <></>}

      <TrafficTypeSelect
        setAnchorElPortion={setAnchorElPortion}
        anchorElPortion={anchorElPortion}
        shape={segment}
        locked={locked}
        handleClickPortionChangeTrafficType={handleClickPortionChangeTrafficType}
        changeHighlightStatusPortion={changeHighlightStatusPortion}
      />

      <TextField
        label="Comment"
        multiline
        minRows={2}
        maxRows={4}
        autoComplete="off"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onBlur={handleCommentChange}
        disabled={locked}
        variant="outlined"
        sx={{ marginTop: theme.spacing(1) }}
      ></TextField>

      <GabaritSelect handleChangeGabarit={handleChangeGabarit} gabarit={gabarit} disabled={!prefsLoaded} />
    </PropertiesComponent>
  );
};

// this component watch the coordinates to recompute the segment length
function SegmentLengthElement({ segment, nbDigits = 3 }: { segment: CircuitSegment; nbDigits?: number }): JSX.Element {
  const coords = segment.geometry.coordinates;

  const [segmentLength, setSegmentLength] = useState(
    getDistanceBetweenPoints([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]) / DISPLAY_UNIT_FACTOR
  );

  useEffect(() => {
    setSegmentLength(
      getDistanceBetweenPoints([coords[0][0], coords[0][1]], [coords[1][0], coords[1][1]]) / DISPLAY_UNIT_FACTOR
    );
  }, [coords]);

  return <>{segmentLength.toFixed(nbDigits)}</>;
}
