export interface Dictionary<T> {
  [id: string]: T;
}

export const userPermissions = [
  'open:application',
  'open:traffic_tools',
  'edit:stations',
  'edit:flows',
  'edit:simulation_configuration',
  'launch:simulation',
  'create:room',
  'access:room',
  'edit:project_safety_configuration',
  'edit:project_configuration',
  'open:project',
  'edit:circuit',
  'create:project',
  'read:map_tiles',
] as const;

export type UserPermissions = (typeof userPermissions)[number];

export interface PaginatedResult<T> {
  docs: T[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export interface UserProfileData {
  email: string;
  groups: string[];
  locale?: string;
  name: string;
  nickname: string;
  permissions: UserPermissions[];
  picture: string;
  roles: string[];
  sub: string;
  updatedAt: string;
}
