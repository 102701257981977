import { z } from 'zod';

export const dataLineCsvSchema = z.object({
  Serial: z.string(),
  Date: z.string(),
  'Rack Name': z.string(),
  'Slot Name': z.string(),
  'Beam position tolerance': z.string(),
  'Offset on beam position (X)': z.string(),
  'Move circuit away or further from truck': z.string().optional(),
  'Reference Mode': z.string(),
  'Reference position tolerance': z.string(),
  'Offset on Reference position (Y)': z.string(),
  'Move circuit left or right': z.string().optional(),
  'Beam height tolerance': z.string(),
  'Offset on beam height (Z)': z.string(),
  'Move beam up or down': z.string().optional(),
});

export function dataLineCsvSchemaSafeParseSuccess(value: unknown): value is DataLineCsv {
  const result = dataLineCsvSchema.safeParse(value);
  if (!result.success) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to parse data line: ${JSON.stringify(result.error.errors, null, 2)}`, value);
  }

  return result.success;
}

/**
 * Fields of the csv file generated by the robots
 */
export type DataLineCsv = z.infer<typeof dataLineCsvSchema>;

export type RobotMeasures = DataLineCsv[];

export interface Csv {
  /* id of the csv file */
  id: string;
  /* Name of the csv file */
  name: string;
  /** Array of csv recording the measures taken by robots on slots */
  data: RobotMeasures;
}

export const offsetOrAngleSchema = z.enum(['offset', 'angle', 'angleAndOffset']);
export type OffsetOrAngle = z.infer<typeof offsetOrAngleSchema>;

export function offsetOrAngleSchemaParseSuccess(value: unknown): value is OffsetOrAngle {
  const result = offsetOrAngleSchema.safeParse(value);
  if (!result.success) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to parse offsetOrAngle: ${JSON.stringify(result.error.errors, null, 2)}`, value);
  }

  return result.success;
}

export interface Recommendation {
  rackName: string;
  /**
   * The translation to apply on the rack in the rack frame of reference
   * Unit: meter
   */
  recommendation: {
    /** Translation to apply in x = depth of the rack [m] */
    dx: number;
    /** Translation to apply in y = left or right when you face the rack [m] */
    dy: number;
    /** Translation to apply in z = from the bottom to the top of the rack [z] */
    dz: RecommendationByLevelLabel;

    /** Rotation to apply [rad] */
    dTheta: number;
    /** Previous rotation applied [rad] */
    formerDTheta?: number;
  };
  /**
   * Is the recommendation already applied? And if yes which type of correction is applied
   * true = offset
   */
  applied: boolean | OffsetOrAngle;
  /** The smallest authorized tolerance of all the slots of the rack */
  authorizedTolerance: {
    /** [m] */
    x: number;
    /** [m] */
    y: number;
    /** [m] */
    z: number;
    /** [rad] */
    theta: number;
  };
  /* Number of measures taken into account for the recommendation on this rack */
  measuresCount: number;
}

export type SlotsWeight = { [slotName: string]: number };

export interface RobotMeasuresInfo {
  /** Toggle to use the robot measure for calculations*/
  disabled: boolean;
  /* The measures taken by the robot */
  measures: RobotMeasures;
  /** The number of unique slots measured by the robot */
  slotsCount: number;
}

export interface RobotsMeasuresInfo {
  [robotName: string]: RobotMeasuresInfo;
}

interface RobotHealth {
  /* Mean of the measures compared to the others */
  mean: { x: number; y: number; z: number };
  /* Standard deviation compared to the others */
  sd: { x: number; y: number; z: number };
}

export interface RobotsHealth {
  [robotName: string]: RobotHealth;
}

interface RecommendationByLevelLabel {
  // Level label is the level of the rack row and it's total height
  // Row level 0 and at 2.3m will be named 0 - 2.3
  [levelLabel: number]: RecommendationDataForAvg;
}

interface RecommendationDataForAvg {
  /** Sum of the error [m] */
  value: number;
  /** Number of measures */
  count: number;
}

export interface RecommendationDataForAvgAllAxis {
  /** Avg of the correction to apply on the rack on each axis */
  x: RecommendationDataForAvg;
  y: RecommendationDataForAvg;
  z: RecommendationByLevelLabel;

  /** Number of measures taken into account for the recommendation on this rack */
  measuresCount: number;
}

export type RackAnalysisSteps = 'import' | 'measures' | 'recommendation' | 'robotHealth';
