import { change90DegSnapAction, openDialogAction, selectToolAction } from 'actions';
import * as d3 from 'd3';
import { Tools } from 'models/tools';
import type { Dispatch } from 'react';
import { connect } from 'react-redux';
import type { AppState } from 'reducers/state';
import { change90DegSnap, selectTool } from 'reducers/tools';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DialogTypes } from 'models';
import store from 'store';
import { ToolBarComponent } from './tool-bar';

interface StateProps {
  activeTool?: Tools;
  snap90deg?: boolean;
}

function mapStateToProps(state: AppState): StateProps {
  const tool = selectTool(state);
  const snap90deg = change90DegSnap(state);

  return {
    activeTool: tool,
    snap90deg: snap90deg,
  };
}

interface DispatchProps {
  selectNewTool: (toolName: Tools) => void;
  change90DegSnap: (snap90deg: boolean) => void;
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
  return {
    selectNewTool: (toolName: Tools) => {
      // remove the drawing shape visualizer
      if (toolName === Tools.Move) {
        d3.select('[layer=draw] g').dispatch('cancel-shape');
      }

      const simulation = store.getState().simulation;

      if (simulation.hasSimulationStarted) {
        dispatch(openDialogAction({ type: DialogTypes.ExitRunningSimulation, payload: { newToolOrMode: toolName } }));
      } else {
        dispatch(selectToolAction({ toolName }));
      }
    },
    change90DegSnap: (snap90deg: boolean) => dispatch(change90DegSnapAction({ snap90deg })),
  };
}

export const ToolBar = connect(mapStateToProps, mapDispatchToProps)(ToolBarComponent);
