import { Expand } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { closeDialogAction } from 'actions';
import { askUpdateRackAction } from 'actions/circuit';
import { createRackSuccessAction, saveRackSuccessAction } from 'actions/racks';
import { generateCopiedName } from 'drawings/copy-paste';
import { useConfirm } from 'material-ui-confirm';
import type { CircuitRack } from 'models/circuit';
import { setBackdropStateAction } from 'reducers/core/reducer';
import store, { useAppDispatch } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { computeRackCoordinates } from 'utils/circuit/racks';
import { toRad } from 'utils/helpers';
import { canBeUndefined } from 'utils/ts/is-defined';
import type { ActionsRack } from './rack-edition';

/**
 * This function split a rack in two parts
 */
function splitRack(rackId: string, uprightIndex: number): void {
  const rack = store.getState().circuit.present.racks.entities[rackId];
  const rack1 = structuredClone(rack);
  const rack2 = structuredClone(rack);

  rack2.id = generateShapeId();
  rack2.properties.name = generateCopiedName(rack2.properties.name);

  const columns = rack.properties.columns;
  const uprights = rack.properties.uprights;

  let x = 0;
  for (let i = 0; i < uprightIndex; i++) {
    const column = columns[i];
    const upright = uprights[i];

    const uprightWidth = upright.enabled ? upright.width : 0;

    x += uprightWidth + column.width;
  }

  const upright = canBeUndefined(uprights[uprightIndex]);
  if (!upright) {
    // eslint-disable-next-line no-console
    console.error(`Upright not found at index ${uprightIndex}`);

    return;
  }

  rack1.properties.uprights = rack1.properties.uprights.slice(0, uprightIndex + 1);
  rack1.properties.columns = rack1.properties.columns.slice(0, uprightIndex);
  rack1.geometry.coordinates = [computeRackCoordinates(rack1, rack1.properties.columns, rack1.properties.uprights)];

  rack2.properties.uprights = rack2.properties.uprights.slice(uprightIndex);
  rack2.properties.columns = rack2.properties.columns.slice(uprightIndex);
  rack2.properties.columns = rack2.properties.columns.map((column) => {
    return {
      ...column,
      x: column.x - x,
    };
  });
  const translationDistance = x * 100; // cm
  const originRack2 = rack2.geometry.coordinates[0][0];
  const angle = toRad(rack2.properties.cap);
  const originRack2Translated = [
    originRack2[0] + Math.cos(angle) * translationDistance,
    originRack2[1] + Math.sin(angle) * translationDistance,
  ];
  rack2.geometry.coordinates[0][0] = originRack2Translated;
  rack2.geometry.coordinates = [computeRackCoordinates(rack2, rack2.properties.columns, rack2.properties.uprights)];

  store.dispatch(saveRackSuccessAction(rack1));
  store.dispatch(createRackSuccessAction(rack2));

  store.dispatch(
    askUpdateRackAction({
      id: rack1.id as string,
      type: 'save',
    })
  );
  store.dispatch(
    askUpdateRackAction({
      id: rack2.id,
      type: 'save',
    })
  );

  store.dispatch(
    askUpdateRackAction({
      id: rack1.id as string,
      type: 'saveSuccess',
    })
  );
  store.dispatch(
    askUpdateRackAction({
      id: rack2.id,
      type: 'saveSuccess',
    })
  );
}

interface CutRackIconProps {
  rack: CircuitRack;
  upright: CircuitRack['properties']['uprights'][0];
  uprightIndex: number;
  actions: ActionsRack;
}

export function CutRackIcon(props: CutRackIconProps): JSX.Element {
  const { rack, uprightIndex, actions } = props;

  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const iconSize = 10; // px

  const handleClick = async (): Promise<void> => {
    try {
      await confirm({
        title: 'Split rack',
        description: 'It will validate all the changes made to the rack and split the rack in two parts.',
        confirmationText: 'Validate & Split',
        cancellationText: 'Close',
      });
    } catch (e) {
      return;
    }

    dispatch(setBackdropStateAction({ newBackdropState: true }));

    actions.validateRack();

    let dialogStillOpen = store.getState().dialog.open;
    do {
      await new Promise((resolve) => setTimeout(resolve, 100));
      dialogStillOpen = store.getState().dialog.open;
    } while (dialogStillOpen);

    splitRack(rack.id as string, uprightIndex);

    dispatch(closeDialogAction());
    dispatch(setBackdropStateAction({ newBackdropState: false }));
  };

  return (
    <Tooltip title="Split upright here">
      <IconButton onClick={handleClick}>
        <Expand
          sx={{
            transform: 'rotate(90deg)',
            fontSize: `${iconSize}px`,
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
