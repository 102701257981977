import { InterestPoint, pointTextSizeToPx } from 'drawings/editor-elements';
import { getMathematicalProjection } from 'drawings/helpers';
import type { Point, Polygon } from 'geojson';
import type { CircuitPoint } from 'models/circuit';
import type { pointSize } from 'models/drawings';
import { useEffect, useMemo, useRef } from 'react';

const zoomScale = 1;
const projection = getMathematicalProjection<Polygon[] | Point[]>();

interface PointComponentProps {
  point: CircuitPoint;
  id: string;
  selected?: boolean;
  size?: pointSize;
  displayGabarit?: boolean;
}

export function PointComponent(props: PointComponentProps): JSX.Element {
  const point = props.point;
  const ref = useRef<SVGSVGElement>(null);
  const size = props.size ?? 'small';

  const isSnapped = !!point.properties?.segment;

  const shape = useMemo(() => {
    if (props.id && point && point.id) {
      return new InterestPoint(point.id.toString(), point.geometry, projection, zoomScale, point.properties, {
        size,
        displayGabarit: props.displayGabarit,
      });
    }
  }, [props.id, point, size, props.displayGabarit]);

  useEffect(() => {
    if (ref.current && shape) {
      const node = shape.node.node();

      if (isSnapped) {
        node?.classList.add('snapped');
      }

      // clickable zone
      const clickElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      clickElement.setAttribute('cx', point.geometry.coordinates[0].toString());
      clickElement.setAttribute('cy', (-point.geometry.coordinates[1]).toString());
      clickElement.setAttribute('r', pointTextSizeToPx(size).toString());
      clickElement.classList.add('select-shape-helper');

      if (node && clickElement) {
        // we remove all the previously added children
        while (ref.current.firstChild) {
          ref.current.firstChild.remove();
        }

        // we add the shape dom
        node.insertBefore(clickElement, node.firstChild);
        ref.current.appendChild(node);
      }
    }
  }, [isSnapped, point.geometry.coordinates, shape, size]);

  useEffect(() => {
    shape?.setActive(props.selected);
  }, [props.selected, shape]);

  return <g ref={ref}></g>;
}
