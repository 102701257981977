import HelpIcon from '@mui/icons-material/Help';
import type { ButtonProps, ToggleButtonProps, TooltipProps } from '@mui/material';
import { Button, ToggleButton, Tooltip } from '@mui/material';

export function StandardModeHelper(): JSX.Element {
  return (
    <Tooltip
      title={
        <>
          This feature is disabled in <em>standard mode</em>. The preview mode is available in the settings menu.
        </>
      }
    >
      <HelpIcon />
    </Tooltip>
  );
}

export function ToggleButtonDisabledInStandard(
  propsExtended: ToggleButtonProps & { standardMode: boolean }
): JSX.Element {
  const { standardMode, title, ...props } = propsExtended;

  return (
    <TooltipInStandard standardMode={standardMode} title={title}>
      <ToggleButton
        {...props}
        disabled={props.disabled}
        data-disabled={standardMode ? true : undefined}
        sx={{
          ...props.sx,
          cursor: standardMode ? 'not-allowed' : undefined,
          color: standardMode ? 'rgba(0, 0, 0, 0.26)' : undefined,
          border: standardMode ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
        }}
      >
        {props.children}
      </ToggleButton>
    </TooltipInStandard>
  );
}

export function ButtonDisabledInStandard(propsExtended: ButtonProps & { standardMode: boolean }): JSX.Element {
  const { standardMode, title, ...props } = propsExtended;

  return (
    <TooltipInStandard standardMode={standardMode} title={title}>
      <Button
        {...props}
        disabled={props.disabled}
        data-disabled={standardMode ? true : undefined}
        sx={{
          ...props.sx,
          cursor: standardMode ? 'not-allowed' : undefined,
          color: standardMode && props.color !== 'primary' ? 'rgba(0, 0, 0, 0.26)' : undefined,
          border: standardMode ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
        }}
      >
        {props.children}
      </Button>
    </TooltipInStandard>
  );
}

export function TooltipInStandard(
  propsExtended: Omit<TooltipProps & { standardMode: boolean }, 'title'> & { title?: TooltipProps['title'] }
): JSX.Element {
  const { standardMode, ...props } = propsExtended;

  if (!standardMode) {
    return <>{props.children}</>;
  }

  return (
    <Tooltip
      {...props}
      title={
        standardMode ? (
          <>
            This feature is disabled in <em>standard mode</em>. The preview mode is available in the settings menu.
          </>
        ) : (
          props.title ?? ''
        )
      }
    >
      {props.children}
    </Tooltip>
  );
}

export const textHelperDisabledStandardMode = 'Unavailable in standard mode';
