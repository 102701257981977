import type { RobotPath } from 'robots/robots';
import { useAppSelector } from 'store';

export function RobotPathsComponent(): JSX.Element {
  const robotPaths = useAppSelector((state) => state.robots.robotPaths);

  return (
    <g className="robot-paths">
      {robotPaths.map((robotPath, index) => (
        <RobotPathComponent key={`${robotPath.robotId}-${index}`} robotPath={robotPath} />
      ))}
    </g>
  );
}

interface RobotPathProps {
  robotPath: RobotPath;
}
export function RobotPathComponent({ robotPath }: RobotPathProps): JSX.Element {
  const path = robotPath.path;

  return (
    <polyline
      className="robot-path"
      points={path.map((point) => `${point[0]},${point[1]}`).join(' ')}
      stroke="black"
      fill="none"
    />
  );
}
