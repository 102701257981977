/*
 * Generated type guards for "racks-naming.ts".
 * WARNING: Do not manually change this file.
 */
import {
  BlockDataSlotLineName,
  BlockDataSlotLineNames,
  NumberVariableName,
  StringVariableName,
  VariablesNameStock,
} from './stock-zone-naming';

export function isStringVariableName(obj: unknown): obj is StringVariableName {
  const typedObj = obj as StringVariableName;
  return typedObj === '@stockZoneName!';
}

export function isNumberVariableName(obj: unknown): obj is NumberVariableName {
  const typedObj = obj as NumberVariableName;
  return typedObj === '@stockLineNumber!';
}

export function isStocksVariableName(obj: unknown): obj is VariablesNameStock {
  const typedObj = obj as VariablesNameStock;
  return typedObj === '@stockZoneName!' || typedObj === '@stockLineNumber!';
}

export function isBlockDataSlotLineName(obj: unknown): obj is BlockDataSlotLineName {
  const typedObj = obj as BlockDataSlotLineName;
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typedObj['type'] === 'char' || typedObj['type'] === 'nbVariable' || typedObj['type'] === 'strVariable') &&
    typeof typedObj['value'] === 'string' &&
    (typeof typedObj['params'] === 'undefined' ||
      (((typedObj['params'] !== null && typeof typedObj['params'] === 'object') ||
        typeof typedObj['params'] === 'function') &&
        (typedObj['params']['formatting'] === 'number' || typedObj['params']['formatting'] === 'letter') &&
        (typedObj['params']['formattingDirection'] === 'ltr' || typedObj['params']['formattingDirection'] === 'rtl') &&
        typeof typedObj['params']['nbCharacters'] === 'number' &&
        typeof typedObj['params']['fillCharacter'] === 'string' &&
        typeof typedObj['params']['startAt'] === 'number' &&
        typeof typedObj['params']['step'] === 'number'))
  );
}

export function isBlockDataSlotLineNames(obj: unknown): obj is BlockDataSlotLineNames {
  const typedObj = obj as BlockDataSlotLineNames;
  return Array.isArray(typedObj) && typedObj.every((e: any) => isBlockDataSlotLineName(e) as boolean);
}
