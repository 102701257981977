import { Settings, Warning } from '@mui/icons-material';
import { FormControlLabel, InputAdornment, Switch, TextField, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import type { ProxyMethods, RemoteObject } from 'comlink';
import { CollapseMore } from 'components/utils/collapse-more';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { startTransition, useCallback, useEffect, useState } from 'react';
import { setRobots } from 'robots/robots';
import { clearAllItineraries } from 'routes/routes';
import {
  setDisplayAllRobotsData,
  setEnableAdvancedDisplayTraffic,
  setEnableCharging,
  setEnableDisplayTraffic,
  setEnableFastRefresh,
  setEnableLibPerfo,
  setMaxDuration,
  setRobotsOpacity,
  setSimulationDisplayState,
} from 'simulation/simulation';
import type { Simulation } from 'simulation/simulation.model';
import store, { useAppDispatch, useAppSelector } from 'store';
import { clearAllCantons } from 'traffic/traffic';
import { theme } from 'utils/mui-theme';
import { DisplayErrorsMonitoringCollapse } from '../display-errors-monitoring-collapse';
import { useBattPointsInTheCircuit } from './use-batt-points';

const simulationDurationMaxHoursName = 'simulation-max-duration-hours';
const simulationDurationMaxMinutesName = 'simulation-max-duration-minutes';

interface ConfigureSimulationParametersProps {
  isSimulationPaused: boolean;
  isSimulationRunning: boolean;
  simulationServiceRef: React.MutableRefObject<(RemoteObject<Simulation> & ProxyMethods) | null>;
  getSimulationData: (force?: boolean) => Promise<void>;
}
export function ConfigureSimulationParameters(props: ConfigureSimulationParametersProps): JSX.Element {
  const { isSimulationPaused, isSimulationRunning, simulationServiceRef, getSimulationData } = props;

  const dispatch = useAppDispatch();

  const maxDuration = useAppSelector((state) => state.simulation.maxDuration);
  const enableCharging = useAppSelector((state) => state.simulation.enableCharging);
  const simulationEnableDisplay = useAppSelector((state) => state.simulation.enableDisplay);
  const fastRefreshEnabled = useAppSelector((state) => state.simulation.enableFastRefresh);
  const displayTrafficEnabled = useAppSelector((state) => state.simulation.enableDisplayTraffic);
  const advancedTrafficDisplayEnabled = useAppSelector((state) => state.simulation.enableAdvancedDisplayTraffic);
  const libPerfoEnabled = useAppSelector((state) => state.simulation.enableLibPerfo);
  const displayAllRobotsData = useAppSelector((state) => state.simulation.displayAllRobotsData);

  const isThereAtLeastOneBattPoint = useBattPointsInTheCircuit();

  const handleChangeCharging = useCallback(
    async (enableBatt: boolean) => {
      const simulationService = simulationServiceRef.current;
      if (!simulationService) return;

      const currentSpeed = store.getState().simulation.speedFactor;

      if (!isSimulationPaused) await simulationService._WEBSIMU_WasmWrapper_setSpeed(0);

      await simulationService._ROBEMU_WasmWrapper_setBatt(enableBatt ? 1 : 0);

      if (!isSimulationPaused) await simulationService._WEBSIMU_WasmWrapper_setSpeed(currentSpeed);
    },
    [isSimulationPaused, simulationServiceRef]
  );

  const handleToggleCharging = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      dispatch(setEnableCharging(newValue));

      handleChangeCharging(newValue);
    },
    [dispatch, handleChangeCharging]
  );

  const [fadeRobots, setFadeRobots] = useState(false);
  const handleToggleFadeRobots = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValueIsFaded: boolean) => {
      const newRobotOpacity = newValueIsFaded ? 0.2 : 1;
      setFadeRobots(newValueIsFaded);

      startTransition(() => {
        dispatch(setRobotsOpacity(newRobotOpacity));
      });
    },
    [dispatch]
  );

  const handleToggleDisplayAllRobotsData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      dispatch(setDisplayAllRobotsData(newValue));
    },
    [dispatch]
  );

  const handleToggleEnableDisplay = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean): Promise<void> => {
      dispatch(setSimulationDisplayState(newValue));

      if (!newValue) {
        // if we disable the display, we remove the previously displayed data
        startTransition(() => {
          dispatch(setRobots([]));
          dispatch(clearAllItineraries());
          dispatch(clearAllCantons());
        });
      } else {
        await getSimulationData(true);
      }
    },
    [dispatch, getSimulationData]
  );

  const maxDurationFormDisabled = !isSimulationPaused && isSimulationRunning;
  const handleUpdateMaxDuration = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const currentTarget = e.currentTarget;

      const maxDurationHrsInput: HTMLInputElement | undefined | unknown = currentTarget.elements[
        simulationDurationMaxHoursName
      ] as unknown;
      const maxDurationMinInput: HTMLInputElement | undefined | unknown = currentTarget.elements[
        simulationDurationMaxMinutesName
      ] as unknown;

      if (!(maxDurationHrsInput instanceof HTMLInputElement) || !(maxDurationMinInput instanceof HTMLInputElement)) {
        // eslint-disable-next-line no-console
        console.error('Wrong type for maxDurationHrsInput or maxDurationMinInput, elements are not HTMLInputElement', {
          maxDurationHrsInput,
          maxDurationMinInput,
        });

        return;
      }

      const maxDurationHrs = parseInt(maxDurationHrsInput.value, 10);
      const maxDurationMin = parseInt(maxDurationMinInput.value, 10);

      if (isNaN(maxDurationHrs) || isNaN(maxDurationMin)) {
        // eslint-disable-next-line no-console
        console.error('maxDurationHrs or maxDurationMin is NaN', { maxDurationHrs, maxDurationMin });

        return;
      }

      const newMaxDuration = maxDurationHrs * 3600 + maxDurationMin * 60;

      dispatch(setMaxDuration(newMaxDuration));
    },
    [dispatch]
  );

  useEffect(() => {
    const maxDurationHrs = maxDuration !== undefined ? Math.floor(maxDuration / 3600) : '';
    const maxDurationMin =
      maxDuration !== undefined && maxDurationHrs !== '' ? Math.floor((maxDuration - maxDurationHrs * 3600) / 60) : '';

    const maxDurationHrsInput = document.querySelector(`[name=${simulationDurationMaxHoursName}]`);
    const maxDurationMinInput = document.querySelector(`[name=${simulationDurationMaxMinutesName}]`);

    if (
      !maxDurationHrsInput ||
      !maxDurationMinInput ||
      !(maxDurationHrsInput instanceof HTMLInputElement) ||
      !(maxDurationMinInput instanceof HTMLInputElement)
    ) {
      // eslint-disable-next-line no-console
      console.error('maxDurationHrsInput or maxDurationMinInput is null or not input elements', {
        maxDurationHrsInput,
        maxDurationMinInput,
      });

      return;
    }

    maxDurationHrsInput.value = maxDurationHrs.toString();
    maxDurationMinInput.value = maxDurationMin.toString();
  }, [dispatch, maxDuration]);

  const handleChangeFastDisplayRefresh = useCallback(() => {
    const newValue = !fastRefreshEnabled;

    dispatch(setEnableFastRefresh(newValue));
  }, [dispatch, fastRefreshEnabled]);

  const handleChangeTrafficDisplay = useCallback(() => {
    const newValue = !displayTrafficEnabled;

    dispatch(setEnableDisplayTraffic(newValue));
    startTransition(() => {
      dispatch(clearAllCantons());
      dispatch(clearAllItineraries());
    });
  }, [dispatch, displayTrafficEnabled]);

  const handleChangeAdvancedTrafficDisplay = useCallback(() => {
    const newValue = !advancedTrafficDisplayEnabled;

    dispatch(setEnableAdvancedDisplayTraffic(newValue));
    startTransition(() => {
      dispatch(clearAllCantons());
      dispatch(clearAllItineraries());
    });
  }, [dispatch, advancedTrafficDisplayEnabled]);

  const handleChangeLibPerfo = useCallback(async () => {
    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    const newLibPerfoEnabledState = !libPerfoEnabled;

    if (newLibPerfoEnabledState) {
      await simulationService._ROBEMU_WasmWrapper_enablePerfo();
    } else {
      await simulationService._ROBEMU_WasmWrapper_disablePerfo();
    }

    dispatch(setEnableLibPerfo(newLibPerfoEnabledState));
  }, [dispatch, libPerfoEnabled, simulationServiceRef]);

  useEffect(() => {
    if (!isThereAtLeastOneBattPoint) {
      dispatch(setEnableCharging(false));
      handleChangeCharging(false);
    }
  }, [dispatch, handleChangeCharging, isThereAtLeastOneBattPoint]);

  return (
    <CollapseMore
      title={
        <>
          Parameters{' '}
          <Settings
            sx={{
              verticalAlign: 'middle',
            }}
          />
        </>
      }
    >
      Maximum duration{' '}
      <HelpIconTooltip
        title={
          <>
            The simulation will stop automatically after this duration
            <br />0 = no maximum duration
          </>
        }
      />
      <form onBlur={handleUpdateMaxDuration}>
        <Stack direction="row" spacing={2} justifyContent={'center'}>
          <TextField
            type="number"
            name={simulationDurationMaxHoursName}
            inputProps={{
              min: 0,
              step: 1,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
            }}
            sx={{
              background: maxDurationFormDisabled ? theme.palette.grey[200] : undefined,
            }}
            size="small"
            disabled={maxDurationFormDisabled}
          />
          <TextField
            type="number"
            name={simulationDurationMaxMinutesName}
            inputProps={{
              min: 0,
              step: 1,
              max: 59,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mn</InputAdornment>,
            }}
            sx={{
              width: '14ch',
              background: maxDurationFormDisabled ? theme.palette.grey[200] : undefined,
            }}
            size="small"
            disabled={maxDurationFormDisabled}
          />
        </Stack>
      </form>
      <FormControlLabel
        control={
          <Switch checked={enableCharging} onChange={handleToggleCharging} disabled={!isThereAtLeastOneBattPoint} />
        }
        label={
          <>
            Enable Charging
            {!isThereAtLeastOneBattPoint && (
              <Tooltip title="There's not charger point in the circuit.">
                <Warning
                  color="warning"
                  sx={{
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(2),
                  }}
                />
              </Tooltip>
            )}
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={fadeRobots} onChange={handleToggleFadeRobots} />}
        label={
          <>
            Fade Robots <HelpIconTooltip title="When enabled, the robots are displayed with a lower opacity." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={simulationEnableDisplay} onChange={handleToggleEnableDisplay} />}
        label={
          <>
            Enable Display{' '}
            <HelpIconTooltip title="When disabled, the robots as well as other elements are not displayed. This option can impact negatively the maximum simulation speed." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={fastRefreshEnabled} onChange={handleChangeFastDisplayRefresh} />}
        label={
          <>
            Fast Display Refresh{' '}
            <HelpIconTooltip title="When disabled, the User Interface is updated every second only (despite the simulation is running well in the background). When enabled, the UI is updated much more frequently. This option can impact negatively the maximum simulation speed." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={displayAllRobotsData} onChange={handleToggleDisplayAllRobotsData} />}
        label={
          <>
            Display All Robots Data{' '}
            <HelpIconTooltip title="When enabled, all the robots tooltips are displayed. This option can impact negatively the maximum simulation speed." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={displayTrafficEnabled} onChange={handleChangeTrafficDisplay} />}
        label={
          <>
            Display Traffic{' '}
            <HelpIconTooltip title="When enabled, some traffic data as well as robot itineraries are displayed on the map. This option can impact negatively the maximum simulation speed." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={advancedTrafficDisplayEnabled} onChange={handleChangeAdvancedTrafficDisplay} />}
        label={
          <>
            Advanced Traffic Display{' '}
            <HelpIconTooltip title="When enabled, more traffic data are displayed on the map. This option can have a huge negative impact on the maximum simulation speed." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <FormControlLabel
        control={<Switch checked={libPerfoEnabled} onChange={handleChangeLibPerfo} />}
        label={
          <>
            Robot Performance Monitoring{' '}
            <HelpIconTooltip title="When enabled, we retrieve more data about the robots. This option can impact negatively the maximum simulation speed." />
          </>
        }
        sx={{
          margin: 'auto',
          marginTop: theme.spacing(1),
        }}
      />
      <br />
      <DisplayErrorsMonitoringCollapse />
    </CollapseMore>
  );
}
