import type { Action } from 'redux';
import { getSettings } from 'utils/config';

export function getShowDynamicShortcutsInitialState(): boolean {
  return getSettings('showDynamicShortcuts');
}

interface ToggleShowDynamicShortcutsAction extends Action {
  type: '[Local] Toggle Show Dynamic Shortcuts';
  payload: {
    show: boolean;
  };
}
export function ToggleShowDynamicShortcuts(
  payload: ToggleShowDynamicShortcutsAction['payload']
): ToggleShowDynamicShortcutsAction {
  return { type: '[Local] Toggle Show Dynamic Shortcuts', payload };
}

type ToggleShowDynamicShortcutsActions = ToggleShowDynamicShortcutsAction;

export function showDynamicShortcutsReducer(
  state = getShowDynamicShortcutsInitialState(),
  action: ToggleShowDynamicShortcutsActions
): boolean {
  switch (action.type) {
    case '[Local] Toggle Show Dynamic Shortcuts': {
      return action.payload.show;
    }
  }

  return state;
}
