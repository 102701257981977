import type { CircuitTurn } from 'models/circuit';
import { roundCoordinates } from 'utils/helpers';
import type {
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  DeleteAction,
  DeleteFailureAction,
  DeleteSuccessAction,
  DeleteSuccessManyAction,
  LoadOneAction,
  LoadOneFailureAction,
  LoadOneSuccessAction,
  SaveAction,
  SaveFailureAction,
  SaveManyAction,
  SaveManySuccessAction,
  SaveSuccessAction,
  SelectAction,
} from 'utils/redux';
import { createEntityActions } from 'utils/redux';
import type { RoadEditorAction } from './circuit';

export const {
  types: TurnActionTypes,
  creators: {
    createFailure: createTurnFailureAction,
    createSuccess: createTurnSuccessAction,
    delete: deleteTurnAction,
    deleteFailure: deleteTurnFailureAction,
    deleteSuccess: deleteTurnSuccessAction,
    deleteSuccessMany: deleteTurnSuccessManyAction,
    loadOne: loadTurnAction,
    loadOneFailure: loadTurnFailureAction,
    loadOneSuccess: loadTurnSuccessAction,
    saveFailure: saveTurnFailureAction,
    saveSuccess: saveTurnSuccessAction,
    select: selectTurnAction,
    saveMany: saveManyTurnsAction,
    saveManySuccess: saveManyTurnsSuccessAction,
  },
} = createEntityActions<CircuitTurn>('Turns');

const {
  creators: { save },
} = createEntityActions<CircuitTurn>('Turns');

export function saveTurnAction(payload: Partial<CircuitTurn> & RoadEditorAction['payload']): SaveTurn {
  let action = save(payload);
  if (action.payload.geometry) {
    action = {
      ...action,
      payload: {
        ...action.payload,
        geometry: {
          ...action.payload.geometry,
          coordinates: action.payload.geometry.coordinates.map((coords) => roundCoordinates(coords)),
        },
      },
    };
  }

  return action;
}

export interface CreateTurn extends CreateAction<CircuitTurn> {}
export interface CreateTurnFailure extends CreateFailureAction {}
export interface CreateTurnSuccess extends CreateSuccessAction<CircuitTurn> {}
export interface DeleteTurn extends DeleteAction {}
export interface DeleteTurnFailure extends DeleteFailureAction {}
export interface DeleteTurnSuccess extends DeleteSuccessAction {}
export interface DeleteTurnSuccessMany extends DeleteSuccessManyAction {}
export interface LoadTurn extends LoadOneAction {}
export interface LoadTurnFailure extends LoadOneFailureAction {}
export interface LoadTurnSuccess extends LoadOneSuccessAction<CircuitTurn> {}
export interface SaveTurn extends SaveAction<CircuitTurn> {}
export interface SaveTurnFailure extends SaveFailureAction {}
export interface SaveTurnSuccess extends SaveSuccessAction<CircuitTurn> {}
export interface SelectTurn extends SelectAction {}
export interface SaveManyTurns extends SaveManyAction<CircuitTurn> {}
export interface SaveManyTurnsSuccess extends SaveManySuccessAction<CircuitTurn> {}

export type TurnActions =
  | CreateTurn
  | CreateTurnFailure
  | CreateTurnSuccess
  | DeleteTurn
  | DeleteTurnFailure
  | DeleteTurnSuccess
  | DeleteTurnSuccessMany
  | LoadTurn
  | LoadTurnFailure
  | LoadTurnSuccess
  | SaveTurn
  | SaveTurnFailure
  | SaveTurnSuccess
  | SelectTurn
  | SaveManyTurns
  | SaveManyTurnsSuccess;
