import type { CircuitShape } from 'models/circuit';
import type { CircuitState } from 'reducers/circuit/state';
import store from 'store';

/**
 * This function retrieves all the shapes present in the current circuit state.
 * It iterates over all the shape types in the circuit state, excluding 'cellTemplates' and 'layers' as they are not shapes.
 * For each shape type, it retrieves the shape entities using their ids and adds them to the shapes array.
 * @returns {CircuitShape[]} An array of all the shapes in the current circuit state.
 */
export function getCircuitShapes(): CircuitShape[] {
  const storeState = store.getState();
  const circuitState = storeState.circuit.present;

  const shapes: CircuitShape[] = [];

  const shapeTypes = Object.keys(circuitState) as (keyof CircuitState)[];
  shapeTypes.forEach((shapeType) => {
    // some elements in the circuitState are not shapes
    if (shapeType === 'cellTemplates' || shapeType === 'layers') return;

    const ids = circuitState[shapeType].ids;
    const entities = circuitState[shapeType].entities;

    ids.forEach((id) => {
      const shape = entities[id];
      shapes.push(shape);
    });
  });

  return shapes;
}
