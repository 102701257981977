import { useDebouncedValue } from '@mantine/hooks';
import { AddCircle } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import { openDialogAction } from 'actions';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { Border } from 'components/utils/border';
import { collapseTransitionDuration } from 'components/utils/constants';
import { capitalize } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { DialogTypes } from 'models';
import type { GeneralTrigger, Trigger, TriggerType } from 'models/simulation';
import { Tools } from 'models/tools';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import {
  addDefaultTrigger,
  defaultIntervalTriggerInterval,
  defaultSizeTriggerBuffer,
  removeTrigger,
  selectTrigger,
  setTrigger,
  setTriggerName,
} from 'simulation/triggers';
import store, { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { getTriggerTypeIcon } from '../simulation/triggers-list';
import { BufferTriggerConfiguration } from './buffer-triggers';
import { GeneralTriggerConfiguration } from './general-triggers';
import { IntervalTriggerConfiguration } from './interval-triggers';
import { ReplayTriggerConfiguration } from './replay-triggers';

export function TriggersToolbox(): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedTriggerId = useAppSelector((state) => state.triggers.selectedTriggerId);
  const triggers = useAppSelector((state) => state.triggers.triggers);

  const selectedTrigger = useMemo(() => {
    return triggers.find((trigger) => trigger.id === selectedTriggerId);
  }, [selectedTriggerId, triggers]);

  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.TriggersConfiguration)?.icon;
  }, []);

  const [editName, setEditName] = useState(false);

  const handleEditName = useCallback((newValue: boolean) => {
    setEditName(newValue);
  }, []);

  const handleOpenUpdateIntervalThroughput = useCallback(() => {
    dispatch(openDialogAction({ type: DialogTypes.UpdateIntervalThroughput, payload: {} }));
  }, [dispatch]);

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '450px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title="Triggers Configuration"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>
      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <Stack direction={'row'} spacing={1}>
          {!editName ? (
            <TriggersSelect handleEditName={handleEditName} />
          ) : (
            selectedTrigger && (
              <RenameTrigger handleEditName={handleEditName} trigger={selectedTrigger} triggers={triggers} />
            )
          )}
        </Stack>

        <Border>
          <TriggerConfiguration />
        </Border>
      </CardContent>
      <CardActions sx={{ float: 'right' }}>
        <Button
          variant="contained"
          // endIcon={<Send />}
          onClick={handleOpenUpdateIntervalThroughput}
          // disabled={submittingCollab}
        >
          Update Multiple Interval Throughput
        </Button>
      </CardActions>
    </Card>
  );
}

interface TriggersSelectProps {
  handleEditName?: (newValue: boolean) => void;
}
export function TriggersSelect({ handleEditName }: TriggersSelectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const triggers = useAppSelector((state) => state.triggers.triggers);

  const selectedTriggerId = useAppSelector((state) => state.triggers.selectedTriggerId);
  const selectedTrigger = useMemo(() => {
    return triggers.find((trigger) => trigger.id === selectedTriggerId);
  }, [selectedTriggerId, triggers]);

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const triggerId = e.target.value;
      dispatch(selectTrigger(triggerId));
    },
    [dispatch]
  );

  const handleAddTrigger = useCallback(() => {
    const storeState = store.getState();
    const flows = storeState.flows.flows;
    const selectedFlowId = storeState.flows.selectedFlowId ?? flows[0]?.id;
    if (!selectedFlowId) {
      SnackbarUtils.error(`The trigger has not been created. Please select a flow first.`);

      return;
    }

    dispatch(
      addDefaultTrigger({
        flowId: selectedFlowId,
      })
    );

    const newTrigger = store.getState().triggers.triggers.at(-1);
    if (!newTrigger) {
      SnackbarUtils.error(`The trigger has not been created.`);

      return;
    }

    dispatch(selectTrigger(newTrigger.id));

    SnackbarUtils.success(`Trigger "${newTrigger.name}" created`);
  }, [dispatch]);

  const handleDeleteTrigger = useCallback(async () => {
    if (!selectedTriggerId) {
      // eslint-disable-next-line no-console
      console.error(`No trigger selected`);

      return;
    }

    try {
      await confirm({ title: `Are you sure you want to remove "${selectedTrigger?.name}"?` });
    } catch (e) {
      return;
    }

    dispatch(removeTrigger(selectedTriggerId));
  }, [confirm, dispatch, selectedTrigger?.name, selectedTriggerId]);

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="select-trigger-label">Triggers</InputLabel>
        <Select
          labelId="select-trigger-label"
          label="Triggers"
          value={selectedTriggerId ?? ''}
          onChange={handleChange}
          size="small"
        >
          {triggers.map((trigger) => (
            <MenuItem key={trigger.id} value={trigger.id}>
              {trigger.name}
            </MenuItem>
          ))}
          {triggers.length === 0 && <MenuItem disabled>No triggers</MenuItem>}
        </Select>
      </FormControl>

      <Tooltip title="Rename this trigger">
        <Box component="span">
          <IconButton
            aria-label="rename trigger"
            onClick={() => handleEditName && handleEditName(true)}
            disabled={!selectedTriggerId}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Delete this trigger">
        <Box component="span">
          <IconButton aria-label="delete trigger" onClick={handleDeleteTrigger} disabled={!selectedTriggerId}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Create a new trigger">
        <Box component="span">
          <IconButton aria-label="create trigger" onClick={handleAddTrigger}>
            <AddCircle fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

const availableTriggers: TriggerType[] = ['interval', 'buffer', 'replay'] as const;

export function TriggerConfiguration(): JSX.Element {
  const selectedTriggerId = useAppSelector((state) => state.triggers.selectedTriggerId);
  const [selectedTriggerIdDelayed] = useDebouncedValue(selectedTriggerId, collapseTransitionDuration);

  const triggers = useAppSelector((state) => state.triggers.triggers);

  const dispatch = useAppDispatch();

  const handleChangeTriggerType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const triggerType = e.target.value as TriggerType;
      const trigger = triggers.find((tr) => tr.id === selectedTriggerId);
      if (!trigger) {
        return;
      }

      const defaultFlowId = store.getState().flows.flows[0]?.id;

      const newTriggerGeneral: GeneralTrigger = {
        type: triggerType,
        enabled: trigger.enabled,
        id: trigger.id,
        name: trigger.name,
      };
      let newTrigger: Trigger | undefined = undefined;
      if (newTriggerGeneral.type === 'interval') {
        newTrigger = {
          ...newTriggerGeneral,
          type: 'interval',
          flowId: 'flowId' in trigger ? trigger.flowId : defaultFlowId,
          interval: defaultIntervalTriggerInterval,
        };
      } else if (newTriggerGeneral.type === 'buffer') {
        const flowId =
          'flowId' in trigger
            ? typeof trigger.flowId === 'string'
              ? trigger.flowId
              : trigger.flowId[0]
            : defaultFlowId;

        newTrigger = {
          ...newTriggerGeneral,
          type: 'buffer',
          flowId,
          size: defaultSizeTriggerBuffer,
          bufferForFlowOnly: true,
        };
      } else if (newTriggerGeneral.type === 'replay') {
        newTrigger = {
          ...newTriggerGeneral,
          type: 'replay',
          tasks: [],
        };
      }

      if (!newTrigger) {
        // eslint-disable-next-line no-console
        console.error(`Unknown trigger type "${newTriggerGeneral.type}"`);

        return;
      }

      dispatch(setTrigger(newTrigger));
    },
    [dispatch, selectedTriggerId, triggers]
  );

  const trigger = (selectedTriggerId ? triggers.find((tr) => tr.id === selectedTriggerId) : undefined) ?? undefined;
  const triggerDelayed =
    (selectedTriggerIdDelayed ? triggers.find((tr) => tr.id === selectedTriggerIdDelayed) : undefined) ?? undefined;

  if (!trigger) {
    return <></>;
  }

  const displayTriggerData = selectedTriggerId === selectedTriggerIdDelayed;

  const triggerToUse = !displayTriggerData && triggerDelayed ? triggerDelayed : trigger;
  const triggerType = triggerToUse.type;

  return (
    <Collapse
      in={displayTriggerData}
      timeout={selectedTriggerIdDelayed ? collapseTransitionDuration : 0}
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      <Stack direction={'column'} spacing={1}>
        <FormControl fullWidth size="small" sx={{ marginTop: theme.spacing(1) }}>
          <InputLabel id="select-trigger-type">Trigger Type</InputLabel>
          <Select
            labelId="select-trigger-type"
            label="Trigger Type"
            value={triggerType}
            size="small"
            renderValue={(triggerType) => {
              return capitalize(triggerType);
            }}
            onChange={handleChangeTriggerType}
          >
            {availableTriggers.map((triggerType) => {
              const triggerTypeIcon = getTriggerTypeIcon(triggerType);

              return (
                <MenuItem key={triggerType} value={triggerType}>
                  <ListItemIcon>{triggerTypeIcon}</ListItemIcon>
                  <ListItemText>{capitalize(triggerType)}</ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {triggerType === 'interval' && <IntervalTriggerConfiguration key={triggerToUse.id} trigger={triggerToUse} />}
        {triggerType === 'buffer' && <BufferTriggerConfiguration key={triggerToUse.id} trigger={triggerToUse} />}
        {triggerType === 'replay' && <ReplayTriggerConfiguration key={triggerToUse.id} trigger={triggerToUse} />}

        <GeneralTriggerConfiguration trigger={triggerToUse} />
      </Stack>
    </Collapse>
  );
}

interface RenameTriggerProps {
  trigger: Trigger;
  triggers: Trigger[];
  handleEditName: (newValue: boolean) => void;
}
function RenameTrigger(props: RenameTriggerProps): JSX.Element {
  const { trigger, triggers, handleEditName } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleValidateName = useCallback(() => {
    const newName = inputRef.current?.value;

    const existingTrigger = triggers.find((t) => t.name === newName && t.id !== trigger.id);
    if (!newName || existingTrigger) {
      setError(true);

      return;
    }

    dispatch(
      setTriggerName({
        id: trigger.id,
        name: newName,
      })
    );

    if (handleEditName) {
      handleEditName(false);
    }
  }, [dispatch, handleEditName, trigger.id, triggers]);

  const handleRevertName = useCallback(() => {
    if (handleEditName) {
      handleEditName(false);
    }
  }, [handleEditName]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (error) setError(false);

      if (e.key === 'Enter') {
        handleValidateName();
      } else if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [error, handleRevertName, handleValidateName]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="flow-name"
          label="Flow name"
          variant="outlined"
          size="small"
          defaultValue={trigger.name}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          error={error}
          helperText={error ? 'This name is incorrect or already used' : undefined}
        />
      </FormControl>

      <Tooltip title="Revert">
        <Box component="span">
          <IconButton aria-label="revert" onClick={handleRevertName}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Validate">
        <Box component="span">
          <IconButton aria-label="validate" onClick={handleValidateName}>
            <CheckIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}
