import { Button } from '@mui/material';
import type { CircuitZone } from 'models/circuit';
import { useCallback } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { computeAislesAndAddToCircuit } from 'utils/circuit/compute-aisles';

interface UpdateAisleBtnProps {
  zone: CircuitZone;
}

export function UpdateAisleBtn(props: UpdateAisleBtnProps): JSX.Element {
  const { zone } = props;

  const handleClickUpdateAisle = useCallback(() => {
    if (typeof zone.id !== 'string') return;

    const updatedAisleNb = computeAislesAndAddToCircuit({
      zonesIdsToUpdate: [zone.id],
    });

    if (updatedAisleNb !== 1) {
      // eslint-disable-next-line no-console
      console.error(
        `Error updating aisle for zone with ID: ${zone.id}. Expected to update 1 aisle, but updated ${updatedAisleNb} aisles.`
      );
      SnackbarUtils.error('Error while updating aisle');

      return;
    }

    SnackbarUtils.success('Aisle updated');
  }, [zone.id]);

  if (!zone.properties.aisle?.isAisle) {
    return <></>;
  }

  return (
    <Button onClick={handleClickUpdateAisle} variant="outlined" disabled={zone.properties.locked}>
      Update Aisle
    </Button>
  );
}
