import type { SlotName } from 'components/editor/rack-edition';
import type { CircuitPoint, CircuitRack, CircuitStockZone, SlotArray } from 'models/circuit';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import type { Station } from './flows';

interface PositionToShapeProps {
  position: Station['positions'][0];
}

/**
 * Transforms a station position into its corresponding shape or slot object.
 *
 * This function takes a position from a station and returns the corresponding
 * shape or slot object from the circuit state. It handles different types of
 * positions such as PointShape, RackShape, Slot, and StockLine by checking the
 * type and retrieving the corresponding object from the store's state.
 *
 * @param {PositionToShapeProps} props - The properties object containing the position to transform.
 * @returns The corresponding shape or slot object, or null if not found.
 */

export function positionToShape(
  props: PositionToShapeProps
): CircuitPoint | CircuitRack | CircuitStockZone | SlotName | SlotArray | null {
  const { position } = props;

  const { id, type } = position;

  const circuitState = store.getState().circuit.present;

  if (type === ShapeTypes.PointShape) {
    return circuitState.points.entities[id] || null;
  } else if (type === ShapeTypes.RackShape) {
    return circuitState.racks.entities[id] || null;
  } else if (type === SlotTypes.Slot) {
    const racks = circuitState.racks.entities;
    const racksIds = circuitState.racks.ids;

    for (let i = 0; i < racksIds.length; i++) {
      const rackId = racksIds[i];
      const rack = racks[rackId];
      const columns = rack.properties.columns;
      for (let j = 0; j < columns.length; j++) {
        const column = columns[j];
        for (let k = 0; k < column.cells.length; k++) {
          const cell = column.cells[k];
          for (let l = 0; l < cell.names.length; l++) {
            const slots = cell.names[l];
            for (let m = 0; m < slots.length; m++) {
              const slot = slots[m];
              if (slot.id === id) {
                return slot;
              }
            }
          }
        }
      }
    }
  } else if (type === SlotTypes.StockLine) {
    const stockZones = circuitState.stockZones.entities;
    const stockZonesIds = circuitState.stockZones.ids;

    for (let i = 0; i < stockZonesIds.length; i++) {
      const stockZoneId = stockZonesIds[i];
      const stockZone = stockZones[stockZoneId];
      const slots = stockZone.properties.slots;
      for (let j = 0; j < slots.length; j++) {
        const slot = slots[j];
        if (slot.id === id) {
          return slot;
        }
      }
    }
  } else if (type === ShapeTypes.StockZoneShape) {
    const stockZones = circuitState.stockZones.entities;
    const stockZonesIds = circuitState.stockZones.ids;

    for (let i = 0; i < stockZonesIds.length; i++) {
      const stockZoneId = stockZonesIds[i];
      const stockZone = stockZones[stockZoneId];
      if (stockZone.id === id) {
        return stockZone;
      }
    }
  } else {
    assert<Equals<typeof type, never>>();
  }

  return null;
}
