import { PictureAsPdf, Warning } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { closeDialogAction } from 'actions';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import type { RobotTimers } from 'models/simulation';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import generatePDF, { Margin } from 'react-to-pdf';
import { SnackbarUtils } from 'services/snackbar.service';
import { isTaskABattTask } from 'simulation/opc';
import { setStartCollectingTime } from 'simulation/simulation';
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowFlowTimes,
  styledTableCellColor,
} from 'simulation/styled-table';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { Equals, Not } from 'tsafe';
import { assert } from 'tsafe/assert';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { formatSecondsToHHMMSS, formatSecondsToHHhMM, formatTime, hoursToSeconds } from 'utils/time';
import { deepMerge } from 'utils/ts/deep-merge';
import { isDefined } from 'utils/ts/is-defined';
import { divideAllPropertiesBy, resetObject } from 'utils/ts/reset-object';
import packageJson from '../../../../package.json';
import { DisplaySchedulerConfig } from './display-scheduler-config';
import { FlowThroughputTable } from './flow-throughputs-table';
import { TaskPerformanceAnalysis } from './task-performance-analysis';
import { TasksDurationChartReport } from './tasks-duration-chart-report';

const borderStyleSeparator = `2px solid ${theme.palette.grey[400]} !important`;

/**
 * Minimum duration of the simulation to be considered relevant [s]
 */
const minimumRelevantSimulationDuration = hoursToSeconds(1);

const estimateToDisplay = ['Average', 'Median', 'Min', 'Max'] as const;

interface FlowTimes {
  carryTime: number;
  emptyTime: number;
  pickTime: number;
  dropTime: number;
  totalTime: number;
  waitingTime: number;
}

interface FlowsStatUnique {
  id: string;
  name: string;
  nbTasks: number;
  nbStartedTasks: number;
  nbFinishedTasks: number;
  times: {
    totals: FlowTimes;
    averages: FlowTimes;
    medians: FlowTimes;
    mins: FlowTimes;
    maxs: FlowTimes;
  };
  objective: number;
  palletsPerTask: number;
}

export type FlowsStats = FlowsStatUnique[];

export interface TotalFlowsStats {
  objective: number;
  nbTasks: number;
  nbFinishedTasks: number;
  throughput: number;
  throughputWithUtilizationRate: number;
  deltaWithUtilizationRate: number | null;
}

export function SimulationReportDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const simulationData = useAppSelector((state) => state.dialog.data);

  const flows = useAppSelector((state) => state.flows.flows);
  const stations = useAppSelector((state) => state.flows.stations);

  const elapsedTimeSimulation = store.getState().simulation.simulatedTime ?? 1;
  const simulationTimeEpoch =
    simulationData && 'simulationEpoch' in simulationData ? simulationData.simulationEpoch : 0;

  const startSimulationDate = useAppSelector((state) => state.simulation.startEpochTime);
  const startCollectingTime = useAppSelector((state) => state.simulation.startCollectingTime);
  const robotsTimersHistory = useAppSelector((state) => state.simulation.robotsTimersHistory);

  const handleChangeStartCollectingTime = (value: number): void => {
    if (value < 0) {
      value = 0;
    }

    if (isNaN(value)) {
      value = 0;
    }

    dispatch(setStartCollectingTime(value));
  };

  const tasks = useAppSelector((state) => state.simulation.tasks);
  const tasksArr = useMemo(() => Object.values(tasks), [tasks]);

  const [open, setOpen] = useState(true);

  const handleClose = useCallback((): void => {
    setOpen(false);

    setTimeout(() => {
      dispatch(closeDialogAction());
    }, theme.transitions.duration.leavingScreen);
  }, [dispatch]);

  const dialogContentRef = useRef<HTMLDivElement>(null);

  const [considerWaitingTime, setConsiderWaitingTime] = useState(false);

  const handleChangeConsiderWaitingTime = (event: ChangeEvent<HTMLInputElement>): void => {
    setConsiderWaitingTime(event.target.checked);
  };

  const [generatingPdf, setGeneratingPdf] = useState(false);
  const handleDownloadPdf = useCallback(async () => {
    const el = dialogContentRef.current;
    if (!el) {
      return;
    }

    const projectName =
      (simulationData && 'projectName' in simulationData ? simulationData?.projectName : undefined) ?? 'unknown';
    const time = (simulationData && 'time' in simulationData ? simulationData?.time : undefined) ?? new Date();
    const filename = `${projectName} - ${formatTime(time)}.pdf`;

    setGeneratingPdf(true);

    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, 1000)
    );

    try {
      await generatePDF(() => el, {
        page: {
          margin: Margin.SMALL,
        },
        filename,
      });
    } catch (error) {
      SnackbarUtils.error('An error occured while generating the PDF');
    }

    setGeneratingPdf(false);
  }, [simulationData]);

  const stationsWithPositionsNames = useMemo(
    () =>
      stations.map((station) => {
        return {
          stationName: station.name,
          id: station.id,
          names: station.positions.map((position) => {
            return positionIdToPositionName(position.id, position.type);
          }),
        };
      }),
    [stations]
  );

  const robotsSimulationData = useMemo(() => {
    if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
      return undefined;
    }

    return simulationData.robots;
  }, [simulationData]);
  const [robotsBatteryDuration, setRobotsBatteryDuration] = useState<number[]>([]);

  const flowsStats: FlowsStats = useMemo(() => {
    const emptyTimes = {
      carryTime: 0,
      emptyTime: 0,
      pickTime: 0,
      dropTime: 0,
      totalTime: 0,
      waitingTime: 0,
    };
    const emptyTimesList: Record<keyof typeof emptyTimes, number[]> = {
      totalTime: [],
      carryTime: [],
      emptyTime: [],
      pickTime: [],
      dropTime: [],
      waitingTime: [],
    };

    const emptyFlowStat = {
      id: 'defaultFlow',
      name: 'defaultFlow',
      nbTasks: 0,
      nbStartedTasks: 0,
      nbFinishedTasks: 0,
      times: {
        totals: {
          ...structuredClone(emptyTimes),
        },
        averages: {
          ...structuredClone(emptyTimes),
        },
        medians: {
          ...structuredClone(emptyTimes),
        },
        mins: {
          ...structuredClone(emptyTimes),
        },
        maxs: {
          ...structuredClone(emptyTimes),
        },
      },
      objective: 0,
      palletsPerTask: 1,
    };
    const flowsStatsTmp = flows.map((flow) => {
      const newFlowStat = structuredClone(emptyFlowStat);
      newFlowStat.id = flow.id;
      newFlowStat.name = flow.name;
      newFlowStat.objective = flow.objective ?? 0;
      newFlowStat.palletsPerTask = flow.palletsPerTask ?? 1;

      return newFlowStat;
    });

    const newRobotsBatteryDuration: number[] = [];
    if (robotsSimulationData) {
      robotsSimulationData.forEach((robot) => {
        newRobotsBatteryDuration.push(0);
      });

      tasksArr.forEach((task) => {
        if (
          isTaskABattTask(task) &&
          task.robotID !== undefined &&
          task.startDate !== undefined &&
          task.startDate > startSimulationDate + startCollectingTime
        ) {
          newRobotsBatteryDuration[task.robotID - 1] += (task.endDate || simulationTimeEpoch) - task.startDate;
        }
      });
    }

    const tasks = store.getState().simulation.tasks;

    // key = flow id, value = array of durations
    const timesPerFlow: Record<string, typeof emptyTimesList> = {};

    const doesDefaultFlowExists = Object.values(tasks).some((task) => task.taskName === 'defaultFlow');
    if (doesDefaultFlowExists) {
      flowsStatsTmp.push(emptyFlowStat);
    }

    for (const taskId in tasks) {
      const task = tasks[taskId];

      if (startCollectingTime > 0) {
        if (!task.startDate) {
          continue;
        }

        if (startSimulationDate && task.startDate < startSimulationDate + startCollectingTime) {
          continue;
        }
      }

      if (!task) continue;

      const flowStat = flowsStatsTmp.find((flow) => flow.name === task.taskName);
      const flow = flows.find((flow) => flow.name === task.taskName);
      if (!flowStat || !flow) {
        continue;
      }

      if (!timesPerFlow[flowStat.id]) timesPerFlow[flowStat.id] = structuredClone(emptyTimesList);

      flowStat.nbTasks++;
      if (task.startDate) {
        flowStat.nbStartedTasks++;
      }

      if (task.endDate) {
        flowStat.nbFinishedTasks++;
      }

      if (task.startDate && task.endDate && task.creationDate) {
        const taskDuration = task.endDate - task.startDate;
        const taskWaitingDuration = task.startDate - task.creationDate;

        flowStat.times.totals.totalTime += taskDuration;
        flowStat.times.totals.waitingTime += taskWaitingDuration;

        timesPerFlow[flowStat.id].totalTime.push(taskDuration);
        timesPerFlow[flowStat.id].waitingTime.push(taskWaitingDuration);
      }

      if (
        task.stepNames &&
        task.stepEndDate &&
        task.stepEndDate.length &&
        task.stepNames.length === task.stepEndDate.length
      ) {
        for (let i = task.stepNames.length - 1; i >= 0; i--) {
          let keyTimeNextStep: 'emptyTime' | 'carryTime' = 'emptyTime';
          const stepName = task.stepNames[i];
          const stepDuration = task.stepEndDate[i] - (task.stepEndDate[i - 1] ?? task.startDate);

          if (isNaN(stepDuration)) continue;

          if (stepName === 'pick') {
            flowStat.times.totals.emptyTime += stepDuration;
            keyTimeNextStep = 'emptyTime';

            timesPerFlow[flowStat.id].emptyTime.push(stepDuration);
          } else if (stepName === 'drop') {
            flowStat.times.totals.carryTime += stepDuration;
            keyTimeNextStep = 'carryTime';

            timesPerFlow[flowStat.id].carryTime.push(stepDuration);
          } else if (stepName === 'move') {
            flowStat[keyTimeNextStep] = stepDuration;

            timesPerFlow[flowStat.id][keyTimeNextStep].push(stepDuration);
          } else if (stepName === 'battery' || stepName === 'batteryWaiting') {
            if (task.robotID !== undefined) newRobotsBatteryDuration[task.robotID] += stepDuration;
          } else {
            // eslint-disable-next-line no-console
            console.warn(`Unknown step name: ${stepName}`);
          }
        }
      }
    }

    // sort the times
    Object.keys(timesPerFlow).forEach((flowId) => {
      const times = timesPerFlow[flowId];

      Object.keys(times).forEach((uncastedKey) => {
        const key = uncastedKey as keyof typeof times;
        const timesForKey = times[key];

        timesForKey.sort((a, b) => a - b);
      });
    });

    // compute the averages
    flowsStatsTmp.forEach((flowStat) => {
      const { totalTime, carryTime, emptyTime, pickTime, dropTime, waitingTime } = flowStat.times.totals;
      let { nbTasks, nbStartedTasks, nbFinishedTasks } = flowStat;

      if (nbTasks === 0) {
        nbTasks = 1;
        nbStartedTasks = 1;
        nbFinishedTasks = 1;
      }

      flowStat.times.averages.totalTime = totalTime / nbFinishedTasks;
      flowStat.times.averages.carryTime = carryTime / nbFinishedTasks;
      flowStat.times.averages.emptyTime = emptyTime / nbFinishedTasks;
      flowStat.times.averages.pickTime = pickTime / nbStartedTasks;
      flowStat.times.averages.dropTime = dropTime / nbFinishedTasks;
      flowStat.times.averages.waitingTime = waitingTime / nbTasks;
    });
    // compute the medians and min/max
    flowsStatsTmp.forEach((flowStat) => {
      const flowTimes = timesPerFlow[flowStat.id];

      if (!flowTimes) return;

      const { totalTime, carryTime, emptyTime, pickTime, dropTime, waitingTime } = flowTimes;

      flowStat.times.medians.totalTime = totalTime[Math.floor(totalTime.length / 2)];
      flowStat.times.medians.carryTime = carryTime[Math.floor(carryTime.length / 2)];
      flowStat.times.medians.emptyTime = emptyTime[Math.floor(emptyTime.length / 2)];
      flowStat.times.medians.pickTime = pickTime[Math.floor(pickTime.length / 2)];
      flowStat.times.medians.dropTime = dropTime[Math.floor(dropTime.length / 2)];
      flowStat.times.medians.waitingTime = waitingTime[Math.floor(waitingTime.length / 2)];

      flowStat.times.mins.totalTime = totalTime[0];
      flowStat.times.mins.carryTime = carryTime[0];
      flowStat.times.mins.emptyTime = emptyTime[0];
      flowStat.times.mins.pickTime = pickTime[0];
      flowStat.times.mins.dropTime = dropTime[0];
      flowStat.times.mins.waitingTime = waitingTime[0];

      flowStat.times.maxs.totalTime = totalTime[totalTime.length - 1];
      flowStat.times.maxs.carryTime = carryTime[carryTime.length - 1];
      flowStat.times.maxs.emptyTime = emptyTime[emptyTime.length - 1];
      flowStat.times.maxs.pickTime = pickTime[pickTime.length - 1];
      flowStat.times.maxs.dropTime = dropTime[dropTime.length - 1];
      flowStat.times.maxs.waitingTime = waitingTime[waitingTime.length - 1];

      // replace NaNs by 0
      Object.keys(flowStat.times).forEach((key) => {
        const times = flowStat.times[key as keyof typeof flowStat.times];

        Object.keys(times).forEach((uncastedKey) => {
          const key = uncastedKey as keyof typeof times;
          const time = times[key];

          if (isNaN(time)) times[key] = 0;
        });
      });
    });

    setRobotsBatteryDuration(newRobotsBatteryDuration);

    return flowsStatsTmp;
  }, [flows, robotsSimulationData, simulationTimeEpoch, startCollectingTime, startSimulationDate, tasksArr]);

  const availableUtilizationRates = useMemo(
    () =>
      [
        { label: '0%', value: 0 },
        { label: '5%', value: 0.05 },
        { label: '10%', value: 0.1 },
        { label: '15%', value: 0.15 },
        { label: '20%', value: 0.2 },
        { label: '25%', value: 0.25 },
        { label: '30%', value: 0.3 },
        { label: '35%', value: 0.35 },
        { label: '40%', value: 0.4 },
        { label: '45%', value: 0.45 },
        { label: '50%', value: 0.5 },
        { label: '55%', value: 0.55 },
        { label: '60%', value: 0.6 },
        { label: '65%', value: 0.65 },
        { label: '70%', value: 0.7 },
        { label: '75%', value: 0.75 },
        { label: 'Low (80%)', value: 0.8 },
        { label: 'Medium (85%)', value: 0.85 },
        { label: 'High (90%)', value: 0.9 },
        { label: '95%', value: 0.95 },
        { label: '100%', value: 1 },
      ] as const,
    []
  );

  const [utilizationRateValue, setUtilizationRateValue] = useState('0.85');

  const handleSelectInputChange = (event: SelectChangeEvent): void => {
    setUtilizationRateValue(event.target.value);
  };

  const [selectedTaskId, setSelectedTaskId] = useState(Object.keys(store.getState().simulation.tasks)?.[0]);

  const roadEditorVersion = useMemo(() => getConfig('roadEditorVersion') || packageJson.version || undefined, []);
  const balyoSimulationVersion = window.balyoSimulationVersion;

  //New robot timer value with the startCollectingTime option
  const robotsTimersAfterStartCollectingTime = useMemo(() => {
    if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
      return undefined;
    }

    if (startCollectingTime === 0) {
      return undefined;
    }

    const robotsTimersAtStartCollectingTime = robotsTimersHistory.find(
      (timer) => timer.simulationTime === startCollectingTime
    );

    if (!robotsTimersAtStartCollectingTime) {
      return undefined;
    }

    const timers: RobotTimers[] = robotsTimersAtStartCollectingTime.robotsTimers
      .map((robotTimersAtCollectingTime, i) => {
        const robotTimersAtCurrentTime = simulationData.robots[i].timers;
        if (!!robotTimersAtCurrentTime) {
          //We must substract the timers of the newRobotsTimersHistory from the timers of the entire simulation to get the right data.
          return deepMerge(robotTimersAtCurrentTime, robotTimersAtCollectingTime.timers, true) as RobotTimers;
        }

        return undefined;
      })
      .filter(isDefined);

    return timers;
  }, [robotsTimersHistory, simulationData, startCollectingTime]);

  const robotTimersSum = useMemo(() => {
    if (startCollectingTime === 0) {
      if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
        return undefined;
      }

      let timersSum = simulationData.robots[0].timers
        ? resetObject(structuredClone(simulationData.robots[0].timers), 0)
        : undefined;
      if (!timersSum) return;

      simulationData.robots.forEach((robot) => {
        if (!robot.timers) return;

        if (robot.timers && timersSum) {
          timersSum = deepMerge(timersSum, robot.timers) as typeof timersSum;
        }
      });

      return timersSum;
    }

    if (startCollectingTime > 0) {
      if (!robotsTimersAfterStartCollectingTime) {
        return undefined;
      }

      let timersSumAfterStartCollectingTime = robotsTimersAfterStartCollectingTime[0];

      robotsTimersAfterStartCollectingTime.forEach((robotTimers) => {
        timersSumAfterStartCollectingTime = deepMerge(
          timersSumAfterStartCollectingTime,
          robotTimers
        ) as typeof timersSumAfterStartCollectingTime;
      });

      return timersSumAfterStartCollectingTime;
    }
  }, [robotsTimersAfterStartCollectingTime, simulationData, startCollectingTime]);

  const robotTimersAvg = useMemo(() => {
    if (startCollectingTime === 0) {
      if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
        return undefined;
      }

      const timersSum = robotTimersSum;
      if (!timersSum) return;

      const timersAvg = divideAllPropertiesBy(structuredClone(timersSum), simulationData.robots.length);

      return timersAvg;
    }

    if (startCollectingTime > 0) {
      if (!robotsTimersAfterStartCollectingTime) {
        return undefined;
      }

      const timersSum = robotTimersSum;
      if (!timersSum) return;

      const timersAvgAfterStartCollectingTime = divideAllPropertiesBy(
        structuredClone(timersSum),
        robotsTimersAfterStartCollectingTime.length
      );

      return timersAvgAfterStartCollectingTime;
    }
  }, [robotTimersSum, robotsTimersAfterStartCollectingTime, simulationData, startCollectingTime]);

  const ratioPalTask = flowsStats.map((flowStat) => flowStat.palletsPerTask);

  const isAllRatioAtOne = ratioPalTask.every((e) => e === 1);

  const [simplifiedMode, setSimplifiedMode] = useState(isAllRatioAtOne);

  const handleChangeSimplifiedMode = (event: ChangeEvent<HTMLInputElement>): void => {
    setSimplifiedMode(event.target.checked);
  };

  if (!simulationData || !('isSimulationReportData' in simulationData) || !simulationData.isSimulationReportData) {
    return <></>;
  }

  const simulationTime = simulationData.time;
  const simulationTimeFormatted = formatTime(simulationTime);
  const simulationDurationFormatted = formatSecondsToHHMMSS(elapsedTimeSimulation);
  const consideredSimultationTime = formatSecondsToHHMMSS(elapsedTimeSimulation - startCollectingTime);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="simulation-report-dialog-title"
      id="simulation-report"
    >
      <DialogTitle id="simulation-report-dialog-title">
        Simulation Report
        <Box
          component="span"
          sx={{
            float: 'right',
          }}
        >
          <Tooltip title="Download as PDF">
            <IconButton aria-label="Download as PDF" onClick={handleDownloadPdf} disabled={generatingPdf}>
              {!generatingPdf ? <PictureAsPdf /> : <CircularProgress size="1rem" />}
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent
        ref={dialogContentRef}
        sx={{
          overflow: generatingPdf ? 'visible' : undefined,
        }}
      >
        <Card
          variant="outlined"
          sx={{
            pageBreakInside: 'avoid',
          }}
        >
          <CardHeader title="Start collecting time" />
          <CardContent>
            <Stack direction="row" alignItems={'center'} spacing={2}>
              <div>Define the time at which the data will be considered</div>
              <Select
                variant="outlined"
                size="small"
                sx={{ width: '100px' }}
                value={startCollectingTime.toString()}
                onChange={(event: SelectChangeEvent) => {
                  handleChangeStartCollectingTime(Number(event.target.value));
                }}
              >
                <MenuItem value={0}>0</MenuItem>
                {robotsTimersHistory.map((robotTimer) => (
                  <MenuItem value={robotTimer.simulationTime}>
                    {Math.round(robotTimer.simulationTime / 60 / 5) * 5}
                  </MenuItem>
                ))}
              </Select>
              <div>min</div>
            </Stack>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            pageBreakInside: 'avoid',
            marginTop: '1rem',
          }}
        >
          <CardHeader title="Simulation Configuration" />
          <CardContent>
            <Table size="small">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>Simulation Time</StyledTableCell>
                  <StyledTableCell>{simulationTimeFormatted}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Simulation Duration</StyledTableCell>
                  <StyledTableCell>
                    {simulationDurationFormatted}
                    {startCollectingTime > 0 && `${' '}(considered simulation time: ${consideredSimultationTime})`}
                    {elapsedTimeSimulation < minimumRelevantSimulationDuration ? (
                      <>
                        {' '}
                        <Tooltip
                          title={`The simulation has been carried out on quite a short duration. You should have this in mind when looking at the result.`}
                        >
                          <Warning
                            color="warning"
                            fontSize="small"
                            sx={{
                              verticalAlign: 'middle',
                              marginLeft: '0.5rem',
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Project Name</StyledTableCell>
                  <StyledTableCell>{simulationData.projectName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Project SDK</StyledTableCell>
                  <StyledTableCell>{simulationData.sdkVersion}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Road Editor Version / Balyo Simulation Version</StyledTableCell>
                  <StyledTableCell>
                    {roadEditorVersion} / {balyoSimulationVersion}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Circuit</StyledTableCell>
                  <StyledTableCell>{simulationData.circuitName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Circuit version</StyledTableCell>
                  <StyledTableCell>{simulationData.circuitVersion}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Battery missions</StyledTableCell>
                  <StyledTableCell>{simulationData.battery.enabled ? 'Enabled' : 'Disabled'}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Opportunity Charging (OPC)</StyledTableCell>
                  <StyledTableCell>{simulationData.battery.OPC ? 'Enabled' : 'Disabled'}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {/* <AlertVNAsNotSupported /> */}

        <Card
          variant="outlined"
          sx={{
            marginTop: '1rem',
            pageBreakInside: 'avoid',
          }}
        >
          <CardHeader title="Robots" subheader="Model and parameters" />
          <CardContent>
            <Table size="small" key={'model-and-parameters-1'}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Serial</StyledTableCell>
                  <StyledTableCell>Model</StyledTableCell>
                  <StyledTableCell>Battery</StyledTableCell>
                  <StyledTableCell>Max Forward Speed</StyledTableCell>
                  <StyledTableCell>Max Backward Speed</StyledTableCell>
                  <StyledTableCell>Max Lateral Speed</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {simulationData.robots.map((robot) => (
                  <StyledTableRow key={robot.serial}>
                    <StyledTableCell>{robot.name}</StyledTableCell>
                    <StyledTableCell>{robot.serial}</StyledTableCell>
                    <StyledTableCell>{robot.modelName}</StyledTableCell>
                    <StyledTableCell>{robot.batteryModel}</StyledTableCell>
                    <StyledTableCell>
                      {robot.maxForwardSpeed !== undefined ? robot.maxForwardSpeed.toFixed(2) : 'unknown'} m/s
                    </StyledTableCell>
                    <StyledTableCell>
                      {robot.maxBackwardSpeed !== undefined ? robot.maxBackwardSpeed.toFixed(2) : 'unknown'} m/s
                    </StyledTableCell>
                    <StyledTableCell>
                      {robot.maxLateralSpeed !== undefined ? robot.maxLateralSpeed.toFixed(2) : 'unknown'} m/s
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>

            <Table size="small" key={'model-and-parameters-2'}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Serial</StyledTableCell>
                  <StyledTableCell>Charge Duration</StyledTableCell>
                  <StyledTableCell>Discharge Duration</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {simulationData.robots.map((robot) => (
                  <StyledTableRow key={robot.serial}>
                    <StyledTableCell>{robot.name}</StyledTableCell>
                    <StyledTableCell>{robot.serial}</StyledTableCell>
                    <StyledTableCell>{robot.chargeTime ? formatSecondsToHHhMM(robot.chargeTime) : ''}</StyledTableCell>
                    <StyledTableCell>
                      {robot.dischargeTime ? formatSecondsToHHhMM(robot.dischargeTime) : ''}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>

          <CardHeader title="Robots" subheader="Times and counters" />
          <CardContent>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>
                    Carry{' '}
                    <HelpIconTooltip
                      title="Duration spent when the robot was carrying a load"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    Empty{' '}
                    <HelpIconTooltip
                      title="Duration spent when the robot was not carrying a load"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    Available{' '}
                    <HelpIconTooltip
                      title="Duration spent with no mission, the robot was either waiting at its taxi point or going to its taxi point."
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderLeft: borderStyleSeparator,
                    }}
                  >
                    Battery{' '}
                    <HelpIconTooltip
                      title="Duration spent going to a battery point (docking station or table change) and charging"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderLeft: borderStyleSeparator,
                    }}
                  >
                    Traffic{' '}
                    <HelpIconTooltip
                      title="Duration spent waiting for traffic authorization (waiting for a robot to free the area for example)"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {simulationData.robots.map((robot, robotIndex) => {
                  if (!robot.timers) return <></>;

                  if (startCollectingTime > 0 && robotsTimersAfterStartCollectingTime) {
                    return (
                      <RobotTimerRow
                        key={robot.serial}
                        robotTimers={robotsTimersAfterStartCollectingTime[robotIndex]}
                        label={robot.name}
                        battTime={robotsBatteryDuration[robotIndex] ?? 0}
                      />
                    );
                  }

                  return (
                    <RobotTimerRow
                      key={robot.serial}
                      robotTimers={robot.timers}
                      label={robot.name}
                      battTime={robotsBatteryDuration[robotIndex] ?? 0}
                    />
                  );
                })}
              </TableBody>
              {(robotTimersAvg || robotTimersSum) && (
                <TableFooter
                  sx={{
                    borderTop: borderStyleSeparator,
                  }}
                >
                  {robotTimersAvg && (
                    <RobotTimerRow
                      key="Average"
                      robotTimers={robotTimersAvg}
                      battTime={robotsBatteryDuration.reduce((acc, val) => acc + val, 0) / robotsBatteryDuration.length}
                      label="Average"
                    />
                  )}

                  {robotTimersSum && (
                    <RobotTimerRow
                      key="Total"
                      robotTimers={robotTimersSum}
                      battTime={robotsBatteryDuration.reduce((acc, val) => acc + val, 0)}
                      label="Total"
                      displayPercents={false}
                    />
                  )}
                </TableFooter>
              )}
            </Table>
          </CardContent>

          <Box
            component="div"
            sx={{
              background: startCollectingTime > 0 ? theme.palette.grey[100] : undefined,
            }}
          >
            <CardHeader title="Robots" subheader="Distance traveled and average speed" />
            {startCollectingTime > 0 && (
              <Alert
                severity="info"
                sx={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
              >
                The following table does not consider the "start collecting time" parameter.
              </Alert>
            )}

            <CardContent>
              <Table size="small">
                <TableHead>
                  <StyledTableRow style={{ backgroundColor: 'red', color: 'white' }}>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Distance Traveled</StyledTableCell>
                    <StyledTableCell>Average Speed</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {simulationData.robots.map((robot) => {
                    const distance = robot.traveledDistance ?? 0;
                    const speed = distance / elapsedTimeSimulation;
                    const speedKmHr = speed * 3.6;

                    return (
                      <StyledTableRow key={robot.serial}>
                        <StyledTableCell>{robot.name}</StyledTableCell>
                        <StyledTableCell>{distance.toFixed(2)} m</StyledTableCell>
                        <StyledTableCell>
                          {speed.toFixed(2)} m/s ({speedKmHr.toFixed(1)} km/h)
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Box>
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: '1rem',
            pageBreakInside: 'avoid',
            overflow: 'visible',
          }}
        >
          <CardHeader title="Flows" subheader="List of flows" />
          <CardContent>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Source</StyledTableCell>
                  <StyledTableCell>Intermediate Step(s)</StyledTableCell>
                  <StyledTableCell>Destination</StyledTableCell>
                  <StyledTableCell>Steps</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {flows.map((flow) => {
                  const stationSource = stationsWithPositionsNames.find(
                    (station) => station.id === flow.stations[0]?.id
                  );
                  const stationDestination = stationsWithPositionsNames.find(
                    (station) => station.id === flow.stations?.at(-1)?.id
                  );
                  const intermediateStations =
                    flow.stations
                      .slice(1, -1)
                      .map((station) => {
                        const stationNames = stationsWithPositionsNames
                          .filter((s) => s.id === station.id)
                          ?.map((station) => station.stationName);

                        return stationNames.join(', ');
                      })
                      .join(' → ') ?? '';

                  const sources = stationSource?.names.join(', ') ?? 'None';
                  const destination = stationDestination?.names.join(', ') ?? 'None';

                  return (
                    <StyledTableRow key={flow.id}>
                      <StyledTableCell>{flow.name}</StyledTableCell>
                      <StyledTableCell>{sources}</StyledTableCell>
                      <StyledTableCell>{intermediateStations}</StyledTableCell>
                      <StyledTableCell>{destination}</StyledTableCell>
                      <StyledTableCell>{flow.stations.length}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>

          <CardHeader title="Flows" subheader="Durations" />
          <CardContent>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    Waiting{' '}
                    <HelpIconTooltip
                      title="Duration between the task creation time and when a robot actually began to do it."
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>Empty</StyledTableCell>
                  <StyledTableCell>Carry</StyledTableCell>
                  <StyledTableCell>
                    Total{' '}
                    <HelpIconTooltip
                      title="Carry + Empty durations"
                      sx={{
                        color: styledTableCellColor,
                        display: generatingPdf ? 'none' : undefined,
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {flowsStats.map((flowStat) => {
                  return estimateToDisplay.map((estimateName, index) => {
                    let keyName: keyof typeof flowStat.times | undefined;
                    if (estimateName === 'Average') keyName = 'averages';
                    else if (estimateName === 'Median') keyName = 'medians';
                    else if (estimateName === 'Min') keyName = 'mins';
                    else if (estimateName === 'Max') keyName = 'maxs';

                    assert<Not<Equals<typeof keyName, undefined>>>();

                    if (keyName === undefined) return <></>;

                    const flowTime = flowStat.times[keyName];

                    return (
                      <StyledTableRowFlowTimes key={`${flowStat.id}-${estimateName}`}>
                        {!generatingPdf && (
                          <StyledTableCell
                            rowSpan={index === 0 ? 4 : 1}
                            sx={{
                              display: index === 0 ? 'table-cell' : 'none',
                            }}
                          >
                            {flowStat.name}
                          </StyledTableCell>
                        )}
                        {
                          /**
                           * This block is used because there's a display issue in the generated PDF
                           */
                          generatingPdf && (
                            <>
                              {index === 0 && <StyledTableCell>{flowStat.name}</StyledTableCell>}
                              {index !== 0 && <StyledTableCell></StyledTableCell>}
                            </>
                          )
                        }
                        <StyledTableCell>{estimateName}</StyledTableCell>
                        <StyledTableCell>{formatSecondsToHHMMSS(flowTime.waitingTime)}</StyledTableCell>
                        <StyledTableCell>
                          {formatSecondsToHHMMSS(flowTime.emptyTime)} (
                          {((flowTime.emptyTime / flowTime.totalTime || 0) * 100).toFixed(0)}%)
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatSecondsToHHMMSS(flowTime.carryTime)} (
                          {((flowTime.carryTime / flowTime.totalTime || 0) * 100).toFixed(0)}%)
                        </StyledTableCell>
                        <StyledTableCell>{formatSecondsToHHMMSS(flowTime.totalTime)}</StyledTableCell>
                      </StyledTableRowFlowTimes>
                    );
                  });
                })}
              </TableBody>
            </Table>

            <Alert
              severity="info"
              sx={{
                marginTop: theme.spacing(2),
              }}
            >
              <AlertTitle>Steps Legend</AlertTitle>
              <ul>
                <li>
                  <b>Waiting</b>: Duration spent waiting for a robot to be available
                </li>
                <li>
                  <b>Carry</b>: Duration spent when the robot was carrying a load
                </li>
                <li>
                  <b>Empty</b>: Duration spent when the robot was not carrying a load
                </li>
                <li>
                  <b>Pick</b>: Duration spent picking a load (lifting the forks in front of the slot, etc.)
                </li>
                <li>
                  <b>Drop</b>: Duration spent dropping a load (lowering the forks, etc.)
                </li>
                <li>
                  <b>Total</b>: Total duration of the flow
                </li>
              </ul>
              <AlertTitle>Estimates Legend</AlertTitle>
              Beware that for <b>Average</b> and <b>Median</b> estimates, the values totals are not the sum of the
              values.
            </Alert>
          </CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CardHeader title="Flows" subheader="Throughputs" />
            <Box
              component={'div'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'flex-end'}
              sx={{ marginRight: '15px' }}
            >
              {isAllRatioAtOne && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={simplifiedMode}
                      onChange={handleChangeSimplifiedMode}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Simplified mode"
                />
              )}
              <Box component={'div'}>
                <Stack direction="row">
                  <InputLabel>Utilization rate</InputLabel>
                  <HelpIconTooltip
                    title="Percentage of time the robot is actually moving pallets. The remaining percentage is spent on incidents. Incidents are defined as any event provoked by the customer (warehouse operators) that blocks the robot."
                    sx={{
                      display: generatingPdf ? 'none' : undefined,
                    }}
                  ></HelpIconTooltip>
                </Stack>
                <Select defaultValue={'0.85'} onChange={handleSelectInputChange} size="small" sx={{ minWidth: 155 }}>
                  {availableUtilizationRates.map((number) => (
                    <MenuItem key={number.value} value={number.value}>
                      {number.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Stack>

          <CardContent>
            <FlowThroughputTable
              elapsedTimeSimulation={elapsedTimeSimulation - startCollectingTime}
              flowsStats={flowsStats}
              utilizationRateValue={utilizationRateValue}
              simplifiedMode={simplifiedMode}
            />
          </CardContent>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CardHeader title="Flows" subheader="Duration statistics" />{' '}
            <FormControlLabel
              control={<Switch checked={considerWaitingTime} onChange={handleChangeConsiderWaitingTime} />}
              label="Consider Waiting Time"
            />
          </Stack>

          <CardContent>
            <TasksDurationChartReport tasks={tasksArr} flows={flows} considerWaitingTime={considerWaitingTime} />
          </CardContent>
        </Card>

        {simulationData?.schedulerConfigName && simulationData?.schedulerConfig && (
          <DisplaySchedulerConfig
            schedulerConfig={simulationData.schedulerConfig}
            schedulerConfigName={simulationData.schedulerConfigName}
          />
        )}

        {!generatingPdf && (
          <TaskPerformanceAnalysis
            robotsSimulationData={robotsSimulationData}
            tasksArr={tasksArr}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            simulationData={simulationData}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

interface RobotTimerRowProps {
  robotTimers: RobotTimers;
  battTime: number;
  label: string;
  displayPercents?: boolean;
}
function RobotTimerRow(props: RobotTimerRowProps): JSX.Element {
  const { robotTimers, label, battTime, displayPercents = true } = props;

  const taxiTime = (robotTimers?.task.Available ?? 0) + (robotTimers?.task.RunToTaxi ?? 0);
  const trafficTime = (robotTimers?.traffic.AutoWait ?? 0) + (robotTimers?.traffic.AutoNav ?? 0);
  const carryTime = robotTimers?.carry ?? 0;
  const totalTime = robotTimers?.total ?? 0;
  const emptyTime = totalTime - carryTime;

  // when the robot is in taxi, it is empty but we do not want to consider this duration as empty
  const actualEmptyTime = Math.max(emptyTime - taxiTime, 0);

  return (
    <StyledTableRow>
      <StyledTableCell>{label}</StyledTableCell>
      <StyledTableCell>
        {formatSecondsToHHMMSS(carryTime)}{' '}
        {displayPercents && <>({((carryTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell>
        {formatSecondsToHHMMSS(actualEmptyTime)}{' '}
        {displayPercents && <>({((actualEmptyTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell>
        {formatSecondsToHHMMSS(taxiTime)} {displayPercents && <>({((taxiTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell
        sx={{
          borderLeft: borderStyleSeparator,
        }}
      >
        {formatSecondsToHHMMSS(battTime)} {displayPercents && <>({((battTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
      <StyledTableCell
        sx={{
          borderLeft: borderStyleSeparator,
        }}
      >
        {formatSecondsToHHMMSS(trafficTime)}{' '}
        {displayPercents && <>({((trafficTime / totalTime || 0) * 100).toFixed(0)}%)</>}
      </StyledTableCell>
    </StyledTableRow>
  );
}
