import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';
import { saveDeviceAction, selectDeviceAction } from 'actions/devices';
import { getSvgFromIcon } from 'components/utils/generate-jsx';
import * as d3 from 'd3';
import { Shape } from 'drawings/elements/shape.element';
import { offsetPosition } from 'drawings/helpers';
import type { Point } from 'geojson';
import type { DeviceProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { pointSize } from 'models/drawings';
import store from 'store';
import { cssTransformToPosition } from 'utils/circuit/utils';

const deviceNameOffsetY = -25;
const deviceNameOffsetX = 0;

const deviceIconSvg = getSvgFromIcon(KeyboardHideIcon);

export interface DeviceDrawingProperties {
  size: pointSize;
  refreshGeometryOnDrag?: boolean;
}

export class Device extends Shape<Point> {
  public properties: DeviceProperties;

  private translating = false;

  constructor(
    id: string,
    geometry: Point,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    properties: DeviceProperties,
    drawingProperties: DeviceDrawingProperties
  ) {
    super(id, ShapeTypes.DeviceShape, geometry, projection, zoomScale);
    this.properties = properties;

    this.setLocked(!!this.properties?.locked);

    this.drawDevice();
    this.drawName();

    this.updateShapeRef = this.updateShape;
    this.node.attr('layer-id', properties?.layerId || '');
  }

  public drawDevice(): void {
    const node = this.node;

    const deviceWidth = 20;
    const deviceHeight = 20;

    node
      .append('svg')
      .attr('main-shape-outline', true)
      .attr('viewBox', deviceIconSvg?.querySelector('svg')?.getAttribute('viewBox') || '')
      .attr('x', this.geometry.coordinates[0] - deviceWidth / 2)
      .attr('y', -this.geometry.coordinates[1] - deviceHeight)
      .attr('width', deviceWidth)
      .attr('height', deviceHeight)
      .append('path')
      .attr('d', deviceIconSvg?.querySelector('path')?.getAttribute('d') || '');
  }

  private drawName(): void {
    this.node
      .append('text')
      .attr('x', this.geometry.coordinates[0] + deviceNameOffsetX)
      .attr('y', -this.geometry.coordinates[1] + deviceNameOffsetY)
      .classed('device-name', true)
      .attr('text-anchor', 'middle')
      .text(this.properties.name);
  }

  protected onDrag(): void {
    if (!this.draggeable()) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    const dx = event.dx;
    const dy = event.dy;

    // we update the coordinates
    this.geometry.coordinates = offsetPosition(this.geometry.coordinates, dx, dy);

    // as well as the shape position
    const transform = this.node?.style('transform') as string | undefined | null;
    if (transform === 'none' || !transform) {
      this.node.style('transform', `translate(${dx}px, ${dy}px)`);
    } else {
      const newTransform = cssTransformToPosition(transform);
      newTransform[0] += event.dx;
      newTransform[1] += event.dy;
      this.node.style('transform', `translate(${newTransform[0]}px, ${newTransform[1]}px)`);
    }

    super.onDrag();
  }

  protected onDragEnd(): void {
    const transform = this.node?.style('transform') as string | undefined | null;

    if (transform === 'none' || !transform || transform === 'translate(0px, 0px)') {
      // we just clicked on it, we select it
      store.dispatch(
        selectDeviceAction({
          id: this.id,
        })
      );
    } else {
      // we actually dragged the shape, we save the new position
      store.dispatch(
        saveDeviceAction({
          id: this.id,
          geometry: this.geometry,
        })
      );
    }

    super.onDragEnd(this.properties);
  }

  protected translateEnd(): void {
    if (!this.draggeable()) return;

    store.dispatch(
      saveDeviceAction({
        id: this.id,
        geometry: this.geometry,
      })
    );
  }

  protected updateShape(): void {
    this.refreshGeometry();
  }
}
