import type { CircuitShape } from 'models/circuit';
import { isCircuitRack, isCircuitSegment, isCircuitStockZone } from './shape-guards';

/**
 * This function is used to get all the ids from the shapes
 * This include the shape ids but also their children ids (slotline, columns, cells, etc.)
 * @param shapes the list of shapes
 * @returns the list of ids
 */
export function getAllIdsFromShapes(shapes: CircuitShape[]): string[] {
  const ids = shapes.map((shape) => shape.id as string);

  shapes.forEach((shape) => {
    if (isCircuitSegment(shape)) {
      shape.properties.portions.forEach((portion, index) => {
        if (index === 0) return; // id of the first portion = id of the segment
        ids.push(portion.id);
      });
    } else if (isCircuitStockZone(shape)) {
      shape.properties.slots.forEach((slotLine) => {
        ids.push(slotLine.id);

        slotLine.slots.forEach((slot) => {
          ids.push(slot.id);
        });
      });
    } else if (isCircuitRack(shape)) {
      shape.properties.columns.forEach((column) => {
        ids.push(column.id);

        column.cells.forEach((cell) => {
          ids.push(cell.id);

          cell.names.forEach((slots) => {
            slots.forEach((slot) => {
              if (slot.id) ids.push(slot.id);
            });
          });
        });
      });
    }
  });

  return ids;
}
