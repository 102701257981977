import { loadLidarFromYJSAction } from 'actions';
import { remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import type { LidarState } from 'reducers/maps';
import store from 'store';
import type { YMap } from 'yjs/dist/src/internals';

export const useLidarSubscription = (): void => {
  const lidarMap = remoteDoc?.getMap('lidar') as YMap<LidarState>;

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      if (isTransactionLocal) return;

      const lidar = lidarMap.get('lidar') as LidarState;

      store.dispatch(loadLidarFromYJSAction({ lidar }));
    };

    lidarMap.observe(observerHandle);

    return () => {
      lidarMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
