import type { MapsActions } from 'actions/maps';
import { MapsActionTypes } from 'actions/maps';
import type { MapImage } from 'models/maps';
import { remoteDoc } from 'multiplayer/globals';
import { combineReducers } from 'redux';
import { reduceReducers } from 'utils/redux';
import { getLidarInitialState, lidarReducer } from './lidar-reducer';
import { getMapImageInitialState, mapImageReducer } from './map-image-reducer';
import { getMapImageTilesInitialState, mapImageTilesReducer } from './map-image-tiles-reducer';
import type { MapsState } from './state';

export const mapsReducer = reduceReducers([
  combineReducers({
    lidar: lidarReducer,
    mapImage: mapImageReducer,
    mapImageTiles: mapImageTilesReducer,
  }),
  loadMapsReducer,
]);

function getMapsInitialState(): MapsState {
  return {
    lidar: getLidarInitialState(),
    mapImage: getMapImageInitialState(),
    mapImageTiles: getMapImageTilesInitialState(),
  };
}

export function loadMapsReducer(state: MapsState = getMapsInitialState(), action: MapsActions): MapsState {
  switch (action.type) {
    case MapsActionTypes.LoadLidarFromYJS: {
      const { lidar } = action.payload;

      return {
        ...state,
        lidar,
      };
    }

    case MapsActionTypes.LoadMapImageFromYJS: {
      const { name } = action.payload;

      const layoutImageMap = remoteDoc?.getMap('mapImage');

      if (!layoutImageMap) return state;

      const mapImage = layoutImageMap.get(name) as { data: Partial<MapImage>; uInt8Array: Uint8Array };

      if (!mapImage.uInt8Array) return state;

      const isLayoutImageSvg = name.endsWith('.svg');
      const layoutImageBlob = isLayoutImageSvg
        ? new Blob([mapImage.uInt8Array], { type: 'image/svg+xml' })
        : new Blob([mapImage.uInt8Array]);
      const layoutImageURL = URL.createObjectURL(layoutImageBlob);

      if (state.mapImage.mapImages && state.mapImage.mapImages.some((prevMapImage) => prevMapImage.name === name)) {
        return {
          ...state,
          mapImage: {
            ...state.mapImage,
            mapImages: state.mapImage.mapImages.map((prevMapImage) => {
              if (prevMapImage.name === name) {
                return {
                  ...prevMapImage,
                  URL: layoutImageURL,
                };
              }

              return prevMapImage;
            }),
          },
        };
      }

      const originalWidth = mapImage.data.originalWidth ?? 0;
      const originalHeight = mapImage.data.originalHeight ?? 0;

      return {
        ...state,
        mapImage: {
          ...state.mapImage,
          mapImages: [
            ...(state.mapImage.mapImages ?? []),
            { x: 0, y: 0, URL: layoutImageURL, name, originalHeight, originalWidth },
          ],
        },
      };
    }

    case MapsActionTypes.LoadMapImagePropertiesFromYJS: {
      const { data } = action.payload;

      if (
        state.mapImage.mapImages &&
        state.mapImage.mapImages.some((prevMapImage) => prevMapImage.name === data.name)
      ) {
        return {
          ...state,
          mapImage: {
            ...state.mapImage,
            mapImages: state.mapImage.mapImages.map((prevMapImage) => {
              if (prevMapImage.name === data.name) {
                return {
                  ...prevMapImage,
                  ...data,
                };
              }

              return prevMapImage;
            }),
          },
        };
      }

      return {
        ...state,
        mapImage: {
          ...state.mapImage,
          mapImages: [...(state.mapImage.mapImages || []), data as MapImage],
        },
      };
    }

    default:
      return state;
  }
}
