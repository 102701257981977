import { CellWifi } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAsyncMemo } from 'use-async-memo';
import { PreferencesService } from 'utils/preferences';
import type { CommunicationMode } from './constants';
import { availableCommunicationModes, delayStopEditBlur } from './constants';
import { WifiConfig } from './wifi-config';

function prettifyCommunicationMode(modeValue: string): string {
  const mode = availableCommunicationModes.find((mode) => mode.value === modeValue);

  return mode ? mode.label : modeValue;
}

export interface EditCommunicationModeProps {
  projectOpened: boolean;
}

export function EditCommunicationMode({ projectOpened }: EditCommunicationModeProps): JSX.Element {
  const [editCommunicationMode, setEditCommunicationMode] = useState(false);
  const [communicationMode, setCommunicationMode] = useState<CommunicationMode | null>(null);
  useLayoutEffect(() => {
    // if (communicationMode === null) return;
    if (!projectOpened) return;

    try {
      setCommunicationMode(PreferencesService.getPreferenceValue('communication/mode') as CommunicationMode);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/mode from preferences`, e);
    }
  }, [projectOpened]);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  const fancyCommunicationMode = useMemo(() => {
    return communicationMode ? prettifyCommunicationMode(communicationMode) : communicationMode;
  }, [communicationMode]);

  const handleChangeCommunicationMode = useCallback(async (e: SelectChangeEvent<string>) => {
    const newMode = e.target.value as CommunicationMode;

    const [ok] = await PreferencesService.setPreferenceValue('communication/mode', newMode, true);

    if (ok) {
      setCommunicationMode(newMode);

      SnackbarUtils.success(`Communication mode updated to ${prettifyCommunicationMode(newMode)}`);

      setTimeout(() => setEditCommunicationMode(false), 100);
    } else {
      SnackbarUtils.error(`Could not update communication mode`);
    }
  }, []);

  return (
    <>
      {communicationMode && (
        <ListItem>
          <ListItemIcon>
            <CellWifi />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {editCommunicationMode ? (
                  <Select
                    value={communicationMode || ''}
                    size="small"
                    autoWidth
                    renderValue={(value) => prettifyCommunicationMode(value)}
                    onChange={handleChangeCommunicationMode}
                    onClose={(e) => setTimeout(() => setEditCommunicationMode(false), delayStopEditBlur)}
                  >
                    {availableCommunicationModes.map((mode) => (
                      <MenuItem value={mode.value} key={mode.value} selected={mode.value === communicationMode}>
                        {!!mode.icon && <ListItemIcon>{mode.icon}</ListItemIcon>}
                        <ListItemText primary={mode.label} />
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <>
                    {fancyCommunicationMode}
                    {!editCommunicationMode ? (
                      <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
                        <Box component="span">
                          <IconButton
                            onClick={() => {
                              setEditCommunicationMode(true);
                            }}
                            disabled={!editProjectPerm}
                          >
                            <EditIcon
                              sx={{
                                fontSize: '1rem',
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    ) : undefined}
                  </>
                )}
              </>
            }
            secondary="Communication Mode"
          />
        </ListItem>
      )}
      <Collapse
        in={communicationMode === 'wifi' || communicationMode === 'bridge'}
        timeout="auto"
        sx={{ flexShrink: 0 }}
      >
        <WifiConfig projectOpened={projectOpened} />
      </Collapse>
    </>
  );
}
