import type { AppState } from 'reducers/state';
import { createSelector } from 'reselect';
import type { ToolState } from './state';
import { get90DegSnapSate, getTool } from './reducer';

function toolSelector(state: AppState): ToolState {
  return state.tool;
}

export const selectTool = createSelector(toolSelector, getTool);
export const change90DegSnap = createSelector(toolSelector, get90DegSnapSate);
