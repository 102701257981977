const stringTimesN = (n: number, char: string): string => Array(n + 1).join(char);
export function prettifyXml(
  xmlInput: string,
  options: {
    indent?: number;
    newline?: string;
  } = {}
): string {
  const { indent: indentOption = 2, newline: newlineOption = '\n' } = options;
  const indentString = stringTimesN(indentOption, ' ');

  let formatted = '';
  const regex = /(>)(<)(\/*)/g;
  const xml = xmlInput.replace(regex, `$1${newlineOption}$2$3`);
  let pad = 0;
  xml.split(/\r?\n/).forEach((l) => {
    const line = l.trim();

    let indent = 0;
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    if (line.match(/.+<\/\w[^>]*>$/)) {
      indent = 0;
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    } else if (line.match(/^<\/\w/)) {
      // Somehow istanbul doesn't see the else case as covered, although it is. Skip it.
      /* istanbul ignore else  */
      if (pad !== 0) {
        pad -= 1;
      }
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    } else if (line.match(/^<\w([^>]*[^/])?>.*$/)) {
      indent = 1;
    } else {
      indent = 0;
    }

    const padding = stringTimesN(pad, indentString);
    formatted += padding + line + newlineOption;
    pad += indent;
  });

  return formatted.trim();
}

/**
 * Remove the last child nodes that are empty text nodes
 * This function treat the element in-place
 * @param el the element to trim
 * @returns the element itself for chaining
 */
export function trimEndChildNodes(
  el: Element,
  opts = {
    onlySpaces: false,
  }
): Element {
  let foundNotTextNode = false;
  Array.from(el.childNodes)
    .reverse()
    .forEach((node) => {
      if (foundNotTextNode) return;

      if (!node.textContent) {
        // we ignore the node if it has no text content
        return;
      }

      if (
        node.nodeType === Node.TEXT_NODE &&
        (opts.onlySpaces ? Array.from(node.textContent).every((char) => char === ' ') : node.textContent?.trim() === '')
      ) {
        node.remove();
      } else {
        foundNotTextNode = true;
      }
    });

  return el;
}
