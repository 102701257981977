import type { Position } from '@turf/helpers';
import * as d3 from 'd3';
import { getDistanceBetweenPoints, getUnitaryVector } from 'librarycircuit/utils/geometry/vectors';
import type { CircuitRack, CircuitStockZone, CircuitZone } from 'models/circuit';
import { epsilon, fequal } from 'utils/circuit/utils';
import { roundCoordinates, toDeg, toRad } from 'utils/helpers';

export interface Line {
  start: number[];
  end: number[];
}

export function getMathematicalProjection<T>(): d3.GeoPath<T, d3.GeoPermissibleObjects> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return d3
    .geoPath()
    .projection(matrix(1, 0, 0, -1, 0))
    .pointRadius((d) => 0.2);
}

/**
 * function used to create custom transformation for d3 projection
 * a: x coefficient, b: ?, c: y coefficient, d: ?, tx: x offset, ty: y offset
 * */
export function matrix(a: number, b: number, c: number, d: number, tx: number): d3.GeoStreamWrapper {
  return d3.geoTransform({
    point: function (x, y) {
      this.stream.point(a * x + b * y + tx, c * x + d * y);
    },
  });
}

export function svgCoordsToMathCoords([x, y]: [number, number]): [number, number] {
  return [x, -y];
}

export function offsetPositionArray(
  shape: GeoJSON.Position[][],
  offsetX: number,
  offsetY: number
): GeoJSON.Position[][] {
  if (shape.length > 0) {
    if (Array.isArray(shape[0])) {
      return shape.map((current) => current.map((coords) => offsetPosition(coords, offsetX, offsetY)));
    }
  }

  return shape;
}

export function offsetPositionLine(shape: GeoJSON.Position[], offsetX: number, offsetY: number): GeoJSON.Position[] {
  if (shape.length) {
    return shape.map((point) => offsetPosition(point, offsetX, offsetY));
  }

  return shape;
}

export function offsetPosition(position: GeoJSON.Position, offsetX: number, offsetY: number): GeoJSON.Position {
  return [position[0] + offsetX, position[1] - offsetY];
}

export function getNextPointIndex(start: number, step: number, length: number): number {
  return (start + step) % length;
}

export function getReverseUnitaryVector(line: Line): number[] {
  const vector = getUnitaryVector(line);

  return [-vector[0], -vector[1]];
}

export function getNormalProjection(line: Line, point: number[]): number[] {
  const vector = getUnitaryVector(line);

  const abscissaRes: number = (point[0] - line.start[0]) * vector[0] + (point[1] - line.start[1]) * vector[1];

  return [line.start[0] + abscissaRes * vector[0], line.start[1] + abscissaRes * vector[1]];
}

export function replaceCoordsInArray(
  from: GeoJSON.Position,
  to: GeoJSON.Position,
  coordinates: GeoJSON.Position[][]
): GeoJSON.Position[][] {
  return coordinates.map((currentEntry) => {
    // Remove last entry since it repeats the first one
    currentEntry.pop();
    const data = currentEntry;
    const draggedPointIndex = data.findIndex((elt) => from[0] === elt[0] && from[1] === elt[1]);
    if (draggedPointIndex !== -1) {
      // Well, it's a corner
      const nextCoordIndex = getNextPointIndex(draggedPointIndex, 1, data.length);
      const oppositeCoordIndex = getNextPointIndex(nextCoordIndex, 1, data.length);
      const prevCoordIndex = getNextPointIndex(oppositeCoordIndex, 1, data.length);

      const oppositeCoord = data[oppositeCoordIndex];
      const nextCoord = data[nextCoordIndex];
      const prevCoord = data[prevCoordIndex];

      const previousPointDirection: Line = {
        start: prevCoord,
        end: oppositeCoord,
      };
      const nextPointDirection: Line = {
        start: oppositeCoord,
        end: nextCoord,
      };

      data[prevCoordIndex] = getNormalProjection(previousPointDirection, to);
      data[nextCoordIndex] = getNormalProjection(nextPointDirection, to);
      data[draggedPointIndex] = to;
    } else {
      // Some people in the world die, don't complain on this.
      const { index: prevCoordIndex } = data.reduce(
        (previous, current, i) => {
          const delta = getCollinearityFactor(current, data[getNextPointIndex(i, 1, data.length)], from);

          return previous.delta < delta ? previous : { delta, index: i };
        },
        { delta: getCollinearityFactor(data[0], data[1], from), index: 0 }
      );

      if (prevCoordIndex !== -1) {
        const nextCoordIndex = getNextPointIndex(prevCoordIndex, 1, data.length);

        const previousEdge = {
          start: data[getNextPointIndex(prevCoordIndex, 3, data.length)],
          end: data[prevCoordIndex],
        };
        const nextEdge = {
          start: data[nextCoordIndex],
          end: data[getNextPointIndex(nextCoordIndex, 1, data.length)],
        };

        data[prevCoordIndex] = getNormalProjection(previousEdge, to);
        data[nextCoordIndex] = getNormalProjection(nextEdge, to);
      }
    }

    // The first point is repeated twice so we add it
    data.push(data[0]);

    return data;
  });
}

/**
 *
 * @param v1
 * @param v2
 * @returns an angle in degrees
 */
export function findVectorsAngle(v1: number[], v2: number[]): number {
  const radAngle = Math.atan2(v1[1], v1[0]) - Math.atan2(v2[1], v2[0]);

  return toDeg(radAngle);
}

/**
 * function used to calculate the angle in degrees between a vector and x abscissa
 * p1: origin, p2: vector point
 * The result is in degrees
 * */
export function findShapeOrientation(p1: number[], p2: number[]): number {
  const radAngle = Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);

  return toDeg(radAngle);
}

/**
 * function used to calculate the orientation of a road
 * * p1: first point drawn, p2: second point drawn / opposite point
 * */
export function findShapeOrientationFromCorners(p1: number[], p2: number[]): number {
  const angle = findShapeOrientation(p1, p2);

  return Math.round(angle / 90) * 90;
}

export function getCollinearityFactor(p1: number[], p2: number[], p3: number[]): number {
  const delta = Math.abs(
    Number((p1[0] * (p2[1] - p3[1]) + p2[0] * (p3[1] - p1[1]) + p3[0] * (p1[1] - p2[1])).toFixed(7))
  );

  return delta;
}

/**
 * Get consecutives side length of a polygon
 * @param coordinates coordinates to compute
 */
export function getConsecutivesSidesLength(coordinates: GeoJSON.Position[][]): number[] {
  const firstPoint = coordinates[0][0];
  const secondPoint = coordinates[0][1];
  const thirdPoint = coordinates[0][2];

  return [getDistanceBetweenPoints(firstPoint, secondPoint), getDistanceBetweenPoints(secondPoint, thirdPoint)];
}

/**
 * Get width and height of a polygon
 * @param coordinates coordinates to compute
 */
export function getDimensionsFromCoordinates(coordinates: GeoJSON.Position[][]): number[] {
  const [firstSideLength, secondSideLength] = getConsecutivesSidesLength(coordinates);

  if (firstSideLength > secondSideLength) {
    return [secondSideLength, firstSideLength];
  }

  return [firstSideLength, secondSideLength];
}

/**
 * Get center coordinates of a polygon
 * @param coordinates coordinates to compute
 */
export function getCenterFromCoordinates(coordinates: GeoJSON.Position[][]): number[] {
  const centerVector = [
    (coordinates[0][2][0] - coordinates[0][0][0]) / 2,
    (coordinates[0][2][1] - coordinates[0][0][1]) / 2,
  ];

  return [coordinates[0][0][0] + centerVector[0], coordinates[0][0][1] + centerVector[1]];
}

/**
 * Rotate a polygon
 * @param rotationCenter [x, y] point to rotate around
 * @param coordinates list of coordinates to rotate
 * @param angle in degrees
 * @returns the polygon after rotation
 */
export function rotateCoordinates(
  rotationCenter: GeoJSON.Position,
  coordinates: GeoJSON.Position[][],
  angle: number
): GeoJSON.Position[][] {
  return coordinates.map((current) => {
    return current.map((coord) => {
      return rotatePoint(rotationCenter, coord, angle);
    });
  });
}

/**
 *
 * @param rotationCenter [x, y] point to rotate around
 * @param coordinates list of coordinates to rotate
 * @param angle in degrees
 * @returns the list of coordinates after the rotation
 */
export function rotatePolygon(
  rotationCenter: GeoJSON.Position,
  coordinates: GeoJSON.Position[],
  angle: number
): GeoJSON.Position[] {
  return coordinates.map((coord) => rotatePoint(rotationCenter, coord, angle));
}

/**
 *
 * @param rotationCenter [x, y] point to rotate around
 * @param coordinates [x, y] to rotate
 * @param angle in degrees
 * @returns the coordinates after the rotation
 */
export function rotatePoint(
  rotationCenter: GeoJSON.Position,
  pointToRotate: GeoJSON.Position,
  angle: number
): number[] {
  const [cx, cy] = rotationCenter;
  const [x, y] = pointToRotate;

  const radAngle = toRad(angle);
  const cos = Math.cos(radAngle);
  const sin = Math.sin(radAngle);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;

  return [nx, ny];
}

export function rotatePolygon2(
  rotationCenter: GeoJSON.Position,
  coordinates: GeoJSON.Position[],
  angle: number
): GeoJSON.Position[] {
  return coordinates.map((coord) => rotatePoint2(rotationCenter, coord, angle));
}

export function rotatePoint2(
  rotationCenter: GeoJSON.Position,
  pointToRotate: GeoJSON.Position,
  angle: number
): number[] {
  const [cx, cy] = rotationCenter;
  const [x, y] = pointToRotate;

  const x0 = x - cx;
  const y0 = y - cy;

  const radAngle = toRad(angle);
  const cos = Math.cos(radAngle);
  const sin = Math.sin(radAngle);
  const nx = x0 * cos - y0 * sin + cx;
  const ny = x0 * sin + y0 * cos + cy;

  return [nx, ny];
}

export function buildArrow(
  center: number[],
  orientation: number,
  sideLength: number,
  frontLength: number,
  twoWay: boolean
): Line[] {
  const radiusData: Line[] = [];
  let referenceLength = sideLength;

  if (frontLength < sideLength) {
    referenceLength = sideLength * (frontLength / sideLength);
  }

  const lengthRatio = referenceLength / 2;
  const widthRatio = twoWay ? lengthRatio / 4 : lengthRatio / 2;

  const arrowHead = [
    Math.cos(toRad(-orientation)) * (lengthRatio / 2) + center[0],
    -Math.sin(toRad(-orientation)) * (lengthRatio / 2) + center[1],
  ];

  radiusData.push({
    start: center,
    end: arrowHead,
  });

  radiusData.push({
    start: center,
    end: [
      Math.cos(toRad(-orientation + 180)) * (lengthRatio / 2) + center[0],
      -Math.sin(toRad(-orientation + 180)) * (lengthRatio / 2) + center[1],
    ],
  });

  radiusData.push({
    start: arrowHead,
    end: [
      Math.cos(toRad(-orientation + 150)) * widthRatio + arrowHead[0],
      -Math.sin(toRad(-orientation + 150)) * widthRatio + arrowHead[1],
    ],
  });

  radiusData.push({
    start: arrowHead,
    end: [
      Math.cos(toRad(-orientation - 150)) * widthRatio + arrowHead[0],
      -Math.sin(toRad(-orientation - 150)) * widthRatio + arrowHead[1],
    ],
  });

  return radiusData;
}

/**
 * This function computes points (linestring) to make two arrows at the end points of a linestring
 * @param coords coords of a linestring
 * @param direction of the arrows, first value is the direction of the first arrow and second value of the second one, for exemple: [1, 1] = >--->, [-1, 1] = <--->, [1, -1] = >---<
 * @param arrowLength length of the arrow in px
 * @param arrowThickness thickness of the arrow in px
 * @param forceAngle force the angle of the arrow, if undefined, the angle will be computed from the linestring
 * @returns two linestring that make the arrows
 */
export function computeArrowsFromLineString(
  coords: GeoJSON.Position[],
  direction = [1, 1],
  arrowLength = 10,
  arrowThickness = 7,
  forceAngle?: number
): GeoJSON.Position[][] {
  if (!coords || coords.length < 2) {
    return [] as GeoJSON.Position[][];
  }

  const angleVect1 = forceAngle ?? findShapeOrientation(coords[0], coords[1]) + (direction[0] < 0 ? 180 : 0);
  const angleVect2 =
    forceAngle ??
    (coords.length === 2
      ? angleVect1 + (direction[1] < 0 ? 180 : 0) - (direction[0] < 0 ? 180 : 0)
      : findShapeOrientation(coords[coords.length - 2], coords[coords.length - 1]) + (direction[1] === -1 ? 180 : 0));

  let arrow1: GeoJSON.Position[] = [
    [0, -arrowThickness / 2],
    [arrowLength, 0],
    [0, arrowThickness / 2],
  ];
  let arrow2 = [...arrow1];
  if (direction[0] === -1) arrow1 = offsetPositionLine(arrow1, -arrowLength, 0);
  if (direction[0] === 2) arrow1 = offsetPositionLine(arrow1, -arrowLength, 0);
  if (direction[1] === 1) arrow2 = offsetPositionLine(arrow2, -arrowLength, 0);
  if (direction[1] === -2) arrow2 = offsetPositionLine(arrow2, -arrowLength, 0);

  arrow1 = rotatePolygon([0, 0], arrow1, angleVect1);
  arrow2 = rotatePolygon([0, 0], arrow2, angleVect2);

  arrow1 = offsetPositionLine(arrow1, coords[0][0], coords[0][1]);
  arrow2 = offsetPositionLine(arrow2, coords[1][0], coords[1][1]);

  return [arrow1, arrow2];
}

export function arrowsToString(coords: GeoJSON.Position[][]): string[] {
  return coords.map((a) =>
    a
      .map((b) => {
        return roundCoordinates(b).join(' ');
      })
      .join(',')
  );
}

/**
 * Get the distance between a point and a segment
 * @param x abcissa of the target point
 * @param y ordinate of the target point
 * @param x1 absissa of the first point of the segment
 * @param y1 ordinate of the first point of the segment
 * @param x2 absissa of the second point of the segment
 * @param y2 ordinate of the second point of the segment
 * @return the distance
 */
export function pDistance(x: number, y: number, x1: number, y1: number, x2: number, y2: number): number {
  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const lenSq = C * C + D * D;
  let param = -1;
  if (lenSq !== 0)
    //in case of 0 length line
    param = dot / lenSq;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;

  return Math.sqrt(dx * dx + dy * dy);
}

/**
 * Get the closest point on a segment from a point
 * @param P Position of the target point
 * @param A Position of the first point of the segment
 * @param B Position of the second point of the segment
 * @param includeInSegment @default true wether the point should be included in the segment or not
 * @return Position of the closest point found
 * @return Number in [0, 1] that represents the position of the point on the segment, 0 = A and 1 = B (if includeInSegment is true, t can be outside of the [0, 1] interval)
 * @return Position of the closest segment
 */
export function getClosestPointInSegment(
  P: GeoJSON.Position,
  A: GeoJSON.Position,
  B: GeoJSON.Position,
  includeInSegment = true
): [GeoJSON.Position, number, Position[]] {
  // we create the following vectors
  const AP = [P[0] - A[0], P[1] - A[1]];
  const AB = [B[0] - A[0], B[1] - A[1]];

  const normAB2 = AB[0] * AB[0] + AB[1] * AB[1]; // the norm of the vector AB, but squared

  const dot = AP[0] * AB[0] + AP[1] * AB[1]; // the dot product between AP and AB

  let t = dot / normAB2; // normalized distance between A and the closest point (between 0 and 1)

  if (includeInSegment) {
    // if it's not included in the segment, we clamp it (to the [0, 1] interval)
    if (t > 1) t = 1;
    else if (t < 0) t = 0;
  }

  const segment = [A, B];

  return [
    [A[0] + AB[0] * t, A[1] + AB[1] * t], // we go from A and we add AB times the normalized distance
    t,
    segment,
  ];
}

/**
 * Get the closest point and line on a polygon
 * @param P Position of the target point
 * @param shape Shape : Rack, Zone or StockZone
 * @param segment the opposite attached segment when we move a measurer attached to two shape (Rack, Zone or StockZone) or one shape and one segment
 * @return Position of the closest point found
 */

export function getClosestPointAndLineInPolygon(
  P: Position,
  shape: CircuitRack | CircuitZone | CircuitStockZone,
  segment?: [Position, Position]
): { point: Position; line: [Position, Position] } {
  const coords = shape.geometry.coordinates[0];
  // first we get all our lines from the rack = perimeter
  const lines: [Position, Position][] = [];

  //We create an array of lines in the clockwise direction
  for (let i = 0; i < coords.length - 1; i++) {
    const A = coords[i];
    const B = coords[i + 1];

    lines.push([A, B]);
  }

  // then we get the closest point from the point P to every lines
  const closestPointsAndLines = lines.map((line) => {
    const [pt] = getClosestPointInSegment(P, line[0], line[1], true);

    return { point: pt, line };
  });

  //In the context of rectangles parallel to a segment, our calculation method returns the two sides of the rectangle that are parallel to the segment.
  //We therefore calculate these two solutions ans then discriminate.

  // so we compute the closest point of the previously computed points in clockwise direction
  let minDistance = Infinity;
  let closestPointIndex = 0;
  for (let i = 0; i < closestPointsAndLines.length; i++) {
    const pt = closestPointsAndLines[i].point;
    const distance = getDistanceBetweenPoints(P, pt);

    if (distance < minDistance) {
      closestPointIndex = i;
      minDistance = distance;
    }
  }

  const closestPoint = closestPointsAndLines[closestPointIndex].point;
  const associatedLine = closestPointsAndLines[closestPointIndex].line;

  // and then we compute the closest point of the previously computed points in counterclockwise direction
  let minDistanceBis = Infinity;
  let closestPointIndexBis = 0;
  for (let i = closestPointsAndLines.length - 1; i >= 0; i--) {
    const pt = closestPointsAndLines[i].point;
    const distance = getDistanceBetweenPoints(P, pt);

    if (distance < minDistanceBis) {
      closestPointIndexBis = i;
      minDistanceBis = distance;
    }
  }

  const associatedLineBis = closestPointsAndLines[closestPointIndexBis].line;

  // We want the line parallel to the segment to make sure we don't have any probleme when we move a measurer

  /**is the associatedLine (the clockwise one) intersects with the segment to which the measurer is attached*/
  const isLinesIntersect = segment
    ? findIntersectionBetweenLines(associatedLine[0], associatedLine[1], segment[0], segment[1])[0]
    : undefined;

  /**is the associatedLineBis (the counterclockwise one) intersects with the segment to which the measurer is attached*/
  const isLinesIntersectBis = segment
    ? findIntersectionBetweenLines(associatedLineBis[0], associatedLineBis[1], segment[0], segment[1])[0]
    : undefined;

  // we want to find the right line for the measurer when it is attached to the corner of a shape, the one to which the attached segment is parallel
  if (associatedLine === associatedLineBis) {
    return { point: closestPoint, line: associatedLine };
  }

  if (isLinesIntersect) {
    return { point: closestPoint, line: associatedLineBis };
  }

  if (isLinesIntersectBis) {
    return { point: closestPoint, line: associatedLine };
  }

  return {
    point: closestPoint,
    line: associatedLine,
  };
}

/**
 * Get the closest point on a segment from a point
 * @param P Position of the target point
 * @param A Position of the first point of the segment
 * @param B Position of the second point of the segment
 * @return 0 = point on line, 1 = point on the left, -1 = point on the right
 */
export function getSidePointVsLine(P: GeoJSON.Position, A: GeoJSON.Position, B: GeoJSON.Position): number {
  return Math.sign((B[0] - A[0]) * (P[1] - A[1]) - (B[1] - A[1]) * (P[0] - A[0]));
}

/**
 * Test wehther a point is included in a rectangle
 * @param P The position to test
 * @param x The x coordinate of the top left corner of the rectangle
 * @param y The y coordinate of the top left corner of the rectangle
 * @param x2 The x coordinate of the bottom right corner of the rectangle
 * @param y2 The y coordinate of the bottom right corner of the rectangle
 * @returns Wether or not the point is included in the rectangle
 */
export function isPointInRect(P: GeoJSON.Position, x: number, y: number, x2: number, y2: number): boolean {
  return P[0] >= x && P[0] <= x2 && P[1] >= y && P[1] <= y2;
}

/**
 * Function to find the orientation of three points (a, b, c)
 * @param A First point of the "line"
 * @param B Second point of the "line"
 * @param C Point on the other segment
 * @returns If the orientation is zero, the points are collinear. If it's positive, the orientation is clockwise. If negative, it's counterclockwise.
 */
export function findOrientationOfThreePoints(A: GeoJSON.Position, B: GeoJSON.Position, C: GeoJSON.Position): number {
  return (B[1] - A[1]) * (C[0] - B[0]) - (B[0] - A[0]) * (C[1] - B[1]);
}

/**
 * Function to check if point Q lies on the line segment [PR]
 * @param P First point of the "line"
 * @param Q Point on the other segment
 * @param R Second point of the "line"
 * @returns boolean : point q lies on the line segment pr?
 */
export function isPointOnSegment(P: GeoJSON.Position, Q: GeoJSON.Position, R: GeoJSON.Position): boolean {
  return (
    Q[0] <= Math.max(P[0], R[0]) &&
    Q[0] >= Math.min(P[0], R[0]) &&
    Q[1] <= Math.max(P[1], R[1]) &&
    Q[1] >= Math.min(P[1], R[1])
  );
}

/**
 * Computes if a line (AB) collides with the segment [OP]
 * @param A First point of the "line"
 * @param B Second point of the "line"
 * @param O First point of the segment
 * @param P Second point of the segment
 * @returns boolean : both object collides?
 */

export function collisionLineSegment(
  A: GeoJSON.Position,
  B: GeoJSON.Position,
  O: GeoJSON.Position,
  P: GeoJSON.Position
): boolean {
  const o1 = findOrientationOfThreePoints(A, B, O);
  const o2 = findOrientationOfThreePoints(A, B, P);
  const o3 = findOrientationOfThreePoints(O, P, A);
  const o4 = findOrientationOfThreePoints(O, P, B);

  // Check if the orientations are different and the line segments intersect
  if (!fequal(o1, o2) && !fequal(o3, o4)) {
    return true;
  }

  // Check for special cases where points are collinear and lie on the segments
  if (!fequal(o1, 0) && isPointOnSegment(A, O, B)) {
    return true;
  }

  if (!fequal(o2, 0) && isPointOnSegment(A, P, B)) {
    return true;
  }

  if (!fequal(o3, 0) && isPointOnSegment(O, A, P)) {
    return true;
  }

  if (!fequal(o4, 0) && isPointOnSegment(O, B, P)) {
    return true;
  }

  // If none of the above conditions are met, the line segments do not intersect
  return false;
}

/**
 * Find the point intersection between the lines A1 -> A2 and B1 -> B2
 * @param A1 Position of a point of the first segment
 * @param A2 Other position of a point of the first segment
 * @param B1 Position of a point of the second segment
 * @param B2 Other position of a point of the second segment
 *
 * @return boolean, does the lines intersects?
 * @return boolean, does the segments intersects?
 * @return Position of the intersection
 */
export function findIntersectionBetweenLines(
  A1: GeoJSON.Position,
  A2: GeoJSON.Position,
  B1: GeoJSON.Position,
  B2: GeoJSON.Position
): [boolean, boolean, GeoJSON.Position] {
  let segmentsIntersect: boolean;
  let intersection: GeoJSON.Position;

  const A = [A2[0] - A1[0], A2[1] - A1[1]];
  const B = [B2[0] - B1[0], B2[1] - B1[1]];
  let k = -(A1[0] * B[1] - B1[0] * B[1] - B[0] * A1[1] + B[0] * B1[1]) / (A[0] * B[1] - A[1] * B[0]);

  const linesIntersect = collisionLineSegment(A1, A2, B1, B2);

  if (!linesIntersect) {
    // the lines are parallel, or close to it
    segmentsIntersect = false;
    intersection = [NaN, NaN];
  } else {
    intersection = [A1[0] + k * A[0], A1[1] + k * A[1]];

    const isIntersectionOnFirstSegment = isPointOnSegment(A1, intersection, A2);
    const isIntersectionOnSecondSegment = isPointOnSegment(B1, intersection, B2);

    //the segment intersects if the intersection is on the two segments
    segmentsIntersect = !!(isIntersectionOnFirstSegment && isIntersectionOnSecondSegment);

    // find the closest points on the segments
    if (k < 0) k = 0;
    else if (k > 1) k = 1;
  }

  return [linesIntersect, segmentsIntersect, intersection];
}

/**
 * Computes the shortest distance between two points
 * @param A Position of the first point
 * @param B Position of the second point
 *
 * @return number - the shortest distance
 */
export function findShortestDistanceBetweenPoints(A: GeoJSON.Position, B: GeoJSON.Position): number {
  const deltaX = B[0] - A[0];
  const deltaY = B[1] - A[1];

  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

  return distance;
}

/**
 * Computes the shortest distance between two segments
 * @param A1 Position of one end point of the first segment
 * @param A2 Position of the other end point of the first segment
 * @param B1 Position of one end point of the second segment
 * @param B2 Position of the other end point of the second segment
 * @param closestPoint Position of the closest point on the oposite segment
 *
 * @return number - the shortest distance
 * @return Position of the closest point on the first segment
 * @return Position of the closest point on the second segment
 */
export function findShortestDistanceBetweenSegments(
  A1: GeoJSON.Position,
  A2: GeoJSON.Position,
  B1: GeoJSON.Position,
  B2: GeoJSON.Position,
  closestPoint?: GeoJSON.Position
): [number, GeoJSON.Position, GeoJSON.Position] {
  let shortestDistance = Infinity;
  let P1: GeoJSON.Position = [NaN, NaN];
  let P2: GeoJSON.Position = [NaN, NaN];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  let [linesIntersect, segmentsIntersect, intersection] = findIntersectionBetweenLines(A1, A2, B1, B2);

  // the segments intersect, the shortest distance is zero and the closest points the intersection
  if (segmentsIntersect) {
    P1 = intersection;
    P2 = intersection;
    shortestDistance = 0;
  } else {
    // let's find the other possible distance
    // let's look at A1

    let d = Math.round(pDistance(A1[0], A1[1], B1[0], B1[1], B2[0], B2[1]) / epsilon) * epsilon;
    if (d < shortestDistance) {
      const [closestPoint] = getClosestPointInSegment(A1, B1, B2);
      shortestDistance = d;
      P1 = A1;
      P2 = closestPoint;
    }

    // let's look at A2
    d = Math.round(pDistance(A2[0], A2[1], B1[0], B1[1], B2[0], B2[1]) / epsilon) * epsilon;
    if (d < shortestDistance) {
      const [closestPoint] = getClosestPointInSegment(A2, B1, B2);
      shortestDistance = d;
      P1 = A2;
      P2 = closestPoint;
    }

    // B1
    d = Math.round(pDistance(B1[0], B1[1], A1[0], A1[1], A2[0], A2[1]) / epsilon) * epsilon;
    if (d < shortestDistance) {
      const [closestPoint] = getClosestPointInSegment(B1, A1, A2);
      shortestDistance = d;
      P1 = closestPoint;
      P2 = B1;
    }

    // B2
    d = Math.round(pDistance(B2[0], B2[1], A1[0], A1[1], A2[0], A2[1]) / epsilon) * epsilon;
    if (d < shortestDistance) {
      const [closestPoint] = getClosestPointInSegment(B2, A1, A2);
      shortestDistance = d;
      P1 = closestPoint;
      P2 = B2;
    }

    // if the two lines are parallel, we find the closest point to attach.
    if (!linesIntersect && closestPoint) {
      d = Math.round(pDistance(closestPoint[0], closestPoint[1], B1[0], B1[1], B2[0], B2[1]) / epsilon) * epsilon;

      if (d <= shortestDistance) {
        const [pt] = getClosestPointInSegment(closestPoint, B1, B2);

        shortestDistance = d;
        P1 = closestPoint;
        P2 = pt;
      } else if (d > shortestDistance) {
        //we want to know which end point in the opposite segment is the closest to the closestPoint
        const d1 = Math.round(findShortestDistanceBetweenPoints(closestPoint, B1) / epsilon) * epsilon;
        const d2 = Math.round(findShortestDistanceBetweenPoints(closestPoint, B2) / epsilon) * epsilon;

        if (d1 < d2) {
          const [pt] = getClosestPointInSegment(B1, A1, A2);

          shortestDistance = d1;
          P1 = B1;
          P2 = pt;
        } else {
          const [pt] = getClosestPointInSegment(B2, A1, A2);

          shortestDistance = d2;
          P1 = B2;
          P2 = pt;
        }
      }
    }
  }

  return [shortestDistance, P1, P2];
}

/**
 * Computes if a measurer AB collides with the segment [OP]
 * @param A First point of the measurer
 * @param B Second point of the measurer
 * @param O First point of the segment
 * @param P Second point of the segment
 * @returns boolean: both object collides?
 */
export function collisionMeasurerSegment(
  A: GeoJSON.Position,
  B: GeoJSON.Position,
  O: GeoJSON.Position,
  P: GeoJSON.Position
): boolean {
  const denominator = (B[0] - A[0]) * (P[1] - O[1]) - (B[1] - A[1]) * (P[0] - O[0]);
  const numerator1 = (A[1] - O[1]) * (P[0] - O[0]) - (A[0] - O[0]) * (P[1] - O[1]);

  const numerator2 = (A[1] - O[1]) * (B[0] - A[0]) - (A[0] - O[0]) * (B[1] - A[1]);

  if (denominator === 0) return numerator1 === 0 && numerator2 === 0;

  const r = numerator1 / denominator;

  const s = numerator2 / denominator;

  return r >= 0 && r <= 1 && s >= 0 && s <= 1;
}

/**
 * Function that gives the line coefficient of a segment
 * @param x1  The coordinate of the first point on the horizontal axis
 * @param y1 The coordinate of the first point on the vertical axis
 * @param x2  The coordinate of the second point on the horizontal axis
 * @param y2 The coordinate of the second point on the vertical axis
 * @returns number: the line coefficient
 */
export function findLineCoefficient(x1: number, y1: number, x2: number, y2: number): number | undefined {
  if (x1 === x2) {
    // eslint-disable-next-line no-console
    console.log('The line is vertical, and the coefficient is undefined.');

    return;
  }

  const coefficient = (y2 - y1) / (x2 - x1);

  return coefficient;
}
