import { useEffect } from 'react';
import { AuthService } from 'services/auth.service';
import { Container } from 'typescript-ioc';

export function useAutoRefreshToken(): void {
  useEffect(() => {
    const authService = Container.get(AuthService);

    const refresh = (): void => {
      if (authService.authenticated) {
        authService.refreshToken();
      }
    };

    if (navigator.onLine) {
      refresh();
    }
  }, []);
}
