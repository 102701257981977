import type { Properties } from '@turf/turf';
import { intersect } from '@turf/turf';
import { saveCircuitToHistoryAction, updateSegmentsPortionsAction } from 'actions/circuit';
import { createSegmentSuccessAction, deleteSegmentSuccessAction, saveSegmentSuccessAction } from 'actions/segment';
import { createZoneSuccessAction, saveZoneSuccessAction } from 'actions/zones';
import type { Feature, LineString, MultiPolygon, Polygon } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type { SegmentAisleDirection } from 'models/circuit';
import { Intersection, ShapeTypes, type CircuitZone } from 'models/circuit';
import { getMaxDisplayPriority } from 'services/circuit.service';
import store from 'store';
import { toRad } from 'utils/helpers';
import { formatTime } from 'utils/time';
import { isDefined } from 'utils/ts/is-defined';
import { generateShapeId } from './next-free-id';

interface ComputePolygonAislesRes {
  aisles: {
    /** The intersection polygon of the rectangles in front of the racks */
    polygon: Feature<Polygon | MultiPolygon, Properties>;
    /** The id of the first rack to generate the aisle */
    rack0: string;
    /** The id of the other rack to generate the aisle */
    rack1: string;
  }[];
}

interface ComputePolygonAislesOptions {
  /** Enable debug mode (display intermediate computation) */
  debug?: boolean;
  selectedLayers?: string[];
}

/**
 * Computes the aisles between racks by generating polygons in front of each rack and finding their intersections.
 * This can be used to identify potential aisles in a warehouse or storage facility layout.
 *
 * @param {ComputePolygonAislesOptions} options - Optional parameters to customize the computation, including debug mode.
 * @returns {ComputePolygonAislesRes} An object containing the aisles identified as intersections of polygons in front of racks, along with the ids of the racks forming each aisle.
 */

export function computePolygonAisles(options?: ComputePolygonAislesOptions): ComputePolygonAislesRes {
  const { debug = false, selectedLayers } = options ?? {};

  const circuitState = store.getState().circuit.present;

  const racksIds = circuitState.racks.ids;
  const racks = circuitState.racks.entities;

  const racksIdsInTheSelectedLayers = selectedLayers
    ? racksIds
        .map((rackId) => {
          const rack = racks[rackId];

          if (selectedLayers.includes(rack.properties.layerId)) {
            return rackId;
          }

          return undefined;
        })
        .filter(isDefined)
    : racksIds;

  const rectangleWidth = 3.5; // [m]

  // for every rack, we compute a rectangle in front of it (summits)
  const rectanglesInFrontOfRacks = racksIdsInTheSelectedLayers.map((rackId) => {
    const rack = racks[rackId];
    const angle = toRad(rack.properties.cap) - Math.PI / 2;
    const coordinates = rack.geometry.coordinates[0];

    const firstEdge = [coordinates[2], coordinates[3]];
    const opposedEdge = [
      [
        firstEdge[1][0] + rectangleWidth * 100 * Math.cos(angle),
        firstEdge[1][1] + rectangleWidth * 100 * Math.sin(angle),
      ],
      [
        firstEdge[0][0] + rectangleWidth * 100 * Math.cos(angle),
        firstEdge[0][1] + rectangleWidth * 100 * Math.sin(angle),
      ],
    ];

    const polygon: Polygon = {
      type: 'Polygon',
      coordinates: [[...firstEdge, ...opposedEdge, firstEdge[0]]],
    };

    if (debug) {
      store.dispatch(
        createZoneSuccessAction({
          id: generateShapeId(),
          type: 'Feature',
          geometry: polygon,
          properties: {
            name: `Debug rectangle in front of rack ${rackId}`,
            type: ShapeTypes.ZoneShape,
            intersectionType: Intersection.PointIntersection,
            prio: getMaxDisplayPriority(),
            rules: [],
            comments: ['DEBUG ZONE - This zone has been automatically generated by Road Editor.'],
            layerId: rack.properties.layerId,
          },
        })
      );
    }

    return {
      polygon,
      rackId,
    };
  });

  const intersections: ComputePolygonAislesRes['aisles'] = [];
  // we compute the intersection of all rectangles 2 by 2
  for (let i = 0; i < rectanglesInFrontOfRacks.length; i++) {
    for (let j = i + 1; j < rectanglesInFrontOfRacks.length; j++) {
      let intersection: Feature<Polygon | MultiPolygon, Properties> | undefined | null = undefined;
      try {
        intersection = intersect(rectanglesInFrontOfRacks[i].polygon, rectanglesInFrontOfRacks[j].polygon);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error computing intersection', e, {
          rack0: rectanglesInFrontOfRacks[i],
          rack1: rectanglesInFrontOfRacks[j],
        });
      }

      if (intersection) {
        intersections.push({
          polygon: intersection,
          rack0: rectanglesInFrontOfRacks[i].rackId,
          rack1: rectanglesInFrontOfRacks[j].rackId,
        });
      }
    }
  }

  return {
    aisles: intersections,
  };
}

/**
 * Generates a list of CircuitZone objects representing aisles computed from the intersections of polygons in front of racks.
 * Each aisle is associated with two racks and is named accordingly. MultiPolygon geometries are skipped due to their complexity.
 *
 * @returns {CircuitZone[]} An array of CircuitZone objects, each representing an aisle.
 */

export function computeAisles(selectedLayers?: string[]): CircuitZone[] {
  const polygonAisles = computePolygonAisles({ selectedLayers: selectedLayers });

  const racks = store.getState().circuit.present.racks.entities;
  const selectedLayerId = store.getState().circuit.present.layers.selectedLayer;
  const zonesIds = store.getState().circuit.present.zones.ids;
  const zones = store.getState().circuit.present.zones.entities;

  const now = formatTime(new Date());

  const aisles: CircuitZone[] = polygonAisles.aisles
    .map(({ polygon, rack0, rack1 }) => {
      const rackName0 = racks[rack0].properties.name;
      const rackName1 = racks[rack1].properties.name;

      const aisleName = `Aisle ${rackName0}x${rackName1}`;

      if (polygon.geometry.type === 'MultiPolygon') {
        // eslint-disable-next-line no-console
        console.error('MultiPolygon detected, skipping');

        return null;
      }

      const zoneAlreadyExists = zonesIds.find((zoneId) => {
        const zone = zones[zoneId];

        const aisle = zone.properties.aisle;
        if (!aisle) return false;

        return (aisle.rack0 === rack0 && aisle.rack1 === rack1) || (aisle.rack0 === rack1 && aisle.rack1 === rack0);
      });

      const id = zoneAlreadyExists ? zoneAlreadyExists : generateShapeId();

      return {
        id,
        type: 'Feature' as const,
        geometry: polygon.geometry,
        properties: {
          name: aisleName,
          type: ShapeTypes.ZoneShape as const,
          intersectionType: Intersection.PointIntersection,
          prio: getMaxDisplayPriority(),
          rules: [],
          comments: [`This zone has been automatically generated by Road Editor (${now}).`],
          layerId: selectedLayerId,
          aisle: {
            isAisle: true as const,
            rack0,
            rack1,
          },
          locked: true,
        },
      };
    })
    .filter(isDefined);

  return aisles;
}

interface ComputeAislesAndAddToCircuitProps {
  zonesIdsToUpdate?: string[];
  selectedLayers?: string[];
}

/**
 * Computes the aisles and adds them to the Circuit state.
 * This function is a convenience wrapper around computeAisles and createZoneSuccessAction.
 *
 * @returns {number} The number of aisles added or updated to the Circuit state.
 */
export function computeAislesAndAddToCircuit(props?: ComputeAislesAndAddToCircuitProps): number {
  const { zonesIdsToUpdate, selectedLayers } = props ?? {};

  const aisles = selectedLayers ? computeAisles(selectedLayers) : computeAisles();

  const zones = store.getState().circuit.present.zones.entities;

  store.dispatch(saveCircuitToHistoryAction());

  let nbAisles = 0;

  aisles.forEach((aisle) => {
    const zoneAlreadyExists = !!(aisle.id && typeof aisle.id === 'string' && zones[aisle.id]);

    if (zoneAlreadyExists && zonesIdsToUpdate && typeof aisle.id === 'string' && !zonesIdsToUpdate.includes(aisle.id)) {
      return;
    }

    const action = zoneAlreadyExists ? saveZoneSuccessAction : createZoneSuccessAction;

    store.dispatch(action(aisle));

    nbAisles++;
  });

  return nbAisles;
}

/**
 * Apply offset to the median segement.
 *
 * @returns {number[][][]} The new segments coords with the offset.
 */
export function applyOffsetToSegment(segment: number[][], offset: number): number[][][] {
  const [x1, y1] = segment[0];
  const [x2, y2] = segment[1];

  // Calculate the vector of the segment
  const dx = x2 - x1;
  const dy = y2 - y1;

  // Calculate the normal (perpendicular) vector to the segment
  const normalX = -dy;
  const normalY = dx;

  // Normalize the normal vector
  const length = Math.sqrt(normalX * normalX + normalY * normalY);
  const normalizedX = normalX / length;
  const normalizedY = normalY / length;

  const offsetX = normalizedX * offset;
  const offsetY = normalizedY * offset;

  const newSegmentPositive = [
    [x1 + offsetX, y1 + offsetY],
    [x2 + offsetX, y2 + offsetY],
  ];

  const newSegmentNegative = [
    [x1 - offsetX, y1 - offsetY],
    [x2 - offsetX, y2 - offsetY],
  ];

  const newSegments = [newSegmentPositive, newSegmentNegative];

  return newSegments;
}

interface ComputeMidSegmentAisleRes {
  segments: {
    geometry: LineString;
    aisle: string;
    direction: SegmentAisleDirection;
  }[];
}

/**
 * Computes the middle segment aisles based on the current state of the zones in the circuit.
 * It filters the zones to find those marked as aisles, then calculates the median lines
 * for each aisle zone to determine the middle segments. These segments are intended to represent
 * the central path through an aisle, with directionality indicated for potential use in navigation or visualization.
 *
 * @returns {ComputeMidSegmentAisleRes} An object containing an array of segment objects, each with geometry,
 * aisle identifier, and direction properties.
 */
export function computeMidSegmentAisle(selectedLayers?: string[], offset?: number): ComputeMidSegmentAisleRes {
  const zonesIds = store.getState().circuit.present.zones.ids;
  const zones = store.getState().circuit.present.zones.entities;

  const zonesIdsInTheSelectedLayers = selectedLayers
    ? zonesIds
        .map((zoneId) => {
          const zone = zones[zoneId];

          if (selectedLayers.includes(zone.properties.layerId)) {
            return zoneId;
          }

          return undefined;
        })
        .filter(isDefined)
    : zonesIds;

  const aisles = zonesIdsInTheSelectedLayers
    .map((zoneId) => zones[zoneId])
    .filter((zone) => zone.properties.aisle?.isAisle);

  const segments: ComputeMidSegmentAisleRes['segments'] = aisles
    .map((aisle) => {
      const geometry = aisle.geometry.coordinates[0];
      const median0 = [
        [(geometry[0][0] + geometry[1][0]) / 2, (geometry[0][1] + geometry[1][1]) / 2],
        [(geometry[2][0] + geometry[3][0]) / 2, (geometry[2][1] + geometry[3][1]) / 2],
      ];
      const median1 = [
        [(geometry[1][0] + geometry[2][0]) / 2, (geometry[1][1] + geometry[2][1]) / 2],
        [(geometry[3][0] + geometry[0][0]) / 2, (geometry[3][1] + geometry[0][1]) / 2],
      ];

      const length0 = getDistanceBetweenPoints(median0[0], median0[1]);
      const length1 = getDistanceBetweenPoints(median1[0], median1[1]);

      const median = length0 > length1 ? median0 : median1;

      const medianWithOffset = offset ? applyOffsetToSegment(median, offset) : [];

      const aisleId = aisle.id;
      if (typeof aisleId !== 'string') {
        // eslint-disable-next-line no-console
        console.error('Aisle id is not a string', aisleId);

        return null;
      }

      if (medianWithOffset.length > 0) {
        const resWithoutDirectionOffset = {
          aisle: aisleId,
          geometry: {
            type: 'LineString' as const,
            coordinates: medianWithOffset[0],
          },
          direction: 'both' as const,
        };

        return [
          {
            ...resWithoutDirectionOffset,

            direction: 'right' as const,
          },
          {
            ...resWithoutDirectionOffset,
            geometry: {
              ...resWithoutDirectionOffset.geometry,
              coordinates: medianWithOffset[0].slice().reverse(),
            },
            direction: 'left' as const,
          },
          {
            ...resWithoutDirectionOffset,
            geometry: {
              ...resWithoutDirectionOffset.geometry,
              coordinates: medianWithOffset[1],
            },
            direction: 'right' as const,
          },
          {
            ...resWithoutDirectionOffset,
            geometry: {
              ...resWithoutDirectionOffset.geometry,
              coordinates: medianWithOffset[1].slice().reverse(),
            },
            direction: 'left' as const,
          },
        ];
      }

      const resWithoutDirection = {
        aisle: aisleId,
        geometry: {
          type: 'LineString' as const,
          coordinates: median,
        },
        direction: 'both' as const,
      };

      return [
        {
          ...resWithoutDirection,

          direction: 'right' as const,
        },
        {
          ...resWithoutDirection,
          geometry: {
            ...resWithoutDirection.geometry,
            coordinates: median.slice().reverse(),
          },
          direction: 'left' as const,
        },
      ];
    })
    .filter(isDefined)
    .flat();

  return {
    segments,
  };
}

interface ComputeMidSegmentAisleAndAddToCircuitProps {
  segmentsIdsToUpdate?: string[];
  selectedLayers?: string[];
  offset?: number;
}

/**
 * Computes and adds middle segment aisles to the circuit.
 * This function first computes the middle segments of aisles using the `computeMidSegmentAisle` function.
 * It then iterates over these segments, checking if they already exist in the circuit.
 * If a segment already exists, it updates the segment; otherwise, it creates a new segment.
 * Each segment is dispatched to the store with appropriate actions based on its existence.
 * Finally, it updates the segments portions and returns the number of segments added or updated.
 *
 * @returns {number} The number of segments added or updated in the circuit.
 */
export function computeMidSegmentAisleAndAddToCircuit(props?: ComputeMidSegmentAisleAndAddToCircuitProps): number {
  const { segmentsIdsToUpdate, selectedLayers, offset } = props ?? {};

  const offsetToApply = offset ? offset : 0;

  const segmentsToAdd = selectedLayers
    ? computeMidSegmentAisle(selectedLayers, offsetToApply)
    : computeMidSegmentAisle();

  const segments = store.getState().circuit.present.segments.entities;
  let segmentsIds = store.getState().circuit.present.segments.ids;

  const segmentsIdsToUpdatePortions: string[] = [];

  store.dispatch(saveCircuitToHistoryAction());

  segmentsToAdd.segments.forEach((segmentToAdd) => {
    const segmentAlreadyExists = segmentsIds.find((segmentId) => {
      const segment = segments[segmentId];

      const segmentAisle = segment.properties.aisle;

      return segmentAisle?.aisleId === segmentToAdd.aisle && segmentAisle.direction === segmentToAdd.direction;
    });

    if (segmentAlreadyExists) {
      segmentsIds = segmentsIds.filter((id) => id !== segmentAlreadyExists);
    }

    const remainingSegmentsInTheAisle = segmentsIds
      .map((id) => {
        const segment = segments[id];

        if (segment.properties.aisle?.aisleId === segmentToAdd.aisle) {
          return segment;
        }

        return undefined;
      })
      .filter(isDefined);

    // When we create segments with an offset > 0 and then recompute them with an offet equal to 0, we need to delete the two segments that will not be updated
    if (remainingSegmentsInTheAisle.length === 2 && offsetToApply === 0) {
      remainingSegmentsInTheAisle.forEach((segment) => {
        if (segment.id) {
          store.dispatch(deleteSegmentSuccessAction({ id: segment.id.toString() }));
        }
      });
    }

    if (segmentAlreadyExists && segmentsIdsToUpdate && !segmentsIdsToUpdate.includes(segmentAlreadyExists)) {
      return;
    }

    const action = segmentAlreadyExists ? saveSegmentSuccessAction : createSegmentSuccessAction;
    const id = segmentAlreadyExists ?? generateShapeId();

    segmentsIdsToUpdatePortions.push(id);

    store.dispatch(
      action({
        id,
        type: 'Feature',
        geometry: segmentToAdd.geometry,
        properties: {
          name: `Aisle segment ${segmentToAdd.aisle} ${segmentToAdd.direction}`,
          type: ShapeTypes.SegmentShape,
          prio: getMaxDisplayPriority(),
          comments: ['This segment has been automatically generated by Road Editor.'],
          layerId: store.getState().circuit.present.layers.selectedLayer,
          aisle: {
            aisleId: segmentToAdd.aisle,
            direction: segmentToAdd.direction,
            offset: offsetToApply,
          },
          portions: [],
          twoWay: segmentToAdd.direction === 'both',
          locked: true,
          wireGuided: true,
        },
      })
    );
  });

  store.dispatch(
    updateSegmentsPortionsAction({
      segmentsIds: segmentsIdsToUpdatePortions,
    })
  );

  return segmentsIdsToUpdatePortions.length;
}
