import { ShapeTypes, SlotTypes } from 'models/circuit';
import type { LoadedPoint, LoadedRack, LoadedStockZone } from 'reducers/circuit/state';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import type { StationPositionType, StationPositionWithName } from './flows';

/**
 * Retrieves the name of a position based on its ID and type.
 *
 * @param positionId - The ID of the position.
 * @param positionType - The type of the position.
 * @returns The name of the position.
 */
export function positionIdToPositionName(positionId: string, positionType: StationPositionType | 'all'): string {
  const station = positionIdToStation(positionId, positionType);

  return station?.name ?? 'unknown';
}

/**
 * Converts a position ID and type to a corresponding station object with name, type, and ID.
 *
 * @param {string} positionId - The ID of the position to look up.
 * @param {StationPositionType | 'all'} positionType - The type of the position, or 'all' to search all types.
 * @returns {StationPositionWithName | undefined} An object with the position's name, type, and ID, or undefined if not found.
 */
export function positionIdToStation(
  positionId: string,
  positionType: StationPositionType | 'all'
): StationPositionWithName | undefined {
  const storeState = store.getState();

  if (positionType === ShapeTypes.PointShape || positionType === 'all') {
    const point: LoadedPoint | undefined = storeState.circuit.present.points.entities[positionId];
    if (!point && positionType !== 'all') {
      // eslint-disable-next-line no-console
      console.error('Invalid point id', positionId);

      return;
    }

    if (point || positionType !== 'all')
      return { name: point.properties.name, type: ShapeTypes.PointShape, id: positionId || '' };
  }

  if (positionType === ShapeTypes.RackShape || positionType === 'all') {
    const rack: LoadedRack | undefined = storeState.circuit.present.racks.entities[positionId];
    if (!rack && positionType !== 'all') {
      // eslint-disable-next-line no-console
      console.error('Invalid rack id', positionId);

      return;
    }

    if (rack || positionType !== 'all')
      return {
        id: positionId || '',
        name: rack.properties.name,
        type: ShapeTypes.RackShape,
      };
  }

  if (positionType === SlotTypes.Slot || positionType === 'all') {
    const racksIds = storeState.circuit.present.racks.ids;
    const racks = storeState.circuit.present.racks.entities;

    for (let i = 0; i < racksIds.length; i++) {
      const rack: LoadedRack | undefined = racks[racksIds[i]];
      if (!rack) {
        // eslint-disable-next-line no-console
        if (positionType !== 'all') console.error('Invalid rack id', racksIds[i]);
        continue;
      }

      for (let j = 0; j < rack.properties.columns.length; j++) {
        const column = rack.properties.columns[j];
        if (!column) {
          // eslint-disable-next-line no-console
          console.error('Invalid column', rack.properties.columns[j]);
          continue;
        }

        for (let k = 0; k < column.cells.length; k++) {
          const cell = column.cells[k];
          if (!cell) {
            // eslint-disable-next-line no-console
            console.error('Invalid cell', column.cells[k]);
            continue;
          }

          for (let slotsIndex = 0; slotsIndex < cell.names.length; slotsIndex++) {
            const slots = cell.names[slotsIndex];
            for (let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
              const slot = slots[slotIndex];
              if (slot.id === positionId) {
                return { name: slot.value, id: positionId, type: SlotTypes.Slot };
              }
            }
          }
        }
      }
    }

    if (positionType !== 'all') {
      // eslint-disable-next-line no-console
      console.error('Invalid slot id', positionId);

      return;
    }
  }

  if (positionType === SlotTypes.StockLine || positionType === 'all') {
    const stockZonesIds = storeState.circuit.present.stockZones.ids;
    const stockZones = storeState.circuit.present.stockZones.entities;

    for (let i = 0; i < stockZonesIds.length; i++) {
      const stockZone: LoadedStockZone | undefined = stockZones[stockZonesIds[i]];
      if (!stockZone) {
        if (positionType !== 'all') {
          // eslint-disable-next-line no-console
          console.error('Invalid stock zone id', stockZonesIds[i]);
        }

        continue;
      }

      for (let j = 0; j < stockZone.properties.slots.length; j++) {
        const stockLine = stockZone.properties.slots[j];
        if (!stockLine) {
          // eslint-disable-next-line no-console
          console.error('Invalid stock line', stockZone.properties.slots[j]);
          continue;
        }

        if (stockLine.id === positionId) {
          return { name: stockLine.name, id: positionId, type: SlotTypes.StockLine };
        }
      }
    }

    if (positionType !== 'all') {
      // eslint-disable-next-line no-console
      console.error('Invalid stock line id', positionId);

      return;
    }
  }

  if (positionType === ShapeTypes.StockZoneShape || positionType === 'all') {
    const stockZonesIds = storeState.circuit.present.stockZones.ids;
    const stockZones = storeState.circuit.present.stockZones.entities;

    for (let i = 0; i < stockZonesIds.length; i++) {
      const stockZone: LoadedStockZone | undefined = stockZones[stockZonesIds[i]];
      if (!stockZone) {
        if (positionType !== 'all') {
          // eslint-disable-next-line no-console
          console.error('Invalid stock zone id', stockZonesIds[i]);
        }

        continue;
      }

      if (stockZone.id === positionId) {
        return { name: stockZone.properties.name, id: positionId, type: ShapeTypes.StockZoneShape };
      }
    }

    if (positionType !== 'all') {
      // eslint-disable-next-line no-console
      console.error('Invalid stock zone id', positionId);

      return;
    }
  }

  assert<Equals<typeof positionType, 'all'>>();

  // eslint-disable-next-line no-console
  console.error('Invalid position for position id', positionId, positionType);

  return;
}
