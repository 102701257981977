import {
  deleteAllCellTemplatesAction,
  importCellTemplatesAction,
  importCircuitAction,
  importLayersAction,
  restoreCircuitInitialStateAction,
  updateLayerAction,
} from 'actions/circuit';
import type { GeoJsonCircuit } from 'models/circuit';
import { getLayersInitialState } from 'reducers/circuit/layers.reducer';
import type { LayersDataContainer } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { generateGeoJSON } from 'utils/export/generate-geojson';
import {
  isCircuitDevice,
  isCircuitMeasurer,
  isCircuitNote,
  isCircuitPoint,
  isCircuitRack,
  isCircuitSegment,
  isCircuitStockZone,
  isCircuitTurn,
  isCircuitZone,
} from './shape-guards';

export async function translateGeojsonCircuit(deltaXMeters: number, deltaYMeters: number): Promise<GeoJsonCircuit> {
  const dx = deltaXMeters * 100;
  const dy = deltaYMeters * 100;

  const features = {
    zones: CircuitService.zones,
    stockZones: CircuitService.stockZones,
    points: CircuitService.points,
    segments: CircuitService.segments,
    measurers: CircuitService.measurers,
    turns: CircuitService.turns,
    racks: CircuitService.racks,
    devices: CircuitService.devices,
    notes: CircuitService.notes,
  };

  // eslint-disable-next-line no-console
  console.log('Generate geojson circuit...');

  const [, , geojsonCircuitBefore] = await generateGeoJSON(features, false);

  const geojsonCircuit = structuredClone(geojsonCircuitBefore);

  // eslint-disable-next-line no-console
  console.log('Geojson circuit generated');

  const layers = geojsonCircuit.features;

  const nbShapes = layers.reduce((acc, layer) => acc + layer.features.length, 0);

  const logEveryNFeatures = 100;
  // eslint-disable-next-line no-console
  console.log(`${nbShapes} shapes to translate`);

  let nbShapesProcessed = 0;

  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    for (let j = 0; j < layer.features.length; j++) {
      const shape = layer.features[j];

      if (nbShapesProcessed % logEveryNFeatures === 0) {
        // eslint-disable-next-line no-console
        console.log(
          `${nbShapesProcessed}/${nbShapes} (${(nbShapesProcessed / nbShapes).toFixed(1)}%) features translated`
        );

        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (isCircuitSegment(shape)) {
        const segment = shape;

        segment.geometry.coordinates = segment.geometry.coordinates.map((coord) => {
          return [coord[0] * 100 + dx, coord[1] * 100 + dy];
        });

        segment.properties.portions = segment.properties.portions.map((portion) => {
          portion.points = portion.points.map((point) => {
            return [point[0] + dx, point[1] + dy];
          });

          return portion;
        });
      } else if (isCircuitPoint(shape)) {
        const point = shape;

        point.geometry.coordinates = [
          point.geometry.coordinates[0] * 100 + dx,
          point.geometry.coordinates[1] * 100 + dy,
        ];
      } else if (isCircuitTurn(shape)) {
        const turn = shape;

        turn.geometry.coordinates = turn.geometry.coordinates.map((coord) => {
          return [coord[0] * 100 + dx, coord[1] * 100 + dy];
        });
      } else if (isCircuitStockZone(shape)) {
        const stockZone = shape;

        stockZone.geometry.coordinates[0] = stockZone.geometry.coordinates[0].map((coord) => {
          return [coord[0] * 100 + dx, coord[1] * 100 + dy];
        });

        stockZone.properties.slots.forEach((slotLine) => {
          slotLine.slots = slotLine.slots.map((slot) => {
            slot.slotPosition = {
              ...slot.slotPosition,
              x: slot.slotPosition.x + dx,
              y: slot.slotPosition.y - dy,
            };

            slot.geometry = slot.geometry.map((coord) => {
              return [coord[0] + dx, coord[1] - dy];
            });

            return slot;
          });
        });
      } else if (isCircuitNote(shape)) {
        const note = shape;

        note.geometry.coordinates = [note.geometry.coordinates[0] * 100 + dx, note.geometry.coordinates[1] * 100 + dy];
      } else if (isCircuitMeasurer(shape)) {
        const measurer = shape;

        measurer.geometry.coordinates = measurer.geometry.coordinates.map((coord) => {
          return [coord[0] * 100 + dx, coord[1] * 100 + dy];
        });
      } else if (isCircuitRack(shape)) {
        const rack = shape;

        rack.geometry.coordinates[0] = rack.geometry.coordinates[0].map((coord) => {
          return [coord[0] * 100 + dx, coord[1] * 100 + dy];
        });
      } else if (isCircuitDevice(shape)) {
        const device = shape;

        device.geometry.coordinates = [device.geometry.coordinates[0] + dx, device.geometry.coordinates[1] + dy];
      } else if (isCircuitZone(shape)) {
        const zone = shape;

        zone.geometry.coordinates[0] = zone.geometry.coordinates[0].map((coord) => {
          return [coord[0] * 100 + dx, coord[1] * 100 + dy];
        });
      } else {
        assert<Equals<typeof shape, never>>();
      }

      nbShapesProcessed++;
    }
  }

  // eslint-disable-next-line no-console
  console.log(`${nbShapesProcessed}/${nbShapesProcessed} (100%) features translated`);

  return geojsonCircuit;
}

export async function translateCircuitAndReload(deltaXMeters: number, deltaYMeters: number): Promise<void> {
  if (typeof deltaXMeters !== 'number' || typeof deltaYMeters !== 'number') {
    // eslint-disable-next-line no-console
    console.error(
      `deltaXMeters and deltaYMeters must be numbers, got ${deltaXMeters} and ${deltaYMeters}, operation aborted`
    );

    return;
  }

  const snackBarKey = SnackbarUtils.toast('Translating the whole circuit...', {
    variant: 'info',
  });

  const geojsonCircuit = await translateGeojsonCircuit(deltaXMeters, deltaYMeters);

  SnackbarUtils.closeSnackbar(snackBarKey);
  const snackBarKey2 = SnackbarUtils.toast('Reloading the circuit...', {
    variant: 'info',
  });

  store.dispatch(restoreCircuitInitialStateAction());

  const layers = geojsonCircuit.features;
  const shapes = layers.flatMap((layer) => layer.features.map((feature) => feature));

  const layersData: LayersDataContainer = geojsonCircuit?.properties?.layers || getLayersInitialState();
  Object.values(layersData.layers).forEach((layer, index) => {
    if (layer.order === undefined) {
      layer.order = index;
    }
  });
  store.dispatch(importLayersAction(layersData));
  store.dispatch(
    updateLayerAction({
      layerId: layersData.selectedLayer,
      selectedLayer: layersData.selectedLayer,
    })
  );

  store.dispatch(deleteAllCellTemplatesAction());
  const cellTemplates = geojsonCircuit?.properties?.cellTemplates || {};
  store.dispatch(importCellTemplatesAction({ cellTemplates }));

  store.dispatch(importCircuitAction({ shapes }));

  SnackbarUtils.closeSnackbar(snackBarKey2);
  SnackbarUtils.toast('Circuit translated and reloaded', {
    variant: 'success',
  });
}
