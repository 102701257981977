import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { Box, ListItem, ListItemIcon, ListItemText, Switch, Tooltip } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { setDevicePrefManagement } from 'project/project';
import { useCallback } from 'react';
import { checkPermission } from 'services/check-permission';
import { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';

/**
 * This component displays a list item with a switch inside to see and toggle the state of the enableDevicePrefManagement property
 * @returns the component
 */
export function ManageDevicePreferences(): JSX.Element {
  const dispatch = useAppDispatch();
  const enableDevicePrefManagement = useAppSelector((state) => state.project.enableDevicePrefManagement);

  const handleChange = useCallback(() => {
    dispatch(setDevicePrefManagement(!enableDevicePrefManagement));
  }, [dispatch, enableDevicePrefManagement]);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <DeviceHubIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
              <Box component="span">
                <Switch checked={enableDevicePrefManagement} onChange={handleChange} disabled={!editProjectPerm} />
              </Box>
            </Tooltip>
          }
          secondary={
            <>
              Manage Devices Preferences
              <HelpIconTooltip
                title="If enabled, Road Editor will manage the devices preferences. Disable if you want to manage them yourself (advanced user)"
                sx={{
                  fontSize: '1rem',
                }}
              />
            </>
          }
        />
      </ListItem>
    </>
  );
}
