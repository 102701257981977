import { green, orange, red } from '@mui/material/colors';
import * as d3 from 'd3';
import type { SimpleSelection } from 'drawings/shared';
import type { CollisionPolygon, Lidar } from 'models/maps';
import { LidarPosition } from 'models/maps';
import { getLidarBounds } from 'services/maps.service';
import tinygradient from 'tinygradient';
import { BaseLayer } from './base.layer';
import { LayerNames } from './shared';

const mapWorker = new Worker(new URL('../../editor/map.worker.ts', import.meta.url), {
  name: 'map-worker',
});

export class LidarLayer extends BaseLayer<undefined> {
  protected canvas: SimpleSelection<HTMLCanvasElement, undefined>;
  protected readonly gradient = tinygradient(
    { color: green[500], pos: 0 },
    { color: green[300], pos: 0.5 },
    { color: orange[500], pos: 0.67 },
    { color: red[500], pos: 0.7 },
    { color: red[500], pos: 1 }
  );

  constructor(position: LidarPosition) {
    super(lidarPositionToLayerName(position));
    this.canvas = this.createCanvas();
  }

  public createNode(): SimpleSelection<SVGGElement, undefined> {
    return d3.create<SVGGElement>('svg:g');
  }

  public createCanvas(): SimpleSelection<HTMLCanvasElement, undefined> {
    return this.node
      .append('svg:foreignObject')
      .attr('x', 0)
      .attr('y', 0)
      .attr('overflow', 'visible')
      .attr('width', '100%')
      .attr('height', '100%')
      .append<HTMLCanvasElement>('xhtml:canvas')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('position', 'absolute')
      .style('left', 0)
      .style('z-index', 1)
      .style('image-rendering', 'pixelated');
  }

  public async setPoints(
    lidar: Lidar,
    opacity = 100,
    invalidate = false,
    collisionPolygon?: CollisionPolygon
  ): Promise<void> {
    if (localStorage[`${this.name}-isDrawingPoints`] === 'true') return;
    localStorage[`${this.name}-isDrawingPoints`] = 'true';

    const { coordinates } = lidar;

    const { minX, minY, maxY, maxX, width, height, maxValue } = getLidarBounds(coordinates);

    const foreignObject = this.node.select<SVGForeignObjectElement>('foreignObject');

    // Based on max allowed size in browsers,
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#Maximum_canvas_size
    const MAX_DIM_ALLOWED = 15000;
    let ratio = MAX_DIM_ALLOWED / Math.max(width, height);

    if (ratio > 1) ratio = 1;

    const scaledWidth = Math.floor(width * ratio);
    const scaledHeight = Math.floor(height * ratio);

    if (invalidate) {
      // size of the canvas to be changed, we destruct the former one
      this.canvas.remove();

      this.node
        .select('foreignObject')
        .append<HTMLCanvasElement>('xhtml:canvas')
        .attr('width', '100%')
        .attr('height', '100%')
        .style('position', 'absolute')
        .style('left', 0)
        .style('z-index', 1)
        .style('image-rendering', 'pixelated');

      this.canvas = this.node.select('canvas');

      if (this.name === LayerNames.BackgroundLidar) {
        mapWorker.postMessage({ type: 'invalidate-canvas' });
      }
    }

    // Position foreignObject (canvas wrapper)'s top left corner
    // at [minX, minY] of lidar coordinates
    foreignObject.attr('x', minX).attr('y', -minY).attr('width', width).attr('height', height);

    // Make canvas fill its wrapper
    // Also position it above foreignObject
    // Top left corner of FO is bottom left of Canvas
    this.canvas
      .attr('width', scaledWidth)
      .attr('height', scaledHeight)
      .style('width', `${width}px`)
      .style('height', `${height}px`)
      .style('top', `-${height}px`);

    const canvas = this.canvas.node();
    if (!canvas) return;

    if (this.name === LayerNames.BackgroundLidar) {
      //mapWorker.postMessage({ type: 'clear-canvas' });

      const needCanvasTransfer: boolean = await new Promise((resolve) => {
        function handleEventAnswerNeedCanvas(message: { data: DataMapWorker }): void {
          if (message.data.type === 'answer-need-canvas-transfer') {
            mapWorker.removeEventListener('message', handleEventAnswerNeedCanvas);
            resolve(!!message.data.needCanvasTransfer);
          }
        }

        mapWorker.addEventListener('message', handleEventAnswerNeedCanvas);

        mapWorker.postMessage({ type: 'ask-need-canvas-transfer' });
      });
      const offscreenCanvas = needCanvasTransfer ? canvas.transferControlToOffscreen() : null;
      mapWorker.postMessage(
        {
          canvas: offscreenCanvas,
          coordinates,
          opts: {
            minX,
            minY,
            ratio,
            maxX,
            maxY,
            width,
            height,
            maxValue,
            opacity,
          },
          collisionPolygon,
        },
        offscreenCanvas ? [offscreenCanvas] : []
      );
      await new Promise((resolve, reject): void => {
        function handleEventMapRendered(message: { data: DataMapWorker }): void {
          if (message.data.type === 'render-completed' && message.data.finished) {
            mapWorker.removeEventListener('message', handleEventMapRendered);
            resolve(message.data.finished);
          }
        }

        mapWorker.addEventListener('message', handleEventMapRendered);
      });
    } else {
      const context = this.canvas.node()?.getContext('2d') as CanvasRenderingContext2D;

      if (!context) {
        return;
      }

      const translateX = -minX * ratio;
      const translateY = (minY + height) * ratio;

      context.restore();
      context.save();

      context.setTransform(1, 0, 0, -1, translateX, translateY);
      context.scale(ratio, ratio);

      context.globalAlpha = 1;
      // we disable the anti-aliasing to avoid a blurred image
      context.imageSmoothingEnabled = false;
      context.fillStyle = green[500];

      context.clearRect(minX, minY, maxX - minX, maxY - minY);

      const opaSq = (opacity / 100) * (opacity / 100);
      for (let i = 0, l = coordinates.length; i < l; i++) {
        const coord = coordinates[i];
        if (opacity !== 100) context.globalAlpha = Math.log(coord[2] || 1) * opaSq;
        context.fillRect(coord[0], coord[1], 4, 4);
      }

      /*
        Next line is quite important, it's workaround for a chromium bug where
        the canvas encapsulated in a svg is always rendered at the top.
        Resources about it:
        - https://bugs.chromium.org/p/chromium/issues/detail?id=148499
        - https://observablehq.com/@mootari/embed-canvas-into-svg
      */
      context.getImageData(0, 0, 1, 1);
    }

    await setImmediatePromise();

    localStorage[`${this.name}-isDrawingPoints`] = 'false';
  }
}

function lidarPositionToLayerName(position: LidarPosition): LayerNames {
  switch (position) {
    case LidarPosition.Foreground:
      return LayerNames.ForegroundLidar;
    case LidarPosition.Background:
      return LayerNames.BackgroundLidar;
  }
}

function setImmediatePromise(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 0);
  });
}

interface DataMapWorker {
  type: string;
  needCanvasTransfer?: boolean;
  error?: number | boolean;
  finished?: boolean;
}
