import type { CustomStepEvent, ExtraStepNames } from 'flows/custom-steps.model';

export const extraStepNameToLabel: Record<ExtraStepNames, string> = {
  RPK_START: 'Start',
  RPK_APPROACH: 'Approach',
  RPK_DESTINATION: 'Destination',
  RPK_EVACUATION: 'Evacuation',
  RPK_LASTREVERSE: 'Last Reverse',
};

export const propertyToUnit: Record<CustomStepEvent['actionType'], string | undefined> = {
  waitTime: 's',
  forksMovement: 'm',
  forksTarget: 'm',
  none: undefined,
};

export const actionTypeToLabel: Record<CustomStepEvent['actionType'], string> = {
  waitTime: 'Wait Time',
  forksMovement: 'Forks Movement',
  forksTarget: 'Forks Target',
  none: 'None',
};

export const actionTypeToHelpTooltip: Record<CustomStepEvent['actionType'], string> = {
  waitTime: 'Duration to wait before the next step',
  forksMovement: 'Distance to lift the forks. A positive value means lifting and a negative value means lowering',
  forksTarget: 'Set points position for the forks',
  none: 'No action',
};

export const propertyValueRestrictions: Record<
  CustomStepEvent['actionType'],
  { min?: number; max?: number; step?: number } | undefined
> = {
  waitTime: { min: 0, step: 1 },
  forksMovement: { step: 0.1 },
  forksTarget: { min: 0, step: 0.1 },
  none: undefined,
};
