import { connect } from 'react-redux';
import { selectTokenStatus, selectUserLoggedIn } from 'reducers/auth';
import { selectAppOnline } from 'reducers/core';
import type { AppState } from 'reducers/state';
import { App as AppComponent } from './app';

interface StateProps {
  appOnline: boolean;
  loggedIn: boolean;
  tokenRefreshed: boolean;
}

function mapStateToProps(state: AppState): StateProps {
  const appOnline = selectAppOnline(state);
  const loggedIn = selectUserLoggedIn(state);
  const token = selectTokenStatus(state);

  return { loggedIn, appOnline, tokenRefreshed: token.refreshed };
}

export const App = connect(mapStateToProps)(AppComponent);
