import type { ToolActions } from 'actions';
import { SecondaryToolActionTypes, ToolActionTypes } from 'actions';
import {
  TOOL_LIST_CIRCUIT_INITIAL_STATE,
  TOOL_LIST_FLOW_INITIAL_STATE,
  TOOL_LIST_TRAFFIC_INITIAL_STATE,
} from 'components/menu-bar/tool-info';
import { isLineStrokeSize } from 'models/drawings.guard';
import { Tools } from 'models/tools';
import type { ToolState } from './state';
import { alwaysDisplayCommonLocalStorageKey, displayBBoxSelectionLocalStorageKey } from './state';

function getInitialState(): ToolState {
  const selectStrokeWidthDefault = 20;
  const selectStrokeWidth = localStorage.selectStrokeWidth
    ? parseInt(localStorage.selectStrokeWidth, 10)
    : selectStrokeWidthDefault;

  const pointsSizeDefault = 'small';
  const pointsSizeTmp =
    localStorage.pointsSize && typeof localStorage.pointsSize === 'string'
      ? localStorage.pointsSize
      : pointsSizeDefault;
  const pointsSize: ToolState['pointsSize'] =
    pointsSizeTmp === 'small' || pointsSizeTmp === 'medium' || pointsSizeTmp === 'large'
      ? pointsSizeTmp
      : pointsSizeDefault;

  const lineStrokeSizeDefault = 's';
  const lineStrokeSizeTmp =
    localStorage.lineStrokeSize &&
    typeof localStorage.lineStrokeSize === 'string' &&
    isLineStrokeSize(localStorage.lineStrokeSize)
      ? localStorage.lineStrokeSize
      : lineStrokeSizeDefault;
  const lineStrokeSize: ToolState['lineStrokeSize'] =
    lineStrokeSizeTmp === 's' || lineStrokeSizeTmp === 'm' || lineStrokeSizeTmp === 'l' || lineStrokeSizeTmp === 'xl'
      ? lineStrokeSizeTmp
      : lineStrokeSizeDefault;

  const returnToMoveToolDefault: ToolState['returnToMoveTool'] = 'sometimes';
  const returnToMoveToolTmp =
    localStorage.returnToMoveTool && typeof localStorage.returnToMoveTool === 'string'
      ? localStorage.returnToMoveTool
      : returnToMoveToolDefault;
  const returnToMoveTool: ToolState['returnToMoveTool'] =
    returnToMoveToolTmp === 'always' || returnToMoveToolTmp === 'never' || returnToMoveToolTmp === 'sometimes'
      ? returnToMoveToolTmp
      : returnToMoveToolDefault;
  const alwaysDisplayCommon: ToolState['alwaysDisplayCommon'] = localStorage[alwaysDisplayCommonLocalStorageKey]
    ? localStorage[alwaysDisplayCommonLocalStorageKey] === 'true'
    : true;
  const displayBBoxSelection: ToolState['displayBBoxSelection'] = localStorage[displayBBoxSelectionLocalStorageKey]
    ? localStorage[displayBBoxSelectionLocalStorageKey] === 'true'
    : true;
  const zoomLevel: ToolState['zoomLevel'] = undefined;
  const gridCoordinate: ToolState['gridCoordinate'] = { x0: window.x0, y0: window.y0, x1: window.x1, y1: window.y1 };

  const circuitTools = TOOL_LIST_CIRCUIT_INITIAL_STATE;
  const trafficTools = TOOL_LIST_TRAFFIC_INITIAL_STATE;
  const flowTools = TOOL_LIST_FLOW_INITIAL_STATE;

  return {
    activeTool: Tools.Move,
    lastActiveTool: Tools.Move,
    circuitTools,
    trafficTools,
    flowTools,
    snap90deg: false,
    turnType: 'Normal',
    zoneType: 'Rectangle',
    mapOpacity: 100,
    mapObstacleOpacity: 100,
    selectStrokeWidth: !isNaN(selectStrokeWidth) ? selectStrokeWidth : selectStrokeWidthDefault,
    pointsSize,
    lineStrokeSize,
    returnToMoveTool,
    highlightTurns: false,
    lockOrientation: false,
    alwaysDisplayCommon,
    displayBBoxSelection,
    zoomLevel,
    gridCoordinate,
  };
}

export function toolReducer(state = getInitialState(), action: ToolActions): ToolState {
  switch (action.type) {
    case ToolActionTypes.SelectTool: {
      let { toolName } = action.payload;
      if (!toolName) toolName = getInitialState().activeTool;

      return {
        ...state,
        activeTool: toolName,
        lastActiveTool: state.activeTool,
      };
    }

    case ToolActionTypes.SelectPreviousTool: {
      return {
        ...state,
        activeTool: state.lastActiveTool,
        lastActiveTool: state.activeTool,
      };
    }

    case ToolActionTypes.Select90DegSnap: {
      let { snap90deg } = action.payload;
      if (snap90deg === undefined) snap90deg = !state.snap90deg; // toggle

      return {
        ...state,
        snap90deg,
      };
    }

    case SecondaryToolActionTypes.SelectSecondaryTool: {
      const { turnType = getInitialState().turnType, zoneType = getInitialState().zoneType } = action.payload;

      return {
        ...state,
        turnType,
        zoneType,
      };
    }

    case ToolActionTypes.UpdateMapOpacity: {
      const { mapOpacity = getInitialState().mapOpacity } = action.payload;

      return {
        ...state,
        mapOpacity,
      };
    }

    case ToolActionTypes.UpdateObstacleOpacity: {
      const { mapObstacleOpacity = getInitialState().mapObstacleOpacity } = action.payload;

      return {
        ...state,
        mapObstacleOpacity,
      };
    }

    case ToolActionTypes.UpdateSelectStrokeWidth: {
      const { selectStrokeWidth } = action.payload;

      localStorage.selectStrokeWidth = selectStrokeWidth;

      return {
        ...state,
        selectStrokeWidth,
      };
    }

    case ToolActionTypes.UpdatePointsSize: {
      const { pointsSize } = action.payload;

      if (pointsSize !== 'small' && pointsSize !== 'medium' && pointsSize !== 'large') {
        // eslint-disable-next-line no-console
        console.error('Wrong points size value', pointsSize);

        return state;
      }

      localStorage.pointsSize = pointsSize;

      return {
        ...state,
        pointsSize,
      };
    }

    case ToolActionTypes.UpdateLineStrokeSize: {
      const { lineStrokeSize } = action.payload;

      if (lineStrokeSize !== 's' && lineStrokeSize !== 'm' && lineStrokeSize !== 'l' && lineStrokeSize !== 'xl') {
        // eslint-disable-next-line no-console
        console.error('Wrong line stroke size value', lineStrokeSize);

        return state;
      }

      localStorage.lineStrokeSize = lineStrokeSize;

      return {
        ...state,
        lineStrokeSize,
      };
    }

    case ToolActionTypes.ChangeReturnToMoveTool: {
      const { returnToMoveTool } = action.payload;

      localStorage.returnToMoveTool = returnToMoveTool;

      return {
        ...state,
        returnToMoveTool,
      };
    }

    case ToolActionTypes.ChangeStateHightlightTurns: {
      const { highlightTurns } = action.payload;

      return {
        ...state,
        highlightTurns,
      };
    }

    case ToolActionTypes.ToggleStateHightlightTurns: {
      return {
        ...state,
        highlightTurns: !state.highlightTurns,
      };
    }

    case ToolActionTypes.ChangeStateLockOrientation: {
      const { lockOrientation } = action.payload;

      return {
        ...state,
        lockOrientation,
      };
    }

    case ToolActionTypes.ChangeAlwaysDisplayCommon: {
      const { alwaysDisplayCommon } = action.payload;

      localStorage[alwaysDisplayCommonLocalStorageKey] = alwaysDisplayCommon.toString();

      return {
        ...state,
        alwaysDisplayCommon,
      };
    }

    case ToolActionTypes.ChangeDisplayBBoxSelection: {
      const { displayBBoxSelection } = action.payload;

      localStorage[displayBBoxSelectionLocalStorageKey] = displayBBoxSelection.toString();

      return {
        ...state,
        displayBBoxSelection,
      };
    }

    case ToolActionTypes.UpdateZoomLevel: {
      const { zoomLevel } = action.payload;

      return {
        ...state,
        zoomLevel,
      };
    }

    case ToolActionTypes.UpdateGridCoordinate: {
      const { gridCoordinate } = action.payload;

      return {
        ...state,
        gridCoordinate,
      };
    }

    case ToolActionTypes.UpdateTools: {
      const { circuitTools, flowTools, trafficTools } = action.payload;

      return {
        ...state,
        circuitTools: circuitTools || state.circuitTools,
        flowTools: flowTools || state.flowTools,
        trafficTools: trafficTools || state.trafficTools,
      };
    }

    default: {
      return state;
    }
  }
}

export const getTool = (state: ToolState): ToolState['activeTool'] => state.activeTool;
export const get90DegSnapSate = (state: ToolState): ToolState['snap90deg'] => state.snap90deg;
