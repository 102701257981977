import EvStationIcon from '@mui/icons-material/EvStation';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { pointMovedAction } from 'actions/circuit';
import { savePointAction } from 'actions/points';
import { getSvgFromIcon } from 'components/utils/generate-jsx';
import * as d3 from 'd3';
import { findShapeOrientation, getClosestPointInSegment, offsetPosition, pDistance } from 'drawings/helpers';
import { gabaritsWorkerProxy } from 'editor/gabarits.worker';
import type { Point } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { isEqual } from 'lodash';
import type { InterestPointProperties, SegmentDataInPoint } from 'models/circuit';
import type { pointSize } from 'models/drawings';
import { isShapeInSelectedShapes } from 'multiplayer/globals';
import store from 'store';
import { getGabaritCoordsCached } from 'utils/circuit/get-gabarit-coords';
import { isCircuitPoint } from 'utils/circuit/shape-guards';
import { getConfig } from 'utils/config';
import { toRad } from 'utils/helpers';
import { Circle } from '../elements';

const gpsIconSvg = getSvgFromIcon(GpsFixedIcon);
const homeIconSvg = getSvgFromIcon(HomeIcon);
const battIconSvg = getSvgFromIcon(EvStationIcon);
const teleportationIconSvg = getSvgFromIcon(SwapHorizIcon);

interface RadiusData {
  start: number[];
  end: number[];
}

const STROKE_WIDTH = parseFloat(getConfig('editor').elementWidth.orientationStroke);
const INTEREST_POINT_WIDTH = parseFloat(getConfig('editor').elementWidth.interestPoint);

const pointNameOffsetY = -10;
const pointNameOffsetX = -5;

const iconOffsetX = 15;
const iconOffsetY = 5;
const iconWidth = 10;
const iconHeight = 10;

export interface PointDrawingProperties {
  size: pointSize;
  refreshGeometryOnDrag?: boolean;
  displayGabarit?: boolean;
}

export function pointTextSizeToPx(size: pointSize): number {
  switch (size) {
    case 'small':
      return INTEREST_POINT_WIDTH;
    case 'medium':
      return INTEREST_POINT_WIDTH * 2;
    case 'large':
      return INTEREST_POINT_WIDTH * 4;
  }
}

export class InterestPoint extends Circle {
  public properties: InterestPointProperties;

  private translating = false;

  private radius: number;

  private coordsAtDragStart: number[] | undefined = undefined;
  private segmentAtDragStart: SegmentDataInPoint | undefined = undefined;

  constructor(
    id: string,
    geometry: Point,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    properties: InterestPointProperties,
    drawingProperties: PointDrawingProperties
  ) {
    super(id, geometry, projection, zoomScale, drawingProperties);
    this.properties = properties;

    this.setLocked(!!this.properties?.locked);

    this.radius = pointTextSizeToPx(drawingProperties.size);

    this.drawRadius(this.radius);
    this.drawName();
    this.drawIconPointType();

    this.updateShapeRef = this.updateShape;
    this.node.attr('layer-id', properties?.layerId || '');

    const displayGabarit = drawingProperties?.displayGabarit !== false;

    if (this.properties && this.properties.gabarit && this.properties.gabarit.display && displayGabarit) {
      this.showGabarit();
    }
  }

  private drawName(): void {
    this.node
      .append('text')
      .attr('x', this.geometry.coordinates[0] + pointNameOffsetX - this.radius)
      .attr('y', -this.geometry.coordinates[1] + pointNameOffsetY - this.radius)
      .classed('interest-point-name', true)
      .text(this.properties.name);
  }

  private drawIconPointType(): void {
    const props = this.properties;
    if (props.isTaxi) {
      this.node
        .append('svg')
        .attr('viewBox', homeIconSvg.querySelector('svg')?.getAttribute('viewBox') || '')
        .attr(
          'x',
          this.geometry.coordinates[0] -
            iconOffsetX * (this.radius / INTEREST_POINT_WIDTH) -
            (iconWidth * (this.radius / INTEREST_POINT_WIDTH)) / 2
        )
        .attr('y', -this.geometry.coordinates[1] + iconOffsetY + this.radius)
        .attr('width', iconWidth * (this.radius / INTEREST_POINT_WIDTH))
        .attr('height', iconHeight * (this.radius / INTEREST_POINT_WIDTH))
        .append('path')
        .attr('d', homeIconSvg.querySelector('path')?.getAttribute('d') || '');
    }

    if (props.isInit) {
      this.node
        .append('svg')
        .attr('viewBox', gpsIconSvg.querySelector('svg')?.getAttribute('viewBox') || '')
        .attr('x', this.geometry.coordinates[0] - (iconWidth * (this.radius / INTEREST_POINT_WIDTH)) / 2)
        .attr('y', -this.geometry.coordinates[1] + iconOffsetY + this.radius)
        .attr('width', iconWidth * (this.radius / INTEREST_POINT_WIDTH))
        .attr('height', iconHeight * (this.radius / INTEREST_POINT_WIDTH))
        .append('path')
        .attr('d', gpsIconSvg.querySelector('path')?.getAttribute('d') || '');
    }

    if (props.isBattery) {
      this.node
        .append('svg')
        .attr('viewBox', battIconSvg.querySelector('svg')?.getAttribute('viewBox') || '')
        .attr(
          'x',
          this.geometry.coordinates[0] +
            iconOffsetX * (this.radius / INTEREST_POINT_WIDTH) -
            (iconWidth * (this.radius / INTEREST_POINT_WIDTH)) / 2
        )
        .attr('y', -this.geometry.coordinates[1] + iconOffsetY + this.radius)
        .attr('width', iconWidth * (this.radius / INTEREST_POINT_WIDTH))
        .attr('height', iconHeight * (this.radius / INTEREST_POINT_WIDTH))
        .append('path')
        .attr('d', battIconSvg.querySelector('path')?.getAttribute('d') || '');
    }

    if (props.isTeleportation) {
      this.node
        .append('svg')
        .attr('viewBox', teleportationIconSvg.querySelector('svg')?.getAttribute('viewBox') || '')
        .attr('x', this.geometry.coordinates[0] - (iconWidth * (this.radius / INTEREST_POINT_WIDTH)) / 2)
        .attr('y', -this.geometry.coordinates[1] + (iconOffsetY + this.radius) * 2)
        .attr('width', iconWidth * (this.radius / INTEREST_POINT_WIDTH))
        .attr('height', iconHeight * (this.radius / INTEREST_POINT_WIDTH))
        .append('path')
        .attr('d', teleportationIconSvg.querySelector('path')?.getAttribute('d') || '');
    }
  }

  private clearRadius(): void {
    const radius = this.node.selectAll<SVGGElement, unknown>('line');
    radius.nodes().forEach((node) => node.remove());
  }

  public setZoomScale(zoomScale: number): void {
    super.setZoomScale(zoomScale);
    this.drawRadius(this.radius);
  }

  /**
   * Draw the lines that show the orientation of the point
   * @param size radius of the point [px]
   */
  protected drawRadius(size: number): void {
    const radius = this.node.selectAll<SVGGElement, unknown>('line');

    const radiusData: RadiusData[] = [];

    radiusData.push({
      start: this.geometry.coordinates,
      end: [
        Math.cos(toRad(-this.properties.orientation)) * ((1.5 * size) / this.zoomScale) + this.geometry.coordinates[0],
        -Math.sin(toRad(-this.properties.orientation)) * ((1.5 * size) / this.zoomScale) + this.geometry.coordinates[1],
      ],
    });

    radiusData.push({
      start: this.geometry.coordinates,
      end: [
        Math.cos(toRad(-this.properties.orientation + 90)) * (size / this.zoomScale) + this.geometry.coordinates[0],
        -Math.sin(toRad(-this.properties.orientation + 90)) * (size / this.zoomScale) + this.geometry.coordinates[1],
      ],
    });

    radiusData.push({
      start: this.geometry.coordinates,
      end: [
        Math.cos(toRad(-this.properties.orientation - 90)) * (size / this.zoomScale) + this.geometry.coordinates[0],
        -Math.sin(toRad(-this.properties.orientation - 90)) * (size / this.zoomScale) + this.geometry.coordinates[1],
      ],
    });

    const radiusSelection = radius.data(radiusData);

    radiusSelection
      .enter()
      .append<SVGGElement>('svg:line')
      .merge(radiusSelection) //merge enter and update selection
      .attr('d', this.projection as any)
      .attr('x1', (d) => {
        return d.start[0];
      })
      .attr('y1', (d) => {
        return -d.start[1];
      })
      .attr('x2', (d) => {
        return d.end[0];
      })
      .attr('y2', (d) => {
        return -d.end[1];
      })
      .style('stroke', 'black')
      .style('stroke-width', (STROKE_WIDTH * (this.radius / INTEREST_POINT_WIDTH)) / this.zoomScale);

    radiusSelection.exit().remove();
  }

  protected onDragStart(): void {
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    this.coordsAtDragStart = this.geometry.coordinates;
    this.segmentAtDragStart = this.properties.segment;

    super.onDragStart();
  }

  protected onDrag(): void {
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

    if (event.sourceEvent.shiftKey) {
      ///////////////////////////////
      // Rotation
      ///////////////////////////////

      this.properties.orientation = findShapeOrientation(
        [this.geometry.coordinates[0], this.geometry.coordinates[1]],
        [event.x, -event.y]
      );
    } else {
      ///////////////////////////////
      // Translation
      ///////////////////////////////
      const snapDistanceThreshold = 10; // threshold to be defined, would be nice if it depends of the zoom level

      const distanceEvent = getDistanceBetweenPoints(this.geometry.coordinates, [event.x, -event.y]);
      if (distanceEvent > snapDistanceThreshold) {
        this.geometry.coordinates = [event.x, -event.y];
      } else {
        this.geometry.coordinates = offsetPosition(this.geometry.coordinates, event.dx, event.dy);
      }

      const ptCoords = this.geometry.coordinates;
      let snappedCoords = [...ptCoords];

      const segments = store.getState().circuit.present.segments.entities;
      const keys = store.getState().circuit.present.segments.ids;

      // we check if we are still close of the associated segment if the point is associated with one
      let alreadySnapped = false;
      let positionOnSegment: number;
      if (this.properties.segment && this.properties.segment.id && keys.includes(this.properties.segment.id)) {
        const segment = segments[this.properties.segment.id];
        const segCoords = segment.geometry.coordinates;
        const start = segCoords[0];
        const end = segCoords[segCoords.length - 1];

        const d = pDistance(ptCoords[0], ptCoords[1], start[0], start[1], end[0], end[1]);
        if (d < snapDistanceThreshold) {
          [snappedCoords, positionOnSegment] = getClosestPointInSegment(ptCoords, start, end);
          this.geometry.coordinates = snappedCoords;
          this.properties.segment = {
            ...this.properties.segment,
            position: positionOnSegment,
          };
          alreadySnapped = true;
        } else {
          d3.select(`[uuid='${this.properties.segment.id}']`).style('stroke-dasharray', null);

          delete this.properties.segment;
        }
      }

      // if not, we check if we are close to a segment to snap to it
      if (!alreadySnapped) {
        for (const key of keys) {
          const segment = segments[key];

          // points are snappable only on segments of their layer
          if (segment.properties.layerId !== this.properties.layerId) continue;

          const segCoords = segment.geometry.coordinates;
          const start = segCoords[0];
          const end = segCoords[segCoords.length - 1];

          const d = pDistance(ptCoords[0], ptCoords[1], start[0], start[1], end[0], end[1]);
          if (d < snapDistanceThreshold) {
            [snappedCoords, positionOnSegment] = getClosestPointInSegment(ptCoords, start, end);
            this.properties.segment = {
              id: segment.id as string,
              position: positionOnSegment,
            };

            // let's define the orientation as well
            this.properties.orientation = findShapeOrientation([start[0], start[1]], [end[0], end[1]]);

            break;
          }
        }

        this.geometry.coordinates = snappedCoords;

        if (this.properties.segment && this.properties.segment.id && keys.includes(this.properties.segment.id)) {
          d3.select(`[uuid='${this.properties.segment.id}']`).style('stroke-dasharray', '4 1');
        }
      }
    }

    super.onDrag();
    this.clearRadius();
    this.drawRadius(this.radius);

    if (!this.translating) {
      this.node.select('.interest-point-name').remove();
      this.translating = true;
    }
  }

  protected onDragEnd(): void {
    const pointDidntMove = isEqual(this.geometry.coordinates, this.coordsAtDragStart);

    if (pointDidntMove || this.locked || isShapeInSelectedShapes(this.id)) return;

    if (this.properties.segment && this.properties.segment.id) {
      d3.select(`[uuid='${this.properties.segment.id}']`).style('stroke-dasharray', null);
    }

    if (store.getState().circuit.present.points.entities[this.id]?.properties.segment && this.properties.segment) {
      const [snappedCoords, segmentProperty] = [this.geometry.coordinates, this.properties.segment];
      if (this.coordsAtDragStart) this.geometry.coordinates = this.coordsAtDragStart;
      this.properties.segment = this.segmentAtDragStart;

      store.dispatch(
        pointMovedAction({
          id: this.id,
          coordinates: snappedCoords,
          segment: segmentProperty,
        })
      );
    } else {
      store.dispatch(
        pointMovedAction({
          id: this.id,
          coordinates: this.geometry.coordinates,
        })
      );
    }

    super.onDragEnd(this.properties);

    this.coordsAtDragStart = undefined;
    this.segmentAtDragStart = undefined;
  }

  protected translateEnd(): void {
    if (!this.draggeable()) return;

    if (!this.properties?.segment?.id) {
      store.dispatch(
        savePointAction({
          id: this.id,
          geometry: this.geometry,
        })
      );
    }
  }

  protected updateShape(): void {
    this.refreshGeometry();
    this.clearRadius();
    this.drawRadius(pointTextSizeToPx(this.drawingProperties?.size ?? 'small'));
  }

  public async showGabarit(): Promise<void> {
    const modelName = this.properties.gabarit?.modelName;
    const gabaritName = this.properties.gabarit?.type;

    if (!modelName || !gabaritName) return;

    const point = {
      id: this.id,
      geometry: this.geometry,
      properties: this.properties,
    };
    if (!isCircuitPoint(point)) {
      // eslint-disable-next-line no-console
      console.error(`Point ${this.id} is not a circuit point`);

      return;
    }

    const gabaritCoords = getGabaritCoordsCached(gabaritName, modelName);
    if (!gabaritCoords) return;

    const gabarit = await gabaritsWorkerProxy.generatePointGabarit({
      point,
      gabaritName,
      modelName,
      gabaritCoords,
    });
    if (!gabarit || !gabarit.geometry) return;

    this.node
      .append('svg:defs')
      .append('svg:path')
      .attr('id', `gabarit-${this.id}`)
      .data([gabarit.geometry])
      .attr('d', this.projection as any)
      .classed('gabarit', true)
      .exit();
  }
}
