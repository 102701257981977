import type EditIcon from '@mui/icons-material/Edit';
import ReactDOMServer from 'react-dom/server';

/**
 * Return a svg from a mui icon
 * @param Icon a mui icon react component
 * @returns a svg of this mui icon
 */
export function getSvgFromIcon(Icon: typeof EditIcon): Document {
  const iconString = ReactDOMServer.renderToStaticMarkup(<Icon />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');

  return svgDoc;
}
