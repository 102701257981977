import type { StockDBConfiguration } from 'models/stockDB-configuration';

export const stockDBConfiguration: StockDBConfiguration = {
  commonObjects: {
    cellTemplates: {
      path: 'properties/cellTemplates',
      mandatoryProperties: [
        'name',
        'width',
        'height',
        'approachDistance',
        'loads',
        'leftObstacle',
        'rightObstacle',
        'palletOverflow',
        'palletTypes',
        'perception',
      ],
      optionalProperties: ['beamDistanceToRackFront'],
    },
    layerGroup: {
      path: 'properties/layers/layerGroups',
      mandatoryProperties: ['name', 'children'],
    },
    layer: {
      path: 'properties/layers/layers',
      mandatoryProperties: ['name'],
    },
  },
  layers: {
    POINT: {
      optionalProperties: ['minimumInitScore', 'segment'],
      mandatoryProperties: ['name', 'isBattery', 'isTaxi', 'isInit', 'height'],
    },
    STOCK: {
      childObject: { 'STOCK-ZONE': '' },
    },
    'STOCK-ZONE': {
      optionalProperties: ['displayPallets', 'palletToDisplay', 'stockZoneMargin'],
      mandatoryProperties: [
        'name',
        'referencePosX',
        'referenceOffsetX',
        'referencePosY',
        'referenceOffsetY',
        'enableScan',
        'fillStrategy',
        'emptyStrategy',
      ],
      childObject: { 'STOCK-LINE': 'slots' },
    },
    'STOCK-LINE': {
      mandatoryProperties: ['name'],
      childObject: { 'STOCK-SLOT': 'slots' },
    },
    'STOCK-SLOT': {
      optionalProperties: ['freespaceMethod'],
      mandatoryProperties: [
        'name',
        'palletPosition',
        'palletSize',
        'slotPosition',
        'palletTypes',
        'slotSize',
        'tolerancePosition',
      ],
    },
    RACK: {
      optionalProperties: ['conveyor'],
      mandatoryProperties: ['name', 'depth', 'uprights'],
      childObject: { 'RACK-COLUMN': 'columns' },
    },
    'RACK-COLUMN': {
      optionalProperties: ['extendedLengthSegments'],
      mandatoryProperties: ['id', 'nbLevels', 'extendedLength', 'width', 'startHeight'],
      childObject: { 'RACK-CELL': 'cells' },
    },
    'RACK-CELL': {
      optionalProperties: ['cellTemplate'],
      mandatoryProperties: ['height', 'beamThickness', 'names'],
    },
  },
};
