export type RobotCommunicationState = 'OK' | 'OFF' | 'CRC' | 'DST' | 'CMD';
export function robotComStateNbToState(nb: number): RobotCommunicationState {
  switch (nb) {
    case 0: {
      return 'OK';
    }

    case 1: {
      return 'OFF';
    }

    case 2: {
      return 'CRC';
    }

    case 3: {
      return 'DST';
    }

    case 4: {
      return 'CMD';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot communication state: ${nb}`);

      return 'OK';
    }
  }
}

export type RobotSecurityState =
  | 'Front'
  | 'Rear'
  | 'CurtainFront'
  | 'CurtainRear'
  | 'BA'
  | 'OK'
  | 'EmergencyStop'
  | 'EmergencySoftStop'
  | 'HardLimitPosition'
  | 'GPIO'
  | 'LOC'
  | 'EmergencySoftStopCharging'
  | 'Undefined';
export function robotSecurityStateNbToState(nb: number): RobotSecurityState {
  switch (nb) {
    case -5: {
      return 'CurtainRear';
    }

    case -4: {
      return 'CurtainFront';
    }

    case -3: {
      return 'Rear';
    }

    case -2: {
      return 'Front';
    }

    case -1: {
      return 'BA';
    }

    case 0: {
      return 'OK';
    }

    case 1: {
      return 'EmergencyStop';
    }

    case 2: {
      return 'EmergencySoftStop';
    }

    case 3: {
      return 'HardLimitPosition';
    }

    case 4: {
      return 'GPIO';
    }

    case 5: {
      return 'LOC';
    }

    case 6: {
      return 'EmergencySoftStopCharging';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot security state: ${nb}`);

      return 'Undefined';
    }
  }
}

export type RobotPositionState = 'OK' | 'OffTrack' | 'Near' | 'Unitialized' | 'Undefined';
export function robotPositionStateNbToState(nb: number): RobotPositionState {
  switch (nb) {
    case 0: {
      return 'OK';
    }

    case 1: {
      return 'OffTrack';
    }

    case 2: {
      return 'Near';
    }

    case 3: {
      return 'Unitialized';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot position state: ${nb}`);

      return 'Undefined';
    }
  }
}

export type RobotNavigationState =
  | 'NoDestination'
  | 'OK'
  | 'Traffic'
  | 'Stop'
  | 'Manual'
  | 'ErrorManual'
  | 'TrafficES'
  | 'Door'
  | 'Undefined';
export function robotNavigationStateNbToState(nb: number): RobotNavigationState {
  switch (nb) {
    case 0: {
      return 'OK';
    }

    case 1: {
      return 'Traffic';
    }

    case 2: {
      return 'Stop';
    }

    case 3: {
      return 'NoDestination';
    }

    case 4: {
      return 'Manual';
    }

    case 5: {
      return 'ErrorManual';
    }

    case 6: {
      return 'TrafficES';
    }

    case 7: {
      return 'Door';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot navigation state: ${nb}`);

      return 'Undefined';
    }
  }
}

export type RobotTaskState =
  | 'Manual'
  | 'ErrorMission'
  | 'ErrorNavigation'
  | 'ErrorBattery'
  | 'Run'
  | 'RunToTaxi'
  | 'Switch'
  | 'Available'
  | 'Calibration'
  | 'Undefined';

/** @see {isRobotTaskTaxi} ts-auto-guard:type-guard */
export type RobotTaskTaxi = 'RunToTaxi' | 'Available';

export function robotTaskStateNbToState(nb: number): RobotTaskState {
  switch (nb) {
    case 0x10: {
      return 'Manual';
    }

    case 0x20: {
      return 'ErrorMission';
    }

    case 0x30: {
      return 'ErrorNavigation';
    }

    case 0x40: {
      return 'ErrorBattery';
    }

    case 0x07: {
      return 'Run';
    }

    case 0x03: {
      return 'RunToTaxi';
    }

    case 0x01: {
      return 'Switch';
    }

    case 0x80: {
      return 'Calibration';
    }

    case 0: {
      return 'Available';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot mission state: ${nb}`);

      return 'Undefined';
    }
  }
}

export type RobotTrafficState =
  | 'Init'
  | 'Bug'
  | 'Error1'
  | 'Error2'
  | 'Error3'
  | 'Error4'
  | 'Error5'
  | 'Error6'
  | 'Error7'
  | 'Manu'
  | 'ManuThief'
  | 'ManuOff'
  | 'ManuHC'
  | 'ManuOK'
  | 'AutoAU'
  | 'AutoWait'
  | 'AutoNav'
  | 'Auto'
  | 'AutoStart';

/**
 * @see {isRobotTrafficStateOK} ts-auto-guard:type-guard
 */
export type RobotTrafficStateOK = 'Init' | 'Manu' | 'AutoNav' | 'Auto' | 'AutoStart' | 'ManuOK' | 'AutoWait';

/**
 * @see {isRobotTrafficStateWait} ts-auto-guard:type-guard
 */
export type RobotTrafficStateWait = 'AutoWait' | 'AutoNav';

export function robotTrafficStateNbToState(nb: number): RobotTrafficState {
  switch (nb) {
    case 0x41: {
      return 'Init';
    }

    case 0x42: {
      return 'Bug';
    }

    case 0x31: {
      return 'Error1';
    }

    case 0x32: {
      return 'Error2';
    }

    case 0x33: {
      return 'Error3';
    }

    case 0x34: {
      return 'Error4';
    }

    case 0x35: {
      return 'Error5';
    }

    case 0x36: {
      return 'Error6';
    }

    case 0x37: {
      return 'Error7';
    }

    case 0x20: {
      return 'Manu';
    }

    case 0x21: {
      return 'ManuThief';
    }

    case 0x22: {
      return 'ManuOff';
    }

    case 0x23: {
      return 'ManuHC';
    }

    case 0x24: {
      return 'ManuOK';
    }

    case 0x01: {
      return 'AutoAU';
    }

    case 0x02: {
      return 'AutoWait';
    }

    case 0x03: {
      return 'AutoNav';
    }

    case 0x04: {
      return 'Auto';
    }

    case 0x05: {
      return 'AutoStart';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot traffic state: ${nb}`);

      return 'Init';
    }
  }
}

export type RobotActionState =
  | 'undefined'
  | 'idle'
  | 'travel'
  | 'fork_moving'
  | 'fork_moving_up'
  | 'fork_moving_down'
  | 'fork_rotation_right'
  | 'fork_rotation_left'
  | 'fork_retract_in'
  | 'fork_retract_out'
  | 'perception'
  | 'customEvent';

export function robotActionStateNbToState(nb: number): RobotActionState {
  switch (nb) {
    case -1: {
      return 'undefined';
    }

    case 0: {
      return 'idle';
    }

    case 1: {
      return 'travel';
    }

    case 2: {
      return 'fork_moving';
    }

    case 3: {
      return 'fork_moving_up';
    }

    case 4: {
      return 'fork_moving_down';
    }

    case 5: {
      return 'fork_rotation_right';
    }

    case 6: {
      return 'fork_rotation_left';
    }

    case 7: {
      return 'fork_retract_in';
    }

    case 8: {
      return 'fork_retract_out';
    }

    case 9: {
      return 'perception';
    }

    case 10: {
      return 'customEvent';
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot action state: ${nb}`);

      return 'undefined';
    }
  }
}
