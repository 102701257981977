import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { alignElementAction, saveCircuitToHistoryAction } from 'actions/circuit';
import type { AlignAction } from 'drawings/elements/align';
import { useCallback, useRef, useState } from 'react';
import type { SelectedShapesData } from 'reducers/local/state';
import { CircuitService } from 'services/circuit.service';
import { useAppDispatch } from 'store';

const useStyles = makeStyles((theme) =>
  createStyles({
    listItemIconOverride: {
      minWidth: '28px',
    },
  })
);

type ActionDirection = 'Left' | 'Right' | 'Center' | 'Top' | 'Bottom' | 'vertically' | 'horizontally';

interface AlignElementsProps {
  selectedShapes: SelectedShapesData;
  handleClose: VoidFunction;
}

export function AlignElements({ selectedShapes, handleClose }: AlignElementsProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const alignHorizontallyListItemRef = useRef<HTMLLIElement | null>(null);
  const [openMenuAlignHorizontally, setOpenMenuAlignHorizontally] = useState(false);

  const handleCloseMenuAlignHorizontally = useCallback(() => {
    setOpenMenuAlignHorizontally(false);
  }, []);

  const handleClickAlignHorizontally = useCallback(() => {
    setOpenMenuAlignHorizontally(true);
  }, []);

  const alignVerticallyListItemRef = useRef<HTMLLIElement | null>(null);
  const [openMenuAlignVertically, setOpenMenuAlignVertically] = useState(false);

  const handleCloseMenuAlignVertically = useCallback(() => {
    setOpenMenuAlignVertically(false);
  }, []);

  const handleClickAlignVertically = useCallback(() => {
    setOpenMenuAlignVertically(true);
  }, []);

  const handleAlignElement = useCallback(
    (alignAction: AlignAction) => {
      if (
        alignAction === 'horizontallyLeft' ||
        alignAction === 'horizontallyCenter' ||
        alignAction === 'horizontallyRight'
      ) {
        handleCloseMenuAlignHorizontally();
      } else {
        handleCloseMenuAlignVertically();
      }

      handleClose();

      const shapes = selectedShapes.map((shape) => {
        return CircuitService.getShape(shape.id, shape.type);
      });

      dispatch(saveCircuitToHistoryAction());

      dispatch(
        alignElementAction({
          alignAction,
          selectedShapes: shapes,
        })
      );
    },
    [dispatch, handleClose, handleCloseMenuAlignHorizontally, handleCloseMenuAlignVertically, selectedShapes]
  );

  const alignHorizontally = ['Left', 'Center', 'Right'];
  const alignVertically = ['Top', 'Center', 'Bottom'];

  return (
    <>
      <Box component="div" onMouseLeave={handleCloseMenuAlignHorizontally}>
        <MenuItem onMouseEnter={handleClickAlignHorizontally} dense={true} ref={alignHorizontallyListItemRef}>
          <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
            <AlignHorizontalLeftIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<>Align horizontally</>}></ListItemText>
          <ArrowRightIcon />
        </MenuItem>
        <Menu
          anchorEl={alignHorizontallyListItemRef.current}
          open={openMenuAlignHorizontally}
          onClose={handleCloseMenuAlignHorizontally}
          onMouseLeave={handleCloseMenuAlignHorizontally}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            maxHeight: '50vh',
          }}
          hideBackdrop
        >
          {alignHorizontally.map((action, i) => {
            return (
              <ListItemAlignElement
                direction={'horizontally'}
                action={action as ActionDirection}
                handleAlignElement={handleAlignElement}
                key={i}
              />
            );
          })}
        </Menu>
      </Box>

      <Box component="div" onMouseLeave={handleCloseMenuAlignVertically}>
        <MenuItem onMouseEnter={handleClickAlignVertically} dense={true} ref={alignVerticallyListItemRef}>
          <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
            <AlignVerticalTopIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<>Align vertically</>}></ListItemText>
          <ArrowRightIcon />
        </MenuItem>

        <Menu
          anchorEl={alignVerticallyListItemRef.current}
          open={openMenuAlignVertically}
          onClose={handleCloseMenuAlignVertically}
          onMouseLeave={handleCloseMenuAlignVertically}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            maxHeight: '50vh',
          }}
          hideBackdrop
        >
          {alignVertically.map((action, i) => {
            return (
              <ListItemAlignElement
                direction={'vertically'}
                action={action as ActionDirection}
                handleAlignElement={handleAlignElement}
                key={i}
              />
            );
          })}
        </Menu>
      </Box>
    </>
  );
}

interface ListItemAlignElementProps {
  direction: ActionDirection;
  action: ActionDirection;
  handleAlignElement: (alignAction: AlignAction) => void;
}

export function ListItemAlignElement({
  direction,
  action,
  handleAlignElement,
}: ListItemAlignElementProps): JSX.Element {
  const classes = useStyles();

  return (
    <MenuItem
      onClick={() => {
        handleAlignElement(`${direction}${action}` as AlignAction);
      }}
      dense={true}
    >
      <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
        {action === 'Top' ? (
          <AlignVerticalTopIcon fontSize="small" />
        ) : action === 'Center' && direction === 'vertically' ? (
          <AlignVerticalCenterIcon fontSize="small" />
        ) : action === 'Bottom' ? (
          <AlignVerticalBottomIcon fontSize="small" />
        ) : action === 'Left' ? (
          <AlignHorizontalLeftIcon fontSize="small" />
        ) : action === 'Right' ? (
          <AlignHorizontalRightIcon fontSize="small" />
        ) : (
          <AlignHorizontalCenterIcon fontSize="small" />
        )}
      </ListItemIcon>
      {action}
    </MenuItem>
  );
}
