import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { closeDialogAction } from 'actions';
import { DialogTypes } from 'models';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { translateCircuitAndReload } from 'utils/circuit/translate-whole-circuit';
import { getConfig } from 'utils/config';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      position: 'absolute',
      right: theme.spacing(1),
      top: '0',
      pointerEvents: 'none',
    },
    card: {
      display: 'flex',
      background: 'white',
      pointerEvents: 'initial',
      alignSelf: 'center',
      zIndex: 401,
      maxHeight: getConfig('editor').properties.maxHeight,
    },
    cardContent: {
      display: 'flex',
      flexFlow: 'column',
      overflow: 'auto',
      width: '400px',
      position: 'relative',
    },
    formTitle: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
  })
);

export const TranslateCircuitPropertiesComponent = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translateCircuit = useAppSelector(
    (state) => state.dialog.type === DialogTypes.TranslateCircuit && state.dialog.open
  );

  const [deltaX, setDeltaX] = useState('');
  const [deltaY, setDeltaY] = useState('');

  const handleClose = (): void => {
    dispatch(closeDialogAction());
  };

  const handleTranslate = async (): Promise<void> => {
    const deltaXNum = parseFloat(deltaX);
    const deltaYNum = parseFloat(deltaY);

    if (!isNaN(deltaXNum) && !isNaN(deltaYNum) && (deltaXNum !== 0 || deltaYNum !== 0)) {
      await translateCircuitAndReload(deltaXNum, deltaYNum);
    }
  };

  return !translateCircuit ? (
    <></>
  ) : (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.formTitle}>
            <Typography variant="h6">Translate Circuit</Typography>
            <Tooltip title="Close Menu">
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '8px', right: '0' }} size="large">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Box component="div" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
              <TextField
                label="Move in X"
                value={deltaX}
                onChange={(e) => setDeltaX(e.target.value)}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
                variant="standard"
              />
              <TextField
                label="Move in Y "
                value={deltaY}
                onChange={(e) => setDeltaY(e.target.value)}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
                variant="standard"
              />
            </Box>
            <Box component="div" sx={{ display: 'flex', marginTop: 2 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={isNaN(parseFloat(deltaX)) || isNaN(parseFloat(deltaY)) || (deltaX === '0' && deltaY === '0')}
                fullWidth
                onClick={handleTranslate}
              >
                Translate
              </Button>
            </Box>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
