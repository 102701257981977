import { object as YupObject, number as YupNumber, array as YupArray, string as YupString } from 'yup';

export const schemaCoordinates = YupObject().shape({
  coordinates: YupArray().of(YupNumber().required('A value is required')),
});

export const schemaProperties = YupObject().shape({
  name: YupString().required('The name is required'),
  // orientation: YupNumber().min(-180).max(180).required('An orientation is required'),
});
