import { clearShapesSelectionAction } from 'actions/circuit';
import type { UsersHighlight } from 'multiplayer/globals';
import {
  awareness,
  formerSelectedShapes,
  remoteDoc,
  setFormerSelectedShapes,
  setLastCircuitTransactionOrigin,
} from 'multiplayer/globals';
import type { UserPresence } from 'multiplayer/types';
import { useLayoutEffect } from 'react';
import { useAppSelector } from 'store';
import type { YMap } from 'yjs/dist/src/internals';
import type { RenderMode } from '../multiplayer';

function getHighlightStyleWithColor(color: string): string {
  return `3px solid ${color}`;
}

/* These shape have special behavior as their real box element is the main-shape-outline */
const specialBehaviorShapes = ['NOTE', 'DEVICE'];
function setShapeHighlightStyle(shapeId: string, outlineStyle: string): void {
  const shape = document.querySelector(`[uuid="${shapeId}"]`);

  if (!shape) return;

  if (specialBehaviorShapes.includes(shape.getAttribute('type') || '')) {
    const shapeOutline = shape.querySelector('[main-shape-outline]');

    const shapeOutlineIsNotAnSVGElement = !(shapeOutline && shapeOutline instanceof SVGElement);
    if (shapeOutlineIsNotAnSVGElement) return;

    shapeOutline.style.outline = outlineStyle;

    return;
  }

  const mainShape = shape.querySelector('[main-shape]');

  const mainShapeIsNotAnSVGElement = !(mainShape && mainShape instanceof SVGElement);
  if (mainShapeIsNotAnSVGElement) return;

  mainShape.style.outline = outlineStyle;
}

function drawSelectedShapesHighlight(refresh = false): void {
  if (refresh) {
    /* Refresh the highlight shape of the previously highligted shape */
    formerSelectedShapes.forEach((user) => {
      user.selectedShapesId?.forEach((shapeId) => {
        /* SetTimeout to wait for the refresh to finish before highlighting */
        setTimeout(() => {
          setShapeHighlightStyle(shapeId, getHighlightStyleWithColor(user.color));
        }, 0);
      });
    });

    return;
  }

  const selectedShapesMap = remoteDoc?.getMap('selectedShapes') as YMap<string[]>;

  /* Create an array of object which will contain the information to loop throught for the highlight,
  additionally filter the current user as it is already handled locally */
  const selectedShapes = Array.from(selectedShapesMap.entries())
    ?.map(([clientId, state]: [string, string[]]) => {
      if (!awareness || +clientId === awareness.clientID) return undefined;

      const color = awareness.getStates().get(+clientId)?.c as UserPresence['c'];

      return {
        id: clientId,
        color,
        selectedShapesId: state,
      };
    })
    .filter((state): state is UsersHighlight['usersHighlight'][0] => !!state);

  /* If both user manage to select the same shape, unselect all their selections */
  if (
    selectedShapes.length > 0 &&
    selectedShapes
      .flatMap((selectedShape) => selectedShape.selectedShapesId)
      .includes(window.getStoreState().local.selectedShapesData.flatMap((shape) => shape.id)[0])
  ) {
    window.dispatchStore(clearShapesSelectionAction());
  }

  /* Remove the highlight of the the formerly selected shape */
  formerSelectedShapes.forEach((user) => {
    user.selectedShapesId?.forEach((shapeId) => {
      setShapeHighlightStyle(shapeId, 'revert-layer');
    });
  });

  /* Highlight the new selected shape */
  selectedShapes.forEach((user) => {
    user.selectedShapesId?.forEach((shapeId) => {
      setShapeHighlightStyle(shapeId, getHighlightStyleWithColor(user.color));
    });
  });

  setFormerSelectedShapes(selectedShapes);
}

export const useHighlight = ({ mode }: { mode: RenderMode }): void => {
  const selectedShapesMap = remoteDoc?.getMap('selectedShapes') as YMap<string[]>;

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      if (mode === '3d') return;

      const isTransactionLocal = transaction.origin === 'local';
      setLastCircuitTransactionOrigin(isTransactionLocal ? 'local' : 'remote');
      if (isTransactionLocal) return;

      drawSelectedShapesHighlight();
    };

    selectedShapesMap.observe(observerHandle);

    return () => {
      selectedShapesMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const circuit = useAppSelector((state) => state.circuit.present);

  /* On shape move, update the highlight */
  useLayoutEffect(() => {
    if (!circuit || !formerSelectedShapes.length) return;

    if (mode === '3d') return;
    drawSelectedShapesHighlight(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuit]);
};
