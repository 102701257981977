/*
 * Generated type guards for "drawings.ts".
 * WARNING: Do not manually change this file.
 */
import { pointSize, lineStrokeSize, ReturnToMoveToolType } from './drawings';

export function isPointSize(obj: unknown): obj is pointSize {
  const typedObj = obj as pointSize;
  return typedObj === 'small' || typedObj === 'medium' || typedObj === 'large';
}

export function isLineStrokeSize(obj: unknown): obj is lineStrokeSize {
  const typedObj = obj as lineStrokeSize;
  return typedObj === 's' || typedObj === 'm' || typedObj === 'l' || typedObj === 'xl';
}

export function isReturnToMoveToolType(obj: unknown): obj is ReturnToMoveToolType {
  const typedObj = obj as ReturnToMoveToolType;
  return typedObj === 'always' || typedObj === 'never' || typedObj === 'sometimes';
}
