import { deleteZoneAction, saveZoneAction } from 'actions/zones';
import type { CircuitZone } from 'models/circuit';
import type { Dispatch } from 'react';
import { connect } from 'react-redux';
import { selectCurrentCircuit, selectSelectedCircuitZone } from 'reducers/circuit/selectors';
import { selectCurrentLocal } from 'reducers/local/selectors';
import type { AppState } from 'reducers/state';
import { ZonePropertiesComponent } from './zone-properties';

interface StateProps {
  zone?: CircuitZone;
}

function mapStateToProps(state: AppState): StateProps {
  const circuit = selectCurrentCircuit(state);
  const local = selectCurrentLocal(state);
  const zone = selectSelectedCircuitZone({ ...circuit, ...local });

  return { zone };
}

interface DispatchProps {
  onSubmit: (payload: Partial<CircuitZone>) => void;
  onDelete: (payload: string) => void;
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
  return {
    onSubmit: (payload: Partial<CircuitZone>) => dispatch(saveZoneAction({ ...payload })),
    onDelete: (payload: string) => dispatch(deleteZoneAction({ id: payload })),
  };
}

export const ZoneProperties = connect(mapStateToProps, mapDispatchToProps)(ZonePropertiesComponent);
