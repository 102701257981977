export function isNumberArr(value: unknown): value is number[] {
  return value !== undefined && Array.isArray(value) && value.every((v) => typeof v === 'number');
}

export function isNumberArrArr(value: unknown): value is number[][] {
  return value !== undefined && Array.isArray(value) && value.every((v) => isNumberArr(v));
}

export function isNumberArrArrArr(value: unknown): value is number[][][] {
  return value !== undefined && Array.isArray(value) && value.every((v) => isNumberArrArr(v));
}
