import * as Sentry from '@sentry/react';
import { initAction, selectToolAction } from 'actions';
import { CellTemplateActionTypes } from 'actions/cell-templates';
import { CircuitActionTypes } from 'actions/circuit';
import { DeviceActionTypes } from 'actions/devices';
import { MeasurerActionTypes } from 'actions/measurers';
import { NotesActionTypes } from 'actions/notes';
import { PointsActionTypes } from 'actions/points';
import { RackActionTypes } from 'actions/racks';
import { SegmentActionTypes } from 'actions/segment';
import { StockZonesActionTypes } from 'actions/stock-zones';
import { TurnActionTypes } from 'actions/turns';
import { ZonesActionTypes } from 'actions/zones';
import { emergencySave } from 'components/export/emergency-save';
import { routerMiddleware } from 'connected-react-router';
import rootEpic from 'epics';
import type { History } from 'history';
import { createBrowserHistory } from 'history';
import { Tools } from 'models/tools';
import type { Dispatch } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import rootReducer from 'reducers';
import type { CircuitState } from 'reducers/circuit/state';
import type { LocalState } from 'reducers/local/state';
import type { AppState } from 'reducers/state';
import type { AnyAction } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import profileStore from 'redux-profiler';
import { SnackbarUtils } from 'services/snackbar.service';
import { readFileSimulation } from 'simulation/read-file';
import type { Simulation } from 'simulation/simulation.model';
import { computeAgainAllPortions } from 'utils/circuit';
import { computeAgainAllRacks } from 'utils/circuit/compute-again-all-racks';
import { computeAislesAndAddToCircuit } from 'utils/circuit/compute-aisles';
import { exportCSVSlots } from 'utils/circuit/export-csv-slots';
import { fixDuplicateIds, testDuplicateIds } from 'utils/circuit/heal-circuit';
import { computeAgainAllTurns, exportAllTurns } from 'utils/circuit/turns';
import { downloadVda5050Json } from 'utils/export/vda5050/generate-vda5050-json';
import { delayNavigationMiddleware } from './middlewares';
import { onChangeStore } from './store-on-change';

export const history: History = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();

const entityEnhancer =
  (createStore: (arg0: (state: any, action: any) => any, arg1: any, arg2: any) => any) =>
  (reducer: (arg0: any, arg1: any) => any, initialState: any, enhancer: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/explicit-function-return-type
    const monitoredReducer = (state: any, action: any) => reducer(state, action);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return createStore(monitoredReducer, initialState, enhancer);
  };

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

interface RootStateSentry {
  circuit: {
    stockZones: number;
    zones: number;
    points: number;
    segments: number;
    measurers: number;
    turns: number;
    racks: number;
    layers: CircuitState['layers'];
  };
  local: {
    selectedShapesData: LocalState['selectedShapesData'];
    filters: LocalState['filters'];
  };
  auth: RootState['auth'];
  dialog: RootState['dialog'];
  editor: RootState['editor'];
  multiplayer: RootState['multiplayer'];
  project: RootState['project'];
  robots: RootState['robots'];
  tool: {
    activeTool: RootState['tool']['activeTool'];
    lastActiveTool: RootState['tool']['lastActiveTool'];
    snap90deg: RootState['tool']['snap90deg'];
  };
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    const transformedState: RootStateSentry = {
      circuit: {
        zones: state.circuit.present.zones.ids.length,
        stockZones: state.circuit.present.stockZones.ids.length,
        points: state.circuit.present.points.ids.length,
        segments: state.circuit.present.segments.ids.length,
        measurers: state.circuit.present.measurers.ids.length,
        turns: state.circuit.present.turns.ids.length,
        racks: state.circuit.present.racks.ids.length,
        layers: state.circuit.present.layers,
      },
      local: {
        selectedShapesData: state.local.selectedShapesData,
        filters: state.local.filters,
      },
      auth: state.auth,
      dialog: state.dialog,
      editor: state.editor,
      multiplayer: state.multiplayer,
      project: state.project,
      robots: state.robots,
      tool: {
        activeTool: state.tool.activeTool,
        lastActiveTool: state.tool.lastActiveTool,
        snap90deg: state.tool.snap90deg,
      },
    };

    return transformedState;
  },
});

const enhancers = [applyMiddleware(delayNavigationMiddleware(), epicMiddleware, routerMiddleware(history))];

type StoreType = {
  getState: () => AppState;
  dispatch: (any) => void;
};

type ActionType = { type: string; payload: any };

const circuitChangingActions = new Set<string>();
Object.values(CircuitActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);

Object.values(PointsActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(DeviceActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(MeasurerActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(NotesActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(RackActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(SegmentActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(StockZonesActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(TurnActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(ZonesActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);
Object.values(CellTemplateActionTypes).forEach(circuitChangingActions.add, circuitChangingActions);

circuitChangingActions.add(CircuitActionTypes.UpdateTurn);
circuitChangingActions.delete(CircuitActionTypes.SaveCircuitToHistory);
circuitChangingActions.delete(CircuitActionTypes.ApplyUpdatedShapesFromYJS);
circuitChangingActions.delete(CircuitActionTypes.LoadCircuitFromYJS);
circuitChangingActions.delete(CircuitActionTypes.ImportCircuit);
circuitChangingActions.delete(CircuitActionTypes.ImportCircuitSuccess);
circuitChangingActions.delete(CircuitActionTypes.ImportCircuitFailure);
circuitChangingActions.delete(CircuitActionTypes.UnselectShape);
circuitChangingActions.delete(CircuitActionTypes.UnselectSeveralShapes);
circuitChangingActions.delete(CircuitActionTypes.ClearShapesSelection);
circuitChangingActions.delete(CircuitActionTypes.ChangeDisplayStateParameters);

circuitChangingActions.forEach((action) => {
  const actionLowerCase = action.toLowerCase();

  if (actionLowerCase.includes('layer')) {
    circuitChangingActions.delete(action);
  }
});

const blockCircuitActionsWhenSelectedLayerIsHiddenMiddleware =
  (store: StoreType) => (next: (action: ActionType) => void) => (action: ActionType) => {
    if (store.getState().core.loading) {
      /* Continue the action as if nothing happened */
      return next(action);
    }

    const layersState = store.getState().circuit.present.layers;
    const layer = layersState.layers[layersState.selectedLayer];
    if (!layer) {
      // eslint-disable-next-line no-console
      console.error('layer not found');

      return next(action);
    }

    /* Check if the user is trying to do an action which changes the circuit even thought he's on a hidden layer
    Then display a snackbar, change his tool to Move and cancels the action
    */

    if (!layer.visibility && circuitChangingActions.has(action.type)) {
      SnackbarUtils.warning('The layer you are on is hidden, please make it visible to edit it', {
        preventDuplicate: true,
      });
      store.dispatch(
        selectToolAction({
          toolName: Tools.Move,
        })
      );

      return;
    }

    /* Continue the action as if nothing happened */
    return next(action);
  };

/* Add the middleware to the enhancers */
enhancers.push(applyMiddleware(blockCircuitActionsWhenSelectedLayerIsHiddenMiddleware));

const store = createStore(
  rootReducer(history),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-call
  composeEnhancers(!!window.__TEST__ || true ? entityEnhancer : profileStore(), ...enhancers, sentryReduxEnhancer)
);

epicMiddleware.run(rootEpic);

store.dispatch(initAction());

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): Dispatch<AnyAction> => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

store.subscribe(onChangeStore);

declare global {
  interface Window {
    mainScriptLoaded?: boolean;

    getStoreState: () => AppState;
    dispatchStore: AppDispatch;
    registrationWorker?: ServiceWorkerRegistration;
    updateAvailable?: boolean;

    nextFreeId: number;
    nextFreeIdArray: Array<number>;

    computeAgainAllTurns: () => Promise<void>;
    emergencySaveCircuit: () => Promise<void>;
    computeAgainAllPortions: () => Promise<void>;
    computeAgainAllRacks: () => Promise<void>;
    exportAllTurns: () => void;

    testDuplicateIds: () => boolean;
    fixDuplicateIds: () => void;
    regenerateStockLinesName: () => Promise<void>;
    computeAisles: () => void;

    exportCSVSlots: () => void;
    exportSynaOSDev: () => void;

    __TEST__?: boolean;

    libTrackModule?: () => Promise<Simulation>;

    /** Version of the balyo simulation module */
    balyoSimulationVersion?: string;

    /** Util to read a file in the simulation file system */
    readFileSimulation: (filePath: string) => Promise<string | undefined | null>;

    /** Current zoom value */
    currentZoom: number;
    /** Current zoom level */
    currentZoomLevel: string;
    /** grid coordinate : left top corner */
    x0: number;
    /** grid coordinate : left top corner */
    y0: number;
    /** grid coordinate : right top corner */
    x1: number;
    /** grid coordinate : left bottom corner */
    y1: number;

    dataLayer?: any[];

    gtag?: (type: string, name: string, data: any) => void;
  }
}

window.getStoreState = store.getState;
window.dispatchStore = store.dispatch;

window.computeAgainAllTurns = computeAgainAllTurns;
window.emergencySaveCircuit = emergencySave;
window.computeAgainAllPortions = computeAgainAllPortions;
window.computeAgainAllRacks = computeAgainAllRacks;
window.exportAllTurns = exportAllTurns;

window.testDuplicateIds = testDuplicateIds;
window.fixDuplicateIds = fixDuplicateIds;

window.exportCSVSlots = exportCSVSlots;
window.exportSynaOSDev = downloadVda5050Json;

window.computeAisles = computeAislesAndAddToCircuit;

window.readFileSimulation = readFileSimulation;

window.gtag = (...args) => {
  window.dataLayer?.push(args);
};

window.mainScriptLoaded = true;
