export class NotFoundError extends DOMException {
  public fileName: string | undefined;

  constructor(message: string, fileName?: string) {
    super(message, 'NotFoundError');

    this.fileName = fileName;
  }
}

export class NoMapError extends Error {
  constructor() {
    super('Leaflet map is missing');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NoSelectedCircuitError extends Error {
  constructor() {
    super('This action needs a circuit to be selected');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NoSelectedProjectError extends Error {
  constructor() {
    super('This action needs a project to be selected');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class WebSocketConnectionClosedError extends Error {
  constructor() {
    super('The web socket connection is closed');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class WebRTCConnectionClosedError extends Error {
  constructor() {
    super('The web rtc connection is closed');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class AlreadyANonClosedPeerConnection extends Error {
  constructor() {
    super('Cant open more than one peer Connection');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NoNetworkError extends Error {
  constructor() {
    super(`You don't seem to be connected, make sure your connection is established and try again`);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class ZoneConveyorDeletionError extends Error {
  constructor(msg?: string) {
    super(msg ?? 'Cannot delete a zone with a conveyor, the zone must be deleted by the conveyor deletion');
    Object.setPrototypeOf(this, ZoneConveyorDeletionError.prototype);
  }
}

export class MemoryAllocationError extends Error {
  constructor() {
    super('Memory allocation failed');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class LFSFilesNotPulledError extends Error {
  constructor() {
    super('LFS files have not been pulled');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
