import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import { Border } from 'components/utils/border';
import { CollapseMore } from 'components/utils/collapse-more';
import type { ErrorMonitoringAction } from 'services/simu-errors-monitoring-action';
import { isErrorMonitoringAction } from 'services/simu-errors-monitoring-action';
import { setErrorsMonitoringMode } from 'simulation/simulation';
import { useAppDispatch, useAppSelector } from 'store';

interface ErrorLegend {
  key: ErrorMonitoringAction;
  label: string | JSX.Element;
  helpTooltip: string;
  category: 'error-robots' | 'deadlock-robots';
}
const allErrorsLegend: ErrorLegend[] = [
  {
    key: 'Continue',
    label: 'Continue',
    helpTooltip: 'The simulation will continue and all the robots in error will still be in error.',
    category: 'error-robots',
  },
  {
    key: 'StopSimu',
    label: 'Stop',
    helpTooltip: 'The simulation will stop for each robot in error.',
    category: 'error-robots',
  },
  {
    key: 'ResetRobot',
    label: 'Reset robots',
    helpTooltip: 'Robots in error will be reset and the simulation will continue.',
    category: 'error-robots',
  },
  {
    key: 'Continue',
    label: 'Continue',
    helpTooltip: 'The simulation will continue and all the robots will still be in their deadlock situation.',
    category: 'deadlock-robots',
  },
  {
    key: 'StopSimu',
    label: 'Stop',
    helpTooltip: 'The simulation will stop for each robot in deadlock.',
    category: 'deadlock-robots',
  },
  {
    key: 'ResetRobot',
    label: 'Reset robots',
    helpTooltip: 'Robots in deadlock will be reset and the simulation will continue.',
    category: 'deadlock-robots',
  },
];

const allErrorsLegendByCategory: Record<ErrorLegend['category'], ErrorLegend[]> = {
  'error-robots': allErrorsLegend.filter((errorLegend) => errorLegend.category === 'error-robots'),
  'deadlock-robots': allErrorsLegend.filter((errorLegend) => errorLegend.category === 'deadlock-robots'),
};

export function DisplayErrorsMonitoringCollapse(): JSX.Element {
  const valueRobotError = useAppSelector((state) => state.simulation.errorsMonitoring.robotError);
  const valueRobotDeadlock = useAppSelector((state) => state.simulation.errorsMonitoring.deadlock);
  const dispatch = useAppDispatch();

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>, type: 'deadlock' | 'error'): void => {
    const value = event.target.value;
    const mode = type === 'error' ? 'robotError' : 'deadlock';
    if (!isErrorMonitoringAction(value)) {
      // eslint-disable-next-line no-console
      console.error('value is not a monitoring action', value);

      return;
    }

    dispatch(
      setErrorsMonitoringMode({
        newMode: mode,
        newValue: value,
      })
    );
  };

  return (
    <CollapseMore title="Errors Monitoring" unmountOnExit={true}>
      <Border>
        <CollapseMore title="Robots in error" defaultExpended={true} unmountOnExit={true}>
          <FormControl fullWidth>
            <RadioGroup
              value={valueRobotError}
              onChange={(e) => {
                handleChangeValue(e, 'error');
              }}
              row
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <RadioMonitoringComponent error={allErrorsLegendByCategory['error-robots']} />
            </RadioGroup>
          </FormControl>
        </CollapseMore>
        <CollapseMore title="Robots in deadlock" defaultExpended={true} unmountOnExit={true}>
          <FormControl fullWidth>
            <RadioGroup
              value={valueRobotDeadlock}
              onChange={(e) => {
                handleChangeValue(e, 'deadlock');
              }}
              row
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <RadioMonitoringComponent error={allErrorsLegendByCategory['deadlock-robots']} />
            </RadioGroup>
          </FormControl>
        </CollapseMore>
      </Border>
    </CollapseMore>
  );
}

interface RadioMonitoringComponentProps {
  error: ErrorLegend[];
}

function RadioMonitoringComponent({ error }: RadioMonitoringComponentProps): JSX.Element {
  return (
    <>
      {error.map((errorLegend) => (
        <FormControlLabel
          key={`${errorLegend.key}`}
          value={errorLegend.key}
          control={
            <Tooltip title={errorLegend.helpTooltip}>
              <Radio />
            </Tooltip>
          }
          label={errorLegend.label}
        />
      ))}
    </>
  );
}
