/**
 * This helper functions defined a promise that will be rejected after a given duration.
 * @param duration the duration after which the promise will be rejected (ms)
 * @returns nothing
 */
export function timeoutAfter(duration: number): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(`promise timed-out after ${duration}ms`));
    }, duration);
  });
}

/**
 * This helper function defines a promise that will be resolved after a given duration.
 * @param duration the duration after which the promise will be resolved (ms)
 * @returns a promise that resolves after the given duration
 */
export function resolveAfter(duration: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
