import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { CustomTheme } from 'utils/mui-theme';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      height: '100%',
      width: '75%',
      marginLeft: '12.5%',
      marginRight: '12.5%',
      display: 'flex',
      justifyContent: 'center',
    },
    verticallyCentered: {
      alignItems: 'center',
    },
    formPage: {
      maxWidth: '960px',
      margin: 'auto',
    },
    fullWidth: {
      width: '100%',
    },
  })
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CenteredPageLayout({ children, verticallyCentered = false, formPage = false }): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.verticallyCentered]: verticallyCentered,
        [classes.formPage]: formPage,
      })}
    >
      <div className={classes.fullWidth}>{children}</div>
    </div>
  );
}
