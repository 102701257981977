import { Delete, Edit, ImageAspectRatio, OpenInNew, RestartAlt, Warning } from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { clearLidarMapAction, importLidarAction } from 'actions';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useConfirm } from 'material-ui-confirm';
import { LidarPosition } from 'models/maps';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch, useAppSelector } from 'store';
import { MAP_EDITOR_URL } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';

interface EditLidarMapProps {
  projectOpened: boolean;
  editProjectPerm?: boolean;
}

export function EditLidarMap(props: EditLidarMapProps): JSX.Element {
  const { projectOpened, editProjectPerm } = props;

  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const foregroundLidarMap = useAppSelector((state) => state.maps.lidar['foreground-lidar']);
  const backgroundLidarMap = useAppSelector((state) => state.maps.lidar['background-lidar']);

  const [displayBackgroundLidarWarning, setDisplayBackgroundLidarWarning] = useState(false);
  const [backgroundLidarFileName, setBackgroundLidarFileName] = useState(backgroundLidarMap?.name);
  const [isDefaultBackgroundLidar, setIsDefaultBackgroundLidar] = useState(true);

  const uploadLidar = useCallback(
    (file: File, position: LidarPosition, saveBackgroundLidar = false): void => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const textContent = fileReader.result?.toString();
        if (textContent) {
          return dispatch(
            importLidarAction({ position, coords: textContent.split('\n'), name: file.name, saveBackgroundLidar })
          );
        }
      };

      if (file) {
        fileReader.readAsText(file);
      }
    },
    [dispatch]
  );

  const onBackgroundLidarChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0]) {
        delete localStorage.drawnBackgroundLidar;

        let isChecked = false;
        const content = (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    isChecked = e.target.checked;
                  }}
                />
              }
              label="Use as default navigation map of the project"
            />
          </>
        );

        confirm({
          title: 'Background lidar',
          content,
          confirmationButtonProps: { autoFocus: true },
          allowClose: false,
        }).then(() => {
          uploadLidar(files[0], LidarPosition.Background, isChecked);
        });
      }
    },
    [confirm, uploadLidar]
  );

  const onForegroundLidarChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0]) {
        uploadLidar(files[0], LidarPosition.Foreground);
      }
    },
    [uploadLidar]
  );

  const refForegroundLidarInput = useRef<HTMLInputElement | null>(null);
  const refBackgroundLidarInput = useRef<HTMLInputElement | null>(null);

  const eraseNavigationLidarMap = useCallback((): void => {
    confirm({
      title: 'Delete navigation lidar map?',
      allowClose: false,
    })
      .then(() => {
        delete localStorage.drawnBackgroundLidar;
        dispatch(
          clearLidarMapAction({
            name: LidarPosition.Background,
          })
        );
      })
      .catch(() => undefined);
  }, [confirm, dispatch]);

  const eraseObstacleLidarMap = useCallback((): void => {
    confirm({
      title: 'Delete the obstacle map?',
      allowClose: false,
    })
      .then(() => {
        dispatch(
          clearLidarMapAction({
            name: LidarPosition.Foreground,
          })
        );
      })
      .catch(() => undefined);
  }, [confirm, dispatch]);

  const resetToDefaultBackgroundLidar = useCallback(async () => {
    delete localStorage.drawnBackgroundLidar;

    // we load the lidar map file
    let mapFile: Awaited<ReturnType<typeof PreferencesService.loadMapFile>> | undefined;
    try {
      mapFile = await PreferencesService.loadMapFile();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error while loading map file', e);
    }

    if (mapFile?.mapDataTxt && mapFile.mapDataTxt.length) {
      const { mapDataTxt, mapNameGeo } = mapFile;
      const mapFileArr = mapDataTxt.split('\n');

      dispatch(importLidarAction({ position: LidarPosition.Background, coords: mapFileArr, name: mapNameGeo }));
    } else {
      SnackbarUtils.warning(`The navigation map has not been found.`);
    }
  }, [dispatch]);

  useEffect(() => {
    const checkBackgroundLidarWarning = async (): Promise<void> => {
      if (!backgroundLidarMap) {
        setDisplayBackgroundLidarWarning(false);

        return;
      }

      const mapFilePath = (() => {
        try {
          return PreferencesService.getPreferenceValue('localisation/mapFilePath') as string;
        } catch (e) {
          return '';
        }
      })();
      if (
        [backgroundLidarMap.name.split('.')?.[0], 'Woburn_v1'].includes(mapFilePath.split('.')?.[0]) ||
        !mapFilePath.length
      ) {
        setIsDefaultBackgroundLidar(true);
      } else {
        setIsDefaultBackgroundLidar(false);
      }

      const backgroundLidarMapGeoFile = await PreferencesService.getFileByPath(
        `MAP/${backgroundLidarMap.name.replace('.txt', '.geo')}`
      );

      if (backgroundLidarMapGeoFile) {
        setDisplayBackgroundLidarWarning(false);
        setBackgroundLidarFileName(backgroundLidarMap.name);
      } else {
        setDisplayBackgroundLidarWarning(true);
        setBackgroundLidarFileName(backgroundLidarMap.name.replace('.geo', '.txt'));
      }
    };

    checkBackgroundLidarWarning();
  }, [backgroundLidarMap]);

  return (
    <>
      <ListItem>
        <input
          accept=".txt"
          type="file"
          onChange={onBackgroundLidarChange}
          id="background-lidar-input"
          style={{ display: 'none' }}
          ref={refBackgroundLidarInput}
        />
        <ListItemIcon>
          <ImageAspectRatio />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box component="span">
                {backgroundLidarMap ? `Imported (${backgroundLidarFileName})` : 'Not imported'}
              </Box>

              <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
                <Box component="span">
                  {backgroundLidarMap && !projectOpened ? (
                    <IconButton onClick={eraseNavigationLidarMap} disabled={!editProjectPerm}>
                      <Delete
                        sx={{
                          fontSize: '1rem',
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        refBackgroundLidarInput.current?.click();
                      }}
                      disabled={!editProjectPerm}
                    >
                      <Edit
                        sx={{
                          fontSize: '1rem',
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Tooltip>
              {displayBackgroundLidarWarning ? (
                <Tooltip title="Navigation map .geo file not found, please import it in the MAP folder">
                  <Warning sx={{ color: theme.palette.warning.main }} />
                </Tooltip>
              ) : null}
              {!isDefaultBackgroundLidar ? (
                <Tooltip title="Reset to default navigation map">
                  <IconButton onClick={resetToDefaultBackgroundLidar}>
                    <RestartAlt />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
          }
          secondary={
            <>
              Navigation Lidar Map
              <HelpIconTooltip
                title={
                  <>
                    Used by the robots to know their position. To generate this file, you need to use the{' '}
                    <Link target="_blank" href={MAP_EDITOR_URL} sx={{ color: `${theme.palette.grey[300]} !important` }}>
                      Balyo Map Editor <OpenInNew sx={{ verticalAlign: 'middle', fontSize: '0.8rem' }} />
                    </Link>
                    .
                  </>
                }
                sx={{
                  fontSize: '1rem',
                }}
              />
            </>
          }
        />
      </ListItem>
      <ListItem>
        <input
          accept=".txt"
          type="file"
          onChange={onForegroundLidarChange}
          id="foreground-lidar-input"
          style={{ display: 'none' }}
          ref={refForegroundLidarInput}
        />
        <ListItemIcon>
          <ImageAspectRatio />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              {foregroundLidarMap ? `Imported (${foregroundLidarMap.name})` : 'Not imported'}
              <Tooltip title={!editProjectPerm ? 'You do not have the authorization to edit this value' : ''}>
                <Box component="span">
                  {foregroundLidarMap ? (
                    <IconButton onClick={eraseObstacleLidarMap} disabled={!editProjectPerm}>
                      <Delete
                        sx={{
                          fontSize: '1rem',
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        refForegroundLidarInput.current?.click();
                      }}
                      disabled={!editProjectPerm}
                    >
                      <Edit
                        sx={{
                          fontSize: '1rem',
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Tooltip>
            </>
          }
          secondary={
            <>
              Obstacle Lidar Map
              <HelpIconTooltip
                title={
                  <>
                    This file is optional. Display another map that represents the obstacles seen by the safety lasers.
                    To generate this file, you need to use the{' '}
                    <Link target="_blank" href={MAP_EDITOR_URL} sx={{ color: `${theme.palette.grey[300]} !important` }}>
                      Balyo Map Editor <OpenInNew sx={{ verticalAlign: 'middle', fontSize: '0.8rem' }} />
                    </Link>
                    .
                  </>
                }
                sx={{
                  fontSize: '1rem',
                }}
              />
            </>
          }
        />
      </ListItem>
    </>
  );
}
