import BadgeIcon from '@mui/icons-material/Badge';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NumbersIcon from '@mui/icons-material/Numbers';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PatternIcon from '@mui/icons-material/Pattern';
import StorageIcon from '@mui/icons-material/Storage';
import StraightenIcon from '@mui/icons-material/Straighten';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import type { SelectChangeEvent } from '@mui/material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { closeDialogAction, openDialogAction } from 'actions';
import LowyImg from 'assets/trucks/full/lowy.png';
import LowyCBImg from 'assets/trucks/full/lowyCB.png';
import MC10Img from 'assets/trucks/full/mc10.png';
import MC12Img from 'assets/trucks/full/mc12.png';
import MC15Img from 'assets/trucks/full/mc15.png';
import MO25Img from 'assets/trucks/full/mo25.png';
import MO70Img from 'assets/trucks/full/mo70.png';
import ReachyImg from 'assets/trucks/full/reachy.png';
import TruckyImg from 'assets/trucks/full/trucky.png';
import TuggyImg from 'assets/trucks/full/tuggy.png';
import VeenyImg from 'assets/trucks/full/veeny.png';
import { maximumCharLength, TrucksChips } from 'components/menu-bar/trucks-chip';
import type { RobotModelData } from 'components/utils/robot-model-data';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { capitalize, countBy } from 'lodash';
import { DialogTypes } from 'models';
import type { ReactNode, SyntheticEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { cpxStrToPosition } from 'utils/circuit';
import { computeTurnMinRadius } from 'utils/compute-turn-min-radius';
import { toRad } from 'utils/helpers';
import { PreferencesService } from 'utils/preferences';
import { truckNameToMarketingTruckName } from 'utils/robots-name';
import { batteryHardwares, batteryTypes } from 'utils/robots/models';

interface RobotDataTab {
  ID: number;
  serial: string;
  name: string | undefined;
  model: string | undefined;
  IP: string | undefined;
  battery: string | undefined;
  communicationMode: string | undefined;
  backrestAbscissa: string | undefined;
  navigationLaserModel: string | undefined;
  forksLength: string | undefined;
  distanceToFront: number | undefined;
  robotsMinimumTurnRadius: string | undefined;
  sideForks: number | undefined;
  robotLengths: string | undefined;
  robotWidth: string | undefined;
}

interface RobotDataDialogProps {
  robotName: string;
}
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export function TabPanel({ children, index, value, ...other }: TabPanelProps): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function RobotDataDialog({ robotName }: RobotDataDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleChangeTabs = useCallback((event: SyntheticEvent, newValue: number): void => {
    setSelectedTabIndex(newValue);
  }, []);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const supervisorIP = useMemo(() => {
    try {
      return PreferencesService.getPreferenceValue('communication/IP_supervisor') as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/IP_supervisor from preferences`, e);
    }
  }, []);

  const trucksPicturePath = useMemo(() => {
    return PreferencesService.getPreferenceValue('general/trucksPicturePath') as string[];
  }, []);
  const trucksPicturesWithoutCurrentSelected = useMemo(() => {
    return Object.entries(countBy(trucksPicturePath)).filter((truckPicturePath) => truckPicturePath[0] !== robotName);
  }, [robotName, trucksPicturePath]);
  const robotIds = useMemo(() => {
    return trucksPicturePath
      .map((path, index) => (path === robotName ? index : null))
      .filter((id): id is number => id !== null);
  }, [robotName, trucksPicturePath]);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

  const robots = useMemo(() => {
    let trucksSerial: string[] | undefined;
    let trucksName: string[] | undefined;
    let trucksModels: string[] | undefined;
    let IPTrucks: string[] | undefined;
    let trucksBattery: string[] | undefined;
    let navigationLaserModel: string[] | undefined;
    let communicationMode: string | undefined;
    let backrestAbscissa: string[] | undefined;
    let forkLength: string[] | undefined;
    let distanceToFront: number[] | undefined;
    let robotLengths: string[] | undefined;
    let robotWidth: string[] | undefined;
    let robotsMinimumTurnRadius: string[] | undefined;
    let sideForks: number[] | undefined;
    try {
      trucksSerial = (PreferencesService.getPreferenceValue('general/trucksSerial') as string[]).filter(
        (serial, index) => robotIds.includes(index)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get general/trucksSerial from preferences`, e);

      return [];
    }

    try {
      trucksName = (PreferencesService.getPreferenceValue('general/trucksName') as string[]).filter(
        (truckName, index) => robotIds.includes(index)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get general/trucksName from preferences`, e);
    }

    try {
      trucksModels = trucksSerial.map(
        (serial) => PreferencesService.getPreferenceValue('general/truckModel', serial) as string
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get trucksModels from preferences`, e);
    }

    try {
      backrestAbscissa = trucksSerial.map(
        (serial) => PreferencesService.getPreferenceValue('general/backrestAbscissa', serial) as string
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get backrestAbscissa from preferences`, e);
    }

    try {
      const sideForkEnable: number[] = [];
      const sideForksEnable = trucksSerial.map(
        (serial) => PreferencesService.getPreferenceValue('sensors/sideForks/enable', serial) as string
      );
      sideForkEnable.push(Number(sideForksEnable));
      sideForks = sideForkEnable;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get backrestAbscissa from preferences`, e);
    }

    try {
      const turretXForksUpPref = trucksSerial.map((serial) =>
        PreferencesService.getPreferenceValue('trajectoryDriver/robotKinematicsModel/turretXPositionForksUp', serial)
      );
      const maxTurretAnglePref = trucksSerial.map((serial) =>
        PreferencesService.getPreferenceValue('trajectoryDriver/robotKinematicsModel/maxTurretAngle', serial)
      );
      const minTurretAnglePref = trucksSerial.map((serial) =>
        PreferencesService.getPreferenceValue('trajectoryDriver/robotKinematicsModel/minTurretAngle', serial)
      );
      const wheelOffset_yPref = trucksSerial.map((serial) =>
        PreferencesService.getPreferenceValue('trajectoryDriver/robotKinematicsModel/turretYPosition', serial)
      );

      const minimumTurnRadius: string[] = [];
      for (let i = 0; i < trucksSerial.length; i++) {
        const turretXPositionForksUp = parseFloat(turretXForksUpPref[i] as string);
        const maxTurretAngle = parseFloat(maxTurretAnglePref[i] as string);
        const minTurretAngle = parseFloat(minTurretAnglePref[i] as string);
        const wheelOffset_y = parseFloat(wheelOffset_yPref[i] as string);

        const minTurnRadiusLeft = turretXPositionForksUp * Math.tan(toRad(90) - toRad(maxTurretAngle)) + wheelOffset_y;
        const minTurnRadiusRight = turretXPositionForksUp * Math.tan(toRad(90) + toRad(minTurretAngle)) - wheelOffset_y;
        const truckName = trucksName?.[i] ?? 'Unknown Truck name';
        const modelData: RobotModelData = {
          name: truckName,
          minTurretAngle: minTurretAngle,
          maxTurretAngle: maxTurretAngle,
          turretYPosition: wheelOffset_y,
          turretXPositionForks: turretXPositionForksUp,
        };
        modelData.minTurnRadius = computeTurnMinRadius(modelData)[i];
        const minTurnRadius = Math.max(minTurnRadiusLeft, minTurnRadiusRight);
        minimumTurnRadius.push(minTurnRadius.toFixed(2));
        robotsMinimumTurnRadius = minimumTurnRadius;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get minimumTurnRadius from preferences`, e);
    }

    try {
      const forkLengthArray: string[] = [];
      for (let i = 0; i < trucksSerial.length; i++) {
        const forksEndAbscissaValue = trucksSerial.map((serial) =>
          PreferencesService.getPreferenceValue('general/forksEndAbscissa', serial)
        );
        const backrestAbscissaValue = trucksSerial.map((serial) =>
          PreferencesService.getPreferenceValue('general/backrestAbscissa', serial)
        );

        const forkLengthCalculate =
          parseFloat(forksEndAbscissaValue[i] as string) - parseFloat(backrestAbscissaValue[i] as string);
        const forkLengthCalculateToPositive = Math.abs(forkLengthCalculate);
        const forkLengthConvert = forkLengthCalculateToPositive.toFixed(2);
        forkLengthArray.push(forkLengthConvert);
        forkLength = forkLengthArray;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get ForksLength from preferences`, e);
    }

    try {
      for (let i = 0; i < trucksSerial.length; i++) {
        const shapes = trucksSerial
          .map((serial) => PreferencesService.getPreferenceValue('general/shape', serial) as string[])
          .map((shape) => shape.map((strCpx) => cpxStrToPosition(strCpx, false)));

        const extremumShapes = shapes.map((shape) => ({
          minX: Math.min(...shape.map((pos) => pos[0])),
          maxX: Math.max(...shape.map((pos) => pos[0])),
          minY: Math.min(...shape.map((pos) => pos[1])),
          maxY: Math.max(...shape.map((pos) => pos[1])),
        }));
        robotLengths = extremumShapes.map((extremumShape) => (extremumShape.maxX - extremumShape.minX).toFixed(2));
        robotWidth = extremumShapes.map((extremumShape) => (extremumShape.maxY - extremumShape.minY).toFixed(2));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get shape points of all robots from preferences`, e);
    }

    try {
      IPTrucks = (PreferencesService.getPreferenceValue('communication/TCP/IP_trucks') as string[]).filter(
        (truckIP, index) => robotIds.includes(index)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/TCP/IP_trucks from preferences`, e);
    }

    try {
      trucksBattery = trucksSerial.map(
        (serial) => PreferencesService.getPreferenceValue('battery/batteryType', serial) as string
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get battery/batteryType from preferences`, e);
    }

    try {
      navigationLaserModel = trucksSerial.map(
        (serial) => PreferencesService.getPreferenceValue('navigationLaser/laserModel', serial) as string
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get navigationLaser/laserModel from preferences`, e);
    }

    try {
      communicationMode = PreferencesService.getPreferenceValue('communication/mode') as string;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Could not get communication/mode from preferences`, e);
    }

    return trucksSerial.map((serial, index) => ({
      ID: robotIds[index] + 1,
      serial,
      name: trucksName && trucksName[index],
      model: trucksModels && trucksModels[index],
      IP: IPTrucks && IPTrucks[index],
      battery: trucksBattery && trucksBattery[index],
      communicationMode,
      backrestAbscissa: backrestAbscissa && backrestAbscissa[index],
      navigationLaserModel: navigationLaserModel && navigationLaserModel[index],
      forksLength: forkLength && forkLength[index],
      distanceToFront: distanceToFront && distanceToFront[index],
      robotsMinimumTurnRadius: robotsMinimumTurnRadius && robotsMinimumTurnRadius[index],
      sideForks: sideForks && sideForks[index],
      robotLengths: robotLengths && robotLengths[index],
      robotWidth: robotWidth && robotWidth[index],
    }));
  }, [robotIds]);

  const fancyName = useMemo(() => truckNameToMarketingTruckName(robotName.split('/')[1]), [robotName]);

  const [editRobotIP, setEditRobotIP] = useState<number | null>(null);
  const [editRobotBattery, setEditRobotBattery] = useState<number | null>(null);

  const openProjectSettings = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.ProjectSettings,
        payload: undefined,
      })
    );
  }, [dispatch]);

  const robotSrc = useMemo(() => robotNameToSrc(robotName), [robotName]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="lg" onClose={handleClose}>
      <DialogTitle>
        Robot Information
        <Box component="span" sx={{ float: 'right' }}>
          <TrucksChips trucksPictures={trucksPicturesWithoutCurrentSelected} />
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} minHeight={'50%'}>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            {robotSrc && <img src={robotSrc} alt={robotName} style={{ maxWidth: '100%', maxHeight: '100%' }} />}

            <List dense={true} disablePadding={true}>
              <ListSubheader>Robot Manager Information</ListSubheader>
              {supervisorIP && (
                <ListItem>
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={supervisorIP}
                    secondary={
                      <>
                        Supervisor IP
                        <Tooltip title="This value can be edited in the project settings">
                          <IconButton onClick={openProjectSettings}>
                            <OpenInNewIcon
                              sx={{
                                fontSize: '1rem',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={6} sx={{ background: 'rgba(255, 255, 255, 0.5)' }}>
            <Typography variant="h4" gutterBottom component="div">
              <Typography variant="h5" gutterBottom component="span" sx={{ color: 'grey !important' }}>
                {robotIds.length}x{' '}
              </Typography>
              {fancyName}
            </Typography>
            <Box component="div" sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
              <Tabs
                value={selectedTabIndex}
                onChange={handleChangeTabs}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {robots.map((robot, index) => {
                  const displaySerialLengthError = robot.serial.length >= maximumCharLength;
                  const displayNameLengthError = (robot.name?.length ?? 0) >= maximumCharLength;

                  const error = displaySerialLengthError || displayNameLengthError;

                  return (
                    <Tab
                      key={robot.ID}
                      label={<Typography color={error ? 'error' : undefined}>{robot.name}</Typography>}
                      tabIndex={index}
                    />
                  );
                })}
              </Tabs>
              {robots.map((robot, index) => (
                <RobotPropertiesTab
                  key={robot.ID}
                  index={index}
                  robot={robot}
                  robots={robots}
                  selectedTabIndex={selectedTabIndex}
                  editRobotIP={editRobotIP}
                  setEditRobotIP={setEditRobotIP}
                  editRobotBattery={editRobotBattery}
                  setEditRobotBattery={setEditRobotBattery}
                  openProjectSettings={openProjectSettings}
                  fancyName={fancyName}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

interface RobotPropertiesTabProps {
  robots: RobotDataTab[];
  robot: RobotDataTab;
  selectedTabIndex: number;
  index: number;
  editRobotIP: number | null;
  setEditRobotIP: React.Dispatch<React.SetStateAction<number | null>>;
  editRobotBattery: number | null;
  setEditRobotBattery: React.Dispatch<React.SetStateAction<number | null>>;
  openProjectSettings: () => void;
  fancyName: string;
}
function RobotPropertiesTab(props: RobotPropertiesTabProps): JSX.Element {
  const {
    robots,
    robot,
    selectedTabIndex,
    index,
    editRobotIP,
    setEditRobotIP,
    editRobotBattery,
    setEditRobotBattery,
    openProjectSettings,
    fancyName,
  } = props;

  const refInputIpRobot = useRef<HTMLInputElement | null>(null);

  const [battery, setBattery] = useState(robot.battery);

  const batteryTypesList = robot.model?.includes('LINDE_R')
    ? batteryTypes['48V']
    : robot.model?.includes('LINDE_K')
      ? batteryTypes['80V']
      : batteryTypes['24V'];

  const updateIpRobot = useCallback(
    async (robotId: number, newIP: string): Promise<void> => {
      const isIP = (await import('is-ip')).isIP; // dynamically import it because we have a bug in jest
      if (isIP(newIP)) {
        // we need to retrieve the content of the preference
        // update it
        // and update the pref
        const installDoc = PreferencesService.getInstallDocument();
        if (!installDoc) {
          // eslint-disable-next-line no-console
          console.error(`Could not get install document, the project may not be loaded but it should`);

          return;
        }

        const robot = robots.find((robot) => robot.ID === robotId + 1);
        if (!robot) {
          // eslint-disable-next-line no-console
          console.error(`Could not find robot with id ${robotId}`);

          return;
        }

        const IPTrucks = installDoc.querySelector('[name="communication"] [name="TCP"] [name="IP_trucks"]');
        if (IPTrucks) {
          // const IPTrucks = IPTrucks.cloneNode(true) as Element;
          const valueEls = IPTrucks.querySelectorAll('value');

          if (valueEls && valueEls.length >= robotId) {
            valueEls[robotId].textContent = newIP;

            const robot = robots[robotId];
            if (robot) {
              robot.IP = newIP;
            }

            const [ok] = await PreferencesService.setPreferenceValue(`communication/TCP/IP_trucks[${robotId}]`, newIP);
            if (ok) {
              const truckName = robot.name;

              robot.IP = newIP;

              SnackbarUtils.success(`IP address ${newIP} set to ${truckName}`);

              setEditRobotIP(null);
            } else {
              SnackbarUtils.error(`Could not set IP address ${newIP} to robot ${robotId}`);
            }
          } else {
            // eslint-disable-next-line no-console
            console.error(
              `Could not get IPTrucksClone valueEls or not enough robots in the pref (min robots: ${robotId + 1})`,
              valueEls
            );

            SnackbarUtils.error('Failed to set the preference, please check your install.xml file');
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(`Could not get IPTrucks from install document`);

          SnackbarUtils.error('Could not get IPTrucks from the preferences');
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`IP ${newIP} is not a valid IP address`);

        SnackbarUtils.error(`${newIP} is not a valid IP address`);
      }
    },
    [robots, setEditRobotIP]
  );

  const handleChangeBattery = (event: SelectChangeEvent): void => {
    setBattery(event.target.value);
  };

  function findBatteryHardware(battery: string): string | null {
    for (const batteryHardware in batteryHardwares) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (batteryHardwares[batteryHardware]?.includes(battery)) {
        return batteryHardware;
      }
    }

    return null;
  }

  const updateRobotBattery = useCallback(
    async (robot: RobotDataTab, newBattery?: string, option?: { displaySnackbar: boolean }): Promise<void> => {
      if (!newBattery) {
        return;
      }

      //We need to find the associated robot file and update it with the new battery value
      const truckDocs = PreferencesService.getPreferencesXmlDocument().trucks;

      const robotTruckDoc = truckDocs.find((doc) => doc.serial === robot.serial)?.document;

      const associatedHardware = findBatteryHardware(newBattery);

      if (!robotTruckDoc) {
        // eslint-disable-next-line no-console
        console.error(`Could not get truck document, the project may not be loaded but it should`);

        return;
      }

      if (!associatedHardware) {
        // eslint-disable-next-line no-console
        console.error(`error`);

        return;
      }

      const prefBatteryType = 'battery/batteryType';

      const prefBatteryHardware = 'battery/hardware';

      const [okBatteryType] = await PreferencesService.setPreferenceValue(
        prefBatteryType,
        newBattery,
        false,
        'pref',
        true,
        robotTruckDoc,
        robot.serial
      );

      const [okBatteryHardware] = await PreferencesService.setPreferenceValue(
        prefBatteryHardware,
        associatedHardware,
        false,
        'pref',
        true,
        robotTruckDoc,
        robot.serial
      );

      if (okBatteryType && okBatteryHardware) {
        robot.battery = newBattery;

        if (option?.displaySnackbar) {
          SnackbarUtils.success(`Robot battery changed`);
        }

        setEditRobotBattery(null);
      } else {
        SnackbarUtils.error(`An error occurred and the change of battery model was not successful`);
      }
    },
    [setEditRobotBattery]
  );

  const updateAllRobotBattery = useCallback(
    (newBattery?: string): void => {
      if (!newBattery) return;

      for (let i = 0; i < robots.length; i++) {
        const robot = robots[i];

        updateRobotBattery(robot, newBattery, { displaySnackbar: false });

        robot.battery = newBattery;
      }

      SnackbarUtils.success(`All ${fancyName} battery changed`);
    },
    [fancyName, robots, updateRobotBattery]
  );

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  const displayForksLength = robot.sideForks !== 1;
  const displayMinimumTurnRadius = robot.sideForks !== 1;

  const displaySerialLengthError = robot.serial.length >= maximumCharLength;
  const displayNameLengthError = (robot.name?.length ?? 0) >= maximumCharLength;

  return (
    <TabPanel key={robot.ID} value={selectedTabIndex} index={index}>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', p: '0.5rem' }}>
        <Box component="div">
          {robot.ID !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <NumbersIcon />
              </ListItemIcon>
              <ListItemText primary={<>{robot.ID}</>} secondary={'ID'} />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.serial !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Stack direction="row" columnGap={0.5}>
                    {displaySerialLengthError && (
                      <Tooltip title="The serial is over 32 characters causing the simulation and install to fail to load">
                        <ErrorIcon color="error" />
                      </Tooltip>
                    )}
                    <Typography color={displaySerialLengthError ? 'error' : 'undefined'}>{robot.serial}</Typography>
                  </Stack>
                }
                secondary={'Serial'}
              />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.name !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Stack direction="row" columnGap={0.5}>
                    {displayNameLengthError && (
                      <Tooltip title="The name is over 32 characters causing the simulation and install to fail to load">
                        <ErrorIcon color="error" />
                      </Tooltip>
                    )}
                    <Typography color={displayNameLengthError ? 'error' : 'undefined'}>{robot.name}</Typography>
                  </Stack>
                }
                secondary={'Name'}
              />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.model !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <PatternIcon />
              </ListItemIcon>
              <ListItemText primary={<>{robot.model}</>} secondary={'Model'} />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.IP !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <NetworkWifi2BarIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {editRobotIP === index ? (
                      <TextField
                        defaultValue={robot.IP}
                        focused={true}
                        onBlur={() => setTimeout(() => setEditRobotIP(null), 100)}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setEditRobotIP(null)}>
                                <ClearIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  if (refInputIpRobot.current)
                                    updateIpRobot(robot.ID - 1, refInputIpRobot.current.value);
                                }}
                              >
                                <DoneIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputRef={refInputIpRobot}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (refInputIpRobot.current) updateIpRobot(robot.ID - 1, refInputIpRobot.current.value);
                          }
                        }}
                      />
                    ) : (
                      <>
                        {robot.IP}
                        {editRobotIP === null ? (
                          <Tooltip title={!editProjectPerm ? 'You do not have the permission to edit the value' : ''}>
                            <Box component={'span'}>
                              <IconButton
                                onClick={() => {
                                  setEditRobotIP(index);
                                }}
                                disabled={!editProjectPerm}
                              >
                                <EditIcon
                                  sx={{
                                    fontSize: '0.875rem',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        ) : undefined}
                      </>
                    )}
                  </>
                }
                secondary={'IP'}
              />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          <EditRobotBattery
            editRobotBattery={editRobotBattery}
            index={index}
            battery={battery}
            batteryTypesList={batteryTypesList}
            fancyName={fancyName}
            robot={robot}
            editProjectPerm={editProjectPerm}
            handleChangeBattery={handleChangeBattery}
            setEditRobotBattery={setEditRobotBattery}
            updateRobotBattery={updateRobotBattery}
            updateAllRobotBattery={updateAllRobotBattery}
          />
        </Box>
        <Box component="div">
          {robot.navigationLaserModel && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <GpsFixedIcon />
              </ListItemIcon>
              <ListItemText primary={<>{robot.navigationLaserModel}</>} secondary={'Navigation Laser Model'} />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.communicationMode && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <CellWifiIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {capitalize(robot.communicationMode)}{' '}
                    <Tooltip title="This value can be edited in the project settings">
                      <IconButton onClick={openProjectSettings}>
                        <OpenInNewIcon
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                secondary={'Communication Mode'}
              />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.backrestAbscissa !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <ForkLeftIcon />
              </ListItemIcon>

              <ListItemText
                primary={<>{robot.backrestAbscissa} m</>}
                secondary={
                  <>
                    Backrest abscissa
                    <HelpIconTooltip
                      title="Length in between the backrest and the rollers"
                      sx={{
                        fontSize: '1rem',
                      }}
                    />
                  </>
                }
              />
            </ListItem>
          )}
        </Box>
        {displayForksLength ? (
          <Box component="div">
            {robot.forksLength !== undefined && (
              <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemIcon>
                  <StraightenIcon />
                </ListItemIcon>
                <ListItemText primary={<>{robot.forksLength} m</>} secondary={'Forks length '} />
              </ListItem>
            )}
          </Box>
        ) : undefined}

        <Box component="div">
          {robot.distanceToFront !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <KeyboardTabIcon />
              </ListItemIcon>
              <ListItemText primary={<>{robot.distanceToFront} m</>} secondary={'Roller to front distance'} />
            </ListItem>
          )}
        </Box>
        {displayMinimumTurnRadius ? (
          <Box component="div">
            {robot.robotsMinimumTurnRadius !== undefined && (
              <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemIcon>
                  <UTurnLeftIcon />
                </ListItemIcon>
                <ListItemText primary={<>{robot.robotsMinimumTurnRadius} m</>} secondary={'Minimum turn radius'} />
              </ListItem>
            )}
          </Box>
        ) : undefined}
        <Box component="div">
          {robot.robotLengths !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <StraightenIcon />
              </ListItemIcon>
              <ListItemText primary={<>{robot.robotLengths} m</>} secondary={'Robot Length'} />
            </ListItem>
          )}
        </Box>
        <Box component="div">
          {robot.robotWidth !== undefined && (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon>
                <StraightenIcon />
              </ListItemIcon>
              <ListItemText primary={<>{robot.robotWidth} m</>} secondary={'Robot Width'} />
            </ListItem>
          )}
        </Box>
      </Box>
    </TabPanel>
  );
}

interface EditRobotBatteryProps {
  editRobotBattery: number | null;
  index: number;
  battery: string | undefined;
  batteryTypesList: string[];
  fancyName: string;
  robot: RobotDataTab;
  editProjectPerm: boolean | undefined;
  handleChangeBattery: (event: SelectChangeEvent) => void;
  setEditRobotBattery: React.Dispatch<React.SetStateAction<number | null>>;
  updateRobotBattery: (
    robot: RobotDataTab,
    newBattery?: string,
    option?: {
      displaySnackbar: boolean;
    }
  ) => Promise<void>;
  updateAllRobotBattery: (newBattery?: string) => void;
}

export function EditRobotBattery({
  editRobotBattery,
  index,
  battery,
  batteryTypesList,
  fancyName,
  robot,
  editProjectPerm,
  handleChangeBattery,
  setEditRobotBattery,
  updateRobotBattery,
  updateAllRobotBattery,
}: EditRobotBatteryProps): JSX.Element {
  return (
    <>
      {robot.battery !== undefined && (
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemIcon>
            <BatteryCharging90Icon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {editRobotBattery === index ? (
                  <Box component={'div'} alignItems={'center'} display={'flex'}>
                    <FormControl>
                      <Select size="small" value={battery || ''} onChange={handleChangeBattery}>
                        {batteryTypesList.map((batteryType) => (
                          <MenuItem value={batteryType}>{batteryType.replace(/_/g, ' ')}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <IconButton onClick={() => setEditRobotBattery(null)}>
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        updateRobotBattery(robot, battery, { displaySnackbar: true });
                      }}
                    >
                      <DoneIcon />
                    </IconButton>
                    <Tooltip title={`Change all ${fancyName} battery model`}>
                      <IconButton
                        onClick={() => {
                          updateAllRobotBattery(battery);
                        }}
                      >
                        <DoneAllIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <>
                    {robot.battery.replace(/_/g, ' ')}
                    {editRobotBattery === null ? (
                      <Tooltip title={!editProjectPerm ? 'You do not have the permission to edit the value' : ''}>
                        <Box component={'span'}>
                          <IconButton
                            onClick={() => {
                              setEditRobotBattery(index);
                            }}
                            disabled={!editProjectPerm}
                          >
                            <EditIcon
                              sx={{
                                fontSize: '0.875rem',
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    ) : undefined}
                  </>
                )}
              </>
            }
            secondary={'Battery Type'}
          />
        </ListItem>
      )}
    </>
  );
}

export function robotNameToSrc(robotName: string, split = true): string {
  let name: string;
  if (split) {
    const splittedName = robotName.split('/');
    if (!splittedName || splittedName.length !== 2) return '';

    name = splittedName[1].toLowerCase();
  } else {
    name = robotName.toLowerCase();
  }

  switch (name) {
    case 'lowy':
    case 'lmaticlhp': {
      return LowyImg;
    }

    case 'lowy cb':
    case 'cblowy':
    case 'lmaticac': {
      return LowyCBImg;
    }

    case 'reachy':
    case 'rmatic': {
      return ReachyImg;
    }

    case 'trucky':
    case 'tmatic':
    case 'mpe080':
    case 'mpe080_long': {
      return TruckyImg;
    }

    case 'tuggy':
    case 'pmatic': {
      return TuggyImg;
    }

    case 'veeny':
    case 'kmatic': {
      return VeenyImg;
    }

    case 'mc10': {
      return MC10Img;
    }

    case 'mc12': {
      return MC12Img;
    }

    case 'mc15': {
      return MC15Img;
    }

    case 'mo25': {
      return MO25Img;
    }

    case 'mo70': {
      return MO70Img;
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown robot name: ${robotName}`);

      return '';
    }
  }
}
