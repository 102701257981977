import type { UserPermissions } from 'shared';
import { Container } from 'typescript-ioc';

/**
 * Checks if the user has a specific permission.
 * If the user is not logged in, it assumes all permissions.
 *
 * @param {UserPermissions} permissionName - The name of the permission to check.
 * @returns {boolean} - Returns true if the user has the permission, false otherwise.
 */
export async function checkPermission(permissionName: UserPermissions): Promise<boolean> {
  const AuthService = (await import('./auth.service')).AuthService; // ioc import issues...

  const authService = Container.get(AuthService);

  if (!authService.authenticated) return true; // if not logged in, assume all permissions

  return authService.checkPermission(permissionName);
}
