import type { LayerGroupData } from 'reducers/circuit/state';
import store from 'store';
import type { Truck } from 'utils/preferences';
import { getPreferenceValue } from 'utils/preferences';

/**
 * Get the layer group name of a robot
 * @param robot the robot
 * @returns the layer group name
 */
export function getRobotLayerGroupName(robot: Truck): string {
  const robotSerial = robot.serial;

  let robotLayerGroupName = (() => {
    try {
      return getPreferenceValue('general/modelCircuitName', robotSerial);
    } catch (error) {}
  })();

  if (!robotLayerGroupName || Array.isArray(robotLayerGroupName)) {
    robotLayerGroupName = robot.modelName;
  }

  return robotLayerGroupName;
}

/**
 * Get the layer group of a robot
 * @param robot the robot
 * @returns the layer group
 */
export function getRobotLayerGroup(robot: Truck): LayerGroupData | undefined {
  const layerGroupName = getRobotLayerGroupName(robot);

  const layerGroups = store.getState().circuit.present.layers.layerGroups;
  const layerGroupsArr = Object.values(layerGroups);

  const layerGroup = layerGroupsArr.find((layerGroup) => layerGroup.name === layerGroupName);

  return layerGroup;
}
