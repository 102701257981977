import type { MapsActions } from 'actions/maps';
import { MapsActionTypes } from 'actions/maps';
import type { MapImage } from 'models/maps';
import type { MapImages } from './state';

export function getMapImageInitialState(): MapImages {
  return {
    mapImages: [],
    error: undefined,
    updated: false,
    updating: false,
    openProperties: undefined,
  };
}

export function mapImageReducer(state = getMapImageInitialState(), action: MapsActions): MapImages {
  switch (action.type) {
    case MapsActionTypes.ImportMapImage: {
      return {
        ...state,
        updating: true,
      };
    }

    case MapsActionTypes.ImportMapImageSuccess: {
      const { imageURL, name, originalHeight, originalWidth } = action.payload;

      if (state.mapImages?.length && state.mapImages?.filter((mapImage) => mapImage.name === name).length) {
        return {
          ...state,
          updating: false,
          updated: true,
          mapImages: state.mapImages.map((mapImage) =>
            mapImage.name === name
              ? { URL: imageURL, name, height: undefined, x: 0, y: 0, originalHeight, originalWidth }
              : mapImage
          ),
        };
      }

      return {
        ...state,
        updating: false,
        updated: true,
        mapImages: [
          ...(state.mapImages ?? []),
          { URL: imageURL, name, height: undefined, x: 0, y: 0, originalHeight, originalWidth },
        ],
      };
    }

    case MapsActionTypes.ImportMapImageFailure: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        updating: false,
        updated: true,
      };
    }

    case MapsActionTypes.SetImageHeight: {
      const { imageHeight, scaling, name } = action.payload;

      if (!state.mapImages) {
        return state;
      }

      return {
        ...state,
        mapImages: state.mapImages.map((prevMapImage) => {
          return prevMapImage.name === name ? { ...prevMapImage, height: imageHeight, scaling } : prevMapImage;
        }),
        updating: false,
        updated: true,
      };
    }

    case MapsActionTypes.ClearMapImage: {
      const { name } = action.payload;

      if (!state.mapImages) {
        return state;
      }

      return {
        ...getMapImageInitialState(),
        mapImages: state.mapImages.filter((mapImage) => mapImage.name !== name) ?? state,
      };
    }

    case MapsActionTypes.ChangeDisplayStateMapImageProperties: {
      return {
        ...state,
        openProperties: action.payload.openIndex,
      };
    }

    case MapsActionTypes.UpdateMapImageProperties: {
      const { properties, targetName } = action.payload;
      if (targetName && targetName !== properties.name) {
        return {
          ...state,
          mapImages: state.mapImages?.map((mapImage) =>
            mapImage.name === targetName ? { ...mapImage, ...properties } : mapImage
          ),
        };
      }

      if (state.mapImages?.find((mapImage) => mapImage.name === properties.name)) {
        return {
          ...state,
          mapImages: state.mapImages?.map((mapImage) =>
            mapImage.name === properties.name ? { ...mapImage, ...properties } : mapImage
          ),
        };
      }

      return {
        ...state,
        mapImages: [...(state.mapImages ?? []), properties as MapImage],
      };
    }

    default: {
      return state;
    }
  }
}

export const getMapImage = (state: MapImages): MapImage => state.mapImages?.[state.openProperties ?? 0] as MapImage;
export const getMapImageUpdating = (state: MapImages): MapImages['updating'] => state.updating;
export const getMapImageUpdated = (state: MapImages): MapImages['updated'] => state.updated;
