import React from 'react';
import { LargeError } from 'components/core';
import { FullPageLayout } from 'components/layouts';

interface NotFoundPageProps {}

export function NotFoundPage(props: NotFoundPageProps): JSX.Element {
  return (
    <FullPageLayout verticallyCentered>
      <LargeError code={404} />
    </FullPageLayout>
  );
}
